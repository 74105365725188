<script setup lang="ts">
import { computed } from 'vue';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { Baggage, BaggageList } from '@/models/baggage';
import UiDateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import { columns, createCustomRow } from '@/_components/baggage/baggage-table-view-definition';

const props = defineProps<{
    baggageList?: BaggageList;
}>();

const emits = defineEmits<{
    (e: 'clickBaggage', record: Baggage): void,
}>();

//
// データ
//
const list = computed(() => props.baggageList?.data ?? []);
const isOver50 = computed(() => (props.baggageList?.totalRecordCount ?? 0) > 50);

/**
 * 行クリックハンドラー
 */
const onClickRow = (baggage: Baggage) => emits('clickBaggage', baggage);

/**
 * テーブルカスタマイズ定義
 */
const customRow = (record: Baggage) => createCustomRow(record, onClickRow);

/**
 * 運賃フォーマッター
 */
const freightText = (baggage: Baggage) => (new BaggageFreightValue(baggage.freight)).format();
</script>

<template>
    <div>
        <a-table class="app-result-list-table"
                 bordered
                 :columns="columns"
                 :data-source="list"
                 rowKey="id"
                 size="middle"
                 :loading="false"
                 :row-class-name="() => 'app-table-row app-table-row--clickable'"
                 :pagination="false"
                 :custom-row="customRow">
            <template v-slot:departureArrival="text, record">
                <ui-date-time-location-label :departure-min="record.departureMin"
                                             :departure-max="record.departureMax"
                                             :departure-pref="record.departurePref"
                                             :departure-city="record.departureCity"
                                             :arrival-min="record.arrivalMin"
                                             :arrival-max="record.arrivalMax"
                                             :arrival-pref="record.arrivalPref"
                                             :arrival-city="record.arrivalCity"
                ></ui-date-time-location-label>
            </template>
            <template v-slot:freight="text, record">
                <div class="table-row-freight">
                    <div class="freight-text">{{ freightText(record) }}</div>
                    <div class="highway-fare-text" v-if="record.highwayFareFlg">高速代<b>あり</b></div>
                    <div v-else class="highway-fare-text highway-fare-text--none">高速代なし</div>
                </div>
            </template>
            <template v-slot:truckWeight="text, record">
                <div class="table-row-truck-weight">
                    <div class="table-row-ellipsis-text">{{ record.truckWeight.label }}</div>
                </div>
            </template>
            <template v-slot:truckModel="text, record">
                <div class="table-row-truck-type">
                    <div class="table-row-ellipsis-text">{{ record.truckModel.label }}</div>
                </div>
            </template>
            <template v-slot:type="text, record">
                <div class="table-row-type">
                    <div class="table-row-ellipsis-text">{{ record.type }}</div>
                </div>
            </template>
        </a-table>

        <div class="list-notice" v-if="isOver50">新着50件の荷物を表示しています</div>
    </div>
</template>

<style scoped lang="less">
.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

.freight-text {
    font-weight: bold;
    color: @heading-color;
}

.list-notice {
    margin-top: 16px;
}

::v-deep .app-result-list-table .ant-table-bordered .ant-table-body > table {
    border-left: 1px solid @border-color-split;
    border-right: 1px solid @border-color-split;
}
</style>
