import _ from 'lodash';
import { DataLayerObject, EventData } from '@/types/google-tag-manager';

export const gtm = {
    setPage: (path: string, title?: string): void => {
        if (window.dataLayer === undefined) {
            return;
        }
        window.dataLayer.push({
            event: 'setPage',
            pageData: {
                path,
                title,
            },
        });
    },

    setUser: (companyId?: number, userId?: number): void => {
        if (window.dataLayer === undefined) {
            return;
        }
        const lastEvent = _.findLast(window.dataLayer, (each: DataLayerObject) => each.event === 'setUser');
        if (lastEvent?.userData?.userId === userId) {
            return;
        }
        window.dataLayer.push({
            event: 'setUser',
            userData: {
                companyId,
                userId,
            },
        });
    },

    setPlan: (type?: string): void => {
        if (window.dataLayer === undefined) {
            return;
        }
        const lastEvent = _.findLast(window.dataLayer, (each: DataLayerObject) => each.event === 'setPlan');
        if (lastEvent?.planData?.type === type) {
            return;
        }
        window.dataLayer.push({
            event: 'setPlan',
            planData: {
                type,
            },
        });
    },

    setEvent: (name: string, data?: EventData): void => {
        if (window.dataLayer === undefined) {
            return;
        }
        window.dataLayer.push({
            event: 'setEvent',
            eventData: { name, data },
        });
    },
};
