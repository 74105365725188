<script setup lang="ts">
import BaggageFreight from '@/_components/ui/BaggageFreight.vue';
import CompanyProfileName from '@/_components/ui/CompanyProfileName.vue';
import DateTimeLabelText from '@/_components/ui/DateTimeLabelText.vue';
import DateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import FavoriteButton from '@/_components/ui/FavoriteButton.vue';
import TableRowBadge from '@/_components/ui/TableRowBadge.vue';
import { BAGGAGE_SEARCH_PROVIDER_KEY, BaggageRow } from '@/composables/provider/baggage-search-provider';
import { Const } from '@/const';
import { CustomRow } from '@/types/ant-design';
import { injectStrictly } from '@/util/vue';
import { useElementSize } from '@vueuse/core';
import { computed, ref } from 'vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import Amount from '@/_components/ui/Amount.vue';
import {
    baggageColumns,
    baggageCustomRow,
    baggageRawClassName
} from '@/_components/baggage/baggage-search-table-view-definition';
import { useRouting } from '@/composables/helper/routing';

const root = ref<HTMLElement | null>(null);
const { width: tableWidth } = useElementSize(root);
const showAsSingle = computed<boolean>(() => tableWidth.value > Const.FLEXIBLE_TABLE_MODE_THRESHOLD);
const props = defineProps<{
    completeUnmarkFavoriteFn?: (baggageId: number) => Promise<void>,
}>();
const emits = defineEmits<{
    (e: 'selected', baggageId: number | undefined): void,
}>();

const {
    state: { list, loading, selectedBaggageId, showRecommend, },
    markFavorite,
    clickUnmarkFavorite,
    cancelUnmarkFavorite,
    baggageProperty: {
        isFreightUndecided,
        handlingDetail,
        handlingOverview,
    },
} = injectStrictly(BAGGAGE_SEARCH_PROVIDER_KEY);
const { openBaggageRecommendReturns } = useRouting();
const columns = computed(() => baggageColumns(showAsSingle.value, showRecommend.value));
const customRow = (record: BaggageRow): CustomRow => baggageCustomRow(record, () => emits('selected', record.id));
const rowClassName = (record: BaggageRow): string => baggageRawClassName(record, selectedBaggageId.value);

const onClickUnmarkFavorite = (baggageId: number) => {
    clickUnmarkFavorite(baggageId, async (completeBaggageId: number) => {
        if (props.completeUnmarkFavoriteFn) {
            await props.completeUnmarkFavoriteFn(completeBaggageId);
        }
    });
};
const onClickRecommendations = (event: Event, baggageId: number): void => {
    event.stopPropagation();
    openBaggageRecommendReturns(baggageId);
};

const colorToName = (color: string): string => {
    return {
        '#eb2f96': 'pink',
        '#f5222d': 'red',
        '#fa8c16': 'orange',
        '#52c41a': 'green',
        '#13c2c2': 'cyan',
        '#1890ff': 'blue',
        '#722ed1': 'purple'
    }[color] ?? '';
};

</script>

<template>
    <div ref="root">
        <a-spin :spinning="loading">
            <slot name="header" />
            <a-table class="app-result-list-table no-alternating"
                     bordered
                     :columns="columns"
                     :data-source="list"
                     rowKey="id"
                     :custom-row="customRow"
                     :size="`${showAsSingle ? 'small' : 'middle'}`"
                     :scroll="{x: 800}"
                     :pagination="false"
                     :row-class-name="rowClassName"
                     v-if="list">
                <template #action="text, record">
                    <div class="table-row-action">
                        <table-row-badge v-if="record.underNegotiation" class="table-row-action__badge"
                                         type="dark">商談中
                        </table-row-badge>
                        <table-row-badge v-if="record.newFlg" class="table-row-action__badge">新着
                        </table-row-badge>
                        <table-row-badge v-if="record.closed" class="table-row-action__badge badge-closed">成約済
                        </table-row-badge>
                        <table-row-badge v-if="record.expire" class="table-row-action__badge badge-expire">期限切れ
                        </table-row-badge>
                        <table-row-badge v-if="record.cancel" class="table-row-action__badge badge-cancel">取り下げ済
                        </table-row-badge>
                        <table-row-badge v-if="record.noAccess" class="table-row-action__badge badge-no-access">非公開
                        </table-row-badge>
                        <div class="favorite-buttons">
                            <favorite-button :state="record.favorite"
                                             @mark="markFavorite(record.id)"
                                             @unmark="onClickUnmarkFavorite(record.id)"
                                             @cancelUnmark="cancelUnmarkFavorite(record.id)"/>
                        </div>
                    </div>
                </template>

                <template #company="text, record">
                    <a-tooltip placement="bottom" :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                        <template #title>
                            <company-profile-name v-if="record.company?.name" :name="record.company.name"/>
                        </template>
                        <div class="ant-table-row-cell-ellipsis company-name">
                            <company-profile-name v-if="record.company?.name" :name="record.company.name"/><br/>
                            <span v-if="record.shipperName">（{{ record.shipperName }}）</span>
                        </div>
                    </a-tooltip>
                </template>
                <template v-slot:label="text, record">
                    <span v-if="record.labelText">
                        <a-tooltip :title="record.labelText" placement="bottom">
                            <a-tag :color="colorToName(record.labelColor)" style="max-width: 120px">
                                <div class="label-text">{{ record.labelText }}</div>
                            </a-tag>
                        </a-tooltip>
                    </span>
                </template>
                <template #express="text, record">
                    <div class="table-row-express">
                        <template v-if="record.express">○</template>
                        <template v-else>×</template>
                    </div>
                </template>
                <template #departureArrival="text, record">
                    <date-time-location-label v-if="record.departureMin"
                                              :departure-min="record.departureMin"
                                              :departure-max="record.departureMax"
                                              :departure-pref="record.departurePref"
                                              :departure-city="record.departureCity"
                                              :arrival-min="record.arrivalMin"
                                              :arrival-max="record.arrivalMax"
                                              :arrival-pref="record.arrivalPref"
                                              :arrival-city="record.arrivalCity"/>
                </template>
                <template #departureDate="text, record">
                    <div class="table-row-departure-date">
                        <div class="departureDate">
                            <date-time-label-text :date-time-min="record.departureMin"
                                                  :date-time-max="record.departureMax"/>
                        </div>
                    </div>
                </template>
                <template #arrivalDate="text, record">
                    <div class="table-row-arrival-date">
                        <div class="arrivalDate">
                            <date-time-label-text :date-time-min="record.arrivalMin" :date-time-max="record.arrivalMax"/>
                        </div>
                    </div>
                </template>
                <template #departureLocation="text, record">
                    <div class="table-row-departure-location">
                        <div class="departureLocation">
                            {{ record.departurePref.label }}{{ record.departureCity }}
                        </div>
                    </div>
                </template>
                <template #arrow="_text, _record">
                    <div class="table-row-arrow">
                        <div class="arrow">
                            <tbx-icon type="right" :style="{ fontSize: '10px' }"/>
                        </div>
                    </div>
                </template>
                <template #arrivalLocation="text, record">
                    <div class="table-row-arrival-location">
                        <div class="arrivalLocation">
                            {{ record.arrivalPref.label }}{{ record.arrivalCity }}
                        </div>
                    </div>
                </template>
                <template #freight="text, record">
                    <div v-if="!record.noAccess" class="table-row-freight">
                        <div class="freight-text">
                            <baggage-freight :freight="record.freight"/>
                        </div>
                        <template v-if="isFreightUndecided(record)">
                            <div class="reference-freight-text">参考:
                                <amount :amount="record.referenceFreight?.freight"/>
                            </div>
                        </template>
                        <template v-else>
                            <div class="highway-fare-text" v-if="record.highwayFareFlg">高速代<b>あり</b></div>
                            <div v-else class="highway-fare-text">高速代なし</div>
                        </template>
                    </div>
                </template>
                <template #share="text, record">
                    <div class="table-row-share">
                        <template v-if="record.share">○</template>
                        <template v-else-if="record.share !== undefined">×</template>
                    </div>
                </template>
                <template #truckWeight="text, record">
                    <div v-if="record.truckWeight" class="table-row-truck-weight">
                        <div class="table-row-ellipsis-text">{{ record.truckWeight.label }}</div>
                    </div>
                </template>
                <template #truckModel="text, record">
                    <div v-if="record.truckModel" class="table-row-ellipsis-text">{{ record.truckModel.label }}</div>
                </template>
                <template #type="text, record">
                    <div v-if="record.type" class="table-row-type">
                        <div class="table-row-ellipsis-text">{{ record.type }}</div>
                    </div>
                </template>
                <template #handling="text, record">
                    <div class="table-row-handling">
                        <a-tooltip placement="bottom" :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                            <template #title v-if="handlingDetail(record)">
                                <div v-for="line in handlingDetail(record)" :key="line">{{ line }}</div>
                            </template>
                            <div class="table-row-ellipsis-text">{{ handlingOverview(record) }}</div>
                        </a-tooltip>
                    </div>
                </template>
                <template #description="text, record">
                    <div class="description-container">
                        <a-tooltip placement="bottom" :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                            <template #title>
                        <span v-if="record.multipleBaggage">（複数台荷物です）<br
                            v-if="record.description"/></span>{{ record.description }}
                            </template>
                            <a-tag v-if="record.multipleBaggage" class="tag-multiple-baggage">複数台荷物</a-tag>
                            <div class="table-row-ellipsis-text">
                                <span>{{ record.description }}</span>
                            </div>
                        </a-tooltip>
                    </div>
                </template>
                <template #recommendations="text, record">
                    <span v-if="record.recommendationCount && record.recommendationCount.count > 0">
                        <a @click="onClickRecommendations($event, record.id)">
                            <span style="margin-right: 4px">{{ record.recommendationCount.count }}件</span>
                            <tbx-icon style="margin-left: 0" type="external-link" class="external-link-icon" />
                        </a>
                    </span>
                </template>
            </a-table>
            <slot name="footer" />
        </a-spin>
    </div>
</template>

<style scoped lang="less">
.table-row-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .table-row-action__badge {
        position: relative;
        left: -8px;
    }

    .table-row-action__badge + .favorite-buttons {
        margin-left: 6px;
    }

    .favorite-buttons {
        margin-left: 26px;
    }
    .badge-closed {
        background-color: @primary-color;
    }
    .badge-expire {
        background-color: rgba(0, 0, 0, 0.65);
    }
    .badge-cancel {
        font-size: 0.8em;
        background-color: rgba(0, 0, 0, 0.65);
    }
    .badge-no-access {
      background-color: @error-color;
    }
}

/* 荷物選択時のアウトラインが新着バッジの下に隠れてしまうことへの対応 */
::v-deep tr.app-table-row.app-table-row--selected {
    z-index: 1;
}

// 自動改行時一文字下げしない
.table-row-departure-location,
.table-row-arrival-location {
    text-indent: -1em;
    padding-left: 1em;
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

.label-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.company-name {
    font-weight: bold;
    color: @heading-color;
}

.freight-text {
    font-weight: bold;
    color: @heading-color;
}

.reference-freight-text {
    font-size: @font-size-sm;
}

.highway-fare-text {
    color: rgba(0, 0, 0, 0.65);
}

.description-container {
    padding-top: 6px;
    padding-bottom: 6px;

    .tag-multiple-baggage {
        background-color: transparent;
        margin-bottom: 4px;
    }
}

.app-result-list-table {
    // 荷物行のスタイル優先順
    // 1. 募集していない荷物
    // 2. 既読の荷物
    // 3. 未読の荷物

    // 既読の荷物
    ::v-deep .baggage-row--marked-as-read.baggage-row--open {
        background: #EDF1EF;
    }

    // 募集していない荷物
    // 保存した荷物一覧の場合、荷物保存後に非公開となった荷物も表示するため下記の設定が使用されます。
    ::v-deep tr:not(.baggage-row--open) {

        td, .day-text, .ui-date-time-label-text, .company-name, .freight-text, .highway-fare-text {
            color: @color-gray-3;
        }
    }

    // 既読の荷物 or 募集していない荷物
    ::v-deep .baggage-row--marked-as-read.baggage-row--open, ::v-deep tr:not(.baggage-row--open) {
        .company-name,
        .freight-text,
        .highway-fare-text b {
            font-weight: normal;
        }

        .ui-date-time-label-text {
            .date-text, .day-text {
                font-weight: normal;
            }
        }
    }
}

</style>
