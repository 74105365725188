<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import { DateUtil } from '@/util';
import { Const } from '@/const';

const props = defineProps<{
    baggage: Pick<Baggage, 'paymentDate'>;
}>();

const text = computed<string | undefined>(() => {
    if (props.baggage.paymentDate === undefined) {
        return undefined;
    }
    return DateUtil.parseDateText(props.baggage.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
});

</script>

<template>
    <span v-if="text">{{ text }}</span>
    <span v-else>登録した支払いサイトに準拠します。</span>
</template>

<style scoped lang="less">

</style>
