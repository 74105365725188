import { ref } from 'vue';
import { baggageApi } from '@/repository/api/internal/baggage';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 荷物お気に入り状態をロードする機能を提供します。
 */
export const useBaggageFavorite = () => {
    const marked = ref<boolean | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (baggageId: number) => {
        await withLoading(async () => {
            const data = await baggageApi.queryFavorite({ ids: [baggageId] });
            marked.value = data.ids.includes(baggageId);
        });
    };

    const changeMark = (value: boolean) => {
        if (marked.value) {
            marked.value = value;
        }
    };

    const clear = () => {
        marked.value = undefined;
    };

    return {
        state: {
            loading,
            marked,
        },
        load,
        clear,
        changeMark,
    };
};
