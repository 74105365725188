import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { RenderContext } from 'vue/types/options';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { TruckModelGroupEnum, TruckModelGroupEnumCode } from '@/enums/truck-model-group.enum';

type CheckedValue = TruckModelGroupEnumCode | TruckModelEnumCode;
interface TruckModelTreeItem {
    title: string;
    key: CheckedValue;
    children?: Array<{ title: string; key: string }>;
}

@Component({
    components: {
        VNodes: {
            functional: true,
            render: (_h: CreateElement, context: RenderContext) => context.props.vnodes,
        },
    },
})
export default class UiTruckModelTreeSelect extends Vue {
    @Prop()
    declare readonly value?: Array<TruckModelEnumCode>;
    @Prop()
    declare readonly size?: 'default' | 'large' | 'small'; // select メニューのサイズ
    @Prop({ default: true })
    declare readonly ellipsis: boolean; // タグが1行に収まらない場合は「・・・」の省略記号を表示するか否か（複数選択モードでのみ機能します）
    @Prop({ default: '車種' })
    declare readonly placeholder: string; // select メニューのプレースホルダ
    @Prop({ default: 'bottomLeft' })
    declare readonly placement: string; // ドロップダウン内のコンテンツを表示する位置

    isMenuOpen = false; // ドロップダウンメニューの開閉状態

    // トラック車種ツリー
    truckModelTreeData: { flatVan: TruckModelTreeItem[]; others: TruckModelTreeItem[] } = {
        flatVan: [TruckModelGroupEnum.Flat, TruckModelGroupEnum.Van].map((each) => this.mapToTreeItem(each)),
        others: [
            this.mapToTreeItem(TruckModelGroupEnum.Wing),
            {
                title: TruckModelEnum.Unic.label,
                key: TruckModelEnum.Unic.code,
            },
            {
                title: TruckModelEnum.Freezer.label,
                key: TruckModelEnum.Freezer.code,
            },
            {
                title: TruckModelEnum.Refrigerator.label,
                key: TruckModelEnum.Refrigerator.code,
            },
            {
                title: TruckModelEnum.Other.label,
                key: TruckModelEnum.Other.code,
            },
        ],
    };

    /**
     * 画面左側（平・箱）のツリーにおける、チェックボックスのチェック状態
     */
    get checkedKeysForFlatVanTree(): CheckedValue[] {
        if (!this.value) {
            return [];
        }
        const selectedTruckModelGroups = TruckModelGroupEnum.values
            .filter((each) => each.truckModels.every((tmCode) => this.value && this.value.includes(tmCode)))
            .map((each) => each.code);

        return _.concat(
            _.intersection(selectedTruckModelGroups, [TruckModelGroupEnum.Flat.code, TruckModelGroupEnum.Van.code]),
            _.intersection(this.value, [
                ...TruckModelGroupEnum.Flat.truckModels,
                ...TruckModelGroupEnum.Van.truckModels,
            ])
        );
    }

    set checkedKeysForFlatVanTree(values: CheckedValue[]) {
        // 2つのツリーの値を結合して、TruckModelGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, this.checkedKeysForOthersTree),
            ...TruckModelGroupEnum.values.map((each) => each.code)
        ) as TruckModelEnumCode[];
        this.emitValue(newValues);
    }

    /**
     * 画面右側（平・箱以外）のツリーにおける、チェックボックスのチェック状態
     */
    get checkedKeysForOthersTree(): CheckedValue[] {
        if (!this.value) {
            return [];
        }

        const isWingTruckAllSelected = TruckModelGroupEnum.Wing.truckModels.every(
            (each) => this.value && this.value.includes(each)
        );
        // exclude flat and van truck models
        const others = _.difference(this.value, [
            ...TruckModelGroupEnum.Flat.truckModels,
            ...TruckModelGroupEnum.Van.truckModels,
        ]);
        return _.concat(isWingTruckAllSelected ? [TruckModelGroupEnum.Wing.code] : [], others);
    }

    set checkedKeysForOthersTree(values: CheckedValue[]) {
        // 2つのツリーの値を結合して、TruckModelGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, this.checkedKeysForFlatVanTree),
            ...TruckModelGroupEnum.values.map((each) => each.code)
        ) as TruckModelEnumCode[];
        this.emitValue(newValues);
    }

    /**
     * プルダウンメニューの表示用ラベルの値
     */
    get labels(): Array<TruckModelEnum | TruckModelGroupEnum> {
        if (!this.value) {
            return [];
        }
        const selectedTruckModelGroups = TruckModelGroupEnum.values.filter((each) =>
            each.truckModels.every((tmCode) => this.value && this.value.includes(tmCode))
        );
        const selectedValue = _.difference(this.value, ...selectedTruckModelGroups.map((each) => each.truckModels));
        return [
            ...selectedTruckModelGroups,
            ...selectedValue.map((value) => {
                const truckModel = TruckModelEnum.valueOf(value);
                if (!truckModel) {
                    throw new Error('given value is not defined. [value] => ' + value);
                }
                return truckModel;
            }),
        ];
    }

    /**
     * プルダウンメニュー（ant-select）に付与するstyleClassを取得します。
     */
    get selectStyleClass(): { [key: string]: boolean } {
        return {
            'ant-select-lg': this.size === 'large',
            'ant-select-sm': this.size === 'small',
            'ant-select-default': this.size === 'default',
            'ant-select-open': this.isMenuOpen,
        };
    }

    created(): void {
        if (this.value && !_.isArray(this.value)) {
            throw new Error('v-model(value) must be array object.');
        }
    }

    /**
     * TruckModelGroupEnumをTreeDataItemにマップします。
     */
    mapToTreeItem(truckModel: TruckModelGroupEnum): TruckModelTreeItem {
        return {
            title: truckModel.label,
            key: truckModel.code,
            children: truckModel.truckModels.map((each) => {
                const truckModel = TruckModelEnum.valueOf(each);
                if (!truckModel) {
                    throw new Error('given value is not defined. [value] => ' + each);
                }
                return {
                    title: truckModel.label,
                    key: truckModel.code,
                };
            }),
        };
    }

    /**
     * 選択した項目（車種）を取り除きます。
     */
    removeItem(event: Event, value: TruckModelEnum | TruckModelGroupEnum): void {
        event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        const newValues = _.difference(
            this.value ?? [],
            value instanceof TruckModelGroupEnum ? value.truckModels : [value.code]
        );
        this.emitValue(newValues);
    }

    /**
     * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
     */
    getOverflowIndex(index: number): number {
        if (index < 0) {
            return -1;
        }
        const tagsElement = this.$refs.tags as Element | undefined;
        if (!tagsElement) {
            return -1;
        }
        const tagBaseWidth = this.size === 'large' ? 36 : 32;
        const tagSpacing = 4;
        const dropdownIconWidth = 16;
        const widthByLabels = this.labels.map((each) => {
            return tagSpacing + tagBaseWidth + each.label.length * 14;
        });
        return widthByLabels.findIndex(
            (_each, labelIndex, array) =>
                _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
        );
    }

    /**
     * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
     */
    isOverflowTag(index: number): boolean {
        if (!this.ellipsis) {
            return false;
        }
        const overflowIndex = this.getOverflowIndex(index);
        return overflowIndex > 0 && index >= overflowIndex;
    }

    /**
     * 確定ボタンを押下すると呼び出されます。
     */
    onClickOk(): void {
        this.isMenuOpen = false;
    }

    /**
     * 選択ラベルをクリックすると呼び出されます。
     */
    onClickChoiceContent(e: Event): void {
        // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        e.stopPropagation();
    }

    /**
     * 画面左側（平・箱）のツリーでチェック操作が行われた際に呼び出されます。
     */
    onCheckFlatVanTree(checkedKeys: Array<CheckedValue>): void {
        this.checkedKeysForFlatVanTree = checkedKeys;
    }

    /**
     * 画面右側（平・箱以外）のツリーでチェック操作が行われた際に呼び出されます。
     */
    onCheckOthersTree(checkedKeys: Array<CheckedValue>): void {
        this.checkedKeysForOthersTree = checkedKeys;
    }

    private emitValue(values: TruckModelEnumCode[]): void {
        this.$emit('input', values.sort());
    }
}
