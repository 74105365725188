<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import SpotSelectInput from '@/_components/ui/SpotSelectInput.vue';
import DateTimePicker from '@/_components/ui/DateTimePicker.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import TruckTypeSelect from '@/_components/ui/TruckTypeSelect.vue';
import FreightInput from '@/_components/ui/FreightInput.vue';
import StaffNameAutoComplete from '@/_components/ui/StaffNameAutoComplete.vue';
import { useTruckRegisterHelper } from '@/_pages/Truck/Register/truck-register-helper';
import { useFormModelItem } from '@/composables/form-helper';

const props = defineProps<{
    sourceTruckId?: string,
    companyTruckId?: string,
    sourceAgreementId?: string,
}>();

const {
    loading,
    formModel,
    form,
    formValidateRules,
    cols,
    suggestionList,
    circleOptions,
    onSubmit,
    onClickClear,
    onDeleteStaffNameSuggestion,
} = useTruckRegisterHelper(Number(props.sourceTruckId), Number(props.companyTruckId), Number(props.sourceAgreementId));
const { formModelItem: formItemDepartureDateTime, onChange: onChangeDepartureDateTime } = useFormModelItem();
const { formModelItem: formItemArrivalDateTime, onChange: onChangeArrivalDateTime } = useFormModelItem();
const onChangeDateTime = () => {
    onChangeDepartureDateTime();
    onChangeArrivalDateTime();
};
const { formModelItem: formItemStaffName, onChange: onChangeStaffName } = useFormModelItem();
</script>

<template>
    <page-layout :show-header="false" :back-icon="false">
        <div class="board-container">
            <a-spin :spinning="loading">
                <a-card>
                    <a-form-model ref="formModel"
                                  :model="form"
                                  :rules="formValidateRules"
                                  :colon="false"
                                  :label-col="{ sm: 6, lg: 4 }"
                                  :wrapper-col="{ sm: 18, lg: 20 }">

                        <!-- 発着日時、発着地 -->
                        <div>
                            <a-row :gutter="[16, 0]">
                                <a-col :sm="24" :lg="10">
                                    <a-form-model-item label="空車地"
                                                       :label-col="cols.first.labelCol"
                                                       :wrapper-col="cols.first.wrapperCol"
                                                       prop="departureDateTime"
                                                       ref="formItemDepartureDateTime">
                                        <date-time-picker style="width: 100%"
                                                          :valid-range="form.selectableDateRange"
                                                          v-model="form.departureDateTime"
                                                          @change="onChangeDateTime"/>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :sm="24" :lg="14">
                                    <spot-select-input label=""
                                                       prop-name="departureSpot"
                                                       :label-col="cols.second.labelCol"
                                                       :wrapper-col="cols.second.wrapperCol"
                                                       :show-address="false"
                                                       v-model="form.departureSpot">
                                        <template #prefTitle>
                                            <img src="/img/ic-departure.svg" width="24" height="24" alt="空車地"
                                                 style="margin-right: 8px"/>空車地
                                        </template>
                                    </spot-select-input>
                                </a-col>
                            </a-row>
                            <a-row :gutter="[16, 0]">
                                <a-col :sm="24" :lg="10">
                                    <a-form-model-item label="行先地"
                                                       :label-col="cols.first.labelCol"
                                                       :wrapper-col="cols.first.wrapperCol"
                                                       prop="arrivalDateTime"
                                                       ref="formItemArrivalDateTime">
                                        <date-time-picker style="width: 100%"
                                                          :valid-range="form.selectableDateRange"
                                                          v-model="form.arrivalDateTime"
                                                          @change="onChangeDateTime"/>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :sm="24" :lg="14">
                                    <spot-select-input label=""
                                                       prop-name="arrivalSpot"
                                                       :label-col="cols.second.labelCol"
                                                       :wrapper-col="cols.second.wrapperCol"
                                                       :show-address="false"
                                                       v-model="form.arrivalSpot">
                                        <template #prefTitle>
                                            <img src="/img/ic-arrival.svg" width="24" height="24" alt="行先地"
                                                 style="margin-right: 8px"/>行先地
                                        </template>
                                    </spot-select-input>
                                </a-col>
                            </a-row>
                        </div>
                        <div>
                            <a-form-model-item label="その他対応可能空車地"
                                               :wrapper-col="{ span: 16 }"
                                               prop="departure">
                                <a-input-group compact>
                                    <prefecture-select style="width: 50%"
                                                       :multiple="true"
                                                       placeholder="都道府県（複数選択可）"
                                                       title="その他対応可能空車地"
                                                       v-model="form.additionalDeparturePrefCode"/>
                                </a-input-group>
                            </a-form-model-item>
                            <a-form-model-item label="その他対応可能行先地"
                                               :wrapper-col="{ span: 16 }"
                                               prop="departure">
                                <a-input-group compact>
                                    <prefecture-select style="width: 50%"
                                                       :multiple="true"
                                                       placeholder="都道府県（複数選択可）"
                                                       title="その他対応可能行先地"
                                                       v-model="form.additionalArrivalPrefCode"/>
                                </a-input-group>
                            </a-form-model-item>
                        </div>
                        <truck-type-select v-model="form.truckType" prop-name="truckType" option-type="truck"/>
                        <freight-input v-model="form.minFreightValue" prop-name="minFreightValue" label="最低運賃"/>
                        <a-form-model-item label="担当者" :wrapper-col="{ span: 16 }" prop="staffName"
                                           ref="formItemStaffName">
                            <staff-name-auto-complete v-model="form.staffName"
                                                      placeholder="担当者名"
                                                      :suggestions="suggestionList"
                                                      @deleteStaffNameSuggestion="onDeleteStaffNameSuggestion"
                                                      @input="onChangeStaffName"/>
                        </a-form-model-item>
                        <!-- 備考 -->
                        <a-form-model-item label="備考"
                                           :wrapper-col="{ span: 16 }"
                                           prop="description">
                            <a-textarea placeholder="※注意 連絡先として「他営業所の電話番号」を入力することはご遠慮ください。"
                                        v-model="form.description"
                                        :auto-size="{ minRows: 2, maxRows: 5 }"/>
                        </a-form-model-item>

                      <!-- 部屋 -->
                      <a-form-model-item label="部屋"
                                         prop="circleId">
                        <a-select :options="circleOptions"
                                  v-model="form.circleId"
                                  placeholder="部屋を選択"
                                  style="width: 300px" />
                      </a-form-model-item>

                        <a-row :gutter="[32, 48]">
                            <a-col style="text-align: center;">
                                <a-button style="max-width: 200px; margin-right: 22px;"
                                          type="primary"
                                          size="large"
                                          block
                                          @click="onSubmit">登録
                                </a-button>
                                <a-button style="max-width: 80px"
                                          block
                                          size="large"
                                          @click="onClickClear">クリア
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-form-model>
                </a-card>
            </a-spin>
        </div>
    </page-layout>
</template>
<style scoped lang="less">
.board-container {
    padding: 24px;
}

.form-actions {
    margin-top: 48px;
    text-align: center;
}

</style>
