import _ from 'lodash';
import { Column } from 'ant-design-vue/types/table/column';
import { Vue } from 'vue-property-decorator';
import { BaggageRow } from '@/composables/provider/baggage-search-provider';
import { BaggageUtil } from '@/util';
import { CustomRow } from '@/types/ant-design';

type AntColumn = Omit<Column, keyof Vue>;
type CustomOption = { single: number; multiple: number; border?: boolean; title: string; };
type FlexibleColumn = AntColumn & CustomOption;

const COLUMN_DEFINITION: Array<FlexibleColumn> = [
    { single: 70, multiple: 70, key: 'action', title: '', border: false },
    { single: 150, multiple: 140, key: 'company', title: '企業名（荷主名）', ellipsis: true },
    { single: 160, multiple: 0, key: 'departureDate', title: '発日時', border: false },
    { single: 160, multiple: 0, key: 'arrivalDate', title: '着日時', align: 'left' },
    { single: 91, multiple: 0, key: 'departureLocation', title: '発地', align: 'left', border: false },
    { single: 18, multiple: 0, key: 'arrow', title: '', border: false },
    { single: 91, multiple: 0, key: 'arrivalLocation', title: '着地', align: 'left' },
    { single: 0, multiple: 240, key: 'departureArrival', title: '発日時・発地 / 着日時・着地', align: 'center' },
    { single: 120, multiple: 120, key: 'label', title: 'ラベル', align: 'center' },
    { single: 100, multiple: 100, key: 'freight', title: '運賃', align: 'center' },
    { single: 50, multiple: 30, key: 'share', title: '積合', align: 'center' },
    { single: 70, multiple: 70, key: 'truckWeight', title: '重量', align: 'center' },
    { single: 80, multiple: 80, key: 'truckModel', title: '車種' },
    { single: 80, multiple: 80, key: 'type', title: '荷種' },
    { single: 70, multiple: 70, key: 'handling', title: 'ドライバー作業', align: 'center' },
    { single: 130, multiple: 130, key: 'description', title: '備考' },
];

const RECOMMEND_COLUMN_DEFINITION: Array<FlexibleColumn> = [
    ...COLUMN_DEFINITION,
    { single: 70, multiple: 70, key: 'recommendations', title: 'おすすめ帰り便' },
];

export const baggageColumns = (showAsSingle: boolean, showRecommendations: boolean = false): FlexibleColumn[] => {

    /**
     * single/multipleに応じた列定義を取得します。
     */
    const columnDefinition = (single: boolean) => {
        const definition = showRecommendations ? RECOMMEND_COLUMN_DEFINITION : COLUMN_DEFINITION;
        return definition.filter(each => single ? each.single > 0 : each.multiple > 0);
    };

    /**
     * 列幅を比率で取得します。
     */
    const widthRatio = (definition: FlexibleColumn, single: boolean) => {
        if (single) {
            const sum = _.sumBy(columnDefinition(single), each => each.single);
            return definition.single / sum * 100;
        } else {
            const sum = _.sumBy(columnDefinition(single), each => each.multiple);
            return definition.multiple / sum * 100;
        }
    };

    return columnDefinition(showAsSingle).map(column => ({
        ...column,
        scopedSlots: { customRender: column.key },
        dataIndex: column.key,
        width: widthRatio(column, showAsSingle),
        className: column.border === false ? 'no-border-right' : '',
    }));
};

export const baggageCustomRow = (record: BaggageRow, callback: () => void): CustomRow => {
    return (record.noAccess) ? {} : {
        on: {
            click: (event: Event) => {
                // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
                event.stopPropagation();
                callback();
            },
        },
    };
};

export const baggageRawClassName = (record: BaggageRow, selectedBaggageId: number | undefined): string => {
    const styleClasses = ['app-table-row', 'app-table-row--has-action-column'];
    if (record.noAccess) {
        styleClasses.push('app-table-row--no-access');
    } else {
        styleClasses.push('app-table-row--clickable');
    }
    if (record.id === selectedBaggageId) {
        styleClasses.push('app-table-row--selected');
    }
    // 公開中荷物
    if (BaggageUtil.isOpen(record)) {
        styleClasses.push('baggage-row--open');
    }
    // 既読荷物
    if (record.readFlg) {
        styleClasses.push('baggage-row--marked-as-read');
    }
    return styleClasses.join(' ');
};
