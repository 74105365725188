export class WebStorageBase {
    private readonly storage?: Storage = undefined;

    constructor(storage: Storage) {
        // NOTE(asamac): システム準備 (sysprep) ツールを用いてセットアップされたWindows PCのIE11では、
        // LocalStorageを呼び出すとエラーがthrowされてしまうため、try~catchを用いている
        // 参考URL: https://blogs.technet.microsoft.com/askcorejp/2016/01/24/windows-8-windows-8-1-low-mandatory-level-1/
        // http://qiita.com/hida/items/5570e390781b6f118a4f
        try {
            this.storage = storage;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Current browser denied LocalStorage access privileges. Please fix mandatory level.`);
        }
    }

    /**
     * アイテムを取得します。
     */
    getItem(key: string): string | null {
        if (!this.storage) {
            return null;
        }
        return this.storage.getItem(key);
    }

    /**
     * アイテムを保存します。
     */
    setItem(key: string, value: string): void {
        if (this.storage) {
            this.storage.setItem(key, value);
        }
    }

    /**
     * アイテムを削除します。
     */
    removeItem(key: string): void {
        if (this.storage) {
            this.storage.removeItem(key);
        }
    }

    /**
     * ストレージ内のデータをすべて削除します。
     */
    clear(): void {
        if (this.storage) {
            this.storage.clear();
        }
    }
}
