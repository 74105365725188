import { baggageApi } from '@/repository/api/internal/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { Modal } from 'ant-design-vue';
import { ModalUtil } from '@/util';
import { Karte } from '@/karte';

/**
 * 荷物を削除する機能を提供します。
 */
export const useBaggageCancel = () => {
    const { state: { loading }, withLoading } = useLoading();

    const confirm = () => {
        // TODO: useModal()でokTypeやiconを受け入れるようにする
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: `選択した荷物を削除しますか？`,
            content: '削除すると、荷物の掲載も終了となります。また、この操作は元に戻すことができません。ご注意ください。',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '削除',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okType: 'danger',
            autoFocusButton: 'cancel',
        }));
    };

    /**
     * 荷物を削除する。
     */
    const cancel = async (id: number): Promise<boolean> => {
        if (!await confirm()) {
            return false;
        }

        await withLoading(async () => {
            await baggageApi.cancel(id);
            Karte.trackCancelBaggage(id);
        });

        return true;
    };

    return {
        state: {
            loading,
        },
        cancel,
    };
};
