import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageRegisterFormModel } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class PaletteCountRegister extends Vue {
    @Prop()
    declare readonly value?: BaggageRegisterFormModel;

    get paletteCount(): string {
        return this.value?.paletteCount ?? '';
    }

    set paletteCount(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (newValue.length > 0) {
            cloned.paletteCount = isNaN(Util.toNumber(newValue)) ? '' : Util.toDigits(newValue);
        } else {
            cloned.paletteCount = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            pattern: /^[0-9]+$/,
            message: 'パレット枚数（目安）は数字で入力してください。',
        },
        {
            max: Const.MAX_PALETTE_COUNT_DIGITS,
            message: `パレット枚数（目安）は最大${Const.MAX_PALETTE_COUNT_DIGITS}桁で入力してください。`,
        },
    ];
}
