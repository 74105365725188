<script setup lang="ts">
import { computed } from 'vue';
import { BaggageHighwayFarePaymentOption } from '@/models/baggage';

const props = defineProps<{
    value?: BaggageHighwayFarePaymentOption
}>();

const emit = defineEmits<{
    (e: 'input', value: BaggageHighwayFarePaymentOption | undefined): void;
    (e: 'change'): void;
}>();

const model = computed({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    },
});

// TODO モデルへうつした方が良さそう
const options: { value: BaggageHighwayFarePaymentOption, label: string }[] = [
    { value: 'available', label: '別途支払う' },
    { value: 'unavailable', label: '支払わない' },
];

</script>

<template>
    <a-radio-group name="category"
                   :options="options"
                   v-model="model"
                   @change="emit('change')"/>
</template>

<style scoped lang="less">

</style>
