import { Component, Prop, Vue } from 'vue-property-decorator';
import { AgreementUpdateForm } from '@/models/agreement';
import { BaggageTemperatureZoneEnum, BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';
import _ from 'lodash';

@Component
export default class AgreementBaggageTemperatureZonePicker extends Vue {
    @Prop()
    declare readonly value?: AgreementUpdateForm;

    get temperatureZone(): string {
        return this.value?.temperatureZone.code ?? '';
    }

    set temperatureZone(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        const temperatureZone = BaggageTemperatureZoneEnum.valueOf(newValue as BaggageTemperatureZoneEnumCode);
        if (!temperatureZone) return;
        cloned.temperatureZone = temperatureZone;
        this.$emit('input', cloned);

        this.$emit('changeTemperatureZone');

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    options = BaggageTemperatureZoneEnum.registrableValuesForBaggage.map((e) => ({
        label: e.label,
        value: e.code
    }));
}
