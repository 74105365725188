import _ from 'lodash';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
// @ts-ignore
import UiBaggageTypeInput from '@/components/UI/BaggageTypeInput';

@Component({
    components: {
        UiBaggageTypeInput,
    },
})
export default class AgreementTypeEdit extends Vue {
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '荷種を入力してください。',
        },
        {
            max: 250,
            message: '荷種は250文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementUpdateForm;

    /**
     * 荷種
     */
    get baggageType(): string {
        return this.value?.type ?? '';
    }

    set baggageType(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 荷種を書き換え
        cloned.type = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
