import { Component, Vue } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { Route } from 'ant-design-vue/types/breadcrumb/breadcrumb';
import { gtm } from '@/gtm';
import { PageUtil } from '@/util';

declare interface BreadcrumbRenderParam {
    route: Route;
    // eslint-disable-next-line
    params: any;
    routes: Route[];
    paths: [string];
    h: CreateElement;
}

@Component
export class PageVue extends Vue {
    mounted(): void {
        const { title } = this.$options;

        PageUtil.setTitle(title);

        // GTMへページビューイベントを送信
        gtm.setPage(this.$route.fullPath, title ?? '');
    }

    itemRender(param: BreadcrumbRenderParam): VNode {
        const { route, routes, paths, h } = param;

        const text = route.breadcrumbName as string;

        const isLast = routes.indexOf(route) === routes.length - 1;
        if (isLast) {
            return h('span', text);
        } else {
            return h('router-link', { attrs: { to: '/' + paths.join('/') } }, text);
        }
    }
}
