import { microCmsApi } from '@/repository/api/internal/microcms';
import { ref } from 'vue';
import { createGlobalState } from '@vueuse/core';
import { Dictionary } from 'vue-router/types/router';
import _ from 'lodash';

export const useMicroCMS = createGlobalState(() => {
    const data = ref<Map<string, Record<any, any>>>(new Map());

    const load = async (contentName: string, params: Dictionary<string | (string | null)[]> = {}): Promise<Record<any, any>> => {
        try {
            const draftKey = params['draftKey'] as string;
            const res = await microCmsApi.contents(contentName, draftKey);
            const resData = res ?? { };
            data.value.set(contentName, resData);
            return res ? res : { };
        } catch(e) {
            data.value.set(contentName, { });
            return { };
        }
    };

    const getData = (contentName: string): Record<any, any> => {
        return data.value.get(contentName) ?? {};
    };

    const hasData = (contentName: string): boolean => {
        return data.value.has(contentName) && !_.isEmpty(data.value.get(contentName));
    };

    return {
        load,
        getData,
        hasData,
    };
});
