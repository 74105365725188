<script setup lang="ts">
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import { CompanyPayment } from '@/models/company';
import { DateValue } from '@/models/vo/date';
import { Const } from '@/const';

const props = withDefaults(defineProps<{
    payment?: CompanyPayment,
    isPremium: boolean,
}>(), {
    isPremium: false,
});

const emits = defineEmits<{
    (e: 'input', value: boolean): void,
}>();

// いま申し込んだ場合、有料別途課金が適用されるか否か
const willApplyPremiumX = DateValue.today().date > Const.MONTHLY_TERM_END_DAY;

// 今月
const currentMonthText = DateValue.today().format('YYYY年MM月');
// 来月
const nextMonthText = DateValue.today().add(1, 'month').format('YYYY年MM月');
</script>

<template>
    <div class="modal-content">
        <p>運賃全額保証サービスをお申し込みされる前に、以下の内容をご確認いただき、ご了承の上、お申し込みください。</p>

        <template v-if="!props.isPremium">
            <div class="heading">プレミアムプランの同時申し込みについて</div>
            <p>
                <span>運賃全額保証サービスは「プレミアムプラン限定のサービス」です。そのため、</span>
                <span class="highlight-text">プレミアムプランへも同時に申し込み</span>
                <span>となります。</span>
            </p>
            <div class="heading">料金について</div>
            <p>
                プレミアムプラン 6,000円（税別）＋ 運賃全額保証サービス 5,000円〜（税別）<br>
                合計11,000円〜（税別） となります。
            </p>
            <div class="heading">契約（料金発生）のタイミング</div>
            <ul class="contracts" v-if="willApplyPremiumX">
                <li>プレミアムプラン・・・{{ nextMonthText }} 利用分から発生します。</li>
                <li>運賃保証サービス・・・{{ currentMonthText }} 利用分から発生します。</li>
            </ul>
            <p v-else>
                {{ currentMonthText }} 利用分から上記の費用が発生します。
            </p>
        </template>

        <template v-else>
            <div class="heading">料金について</div>
            <p>運賃全額保証サービス 5,000円〜（税別）</p>
            <div class="heading">契約（料金発生）のタイミング</div>
            <p>
                {{ currentMonthText }} 利用分から上記の費用が発生します。
            </p>
        </template>

        <p>
            お支払い方法は
            <span v-if="props.payment?.method.label">{{ props.payment?.method.label }}</span>
            <span v-else>銀行振込</span>
            となります。
        </p>
        <div class="heading">その他注意事項</div>
        <p>
            <span>運賃全額保証申し込み以前に成約した案件は保証対象になりません。</span>
        </p>
        <p>
        <span>
            日割りの計算は行っていないのでご注意ください。<br/>
            運賃全額保証サービスは月間の成約料金によって異なります。
        </span>
            <a :href="Const.externalPageUrl.guarantee" target="_blank">
                <tbx-link-text type="external">詳細はこちら</tbx-link-text>
            </a>
        </p>
        <div class="confirm">
            <a-checkbox @change="e => emits('input', e.target.checked)">上記の内容を確認しました。</a-checkbox>
        </div>
    </div>
</template>

<style scoped lang="less">
.modal-content {
    counter-reset: terms;

    .heading {
        &::before {
            display: inline-block;
            margin-right: 4px;
            counter-increment: terms;
            content: counter(terms) ". ";
        }
    }
}

.heading {
    margin: 16px 0 8px;
    font-weight: bold;
}

.highlight-text {
    display: inline-block;
    padding: 0 4px;
    background-color: @brand-color-trabox-yellow;
    font-weight: bold;
}

.contracts {
    padding-left: 20px;
    list-style-type: disc;
}

.confirm {
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
}
</style>
