<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { Const } from '@/const';
import { useReportLatePaymentHelper } from './report-late-payment-helper';

const {
    phase,
    loading,
    profile,
    form,
    formModel,
    formValidateRules,
    onSubmit
} = useReportLatePaymentHelper();
</script>

<template>
    <page-layout :back-icon="false">
        <a-spin :spinning="loading">
            <a-card class="board-container">
                <template v-if="phase === 'init'">
                    <a-row :gutter="[16, 32]">
                        <a-col :span="6" class="label">法人名・事業者名:</a-col>
                        <a-col :span="18">{{ profile.myCompanyName }}</a-col>
                    </a-row>

                    <a-row :gutter="[16, 32]">
                        <a-col :span="6" class="label">担当者名:</a-col>
                        <a-col :span="18">{{ profile.myStaffName }}</a-col>
                    </a-row>

                    <a-row :gutter="[16, 32]">
                        <a-col :span="6" class="label">メールアドレス:</a-col>
                        <a-col :span="18">{{ profile.myEmail }}</a-col>
                    </a-row>

                    <a-form-model ref="formModel"
                                  :model="form"
                                  :rules="formValidateRules"
                                  :colon="false"
                                  :label-col="{ span: 6 }"
                                  :wrapper-col="{ span: 12 }">
                        <a-form-model-item label="入金遅延先会社名" prop="targetCompanyName"
                                           :wrapper-col="{ span: 10 }">
                            <a-input placeholder="○○株式会社"
                                     v-model="form.targetCompanyName"
                                     @blur="form.normalize()"/>
                        </a-form-model-item>

                        <a-form-model-item label="運行日付" prop="deliveryDate">
                            <a-date-picker placeholder="運行日付を選択"
                                           :format="Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT"
                                           valueFormat="YYYY-MM-DD"
                                           v-model="form.deliveryDate"></a-date-picker>
                        </a-form-model-item>

                        <a-form-model-item label="運賃" prop="freight">
                            <a-input style="width: 170px;"
                                     suffix="円"
                                     placeholder="税別価格"
                                     v-model="form.freight">
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="支払期日" prop="paymentDate">
                            <a-date-picker placeholder="支払期日を選択"
                                           :format="Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT"
                                           valueFormat="YYYY-MM-DD"
                                           v-model="form.paymentDate"></a-date-picker>
                        </a-form-model-item>

                        <a-form-model-item label="その他状況など" prop="description">
                            <a-textarea placeholder="例：5月10日現在、入金の確認ができていません。先方へは何度か電話しています。"
                                        v-model="form.description"
                                        :auto-size="{ minRows: 5, maxRows: 20 }"
                                        @blur="form.normalize()"/>
                        </a-form-model-item>

                        <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                            <a-button style="max-width: 200px"
                                      type="primary"
                                      size="large"
                                      block
                                      @click="onSubmit">確認
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </template>

                <template v-else-if="phase === 'done'">
                    <a-result status="success">
                        <template v-slot:title>
                            ご連絡ありがとうございます。<br>
                            送信が完了しました。
                        </template>
                    </a-result>
                </template>
            </a-card>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
.board-container {
    margin: @padding-lg;
    padding: @padding-md @padding-lg;
}

.label {
    color: @ui-color-form-label-text;
    text-align: end;
}

::v-deep(.ant-form-item):first-child {
    margin-top: @padding-md;
}
</style>
