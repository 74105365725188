import _ from 'lodash';
import { computed, onBeforeMount, provide, ref } from 'vue';
import { Dictionary, Route } from 'vue-router/types/router';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Karte } from '@/karte';
import { Util } from '@/util';
import { Baggage } from '@/models/baggage';
import { CompanyProfileSearchFormModel } from '@/models/company';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { useCompanySearch } from '@/composables/company-search';
import { useCompanySearchFormSave } from '@/composables/company-search-form-save';
import { BAGGAGE_DRAWER_PROVIDER_KEY, useBaggageDrawerProvider } from '@/composables/provider/baggage-drawer-provider';
import { COMPANY_DRAWER_PROVIDER_KEY, useCompanyDrawerProvider } from '@/composables/provider/company-drawer-provider';

export const useCompanySearchHelper = () => {
    const { currentRoute } = useRoute();
    const { replaceQuery } = useRouting();
    const { save: saveForm, load: loadForm } = useCompanySearchFormSave();

    // 企業検索
    const { state: { list }, load: search } = useCompanySearch();

    // 企業ドロワー
    const companyDrawerProvider = useCompanyDrawerProvider();
    provide(COMPANY_DRAWER_PROVIDER_KEY, companyDrawerProvider);
    const { load: initializeCompanyDrawer } = companyDrawerProvider;

    // 荷物ドロワー
    const baggageDetailProvider = useBaggageDrawerProvider();
    provide(BAGGAGE_DRAWER_PROVIDER_KEY, baggageDetailProvider);
    const { load: initializeBaggageDrawer } = baggageDetailProvider;

    //
    // ページ固有の変数
    //
    // 連打防止
    const routeUpdating = ref<boolean>(false);
    // フォーム
    const form = ref<CompanyProfileSearchFormModel>(new CompanyProfileSearchFormModel({
        keyword: '',
        pref: undefined,
        city: '',
        pageNo: 1,
        pageSize: 50,
    }));

    //
    // computed values
    //
    /**
     * 現在選択中の企業ID
     */
    const currentCompanyId = computed<number | undefined>({
        get: () => extractCompanyId(currentRoute),
        set: async (value) => {
            if (routeUpdating.value || currentCompanyId.value === value) return;

            const param: Dictionary<string> = value ? { companyId: `${ value }` } : {};
            await replaceQuery(param);
        },
    });
    /**
     * 現在選択中の荷物ID
     */
    const currentBaggageId = computed<number | undefined>({
        get: () => extractBaggageId(currentRoute),
        set: async (value) => {
            if (routeUpdating.value || currentBaggageId.value === value || !currentCompanyId.value) return;

            const param: Dictionary<string> = value
                ? { companyId: `${ currentCompanyId.value }`, baggageId: `${ value }` }
                : { companyId: `${ currentCompanyId.value }` };
            await replaceQuery(param);
        },
    });
    /**
     * 企業ドロワー表示有無
     */
    const companyDrawerVisibility = computed<boolean>(() => !_.isNil(currentCompanyId.value));
    /**
     * 荷物ドロワー表示有無
     */
    const baggageDrawerVisibility = computed<boolean>(() =>
        companyDrawerVisibility.value && !_.isNil(currentBaggageId.value)
    );

    //
    // ツール
    //
    /**
     * {@link Route}から企業IDを抽出します。
     */
    const extractCompanyId = (route: Route): number | undefined => {
        const { companyId } = route.query;
        if (typeof companyId === 'string' && Util.isNumeric(companyId)) {
            return Number(companyId);
        }
    };
    /**
     * {@link Route}から荷物IDを抽出します。
     */
    const extractBaggageId = (route: Route): number | undefined => {
        const { baggageId } = route.query;
        if (typeof baggageId === 'string' && Util.isNumeric(baggageId)) {
            return Number(baggageId);
        }
    };

    //
    // UIのイベントに対応する関数
    //
    /**
     * 検索ボタンクリックをハンドリングします。
     */
    const onClickSearch = async () => await doSearch(1);
    /**
     * ページングをハンドリングします。
     */
    const onClickChangePage = async (pageNo: number) => await doSearch(pageNo);
    /**
     * 企業クリックをハンドリングします。
     */
    const onClickCompany = (companyId: number) => currentCompanyId.value = companyId;
    /**
     * 企業ドロワー閉じるをハンドリングします。
     */
    const onClickCloseCompanyDrawer = () => currentCompanyId.value = undefined;
    /**
     * 荷物クリックをハンドリングします。
     */
    const onClickBaggage = async (baggage: Baggage) => currentBaggageId.value = baggage.id;
    /**
     * 荷物ドロワー閉じるをハンドリングします。
     */
    const onClickCloseBaggageDrawer = () => currentBaggageId.value = undefined;
    /**
     * ドロワーマスクエリアクリックをハンドリングします。
     */
    const onClickGround = async () => {
        // 連打によるルーティングの多重実行を防止
        if (routeUpdating.value) return;
        onClickCloseCompanyDrawer();
    };

    //
    // データ操作
    //
    /**
     * 企業ドロワーを初期化します。
     */
    const initializeCompanyDrawerIfNeeded = async (nextCompanyId: number) => {
        if (nextCompanyId === currentCompanyId.value) return;

        await initializeCompanyDrawer(nextCompanyId);
    };

    /**
     * 荷物詳細ドロワーを初期化します。
     */
    const initializeBaggageDrawerIfNeeded = async (nextBaggageId: number) => {
        if (nextBaggageId === currentBaggageId.value) return;

        await initializeBaggageDrawer(nextBaggageId);
    };

    /**
     * 検索します。
     */
    const doSearch = async (pageNo: number) => {
        form.value.pageNo = pageNo;
        await search(form.value);

        saveForm(form.value);

        // KARTEイベント送信： 企業検索
        Karte.trackSearchCompany();
    };

    //
    // LifeCycle hooks
    //
    onBeforeMount(async () => {
        const restoredSearchForm = loadForm();
        if (restoredSearchForm) {
            form.value = restoredSearchForm;
        }

        const companyId = currentCompanyId.value;
        const baggageId = currentBaggageId.value;

        if (companyId) {
            await initializeCompanyDrawer(companyId).catch(onClickCloseCompanyDrawer);
            if (!baggageId) {
                return;
            }

            await initializeBaggageDrawer(baggageId).catch(onClickCloseBaggageDrawer);
        }
    });
    onBeforeRouteUpdate(async (to, from, next) => {
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const nextCompanyId = extractCompanyId(to);
        const nextBaggageId = extractBaggageId(to);

        if (!nextCompanyId) {
            next();
            return;
        }
        routeUpdating.value = true;
        try {
            await initializeCompanyDrawerIfNeeded(nextCompanyId).catch(onClickCloseCompanyDrawer);
            if (!nextBaggageId) {
                next();
                return;
            }

            await initializeBaggageDrawerIfNeeded(nextBaggageId).catch(onClickCloseBaggageDrawer);
            next();
        } finally {
            routeUpdating.value = false;
        }
    });

    return {
        form,
        companyDrawerVisibility,
        baggageDrawerVisibility,
        list,
        onClickSearch,
        onClickChangePage,
        onClickCompany,
        onClickCloseCompanyDrawer,
        onClickBaggage,
        onClickCloseBaggageDrawer,
        onClickGround,
    };
};
