import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import {
    DeliveryOrder,
    DeliveryOrderExist,
    DeliveryOrderListViewForm,
    DeliveryOrderRegisterForm,
    DeliveryOrderReplyForm,
    DeliveryOrderUpdateForm
} from '@/models/delivery-order';

export const deliveryOrderApi = {
    /**
     * 配送依頼書の一覧を取得します。
     */
    list(param: DeliveryOrderListViewForm): Promise<DeliveryOrder[]> {
        return appAxios.post('/api/delivery-order/list', param).then(isSuccess);
    },
    /**
     * 配送依頼書を取得します。
     */
    find(deliveryOrderId: number): Promise<DeliveryOrder> {
        return appAxios.get(`/api/delivery-order/${ deliveryOrderId }`).then(isSuccess);
    },
    /**
     * 成約に紐づく配送依頼書が存在するか確認します。
     */
    exist(agreementId: number): Promise<DeliveryOrderExist> {
        return appAxios.get(`/api/delivery-order/agreement/${ agreementId }/exist`).then(isSuccess);
    },
    /**
     * 配送依頼書を登録します。
     */
    register(param: DeliveryOrderRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/delivery-order/register', param).then(isSuccess);
    },
    /**
     * 配送依頼書を更新します。
     */
    update(deliveryOrderId: number, param: DeliveryOrderUpdateForm): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/update`, param).then(isSuccess);
    },
    /**
     * 配送依頼書を送信します。
     */
    send(deliveryOrderId: number): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/send`, {}).then(isSuccess);
    },
    /**
     * 配送依頼書を既読にします。
     */
    confirm(deliveryOrderId: number): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/confirm`, {}).then(isSuccess);
    },
    /**
     * 配送依頼書の更新を送信します。
     */
    revise(deliveryOrderId: number): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/revise`, {}).then(isSuccess);
    },
    /**
     * 車番を返信します。
     */
    reply(deliveryOrderId: number, param: DeliveryOrderReplyForm): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/reply`, param).then(isSuccess);
    },
    /**
     * 車番返信を既読にします。
     */
    confirmReply(deliveryOrderId: number): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/confirm-reply`, {}).then(isSuccess);
    },
    /**
     * 車番を変更します。
     */
    reviseReply(deliveryOrderId: number, param: DeliveryOrderReplyForm): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/delivery-order/${ deliveryOrderId }/revise-reply`, param).then(isSuccess);
    },
};
