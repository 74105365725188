<script setup lang="ts">
import _ from 'lodash';
import { computed, onMounted } from 'vue';
import { useGtm } from '@/composables/helper/gtm-helper';
import { useRoute } from '@/composables/helper/route';
import { useDocumentTitle } from '@/composables/helper/document-helper';
import { useMicroCMS } from '@/composables/helper/microcms-helper';
import { Const } from '@/const';

const { hasData } = useMicroCMS();
const { setTitle } = useDocumentTitle();
const { gtm } = useGtm();
const { currentRoute, currentRouteMeta } = useRoute();

const hasMicroCmsData = computed(() => hasData('login-side'));

const pageTitle = computed<string | undefined>(() => {
    if (currentRouteMeta.title && typeof currentRouteMeta.title === 'function') {
        const res = currentRouteMeta.title(currentRoute);
        return res.join(Const.PAGE_TITLE_SEPARATOR);
    } else if (!_.isNil(currentRouteMeta.title)) {
        return currentRouteMeta.title;
    }

    return undefined;
});

onMounted(() => {
    if (pageTitle.value) {
        setTitle(pageTitle.value);
        gtm.setPage(currentRoute.fullPath, pageTitle.value);
    }
});
</script>

<template>
    <a-layout class="mission-layout">
        <a-layout-sider v-if="hasMicroCmsData" class="side" breakpoint="lg" collapsed-width="0" width="378">
            <div class="side__notice">
                <div class="component-heading-h1" id="login-side-title" v-microcms-tag:login-side="'login-side-title'"></div>
                <div class="component-body-s" id="login-side-content" v-microcms-tag:login-side="'login-side-content'"></div>
                <a id="login-side-cv-button"
                   v-microcms-tag:login-side="'login-side-cv-button'"
                   v-microcms-tag:login-side.href="'login-side-cv-url'"
                   target="_blank"
                   class="component-button-text-m">
                </a>
            </div>
        </a-layout-sider>
        <a-layout-sider v-else class="side" breakpoint="lg" collapsed-width="0" width="378" :style="{ backgroundImage: `url('/img/bg-welcome.jpg')` }">
            <div class="corporate-mission">
                <div class="corporate-mission__message">
                    <img src="/img/corporate-mission.svg" alt="物流の仕組みを、未来へ加速させる" />
                </div>
                <div class="corporate-mission__symbol">
                    <img src="/img/logo-symbol.svg" alt="trabox symbol mark" />
                </div>
            </div>
        </a-layout-sider>
        <a-layout class="main">
            <section class="main__body">
                <h1 class="main__body__title"><img src="/img/logo-simple.svg" alt="トラボックス"></h1>
                <slot/>
            </section>
            <footer class="main__footer">
                <slot name="footer"/>
            </footer>
        </a-layout>
    </a-layout>

</template>

<style scoped lang="less">
// Base Layout Styles
.mission-layout {
    display: flex;
    height: 100vh;
    background-color: @body-background;

    > aside {
        width: 378px;
    }

    > main {
        flex: 1;
    }
}

.side {
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 116, 87, 0.9) 44.39%, rgba(0, 116, 87, 0.5) 87.37%) center;
    background-size: cover;
    background-image: url(/img/bg-welcome.jpg);

    &__notice {
        position: absolute;
        height: 220px;
        top: calc(50% - 220px / 2 + 0.5px);
        margin: 0 24px;

        #login-side-title {
            height: 50px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            white-space: pre-wrap;

            /* タイポグラフィ/Text-White */
            color: @token-main-color-white;

            /* オートレイアウト内部 */
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        #login-side-content {
            height: 84px;
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            white-space: pre-wrap;

            /* タイポグラフィ/Text-White */
            color: @token-main-color-white;

            /* オートレイアウト内部 */
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        #login-side-cv-button {
            /* Button */
            box-sizing: border-box;
            background-color: @token-main-color-white;
            border-radius: 4px;
            border: 1px solid transparent;

            /* オートレイアウト */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 12px 16px;
            gap: 8px;
            height: 40px;
            display: flex;
            align-items: center;
            text-align: center;

            /* タイポグラフィ/Text-Normal */
            color: @token-text-color-normal;

            /* オートレイアウト内部 */
            flex: none;
            order: 1;
            flex-grow: 0;

            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;
            }
        }
    }


}
.ant-layout-sider-zero-width .side__notice {
    display: none;
}
.main {
    display: flex;
    flex-direction: column;
    padding: 64px 32px 64px;
    background-color: @body-background;

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__title {
            text-align: center;

            > img {
                width: 275px;
                height: 33px;
            }
        }
    }
}

// Section Styles
.corporate-mission {
    width: 100%;
    height: 100%;
    padding: 48px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__message {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 48px;

        > img {
            width: 97px;
            height: 332px;
        }
    }

    &__symbol {
        margin-top: auto;
        height: 36px;
        text-align: center;

        > img {
            width: 36px;
            height: 36px;
        }
    }
}
</style>
