import axios from 'axios';
import _ from 'lodash';

export class FileUtil {
    /**
     * @param url  ダウンロードするファイルのURL
     * @param filename  保存するファイル名
     */
    static downloadFile(url: string, filename?: string): Promise<string> {
        return axios
            .get<Blob>(url, { responseType: 'blob' })
            .then((response) => {
                const name = filename ?? _.last(new URL(url).pathname.split('/')) ?? 'untitled';
                const blobUrl = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.download = name;
                a.href = blobUrl;
                a.click();
                a.remove();
                URL.revokeObjectURL(blobUrl);
                return name;
            });
    }
}
