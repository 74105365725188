import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { SettlementPartnerCompanyModel } from '@/models/settlement';
import { settlementApi } from '@/repository/api/internal/settlement';

/**
 * 決済取引追加パートナー企業検索
 */
export const useSettlementPartnerCompanySearch = () => {
    const list = ref<SettlementPartnerCompanyModel[]>([]);
    const { state: { loading }, withLoading } = useLoading();

    const search = (form: { keyword: string }) => withLoading(async () => {
        list.value = await settlementApi.searchPartnerCompany(form)
            .then(result => result.map(each => new SettlementPartnerCompanyModel(each)));
    });

    return {
        state: {
            loading,
            list,
        },
        search,
    };
};
