import { useAccountEmailVerify } from '@/composables/account-email-verify';
import { useRouting } from '@/composables/helper/routing';
import { onMounted, ref } from 'vue';
import { useTimer } from '@/composables/helper/time-helper';
import { useLoading } from '@/composables/helper/loading-helper';

export const useAccountEmailVerifyHelper = (verificationCode?: string) => {
    const { verify } = useAccountEmailVerify();
    const { state: { loading }, withLoading } = useLoading();
    const { delay } = useTimer();
    const { goToLogin } = useRouting();
    const succeed = ref(false);

    const initialize = async () => {
        if (!verificationCode?.trim()) return;
        await withLoading(async () => {
            try {
                await verify(verificationCode);
                succeed.value = true;
            } catch {
                succeed.value = false;
            }
        });
        if (succeed.value) {
            // NOTE: 文章が読めるように若干の遅延を入れる
            await delay(1000);
            await goToLogin();
        }
    };

    onMounted(() => initialize());

    return {
        loading,
        succeed,
    };
};
