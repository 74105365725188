import { computed, onMounted, ref, watch } from 'vue';
import { TraboxNoticeItem } from '@/models/help';
import * as types from '@/vuex/modules/help/types';
import { DateUtil } from '@/util';
import { useNotice } from '@/composables/global/notice';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';

export const useTraboxNoticeService = () => {
    const { state: { notice }, markRead } = useNotice();
    const { state: { isJudging } } = useCompanyMyStatus();

    const previousNoticeIds = ref<number[]>([]);
    const visibleValue = ref<boolean>(false);
    const visible = computed<boolean>({
        get: () => visibleValue.value,
        set: (value: boolean) => {
            visibleValue.value = value;
        }
    });

    const items = computed<TraboxNoticeItem[]>(() => notice.value?.items ?? []);

    /**
     * 新着であるか否かを取得します。
     */
    const isBrandNew = (record: types.TraboxNoticeItem): boolean => {
        return DateUtil.parseDatetimeText(record.brandNewEnd).isAfter(DateUtil.now());
    };

    /**
     * お知らせ項目をクリックすると呼び出されます。
     */
    const read = (record: types.TraboxNoticeItem): void => {
        if (visible.value) {
            visible.value = false;
        }
        markRead(record);
    };

    /**
     * お知らせを一時的にストアします。
     * @private
     */
    const storePreviousNotice = () => {
        previousNoticeIds.value = items.value.map((each) => each.id);
    };

    onMounted(() => {
        storePreviousNotice();
    });

    /**
     * トラボックスからのお知らせを表示するべきか否かを取得します。
     */
    const shouldShowTraboxNotice = computed<boolean>(() => {
        return !isJudging.value;
    });

    /**
     * お知らせを開くべきか否かを取得します。
     * @private
     */
    const shouldVisibleNotice = computed<boolean>(() => {
        const latestNoticeId = items.value.length > 0 ? items.value[0].id : undefined;
        return latestNoticeId !== undefined && !previousNoticeIds.value.includes(latestNoticeId);
    });

    watch(notice, () => {
        if (shouldVisibleNotice.value) {
            visible.value = true;
        }
        storePreviousNotice();
    }, { immediate: true } );

    return {
        state: {
            visible,
            notice,
            shouldVisibleNotice,
            shouldShowTraboxNotice,
        },
        read,
        isBrandNew,
    };
};
