import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { Const } from '@/const';
import { Karte } from '@/karte';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as companyTypes from '@/vuex/modules/company/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import _ from 'lodash';
// @ts-ignore
import BillingView from '@/components/Billing/View';
// @ts-ignore
import GuaranteedModal from '@/components/Billing/GuaranteedModal';
import { FileUtil } from '@/util/file';

const companyMod = namespace('company');
const agreementMod = namespace('agreement');
const MONTHLY_AGREEMENT_LIST_PAGE_SIZE = 5;

@Component({
    title: 'ご利用金額',
    components: {
        BillingView,
        GuaranteedModal,
    },
    beforeRouteEnter: SettingBillingPage.beforeRouteEnter,
})
export default class SettingBillingPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(companyTypes.GETTER.BILLING_TERM)
    BILLING_TERM!: companyTypes.CompanyBillingTerm;
    @companyMod.Getter(companyTypes.GETTER.BILLING_LIST)
    BILLING_LIST!: companyTypes.CompanyBilling[];
    @companyMod.Getter(companyTypes.GETTER.INVOICE_LIST)
    INVOICE_LIST?: companyTypes.CompanyInvoice[];
    @companyMod.Getter(companyTypes.GETTER.GUARANTEED_AMOUNT)
    GUARANTEED_AMOUNT?: companyTypes.CompanyGuaranteedAmount;
    @agreementMod.Getter(agreementTypes.GETTER.MONTHLY_AGREEMENT_LIST)
    MONTHLY_AGREEMENT_LIST?: agreementTypes.AgreementList;

    // ======================================================
    // Data
    // ======================================================
    selectedYear: '' | number = ''; // Computed Propertyの仕様として初期値が必要なためempty stringとしている
    isLoadingBilling = false;
    isModalVisible = false;
    isLoadingAcceptedAgreement = false;
    pricingPageUrl = Const.externalPageUrl.pricing;

    /**
     * 請求年の一覧を取得します。
     */
    get billingTermList(): number[] {
        if (!this.BILLING_TERM) {
            return [];
        }
        const years = [];
        for (let i = this.BILLING_TERM.min; i <= this.BILLING_TERM.max; i++) {
            years.push(i);
        }
        return years.reverse();
    }

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        // 初期読み込み時は最新の年を選択状態にします
        this.selectedYear = this.BILLING_TERM.max;
    }

    /**
     * 請求年の変更を行った際に呼び出されます。
     * @param year
     */
    async onChangeYearTerm(year: number): Promise<void> {
        if (year === this.selectedYear) {
            return;
        }
        this.selectedYear = year;
        this.isLoadingBilling = true;
        await SettingBillingPage.loadBillingHistory(year);
        this.isLoadingBilling = false;
    }

    /**
     * 運賃保証サービスの明細リンクを押下した際に呼び出されます。
     * @param record
     */
    async onClickGuaranteedModal(record: companyTypes.CompanyBilling): Promise<void> {
        this.isModalVisible = true;
        this.isLoadingAcceptedAgreement = true;
        await Promise.all([
            SettingBillingPage.loadGuaranteedAmount(record.year, record.month),
            SettingBillingPage.loadMonthlyAcceptedAgreement(
                record.year,
                record.month,
                1,
                MONTHLY_AGREEMENT_LIST_PAGE_SIZE
            ),
        ]);
        this.isLoadingAcceptedAgreement = false;
    }

    /**
     * 新しい請求年月が開かれると呼び出されます。
     * @param yearMonth
     */
    onOpenNewYearMonth(year: number, month: number): Promise<void> {
        return SettingBillingPage.loadInvoice(Number(year), Number(month));
    }

    async onClickInvoiceDownload(year: number, month: number): Promise<void> {
        SettingBillingPage.issueInvoice(year, month)
            .then(url => FileUtil.downloadFile(url, `trabox_${ year }-${ month }.pdf`))
            .then(() => this.$message.success({ content: '請求書のダウンロードを開始しました。' }))
            .then(() => Karte.trackDownloadInvoice(year, month))
            .catch(() => this.$message.error({ content: 'ダウンロードできませんでした。時間をおいて再度お試しください。' }));
    }

    /**
     * 月間成約一覧モーダルで、ページを変更した際に呼び出されます。
     */
    async onChangePage(param: { year: number; month: number; pageNo: number; pageSize: number }): Promise<void> {
        this.isLoadingAcceptedAgreement = true;

        await SettingBillingPage.loadMonthlyAcceptedAgreement(param.year, param.month, param.pageNo, param.pageSize);

        this.isLoadingAcceptedAgreement = false;
    }

    /**
     * 月間成約一覧モーダルを閉じた際に呼び出されます。
     */
    async onCloseModal(): Promise<void> {
        await SettingBillingPage.clearMonthlyAcceptedAgreement();
    }

    private static maxMonth(billingList: companyTypes.CompanyBilling[]): number | undefined {
        if (!_.isEmpty(billingList) && billingList.length > 0) {
            return Math.max(...billingList.map(b => b.month));
        } else {
            return undefined;
        }
    }

    /**
     * 請求年情報をロードします。
     * @private
     */
    private static loadBillingTerm(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_BILLING_TERM}`);
    }

    /**
     * 指定した年月の請求明細をロードします。
     * @private
     */
    private static loadBillingHistory(year: number, month?: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LIST_BILLING_HISTORY}`, { year, month });
    }

    /**
     * 指定した年月の請求書をロードします。
     */
    private static loadInvoice(year: number, month: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.ADD_INVOICE }`, { year, month });
    }

    private static clearInvoice(): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.CLEAR_INVOICE }`);
    }

    /**
     * 指定した年月の請求書のダウンロードURLを発行します。
     */
    private static issueInvoice(year: number, month: number): Promise<string> {
        return store.dispatch(`company/${ companyTypes.ACTION.ISSUE_INVOICE_URL }`, { year, month });
    }

    /**
     * 指定した年月の運賃保証サービスの月間成約金額をロードします。
     * @private
     */
    private static loadGuaranteedAmount(year: number, month: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_GUARANTEED_AMOUNT}`, { year, month });
    }
    /**
     * 指定した年月の運賃保証サービスの月間成約明細一覧をロードします。
     * @private
     */
    private static loadMonthlyAcceptedAgreement(
        year: number,
        month: number,
        pageNo = 1,
        pageSize?: number
    ): Promise<void> {
        return store.dispatch(`agreement/${agreementTypes.ACTION.LIST_MONTHLY_ACCEPTED_AGREEMENT}`, {
            year,
            month,
            param: { pageNo, pageSize },
        });
    }

    /**
     * 運賃保証サービスの月間成約明細一覧をクリアします。
     * @private
     */
    private static clearMonthlyAcceptedAgreement(): void {
        store.dispatch(`agreement/${agreementTypes.ACTION.CLEAR_MONTHLY_ACCEPTED_AGREEMENT}`);
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingBillingPage>
    ): Promise<void> {
        await SettingBillingPage.loadBillingTerm()
            .then(async () => {
                const billingTerm = store.getters[`company/${companyTypes.GETTER.BILLING_TERM}`];
                await SettingBillingPage.loadBillingHistory(billingTerm.max);
            })
            .catch(() => next({ name: 'NotFound' }));
        next();
    }

    async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingBillingPage>
    ): Promise<void> {
        SettingBillingPage.clearInvoice();
        next();
    }
}
