import { Component, Prop, Vue } from 'vue-property-decorator';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { concat, max, min } from 'lodash';

@Component({})
export default class BaggageMap extends Vue {
    @Prop()
    declare readonly id: string;
    @Prop()
    declare readonly initialRoute: number[][];
    @Prop()
    declare readonly toReturnRoute: number[][];
    @Prop()
    declare readonly returnRoute: number[][];
    @Prop()
    declare readonly fromReturnRoute: number[][];

    mounted(): void {
        mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
        const map = new mapboxgl.Map({
            container: this.id,
            style: import.meta.env.VITE_MAPBOX_STYLE,
            maxBounds: [105.0, 22.0, 160.0, 48.0],
            center: [127.5, 35.0],
            bounds: this.getRouteBounds(),

        });
        map.addControl(new mapboxgl.FullscreenControl());
        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();

        const INITIAL_ROUTE_COLOR = '#4021ff';
        const RETURN_ROUTE_COLOR = '#fd5223';
        const EMPTY_ROUTE_COLOR = '#222222';
        const LOADING_ROUTE_DASHARRAY: number[] = [];
        const LOADING_ROUTE_LINE_WIDTH = 4;
        const EMPTY_ROUTE_DASHARRAY = [2, 2];
        const EMPTY_ROUTE_LINE_WIDTH = 2;

        map.on('load', () => {
            this.addRoute(map, this.initialRoute, 'initialRoute', INITIAL_ROUTE_COLOR, LOADING_ROUTE_LINE_WIDTH, LOADING_ROUTE_DASHARRAY);
            this.addRoute(map, this.toReturnRoute, 'toReturnRoute', EMPTY_ROUTE_COLOR, EMPTY_ROUTE_LINE_WIDTH, EMPTY_ROUTE_DASHARRAY);
            this.addRoute(map, this.returnRoute, 'returnRoute', RETURN_ROUTE_COLOR, LOADING_ROUTE_LINE_WIDTH, LOADING_ROUTE_DASHARRAY);
            this.addRoute(map, this.fromReturnRoute, 'fromReturnRoute', EMPTY_ROUTE_COLOR, EMPTY_ROUTE_LINE_WIDTH, EMPTY_ROUTE_DASHARRAY);
            this.addMarkers(map, '/img/mapbox-marker-icon-blueA.png', 'initial-markers', this.initialDepartureLocation, this.initialArrivalLocation);
            this.addMarkers(map, '/img/mapbox-marker-icon-redB.png', 'return-markers', this.returnDepartureLocation, this.returnArrivalLocation);
        });
    }

    get initialDepartureLocation(): number[] {
        return this.initialRoute[0];
    }

    get initialArrivalLocation(): number[] {
        return this.initialRoute[this.initialRoute.length - 1];
    }

    get returnDepartureLocation(): number[] {
        return this.returnRoute[0];
    }

    get returnArrivalLocation(): number[] {
        return this.returnRoute[this.returnRoute.length - 1];
    }

    private addMarkers(map: mapboxgl.Map, imagePath: string, id: string, departureLocation: number[], arrivalLocation: number[]) {
        map.loadImage(
            imagePath,
            (error: any, image?: HTMLImageElement | ImageBitmap) => {
                if (error) throw error;
                if (!image) return;
                map.addImage(id, image);
                map.addSource(id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': departureLocation
                                },
                                'properties': {}
                            },
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': arrivalLocation
                                },
                                'properties': {}
                            }
                        ]
                    }
                });

                map.addLayer({
                    'id': id,
                    'type': 'symbol',
                    'source': id,
                    'layout': {
                        'icon-image': id,
                        'icon-size': 0.11,
                        'icon-allow-overlap': true,
                    }
                });
            }
        );

    }

    private addRoute(map: mapboxgl.Map, route: number[][], id: string, color: string, lineWidth: number, dasharray: number[]): void {
        map.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': route
                }
            }
        });
        map.addLayer({
            'id': id,
            'type': 'line',
            'source': id,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': color,
                'line-width': lineWidth,
                'line-dasharray': dasharray
            }
        });
    }

    private getRouteBounds(): any {
        const coordinates = concat(this.initialRoute, this.toReturnRoute, this.returnRoute, this.fromReturnRoute);
        const xs = [];
        const ys = [];
        for (const coordinate of coordinates) {
            const [x, y] = coordinate;
            xs.push(Number(x));
            ys.push(Number(y));
        }
        const minX = (min(xs) ?? 0);
        const minY = (min(ys) ?? 0);
        const maxX = (max(xs) ?? 300);
        const maxY = (max(ys) ?? 300);
        const offsetX = (maxX - minX) * 0.2;
        const offsetY = (maxY - minY) * 0.2;
        return [[minX - offsetX, minY - offsetY], [maxX + offsetX, maxY + offsetY]];
    }
}

