import { nextTick, ref } from 'vue';
import { useSettlementPartnerCompanyPicker } from '@/composables/settlement-partner-company-picker';
import { useRouting } from '@/composables/helper/routing';
import { useFormModel } from '@/composables/form-helper';
import { useSettlementIncomeRegister } from '@/composables/settlement-income-register';
import {
    SettlementIncomeRegisterViewModel,
    useSettlementIncomeRegisterViewModel
} from '@/composables/settlement-income-register-viewmodel';
import { useEntitlement } from '@/composables/entitlement';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useConfirmLeavingPage } from '@/composables/helper/modal-extension';
import { useNotification } from '@/composables/helper/page-helper';
import { useModal } from '@/composables/helper/modal-helper';
import Content from '@/_components/parts/SettlementRegistrationConfirmDialog.vue';

export const useSettlementIncomeRegisterHelper = () => {
    const { pick: pickCompany } = useSettlementPartnerCompanyPicker();
    const { openAgreementList, openAgreementAcceptedList, goToSettlementIncomeDetail } = useRouting();
    const { state: { entitlement } } = useEntitlement();
    const { formModel, submit } = useFormModel();
    const { confirm: confirmLeavingPage } = useConfirmLeavingPage();
    const notification = useNotification();
    const { confirm } = useModal();

    const validate = (props: (keyof SettlementIncomeRegisterViewModel)[]) => {
        nextTick(() => {
            formModel.value?.validateField(props, () => {
            });
        });
    };

    const {
        viewModel,
        isDirty,
        validationRules,
        buildApiForm,
    } = useSettlementIncomeRegisterViewModel(validate, entitlement);

    const {
        state: {
            loading: loadingRegister,
        },
        register,
    } = useSettlementIncomeRegister();

    const pickPartnerCompany = async () => {
        const selectedCompany = await pickCompany((value: { id: number, myBaggage: boolean }) => {
            if (value.myBaggage) {
                openAgreementList(value.id);
            } else {
                openAgreementAcceptedList(value.id);
            }
        });
        if (selectedCompany === undefined) {
            return;
        }
        viewModel.value.payerCompany = selectedCompany.profile;
    };

    // 追加処理が完了したか否か
    const done = ref(false);

    const onSubmit = () => submit(async () => {
        try {
            const confirmed = await confirm(
                '取引内容の確認',
                (h) => h(Content, {
                    props: {
                        model: viewModel.value,
                    }
                }),
                '取引追加を申請する',
                '戻る',
                700,
                undefined,
                (h) => h(),
            );
            if (!confirmed) return;

            const settlementId = await register(buildApiForm());
            done.value = true;

            notification.success({ message: '取引を登録しました。', description: '' });

            await goToSettlementIncomeDetail(settlementId);
        } catch {
            notification.error({ message: '登録できませんでした。', description: '' });
        }
    });

    onBeforeRouteLeave(async (_to, _from, next) => {
        // 追加処理が未完了で編集途中の場合はページを離脱するか確認する
        if (!done.value && isDirty.value) {
            if (!await confirmLeavingPage()) {
                next(false);
                return;
            }
        }
        next();
    });

    return {
        loadingRegister,
        viewModel,
        formModel,
        validationRules,
        pickPartnerCompany,
        onSubmit,
    };
};
