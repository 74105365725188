// @ts-ignore
import axios, { AxiosResponse } from 'axios';
// @ts-ignore
import jsonpAdapter from 'axios-jsonp';
import httpErrorResponseInterceptor from '@/repository/api/http-error-response-interceptor';
import { gtmErrorResponseInterceptor, gtmSuccessResponseInterceptor } from '@/repository/api/gtm-event-interceptor';

const axiosRequestConfig = {
    // ヘッダ
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
    // CSRFトークンクッキー名
    xsrfCookieName: 'WEB-CSRF-TOKEN',
    // CSRFトークンヘッダ名
    xsrfHeaderName: 'X-WEB-CSRF-TOKEN',
};
//@ts-ignore
export const appAxios = axios.create(axiosRequestConfig);
// エラーレスポンスが返却された場合の共通処理
appAxios.interceptors.response.use((response) => response, httpErrorResponseInterceptor);
// GTMへイベントを送出するための共通処理
appAxios.interceptors.response.use(gtmSuccessResponseInterceptor, gtmErrorResponseInterceptor);
// @ts-ignore
export const appAxiosWithoutErrorMessage = axios.create(axiosRequestConfig);
appAxiosWithoutErrorMessage.interceptors.response.use(gtmSuccessResponseInterceptor, gtmErrorResponseInterceptor);

export const jsonpAxios = axios.create({
    adapter: jsonpAdapter,
});

export const resultCode = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    // パスワード間違いによるログインロック
    LOCKED: 'LOCKED',
    // 企業単位でのログインロック
    COMPANY_LOCKED: 'COMPANY_LOCKED',
    IS_LOGIN_REQUIRED: 'LOGIN_REQUIRED',
    CONTRACT_EXPIRED: 'CONTRACT_EXPIRED',
    AUTH_REQUIRED: 'AUTH_REQUIRED',
};

// eslint-disable-next-line
export interface JsonResponseBean<T = any> {
    code: string;
    data?: T;
}

export const isSuccess = (response: AxiosResponse<JsonResponseBean>): any => {
    if (response.data.code !== resultCode.SUCCESS) throw response.data;
    return response.data.data;
};

// TODO isSuccessとマージ
export const wrapClass = <T>(generator: new(_: T) => T, response: T): T => new generator(response);

/**
 * APIのレスポンスを呼び出し元に返す際に遅延を加えます。積極的には利用しないこと。
 */
export const addDelay = (response: AxiosResponse<JsonResponseBean>): Promise<AxiosResponse<JsonResponseBean>> =>
    new Promise<AxiosResponse<JsonResponseBean>>((resolve) => setTimeout(() => resolve(response), 1000));
