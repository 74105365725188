<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import TbxButton from '@/_components/ui/TbxButton.vue';
import { useSettlementIncomePaper } from './settlement-income-paper-helper';

const {
    loading,
    selectedYear,
    term,
    list,
    onClickDownload,
} = useSettlementIncomePaper();
</script>

<template>
    <div class="container">
        <!-- left pane -->
        <a-timeline class="section-timeline">
            <a-timeline-item v-for="year in term.list" :key="year">
                    <span @click="selectedYear = year"
                          class="timeline-item-label"
                          :class="{ 'timeline-item-label--selected': selectedYear === year }">{{ year }}年</span>
            </a-timeline-item>
        </a-timeline>

        <!-- right pane -->
        <div class="section-list">
            <a-spin :spinning="loading">
                <div class="section-list-header">
                    <h2 class="section-list-header--title component-heading-h2">{{ selectedYear }}年 支払通知書</h2>
                    <div class="component-note-xs">
                        ※未承認の取引は支払通知書に含まれていません。ご理解の上ダウンロードをお願いします。
                    </div>
                </div>

                <!-- 請求明細 -->
                <a-config-provider>
                    <!--suppress TypeScriptValidateTypes -->
                    <a-list size="large" bordered :data-source="list">
                        <template #renderItem="item">
                            <a-list-item>
                                <a-row type="flex" align="middle" class="section-list--row">
                                    <a-col flex="150px">
                                        <h3 class="section-list--payee-date component-heading-h3">{{ item.payeeDate.format() }}</h3>
                                    </a-col>
                                    <a-col flex="auto" class="section-list--cell--action">
                                        <tbx-button type="secondary" size="large"
                                                    @click="onClickDownload(item.payeeDate)">
                                            <tbx-icon type="download"/>
                                            支払通知書ダウンロード
                                        </tbx-button>
                                    </a-col>
                                </a-row>
                            </a-list-item>
                        </template>
                    </a-list>
                    <template #renderEmpty>
                        <span>{{ selectedYear }}年のご利用はございません。</span>
                    </template>
                </a-config-provider>
            </a-spin>
        </div>
    </div>
</template>

<style scoped lang="less">
.container {
    display: flex;
    width: 100%;

    .section-timeline {
        margin-top: @padding-xs;
        width: 144px;

        .timeline-item-label {
            cursor: pointer;

            &--selected {
                color: @ui-color-selected;
                font-weight: @token-font-weight-bold;
            }
        }
    }

    .section-list {
        flex: 1;

        &-header {
            margin-bottom: @padding-md;

            &--title {
                margin-bottom: @padding-xs;
            }
        }

        &--row {
            width: 100%;
        }

        &--cell--action {
            text-align: end;
        }

        &--payee-date {
            margin-bottom: 0;
        }
    }
}
</style>
