import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';

@Component
export default class CompanyDescription extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get description(): string {
        return this.profile?.description ?? '';
    }
}
