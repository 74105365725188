import { Baggage } from '@/models/baggage';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';

// 荷物ヘルパー
// Note: 自社荷物前提
export const useMyBaggageExtension = () => {
    const isOpened = (baggage: Baggage) => {
        return BaggageStatusEnum.codeOf(baggage.status.code).isOpened();
    };

    /**
     * 商談中か否かを取得します。
     */
    const isUnderNegotiation = (baggage: Baggage) => {
        return isOpened(baggage) && baggage.underNegotiation;
    };

    /**
     * 商談中に設定できるか否かを取得します。
     */
    const canMarkUnderNegotiation = (baggage: Baggage) => {
        return isOpened(baggage) && !baggage.underNegotiation;
    };

    /**
     * 商談中を解除できるか否かを取得します。
     */
    const canUnmarkUnderNegotiation = (baggage: Baggage) => {
        return isOpened(baggage) && baggage.underNegotiation;
    };

    const negotiationType = (baggage: Baggage): NegotiationTypeEnum => {
        return NegotiationTypeEnum.codeOf(baggage.negotiationType.code);
    };

    /**
     * 「トラボックス掲載中」を表示するか否かを取得します。
     */
    const shouldShowTraboxTag = (baggage: Baggage): boolean => {
        return baggage.traboxBaggageId !== undefined;
    };

    const isOnlineNegotiation = (baggage: Baggage) => {
        return baggage.negotiationType.code === NegotiationTypeEnum.Online.code;
    };

    return {
        isOpened,
        isUnderNegotiation,
        canMarkUnderNegotiation,
        canUnmarkUnderNegotiation,
        negotiationType,
        shouldShowTraboxTag,
        isOnlineNegotiation,
    };
};
