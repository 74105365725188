import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { Util } from '@/util';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiTruckModelTreeSelect from '@/components/UI/TruckModelTreeSelect';
// @ts-ignore
import UiTruckWeightTreeSelect from '@/components/UI/TruckWeightTreeSelect';
// @ts-ignore
import TraCheckbox from '@/components/Baggage/Search/Condition/Checkbox';
// @ts-ignore
import BaggageSearchConditionAutoSearch from '@/components/Baggage/Search/Condition/AutoSearch';
// @ts-ignore
import TraToggleButton from '@/components/Baggage/Search/Condition/ToggleButton';
// @ts-ignore
import Share from '@/components/Baggage/Search/Condition/Share';
// @ts-ignore
import Category from '@/components/Baggage/Search/Condition/Category';
import { Validator } from '@/validator';

@Component({
    components: {
        UiPrefectureSelect,
        UiTruckModelTreeSelect,
        UiTruckWeightTreeSelect,
        TraCheckbox,
        BaggageSearchConditionAutoSearch,
        TraToggleButton,
        Share,
        Category,
    },
})
export default class BaggageSearchConditionEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.BaggageSearchForm;
    // よく使う検索条件リスト
    @Prop({ default: () => ([]) })
    declare readonly savedBaggageSearchConditions: types.BaggageSearchCondition[];
    @Prop({ default: () => false })
    declare readonly loading: boolean;
    @Prop()
    declare readonly baggageList?: types.Baggage[];
    /**
     * 新着荷物IDリスト
     */
    @Prop()
    declare readonly newBaggageIdList?: number[];

    visibleSearchCondition = false;
    visibleSearchDetailCondition = false;
    validationErrorMessage = '';

    /**
     * 荷物番号を取得します。
     */
    get id(): string {
        const digits = this.value?.id;
        return digits ? String(digits) : '';
    }

    /**
     * 荷物番号を設定します。
     */
    set id(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 荷物番号を書き換え
        // 0より大きい数値だったら入力を受け入れ、それ以外は拒否しundefinedとする。
        const id = Util.toNumber(newValue);
        cloned.id = id > 0 ? id : undefined;

        this.$emit('input', cloned);
    }

    /**
     * 発地を取得します。
     */
    get departurePref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.departurePref?.map((each) => each.code) || [];
    }

    /**
     * 発地を設定します。
     */
    set departurePref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.departurePref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 着地を取得します。
     */
    get arrivalPref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.arrivalPref?.map((each) => each.code) || [];
    }

    /**
     * 着地を設定します。
     */
    set arrivalPref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.arrivalPref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 発日（開始）を取得します。
     */
    get departureFrom(): string {
        return this.value?.departureFrom ?? '';
    }

    /**
     * 発日（開始）を設定します。
     */
    set departureFrom(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureFrom = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 発日（終了）を取得します。
     */
    get departureTo(): string {
        return this.value?.departureTo ?? '';
    }

    /**
     * 発日（終了）を設定します。
     */
    set departureTo(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureTo = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 運送区分を取得します。
     */
    get category(): string {
        return this.value?.category?.code ?? '';
    }

    /**
     * 運送区分を設定します。
     */
    set category(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.category = { code: newValue };

        this.$emit('input', cloned);
    }

    /**
     * 着日（開始）を取得します。
     */
    get arrivalFrom(): string {
        return this.value?.arrivalFrom ?? '';
    }

    /**
     * 着日（開始）を設定します。
     */
    set arrivalFrom(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.arrivalFrom = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 着日（終了）を取得します。
     */
    get arrivalTo(): string {
        return this.value?.arrivalTo ?? '';
    }

    /**
     * 着日（終了）を設定します。
     */
    set arrivalTo(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.arrivalTo = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 積合を取得します。
     */
    get share(): boolean | undefined {
        return this.value?.share;
    }

    /**
     * 積合を設定します。
     */
    set share(newValue: boolean | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.share = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 運賃を取得します。
     */
    get freight(): string {
        return this.value?.freight ?? '';
    }

    /**
     * 運賃を設定します。
     */
    set freight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.freight = Util.parseFreightString(newValue);

        this.$emit('input', cloned);
    }

    /**
     * 「要相談を除く」を取得します。
     */
    get excludeUndecidedFreight(): boolean {
        return this.value?.excludeUndecidedFreight ?? false;
    }

    /**
     * 「要相談を除く」を設定します。
     */
    set excludeUndecidedFreight(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.excludeUndecidedFreight = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 「重量、問わず除く」を取得します。
     */
    get excludeUndecidedTruckWeight(): boolean {
        return this.value?.excludeUndecidedTruckWeight ?? false;
    }

    /**
     * 「重量、問わず除く」を設定します。
     */
    set excludeUndecidedTruckWeight(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.excludeUndecidedTruckWeight = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 「車種、問わず除く」を取得します。
     */
    get excludeUndecidedTruckModel(): boolean {
        return this.value?.excludeUndecidedTruckModel ?? false;
    }

    /**
     * 「車種、問わず除く」を設定します。
     */
    set excludeUndecidedTruckModel(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.excludeUndecidedTruckModel = newValue;

        this.$emit('input', cloned);
    }

    // ======================================================
    // Data
    // ======================================================
    /**
     * 重量を取得します。
     */
    get truckWeight(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.truckWeight?.map((each) => each.code) || [];
    }

    /**
     * 重量を設定します。
     */
    set truckWeight(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.truckWeight = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 車種を取得します。
     */
    get truckModel(): string[] {
        return this.value?.truckModel?.map((each) => each.code) || [];
    }

    /**
     * 車種を設定します。
     */
    set truckModel(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.truckModel = newValue.map((each) => ({ code: each }));

        this.$emit('input', cloned);
    }

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        this.showSearchDetailConditionIfNeeded();
    }

    /**
     * 都道府県の入れ替えボタンが押下された際に呼び出されます。
     */
    onClickInvertPref(): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departurePref = this.arrivalPref.map((code) => ({ code }));
        cloned.arrivalPref = this.departurePref.map((code) => ({ code }));
        this.$emit('input', cloned);
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        this.$emit('resetSearchForm');
    }

    /**
     * 検索が押下された際に呼び出されます。
     */
    onClickSubmit(): void {
        if (this.validate()) {
            this.$emit('search');
        }
    }

    /**
     * 自動検索をonにした時に呼び出されます。
     */
    startAutoSearch(): void {
        if (this.validate()) {
            this.$emit('startAutoSearch');
        }
    }

    /**
     * 自動検索をoffにした時に呼び出されます。
     */
    stopAutoSearch(): void {
        this.$emit('stopAutoSearch');
    }

    /**
     * よく使う検索条件に保存を押下した際に呼び出されます。
     */
    onClickSaveSearchCondition(): void {
        this.$emit('saveSearchCondition');
    }

    /**
     * よく使う検索条件の管理ボタンを押下した際に呼び出されます。
     */
    async onClickSearchConditionSetting(): Promise<void> {
        await this.$router.push({ name: 'SettingEmailBaggageAndTruck' });
    }

    /**
     * よく使う検索条件のドロップダウンメニューから、検索条件を選択した際に呼び出されます。
     */
    onClickLoadSearchCondition(event: { key: number }): void {
        this.visibleSearchCondition = false;
        this.$emit('selectSavedSearchCondition', event.key);
        this.$nextTick(() => this.showSearchDetailConditionIfNeeded());
    }

    /**
     * 検索条件のラベルテキスト
     */
    searchConditionLabelText(record: types.BaggageSearchCondition): string {
        // NOTE: このコンポーネントはもう使われていないので無効にしました。
        return '';
        //return BaggageUtil.formatBaggageSearchConditionLabel(record);
    }

    /**
     * バリデーションを行います。
     */
    validate(): boolean {
        const validated = Validator.validateDateRangeForSearch(
            this.departureFrom,
            this.departureTo,
            this.arrivalFrom,
            this.arrivalTo
        );
        this.validationErrorMessage = validated.result ? '' : validated.message ?? '';
        return validated.result;
    }

    /**
     * 詳細検索条件を表示します。
     */
    private showSearchDetailConditionIfNeeded(): void {
        this.visibleSearchDetailCondition = !_.isNil(this.value?.share) || !_.isEmpty(this.value?.category?.code);
    }
}
