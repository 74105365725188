import { computed, ref } from 'vue';
import { BaggageListFormModel, BaggageListModel, BaggageListType } from '@/models/baggage';
import { baggageApi } from '@/repository/api/internal/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { BaggageSortKeyCode, Const } from '@/const';
import { SortInfo } from '@/_components/ui/types/sort-info';
import { PageInfo } from '@/_components/ui/types/page-info';
import _ from 'lodash';
import { usePagination } from '@/composables/global/pagination';
import { SortOrder } from '@/models/vo/pagination';

/**
 * マイ荷物リストをロードする機能を提供します。
 */
export const useBaggageList = (listType: BaggageListType) => {
    const { state: { loading }, withMuteableLoading } = useLoading();
    const { state: { pageSize: pageSizeStorage } } = usePagination();

    /**
     * 初期検索フォームを生成する
     */
    const initialForm = (): BaggageListFormModel => {
        return {
            id: undefined,
            status: { code: listType },
            departurePref: [],
            arrivalPref: [],
            departureFrom: undefined,
            departureTo: undefined,
            truckWeight: [],
            truckModel: [],
            staffName: '',
            pageNo: 1,
            pageSize: pageSizeStorage.value ?? Const.DEFAULT_PAGE_SIZE,
            sortKey: 'ID',
            sortOrder: 'DESC',
        };
    };

    // 検索フォーム
    const form = ref(initialForm());
    // 最後の検索フォーム
    const lastForm = ref<BaggageListFormModel | undefined>();
    // 検索結果
    const list = ref<BaggageListModel | undefined>();

    // バリデーションメッセージ
    const validationMessage = ref<string>();

    // ソート情報
    const sortInfo = computed<SortInfo<BaggageSortKeyCode>>(() =>
        ({
            sortOptions: Const.baggageSortKey,
            sortKey: form.value.sortKey,
            sortOrder: form.value.sortOrder,
        })
    );

    // ページング情報
    const pageInfo = computed<PageInfo>(() => ({
        totalPageCount: list.value?.totalPageCount ?? 0,
        totalRecordCount: list.value?.totalRecordCount ?? 0,
        currentPage: list.value?.currentPageNumber ?? 1,
        currentPageSize: list.value?.pageSize ?? Const.DEFAULT_PAGE_SIZE,
        pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
    }));

    /**
     * 検索条件で絞り込み中か否かを取得する。
     */
    const isFiltered = computed(() => {
        if (lastForm.value === undefined) {
            return false;
        }
        return !_.isEqual(valueToCompare(lastForm.value), valueToCompare(initialForm()));
    });

    const valueToCompare = (value: BaggageListFormModel) => _.omit(value, ['pageNo', 'pageSize', 'sortKey', 'sortOrder']);

    /**
     * 検索する。
     */
    const search = async (mute: boolean = false): Promise<void> => {
        // バリデーション
        const result = validate();
        if (result.tag === 'failure') {
            validationMessage.value = result.message;
            return;
        }
        validationMessage.value = undefined;

        // 検索
        await withMuteableLoading(mute, async () => {
            // 荷物一覧を取得
            const baggageList = await baggageApi.list(form.value);
            const ids = baggageList.data.map((item) => item.id);

            // 閲覧数を取得
            const viewCounts = (ids.length > 0) ? await baggageApi.countUnique(ids) : [];

            list.value = new BaggageListModel(baggageList, viewCounts);

            lastForm.value = _.cloneDeep(form.value);
        });
    };

    /**
     * 再検索
     */
    const reload = async (mute: boolean = false): Promise<void> => {
        if (lastForm.value === undefined) {
            return;
        }
        form.value = _.cloneDeep(lastForm.value);
        await search(mute);
    };

    /**
     * ページング、ソート切り替えする。
     */
    const changePage = async (value: {
        pageNo: number,
        pageSize: number,
        sortKey?: BaggageSortKeyCode,
        sortOrder?: SortOrder
    }): Promise<void> => {
        if (lastForm.value === undefined) {
            return;
        }
        // 最後の検索フォームにページング情報をマージして新しいフォームを生成する
        pageSizeStorage.value = value.pageSize;
        form.value = {
            ..._.cloneDeep(lastForm.value),
            pageNo: value.pageNo,
            pageSize: value.pageSize,
            sortKey: value.sortKey ?? lastForm.value.sortKey,
            sortOrder: value.sortOrder ?? lastForm.value.sortOrder
        };
        await search();
    };

    /**
     * フォームをクリアする。
     */
    const clearForm = () => {
        form.value = initialForm();
    };

    /**
     * 検索フォームをバリデートする。
     */
    const validate = (): { tag: 'success' } | { tag: 'failure', message: string } => {
        if (form.value.departureFrom && form.value.departureTo) {
            if (form.value.departureTo.isBefore(form.value.departureFrom)) {
                return { tag: 'failure', message: '発日（終了）を正しく指定してください。' };
            }
        }

        if (form.value.staffName && form.value.staffName.length > 250) {
            return { tag: 'failure', message: '担当者名は250文字以内で入力してください。' };
        }

        return { tag: 'success' };
    };

    return {
        state: {
            loading,
            form,
            isFiltered,
            validationMessage,
            list,
            sortInfo,
            pageInfo,
        },
        search,
        reload,
        changePage,
        clearForm,
    };
};
