import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import {
    CompanyTruckEvent,
    CompanyTruckEventMemoRegisterForm,
    CompanyTruckEventSearchFormModel,
    CompanyTruckEventMemoUpdateForm,
    CompanyTruckEventTruckRegisterForm,
    CompanyTruckEventTruckUpdateForm
} from '@/models/company-truck-event';
import { companyTruckEventApi } from '@/repository/api/internal/company-truck-event';
import { DateValue } from '@/models/vo/date';
import { FormValidateUtil } from '@/models/validate-helper';

export const useCompanyTruckEventSearch = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<CompanyTruckEvent[]>([]);

    const search = (startDate: DateValue, endDate: DateValue) => withLoading(async () => {
        const form = new CompanyTruckEventSearchFormModel();
        form.startTmFromValue = startDate;
        form.endTmFromValue = endDate;
        list.value = await companyTruckEventApi.search(form);
    });

    return {
        state: {
            loading,
            list,
        },
        search,
    };
};

export const useCompanyTruckEvent = () => {
    const { state: { loading }, withLoading } = useLoading();
    const companyTruckEvent = ref<CompanyTruckEvent | undefined>();
    const load = (companyTruckEventId: number) => withLoading(async () => {
        companyTruckEvent.value = await companyTruckEventApi.find(companyTruckEventId);
    });

    return {
        state: {
            loading,
            companyTruckEvent,
        },
        load,
    };
};

export const useCompanyTruckEventDelete = () => {
    const { state: { loading }, withLoading } = useLoading();
    const deleteEvent = (id: number) => withLoading(async () => {
        await companyTruckEventApi.delete(id);
    });

    return {
        state: {
            loading,
        },
        deleteEvent,
    };
};

export const useCompanyTruckEventMemoRegister = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<CompanyTruckEventMemoRegisterForm>(new CompanyTruckEventMemoRegisterForm());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const register = (form: CompanyTruckEventMemoRegisterForm) => withLoading(async () => {
        await companyTruckEventApi.registerMemo(form);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        register,
    };
};

export const useCompanyTruckEventMemoUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<CompanyTruckEventMemoUpdateForm>(new CompanyTruckEventMemoUpdateForm());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const update = (id: number, form: CompanyTruckEventMemoUpdateForm) => withLoading(async () => {
        await companyTruckEventApi.updateMemo(id, form);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        update,
    };
};

export const useCompanyTruckEventTruckRegister = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<CompanyTruckEventTruckRegisterForm>(new CompanyTruckEventTruckRegisterForm());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const register = (form: CompanyTruckEventTruckRegisterForm) => withLoading(async () => {
        await companyTruckEventApi.registerTruck(form);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        register,
    };
};

export const useCompanyTruckEventTruckUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<CompanyTruckEventTruckUpdateForm>(new CompanyTruckEventTruckUpdateForm());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const update = (id: number, form: CompanyTruckEventTruckUpdateForm) => withLoading(async () => {
        await companyTruckEventApi.updateTruck(id, form);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        update,
    };
};
