import { computed, ref } from 'vue';
import { Const } from '@/const';
import { useRouting } from '@/composables/helper/routing';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyContracts } from '@/composables/global/company-contracts';

export const UnSubscribeIndexHelper = () => {
    const appServiceName = Const.APP_SERVICE_NAME;
    const { state: { profile } } = useAccountMyProfile();
    const { state: { contracts } } = useCompanyContracts();
    const { goBack, goToByName } = useRouting();

    const agreed = ref(false);

    /**
     * プレミアムプランを契約中かどうかを取得します。
     */
    const hasActivePremiumPlan = computed<boolean>(() => {
        return contracts.value?.activePlanType === 'PREMIUM' ?? false;
    });

    /**
     * 「戻る」ボタンをクリックした際に呼び出されます。
     */
    const onClickBack = (): void => {
        goBack();
    };

    /**
     * 有料サービス停止ボタンが押下された際に呼び出される。
     */
    const onClickToRevokePremium = async (): Promise<void> => {
        await goToByName('RevokePremiumPlan');
    };

    /**
     * 退会ボタンが押下された際に呼び出される。
     */
    const onClickStartUnsubscribe = async (): Promise<void> => {
        await goToByName('UnsubscribeEdit');
    };

    return {
        state: {
            profile,
            contracts,
        },
        appServiceName,
        agreed,
        hasActivePremiumPlan,
        onClickBack,
        onClickToRevokePremium,
        onClickStartUnsubscribe,
    };
};
