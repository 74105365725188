import { ref } from 'vue';
import {
    CompanyDispatchedDriverList,
    CompanyDispatchedTruckList,
    CompanyDispatchHistoryRegisterForm
} from '@/models/company';
import { useLoading } from '@/composables/helper/loading-helper';
import { companyApi } from '@/repository/api/internal/company';
import _ from 'lodash';

export const useCompanyDispatchedTruckList = () => {
    const list = ref<CompanyDispatchedTruckList>();
    const { state: { loading }, withLoading } = useLoading();

    const load = () => withLoading(async () => {
        list.value = await companyApi.loadDispatchedTruckHistories();
    });

    return {
        state: {
            list,
            loading,
        },
        load,
    };
};

export const useCompanyDispatchedDriverList = () => {
    const list = ref<CompanyDispatchedDriverList>();
    const { state: { loading }, withLoading } = useLoading();

    const load = () => withLoading(async () => {
        list.value = await companyApi.loadDispatchedDriverHistories();
    });

    return {
        state: {
            list,
            loading,
        },
        load,
    };
};

export const useCompanyDispatchHistoryRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref<CompanyDispatchHistoryRegisterForm>({});

    const register = () => withLoading(async () => {
        // 未入力なら登録処理をスキップ
        if (_.trim(form.value.carNumber).length == 0 && _.trim(form.value.driverName).length == 0) return;

        await companyApi.registerDispatchHistory(form.value);
    });

    return {
        state: {
            form,
            loading,
        },
        register,
    };
};

export const useCompanyDispatchHistoryRemove = () => {
    const { state: { loading }, withLoading } = useLoading();
    const remove = (form: CompanyDispatchHistoryRegisterForm) => withLoading(async () => {
        await companyApi.deleteDispatchHistory(form);
    });

    return {
        state: {
            loading,
        },
        remove,
    };
};
