import { readonly, ref } from 'vue';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/environment/types';
import { Environment } from '@/models/environment';
import { environmentApi } from '@/repository/api/api';
import { createGlobalState } from '@vueuse/core';
import { useVuexSync } from '@/composables/helper/vuex';

/**
 * 環境情報を取得する機能を提供します。
 */
export const useEnvironment = createGlobalState(() => {
    const loadingEnvironmentState = ref<'init' | 'loading' | 'loaded' | 'failed'>('init');
    const environment = ref<Environment | undefined>(undefined);

    useVuexSync(environment, { module: 'environment', mutation: m.SET_ENVIRONMENT, getter: g.ENVIRONMENT });

    /**
     * 環境情報のロード
     */
    const loadEnvironment = async (): Promise<void> => {
        loadingEnvironmentState.value = 'loading';
        try {
            environment.value = await environmentApi.load();
            // Vuex storeを利用している既存機能の互換性を保つ為
            loadingEnvironmentState.value = 'loaded';
        } catch {
            loadingEnvironmentState.value = 'failed';
        }
    };

    return {
        state: {
            loadingEnvironmentState: readonly(loadingEnvironmentState),
            environment: readonly(environment),
        },
        loadEnvironment,
    };
});
