<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useDocumentTitle } from '@/composables/helper/document-helper';
import { useGtm } from '@/composables/helper/gtm-helper';
import { useRoute } from '@/composables/helper/route';
import { Const } from '@/const';
import _ from 'lodash';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

const props = withDefaults(defineProps<{
    title?: string,
    showHeader?: boolean,
    backIcon?: boolean,
}>(), {
    showHeader: true,
    backIcon: true,
});
const emits = defineEmits<{
    (e: 'back'): void,
    (e: 'click'): void,
}>();

const { setTitle } = useDocumentTitle();
const { gtm } = useGtm();
const { currentRoute, currentRouteMeta } = useRoute();

const pageTitle = computed<string | undefined>(() => {
    if (currentRouteMeta.title && typeof currentRouteMeta.title === 'function') {
        const res = currentRouteMeta.title(currentRoute);
        return res.join(Const.PAGE_TITLE_SEPARATOR);
    } else if (!_.isNil(currentRouteMeta.title)) {
        return currentRouteMeta.title;
    } else {
        return props.title;
    }
});

const onBack = computed(() => {
    if (props.backIcon) {
        return (e: Event) => {
            e.stopPropagation();
            emits('back');
        };
    } else {
        return () => undefined;
    }
});
const onClick = computed(() => {
    return () => emits('click');
});

onMounted(() => {
    if (pageTitle.value) {
        setTitle(pageTitle.value);
        gtm.setPage(currentRoute.fullPath, pageTitle.value);
    }
});
</script>

<template>
    <a-layout-content @click="onClick" class="page-layout__container">
        <a-page-header v-if="showHeader" :ghost="false" @back="onBack">
            <template #backIcon>
                <slot name="backIcon">
                    <tbx-icon type="arrow-left" v-if="props.backIcon"/>
                </slot>
            </template>
            <template #title>
                <slot name="title">{{ pageTitle }}</slot>
            </template>
            <template #extra>
                <slot name="extra" />
            </template>
        </a-page-header>
        <div class="page-layout__body">
            <slot />
        </div>
    </a-layout-content>
</template>

<style scoped lang="less">

</style>
