import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import {
    NegotiationMessageUpdateForm,
    NegotiationRegisterForm,
    NegotiationRequestFormModel
} from '@/models/negotiation';
import { FormValidateUtil } from '@/models/validate-helper';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { negotiationApi } from '@/repository/api/internal/negotiation';
import { useMessage } from '@/composables/helper/page-helper';
import { Util } from '@/util';

/**
 * 商談リクエストをする機能を提供します。
 */
export const useNegotiationRequest = () => {
    const { state: { loading }, withLoading } = useLoading();
    const { state: { profile } } = useAccountMyProfile();
    const message = useMessage();

    const form = ref<NegotiationRequestFormModel>(new NegotiationRequestFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const request = (baggageId: number) => withLoading(async () => {
        try {
            const apiForm: NegotiationRegisterForm = {
                baggageId: baggageId,
                staffName: profile.value?.name ?? '',
                reportStatus: Util.requireNotNull(form.value.reportStatus),
                message: form.value.message,
            };
            await negotiationApi.register(apiForm);
            message.success('対応可否を返答しました。');
        } catch {
            message.error('対応可否を返答できませんでした。時間をおいて再度お試しください。');
        }
    });

    const updateMessage = (baggageId: number, negotiationId: number, messageId: number) => withLoading(async () => {
        try {
            const apiForm: NegotiationMessageUpdateForm = {
                baggageId: baggageId,
                staffName: profile.value?.name ?? '',
                reportStatus: Util.requireNotNull(form.value.reportStatus),
                message: form.value.message,
            };
            await negotiationApi.updateMessage(negotiationId, messageId, apiForm);
            message.success('対応可否を更新しました。');
        } catch {
            message.error('対応可否を更新できませんでした。時間をおいて再度お試しください。');
        }
    });

    return {
        state: {
            form,
            formValidateRules,
            loading,
        },
        request,
        updateMessage,
    };
};
