import { useExternalPageUrl } from '@/composables/global/external-page-url';
import { computed, ref } from 'vue';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useHighContrastMode } from '@/composables/global/high-contrast-mode';
import { useEntitlement } from '@/composables/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';

/**
 * アカウントメニュー機能を提供します。
 */
export const useAccountMenuService = () => {
    const { state: { profile } } = useAccountMyProfile();
    const { state: { highContrastMode }, toggle: toggleHighContrastMode } = useHighContrastMode();
    const { state: externalPage, } = useExternalPageUrl();
    const { state: { entitlement } } = useEntitlement();

    const menuVisible = ref<boolean>(false);
    const highContrastMenuItemKey = ref<string>('HIGH_CONTRAST_MODE_MENU_ITEM_KEY');

    /**
     * ユーザー名
     */
    const name = computed<string>(() => profile.value?.name ?? '');

    /**
     * 会社情報管理が可能か否か
     */
    const canViewSetting = computed(() => {
        return entitlement.value?.canViewSelfProfileSetting(DateTimeValue.now()) ?? true;
    });

    /**
     * 会社概要印刷を表示するべきか否か
     */
    const shouldShowPrintSelf = computed(() => {
        return true;
    });

    /**
     * 会社概要印刷が可能か否か
     */
    const canPrintSelf = computed<boolean>(() => entitlement.value?.canPrintSelfProfile(DateTimeValue.now()) ?? true);

    return {
        name,
        menuVisible,
        highContrastMenuItemKey,
        highContrastMode,
        canPrintSelf,
        shouldShowPrintSelf,
        canViewSetting,
        externalPage,
        toggleHighContrastMode,
    };
};
