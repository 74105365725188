<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    value?: string;
    autoSize?: { minRows: number, maxRows: number }
}>();

// Note: 上位コンポーネントのform-model-itemが自動バリデーションを発動できるようにchange, blurをemitする
// https://1x.antdv.com/components/form-model/#Note

const emit = defineEmits<{
    (e: 'input', value: string | undefined): void;
    (e: 'change'): void;
    (e: 'blur'): void;
}>();

const model = computed({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});
</script>

<template>
    <a-textarea placeholder="※注意 「交換」「交換希望」はトラブルの元になります。ご遠慮ください。"
                v-model="model"
                :auto-size="autoSize"
                @change="emit('change')"
                @blur="emit('blur')"/>
</template>

<style scoped lang="less">

</style>
