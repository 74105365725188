import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
// @ts-ignore
import UiBaggageTypeInput from '@/components/UI/BaggageTypeInput';
import { BaggageUtil } from '@/util';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';

@Component({
    components: {
        UiBaggageTypeInput,
    },
})
export default class BaggageTypeEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '荷種を入力してください。',
        },
        {
            max: 250,
            message: '荷種は250文字以内で入力してください。',
        },
    ];

    get baggageType(): string {
        return this.value?.type ?? '';
    }

    set baggageType(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.type = newValue;

        // 引っ越しという単語が含まれている場合は引っ越し案件に強制的に設定
        const isRelocate = BaggageUtil.includesRelocateWord(newValue);
        if (isRelocate) {
            cloned.category = { code: BaggageCategoryEnum.Relocation.code };
        } else if (cloned.category.code === BaggageCategoryEnum.Relocation.code) {
            cloned.category = { code: BaggageCategoryEnum.Normal.code };
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
