import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementRelocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;

    get relocation(): string {
        if (!this.agreement) return '';
        return this.agreement.category.code == 'BC2' ? '○' : '×';
    }
}
