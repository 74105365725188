import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useCompanyTransfer, useCompanyTransferUpdate } from '@/composables/company-transfer';
import { useFormModel } from '@/composables/form-helper';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { computed, onBeforeMount } from 'vue';
import { useBankAccountTypeOptions } from '@/composables/option-helper';

export const useSettingCompanyTransferHelper = () => {
    const { state: { myProfile: profile } } = useCompanyMyProfile();
    const { state: { transfer, loading: loadingGet }, load } = useCompanyTransfer();
    const { state: { form, formValidateRules, loading: loadingUpdate }, initForm, fillForm, update } = useCompanyTransferUpdate();
    const { options: bankAccountTypes } = useBankAccountTypeOptions();
    const { formModel: formModelRef, submit } = useFormModel();
    const message = useMessage();
    const notification = useNotification();

    const loading = computed(() => loadingGet.value || loadingUpdate.value);

    const onClickAutoFill = () => {
        if (!profile.value) return;
        const confirmed = confirm('すでに入力済みのデータが上書きされますがよろしいですか？');
        if (!confirmed) return;
        fillForm(profile.value);
    };

    const onSubmit = () => submit(async () => {
        try {
            await update().then(load);
            message.success('振込先口座情報を更新しました。');
        } catch {
            notification.error({
                message: '振込先口座情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    onBeforeMount(async () => {
        await load();
        if (transfer.value) initForm(transfer.value);
    });

    return {
        loading,
        form,
        formModelRef,
        formValidateRules,
        bankAccountTypes,
        onClickAutoFill,
        onSubmit,
    };
};
