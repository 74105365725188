import { ref } from 'vue';
import { AgreementModel } from '@/models/agreement';
import { useLoading } from '@/composables/helper/loading-helper';
import { agreementApi } from '@/repository/api/internal/agreement';

export const useAgreement = () => {
    const agreement = ref<AgreementModel>();
    const { state: { loading }, withLoading } = useLoading();

    const load = (agreementId: number) => withLoading(async () => {
        agreement.value = await agreementApi.find(agreementId).then(data => new AgreementModel(data));
    });

    return {
        state: {
            agreement,
            loading,
        },
        load,
    };
};

export const useAgreementStabilize = () => {
    const { state: { loading }, withLoading } = useLoading();

    const stabilize = (agreementId: number) => withLoading(async () => {
        await agreementApi.stabilize(agreementId);
    });

    return {
        state: {
            loading,
        },
        stabilize,
    };
};
