import {
    InquiryForm,
    ReportLatePaymentForm,
    TraboxNotice
} from '@/vuex/modules/help/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const helpApi = {
    /**
     * その他お問い合わせ・ご意見を送信します。
     */
    inquiry(param: InquiryForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/inquiry', param).then(isSuccess);
    },
    /**
     * 入金遅延連絡を送信します。
     */
    reportUnpaid(param: ReportLatePaymentForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/report/unpaid', param).then(isSuccess);
    },
    /**
     * お知らをを取得します。
     */
    notice(): Promise<TraboxNotice> {
        return appAxios.get<JsonResponseBean<TraboxNotice>>('/api/notice').then(isSuccess);
    },
};
