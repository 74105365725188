import _ from 'lodash';
import { computed, onMounted } from 'vue';
import { useFormModel } from '@/composables/form-helper';
import { useCompanyMyConfidence } from '@/composables/global/company-my-confidence';
import { useCompanyConfidenceUpdate } from '@/composables/company-confidence';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';

export const useSettingCompanyConfidenceHelper = () => {
    const { formModel, submit } = useFormModel();
    const message = useMessage();
    const notification = useNotification();
    const { goToNotFound } = useRouting();

    // 企業信用取得
    const {
        state: { loading: loadingConfidence, myConfidence },
        load
    } = useCompanyMyConfidence();
    // 企業信用更新
    const {
        state: { loading: loadingUpdate, form, formValidateRules },
        initForm,
        update
    } = useCompanyConfidenceUpdate();

    const loading = computed(() => loadingConfidence.value || loadingUpdate.value);

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    const onSubmit = () => submit(async () => {
        try {
            await update().then(load);
            message.success('信用情報を更新しました。');
        } catch {
            notification.error({
                message: '信用情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    onMounted(async () => {
        await load().catch(goToNotFound);
        if (!_.isNil(myConfidence.value)) {
            initForm(myConfidence.value);
        }
    });

    return {
        loading,
        form,
        formModel,
        formValidateRules,
        onSubmit,
    };
};
