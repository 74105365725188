export type DetailProps = {
    baggageId: number;
    // 編集モードか？
    edit: boolean;
    // 荷物登録直後からの遷移か？
    registered: boolean;
    // 空車レコメンド／会社詳細を表示するか？
    truckCompany?: { companyId: number, truckId: number };
    // 商談リクエスト／会社詳細が表示するか？
    negotiationCompany?: { companyId: number, negotiationId: number };
};

/**
 * 編集モードから離脱しようとしているか否かを取得する。
 */
export const isLeavingEditor = (from: DetailProps | undefined, to: DetailProps | undefined): boolean => {
    // 現在編集モードか？
    if (from && from.edit) {
        // 詳細から離脱 or 編集モードから離脱 or 別の荷物の編集モードへ移行
        return to === undefined || !to.edit ||  from.baggageId !== to.baggageId;
    }
    return false;
};
