import { useLoading } from '@/composables/helper/loading-helper';
import { ref } from 'vue';
import { NegotiationList, Negotiation } from '@/models/negotiation';
import { negotiationApi } from '@/repository/api/internal/negotiation';
import { CompanyProfile } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import _ from 'lodash';

export type NegotiationListResult = Omit<NegotiationList, 'data'> & { data: (Negotiation & { company:  CompanyProfile })[] };

/**
 * 商談リクエストをロードする機能を提供します。
 */
export const useNegotiationList = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<NegotiationListResult | undefined>(undefined);

    const companies = async (ids: number[]) => {
        const companies = ids.length > 0 ? await companyApi.listProfile({ id: ids }) : [];
        return _.keyBy(companies, each => each.id);
    };

    const load = (baggageId: number, pageNo: number, pageSize: number) => withLoading(async () => {
        const negotiations = await negotiationApi.list(baggageId, { pageNo, pageSize });
        const ids = negotiations.data.map(each => each.truckCompanyId);
        const companyMap = await companies(ids);

        list.value = {
            ...negotiations,
            data: negotiations.data.map(each => ({
                ...each,
                company: companyMap[each.truckCompanyId],
            })),
        };
    });

    const clear = () => {
        list.value = undefined;
    };

    return {
        state: {
            loading,
            list,
        },
        load,
        clear,
    };
};
