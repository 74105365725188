import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { environmentApi } from '@/repository/api/api';
import { ACTION as a, Environment, EnvironmentState, GETTER as g, MUTATION as m } from './types';

const state: EnvironmentState = {
    environment: undefined,
};

const actions: ActionTree<EnvironmentState, void> = {
    /**
     * 環境情報をロードします。
     */
    [a.LOAD_ENVIRONMENT]: ({ commit }) =>
        environmentApi.load().then((environment) => commit(m.SET_ENVIRONMENT, environment)),
};

const getters: GetterTree<EnvironmentState, void> = {
    /**
     * 環境情報を取得します。
     */
    [g.ENVIRONMENT]: (s) => s.environment,
};

const mutations: MutationTree<EnvironmentState> = {
    /**
     * 環境情報を設定します。
     *
     */
    [m.SET_ENVIRONMENT]: (s, environment: Environment) => (s.environment = environment),
};

export const environment: Module<EnvironmentState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
