import { usePagination } from '@/composables/global/pagination';
import { useCompanyPlaceDestroy, useCompanyPlaceSearch } from '@/composables/company-place';
import { computed, onBeforeMount } from 'vue';
import { Const } from '@/const';
import { CompanyPlaceModel } from '@/models/company-place';
import { useRouting } from '@/composables/helper/routing';
import { message, Modal } from 'ant-design-vue';
import { ModalUtil, PageUtil } from '@/util';
import { useComponentRef } from '@/composables/helper/page-helper';

export const useCompanyPlaceListHelper = () => {
    const { state: { pageSize } } = usePagination();
    const { state: { loading: loadingSearch, list, form }, search } = useCompanyPlaceSearch();
    const { state: { loading: loadingDestroy }, destroy } = useCompanyPlaceDestroy();
    const loading = computed<boolean>(() => loadingSearch.value || loadingDestroy.value);
    const pageInfo = computed(() => {
        return {
            totalPageCount: list.value?.totalPageCount ?? 0,
            totalRecordCount: list.value?.totalRecordCount ?? 0,
            currentPage: list.value?.currentPageNumber ?? 1,
            currentPageSize: form.value.pageSize,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });
    const companyPlaceListData = computed<CompanyPlaceModel[]>(() => {
        return list.value?.data?.map(each => new CompanyPlaceModel(each)) ?? [];
    });
    const { component: searchResultsRef, el } = useComponentRef();

    const { goToCompanyPlaceRegister, goToCompanyPlaceEdit } = useRouting();

    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    const scrollToSearchResults = () => {
        PageUtil.scrollToContentTop(el.value?.offsetTop);
    };

    const onChangePage = async (param: { pageNo: number, pageSize: number }) => {
        await search(param.pageNo, param.pageSize);
        scrollToSearchResults();
        pageSize.value = param.pageSize;
    };

    onBeforeMount(async () => {
        const initialPageSize = pageSize.value ?? Const.DEFAULT_PAGE_SIZE;
        await search(1, initialPageSize);
    });

    // --------------------------------------------------------------------------
    // Action Button
    // --------------------------------------------------------------------------
    const onClickEdit = (companyPlaceId: number) => {
        goToCompanyPlaceEdit(companyPlaceId).then(r => null);
    };

    const onClickCopy = (companyPlaceId: number) => {
        goToCompanyPlaceRegister(companyPlaceId).then(r => null);
    };

    const onClickDelete = async (id: number) => {
        Modal.confirm({
            title: '選択した地点情報を本当に削除しますか？',
            content: 'この操作は元に戻すことができません。ご注意ください。',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                await destroy(id).then(() => {
                    message.success('登録した地点情報を削除しました。');
                });

                await search(form.value.pageNo, form.value.pageSize);
            }
        });
    };

    return {
        loading,
        pageInfo,
        searchResultsRef,
        companyPlaceListData,
        onChangePage,
        onClickEdit,
        onClickCopy,
        onClickDelete,
    };
};
