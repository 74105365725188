import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';
import { Const } from '@/const';
import { PageVue } from '@/mixin/PageVue';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({ title: '退会' })
export default class UnSubscribePage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Data
    // ======================================================
    appServiceName = Const.APP_SERVICE_NAME;
    agreed = false;

    /**
     * プレミアムプランを契約中かどうかを取得します。
     */
    get hasActivePremiumPlan(): boolean {
        return this.CONTRACT_LIST?.activePlanType === 'PREMIUM' ?? false;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 「戻る」ボタンをクリックした際に呼び出されます。
     */
    onClickBack(): void {
        this.$router.go(-1);
    }

    /**
     * 有料サービス停止ボタンが押下された際に呼び出される。
     */
    onClickToRevokePremium(): Promise<Route> {
        return this.$router.push({ name: 'RevokePremiumPlan' });
    }

    /**
     * 退会ボタンが押下された際に呼び出される。
     */
    onClickStartUnsubscribe(): Promise<Route> {
        return this.$router.push({ name: 'UnsubscribeEdit' });
    }
}
