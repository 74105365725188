import { computed, onMounted, ref, watch } from 'vue';
import { useTruckSearch } from '@/composables/truck';
import { DateValue } from '@/models/vo/date';
import { PageInfo } from '@/_components/ui/types/page-info';
import { Const } from '@/const';
import { SortInfo } from '@/_components/ui/types/sort-info';
import { TruckModel } from '@/models/truck';
import { useCompanyProfileList } from '@/composables/company-profile-list';
import _ from 'lodash';
import { Karte } from '@/karte';
import { CompanyProfile } from '@/models/company';
import { useRouting } from '@/composables/helper/routing';
import { ExcludeMethods } from '@/types/lang';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Dictionary, Route } from 'vue-router/types/router';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { useRoute } from '@/composables/helper/route';
import { useComponentRef, useMessage, useNotification } from '@/composables/helper/page-helper';
import { PageUtil } from '@/util';
import { usePagination } from '@/composables/global/pagination';
import { useGtm } from '@/composables/helper/gtm-helper';
import { Validator } from '@/validator';
import { useOfficialCompany } from '@/composables/company-official';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

class TruckWithCompanyRecord {
    truckId: number;
    truck: TruckModel;
    company?: CompanyProfile;

    constructor(param: ExcludeMethods<TruckWithCompanyRecord>) {
        this.truckId = param.truckId;
        this.truck = param.truck;
        this.company = param.company;
    }
}

interface TruckSearchQuery {
    companyId?: number;
    truckId?: number;
}

export const useTruckSearchHelper = () => {
    const message = useMessage();
    const notification = useNotification();
    const { state: { pageSize } } = usePagination();
    const { gtm } = useGtm();
    const {
        state: { loading: loadingTruck, list: truckList, form, formValidateRules },
        search: searchTruck,
        clearForm
    } = useTruckSearch();
    const {
        state: { loading: loadingCompanyList, list: companyList },
        load: loadCompanyList,
    } = useCompanyProfileList();
    const {
        state: { profile, loading: loadingProfile, },
        load: loadProfile,
        clear: clearProfile,
    } = useCompanyProfile();
    const { state: { officialCompany },
        load: loadOfficialCompany,
        clear: clearOfficialCompany
    } = useOfficialCompany();
    const {
        state: { confidence, loading: loadingConfidence },
        load: loadConfidence,
        clear: clearConfidence,
    } = useCompanyConfidence();
    const {
        state: { statistics, loading: loadingStatistics },
        load: loadStatistics,
        clear: clearStatistics,
    } = useCompanyStatistics();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const { currentRoute } = useRoute();
    const { replaceQuery, openCompanyPrint } = useRouting();
    // ======================================================
    // Data
    // ======================================================
    const searchableDate = {
        min: DateValue.today(),
        max: DateValue.today().add(1, 'year'),
    };
    const validationErrorMessage = ref<string>();

    const loading = computed(() => loadingTruck.value || loadingCompanyList.value);
    const sortInfo = computed<SortInfo>(() => {
        return {
            sortOptions: Const.truckSortKey.map(each => {
                return {
                    label: each.label,
                    code: each.code,
                    defaultOrder: each.defaultOrder === 'ASC' ? 'ASC' : 'DESC'
                };
            }),
            sortKey: form.value.sortKey,
            sortOrder: form.value.sortOrder,
        };
    });
    const pageInfo = computed<PageInfo>(() => {
        return {
            totalPageCount: truckList.value?.totalPageCount ?? 0,
            totalRecordCount: truckList.value?.totalRecordCount ?? 0,
            currentPage: truckList.value?.currentPageNumber ?? 1,
            currentPageSize: form.value.pageSize,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });
    const truckListData = computed<Array<TruckWithCompanyRecord>>(() => {

        return truckList.value?.data?.map(truck => {
            /* 空車情報と企業プロフィールをマージする */
            return new TruckWithCompanyRecord({
                truckId: truck.id,
                truck: new TruckModel(truck),
                company: companyList.value.find(each => each.id === truck.companyId)
            });
        }) ?? [];
    });
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);
    const currentQuery = computed<TruckSearchQuery>({
        get: () => {
            const companyId = currentRoute.query?.companyId;
            const truckId = currentRoute.query?.truckId;
            return {
                companyId: companyId ? Number(companyId) : undefined,
                truckId: truckId ? Number(truckId) : undefined,
            };
        },
        set: async (value) => {
            if (routeUpdating.value || currentQuery.value === value) return;
            // URLクエリーの更新をリクエスト。
            const query: Dictionary<string> = {};
            if (value.companyId) query['companyId'] = `${ value.companyId }`;
            if (value.truckId) query['truckId'] = `${ value.truckId }`;
            await replaceQuery(query);
            // if (value.companyId && value.truckId) await goToTruckCompanyDetail(value.companyId, value.truckId);
        },
    });
    const { component: searchResultsRef, el } = useComponentRef();
    const drawerVisibility = computed<boolean>(() => !routeUpdating.value && !_.isNil(currentQuery.value.companyId));
    const loadingCompanyDetail = computed(() => loadingProfile.value || loadingConfidence.value || loadingStatistics.value);
    const circleOptions = computed(() => {
        return myProfile.value?.circles?.map(each => {
            return {
                label: each.name,
                value: each.id,
                key: each.id
            };
        });
    });
    const isMemberOfSingleCircle = computed(() =>
        circleOptions.value?.length === 1
    );

    // ======================================================
    // Functions
    // ======================================================
    const validate = (): boolean => {
        const validated = Validator.validateDateRangeForSearch(
            form.value.departureFrom,
            form.value.departureTo,
            form.value.arrivalFrom,
            form.value.arrivalTo
        );
        validationErrorMessage.value = validated.result ? '' : validated.message ?? '';
        return validated.result;
    };
    const search = async (pageNo: number, pageSize: number, sortKey: string, sortOrder: 'ASC' | 'DESC') => {
        await searchTruck(pageNo, pageSize, sortKey, sortOrder)
            .catch(() => message.error('空車情報の検索に失敗しました。検索条件を見直してもう一度お試しください。'));
        const companyIds = _.uniq(truckList.value?.data.map(each => each.companyId));
        await loadCompanyList(companyIds);
    };

    const loadCompanyDetail = async (companyId: number) => {
        await Promise.all([
            loadProfile(companyId),
            loadOfficialCompany(companyId).catch(() => false),
            loadConfidence(companyId),
            loadStatistics(companyId),
        ]);
    };

    const clearCompanyDetail = () => {
        clearProfile();
        clearOfficialCompany();
        clearConfidence();
        clearStatistics();
    };
    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    const scrollToSearchResults = () => {
        // TODO useElementScroll()に移行
        PageUtil.scrollToContentTop(el.value?.offsetTop);
    };
    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    const onClickGround = async () => {
        // 連打によるルーティングの多重実行を防止
        if (routeUpdating.value) return;
        // Drawerが開いている場合は閉じる
        if (drawerVisibility.value) {
            await onClickCloseDrawer();
        }
    };
    /**
     * 検索が押下された際に呼び出されます。
     */
    const onClickSearch = async () => {
        if (!validate()) return;
        await search(1, pageInfo.value.currentPageSize, form.value.sortKey, form.value.sortOrder);
        // KARTEイベント送信：空きトラック検索ボタン押下
        Karte.trackSearchTruck();
        scrollToSearchResults();
    };
    /**
     * クリアが押下された際に呼び出されます。
     */
    const onClickClear = () => clearForm();

    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     * ソート順かソートキーを操作した際に呼び出されます。
     */
    const onChangePage = async (param: { pageNo: number, pageSize: number, sortKey: string, sortOrder: 'ASC' | 'DESC' }) => {
        const isPageChange = pageInfo.value.currentPage != param.pageNo || pageInfo.value.currentPageSize != param.pageSize;
        await search(param.pageNo, param.pageSize, param.sortKey, param.sortOrder);
        pageSize.value = param.pageSize;
        // ページネーションの操作の場合のみスクロールをTOPへ
        if (isPageChange) scrollToSearchResults();
    };
    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    const onClickCloseDrawer = async () => {
        currentQuery.value = {};
    };
    /**
     * 都道府県の入れ替えボタンが押下された際に呼び出されます。
     */
    const onClickInvertPref = () => {
        const departurePref = form.value.arrivalPref;
        const arrivalPref = form.value.departurePref;
        form.value.departurePref = departurePref;
        form.value.arrivalPref = arrivalPref;
    };
    /**
     * カレンダーUIにおいて、引数に与えられた日付が選択不可の日付かどうかを判定します。
     * 今日〜1年後の年末までが選択可能な範囲としています。
     */
    const isDisabledDate = (currentDate: DateValue) => {
        return currentDate.isBefore(searchableDate.min) || currentDate.isAfter(searchableDate.max);
    };
    /**
     * 連絡先ツールチップをクリックした際に呼び出されます。
     */
    const onVisibleChangeContactsTooltip = (visible: boolean, id: number): void => {
        if (visible) {
            Karte.trackClickTruckContact(id);
            gtm.setEvent('open_truck_contacts', { id });
        }
    };
    /**
     * 企業のリンクをクリックすると呼び出されます。
     */
    const onClickCompanyLink = async ($event: Event, record: TruckWithCompanyRecord): Promise<void> => {
        // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
        $event.stopPropagation();
        if (currentQuery.value.companyId == record.truck.companyId && currentQuery.value.truckId == record.truck.id) {
            return;
        }
        currentQuery.value = { companyId: record.truck.companyId, truckId: record.truck.id };
    };
    /**
     * 企業ドロワーの電話番号を表示した際に呼び出されます。
     */
    const onClickContact = () => {
        if (!currentQuery.value.truckId) return;
        const id = currentQuery.value.truckId;
        Karte.trackClickTruckContact(id);
        gtm.setEvent('open_truck_contacts', { id });
    };
    /**
     * 企業ドロワーの印刷ボタンが押下された際に呼び出されます。
     */
    const onClickPrint = () => {
        if (!currentQuery.value.companyId) return;
        openCompanyPrint(currentQuery.value.companyId);
    };

    watch(currentQuery, async (newValue, _oldValue) => {
        const pageTitle = ['空車検索'];
        // GTMへページビューイベントを送信
        gtm.setPage(currentRoute.fullPath, pageTitle.join(Const.PAGE_TITLE_SEPARATOR));
    });

    onMounted(async () => {
        if (!myProfile.value) await loadMyProfile();
        if (isMemberOfSingleCircle.value) form.value.circleId = myProfile.value?.circles?.[0]?.id;
        const initialPageSize = pageSize.value ?? Const.DEFAULT_PAGE_SIZE;
        await search(1, initialPageSize, form.value.sortKey, form.value.sortOrder);
        if (currentQuery.value.companyId) {
            try {
                await loadCompanyDetail(currentQuery.value.companyId);
            } catch {
                notification.error({
                    message: '企業情報の読み込みに失敗しました。',
                    description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                    duration: 10,
                });
            }
        }
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const parseCompanyId = (route: Route): number | undefined =>
            route.query.companyId ? Number(route.query.companyId) : undefined;

        const toCompanyId = parseCompanyId(to);
        // toがない場合、一覧に戻る
        if (!toCompanyId) {
            clearCompanyDetail();
            next();
            return;
        }
        // ドロワー表示 or 更新
        routeUpdating.value = true;
        try {
            await loadCompanyDetail(toCompanyId);
            next();
        } catch {
            notification.error({
                message: '企業情報の読み込みに失敗しました。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                duration: 10,
            });
            next(false);
        } finally {
            routeUpdating.value = false;
        }
    });
    return {
        form,
        formValidateRules,
        loading,
        searchResultsRef,
        validationErrorMessage,
        sortInfo,
        pageInfo,
        truckListData,
        drawerVisibility,
        loadingCompanyDetail,
        circleOptions,
        profile,
        officialCompany,
        confidence,
        statistics,
        onClickGround,
        onClickSearch,
        onClickClear,
        onChangePage,
        onClickInvertPref,
        isDisabledDate,
        onVisibleChangeContactsTooltip,
        onClickCloseDrawer,
        onClickCompanyLink,
        onClickContact,
        onClickPrint,
    };
};
