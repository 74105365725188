import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { TruckRegisterFormModel } from '@/vuex/modules/truck/types';
import { Util } from '@/util';

@Component
export default class TruckFreightEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: TruckRegisterFormModel;

    get minFreight(): string {
        return this.value?.minFreight ?? '';
    }

    set minFreight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 最低運賃を書き換え
        cloned.minFreight = Util.parseFreightString(newValue);

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get freightDigits(): number {
        return Util.toNumber(this.minFreight);
    }

    get checked(): boolean {
        return this.value?.negotiate ?? false;
    }

    set checked(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (_.isNil(cloned)) return;

        // 要相談
        cloned.negotiate = newValue;
        if (cloned) {
            // 最低運賃を書き換え
            cloned.minFreight = '';
        }
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    private validate(callback: (message?: string) => void): void {
        if (this.checked) {
            callback();
        } else if (this.freightDigits > 0) {
            callback();
        } else {
            callback('有効な金額を入力するか、要相談をチェックしてください。');
        }
    }
}
