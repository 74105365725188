import { ref } from 'vue';

/**
 * ローディング状態を提供する関数
 */
export const useLoading = () => {
    const loading = ref<boolean>(false);

    const withLoading = async <T = void>(f: () => Promise<T>): Promise<T> => withMuteableLoading(false, f);

    // ローディング状態をミュートできるバージョン
    const withMuteableLoading = async <T = void>(mute: boolean, f: () => Promise<T>): Promise<T> => {
        loading.value = !mute;
        return await f().finally(() => loading.value = false);
    };

    return {
        state: {
            loading,
        },
        withLoading,
        withMuteableLoading,
    };
};
