import { Enum } from '@/types/enum';

export type CompanyNoticeStatus = 'INIT' | 'READ';
export type CompanyNoticeType =
    | 'AGREEMENT_AGREED' // 運送企業による成約登録（荷主企業にお知らせ）
    | 'ACCEPTED_AGREEMENT_CHANGE' // 運送企業による成約変更申請（荷主企業にお知らせ）
    | 'AGREED_AGREEMENT_CHANGE' // 荷主企業による成約変更申請（運送企業にお知らせ）
    | 'AGREEMENT_REVOKE' // 運送企業による成約取消申請（荷主企業にお知らせ）
    | 'AGREEMENT_REVOKED' // 荷主企業による成約取消（運送企業にお知らせ）
    | 'ONLINE_ORDER_CREATE' // トラボックス受発注 荷主企業による依頼書作成（運送企業にお知らせ）
    | 'ONLINE_ORDER_UPDATE' // トラボックス受発注 荷主企業による依頼書編集（運送企業にお知らせ）
    | 'ONLINE_ORDER_REPLY' // トラボックス受発注 運送企業による車番返信（荷主企業にお知らせ）
    | 'ONLINE_ORDER_REPLY_UPDATE' // トラボックス受発注 運送企業による車番編集（荷主企業にお知らせ）
    | 'ONLINE_ORDER_DELETE'; // トラボックス受発注 荷主企業による依頼書キャンセル（運送企業にお知らせ）

export const agreementTypes = ['AGREEMENT_AGREED', 'ACCEPTED_AGREEMENT_CHANGE', 'AGREED_AGREEMENT_CHANGE', 'AGREEMENT_REVOKE', 'AGREEMENT_REVOKED', 'ONLINE_ORDER_CREATE', 'ONLINE_ORDER_UPDATE', 'ONLINE_ORDER_REPLY', 'ONLINE_ORDER_REPLY_UPDATE', 'ONLINE_ORDER_DELETE'];


export interface CompanyNotice {
    id: number;
    companyId: number;
    status: Enum<CompanyNoticeStatus>;
    type: Enum<CompanyNoticeType>;
    objectId: number;
    sourceId: number;
    expireTm: string;
    entryTm: string;
    callbackUrl?: string;
}
