import { useLoading } from '@/composables/helper/loading-helper';
import { ref } from 'vue';
import { AccountProfileListModel } from '@/models/account-profile-list';
import { accountApi } from '@/repository/api/internal/account';

/**
 * 商談リクエストをロードする機能を提供します。
 */
export const useAccountList = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<AccountProfileListModel | undefined>(undefined);

    const load = () => withLoading(async () => {
        await accountApi.profileList()
            .then(data => list.value = new AccountProfileListModel(data));
    });

    return {
        state: {
            loading,
            list,
        },
        load,
    };
};
