import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { ZipUtil } from '@/util';

@Component
export default class CompanyLocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get location(): string {
        if (!this.profile) return '';
        const zip = this.profile.zipCode ?? '';
        const prefecture = this.profile.location.prefecture.label ?? '';
        const city = this.profile.location.city;
        const address = this.profile.location.address;
        return `〒${ZipUtil.format(zip)} ${prefecture}${city}${address}`;
    }
}
