<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useBaggageBulkRegisterHelper } from '@/_pages/Baggage/BulkRegister/baggage-bulk-register-helper';

const {
    fileList,
    submittable,
    loading,
    circleId,
    circleOptions,
    beforeUpload,
    handleRemove,
    handleSubmit,
    handleDownloadFormat,
} = useBaggageBulkRegisterHelper();

</script>

<template>
    <page-layout :back-icon="false">
        <div class="container">
            <a-card class="form-card">
                <div style="width: 500px">
                    <a-upload
                        :file-list="fileList"
                        :accept="'.csv'"
                        :before-upload="beforeUpload"
                        :remove="handleRemove">
                        <a-button>
                            <a-icon type="upload"/>
                            ファイルを選択
                        </a-button>
                    </a-upload>
                </div>
                <div style="margin-top: 24px">
                  <a-select :options="circleOptions"
                            v-model="circleId"
                            placeholder="部屋を選択"
                            style="width: 300px" />
                </div>
                <a-button
                    :disabled="!submittable"
                    :loading="loading"
                    class="submit-button"
                    type="primary"
                    @click="handleSubmit">登録
                </a-button>
                <a-button type="link" @click="handleDownloadFormat">フォーマットをダウンロード</a-button>
            </a-card>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
// typescript/pages/Baggage/Register/style.less
.container {
    padding: 24px;
}

.form-card {
    padding: 16px 24px;
    height: 100vh;
}

.submit-button {
    margin-top: 24px;
}

</style>
