import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { EntitlementModel } from '@/models/entitlement';
import { createGlobalState } from '@vueuse/core';
import { accountApi } from '@/repository/api/api';
import { useVuexSync } from '@/composables/helper/vuex';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/entitlement/types';

/**
 * 資格を取得する。
 */
export const useEntitlement = createGlobalState(() => {
    const cache = ref<EntitlementModel>();

    useVuexSync(cache, { module: 'entitlement', getter: g.ENTITLEMENT, mutation: m.SET_ENTITLEMENT });

    const { state: { loading }, withLoading } = useLoading();

    const load = async (force: boolean): Promise<EntitlementModel> => {
        if (!force && cache.value !== undefined) {
            return cache.value;
        }

        const entitlement = await withLoading(() =>
            accountApi.entitlement()
                .then((item) => new EntitlementModel(item))
        );

        cache.value = entitlement;

        return entitlement;
    };

    return {
        state: {
            loading,
            entitlement: cache,
        },
        load,
    };
});
