import { createGlobalState, useLocalStorage } from '@vueuse/core';
import { Const } from '@/const';
import { computed } from 'vue';

export const usePagination = createGlobalState(() => {
    const pageSizeSaved = useLocalStorage<number | null>('pageSize', Const.DEFAULT_PAGE_SIZE);
    const pageSize = computed({
        get: () => pageSizeSaved.value,
        // 既存コードとの互換性を保つ為。stringなど何が入ってきても大丈夫なようにしておく
        set: (value: any) => {
            try {
                pageSizeSaved.value = Number(value);
            } catch {
                // DO Nothing
            }
        },
    });

    const clear = () => {
        pageSize.value = null;
    };

    return {
        state: {
            pageSize,
        },
        clear
    };
});
