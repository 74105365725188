<script setup lang="ts">
import {
    useSettlementDetailDrawerHelper
} from '@/_pages/Baggage/List/drawers/SettlementDetail/settlement-detail-drawer-helper';
import DrawerContentLayout from '@/_pages/Baggage/List/drawers/common/DrawerContentLayout.vue';
import SettlementBaggageType from '@/_pages/Baggage/List/tabs/components/settlement/detail/SettlementBaggageType.vue';
import TbxButton from '@/_components/ui/TbxButton.vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { useRouting } from '@/composables/helper/routing';
import { Util } from '@/util';

const props = defineProps<{
    visible: boolean;
    type: 'income' | 'outgo',
}>();

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'change'): void;
}>();

const isIncome = props.type === 'income';
const isOutgo = props.type === 'outgo';

const {
    loading,
    settlement,
    partner,
    diff,
    iAmPayer,
    iAmPayee,
    canEdit,
    canApprove,
    canReject,
    canCancel,
    showNormalAmount,
    showCancellationAmount,
    showHighlight,
    onApprove,
    onReject,
    onCancel,
    toggleConfirmation,
} = useSettlementDetailDrawerHelper();
const { goToSettlementIncomeEditor } = useRouting();

const onApproveRequest = async () => {
    await onApprove();
    emit('change');
};
const onRejectRequest = async () => {
    await onReject();
    emit('change');
};
const onCancelRequest = async () => {
    await onCancel();
    emit('change');
};
const onChangeConfirmation = async () => {
    await toggleConfirmation();
    emit('change');
};
const onEdit = () => {
    goToSettlementIncomeEditor(Util.requireNotNull(settlement.value?.id));
};
</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0, height: 'calc(100% - 54px)', overflow: 'auto' }"
              :mask-style="{ background: 'transparent' }"
              :destroy-on-close="true"
              @close="emit('close')">
        <template #title>
            取引情報
        </template>
        <drawer-content-layout :loading="loading">
            <!-- 申請通知 -->
            <a-alert type="warning" v-if="canApprove || canReject || canCancel" show-icon
                     class="settlement-changed-alert">
                <template #icon>
                    <a-icon type="info-circle" class="changed-notification-icon"/>
                </template>
                <template #message>
                    <a-spin :spinning="false">
                        <a-row type="flex" align="middle">
                            <a-col flex="auto">
                                <div class="changed-notification-message">
                                    <span class="component-heading-h3" v-if="iAmPayer">運送会社からの承認依頼があります。</span>
                                    <span class="component-heading-h3" v-else-if="iAmPayee">未承認の申請があります。</span>
                                    <span class="component-body-s">承認期限：{{
                                            settlement?.changeLimitTm.format('M/D(ddd) HH:mm')
                                        }}</span>
                                </div>
                            </a-col>
                            <a-col flex="200px" style="text-align: end">
                                <a-space direction="horizontal">
                                    <tbx-button v-if="canApprove" type="primary" @click="onApproveRequest">
                                        <tbx-icon type="check"/>
                                        承認
                                    </tbx-button>
                                    <tbx-button v-if="canReject" type="secondary" @click="onRejectRequest">
                                        <tbx-icon type="close"/>
                                        却下
                                    </tbx-button>
                                    <tbx-button v-if="canCancel" type="secondary" @click="onCancelRequest">
                                        <tbx-icon type="close"/>
                                        取り下げ
                                    </tbx-button>
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-spin>
                </template>
            </a-alert>
            <div class="editable-area" v-if="isIncome">
                <a-checkbox :checked="settlement?.confirmed" v-if="settlement?.proxy" :disabled="!settlement"
                            @change="onChangeConfirmation">
                    確認済
                </a-checkbox>
                <tbx-button type="secondary" v-if="canEdit"
                            :disabled="settlement?.confirmed" @click="onEdit">
                    <tbx-icon type="edit"/>
                    変更
                </tbx-button>
            </div>
            <a-descriptions bordered :column="1" size="small">
                <a-descriptions-item label="取引番号">
                    {{ settlement?.id }}
                </a-descriptions-item>
                <a-descriptions-item v-if="isIncome" label="変更期限">
                    {{ settlement?.changeLimitTm.format('YYYY年M月D日(ddd) H:mm') }}
                </a-descriptions-item>
                <a-descriptions-item v-if="isIncome" label="入金日">
                    {{ settlement?.payeeDate.format('YYYY年M月D日(ddd)') }}
                </a-descriptions-item>
                <a-descriptions-item v-if="isOutgo" label="支払日">
                    <div>{{ settlement?.payerDate.format('YYYY年M月D日(ddd)') }}</div>
                    <div v-if="settlement?.proxy" class="component-note-xs">
                        ※運送会社への入金は、TRABOXから{{ settlement?.payeeDate.format('M月D日') }}に行われます。
                    </div>
                </a-descriptions-item>
                <a-descriptions-item label="おまかせ請求">
                    {{ settlement?.proxy ? '対象' : '対象外' }}
                    <template v-if="diff && diff.proxyDiff.hasDiff">
                        →
                        <span class="change-request">{{ diff?.proxyDiff.request ? '対象' : '対象外' }}</span>
                    </template>
                </a-descriptions-item>
                <a-descriptions-item v-if="isOutgo && settlement?.proxy" label="運送会社の確認">
                    <div class="confirmation" v-if="settlement?.confirmed">
                        <a-button type="primary" shape="circle" icon="check" class="btn-small"/>
                        確認済
                    </div>
                    <div class="confirmation" v-else>
                        未確認
                    </div>
                </a-descriptions-item>
                <a-descriptions-item>
                    <template #label><span class="total-amount">合計金額</span></template>
                    <div class="amount-total">
                        <a-space>
                            <span :class="{'changed-value': settlement?.status.isInitial}">
                                {{ settlement?.totalAmount.format() }}
                            </span>
                            <span v-if="settlement?.status.isInitial">(新規取引)</span>
                        </a-space>
                        <template v-if="diff && diff.totalAmountDiff.hasDiff">
                            →
                            <span class="change-request">
                                {{ diff?.totalAmountDiff.request.format() ?? '0円' }}
                            </span>
                        </template>
                    </div>
                    <template v-if="showNormalAmount">
                        <div class="amount-item">
                            運賃：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.freight)}">
                                {{ settlement?.freight.format('', '円 (税別)') }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.freightDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.freightDiff.request.format('', '円 (税別)') }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            高速代：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.highwayFare) > 0}">
                                {{ settlement?.highwayFare.format('', '円 (税込)') }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.highwayFareDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.highwayFareDiff.request.format('', '円 (税込)') }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            待機料：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.waitTimeFee)}">
                                {{ settlement?.waitTimeFee?.format('', '円 (税別)') ?? '0円 (税別)' }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.waitTimeFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.waitTimeFeeDiff.request?.format('', '円 (税別)') ?? '0円 (税別)' }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            付帯作業料：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.operationFee)}">
                                {{ settlement?.operationFee?.format('', '円 (税別)') ?? '0円 (税別)' }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.operationFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.operationFeeDiff.request?.format('', '円 (税別)') ?? '0円 (税別)' }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            搬出料：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.pickingFee)}">
                                {{ settlement?.pickingFee?.format('', '円 (税込)') ?? '0円 (税込)' }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.pickingFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.pickingFeeDiff.request?.format('', '円 (税込)') ?? '0円 (税込)' }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            駐車代：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.parkingFee)}">
                                {{ settlement?.parkingFee?.format('', '円 (税込)') ?? '0円 (税込)' }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.parkingFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.parkingFeeDiff.request?.format('', '円 (税込)') ?? '0円 (税込)' }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                        <div class="amount-item">
                            通関料：
                            <span v-if="!settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.clearanceFee)}">
                                {{ settlement?.clearanceFee?.format('', '円 (非課税)') ?? '0円 (非課税)' }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.clearanceFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="!diff?.request.hasCancellationFee">
                                        {{ diff?.clearanceFeeDiff.request?.format('', '円 (非課税)') ?? '0円 (非課税)' }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                    </template>
                    <template v-if="showCancellationAmount">
                        <div class="amount-item">
                            キャンセル料：
                            <span v-if="settlement?.hasCancellationFee"
                                  :class="{'change-request': showHighlight(settlement?.cancellationFee)}">
                                {{ settlement?.cancellationFee?.format('', '円 (非課税)') }}
                            </span>
                            <span v-else>なし</span>
                            <template v-if="diff && diff.cancellationFeeDiff.hasDiff">
                                →
                                <span class="change-request">
                                    <span v-if="diff?.request.hasCancellationFee">
                                        {{ diff?.cancellationFeeDiff.request.format('', '円 (非課税)') }}
                                    </span>
                                    <span v-else>なし</span>
                                </span>
                            </template>
                        </div>
                    </template>
                </a-descriptions-item>
                <a-descriptions-item label="発日">
                    {{ settlement?.departureDate.format('YYYY年M月D日(ddd)') }}
                </a-descriptions-item>
                <a-descriptions-item label="発地">
                    {{ settlement?.departureLocation }}
                </a-descriptions-item>
                <a-descriptions-item label="着日">
                    {{ settlement?.arrivalDate.format('YYYY年M月D日(ddd)') }}
                </a-descriptions-item>
                <a-descriptions-item label="着地">
                    {{ settlement?.arrivalLocation }}
                </a-descriptions-item>
                <a-descriptions-item label="重量">
                    {{ settlement?.truckType.weight.label }}
                </a-descriptions-item>
                <a-descriptions-item label="車種">
                    {{ settlement?.truckType.model.label }}
                </a-descriptions-item>
                <a-descriptions-item label="荷種">
                    <settlement-baggage-type v-if="settlement" :settlement="settlement"/>
                </a-descriptions-item>
                <a-descriptions-item label="成約番号">
                    {{ settlement?.agreementId }}
                </a-descriptions-item>
                <a-descriptions-item v-if="isIncome" label="荷主">
                    <div>{{ partner?.name.kanji }}</div>
                    <div>担当者：{{ settlement?.staffName }}</div>
                    <div>TEL：{{ partner?.formattedPhoneNumber }}</div>
                    <div>FAX：{{ partner?.formattedFaxNumber }}</div>
                </a-descriptions-item>
                <a-descriptions-item v-if="isOutgo" label="運送会社">
                    <div>{{ partner?.name.kanji }}</div>
                    <div>担当者：{{ settlement?.truckStaffName }}</div>
                    <div>TEL：{{ partner?.formattedPhoneNumber }}</div>
                    <div>FAX：{{ partner?.formattedFaxNumber }}</div>
                </a-descriptions-item>
                <a-descriptions-item label="備考">
                    {{ settlement?.description }}
                </a-descriptions-item>
            </a-descriptions>
        </drawer-content-layout>
        <slot name="editor"/>
    </a-drawer>
</template>

<style scoped lang="less">
.confirmation {
    line-height: 1;

    .btn-small {
        min-width: 13px;
        width: 13px;
        height: 13px;
        font-size: 8px;
        line-height: 1;
        vertical-align: bottom;
    }
}

.amount {
    &-total {
        font-weight: @token-font-weight-bold;
    }

    &-item {
        margin-left: 1em;
        line-height: 17px;
    }
}

:deep(.ant-descriptions-item-label) {
    position: relative;

    .total-amount {
        position: absolute;
        top: 8px;
        left: 16px;
    }
}

:deep(.ant-descriptions-item-content) {
    white-space: pre-line;
}

:deep(.ant-drawer-header) {
    border: none;
}

:deep(.ant-descriptions-item-label) {
    width: 130px;
}

.change-request {
    background-color: @token-accent-color-warning;
    display: inline-block;
    margin: 2px 0;
    font-weight: @token-font-weight-bold;
}

.settlement-changed-alert {
    margin-bottom: 24px;
}

.changed-notification-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
}

.changed-notification-icon {
    color: @color-neutral-13;
    font-size: 24px;
}

.editable-area {
    float: right;
    margin-bottom: 12px;
}
</style>
