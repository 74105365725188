<script setup lang="ts">
import dayjs, { Dayjs } from 'dayjs';
import { computed, ref } from 'vue';
import { injectStrictly } from '@/util/vue';
import { TIMELINE_SCHEDULER_PROVIDER_KEY } from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';


const {
    state: {
        timelineCurrentPosition,
        timelineViewMinWidth,
        timelineVerticalScrollBarWidth,
        dateList,
        slotList,
        slotCountInDate,
        eventBus,
    },
} = injectStrictly(TIMELINE_SCHEDULER_PROVIDER_KEY);

const headerDateFormat = (date: Dayjs): string => {
    return date.format('D(dd)');
};

const headerTimeFormat = (date: Dayjs): string => {
    return date.format('HH:mm');
};

const currentTimeText = computed<string>(() => {
    return dayjs().format('h:mm A');
});

const currentTimeStyle = computed(() => {
    return { left: `${ timelineCurrentPosition.value }px` };
});

// ScheduleTableの水平スクロールイベントを受け取り、DateHeaderの水平スクロール量を同期する
const wrapper = ref<HTMLElement>();
eventBus.on('HorizontalScrolled', (value: number) => {
    wrapper.value?.scrollTo(value, 0);
});

// 日付ヘッダーの幅について
//  DateHeaderのテーブル    <------------------- timeline表示幅 --------------><-scrollWidth->
//                        <--slot--><--slot--><--slot--><--slot--><--slot-->
//  ScheduleTableのテーブル <------------------- timeline表示幅 --------------><--scrollBar-->
//                        <--slot--><--slot--><--slot--><--slot--><--slot-->
//
// ２つのテーブルが独立しているが、Slotの幅を揃えることで縦の列が揃うように設計されている。
// ScheduleTable側は下にスクロールする可能性があるため、スクロールバーが表示されてる可能性がある。
// ScrollBarの幅を含めてDateHeaderの幅を表示しないと、２つのテーブルの間でSlotの幅に差が発生する。

const headerMinWidth = computed(() => {
    return `${ timelineViewMinWidth + timelineVerticalScrollBarWidth.value }px`;
});

const showScrollBarPadding = computed(() => {
    return timelineVerticalScrollBarWidth.value > 0;
});

const scrollBarPaddingStyle = computed(() => {
    return { width: `${ timelineVerticalScrollBarWidth.value }px` };
});
</script>

<template>
    <div ref="wrapper" class="date-header-wrap">
        <table ref="table" class="schedule-table" :style="{ minWidth: headerMinWidth }">
            <tbody>
            <tr>
                <td v-for="item in dateList" :key="item.toString()" :colspan="slotCountInDate" class="header-cells"
                    style="min-width: 200px;">
                    <div class="date-text">{{ headerDateFormat(item) }}</div>
                </td>
                <td v-if="showScrollBarPadding" :style="scrollBarPaddingStyle"></td>
            </tr>
            <tr>
                <td v-for="item in slotList" :key="item.date.toString()" class="time-slots"
                    :class="{ 'time-cells': !item.isDuration }">
                    <div v-if="item.isDuration">{{ headerTimeFormat(item.date) }}</div>
                </td>
                <td v-if="showScrollBarPadding"></td>
            </tr>
            </tbody>
        </table>
        <span v-if=timelineCurrentPosition class="current-time" :style="currentTimeStyle">{{ currentTimeText }}</span>
    </div>
</template>

<style scoped lang="less">
.date-header-wrap {
    background: #fff;
    font-size: 13px;
    overflow: hidden;
    position: relative;
    border-color: rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0;

    .schedule-table {
        border: 0 none;
        border-collapse: separate;
        border-spacing: 0;
        margin: 0;
        table-layout: fixed;
        width: 100%;
    }

    .current-time {
        color: #e3165b;
        font-size: 11px;
        bottom: 0;
        position: absolute;
    }

    .time-slot {
        min-width: 50px;
    }

    .time-cel {
        border-left-width: 0;
    }

    table tbody td {
        height: 36px;
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-color: rgba(0, 0, 0, 0.12);
        border-style: solid;
        border-width: 0 0 1px 1px;
        color: rgba(0, 0, 0, 0.87);
    }
}
</style>
