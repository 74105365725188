<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import AccountList from '@/_components/account/AccountList.vue';
import { useSettingAccountListHelper } from '@/_pages/Setting/Account/List/setting-account-list-helper';

const {
    state: {
        myProfile,
    },
    visibleAccounts,
    onClickCreate,
    onClickEdit,
    onClickDelete,
} = useSettingAccountListHelper();
</script>

<template>
    <page-layout :back-icon="false">
        <template #extra>
            <a-button type="primary" icon="plus" @click="onClickCreate">ユーザー追加</a-button>
        </template>
        <div class="board-container">
            <a-card>
                <account-list :me="myProfile"
                              :account-list="visibleAccounts"
                              @clickEdit="onClickEdit"
                              @clickDelete="onClickDelete"></account-list>
            </a-card>
        </div>
    </page-layout>

</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}
</style>
