import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { DateUtil } from '@/util';
import { Const } from '@/const';

@Component
export default class AgreementPaymentDate extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get paymentDate(): string {
        if (!this.baggage || !this.baggage.paymentDate) return '';
        return DateUtil.parseDateText(this.baggage.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }
}
