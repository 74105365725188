import { computed, onUnmounted, ref } from 'vue';
import { CompanyProfile, CompanyProfileExtraUpdateForm } from '@/models/company';
import _ from 'lodash';
import { companyApi } from '@/repository/api/internal/company';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { FormValidateUtil } from '@/models/validate-helper';

/**
 * 企業詳細情報を更新する機能を提供します。
 */
export const useCompanyProfileExtraUpdate = () => {
    const { load } = useCompanyMyProfile();
    const message = useMessage();
    const notification = useNotification();
    const { state: { loading }, withLoading } = useLoading();

    const form = ref<CompanyProfileExtraUpdateForm>(new CompanyProfileExtraUpdateForm({
        description: undefined,
        representativeName: undefined,
        establishmentDate: undefined,
        capital: undefined,
        employeesNumber: undefined,
        officeAddress: undefined,
        sales: undefined,
        bank: undefined,
        customer: undefined,
        salesArea: undefined,
        cutOffDay: undefined,
        paymentMonth: undefined,
        paymentDay: undefined,
    }));

    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const initialize = (profile: CompanyProfile): void => {
        form.value = _.merge(form.value, {
            description: profile.description,
            representativeName: profile.representativeName,
            establishmentDate: profile.establishmentDate,
            capital: profile.capital,
            employeesNumber: profile.employeesNumber,
            officeAddress: profile.officeAddress,
            sales: profile.sales,
            bank: profile.bank,
            customer: profile.customer,
            salesArea: profile.salesArea,
            cutOffDay: profile.cutOffDay,
            paymentMonth: profile.paymentMonth,
            paymentDay: profile.paymentDay,
        });
    };

    const update = async () => {
        try {
            await withLoading(async () => {
                await companyApi.updateProfileExtra(form.value);
                await load();
            });
            message.success('企業情報を更新しました。');
        } catch {
            notification.error({
                key: 'UPDATE_COMPANY_PROFILE_FAILED',
                message: '企業情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    };

    onUnmounted(() => {
        notification.close('UPDATE_COMPANY_PROFILE_FAILED');
    });

    return {
        state: {
            form,
            formValidateRules,
            loading,
        },
        initialize,
        update,
    };
};
