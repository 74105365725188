import { ref } from 'vue';
import { AgreementListModel } from '@/models/agreement';
import { agreementApi } from '@/repository/api/internal/agreement';
import { useLoading } from '@/composables/helper/loading-helper';

const PAGE_SIZE = 5;
const SORT_KEY = 'ID';
const SORT_ORDER = 'DESC';

const defaultData = () => ({
    pageSize: 0,
    totalRecordCount: 0,
    totalPageCount: 0,
    currentPageNumber: 1,
    data: [],
});

/**
 * 月次成約をロードする機能を提供します。
 */
export const useAgreementMonthlyList = () => {
    const list = ref<AgreementListModel>(defaultData());

    const { state: { loading }, withLoading } = useLoading();

    const load = async (year: number, month: number, pageNo: number = 1) => withLoading(async () => {
        list.value = await agreementApi.listAcceptedMonthly(year, month, {
            pageNo,
            pageSize: PAGE_SIZE,
            sortKey: SORT_KEY,
            sortOrder: SORT_ORDER
        }).then(result => new AgreementListModel(result));
    });

    const clear = () => {
        list.value = defaultData();
    };

    return {
        state: {
            loading,
            list,
        },
        load,
        clear,
    };
};
