import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
// @ts-ignore
import UpgradePlan from '@/components/UpgradePlan/View';
// @ts-ignore
import UpgradePlanConfirmModalContent from '@/components/UpgradePlan/UpgradePlanConfirmModalContent';
import store from '@/vuex/store';
import { BillingCycleEnum, InvoiceEnum } from '@/components/UpgradePlan/View/enums';
import * as accountTypes from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { Modal } from 'ant-design-vue';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: 'プレミアムプランのお申し込み',
    components: {
        UpgradePlan,
        UpgradePlanConfirmModalContent,
    },
    beforeRouteEnter: UpgradePlanPage.beforeRouteEnter,
})
export default class UpgradePlanPage extends PageVue {
    // ======================================================
    // Properties
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Data
    // ======================================================
    isSubmitting = false;

    form: companyTypes.CompanyPremiumRegisterForm = {
        plan: { code: BillingCycleEnum.Monthly.code },
        invoice: { code: InvoiceEnum.Free.code },
    };

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 戻るボタンが押下された際に呼び出されます。
     */
    onClickBack(): void {
        this.$router.back();
    }

    /**
     * プレミアムプラン申し込みをハンドリングします
     */
    async onSubmit(): Promise<void> {
        const notifyFailedKey = 'REGISTER_PREMIUM_FAILED';

        const onSuccess = async () => {
            await this.$router.push({ name: 'UpgradePlanComplete' });
        };
        const onError = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: 'プレミアムプランのお申し込みができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        // 確認モーダルの表示
        if (!await this.confirmUpgrade()) {
            return;
        }

        this.isSubmitting = true;

        await UpgradePlanPage.registerPremium(this.form).then(onSuccess).catch(onError);

        this.isSubmitting = false;
    }

    /**
     * プレミアムプラン申し込み確認モーダルを表示します。
     */
    private confirmUpgrade(): Promise<boolean> {
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: 'この内容でプレミアムプランの申し込みを確定します。よろしいですか？',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            content: (h: Function) => h(UpgradePlanConfirmModalContent, { props: { form: this.form } }),
            okText: '申し込む',
            cancelText: 'キャンセル',
            autoFocusButton: 'cancel',
            centered: true,
        }));
    }

    /**
     * プレミアムプランに申し込みます。
     */
    private static registerPremium(param: companyTypes.CompanyPremiumRegisterForm): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.REGISTER_PREMIUM}`, param);
    }

    /**
     * 契約一覧をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<UpgradePlanPage>
    ): Promise<void> {
        await UpgradePlanPage.loadContractList().catch(() => next({ name: 'NotFound' }));
        next();
    }
}
