import { GuaranteePriceMaster } from '@/vuex/modules/guarantee/types';
import { CompanyContractModel } from '@/models/company-contract';
import { ProductUtil } from '@/util/product';

export class GuaranteeUtil {
    /**
     * 運賃保証サービス料を計算します。
     * - 運賃保証が月払い契約の場合は、月額の基本金額を加えた額をサービス料として計算する
     * - 運賃保証が6か月または12か月払い契約の場合は、基本金額を除いた額をサービス料として計算する
     *
     * @return 運賃保証サービス料
     */
    static calculateGuaranteePrice(
        amount: number,
        master: GuaranteePriceMaster[],
        contracts: CompanyContractModel[]
    ): number {
        // 基本料金の計算
        // TODO 料金をサーバから取得した方がよいかもしれない
        const basePrice = contracts.some((contract) => contract.product.code === ProductUtil.FreightProtection1m.code)
            ? 5000
            : 0;
        // 基本料金を超える部分の金額
        const payPerUsePrice = master.find((master) => master.min <= amount && master.max >= amount)?.price ?? 0;
        return basePrice + payPerUsePrice;
    }
}
