<script setup lang="ts">
// Components
import CompanyNotice from './CompanyNotice.vue';
// 何故かTraboxNoticeだけunusedに引っかかるのでdisable
// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import TraboxNotice from './TraboxNotice.vue';
import UserAccountMenu from './UserAccountMenu.vue';
import TrialNotificationBar from './TrialNotificationBar.vue';
import GlobalStatisticsButton from './GlobalStatisticsButton.vue';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useEntitlement } from '@/composables/entitlement';
import { computed } from 'vue';
import { DateTimeValue } from '@/models/vo/datetime';

const { state: { isJudging } } = useCompanyMyStatus();
const { state: { entitlement } } = useEntitlement();

const now = computed(() => DateTimeValue.now());

/**
 * 予約中ロックを取得する。
 */
const reservedLock = computed(() => entitlement.value?.reservedLock(now.value));

/**
 * 稼働中ロックを取得する。
 */
const activeLock = computed(() => entitlement.value?.activeLock(now.value));

</script>

<template>
    <a-layout-header class="header">
        <div class="header__notification">
            <a-alert type="warning" v-if="reservedLock">
                <template #message>
                    <div class="reserved-lock-container">
                        <span v-if="reservedLock.lockType.code === 'General'">トラボックスから貴社へ連絡が取れないため、<strong><span class="lock-datetime">{{ reservedLock.lockTm.format('M月DD日 H:mm') }}</span>に利用を制限させていただきます。</strong></span>
                        <span v-if="reservedLock.lockType.code === 'LatePayment'">トラボックスご利用料金未納のため、このまま入金の確認が取れなかった場合は<strong><span class="lock-datetime">{{ reservedLock.lockTm.format('M月DD日 H:mm') }}</span>に利用を制限させていただきます。</strong></span>
                    </div>
                </template>
            </a-alert>
            <a-alert type="warning" v-if="activeLock">
                <template #message>
                    <div class="active-lock-container">
                        <span v-if="activeLock.lockType.code === 'General'">【ご連絡お願いします】トラボックスから貴社へ連絡が取れないため、利用を制限しております。</span>
                        <span v-if="activeLock.lockType.code === 'LatePayment'">【ご対応お願いします】トラボックスご利用料金未納のため、利用を制限しております。</span>
                    </div>
                </template>
            </a-alert>
            <a-alert type="warning" v-if="isJudging">
                <template #message>
                    <div class="judging-notification-message">
                        ただいま審査中です。必要書類をご提出いただいた後、審査を経てお試し利用開始となります。前の画面に戻るには
                        <router-link :to="{ name: 'Judging' }" class="component-text-link-inside">こちら</router-link>
                        。
                    </div>
                </template>
            </a-alert>
            <trial-notification-bar></trial-notification-bar>
        </div>
        <div class="header-content">
            <router-link :to="{ name: 'Index' }" class="header-content-logo">
                <img src="/img/logo-simple.svg" alt="トラボックス"/>
            </router-link>

            <global-statistics-button class="header-statistics"></global-statistics-button>

            <company-notice class="header-company-notification"></company-notice>

            <div class="header-nav">
                <trabox-notice class="header-nav__item"></trabox-notice>
                <user-account-menu class="header-nav__item"></user-account-menu>
            </div>
        </div>
    </a-layout-header>
</template>

<style scoped lang="less">
@header-height: 64px;

.header {
    width: 100%;
    padding: 0;
    border-bottom: solid 1px @border-color-split;
    min-height: @header-height;
    height: auto !important; // overwrite ant-design style rule
    line-height: normal;
    background-color: @component-background;

    .header-content {
        display: flex;
        align-items: center;
        height: 64px;
        padding-left: 24px;
        padding-right: 24px;
        user-select: none;

        .header-content-logo > img {
            width: 167px;
            height: 20px;
        }

        .header-statistics {
            margin-left: 32px;
        }

        .header-company-notification {
            margin-left: 32px;
        }

        .header-nav {
            margin-left: auto;
            display: flex;
        }
    }
}

.reserved-lock-container, .active-lock-container {
    text-align: center;

    .lock-datetime {
        white-space: nowrap;
    }

    strong {
        color: @color-red;
        font-weight: 700;
    }
}

.judging-notification-message {
    color: @ui-color-text;
    text-align: center;
}
</style>
