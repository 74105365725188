import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const predictionApi = {
    /**
     * 荷物の成約予測を取得します。
     */
    baggageAgreement(param: PredictionBaggageAgreementForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/prediction/baggage/agreement', param).then(isSuccess);
    },
};
