import { appAxios } from './base';

export const microCmsApi = {
    /**
     * microCMSからコンテンツを取得します。
     */
    contents(name: string, draftKey?: string): Promise<Record<any, any> | undefined> {
        return appAxios
            .get<Record<any, any>>(`/api/microcms/contents/${ name }`, { params: { draftKey: draftKey } })
            .then((result) => result.data)
            .catch(() => undefined);
    },
};
