import { Component, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: { TbxLinkText }
})
export default class ChangeLimitAlert extends Vue {
    get url(): string {
        return Const.externalPageUrl.howToChangeAgreement;
    }
}
