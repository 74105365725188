import { TbxRouteConfig } from '@/router/routes/types';
import DeliveryOrderPreviewPage from '@/_pages/DeliveryOrder/Preview/DeliveryOrderPreview.vue';
import DeliveryOrderPrintPage from '@/_pages/DeliveryOrder/Print/DeliveryOrderPrint.vue';
import DeliveryOrderEditPage from '@/_pages/DeliveryOrder/Edit/DeliveryOrderEdit.vue';
import DeliveryOrderReplyPage from '@/_pages/DeliveryOrder/Reply/DeliveryOrderReply.vue';
import { Route } from 'vue-router/types/router';

export const deliveryOrderRoutes: TbxRouteConfig[] = [
    {
        path: '/agreement/:agreementId/delivery-order',
        name: 'DeliveryOrderPreview',
        component: DeliveryOrderPreviewPage,
        meta: { title: '配送依頼書', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ agreementId: Number(route.params.agreemetnId) }),
    },
    {
        path: '/delivery-order/:deliveryOrderId/print',
        name: 'DeliveryOrderPrint',
        component: DeliveryOrderPrintPage,
        meta: { title: '配送依頼書印刷', layout: { name: 'main', contentOnly: true, noFooter: true } },
        props: (route: Route) => ({ deliveryOrderId: Number(route.params.deliveryOrderId) }),
    },
    {
        path: '/delivery-order/:deliveryOrderId/edit',
        name: 'DeliveryOrderEdit',
        component: DeliveryOrderEditPage,
        meta: { title: '配送依頼書編集', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ deliveryOrderId: Number(route.params.deliveryOrderId) }),
    },
    {
        path: '/delivery-order/:deliveryOrderId/reply',
        name: 'DeliveryOrderReply',
        component: DeliveryOrderReplyPage,
        meta: { title: '車番返信', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ deliveryOrderId: Number(route.params.deliveryOrderId) }),
    },
];
