import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { Enum } from '@/types/enum';
import _ from 'lodash';

type ITEM = { label: string, value: string };

@Component
export default class AgreementSpecifiedTruckRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;

    /**
     * 床高
     */
    get truckHeight(): Enum | undefined {
        return this.baggage?.truckHeight;
    }

    /**
     * 車幅
     */
    get truckWidth(): Enum | undefined {
        return this.baggage?.truckWidth;
    }

    /**
     * 大型可否
     */
    get largeTruckFlg(): boolean | undefined {
        return this.baggage?.largeTruckFlg;
    }

    get hasSpecifiedTruck(): boolean {
        return [this.truckHeight, this.truckWidth, this.largeTruckFlg].some(each => !_.isNil(each));
    }

    get items(): ITEM[] {
        const items: ITEM[] = [];
        if (this.truckHeight) {
            items.push({ label: '床高', value: this.truckHeight.label ?? '' });
        }
        if (this.truckWidth) {
            items.push({ label: '車幅', value: this.truckWidth.label ?? '' });
        }
        if (!_.isNil(this.largeTruckFlg)) {
            items.push({ label: '大型可否', value: this.largeTruckFlg ? '可' : '不可' });
        }
        return items;
    }
}
