import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useCompanyMyConfidence } from '@/composables/global/company-my-confidence';
import { useCompanyMyStatistics } from '@/composables/global/company-my-statistics';
import { useLoading } from '@/composables/helper/loading-helper';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { useOfficialCompany } from '@/composables/company-official';
import { Util } from '@/util';

export const useCompanyPrintHelper = () => {
    const { currentRoute } = useRoute();
    const { goToNotFound } = useRouting();
    const { state: { loading }, withLoading } = useLoading();

    const { load: loadMyProfile, state: { myProfile } } = useCompanyMyProfile();
    const { load: loadProfile, state: { profile } } = useCompanyProfile();
    const { load: loadOfficialCompany, state: { officialCompany } } = useOfficialCompany();
    const { load: loadMyConfidence, state: { myConfidence } } = useCompanyMyConfidence();
    const { load: loadConfidence, state: { confidence } } = useCompanyConfidence();
    const { load: loadMyStatistics, state: { myStatistics } } = useCompanyMyStatistics();
    const { load: loadStatistics, state: { statistics } } = useCompanyStatistics();

    const id = ref<number>();

    const companyProfile = computed(() => profile.value ?? myProfile.value);
    const companyConfidence = computed(() => confidence.value ?? myConfidence.value);
    const companyStatistics = computed(() => statistics.value ?? myStatistics.value);

    const onClickPrint = () => window.print();
    const onClickClose = () => window.close();

    const initialize = () => withLoading(async () => {
        if (_.isNil(id.value)) {
            await Promise.all([loadMyProfile(), loadMyConfidence(), loadMyStatistics()]);
            await loadOfficialCompany(Util.requireNotNull(myProfile.value?.id)).catch(() => false);
        } else {
            await Promise.all([
                loadProfile(id.value),
                loadOfficialCompany(id.value).catch(() => false),
                loadConfidence(id.value),
                loadStatistics(id.value)
            ]);
        }
    });

    onMounted(async () => {
        const { companyId } = currentRoute.params;

        id.value = _.isNil(companyId) ? undefined : Number(companyId);

        await initialize().catch(goToNotFound);
    });

    return {
        loading,
        companyProfile,
        officialCompany,
        companyConfidence,
        companyStatistics,
        onClickPrint,
        onClickClose,
    };
};
