import { accountApi } from '@/repository/api/api';
import store from '@/vuex/store';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as environmentTypes from '@/vuex/modules/environment/types';
import { AgreementModel, AgreementOnlineOrderAvailability } from '@/vuex/modules/agreement/types';
import { Notification } from 'ant-design-vue/types/notification';
import { Const } from '@/const';

export type OpenOnlineOrderError = 'BAGGAGE_COMPANY_UNAVAILABLE' | 'TRUCK_COMPANY_UNAVAILABLE' | 'BOTH_COMPANY_UNAVAILABLE' | 'NOT_EXISTS_ONLINE_ORDER_DIRECTION' | 'UNKNOWN_ERROR';

export class OnlineOrderService {
    static sso(agreementId: number): Promise<void> {
        return accountApi.issueSsoOnlineOrderToken()
            .then((token) => `${this.environment.traboxOnlineOrderUrl}/#/online_order/new?sso_token=${token}&agreement_id=${agreementId}`)
            .then((url) => { window.open(url, '_blank'); });
    }

    static openWithToken(url: string): Promise<void> {
        return accountApi.issueSsoOnlineOrderToken()
            .then((token) => `${url}&sso_token=${token}`)
            .then((url) => { window.open(url, '_blank'); });
    }

    private static get environment(): environmentTypes.Environment {
        return store.getters[`environment/${environmentTypes.GETTER.ENVIRONMENT}`] as environmentTypes.Environment;
    }

    /**
     * 受発注依頼書へのリンクを表示するか否かを取得する。
     *
     * 受発注サービスの存在を広く知ってもらうため、依頼書を利用できないケースでもリンクを表示します。
     * よって、実際にリンクできるか否か[openOnlineOrder]とは判定基準が異なります。
     */
    static shouldShowLinkToOnlineOrder(
        agreement: agreementTypes.Agreement | AgreementModel,
        profile: companyTypes.CompanyProfile,
        existsOnlineOrderDirection: boolean
    ): boolean {
        if (profile.id === agreement.baggageCompanyId) {
            // 荷主企業の場合
            return true;
        } else if (profile.id === agreement.truckCompanyId) {
            // 運送企業の場合は依頼書の有無で判定する
            return existsOnlineOrderDirection;
        }
        return false;
    }

    /**
     * トラボックス受発注を開く。
     */
    static async openOnlineOrder(
        agreement: agreementTypes.Agreement | AgreementModel,
        profile: companyTypes.CompanyProfile,
        existsOnlineOrderDirection: boolean,
        onlineOrderAvailability: AgreementOnlineOrderAvailability
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // 依頼書サービスを利用可能であること
            if (!onlineOrderAvailability.baggageCompany && onlineOrderAvailability.truckCompany) {
                reject('BAGGAGE_COMPANY_UNAVAILABLE');
                return;
            } else if (onlineOrderAvailability.baggageCompany && !onlineOrderAvailability.truckCompany) {
                reject('TRUCK_COMPANY_UNAVAILABLE');
                return;
            } else if (!onlineOrderAvailability.baggageCompany && !onlineOrderAvailability.truckCompany) {
                reject('BOTH_COMPANY_UNAVAILABLE');
                return;
            }

            if (profile.id === agreement.baggageCompanyId) {
                // 荷主企業の場合は常に許可
                resolve();
            } else if (profile.id === agreement.truckCompanyId) {
                // 運送企業の場合は依頼書の有無で判定
                if (existsOnlineOrderDirection) {
                    resolve();
                } else {
                    reject('NOT_EXISTS_ONLINE_ORDER_DIRECTION');
                }
            } else {
                reject('UNKNOWN_ERROR');
            }
        })
            .then(() => OnlineOrderService.sso(agreement.id));
    }

    static handleOpenOnlineOrderError(
        error: OpenOnlineOrderError,
        notification: Notification
    ): void {
        //
        // お知らせの表示
        //
        const showNotification = (builder: Function) => {
            const inquiryLabel = 'お問い合わせフォーム';
            const inquiryAttr = {
                href: Const.externalPageUrl.inquiryForm,
                target: '_blank'
            };
            // 同じイベントループで通知を連続すると通知表示が重なってしまうので、通知ごとにイベントループをずらす
            setTimeout(() =>
                notification.error({
                    message: '依頼書を作成できませんでした。',
                    // @ts-ignore
                    description: (h) => h('div', [
                        h('div', builder(h)),
                        h('div', [`TEL：${ Const.supportDeskContacts.phone }`]),
                        h('div', ['（受付時間 平日 09:00〜17:00）']),
                        h('a', { attrs: inquiryAttr }, [inquiryLabel])
                    ])
                }), 0);
        };

        // 自社(荷主企業)起因で受発注サービスを利用できない場合
        const showNotificationForBaggageCompany = () => {
            showNotification((h: Function) => [
                '依頼をWEBで作成するためには別途審査が必要です。',
                h('br'),
                'お手数ですが、お客様相談室へお問合せください。',
                h('br'),
                'お急ぎの場合はこれまで通り、FAX等での作成をお願いいたします。',
            ]);
        };

        // 相手(運送企業)起因で受発注サービスを利用できない場合
        const showNotificationForTruckCompany = () => {
            showNotification((h: Function) => [
                '成約先企業様の依頼書を送るための審査が完了していないため依頼書を作成できませんでした。',
                h('br'),
                'お手数ですが、お客様相談室へお問合せください。',
                h('br'),
                'お急ぎの場合はこれまで通り、FAX等での作成をお願いいたします。',
            ]);
        };

        switch (error) {
            // 自社起因
            case 'BAGGAGE_COMPANY_UNAVAILABLE':
                showNotificationForBaggageCompany();
                break;
            // 相手起因
            case 'TRUCK_COMPANY_UNAVAILABLE':
                showNotificationForTruckCompany();
                break;
            // 双方起因
            case 'BOTH_COMPANY_UNAVAILABLE':
                showNotificationForBaggageCompany();
                showNotificationForTruckCompany();
                break;
            case 'NOT_EXISTS_ONLINE_ORDER_DIRECTION':
            case 'UNKNOWN_ERROR':
                // これらのケースの場合リンクを開く方法をユーザに提供しないので、ここへ到達しない
                break;
        }
    }
}
