<script setup lang="ts">
import EmptyTag from '@/_components/EmptyTag';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { Util } from '@/util';
import { computed } from 'vue';
import _ from 'lodash';

const props = defineProps<{
    freight?: number | undefined | BaggageFreightValue | null
}>();

const freightValue = computed<number | undefined>(() => {
    if (_.isNil(props.freight)) {
        return undefined;
    }
    if (typeof props.freight === 'number') {
        return props.freight;
    }
    return props.freight.value;
});

const text = computed<string>(() => {
    if (!freightValue.value) return '要相談';
    return `${ Util.formatNumber(freightValue.value) }円`;
});
</script>

<template>
    <empty-tag>{{ text }}</empty-tag>
</template>

<style scoped lang="less">

</style>
