<script setup lang="ts">
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { computed, ref, watch } from 'vue';
import { useCityData } from '@/composables/city';

const props = withDefaults(defineProps<{
    value?: string,
    prefCode?: PrefectureEnumCode,
    placeholder?: string,
    trimOnBlur?: boolean,
}>(), {
    placeholder: '市区町村',
    // TODO: 利用箇所を修正してデフォルト値をtrueにする
    trimOnBlur: false,
});
const emits = defineEmits<{
    (e: 'input', value: string): void,
    (e: 'change', value: string): void,
    (e: 'blur'): void,
}>();
const { state: { cityData }, load: loadCityData, clear: clearCityData } = useCityData();
const dataSource = ref<{ value: string; text: string }[]>([]);
const prevPrefCode = ref<PrefectureEnumCode | ''>('');

/**
 * 市区町村名テキスト
 */
const city = computed<string>({
    get: () => props.value ?? '',
    set: (value: string): void => {
        const result = cityData.value.find((each) => each.code === value);
        emits('input', result?.name ?? value);
    },
});
watch(city, (value) => emits('change', value));

/**
 * オートコンプリートのサジェストに必要なデータソースを設定します。
 * @param value
 */
const setDataSource = (value?: string): void => {
    if (value === undefined) {
        dataSource.value = [];
        return;
    }
    dataSource.value = cityData.value
        .filter((each) => each.name.indexOf(value) >= 0)
        .map((each) => ({ value: each.code, text: each.name }));
};

/**
 * 入力フィールドをfocusした際に呼び出されます。
 */
const onFocusInput = async (): Promise<void> => {
    if (!props.prefCode) {
        setDataSource();
        clearCityData();
        prevPrefCode.value = '';
        return;
    }
    if (prevPrefCode.value !== props.prefCode) {
        setDataSource();
        await loadCityData(props.prefCode);
        prevPrefCode.value = props.prefCode;
    }
    setDataSource(city.value);
};

/**
 * 入力フィールドに文字を入力すると呼び出されます。
 * @param value
 */
const onSearchCity = async (value: string): Promise<void> => {
    setDataSource(value);
};

/**
 * Blur時に呼び出されます。
 */
const onBlur = (): void => {
    if (props.trimOnBlur) {
        city.value = city.value.trim();
    }
    emits('blur');
};
</script>

<template>
    <a-auto-complete v-model="city"
                     :data-source="dataSource"
                     :placeholder="placeholder"
                     @focus="onFocusInput"
                     @blur="onBlur"
                     @search="onSearchCity"
                     class="ui-city-input">
    </a-auto-complete>
</template>

<style scoped lang="less">

</style>
