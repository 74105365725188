<script setup lang="ts">
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { computed, nextTick, onMounted, ref } from 'vue';
import _ from 'lodash';
import { RegionEnum } from '@/enums/region.enum';
import { RegionUtil } from '@/util';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import JapanMap from '@/_components/ui/map/JapanMap.vue';

const props = withDefaults(defineProps<{
    value?: PrefectureEnumCode | any, // PrefectureEnumCode[]/Array<PrefectureEnumCode>を指定すると実行時エラーになるので仕方なくany
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    multiple?: boolean, // 複数選択を許容するか否か
    allowClear?: boolean, // 選択解除を許容するか否か（単一選択モードでのみ機能します）
    ellipsis?: boolean, // タグが1行に収まらない場合は「・・・」の省略記号を表示するか否か（複数選択モードでのみ機能します）
    placeholder?: string, // select メニューのプレースホルダ
    placeholderIcon?: boolean, // メニューの前にプレースホルダアイコンを表示するか否か
    title?: string, // 都道府県マップに表示するタイトルテキスト
    placement?: string; // ドロップダウン内のコンテンツ（＝日本地図）を表示する位置
}>(), {
    size: 'default',
    multiple: true,
    allowClear: false,
    ellipsis: true,
    placeholder: '都道府県',
    placeholderIcon: false,
    title: '',
    placement: 'bottomCenter',
});
const emits = defineEmits<{
    (e: 'input', value: PrefectureEnumCode | PrefectureEnumCode[] | undefined): void,
    (e: 'change', value: PrefectureEnumCode | PrefectureEnumCode[] | undefined): void,
}>();

const isMenuOpen = ref<boolean>(false); // ドロップダウンメニューの開閉状態

/**
 * 都道府県の値
 * propsで受ける `value` は PrefectureEnumCode or Array<PrefectureEnumCode> となるため、
 * Component 内部では Array<PrefectureEnumCode> として扱えるようにする
 */
const prefectureValues = computed<PrefectureEnumCode[]>({
    get: () => {
        if (!props.value) {
            return [];
        }
        if (props.multiple && _.isArray(props.value)) {
            return props.value;
        }
        if (_.isString(props.value)) {
            // 文字列でバインドされた場合、内部では配列として扱う
            return [props.value];
        }
        return [];
    },
    set: (value: PrefectureEnumCode[]): void => {
        emits('input', props.multiple ? _.cloneDeep(value) : value[0]);
        emits('change', props.multiple ? _.cloneDeep(value) : value[0]);
        if (!props.multiple) {
            closeDropdown();
        }
    },
});

/**
 * プルダウンメニューの表示用ラベルの値
 * 地域名または都道府県名を返します。
 */
const labels = computed<Array<RegionEnum | PrefectureEnum>>({
    get: () => RegionUtil.parseRegionsFromPrefectures(prefectureValues.value),
    set: (values: Array<RegionEnum | PrefectureEnum>): void => {
        const selectedPrefectures = values.flatMap((v) => {
            if (v instanceof RegionEnum) {
                return v.prefectures;
            }
            return v.code;
        });
        emits('input', _.uniq(selectedPrefectures));
        emits('change', _.uniq(selectedPrefectures));
    }
});

/**
 * ドロップダウンメニューの表示用ラベル（単一選択時）
 */
const labelForSingleChoice = computed<string>(() =>
    labels.value.map(each => each.label).join('、')
);

type StyleClass = { [key: string]: boolean };
/**
 * プルダウンメニュー（ant-select）に付与するstyleClassを取得します。
 */
const selectStyleClass = computed<StyleClass>(() => {
    return {
        'ant-select-lg': props.size === 'large',
        'ant-select-sm': props.size === 'small',
        'ant-select-default': props.size === 'default',
        'ant-select-open': isMenuOpen.value,
    };
});

/**
 * 選択した項目（都道府県）を取り除きます。
 */
const removeItem = (event: Event, value: RegionEnum | PrefectureEnum): void => {
    event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    prefectureValues.value = _.difference(
        prefectureValues.value,
        value instanceof RegionEnum ? value.prefectures : [value.code]
    );
};
const tags = ref<Element | undefined>();

/**
 * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
 */
const getOverflowIndex = (index: number): number => {
    if (index < 0) {
        return -1;
    }
    const tagsElement = tags.value;
    if (!tagsElement) {
        return -1;
    }
    const tagBaseWidth = props.size === 'large' ? 36 : 32;
    const tagSpacing = 4;
    const dropdownIconWidth = 16;
    const widthByLabels = labels.value.map((each) => {
        const label = each instanceof PrefectureEnum ? each.shortLabel : each.label;
        return tagSpacing + tagBaseWidth + label.length * 14;
    });
    return widthByLabels.findIndex(
        (_each, labelIndex, array) =>
            _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
    );
};

/**
 * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
 */
const isOverflowTag = (index: number): boolean => {
    if (!props.ellipsis) {
        return false;
    }
    const overflowIndex = getOverflowIndex(index);
    return overflowIndex > 0 && index >= overflowIndex;
};

/**
 * ドロップダウンメニューを閉じます。
 */
const closeDropdown = (): void => {
    isMenuOpen.value = false;
};

/**
 * 都道府県ラベルをクリックすると呼び出されます。
 */
const onClickChoiceContent = (e: Event): void => {
    // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    e.stopPropagation();
};

/**
 * 都道府県選択状態でフォーム内右寄りの閉じるボタン（バツ印）をクリックすると呼び出されます。
 */
const onClickClear = (e: Event): void => {
    emits('input', undefined);
    emits('change', undefined);
    e.stopPropagation();
};
// created相当
if (props.multiple && props.value && !_.isArray(props.value)) {
    throw new Error('v-model(value) must be array object when multiple selection mode.');
}
if (!props.multiple && props.value && !_.isString(props.value)) {
    throw new Error('v-model(value) must be string when single selection mode.');
}
onMounted(() => {
    // workaround for https://github.com/trabox-inc/ultrabox/issues/5152
    // #5126 で恒久対応する
    if (props.multiple) {
        nextTick(() => {
            prefectureValues.value = _.cloneDeep(prefectureValues.value);
        });
    }
});
</script>

<template>
    <a-dropdown class="ui-prefecture-select" v-model="isMenuOpen" :trigger="['click']" :placement="placement">
        <div class="ant-select ant-select-enabled ant-select-allow-clear" :class="selectStyleClass">
            <!-- icon -->
            <tbx-icon v-if="placeholderIcon" type="prefecture-select" class="ui-prefecture-select__placeholder-icon" />
            <div class="ant-select-selection"
                 :class="{
                'ant-select-selection--single': !multiple,
                'ant-select-selection--multiple': multiple,
                'ant-select-selection--placeholder-icon': placeholderIcon,
             }">
                <div class="ant-select-selection__rendered" ref="tags">
                    <!-- placeholder -->
                    <div class="ant-select-selection__placeholder"
                         :style="{ display: prefectureValues.length > 0 ? 'none' : 'block' }"
                         style="user-select: none;">{{ placeholder }}</div>
                    <!-- value for single -->
                    <div v-if="!multiple"
                         class="ant-select-selection-selected-value"
                         :style="{ display: prefectureValues.length > 0 ? 'block' : 'none' }">{{ labelForSingleChoice }}</div>
                    <!-- value for multiple -->
                    <ul v-if="multiple"
                        class="ui-prefecture-select__tags"
                        :class="{'ui-prefecture-select__tags--overflow': ellipsis && getOverflowIndex(labels.length - 1) > 0 }">
                        <li v-for="(item, itemIndex) in labels"
                            :key="item.code"
                            class="ant-select-selection__choice ui-prefecture-select__tags__item"
                            :style="{ display: isOverflowTag(itemIndex) ? 'none' : 'list-item' }"
                            style="user-select: none;">
                            <div class="ant-select-selection__choice__content" @click="onClickChoiceContent">{{ item.shortLabel ? item.shortLabel : item.label }}</div>
                            <span class="ant-select-selection__choice__remove" @click="removeItem($event, item)">
                                <tbx-icon class="ant-select-arrow-icon" type="close"/>
                            </span>
                        </li>
                        <li v-if="ellipsis" class="ui-prefecture-select__tags__item ui-prefecture-select__tags__item--ellipsis">…</li>
                    </ul>
                </div>
                <span class="ant-select-arrow"><tbx-icon class="ant-select-arrow-icon" type="down"/></span>
                <span unselectable="on" class="ant-select-selection__clear" style="user-select: none;" @click="onClickClear" v-if="allowClear && value"><tbx-icon type="close-circle-filled" v-if="allowClear && value"></tbx-icon></span>
            </div>
        </div>
        <template #overlay>
            <div class="ui-prefecture-dropdown-container">
                <japan-map v-model="prefectureValues"
                           :title="title"
                           :multiple="multiple"
                           :allow-clear="multiple ? true : allowClear"
                           @close="closeDropdown">
                    <template #title>
                        <slot name="title"></slot>
                    </template>
                </japan-map>
            </div>
        </template>
    </a-dropdown>
</template>

<style scoped lang="less">
.ui-prefecture-select.ant-select {
    position: relative;
    width: 100%;
    height: 100%;

    > .ant-select-selection {
        height: 100%;

        &.ant-select-selection--single {
            position: initial;
        }

        &.ant-select-selection--multiple .ant-select-selection__clear,
        &.ant-select-selection--multiple .ant-select-arrow {
            top: 50%;
        }

        &.ant-select-selection--multiple .ant-select-selection__rendered::after {
            display: none;
        }

        &.ant-select-selection--placeholder-icon {
            padding-left: 14px + 12px;
        }

        .ant-select-selection__choice__remove {
            &:hover {
                opacity: 0.75;
            }
        }

        .ant-select-selection__placeholder {
            height: 24px;
            line-height: 24px;
            margin-top: -12px;
            padding-top: 1px;
            padding-bottom: 1px;
        }

    }

    &.ant-select-sm {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 26px;
            }
        }
    }

    &.ant-select-default {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 30px;
            }
        }
    }

    &.ant-select-lg {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 38px;
            }
        }
    }
}

.ui-prefecture-dropdown-container {
    border-radius: @border-radius-base;
    background-color: @select-background;
    box-shadow: @box-shadow-base;
}

.ui-prefecture-select {
    position: relative;
}

.ui-prefecture-select__tags {
    position: relative;
    top: 3px;
    display: inline-flex;
    overflow-x: hidden;

    .ui-prefecture-select__tags__item {
        flex: none;
        margin-top: 0;

        &.ui-prefecture-select__tags__item--overflow {
            display: none;
        }

        &.ui-prefecture-select__tags__item--ellipsis {
            display: none;
            color: @color-trabox-green-8;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
        }
    }

    &.ui-prefecture-select__tags--overflow {
        .ui-prefecture-select__tags__item.ui-prefecture-select__tags__item--ellipsis {
            display: list-item;
        }
    }
}

.ui-prefecture-select__placeholder-icon {
    position: absolute;
    top: 9px;
    left: 12px;
    pointer-events: none;
    color: @ui-color-empty-text;
}
</style>
