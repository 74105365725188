<script setup lang="ts">
import BaggageSearchDetailDrawer from '@/_components/baggage/BaggageSearchDetailDrawer.vue';
import BaggageSearchTableView from '@/_components/baggage/BaggageSearchTableView.vue';
import { useBaggageSearchHelper } from './baggage-search-helper';
import ExcludedCompany from '@/_pages/Baggage/Search/_components/ExcludedCompany.vue';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';
import TruckModelTreeSelect from '@/_components/ui/TruckModelTreeSelect.vue';
import TruckWeightTreeSelect from '@/_components/ui/TruckWeightTreeSelect.vue';
import { BaggageUtil } from '@/util';
import BaggageSearchPalette from '@/_pages/Baggage/Search/_components/BaggageSearchPalette.vue';
import BaggageAutoSearch from '@/_pages/Baggage/Search/_components/BaggageAutoSearch.vue';

const {
    form,
    formValidateRules,
    loading,
    sortInfo,
    pageInfo,
    drawerVisibility,
    excludedCompanyList,
    excludedBaggageCount,
    visibleSearchCondition,
    visibleSearchDetailCondition,
    savedBaggageSearchConditions,
    baggageSearchResultRef,
    autoSearch,
    validationErrorMessage,
    circleOptions,
    myProfile,
    onChangePage,
    onClickGround,
    onClickCloseBaggageDetailDrawer,
    onSelectedBaggageId,
    onClickRemoveCompany,
    onClickSubmit,
    onClickClear,
    onClickSaveSearchCondition,
    onClickLoadSearchCondition,
    onClickSearchConditionSetting,
    onClickModifySearchForm,
    onClickRefresh,
    onClickChangeAutoSearch,
    // 検索条件
    onClickInvertPref,
} = useBaggageSearchHelper();

</script>

<template>
    <a-layout-content style="height: 100%;" @click="onClickGround">
        <div class="baggage-search-header">
            <div id="baggage-search-header-banner-area" v-embed-tag="'baggage-search-header-banner-area'"></div>
        </div>

        <div class="board-container">
            <a-spin :spinning="loading" class="board-container__item">
                <a-card :body-style="{ padding: 0 }">
                    <div class="search-form-container">
                        <a-row class="section-title" align="middle">
                            <a-col :span="24">
                                <a-space size="middle">
                                    <span class="section-title">検索条件</span>
                                    <a-popover v-model="visibleSearchCondition" trigger="click" placement="bottom" overlay-class-name="app-popover-search-condition">
                                        <template #content>
                                            <div class="search-condition-content">
                                                <div v-if="savedBaggageSearchConditions.length > 0">
                                                    <a-menu class="search-condition-menu" :selectable="false"
                                                            @click="onClickLoadSearchCondition">
                                                        <a-menu-item class="search-condition-menu__item"
                                                                     v-for="item in savedBaggageSearchConditions" :key="item.id">
                                                            <a-tooltip :title="BaggageUtil.formatBaggageSearchConditionLabel(item, myProfile)">
                                                                <a-button size="small">
                                                                    <a-icon type="search" style="margin-right: 0;"/>
                                                                    検索
                                                                </a-button>
                                                                {{ BaggageUtil.formatBaggageSearchConditionLabel(item, myProfile) }}
                                                            </a-tooltip>
                                                        </a-menu-item>
                                                    </a-menu>
                                                    <div class="search-condition-footer">
                                                        <a-button size="small" icon="setting"
                                                                  @click="onClickSearchConditionSetting">よく使う検索条件を管理
                                                        </a-button>
                                                    </div>
                                                </div>
                                                <p v-else class="search-condition-content__empty">よく使う検索条件はまだ1件も保存されていません。<br>検索条件を入力後「よく使う検索条件に保存」ボタンをクリックすると、保存できます。
                                                </p>
                                            </div>
                                        </template>
                                        <a-button type="link" class="btn-search-condition"
                                                  :class="{ 'btn-search-condition--open': visibleSearchCondition }">
                                            よく使う検索条件（{{ savedBaggageSearchConditions.length }}）
                                            <a-icon type="down"/>
                                        </a-button>
                                    </a-popover>
                                </a-space>
                            </a-col>
                        </a-row>
                        <div>
                            <a-form-model layout="vertical">
                                <a-form-model-item label="部屋で絞る">
                                    <a-select :options="circleOptions"
                                              v-model="form.circleId"
                                              :allowClear="true"
                                              style="width: 400px" />
                                </a-form-model-item>
                            </a-form-model>
                        </div>
                        <div class="first-row-container">
                            <div class="location-input-group">
                                <a-input-group class="app-input-group" compact>
                                    <prefecture-select class="app-input-group__item"
                                                       :multiple="true"
                                                       placeholder="発地"
                                                       :placeholder-icon="true"
                                                       v-model="form.departurePrefCode">
                                        <template #title><img src="/img/ic-departure.svg" width="24" height="24" alt="発地"/>発地</template>
                                    </prefecture-select>
                                    <div class="app-input-group__item btn-prefecture-invert">
                                        <a-tooltip title="発地と着地を入れ替えます">
                                            <a-button icon="swap" type="link" @click="onClickInvertPref"></a-button>
                                        </a-tooltip>
                                    </div>
                                    <prefecture-select class="app-input-group__item"
                                                       :multiple="true"
                                                       placeholder="着地"
                                                       v-model="form.arrivalPrefCode">
                                        <template #title><img src="/img/ic-departure.svg" width="24" height="24" alt="着地"/>着地</template>
                                    </prefecture-select>
                                </a-input-group>
                            </div>
                            <div class="date-input-group">
                                <div class="departure">
                                    <a-input-group class="app-input-group" size="small" compact style="justify-content: flex-end">
                                        <date-picker class="app-input-group__item start"
                                                     placeholder="発日（開始）"
                                                     format="YYYY/M/D"
                                                     v-model="form.departureFromDate">
                                            <template #suffixIcon>
                                                <a-icon type="calendar"></a-icon>
                                            </template>
                                        </date-picker>
                                        <span class="app-input-group__item app-input-group__item--separator">〜</span>
                                        <date-picker class="app-input-group__item end"
                                                     placeholder="（終了）"
                                                     format="YYYY/M/D"
                                                     v-model="form.departureToDate">
                                            <template #suffixIcon></template>
                                        </date-picker>
                                    </a-input-group>
                                </div>
                                <div class="arrival">
                                    <a-input-group class="app-input-group" size="small" compact>
                                        <date-picker class="app-input-group__item start"
                                                     placeholder="着日（開始）"
                                                     format="YYYY/M/D"
                                                     v-model="form.arrivalFromDate">
                                            <template #suffixIcon>
                                                <a-icon type="calendar"></a-icon>
                                            </template>
                                        </date-picker>
                                        <span class="app-input-group__item app-input-group__item--separator">〜</span>
                                        <date-picker class="app-input-group__item end"
                                                       placeholder="（終了）"
                                                       format="YYYY/M/D"
                                                       v-model="form.arrivalToDate">
                                            <template #suffixIcon></template>
                                        </date-picker>
                                    </a-input-group>
                                </div>

                            </div>
                        </div>
                        <div class="second-row-container">
                            <div class="freight-input-group with-checkbox">
                                <a-form-model :model="form" :rules="formValidateRules" :colon="false">
                                    <a-form-model-item prop="freight">
                                        <a-input class="app-input-group" addon-after="円以上" v-model="form.freight" placeholder="運賃（税別）" size="small"/>
                                    </a-form-model-item>
                                    <div class="checkbox">
                                        <a-checkbox v-model="form.excludeUndecidedFreight">要相談を除く</a-checkbox>
                                    </div>
                                </a-form-model>
                            </div>

                            <div class="truck-weight-input-group with-checkbox">
                                <truck-weight-tree-select
                                    class="truck-weight"
                                    v-model="form.truckWeightCode"></truck-weight-tree-select>
                                <div class="checkbox">
                                    <a-checkbox v-model="form.excludeUndecidedTruckWeight">問わずを除く</a-checkbox>
                                </div>
                            </div>
                            <div class="truck-weight-input-group with-checkbox">
                                <truck-model-tree-select
                                    class="truck-model"
                                    v-model="form.truckModelCode"></truck-model-tree-select>
                                <div class="checkbox">
                                    <a-checkbox v-model="form.excludeUndecidedTruckModel">問わずを除く</a-checkbox>
                                </div>
                            </div>
                            <div class="status-input-group with-checkbox">
                                <div class="checkbox">
                                    <a-checkbox v-model="form.onlyStatusOpen">募集中のみ表示</a-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="detail-condition-container">
                            <!-- トグル -->
                            <a-space class="btn-toggle" :class="{ 'state-on': visibleSearchDetailCondition }" size="small" direction="horizontal" @click="() => visibleSearchDetailCondition = !visibleSearchDetailCondition">
                                <span>詳細検索</span>
                                <a-icon type="down"/>
                            </a-space>
                            <br/>

                            <a-space align="center" size="small" v-show="visibleSearchDetailCondition">
                                <div>
                                    <a-checkbox v-model="form.onlyShare" class="checkbox">
                                        <span>積合のみ</span>
                                    </a-checkbox>
                                    <a-checkbox v-model="form.excludeShare" class="checkbox">
                                        <span>積合を除く</span>
                                    </a-checkbox>
                                </div>
                                <a-divider type="vertical" style="height: 30px"/>
                                <div>
                                    <a-checkbox v-model="form.onlyRelocation" class="checkbox">
                                        <span>引越しのみ</span>
                                    </a-checkbox>
                                    <a-checkbox v-model="form.excludeRelocation" class="checkbox">
                                        <span>引越しを除く</span>
                                    </a-checkbox>
                                </div>
                                <a-divider type="vertical" style="height: 30px"/>
                                <a-form-model :model="form" :rules="formValidateRules" :colon="false">
                                    <a-form-model-item prop="baggageId" class="baggage-no__item">
                                        <a-input class="baggage-no" v-model="form.baggageId" placeholder="荷物番号" @pressEnter="onClickSubmit"
                                                 :allowClear="true" size="default" :maxLength="16" />
                                    </a-form-model-item>
                                </a-form-model>

                            </a-space>
                        </div>
                        <a-row align="middle" v-if="validationErrorMessage">
                            <a-col :span="24">
                                <span class="error-message">{{ validationErrorMessage }}</span>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="search-action-container">
                        <div class="search-button-group">
                            <a-button class="search-button" type="primary" shape="round" @click="onClickSubmit()">
                                <a-icon type="search" size="middle" />
                                <span style="margin-left: 8px;">検索</span>
                            </a-button>
                        </div>
                        <div class="option-buttons">
                            <a-space size="middle">
                                <a-button @click="onClickSaveSearchCondition">よく使う検索条件に保存</a-button>
                                <a-button @click="onClickClear">クリア</a-button>
                            </a-space>
                        </div>
                    </div>
                    <baggage-auto-search :value="autoSearch" @input="onClickChangeAutoSearch" />
                </a-card>
            </a-spin>

            <a-card id="jsi-search-results" class="board-container__item" ref="baggageSearchResultRef"
                    :body-style="{ position: 'relative', padding: 0 }">
                <baggage-search-table-view @selected="onSelectedBaggageId">
                    <template #header>
                        <div class="search-results-header">
                            <div class="search-results-count">検索結果 {{ pageInfo.totalRecordCount }} 件</div>
                            <sort-pagination-control :sort-info="sortInfo" :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                    </template>
                    <template #footer>
                        <div class="search-results-footer">
                            <baggage-search-palette class="search-results-footer__palette"
                                                    @modifySearchForm="onClickModifySearchForm"
                                                    @refresh="onClickRefresh"></baggage-search-palette>

                            <sort-pagination-control :show-sort="false" :sort-info="sortInfo" :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                    </template>
                </baggage-search-table-view>
            </a-card>
        </div>
        <baggage-search-detail-drawer :visible="drawerVisibility" @close="onClickCloseBaggageDetailDrawer"/>
        <excluded-company :excluded-baggage-count="excludedBaggageCount"
                          :excluded-company-list="excludedCompanyList"
                          @removeCompany="onClickRemoveCompany"/>
    </a-layout-content>
</template>

<style scoped lang="less">
@drawer-footer-height: 72px;

.baggage-search-header {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 16px;
    height: 64px;
}

#baggage-search-header-banner-area {
    display: flex;
    align-items: center;
    justify-content: right;
}

.board-container {
    padding: 0 16px 32px 16px;

    &__item + &__item {
        margin-top: 16px;
    }
}

.search-results-table {
    color: @black;

    .ant-table-bordered .ant-table-tbody tr td {
        border-right-style: dashed !important;
    }
}

.search-results-header {
    display: flex;
    align-items: center;
    padding: @padding-lg @padding-lg 0;
    margin-bottom: 8px;

    .clean-favorite-baggages {
        margin-left: auto;
    }

    .clean-favorite-baggages + .app-table-pagination {
        margin-left: 0;
    }
}

.search-results-footer {
    display: flex;
    align-items: center;
    padding: @padding-sm;
}

.search-results-count {
    font-size: 16px;
    font-weight: bold;
    margin-right: 16px;
}

.search-results-sorting {
    width: initial;
    margin-left: 16px;
}

// 検索条件
.section-title {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: @heading-color;
    margin-bottom: 6px;
}

::v-deep(.ant-form-item) {
    margin-bottom: 0;
}
.search-form-container {
    padding-left: 14px;
    padding-top: 16px;
    // 内部要素にマージン設定しているため設定していない
    padding-bottom: 0;
    padding-right: 4px;
    max-width: 1200px;

    // フォーム縦サイズ・共通フォント設定
    ::v-deep .ant-select,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection--multiple,
    ::v-deep .ant-select-selection__rendered,
    ::v-deep .ant-select-selection__placeholder,
    ::v-deep .ant-input,
    .app-input-group__item,
    .app-input-group__item--separator {
        top: 0!important;
        height: 34px!important;
        min-height: 34px!important;
    }

    // 発着地・ 重量・車種プレースホルダー文字列の縦位置
    ::v-deep .ui-prefecture-select.ant-select > .ant-select-selection .ant-select-selection__placeholder,
    ::v-deep .ui-truck-weight-tree-select.ant-select > .ant-select-selection .ant-select-selection__placeholder,
    ::v-deep .ui-tree-select.ant-select > .ant-select-selection .ant-select-selection__placeholder {
        line-height: 34px;
        margin-top: -2px;
        margin-left: 0;
    }

    // フォーム一列目
    .first-row-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        // 発着地入力フォーム
        .location-input-group {
            min-width: 200px;
            margin-right: 14px;
            margin-bottom: 14px;
            flex-grow: 1;
        }

        // 発地のアイコン位置調整
        ::v-deep .ui-prefecture-select__placeholder-icon {
            top: 11px;
        }

        // ラベル縦位置
        ::v-deep .ant-select ul {
            top: 4px;
        }

        // 発着日
        .date-input-group {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .departure {
                width: 300px;
                margin-right: 14px;
                margin-bottom: 14px;
            }

            .arrival {
                width: 300px;
                margin-right: 10px;
                margin-bottom: 14px;
            }
        }

        // 発地横のテキストマージン
        ::v-deep .ant-select-selection__rendered {
            margin-left: 0;
        }

        // 発着日（開始）
        .start {
            ::v-deep .ant-calendar-picker-input {
                width: 100%;
                flex-basis: 100px;
                padding-left: 28px;
            }
        }

        // 発着日（開始）
        .end {
            ::v-deep .ant-calendar-picker-input {
                width: 100%;
                flex-basis: 100px;
            }
        }
        .app-input-group__item {
            max-width: 300px;
        }
        // 発着日セパレータ
        .app-input-group__item--separator {
            width: 20px;
        }
    }

    // フォーム二列目
    .second-row-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        // 運賃のボタンのマージン調整
        ::v-deep .ant-input-group-addon {
            padding-left: 4px;
            padding-right: 3px;
            font-size: 12px;
        }

        // 運賃入力フォーム
        .freight-input-group {
            margin-right: 16px;
        }

        // 重量・車種
        .truck-weight-input-group,
        .truck-model-input-group {
            margin-right: 10px;
        }

        // 運賃入力フォーム
        .with-checkbox {
            height: 60px;
            margin-bottom: 14px;

            .app-input-group,
            .truck-weight,
            .truck-model {
                margin-right: 0;
                margin-bottom: 4px;
            }
            .app-input-group {
                top: 0;
            }

            ::v-deep .ant-checkbox {
                // antの位置指定を打ち消す
                top: 0;
                + span {
                    font-size: 12px;
                    padding-right: 8px;
                    padding-left: 4px;
                }
            }

            // 要相談を除くチェックボックス
            .checkbox {
                height: 26px;
            }
        }

        // 重量
        .truck-weight {
            // ラベルがあふれるのでこれ以上狭めないこと
            width: 150px;
        }

        // 車種
        .truck-model {
            // ラベルがあふれるのでこれ以上狭めないこと
            width: 228px;
        }

        // フォームテキストの開始位置を揃える
        ::v-deep .ant-input {
            padding-left: 5px;
        }

        // ラベル縦位置
        ::v-deep .ant-select ul {
            top: 4px;
        }

        // 荷物番号の表示調整
        .baggage-no {
            width: 132px;
            margin-right: 10px;
            margin-bottom: 18px;
        }
        .status-input-group {
            display: flex;
            align-items: center;
            .checkbox {
                margin-left: 20px;
            }
        }
    }
}

.detail-condition-container {
    padding: 12px 0;
}

.search-action-container {
    height: 66px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fafafa;

    // 検索ボタン、中央配置用ブロック
    .search-button-group {
        width: calc(50% + 114px);
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;

        // 検索ボタン
        .search-button {
            width: 192px;
            height: 34px;
            left: calc(100% - 228px);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // その他のボタングループ
    .option-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        height: 32px;
        margin-bottom: 16px;
    }

    // 検索条件を保存ボタン
    .store-button {
        width: 134px;
        height: 34px;
        color: #16856e;
        margin: 0 10px;
        margin-bottom: 16px;
    }

    // 検索条件をクリアの右マージン削除
    ::v-deep .ant-btn-link {
        padding-right: 0;
    }
}

// 検索条件ドロップダウンのスタイル
.search-condition-content {
    padding: 8px 0;

    .search-condition-content__empty {
        padding: 8px 16px 0;
        text-align: center;
    }
}

.search-condition-menu {
    max-height: 32px * 5;
    overflow-y: auto;
}

.search-condition-menu__item {
    margin-top: 0;
    margin-bottom: 0 !important;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;

    &:hover {
        background: rgba(@primary-color, 10%);
        color: @ui-color-text;
    }
}

.search-condition-footer {
    margin-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: right;
}

.btn-search-condition {
    i.anticon.anticon-down {
        height: 12px;
        transform-origin: 50% 50%;
        transition: transform 0.3s;
    }

    &--open {
        background: rgba(@primary-color, 10%);

        i.anticon.anticon-down {
            transform: scale(0.83333333) rotate(180deg);
        }
    }
}

.btn-prefecture-invert {
    flex: none !important;
    width: 32px;
    height: auto;
    border-top: @btn-border-style @btn-border-width @select-border-color;
    border-bottom: @btn-border-style @btn-border-width @select-border-color;

    > .ant-btn {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 14px;
        color: @link-color;
    }
}
.btn-toggle {
    cursor: pointer;
    font-size: 12px;
    user-select: none;

    i.anticon.anticon-down {
        transform-origin: 50% 50%;
        transition: transform 0.3s;
    }

    &.state-on {
        i.anticon.anticon-down {
            transform: rotate(180deg);
        }
    }
}
.checkbox {
    user-select: none;
    font-size: 12px;
}
.search-results-footer {
    display: flex;
    align-items: center;
    padding: @padding-sm;

    &__palette {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
.error-message {
    min-height: 22px;
    font-size: 12px;
    line-height: 1.5;
    color: @error-color;
}
</style>
