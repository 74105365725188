<script setup lang="ts">
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import { useSettingCompanyTransferHelper } from '@/_pages/Setting/Company/Transfer/setting-company-transfer-helper';
import { useFormModelItem } from '@/composables/form-helper';

const {
    loading,
    form,
    formModelRef,
    formValidateRules,
    bankAccountTypes,
    onClickAutoFill,
    onSubmit,
} = useSettingCompanyTransferHelper();
const { formModelItem: formLocationItem, onChange: changeLocation, } = useFormModelItem();
</script>

<template>
    <setting-layout>
        <a-card>
            <h3 class="heading">トラボックスから振込する際の口座情報を設定してください</h3>
            <br>
            <a-spin :spinning="loading">
                <a-form-model ref="formModelRef"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ xs: { span: 4 }, xxl: { span: 4, offset: 0 } }"
                              :wrapper-col="{ xs: { span: 20 }, xxl: { span: 17 } }">
                    <a-form-model-item label="銀行"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="bank">
                        <a-input v-model="form.bank" placeholder="" />
                    </a-form-model-item>

                    <a-form-model-item label="支店名"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="branchName">
                        <a-input v-model="form.branchName" placeholder="" />
                    </a-form-model-item>

                    <a-form-model-item label="口座種別"
                                       prop="bankAccountType"
                                       ref="formItem">
                        <a-row type="flex" align="middle" :gutter="[16, 24]">
                            <a-col :span="18">
                                <a-input-group compact>
                                    <a-select placeholder=""
                                              :options="bankAccountTypes"
                                              v-model="form.bankAccountType"/>
                                </a-input-group>
                            </a-col>
                        </a-row>
                    </a-form-model-item>

                    <a-form-model-item label="口座番号"
                                       :wrapper-col="{ span: 5 }"
                                       prop="accountNumber"
                                       ref="formItem">
                        <a-input v-model="form.accountNumber"
                                 :max-length="7"
                                 placeholder="" />
                    </a-form-model-item>

                    <a-form-model-item label="口座名義（カナ）"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="accountHolder"
                                       ref="formItem">
                        <a-input v-model="form.accountHolder" placeholder="" />
                    </a-form-model-item>

                    <br/>

                    <h3 class="heading">支払通知書の送付先情報を設定してください</h3>
                    <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                        <a-button style="max-width: 250px"
                                  size="large"
                                  block
                                  @click="onClickAutoFill">企業基本情報を貼り付ける
                        </a-button>
                    </a-form-model-item>
                    <a-form-model-item label="通知先会社名"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="companyName"
                                       ref="formItem">
                        <a-input v-model="form.companyName"
                                 placeholder=""/>
                    </a-form-model-item>

                    <a-form-model-item label="通知先担当者名"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="staffName"
                                       ref="formItem">
                        <a-input v-model="form.staffName"
                                 placeholder=""/>
                    </a-form-model-item>

                    <a-form-model-item label="通知先住所"
                                       prop="location"
                                       :auto-link="false"
                                       ref="formLocationItem">
                        <a-input-group compact size="default">
                            <a-input style="width: 18%"
                                     prefix="〒"
                                     :max-length="8"
                                     v-model="form.zipCode"
                                     @change="changeLocation"/>

                            <prefecture-select style="width: 18%"
                                               :multiple="false"
                                               placeholder="都道府県"
                                               v-model="form.prefectureCode"
                                               @change="changeLocation"/>

                            <city-input style="width: 32%;"
                                        placeholder="市区町村"
                                        :pref-code="form.prefectureCode"
                                        v-model="form.city"
                                        @change="changeLocation"></city-input>

                            <a-input style="width: 32%"
                                     placeholder="番地・建物"
                                     v-model="form.address"
                                     @change="changeLocation"></a-input>
                        </a-input-group>
                    </a-form-model-item>

                    <a-form-model-item label="通知先電話番号"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="phoneNumber"
                                       ref="formItem">
                        <a-input v-model="form.phoneNumber"/>
                    </a-form-model-item>

                    <a-form-model-item label="通知先FAX番号"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="faxNumber"
                                       ref="formItem">
                        <a-input v-model="form.faxNumber"/>
                    </a-form-model-item>

                    <br/>
                    <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                        <a-button style="max-width: 200px"
                                  type="primary"
                                  size="large"
                                  block
                                  @click="onSubmit">保存
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-card>
    </setting-layout>
</template>

<style scoped lang="less">
.heading {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: @font-size-lg;
    line-height: @line-height-base;
}
// components/Company/Edit/AccountType/style.less
.label {
    text-align: end;
}

.ant-form-item-control .ant-form-item-children .ant-input-group .ant-select {
    width: 50%;
}
</style>
