export type CompanyTruckEventTypeCode =
    | 'MEMO'
    | 'EMPTY';

export class CompanyTruckEventType {
    constructor(public code: CompanyTruckEventTypeCode, public label: string) {
    }

    static Empty = new CompanyTruckEventType('EMPTY', '空車');
    static Memo = new CompanyTruckEventType('MEMO', 'メモ');

    static valueOf = (code: CompanyTruckEventTypeCode): CompanyTruckEventType | undefined =>
        CompanyTruckEventType.values.find((value) => value.code === code);

    static values = [
        CompanyTruckEventType.Empty,
        CompanyTruckEventType.Memo,
    ];

    static registrableValues = this.values;
}
