import { CompanyProfile, CompanyTransfer, CompanyTransferUpdateFormModel } from '@/models/company';
import { computed, ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { companyApi } from '@/repository/api/internal/company';
import { FormValidateUtil } from '@/models/validate-helper';
import _ from 'lodash';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';

export const useCompanyTransfer = () => {
    const transfer = ref<CompanyTransfer | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = () => withLoading(async () => {
        transfer.value = await companyApi.transfer().catch(() => undefined);
    });

    const clear = () => {
        transfer.value = undefined;
    };

    return {
        state: {
            transfer,
            loading,
        },
        load,
        clear,
    };
};

export const useCompanyTransferUpdate = () => {
    const form = ref<CompanyTransferUpdateFormModel>(new CompanyTransferUpdateFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const { state: { loading }, withLoading } = useLoading();

    const initForm = (model: CompanyTransfer) => {
        form.value = new CompanyTransferUpdateFormModel(model);
    };

    const fillForm = (profile: CompanyProfile) => {
        const cloned = _.cloneDeep(form.value);
        cloned.companyName = profile.name.kanji;
        cloned.staffName = 'ご担当者';
        cloned.zipCode = profile.zipCode;
        cloned.prefectureCode = profile.location.prefecture.code as PrefectureEnumCode;
        cloned.city = profile.location.city;
        cloned.address = profile.location.address;
        cloned.phoneNumber = profile.phone.number;
        cloned.faxNumber = profile.phone.faxNumber;
        form.value = cloned;
    };

    const update = () => withLoading(async () => {
        await companyApi.updateTransfer(form.value);
    });

    return {
        state: {
            form,
            formValidateRules,
            loading,
        },
        initForm,
        fillForm,
        update,
    };
};
