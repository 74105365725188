/**
 * トラックの床高Enum
 */
export type TruckHeightEnumCode =
    | 'TH01'
    | 'TH02'
    | 'TH03'
    | 'TH04'
    | 'TH99';

export class TruckHeightEnum {
    constructor(public code: TruckHeightEnumCode, public label: string, public orderNo: number) {}

    static LowOnly = new TruckHeightEnum('TH01', '低床のみ', 1);
    static LowOrMiddle = new TruckHeightEnum('TH02', '低床or中低床', 1);
    static MiddleOrHigh = new TruckHeightEnum('TH03', '中低床or高床', 1);
    static HighOnly = new TruckHeightEnum('TH04', '高床のみ', 1);
    static Unspecified = new TruckHeightEnum('TH99', '問わず', 1);

    static valueOf = (code: TruckHeightEnumCode): TruckHeightEnum | undefined =>
        TruckHeightEnum.allValues.find((value) => value.code === code);

    static values = [
        TruckHeightEnum.LowOnly,
        TruckHeightEnum.LowOrMiddle,
        TruckHeightEnum.MiddleOrHigh,
        TruckHeightEnum.HighOnly,
    ];
    static allValues = [
        ...TruckHeightEnum.values,
        TruckHeightEnum.Unspecified,
    ];
    // 荷物で登録可能な値
    static registrableValuesForBaggage = [...TruckHeightEnum.values, TruckHeightEnum.Unspecified];
}

export const isTruckHeightEnumCode = (value: unknown): value is TruckHeightEnumCode => {
    return typeof value === 'string' && TruckHeightEnum.allValues.find((item) => item.code === value) !== undefined;
};
