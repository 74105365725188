import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import _ from 'lodash';

@Component({})
export default class BaggageSearchExcludedCompany extends Vue {
    @Prop()
    declare readonly baggageList?: baggageTypes.BaggageList;

    @Prop()
    declare readonly baggageSearchExcludedCompanyList?: baggageTypes.BaggageSearchExcludedCompany[];

    visibleExcludedCompanies = false;

    get excludedCompanyCount(): number {
        return this.baggageSearchExcludedCompanyList?.length ?? 0;
    }

    get excludedBaggageCount(): number {
        const list = this.baggageList?.data ?? [];
        const excludedCompanyIds = this.baggageSearchExcludedCompanyList?.map(c => c.id) ?? [];
        return list.filter(d => _.includes(excludedCompanyIds, d.companyId)).length;
    }

    get excludedCompanies(): baggageTypes.BaggageSearchExcludedCompany[] {
        return this.baggageSearchExcludedCompanyList ?? [];
    }

    onClickShowExcludedCompanies(): void {
        this.visibleExcludedCompanies = !this.visibleExcludedCompanies;
    }

    @Watch('excludedCompanyCount')
    onChangeExcludedCompanyList(): void {
        if (this.excludedCompanyCount === 0) {
            this.visibleExcludedCompanies = false;
        }
    }

    async onClickRemoveCompany(companyId: number): Promise<void> {
        await this.$store.dispatch(`baggage/${baggageTypes.ACTION.REMOVE_BAGGAGE_SEARCH_EXCLUDED_COMPANY}`, companyId);
    }
}
