import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { TruckRegisterFormModel } from '@/vuex/modules/truck/types';
import { CompanyStaffNameSuggestionList } from '@/vuex/modules/company/types';

@Component
export default class TruckStaffNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: TruckRegisterFormModel;
    @Prop()
    declare readonly suggestions?: CompanyStaffNameSuggestionList;
    @Prop({ default: true })
    declare readonly showDelete: boolean;

    get staffName(): string {
        return this.value?.staffName ?? '';
    }

    set staffName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.staffName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get staffNameSuggestions(): Array<string> {
        return this.suggestions?.staffNames ?? [];
    }

    /**
     * サジェストの項目と入力文字列を照合して絞り込みを行います。
     */
    onClickDelete(value: string, event: MouseEvent): void {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        // 削除APIを実行
        this.$emit('deleteStaffNameSuggestion', value);
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    private validate(callback: (message?: string) => void): void {
        if (!this.value?.staffName?.trim()) {
            callback('入力してください。');
        } else if (this.value?.staffName && this.value.staffName?.length > 250) {
            callback('250文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
