import { Component, Prop, Vue } from 'vue-property-decorator';
import * as truckTypes from '@/vuex/modules/truck/types';
import { Const } from '@/const';
// @ts-ignore
import UiSortPaginationControl from '@/components/UI/SortPaginationControl';

@Component({
    components: {
        UiSortPaginationControl,
    },
})
export default class TruckSearchPagination extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly sortKey?: string;
    @Prop()
    declare readonly sortAscending?: boolean;
    @Prop()
    declare readonly truckList?: truckTypes.TruckList;
    @Prop({ default: false })
    declare readonly showCount?: boolean;
    @Prop({ default: false })
    declare readonly showSort?: boolean;

    /**
     * 検索結果数
     */
    get count(): number {
        return this.truckList?.totalRecordCount ?? 0;
    }

    /**
     * 現在のページ番号
     */
    get currentPageNumber(): number {
        return this.truckList?.currentPageNumber ?? 1;
    }

    /**
     * 1ページの件数
     */
    get pageSize(): number {
        return this.truckList?.pageSize ?? 0;
    }

    // ======================================================
    // Data
    // ======================================================
    truckSortKey = Const.truckSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    // ======================================================
    // Functions
    // ======================================================
    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('changePage', { pageNo, pageSize });
    }

    /**
     * ソート順ボタンが押下された際に呼び出されます。
     */
    onChangeSort(sortKey: string, sortAscending: boolean): void {
        this.$emit('changeSort', { sortKey, sortAscending });
    }
}
