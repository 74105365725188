import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';

@Component
export default class AgreementTruckRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;

    /**
     * 車種
     */
    get truckModel(): string {
        return this.baggage?.truckModel?.label ?? '';
    }

    /**
     * 重量
     */
    get truckWeight(): string {
        return this.baggage?.truckWeight?.label ?? '';
    }
}
