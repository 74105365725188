/**
 * 運賃ValueObject
 */
import { ValueObject } from '@/models/vo/value-object';
import _ from 'lodash';

export class FreightValue extends ValueObject<number | undefined> {
    constructor(value?: number) {
        super(value);
    }

    get isNegotiate(): boolean {
        return _.isNil(this.value);
    }

    format(prefix: string = '', suffix: string = '円', empty: string = '要相談'): string {
        if (_.isNil(this.value)) return empty;
        return `${ prefix }${ Intl.NumberFormat().format(this.value) }${ suffix }`;
    }
}

export class HighwayFareValue extends ValueObject<{ amount: number | undefined, option: boolean }> {
    constructor(value: { amount: number, option: boolean }) {
        super(value);
    }

    format(prefix: string = '', suffix: string = '円'): string {
        if (_.isNil(this.value.option)) return 'なし';
        return `${ prefix }${ Intl.NumberFormat().format(this.value.amount ?? 0) }${ suffix }`;
    }
}

export class CancellationFeeValue extends ValueObject<number> {
    constructor(value: number) {
        super(value);
    }

    format(prefix: string = '', suffix: string = '円'): string {
        return `${ prefix }${ Intl.NumberFormat().format(this.value) }${ suffix }`;
    }
}
