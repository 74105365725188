import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import { Enum } from '@/types/enum';

@Component
export default class UiSortPaginationControl extends Vue {
    @Prop({ default: true })
    declare showSortControl: boolean;
    @Prop()
    declare sortOptions?: Array<Enum & { key: string }>;
    @Prop()
    declare sortKey?: string;
    @Prop({ default: true })
    declare sortAscending: boolean;
    @Prop({ default: true })
    declare showPaginationControl: boolean;
    @Prop({ default: 0 })
    declare totalPageCount: number;
    @Prop({ default: 1 })
    declare currentPageNumber: number;
    @Prop({ default: 0 })
    declare totalRecordCount: number;
    @Prop({ default: Const.DEFAULT_PAGE_SIZE })
    declare pageSize?: number;
    @Prop({ default: false })
    declare loading: boolean;

    pageSizeOptions = Const.PAGE_SIZE_OPTIONS;

    /**
     * ソートキーを変更した際に呼び出されます。
     * @param value
     */
    onChangeSort(value: string): void {
        this.$emit('changeSort', value, this.sortAscending);
    }

    /**
     * 並び順を変更した際に呼び出されます。
     */
    onClickSortOrder(): void {
        this.$emit('changeSort', this.sortKey, !this.sortAscending);
    }

    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('changePage', pageNo, pageSize);
    }
}
