<script setup lang="ts">
import { computed } from 'vue';
import { useTruckHeightForBaggageOptions } from '@/composables/option-helper';
import { TruckHeightEnumCode } from '@/enums/truck-height.enum';

const props = defineProps<{
    value?: TruckHeightEnumCode,
    optionType: 'baggage',
}>();

const emit = defineEmits<{
    (e: 'input', value: TruckHeightEnumCode | undefined): void,
}>();

const options = useTruckHeightForBaggageOptions();

const truckHeight = computed<TruckHeightEnumCode | undefined>({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});

</script>

<template>
    <a-select placeholder="床高を選択"
              :options="options.options"
              v-model="truckHeight"
              :allowClear="true"/>
</template>

<style scoped lang="less">

</style>
