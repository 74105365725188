import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TrialFlowStep extends Vue {
    @Prop({ default: 1 })
    declare readonly currentStep: number;

    /**
     * 現在進行中のステップか否かを判定します。
     * @param step
     */
    inProgressStep(step: number): boolean {
        return step === this.currentStep;
    }
}
