import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import { Validator } from '@/validator';
import _ from 'lodash';

@Component
export default class CompanyAccountHolderEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    /**
     * 口座名義
     */
    get accountHolder(): string {
        return this.value?.accountHolder ?? '';
    }

    set accountHolder(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.accountHolder = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.accountHolder = this.accountHolder.trim();
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (!this.value?.accountHolder) {
            callback('口座名義を入力してください。');
        } else {
            const result = Validator.validateAccountHolder(this.value?.accountHolder ?? '');
            if (!result.result) {
                callback(result.message);
            } else {
                const normalizedLength = this.value?.accountHolder?.normalize('NFD').length ?? 0;
                if (normalizedLength > 48) {
                    callback(`濁点・半濁点・記号を1文字とした${48}文字以内で入力してください。`);
                }
                callback();
            }
        }
    }
}
