import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageUtil } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

const companyMod = namespace('company');

@Component
export default class SettingPage extends Vue {
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @companyMod.Getter(companyTypes.GETTER.STATUS)
    STATUS?: companyTypes.CompanyStatus;

    // ======================================================
    // Properties
    // ======================================================
    get contentContainer(): Element | undefined {
        const container = this.$refs.contentContainer as Vue | undefined;
        return container?.$el;
    }

    /**
     * 企業ステータスが審査中か否かを取得します。
     */
    get isJudgingCompany(): boolean {
        return this.STATUS?.code === 'JUD';
    }

    /**
     * プレミアムプランを利用中かどうか
     */
    get hasPremiumPlan(): boolean {
        return this.CONTRACT_LIST?.existsPremiumPlan ?? false;
    }

    get selectedKeys(): string[] {
        const path = this.$route.path;

        const definition = [
            { path: '/setting/account/list', key: ['accounts'] },
            { path: '/setting/account/create', key: ['accounts'] },
            { path: '/setting/account/create/complete', key: ['accounts'] },
            { path: '/setting/account/edit', key: ['accounts'] },
            { path: '/setting/company/profile/extra', key: ['companyProfileExtra'] },
            { path: '/setting/company/profile', key: ['companyProfile'] },
            { path: '/setting/company/confidence', key: ['companyConfidence'] },
            { path: '/setting/company/contract', key: ['companyContract'] },
            { path: '/setting/company/transfer', key: ['companyTransfer'] },
            { path: '/setting/company/payment', key: ['companyPayment'] },
            { path: '/setting/email/baggage-and-truck', key: ['matchingMail'] },
            { path: '/setting/billing', key: ['bill'] },
        ];

        return definition.find((each) => path.startsWith(each.path))?.key ?? [];
    }

    /**
     * コンテンツレイアウトの最上部までスクロールします。
     */
    private scrollToTop(): void {
        if (!this.contentContainer) return;
        PageUtil.scrollElementTo(this.contentContainer);
    }

    async beforeRouteUpdate(
        to: VueRoute,
        from: VueRoute,
        next: NavigationGuardNext<SettingPage>
    ): Promise<void> {
        this.scrollToTop();
        next();
    }
}
