import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Validator } from '@/validator';
import { JsonUtil } from '@/util/json';

/**
 * 転生フォーム
 */
export interface AccountRenewalVerificationForm {
    verificationCode: string;
    password: string;
}

/**
 * 転生フォームモデル
 */
export class AccountRenewalVerificationFormModel implements AccountRenewalVerificationForm, FormValidatable<AccountRenewalVerificationFormModel> {
    verificationCode: string;
    password: string;
    passwordConfirm: string;

    constructor(param: AccountRenewalVerificationForm | null = null) {
        this.verificationCode = param?.verificationCode ?? '';
        this.password = param?.password ?? '';
        this.passwordConfirm = param?.password ?? '';
    }

    public normalize(): void {
        this.verificationCode = this.verificationCode.trim();
        this.password = this.password.trim();
        this.passwordConfirm = this.passwordConfirm.trim();
    }

    validator(): FormValidator<AccountRenewalVerificationFormModel> {
        return {
            verificationCode: [],
            password: [
                {
                    required: true,
                    message: 'パスワードを入力してください。',
                },
                {
                    validator: (rule, value, callback) => {
                        const validated = Validator.validatePasswordFormat(value ?? '');
                        if (validated.result) {
                            callback();
                        } else {
                            callback(validated.message);
                        }
                    },
                },
            ],
            passwordConfirm: [
                {
                    required: true,
                    message: '確認のため同じパスワードをもう一度入力してください。',
                },
                {
                    validator: (rule, value, callback) => {
                        if (this.password !== this.passwordConfirm) {
                            callback('確認のため同じパスワードをもう一度入力してください。');
                        } else {
                            callback();
                        }
                    }
                },
            ],
        };
    }

    // noinspection JSUnusedGlobalSymbols
    toJSON(): string {
        return JsonUtil.serialize({
            verificationCode: this.verificationCode,
            password: this.password,
        });
    }
}
