import { circleApi } from '@/repository/api/internal/circle';
import { ref } from 'vue';
import { CompanyProfile } from '@/models/company';

/**
 * テナント内企業一覧をロードする機能を提供します。
 */
export const useCircleCompanyList = () => {
    const list = ref<CompanyProfile[]>([]);

    const load = async (circleId: number): Promise<void> => {
        const data = await circleApi.list(circleId);
        list.value = data;
    };

    return {
        state: {
            list,
        },
        load,
    };
};
