<script setup lang="ts">
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { computed, ref } from 'vue';
import { TruckWeightGroupEnum, TruckWeightGroupEnumCode } from '@/enums/truck-weight-group.enum';
import _ from 'lodash';

type CheckedValue = TruckWeightGroupEnumCode | TruckWeightEnumCode;
interface TruckWeightTreeItem {
    title: string;
    key: CheckedValue;
    children?: Array<{ title: string; key: string }>;
}

const props = withDefaults(defineProps<{
    value?: Array<TruckWeightEnumCode>,
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    ellipsis?: boolean,
    placeholder?: string,
    placement?: string,
}>(), {
    ellipsis: true,
    placeholder: '重量',
    placement: 'bottomLeft',
});

const emits = defineEmits<{ (e: 'input', newValues: TruckWeightEnumCode[]): void }>();

const isMenuOpen = ref<boolean>(false); // ドロップダウンメニューの開閉状態
const tags = ref<Element | undefined>();
// トラック車両重量ツリー
const treeData = computed(() => {
    /**
     * TruckModelTypeEnumをTreeDataItemにマップします。
     */
    const buildTreeItem = (items: Array<TruckWeightEnum | TruckWeightGroupEnum>): TruckWeightTreeItem[] => {
        return items.map((item) => {
            const treeItem: TruckWeightTreeItem = {
                title: item.label,
                key: item.code,
            };
            if (item instanceof TruckWeightGroupEnum) {
                treeItem.children = item.truckWeights.map((each) => {
                    const truckWeight = TruckWeightEnum.valueOf(each);
                    if (!truckWeight) {
                        throw new Error('given value is not defined. [value] => ' + each);
                    }
                    return {
                        title: truckWeight.label,
                        key: truckWeight.code,
                    };
                });
            }
            return treeItem;
        });
    };
    return {
        smallMedium: buildTreeItem([
            TruckWeightEnum.Mini,
            TruckWeightGroupEnum.Small,
            TruckWeightGroupEnum.Medium,
        ]),
        others: buildTreeItem([TruckWeightGroupEnum.Large, TruckWeightEnum.Trailer, TruckWeightEnum.Other]),
    };
});
const selectStyleClass = computed(() => {
    return {
        'ant-select-lg': props.size === 'large',
        'ant-select-sm': props.size === 'small',
        'ant-select-default': props.size === 'default',
        'ant-select-open': isMenuOpen.value,
    };
});
const labels = computed(() => {
    if (!props.value) {
        return [];
    }
    const selectedGroups = TruckWeightGroupEnum.values.filter((each) =>
        each.truckWeights.every((code) => props.value && props.value.includes(code))
    );
    const selectedWeights = _.difference(props.value, ...selectedGroups.map((each) => each.truckWeights)).map(
        (value) => {
            const truckWeight = TruckWeightEnum.valueOf(value);
            if (!truckWeight) {
                throw new Error('given value is not defined. [value] => ' + value);
            }
            return truckWeight;
        }
    );
    return _.concat<TruckWeightEnum | TruckWeightGroupEnum>(selectedGroups, _.orderBy(selectedWeights, 'orderNo'));
});
const checkedKeysForSmallMediumTree = computed({
    get: () => {
        if (!props.value) {
            return [];
        }
        const selectedGroups = TruckWeightGroupEnum.values
            // Groupに属するTruckWeightがすべてチェックされているか確認
            .filter((each) => each.truckWeights.every((code) => props.value && props.value.includes(code)))
            // Small or Mediumのみに絞り込み
            .filter(
                (each) =>
                    each.code === TruckWeightGroupEnum.Small.code || each.code === TruckWeightGroupEnum.Medium.code
            );

        return _.concat(
            selectedGroups.map((each) => each.code),
            _.intersection(props.value, [
                TruckWeightEnum.Mini.code,
                ...TruckWeightGroupEnum.Small.truckWeights,
                ...TruckWeightGroupEnum.Medium.truckWeights,
            ])
        );
    },
    set: (values: CheckedValue[]) => {
        // 2つのツリーの値を結合して、TruckWeightGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, checkedKeysForOthersTree.value),
            ...TruckWeightGroupEnum.values.map((each) => each.code)
        ) as TruckWeightEnumCode[];
        emits('input', newValues);
    },
});
const checkedKeysForOthersTree = computed({
    get: () => {
        if (!props.value) {
            return [];
        }

        const isLargeTruckAllSelected = TruckWeightGroupEnum.Large.truckWeights.every(
            (each) => props.value && props.value.includes(each)
        );
        // exclude small and medium truck weights
        const others = _.difference(
            props.value,
            TruckWeightGroupEnum.Small.truckWeights,
            TruckWeightGroupEnum.Medium.truckWeights,
            [TruckWeightEnum.Mini.code]
        );
        return _.concat(isLargeTruckAllSelected ? [TruckWeightGroupEnum.Large.code] : [], others);
    },
    set: (values: CheckedValue[]) => {
        // 2つのツリーの値を結合して、TruckWeightGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, checkedKeysForSmallMediumTree.value),
            ...TruckWeightGroupEnum.values.map((each) => each.code)
        ) as TruckWeightEnumCode[];
        emits('input', newValues);
    },
});
/**
 * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
 */
const isOverflowTag = (index: number): boolean => {
    if (!props.ellipsis) {
        return false;
    }
    const overflowIndex = getOverflowIndex(index);
    return overflowIndex > 0 && index >= overflowIndex;
};
/**
 * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
 */
const getOverflowIndex = (index: number) => {
    if (index < 0) {
        return -1;
    }
    const tagsElement = tags.value;
    if (!tagsElement) {
        return -1;
    }
    const tagBaseWidth = props.size === 'large' ? 36 : 32;
    const tagSpacing = 4;
    const dropdownIconWidth = 16;
    const widthByLabels = labels.value.map((each) => {
        return tagSpacing + tagBaseWidth + each.label.length * 14;
    });
    return widthByLabels.findIndex(
        (_each, labelIndex, array) =>
            _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
    );
};
/**
 * 選択ラベルをクリックすると呼び出されます。
 */
const onClickChoiceContent = (e: Event): void => {
    // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    e.stopPropagation();
};
/**
 * 画面左側（重量: 軽/小/中）のツリーでチェック操作が行われた際に呼び出されます。
 */
const onCheckSmallMediumTree = (checkedKeys: Array<CheckedValue>): void => {
    checkedKeysForSmallMediumTree.value = checkedKeys;
};
/**
 * 画面右側（重量: 軽/小/中以外）のツリーでチェック操作が行われた際に呼び出されます。
 */
const onCheckOthersTree = (checkedKeys: Array<CheckedValue>): void => {
    checkedKeysForOthersTree.value = checkedKeys;
};
/**
 * 選択した項目（車両重量）を取り除きます。
 */
const removeItem = (event: Event, value: TruckWeightEnum | TruckWeightGroupEnum): void => {
    event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    const newValues = _.difference(
        props.value ?? [],
        value instanceof TruckWeightGroupEnum ? value.truckWeights : [value.code]
    );
    emits('input', newValues);
};
/**
 * 確定ボタンを押下すると呼び出されます。
 */
const onClickOk = (): void => {
    isMenuOpen.value = false;
};
</script>

<template>
    <a-dropdown class="ui-truck-weight-tree-select" v-model="isMenuOpen" :trigger="['click']" :placement="placement">
        <div class="ant-select ant-select-enabled" :class="selectStyleClass">
            <div class="ant-select-selection ant-select-selection--multiple">
                <div class="ant-select-selection__rendered" ref="tags">
                    <!-- placeholder -->
                    <div class="ant-select-selection__placeholder"
                         :style="{ display: value.length > 0 ? 'none' : 'block' }"
                         style="user-select: none;">{{ placeholder }}
                    </div>
                    <!-- labels for selected value -->
                    <ul class="ui-tree-select__tags"
                        :class="{'ui-tree-select__tags--overflow': ellipsis && getOverflowIndex(labels.length - 1) > 0 }">
                        <li v-for="(item, itemIndex) in labels"
                            :key="item.code"
                            class="ant-select-selection__choice ui-tree-select__tags__item"
                            :style="{ display: isOverflowTag(itemIndex) ? 'none' : 'list-item' }"
                            style="user-select: none;">
                            <div class="ant-select-selection__choice__content" @click="onClickChoiceContent">
                                {{ item.label }}
                            </div>
                            <span class="ant-select-selection__choice__remove" @click="removeItem($event, item)">
                            <a-icon class="ant-select-arrow-icon" type="close"/>
                        </span>
                        </li>
                        <li v-if="ellipsis" class="ui-tree-select__tags__item ui-tree-select__tags__item--ellipsis">…
                        </li>
                    </ul>
                </div>
                <span class="ant-select-arrow"><a-icon class="ant-select-arrow-icon" type="down"/></span>
            </div>
        </div>
        <!-- Dropdown Contents -->
        <template #overlay>
            <div class="dropdown-container">
                <div class="tree-container">
                    <a-tree class="tree"
                            v-model="checkedKeysForSmallMediumTree"
                            :tree-data="treeData.smallMedium"
                            :checkable="true"
                            :selectable="false"
                            :default-expand-all="true"
                            @check="onCheckSmallMediumTree"/>
                    <a-tree class="tree"
                            v-model="checkedKeysForOthersTree"
                            :tree-data="treeData.others"
                            :checkable="true"
                            :selectable="false"
                            :default-expand-all="true"
                            @check="onCheckOthersTree"/>
                </div>
                <footer class="footer">
                    <nav>
                        <ul class="footer__actions">
                            <li class="footer__actions__ok">
                                <a-button type="primary" @click="onClickOk">確定</a-button>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </div>
        </template>
    </a-dropdown>
</template>

<style scoped lang="less">
.ui-truck-weight-tree-select.ant-select {
    width: 100%;
    height: 100%;

    > .ant-select-selection {
        height: 100%;

        &.ant-select-selection--multiple .ant-select-selection__clear,
        &.ant-select-selection--multiple .ant-select-arrow {
            top: 50%;
        }

        .ant-select-selection__choice__remove {
            &:hover {
                opacity: 0.75;
            }
        }

        .ant-select-selection__placeholder {
            height: 24px;
            line-height: 24px;
            margin-top: -12px;
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    &.ant-select-sm {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 26px;
            }
        }
    }

    &.ant-select-default {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 30px;
            }
        }
    }

    &.ant-select-lg {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 38px;
            }
        }
    }
}

.dropdown-container {
    border-radius: @border-radius-base;
    background-color: @select-background;
    box-shadow: @box-shadow-base;
}

.tree-container {
    display: flex;

    > .tree {
        width: 160px;
        padding: (@padding-xs / 2) @padding-xs;
    }

    > .tree + .tree {
        border-left: @border-width-base @border-style-base @border-color-base;
    }

    > .tree:last-child {
        padding-bottom: 48px;
    }
}

.ui-tree-select__tags {
    position: relative;
    top: 3px;
    display: inline-flex;
    overflow-x: hidden;

    .ui-tree-select__tags__item {
        flex: none;
        margin-top: 0;

        &.ui-tree-select__tags__item--overflow {
            display: none;
        }

        &.ui-tree-select__tags__item--ellipsis {
            display: none;
            color: @color-trabox-green-8;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
        }
    }

    &.ui-tree-select__tags--overflow {
        .ui-tree-select__tags__item.ui-tree-select__tags__item--ellipsis {
            display: list-item;
        }
    }
}

.footer {
    border-top: @border-style-base @border-width-base @color-neutral-5;
    padding: 10px 12px;

    &__actions {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        &__ok {
            margin-left: auto;
        }
    }
}
</style>
