import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/baggage/types';
import { Util } from '@/util';

@Component
export default class BaggageTruckCountRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.BaggageRegisterForm;

    get truckCount(): string | number {
        return this.value ? this.value.truckCount : '1';
    }

    set truckCount(newValue: string | number) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.truckCount = typeof newValue !== 'number' ? '' : newValue.toString();
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 配車台数入力時に全角数字から半角数字に変換する
     * @param value
     */
    onTruckCountParse(value: string): number | undefined {
        if (_.isEmpty(value)) return undefined;
        return Util.toNumber(value);
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        const target = Util.toNumber(`${this.truckCount}`);
        if (0 < target && target <= 10) {
            callback();
        } else {
            callback('台数は1〜10台の間で入力してください。');
        }
    }
}
