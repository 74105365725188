import { Component, Prop, Vue } from 'vue-property-decorator';
import { GuaranteeUtil, Util } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

@Component
export default class AgreementGuaranteeUsage extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ default: '0' })
    declare readonly freight: string;
    @Prop({ default: 0 })
    declare readonly highwayFare: number;
    @Prop()
    declare readonly contractList?: CompanyContractListModel;
    @Prop()
    declare readonly guaranteedAmount?: companyTypes.CompanyGuaranteedAmount;
    @Prop()
    declare readonly guaranteePriceMaster?: guaranteeTypes.GuaranteePriceMaster[];

    /**
     * 今月これまでの保証対象累計額
     */
    private get usedGuaranteedAmount(): number | undefined {
        if (!this.guaranteedAmount) {
            return undefined;
        }
        return this.guaranteedAmount.amount;
    }

    /**
     * 今月これまでの保証対象累計額（表示用テキスト）
     */
    get usedGuaranteedAmountText(): string | undefined {
        return this.usedGuaranteedAmount ? Util.formatNumber(this.usedGuaranteedAmount) : '0';
    }

    /**
     * 今回の保証対象金額
     */
    private get newGuaranteedAmount(): number {
        const freight = Util.toNumber(this.freight);
        if (isNaN(freight)) return 0;

        return freight + this.highwayFare;
    }

    /**
     * 今回の保証対象金額(フォーマット済み)
     */
    get newGuaranteedAmountText(): string {
        return Util.formatNumber(this.newGuaranteedAmount);
    }

    /**
     * 保証対象の合計
     */
    private get totalGuaranteedAmount(): number | undefined {
        if (!this.guaranteedAmount) {
            return undefined;
        }
        return this.guaranteedAmount.amount + this.newGuaranteedAmount;
    }

    /**
     * 保証対象の合計（表示用テキスト）
     */
    get totalGuaranteedAmountText(): string {
        return this.totalGuaranteedAmount ? Util.formatNumber(this.totalGuaranteedAmount) : '0';
    }

    /**
     * 今月これまでの運賃保証サービス料
     */
    private get usedServiceFee(): number | undefined {
        if (!this.guaranteedAmount || !this.guaranteePriceMaster || !this.contractList) {
            return undefined;
        }
        return GuaranteeUtil.calculateGuaranteePrice(
            this.guaranteedAmount.amount,
            this.guaranteePriceMaster,
            this.contractList.contracts
        );
    }

    /**
     * 今月これまでの運賃保証サービス料（表示用テキスト）
     */
    get usedServiceFeeText(): string | undefined {
        return this.usedServiceFee ? Util.formatNumber(this.usedServiceFee) : '0';
    }

    /**
     * 合計利用金額
     */
    private get totalServiceFee(): number | undefined {
        if (!this.guaranteedAmount || !this.guaranteePriceMaster || !this.contractList) {
            return undefined;
        }
        return GuaranteeUtil.calculateGuaranteePrice(
            this.guaranteedAmount.amount + this.newGuaranteedAmount,
            this.guaranteePriceMaster,
            this.contractList.contracts
        );
    }

    /**
     * 合計利用金額の表示用テキスト
     */
    get totalServiceFeeText(): string {
        return this.totalServiceFee ? Util.formatNumber(this.totalServiceFee) : '0';
    }
}
