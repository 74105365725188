<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';
import { Util } from '@/util';
import * as _ from 'lodash';

const props = withDefaults(defineProps<{
    value?: number;
    suffix?: string;
    disabled?: boolean;
    range?: { min: number, max: number };
}>(), {
    disabled: false,
});

const emit = defineEmits<{
    (e: 'input', value: number | undefined): void;
    (e: 'change', value: number | undefined): void;
}>();

const vm = getCurrentInstance();

const amount = computed({
    get: () => props.value !== undefined ? `${ props.value }` : undefined,
    set: (newValue) => {
        const value = confine(convert(newValue), props.range);
        onChange(value);
        // 数字以外の文字が入力された場合、その文字がinputフィールドに残ってしまうので、強制的にコンポーネントを更新する
        vm?.proxy.$forceUpdate();
    }
});

const onChange = (value: number | undefined) => {
    emit('input', value);
    emit('change', value);
};

const convert = (value: string | undefined): number => {
    if (value === undefined) {
        return 0;
    }
    const normalized = Util.toDigits(value);
    if (normalized === '') {
        return 0;
    }
    return _.toNumber(normalized);
};

const confine = (value: number, range: { min: number, max: number } | undefined): number => {
    if (range === undefined) {
        return value;
    }
    return Math.min(Math.max(value, range.min), range.max);
};

</script>

<template>
    <a-input v-model="amount"
             class="tbx-currency-input"
             :disabled="disabled">
        <template #suffix>
            <slot name="suffix">
                <span v-if="suffix">{{ suffix ?? '' }}</span>
            </slot>
        </template>
    </a-input>
</template>

<style scoped lang="less">

</style>
