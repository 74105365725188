<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';

const props = defineProps<{
    baggage: Pick<Baggage, 'truckWeight' | 'truckModel'>;
}>();

const text = computed<string>(() => {
    return `重量：${ props.baggage.truckWeight.label } 車種：${ props.baggage.truckModel.label }`;
});

</script>

<template>
    <span>{{ text }}</span>
</template>

<style scoped lang="less">

</style>
