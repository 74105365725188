import { baggageApi } from '@/repository/api/internal/baggage';

/**
 * 荷物の既読登録機能を提供します。
 */
export const useBaggageMarkRead = () => {
    /**
     * 荷物を既読登録します。awaitしません。
     * @param baggageId
     */
    const markRead = (baggageId: number) => {
        baggageApi.markRead(baggageId).catch(() => { /*エラーは無視する*/ });
    };

    return {
        markRead
    };
};
