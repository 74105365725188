//
// Action
//
export namespace ACTION {
    export const START_ROUTING = 'START_ROUTING';
    export const END_ROUTING = 'END_ROUTING';
}

//
// Getter
//
export namespace GETTER {
    export const ROUTING = 'ROUTING';
}

//
// Mutation
//
export namespace MUTATION {
    export const SET_ROUTING = 'SET_ROUTING';
}

//
// Store
//
export interface RoutingState {
    routing: boolean;
}
