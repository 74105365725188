<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { PageUtil } from '@/util';
import { useRoute } from '@/composables/helper/route';
//
// Components import
//
import AppHeader from './header/AppHeader.vue';
import BackTopButton from '@/_components/ui/BackTopButton.vue';
import AppSidebar from '@/_components/root/AppSidebar.vue';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useCompanyNotice } from '@/composables/global/company-notice';
import { useCompanyNoticeListener } from '@/composables/global/company-notice-listen';
import { useGlobalStatistics } from '@/composables/global/global-statistics';
import { useHighContrastMode } from '@/composables/global/high-contrast-mode';
import { useNotice } from '@/composables/global/notice';
import { useNoticeListener } from '@/composables/global/notice-listen';
// Props
const props = defineProps<{
    contentOnly?: boolean;
}>();

// use-composables
const { loadIfNotExists: loadContractIfNotExists } = useCompanyContracts();
const { loadIfNotExists: loadCompanyStatusIfNotExists } = useCompanyMyStatus();
const { load: loadMyCompanyProfile } = useCompanyMyProfile();
const { load: loadCompanyNotice } = useCompanyNotice();
const { start: startListenCompanyNotice, stop: stopListenCompanyNotice } = useCompanyNoticeListener();
const { load: loadGlobalStatistics } = useGlobalStatistics();
const { state: { highContrastMode, } } = useHighContrastMode();
const { load: loadTraboxNotification } = useNotice();
const { start: startListenTraboxNotice, stop: stopListenTraboxNotice, } = useNoticeListener();

const { isContentOnlyLayout, isNoFooterLayout } = useRoute();
const hideSideMenu = ref<boolean | undefined>(undefined);
/**
 * app-themeスタイルクラスを取得します。
 */
const additionalRootClasses = computed<{ [key: string]: boolean }>(() => {
    return {
        ...PageUtil.createAppThemeClassNames(highContrastMode.value),
        'board-main-layout--content-only': props.contentOnly === true,
    };
});

/**
 * 追加で適用するスタイルクラスを取得します。
 */
const additionalRootStyles = computed<Partial<{ [key: string]: string }>>(() => {
    // 解像度 800x600, 1024x768のディスプレイを利用している方は幅1280px基準で見れるように全体を縮小する。
    // Google アナリティクスで確認したところ、想定よりも利用者が多かったため、その救済策として実装している。
    if (window.devicePixelRatio === 1 && screen.availWidth < 1280) {
        return {
            zoom: `${screen.availWidth / 1280}`,
        };
    }
    return {};
});

// Lifecycle hooks
onBeforeMount(async () => {
    loadCompanyStatusIfNotExists().then(status => {
        switch (status.code) {
            case 'ACT':
                Promise.all([
                    loadMyCompanyProfile(),
                    loadContractIfNotExists(),
                ]);
                Promise.all([
                    loadGlobalStatistics(),
                    loadTraboxNotification(),
                    loadCompanyNotice(),
                ]);
                startListenTraboxNotice();
                startListenCompanyNotice();
                return;
            case 'JUD':
                loadMyCompanyProfile();
                return;
            default: return;
        }
    });
});

onBeforeUnmount(() => {
    stopListenTraboxNotice();
    stopListenCompanyNotice();
});
</script>

<template>
    <a-layout class="board-main-layout" :style="additionalRootStyles" :class="additionalRootClasses">
        <app-header v-if="!isContentOnlyLayout" />
        <a-layout class="board-main-layout__body">
            <app-sidebar v-if="!isContentOnlyLayout" v-model="hideSideMenu" />
            <a-layout-content id="jsi-board-content"
                              class="board-content"
                              :class="{ menu: !isContentOnlyLayout, wide: hideSideMenu, thin: !hideSideMenu}">
                <router-view/>
            </a-layout-content>

            <footer v-if="!isNoFooterLayout">
                <back-top-button />
            </footer>
        </a-layout>
    </a-layout>
</template>

<style scoped lang="less">
@menu-item-width-small: 64px;
@menu-item-width-large: 180px;

.board-main-layout {
    height: 100vh;
    overflow-y: hidden;

    &--content-only {
        height: auto;
        overflow-y: auto;
    }

    &__body {
        flex: 1;
    }
}

.board-content {
    height: auto;
    position: relative;

    .ant-layout-header {
        position: fixed;
        z-index: 1;
    }

    &.menu {
        &.wide {
            margin-left: @menu-item-width-small;

            .ant-layout-header {
                width: calc(~"100vw" - @menu-item-width-small);
            }
        }

        &.thin {
            margin-left: @menu-item-width-large;

            .ant-layout-header {
                width: calc(~"100vw" - @menu-item-width-large);
            }
        }
    }
}
</style>
