import { Component, Prop, Vue } from 'vue-property-decorator';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faClone } from '@fortawesome/free-regular-svg-icons';
library.add(faClone);

@Component
export default class OpenOnlineOrderButton extends Vue {
    @Prop()
    declare readonly size?: string;
    @Prop({ default: false })
    declare readonly loading: boolean;

    onClick(): void {
        this.$emit('click');
    }
}
