/**
 * 配送依頼書ステータスEnum
 */
export type DeliveryOrderStatusEnumCode = 'DRAFT' | 'SENT' | 'READ' | 'REPLIED' | 'REPLY_READ';

export class DeliveryOrderStatusEnum {
    constructor(
        public code: DeliveryOrderStatusEnumCode,
        public label: string,
        public shipperLabel: string,
        public transporterLabel: string
    ) {}

    static Draft = new DeliveryOrderStatusEnum('DRAFT', '下書き', '', '');
    static Sent = new DeliveryOrderStatusEnum('SENT', '依頼書送信済', '依頼書送信済', '要返信');
    static Read = new DeliveryOrderStatusEnum('READ', '依頼書確認済', '依頼書送信済', '要返信');
    static Replied = new DeliveryOrderStatusEnum('REPLIED', '車番返信済', '車番確定(未読)', '車番返信済');
    static ReplyRead = new DeliveryOrderStatusEnum('REPLY_READ', '車番返信確認済', '車番確定(既読)', '車番返信済');

    static valueOf = (code: DeliveryOrderStatusEnumCode): DeliveryOrderStatusEnum | undefined =>
        DeliveryOrderStatusEnum.values.find((value) => value.code === code);

    static values = [
        DeliveryOrderStatusEnum.Draft,
        DeliveryOrderStatusEnum.Sent,
        DeliveryOrderStatusEnum.Read,
        DeliveryOrderStatusEnum.Replied,
        DeliveryOrderStatusEnum.ReplyRead,
    ];

    static codeOf = (code: DeliveryOrderStatusEnumCode): DeliveryOrderStatusEnum => {
        switch (code) {
            case 'DRAFT':
                return DeliveryOrderStatusEnum.Draft;
            case 'SENT':
                return DeliveryOrderStatusEnum.Sent;
            case 'READ':
                return DeliveryOrderStatusEnum.Read;
            case 'REPLIED':
                return DeliveryOrderStatusEnum.Replied;
            case 'REPLY_READ':
                return DeliveryOrderStatusEnum.ReplyRead;
        }
    };

    get forShipper(): string {
        return this.shipperLabel;
    }

    get forTransporter(): string {
        return this.transporterLabel;
    }

    /**
     * 依頼書を1回でも送信済か判定します。
     */
    get isAlreadySent(): boolean {
        return [
            DeliveryOrderStatusEnum.Sent.code,
            DeliveryOrderStatusEnum.Read.code,
            DeliveryOrderStatusEnum.Replied.code,
            DeliveryOrderStatusEnum.ReplyRead.code,
        ].includes(this.code);
    }

    get isSent(): boolean {
        return this.code === DeliveryOrderStatusEnum.Sent.code;
    }

    get isReplied(): boolean {
        return this.code === DeliveryOrderStatusEnum.Replied.code;
    }

    get isReplyRead(): boolean {
        return this.code === DeliveryOrderStatusEnum.ReplyRead.code;
    }
}
