<script setup lang="ts">
import { DateTimePickerValue } from '@/_components/ui/types/date-time-picker-type';
import dayjs, { Dayjs } from 'dayjs';
import { computed, ref, watch } from 'vue';
import { TimeHourEnum } from '@/enums/time-hour.enum';
import { TimeMinuteEnum } from '@/enums/time-minute.enum';
import moment from 'moment';
import { DeliveryDateTime, DeliveryDateTimeType } from '@/models/vo/delivery-datetime';
import { Const } from '@/const';
import { DateUtil } from '@/util';
import { RenderHeader } from 'ant-design-vue/types/calendar';

const props = withDefaults(defineProps<{
    value?: DateTimePickerValue, // `[min, max]` で値を扱う。
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    allowClear?: boolean,  // 選択解除を許容するか否か。
    placeholder?: string,  // select メニューのプレースホルダ
    placement?: string,  // ドロップダウン内のコンテンツを表示する位置
    disabledDate?: (currentDate: Dayjs) => boolean, // 指定した日付を選択できないようにする関数をバインドできる
    validRange?: [Dayjs | undefined, Dayjs | undefined], // カレンダーで選択できる範囲。制限を設けない場合はundefined
    disabled?: boolean, // ドロップダウンを無効（非活性）にするか否か。
    onlyJustTime?: boolean,
    borderLeft?: boolean,
    borderRight?: boolean,
    arrow?: boolean,
}>(), {
    allowClear: false,
    placeholder: '日時を選択',
    placement: 'bottomLeft',
    disabled: false,
    onlyJustTime: false,
    borderLeft: true,
    borderRight: true,
    arrow: true,
});
const emits = defineEmits<{
    (e: 'input', value: DateTimePickerValue): void,
    (e: 'change', value: DateTimePickerValue): void,
}>();
const calendarViewMode = ref<'month' | 'year'>('month');
const isMenuOpen = ref<boolean>(false);
const timeHourEnumValues = computed(() => TimeHourEnum.values.filter(e => !props.onlyJustTime || (e !== TimeHourEnum.Day && e !== TimeHourEnum.Morning && e !== TimeHourEnum.Afternoon)));
const timeMinuteEnumValues = computed(() => TimeMinuteEnum.values.filter(e => !props.onlyJustTime || e !== TimeMinuteEnum.None));
const calendarValue = ref<moment.Moment>(moment().startOf('day')); // カレンダー部で表示/選択されている日付

/**
 * model(DeliveryDateTime)を取得します。
 */
const model = computed<DeliveryDateTime | null>({
    get: (): DeliveryDateTime | null => {
        const min = props.value?.[0] ?? '';
        const max =  props.value?.[1] ?? '';
        if (!min || !max) {
            return null;
        }
        return DeliveryDateTime.of(min, max);
    },
    set: (dateTime: DeliveryDateTime | null) => {
        const value: DateTimePickerValue = dateTime === null ? [undefined, undefined] : dateTime.rawValuesAsString();
        emitValue(value);
    }
});

/**
 * カレンダーヘッダー部のタイトルテキストを取得します
 */
const calendarHeaderTitle = computed(() => calendarValue.value?.format('YYYY年 M月') ?? '');

/**
 * 表示用ラベルを取得します。
 */
const labelText = computed(() => model.value?.format() ?? '');
const borderStyle = computed(() => {
    const styles = [''];
    if (!props.borderLeft) {
        styles.push('border-left: 0;');
    }
    if (!props.borderRight) {
        styles.push('border-right: 0;');
    }
    return styles.join('');
});

/**
 * Select（ant-select）に付与するstyleClassを取得します。
 */
const selectStyleClass = computed<{ [key: string]: boolean }>(() => {
    return {
        'ant-select-enabled': !props.disabled,
        'ant-select-disabled': props.disabled,
        'ant-select-lg': props.size === 'large',
        'ant-select-sm': props.size === 'small',
        'ant-select-default': props.size === 'default',
        'ant-select-open': isMenuOpen.value,
    };
});

/**
 * 時刻（時間）パネルを開くべきかを取得します。
 */
const shouldOpenHourSelectPanel = computed<boolean>(() => calendarViewMode.value !== 'year' && model.value !== null);

/**
 * 時刻（分）パネルを開くべきかを取得します。
 */
const shouldOpenMinuteSelectPanel = computed<boolean>(() => {
    if (!shouldOpenHourSelectPanel.value || model.value === null) {
        return false;
    }
    return model.value?.type === 'Hourly' || model.value?.type === 'Just';
});

/**
 * 日付を選択できる範囲を取得します
 */
const toMomentValidRange = computed<Array<moment.Moment | undefined> | undefined>(() => {
    if (!props.validRange) {
        return undefined;
    }
    // convert dayjs to moment object
    return props.validRange.map((value) =>
        value ? moment(value.format(Const.INTERNAL_DATETIME_FORMAT), Const.INTERNAL_DATETIME_FORMAT) : undefined
    );
});

// created
watch(model, (value) => {
    if (value) {
        calendarValue.value = moment(value.date.format(Const.INTERNAL_DATETIME_FORMAT));
    }
}, { immediate: true });

/**
 * カレンダーで選択した日時がmodelを同一かどうか
 * @param date
 */
const isSelectedDate = (date: moment.Moment): boolean => {
    if (!model.value?.date) {
        return false;
    }
    return model.value.date.isSame(date.format(Const.INTERNAL_DATE_FORMAT));
};

/**
 * 選択した時間がselectedかどうかを取得します
 */
const isSelectedHour = (timeHour: TimeHourEnum): boolean => {
    if (!model.value) {
        return false;
    }
    switch (model.value.type) {
        case 'Hourly':
        case 'Just': {
            const [hour] = model.value.rawValuesAsString('HH');
            return timeHour.hour === Number(hour);
        }
        default:
            return model.value.type === timeHour.type;
    }
};

/**
 * 選択した分がdisabledかどうかを取得します
 * @param timeMinute
 */
const isDisabledMinute = (timeMinute: TimeMinuteEnum): boolean => {
    if (!model.value) {
        return true;
    }
    const [modelMin] = model.value.rawValues();
    if (timeMinute.type === 'Hourly') {
        return isDisabledHour(timeMinute.type, modelMin.hour());
    }
    return isDisabledDateTime(
        DeliveryDateTime.typeOf(timeMinute.type, modelMin.minute(timeMinute.minute as number))
    );
};

/**
 * 選択した分がselectedかどうかを取得します
 */
const isSelectedMinute = (timeMinute: TimeMinuteEnum): boolean => {
    if (!model.value || model.value.type !== timeMinute.type) {
        return false;
    }

    if (model.value.type === 'Just') {
        const [min] = model.value.rawValuesAsString('m');
        return timeMinute.minute === Number(min);
    }
    return true;
};

/**
 * 選択した日時がdisabledかどうかを取得します
 */
const isDisabledHour = (type: DeliveryDateTimeType, hour?: number): boolean => {
    // 日付を選んでいない場合はdisabled
    if (!model.value) {
        return true;
    }

    let [modelMin] = model.value.rawValues();
    if (type === 'Hourly') {
        modelMin = modelMin.clone().hour(hour as number);
    }
    return isDisabledDateTime(DeliveryDateTime.typeOf(type, modelMin));
};


/**
 * ドロップダウンメニューを閉じます。
 */
const closeDropdown= (): void => {
    isMenuOpen.value = false;
};

/**
 * DisabledなDateTimeかどうかを判定します。
 * @param value
 * @private
 */
const isDisabledDateTime = (value: DeliveryDateTime | null): boolean => {
    if (value === null) {
        return true;
    }
    // disabledDate を親からバインドしている場合はチェックを実行
    if (props.disabledDate !== undefined) {
        const isDisabled = value
            .rawValues()
            .map((each) => (props.disabledDate as (value: Dayjs) => boolean)(each))
            .some((each) => each);
        if (isDisabled) {
            return true;
        }
    }
    // validRange をバインドしている場合はRangeのバリデーションを実行
    if (props.validRange !== undefined) {
        const [min, max] = value.rawValues();
        const [rangeStart, rangeEnd] = props.validRange;
        if (rangeStart && rangeEnd) {
            const isValidRange =
                max.isBetween(rangeStart, rangeEnd, value.type === 'Day' ? 'day' : 'second', '[]');
            if (!isValidRange) return true;
        } else if (rangeStart && rangeStart.isAfter(min)) {
            return true;
        } else if (rangeEnd && rangeEnd.isSameOrBefore(max)) {
            return true;
        }
    }
    return false;
};

/**
 * 指定した日をdisabledにします
 * @param currentDate ant-calendarがmoment型を渡してきます
 * @private
 */
const disabledDateInternal = (currentDate: moment.Moment): boolean => {
    if (props.disabledDate === undefined) {
        return false;
    }
    return props.disabledDate(DateUtil.parseDatetimeText(currentDate.format(Const.INTERNAL_DATETIME_FORMAT)));
};

/**
 * 時刻を設定します。
 * @param type
 * @param hour
 * @param minute
 * @private
 */
const setTime = (type: DeliveryDateTimeType, hour?: number, minute?: number): void => {
    if (model.value === null) {
        throw new Error('require set the date before select time.');
    }
    const [newValue] = model.value.rawValues();
    switch (type) {
        case 'Day':
        case 'Morning':
        case 'Afternoon':
            model.value = DeliveryDateTime.typeOf(type, newValue.clone());
            break;
        case 'Hourly':
            if (hour === undefined) {
                return;
            }
            model.value = DeliveryDateTime.typeOf(type, newValue.clone().hour(hour));
            break;
        case 'Just': {
            let newTime = newValue.clone();
            if (hour !== undefined) {
                newTime = newTime.hour(hour);
            }
            if (minute !== undefined) {
                newTime = newTime.minute(minute);
            }
            model.value = DeliveryDateTime.typeOf(type, newTime);
            break;
        }
        default:
            throw new Error('type is invalid.');
    }
};

/**
 * 値をemitします。
 * @private
 */
const emitValue = (value: DateTimePickerValue): void => {
    emits('input', value);
    emits('change', value);
};

//
// イベント
//
/**
 * ドロップダウンの開く／閉じるが切り替わった際に呼び出されます。
 */
const onVisibleChange = (visible: boolean): void => {
    isMenuOpen.value = visible;
};

/**
 * カレンダーパネルで前月/前年をクリックした際に呼び出されます。
 */
const onClickPanelPrevButton = (render: RenderHeader): void => {
    if (!render.onChange) {
        return;
    }
    render.onChange(calendarValue.value.clone().subtract(1, render.type as 'year' | 'month'));
};

/**
 * カレンダーの年/月表示を切り替えます
 */
const onClickPanelTypeChange = (): void => {
    calendarViewMode.value = calendarViewMode.value === 'month' ? 'year' : 'month';
};

/**
 * カレンダーパネルで次月/次年をクリックした際に呼び出されます。
 */
const onClickPanelNextButton = (render: RenderHeader): void => {
    if (!render.onChange) {
        return;
    }
    render.onChange(calendarValue.value.clone().add(1, render.type as 'year' | 'month'));
};

/**
 * カレンダーパネルから日付を選択した際に呼び出されます。
 */
const onSelectCalendar = (selectedDate: moment.Moment): void => {
    // 月を選択する表示モードの状態で、なにか項目を選択した時は、日付選択カレンダーに表示モードを変更
    if (calendarViewMode.value === 'year') {
        calendarViewMode.value = 'month';
        return;
    }

    if (model.value === null) {
        const nextHour = moment().add(1, 'hour');
        const today = moment().startOf('day');
        const targetDay = selectedDate.startOf('day');
        const isToday = today.diff(targetDay) === 0;

        const criteria = dayjs((isToday ? nextHour : selectedDate.clone()).toDate());

        if (timeHourEnumValues.value.includes(TimeHourEnum.Day)) {
            model.value = DeliveryDateTime.typeOf('Day', criteria);
        } else {
            model.value = DeliveryDateTime.typeOf('Hourly', criteria);
        }
    } else {
        const [minTime, maxTime] = model.value.rawValuesAsString(Const.INTERNAL_TIME_FORMAT);
        model.value = DeliveryDateTime.of(
            `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ minTime }`,
            `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ maxTime }`
        );
    }
};

/**
 * カレンダーパネルから日付を変更した際に呼び出されます。
 */
const onChangeCalendar = (changedDate: moment.Moment): void => {
    calendarValue.value = changedDate;
};

/**
 * 時(Hour)をクリックした際に呼び出されます。
 */
const onClickSelectHour = (timeHour: TimeHourEnum): void => {
    if (model.value === null) {
        throw new Error('require set the date before select time.');
    } else if (isDisabledHour(timeHour.type, timeHour.hour)) {
        return;
    }

    if (timeHour.type === 'Hourly' && model.value.type === 'Just') {
        setTime(model.value.type, timeHour.hour);
    } else {
        setTime(timeHour.type, timeHour.hour);
    }
};

/**
 * 分(Minute)をクリックした際に呼び出されます。
 */
const onClickSelectMinute = (timeMinute: TimeMinuteEnum): void => {
    if (
        model.value === null ||
        model.value.type === 'Day' ||
        model.value.type === 'Morning' ||
        model.value.type === 'Afternoon'
    ) {
        throw new Error('require set the date before select time.');
    }
    const [hour] = model.value.rawValuesAsString('HH');
    if (timeMinute.type === 'Hourly') {
        setTime(timeMinute.type, Number(hour));
    } else if (timeMinute.type === 'Just' && timeMinute.minute !== undefined) {
        setTime(timeMinute.type, Number(hour), timeMinute.minute);
    }
};

/**
 * 指定なしボタンを押下した際に呼び出されます。
 */
const onClickDeselect = (): void => {
    model.value = null;
    closeDropdown();
};

/**
 * 確定ボタンを押下した際に呼び出されます。
 */
const onClickOk = (): void => {
    closeDropdown();
    return;
};

</script>

<template>
    <a-dropdown class="ui-datetime-select" :visible="isMenuOpen" :disabled="disabled" :trigger="['click']"
                :placement="placement" @visibleChange="onVisibleChange">
        <div class="ant-select" :class="selectStyleClass">
            <div class="ant-select-selection ant-select-selection--single" :style="borderStyle">
                <div class="ant-select-selection__rendered">
                    <!-- placeholder -->
                    <div class="ant-select-selection__placeholder"
                         :style="{ display: model === null ? 'block' : 'none' }"
                         style="user-select: none;">{{ placeholder }}
                    </div>
                    <!-- value for single -->
                    <div class="ant-select-selection-selected-value"
                         :style="{ display: model === null ? 'none' : 'block' }">{{ labelText }}
                    </div>
                </div>
                <span v-if="arrow" class="ant-select-arrow"><a-icon class="ant-select-arrow-icon" type="down"/></span>
            </div>
        </div>

        <!-- Dropdown Contents -->
        <template #overlay>
            <div class="dropdown-container">
                <div class="datetime-container">
                    <a-calendar class="calendar"
                                :value="calendarValue"
                                :mode="calendarViewMode"
                                :fullscreen="false"
                                :valid-range="toMomentValidRange"
                                :disabled-date="disabledDateInternal"
                                @change="onChangeCalendar"
                                @select="onSelectCalendar">
                        <template #headerRender="render">
                            <nav>
                                <ul class="calendar-header">
                                    <li class="calendar-header__button">
                                        <a-button type="link" size="large" @click="onClickPanelPrevButton(render)">
                                            <a-icon type="left"/>
                                        </a-button>
                                    </li>
                                    <li class="calendar-header__title"><span class="calendar-header__title__text"
                                                                             @click="onClickPanelTypeChange">{{
                                            calendarHeaderTitle
                                        }}</span></li>
                                    <li class="calendar-header__button">
                                        <a-button type="link" size="large" @click="onClickPanelNextButton(render)">
                                            <a-icon type="right"/>
                                        </a-button>
                                    </li>
                                </ul>
                            </nav>
                        </template>
                        <template #dateFullCellRender="date">
                            <div class="ant-fullcalendar-date calendar__date"
                                 :class="{ 'calendar__date--selected': isSelectedDate(date) }">
                                <div class="ant-fullcalendar-value">{{ date.format('D') }}</div>
                                <div class="ant-fullcalendar-content">
                                    <slot name="dateCellRender"></slot>
                                </div>
                            </div>
                        </template>
                    </a-calendar>
                    <div class="time-select time-select--hour"
                         :class="{ 'time-select--closed': !shouldOpenHourSelectPanel }">
                        <div class="time-select-header">時</div>
                        <ul role="menu"
                            tabindex="0"
                            class="time-dropdown-menu ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content">
                            <li v-for="item in timeHourEnumValues"
                                :key="item.code"
                                role="menuitem"
                                class="time-dropdown-menu-item ant-dropdown-menu-item"
                                :class="{
                            'time-dropdown-menu-item-selected ant-select-dropdown-menu-item-selected': isSelectedHour(item),
                            'time-dropdown-menu-item-disabled ant-select-dropdown-menu-item-disabled': isDisabledHour(item.type, item.hour)
                         }"
                                @click="onClickSelectHour(item)">{{ item.label }}
                            </li>
                        </ul>
                    </div>
                    <div class="time-select time-select--minute"
                         :class="{ 'time-select--closed': !shouldOpenMinuteSelectPanel }">
                        <div class="time-select-header">分</div>
                        <ul role="menu"
                            tabindex="0"
                            class="time-dropdown-menu ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content">
                            <li v-for="item in timeMinuteEnumValues"
                                :key="item.code"
                                role="menuitem"
                                class="time-dropdown-menu-item ant-dropdown-menu-item"
                                :class="{
                            'time-dropdown-menu-item-selected ant-select-dropdown-menu-item-selected': isSelectedMinute(item),
                            'time-dropdown-menu-item-disabled ant-select-dropdown-menu-item-disabled': isDisabledMinute(item)
                         }"
                                @click="onClickSelectMinute(item)">{{ item.label }}
                            </li>
                        </ul>
                    </div>
                </div>
                <footer class="footer">
                    <nav>
                        <ul class="actions">
                            <li v-if="allowClear">
                                <a-button size="small" @click="onClickDeselect">日時を指定しない</a-button>
                            </li>
                            <li class="actions__ok">
                                <a-button type="primary" @click="onClickOk">確定</a-button>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </div>
        </template>
    </a-dropdown>
</template>

<style scoped lang="less">
.ui-datetime-select {
    &.ant-input {
        cursor: pointer;
    }
}

.dropdown-container {
    border-radius: @border-radius-base;
    background-color: @select-background;
    box-shadow: @box-shadow-base;
}

.datetime-container {
    display: flex;
    width: auto;
}

// カレンダースタイル部
.calendar {
    width: 256px;

    ::v-deep .ant-fullcalendar-month-panel-cell.ant-fullcalendar-month-panel-cell-disabled {
        .ant-fullcalendar-month .ant-fullcalendar-value {
            color: @disabled-color;
        }
        &.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
            background: rgba(0, 0, 0, 0.1);;
        }
    }

    ::v-deep .ant-fullcalendar-disabled-cell {
        .ant-fullcalendar-date {
            color: @disabled-color;
            background: @disabled-bg;
            border: @border-width-base @border-style-base transparent;

            .ant-fullcalendar-value:hover {
                background: @disabled-bg;
            }
        }

        &.ant-fullcalendar-selected-day .ant-fullcalendar-date .ant-fullcalendar-value {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    ::v-deep .ant-fullcalendar-last-month-cell:not(.ant-fullcalendar-disabled-cell),
    ::v-deep .ant-fullcalendar-next-month-btn-day:not(.ant-fullcalendar-disabled-cell) {
        .ant-fullcalendar-value {
            color: @disabled-color;
            color: @text-color-secondary;
        }
    }

    ::v-deep .ant-fullcalendar-selected-day .ant-fullcalendar-value {
        color: @text-color;
        background: transparent;
    }

    .calendar__date {
        &--selected .ant-fullcalendar-value {
            color: @text-color-inverse;
            background: @primary-color;
        }
    }
}

.calendar-header {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    .calendar-header__button {
        .ant-btn {
            width: 40px;
            padding: 0;
            color: @text-color;

            &:hover {
                color: @primary-color;
            }
        }
    }

    .calendar-header__title {
        flex: 1;
        color: @heading-color;

        .calendar-header__title__text {
            display: inline-block;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            user-select: none;
            transition: color 300ms;

            &:hover {
                color: @primary-color;
            }
        }
    }
}

.time-select {
    width: 96px;
    border-left: @border-style-base @border-width-base @color-neutral-5;
    transition: width 300ms;

    &.time-select--closed {
        width: 0;
        border-left: none;
    }
}

.time-select-header {
    border-bottom: @border-style-base @border-width-base @color-neutral-5;
    height: 40px + 1px;
    line-height: 40px;
    text-align: center;
    color: @heading-color;
    overflow: hidden;
}

.time-dropdown-menu {
    width: 100%;
    max-height: 272px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    overflow-x: hidden;

    .time-dropdown-menu-item {
        padding-top: 3px;
        padding-bottom: 3px;

        &:hover:not(.time-dropdown-menu-item-disabled) {
            background-color: @item-hover-bg;
        }

        &.time-dropdown-menu-item-selected:not(.time-dropdown-menu-item-disabled) {
            background-color: @select-item-active-bg;
        }
    }
}

.footer {
    border-top: @border-style-base @border-width-base @color-neutral-5;
    padding: 10px 12px;
}

.actions {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__ok {
        margin-left: auto;
    }
}
</style>
