/**
 * 成約予測フォーム
 */
export class PredictionBaggageAgreementForm {
    departure: string;
    departurePref: { code?: string };
    arrival: string;
    arrivalPref: { code?: string };
    type: string;
    truckWeight: { code?: string };
    truckModel: { code?: string };
    share: boolean;
    express: boolean;
    freight?: string;

    constructor(param: Partial<PredictionBaggageAgreementForm>) {
        this.departure = param?.departure ?? '';
        this.departurePref = param?.departurePref ?? {};
        this.arrival = param?.arrival ?? '';
        this.arrivalPref = param.arrivalPref ?? {};
        this.type = param?.type ?? '';
        this.truckWeight = param?.truckWeight ?? {};
        this.truckModel = param?.truckModel ?? {};
        this.share = param?.share ?? false;
        this.express = param?.express ?? false;
        this.freight = param?.freight;
    }
}
