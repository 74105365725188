import { Component, Prop, Vue } from 'vue-property-decorator';
import * as companyTypes from '@/vuex/modules/company/types';
import * as truckTypes from '@/vuex/modules/truck/types';
import { PhoneUtil, RegionUtil, URLUtil, Util } from '@/util';
import { PaginatedList } from '@/types/paginated-list';
import { Enum } from '@/types/enum';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { gtm } from '@/gtm';
import { Karte } from '@/karte';

type TruckWithCompanyRecord = truckTypes.Truck & { company?: companyTypes.CompanyProfile };

interface TruckWithCompanyList extends PaginatedList {
    data: TruckWithCompanyRecord[];
}

@Component({
    components: {
        UiDateTimeLocationLabel,
    },
})
export default class SearchResultListView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly loading: boolean;
    @Prop()
    declare readonly truckList?: TruckWithCompanyList;

    get truckListData(): Array<TruckWithCompanyRecord> {
        return this.truckList?.data ?? [];
    }

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '',
            key: 'action',
            scopedSlots: { customRender: 'action' },
            align: 'center',
            width: 64,
        },
        {
            title: '企業名',
            key: 'companyName',
            scopedSlots: { customRender: 'companyName' },
            width: 192,
            ellipsis: true,
        },
        {
            title: '空車日時・空車地 / 行先日時・行先地',
            key: 'departureArrival',
            scopedSlots: { customRender: 'departureArrival' },
            align: 'center',
            width: 352,
        },
        {
            title: '運賃',
            key: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 112,
        },
        {
            title: '重量',
            key: 'truckWeight',
            scopedSlots: { customRender: 'truckWeight' },
            width: 80,
        },
        {
            title: '車種',
            key: 'truckModel',
            scopedSlots: { customRender: 'truckModel' },
            width: 108,
        },
        {
            title: '備考',
            key: 'description',
            scopedSlots: { customRender: 'description' },
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 車種
     */
    truckModelText(record: TruckWithCompanyRecord): string {
        return record.truckModel.label ?? '';
    }

    /**
     * 重量
     */
    truckWeightText(record: TruckWithCompanyRecord): string {
        return record.truckWeight.label ?? '';
    }

    /**
     * 運賃
     */
    freightText(record: TruckWithCompanyRecord): string {
        if (!record.freight) {
            return '要相談';
        }
        return `${ Util.formatNumber(record.freight) }円`;
    }

    /**
     * 企業名
     */
    companyName(record: TruckWithCompanyRecord): string {
        return record.company?.name.kanji ?? '';
    }

    /**
     * 企業の連絡先電話番号
     */
    companyPhone(record: TruckWithCompanyRecord): string {
        return PhoneUtil.format(record.company?.phone.number ?? '');
    }

    /**
     * 追加で設定された都道府県
     */
    additionalPrefectureText(additionalPrefecture: Array<Enum<PrefectureEnumCode>>): string {
        if (additionalPrefecture.length === 0) {
            return '';
        }
        const regions = RegionUtil.parseRegionsFromPrefectures(additionalPrefecture.map((each) => each.code));
        return `${ regions.map((each) => each.label).join('、') }も対応可能`;
    }

    /**
     * ツールチップ表示用の都道府県テキストラベル
     */
    prefectureTooltipText(
        prefectureLabel: string,
        city: string | undefined,
        additionalPrefecture: Array<Enum<PrefectureEnumCode>>
    ): string {
        const additionalPrefectureText = this.additionalPrefectureText(additionalPrefecture);
        if (!additionalPrefectureText) return '';
        return `${ prefectureLabel }${ city ?? '' }の他、${ additionalPrefectureText }`;
    }

    /**
     * 連絡先ツールチップをクリックした際に呼び出されます。
     */
    onVisibleChangeContactsTooltip(visible: boolean, id: number): void {
        if (visible) {
            Karte.trackClickTruckContact(id);
            gtm.setEvent('open_truck_contacts', { id });
        }
    }

    /**
     * 企業のリンクをクリックすると呼び出されます。
     */
    async onClickCompanyLink($event: Event, record: TruckWithCompanyRecord): Promise<void> {
        // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
        $event.stopPropagation();
        const currentCompanyId = URLUtil.parseQueryValue(this.$route.query.companyId);
        const currentTruckId = URLUtil.parseQueryValue(this.$route.query.truckId);
        const companyId = `${ record.companyId }`;
        const truckId = `${ record.id }`;
        if (currentCompanyId === companyId && currentTruckId === truckId) {
            return;
        }
        await this.$router.push({ name: 'TruckSearch', query: { companyId, truckId } });
    }
}
