import { ref } from 'vue';
import { miscApi, PrefCityResult } from '@/repository/api/internal/misc';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';

/**
 * 市区町村データを扱う機能を提供します
 */
export const useCityData = () => {
    const cityData = ref<PrefCityResult[]>([]);

    /**
     * 指定した都道府県コードから該当する市区町村リストを取得します。
     * @param prefCode
     */
    const load = async (prefCode: PrefectureEnumCode): Promise<void> => {
        cityData.value = await miscApi.listCity(prefCode);
    };

    const clear = () => {
        cityData.value = [];
    };

    return {
        state: {
            cityData,
        },
        load,
        clear,
    };
};
