import { Modal } from 'ant-design-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route, Route as VueRoute } from 'vue-router/types/router';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
import store from '@/vuex/store';
import _ from 'lodash';
import { PageUtil, Util } from '@/util';
// @ts-ignore
import BaggageSearchContainerView from '@/components/Baggage/Search/Container';
// @ts-ignore
import BaggageSearchDetailDrawer from '@/components/Baggage/Search/DetailDrawer';
// @ts-ignore
import BaggageSearchExcludedCompany from '@/components/Baggage/Search/ExcludedCompany';
import { BaggageDetailContainer } from '@/vuex/modules/baggage/baggage-detail-container';
import {
    clearDetailBaggage,
    loadDetailBaggage,
    onClickCloseBaggageDetailDrawer,
    markFavorite,
    unmarkFavorite,
    onClickAgree,
    markUnderNegotiation,
    unmarkUnderNegotiation,
    onClickPrintBaggage,
    onClickCompanyName,
    setPageTitle,
    onClickGround,
    requestNegotiation, loadNegotiationRequest,
} from '@/pages/Baggage/Search/helpers';

const baggageMod = namespace('baggage');
const accountMod = namespace('account');
const negotiationMod = namespace('negotiation');

@Component({
    title: '荷物検索',
    components: {
        BaggageSearchContainerView,
        BaggageSearchDetailDrawer,
        BaggageSearchExcludedCompany,
    },
    beforeRouteEnter: BaggageSearchPage.beforeRouteEnter,
    beforeRouteLeave: BaggageSearchPage.beforeRouteLeave,
})
export default class BaggageSearchPage extends Vue {
    // ======================================================
    // Vuex Bindings
    // ======================================================

    // 荷物検索コンテナ
    @baggageMod.Getter(baggageTypes.GETTER.SEARCH_CONTAINERS)
    readonly BAGGAGE_SEARCH_CONTAINERS!: baggageTypes.BaggageSearchContainer[];

    // よく使う検索条件をロード中か否か
    @baggageMod.Getter(baggageTypes.GETTER.LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS)
    readonly LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS?: boolean;

    // よく使う検索条件リスト
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_FORM_SEARCH_CONDITION_LIST)
    readonly SAVED_BAGGAGE_SEARCH_CONDITIONS!: baggageTypes.BaggageSearchCondition[];

    // 荷物検索除外企業リスト
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST)
    readonly BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST?: baggageTypes.BaggageSearchExcludedCompany[];

    // 荷物詳細コンテナ
    @baggageMod.Getter(baggageTypes.GETTER.SEARCH_BAGGAGE_DETAIL_CONTAINER)
    readonly DETAIL_CONTAINER?: BaggageDetailContainer;

    // 商談リクエスト
    @negotiationMod.Getter(negotiationTypes.GETTER.NEGOTIATION_REQUESTS)
    readonly NEGOTIATION_LIST?: negotiationTypes.Negotiation[];

    // アカウント情報
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly MY_PROFILE?: accountTypes.Profile;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageId?: number;

    @Prop()
    declare readonly refTag?: string;

    /**
     * 自社企業IDを取得します。
     */
    get myCompanyId(): number {
        return this.MY_PROFILE?.companyId ?? 0;
    }

    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get drawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return !_.isNaN(Number(this.baggageId));
    }

    get selectedSearchContainer(): baggageTypes.BaggageSearchContainer {
        return this.BAGGAGE_SEARCH_CONTAINERS[this.selectedContainerIndex];
    }

    get baggageList(): baggageTypes.BaggageList | undefined {
        return this.selectedSearchContainer?.result?.baggageList;
    }

    // 荷物詳細ページ用のプロパティ
    get baggage(): baggageTypes.Baggage | undefined {
        return this.DETAIL_CONTAINER?.baggage;
    }

    get companyProfile(): companyTypes.CompanyProfile | undefined {
        return this.DETAIL_CONTAINER?.companyProfile;
    }

    get companyConfidence(): companyTypes.CompanyConfidence | undefined {
        return this.DETAIL_CONTAINER?.confidence;
    }

    get companyStatistics(): companyTypes.CompanyStatistics | undefined {
        return this.DETAIL_CONTAINER?.statistics;
    }

    get markedFavorite(): boolean {
        return this.DETAIL_CONTAINER?.markedFavorite ?? false;
    }

    get referenceFreight(): baggageTypes.BaggageFreightMaster | undefined {
        return this.DETAIL_CONTAINER?.referenceFreight;
    }

    get negotiation(): negotiationTypes.Negotiation | undefined {
        return (this.NEGOTIATION_LIST ?? []).find(each => each.baggageId === this.DETAIL_CONTAINER?.baggage.id);
    }

    // ======================================================
    // Data
    // ======================================================
    routeUpdating = false;
    private selectedContainerIndex = 0;

    // ======================================================
    // Methods
    // ======================================================

    //
    // Lifecycle methods
    //

    async created(): Promise<void> {
        // 荷物検索コンテナを初期化
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.INIT_SEARCH_CONTAINERS }`);
        // 除外企業リストの読み込み
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST }`);
    }

    async mounted(): Promise<void> {
        setPageTitle(this.$route);
        await Promise.all([
            // よく使う検索条件をロード
            this.$store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_FORM_SEARCH_CONDITION }`),
            // 初回検索
            this.$store.dispatch(`baggage/${ baggageTypes.ACTION.FIRST_SEARCH }`, this.selectedContainerIndex),
        ]);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageSearchPage>
    ): Promise<void> {
        next(async () => {
            //
            // ドロワー表示データ処理
            //
            const { baggageId, ref } = to.query;
            if (baggageId) {
                await loadDetailBaggage(Number(baggageId), Util.castAsString(ref))
                    .then(() => BaggageSearchPage.markRead(Number(baggageId)))
                    .catch(() => next({ name: 'NotFound' }));
            }
        });
    }

    async beforeRouteUpdate(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageSearchPage>
    ): Promise<void> {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            next(false);
            return;
        }
        //
        // ドロワー表示データ処理
        //
        const { baggageId, ref } = to.query;
        // 荷物詳細→荷物一覧に戻ってきたとき
        if (!baggageId) {
            // ページタイトルを設定
            setPageTitle(to);
            // 荷物詳細をクリア
            await clearDetailBaggage();
            next();
            return;
        }
        // 荷物詳細を開いた状態で別の荷物詳細へ遷移しようとしたとき
        if (this.drawerVisibility && _from.query.baggageId !== baggageId) {
            this.routeUpdating = true;
            await clearDetailBaggage();
        }

        next();

        // 少しだけドロワーの開閉アニメーションを見せるためにdelay
        _.delay(() => (this.routeUpdating = false), 100);

        await loadDetailBaggage(Number(baggageId), Util.castAsString(ref))
            .then(() => {
                // ページタイトルを設定
                setPageTitle(to);
            })
            .then(() => BaggageSearchPage.markRead(Number(baggageId)))
            .catch(() => {
                this.$message.error('アクセスしようとした荷物情報は見つかりません。');
            });
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageSearchPage>
    ): Promise<void> {
        await Promise.all([
            clearDetailBaggage()
        ]);
        next();
    }

    async destroyed(): Promise<void> {
        await BaggageSearchPage.clearBaggageSearchContainers();
    }

    //
    // Event handlers
    //
    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    private onClickCloseBaggageDetailDrawer(): Promise<Route> {
        return onClickCloseBaggageDetailDrawer(this.$route);
    }

    /**
     * 荷物をお気に入り保存する。
     * @param baggageId
     */
    async markFavorite(baggageId: number): Promise<void> {
        await markFavorite(baggageId, this.baggageId);
    }

    /**
     * 荷物をお気に入り解除する。
     * @param baggageId
     */
    async unmarkFavorite(baggageId: number): Promise<void> {
        await unmarkFavorite(baggageId, this.baggageId);
    }

    /**
     * 成約が押下された際に呼び出されます。
     */
    async onClickAgree(baggageId: number): Promise<void> {
        await onClickAgree(baggageId);
    }

    /**
     * 「商談中にする」ボタンが押下された際に呼び出されます。
     */
    async markUnderNegotiation(baggageId: number): Promise<void> {
        await markUnderNegotiation(baggageId);
    }

    /**
     * 「商談中を解除」ボタンが押下された際に呼び出されます。
     */
    async unmarkUnderNegotiation(baggageId: number): Promise<void> {
        await unmarkUnderNegotiation(baggageId);
    }

    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        await onClickPrintBaggage(baggageId);
    }

    /**
     * 企業名が押下された際に呼び出されます。
     */
    async onClickCompanyName(companyId: string): Promise<void> {
        await onClickCompanyName(companyId);
    }

    /**
     * 商談リクエストを送る際に呼び出されます。
     */
    async onRequestNegotiation(baggageId: number, message: string | undefined): Promise<void> {
        await requestNegotiation(baggageId, message);
        await loadNegotiationRequest(baggageId);
    }

    /**
     * 荷物を検索する。
     */
    async search(): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.SEARCH_BAGGAGE }`, this.selectedContainerIndex);
        this.scrollToSearchResultsSection();
    }

    /**
     * 自動検索をonにした時に呼び出されます。
     */
    async startAutoSearch(): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.START_AUTO_SEARCH_BAGGAGE }`, this.selectedContainerIndex);
    }

    /**
     * 自動検索をoffにした時に呼び出されます。
     */
    async stopAutoSearch(): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.STOP_AUTO_SEARCH_BAGGAGE }`, this.selectedContainerIndex);
    }

    /**
     * 検索条件を更新する。
     * @param newValue
     */
    async updateSearchForm(newValue: baggageTypes.BaggageSearchForm): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.UPDATE_BAGGAGE_SEARCH_FORM }`, [this.selectedContainerIndex, newValue]);
    }

    /**
     * 検索条件をリセットする。
     */
    async resetSearchForm(): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.RESET_BAGGAGE_SEARCH_FORM }`, this.selectedContainerIndex);
    }

    /**
     * 検索条件をよく使う検索条件として保存する。
     */
    async saveSearchCondition(): Promise<void> {
        const notifyFailedKey = 'SAVE_SEARCH_CONDITION_FAILED';
        this.$notification.close(notifyFailedKey);

        const onSuccess = () => {
            this.$message.success('現在の検索条件を「よく使う検索条件」として保存しました。');
        };
        const onError = (reason: baggageTypes.BaggageSearchConditionSaveError) => {
            switch (reason) {
                case 'REACHED_LIMIT':
                    Modal.error({
                        title: '「よく使う検索条件」の登録上限に達しております。',
                        content: 'なにか他の検索条件を削除してから登録してください。',
                    });
                    break;
                case 'MISSING_FIELDS':
                    this.$notification.error({
                        key: notifyFailedKey,
                        message: '「よく使う検索条件」の保存に必要な項目が入力されていません。',
                        description: '発地、着地、車両重量、車種、運賃のいずれかは指定してください。',
                    });
                    break;
                case 'FAILED_TO_SAVE':
                    this.$notification.error({
                        key: notifyFailedKey,
                        message: '「よく使う検索条件」に保存できませんでした。',
                        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                    });
                    break;
            }
        };

        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.SAVE_BAGGAGE_SEARCH_CONDITION }`, this.selectedContainerIndex)
            .then(onSuccess)
            .catch(onError);
    }

    /**
     * よく使う荷物検索条件から復元する。
     * @param conditionId よく使う荷物検索条件ID
     */
    async selectSavedSearchCondition(conditionId: number): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.RESTORE_BAGGAGE_SEARCH_FORM }`, [this.selectedContainerIndex, conditionId]);
    }

    /**
     * ソートキーを変更する。
     * @param value sortKey
     */
    async updateSortKey(value: string): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.UPDATE_BAGGAGE_SEARCH_SORT_KEY }`, [this.selectedContainerIndex, value]);
    }

    /**
     * ソート方向を変更する。
     * @param value sortDirection
     */
    async updateSortDirection(value: string): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.UPDATE_BAGGAGE_SEARCH_SORT_DIRECTION }`, [this.selectedContainerIndex, value]);
    }

    /**
     * 検索結果をリロードする。
     */
    async reloadSearchResult(): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.RELOAD_SEARCH_RESULT }`, this.selectedContainerIndex);
    }

    /**
     * 検索結果のページ、ページあたり件数を変更する。
     */
    async changeSearchResultPage(param: { pageNo: number, pageSize: number }): Promise<void> {
        await this.$store.dispatch(`baggage/${ baggageTypes.ACTION.CHANGE_SEARCH_RESULT_PAGE }`, [this.selectedContainerIndex, param]);
        this.scrollToSearchResultsSection();
    }

    /**
     * 荷物が選択された際に呼び出されます。
     */
    async selectBaggage(baggageId: number): Promise<void> {
        const currentBaggageId = this.$route.query.baggageId;
        if (currentBaggageId !== `${ baggageId }`) {
            await this.$router.push({ path: '/baggage/search', query: { baggageId: `${ baggageId }` } });
        }
        (this.$refs.baggageDrawer as BaggageSearchDetailDrawer).activeTabKey = 'baggage';
    }

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        await onClickGround(this.routeUpdating, this.drawerVisibility, this.$route);
    }

    //
    // Helper methods
    //
    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    private scrollToSearchResultsSection(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
    }

    private async reloadBaggageIfNeeded(id: number): Promise<void> {
        if (Number(this.baggageId) === id) {
            await loadDetailBaggage(id, undefined);
        }
    }

    /**
     * 荷物検索コンテナをクリアします。
     */
    private static clearBaggageSearchContainers(): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_SEARCH_CONTAINERS }`);
    }

    /**
     * 荷物を既読登録します。
     */
    private static markRead(id: number): void {
        // 詳細画面への遷移をスムーズにするため、既読処理はawaitしない
        store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_READ }`, id)
            .catch(() => { /* エラーは無視する */
            });
    }
}
