import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';

@Component
export default class AccountNameRegisterer extends Vue {
    @Prop({ default: false })
    declare readonly focus: boolean;
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get name(): string {
        return this.value?.member.name ?? '';
    }

    set name(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.member.name = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    mounted(): void {
        if (this.focus) {
            const el = this.$refs.memberName as HTMLInputElement;
            if (!el) {
                return;
            }
            el.focus();
        }
    }

    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.name = this.name.trim();
    }

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.member.name?.trim()) {
            callback('担当者名を入力してください。');
        } else if (this.value.member.name.length > 60) {
            callback('担当者名は60文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
