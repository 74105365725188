import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { BaggageDetailUtil, DateUtil, Util } from '@/util';
import { PrefectureEnum } from '@/enums/prefecture.enum';
import { Const } from '@/const';
// @ts-ignore
import AgreementGuaranteeUsage from '@/components/Agreement/GuaranteeUsage';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import _ from 'lodash';
import { Enum } from '@/types/enum';

@Component({
    components: {
        AgreementGuaranteeUsage,
    },
})
export default class AgreementRegisterConfirmationModal extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ default: false })
    declare readonly value: boolean;
    @Prop()
    declare readonly form?: types.AgreementForm;
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;
    @Prop({ default: false })
    declare readonly loading: boolean;
    @Prop()
    declare readonly existsGuarantee?: boolean;

    /**
     * 表示/非表示
     */
    get visible(): boolean {
        return this.value;
    }

    set visible(newValue: boolean) {
        this.$emit('input', newValue);
    }

    /**
     * モーダルのタイトルテキスト
     */
    get modalTitle(): string {
        if (!this.existsGuarantee) {
            return '成約確認';
        }
        return this.form?.guarantee
            ? '成約確認（運賃全額保証サービスを利用して成約）'
            : '成約確認（運賃全額保証サービスを利用しないで成約）';
    }

    /**
     * 荷物ID
     */
    get baggageId(): string {
        return this.baggage?.id.toString() ?? '';
    }

    /**
     * 出発日時
     */
    get departure(): string {
        if (!this.form) return '';
        return DeliveryDateTime.of(this.form.departureMin, this.form.departureMax)?.format() ?? '';
    }

    /**
     * 到着日時
     */
    get arrival(): string {
        if (!this.form) return '';
        return DeliveryDateTime.of(this.form.arrivalMin, this.form.arrivalMax)?.format() ?? '';
    }

    /**
     * 出発地
     */
    get departureLocation(): string {
        if (!this.form) return '';
        return [
            AgreementRegisterConfirmationModal.prefectureText(this.form.departurePref.code),
            this.form.departureCity,
            this.form.departureAddress ?? '',
        ].join('');
    }

    /**
     * 到着地
     */
    get arrivalLocation(): string {
        if (!this.form) return '';
        return [
            AgreementRegisterConfirmationModal.prefectureText(this.form.arrivalPref.code),
            this.form.arrivalCity,
            this.form.arrivalAddress ?? '',
        ].join('');
    }

    /**
     * 積み時間
     */
    get loadingTimeNote(): string | undefined {
        return this.baggage?.loadingTimeNote;
    }

    /**
     * 卸し時間
     */
    get unloadingTimeNote(): string | undefined {
        return this.baggage?.unloadingTimeNote;
    }

    /**
     * 荷種
     */
    get baggageType(): string {
        return this.form?.type ?? '';
    }

    /**
     * パレット枚数（目安）
     */
    get paletteCount(): string | undefined {
        const shape = BaggageShapeEnum.valueOf(this.form?.shape?.code);
        return BaggageDetailUtil.paletteCount(
            shape?.code,
            this.form?.paletteCount ? Util.toNumber(this.form?.paletteCount) : undefined,
        );
    }

    /**
     * パレットサイズ
     */
    get paletteSize(): string | undefined {
        const shape = BaggageShapeEnum.valueOf(this.form?.shape?.code);
        return BaggageDetailUtil.paletteSize(
            shape?.code,
            this.form?.paletteHeight ? Util.toNumber(this.form?.paletteHeight) : undefined,
            this.form?.paletteWidth ? Util.toNumber(this.form?.paletteWidth) : undefined
        );
    }

    /**
     * 荷物の個数（目安）
     */
    get totalCount(): string | undefined {
        const shape = BaggageShapeEnum.valueOf(this.form?.shape?.code);
        return BaggageDetailUtil.totalCount(
            shape?.code,
            this.form?.totalCount ? Util.toNumber(this.form?.totalCount) : undefined
        );
    }

    /**
     * 荷物の体積
     */
    get totalVolume(): string | undefined {
        const shape = BaggageShapeEnum.valueOf(this.form?.shape?.code);
        return BaggageDetailUtil.totalVolume(
            shape?.code,
            this.form?.totalVolume ? Util.toNumber(this.form?.totalVolume) : undefined
        );
    }

    /**
     * 総重量
     */
    get totalWeight(): string | undefined {
        return BaggageDetailUtil.totalWeight(
            this.form?.totalWeight ? Util.toNumber(this.form?.totalWeight) : undefined
        );
    }

    /**
     * ドライバー作業
     */
    get handling(): string | undefined {
        return BaggageDetailUtil.handling(
            this.form?.loading?.code,
            this.form?.unloading?.code
        );
    }

    /**
     * 積合
     */
    get shared(): string {
        return this.baggage?.share ? '○' : '×';
    }

    /**
     * 車種
     */
    get truckModel(): string {
        return this.baggage?.truckModel?.label ?? '';
    }

    /**
     * 重量
     */
    get truckWeight(): string {
        return this.baggage?.truckWeight?.label ?? '';
    }

    /**
     * 車両指定項目を表示するか
     */
    get hasSpecifiedTruck(): boolean {
        return [this.baggage?.truckHeight, this.baggage?.truckWidth, this.baggage?.largeTruckFlg].some(each => !_.isNil(each));
    }

    /**
     * 床高
     */
    get truckHeight(): Enum | undefined {
        return this.baggage?.truckHeight;
    }

    /**
     * 車幅
     */
    get truckWidth(): Enum | undefined {
        return this.baggage?.truckWidth;
    }

    /**
     * 大型可否
     */
    get largeTruckFlg(): boolean | undefined {
        return this.baggage?.largeTruckFlg;
    }

    /**
     * 車両指定表示用配列
     */
    get specifiedTruckItems(): { label: string, value: string }[] {
        const items = [];
        if (this.truckHeight) {
            items.push({ label: '床高', value: this.truckHeight.label ?? '' });
        }
        if (this.truckWidth) {
            items.push({ label: '車幅', value: this.truckWidth.label ?? '' });
        }
        if (!_.isNil(this.largeTruckFlg)) {
            items.push({ label: '大型可否', value: this.largeTruckFlg ? '可' : '不可' });
        }
        return items;
    }

    /**
     * 必要装備
     */
    get truckEquipment(): string | undefined {
        return this.baggage?.truckEquipment;
    }

    /**
     * 運賃
     */
    get freight(): string {
        if (!this.form) return '';
        return `${Util.formatNumber(Util.toNumber(this.form.freight))}円`;
    }

    /**
     * 高速代
     */
    get highwayFare(): string {
        if (!this.form) return '';

        if (!this.form.highwayFareFlg) return 'なし';

        const value =
            typeof this.form.highwayFare === 'string' ? Util.toNumber(this.form.highwayFare) : this.form.highwayFare;
        return value === 0 ? '金額未定' : `${ Util.formatNumber(value) }円`;
    }

    /**
     * 受注担当者
     */
    get truckStaffName(): string {
        return this.form?.truckStaffName ?? '';
    }

    /**
     * 備考
     */
    get description(): string {
        return this.form?.description ?? '';
    }

    /**
     * 入金予定日
     */
    get paymentDate(): string {
        return this.form?.paymentDate
            ? DateUtil.parseDateText(this.form.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT)
            : '';
    }

    /**
     * 運賃全額保証サービス
     */
    get guarantee(): string {
        return this.form?.guarantee ?? false ? '利用する' : '利用しない';
    }

    // ======================================================
    // Functions
    // ======================================================
    private static prefectureText(code?: string) {
        if (!code) return '';
        return PrefectureEnum.values.find((each) => each.code === code)?.label ?? '';
    }
}
