import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportLatePaymentForm } from '@/vuex/modules/help/types';
import { Util } from '@/util';

@Component
export default class ReportLatePaymentFreightView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly form?: ReportLatePaymentForm;

    get freight(): string {
        const freight = this.form?.freight ? Util.toNumber(this.form.freight) : 0;
        return freight > 0 ? `${Util.formatNumber(freight)}円` : '';
    }
}
