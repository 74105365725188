import { useMyTruck, useMyTruckUpdate } from '@/composables/truck';
import { useRouting } from '@/composables/helper/routing';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import { computed, onBeforeMount, ref } from 'vue';
import { useFormModel } from '@/composables/form-helper';
import { useElementScroll, useNotification } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

export const useTruckEditHelper = (truckId?: number) => {
    const { state: { truck, loading: loadingTruck }, load: loadMyTruck } = useMyTruck();
    const { state: { form: formModel, formValidateRules, dirty, }, initForm, update } = useMyTruckUpdate();
    const { goToTruckList, goBack } = useRouting();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const { state: { suggestionList }, load: loadStaffNameSuggestions } = useCompanyStaffNameSuggestions();
    const { register: registerStaffNameSuggestions } = useCompanyStaffNameSuggestionRegister();
    const { state: { loading: loadingTruckEdit }, withLoading } = useLoading();
    const { formModel: formModelRef, submit } = useFormModel();
    const formEl = computed(() => {
        if (formModelRef) return formModelRef.value ? formModelRef.value.$el as HTMLElement : undefined;
        return undefined;

    });
    const { scrollToContentTop } = useElementScroll(formEl);

    const loading = computed<boolean>(() =>
        [loadingTruck, loadingTruckEdit].some(each => each.value)
    );
    const initialized = ref<boolean>(false);

    const cols = {
        first: {
            labelCol: { sm: 6, lg: 10 },
            wrapperCol: { sm: 18, lg: 14 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                sm: { offset: 6, span: 18 },
                lg: { offset: 0, span: 24 },
            },
        },
    };

    const circleOptions = computed(() => myProfile.value?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));
    const isMemberOfSingleCircle = computed(() =>
        myProfile.value?.circles?.length === 1
    );

    // ======================================================
    // Notification
    // ======================================================
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_UPDATE = 'UPDATE_TRUCK_ERROR';
    /**
     * 空車更新失敗を通知します。
     */
    const notifyFailedToUpdate = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_UPDATE,
        message: '空車の更新ができませんでした。',
        description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
    });
    /**
     * 空車更新失敗通知を閉じます。
     */
    const closeFailedToUpdateNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_UPDATE);

    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると編集中の内容は失われます。よろしいですか？');

    // ======================================================
    // Event
    // ======================================================
    /**
     * 初期化します。
     */
    const initialize = async (): Promise<void> => {
        if (!truckId) {
            await goToTruckList();
            return;
        }

        if (!myProfile.value) await loadMyProfile();

        // 部屋は一つしかない場合に設定する
        if (isMemberOfSingleCircle.value) formModel.value.circleId = myProfile.value?.circles?.[0]?.id;

        // 担当者名候補リスト
        await loadStaffNameSuggestions();
        // 空車
        await loadMyTruck(truckId).catch(() => goToTruckList());

        if (truck.value) {
            initForm(truck.value);
        } else {
            await goToTruckList();
        }
        initialized.value = true;
    };

    /**
     * 登録ボタンが押下された際に呼び出される。
     */
    const onSubmit = () => submit(async () => withLoading(async () => {
        const onSuccess = async () => {
            return goToTruckList();
        };

        closeFailedToUpdateNotification();
        // // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        registerStaffNameSuggestions(formModel.value.staffName).catch(() => ({}));
        // 更新
        await update().then(onSuccess).catch(notifyFailedToUpdate);
    }), async () => {
        scrollToContentTop();
    });

    const onClickBack = () => {
        goBack();
    };

    onBeforeMount(() => initialize());

    onBeforeRouteLeave((_to, _from, next) => {
        // 空車ID不正で別画面遷移
        if (!truckId) return next();
        // 初期化完了前に遷移する場合(IDが自分のものではない、などの不正)
        if (!initialized.value) return next();
        // 入力内容不変、正常登録完了など
        if (!dirty.value) return next();

        if (!confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        formModel,
        formValidateRules,
        formModelRef,
        suggestionList,
        cols,
        circleOptions,
        onClickBack,
        onSubmit,
    };
};
