<script setup lang="ts">
import { computed } from 'vue';
import { FreightValue } from '@/models/vo/freight';
import { Truck, TruckList } from '@/models/truck';
import UiDateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import { columns } from '@/_components/truck/truck-table-view-definition';

const props = defineProps<{
    truckList?: TruckList;
}>();

//
// データ
//
const list = computed(() => props.truckList?.data ?? []);
const isOver50 = computed(() => (props.truckList?.totalRecordCount ?? 0) > 50);

/**
 * 車種フォーマッター
 */
const truckModelText = (record: Truck): string => record.truckModel.label ?? '';

/**
 * 重量フォーマッター
 */
const truckWeightText = (record: Truck): string => record.truckWeight.label ?? '';

/**
 * 運賃フォーマッター
 */
const freightText = (truck: Truck) => (new FreightValue(truck.freight)).format();
</script>

<template>
    <div>
        <a-table class="app-result-list-table"
                 bordered
                 :columns="columns"
                 :data-source="list"
                 rowKey="id"
                 size="middle"
                 :loading="false"
                 :scroll="{ x: 802, scrollToFirstRowOnChange: true }"
                 :row-class-name="() => 'app-table-row'"
                 :pagination="false">
            <template v-slot:departureArrival="text, record">
                <ui-date-time-location-label :departure-min="record.departureMin"
                                             :departure-max="record.departureMax"
                                             :departure-pref="record.departurePrefecture"
                                             :departure-city="record.departureCity"
                                             :arrival-min="record.arrivalMin"
                                             :arrival-max="record.arrivalMax"
                                             :arrival-pref="record.arrivalPrefecture"
                                             :arrival-city="record.arrivalCity">
                </ui-date-time-location-label>
            </template>
            <template v-slot:freight="text, record">
                <span class="freight-text">{{ freightText(record) }}</span>
            </template>
            <template v-slot:truckWeight="text, record">
                <span class="table-row-ellipsis-text">{{ truckWeightText(record) }}</span>
            </template>
            <template v-slot:truckModel="text, record">
                <span class="table-row-ellipsis-text">{{ truckModelText(record) }}</span>
            </template>
            <template v-slot:description="text, record">
                <a-tooltip :title="record.description" placement="bottom"
                           :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                    <span class="table-row-ellipsis-text">{{ record.description }}</span>
                </a-tooltip>
            </template>
        </a-table>

        <div class="list-notice" v-if="isOver50">新着50件の空車を表示しています</div>
    </div>
</template>

<style scoped lang="less">
.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

.freight-text {
    font-weight: bold;
}

.list-notice {
    margin-top: 16px;
}

::v-deep .app-result-list-table .ant-table-bordered .ant-table-body > table {
    border-left: 1px solid @border-color-split;
    border-right: 1px solid @border-color-split;
}
</style>
