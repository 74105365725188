import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { AgreementUpdateForm } from '@/vuex/modules/agreement/types';

@Component
export default class AgreementBaggageShapeEdit extends Vue {
    @Prop()
    declare readonly value?: AgreementUpdateForm;

    get shape(): string {
        return this.value?.shape?.code ?? '';
    }

    set shape(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        const shape = BaggageShapeEnum.valueOf(newValue);
        if (!shape) return;
        cloned.shape = shape;
        this.$emit('input', cloned);

        this.$emit('changeShape');
        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            message: '荷姿を選択してください。',
        },
    ];

    options = BaggageShapeEnum.values.map((e) => ({
        label: e.label,
        value: e.code
    }));
}
