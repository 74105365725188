import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { CompanyPaymentModel } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';

/**
 * 企業支払い情報をロードする機能を提供します。
 */
export const useCompanyPayment = () => {
    const payment = ref<CompanyPaymentModel | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async () => withLoading(async () => {
        payment.value = await companyApi.payment().then(data => new CompanyPaymentModel(data));
    });

    return {
        state: {
            payment,
            loading,
        },
        load,
    };
};
