import { Component, Prop, Vue } from 'vue-property-decorator';
import { Truck, TruckList } from '@/vuex/modules/truck/types';
import { Util } from '@/util';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';

@Component({
    components: {
        UiDateTimeLocationLabel,
    },
})
export default class CompanyTruckList extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly truckList?: TruckList;

    get list(): Array<Truck> {
        return this.truckList?.data ?? [];
    }

    get listLimitOver(): boolean {
        return (this.truckList?.totalRecordCount ?? 0) > 50;
    }

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '空車日時・空車地 / 行先日時・行先地',
            key: 'departureArrival',
            scopedSlots: { customRender: 'departureArrival' },
            align: 'center',
            width: 352,
        },
        {
            title: '運賃',
            key: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 112,
        },
        {
            title: '重量',
            key: 'truckWeight',
            scopedSlots: { customRender: 'truckWeight' },
            width: 80,
        },
        {
            title: '車種',
            key: 'truckModel',
            scopedSlots: { customRender: 'truckModel' },
            width: 108,
        },
        {
            title: '備考',
            key: 'description',
            scopedSlots: { customRender: 'description' },
            width: 150,
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 車種
     */
    truckModelText(record: Truck): string {
        return record.truckModel.label ?? '';
    }

    /**
     * 重量
     */
    truckWeightText(record: Truck): string {
        return record.truckWeight.label ?? '';
    }

    /**
     * 運賃
     */
    freightText(record: Truck): string {
        if (!record.freight) {
            return '要相談';
        }
        return `${Util.formatNumber(record.freight)}円`;
    }
}
