import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import SearchForm from '@/components/Baggage/Search/Condition';
// @ts-ignore
import UiPaginationControl from '@/components/UI/PaginationControl';
// @ts-ignore
import BaggageTableView from '@/components/Baggage/Search/TableView';
// @ts-ignore
import BaggageSearchPalette from '@/components/Baggage/Search/Palette';
import { PageInfo } from '@/components/UI/PaginationControl/types';
import { Const } from '@/const';
import { PageUtil } from '@/util';
import _ from 'lodash';
import dayjs from 'dayjs';

@Component({
    components: {
        SearchForm,
        UiPaginationControl,
        BaggageTableView,
        BaggageSearchPalette,
    }
})
export default class BaggageSearchContainerView extends Vue {
    /**
     * 荷物検索コンテナ
     */
    @Prop()
    declare readonly baggageSearchContainer: baggageTypes.BaggageSearchContainer;
    /**
     * 保存された検索条件
     */
    @Prop({ default: () => ([]) })
    declare readonly savedBaggageSearchConditions: baggageTypes.BaggageSearchCondition[];
    /**
     * 保存された検索条件をロード中か否か
     */
    @Prop({ default: false })
    declare readonly loadingSavedSearchConditions: boolean;
    /**
     * 自社企業ID
     */
    @Prop()
    declare readonly myCompanyId?: number;
    /**
     * 検索除外企業リスト
     */
    @Prop({ default: () => ([]) })
    declare readonly baggageSearchExcludedCompanyList: baggageTypes.BaggageSearchExcludedCompany[];

    /**
     * 検索条件
     */
    get form(): baggageTypes.BaggageSearchForm {
        return this.baggageSearchContainer.condition;
    }

    set form(newValue: baggageTypes.BaggageSearchForm) {
        // 検索条件を更新する
        this.$emit('updateSearchForm', newValue);
    }

    /**
     * ソートキー
     */
    get sortKey(): string {
        return this.baggageSearchContainer.condition.sortKey;
    }

    set sortKey(newValue: string) {
        this.$emit('updateSortKey', newValue);
    }

    /**
     * ソート順が昇順であるか否か
     */
    get isSortAscending(): boolean {
        return this.baggageSearchContainer.condition.sortOrder === 'ASC';
    }

    /**
     * 検索中であるか否か
     */
    get searchingBaggages(): boolean {
        return this.baggageSearchContainer.searching;
    }

    /**
     * ページング情報
     */
    get pageInfo(): PageInfo {
        const info = this.baggageSearchContainer.result?.pageInfo;
        return {
            totalPageCount: info?.totalPageCount ?? 0,
            totalRecordCount: info?.totalRecordCount ?? 0,
            currentPage: info?.currentPageNumber ?? 1,
            currentPageSize: info?.pageSize ?? Const.DEFAULT_PAGE_SIZE,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    }

    /**
     * 荷物リスト
     */
    get arrangedBaggages(): baggageTypes.Baggage[] {
        const excludedCompanyIds = this.baggageSearchExcludedCompanyList.map(c => c.id);
        return (this.baggageSearchContainer.result?.baggages ?? []).filter(d =>  !_.includes(excludedCompanyIds, d.companyId));
    }

    /**
     * 企業プロフィールリスト
     */
    get companyProfiles(): companyTypes.CompanyProfile[] {
        return this.baggageSearchContainer.result?.companyProfiles ?? [];
    }

    /**
     * 既読荷物IDリスト
     */
    get readBaggageIds(): number[] {
        return this.baggageSearchContainer.result?.readBaggageIds ?? [];
    }

    /**
     * お気に入り登録済みの荷物IDリスト
     */
    get favoriteBaggageIds(): number[] {
        return this.baggageSearchContainer.result?.favoriteBaggageIds ?? [];
    }

    get newBaggageIds(): number[] {
        const lastDatetime = this.baggageSearchContainer.lastSearchDatetime;
        if (lastDatetime === undefined) {
            return [];
        }
        const baggages = this.baggageSearchContainer.result?.baggageList.data ?? [];
        return baggages.filter((baggage) => dayjs(baggage.entryTm).isAfter(lastDatetime))
            .map((baggage) => baggage.id);
    }

    /**
     * 参考運賃リスト
     */
    get referenceFreights(): baggageTypes.BaggageFreightMaster[] {
        return this.baggageSearchContainer.result?.referenceFreights ?? [];
    }

    /**
     * おすすめ帰り便の数
     */
    get baggageRecommendReturnsCounts(): baggageTypes.BaggageRecommendReturnsCount[] {
        return this.baggageSearchContainer.result?.baggageRecommendReturnsCounts ?? [];
    }

    /**
     * ソートキーのオプション
     */
    baggageSortKey = Const.baggageSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    //
    // Action Handlers
    //

    /**
     * 荷物を検索する。（検索ボタントリガー）
     */
    search(): void {
        this.$emit('search');
    }

    /**
     * 自動検索をonにした時に呼び出されます。
     */
    startAutoSearch(): void {
        this.$emit('startAutoSearch');
    }

    /**
     * 自動検索をoffにした時に呼び出されます。
     */
    stopAutoSearch(): void {
        this.$emit('stopAutoSearch');
    }

    /**
     * 検索条件をリセットする。
     */
    resetSearchForm(): void {
        this.$emit('resetSearchForm');
    }

    /**
     * よく使う検索条件として保存する。
     */
    saveSearchCondition(): void {
        this.$emit('saveSearchCondition');
    }

    /**
     * 選択されたよく使う検索条件を復元する。
     */
    selectSavedSearchCondition(conditionId: number): void {
        this.$emit('selectSavedSearchCondition', conditionId);
    }

    /**
     * ソート方向を変更する。
     */
    toggleSortDirection(): void {
        this.$emit('updateSortDirection', this.isSortAscending ? 'DESC' : 'ASC');
    }

    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    async onChangePage(param: { pageNo: number, pageSize: number }): Promise<void> {
        this.$emit('changeSearchResultPage', param);
    }

    /**
     * 検索フォームにスクロールする。
     */
    focusSearchForm(): void {
        PageUtil.scrollToContentTop();
    }

    /**
     * 更新ボタンをクリックした際に呼び出されます。
     */
    async reloadSearchResult(): Promise<void> {
        this.$emit('reloadSearchResult');
        this.scrollToSearchResultsSection();
    }

    /**
     * 荷物を選択した際に呼び出されます。
     * @param baggageId
     */
    async selectBaggage(baggageId: number): Promise<void> {
        this.$emit('selectBaggage', baggageId);
    }

    /**
     * お気に入り「保存」ボタンが押下された際に呼び出されます。
     */
    async markFavorite(baggageId: number): Promise<void> {
        this.$emit('markFavorite', baggageId);
    }

    /**
     * お気に入り「保存済」ボタンが押下された際に呼び出されます。
     */
    async unmarkFavorite(baggageId: number): Promise<void> {
        this.$emit('unmarkFavorite', baggageId);
    }

    /**
     * 検索結果セクションまでスクロール
     */
    private scrollToSearchResultsSection(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
    }
}
