<script setup lang="ts">
import { onMounted } from 'vue';
import { useSettlementAvailability } from '@/composables/settlement-availability';

const props = defineProps<{
    activeTabKey: string
}>();

const emit = defineEmits<{
    (e: 'changeTab', key: string): void,
}>();

const {
    state: {
        available,
    },
    load,
} = useSettlementAvailability();

onMounted(async () => {
    await load();
});
</script>

<template>
    <a-tabs default-active-key="opened"
            type="card"
            class="baggage-page-tabs"
            :tabBarStyle="{ backgroundColor: 'white', padding: '15px 24px 0', margin: '0' }"
            :animated="false"
            :active-key="props.activeTabKey"
            @change="key => emit('changeTab', key)">
        <a-tab-pane key="opened" tab="募集中"></a-tab-pane>
        <a-tab-pane key="expired" tab="成約しなかった荷物"></a-tab-pane>
        <a-tab-pane key="agreed" tab="自社荷物の成約"></a-tab-pane>
        <a-tab-pane key="accepted" tab="受託荷物の成約"></a-tab-pane>
        <a-tab-pane key="settlement" tab="請求・支払" v-if="available"></a-tab-pane>
    </a-tabs>
</template>

<style scoped lang="less">
.baggage-page-tabs {
    height: 72px;

    ::v-deep .ant-tabs-nav {
        .ant-tabs-tab:nth-child(2), .ant-tabs-tab:nth-child(4) {
            margin-right: 8px;
        }
    }
}
</style>
