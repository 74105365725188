import { Component, Prop, Vue } from 'vue-property-decorator';
import { LoginForm } from '@/vuex/modules/account/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Validator } from '@/validator';

@Component
export default class AccountEmailLogin extends Vue {
    @Prop()
    declare readonly value?: LoginForm;
    @Prop({ default: false })
    declare readonly focus: boolean;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line
            validator: (_rule, _value, callback) => this.validate(callback as (message?: string) => void),
        },
    ];

    mounted(): void {
        if (this.focus) {
            const el = this.$refs.emailInput as HTMLInputElement;
            if (!el) {
                return;
            }
            el.focus();
        }
    }

    get emailAddress(): string {
        return this.value?.emailAddress ?? '';
    }

    set emailAddress(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.emailAddress = newValue.trim();
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    private validate(callback: (message?: string) => void): void {
        const validated = Validator.validateEmail(this.emailAddress);
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }
}
