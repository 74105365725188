import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as accountTypes from '@/vuex/modules/account/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import BaggagePrint from '@/components/Baggage/Print';

const accountMod = namespace('account');
const baggageMod = namespace('baggage');
const companyMod = namespace('company');

@Component({
    title: '荷物情報印刷',
    beforeRouteEnter: BaggagePrintPage.beforeRouteEnter,
    beforeRouteLeave: BaggagePrintPage.beforeRouteLeave,
    components: {
        BaggagePrint
    },
})
export default class BaggagePrintPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: accountTypes.Profile;
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly MY_COMPANY_PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ required: true, type: Number })
    declare readonly baggageId?: number;

    // ======================================================
    // Data
    // ======================================================
    informationComment = '';

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 自社の荷物か否かを取得します。
     */
    get isMyCompanyBaggage(): boolean {
        if (!this.ACCOUNT_PROFILE || !this.BAGGAGE) {
            return false;
        }
        return this.ACCOUNT_PROFILE.companyId === this.BAGGAGE.companyId;
    }

    /**
     * 企業プロフィールを取得します。
     */
    get companyProfile(): companyTypes.CompanyProfile | undefined {
        return this.isMyCompanyBaggage ? this.MY_COMPANY_PROFILE : this.COMPANY_PROFILE;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrint(): void {
        window.print();
    }

    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    onClickClose(): void {
        window.close();
    }

    /**
     * 荷物をロードします。
     */
    private static loadBaggage(id: number): Promise<baggageTypes.Baggage> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.LOAD_BAGGAGE}`, id);
    }

    /**
     * 荷物をクリアします。
     */
    private static clearBaggage(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.CLEAR_BAGGAGE}`);
    }

    /**
     * 自社の企業プロフィールをロードします。
     */
    private static loadMyCompanyProfile(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadCompanyProfile(id: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_PROFILE}`, id);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggagePrintPage>
    ): Promise<void> {
        const { baggageId } = to.params;
        const onError = () => next({ name: 'NotFound' });
        if (!baggageId) {
            onError();
        }
        BaggagePrintPage.loadBaggage(Number(baggageId))
            .then((baggage) => {
                const accountProfile = store.getters[`account/${accountTypes.GETTER.PROFILE}`] as accountTypes.Profile;
                // NOTE: 自社の荷物情報を呼び出す際は、自社企業情報取得APIを用いる
                if (accountProfile.companyId === baggage.companyId) {
                    return BaggagePrintPage.loadMyCompanyProfile();
                }
                return BaggagePrintPage.loadCompanyProfile(Number(baggage.companyId));
            })
            .then(() => next())
            .catch(onError);
    }

    static async beforeRouteLeave(to: VueRoute, _from: VueRoute, next: NavigationGuardNext<BaggagePrintPage>): Promise<void> {
        await BaggagePrintPage.clearBaggage();
        next();
    }
}
