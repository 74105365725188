import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';

@Component({
    title: 'お探しのページは見つかりません',
    components: {
        UiPreLoginLayout,
    }
})
export default class NotFoundPage extends PageVue {
    /**
     * トップページへ遷移するボタンをクリックした際に呼び出されます。
     */
    async onClickToTopPage(): Promise<void> {
        await this.$router.push({ name: 'Index' });
    }
}
