import { onMounted, ref } from 'vue';
import { useNotification } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';
import { useFormModel } from '@/composables/form-helper';
import { useAccountPasswordReset, useAccountPasswordResetVerify } from '@/composables/account-password';

export const useAccountPasswordResetVerifyHelper = (verificationCode?: string) => {
    const notification = useNotification();
    const { goToAccountPasswordResetComplete } = useRouting();
    const { submit, formModel } = useFormModel();

    const { state: { loading: loadingVerify }, verify } = useAccountPasswordResetVerify();
    const { state: { loading: loadingSubmit, form, formValidateRules }, reset } = useAccountPasswordReset();

    const passwordInput = ref<HTMLInputElement>();
    const verified = ref(false);

    /**
     * 認証コードを検証します。
     */
    const doVerification = async () => {
        try {
            await verify(verificationCode);
            verified.value = true;
        } catch {
            verified.value = false;
        }
    };

    /**
     * パスワード再設定ボタンが押下された際に呼び出されます。
     */
    const onSubmit = () => submit(async () => {
        const failedNotificationKey = 'RESET_FAILED';
        notification.close(failedNotificationKey);
        try {
            await reset(verificationCode);
            await goToAccountPasswordResetComplete();
        } catch {
            notification.error({
                key: failedNotificationKey,
                message: 'パスワードの再設定に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。',
            });
        }
    });

    onMounted(async () => {
        await doVerification();

        passwordInput.value?.focus();
    });

    return {
        loadingVerify,
        loadingSubmit,
        verified,
        form,
        formValidateRules,
        formModel,
        onSubmit,
        passwordInput,
    };
};
