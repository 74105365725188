import { FormModel, Modal } from 'ant-design-vue';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as types from '@/vuex/modules/baggage/types';
import { BaggageRegisterFormModel } from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import { AgreementUtil, PageUtil, Util } from '@/util';
import { Karte } from '@/karte';
import { baggageRegisterForm as formInLocalStorage } from '@/repository/storage/web-storage';
import {
    clearBaggage, closeFailedToDeleteStaffNotification, closeFailedToRegisterNotification,
    deleteCompanyStaffNameSuggestion,
    goToBaggageDetail,
    loadBaggage,
    loadCompanyStaffNameSuggestions,
    needsRePrediction, notifyFailedToDeleteStaff, notifyFailedToRegister,
    predictAgreementProbability,
    queryReferenceFreight,
    clearReferenceFreight,
    registerBaggage,
    registerCompanyStaffNameSuggestion, needsReQueryFreight, baggageFromQuery
} from '@/pages/Baggage/Register/helpers';
// @ts-ignore
import BaggageSpot from '@/components/Baggage/Register/Spot';
// @ts-ignore
import BaggageFlags from '@/components/Baggage/Register/Flags';
// @ts-ignore
import BaggageType from '@/components/Baggage/Register/Type';
// @ts-ignore
import BaggageTruckType from '@/components/Baggage/Register/TruckType';
// @ts-ignore
import BaggageTruckHeight from '@/components/Baggage/Register/TruckHeight';
// @ts-ignore
import BaggageTruckWidth from '@/components/Baggage/Register/TruckWidth';
// @ts-ignore
import BaggageLargeTruck from '@/components/Baggage/Register/LargeTruck';
// @ts-ignore
import BaggageTruckEquipment from '@/components/Baggage/Register/TruckEquipment';
// @ts-ignore
import BaggageFreight from '@/components/Baggage/Register/Freight';
// @ts-ignore
import BaggageHighwayFare from '@/components/Baggage/Register/HighwayFare';
// @ts-ignore
import BaggageStaffName from '@/components/Baggage/Register/StaffName';
// @ts-ignore
import BaggageDescription from '@/components/Baggage/Register/Description';
// @ts-ignore
import BaggageTruckCount from '@/components/Baggage/Register/TruckCount';
// @ts-ignore
import BaggageShape from '@/components/Baggage/Register/Shape';
// @ts-ignore
import PaletteCount from '@/components/Baggage/Register/PaletteCount';
// @ts-ignore
import PaletteSize from '@/components/Baggage/Register/PaletteSize';
// @ts-ignore
import BaggageTotalCount from '@/components/Baggage/Register/TotalCount';
// @ts-ignore
import BaggageTotalVolume from '@/components/Baggage/Register/TotalVolume';
// @ts-ignore
import BaggageTotalWeight from '@/components/Baggage/Register/TotalWeight';
// @ts-ignore
import BaggageHandling from '@/components/Baggage/Register/Handling';
// @ts-ignore
import BaggageUnderNegotiation from '@/components/Baggage/Register/UnderNegotiation';
// @ts-ignore
import Prediction from '@/components/Baggage/Register/Prediction';
// @ts-ignore
import PaymentDate from '@/components/Baggage/Register/PaymentDate';
// @ts-ignore
import BaggageNegotiationType from '@/components/Baggage/Register/NegotiationType';

const baggageMod = namespace('baggage');
const companyMod = namespace('company');

@Component({
    title: '荷物登録',
    components: {
        BaggageSpot,
        BaggageFlags,
        BaggageType,
        BaggageTruckType,
        BaggageFreight,
        BaggageHighwayFare,
        BaggageStaffName,
        BaggageDescription,
        BaggageTruckCount,
        BaggageShape,
        PaletteCount,
        PaletteSize,
        BaggageTotalCount,
        BaggageTotalVolume,
        BaggageTotalWeight,
        BaggageTruckHeight,
        BaggageTruckWidth,
        BaggageLargeTruck,
        BaggageTruckEquipment,
        BaggageHandling,
        BaggageUnderNegotiation,
        Prediction,
        PaymentDate,
        BaggageNegotiationType,
    },
    beforeRouteEnter: BaggageRegisterPage.beforeRouteEnter,
})
export default class BaggageRegisterPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(types.GETTER.BAGGAGE)
    BAGGAGE?: types.Baggage;
    @companyMod.Getter(companyTypes.GETTER.STAFF_NAME_SUGGESTION_LIST)
    staffNameSuggestions?: companyTypes.CompanyStaffNameSuggestionList;
    @baggageMod.Getter(types.GETTER.BAGGAGE_REGISTER_REFERENCE_FREIGHT)
    REFERENCE_FREIGHT?: types.BaggageFreightMaster;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    MY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    private loading = false;
    private form = new types.BaggageRegisterFormModel();
    private agreementProbability = 0;
    // TODO: TruckWidth/TruckHeight/LargeTruckFlg/TruckEquipmentのいずれかに値があったらtrueになるように
    private specifiedTruckChecked = false;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly sourceBaggageId?: string;


    get baggage(): types.BaggageRegisterFormModel {
        return this.form;
    }

    set baggage(newBaggage: types.BaggageRegisterFormModel) {
        this.predictAgreementProbabilityIfNeeded(newBaggage);
        this.queryReferenceFreightIfNeeded(newBaggage);

        this.form = _.cloneDeep(newBaggage);
        formInLocalStorage.set(newBaggage);
        if (this.form.hasAnySpecifiedTruck) {
            this.specifiedTruckChecked = true;
        }
    }

    get specifiedTruckEditable(): boolean {
        return this.specifiedTruckChecked || this.form?.hasAnySpecifiedTruck;
    }

    get specifiedTruckCheck(): boolean {
        return this.specifiedTruckChecked;
    }

    set specifiedTruckCheck(checked: boolean) {
        if (!checked) {
            this.form.clearSpecifiedTruck();
        }
        this.specifiedTruckChecked = checked;
    }

    /**
     * 入金予定日の初期値を取得します。
     */
    get defaultPaymentDate(): string | undefined{
        if (!this.baggage) return undefined;
        if (!this.MY_PROFILE?.hasPaymentTerms) return undefined;
        const departureDate = dayjs(this.baggage?.departureMax);
        const paymentDate = AgreementUtil.paymentDate(departureDate, this.MY_PROFILE?.cutOffDay?.code, this.MY_PROFILE?.paymentMonth?.code, this.MY_PROFILE?.paymentDay?.code);
        return paymentDate.format('YYYY-MM-DD');
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * コピー元荷物情報を取得します。(as function because it's not reactive)
     */
    sourceBaggage(): types.BaggageRegisterFormModel | undefined {
        if (!this.sourceBaggageId || !Util.isNumeric(this.sourceBaggageId) || !this.BAGGAGE) return;

        if (Util.toNumber(this.sourceBaggageId) !== this.BAGGAGE.id) return;

        return types.BaggageRegisterFormModel.of(this.BAGGAGE);
    }

    /**
     * 保存済み荷物情報を取得します。(as function because it's not reactive)
     */
    storedBaggage(): types.BaggageRegisterFormModel | undefined {
        //
        return formInLocalStorage.get();
    }

    /**
     * クエリーで指定された荷物情報を取得します。
     */
    queryBaggage(): types.BaggageRegisterFormModel | undefined {
        return baggageFromQuery(this.$route);
    }

    created(): void {
        // コピー元、クエリー、保存済み、新規の優先順位で初期データを構築
        this.baggage = this.sourceBaggage() ?? this.queryBaggage() ?? this.storedBaggage() ?? new types.BaggageRegisterFormModel();
        // クエリーからの新規登録の場合は入金予定日をクリアーしない
        if (!this.$route.query.parser) {
            this.baggage.paymentDate = undefined;
        }
    }

    mounted(): void {
        // 荷物コピーによる新規登録の場合、値がセットされた状態となっているためバリデーションを実行
        if (this.sourceBaggage()) {
            (this.$refs.formModel as FormModel).validate(() => { /* callbackがないとコンソールエラーが表示される */ });
        }
        // クエリーからの新規登録の場合、値がセットされた状態となっているためバリデーションを実行
        if (this.$route.query.parser) {
            (this.$refs.formModel as FormModel).validate(() => { /* callbackがないとコンソールエラーが表示される */ });
        }
    }

    /**
     * 登録ボタンが押下された際に呼び出される。
     */
    onSubmit(): void {
        const onSuccess = async (baggageIds: number[]) => {
            formInLocalStorage.clear();
            this.$message.success('荷物の登録が完了しました。');
            // KARTEイベント送信：荷物登録
            Karte.trackRegisterBaggage(baggageIds[0]);
            await goToBaggageDetail(baggageIds[0], true);
        };

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) {
                BaggageRegisterPage.scrollToTopOfForm();
                return;
            }

            this.loading = true;
            closeFailedToRegisterNotification();

            const formData = this.baggage.toForm();

            // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
            registerCompanyStaffNameSuggestion(this.baggage?.staffName).catch(() => ({}));

            // 荷物登録
            await registerBaggage(formData).then(onSuccess).catch(notifyFailedToRegister);

            this.loading = false;
        });
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * クリアボタンを謳歌した際に呼び出されます。
     */
    async onClickClear(): Promise<void> {
        if (!await this.confirmClearForm()) return;

        await clearBaggage();
        this.baggage = new types.BaggageRegisterFormModel();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     * @param staffName
     */
    async onDeleteStaffNameSuggestion(staffName: string): Promise<void> {
        closeFailedToDeleteStaffNotification();

        deleteCompanyStaffNameSuggestion(staffName)
            .catch(notifyFailedToDeleteStaff)
            .then(loadCompanyStaffNameSuggestions);
    }

    /**
     * 入力内容クリア確認モーダルを表示します。
     */
    private confirmClearForm(): Promise<boolean> {
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: '入力内容を削除してよろしいですか？',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '削除',
            cancelText: 'キャンセル',
        }));
    }

    /**
     * 成約確率を予測します。
     */
    private predictAgreementProbabilityIfNeeded(next: BaggageRegisterFormModel): void {
        if (!next.isReadyToPredict) {
            this.agreementProbability = 0;
            return;
        }

        if (!needsRePrediction(this.form, next)) return;

        this.predictAgreementProbability(this, next);
    }

    /**
     * 成約確率を予測します。
     */
    private predictAgreementProbability = _.debounce(async (that: BaggageRegisterPage, next: BaggageRegisterFormModel) => {
        that.agreementProbability = await predictAgreementProbability(next);
    }, 500);

    /**
     * 参考運賃を取得します。
     */
    private queryReferenceFreightIfNeeded(next: BaggageRegisterFormModel): void {
        if (!needsReQueryFreight(this.form, next)) return;

        queryReferenceFreight(next).catch(() => ({ /* ignore error */ }));
    }

    async beforeRouteLeave(_to: VueRoute, _: VueRoute, next: NavigationGuardNext<BaggageRegisterPage>): Promise<void> {
        await clearBaggage();
        await clearReferenceFreight();
        next();
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageRegisterPage>
    ): Promise<void> {
        const sourceBaggageId = _.isArray(to.query.sourceBaggageId)
            ? _.last(to.query.sourceBaggageId)
            : to.query.sourceBaggageId;
        await loadCompanyStaffNameSuggestions();
        // クエリパラメーターに `sourceBaggageId` が無い場合はそのまま表示
        if (!sourceBaggageId) {
            next();
            return;
            // sourceBaggageId に数字以外が含まれている場合は、クエリパラメーターをリセット
        } else if (!Util.isNumeric(sourceBaggageId)) {
            next({ name: 'BaggageRegister' });
            return;
        }
        await loadBaggage(_.toNumber(sourceBaggageId)).catch((err) => {
            // 他企業の荷物や削除された荷物の場合は、クエリパラメーターをリセット
            next({ name: 'BaggageRegister' });
            throw err;
        });

        next();
    }

    /**
     * フォーム上端までウィンドウ内コンテンツをスクロールします。
     */
    private static scrollToTopOfForm(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-register-form')?.offsetTop);
    }
}
