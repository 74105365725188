import { Enum } from '@/types/enum';
import { CompanyApplication } from '@/models/company';

export class CompanyApplicationModel {
    id: number;
    companyId: number;
    memberId: number;
    status: Enum;
    product: Enum;
    entryDatetime: string;

    constructor(param: CompanyApplication) {
        this.id = param.id;
        this.companyId = param.companyId;
        this.memberId = param.memberId;
        this.status = param.status;
        this.product = param.product;
        this.entryDatetime = param.entryDatetime;
    }
}
