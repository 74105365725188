import { onBeforeMount } from 'vue';
import { useCompanyPayment } from '@/composables/company-payment';

export const useSettingCompanyPaymentHelper = () => {
    const { state: { payment, loading }, load } = useCompanyPayment();

    onBeforeMount(async () => {
        await load().catch(() => ({}));
    });
    return {
        loading,
        payment,
    };
};
