import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageTruck extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get truck(): string {
        if (!this.baggage) return '';

        return `重量：${this.baggage.truckWeight.label} 車種：${this.baggage.truckModel.label}`;
    }
}
