import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyMlitGrantNumberEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyConfidenceUpdateForm;

    /**
     * 国交省認可番号
     */
    get mlitGrantNumber(): string {
        return this.value?.mlitGrantNumber ?? '';
    }

    set mlitGrantNumber(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.mlitGrantNumber = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.mlitGrantNumber = this.mlitGrantNumber.trim();
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if ((this.value?.mlitGrantNumber ?? '').length > 200) {
            callback('200文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
