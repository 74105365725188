import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { agreementApi, companyApi, companyNoticeApi } from '@/repository/api/api';
import {
    ACTION as a,
    agreementTypes,
    CompanyNoticeState,
    GETTER as g,
    MUTATION as m,
} from './types';
import { CompanyNoticeListModel } from '@/vuex/modules/companyNotice/company-notice-list.model';

const state: CompanyNoticeState = {
    noticeList: undefined,
    noticeListener: undefined,
};

const actions: ActionTree<CompanyNoticeState, void> = {
    /**
     * 企業お知らせ一覧をロードします。
     */
    [a.LOAD_COMPANY_NOTICE]: ({ commit }) => loadCompanyNotices()
        .then(list => commit(m.SET_COMPANY_NOTICE_LIST, list)),
    /**
     * 企業お知らせを既読にします。
     */
    [a.MARK_AS_READ]: ({ commit }, id: number) =>
        companyNoticeApi.markAsRead(id)
            .then(() => loadCompanyNotices())
            .then(list => commit(m.SET_COMPANY_NOTICE_LIST, list)),
    /**
     * 企業お知らせを定期的に取得するリスナーを開始します。
     */
    [a.START_COMPANY_NOTICE_LISTENER]: async ({ commit, dispatch, state }) => {
        // NOTE: 既にsetIntervalが動作している場合は一度停止させる
        if (state.noticeListener) {
            await dispatch(a.STOP_COMPANY_NOTICE_LISTENER);
        }
        const intervalTime = 60 * 1000; // 1 minute
        commit(
            m.SET_COMPANY_NOTICE_LISTENER,
            setInterval(() => dispatch(a.LOAD_COMPANY_NOTICE), intervalTime)
        );
    },
    /**
     * 企業お知らせを定期的に取得するリスナーを停止します。
     */
    [a.STOP_COMPANY_NOTICE_LISTENER]: ({ commit, state }) =>
        commit(m.SET_COMPANY_NOTICE_LISTENER, clearInterval(state.noticeListener)),
};

function loadCompanyNotices(): Promise<CompanyNoticeListModel> {
    return companyNoticeApi.list().then(async (notices) => {
        const agreementIds = notices
            .filter((item) => agreementTypes.includes(item.type.code))
            .map((item) => item.objectId);
        const [agreements, profileProfiles] = await Promise.all([
            agreementIds.length > 0 ? agreementApi.search({ id: agreementIds }) : [],
            agreementIds.length > 0 ? companyApi.listPartnerProfile({ id: agreementIds }) : []
        ]);
        return new CompanyNoticeListModel(notices, agreements, profileProfiles);
    });
}

const getters: GetterTree<CompanyNoticeState, void> = {
    /**
     * 企業お知らせ一覧を取得します。
     */
    [g.COMPANY_NOTICE_LIST]: (s) => s.noticeList,
};

const mutations: MutationTree<CompanyNoticeState> = {
    /**
     * 企業お知らせ一覧を設定します。
     *
     * @param list 企業お知らせ一覧
     */
    [m.SET_COMPANY_NOTICE_LIST]: (s, model: CompanyNoticeListModel) => (s.noticeList = model),
    /**
     * 企業お知らせを定期的に取得するためのリスナーを設定します。
     * @param intervalId 企業お知らせ定期取得で用いるsetIntervalで発行された `intervalId`
     */
    [m.SET_COMPANY_NOTICE_LISTENER]: (s, intervalId?: number) => (s.noticeListener = intervalId),
};

export const companyNotice: Module<CompanyNoticeState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
