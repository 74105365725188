import { Route } from 'vue-router/types/router';
import router from '@/router';

// ======================================================
// Routing Helpers
// ======================================================
/**
 * 空車登録画面へ遷移します。
 */
export const goToRegisterTruck = (id: number): Promise<Route> =>
    router.push({ path: '/truck/register', query: { sourceTruckId: id.toString() } });

/**
 * 空車編集画面へ遷移します。
 */
export const goToEditTruck = (id: number): Promise<Route> =>
    router.push({ name: 'TruckEdit', params: { truckId: id.toString() } });
