import { BaggageSearchForm, BaggageSearchFormModel } from '@/models/baggage';
import { createGlobalState, useLocalStorage, useSessionStorage } from '@vueuse/core';
import _ from 'lodash';

export const useBaggageSearchConditionSave = createGlobalState(() => {
    const sessionValues = useSessionStorage<BaggageSearchForm[]>('baggageSearchForms', []);
    const localValues = useLocalStorage<BaggageSearchForm[]>('baggageSearchForms', []);

    const save = (form: BaggageSearchFormModel): void => {
        sessionValues.value = [ form ];
        localValues.value = [ form ];
    };

    const load = (): BaggageSearchFormModel | undefined => {
        if (!_.isEmpty(sessionValues.value)) {
            return new BaggageSearchFormModel(sessionValues.value[0]);
        }
        if (!_.isEmpty(localValues.value)) {
            return new BaggageSearchFormModel(localValues.value[0]);
        }
        return undefined;
    };

    return {
        save,
        load,
    };
});
