import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { BaggageUtil } from '@/util';

@Component
export default class BaggageFlagsEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    validationRules = [];

    get expressChecked(): boolean {
        return this.value?.express === true;
    }

    set expressChecked(checked: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.express = checked;

        this.emitValue(cloned);
    }

    get relocateChecked(): boolean {
        return this.value?.category.code == BaggageCategoryEnum.Relocation.code;
    }

    set relocateChecked(checked: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.category.code =
            checked || this.includesRelocateWord
                ? BaggageCategoryEnum.Relocation.code
                : BaggageCategoryEnum.Normal.code;

        this.emitValue(cloned);
    }

    get shareChecked(): boolean {
        return this.value?.share === true;
    }

    set shareChecked(checked: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.share = checked;

        this.emitValue(cloned);
    }

    /**
     * 荷種に引っ越しという単語が含まれているかどうかを取得します。
     */
    get includesRelocateWord(): boolean {
        return BaggageUtil.includesRelocateWord(this.value?.type);
    }

    private emitValue(value: BaggageUpdateForm) {
        this.$emit('input', value);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
