export const columns = [
    {
        title: '空車日時・空車地 / 行先日時・行先地',
        key: 'departureArrival',
        scopedSlots: { customRender: 'departureArrival' },
        align: 'center',
        width: 352,
    },
    {
        title: '運賃',
        key: 'freight',
        scopedSlots: { customRender: 'freight' },
        width: 112,
    },
    {
        title: '重量',
        key: 'truckWeight',
        scopedSlots: { customRender: 'truckWeight' },
        width: 80,
    },
    {
        title: '車種',
        key: 'truckModel',
        scopedSlots: { customRender: 'truckModel' },
        width: 108,
    },
    {
        title: '備考',
        key: 'description',
        scopedSlots: { customRender: 'description' },
        width: 150,
    },
];
