import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageExpress extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get express(): string {
        return this.baggage?.express ?? false ? '○' : '×';
    }
}
