<script setup lang="ts">
import { useTraboxNoticeService } from '@/composables/service/trabox-notice-service';
import { TraboxNoticeItem } from '@/models/help';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

const { state: { visible, notice }, read, isBrandNew } = useTraboxNoticeService();

const onClickNewsItem = (item: TraboxNoticeItem) => {
    read(item);
};
</script>

<template>
    <a-popover trigger="click"
               v-model="visible"
               overlay-class-name="app-popover-notification">
        <template #content>
            <div class="container">
                <template v-if="notice">
                    <ul class="news" v-if="notice.items.length > 0">
                        <li class="news-item" v-for="item in notice.items" :key="item.id">
                            <a :href="item.url" target="_blank" rel="noopener" class="news-item__link"
                               @click="onClickNewsItem(item)">
                                <div class="news-item-avatar">
                                    <a-avatar :size="32" icon="bell" class="news-item-avatar__icon"/>
                                    <div class="news-item-avatar__brand-new" v-if="isBrandNew(item)">新</div>
                                </div>
                                <div class="news-item-title">{{ item.title }}</div>
                            </a>
                        </li>
                    </ul>
                    <div v-else class="empty-message">現在、お知らせはありません。</div>
                    <div class="footer">
                        <a class="footer__close-link" @click="visible = !visible">閉じる</a>
                    </div>
                </template>
                <a-skeleton v-else active avatar :paragraph="{ rows: 2 }"></a-skeleton>
            </div>
        </template>
        <div class="nav-item" :class="{ 'nav-item--open': visible }">
            <tbx-icon type="bell" />
        </div>
    </a-popover>
</template>

<style scoped lang="less">
.notification-popover-overlay {
    .ant-popover-inner-content {
        padding: 0;
    }
}

.container {
    width: 400px;
}

.nav-item {
    display: inline-flex;
    width: 60px;
    height: 64px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 300ms;

    .anticon {
        color: @black;
        font-size: 20px;
    }

    &--open,
    &:hover {
        background-color: @ui-color-selected-background-hover;
    }
}

.empty-message {
    padding: 24px 0;
    text-align: center;
}

.news {
    margin: 8px 0 0;
    padding: 0;
    max-height: calc(90vh - (64px * 2));
    overflow-y: auto;
}

.news-item {
    list-style-type: none;
    display: flex;
    align-items: center;

    .news-item__link {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 10px 16px;
        color: @black;
        transition: background-color 300ms;

        &:hover {
            background-color: @ui-color-selected-background-hover;
        }
    }
}

.news-item-avatar {
    position: relative;
    align-items: center;

    .news-item-avatar__icon {
        background-color: @color-junction-blue;
    }

    .news-item-avatar__brand-new {
        position: absolute;
        top: -6px;
        right: -6px;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        border: solid 1px @white;
        background-color: @ui-color-emphasis;
        font-size: 10px;
        line-height: 18px - (1px * 2);
        font-weight: bold;
        text-align: center;
        color: @white;
        user-select: none;
    }
}

.news-item-avatar + .news-item-title {
    margin-left: 8px;
}

.footer {
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    padding: 10px 16px;
    text-align: right;

    .footer__close-link {
        font-size: 12px;
        line-height: 12px;
    }
}
</style>
