import * as questionnaireTypes from '@/vuex/modules/questionnaire/types';

export class QuestionnaireUtil {
    /**
     * 回答フォームを作成します。
     */
    static createAnswerForm(item: questionnaireTypes.QuestionItem): Array<undefined> | undefined | '' {
        switch (item.type) {
            case 'multiple-select':
                return item.options ? item.options.map(() => undefined) : [];
            case 'single-select':
                return undefined;
            case 'text':
                return '';
        }
    }
}
