import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { onBeforeMount, watch } from 'vue';
import { useCompanyProfileExtraUpdate } from '@/composables/company-profile-extra-update';
import { useFormModel } from '@/composables/form-helper';
import _ from 'lodash';
import {
    useEmployeeNumbersOptions,
    useAccountingDayOptions,
    useAccountingMonthOptions,
} from '@/composables/option-helper';
import { DateValue } from '@/models/vo/date';

export const useSettingCompanyProfileExtraHelper = () => {
    const { state: { myProfile }, load: loadCompanyMyProfile } = useCompanyMyProfile();
    const { state: { form, formValidateRules, loading, }, update, initialize, } = useCompanyProfileExtraUpdate();

    const { submit, formModel } = useFormModel();

    const { options: employeeNumbers } = useEmployeeNumbersOptions();
    const { options: accountingDay } = useAccountingDayOptions();
    const { options: accountingMonth } = useAccountingMonthOptions();

    const disableEstablishmentDate = (date: DateValue) => {
        // 設立年月で選択可能な範囲は、1700年〜今月を範囲とする。
        // 1700年としているのは、ご利用企業様に享保元年(1716年)創業の企業がいらっしゃるため。
        return !date.isBetween(
            new DateValue('1700-01-01'),
            DateValue.today().endOf('month'),
            'month',
            '[]'
        );
    };

    const onSubmit = async () => {
        await submit(async () => await update());
    };

    watch(myProfile, (profile) => {
        if (_.isNil(profile)) return;
        initialize(profile);
    }, { immediate: true });

    // 画面表示時は常にロードする
    onBeforeMount(async () => {
        await loadCompanyMyProfile();
    });

    return {
        myProfile,
        form,
        formValidateRules,
        formModel,
        loading,
        employeeNumbers,
        accountingDay,
        accountingMonth,
        disableEstablishmentDate,
        onSubmit
    };
};
