import _ from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { truckApi } from '@/repository/api/api';
import { ACTION as a, GETTER as g, MUTATION as m, Truck, TruckList, TruckState } from '@/vuex/modules/truck/types';
import { Const } from '@/const';

const state: TruckState = {
    truck: undefined,
    truckList: {
        currentPageNumber: 1,
        pageSize: Const.DEFAULT_PAGE_SIZE,
        totalPageCount: 0,
        totalRecordCount: 0,
        data: [],
    },
    registeredTruckList: {
        currentPageNumber: 1,
        pageSize: Const.DEFAULT_PAGE_SIZE,
        totalPageCount: 0,
        totalRecordCount: 0,
        data: [],
    },
};

const actions: ActionTree<TruckState, void> = {
    /**
     * 取得します。
     */
    [a.LOAD_TRUCK]: ({ commit }, id) => truckApi.find(id).then(truck => commit(m.SET_TRUCK, truck)),
    /**
     * 自社空車を取得します。
     */
    [a.LOAD_MY_TRUCK]: ({ commit }, id) => truckApi.findMyTruck(id).then(truck => commit(m.SET_TRUCK, truck)),
    /**
     * 空車情報を検索します。
     */
    [a.SEARCH_TRUCK]: ({ commit }, param) => truckApi.searchTruck(param).then((list) => commit(m.SET_TRUCK_LIST, list)),
    /**
     * 空車情報を登録します。
     */
    [a.REGISTER_TRUCK]: (_, param) => truckApi.registerTruck(param),
    /**
     * 空車情報を更新します。
     */
    [a.UPDATE_TRUCK]: (_, [id, param]) => truckApi.updateTruck(id, param),
    /**
     * 空車情報一覧を取得します。
     */
    [a.LIST_TRUCK]: ({ commit }, param) =>
        truckApi.listTruck(param).then((list) => commit(m.SET_REGISTERED_TRUCK_LIST, list)),
    /**
     * 空車をクリアします。
     */
    [a.CLEAR_TRUCK]: ({ commit }) => commit(m.SET_TRUCK, undefined),
    /**
     * 空車情報一覧をクリアします。
     */
    [a.CLEAR_TRUCK_LIST]: ({ commit }) => commit(m.SET_TRUCK_LIST, _.cloneDeep(state.truckList)),
    /**
     * 空車情報を削除します。
     */
    [a.CLOSE_TRUCK]: ({ commit }, { id, param }) =>
        truckApi
            .closeTruck(id)
            .then(() => truckApi.listTruck(param))
            .then((list) => commit(m.SET_REGISTERED_TRUCK_LIST, list)),
};

const getters: GetterTree<TruckState, void> = {
    [g.TRUCK]: (s) => s.truck,
    // 空車検索の一覧
    [g.TRUCK_LIST]: (s) => s.truckList,
    // 登録した空車情報の一覧
    [g.REGISTERED_TRUCK_LIST]: (s) => s.registeredTruckList,
};

const mutations: MutationTree<TruckState> = {
    [m.SET_TRUCK]: (s, truck: Truck) => (s.truck = truck),
    [m.SET_TRUCK_LIST]: (s, list: TruckList) => (s.truckList = list),
    [m.SET_REGISTERED_TRUCK_LIST]: (s, list: TruckList) => (s.registeredTruckList = list),
};

export const truck: Module<TruckState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
