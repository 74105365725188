import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementDepartureLocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get departureLocation(): string {
        if (!this.agreement) return '';
        const array = [
            this.agreement.departurePref.label,
            this.agreement.departureCity,
            this.agreement.departureAddress ?? '',
        ];
        return array.join('');
    }

    get departureLocationCr(): string {
        if (!this.agreementCr) return '';
        const array = [
            this.agreementCr.departurePref.label,
            this.agreementCr.departureCity,
            this.agreementCr.departureAddress ?? '',
        ];
        return array.join('');
    }

    get changeRequest(): string {
        return this.departureLocation != this.departureLocationCr ? this.departureLocationCr : '';
    }
}
