<script setup lang="ts">
// TODO: vue3.3にしたらgeneric化して、SortInfoの型パラメータを指定する。
// https://blog.vuejs.org/posts/vue-3-3#generic-components

import PaginationControl from '@/_components/ui/PaginationControl.vue';
import { PageInfo } from './types/page-info';
import { SortInfo } from './types/sort-info';
import { Util } from '@/util';
import { computed } from 'vue';
import { SortOrder } from '@/models/vo/pagination';

const props = withDefaults(defineProps<{
    sortInfo?: SortInfo,
    pageInfo?: PageInfo,
    loading?: boolean,
    showSort?: boolean,
}>(), {
    loading: false,
    showSort: true,
});
const emits = defineEmits<{
    (e: 'change', value: { pageNo: number, pageSize: number, sortKey?: string, sortOrder?: SortOrder }): void,
}>();
const options = computed(() => {
    return props.sortInfo?.sortOptions?.map(each => {
        return { label: each.label, value: each.code };
    }) ?? [];
});
const orderIcon = computed(() => {
    const sortOrder = Util.requireNotNull(props.sortInfo?.sortOrder);
    if (sortOrder === 'ASC') return 'sort-ascending';
    if (sortOrder === 'DESC') return 'sort-descending';
    return '';
});
const toggleSortOrder = (sortOrder: 'ASC' | 'DESC'): 'ASC' | 'DESC' => {
    if (sortOrder === 'ASC') return 'DESC';
    return 'ASC';
};

const onChangeSort = (newSortKey: string) => {
    const newOption = Util.requireNotNull(props.sortInfo?.sortOptions.find(k => k.code === newSortKey));
    emits('change', {
        pageNo: 1,
        pageSize: Util.requireNotNull(props.pageInfo?.currentPageSize),
        sortKey: newSortKey,
        sortOrder: newOption.defaultOrder,
    });
};
const onClickSortOrder = () => {
    emits('change', {
        pageNo: 1,
        pageSize: Util.requireNotNull(props.pageInfo?.currentPageSize),
        sortKey: Util.requireNotNull(props.sortInfo?.sortKey),
        sortOrder: toggleSortOrder(Util.requireNotNull(props.sortInfo?.sortOrder)),
    });
};
const onChangePage = (param: { pageNo: number, pageSize: number }) => {
    emits('change', {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        sortKey: props.sortInfo?.sortKey,
        sortOrder: props.sortInfo?.sortOrder,
    });
};
</script>
<template>
    <div class="ui-sort-pagination-control">
        <a-input-group compact class="table-sorting" v-if="showSort && sortInfo && sortInfo.sortOptions.length > 0">
            <a-select :dropdown-match-select-width="false"
                      :options="options"
                      :value="sortInfo.sortKey"
                      :disabled="loading"
                      @change="onChangeSort">
                <template #suffixIcon></template>
            </a-select>
            <a-tooltip title="並び順の昇順・降順を変更します">
                <a-button :icon="orderIcon"
                          :disabled="loading"
                          @click="onClickSortOrder"></a-button>
            </a-tooltip>
        </a-input-group>

        <slot/>

        <div class="app-table-pagination" v-if="pageInfo && pageInfo.totalPageCount > 0">
            <pagination-control :page-info="pageInfo" @change="onChangePage" />
        </div>
    </div>

</template>
<style scoped lang="less">
.ui-sort-pagination-control {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.table-sorting {
    max-width: 139px;
}
</style>
