import { TbxRouteConfig } from '@/router/routes/types';
import MiscServicePage from '@/pages/MiscService/index.vue';
import MiscServiceGuaranteePage from '@/pages/MiscService/Guarantee/index.vue';
import MiscServiceGuaranteeRevokeEditPage from '@/pages/MiscService/Guarantee/Revoke/Edit/index.vue';
import MiscServiceGuaranteeRevokeCompletePage from '@/pages/MiscService/Guarantee/Revoke/Complete/index.vue';

import _MiscServicePage from '@/_pages/MiscService/MiscService.vue';
import _MiscGuaranteeRegisterPage from '@/_pages/MiscService/Guarantee/Register/MiscGuaranteeRegister.vue';

export const miscRoutes: TbxRouteConfig[] = [
    {
        path: '/misc/service',
        name: 'MiscService',
        component: _MiscServicePage,
        backComponent: MiscServicePage,
        meta: {
            title: '便利サービス',
        }
    },
    {
        path: '/misc/service/guarantee',
        name: 'MiscServiceGuarantee',
        component: _MiscGuaranteeRegisterPage,
        backComponent: MiscServiceGuaranteePage,
        meta: {
            title: '運賃全額保証サービス',
        }
    },
    {
        path: '/misc/service/guarantee/revoke',
        name: 'MiscServiceGuaranteeRevoke',
        component: MiscServiceGuaranteeRevokeEditPage,
    },
    {
        path: '/misc/service/guarantee/revoke/complete',
        name: 'MiscServiceGuaranteeRevokeComplete',
        component: MiscServiceGuaranteeRevokeCompletePage,
    },
];
