import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { SoundUtil, Util } from '@/util';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { Karte } from '@/karte';

@Component({})
export default class BaggageSearchConditionAutoSearch extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageList?: baggageTypes.Baggage[];
    /**
     * 新着荷物IDリスト
     */
    @Prop({ default: () => ([]) })
    declare readonly newBaggageIdList: number[];

    // ======================================================
    // Data
    // ======================================================
    autoSearch = false;

    // ======================================================
    // Functions
    // ======================================================
    beforeDestroy(): void {
        this.notifyTerminated();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 自動検索スイッチが押下された際に呼び出されます。
     */
    async onClickAutoSearch(): Promise<void> {
        if (!Notification) {
            this.autoSearch = false;
            return;
        }

        if (this.autoSearch) {
            if (!await this.initNotification()) {
                this.autoSearch = false;
                return;
            }

            this.startAutoSearch();
        } else {
            this.stopAutoSearch();
        }

        this.autoSearch ? Karte.trackTurnOnBaggageAutoSearch() : Karte.trackTurnOffBaggageAutoSearch();
    }

    /**
     * 新着荷物一覧が更新された際に呼び出されます。
     */
    @Watch('newBaggageIdList')
    onChangeNewBaggageList(): void {
        if (!this.autoSearch || !this.baggageList) return;

        const newBaggageList = this.baggageList.filter((baggage) => this.newBaggageIdList.includes(baggage.id));
        if (newBaggageList.length === 0) {
            return;
        }

        newBaggageList.forEach(baggage => this.notifyNewBaggage(baggage));

        SoundUtil.playBell();
    }

    /**
     * 自動検索を開始します。
     */
    private startAutoSearch(): void {
        this.$emit('startAutoSearch');
    }

    /**
     * 自動検索を停止します。
     */
    private stopAutoSearch(): void {
        this.$emit('stopAutoSearch');
    }

    /**
     * 通知準備を行います。
     */
    private async initNotification(): Promise<boolean> {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            this.notifyFailed();
            return false;
        }

        return true;
    }

    /**
     * 新着荷物を通知します。
     */
    private notifyNewBaggage(baggage: baggageTypes.Baggage): void {
        const router = this.$router;

        const onClickNotification = (event: Event) => {
            event.preventDefault();
            router.push({ path: '/baggage/search', query: { baggageId: `${ baggage.id }` } }).catch(() => {
                // すでに開いている場合があるのでcatch
            });
            window.focus();

            Karte.trackClickBaggageAutoSearchNotification(baggage.id);
        };

        const notification = new Notification(BaggageSearchConditionAutoSearch.createNotificationMessage(baggage));
        notification.onclick = onClickNotification;

        Karte.trackReceiveBaggageAutoSearchNotification(baggage.id);
    }

    /**
     * 自動検索の開始失敗を通知します。
     */
    private notifyFailed(): void {
        this.$notification.info({
            key: 'BAGGAGE_AUTO_SEARCH_PERMISSION',
            message: '通知の送信が許可されていないため自動検索できません。',
            description: 'ブラウザの設定で通知の送信を許可してください。',
        });
    }

    /**
     * 自動検索の停止を通知します。
     */
    private notifyTerminated(): void {
        if (!this.autoSearch) return;
        this.$message.info('自動検索を停止しました。');
    }

    /**
     * 通知メッセージを生成します。
     */
    private static createNotificationMessage(baggage: baggageTypes.Baggage): string {
        // 出発日
        const departureDate = BaggageSearchConditionAutoSearch.toDepartureDate(baggage);
        // 出発/到着都道府県
        const prefecture = BaggageSearchConditionAutoSearch.toPrefecture(baggage);
        // 運賃
        const freight = BaggageSearchConditionAutoSearch.toFreight(baggage);

        return `${ departureDate }${ prefecture }${ freight }`;
    }

    /**
     * 荷物情報を出発日テキストへ変換します。
     */
    private static toDepartureDate(baggage: baggageTypes.Baggage): string {
        if (!baggage.departureMin || !baggage.departureMax) return '';
        const date = DeliveryDateTime.of(baggage.departureMin, baggage.departureMin)?.date?.format('M/D');
        return date ? `[${ date }]` : '';
    }

    /**
     * 荷物情報を出発/到着都道府県テキストへ変換します。
     */
    private static toPrefecture(baggage: baggageTypes.Baggage): string {
        const departure = baggage.departurePref.label;
        const arrival = baggage.arrivalPref.label;
        if (!departure || !arrival) return '';
        return `${ departure ?? '' }→${ arrival ?? '' }`;
    }

    /**
     * 荷物情報を運賃テキストへ変換します。
     */
    private static toFreight(baggage: baggageTypes.Baggage): string {
        const freight = baggage.freight ? `${ Util.formatNumber(baggage.freight) }円` : '要相談';
        return `(${ freight })`;
    }
}
