/**
 * 金額ValueObject
 */
import { ValueObject } from '@/models/vo/value-object';

export class AmountValue extends ValueObject<number> {
    constructor(value: number) {
        super(value);
    }

    addAll(...values: AmountValue[]): AmountValue {
        const amount = values.reduce(
            (prev, next) => prev + next.value,
            this.value
        );
        return new AmountValue(amount);
    }

    format(prefix: string = '', suffix: string = '円'): string {
        return `${ prefix }${ Intl.NumberFormat().format(this.value) }${ suffix }`;
    }
}
