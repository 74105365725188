<script setup lang="ts">
/**
 * 初期データのロード処理を行い、ロードが完了したら子コンポーネントに渡す。
 */
import { computed, onMounted, ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { useMyBaggage } from '@/composables/my-baggage';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import BaggageEditor from '@/_pages/Baggage/List/drawers/BaggageEditor/BaggageEditor.vue';
import { useNotificationExtension } from '@/composables/helper/notification-extension';

const props = defineProps<{
    baggageId: number;
}>();

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'dirty'): void;
    (e: 'done'): void;
}>();

const { state: { loading }, withLoading: withLoadingForInitializing } = useLoading();

const {
    state: { baggage },
    load: loadBaggage
} = useMyBaggage();

const {
    state: {
        myProfile: companyProfile,
    },
    load: loadCompanyProfile
} = useCompanyMyProfile();

const { close: closeNotification, sorryNotification } = useNotificationExtension('UPDATE_BAGGAGE_FAILED');

const showErrorMessage = () => {
    closeNotification();
    sorryNotification({ message: '荷物情報の取得ができませんでした。', });
};

onMounted(async () => withLoadingForInitializing(async () => {
    await Promise.all([
        await loadBaggage(props.baggageId),
        await loadCompanyProfile(),
    ]);

    if (!baggage.value || !companyProfile.value) {
        // 読み込み失敗
        showErrorMessage();
        emit('close');
        return;
    }
}));

type ViewState = 'editing' | 'updating' | 'finished';
const state = ref<ViewState>('editing');
const spinning = computed(() => state.value === 'updating' || state.value === 'finished');

const stateChanged = (newState: 'editing' | 'updating' | 'finished') => {
    state.value = newState;
    if (newState === 'finished') {
        emit('done');
    }
};

</script>

<template>
    <div class="layout">
        <div v-if="loading" class="loading-container">
            <a-spin/>
        </div>
        <template v-else-if="baggage && companyProfile">
            <a-spin :spinning="spinning">
                <baggage-editor :baggage="baggage"
                                :company-profile="companyProfile"
                                :state-changed="stateChanged"
                                @close="emit('close')"
                                @dirty="emit('dirty')"/>
            </a-spin>
        </template>
    </div>
</template>

<style scoped lang="less">
.layout {
    // 中央にスピンを表示する
    .loading-container {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
}
</style>
