import _ from 'lodash';
import { Modal } from 'ant-design-vue';
import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { Const } from '@/const';
import { ModalUtil, Util } from '@/util';
import { Karte } from '@/karte';
import { myBaggageSearchFormVisibility, pagination } from '@/repository/storage/web-storage';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as truckTypes from '@/vuex/modules/truck/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';
import {
    copy,
    goToRegisterBaggage,
    goToRoot,
    lazy,
    openPrintBaggagePage,
    scrollToTopOfResultList,
} from '@/pages/Baggage/List/tabs/common-helpers';
import {
    askEnteringPaymentTerms,
    cancelBaggage,
    clearBaggage,
    extractBaggageId,
    goToBaggageDetail,
    goToEditBaggage,
    goToSettingCompanyProfileExtra,
    isEmptyForm,
    loadBaggage,
    loadBaggageList,
    loadBaggageViewCountList,
    loadMyProfile,
    loadTruckRecommend,
    markBaggageUnderNegotiation,
    unmarkBaggageUnderNegotiation,
    loadNegotiationList,
} from '@/pages/Baggage/List/tabs/baggage-helpers';
import { predictAgreementProbability } from '@/pages/Baggage/List/tabs/prediction-helpers';
// @ts-ignore
import SearchCondition from '@/components/MyBaggage/Search/Condition';
// @ts-ignore
import BaggageList from '@/components/Baggage/View/List';
// @ts-ignore
import BaggageDetailDrawerPage from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent';
// @ts-ignore
import TruckCompanyDetailDrawerPage from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent/TruckCompanyDetailDrawerContent';
// @ts-ignore
import NegotiationCompanyDetailDrawerPage from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent/NegotiationCompanyDetailDrawerContent';
import { BaggageDetailTabKey } from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent/script';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
const baggageMod = namespace('baggage');
const companyMod = namespace('company');

const NOTIFICATION_KEY = 'BaggageOpenedListPage';

@Component({
    components: {
        SearchCondition,
        BaggageList,
        BaggageDetailDrawerPage,
        TruckCompanyDetailDrawerPage,
        NegotiationCompanyDetailDrawerPage,
    },
    beforeRouteEnter: BaggageOpenedListPage.beforeRouteEnter,
})
export default class BaggageOpenedListPage extends Vue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_LIST)
    readonly BAGGAGE_LIST?: baggageTypes.BaggageList;
    @baggageMod.Getter(baggageTypes.GETTER.TRUCK_RECOMMEND_LIST)
    readonly truckRecommendList?: truckTypes.TruckWithCompanyList;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly MY_COMPANY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageId?: string;

    /**
     * editクエリパラメーターを持っているか（編集中ページにアクセス中か否か）を取得します。
     */
    get hasEditQueryParam(): boolean {
        return _.has(this.$route.query, 'edit');
    }

    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get baggageDrawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return !_.isNaN(Number(this.baggageId));
    }

    /**
     * 空車企業ドロワーの表示状態を取得します。
     */
    get companyDrawerVisibility(): boolean {
        return _.has(this.$route.query, 'companyId');
    }

    get truckCompanyId(): number | undefined {
        const { companyId } = this.$route.query;

        if (typeof companyId === 'string' && Util.isNumeric(companyId)) {
            return Number(companyId);
        }
    }

    get truckId(): number | undefined {
        const { truckId } = this.$route.query;

        if (typeof truckId === 'string' && Util.isNumeric(truckId)) {
            return Number(truckId);
        }
    }

    /**
     * 商談リクエスト企業ドロワーの表示状態を取得します
     */
    get negotiationCompanyDrawerVisibility(): boolean {
        return _.has(this.$route.query, 'negotiationCompanyId');
    }

    get negotiationCompanyId(): number | undefined {
        const { negotiationCompanyId } = this.$route.query;

        if (typeof negotiationCompanyId === 'string' && Util.isNumeric(negotiationCompanyId)) {
            return Number(negotiationCompanyId);
        }
    }

    get negotiationId(): number | undefined {
        const { negotiationId } = this.$route.query;

        if (typeof negotiationId === 'string' && Util.isNumeric(negotiationId)) {
            return Number(negotiationId);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物の子ドロワー（＝荷物編集）の表示状態を取得します。
     */
    get baggageChildDrawerVisibility(): boolean {
        return this.baggageDrawerVisibility && this.hasEditQueryParam;
    }

    /**
     * ページサイズ
     */
    get pageSize(): number {
        return Number(pagination.getPageSize() ?? '20');
    }

    set pageSize(newValue: number) {
        this.value.pageSize = newValue;
        pagination.setPageSize(newValue.toString());
    }

    /**
     * 絞り込み中か否かを取得します。
     */
    get isFiltered(): boolean {
        return !isEmptyForm(this.value);
    }

    get truckRecommendCount(): number {
        return this.truckRecommendList?.data?.length ?? 0;
    }

    // ======================================================
    // Data
    // ======================================================
    loading = true;
    routeUpdating = false;
    searchFormVisible = false;
    detailTabKey: BaggageDetailTabKey = 'baggage';
    announceVisible = false;
    initialFormModel: Partial<baggageTypes.BaggageListForm> = {
        id: undefined,
        departurePref: [],
        arrivalPref: [],
        departureFrom: '',
        departureTo: '',
        truckWeight: [],
        truckModel: [],
        staffName: '',
    };
    formModel = _.cloneDeep(this.initialFormModel);
    initialValue: baggageTypes.BaggageListForm = {
        id: undefined,
        status: { code: 'OPENED' },
        departurePref: [],
        arrivalPref: [],
        departureFrom: '',
        departureTo: '',
        truckWeight: [],
        truckModel: [],
        staffName: '',
        pageNo: 1,
        pageSize: 20,
        sortKey: 'ID',
        sortOrder: 'DESC',
    };
    value = _.cloneDeep(this.initialValue);
    @ProvideReactive() agreementProbability = 0;

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.value.pageSize = this.pageSize;
        this.searchFormVisible = myBaggageSearchFormVisibility.get('OPENED');
    }

    /**
     * 検索フォームを開閉する。
     */
    toggleSearchFormVisibility(): void {
        this.searchFormVisible = !this.searchFormVisible;
        myBaggageSearchFormVisibility.set('OPENED', this.searchFormVisible);
    }

    /**
     * 検索ボタンが押下された際に呼び出されます。
     */
    async onClickSearch(): Promise<void> {
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // フォーム内容を検索条件へ反映
        this.commitForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);

        await this.askEnteringPaymentTermsIfNeeded();
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        // フォームをクリアするが検索条件には反映しない
        this.formModel = _.cloneDeep(this.initialFormModel);
    }

    /**
     * ソート順が変更された際に呼び出されます。
     */
    async onChangeSort(param: { sortKey: string; sortAscending: boolean }): Promise<void> {
        // ソート順定義を構築
        const definition = new Map<string, string>(Const.baggageSortKey.map(each => [each.code, each.defaultOrder]))
            .set(this.value.sortKey, param.sortAscending ? 'ASC' : 'DESC');
        // ソートキー
        this.value.sortKey = param.sortKey;
        // ソート順
        this.value.sortOrder = definition.get(param.sortKey) ?? this.value.sortOrder;
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);

        await this.askEnteringPaymentTermsIfNeeded();
    }

    /**
     * ページングが変更された際に呼び出されます。
     */
    async onChangePage(param: { pageNo: number; pageSize: number }): Promise<void> {
        // ページ番号
        this.value.pageNo = param.pageNo;
        // ページサイズ
        this.pageSize = param.pageSize;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);

        scrollToTopOfResultList();

        await this.askEnteringPaymentTermsIfNeeded();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物一覧の行が押下された際に呼び出されます。
     */
    async onClickBaggageRow(id: number): Promise<void> {
        if (extractBaggageId(this.$route) === id) {
            return;
        }
        await goToBaggageDetail(this.$route, id);
    }

    /**
     * 荷物をコピーするボタンが押下された際に呼び出されます。
     */
    onClickCopyBaggage(baggageId: number): Promise<Route> {
        return goToRegisterBaggage(baggageId);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物を変更するボタンが押下された際に呼び出されます。
     */
    async onClickEditBaggage(baggageId: number): Promise<void> {
        await goToEditBaggage(this.$route, baggageId);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物を削除するボタンが押下された際に呼び出されます。
     */
    async onClickDeleteBaggage(id: number): Promise<void> {
        this.closeErrorNotification();

        if (!await this.confirmCancelBaggage()) return;

        await this.cancelBaggage(id);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 商談中にします。
     */
    async onClickMarkUnderNegotiation(id: number): Promise<void> {
        const track = () => Karte.trackTurnOnUnderNegotiationFlg(id);
        await markBaggageUnderNegotiation(id).then(track);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 商談中を解除します。
     */
    async onClickUnmarkUnderNegotiation(id: number): Promise<void> {
        const track = () => Karte.trackTurnOffUnderNegotiationFlg(id);
        await unmarkBaggageUnderNegotiation(id).then(track);
    }

    onChangeTabKey(value: BaggageDetailTabKey): void {
        this.detailTabKey = value;
        this.trackShowTruckRecommend();
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        if (extractBaggageId(this.$route)) {
            await goToRoot(this.$route);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        openPrintBaggagePage(baggageId);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 詳細ページから更新処理があった際に呼び出されます。
     */
    async onUpdateList(): Promise<void> {
        await this.loadDataList();
        await this.askEnteringPaymentTermsIfNeeded();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 詳細ページから成約確率の予測がリクエストされた際に呼び出されます。
     */
    async onRequestPrediction(value: PredictionBaggageAgreementForm): Promise<void> {
        predictAgreementProbability(value).then(value => this.agreementProbability = value);
    }

    @Watch('truckRecommendList')
    private loadedTruckRecommend(): void {
        if (!this.truckRecommendList) return;
        /**
         * 空車情報の読み込みが完了した際に処理されます。
         */
        const registered = Boolean(this.$route.query.registered);
        if (registered && this.truckRecommendCount > 0) {
            this.announceVisible = true;
        }
    }

    async onCompanyClose(): Promise<void> {
        await goToBaggageDetail(this.$route, Number(this.baggageId));
    }

    async onNegotiationCompanyClose(): Promise<void> {
        await goToBaggageDetail(this.$route, Number(this.baggageId));
    }

    /**
     * 空車情報のアナウンスで「今すぐ見る」を押した際に呼び出されます。
     */
    onClickSoon(): void {
        this.detailTabKey = 'truck';
        this.announceVisible = false;
        if (!this.baggageId) return;
        Karte.trackClickTruckRecommendAnnounce(Number(this.baggageId), 'soon');
    }

    /**
     * 空車情報のアナウンスで「あとで見る」を押した際に呼び出されます。
     */
    onClickLater(): void {
        this.detailTabKey = 'baggage';
        this.announceVisible = false;
        if (!this.baggageId) return;
        Karte.trackClickTruckRecommendAnnounce(Number(this.baggageId), 'later');
    }

    /**
     * 商談リクエスト一覧のページネーションが変更された際に呼び出されます。
     */
    onChangeNegotiationListPage(pageNo: number, pageSize: number): void {
        loadNegotiationList(Number(this.baggageId), { pageNo: pageNo, pageSize: pageSize });
    }

    /**
     * 荷物削除確認モーダルを表示します。
     */
    private confirmCancelBaggage(): Promise<boolean> {
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: `選択した荷物を削除しますか？`,
            content: '削除すると、荷物の掲載も終了となります。また、この操作は元に戻すことができません。ご注意ください。',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '削除',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okType: 'danger',
            autoFocusButton: 'cancel',
        }));
    }

    /**
     * エラーNotificationを表示します。
     */
    private showErrorNotification(message: string): void {
        this.$notification.error({
            key: NOTIFICATION_KEY,
            message,
            description:
                '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        });
    }

    /**
     * エラーNotificationの表示を閉じます。
     */
    private closeErrorNotification(): void {
        this.$notification.close(NOTIFICATION_KEY);
    }

    /**
     * ルーティングによってこのページに到達した際に呼び出されます。
     * @param to
     * @param _from
     * @param next
     */
    static async beforeRouteEnter(to: Route, _from: Route, next: NavigationGuardNext<BaggageOpenedListPage>): Promise<void> {
        next(async page => {
            await page.loadDataList().catch(page.notifyFailedToLoadData);

            const baggageId = extractBaggageId(to);

            // 一覧表示なら終了
            if (baggageId !== undefined) {
                await lazy(async () => {
                    await loadBaggage(baggageId)
                        .then(baggage => {
                            if (baggage.negotiationType.code === NegotiationTypeEnum.Online.code) {
                                loadNegotiationList(baggageId, { pageNo: 1, pageSize: 50 });
                            }
                        })
                        .catch(page.notifyFailedToLoadDetailData);
                    await loadTruckRecommend();
                });
            }

            await page.askEnteringPaymentTermsIfNeeded();
        });
    }

    // noinspection DuplicatedCode
    /**
     * ルーティング情報が変化した際に呼び出されます。
     * @param to
     * @param _from
     * @param next
     */
    async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<BaggageOpenedListPage>): Promise<void> {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (this.routeUpdating) return next(false);

        const fromBaggageId = extractBaggageId(from);
        const toBaggageId = extractBaggageId(to);

        next();
        // 同じ荷物でのドロワー内での遷移(荷物・編集・空車情報・商談リクエスト間の遷移)の場合は終了
        if (fromBaggageId && toBaggageId && _.isEqual(fromBaggageId, toBaggageId)) {
            return;
        }

        // 以降は、閲覧中の荷物詳細が変化するパターン
        this.detailTabKey = 'baggage';

        await clearBaggage();

        // 一覧表示なら終了
        if (!toBaggageId) return;

        this.routeUpdating = true;

        await lazy(async () => {
            await loadBaggage(Number(toBaggageId))
                .then(baggage => {
                    if (baggage.negotiationType.code === NegotiationTypeEnum.Online.code) {
                        loadNegotiationList(toBaggageId, { pageNo: 1, pageSize: 50 });
                    }
                })
                .catch(this.notifyFailedToLoadDetailData);
            await loadTruckRecommend();
            this.trackShowTruckRecommend();
        });

        this.routeUpdating = false;
    }

    /**
     * データロード失敗を通知します。
     */
    private notifyFailedToLoadData(): void {
        this.$message.error(`荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。`);
    }

    /**
     * 詳細データロード失敗を通知します。
     */
    private notifyFailedToLoadDetailData(): void {
        this.$message.error(`荷物番号 ${ this.baggageId } の荷物情報を読み込みできませんでした。すでに荷物が削除されている可能性があります。`);
    }

    /**
     * データをロードします。
     */
    private async loadDataList(): Promise<void> {
        this.loading = true;

        // 荷物一覧ロード
        await loadBaggageList(this.value);

        // 荷物閲覧会員数ロード
        await loadBaggageViewCountList(this.BAGGAGE_LIST?.data ?? []);

        // 企業プロフィールロード（最新の支払いサイト設定状況を取得しなす目的）
        await loadMyProfile();

        this.loading = false;
    }

    /**
     * 荷物を削除します。
     */
    private async cancelBaggage(id: number): Promise<void> {
        const track = () => Karte.trackCancelBaggage(id);

        const onSuccess = async () => {
            await this.onClickCloseDrawer();

            this.$message.success('選択した荷物を削除しました。');
        };

        const onError = () => this.showErrorNotification('選択した荷物を削除できませんでした。');

        await cancelBaggage(id)
            .then(track)
            .then(onSuccess)
            .catch(onError);

        await this.loadDataList();

        await this.askEnteringPaymentTermsIfNeeded();
    }

    /**
     * 検索条件フォーム内容を検索条件として確定させます。
     */
    private commitForm(): void {
        copy(this.value, this.formModel);
    }

    /**
     * 確定済みの検索条件を検索フォームへ戻します。
     */
    private revertForm(): void {
        copy(this.formModel, this.value, _.keys(this.formModel));
    }


    /**
     * 必要であれば支払サイト入力誘導モーダルを表示します。
     */
    private async askEnteringPaymentTermsIfNeeded(): Promise<void> {
        if (this.MY_COMPANY_PROFILE === undefined || this.BAGGAGE_LIST === undefined) {
            return;
        }
        if (!this.MY_COMPANY_PROFILE.hasPaymentTerms && this.BAGGAGE_LIST.data.length > 0) {
            if (await askEnteringPaymentTerms()) {
                await goToSettingCompanyProfileExtra();
            }
        }
    }

    /**
     * 空車レコメンドデータがあり、空車情報タブを開いた際にKarteにログを送る
     */
    private trackShowTruckRecommend(): void {
        if (this.detailTabKey !== 'truck') return;
        if (!this.truckRecommendList) return;
        if (!this.baggageId) return;
        const baggageId = Number(this.baggageId);
        const truckIds = this.truckRecommendList?.data?.map(t => t.id) ?? [];
        Karte.trackShowTruckRecommend(baggageId, truckIds);
    }
}
