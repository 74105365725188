export interface JsonSerializeOption {
    strict: boolean;
}
export class JsonUtil {
    /**
     * ObjectのEntryから `_` から始まるpropertyを削除
     * PrototypeのEntryから
     * @param instance
     * @param ignoreKeys
     */
    static serialize<T>(instance: T, options: JsonSerializeOption = { strict: true }): any {
        // strict = trueの場合、Object.entriesのキーのうち、_から始まるキーは対応するgetterがあればコピー。それ以外は普通にコピー。
        const proto = Object.getPrototypeOf(instance);
        const getterEntries = Object.entries(Object.getOwnPropertyDescriptors(proto))
            .filter(([key, descriptor]) => typeof descriptor.get === 'function');
        if (options.strict) {
            const descriptorMap = new Map<string, PropertyDescriptor>();
            getterEntries.map(([key, descriptor]) => {
                descriptorMap.set(key, descriptor);
            });
            const jsonObj: any = {};
            const instanceEntries = Object.entries(instance);
            instanceEntries.map(([key, value]) => {
                if (key[0] !== '_') {
                    jsonObj[key] = value;
                } else {
                    const rawKey = key.substring(1);
                    if (descriptorMap.has(key.substring(1))) {
                        jsonObj[rawKey] = value;
                    }
                }
            });
            return jsonObj;
        } else {
            const jsonObj: any = Object.assign({}, instance);
            getterEntries.map(([key, descriptor]) => {
                if (descriptor && key[0] !== '_') {
                    const val = (instance as any)[key];
                    jsonObj[key] = val;
                }
            });
            return jsonObj;
        }
    }
}
