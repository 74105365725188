import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementDescription extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get description(): string {
        return this.agreement?.description ?? '';
    }

    get descriptionCr(): string {
        return this.agreementCr?.description ?? '';
    }

    get changeRequest(): string {
        // change request does not exist
        if (!this.agreementCr) return '';
        // no change
        if (this.description === this.descriptionCr) return '';

        return this.descriptionCr ? this.descriptionCr : '(削除済み)';
    }
}
