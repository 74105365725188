<script setup lang="ts">
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import { useAccountPasswordResetVerifyHelper } from './account-password-reset-verify-helper';

const props = defineProps<{
    verificationCode?: string,
}>();
const {
    loadingVerify,
    loadingSubmit,
    verified,
    form,
    formValidateRules,
    formModel,
    onSubmit,
    passwordInput,
} = useAccountPasswordResetVerifyHelper(props.verificationCode);
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <a-spin :spinning="loadingVerify">
                <div v-if="loadingVerify">
                    <h1 class="body__title">確認を行っています</h1>
                    <p>しばらくお待ちください...</p>
                </div>
                <div v-else-if="verified" class="form">
                    <h1 class="body__title">パスワード再設定</h1>
                    <a-spin :spinning="loadingSubmit">
                        <p>新しいパスワードを入力してください。</p>
                        <a-form-model ref="formModel" :model="form" :rules="formValidateRules" :colon="false">
                            <div class="form__fields">
                                <a-form-model-item prop="password"
                                                   ref="formItemPassword">
                                    <a-input-password ref="passwordInput"
                                                      placeholder="パスワード"
                                                      v-model="form.password"
                                                      @pressEnter="onSubmit">
                                        <template #prefix><a-icon type="lock"/></template>
                                    </a-input-password>
                                </a-form-model-item>

                                <a-form-model-item prop="passwordConfirm"
                                                   ref="formItemPasswordConfirm">
                                    <a-input-password placeholder="パスワード（確認用）"
                                                      v-model="form.passwordConfirm"
                                                      @pressEnter="onSubmit">
                                        <template #prefix><a-icon type="lock"/></template>
                                    </a-input-password>
                                </a-form-model-item>
                            </div>
                            <nav>
                                <ul class="form__actions">
                                    <li><a-button type="primary" @click="onSubmit">パスワードを再設定</a-button></li>
                                </ul>
                            </nav>
                        </a-form-model>
                    </a-spin>
                </div>
                <div v-else>
                    <h1 class="body__title">メール確認ができませんでした</h1>
                    <p>パスワード再設定の確認メールからもう一度アクセスしなおしてください。</p>
                    <p>何度試しても変わらない場合は、<router-link :to="{ name: 'AccountPasswordRemind' }">パスワード再設定</router-link>ページからもう一度お試しください。</p>
                </div>
            </a-spin>
        </div>
    </pre-login-layout>
</template>

<style scoped lang="less">
.body__container {
    width: 400px;
    min-height: 288px;
}

.body__title {
    margin-bottom: @padding-lg;
    font-size: 18px;
    line-height: 30px;
    color: #48483f;
}

.form {
    .form__fields {
        min-height: 132px;
    }

    .form__actions {
        margin: @padding-lg 0 0;
        padding: 0;
        list-style-type: none;

        > li {
            text-align: center;

            .ant-btn {
                min-width: 128px;
            }
        }

        > li + li {
            margin-top: @padding-sm;
        }
    }
}
</style>
