import {
    AccountChangeEmailVerificationForm, AccountNewMemberResult,
    AccountPasswordRemindForm, AccountPasswordResetForm,
    AccountPasswordResetVerificationForm,
    AccountPasswordUpdateForm,
    AccountProfileCreateForm, AccountProfileCreateOrUpdateForm,
    AccountRegisterForm,
    AccountVerificationForm,
    Profile
} from '@/vuex/modules/account/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';
import { AccountRenewalVerificationForm } from '@/models/account-renewal';
import { Entitlement } from '@/models/entitlement';

export const accountApi = {
    /**
     * トラボックス配車用のSSOトークンを発行します。
     */
    issueSsoDispatchToken(): Promise<string> {
        return appAxios.get<JsonResponseBean<string>>('/api/account/sso/token/dispatch').then(isSuccess);
    },
    /**
     * トラボックス受発注用のSSOトークンを発行します。
     */
    issueSsoOnlineOrderToken(): Promise<string> {
        return appAxios.get<JsonResponseBean<string>>('/api/account/sso/token/online-order').then(isSuccess);
    },
    /**
     * 会員登録します。
     */
    register(param: AccountRegisterForm): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>('/api/account/register', param).then(isSuccess);
    },
    /**
     * 本人認証します。
     */
    verify(param: AccountVerificationForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/verify', param).then(isSuccess);
    },
    /**
     * 追加アカウントの本人認証します。
     */
    verifyForAdditionalAccount(param: AccountVerificationForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/new/verify', param).then(isSuccess);
    },
    /**
     * 本人認証します。
     */
    verifyEmail(param: AccountChangeEmailVerificationForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/email/verify', param).then(isSuccess);
    },
    /**
     * ログインします。
     *
     * @param emailAddress メールアドレス
     * @param password パスワード
     * @param token reCAPTCHAトークン
     */
    login(emailAddress: string, password: string, token: string): Promise<JsonResponseBean> {
        const formData = new FormData();
        formData.append('username', emailAddress);
        formData.append('password', password);
        formData.append('token', token);

        return appAxios.post<JsonResponseBean>('/doLogin', formData).then(isSuccess);
    },

    /**
     * ログアウトします
     */
    logout(): Promise<void> {
        return appAxios.post<void>('/logout').then(() => undefined);
    },

    /**
     * 自分の会員プロフィールを取得します。
     */
    myProfile(): Promise<Profile> {
        return appAxios.get<JsonResponseBean<Profile>>('/api/account/profile').then(isSuccess);
    },
    /**
     * 企業のアカウント一覧（プロフィール一覧）を取得します。
     */
    profileList(): Promise<Profile[]> {
        return appAxios.get<JsonResponseBean<Profile[]>>('/api/account/list').then(isSuccess);
    },
    /**
     * 会員プロフィールを取得します。
     */
    profile(id: number): Promise<Profile> {
        return appAxios.get<JsonResponseBean<Profile>>(`/api/account/${ id }/profile`).then(isSuccess);
    },
    /**
     * 会員パスワードを更新します。
     */
    updatePassword(param: AccountPasswordUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/password', param).then(isSuccess);
    },
    /**
     * 会員パスワードの再設定を行うための確認メールを送信します。
     */
    remindPassword(param: AccountPasswordRemindForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/password/remind', param).then(isSuccess);
    },
    /**
     * 会員パスワード再設定を行うための認証コードの検証をします。
     */
    verifyAccountPasswordResetCode(param: AccountPasswordResetVerificationForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/password/verify', param).then(isSuccess);
    },
    /**
     * 会員パスワードを再設定（新しいパスワードで設定）します。
     */
    resetPassword(param: AccountPasswordResetForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/password/reset', param).then(isSuccess);
    },
    /**
     * 会員を転生（メールアドレス変更＋パスワード再設定）します。
     */
    verifyAccountRenewalCode(param: AccountRenewalVerificationForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/renewal/verify', param).then(isSuccess);
    },
    /**
     * 新しい会員アカウント（プロフィール）を新規作成します。
     */
    createProfile(param: AccountProfileCreateForm): Promise<AccountNewMemberResult> {
        return appAxios.post<JsonResponseBean<AccountNewMemberResult>>('/api/account/new', param).then(isSuccess);
    },
    /**
     * 会員アカウント（プロフィール）を更新します。
     */
    updateProfile(param: AccountProfileCreateOrUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/account/profile/update', param).then(isSuccess);
    },
    /**
     * 会員アカウント（プロフィール）を削除します。
     */
    deleteProfile(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/account/${ id }/delete`).then(isSuccess);
    },
    /**
     * 資格を取得します。
     */
    entitlement(): Promise<Entitlement> {
        return appAxios.get<JsonResponseBean<Entitlement>>('/api/account/entitlement').then(isSuccess);
    },
};
