import { computed, onMounted, ref } from 'vue';
import type Vue from 'vue';
import { useBaggageList } from '@/composables/baggage-list';
import { useMyBaggageSearchFormVisibility } from '@/composables/my-baggage-search-form';
import { useMessage } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';
import { BaggageSortKeyCode } from '@/const';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { useTimer } from '@/composables/helper/time-helper';
import { useRouteExtension } from '@/composables/helper/route-extension';
import { useMyBaggage } from '@/composables/my-baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import type { DetailProps } from './props';
import { PageUtil } from '@/util';

export const useBaggageListCanceledHelper = (
    props: { detail?: DetailProps }
) => {
    const {
        state: {
            loading,
            form,
            isFiltered,
            validationMessage,
            list,
            sortInfo,
            pageInfo,
        },
        search: searchBaggage,
        changePage: changeBaggageListPage,
        clearForm,
    } = useBaggageList('CANCEL');
    const {
        state: { baggage },
        load: loadMyBaggage,
        clear: clearMyBaggage
    } = useMyBaggage();
    const { visible: searchFormVisible } = useMyBaggageSearchFormVisibility('CANCELED');
    const message = useMessage();
    const {
        goToCanceledBaggageList,
        goToBaggageRegister,
        goToCanceledBaggageDetail,
        openMyBaggagePrint,
        goToNotFound,
    } = useRouting();
    const { lazy } = useTimer();
    const { canceledMyBaggageDetailProps } = useRouteExtension();
    const { state: { loading: loadingDetail }, withMuteableLoading } = useLoading();

    const resultListRef = ref<Vue>();

    const routeUpdating = ref(false);

    // 詳細ドロワーの表示状態
    const baggageDrawerVisibility = computed(() => {
        return !routeUpdating.value && props.detail !== undefined;
    });

    const search = async () => {
        try {
            await searchBaggage();
        } catch {
            notifyFailedToLoadData();
        }
    };

    const changePage = async (value: {
        pageNo: number,
        pageSize: number,
        sortKey?: BaggageSortKeyCode,
        sortOrder?: 'ASC' | 'DESC'
    }) => {
        try {
            await changeBaggageListPage(value);
            scrollToTopOfResultList();
        } catch {
            notifyFailedToLoadData();
        }
    };

    const goToBaggageDetail = async (
        baggageId: number
    ) => {
        // 連打などにより画面遷移が失敗する可能性があるので、遷移失敗の例外をもみ消す
        await goToCanceledBaggageDetail(baggageId).catch(() => {});
    };

    const closeBaggageView = async () => {
        if (props.detail?.baggageId) {
            await goToCanceledBaggageList();
        }
    };

    const notifyFailedToLoadData = () => {
        message.error('荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。');
    };

    const scrollToTopOfResultList = () => {
        if (resultListRef.value) {
            PageUtil.scrollToContentTop((resultListRef.value?.$el as HTMLDivElement).offsetTop);
        }
    };

    const clearDetail = () => {
        clearMyBaggage();
    };

    const loadDetail = async (baggageId: number, mute: boolean = false) => {
        await withMuteableLoading(mute, async () => {
            await loadMyBaggage(baggageId);
        });
    };

    onMounted(async () => {
        await search();

        if (props.detail?.baggageId) {
            try {
                await loadDetail(props.detail.baggageId);
            } catch {
                await goToNotFound();
            }
        }
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const fromDetailProps = canceledMyBaggageDetailProps(from);
        const toDetailProps = canceledMyBaggageDetailProps(to);

        // 荷物が非選択になる場合
        if (toDetailProps === undefined) {
            clearDetail();
            next();
            return;
        }

        // 荷物IDが同じ場合
        if (fromDetailProps?.baggageId === toDetailProps.baggageId) {
            next();
            return;
        }

        // 選択荷物に変化がある場合
        // - 未選択状態から荷物を選択した場合
        // - 異なる荷物を選択した場合
        routeUpdating.value = true;
        try {
            await lazy(async () => {
                await loadDetail(toDetailProps.baggageId);
            });
        } catch {
            message.error(`荷物番号 ${ toDetailProps.baggageId } の荷物情報を読み込みできませんでした。すでに荷物が削除されている可能性があります。`);
            next(false);
            return;
        } finally {
            routeUpdating.value = false;
        }

        next();
    });

    return {
        searchFormVisible,
        isFiltered,
        loading,
        loadingDetail,
        form,
        validationMessage,
        list,
        sortInfo,
        pageInfo,
        baggageDrawerVisibility,
        baggage,
        resultListRef,
        search,
        changePage,
        clearForm,
        selectBaggage: goToBaggageDetail,
        copyBaggage: goToBaggageRegister,
        printBaggage: openMyBaggagePrint,
        closeBaggageView,
    };
};
