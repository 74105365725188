<script setup lang="ts">
import { useSettingCompanyProfileHelper } from './setting-company-profile-helper';
import { useFormModelItem } from '@/composables/form-helper';
// Component
import UiPrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import UiCityInput from '@/_components/ui/CityInput.vue';
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';

const {
    myProfile,
    form,
    formValidateRules,
    formModel,
    loading,
    onSubmit,
} = useSettingCompanyProfileHelper();
const { formModelItem: formLocationItem, onChange: changeLocation, } = useFormModelItem();

</script>

<template>
    <setting-layout>
        <a-card>
            <h3>{{ myProfile?.name.kanji }}</h3>
            <br>

            <a-spin :spinning="loading">
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ xs: { span: 3 }, xxl: { span: 3, offset: 2 } }"
                              :wrapper-col="{ xs: { span: 21 }, xxl: { span: 18 } }">
                    <a-form-model-item label="住所"
                                       prop="location"
                                       :auto-link="false"
                                       ref="formLocationItem">
                        <a-input-group compact size="default">
                            <a-input style="width: 18%"
                                     prefix="〒"
                                     :max-length="8"
                                     v-model="form.zipCode"
                                     @change="changeLocation"></a-input>

                            <ui-prefecture-select style="width: 18%"
                                                  :multiple="false"
                                                  placeholder="都道府県"
                                                  @change="changeLocation"
                                                  v-model="form.prefecture"/>

                            <ui-city-input style="width: 32%;"
                                           placeholder="市区町村"
                                           :pref-code="form.prefecture"
                                           @change="changeLocation"
                                           v-model="form.city"></ui-city-input>

                            <a-input style="width: 32%"
                                     placeholder="番地・建物"
                                     v-model="form.address"
                                     @change="changeLocation"
                                     @blur="form.normalize()"></a-input>
                        </a-input-group>
                    </a-form-model-item>
                    <a-form-model-item label="電話番号"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="phoneNumber">
                        <a-input v-model="form.phoneNumber" @blur="form.normalize()"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="FAX"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="faxNumber">
                        <a-input v-model="form.faxNumber" @blur="form.normalize()"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="車両台数"
                                       :wrapper-col="{ xs: { span: 3 }, xxl: { span: 3 } }"
                                       prop="truckCount">
                        <a-input v-model="form.truckCount" suffix="台" :max-length="5"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="HPアドレス"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="url"
                                       ref="formItem">
                        <a-input placeholder="www.trabox.com" v-model="form.urlPath">
                            <template #addonBefore>
                                <a-select v-model="form.urlScheme" style="width: 96px;">
                                    <a-select-option value="https://">https://</a-select-option>
                                    <a-select-option value="http://">http://</a-select-option>
                                </a-select>
                            </template>
                        </a-input>
                    </a-form-model-item>

                    <br/>

                    <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                        <a-button style="max-width: 200px"
                                  type="primary"
                                  size="large"
                                  block
                                  @click="onSubmit">保存
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-card>
    </setting-layout>
</template>

<style scoped lang="less">

</style>
