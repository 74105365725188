<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import EasyPaymentGuideLink from '@/_components/easy-payment/EasyPaymentGuideLink.vue';
import { computed } from 'vue';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { useSettlementListTabKey } from '@/composables/settlement-list';
import { useSettlementPaperTabKey } from '@/composables/transaction';

export type SettlementTabKey = 'home' | 'list' | 'paper';

const { currentRoute } = useRoute();
const { goToSettlementHome } = useRouting();
const { goToSettlementList } = useSettlementListTabKey();
const { goToSettlementPaper } = useSettlementPaperTabKey();

const activeTabKey = computed(() => currentRoute.meta?.activeTabKey?.split('/')?.at(1));

const onChangeTab = async (key: SettlementTabKey) => ({
    home: goToSettlementHome,
    list: goToSettlementList,
    paper: goToSettlementPaper,
})[key]?.();
</script>

<template>
    <a-card class="settlement-container">
        <a-tabs
            :tabBarStyle="{ padding: '4px 8px 0', margin: '0' }"
            :active-key="activeTabKey"
            @change="onChangeTab">
            <a-tab-pane key="home">
                <template #tab>
                    <tbx-icon type="home"/>
                    ホーム
                </template>
            </a-tab-pane>
            <a-tab-pane key="list">
                <template #tab>
                    <tbx-icon type="handshake"/>
                    取引
                </template>
            </a-tab-pane>
            <a-tab-pane key="paper">
                <template #tab>
                    <tbx-icon type="file-text"/>
                    支払通知書・請求書
                </template>
            </a-tab-pane>
            <template #tabBarExtraContent>
                <easy-payment-guide-link class="text-link"/>
            </template>
        </a-tabs>

        <router-view></router-view>
    </a-card>
</template>

<style scoped lang="less">
.settlement-container > ::v-deep(.ant-card-body) {
    padding: 0;
}

::v-deep(.ant-tabs-tab) {
    margin: 0;
}

.text-link {
    margin-right: @padding-md;
}
</style>
