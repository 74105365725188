import { useRoute } from '@/composables/helper/route';
import { computed, onMounted } from 'vue';
import { URLUtil } from '@/util';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useAccountRegister } from '@/composables/account-register';
import { useNotification } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';

export const useAccountRegisterHelper = () => {
    const { currentRoute } = useRoute();
    const { state: { loading, form, dirty }, register } = useAccountRegister();
    const notification = useNotification();
    const { goToAccountComplete, goToPasswordRemind } = useRouting();

    /**
     * iframeで埋め込まれた登録フォームか否かを取得します。
     * landingページについては /account/register?view=embed でアクセスされるようになっています
     */
    const isEmbedView = computed(() => {
        const viewQuery = URLUtil.parseQueryValue(currentRoute.query.view);
        return viewQuery === 'embed';
    });

    const notifyFailedKey = 'REGISTER_FAILED';
    const notifyRegisterFailed = () => {
        notification.error({
            key: notifyFailedKey,
            message: 'アカウントの登録ができませんでした。',
            description:
                'ご入力いただいたメールアドレスはすでに登録されています。過去に登録されたことのあるメールアドレスをご利用になりたい場合は、パスワードの再設定をお試しください。',
            btn: (h: Function) => {
                return h(
                    'a-button',
                    {
                        props: {
                            type: 'secondary',
                            size: 'small',
                        },
                        on: {
                            click: async () => {
                                notification.close(notifyFailedKey);
                                await goToPasswordRemind();
                            },
                        },
                    },
                    'パスワードの再設定を行う'
                );
            },
        });
    };
    const onSubmit = async () => {
        try {
            await register();
            await goToAccountComplete(isEmbedView.value ? 'embed' : undefined);
        } catch {
            notifyRegisterFailed();
        }
    };

    /**
     * ページ離脱時に確認メッセージを返却します。
     */
    const unloaded = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        const confirmMessage = 'お申し込みが完了していません。このページから移動しますか？';
        e.returnValue = confirmMessage;
        return confirmMessage;
    };

    onMounted(() => window.addEventListener('beforeunload', unloaded, false));

    onBeforeRouteLeave((_to, _from, next) => {
        if (dirty.value && !confirm('画面を移動すると入力中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            window.removeEventListener('beforeunload', unloaded, false);
            next();
        }
    });

    return {
        loading,
        form,
        onSubmit,
    };
};
