import { Questionnaire } from '@/models/questionnaire';
import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { questionnaireApi } from '@/repository/api/internal/questionnaire';

export const useQuestionnaire = () => {
    const { state: { loading }, withLoading } = useLoading();
    const questionnaire = ref<Questionnaire | undefined>(undefined);

    const load = (use: 'REVOKE_PREMIUM' | 'DEACTIVATE_COMPANY') => withLoading(async () => {
        questionnaire.value = await questionnaireApi.question(use);
    });

    return {
        state: {
            loading,
            questionnaire,
        },
        load,
    };
};
