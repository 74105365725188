import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/baggage/types';
import { AgreementUtil, DateUtil } from '@/util';
import moment from 'moment';
import dayjs from 'dayjs';

@Component
export default class BaggagePaymentDateRegister extends Vue {
    viewDateFormat = Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.BaggageRegisterFormModel;
    @Prop()
    declare readonly defaultPaymentDate?: string;
    edit: boolean = false;
    datePickerModeValue: 'date' | 'month' = 'date';

    get validationRules(): Array<ValidationRule> {
        return [
            {
                required: _.isEmpty(this.defaultPaymentDate),
                message: '入金予定日を選択してください。',
            },
            {
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
            },
        ];
    }

    /**
     * 入金予定日
     */
    get paymentDate(): string | undefined {
        if (!this.editPaymentDate) {
            return this.defaultPaymentDate ?? undefined;
        }
        return this.value?.paymentDate;
    }

    set paymentDate(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 入金予定日を書き換え
        cloned.paymentDate = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 入金編集チェック
     */
    get editPaymentDate(): boolean {
        if (_.isEmpty(this.defaultPaymentDate)) {
            return true;
        }
        return this.edit;
    }

    set editPaymentDate(newValue: boolean) {
        this.edit = newValue;
        if (this.edit) {
            this.paymentDate = this.defaultPaymentDate ?? undefined;
        } else {
            this.paymentDate = undefined;
        }
        this.$emit('enableEdit', newValue);
    }

    get disablePaymentDate(): boolean {
        return !this.editPaymentDate;
    }

    get editablePaymentDate(): boolean {
        return !_.isEmpty(this.defaultPaymentDate);
    }

    get datePickerMode(): 'date' | 'month' {
        return this.datePickerModeValue;
    }

    set datePickerMode(value: 'date' | 'month') {
        this.datePickerModeValue = value;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 選択できない日付であるか否かを取得します。
     * @param date
     */
    isDisabledDate(date: moment.Moment): boolean {
        if (!this.value) {
            return false;
        }
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(this.value.arrivalMax), false);
        return !DateUtil.isIncluded(dayjs.unix(date.unix()), range);
    }

    onPanelChange(value: moment.Moment, mode: 'time' | 'date' | 'month' | 'year' | 'decade'): void {
        if (mode === 'year' || mode === 'decade') {
            this.datePickerMode = 'month';
        } else if (mode === 'time') {
            this.datePickerMode = 'date';
        } else {
            this.datePickerMode = mode;
        }
        if (!_.isEmpty(this.paymentDate)) this.paymentDate = value.format('YYYY-MM-DD');
    }

    mounted(): void {
        if (this.value?.paymentDate) {
            this.edit = true;
        }
    }

    @Watch('defaultPaymentDate')
    watchPaymentDate(newValue: string | undefined, oldValue: string | undefined): void {
        if (!_.isEqual(newValue, oldValue)) {
            // @ts-ignore
            this.$nextTick(() => this.$refs.formItem.onFieldChange());
        }
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        const arrivalDate = this.value?.arrivalMax ?? '';
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(arrivalDate), false);
        if (!this.paymentDate) {
            callback();
            return;
        }
        if (!DateUtil.isIncluded(DateUtil.parseDateText(this.paymentDate), range)) {
            const rangeText = `${ range[0].format('YYYY年MM月DD日') }から${ range[1].format('YYYY年MM月DD日') }`;
            callback(`入金予定日は${ rangeText }の範囲で指定してください。`);
        } else {
            callback();
        }
    }
}
