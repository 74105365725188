<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import CreateEventModal from '@/_pages/Truck/Schedule/EventModal/Register/CreateEventModal.vue';
import EditEventModal from '@/_pages/Truck/Schedule/EventModal/Edit/EditEventModal.vue';
import { useSchedulerHelper } from '@/_pages/Truck/Schedule/scheduler-helper';
import TimelineScheduler from '@/_components/ui/timeline-scheduler/TimelineScheduler.vue';
import DrawerContentLayout from '@/_pages/Baggage/List/drawers/common/DrawerContentLayout.vue';
import DateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import { Util } from '@/util';
import BaggageTypeView from '@/_components/parts/baggage/BaggageType.vue';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { Enum } from '@/types/enum';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';

interface Props {
    eventId?: string;
}

const props = defineProps<Props>();

const {
    loading,
    loadingMatches,
    selectedDate,
    selectedCell,
    selectedEvent,
    resourceList,
    matchingList,
    eventSettings,
    createEventModalVisible,
    editEventModalVisible,
    eventMatchingDrawerVisibility,
    pageInfo,
    onChangePage,
    onSelect,
    onEventClick,
    onEventMatchingClick,
    onClickGround,
    onClickMatch,
    onCellClick,
    onDateChanged,
    onEventModalOk,
    onEventModalCancel,
    closeEventMatching,
} = useSchedulerHelper(props);

const freightText = (freight?: number) => {
    freight = freight ?? 0;
    return freight > 0 ? `￥${ Util.formatNumber(freight) }` : '要相談';
};

const highwayFareText = (highwayFareFlg: boolean) => `高速代${ highwayFareFlg ? 'あり' : 'なし' }`;

const toPrefectureEnum = (e: Enum) => {
    return e as Enum<PrefectureEnumCode>;
};

</script>

<template>
    <page-layout :show-header="false" :back-icon="false" @click="onClickGround">
        <div class="board-container">
            <a-card :body-style="{ padding: 0 }">
                <a-spin :spinning="loading">
                    <div v-if="resourceList.length === 0" style="text-align: center; padding: 20px;">
                        <a-empty description="車両マスターに車両を追加すると空車表が利用できます。"/>
                    </div>
                    <TimelineScheduler
                        v-else
                        width="100%"
                        height="calc(100vh - 200px)"
                        :eventList="eventSettings.dataSource"
                        :resourceList="resourceList"
                        :selectedDate="selectedDate"
                        @event-click="onEventClick"
                        @event-matching-click="onEventMatchingClick"
                        @cell-selected="onSelect"
                        @cell-click="onCellClick"
                        @date-changed="onDateChanged"
                    />
                    <CreateEventModal
                        v-if="selectedCell"
                        :visible.sync="createEventModalVisible"
                        :startTm="selectedCell.startDate"
                        :endTm="selectedCell.endDate"
                        :company-truck-id="selectedCell.resourceId"
                        @ok="onEventModalOk"
                        @cancel="onEventModalCancel"/>
                    <EditEventModal
                        v-if="selectedEvent"
                        :visible.sync="editEventModalVisible"
                        :company-event-id="selectedEvent.Id"
                        @ok="onEventModalOk"
                        @cancel="onEventModalCancel"/>
                </a-spin>
            </a-card>
        </div>
        <a-drawer width="600"
                  :visible="eventMatchingDrawerVisibility"
                  :body-style="{ padding: 0 }"
                  :mask="false"
                  :destroy-on-close="true"
                  @close="closeEventMatching">
            <a-tabs class="drawer-content-tabs"
                    :tabBarGutter="0"
                    :tabBarStyle="{ padding: '12px 68px 0px 24px', margin: 0 }">
                <a-tab-pane key="baggage" tab="マッチした荷物情報">
                    <drawer-content-layout :loading="loadingMatches">
                        <a-empty v-if="!loadingMatches && matchingList.length === 0">
                            <template #description>
                                <span>マッチした荷物情報がありません。</span>
                            </template>
                        </a-empty>
                        <div v-else>
                            <div class="match-contents">
                                <a-row :gutter="[0,16]">
                                    <a-col v-for="match in matchingList" :span="24" :key="match.baggage.id">
                                        <a-card style="width: 100%; cursor: pointer" @click="onClickMatch(match.baggage)">
                                            <template #title>
                                                <span class="component-note-xs">{{
                                                        match.companyProfile.name.kanji
                                                    }}</span>
                                            </template>
                                            <date-time-location-label :departure-min="match.baggage.departureMin"
                                                                      :departure-max="match.baggage.departureMax"
                                                                      :departure-pref="toPrefectureEnum(match.baggage.departurePref)"
                                                                      :departure-city="match.baggage.departureCity"
                                                                      :arrival-min="match.baggage.arrivalMin"
                                                                      :arrival-max="match.baggage.arrivalMax"
                                                                      :arrival-pref="toPrefectureEnum(match.baggage.arrivalPref)"
                                                                      :arrival-city="match.baggage.arrivalCity">
                                            </date-time-location-label>
                                            <div class="match-details">
                                                <a-row>
                                                    <a-col :span="4" class="match-details__title">運賃</a-col>
                                                    <a-col :span="20" class="component-body-s">
                                                        {{ freightText(match.baggage.freight) }}
                                                        {{ highwayFareText(match.baggage.highwayFareFlg) }}
                                                    </a-col>
                                                </a-row>
                                                <a-divider dashed/>
                                                <a-row>
                                                    <a-col :span="4" class="match-details__title">荷種</a-col>
                                                    <a-col :span="20" class="component-body-s">
                                                        <baggage-type-view :baggage="match.baggage"/>
                                                    </a-col>
                                                </a-row>
                                            </div>
                                        </a-card>
                                    </a-col>
                                    <a-col>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <sort-pagination-control :show-sort="false"
                                                             :page-info="pageInfo"
                                                             :loading="loadingMatches"
                                                             @change="onChangePage"/>
                                </a-row>
                            </div>
                        </div>
                    </drawer-content-layout>
                </a-tab-pane>
            </a-tabs>

        </a-drawer>
    </page-layout>
</template>

<style scoped lang="less">
//pages/Truck/List/style.less
.board-container {
    padding: 24px;
}

.drawer-content-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    :deep(.ant-tabs-bar) {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    :deep(.ant-tabs-content) {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
    }

    :deep(.ant-tabs-tabpane-active) {
        height: 100%;
    }
}

.match-contents {
    :deep(.ant-card-head) {
        border-bottom: 0;
    }

    :deep(.ant-card-body) {
        padding-top: 0;
        padding-bottom: 16px;
    }

    :deep(.ant-divider-horizontal) {
        margin: 4px 0;
    }

    :deep(.ant-btn-link) {
        padding: 0;
    }

    .match-details {
        margin-top: 16px;
        margin-bottom: 10px;

        &__title {
            font-weight: 600;
        }
    }
}
</style>
<style>

.e-schedule .e-timeline-view .e-appointment, .e-schedule .e-timeline-month-view .e-appointment {
    height: 60px !important;
}
</style>
