import { Component, Prop, Vue } from 'vue-property-decorator';
import { PageUtil } from '@/util';

@Component
export default class BaggagePredictionRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ default: 0 })
    declare percent: number;

    get hasNoData(): boolean {
        return this.percent === 0;
    }

    // ======================================================
    // Data
    // ======================================================
    private timer?: number;
    private visible = false;

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        this.timer = window.setInterval(this.onPeriodic, 500);
    }

    destroyed(): void {
        if (!this.timer) return;
        clearInterval(this.timer);
    }

    private onPeriodic(): void {
        if (!this.$el) return;

        this.visible = PageUtil.isElementVisible(this.$el);
    }
}
