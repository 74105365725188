import { TbxRouteConfig } from '@/router/routes/types';
import AccountRegisterPage from '@/pages/Account/Register/index.vue';
import AccountCompletePage from '@/pages/Account/Complete/index.vue';
import AccountVerifyPage from '@/pages/Account/Verify/index.vue';
import AccountEmailVerifyPage from '@/pages/Account/Email/Verify/index.vue';
import AccountPasswordRegisterPage from '@/pages/Account/Password/Register/index.vue';
import AccountPasswordRemindPage from '@/pages/Account/Password/Remind/index.vue';
import AccountPasswordRemindCompletePage from '@/pages/Account/Password/Remind/Complete/index.vue';
import AccountPasswordResetVerifyPage from '@/pages/Account/Password/Reset/Verify/index.vue';
import AccountPasswordResetCompletePage from '@/pages/Account/Password/Reset/Complete/index.vue';

import AccountRenewalVerifyPage from '@/_pages/Account/Renewal/Verify/AccountRenewalVerify.vue';
import AccountRenewalCompletePage from '@/_pages/Account/Renewal/Complete/AccountRenewalComplete.vue';
import _AccountRegisterPage from '@/_pages/Account/Register/AccountRegister.vue';
import _AccountCompletePage from '@/_pages/Account/Complete/AccountComplete.vue';
import _AccountPasswordRegisterPage from '@/_pages/Account/Password/Register/AccountPasswordRegister.vue';
import _AccountVerifyPage from '@/_pages/Account/Verify/AccountVerify.vue';
import _AccountPasswordRemindPage from '@/_pages/Account/Password/Remind/AccountPasswordRemind.vue';
import _AccountPasswordRemindCompletePage from '@/_pages/Account/Password/Remind/Complete/AccountPasswordRemindComplete.vue';
import _AccountEmailVerifyPage from '@/_pages/Account/Email/Verify/AccountEmailVerify.vue';
import _AccountPasswordResetVerifyPage from '@/_pages/Account/Password/Reset/Verify/AccountPasswordResetVerify.vue';
import _AccountPasswordResetCompletePage from '@/_pages/Account/Password/Reset/Complete/AccountPasswordResetComplete.vue';

export const accountRoutes: TbxRouteConfig[] = [
    {
        path: '/account/register',
        name: 'AccountRegister',
        component: _AccountRegisterPage,
        backComponent: AccountRegisterPage,
        meta: { title: '14日間無料お試し申し込みフォーム', layout: { name: 'blank' } },
        props: (route) => ({ formStyle: route.query.form }),
    },
    {
        path: '/account/complete',
        name: 'AccountComplete',
        component: _AccountCompletePage,
        backComponent: AccountCompletePage,
        meta: { title: 'お送りしたメールから申し込み手続きを進めてください', layout: { name: 'blank' } },
    },
    {
        // 新規メインアカウント開設時の本人確認ページ。メールからアクセスされる
        path: '/account/verify',
        name: 'AccountVerify',
        component: _AccountVerifyPage,
        backComponent: AccountVerifyPage,
        meta: { title: '本人確認', layout: { name: 'blank' }, accountType: 'MAIN_ACCOUNT' },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // メールアドレス確認ページ。メールからアクセスされる
        path: '/account/email/verify',
        name: 'AccountEmailVerify',
        component: _AccountEmailVerifyPage,
        backComponent: AccountEmailVerifyPage,
        meta: { title: 'メールアドレス認証', layout: { name: 'blank' } },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // 追加アカウント開設時（メインアカウントが新規ユーザーを追加した場合）の本人確認ページ。メールからアクセスされる
        path: '/account/new/verify',
        name: 'AdditionalAccountVerify',
        component: _AccountVerifyPage,
        backComponent: AccountVerifyPage,
        meta: { title: '本人確認', layout: { name: 'blank' }, accountType: 'ADDITIONAL_ACCOUNT' },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // 初期パスワード設定ページ（本人確認が完了すると遷移してくる）
        path: '/account/password/register',
        name: 'AccountPasswordRegister',
        component: _AccountPasswordRegisterPage,
        backComponent: AccountPasswordRegisterPage,
        meta: { title: '初回パスワード設定', layout: { name: 'blank' } },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // パスワード再設定: メールアドレス入力 ページ
        path: '/account/password/remind',
        name: 'AccountPasswordRemind',
        component: _AccountPasswordRemindPage,
        backComponent: AccountPasswordRemindPage,
        meta: { title: 'パスワード再設定', layout: { name: 'blank' } },
    },
    {
        // パスワード再設定: メールを送信しました ページ
        path: '/account/password/remind/complete',
        name: 'AccountPasswordRemindComplete',
        component: _AccountPasswordRemindCompletePage,
        backComponent: AccountPasswordRemindCompletePage,
        meta: { title: 'パスワード再設定メールをお送りしました', layout: { name: 'blank' } },
    },
    {
        // パスワード再設定前の確認ページ。パスワード再設定メールからアクセスされる。
        path: '/account/password/reset/verify',
        name: 'AccountPasswordResetVerify',
        component: _AccountPasswordResetVerifyPage,
        backComponent: AccountPasswordResetVerifyPage,
        meta: { title: 'パスワードの再設定', layout: { name: 'blank' } },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // パスワード再設定完了ページ
        path: '/account/password/reset/complete',
        name: 'AccountPasswordResetComplete',
        component: _AccountPasswordResetCompletePage,
        backComponent: AccountPasswordResetCompletePage,
        meta: { title: 'パスワードの再設定が完了しました', layout: { name: 'blank' } },
    },
    {
        // 転生前の確認ページ。転生メールからアクセスされる。
        path: '/account/renewal/verify',
        name: 'AccountRenewalVerify',
        component: AccountRenewalVerifyPage,
        meta: { layout: { name: 'blank' }, title: 'パスワード再設定' },
        props: (route) => ({ verificationCode: route.query.t }),
    },
    {
        // 転生完了ページ
        path: '/account/renewal/complete',
        name: 'AccountRenewalComplete',
        component: AccountRenewalCompletePage,
        meta: { layout: { name: 'blank' }, title: 'パスワード再設定完了' },
    },
];
