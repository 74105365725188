import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { Util } from '@/util/index';

export class BaggageDetailUtil {
    static paletteCount(shapeCode?: BaggageShapeEnumCode, count?: number): string | undefined {
        if (shapeCode !== BaggageShapeEnum.Palette.code) return;
        if (count === undefined) return;
        return `パレット枚数（目安）：${ Util.formatNumber(count) }`;
    }

    static paletteSize(shapeCode?: BaggageShapeEnumCode, height?: number, width?: number): string | undefined {
        if (shapeCode !== BaggageShapeEnum.Palette.code) return;
        if (height === undefined && width === undefined) return;
        const heightText = height !== undefined ? `${ Util.formatNumber(height) }cm` : '-';
        const widthText = width !== undefined ? `${ Util.formatNumber(width) }cm` : '-';
        return `パレットサイズ：縦${ heightText } x 横${ widthText }`;
    }

    static totalCount(shapeCode?: BaggageShapeEnumCode, count?: number): string | undefined {
        if (shapeCode !== BaggageShapeEnum.Other.code) return;
        if (count === undefined) return;
        return `荷物の個数（目安）：${ Util.formatNumber(count) }`;
    }

    static totalVolume(shapeCode?: BaggageShapeEnumCode, volume?: number): string | undefined {
        if (shapeCode !== BaggageShapeEnum.Other.code) return;
        if (volume === undefined) return;
        return `荷物の体積：${ Util.formatNumber(volume) }`;
    }

    static totalWeight(weight?: number): string | undefined {
        if (weight === undefined) return;
        return `総重量：${ Util.formatNumber(weight) }kg`;
    }

    static handling(loadingCode?: BaggageHandlingTypeEnumCode, unloadingCode?: BaggageHandlingTypeEnumCode): string | undefined {
        const loading = BaggageHandlingTypeEnum.valueOf(loadingCode);
        const unloading = BaggageHandlingTypeEnum.valueOf(unloadingCode);
        if (!loading && !unloading) return;
        return `ドライバー作業：[積み] ${ loading?.getLabel(true) ?? '-' } [卸し] ${ unloading?.getLabel(false) ?? '-' }`;
    }

    static handlingOverview(loadingCode?: BaggageHandlingTypeEnumCode, unloadingCode?: BaggageHandlingTypeEnumCode): string {
        const loading = BaggageHandlingTypeEnum.valueOf(loadingCode);
        const unloading = BaggageHandlingTypeEnum.valueOf(unloadingCode);
        if (
            (loading && (loading !== BaggageHandlingTypeEnum.None)) ||
            (unloading && (unloading !== BaggageHandlingTypeEnum.None))
        ) return 'あり';
        if (!loading || !unloading) return '未入力';
        return 'なし';
    }

    static handlingDetail(loadingCode?: BaggageHandlingTypeEnumCode, unloadingCode?: BaggageHandlingTypeEnumCode): string[] | undefined {
        const loading = BaggageHandlingTypeEnum.valueOf(loadingCode);
        const unloading = BaggageHandlingTypeEnum.valueOf(unloadingCode);
        if (!loading && !unloading) return;
        return [`積み：${ loading?.getLabel(true) ?? '未入力' }`, `卸し：${ unloading?.getLabel(false) ?? '未入力' }`];
    }
}
