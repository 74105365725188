// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 企業振込情報をロードします。
 */
import store from '@/vuex/store';
import * as types from '@/vuex/modules/company/types';

export const loadTransfer = (): Promise<void> =>
    store.dispatch(`company/${ types.ACTION.LOAD_TRANSFER }`)
        .catch(() => Promise.resolve());

/**
 * 企業振込情報を更新します。
 */
export const updateTransfer = (form: types.CompanyTransferUpdateForm): Promise<void> =>
    store.dispatch(`company/${ types.ACTION.UPDATE_TRANSFER }`, form);

/**
 * 自分の企業プロフィールをロードします。
 */
export const loadMyProfile = (): Promise<void> =>
    store.dispatch(`company/${types.ACTION.LOAD_MY_PROFILE}`);
