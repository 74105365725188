import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InquiryForm } from '@/vuex/modules/help/types';

@Component
export default class InquiryDescriptionEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: InquiryForm;

    get description(): string {
        return this.value?.description ?? '';
    }

    set description(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.description = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: 'お問い合わせ内容を入力してください。',
        },
        {
            max: 500,
            message: '500文字以内で入力してください。',
        },
    ];
}
