import { Route } from 'vue-router/types/router';
import { BaggageListTabKey } from '@/pages/Baggage/List/script';
import BaggageRegisterPage from '@/pages/Baggage/Register/index.vue';
import BaggageListPage from '@/pages/Baggage/List/index.vue';
import BaggageOpenedListPage from '@/pages/Baggage/List/tabs/Opened/index.vue';
import BaggageExpiredListPage from '@/pages/Baggage/List/tabs/Expired/index.vue';
import AgreementAgreedListPage from '@/pages/Baggage/List/tabs/Agreed/index.vue';
import AgreementAcceptedListPage from '@/pages/Baggage/List/tabs/Accepted/index.vue';
import BaggageSearchPage from '@/pages/Baggage/Search/index.vue';
import BaggageFavoritesPage from '@/pages/Baggage/Favorite/index.vue';
import BaggageRecentPage from '@/pages/Baggage/Recent/index.vue';
import BaggageSearchPrintPage from '@/pages/Baggage/Search/Print/index.vue';
import BaggagePrintPage from '@/pages/Baggage/Print/index.vue';
import BaggageRecommendReturnsPage from '@/pages/Baggage/Search/Recommend/Returns/index.vue';
import AgreementRegisterPage from '@/pages/Agreement/Register/index.vue';
import AgreementPrintPage from '@/pages/Agreement/Print/index.vue';
import { resolveTitle, TbxRouteConfig } from '@/router/routes/types';

// リファクタリングページ
import _BaggageRecentPage from '@/_pages/Baggage/Recent/BaggageRecent.vue';
import _BaggageFavoritesPage from '@/_pages/Baggage/Favorite/BaggageFavorite.vue';
import _BaggageSearchPage from '@/_pages/Baggage/Search/BaggageSearch.vue';
import _BaggagePrintPage from '@/_pages/Baggage/Print/BaggagePrint.vue';
import _BaggageSearchPrintPage from '@/_pages/Baggage/Search/Print/BaggageSearchPrint.vue';
import _BaggageRegisterPage from '@/_pages/Baggage/Register/BaggageRegister.vue';
import _BaggageBulkRegisterPage from '@/_pages/Baggage/BulkRegister/BaggageBulkRegister.vue';
import _AgreementRegisterPage from '@/_pages/Agreement/Register/AgreementRegister.vue';
import _AgreementPrintPage from '@/_pages/Agreement/Print/AgreementPrint.vue';
import { Util } from '@/util';
import _BaggageListOpenedPage from '@/_pages/Baggage/List/tabs/Opened/BaggageListOpened.vue';
import _BaggageListCanceled from '@/_pages/Baggage/List/tabs/Canceled/BaggageListCanceled.vue';
import _SettlementPage from '@/_pages/Baggage/List/tabs/Settlement/Settlement.vue';
import _SettlementHomePage from '@/_pages/Baggage/List/tabs/Settlement/tabs/Home/SettlementHome.vue';
import _SettlementListPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/SettlementList.vue';
import _SettlementIncomeListPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/SettlementIncomeList.vue';
import _SettlementOutgoListPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/SettlementOutgoList.vue';
import _SettlementPaperPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/Paper/SettlementPaper.vue';
import _SettlementIncomePaperPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/Paper/Income/SettlementIncomePaper.vue';
import _SettlementOutgoPaperPage from '@/_pages/Baggage/List/tabs/Settlement/tabs/Paper/Outgo/SettlementOutgoPaper.vue';
import _SettlementIncomeRegisterPage from '@/_pages/Baggage/List/tabs/Settlement/RegisterIncome/SettlementIncomeRegister.vue';
import type { DetailProps as OpenedMyBaggageDetailProps } from '@/_pages/Baggage/List/tabs/Opened/props';
import type { DetailProps as CanceledMyBaggageDetailProps } from '@/_pages/Baggage/List/tabs/Canceled/props';
import { useRouteExtension } from '@/composables/helper/route-extension';

const {
    openedMyBaggageDetailProps,
    canceledMyBaggageDetailProps,
    preloadSettlementIncomeListProps,
    preloadSettlementOutgoListProps,
    settlementIncomeDetailProps,
    settlementOutgoDetailProps,
} = useRouteExtension();

export const baggageRoutes: TbxRouteConfig[] = [
    {
        path: '/baggage/register',
        name: 'BaggageRegister',
        component: _BaggageRegisterPage,
        backComponent: BaggageRegisterPage,
        props: (route: Route) => ({ sourceBaggageId: route.query.sourceBaggageId }),
        meta: { title: '荷物登録' },
    },
    {
        path: '/baggage/bulk_register',
        name: 'BaggageBulkRegister',
        component: _BaggageBulkRegisterPage,
        meta: { title: '荷物一括登録' },
    },
    {
        path: '/baggage/list/settlement/list/income/register',
        name: 'SettlementIncomeRegister',
        component: _SettlementIncomeRegisterPage,
        meta: { title: '取引追加' },
    },
    {
        path: '/baggage/list',
        component: BaggageListPage,
        backComponent: BaggageListPage,
        meta: { title: () => ['マイ荷物'] },
        children: [
            {
                path: 'opened',
                name: 'BaggageList',
                component: _BaggageListOpenedPage,
                backComponent: BaggageOpenedListPage,
                props: (route: Route): { detail: OpenedMyBaggageDetailProps | undefined, baggageId: string | (string | null)[] } => ({
                    detail: openedMyBaggageDetailProps(route),

                    // TODO: 旧コンポーネント(/pages/Baggage/List)を削除するときに、下記も合わせて削除する
                    // 旧コンポーネント用のProps
                    baggageId: route.query.baggageId
                }),
                meta: {
                    title: (route: Route) => resolveTitle([
                        { name: 'default', label: '募集中' },
                        { name: 'baggageId', label: '荷物詳細' },
                        { name: 'edit', label: '荷物情報変更' },
                    ], route),
                    activeTabKey: 'opened' as BaggageListTabKey
                },
            },
            {
                path: 'expired',
                name: 'BaggageExpiredList',
                component: _BaggageListCanceled,
                backComponent: BaggageExpiredListPage,
                props: (route: Route): { detail: CanceledMyBaggageDetailProps | undefined, baggageId: string | (string | null)[] } => ({
                    detail: canceledMyBaggageDetailProps(route),

                    // TODO: 旧コンポーネント(/pages/Baggage/List)を削除するときに、下記も合わせて削除する
                    // 旧コンポーネント用のProps
                    baggageId: route.query.baggageId
                }),
                meta: {
                    title: (route: Route) => resolveTitle([
                        { name: 'default', label: '成約しなかった荷物' },
                        { name: 'baggageId', label: '荷物詳細' },
                    ], route),
                    activeTabKey: 'expired' as BaggageListTabKey
                },
            },
            {
                path: 'agreed',
                name: 'AgreementList',
                component: AgreementAgreedListPage,
                props: (route: Route) => ({ agreementId: route.query.agreementId }),
                meta: {
                    title: (route: Route) => resolveTitle([
                        { name: 'default', label: '自社荷物の成約' },
                        { name: 'agreementId', label: '成約詳細' },
                        { name: 'edit', label: '成約内容変更' },
                    ], route),
                    activeTabKey: 'agreed' as BaggageListTabKey
                },
            },
            {
                path: 'accepted',
                name: 'AgreementAcceptedList',
                component: AgreementAcceptedListPage,
                props: (route: Route) => ({ agreementId: route.query.agreementId }),
                meta: {
                    title: (route: Route) => resolveTitle([
                        { name: 'default', label: '受託荷物の成約' },
                        { name: 'agreementId', label: '成約詳細' },
                        { name: 'edit', label: '成約内容変更' },
                    ], route),
                    activeTabKey: 'accepted' as BaggageListTabKey
                },
            },
            {
                path: 'settlement',
                component: _SettlementPage,
                meta: {
                    title: () => ['請求・支払'],
                },
                children: [
                    {
                        path: 'home',
                        name: 'SettlementHome',
                        component: _SettlementHomePage,
                        meta: {
                            title: () => ['ホーム'],
                            activeTabKey: 'settlement/home' as BaggageListTabKey,
                        },
                    },
                    {
                        path: 'list',
                        component: _SettlementListPage,
                        meta: {
                            title: () => ['取引'],
                        },
                        children: [
                            {
                                path: 'income',
                                name: 'SettlementIncomeList',
                                component: _SettlementIncomeListPage,
                                props: (route: Route) => ({
                                    preload: preloadSettlementIncomeListProps(route),
                                    detail: settlementIncomeDetailProps(route),
                                }),
                                meta: {
                                    title: () => ['入金'],
                                    activeTabKey: 'settlement/list/income' as BaggageListTabKey,
                                },
                            },
                            {
                                path: 'outgo',
                                name: 'SettlementOutgoList',
                                component: _SettlementOutgoListPage,
                                props: (route: Route) => ({
                                    preload: preloadSettlementOutgoListProps(route),
                                    detail: settlementOutgoDetailProps(route),
                                }),
                                meta: {
                                    title: () => ['出金'],
                                    activeTabKey: 'settlement/list/outgo' as BaggageListTabKey,
                                },
                            },
                        ],
                    },
                    {
                        path: 'paper',
                        name: 'SettlementPaper',
                        component: _SettlementPaperPage,
                        meta: {
                            title: () => [],
                            activeTabKey: 'settlement/paper' as BaggageListTabKey,
                        },
                        children: [
                            {
                                path: 'income',
                                name: 'SettlementIncomePaper',
                                component: _SettlementIncomePaperPage,
                                meta: {
                                    title: () => ['支払通知書'],
                                    activeTabKey: 'settlement/paper/income' as BaggageListTabKey,
                                },
                            },
                            {
                                path: 'outgo',
                                name: 'SettlementOutgoPaper',
                                component: _SettlementOutgoPaperPage,
                                meta: {
                                    title: () => ['請求書'],
                                    activeTabKey: 'settlement/paper/outgo' as BaggageListTabKey,
                                }
                            }
                        ],
                    },
                ],
            },
            {
                path: '*',
                redirect: { name: 'BaggageList' },
            },
        ],
    },
    {
        path: '/baggage/search',
        name: 'BaggageSearch',
        component: _BaggageSearchPage,
        backComponent: BaggageSearchPage,
        props: (route: Route) => ({ baggageId: route.query.baggageId, refTag: route.query.ref }),
        meta: { title: '荷物検索' },
    },
    {
        path: '/baggage/favorites',
        name: 'BaggageFavorites',
        component: _BaggageFavoritesPage,
        backComponent: BaggageFavoritesPage,
        props: (route: Route) => ({ baggageId: route.query.baggageId ? Number(route.query.baggageId) : undefined }),
        meta: { title: '保存した荷物' },
    },
    {
        path: '/baggage/recent',
        name: 'BaggageRecent',
        component: _BaggageRecentPage,
        backComponent: BaggageRecentPage,
        props: (route: Route) => ({ baggageId: route.query.baggageId ? Number(route.query.baggageId) : undefined }),
        meta: { title: '最近見た荷物' },
    },
    {
        path: '/baggage/search/print/:baggageId',
        name: 'BaggageSearchPrint',
        component: _BaggageSearchPrintPage,
        backComponent: BaggageSearchPrintPage,
        meta: { title: '荷物検索情報印刷', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ baggageId: Number(route.params.baggageId) }),
    },
    {
        path: '/baggage/print/:baggageId',
        name: 'BaggagePrint',
        component: _BaggagePrintPage,
        backComponent: BaggagePrintPage,
        meta: { title: '荷物情報印刷', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ baggageId: Number(route.params.baggageId) }),
    },
    {
        path: '/baggage/search/recommend/:baggageId/returns',
        name: 'BaggageRecommendReturns',
        component: BaggageRecommendReturnsPage,
        props: (route: Route) => ({
            baggageId: Number(route.params.baggageId),
            recommendationId: route.query.baggageId
        }),
        meta: { title: 'おすすめ帰り便 (ベータ版)' },
    },
    {
        path: '/baggage/:id/agreement/register',
        name: 'AgreementRegister',
        component: _AgreementRegisterPage,
        backComponent: AgreementRegisterPage,
        props: (route: Route) => ({ baggageId: Number(route.params.id) }),
        meta: {
            title: (route: Route): string[] => {
                return [`荷物成約 ${ Util.isNumeric(route.params.id) ? '[荷物番号 ' + Number(route.params.id) + ']' : '' }`];
            },
        },
    },
    {
        path: '/agreement/print/:agreementId',
        name: 'AgreementPrint',
        component: _AgreementPrintPage,
        backComponent: AgreementPrintPage,
        meta: { title: '成約情報印刷', layout: { name: 'main', contentOnly: true } },
        props: (route: Route) => ({ agreementId: Number(route.params.agreementId) }),
    },
];
