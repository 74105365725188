<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { SettlementIncomeRegisterViewModel } from '@/composables/settlement-income-register-viewmodel';
import { computed } from 'vue';
import { PrefectureEnum } from '@/enums/prefecture.enum';
import { Util } from '@/util';
import { AmountValue } from '@/models/vo/amount';
import { CancellationFeeValue } from '@/models/vo/freight';

const props = defineProps<{
    model: SettlementIncomeRegisterViewModel,
}>();

const departureLocation = computed(() => {
    return [
        PrefectureEnum.valueOf(Util.requireNotNull(props.model.departurePref))?.label ?? '',
        props.model.departureCity ?? '',
        props.model.departureAddress ?? '',
    ].join('');
});
const arrivalLocation = computed(() => {
    return [
        PrefectureEnum.valueOf(Util.requireNotNull(props.model.arrivalPref))?.label ?? '',
        props.model.arrivalCity ?? '',
        props.model.arrivalAddress ?? '',
    ].join('');
});
const freight = computed(() => {
    return new AmountValue(props.model.freight);
});
const highwayFare = computed(() => {
    return new AmountValue(props.model.highwayFare);
});
const waitTimeFee = computed(() => {
    return new AmountValue(props.model.waitTimeFee);
});
const operationFee = computed(() => {
    return new AmountValue(props.model.operationFee);
});
const pickingFee = computed(() => {
    return new AmountValue(props.model.pickingFee);
});
const parkingFee = computed(() => {
    return new AmountValue(props.model.parkingFee);
});
const clearanceFee = computed(() => {
    return new AmountValue(props.model.clearanceFee);
});
const cancellationFee = computed(() => {
    return new CancellationFeeValue(props.model.cancellationFee);
});
</script>

<template>
    <div>
        <a-row type="flex" align="middle" :gutter="[16, 0]">
            <a-col :span="24">
                <a-alert type="warning" :show-icon="true">
                    <template #icon>
                        <tbx-icon type="info-circle" class="settlement-alert__icon"/>
                    </template>
                    <template #message>
                        <div class="settlement-alert__message">
                        <div class="component-body-s">
                            ・トラブル防止のため、<strong>荷主と合意した内容のみ</strong>申請を行なってください。
                        </div>
                        <div class="component-body-s">
                            ・<strong>承認期限を過ぎると翌月に繰り越されます</strong>ので、ご注意ください。
                        </div>
                        <div class="component-body-s">
                            ・トラボックス側では取引内容の変更はできません。
                        </div>
                        </div>
                    </template>
                </a-alert>
            </a-col>
        </a-row>
        <section class="summary">
            <div class="settlement-summary">
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>荷主：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ model.payerCompany.name.kanji }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>発日：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ model.departureDate.format('YYYY年M月D日(ddd)') }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>発地：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ departureLocation }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>着日：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ model.arrivalDate.format('YYYY年M月D日(ddd)') }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>着地：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ arrivalLocation }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>車両：</span>
                    </a-col>
                    <a-col :span="13">
                    <span>
                        重量：
                        {{ model.truckType.truckWeight?.label ?? '' }}
                        車種：
                        {{ model.truckType.truckModel?.label ?? '' }}
                    </span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>運賃：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ freight.format('', '円（税別）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>高速代：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ highwayFare.format('', '円（税込）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>待機料：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ waitTimeFee.format('', '円（税別）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>付帯作業料：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ operationFee.format('', '円（税別）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>搬出料：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ pickingFee.format('', '円（税込）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>駐車代：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ parkingFee.format('', '円（税込）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>通関料：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="!model.isCanceling">{{ clearanceFee.format('', '円（非課税）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>キャンセル料：</span>
                    </a-col>
                    <a-col :span="13">
                        <span v-if="model.isCanceling">{{ cancellationFee.format('', '円（非課税）') }}</span>
                        <span v-else>なし</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>入金予定日：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ (model.paymentDate ?? model.defaultPaymentDate).format('YYYY年M月D日(ddd)') }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="11" style="text-align: end">
                        <span>承認期限：</span>
                    </a-col>
                    <a-col :span="13">
                        <span>{{ model.nextLimitDate.toDateTime().endOf('day').format('YYYY年M月D日(ddd) H:mm') }}</span>
                    </a-col>
                </a-row>
            </div>
        </section>
    </div>
</template>

<style scoped lang="less">
.settlement-alert {
    &__icon {
        font-size: 24px;
    }

    &__message {
        margin: 0 0 0 16px;
        padding: 0;
    }
}

.summary {
    &__title {
        margin-bottom: 8px;
        font-weight: bold;
    }
}

.settlement-summary {
    margin-top: 24px;
    margin-bottom: 24px;
}

</style>
