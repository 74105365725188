import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { Validator } from '@/validator';

const PREFIX_HTTP = 'http://';
const PREFIX_HTTPS = 'https://';

@Component
export default class AccountUrlRegisterer extends Vue {
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule: ValidationRule, value: string | undefined, callback: Function) =>
                AccountUrlRegisterer.validate(value, callback as (message?: string) => void),
        },
    ];

    get urlScheme(): string {
        const isSecure = this.value?.company.url?.startsWith(PREFIX_HTTPS) ?? true;
        return isSecure ? PREFIX_HTTPS : PREFIX_HTTP;
    }

    set urlScheme(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.url = `${ newValue }${ this.url }`;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get url(): string {
        const urls = this.value?.company.url?.split('://') ?? [];
        urls.shift();
        return urls.join('');
    }

    set url(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        const newUrl = newValue.trim().replace(PREFIX_HTTP, '').replace(PREFIX_HTTPS, '');
        cloned.company.url = _.isEmpty(newUrl) ? undefined : `${ this.urlScheme }${ newUrl }`;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    private static validate(value: string | undefined, callback: (message?: string) => void): void {
        // 未入力OK
        if (value === undefined) {
            callback();
            return;
        }
        const validated = Validator.validateUrl(value as string);
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }
}
