import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class AccountPhoneRegisterer extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    /**
     * 電話番号
     */
    get phoneNumber(): string {
        return this.value?.company.phone.number ?? '';
    }

    set phoneNumber(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.phone.number = newValue;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemNumber.onFieldChange());
    }

    /**
     * FAX番号
     */
    get faxNumber(): string {
        return this.value?.company.phone.faxNumber ?? '';
    }

    set faxNumber(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.phone.faxNumber = newValue;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemFaxNumber.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    numberValidationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '電話番号を入力してください。',
        },
        {
            transform: (value: string): string => Util.toDigits(value),
            min: Const.MIN_PHONE_NUMBER,
            max: Const.MAX_PHONE_NUMBER,
            message: `電話番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
        },
        {
            transform: (value: string): string => Util.toDigits(value),
            pattern: Const.PHONE_NUMBER_REGEX,
            message: '電話番号を正しい形式で入力してください。',
        },
    ];

    faxNumberValidationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: 'FAX番号を入力してください。',
        },
        {
            transform: (value: string): string => Util.toDigits(value),
            min: Const.MIN_PHONE_NUMBER,
            max: Const.MAX_PHONE_NUMBER,
            message: `FAX番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
        },
        {
            transform: (value: string): string => Util.toDigits(value),
            pattern: Const.PHONE_NUMBER_REGEX,
            message: 'FAX番号を正しい形式で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 電話番号のフォーカスが外れた際に呼び出されます。
     */
    onBlurPhoneNumber(): void {
        this.phoneNumber = Util.toDigits(this.phoneNumber.trim());
    }

    /**
     * FAX番号のフォーカスが外れた際に呼び出されます。
     */
    onBlurFaxNumber(): void {
        this.faxNumber = Util.toDigits(this.faxNumber.trim());
    }
}
