import { Const } from '@/const';

export class PageUtil {
    /**
     * ページのタイトルを設定します。
     */
    static setTitle(title?: string | Array<string>): void {
        if (!title) {
            return;
        }

        let pageTitle = title;
        if (Array.isArray(title)) {
            pageTitle = title.join(Const.PAGE_TITLE_SEPARATOR);
        }
        document.title = `${ pageTitle }${ Const.PAGE_TITLE_SEPARATOR }${ Const.APP_SERVICE_NAME }`;
    }

    /**
     * アプリテーマスタイルクラス名を作成します。
     * @param isHighContrast
     */
    static createAppThemeClassNames(isHighContrast: boolean): { [key: string]: boolean } {
        return {
            'app-theme': true,
            'app-theme--default': !isHighContrast,
            'app-theme--high-contrast': isHighContrast,
        };
    }

    /**
     * コンテンツレイアウトの最上部までスクロールします。
     */
    static scrollToContentTop(offset?: number, smoothScroll = true): void {
        const margin = 32;
        const element = document.getElementById('jsi-board-content');
        if (!element) {
            return;
        }
        const top = offset && offset >= margin ? offset - margin : 0;
        PageUtil.scrollElementTo(element, top, smoothScroll);
    }

    /**
     * {@link Element}をスクロールします。
     */
    static scrollElementTo(element: Element, top = 0, smoothScroll = false): void {
        // IE11には `Element.scrollTo` が未サポートのため、`Element.scrollTop` で代用している
        if (typeof element.scrollTo === 'function') {
            element.scrollTo({ top, behavior: smoothScroll ? 'smooth' : 'auto' });
        } else {
            element.scrollTop = top;
        }
    }

    /**
     * {@link Element}が画面内に描画されているか否かを取得します。
     */
    static isElementVisible(element: Element): boolean {
        const container = document.getElementById('jsi-board-content');
        if (!container) {
            return false;
        }

        const max = container.offsetTop + container.clientHeight;

        const { top, bottom } = element.getBoundingClientRect();

        return top > 0 && bottom < max;
    }
}
