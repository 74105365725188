import { Enum } from '@/types/enum';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { FormValidatable, FormValidationRule, FormValidator, ValidationContext } from '@/models/validate-helper';
import { DateValue } from '@/models/vo/date';
import { SettlementStatusEnum, SettlementStatusEnumCode } from '@/enums/settlement-status.enum';

/**
 * 入金取引検索フォーム(API用)
 */
export type SettlementSearchIncomeApiForm = {
    // 入金日
    payeeDate?: string;
    // 出金企業ID
    payerCompanyId?: number;
    // 発地都道府県
    departurePref?: Enum<PrefectureEnumCode>;
    // 発日
    departureFrom?: string;
    departureTo?: string;
    // 取引ID
    id?: number;
    // ステータス
    status?: Enum<SettlementStatusEnumCode>[];
    // 決済代行のみ
    onlyProxy: boolean;
    // ページ番号
    pageNo: number;
};

/**
 * 出金取引検索フォーム(API用)
 */
export type SettlementSearchOutgoApiForm = {
    // 出金日
    payerDate?: string;
    // 入金企業ID
    payeeCompanyId?: number;
    // 発地都道府県
    departurePref?: Enum<PrefectureEnumCode>;
    // 発日
    departureFrom?: string;
    departureTo?: string;
    // 取引ID
    id?: number;
    // ステータス
    status?: Enum<SettlementStatusEnumCode>[];
    // 決済代行のみ
    onlyProxy: boolean;
    // ページ番号
    pageNo: number;
};

type SearchDateRange = { from: DateValue | undefined, to: DateValue | undefined };

/**
 * 入金取引検索フォームモデル(UI用)
 */
export class SettlementSearchIncomeFormModel implements FormValidatable<SettlementSearchIncomeFormModel> {
    payeeDate?: DateValue;
    payerCompanyId?: number;
    departurePref?: PrefectureEnum;
    departure: SearchDateRange;
    settlementId?: number;
    status?: SettlementStatusEnum[];
    onlyProxy: boolean;
    pageNo: number;

    constructor(param: Partial<SettlementSearchIncomeFormModel> | undefined = undefined) {
        this.payeeDate = param?.payeeDate;
        this.payerCompanyId = param?.payerCompanyId;
        this.departurePref = param?.departurePref;
        this.departure = {
            from: param?.departure?.from,
            to: param?.departure?.to,
        };
        this.settlementId = param?.settlementId;
        this.status = param?.status;
        this.onlyProxy = param?.onlyProxy ?? false;
        this.pageNo = param?.pageNo ?? 1;
    }

    get dirty(): boolean {
        return this.payeeDate !== undefined
            || this.payerCompanyId !== undefined
            || this.departurePref !== undefined
            || this.departure.from !== undefined
            || this.departure.to !== undefined
            || this.settlementId !== undefined
            || this.onlyProxy;
    }

    validator: () => FormValidator<SettlementSearchIncomeFormModel> = () => {
        return {
            departure: departureDate(() => this.departure),
        };
    };

    convertToApiForm(): SettlementSearchIncomeApiForm {
        return {
            payeeDate: this.payeeDate?.format('YYYY-MM-DD'),
            payerCompanyId: this.payerCompanyId,
            departurePref: this.departurePref?.code ? { code: this.departurePref.code } : undefined,
            departureFrom: this.departure.from?.format('YYYY-MM-DD'),
            departureTo: this.departure.to?.format('YYYY-MM-DD'),
            id: this.settlementId,
            status: this.status,
            onlyProxy: this.onlyProxy,
            pageNo: this.pageNo,
        };
    }
}

/**
 * 出金取引検索フォームモデル(UI用)
 */
export class SettlementSearchOutgoFormModel implements FormValidatable<SettlementSearchOutgoFormModel> {
    payerDate?: DateValue;
    payeeCompanyId?: number;
    departurePref?: PrefectureEnum;
    departure: SearchDateRange;
    settlementId?: number;
    status?: SettlementStatusEnum[];
    onlyProxy: boolean;
    pageNo: number;

    constructor(param: Partial<SettlementSearchOutgoFormModel> | undefined = undefined) {
        this.payerDate = param?.payerDate;
        this.payeeCompanyId = param?.payeeCompanyId;
        this.departurePref = param?.departurePref;
        this.departure = {
            from: param?.departure?.from,
            to: param?.departure?.to,
        };
        this.settlementId = param?.settlementId;
        this.status = param?.status;
        this.onlyProxy = param?.onlyProxy ?? false;
        this.pageNo = param?.pageNo ?? 1;
    }

    get dirty(): boolean {
        return this.payerDate !== undefined
            || this.payeeCompanyId !== undefined
            || this.departurePref !== undefined
            || this.departure.from !== undefined
            || this.departure.to !== undefined
            || this.settlementId !== undefined
            || this.onlyProxy;
    }

    validator: () => FormValidator<SettlementSearchOutgoFormModel> = () => {
        return {
            departure: departureDate(() => this.departure),
        };
    };

    convertToApiForm(): SettlementSearchOutgoApiForm {
        return {
            payerDate: this.payerDate?.format('YYYY-MM-DD'),
            payeeCompanyId: this.payeeCompanyId,
            departurePref: this.departurePref?.code ? { code: this.departurePref.code } : undefined,
            departureFrom: this.departure.from?.format('YYYY-MM-DD'),
            departureTo: this.departure.to?.format('YYYY-MM-DD'),
            id: this.settlementId,
            status: this.status,
            onlyProxy: this.onlyProxy,
            pageNo: this.pageNo,
        };
    }
}

const departureDate = (context: ValidationContext<SearchDateRange>): FormValidationRule<SearchDateRange>[] => {
    return [
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { from, to } = context();
                if (from && from.isAfter(DateValue.today())) return callback('発日(開始)は今日以前を指定してください');
                if (to && to.isAfter(DateValue.today())) return callback('発日(終了)は今日以前を指定してください');
                if (from && to && from.isAfter(to)) return callback('発日(開始)は発日(終了)より前を指定してください');
                callback();
            }
        }
    ];
};
