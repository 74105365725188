import { CompanyTruckEventType, CompanyTruckEventTypeCode } from '@/enums/truck-event-type.enum';
import { Enum } from '@/types/enum';
import { DateValue } from '@/models/vo/date';
import { Spot } from '@/_components/ui/types/spot-type';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Const } from '@/const';

// 車両イベント
export interface CompanyTruckEvent {
    id: number;
    companyTruckId: number;
    eventType: Enum<CompanyTruckEventTypeCode>;
    startTm: string;
    endTm: string;
    title?: string;
    description?: string;
    location?: string;
    truckId?: number;
}

export class CompanyTruckEventModel {
    id: number;
    companyTruckId: number;
    eventType: Enum<CompanyTruckEventTypeCode>;
    startTM: string;
    endTM: string;
    title?: string;
    description?: string;
    location?: string;
    truckId?: number;

    constructor(param: CompanyTruckEvent) {
        this.id = param.id;
        this.companyTruckId = param.companyTruckId;
        this.eventType = param.eventType;
        this.startTM = param.startTm;
        this.endTM = param.endTm;
        this.title = param.title;
        this.description = param.description;
        this.location = param.location;
        this.truckId = param.truckId;
    }
}

// 車両イベント検索フォーム
export interface CompanyTruckEventSearchForm {
    startTm?: string;
    endTm?: string;
}

export class CompanyTruckEventSearchFormModel implements CompanyTruckEventSearchForm {
    startTm?: string;
    endTm?: string;

    constructor(param: Partial<CompanyTruckEventSearchForm> | null = null) {
        this.startTm = param?.startTm;
        this.endTm = param?.endTm;
    }

    set startTmFromValue(newValue: DateValue | undefined) {
        this.startTm = newValue ? newValue.toDateTime().startOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get startTmFromValue(): DateValue | undefined {
        return this.startTm ? new DateValue(this.startTm) : undefined;
    }

    set endTmFromValue(newValue: DateValue | undefined) {
        this.endTm = newValue ? newValue.toDateTime().endOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get endTmFromValue(): DateValue | undefined {
        return this.endTm ? new DateValue(this.endTm) : undefined;
    }
}

// 車両イベントメモ登録フォーム
export class CompanyTruckEventMemoRegisterForm implements FormValidatable<CompanyTruckEventMemoRegisterForm> {
    companyTruckId?: number;
    eventType: CompanyTruckEventType;
    startTm?: string;
    endTm?: string;
    title?: string;
    description?: string;
    location?: string;

    constructor(param: CompanyTruckEventMemoRegisterForm | null = null) {
        this.companyTruckId = param?.companyTruckId;
        this.eventType = CompanyTruckEventType.Memo;
        this.startTm = param?.startTm;
        this.endTm = param?.endTm;
        this.title = param?.title;
        this.description = param?.description;
        this.location = param?.location;
    }

    validator(): FormValidator<CompanyTruckEventMemoRegisterForm> {
        return {
            title: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: 2000,
                    message: '2000文字以内で入力してください。',
                },
            ],
            location: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
        };
    }
}
// 車両イベントメモ更新フォーム
export class CompanyTruckEventMemoUpdateForm implements FormValidatable<CompanyTruckEventMemoUpdateForm>{
    companyTruckId?: number;
    eventType: CompanyTruckEventType;
    startTm?: string;
    endTm?: string;
    title?: string;
    description?: string;
    location?: string;

    constructor(param: CompanyTruckEventMemoUpdateForm | null = null) {
        this.companyTruckId = param?.companyTruckId;
        this.eventType = CompanyTruckEventType.Memo;
        this.startTm = param?.startTm;
        this.endTm = param?.endTm;
        this.title = param?.title;
        this.description = param?.description;
        this.location = param?.location;
    }

    validator(): FormValidator<CompanyTruckEventMemoUpdateForm> {
        return {
            title: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: 2000,
                    message: '2000文字以内で入力してください。',
                },
            ],
            location: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
        };
    }
}

// 車両イベント空車登録フォーム
export class CompanyTruckEventTruckRegisterForm implements FormValidatable<CompanyTruckEventTruckRegisterForm> {
    circleId: number | undefined;
    companyTruckId?: number;
    eventType: CompanyTruckEventType;
    startTm?: string;
    endTm?: string;
    departurePref: { code?: string, label?: string };
    departureCity?: string;
    arrivalPref: { code?: string, label?: string };
    arrivalCity?: string;
    title?: string;
    description?: string;
    location?: string;
    staffName?: string;

    constructor(param: CompanyTruckEventTruckRegisterForm | null = null) {
        this.circleId = param?.circleId;
        this.companyTruckId = param?.companyTruckId;
        this.eventType = CompanyTruckEventType.Empty;
        this.startTm = param?.startTm;
        this.endTm = param?.endTm;
        this.departurePref = param?.departurePref ?? {};
        this.departureCity = param?.departureCity ?? '';
        this.arrivalPref = param?.arrivalPref ?? {};
        this.arrivalCity = param?.arrivalCity ?? '';
        this.title = param?.title;
        this.description = param?.description;
        this.location = param?.location;
        this.staffName = param?.staffName;
    }

    get departureSpot(): Spot {
        return {
            pref: this.departurePref?.code ? PrefectureEnum.valueOf(this.departurePref.code as PrefectureEnumCode) : undefined,
            city: this.departureCity,
        };
    }

    set departureSpot(spot: Spot) {
        this.departurePref = PrefectureEnum.valueOf(spot.pref?.code as PrefectureEnumCode) ?? {};
        this.departureCity = spot?.city ?? '';
        this.updateLocation();
    }

    get arrivalSpot(): Spot {
        return {
            pref: this.arrivalPref?.code ? PrefectureEnum.valueOf(this.arrivalPref.code as PrefectureEnumCode) : undefined,
            city: this.arrivalCity,
        };
    }

    set arrivalSpot(spot: Spot) {
        this.arrivalPref = PrefectureEnum.valueOf(spot.pref?.code as PrefectureEnumCode) ?? {};
        this.arrivalCity = spot?.city ?? '';
        this.updateLocation();
    }

    updateLocation(): void {
        this.location = `${this.departurePref?.label ?? ''}${this.departureCity ?? ''} → ${this.arrivalPref?.label ?? ''}${this.arrivalCity ?? ''}`;
    }

    validator(): FormValidator<CompanyTruckEventTruckRegisterForm> {
        return {
            departureSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.departurePref.code) {
                            callback('都道府県を選択してください。');
                        } else if (this.departureCity && this.departureCity.length > Const.MAX_CITY) {
                            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
                        } else {
                            callback();
                        }
                    }
                }
            ],
            arrivalSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.arrivalPref.code) {
                            callback('都道府県を選択してください。');
                        } else if (this.arrivalCity && this.arrivalCity.length > Const.MAX_CITY) {
                            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
                        } else {
                            callback();
                        }
                    }
                }
            ],
            title: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: 2000,
                    message: '2000文字以内で入力してください。',
                },
            ],
            circleId: [
                {
                    required: true,
                    message: '部屋を選択してください。',
                }
            ],
        };
    }
}

// 車両イベント空車更新フォーム
export class CompanyTruckEventTruckUpdateForm implements FormValidatable<CompanyTruckEventTruckUpdateForm> {
    circleId: number | undefined;
    companyTruckId?: number;
    startTm?: string;
    endTm?: string;
    departurePref: { code?: string, label?: string };
    departureCity?: string;
    arrivalPref: { code?: string, label?: string };
    arrivalCity?: string;
    title?: string;
    description?: string;
    location?: string;
    constructor(param: CompanyTruckEventTruckUpdateForm | null = null) {
        this.circleId = param?.circleId;
        this.companyTruckId = param?.companyTruckId;
        this.startTm = param?.startTm;
        this.endTm = param?.endTm;
        this.departurePref = param?.departurePref ?? {};
        this.departureCity = param?.departureCity ?? '';
        this.arrivalPref = param?.arrivalPref ?? {};
        this.arrivalCity = param?.arrivalCity ?? '';
        this.title = param?.title;
        this.description = param?.description;
        this.location = param?.location;
    }

    get departureSpot(): Spot {
        return {
            pref: this.departurePref?.code ? PrefectureEnum.valueOf(this.departurePref.code as PrefectureEnumCode) : undefined,
            city: this.departureCity,
        };
    }

    set departureSpot(spot: Spot) {
        this.departurePref = PrefectureEnum.valueOf(spot.pref?.code as PrefectureEnumCode) ?? {};
        this.departureCity = spot?.city ?? '';
        this.updateLocation();
    }

    get arrivalSpot(): Spot {
        return {
            pref: this.arrivalPref?.code ? PrefectureEnum.valueOf(this.arrivalPref.code as PrefectureEnumCode) : undefined,
            city: this.arrivalCity,
        };
    }

    set arrivalSpot(spot: Spot) {
        this.arrivalPref = PrefectureEnum.valueOf(spot.pref?.code as PrefectureEnumCode) ?? {};
        this.arrivalCity = spot?.city ?? '';
        this.updateLocation();
    }

    updateLocation(): void {
        this.location = `${this.departurePref?.label ?? ''}${this.departureCity ?? ''} → ${this.arrivalPref?.label ?? ''}${this.arrivalCity ?? ''}`;
    }

    validator(): FormValidator<CompanyTruckEventTruckUpdateForm> {
        return {
            departureSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.departurePref.code) {
                            callback('都道府県を選択してください。');
                        } else if (this.departureCity && this.departureCity.length > Const.MAX_CITY) {
                            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
                        } else {
                            callback();
                        }
                    }
                }
            ],
            arrivalSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.arrivalPref.code) {
                            callback('都道府県を選択してください。');
                        } else if (this.arrivalCity && this.arrivalCity.length > Const.MAX_CITY) {
                            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
                        } else {
                            callback();
                        }
                    }
                }
            ],
            title: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: 2000,
                    message: '2000文字以内で入力してください。',
                },
            ],
            circleId: [
                {
                    required: true,
                    message: '部屋を選択してください。',
                }
            ],
        };
    }
}
