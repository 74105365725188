import _ from 'lodash';
import { computed, ref } from 'vue';
import { CompanyBillingMonthlyModel } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';
import { AmountValue } from '@/models/vo/amount';

/**
 * 請求明細をロードする機能を提供します。
 */
export const useCompanyBilling = () => {
    const term = ref<number[]>([]);
    const list = ref<CompanyBillingMonthlyModel[]>([]);

    const billableList = computed<CompanyBillingMonthlyModel[]>(() => {
        return list.value.map(each => {
            const actualList = each.list.filter(model => !model.product.isInvoice);
            const actualAmount = _.sumBy(actualList, each => each.price.taxPrice.value);
            if (actualAmount <= 0) {
                const cloned = _.cloneDeep(each);
                cloned.list = actualList;
                cloned.total = new AmountValue(actualAmount);
                return cloned;
            } else {
                return each;
            }
        });
    });

    const { state: { loading }, withLoading } = useLoading();

    /**
     * 請求書発行期間をロードします。
     */
    const loadTerm = async () => withLoading(async () => {
        term.value = await companyApi.billingTerm()
            .then(({ min, max }) => _.range(min, max + 1).reverse());
    });

    /**
     * 請求一覧をロードします。
     */
    const loadList = async (year: number, month: number | undefined = undefined) => withLoading(async () => {
        list.value = await companyApi.billing(year, month)
            .then(list => CompanyBillingMonthlyModel.create(list));
    });

    return {
        state: {
            loading,
            term,
            list,
            billableList,
        },
        loadTerm,
        loadList,
    };
};
