import { computed, CreateElement, onBeforeMount, VNode } from 'vue';
import { Profile } from '@/models/account';
import { ModalUtil } from '@/util';
import { ProductCode } from '@/enums/product.enum';
import { AccountProfileModel } from '@/models/account-profile';
import _ from 'lodash';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useRouting } from '@/composables/helper/routing';
import { useAccountList } from '@/composables/account-list';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useAccountDelete } from '@/composables/account-delete';
import { useModal } from '@/composables/helper/modal-helper';

export const useSettingAccountListHelper = () => {
    const { state: { profile: myProfile } } = useAccountMyProfile();
    const { state: { contracts } } = useCompanyContracts();
    const { state: { list: accountList }, load: loadAccountList } = useAccountList();
    const { deleteAccount } = useAccountDelete();
    const notification = useNotification();
    const message = useMessage();
    const { confirm } = useModal();
    const { goToByName } = useRouting();

    onBeforeMount(async () => {
        await Promise.all([
            loadAccountList(),
        ]);
    });

    /**
     * 管理者ユーザー、もしくは追加ユーザー一覧を取得します。※契約が満了ユーザーを除外します
     */
    const visibleAccounts = computed<AccountProfileModel[]>(() => {
        if (!accountList.value) return [];
        if (!contracts.value) return [];

        const mainAccount = accountList.value.accounts.filter(each => each.isMainAccount);
        const otherAccounts = accountList.value.accounts.filter(each => {
            return !each.isMainAccount && (each.isAdditionalAccount || each.isWaitingForVerification);
        });
        return _.concat(mainAccount, otherAccounts);
    });

    /**
     * アカウントの作成ボタンが押下された際に呼び出されます。
     */
    const onClickCreate = async (): Promise<void> => {
        await goToByName('SettingAccountCreate');
    };

    /**
     * アカウントの編集ボタンが押下された際に呼び出されます。
     */
    const onClickEdit = async (): Promise<void> => {
        await goToByName('SettingAccountEdit');
    };

    /**
     * アカウントの削除ボタンが押下された際に呼び出されます。
     */
    const onClickDelete = async (record: Profile): Promise<void> => {
        const notifyFailedKey = 'DELETE_ACCOUNT_FAILED';
        if (!await confirm(
            `「${record.name}」さんを削除しますか？`,
            (h: CreateElement): VNode =>
                h(
                    'div',
                    getModalContent(record).map((message) => h('p', message))
                ),
            '削除',
            'キャンセル',
            undefined,
            'danger',
            ModalUtil.createConfirmDeletionIcon
        )) { return; }

        const onSuccess = () => {
            message.success(`「${record.name}」さんを削除しました。`);
            loadAccountList();
        };
        const onError = () =>
            notification.error({
                key: notifyFailedKey,
                message: `「${record.name}」さんを削除できませんでした。`,
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        notification.close(notifyFailedKey);
        await deleteAccount(record.id).then(onSuccess).catch(onError);
    };

    const getModalContent = (record: Profile): Array<string> => {
        const message = [];
        // TODO(asamac): クーポン対応時にメッセージングを細分化すること
        switch (record.product?.code as ProductCode) {
            case 'ACCOUNT_1M':
                message.push('このユーザーに紐付くプラン契約は、今月末付けで終了となります。');
                break;
            case 'PREMIUM_ACCOUNT_1M':
            case 'ACCOUNT_6M':
            case 'ACCOUNT_1Y':
            case 'PREMIUM_ACCOUNT_6M':
            case 'PREMIUM_ACCOUNT_1Y':
                message.push('ユーザーは削除されますが、プランの契約は継続されます。');
                break;
        }
        message.push('この操作は元に戻すことができません。ご注意ください。');
        return message;
    };

    return {
        state: {
            myProfile,
        },
        visibleAccounts,
        onClickCreate,
        onClickEdit,
        onClickDelete,
    };
};
