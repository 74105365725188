<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { CompanyTruckEventType } from '@/enums/truck-event-type.enum';
import InnerModalEditMemo from '@/_pages/Truck/Schedule/EventModal/Edit/InnerModalEditMemo.vue';
import InnerModalEditTruck from '@/_pages/Truck/Schedule/EventModal/Edit/InnerModalEditTruck.vue';
import { useCompanyTruckEvent } from '@/composables/company-truck-event';
import { useMyTruck } from '@/composables/truck';

const props = defineProps<{
    visible: boolean,
    companyEventId: number,
}>();

const emit = defineEmits<{
    (e: 'update:visible', value: boolean): void;
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const visible = computed(({
    get: () => props.visible,
    set: (value) => emit('update:visible', value),
}));

const selectedEventType = ref<CompanyTruckEventType | undefined>();

const onOk = () => {
    visible.value = false;
    emit('ok');
};

const onCancel = () => {
    visible.value = false;
    emit('cancel');
};


const useHelper = (companyTruckEventId: number) => {
    const { state: { companyTruckEvent }, load: loadCompanyTruckEvent } = useCompanyTruckEvent();
    const { state: { truck }, load: loadTruck } = useMyTruck();
    const loading = ref<boolean>(false);

    const initialize = async () => {
        loading.value = true;
        await loadCompanyTruckEvent(companyTruckEventId);

        if (companyTruckEvent.value?.truckId)
            await loadTruck(companyTruckEvent.value.truckId);

        if (companyTruckEvent.value?.eventType.code) {
            selectedEventType.value = CompanyTruckEventType.valueOf(companyTruckEvent.value.eventType.code);
        }
        loading.value = false;
    };

    onBeforeMount(() => initialize());

    return {
        loading,
        companyTruckEvent,
        truck,
    };
};

const { loading, companyTruckEvent, truck } = useHelper(props.companyEventId);

</script>

<template>
    <a-modal v-model:visible="visible" width="800px" :footer="null" :confirm-loading="loading" :afterClose="onCancel">
        <template #title>
            <div class="modal-title-section">
                <span class="title">編集</span>
                <span v-if="selectedEventType?.label" class="button">
                    <a-dropdown :disabled="true">
                        <a-button>
                            {{ selectedEventType?.label }}
                            <a-icon type="down"/>
                        </a-button>
                    </a-dropdown>
                </span>
            </div>
        </template>
        <a-spin :spinning="loading">
            <template v-if="companyTruckEvent">
                <inner-modal-edit-memo v-if="selectedEventType === CompanyTruckEventType.Memo"
                                       :company-truck-event="companyTruckEvent"
                                       submitText="編集する"
                                       @ok="onOk"
                                       @cancel="onCancel"/>
                <inner-modal-edit-truck v-else-if="selectedEventType === CompanyTruckEventType.Empty"
                                        :company-truck-event="companyTruckEvent"
                                        :truck="truck"
                                        submitText="編集する"
                                        @ok="onOk"
                                        @cancel="onCancel"/>
            </template>
        </a-spin>
    </a-modal>
</template>

<style scoped lang="less">
.modal-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;

    .title {
        font-size: 20px;
        font-weight: bold;
    }

    .button {
    }
}

</style>
