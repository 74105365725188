<script setup lang="ts">
import _ from 'lodash';
import { computed, ref } from 'vue';
import moment from 'moment';
import { DateValue } from '@/models/vo/date';

const props = withDefaults(defineProps<{
    value?: DateValue,
    placeholder?: string,
    format?: 'YYYY/M/D' | 'YYYY-MM-DD' | 'YYYY年M月D日(ddd)',
    disabledDate?: (currentDate: DateValue) => boolean,
    inputReadOnly?: boolean,
    allowClear?: boolean,
    showToday?: boolean,
    disabled?: boolean,
}>(), {
    format: 'YYYY-MM-DD',
    disabledDate: (currentDate: DateValue) => false,
    inputReadOnly: false,
    allowClear: true,
    showToday: true,
});

const emits = defineEmits<{
    (e: 'input', selectedDate: DateValue | undefined): void;
    (e: 'change', selectedDate: DateValue | undefined): void;
}>();

// picker panel mode
const mode = ref('date');

const selectedDate = computed({
    get: () => props.value ? props.value.format() : undefined,
    set: (value: string | undefined) => {
        const newValue = value ? new DateValue(value) : undefined;
        emits('input', newValue);
        emits('change', newValue);
    }
});

const disabledDateByMoment = (currentDateAsMoment: moment.Moment): boolean =>
    props.disabledDate(new DateValue(currentDateAsMoment.format('YYYY-MM-DD')));

/**
 * カレンダーパネルを「月選択」と「日選択」に限定します。
 */
const onPanelChange = (_value: any, newMode: 'time' | 'date' | 'month' | 'year' | 'decade') => {
    mode.value = _.get({
        'decade': 'month',
        'year': 'month',
        'month': 'month',
        'date': 'date',
        'time': 'date',
    }, newMode, 'date');
};
</script>
<template>
    <a-date-picker v-model="selectedDate"
                   :placeholder="placeholder"
                   :format="format"
                   :input-read-only="inputReadOnly"
                   :allow-clear="allowClear"
                   :show-today="showToday"
                   :mode="mode"
                   :disabled-date="disabledDateByMoment"
                   :disabled="disabled"
                   value-format="YYYY-MM-DD"
                   @panelChange="onPanelChange">
        <template #suffixIcon>
            <slot name="suffixIcon"></slot>
        </template>
    </a-date-picker>
</template>
<style scoped lang="less">
</style>
