<script setup lang="ts">
import { Const } from '@/const';
import PageLayout from '@/_components/PageLayout.vue';

const appServiceName = Const.APP_SERVICE_NAME;
</script>

<template>
    <page-layout :back-icon="false">
        <div class="board-container">
            <a-card>
                <a-result status="success" title="ご愛顧ありがとうございました。退会手続きが完了しました。">
                    <template #extra>
                        <p>
                            確認メールを送付いたしましたのでご確認ください。<br/>
                            {{ appServiceName }}をご利用いただきありがとうございました。
                        </p>
                    </template>
                </a-result>
            </a-card>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}
</style>
