<script setup lang="ts">
import { Spot } from '@/_components/ui/types/spot-type';
import { computed } from 'vue';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import _ from 'lodash';
import { Col } from 'ant-design-vue/types/grid/col';
import { useFormModelItem } from '@/composables/form-helper';

const props = withDefaults(defineProps<{
    value: Spot,
    propName: string,
    label?: string,
    showAddress?: boolean,
    labelCol?: Partial<Col>,
    wrapperCol?: Partial<Col>,
}>(), {
    label: '',
    showAddress: true,
});
const emits = defineEmits<{
    (e: 'input', value: Spot): void,
}>();
const prefCode = computed<PrefectureEnumCode | undefined>({
    get: () => props.value.pref?.code,
    set: (value: PrefectureEnumCode | undefined) => {
        const spot = _.cloneDeep(props.value);

        const changed = spot.pref?.code !== value;

        // 都道府県
        spot.pref = value ? PrefectureEnum.valueOf(value) : undefined;
        // 市区町村（都道府県が変更された場合はクリア）
        spot.city = changed ? '' : spot.city;
        // 番地・建物（都道府県変更された場合はクリア）
        spot.address = changed ? '' : spot.address;

        emits('input', spot);
    },
});
const city = computed<string | undefined>({
    get: () => props.value.city,
    set: (value: string | undefined) => {
        const spot = _.cloneDeep(props.value);
        spot.city = value;
        emits('input', spot);
    }
});
const address = computed<string | undefined>({
    get: () => props.value.address,
    set: (value: string | undefined) => {
        const spot = _.cloneDeep(props.value);
        spot.address = value;
        emits('input', spot);
    }
});
const widths = computed<{ pref: number, city: number, address: number }>(() => {
    if (props.showAddress) {
        return { pref: 20, city: 40, address: 40 };
    }
    return { pref: 33, city: 67, address: 0 };
});
const { formModelItem, onChange: onChangeSpot } = useFormModelItem();
</script>

<template>
    <a-form-model-item :label="label"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol"
                       :auto-link="false"
                       :prop="propName"
                       ref="formModelItem">
        <a-input-group compact>
            <prefecture-select :style="`width: ${ widths.pref }%;`"
                               :multiple="false"
                               placeholder="都道府県"
                               @change="onChangeSpot"
                               v-model="prefCode">
                <template #title>
                    <slot name="prefTitle"></slot>
                </template>
            </prefecture-select>
            <city-input :style="`width: ${ widths.city }%;`"
                        placeholder="市区町村"
                        :pref-code="prefCode"
                        @change="onChangeSpot"
                        v-model="city"/>
            <a-input v-if="showAddress"
                     :style="`width: ${ widths.address }%`"
                     placeholder="番地・建物（任意）"
                     @change="onChangeSpot"
                     v-model="address"/>
        </a-input-group>
    </a-form-model-item>
</template>

<style scoped lang="less">

</style>
