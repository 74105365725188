import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { RenderContext } from 'vue/types/options';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckWeightGroupEnum, TruckWeightGroupEnumCode } from '@/enums/truck-weight-group.enum';

type CheckedValue = TruckWeightGroupEnumCode | TruckWeightEnumCode;
interface TruckWeightTreeItem {
    title: string;
    key: CheckedValue;
    children?: Array<{ title: string; key: string }>;
}

@Component({
    components: {
        VNodes: {
            functional: true,
            render: (_h: CreateElement, context: RenderContext) => context.props.vnodes,
        },
    },
})
export default class UiTruckWeightTreeSelect extends Vue {
    @Prop()
    declare readonly value?: Array<TruckWeightEnumCode>;
    @Prop()
    declare readonly size?: 'default' | 'large' | 'small'; // select メニューのサイズ
    @Prop({ default: true })
    declare readonly ellipsis: boolean; // タグが1行に収まらない場合は「・・・」の省略記号を表示するか否か（複数選択モードでのみ機能します）
    @Prop({ default: '重量' })
    declare readonly placeholder: string; // select メニューのプレースホルダ
    @Prop({ default: 'bottomLeft' })
    declare readonly placement: string; // ドロップダウン内のコンテンツを表示する位置

    isMenuOpen = false; // ドロップダウンメニューの開閉状態

    // トラック車両重量ツリー
    treeData: { smallMedium: TruckWeightTreeItem[]; others: TruckWeightTreeItem[] } = {
        smallMedium: this.buildTreeItem([
            TruckWeightEnum.Mini,
            TruckWeightGroupEnum.Small,
            TruckWeightGroupEnum.Medium,
        ]),
        others: this.buildTreeItem([TruckWeightGroupEnum.Large, TruckWeightEnum.Trailer, TruckWeightEnum.Other]),
    };

    /**
     * 画面左側（重量: 軽/小/中）のツリーにおける、チェックボックスのチェック状態
     */
    get checkedKeysForSmallMediumTree(): CheckedValue[] {
        if (!this.value) {
            return [];
        }
        const selectedGroups = TruckWeightGroupEnum.values
            // Groupに属するTruckWeightがすべてチェックされているか確認
            .filter((each) => each.truckWeights.every((code) => this.value && this.value.includes(code)))
            // Small or Mediumのみに絞り込み
            .filter(
                (each) =>
                    each.code === TruckWeightGroupEnum.Small.code || each.code === TruckWeightGroupEnum.Medium.code
            );

        return _.concat(
            selectedGroups.map((each) => each.code),
            _.intersection(this.value, [
                TruckWeightEnum.Mini.code,
                ...TruckWeightGroupEnum.Small.truckWeights,
                ...TruckWeightGroupEnum.Medium.truckWeights,
            ])
        );
    }

    set checkedKeysForSmallMediumTree(values: CheckedValue[]) {
        // 2つのツリーの値を結合して、TruckWeightGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, this.checkedKeysForOthersTree),
            ...TruckWeightGroupEnum.values.map((each) => each.code)
        ) as TruckWeightEnumCode[];
        this.emitValue(newValues);
    }

    /**
     * 画面右側（重量: 軽/小/中以外）のツリーにおける、チェックボックスのチェック状態
     */
    get checkedKeysForOthersTree(): CheckedValue[] {
        if (!this.value) {
            return [];
        }

        const isLargeTruckAllSelected = TruckWeightGroupEnum.Large.truckWeights.every(
            (each) => this.value && this.value.includes(each)
        );
        // exclude small and medium truck weights
        const others = _.difference(
            this.value,
            TruckWeightGroupEnum.Small.truckWeights,
            TruckWeightGroupEnum.Medium.truckWeights,
            [TruckWeightEnum.Mini.code]
        );
        return _.concat(isLargeTruckAllSelected ? [TruckWeightGroupEnum.Large.code] : [], others);
    }

    set checkedKeysForOthersTree(values: CheckedValue[]) {
        // 2つのツリーの値を結合して、TruckWeightGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, this.checkedKeysForSmallMediumTree),
            ...TruckWeightGroupEnum.values.map((each) => each.code)
        ) as TruckWeightEnumCode[];
        this.emitValue(newValues);
    }

    /**
     * プルダウンメニューの表示用ラベルの値
     */
    get labels(): Array<TruckWeightEnum | TruckWeightGroupEnum> {
        if (!this.value) {
            return [];
        }
        const selectedGroups = TruckWeightGroupEnum.values.filter((each) =>
            each.truckWeights.every((code) => this.value && this.value.includes(code))
        );
        const selectedWeights = _.difference(this.value, ...selectedGroups.map((each) => each.truckWeights)).map(
            (value) => {
                const truckWeight = TruckWeightEnum.valueOf(value);
                if (!truckWeight) {
                    throw new Error('given value is not defined. [value] => ' + value);
                }
                return truckWeight;
            }
        );
        return _.concat<TruckWeightEnum | TruckWeightGroupEnum>(selectedGroups, _.orderBy(selectedWeights, 'orderNo'));
    }

    /**
     * プルダウンメニュー（ant-select）に付与するstyleClassを取得します。
     */
    get selectStyleClass(): { [key: string]: boolean } {
        return {
            'ant-select-lg': this.size === 'large',
            'ant-select-sm': this.size === 'small',
            'ant-select-default': this.size === 'default',
            'ant-select-open': this.isMenuOpen,
        };
    }

    created(): void {
        if (this.value && !_.isArray(this.value)) {
            throw new Error('v-model(value) must be array object.');
        }
    }

    /**
     * TruckModelTypeEnumをTreeDataItemにマップします。
     */
    buildTreeItem(items: Array<TruckWeightEnum | TruckWeightGroupEnum>): TruckWeightTreeItem[] {
        return items.map((item) => {
            const treeItem: TruckWeightTreeItem = {
                title: item.label,
                key: item.code,
            };
            if (item instanceof TruckWeightGroupEnum) {
                treeItem.children = item.truckWeights.map((each) => {
                    const truckWeight = TruckWeightEnum.valueOf(each);
                    if (!truckWeight) {
                        throw new Error('given value is not defined. [value] => ' + each);
                    }
                    return {
                        title: truckWeight.label,
                        key: truckWeight.code,
                    };
                });
            }
            return treeItem;
        });
    }

    /**
     * 選択した項目（車両重量）を取り除きます。
     */
    removeItem(event: Event, value: TruckWeightEnum | TruckWeightGroupEnum): void {
        event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        const newValues = _.difference(
            this.value ?? [],
            value instanceof TruckWeightGroupEnum ? value.truckWeights : [value.code]
        );
        this.emitValue(newValues);
    }

    /**
     * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
     */
    getOverflowIndex(index: number): number {
        if (index < 0) {
            return -1;
        }
        const tagsElement = this.$refs.tags as Element | undefined;
        if (!tagsElement) {
            return -1;
        }
        const tagBaseWidth = this.size === 'large' ? 36 : 32;
        const tagSpacing = 4;
        const dropdownIconWidth = 16;
        const widthByLabels = this.labels.map((each) => {
            return tagSpacing + tagBaseWidth + each.label.length * 14;
        });
        return widthByLabels.findIndex(
            (_each, labelIndex, array) =>
                _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
        );
    }

    /**
     * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
     */
    isOverflowTag(index: number): boolean {
        if (!this.ellipsis) {
            return false;
        }
        const overflowIndex = this.getOverflowIndex(index);
        return overflowIndex > 0 && index >= overflowIndex;
    }

    /**
     * 確定ボタンを押下すると呼び出されます。
     */
    onClickOk(): void {
        this.isMenuOpen = false;
    }

    /**
     * 選択ラベルをクリックすると呼び出されます。
     */
    onClickChoiceContent(e: Event): void {
        // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        e.stopPropagation();
    }

    /**
     * 画面左側（重量: 軽/小/中）のツリーでチェック操作が行われた際に呼び出されます。
     */
    onCheckSmallMediumTree(checkedKeys: Array<CheckedValue>): void {
        this.checkedKeysForSmallMediumTree = checkedKeys;
    }

    /**
     * 画面右側（重量: 軽/小/中以外）のツリーでチェック操作が行われた際に呼び出されます。
     */
    onCheckOthersTree(checkedKeys: Array<CheckedValue>): void {
        this.checkedKeysForOthersTree = checkedKeys;
    }

    private emitValue(values: TruckWeightEnumCode[]): void {
        this.$emit('input', values.sort());
    }
}
