import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validator } from '@/validator';
import * as types from '@/vuex/modules/account/types';

@Component
export default class AccountEmailEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AccountProfileCreateOrUpdateForm;

    /**
     * メールアドレス
     */
    get email(): string {
        return this.value?.email ?? '';
    }

    set email(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.email = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 値が変更された際に呼び出される。
     */
    onBlur(): void {
        this.email = this.email.trim();
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        const validated = Validator.validateEmail(this.value?.email ?? '');
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }
}
