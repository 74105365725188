import { onMounted } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useDeliveryOrderPrintSave } from '@/composables/global/delivery-order-print';

export const useDeliveryOrderPrintHelper = () => {
    const { state: { doc }, clear: clearStoredDocument } = useDeliveryOrderPrintSave();
    const router = useRouter();

    onMounted(async () => {
        document.title = '';
        document.title = fileName();

        window.onafterprint = async () => {
            router.go(-1);
            clearStoredDocument();
        };
        window.print();
    });

    const fileName = (): string => {
        const departureDate = doc.value?.content.departureDateTime.dateValue.format('YYYY-MM-DD');
        const manageNumber = doc.value?.content.manageNumber;
        return `配車依頼書_${ departureDate }_${ manageNumber }.pdf`;
    };

    return {
        doc,
    };
};
