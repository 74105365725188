import { computed, ref, watch } from 'vue';
import { Modal, Tabs } from 'ant-design-vue';
import { useElementScroll } from '@/composables/helper/page-helper';
import { injectStrictly } from '@/util/vue';
import { BAGGAGE_DRAWER_PROVIDER_KEY } from '@/composables/provider/baggage-drawer-provider';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import _ from 'lodash';
import { useFormModel } from '@/composables/form-helper';
import { useNegotiationReportStatusOptions } from '@/composables/option-helper';

export const useBaggageSearchDetailDrawerHelper = () => {
    // 選択中のタブ
    const activeTabKey = ref<'baggage' | 'company'>('baggage');

    // タブコンテントのスクロール
    const baggageDetailTabsRef = ref<Tabs>();
    const tabContent = computed<HTMLElement | undefined>(() => {
        return baggageDetailTabsRef.value?.$el.getElementsByClassName('ant-tabs-content')[0] as HTMLElement | undefined;
    });
    const { scrollToTop: scrollToContentTop, scrollToContent } = useElementScroll(tabContent);
    const onTabChange = (): void => {
        scrollToContentTop();
    };

    const { submit, formModel: negotiationRequestFormModel } = useFormModel();

    // 商談リクエストのスクロール
    const negotiationRequestFormBlockRef = ref<HTMLDivElement>();
    const scrollToNegotiationRequestForm = () => {
        if (negotiationRequestFormBlockRef.value) {
            scrollToContent(negotiationRequestFormBlockRef.value.offsetTop);
        }
    };
    const { options: negotiationReportStatusOptions } =  useNegotiationReportStatusOptions();

    const {
        state: {
            baggage,
            baggageStatus,
            isMyCompanyBaggage,
            underNegotiation,
            favoriteState,
            profile,
            officialCompany,
            companyName,
            referenceFreight,
            confidence,
            statistics,
            negotiation,
            canAgree,
            canMarkNegotiation,
            canUnmarkNegotiation,
            negotiationRequestForm,
            negotiationRequestFormValidateRules,
        },
        clickAgree,
        clickMarkFavorite,
        clickUnmarkFavorite,
        clickCancelUnmarkFavorite,
        clickMarkUnderNegotiation,
        clickUnmarkUnderNegotiation,
        clickCompany,
        clickPrint,
        clickRequestNegotiation,
    } = injectStrictly(BAGGAGE_DRAWER_PROVIDER_KEY);

    const baggageId = computed<number | undefined>(() => baggage.value?.id);

    /**
     * ステータスラベルを表示すべきか否かを取得します。
     */
    const shouldShowStatusLabel = computed<boolean>(() => {
        return (baggageStatus.value?.isClosed() ?? false) ||
            (baggageStatus.value?.isCancel() ?? false);
    });

    /**
     * 「成約」ボタンを表示すべきか否かを取得します。
     */
    const shouldShowAgreeButton = computed<boolean>(() => {
        return canAgree.value;
    });

    /**
     * 取り下げ済ラベルを表示すべきか否かを取得します。
     */
    const shouldShowCanceledLabel = computed<boolean>(() => {
        return baggageStatus.value?.isCancel() ?? false;
    });

    /**
     * 「商談中にする」を表示すべきか否かを取得します。
     */
    const shouldShowMarkNegotiationButton = computed<boolean>(() => {
        return canMarkNegotiation.value;
    });

    /**
     * 「商談中を解除」を表示すべきか否かを取得します。
     */
    const shouldShowUnmarkNegotiationButton = computed<boolean>(() => {
        return canUnmarkNegotiation.value;
    });

    /**
     * 商談リクエストを表示するか否かを取得します。
     * @param event
     */
    const shouldShowNegotiationRequest = computed<boolean>(() => {
        return !isMyCompanyBaggage.value && baggage.value?.negotiationType.code === NegotiationTypeEnum.Online.code;
    });

    /**
     * 「トラボックス掲載中」を表示するか否かを取得します。
     */
    const shouldShowTraboxTag = computed<boolean>(() => {
        return baggage.value?.traboxBaggageId !== undefined;
    });

    /**
     * すでに商談リクエスト済か否かを取得します。
     * @param event
     */
    const isAlreadyRequestNegotiation = computed<boolean>(() => {
        return !_.isNil(negotiation.value);
    });

    /**
     * 商談リクエストを受け付けているかを取得します。
     */
    const canRequestNegotiation = computed<boolean>(() => {
        if (!shouldShowNegotiationRequest.value) return false;
        return canAgree.value;
    });

    const onClickAgree = async (event: Event): Promise<void> => {
        event.cancelBubble = true;
        await clickAgree();
    };

    /**
     * 荷物情報タブで「実績をみる」が押下された際に呼び出されます。
     */
    const onClickShowAchievement = (): void => {
        activeTabKey.value = 'company';
        scrollToContentTop();
    };

    /**
     * 荷物詳細カラム内の「商談リクエストする」が押下された際に呼び出されます。
     */
    const onClickNegotiationRequestDetail = (): void => {
        scrollToNegotiationRequestForm();
    };

    /**
     * フォームの「商談リクエスト」が押下された際に呼び出されます。
     */
    const onClickNegotiationRequest = async (successCallback: () => void): Promise<void> => {
        if (!await confirmRequestForm()) return;

        submit(
            async () => await clickRequestNegotiation().then(() => successCallback()),
            () => Promise.resolve(),
        );
    };

    /**
     * 入力内容確認モーダルを表示します。
     */
    const confirmRequestForm = (): Promise<boolean> => {
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: '対応可否を報告しますか？',
            content: '',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '送信',
            cancelText: 'キャンセル',
        }));
    };

    /**
     * 荷物が切り替わった時に呼び出されます。
     */
    watch(baggageId, () => {
        activeTabKey.value = 'baggage';
        scrollToContentTop();
    });

    return {
        activeTabKey,
        baggage,
        baggageId,
        profile,
        officialCompany,
        underNegotiation,
        favoriteState,
        referenceFreight,
        isMyCompanyBaggage,
        companyName,
        confidence,
        statistics,
        shouldShowStatusLabel,
        shouldShowCanceledLabel,
        shouldShowAgreeButton,
        shouldShowMarkNegotiationButton,
        shouldShowUnmarkNegotiationButton,
        shouldShowNegotiationRequest,
        shouldShowTraboxTag,
        isAlreadyRequestNegotiation,
        canRequestNegotiation,
        negotiation,
        negotiationRequestForm,
        negotiationRequestFormValidateRules,
        negotiationRequestFormModel,
        baggageDetailTabsRef,
        negotiationRequestFormBlockRef,
        negotiationReportStatusOptions,
        onTabChange,
        onClickShowAchievement,
        onClickAgree,
        onClickNegotiationRequestDetail,
        onClickNegotiationRequest,
        clickMarkFavorite,
        clickUnmarkFavorite,
        clickCancelUnmarkFavorite,
        clickMarkUnderNegotiation,
        clickUnmarkUnderNegotiation,
        clickCompany,
        clickPrint,
    };
};
