import { useBaggageFavoriteIdList } from '@/composables/baggage-favorite-id-list';
import { useBaggageFreightMasterList } from '@/composables/baggage-freight-master-list';
import { useBaggageMarkFavorite } from '@/composables/baggage-mark-favorite';
import { useBaggageReadIdList } from '@/composables/baggage-read-id-list';
import { useCompanyProfileList } from '@/composables/company-profile-list';
import { useLoading } from '@/composables/helper/loading-helper';
import { useMessage } from '@/composables/helper/page-helper';
import {
    Baggage,
    BaggageFavoriteState,
    BaggageFreightMaster,
    BaggageFreightMasterListForm,
    BaggageFreightMasterQueryForm,
    BaggageList,
    BaggageRecommendReturnsCount,
    MaskedBaggage,
    MaskedBaggageList
} from '@/models/baggage';
import { CompanyProfile } from '@/models/company';
import { BaggageDetailUtil, BaggageUtil } from '@/util';
import _ from 'lodash';
import { computed, InjectionKey, ref, Ref } from 'vue';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useBaggageSearchExcludeCompany } from '@/composables/global/baggage-search-exlucde-company';
import { DateTimeValue } from '@/models/vo/datetime';
import { createGlobalState, useSessionStorage } from '@vueuse/core';
import { useBaggageRecommendations } from '@/composables/baggage-recommendations';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';

const useBaggageSearchTimeLog = createGlobalState(() => {
    const searchTimeLog = ref(new BaggageSearchExecutionTimeLog());

    return { searchTimeLog };
});

/**
 * 荷物検索テーブル用のデータをまとめて受け渡しする機能を提供します。
 * @param baggageComposable 荷物のロード方法を提供するComposable。状態を呼び出し元と共有します。
 * @param options オプション
 */
export const useBaggageSearchProvider = (
    baggageComposable: BaggageComposableType,
    options: {
        newArrival?: boolean,
        excludeCompany?: boolean,
        showRecommend?: boolean,
    } = {}
) => {
    /* 自分の会社情報 */
    const { state: { myCompanyId } } = useCompanyMyProfile();
    /* 荷物データとロード用のComposable */
    const { state: { baggageList }, load: loadBaggageList, changeNegotiation } = baggageComposable;
    const oldList = ref<BaggageRow[]>([]);
    const excludedBaggageCount = ref<number>(0);

    /* 企業一覧Composable */
    const {
        state: { list: companyProfileList, },
        load: loadCompanyProfileList,
    } = useCompanyProfileList();

    /* 荷物の既読状態一覧Composable */
    const {
        state: { ids: readBaggageIdList, },
        load: loadBaggageReadIdList,
        changeMark: changeReadMarkIdList,
    } = useBaggageReadIdList();

    /* 保存した荷物一覧Composable */
    const {
        state: { ids: favoriteBaggageIdList, },
        load: loadBaggageFavoriteIdList,
        changeMark: changeFavoriteMarkIdList,
    } = useBaggageFavoriteIdList();
    /* 企業除外リスト */
    const { state: { list: excludedCompanyList } } = useBaggageSearchExcludeCompany();
    /* 荷物検索実行時間ログ */
    const { searchTimeLog } = useBaggageSearchTimeLog();

    // 新着扱いとする荷物IDリスト
    const newBaggageIdList = computed(() => baggageList.value.data
        .filter(baggage => new DateTimeValue(baggage.entryTm).isAfter(searchTimeLog.value.previous))
        .map(baggage => baggage.id));

    /* 参考価格取得Composable */
    const {
        state: { list: referenceFreightList, },
        load: loadBaggageFreightMaterList,
    } = useBaggageFreightMasterList();

    /* 荷物の保存登録・登録解除Composable */
    const { state: { cancellableIds }, mark, unmark, unmarkCancellable, cancelUnmark } = useBaggageMarkFavorite();
    // おすすめお帰り便フラグ
    const showRecommendationsFlag = useSessionStorage<boolean>('showRecommendations', false);
    /* おすすめ帰り便Composable */
    const {
        state: { counts: baggageRecommendReturnsCount },
    } = useBaggageRecommendations();
    //
    // ヘルパー
    //
    const message = useMessage();
    const { state: { loading }, withLoading } = useLoading();

    const selectedBaggageId = ref<number | undefined>(undefined);

    /**
     * 成約可能な荷物かどうか。
     */
    const isReadyToAgree = (record: Baggage | MaskedBaggage): boolean => {
        return BaggageUtil.isReadyToAgree(record, myCompanyId.value);
    };

    /**
     * 荷物の保存登録済みか否かを取得します。
     */
    const isMarkedAsFavorite = (record: Baggage | MaskedBaggage): boolean => {
        return (favoriteBaggageIdList.value ?? []).includes(record.id);
    };

    /**
     * 荷物の保存がキャンセル可能か否かを取得します。
     * @param record
     */
    const isAbleToCancelUnmarkFavorite = (record: Baggage | MaskedBaggage): boolean => {
        return cancellableIds.value.includes(record.id);
    };

    /**
     * 荷物の保存状態を取得します。
     */
    const favoriteState = (record: Baggage | MaskedBaggage): BaggageFavoriteState | undefined => {
        if (isReadyToAgree(record) && !isMarkedAsFavorite(record)) {
            // 未保存
            return 'Unmarked';
        } else if (isMarkedAsFavorite(record) && !isAbleToCancelUnmarkFavorite(record)) {
            // 保存済
            return 'Marked';
        } else if (isMarkedAsFavorite(record) && isAbleToCancelUnmarkFavorite(record)) {
            // 保存を解除中
            return 'Unmarking';
        } else {
            // 保存不可の荷物
            return undefined;
        }
    };

    /**
     * 荷物検索テーブルに使用する一覧
     */
    const list = computed<BaggageRow[]>(() => {
        if (loading.value) return oldList.value;
        /* 企業を探す関数 */
        const findCompany = (id: number) => companyProfileList.value.find((each) => each.id === id);
        /* 参考運賃を探す関数 */
        const findReferenceFreight = (baggage: Baggage | MaskedBaggage) =>
            referenceFreightList.value.find((each) =>
                baggage.departurePref ? each.matchFor(baggage as Baggage) : false);

        /* 企業プロフィールをマージする関数 */
        const mergeCompany = <T extends Baggage | MaskedBaggage>(baggage: T): T & { company?: CompanyProfile } => {
            return { ...baggage, company: findCompany(baggage.companyId) };
        };

        /* 参考運賃をマージする関数 */
        const mergeFreight = <T extends Baggage | MaskedBaggage>(baggage: T): T & { referenceFreight?: BaggageFreightMaster } => {
            return { ...baggage, referenceFreight: findReferenceFreight(baggage) };
        };

        /* 新着フラグをマージする関数 */
        const mergeNewFlg = <T extends Baggage | MaskedBaggage>(baggage: T): T & { newFlg: boolean } => {
            const newFlg = !baggage.underNegotiation && newBaggageIdList.value.includes(baggage.id);
            return { ...baggage, newFlg };
        };

        /* 成約済フラグをマージする関数 */
        const mergeStatusFlg = <T extends Baggage | MaskedBaggage>(baggage: T): T & { closed: boolean, expire: boolean, cancel: boolean, noAccess: boolean } => {
            const closed = (baggage.status?.code === BaggageStatusEnum.Closed.code);
            const expire = (baggage.status?.code === BaggageStatusEnum.Expire.code);
            const cancel = (baggage.status?.code === BaggageStatusEnum.Cancel.code);
            const noAccess = !baggage.status;
            return { ...baggage, closed, expire, cancel, noAccess };
        };

        /* 荷物の保存状態をマージする関数 */
        const mergeFavorite = <T extends Baggage | MaskedBaggage>(baggage: T): T & { favorite?: BaggageFavoriteState } => {
            return { ...baggage, favorite: favoriteState(baggage) };
        };

        /* 荷物の既読状態をマージする関数 */
        const mergeReadFlg = <T extends Baggage | MaskedBaggage>(baggage: T): T & { readFlg: boolean } => {
            const readFlg = readBaggageIdList.value.includes(baggage.id);
            return { ...baggage, readFlg };
        };
        /** 企業除外リストで除外する関数 */
        const excludedCompanyIds = excludedCompanyList.value.map(each => each.id);
        const filterExcludedCompany = <T extends Baggage | MaskedBaggage>(baggage: T): boolean => {
            if (options.excludeCompany) return !excludedCompanyIds.includes(baggage.companyId);
            return true;
        };
        /** おすすめ帰り便の数をマージする関数 */
        const mergeRecommendReturnsCount = <T extends Baggage | MaskedBaggage>(baggage: T): T & { recommendationCount?: BaggageRecommendReturnsCount } => {
            const recommendationCount = baggageRecommendReturnsCount.value.find(each => each.id === baggage.id);
            return { ...baggage, recommendationCount };
        };

        const resultList = baggageList.value.data
            .filter(filterExcludedCompany)
            .map(mergeCompany)
            .map(mergeFreight)
            .map(mergeNewFlg)
            .map(mergeStatusFlg)
            .map(mergeFavorite)
            .map(mergeReadFlg)
            .map(mergeRecommendReturnsCount);

        excludedBaggageCount.value = baggageList.value.data.length - resultList.length;
        oldList.value = resultList;
        return resultList;
    });

    const showRecommend = computed<boolean>(() => {
        if (!options.showRecommend) {
            return false;
        }
        return showRecommendationsFlag.value;
    });

    const favoriteIdList = computed<number[]>(() => {
        return list.value.filter(each => each.favorite === 'Marked').map(each => each.id);
    });

    /**
     * 荷物一覧の読み込みを行います。
     * @param pageNo
     * @param pageSize
     */
    const load = (pageNo: number, pageSize: number) => withLoading(async () => {
        await loadBaggageList(pageNo, pageSize);
        const baggageIds = baggageList.value.data.map(baggage => baggage.id);
        const companyIds = _.uniq(baggageList.value.data.map(baggage => baggage.companyId));

        const loadBaggageFreight = async () => {
            const form = new BaggageFreightMasterListForm(
                baggageList.value.data.filter((each) => each.departurePref)
                    .map(each => BaggageFreightMasterQueryForm.newInstance(each as Baggage)
                    )
            );
            await loadBaggageFreightMaterList(form);
        };
        await Promise.all([
            loadBaggageFavoriteIdList(baggageIds),
            loadBaggageReadIdList(baggageIds),
            loadBaggageFreight(),
            loadCompanyProfileList(companyIds),
        ]);

        if (options.newArrival && pageNo === 1) {
            searchTimeLog.value.push(DateTimeValue.now());
        }
    }).catch((e: Error) => {
        message.error('荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。');
        throw e;
    });

    const selectBaggage = (id: number | undefined): void => {
        if (!_.isNil(id)) {
            const baggageIds = baggageList.value.data.map(baggage => baggage.id);
            if (!baggageIds.includes(id)) return;
        }
        selectedBaggageId.value = id;
    };

    const markFavorite = async (id: number): Promise<void> => {
        try {
            await mark(id);
            changeFavoriteMarkIdList(id, true);
        } catch (e) {
            message.error('公開が終了した荷物のため、保存できません。');
        }
    };

    const unmarkFavorite = async (id: number, callback?: (baggageId: number) => Promise<void>): Promise<void> => {
        if (callback) {
            await unmarkCancellable(id, async (callBackBaggageId: number) => {
                await unmark(callBackBaggageId);
                changeFavoriteMarkIdList(callBackBaggageId, false);
                await callback(callBackBaggageId);
            });
        } else {
            await unmark(id);
            changeFavoriteMarkIdList(id, false);
        }
    };

    /**
     * お気に入り登録解除の「キャンセル」ボタンが押下された際に呼び出されます。
     */
    const cancelUnmarkFavorite = async (id: number): Promise<void> => {
        cancelUnmark(id);
    };

    //
    // 荷物モデルに依存する関数(今のBaggageはInterfaceなのでメソッド追加できない為ここに記載)
    //
    /**
     * 運賃が未定（要相談）であるか否かを取得します。
     */
    const isFreightUndecided = (record: Baggage): boolean => {
        return !record.freight;
    };

    /**
     * ドライバー作業
     */
    const handlingOverview = (record: Baggage | MaskedBaggage): string => {
        if (!record.loading || !record.unloading) return '';
        return BaggageDetailUtil.handlingOverview(record.loading?.code, record.unloading?.code);
    };

    /**
     * ドライバー作業（詳細）
     */
    const handlingDetail = (record: Baggage | MaskedBaggage): string[] | undefined => {
        if (!record.loading || !record.unloading) return undefined;
        return BaggageDetailUtil.handlingDetail(record.loading?.code, record.unloading?.code);
    };

    /**
     * お気に入り「保存済」ボタンが押下された際に呼び出されます。
     */
    const clickUnmarkFavorite = async (baggageId: number, callback?: (baggageId: number) => Promise<void>): Promise<void> => {
        if (callback) {
            await unmarkCancellable(baggageId, async (completeBaggageId: number) => {
                await unmarkFavorite(completeBaggageId);
                await callback(baggageId);
            });
        } else {
            await unmarkFavorite(baggageId);
        }
    };

    return {
        state: {
            list,
            favoriteIdList,
            loading,
            selectedBaggageId,
            excludedBaggageCount,
            showRecommend,
        },
        load,
        selectBaggage,
        markFavorite,
        unmarkFavorite,
        cancelUnmarkFavorite,
        changeReadMarkIdList,
        changeFavoriteMarkIdList,
        changeNegotiation,
        clickUnmarkFavorite,
        baggageProperty: {
            isFreightUndecided,
            handlingDetail,
            handlingOverview,
        },
    };
};

/**
 * テーブル1行分のデータ表示型
 */
export type BaggageRow = (Baggage | MaskedBaggage) & {
    company?: CompanyProfile,
    referenceFreight?: BaggageFreightMaster,
    newFlg: boolean,
    closed: boolean,
    expire: boolean,
    cancel: boolean,
    noAccess: boolean,
    favorite?: BaggageFavoriteState,
    readFlg: boolean,
    recommendationCount?: BaggageRecommendReturnsCount,
};

/**
 * 荷物検索実行時刻ログ
 */
class BaggageSearchExecutionTimeLog {
    current = DateTimeValue.now();
    previous = DateTimeValue.now();

    push(value: DateTimeValue) {
        this.previous = this.current;
        this.current = value;
    }
}

/**
 * 荷物コンポーサブルとして要求する型
 */
export interface BaggageComposableType {
    state: { baggageList: Ref<BaggageList | MaskedBaggageList>, },
    load: (pageNo: number, pageSize: number) => Promise<void>,
    changeNegotiation: (baggageId: number, value: boolean) => void,
}

export type BaggageSearchProviderType = ReturnType<typeof useBaggageSearchProvider>;
export const BAGGAGE_SEARCH_PROVIDER_KEY: InjectionKey<BaggageSearchProviderType> = Symbol('BaggageSearchProvider');
