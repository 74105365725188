import { GuaranteePriceMaster } from '@/models/guarantee-master';
export * from '@/models/guarantee-master';

//
// Action
//
export class ACTION {
    static readonly LOAD_GUARANTEE_PRICE_MASTER = 'LOAD_GUARANTEE_PRICE_MASTER';
}

//
// Signature for Action
//
export type loadGuaranteePriceMaster = () => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly GUARANTEE_PRICE_MASTER = 'GUARANTEE_PRICE_MASTER';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_GUARANTEE_PRICE_MASTER = 'SET_GUARANTEE_PRICE_MASTER';
}

//
// Store
//
export interface GuaranteeState {
    priceMasterList?: GuaranteePriceMaster[];
}
