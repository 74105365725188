import { computed, ref } from 'vue';
import { CompanyProfileList, CompanyProfileSearchForm } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';
import { useEntitlement } from '@/composables/entitlement';

const defaultData = () => ({
    totalPageCount: 0,
    totalRecordCount: 0,
    currentPageNumber: 1,
    data: [],
});

/**
 * 企業を検索する機能を提供します。
 */
export const useCompanySearch = () => {
    const list = ref<CompanyProfileList>(defaultData());

    const { state: { loading }, withLoading } = useLoading();
    const { state: { entitlement } } = useEntitlement();

    const available = computed(() => entitlement.value?.can('searchCompany') ?? false);

    const load = (form: CompanyProfileSearchForm) => withLoading(async () => {
        list.value = await companyApi.searchProfile(form);
    });

    return {
        state: {
            loading,
            available,
            list,
        },
        load,
    };
};
