<script setup lang='ts'>
import EmptyTag from '@/_components/EmptyTag';
import { IconType } from './types/icon-type';
import AchievementBaggage from '@/_components/ui/icons/AchievementBaggage.vue';
import AchievementTruck from '@/_components/ui/icons/AchievementTruck.vue';
import Baggage from '@/_components/ui/icons/Baggage.vue';
import Truck from '@/_components/ui/icons/Truck.vue';

const props = defineProps<{
    type: IconType,
}>();
const emits = defineEmits<{
    (e: 'click'): void,
}>();
import { computed } from 'vue';

type ComponentType = 'achievement-baggage' | 'achievement-truck' | 'font-awesome' | 'ant-icon' | 'baggage' | 'truck';
type IconTypeMap = { [Key in IconType]: { componentType: ComponentType, props?: Record<string, any> } };
const map: IconTypeMap = {
    'achievement-baggage': { componentType: 'achievement-baggage' },
    'achievement-truck': { componentType: 'achievement-truck' },
    'baggage-favorite': { componentType: 'font-awesome', props: { icon: ['far', 'bookmark'] } },
    'baggage-favorite-marked': { componentType: 'font-awesome', props: { icon: ['fas', 'bookmark'] } },
    'baggage-search': { componentType: 'ant-icon', props: { type: 'search' } },
    'baggage-recent': { componentType: 'ant-icon', props: { type: 'history' } },
    'baggage-register': { componentType: 'ant-icon', props: { type: 'plus' } },
    'baggage': { componentType: 'baggage' },
    'truck': { componentType: 'truck' },
    'company-search': { componentType: 'font-awesome', props: { icon: ['far', 'building'] } },
    'misc': { componentType: 'ant-icon', props: { type: 'deployment-unit' } },
    'setting': { componentType: 'ant-icon', props: { type: 'setting' } },
    'menu-fold': { componentType: 'ant-icon', props: { type: 'menu-fold' } },
    'menu-unfold': { componentType: 'ant-icon', props: { type: 'menu-unfold' } },
    'arrow-left': { componentType: 'ant-icon', props: { type: 'arrow-left' } },
    'arrow-right': { componentType: 'ant-icon', props: { type: 'arrow-right' } },
    'left': { componentType: 'ant-icon', props: { type: 'left' } },
    'right': { componentType: 'ant-icon', props: { type: 'right' } },
    'down': { componentType: 'ant-icon', props: { type: 'down' } },
    'caret-right': { componentType: 'ant-icon', props: { type: 'caret-right' } },
    'online-order': { componentType: 'font-awesome', props: { icon: ['far', 'clone'] } },
    'dispatch': { componentType: 'font-awesome', props: { icon: ['far', 'clone'] } },
    'question': { componentType: 'ant-icon', props: { type: 'question-circle' } },
    'bell': { componentType: 'ant-icon', props: { type: 'bell' } },
    'lock': { componentType: 'ant-icon', props: { type: 'lock' } },
    'calendar': { componentType: 'ant-icon', props: { type: 'calendar' } },
    'info-circle': { componentType: 'ant-icon', props: { type: 'info-circle' } },
    'info-filled': { componentType: 'ant-icon', props: { type: 'info-circle', theme: 'filled' } },
    'prefecture-select': { componentType: 'ant-icon', props: { type: 'environment' } },
    'edit': { componentType: 'ant-icon', props: { type: 'edit' } },
    'check': { componentType: 'ant-icon', props: { type: 'check' } },
    'check-circle-filled': { componentType: 'ant-icon', props: { type: 'check-circle', theme: 'filled' } },
    'phone': { componentType: 'ant-icon', props: { type: 'phone' } },
    'phone-filled': { componentType: 'ant-icon', props: { type: 'phone', theme: 'filled' } },
    'close': { componentType: 'ant-icon', props: { type: 'close' } },
    'close-circle-filled': { componentType: 'ant-icon', props: { type: 'close-circle', theme: 'filled' } },
    'easy-payment': { componentType: 'ant-icon', props: { type: 'account-book', theme: 'outlined' } },
    'external-link': { componentType: 'font-awesome', props: { icon: ['fas', 'external-link-alt'] } },
    'file-text': { componentType: 'ant-icon', props: { type: 'file-text', theme: 'outlined' } },
    'download': { componentType: 'ant-icon', props: { type: 'download', theme: 'outlined' } },
    'easy-payment-link-to-agreement': { componentType: 'font-awesome', props: { icon: ['far', 'clone'] } },
    'easy-payment-link-to-guide': { componentType: 'font-awesome', props: { icon: ['far', 'clone'] } },
    'easy-payment-add-settlement-item': { componentType: 'ant-icon', props: { type: 'plus' } },
    'easy-payment-move-up-settlement-item': { componentType: 'ant-icon', props: { type: 'arrow-up' } },
    'easy-payment-move-down-settlement-item': { componentType: 'ant-icon', props: { type: 'arrow-down' } },
    'easy-payment-delete-settlement-item': { componentType: 'ant-icon', props: { type: 'delete' } },
    'easy-payment-exclude-settlement-item': { componentType: 'ant-icon', props: { type: 'stop' } },
    'easy-payment-cancel-exclude-settlement-item': { componentType: 'ant-icon', props: { type: 'stop' } },
    'staff': { componentType: 'font-awesome', props: { icon: ['fas', 'user'] } },
    'handshake': { componentType: 'font-awesome', props: { icon: ['far', 'handshake'] } },
    'home': { componentType: 'ant-icon', props: { type: 'home' } },
    'deal': { componentType: 'ant-icon', props: { type: 'transaction' } },
    'yen': { componentType: 'ant-icon', props: { type: 'pay-circle' } },
    'warning': { componentType: 'ant-icon', props: { type: 'warning' } },
    'file-done': { componentType: 'ant-icon', props: { type: 'file-done' } },
    'settlement-add-income': { componentType: 'ant-icon', props: { type: 'plus' } },
};
const componentType = computed<ComponentType>(() => {
    return map[props.type].componentType;
});
const iconProps = computed(() => map[props.type].props);
</script>

<template>
    <!-- VueではRootのタグは1つしか受付ないが、htmlタグが生成されるとstyleがずれるのでタグ生成のないコンポーネントで囲っている -->
    <empty-tag>
        <font-awesome-icon v-if="componentType === 'font-awesome'" v-bind="iconProps" class="anticon"/>
        <a-icon v-else-if="componentType === 'ant-icon'" v-bind="iconProps" @click="emits('click')"/>
        <baggage v-else-if="componentType === 'baggage'" class="anticon ui-icon"/>
        <truck v-else-if="componentType === 'truck'" class="anticon ui-icon"/>
        <achievement-baggage v-else-if="componentType === 'achievement-baggage'" class="anticon ui-icon"/>
        <achievement-truck v-else-if="componentType === 'achievement-truck'" class="anticon ui-icon"/>
    </empty-tag>
</template>

<style scoped lang='less'>
</style>
