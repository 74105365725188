import { useLoading } from '@/composables/helper/loading-helper';
import { AccountType } from '@/pages/Account/Verify/script';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountVerify = (accountType?: AccountType) => {
    const { state: { loading }, withLoading } = useLoading();

    const verify = (verificationCode: string) => withLoading(async () => {
        if (!verificationCode.trim()) throw Error('no verification code.');

        if (accountType === 'MAIN_ACCOUNT') {
            return await accountApi.verify({ verificationCode });
        } else if (accountType === 'ADDITIONAL_ACCOUNT') {
            return await accountApi.verifyForAdditionalAccount({ verificationCode });
        }
        throw Error('accountType is not specified.');
    });

    return {
        state: {
            loading,
        },
        verify,
    };
};
