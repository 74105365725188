import { computed, onBeforeMount } from 'vue';
import _ from 'lodash';
import {
    useDeliveryOrderRegister,
    useDeliveryOrderExist,
    useDeliveryOrderFind,
    useDeliveryOrderSend,
    useDeliveryOrderRevise,
    useDeliveryOrderConfirm,
    useDeliveryOrderConfirmReply,
} from '@/composables/delivery-order';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useRoute } from 'vue-router/composables';
import { Util } from '@/util';
import { useRouting } from '@/composables/helper/routing';
import { usePartnerCompanyProfile } from '@/composables/company-profile';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useDeliveryOrderPrintSave } from '@/composables/global/delivery-order-print';
import { DeliveryOrderPrintModel } from '@/models/delivery-order';
import { CompanyProfile } from '@/models/company';
import { useModal } from '@/composables/helper/modal-helper';

export const useDeliveryOrderPreviewHelper = () => {
    const { state: { loading: findLoading, deliveryOrder }, find: findDeliveryOrder } = useDeliveryOrderFind();
    const {
        state: { loading: existLoading, deliveryOrderId: existingDeliveryOrderId },
        exist
    } = useDeliveryOrderExist();
    const { state: { form: deliveryOrderRegisterForm }, register: registerDeliveryOrder } = useDeliveryOrderRegister();
    const { state: { loading: sendLoading }, send: sendDeliveryOrder } = useDeliveryOrderSend();
    const { state: { loading: reviseLoading }, revise: reviseDeliveryOrder } = useDeliveryOrderRevise();
    const { state: { loading: confirmLoading }, confirm: confirmDeliveryOrder } = useDeliveryOrderConfirm();
    const { state: { loading: confirmReplyLoading }, confirmReply } = useDeliveryOrderConfirmReply();
    const { state: { myProfile: myCompanyProfile } } = useCompanyMyProfile();
    const {
        state: { loading: partnerCompanyProfileLoading, profile: partnerCompanyProfile },
        load: loadPartnerCompanyProfile
    } = usePartnerCompanyProfile();
    const { state: { profile } } = useAccountMyProfile();
    const { saveItem: savePrintDocument } = useDeliveryOrderPrintSave();
    const { goToDeliveryOrderEdit, goToDeliveryOrderPrint, goToDeliveryOrderReply } = useRouting();
    const modal = useModal();
    const route = useRoute();
    const agreementId = Number(route.params.agreementId);

    const loading = computed(() =>
        findLoading.value ||
        existLoading.value ||
        confirmLoading.value ||
        confirmReplyLoading.value ||
        partnerCompanyProfileLoading.value
    );

    /**
     * 依頼書を送信中かを判定します。
     */
    const sending = computed(() => sendLoading.value || reviseLoading.value);

    /**
     * 荷主企業が依頼書を見ているかを判定します。
     */
    const isBaggageCompany = computed(() =>
        Util.requireNotNull(profile.value).companyId === deliveryOrder.value?.baggageCompanyId);

    /**
     * 運送企業が依頼書を見ているかを判定します。
     */
    const isTransportCompany = computed(() =>
        Util.requireNotNull(profile.value).companyId === deliveryOrder.value?.truckCompanyId);

    /**
     * 依頼書が送信済みかを判定します。
     */
    const isSent = computed(() => !_.isEmpty(deliveryOrder.value?.lastSentTm) ?? false);

    /**
     * 車番を返信済みかを判定します。
     */
    const isReplied = computed(() => !_.isEmpty(deliveryOrder.value?.lastRepliedTm) ?? false);

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 依頼書送信確認モーダルを表示します。
     */
    const confirmSend = (): Promise<boolean> => modal.confirm(
        '依頼書を送信してもよろしいですか？',
        '',
        '送信',
        'キャンセル'
    );

    /**
     * 編集ボタン
     */
    const onClickEditButton = () => goToDeliveryOrderEdit(Util.requireNotNull(deliveryOrder.value).id);

    /**
     * 送信ボタン
     */
    const onClickSendButton = async () => {
        if (!await confirmSend()) return;

        const deliveryOrderId = Util.requireNotNull(deliveryOrder.value).id;
        if (deliveryOrder.value?.status.isAlreadySent) {
            await reviseDeliveryOrder(deliveryOrderId).then(
                () => {
                    findDeliveryOrder(deliveryOrderId).then(r => null);
                },
            );
        } else {
            await sendDeliveryOrder(deliveryOrderId).then(
                () => {
                    findDeliveryOrder(deliveryOrderId).then(r => null);
                },
            );
        }
    };

    /**
     * 車番を返信ボタン
     */
    const onClickReplyButton = () => goToDeliveryOrderReply(Util.requireNotNull(deliveryOrder.value).id);

    /**
     * 印刷ボタン
     */
    const onClickPrintButton = () => {
        // 印刷ページを表示したときに印刷用のCSSを正しく適用するため、印刷内容をGlobalStateに保存する
        savePrintDocument(
            new DeliveryOrderPrintModel(
                Util.requireNotNull(deliveryOrder.value),
                myCompanyProfile.value as CompanyProfile,
                partnerCompanyProfile.value as CompanyProfile
            )
        );

        goToDeliveryOrderPrint(Util.requireNotNull(deliveryOrder.value).id).then(r => null);
    };

    /**
     * 閉じるボタン
     */
    const onClickCloseButton = () => window.close();

    /**
     * 処理化処理
     */
    const initialize = async () => {
        await Promise.all([
            exist(agreementId),
            loadPartnerCompanyProfile(agreementId)
        ]);

        // 依頼書が作成済なら取得
        if (existingDeliveryOrderId?.value?.id) {
            await findDeliveryOrder(Util.requireNotNull(existingDeliveryOrderId.value.id));
            // 既読処理
            readDeliveryOrder().then(r => null);
            return;
        }

        // 依頼書が未作成であれば作成&Load
        deliveryOrderRegisterForm.value = {
            agreementId,
            staffName: Util.requireNotNull(profile.value).name,
        };
        const registeredDeliveryOrderId = await registerDeliveryOrder();
        await findDeliveryOrder(registeredDeliveryOrderId);
    };

    /**
     * 送信された依頼書または車番返信を既読にします。
     */
    const readDeliveryOrder = async () => {
        if (isTransportCompany.value && deliveryOrder.value?.status.isSent) {
            await confirmDeliveryOrder(deliveryOrder.value.id);
        }
        if (isBaggageCompany.value && deliveryOrder.value?.status.isReplied) {
            await confirmReply(deliveryOrder.value.id);
        }
    };

    onBeforeMount(() => initialize());

    return {
        loading,
        sending,
        isBaggageCompany,
        isSent,
        isReplied,
        deliveryOrder,
        myCompanyProfile,
        partnerCompanyProfile,
        onClickEditButton,
        onClickSendButton,
        onClickReplyButton,
        onClickPrintButton,
        onClickCloseButton,
    };
};
