import { useMyTruck, useMyTruckRegister } from '@/composables/truck';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestionRemove,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import { computed, nextTick, onBeforeMount } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { useLoading } from '@/composables/helper/loading-helper';
import { useFormModel } from '@/composables/form-helper';
import { useModal } from '@/composables/helper/modal-helper';
import { TruckRegisterFormValidatableModel } from '@/models/truck';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useMessage } from '@/composables/helper/page-helper';
import { useFindCompanyTruck } from '@/composables/company-truck';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useAgreement } from '@/composables/agreement';

export const useTruckRegisterHelper = (sourceTruckId?: number, companyTruckId?: number, sourceAgreementId?: number) => {
    const { state: { truck, loading: loadingTruck }, load: loadMyTruck } = useMyTruck();
    const {
        state: { form, formValidateRules, dirty },
        initForm,
        initFormFromCompanyTruck,
        initFormFromAgreement,
        register,
        initStaffName,
        initCircleId,
    } = useMyTruckRegister();
    const { state: { suggestionList }, load: loadStaffNameSuggestions } = useCompanyStaffNameSuggestions();
    const { register: registerStaffNameSuggestions } = useCompanyStaffNameSuggestionRegister();
    const { remove: removeStaffNameSuggestions } = useCompanyStaffNameSuggestionRemove();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const { state: { profile: accountMyProfile }, load: loadAccountMyProfile } = useAccountMyProfile();
    const { state: { loading: loadingTruckRegister }, withLoading } = useLoading();
    const { formModel, submit } = useFormModel();
    const message = useMessage();
    const { goToTruckRegister, goToTruckList } = useRouting();
    const { state: { companyTruck, loading: loadingCompanyTruck }, find: findCompanyTruck } = useFindCompanyTruck();
    const { state: { agreement }, load: loadAgreement } = useAgreement();
    const circleOptions = computed(() => myProfile.value?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));
    const isMemberOfSingleCircle = computed(() =>
        myProfile.value?.circles?.length === 1
    );

    const modal = useModal();

    const loading = computed<boolean>(() =>
        [loadingTruck, loadingTruckRegister, loadingCompanyTruck].some(each => each.value)
    );

    const cols = {
        first: {
            labelCol: { sm: 6, lg: 10 },
            wrapperCol: { sm: 18, lg: 14 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                sm: { offset: 6, span: 18 },
                lg: { offset: 0, span: 24 },
            },
        },
    };

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 入力内容クリア確認モーダルを表示します。
     */
    const confirmClearForm = (): Promise<boolean> => modal.confirm(
        '入力内容を削除してよろしいですか？',
        '',
        '削除',
        'キャンセル');

    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');

    // ======================================================
    // Event
    // ======================================================
    /**
     * 初期化します。
     */
    const initialize = async (): Promise<void> => {
        let isIllegalQueryParams = false;

        // コピー
        if (sourceTruckId) {
            // `sourceTruckId`に不正なものが含まれている場合は、クエリパラメーターをリセット
            await loadMyTruck(sourceTruckId).catch(() => isIllegalQueryParams = true);
            if (truck.value) {
                initForm(truck.value);
                nextTick(async () => {
                    formModel.value?.validate(() => {
                        // callbackがないとerrorとなるため、empty functionを入れる
                    });
                });
            }
        }

        // 車両マスタからの空車登録
        if (companyTruckId) {
            await findCompanyTruck(companyTruckId).catch(() => isIllegalQueryParams = true);
            if (companyTruck.value) {
                initFormFromCompanyTruck(companyTruck.value);
            }
        }

        // 成約からの空車登録
        if (sourceAgreementId) {
            await loadAgreement(sourceAgreementId).catch(() => isIllegalQueryParams = true);
            if (agreement.value) {
                initFormFromAgreement(agreement.value);
            }
        }

        if (isIllegalQueryParams) {
            goToTruckRegister().then(r => null);
        }

        // ログインユーザー名を担当者の初期値とする
        await loadAccountMyProfile();
        if (!myProfile.value) await loadMyProfile();
        initStaffName(accountMyProfile.value?.name ?? '');
        // 部屋は一つしかない場合に設定する
        if (isMemberOfSingleCircle.value) initCircleId(myProfile.value?.circles?.[0]?.id);

        // 担当者名候補リスト
        await loadStaffNameSuggestions();
    };
    /**
     * 登録ボタンが押下された際に呼び出されます。
     */
    const onSubmit = () => submit(async () => withLoading(async () => {
        const onSuccess = async (truckId: number) => {
            message.success('空車情報を登録しました。');
            return goToTruckList();
        };
        // // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        registerStaffNameSuggestions(form.value.staffName).catch(() => ({}));
        // 更新
        await register().then(onSuccess);
    }));

    /**
     * クリアボタンが押下された際に呼び出されます。
     */
    const onClickClear = async () => {
        if (!await confirmClearForm()) return;
        form.value = new TruckRegisterFormValidatableModel();
    };

    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     * @param staffName
     */
    const onDeleteStaffNameSuggestion = async (staffName: string) => {
        await removeStaffNameSuggestions(staffName)
            .then(loadStaffNameSuggestions);
    };

    onBeforeMount(() => initialize());

    onBeforeRouteLeave((_to, _from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        formModel,
        form,
        formValidateRules,
        cols,
        suggestionList,
        circleOptions,
        onSubmit,
        onClickClear,
        onDeleteStaffNameSuggestion,
    };
};
