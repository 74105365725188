<script setup lang="ts">
import AccountTrial from '@/_components/account/AccountTrial.vue';
import AccountRegisterForm from '@/_components/account/AccountRegisterForm.vue';
import MissionLayout from '@/_components/ui/layouts/MissionLayout.vue';
import { computed } from 'vue';
import { useAccountRegisterHelper } from '@/_pages/Account/Register/account-register-helper';

type FormStyle = string | 'nejp_trial';

interface Props {
    formStyle: FormStyle,
}
const props = withDefaults(defineProps<Props>(), {
    formStyle: '',
});
const isServiceSiteFormStyle = computed(() => props.formStyle === 'nejp_trial');
const {
    loading,
    form,
    onSubmit,
} = useAccountRegisterHelper();
</script>

<template>
    <section>
        <!-- ne.jpのお試しフォームのスタイル -->
        <account-trial v-if="isServiceSiteFormStyle">
            <account-register-form v-model="form"
                                   :loading="loading"
                                   :theme="isServiceSiteFormStyle ? 'nejp' : 'com'"
                                   @submit="onSubmit" />

        </account-trial>
        <!-- com -->
        <mission-layout v-else>
            <h2 class="page-title" v-if="!isServiceSiteFormStyle">14日間無料お試し申し込みフォーム</h2>
            <account-register-form v-model="form"
                                   :loading="loading"
                                   :theme="isServiceSiteFormStyle ? 'nejp' : 'com'"
                                   @submit="onSubmit" />
        </mission-layout>
    </section>

</template>

<style scoped lang="less">
.page-title {
    margin: 12px 0 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: @heading-color;
}
</style>
