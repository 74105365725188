import { PageInfo } from '@/components/UI/PaginationControl/types';
import { useBaggageRecentList } from '@/composables/baggage-recent-list';
import { usePagination } from '@/composables/global/pagination';
import { useDocumentTitle } from '@/composables/helper/document-helper';
import { useGtm } from '@/composables/helper/gtm-helper';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { BAGGAGE_DRAWER_PROVIDER_KEY, useBaggageDrawerProvider } from '@/composables/provider/baggage-drawer-provider';
import { BAGGAGE_SEARCH_PROVIDER_KEY, useBaggageSearchProvider } from '@/composables/provider/baggage-search-provider';
import { Const } from '@/const';
import { computed, onBeforeMount, provide, ref, watch } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Route } from 'vue-router/types/router';
import _ from 'lodash';

/**
 * 最近見た荷物ページに必要なデータ・機能を集約して提供する関数
 */
export const useBaggageRecentHelper = () => {
    /* 最近見た荷物一覧 */
    const baggageRecentListStore = useBaggageRecentList();
    const { state: { baggageList } } = baggageRecentListStore;
    /* 荷物検索テーブルProvider */
    const baggageSearchProvider = useBaggageSearchProvider(baggageRecentListStore);
    provide(BAGGAGE_SEARCH_PROVIDER_KEY, baggageSearchProvider);
    const { state: { favoriteIdList }, load: loadList, changeReadMarkIdList, changeFavoriteMarkIdList, selectBaggage, changeNegotiation } = baggageSearchProvider;
    /* 荷物詳細ドロワー用Provider */
    const baggageDetailProvider = useBaggageDrawerProvider();
    provide(BAGGAGE_DRAWER_PROVIDER_KEY, baggageDetailProvider);
    const { state: { baggageFavorite, baggageUnderNegotiation } ,load: loadBaggage, clear: clearBaggage, markRead, changeFavorite } = baggageDetailProvider;

    //
    // helper
    //
    const { currentRoute } = useRoute();
    const { goToNotFound, replaceQuery } = useRouting();

    const { state: { pageSize } } = usePagination();
    const { setTitle } = useDocumentTitle();
    const { gtm } = useGtm();

    //
    // ページ固有の変数
    //
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);

    //
    // computed values
    //
    /**
     * 現在選択中の荷物ID
     */
    const currentBaggageId = computed<number | undefined>({
        get: () => {
            const id = currentRoute.query?.baggageId;
            return id ? Number(id) : undefined;
        },
        set: async (value) => {
            if (routeUpdating.value || currentBaggageId.value === value) return;
            // URLクエリーの更新をリクエスト。onBeforeMountに失敗したら更新されない。
            await replaceQuery(value ? { baggageId: `${ value }` } : {});
        },
    });

    /**
     * ドロワー表示有無
     */
    const drawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && currentBaggageId.value !== undefined;
    });

    const pageInfo = computed<PageInfo>(() => {
        return {
            totalPageCount: baggageList.value.totalPageCount ?? 0,
            totalRecordCount: baggageList.value.totalRecordCount ?? 0,
            currentPage: baggageList.value.currentPageNumber ?? 1,
            currentPageSize: baggageList.value.pageSize ?? Const.DEFAULT_PAGE_SIZE,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });

    const availablePageSize = computed<number>(() => {
        return pageSize.value && pageInfo.value.pageSizeOptions.includes(pageSize.value?.toString() ?? '') ? pageSize.value : Const.DEFAULT_PAGE_SIZE;
    });

    //
    // watch
    //
    // baggageIdが変わったときの処理
    // baggageIdが変化した = URLが変わった = 正常に画面遷移が完了した
    watch(currentBaggageId, async (newValue, _oldValue) => {
        const pageTitle = ['最近見た荷物'];
        if (newValue) {
            pageTitle.unshift('荷物詳細');
            // 既読に変更
            await markRead(newValue);
        }
        // 一覧の選択状態に反映
        selectBaggage(newValue);
        // ブラウザのtitleを変更
        setTitle(pageTitle);
        // GTMへページビューイベントを送信
        gtm.setPage(currentRoute.fullPath, pageTitle.join(Const.PAGE_TITLE_SEPARATOR));
    }, { immediate: true });

    // お気に入り保存済み状態を同期
    watch(baggageFavorite, (newValue) => {
        if (!_.isNil(newValue)) {
            changeFavoriteMarkIdList(newValue.baggageId, newValue.favorite);
        }
    });
    // 商談中状態を同期
    watch(baggageUnderNegotiation, (newValue) => {
        if (!_.isNil(newValue)) {
            changeNegotiation(newValue.baggageId, newValue.underNegotiation);
        }
    });
    watch(favoriteIdList, async (_newIdList) => {
        if (currentBaggageId.value) {
            await changeFavorite();
        }
    });

    //
    // UIのイベントに対応する関数
    //
    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    const onChangePage = async (param: { pageNo: number, pageSize: number }): Promise<void> => {
        await loadList(param.pageNo, param.pageSize);
        pageSize.value = param.pageSize;
    };

    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    const onClickCloseBaggageDetailDrawer = () => {
        currentBaggageId.value = undefined;
    };

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    const onClickGround = async () => {
        // 連打によるルーティングの多重実行を防止
        if (routeUpdating.value) return;
        if (drawerVisibility.value) {  // ドロワー表示中 = 荷物情報がロード済み
            onClickCloseBaggageDetailDrawer();
        }
    };

    /**
     * 荷物一覧の選択中荷物が変更されると呼び出されます。
     */
    const onSelectedBaggageId = (baggageId: number | undefined) => {
        currentBaggageId.value = baggageId;
    };

    //
    // LifeCycle hooks
    //
    onBeforeMount(async () => {
        const initialPageSize = availablePageSize.value;
        await loadList(1, initialPageSize);
        if (currentBaggageId.value) {
            try {
                await loadBaggage(currentBaggageId.value);
                selectBaggage(currentBaggageId.value);
                changeReadMarkIdList(currentBaggageId.value, true);
            } catch {
                await goToNotFound();
            }
        }
    });

    // クエリーが変化した後にロードだと、GTMイベントの発火タイミングなどの都合が悪いので、
    // クエリー変化時の処理が成功したときのみURLを変化させる
    onBeforeRouteUpdate(async (to, from, next) => {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (routeUpdating.value) {
            next(false);
            return;
        }
        const parseBaggageId = (route: Route): number | undefined =>
            route.query.baggageId ? Number(route.query.baggageId) : undefined;

        const toBaggageId = parseBaggageId(to);

        // toがない場合、一覧に戻る
        if (!toBaggageId) {
            await clearBaggage();
            next();
            return;
        }
        // ドロワー表示 or 更新
        routeUpdating.value = true;
        try {
            await loadBaggage(toBaggageId);
            // 一覧の既読状態を変更する
            changeReadMarkIdList(toBaggageId, true);
            next();
        } catch {
            next(false);
        } finally {
            routeUpdating.value = false;
        }
    });

    return {
        currentBaggageId,
        drawerVisibility,
        pageInfo,
        onChangePage,
        onClickGround,
        onClickCloseBaggageDetailDrawer,
        onSelectedBaggageId,
    };
};
