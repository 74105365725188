import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Const } from '@/const';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

@Component
export default class AgreementDeparture extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get departure(): string {
        if (!this.agreement) return '';
        return (
            DeliveryDateTimeRange.of(this.agreement.departureMin, this.agreement.departureMax)?.format(
                Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT
            ) ?? ''
        );
    }

    get departureCr(): string {
        if (!this.agreementCr) return '';
        return (
            DeliveryDateTimeRange.of(this.agreementCr.departureMin, this.agreementCr.departureMax)?.format(
                Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT
            ) ?? ''
        );
    }

    get changeRequest(): string {
        return this.departure != this.departureCr ? this.departureCr : '';
    }
}
