import { useModal } from '@/composables/helper/modal-helper';

export const useConfirmLeavingPage = () => {
    const { confirmWithOptions } = useModal();

    // true - ページを離脱する
    // false - ページにとどまる
    const confirm = async () => await confirmWithOptions({
        title: '入力途中の項目があります。画面を移動すると入力中の内容は失われます。よろしいですか？',
        okText: '編集を続ける',
        cancelText: '編集をやめる',
        keyboard: false,
    })
        .then((result) => !result);

    return {
        confirm
    };
};
