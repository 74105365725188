import { computed, ref } from 'vue';
import {
    QuestionItem,
    QuestionItemAnswer,
    QuestionItemAnswerFormModel,
    QuestionnaireFormModel
} from '@/vuex/modules/questionnaire/types';
import { useLoading } from '@/composables/helper/loading-helper';
import { companyApi } from '@/repository/api/internal/company';
import { useNotification } from '@/composables/helper/page-helper';
import _ from 'lodash';
import { FormValidateUtil } from '@/models/validate-helper';

export const useCompanyDelete = () => {
    const notification = useNotification();
    const { state: { loading }, withLoading } = useLoading();

    const form = ref<QuestionnaireFormModel>(new QuestionnaireFormModel({ questions: [] }));
    const sourceForm = ref<QuestionnaireFormModel>(new QuestionnaireFormModel({ questions: [] }));
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const dirty = computed(() => {
        return !_.isEqual(form.value, sourceForm.value);
    });
    const initForm = (params: QuestionItem[]) => {
        const cloned = _.cloneDeep(form.value);
        const sourceCloned = _.cloneDeep(form.value);
        cloned.questions = params.map((each, index) => new QuestionItemAnswerFormModel(each, index));
        form.value = cloned;
        sourceCloned.questions = params.map((each, index) => new QuestionItemAnswerFormModel(each, index));
        sourceForm.value = sourceCloned;
    };

    const notifyFailedKey = 'DELETE_COMPANY_ERROR';

    const onError = () => {
        notification.error({
            key: notifyFailedKey,
            message: '退会ができませんでした。',
            description: '入力内容をご確認のうえ、再度お試しください。',
        });
    };

    const deleteCompany = () => withLoading(async () => {
        const questionnaire = form.value.questions.map(each => {
            return {
                id: each.question.id,
                type: each.question.type,
                answer: Array.isArray(each.answer) ? each.answer.filter((a) => a !== undefined) : each.answer,
            } as QuestionItemAnswer;
        });
        notification.close(notifyFailedKey);
        await companyApi.delete({ questionnaire })
            .catch(onError);
        sourceForm.value = _.cloneDeep(form.value);
    });

    return {
        state: {
            form,
            formValidateRules,
            loading,
            dirty,
        },
        initForm,
        deleteCompany,
    };
};
