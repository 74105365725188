<script setup lang="ts">
import { useUnsubscribeEditHelper } from '@/_pages/UnSubscribe/Edit/unsubscribe-edit-helper';
import PageLayout from '@/_components/PageLayout.vue';
import QuestionItemAnswerInput from '@/_components/ui/QuestionItemAnswerInput.vue';

const {
    contracts,
    profile,
    canUnsubscribe,
    loading,
    form,
    formValidateRules,
    formModelRef,
    validate,
    onClickBack,
    onClickConfirm,
} = useUnsubscribeEditHelper();
</script>

<template>
    <page-layout :back-icon="false">
        <div class="board-container">
            <template v-if="canUnsubscribe">
                <a-spin :spinning="loading">
                    <a-form-model ref="formModelRef" :model="form" :rules="formValidateRules" :colon="false" layout="vertical">
                        <!-- フォーム入力 -->
                        <a-card>
                            <div class="introduction">
                                <p>よりよいサービスに改善するため、以下のアンケートにご回答ください。</p>
                            </div>

                            <div v-if="form.questions && form.questions.length > 0">
                                <a-form-model-item v-for="(question, index) in form.questions"
                                                   :key="question.question.id"
                                                   :prop="question.name"
                                                   :label="question.label">
                                    <question-item-answer-input v-model="form.questions[index]" @input="validate(question.name)" />
                                </a-form-model-item>
                            </div>

                            <nav>
                                <ul class="actions">
                                    <li class="actions__item">
                                        <a-button @click="onClickBack">戻る</a-button>
                                    </li>
                                    <li class="actions__item">
                                        <a-button type="primary" @click="onClickConfirm">退会する</a-button>
                                    </li>
                                </ul>
                            </nav>
                        </a-card>
                    </a-form-model>
                </a-spin>
            </template>

            <template v-else>
                <a-card v-if="contracts && contracts.existsPremiumPlanX">
                    <a-result status="warning"
                              title="プレミアムプランの初月無料利用期間のため、退会手続きを行うことはできません。"
                              subTitle="来月以降に退会手続きを行ってください。"></a-result>
                </a-card>
                <a-card v-else-if="profile && !profile.isMainAccount">
                    <a-result status="warning"
                              title="退会手続きは、管理者の方のみ行えます。"
                              subTitle="お手数ですが、管理者の方にご依頼ください。"></a-result>
                </a-card>
            </template>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}

.actions {
    margin-top: 24px;
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;

    .actions__item + .actions__item {
        margin-left: 16px;
    }
}
</style>
