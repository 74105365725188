<script setup lang="ts">
import { Const } from '@/const';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import PageLayout from '@/_components/PageLayout.vue';
import UiPrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import UiCityInput from '@/_components/ui/CityInput.vue';
import CompanyDrawer from '@/_components/company/CompanyDrawer.vue';
import BaggageSearchDetailDrawer from '@/_components/baggage/BaggageSearchDetailDrawer.vue';
import { useCompanySearchHelper } from '@/_pages/Company/Search/company-search-helper';

const {
    form,
    companyDrawerVisibility,
    baggageDrawerVisibility,
    list,
    onClickSearch,
    onClickChangePage,
    onClickCompany,
    onClickCloseCompanyDrawer,
    onClickBaggage,
    onClickCloseBaggageDrawer,
    onClickGround,
} = useCompanySearchHelper();
</script>

<template>
    <page-layout class="container" :show-header="false" @click="onClickGround">
        <!--suppress HtmlDeprecatedAttribute 検索フォーム-->
        <a-row align="middle" type="flex" :gutter="[16, 0]" class="search-form">
            <a-col flex="0 1 320px">
                <!-- 「1word 30文字以内の制限を課す意図」だが「パラメーターそのものを最大30文字にしてしまえば概ねOK」ってことにしてある -->
                <a-input placeholder="キーワード 例：トラボックス 0876"
                         :max-length="30"
                         @pressEnter="onClickSearch"
                         v-model="form.keyword">
                </a-input>
            </a-col>
            <a-col flex="0 1 320px">
                <a-input-group compact>
                    <ui-prefecture-select style="width: 40%"
                                          :multiple="false"
                                          placeholder="都道府県"
                                          title=""
                                          :allowClear="true"
                                          v-model="form.prefectureCode"/>
                    <ui-city-input style="width: 60%"
                                   placeholder="市区町村"
                                   :pref-code="form.prefectureCode"
                                   v-model="form.city"></ui-city-input>
                </a-input-group>
            </a-col>
            <a-col flex="0 0 auto">
                <a-button type="primary" icon="search" @click="onClickSearch">検索</a-button>
            </a-col>
            <a-col flex="1 1 320px" class="link">
                <a :href="Const.externalPageUrl.guaranteeAppliedCompanies" target="_blank" rel="noopener">
                    <tbx-link-text type="external">運賃全額保証サービス履行による強制退会企業</tbx-link-text>
                </a>
            </a-col>
        </a-row>

        <!-- ページネーション -->
        <a-row type="flex" justify="end" class="pagination">
            <a-pagination :current="list.currentPageNumber"
                          :total="list.totalRecordCount"
                          :page-size="form.pageSize"
                          :show-less-items="true"
                          :hide-on-single-page="true"
                          @change="onClickChangePage"
                          :show-size-changer="false"></a-pagination>
        </a-row>

        <!-- 検索結果 -->
        <div class="result-list">
            <a-config-provider>
                <a-list :grid="{ gutter: 16, lg: 2, xl: 3, xxl: 4 }"
                        :data-source="list.data"
                        v-if="list">
                    <template #renderItem="item">
                        <a-list-item>
                            <a-card :hoverable="true"
                                    @click.stop="onClickCompany(item.id)"
                                    class="company-card"
                                    :body-style="{ padding: '16px 24px 16px 16px', height: '100px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)' }">
                                <div class="company-card__name">{{ item.name.kanji }}</div>
                                <div class="company-card__prefecture">{{ item.location.prefecture.label }}</div>
                            </a-card>
                        </a-list-item>
                    </template>
                </a-list>

                <template #renderEmpty>
                    <a-card :bordered="false"
                            :style="{ padding: '0', margin: 'auto' }"
                            :bodyStyle="{ padding: '64px' }">
                        <img src="/img/ic-company-search.svg" alt="">
                        <div style="text-align: left; margin-top: 32px">
                            <p>「トラボックス」と入れると、企業名に「トラボックス」が含まれている企業がすべて表示されます。</p>
                            <p>「0876」と入れると、電話番号や住所(郵便番号)に「0876」が含まれているすべての企業が表示されます。</p>
                        </div>
                    </a-card>
                </template>
            </a-config-provider>
        </div>

        <!-- ページネーション -->
        <a-row type="flex" justify="end" class="pagination">
            <a-pagination :current="list.currentPageNumber"
                          :total="list.totalRecordCount"
                          :page-size="form.pageSize"
                          :show-less-items="true"
                          :hide-on-single-page="true"
                          @change="onClickChangePage"
                          :show-size-changer="false"></a-pagination>
        </a-row>

        <!-- 企業情報ドロワー -->
        <company-drawer :visible="companyDrawerVisibility"
                        @close="onClickCloseCompanyDrawer"
                        @clickBaggage="onClickBaggage"/>

        <!-- 荷物詳細ドロワー -->
        <baggage-search-detail-drawer :visible="baggageDrawerVisibility"
                                      :mask="true"
                                      :visible-exclude-company="false"
                                      @close="onClickCloseBaggageDrawer"/>
    </page-layout>
</template>

<style scoped lang="less">
.container {
    // ドロワー外クリック領域を画面いっぱいに広げるため
    height: 100%;
}

.board-container {
    padding: 16px 16px 32px;
}

.search-form {
    flex-wrap: nowrap;
    padding: @padding-md @padding-lg;
    background-color: @component-background;

    .link {
        display: flex;

        > * {
            margin-left: auto;
        }
    }
}

.pagination {
    padding: @padding-lg @padding-lg 0 @padding-lg;
}

.result-list {
    padding: @padding-lg;
}

.company-card {
    position: relative;

    &--selected {
        box-shadow: 0 0 0 2px @color-trabox-green-8;
    }

    &__name {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: @heading-color;
        max-height: 48px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    &__prefecture {
        position: absolute;
        right: 16px;
        bottom: 16px;
        text-align: right;
        font-weight: bold;
        font-size: 14px;
        color: @color-trabox-green-8;
    }
}
</style>
