import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementArrivalLocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get arrivalLocation(): string {
        if (!this.agreement) return '';
        const array = [
            this.agreement.arrivalPref.label,
            this.agreement.arrivalCity,
            this.agreement.arrivalAddress ?? '',
        ];
        return array.join('');
    }

    get arrivalLocationCr(): string {
        if (!this.agreementCr) return '';
        const array = [
            this.agreementCr.arrivalPref.label,
            this.agreementCr.arrivalCity,
            this.agreementCr.arrivalAddress ?? '',
        ];
        return array.join('');
    }

    get changeRequest(): string {
        return this.arrivalLocation != this.arrivalLocationCr ? this.arrivalLocationCr : '';
    }
}
