import _ from 'lodash';

const negotiationTypeEnumCodes = ['TEL', 'ONL'] as const;

/**
 * 商談タイプのEnum
 */
export type NegotiationTypeEnumCode = (typeof negotiationTypeEnumCodes)[number];

export function isNegotiationTypeCode(code: string): code is NegotiationTypeEnumCode {
    return _.includes(negotiationTypeEnumCodes, code);
}

export class NegotiationTypeEnum {
    constructor(public code: NegotiationTypeEnumCode, public label: string) {}

    static Phone = new NegotiationTypeEnum('TEL', '電話で受付（従来通り）');
    static Online = new NegotiationTypeEnum('ONL', 'オンラインで受付（ベータ版）');

    static valueOf = (code?: string): NegotiationTypeEnum | undefined =>
        NegotiationTypeEnum.values.find((value) => value.code === code);

    static values = [NegotiationTypeEnum.Phone, NegotiationTypeEnum.Online];

    static codeOf = (code: NegotiationTypeEnumCode): NegotiationTypeEnum => {
        switch (code) {
            case 'TEL': return NegotiationTypeEnum.Phone;
            case 'ONL': return NegotiationTypeEnum.Online;
        }
    };
}
