import { computed, nextTick, onMounted, ref } from 'vue';
import { Baggage, NewBaggageUpdateFormModel as BaggageUpdateFormModel } from '@/models/baggage';
import { useFormModel } from '@/composables/form-helper';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import {
    buildFormModel,
    buildValidationRules,
    convertToApiForm,
    useBaggageUpdate,
} from '@/composables/baggage-update';
import { useBaggageInputViewModel } from '@/composables/baggage-input-view-model';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useKarte } from '@/composables/helper/karte-helper';
import { CompanyMyProfile } from '@/models/company';
import { useCircleLabelRegister, useCircleLabels } from '@/composables/circle-label';

export const useBaggageEditorHelper = (
    baggage: Baggage,
    companyProfile: CompanyMyProfile,
    emitDirty: () => void,
    stateChanged: (state: 'editing' | 'updating' | 'finished') => void,
) => {
    const {
        state: { suggestionList: staffNameSuggestionList },
        load: loadStaffNameSuggestions
    } = useCompanyStaffNameSuggestions();
    const { state: { labelList }, load: loadCircleLabels, clear: clearCircleLabels } = useCircleLabels();
    const { register: registerCircleLabel } = useCircleLabelRegister();
    const {
        update: updateBaggage
    } = useBaggageUpdate();
    const { register: registerStaffName } = useCompanyStaffNameSuggestionRegister();
    const { formModel, submit } = useFormModel();
    const message = useMessage();
    const notification = useNotification();
    const { Karte } = useKarte();
    const circleOptions = computed(() => companyProfile?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));

    const model = ref(buildFormModel(baggage));

    const validate = (props: (keyof BaggageUpdateFormModel)[]) => {
        nextTick(() => {
            formModel.value?.validateField(props, () => {
            });
        });
    };

    const viewModel = useBaggageInputViewModel(
        model,
        baggage,
        companyProfile,
        validate,
        emitDirty,
        async () => {
            clearCircleLabels();
            if (model.value.circleId)
                await loadCircleLabels(model.value.circleId);
        }
    );

    const formValidateRules = ref(buildValidationRules(
        () => model.value,
        () => viewModel.availablePaymentDateRange.value,
        viewModel.defaultPaymentDate.value,
    ));

    const update = async () => submit(async () => {
        const notifyFailedKey = 'UPDATE_BAGGAGE_FAILED';
        notification.close(notifyFailedKey);

        // API向けオブジェクトに変換
        const formToPost = convertToApiForm(model.value);

        if (formToPost === undefined) {
            // Note: 理論上ここへ入ることはないけど、もうちょっと良い方法があると思う。
            return;
        }

        try {
            stateChanged('updating');

            // 荷物更新
            await updateBaggage(baggage.id, formToPost);

            // 担当者登録
            // 例外はもみ消す
            await registerStaffName(model.value.staffName).catch(() => {
            });
            if (model.value.circleId && model.value.label.labelText && model.value.label.labelColor)
                registerCircleLabel(model.value.circleId, {
                    labelText: model.value.label.labelText,
                    labelColor: model.value.label. labelColor
                }).catch(() => {});

            message.success('荷物情報を変更しました。');
            Karte.trackEditBaggage(baggage.id);

            stateChanged('finished');
        } catch {
            stateChanged('editing');

            notification.error({
                key: notifyFailedKey,
                message: '荷物情報の変更ができませんでした。',
                description: '入力内容をご確認のうえ、もう一度お試しください。',
            });
        }
    });

    onMounted(async () => {
        await loadStaffNameSuggestions();
        if (baggage.circle?.id) await loadCircleLabels(baggage.circle.id);
    });

    return {
        model,
        viewModel,
        formValidateRules,
        formModel,
        staffNameSuggestionList,
        circleOptions,
        labelList,
        update,
    };
};
