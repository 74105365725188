import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/agreement/types';
import { Util } from '@/util';

@Component
export default class AgreementHighwayFareRegister extends Vue {
    validationRules: Array<ValidationRule> = [
        {
            pattern: /^[0-9０-９]*$/,
            message: '高速代は数字で入力してください。',
        },
        {
            transform: (value: number | string): string =>
                typeof value === 'number' ? `${value}` : Util.toDigits(value),
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule: ValidationRule, value: number, callback: Function) =>
                this.validate(value, callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementForm;

    /**
     * 高速代
     */
    get highwayFare(): number | string {
        if (!this.value) return '';
        const highwayFare = this.value.highwayFare;
        return highwayFare !== 0 ? `${highwayFare}` : '';
    }

    set highwayFare(newValue: number | string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 高速代を書き換え
        cloned.highwayFare = newValue;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 高速代支払い有無
     */
    get noHighwayFare(): boolean {
        return !(this.value?.highwayFareFlg ?? false);
    }

    set noHighwayFare(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 高速代支払い有無を書き換え
        cloned.highwayFareFlg = !newValue;
        // 高速代を支払わない場合、高速代をゼロにする
        if (newValue) {
            cloned.highwayFare = 0;
        }
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 高速代のフォーカスが外れた際に呼び出されます。
     */
    onBlurHighwayFare(): void {
        const parsed = Util.toNumber(Util.parseFreightString(this.highwayFare));
        this.highwayFare = isNaN(parsed) ? 0 : parsed;
    }

    /**
     * バリデーションを行う。
     */
    private validate(value: number, callback: (message?: string) => void): void {
        if (value >= 0 && value <= Const.MAX_HIGHWAY_FARE) {
            callback();
        } else {
            callback(`高速代は${Const.MAX_HIGHWAY_FARE / 10000}万円以内で入力してください。`);
        }
    }
}
