//
// Action
//
export class ACTION {
    static readonly PREDICT_AGREEMENT_PROBABILITY = 'PREDICT_AGREEMENT_PROBABILITY';
}

//
// Store
//
export interface PredictionState {
    dummy: string;
}
