<script setup lang="ts">
import { computed } from 'vue';
import moment from 'moment';
import { DateValue } from '@/models/vo/date';

interface Props {
    placeholder?: string,
    format?: 'YYYY-MM' | 'YYYY年M月',
    valueFormat?: string,
    value?: DateValue,
    disabledDate?: (currentDate: DateValue) => boolean
}

const props = withDefaults(defineProps<Props>(), {
    placeholder: '',
    format: 'YYYY-MM',
    valueFormat: 'YYYY-MM-DD',
    disabledDate: () => false,
});

const emits = defineEmits<{ (e: 'input', selectedDate: DateValue | undefined) : void }>();

const selectedDate = computed({
    get: () => props.value ? props.value.format() : undefined,
    set: (value: string | undefined) => emits('input',
        value ? new DateValue(value) : undefined),
});

const disabledDateByMoment = (currentDateAsMoment: moment.Moment): boolean =>
    props.disabledDate(new DateValue(currentDateAsMoment.format('YYYY-MM-01')));

</script>

<template>
    <a-month-picker :placeholder="placeholder"
                    :format="format"
                    :valueFormat="valueFormat"
                    v-model="selectedDate"
                    :disabled-date="disabledDateByMoment"/>
</template>

<style scoped lang="less"></style>
