import { CreateElement, VNode } from 'vue';
import { Icon } from 'ant-design-vue';

export class ModalUtil {
    /**
     * 削除確認アイコンを生成します。
     */
    static createConfirmDeletionIcon(h: CreateElement): VNode {
        return h(Icon, { attrs: { type: 'exclamation-circle', style: 'color: #eb5757' } });
    }

    /**
     * 登録/追加確認アイコンを生成します。
     */
    static createConfirmRegistrationIcon(h: CreateElement): VNode {
        return h(Icon, { attrs: { type: 'exclamation-circle', style: 'color: #16856e' } });
    }
}
