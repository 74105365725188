<script lang="ts" setup>
import { computed, VNode } from 'vue';
import _ from 'lodash';

const props = withDefaults(defineProps<{
    value: {
        labelText?: string;
        labelColor?: string;
    },
    labelList: { id: number, labelText: string, labelColor: string }[];
    showDelete: boolean;
}>(), {});

const emits = defineEmits<{
    (e: 'input', value: { labelText?: string, labelColor?: string }): void,
    (e: 'deleteLabel', id: number): void,
}>();

const color = computed({
    get: () => props.value.labelColor,
    set: (value: string | undefined) => {
        if (value == '' || props.value.labelColor == value)
            value = undefined;
        const newValue = { labelText: props.value.labelText, labelColor: value };
        emits('input', newValue);
    },
});

const text = computed({
    get: () => props.value.labelText,
    set: (value: string | undefined) => {
        if (value == '')
            value = undefined;
        const newValue = { labelText: value, labelColor: value ? props.value.labelColor : undefined };
        emits('input', newValue);
    },
});

const onClick = (newColor: string) => {
    color.value = newColor;
};

const onBlur = () => {
    text.value = text.value?.trim();
};

const labelTextFilterOption = (input: string, option: VNode) => {
    const prop: { title?: string } = option.componentOptions?.propsData ?? {};
    if (!prop) {
        return false;
    }
    return _.isString(prop.title) && prop.title.toUpperCase().indexOf(input.toUpperCase()) >= 0;
};

const onSelectLabel = (value: string, option: VNode) => {
    const label = props.labelList.find(label => label.id === Number(value));
    if (!label) return;
    emits('input', { labelText: label.labelText, labelColor: label.labelColor });
};

const onClickDelete = (event: Event, id: number) => {
    event.stopPropagation();
    emits('deleteLabel', id);
};

const colors = [
    '#eb2f96',
    '#f5222d',
    '#fa8c16',
    '#52c41a',
    '#13c2c2',
    '#1890ff',
    '#722ed1'
];
</script>

<template>
    <a-space style="flex-wrap: wrap">
        <a-auto-complete :allow-clear="true"
                         :default-active-first-option="false"
                         :filter-option="labelTextFilterOption"
                         option-label-prop="title"
                         v-model="text"
                         placeholder="ラベルのテキスト"
                         style="width: 300px"
                         @select="onSelectLabel"
                         @blur="onBlur">
            <template #dataSource>
                <a-select-option v-for="label in labelList"
                                 :key="label.id"
                                 :value="`${label.id}`"
                                 :title="label.labelText">
                    <span>{{ label.labelText }}</span>
                    <a-button v-if="props.showDelete" icon="delete"
                              size="small"
                              style="float: right"
                              type="default"
                              @click="onClickDelete($event, label.id)"></a-button>
                </a-select-option>
            </template>
        </a-auto-complete>
        <div class="container">
            <div v-for="otherColor in colors" :key="otherColor"
                 :class="{ 'color-container-selected': color === otherColor }"
                 class="color-container" @click="onClick(otherColor)">
                <div :class="{ 'color-selected': color === otherColor }" :style="{ background: otherColor }"
                     class="color"></div>
            </div>
        </div>
    </a-space>
</template>

<style lang="less" scoped>
.container {
    display: flex;
    column-gap: 5px;
}

.color {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 2px;
    border-radius: 4px;
    opacity: 0.6;
    transition: opacity 0.2s;

    &.color-selected {
        opacity: 1.0;
    }
}

.color-container {
    display: inline-block;
    border-radius: 4px;
    width: 31px;
    height: 31px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border 0.2s;

    &:hover {
        border: 1px solid @primary-color;
    }

    &.color-container-selected {
        border: 1px solid @primary-color;
    }
}
</style>
