import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { Util } from '@/util';

@Component
export default class BaggageFreightView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get freight(): string {
        const freight = this.baggage?.freight ?? 0;
        return freight > 0 ? `${Util.formatNumber(freight)}円` : '要相談';
    }
}
