import { appAxios, appAxiosWithoutErrorMessage, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import {
    CompanyProfile
} from '@/models/company';
import { CircleLabel } from '@/models/circle';

export const circleApi = {
    /**
     * テナント内企業一覧を取得します。
     */
    list(circleId: number): Promise<CompanyProfile[]> {
        return appAxios.get<JsonResponseBean<CompanyProfile[]>>(`/api/circle/${circleId}/company/list`).then(isSuccess);
    },

    /**
     * 部屋ラベルを登録します。
     */
    register(circleId: number, data: { labelText: string, labelColor: string }): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/circle/${circleId}/label/register`, data).then(isSuccess);
    },
    /**
     * 部屋ラベル一覧をロードします。
     */
    loadLabels(circleId: number): Promise<CircleLabel[]> {
        return appAxiosWithoutErrorMessage
            .get<JsonResponseBean<CircleLabel[]>>(`/api/circle/${circleId}/label/list`)
            .then(isSuccess);
    },
    /**
     * 部屋ラベルを削除します。
     */
    deleteLabel(circleId: number, param: { id: number }): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/circle/${circleId}/label/delete`, param)
            .then(isSuccess);
    },
};
