import { computed, InjectionKey, ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import mitt from 'mitt';

export type TimelineResourceType = {
    Id: number,
    Name: string,
    Subtitle: string,
    Description: string,
};

type RenderedResourceType = TimelineResourceType & {
    height: number;
};

export type TimelineEventType = {
    Id: number,
    StartTime: Date,
    EndTime: Date,
    Subject: string,
    Location: string,
    Description: string,
    EventType: string,
    CompanyTruckId: number,
    ExistsRecommendation: boolean,
};

type RenderedEventType = TimelineEventType & {
    left: number;
    width: number;
    top: number;
    selected: boolean;
};

export type TimeSlot = {
    date: Dayjs;
    isDuration: boolean;
};

export type TimelineDateRangeType = { startDate: Dayjs, endDate: Dayjs };

export type TimelineSelectedCellType = { startDate: Dayjs, endDate: Dayjs, resourceId: number };

// 指定時間に対応する位置を計算する
const timePosition = (startDate: Dayjs, date: Dayjs, totalWidth: number): number => {
    const weekMinutes = 24 * 60 * 7;
    const from = startDate.unix(); // seconds
    const to = date.unix(); // seconds
    const diff = (to - from) / 60;
    return (diff / weekMinutes) * totalWidth;
};

// ブラウザーのscrollbarの幅を取得する
export const getScrollbarWidth = (): number => {
    // 要素を作成
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // スクロールバーを強制的に表示
    document.body.appendChild(outer);

    // 内側の要素を作成
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // スクロールバーの幅を計算
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // 作成した要素を削除
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
};

type TimelineSchedulerEvent = {
    // イベントをクリックした
    'EventClick': TimelineEventType;

    // イベントマッチングをクリックした
    'EventMatchingClick': number;

    // イベントがDoubleClickされた
    'EventDoubleClick': TimelineEventType;

    // スロットがクリックされた
    'CellClick': TimelineSelectedCellType;

    // スロットが選択された
    'CellSelected': TimelineSelectedCellType;

    // 縦スクロールされた
    'VerticalScrolled': number;

    // 横スクロールされた
    'HorizontalScrolled': number;

    // リサイズされた
    'Resized': void;

    // 日付が変更された
    'DateRangeChanged': TimelineDateRangeType;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTimelineScheduleProvider = () => {
    const eventBus = mitt<TimelineSchedulerEvent>();

    /////////////////////////////////////////////////////
    // スロットの設定
    //     ________________________________________________________________________
    //     | DATE                                                |  DATE + 1
    //     ---------------------------------------------------------------------
    //     | <---Duration--> | <---Duration--> | <---Duration--> |<---
    //     ---------------------------------------------------------------
    //     | <-Slot->|       |         |       |       |         |       |
    //     ---------------------------------------------------------------
    //
    const timelineDays = 7; // タイムラインの表示日数
    const duration = 720; // １日の区切りの時間幅 (minute)
    const slotCount = 2; // Durationの間に何スロットあるか
    const slotWidth = 50; // px
    const durationCount = 24 * 60 / duration; // 1日のDurationの数
    const slotCountInDate = durationCount * slotCount; // 1日のスロットの数
    const minimumResourceHeight = 81;
    const eventBoxHeight = 60;
    const timelineViewMinWidth = 1400; // タイムラインの表示領域の最小幅

    // 入力データ
    const selectedDate = ref<Dayjs>(dayjs());
    const selectedEvent = ref<TimelineEventType | undefined>(undefined);
    const eventList = ref<TimelineEventType[]>([]);
    const resourceList = ref<TimelineResourceType[]>([]);
    const resourceHeightList = ref<Record<number, number>>({});

    // タイムラインの表示領域の高さ
    // ブラウザウィンドウのサイズによって変化する
    const timelineViewHeight = ref<number>(0);

    // タイムラインの縦スクロールバーの幅
    // リソース行の高さよりウィンドウサイズが小さい場合、縦のスクロールバーが表示される。
    // このスクロールバーの幅をDateHeaderで埋めるために使用する。
    const timelineVerticalScrollBarWidth = ref<number>(0);

    // タイムラインの幅
    // ブラウザウィンドウのサイズによって変化する
    const timelineWidth = ref<number>(timelineViewMinWidth);

    // タイムラインの高さ
    const timelineHeight = computed(() => {
        return Object.values(resourceHeightList.value).reduce((sum, height) => sum + height, 0);
    });

    // タイムラインの開始日
    const timelineStartDate = computed(() => {
        return selectedDate.value.startOf('week');
    });
    // タイムラインの終了日
    const timelineEndDate = computed(() => {
        return selectedDate.value.startOf('week');
    });

    // タイムラインの現在位置
    const timelineCurrentPosition = computed(() => {
        const today = dayjs();
        if (timelineStartDate.value.add(timelineDays, 'day') < today) {
            return undefined;
        }
        return timePosition(timelineStartDate.value, dayjs(), timelineWidth.value);
    });

    // タイムライン表示対象の日付一覧
    const dateList = computed<Dayjs[]>(() => {
        const date = timelineStartDate.value;
        return Array.from({ length: timelineDays }).map((_, i) => date.add(i, 'day'));
    });

    // タイムラインの最小区切り(slot)の一覧
    const slotList = computed<TimeSlot[]>(() => {
        return dateList.value.map((date) => {
            const slotDuration = duration / slotCount; // minutes
            return Array.from({ length: slotCountInDate }).map((_, i) => {
                const d = date.add(i * slotDuration, 'minute');
                return {
                    date: d,
                    isDuration: i % slotCount === 0
                };
            });
        }).flat();
    });

    // レンダリング済みリソース一覧
    const renderedResources = computed<RenderedResourceType[]>(() => {
        return resourceList.value.map((resource, index) => {
            const height = resourceHeightList.value[index] || minimumResourceHeight;
            return {
                ...resource,
                height: height,
            };
        });
    });

    // レンダリング済みイベント一覧
    const renderedEvents = ref<RenderedEventType[]>([]);

    // イベントを配置する
    const renderEvents = () => {
        // １つのリソース行に対して、複数のイベントが並行して発生している場合は、
        // イベントが重ならないようにレーンを分けてイベントを配置する。
        const eventLaneByResource: RenderedEventType[][][] = [];

        // リソース別のイベント一覧を作成
        const eventByResource = eventList.value.reduce((groups, event: TimelineEventType) => {
            const key = event.CompanyTruckId;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(event);
            return groups;
        }, {} as Record<string, TimelineEventType[]>);

        // リソース行の高さをリセット
        resourceHeightList.value = {};
        let currentResourceTop = 0;

        // リソース行毎にイベントの配置を計算する
        resourceList.value.forEach((resource, resourceIndex) => {
            // 開始時間順にソート
            const events = eventByResource[resource.Id]?.sort((a, b) => a.StartTime.getTime() - b.StartTime.getTime());

            // イベントが存在しない場合は、レーンを作成しない
            if (!events) {
                updateResourceHeight(resourceIndex, minimumResourceHeight);
                currentResourceTop += minimumResourceHeight;
                return;
            } else {
                eventLaneByResource[resourceIndex] = renderEventsForResource(events, currentResourceTop);
                const height = eventLaneByResource[resourceIndex].length * eventBoxHeight;
                updateResourceHeight(resourceIndex, height);
                currentResourceTop += height;
            }
        });
        renderedEvents.value = eventLaneByResource.flat().flat();
    };

    // リソース行のイベントを配置する
    const renderEventsForResource = (events: TimelineEventType[], top: number) => {
        const maxLane = 20; // 1リソースあたりの最大レーン数
        const resourceLaneList: RenderedEventType[][] = [];

        // イベント毎にどのレーンに配置できるか計算し、配置する
        events.forEach((event) => {

            // eventLaneの0列目から順番に配置できるか確認する(MAXLANEまで)
            for (let i = 0; i < maxLane; i++) {
                const eventsOnLane = resourceLaneList[i];
                const laneTop = top + eventBoxHeight * i;

                // イベントレーンが存在しない場合は、レーン追加する
                if (!eventsOnLane) {
                    const pos = timePosition(timelineStartDate.value, dayjs(event.StartTime), timelineWidth.value);
                    const pos2 = timePosition(timelineStartDate.value, dayjs(event.EndTime), timelineWidth.value);
                    resourceLaneList.push([{ left: pos, width: pos2 - pos, top: laneTop, selected: false, ...event }]);
                    return;
                }

                // 時間幅が重なるイベントが存在しない場合は、そのレーンに追加
                if (eventsOnLane.every((e) => e.EndTime.getTime() <= event.StartTime.getTime())) {
                    const pos = timePosition(timelineStartDate.value, dayjs(event.StartTime), timelineWidth.value);
                    const pos2 = timePosition(timelineStartDate.value, dayjs(event.EndTime), timelineWidth.value);
                    resourceLaneList[i].push({ left: pos, width: pos2 - pos, top: laneTop, selected: false, ...event });
                    return;
                }
            }
        });
        return resourceLaneList;
    };

    // リソースの高さを更新する
    const updateResourceHeight = (resourceIndex: number, height: number) => {
        resourceHeightList.value = {
            ...resourceHeightList.value,
            [resourceIndex]: height
        };
    };

    // イベントの一覧を更新する
    const updateEventList = (newEventList: TimelineEventType[]) => {
        eventList.value = newEventList;
        renderEvents();
    };

    // リソースの一覧を更新する
    const updateResourceList = (newResourceList: TimelineResourceType[]) => {
        resourceList.value = newResourceList;
        renderEvents();
    };

    // タイムラインの幅を変更する
    const updateTimelineWidth = (width: number) => {
        timelineWidth.value = width;
        renderEvents();
    };

    // 選択日付を変更する
    const onSelectDate = (date: Dayjs) => {
        selectedDate.value = date;
        eventBus.emit('DateRangeChanged', { startDate: timelineStartDate.value, endDate: timelineEndDate.value });
    };

    // タイムラインの表示領域の高さを変更する
    const onTimelineViewResize = (height: number) => {
        timelineViewHeight.value = height;
    };

    // イベントを選択状態を解除する
    const clearSelectedEvent = () => {
        renderedEvents.value.forEach((event) => {
            event.selected = false;
        });
        selectedEvent.value = undefined;
    };

    // イベントを選択状態にする
    const setSelectedEvent = (id: number) => {
        const event = renderedEvents.value.find((e) => e.Id === id);
        if (event) {
            // 選択済みのイベントとは異なるものをクリックした場合は、選択を解除する
            if (selectedEvent.value?.Id !== event.Id) {
                clearSelectedEvent();
            }
            event.selected = !event.selected;
            selectedEvent.value = event;
        }
    };

    // イベントをクリックした場合
    let clickTimer: NodeJS.Timeout | undefined = undefined;
    const onClickEvent = (eventId: number) => {
        // clickTimerの期間中にもう一度Clickがあった場合はDoubleClickと判定する
        // それ以外はSingleClickとする
        if (clickTimer) {
            clearTimeout(clickTimer);
            clickTimer = undefined;
            setSelectedEvent(eventId);
            if (selectedEvent.value) eventBus.emit('EventDoubleClick', selectedEvent.value);
        } else {
            clickTimer = setTimeout(() => {
                setSelectedEvent(eventId);
                if (selectedEvent.value) eventBus.emit('EventClick', selectedEvent.value);
                clickTimer = undefined;
            }, 150);
        }
    };

    const onClickEventMatching = (eventId: number, event: MouseEvent) => {
        event.stopPropagation();
        eventBus.emit('EventMatchingClick', eventId);
    };

    // スロットをクリックした場合
    const onCellClick = (data: TimelineSelectedCellType) => {
        eventBus.emit('CellClick', data);
    };

    // 複数スロットを選択した場合
    const onCellSelected = (data: TimelineSelectedCellType) => {
        eventBus.emit('CellSelected', data);
    };

    return {
        state: {
            selectedDate,
            timelineStartDate,
            timelineEndDate,
            timelineCurrentPosition,
            dateList,
            minimumResourceHeight,
            renderedEvents,
            renderedResources,
            slotList,
            slotWidth,
            slotCountInDate,
            timelineViewHeight,
            timelineViewMinWidth,
            timelineWidth,
            timelineHeight,
            timelineVerticalScrollBarWidth,
            eventBus,
        },
        onSelectDate,
        layoutEvents: renderEvents,
        updateEventList,
        updateResourceList,
        updateTimelineWidth,
        onTimelineViewResize,
        onClickEvent,
        onClickEventMatching,
        onCellClick,
        onCellSelected,
    };
};

export type TimelineSchedulerProviderType = ReturnType<typeof useTimelineScheduleProvider>;
export const TIMELINE_SCHEDULER_PROVIDER_KEY: InjectionKey<TimelineSchedulerProviderType> = Symbol('TimelineSchedulerProvider');
