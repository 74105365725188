<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useAccountVerifyHelper } from '@/_pages/Account/Verify/account-verify-helper';

const props = defineProps<{
    verificationCode?: string,
}>();
const {
    loading,
    succeed,
} = useAccountVerifyHelper(props.verificationCode);
</script>

<template>
    <page-layout :show-header="false" :back-icon="false">
        <a-layout-content class="layout-container">
            <a-card class="container">
                <h1>本人確認</h1>
                <a-spin :spinning="loading">
                    <div v-if="loading">本人確認を行っています...</div>
                    <div v-else-if="succeed">
                        <p>本人確認が完了しました。</p>
                        <p>初期パスワードの設定画面に移動します。画面はそのままでお待ちください...</p>
                    </div>
                    <div v-else>
                        <p>
                            本人確認に失敗しました。<br/>
                            確認メールからもう一度アクセスしなおしてください。
                        </p>
                        <p>
                            何度試しても変わらない場合は、契約管理者にお問い合わせください。
                        </p>
                    </div>
                </a-spin>
            </a-card>
        </a-layout-content>
    </page-layout>
</template>

<style scoped lang="less">
.layout-container {
    display: flex;
    align-items: center;
}

.container {
    min-width: 600px;
    width: 50vw;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin: 16px 0 24px;
    }
}

</style>
