//
// Action
//
import {
    Agreement,
    AgreementChangeRequest, AgreementForm,
    AgreementList,
    AgreementOnlineOrderAvailability,
    AgreementPartnerInfo
} from '@/models/agreement';

export class ACTION {
    static readonly REGISTER_AGREEMENT = 'REGISTER_BAGGAGE';
    static readonly UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';
    static readonly LOAD_AGREEMENT = 'LOAD_AGREEMENT';
    static readonly CLEAR_AGREEMENT = 'CLEAR_AGREEMENT';
    static readonly LOAD_AGREEMENT_CHANGE_REQUEST = 'LOAD_AGREEMENT_CHANGE_REQUEST';
    static readonly CLEAR_AGREEMENT_CHANGE_REQUEST = 'CLEAR_AGREEMENT_CHANGE_REQUEST';
    static readonly REVOKE_AGREEMENT = 'REVOKE_AGREEMENT';
    static readonly APPROVE_AGREEMENT_CHANGE = 'APPROVE_AGREEMENT_CHANGE';
    static readonly REJECT_AGREEMENT_CHANGE = 'REJECT_AGREEMENT_CHANGE';
    static readonly CANCEL_AGREEMENT_CHANGE = 'CANCEL_AGREEMENT_CHANGE';
    static readonly LIST_AGREEMENT = 'LIST_AGREEMENT';
    static readonly LIST_ACCEPTED_AGREEMENT = 'LIST_ACCEPTED_AGREEMENT';
    static readonly LIST_MONTHLY_ACCEPTED_AGREEMENT = 'LIST_MONTHLY_ACCEPTED_AGREEMENT';
    static readonly COUNT_ACCEPTED_AGREEMENT = 'COUNT_ACCEPTED_AGREEMENT';
    static readonly CLEAR_MONTHLY_ACCEPTED_AGREEMENT = 'CLEAR_MONTHLY_ACCEPTED_AGREEMENT';
    static readonly SEARCH_AGREED_AGREEMENT = 'SEARCH_AGREED_AGREEMENT';
    static readonly SEARCH_ACCEPTED_AGREEMENT = 'SEARCH_ACCEPTED_AGREEMENT';
    static readonly TOGGLE_GUARANTEE = 'TOGGLE_GUARANTEE';
    static readonly LOAD_EXISTENCE_ONLINE_ORDER_DIRECTION = 'LOAD_EXISTENCE_ONLINE_ORDER_DIRECTION';
    static readonly CLEAR_EXISTENCE_ONLINE_ORDER_DIRECTION = 'CLEAR_EXISTENCE_ONLINE_ORDER_DIRECTION';
    static readonly LOAD_AVAILABILITY_ONLINE_ORDER_DIRECTION = 'LOAD_AVAILABILITY_ONLINE_ORDER_DIRECTION';
    static readonly CLEAR_AVAILABILITY_ONLINE_ORDER_DIRECTION = 'CLEAR_AVAILABILITY_ONLINE_ORDER_DIRECTION';
    static readonly LOAD_AGREEMENT_PARTNER_INFO = 'LOAD_AGREEMENT_PARTNER_INFO';
    static readonly CLEAR_AGREEMENT_PARTNER_INFO = 'CLEAR_AGREEMENT_PARTNER_INFO';
}

//
// Parameter for Action
//

//
// Signature for Action
//
export type registerAgreement = (param: AgreementForm) => Promise<number>;

//
// Getter
//
export class GETTER {
    static readonly AGREEMENT = 'AGREEMENT';
    static readonly AGREEMENT_LIST = 'AGREEMENT_LIST';
    static readonly AGREEMENT_CHANGE_REQUEST = 'AGREEMENT_CHANGE_REQUEST';
    static readonly MONTHLY_AGREEMENT_LIST = 'MONTHLY_AGREEMENT_LIST';
    static readonly EXISTENCE_ONLINE_ORDER_DIRECTION = 'EXISTENCE_ONLINE_ORDER_DIRECTION';
    static readonly AVAILABILITY_ONLINE_ORDER_DIRECTION = 'AVAILABILITY_ONLINE_ORDER_DIRECTION';
    static readonly AGREEMENT_PARTNER_INFO = 'AGREEMENT_PARTNER_INFO';
    static readonly ACCEPTED_AGREEMENT_COUNT = 'ACCEPTED_AGREEMENT_COUNT';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_AGREEMENT = 'SET_AGREEMENT';
    static readonly SET_AGREEMENT_LIST = 'SET_AGREEMENT_LIST';
    static readonly SET_AGREEMENT_CHANGE_REQUEST = 'SET_AGREEMENT_CHANGE_REQUEST';
    static readonly SET_MONTHLY_AGREEMENT_LIST = 'SET_MONTHLY_AGREEMENT_LIST';
    static readonly SET_EXISTENCE_ONLINE_ORDER_DIRECTION = 'SET_EXISTENCE_ONLINE_ORDER_DIRECTION';
    static readonly SET_AVAILABILITY_ONLINE_ORDER_DIRECTION = 'SET_AVAILABILITY_ONLINE_ORDER_DIRECTION';
    static readonly SET_AGREEMENT_PARTNER_INFO = 'SET_AGREEMENT_PARTNER_INFO';
    static readonly SET_ACCEPTED_AGREEMENT_COUNT = 'SET_ACCEPTED_AGREEMENT_COUNT';
}

//
// Store
//
export interface AgreementState {
    agreement?: Agreement;
    agreementList: AgreementList;
    changeRequest?: AgreementChangeRequest;
    monthlyAgreementList: AgreementList;
    existsOnlineOrderDirection: boolean;
    availableOnlineOrderDirection?: AgreementOnlineOrderAvailability;
    agreementPartnerInfo?: AgreementPartnerInfo;
    acceptedAgreementCount?: number
}

//
// Type for store
//
export * from '@/models/agreement';
