import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageStaffName extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get staffName(): string {
        if (!this.baggage) return '';

        return this.baggage.staffName;
    }
}
