<script setup lang="ts">
import SearchOutgoConditionForm from '@/_pages/Baggage/List/tabs/components/settlement/SearchOutgoConditionForm.vue';
import SettlementTable from '@/_pages/Baggage/List/tabs/components/settlement/table/OutgoTable.vue';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import { useSettlementOutgoListHelper } from './settlement-outgo-list-helper';
import SettlementDetailDrawer from '@/_pages/Baggage/List/drawers/SettlementDetail/SettlementDetailDrawer.vue';
import { PreloadProps, DetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/props';

interface Props {
    preload: PreloadProps;
    detail?: DetailProps;
}
const props = defineProps<Props>();

const {
    loading,
    form,
    payerDateList,
    list,
    pageInfo,
    settlementDrawerVisibility,
    search,
    reload,
    changePage,
    selectSettlement,
    onCloseDrawer,
} = useSettlementOutgoListHelper(props);
</script>

<template>
    <div>
        <search-outgo-condition-form v-model="form"
                                     :date-list="payerDateList"
                                     @search="search"/>
        <settlement-table :loading="loading"
                          :list="list"
                          :selected-settlement-id="detail?.settlementId"
                          @selectSettlement="selectSettlement">

            <template #header>
                <sort-pagination-control :show-sort="false" :page-info="pageInfo" @change="changePage"/>
            </template>
            <template #footer>
                <sort-pagination-control :show-sort="false" :page-info="pageInfo" @change="changePage"/>
            </template>
        </settlement-table>
        <settlement-detail-drawer :visible="settlementDrawerVisibility" type="outgo" @close="onCloseDrawer" @change="reload" />
    </div>
</template>

<style scoped lang="less">
</style>
