<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import {
    useSettlementOutgoPaper
} from '@/_pages/Baggage/List/tabs/Settlement/tabs/Paper/Outgo/settlement-outgo-paper-helper';
import { ref } from 'vue';
import TbxButton from '@/_components/ui/TbxButton.vue';

const {
    loading,
    term,
    selectedYear,
    list,
    onClickDownload,
    onClickDownloadDetail,
} = useSettlementOutgoPaper();

const activeKey = ref(['']);
</script>

<template>
    <div class="container">
        <!-- left pane -->
        <a-timeline class="section-timeline">
            <a-timeline-item v-for="year in term.list" :key="year">
                    <span @click="selectedYear = year"
                          class="section-timeline__label"
                          :class="{ 'selected': selectedYear === year }">{{ year }}年</span>
            </a-timeline-item>
        </a-timeline>

        <!-- right pane -->
        <div class="section-list">
            <a-spin :spinning="loading">
                <div class="section-list-header">
                    <div class="section-list-header__row">
                        <h2 class="component-heading-h2">{{ selectedYear }}年 請求書</h2>
                    </div>
                    <div class="component-note-xs">
                        ※未承認の取引または運送会社が未確認の取引がある場合、請求書の金額は未確定となります。ご理解の上ダウンロードをお願いします。
                    </div>
                </div>
                <div v-if="list.length === 0" class="ant-list-bordered ant-list-empty-text">
                    {{ selectedYear }}年のご利用はございません。
                </div>
                <a-collapse v-else v-model="activeKey">
                    <a-collapse-panel
                        v-for="invoice in list"
                        :key="invoice.payerDate.format()">
                        <template #header>
                            <div class="collapse-header">
                                <h3 class="collapse-header__date component-heading-h3">{{ invoice.payerDate.format() }}</h3>
                                <tbx-button type="secondary" size="large"
                                            @click.stop="onClickDownload(invoice.payerDate)">
                                    <tbx-icon type="download"/>
                                    請求書ダウンロード
                                </tbx-button>
                            </div>
                        </template>

                        <div v-for="detail in invoice.list"
                             :key="detail.payeeCompanyId"
                             class="collapse-item">

                            <div class="collapse-item__left">
                                <div class="component-body-s">{{ detail.name.kanji }}</div>
                                <div class="component-body-xs">
                                    <a-space>
                                        <span>確認済 {{ detail.confirmed }}/{{ detail.count }}件</span>
                                        <tbx-icon type="check-circle-filled"
                                                  class="confirmation-icon"
                                                  v-if="detail.unconfirmed === 0"/>
                                    </a-space>
                                </div>
                            </div>
                            <div class="collapse-item__right" ghost>
                                <tbx-button type="text" size="small" class-name="component-button-text-s"
                                            @click="onClickDownloadDetail(invoice.payerDate, detail.payeeCompanyId)">
                                    <tbx-icon type="download"/>
                                    企業別明細ダウンロード
                                </tbx-button>
                            </div>
                        </div>

                        <div v-if="invoice.list.length === 0" class="ant-list-empty-text">明細がありません。</div>
                    </a-collapse-panel>
                </a-collapse>
            </a-spin>
        </div>
    </div>
</template>

<style scoped lang="less">
.container {
    display: flex;
    width: 100%;
}
.section-timeline {
    flex-shrink: 0;
    margin-top: @padding-xs;
    width: 144px;

    &__label {
        cursor: pointer;

        &.selected {
            color: @ui-color-selected;
            font-weight: @token-font-weight-bold;
        }
    }
}
.section-list {
    flex-grow: 1;

    &-header {
        margin-bottom: @padding-md;

        &__row {
            display: flex;
            justify-content: space-between;
            margin-bottom: @padding-xs;
        }

        h2 {
            margin: 0;
        }
    }

    .confirmation-icon {
        font-size: @font-size-lg;
        color: @token-main-color-trabox-green;
        margin-top: 2px;
        cursor: initial;
    }
}

.collapse {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__date {
            margin: 0;
            line-height: 1;
        }
    }

    &-item {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 20px 52px;

        &:not(:last-child) {
            border-bottom: 1px solid @token-component-color-border;
        }

        &__left {
            .count {
                margin-top: 5px;
                font-size: 12px;
            }
        }

        &__right {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            max-width: 50%;
        }
    }
}

:deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding-left: 52px;
}
:deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
    font-size: 14px;
}
</style>
