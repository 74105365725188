import { useRouting } from '@/composables/helper/routing';
import { useCompanyPlaceRegister, useFindCompanyPlace } from '@/composables/company-place';
import { useFormModel } from '@/composables/form-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { useModal } from '@/composables/helper/modal-helper';
import { CompanyPlaceRegisterFormValidatableModel } from '@/models/company-place';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { computed, nextTick, onBeforeMount } from 'vue';

export const useCompanyPlaceRegisterHelper = (sourceCompanyPlaceId?: number) => {
    const { goBack, goToCompanyPlaceList, goToCompanyPlaceRegister } = useRouting();
    const { state: { form, formValidateRules, dirty }, initForm, register } = useCompanyPlaceRegister();
    const { state: { loading: loadingCompanyPlace, companyPlace }, find: loadCompanyPlace } = useFindCompanyPlace();
    const { state: { loading: loadingCompanyPlaceRegister }, withLoading } = useLoading();
    const { formModel, submit } = useFormModel();
    const message = useMessage();
    const modal = useModal();

    const loading = computed<boolean>(() =>
        [loadingCompanyPlace, loadingCompanyPlaceRegister].some(each => each.value)
    );

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 入力内容クリア確認モーダルを表示します。
     */
    const confirmClearForm = (): Promise<boolean> => modal.confirm(
        '入力内容を削除してよろしいですか？',
        '',
        '削除',
        'キャンセル'
    );

    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');


    // ======================================================
    // Event
    // ======================================================
    /**
     * フォームを初期化します。
     */
    const initialize = async () => {
        if (sourceCompanyPlaceId) {
            // `sourceCompanyPlaceId`に不正なものが含まれている場合は、クエリパラメーターをリセット
            await loadCompanyPlace(sourceCompanyPlaceId).catch(() => goToCompanyPlaceRegister());
            if (companyPlace.value) {
                initForm(companyPlace.value);
                nextTick(async () => {
                    formModel.value?.validate(() => {
                        // callbackがないとerrorとなるため、empty functionを入れる
                    });
                });
            }
        }
    };

    /**
     * 登録ボタンが押下された際に呼び出されます。
     */
    const onSubmit = () => submit(async () => withLoading(async () => {
        const onSuccess = async (companyPlaceId: number) => {
            message.success('地点情報を登録しました。');
            return goToCompanyPlaceList();
        };

        await register().then(onSuccess);
    }));

    /**
     * クリアボタンが押下された際に呼び出されます。
     */
    const onClickClear = async () => {
        if (!await confirmClearForm()) return;
        form.value = new CompanyPlaceRegisterFormValidatableModel();
    };

    const onClickBack = () => {
        goBack();
    };

    // ======================================================
    // LifeCycle
    // ======================================================
    onBeforeMount(() => initialize());

    onBeforeRouteLeave((_to, _from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        formModel,
        form,
        formValidateRules,
        onSubmit,
        onClickClear,
        onClickBack,
    };
};
