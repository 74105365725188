import { useNotice } from '@/composables/global/notice';
import { ref } from 'vue';

/**
 * お知らせを定期取得する機能を提供します。
 */
export const useNoticeListener = (intervalTime: number = 30 * 60 * 1000 /* 30 minutes */) => {
    const { load } = useNotice();
    const id = ref<number | undefined>();
    /**
     * お知らせをの定期取得（Listen）を開始します。
     */
    const start = () => {
        stop();
        id.value = window.setInterval(async () => {
            await load();
        }, intervalTime);
    };

    /**
     * お知らせをの定期取得（Listen）を停止します。
     */
    const stop = () => {
        if (id.value) {
            window.clearInterval(id.value);
        }
    };

    return {
        start,
        stop,
    };
};
