import { computed, ref } from 'vue';
import { AccountPasswordRemindFormModel } from '@/models/account';
import { useLoading } from '@/composables/helper/loading-helper';
import { accountApi } from '@/repository/api/internal/account';
import { FormValidateUtil } from '@/models/validate-helper';

export const useAccountPasswordRemind = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref(new AccountPasswordRemindFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const remind = () => withLoading(() => accountApi.remindPassword(form.value));

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        remind,
    };
};
