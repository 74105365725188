import { Component, Prop } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import _ from 'lodash';
import Vue from 'vue';
import { Util } from '@/util';

@Component
export default class AgreementClearanceFee extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get clearanceFee(): string {
        if (!this.agreement) return '';

        if (_.isNil(this.agreement.clearanceFee)) return '金額未定';
        return `${Util.formatNumber(this.agreement.clearanceFee ?? 0)} 円`;
    }

    get clearanceFeeCr(): string {
        if (!this.agreementCr) return '';

        if (_.isNil(this.agreementCr.clearanceFee)) return '金額未定';
        return `${Util.formatNumber(this.agreementCr.clearanceFee ?? 0)} 円`;
    }

    get changeRequest(): string {
        return this.clearanceFee != this.clearanceFeeCr ? this.clearanceFeeCr : '';
    }
}
