<script setup lang="ts">
import { useUpgradePlanCompleteHelper } from '@/_pages/UpgradePlan/Complete/upgrade-plan-complete-helper';
import PageLayout from '@/_components/PageLayout.vue';
import ZipCodeText from '@/_units/ZipCodeText.vue';
import PhoneNumberText from '@/_units/PhoneNumberText.vue';

const {
    appServiceName,
    height,
    onClickToTop,
    onClickBack,
    showAlert,
    payment
} = useUpgradePlanCompleteHelper();
</script>
<template>
    <page-layout @back="onClickBack">
        <a-layout-content class="content-body">
            <div class="section-container" :style="{ minHeight: `${ height ? height + 'px' : 'auto' }` }">
                <div class="header">
                    <img src="/img/logo-simple.svg" alt="トラボックス" class="header__logo"/>
                </div>
                <div class="body">
                    <h1 class="body__title">プレミアムプランのお申し込みが完了しました</h1>
                    <figure class="body__figure">
                        <img src="/img/il-upgrade-complete.svg" alt="お手続きが完了いたしました。"/>
                    </figure>
                    <div class="body__content">
                        <p>お申込みいただき、誠にありがとうございます。<br>
                            お申し込み完了メールを送付いたしました。<br>
                            引き続き{{ appServiceName }}をご利用ください。</p>
                        <a-alert class="alert__body" v-if="showAlert" type="warning" show-icon>
                            <template #message>
                                <div class="alert__content">
                                    <span>請求書送付先に変更がある場合</span>
                                </div>
                            </template>
                            <template #description>
                                <div class="alert__content">
                                    <div>お手数ですが、トラボックスまでご連絡ください。</div>
                                    <div class="alert__content__section">
                                        <div class="alert__content__strong alert__content_section__header">お客様の現在の請求書送付先</div>
                                        <a-row type="flex">
                                            <a-col flex="90px">企業名</a-col>
                                            <a-col flex="auto">{{ payment?.companyName?.kanji }}</a-col>
                                        </a-row>
                                        <a-divider class="alert__content__divider"/>
                                        <a-row type="flex">
                                            <a-col flex="90px">宛名</a-col>
                                            <a-col flex="auto">{{ payment?.name }}</a-col>
                                        </a-row>
                                        <a-divider class="alert__content__divider"/>
                                        <a-row type="flex">
                                            <a-col flex="90px">住所</a-col>
                                            <a-col flex="auto">
                                                <zip-code-text :zip-code="payment?.zipCode" />
                                                <span>{{ payment?.prefecture?.label }}</span>
                                                <span>{{ payment?.city }}</span>
                                                <span>{{ payment?.address }}</span>
                                            </a-col>
                                        </a-row>
                                        <a-divider class="alert__content__divider"/>
                                        <a-row type="flex">
                                            <a-col flex="90px">電話番号</a-col>
                                            <a-col flex="auto">
                                                <phone-number-text :phone-number="payment?.phoneNumber" />
                                            </a-col>
                                        </a-row>
                                        <a-divider class="alert__content__divider"/>
                                        <a-row type="flex">
                                            <a-col flex="90px">FAX番号</a-col>
                                            <a-col flex="auto">
                                                <phone-number-text :phone-number="payment?.faxNumber" />
                                            </a-col>
                                        </a-row>
                                    </div>
                                </div>
                            </template>
                        </a-alert>
                        <div class="body__content__actions">
                            <a-button class="btn-action" type="primary" size="large" @click="onClickToTop">トップページへ
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>
    </page-layout>
</template>
<style scoped lang="less">
.content-body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @component-background;
}

.section-container {
    display: flex;
    flex-direction: column;
    width: 1072px;
    height: auto;
    background-color: @component-background;
}

.header {
    height: 64px;
    padding: 22px 24px 0;

    > .header__logo {
        width: 167px;
        height: 20px;
    }
}

.body__title {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}

.body__title + .body__figure {
    margin-top: 8px;
}

.body__figure {
    text-align: center;
    margin: 0;

    > img {
        width: 419px;
        height: 254px;
    }
}

.body__figure + .body__content {
    margin-top: 16px;
}

.body__content {
    flex: 1;
    padding: 0 32px;
    text-align: center;

    * + .body__content__actions {
        margin: 16px 0;
    }

    .body__content__actions {
        text-align: center;

        > .btn-action {
            min-width: 160px;
        }
    }
}

.alert__body {
    padding-right: 64px;
}

.alert__content {
    text-align: start;
    &__strong {
        font-size: @font-size-base;
        font-weight: @typography-title-font-weight;
        color: @heading-color;
    }
    &__section {
        &__header {
            padding: @padding-md 0 @padding-sm 0;
        }
        padding: @padding-xs 0;
    }
    &__divider {
        margin: 4px 0;
    }
}
</style>
