import { computed, InjectionKey, ref } from 'vue';
import { useCompanyTruckEventSearch } from '@/composables/company-truck-event';
import { useCompanyTruckSearch } from '@/composables/company-truck';
import {
    TimelineEventType,
    TimelineResourceType,
    TimelineSelectedCellType
} from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import { DateValue } from '@/models/vo/date';
import { useMyTruckExistsRecommendations } from '@/composables/truck';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEventScheduleProvider = () => {
    const {
        state: { loading: loadingEvent, list: eventList },
        search: loadCompanyTruckEvent
    } = useCompanyTruckEventSearch();
    const {
        state: {
            loading: loadingTruckExistsRecommendations,
            list: truckExistsRecommendations,
            form: truckExistsRecommendationsForm
        },
        load: loadTruckExistsRecommendations
    } = useMyTruckExistsRecommendations();
    const {
        state: { loading: loadingSearch, list: companyTruckList },
        search: loadCompanyTruck
    } = useCompanyTruckSearch();
    const loading = computed<boolean>(() => loadingSearch.value || loadingEvent.value);

    const selectedDate = ref<Date>(new Date());
    const selectedCell = ref<TimelineSelectedCellType | undefined>();
    const selectedEvent = ref<TimelineEventType | undefined>();
    const resourceList = computed<TimelineResourceType[]>(() => {
        return companyTruckList.value?.data?.map(each => {
            return {
                Id: each.id,
                Name: `[${ each.id }] ${ each.carNumber }`,
                Subtitle: each.driverName,
                Description: `${ each.truckWeight.label } / ${ each.truckModel.label }`
            } as TimelineResourceType;
        }) ?? [];
    });

    const eventSettings = computed<{
        dataSource: TimelineEventType[]
    }>(() => {
        return {
            dataSource: eventList.value.map(each => {
                return {
                    Id: each.id,
                    StartTime: new Date(each.startTm),
                    EndTime: new Date(each.endTm),
                    Subject: each.title,
                    Location: each.location,
                    Description: each.description,
                    EventType: each.eventType.code,
                    CompanyTruckId: each.companyTruckId,
                    ExistsRecommendation: truckExistsRecommendations.value
                        ?.find(recommendation => recommendation.id === each.truckId)
                        ?.existsRecommendation ?? false
                } as TimelineEventType;
            }),
        };
    });

    const refreshResourceList = async () => {
        // TODO: pageしない設計にする
        await loadCompanyTruck(1, 100);
    };

    const refreshEventList = async () => {
        const date = new DateValue(selectedDate.value);
        const startFrom = date.startOf('week');
        const endTo = date.endOf('week');
        await loadCompanyTruckEvent(startFrom, endTo);
        const truckIds = eventList.value.map(each => each.truckId)
            .filter(each => each !== undefined) as number[];
        if (truckIds.length > 0)
            await loadTruckExistsRecommendations(truckIds);
    };

    return {
        state: {
            loading,
            selectedDate,
            selectedCell,
            selectedEvent,
            resourceList,
            eventSettings
        },
        refreshResourceList,
        refreshEventList,
    };
};

export type EventSchedulerProviderType = ReturnType<typeof useEventScheduleProvider>;
export const EVENT_SCHEDULER_PROVIDER_KEY: InjectionKey<EventSchedulerProviderType> = Symbol('EventSchedulerProvider');
