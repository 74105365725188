import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RegionEnum, RegionEnumCode } from '@/enums/region.enum';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';

@Component
export default class JapanMap extends Vue {
    @Prop({ default: '' })
    declare readonly title: string; // 表示タイトル
    @Prop()
    declare readonly multiple?: boolean; // 地域選択を表示するか否か
    @Prop()
    declare readonly allowClear?: boolean; // 選択解除を許容するか否か
    @Prop()
    declare readonly value?: PrefectureEnumCode[];
    regionEnum = RegionEnum;
    prefectureEnum = PrefectureEnum;
    highlightPrefectures: PrefectureEnumCode[] = [];

    get selected(): PrefectureEnumCode[] {
        return this.value ?? [];
    }

    set selected(value: PrefectureEnumCode[]) {
        this.emitValue(value);
    }

    /**
     * 地域を都道府県コードリストへ変換します。
     */
    regionToPrefCodes(region: RegionEnumCode): PrefectureEnumCode[] {
        return RegionEnum.valueOf(region)?.prefectures ?? [];
    }

    /**
     * 都道府県ボタンに対して追加で付与すべきスタイル用Classを取得します。
     */
    getStyleClass(code: PrefectureEnumCode): { highlight: boolean; selected: boolean } {
        return {
            highlight: this.highlightPrefectures.includes(code),
            selected: this.selected.includes(code),
        };
    }

    /**
     * 指定地域が選択状態にあるか否かを取得します。
     */
    isRegionSelected(code: RegionEnumCode): boolean {
        return this.regionToPrefCodes(code).every((each) => this.selected.includes(each));
    }

    /**
     * 指定地域の都道府県が一部だけ選択状態なっているか否かを取得します。
     */
    isRegionIndeterminate(code: RegionEnumCode): boolean {
        if (this.isRegionSelected(code)) {
            return false;
        }
        return this.regionToPrefCodes(code).some((each) => this.selected.includes(each));
    }

    /**
     * 都道府県ボタンがクリックされた際に呼び出されます。
     */
    onClickPrefecture(code: PrefectureEnumCode): void {
        if (!this.multiple) {
            this.selected = [code];
            return;
        }

        if (this.selected.includes(code)) {
            this.selected = _.uniq(_.difference(this.selected, [code]));
        } else {
            this.selected = _.uniq(_.concat(this.selected, [code]));
        }
    }

    /**
     * 地域ボタンがhoverされた際に呼び出されます。
     */
    onMouseOverRegion(regionCode: RegionEnumCode): void {
        this.highlightPrefectures = this.regionEnum.valueOf(regionCode)?.prefectures ?? [];
    }

    /**
     * 地域ボタンのhoverが解除された際に呼び出されます。
     */
    onMouseLeaveRegion(): void {
        this.highlightPrefectures = [];
    }

    /**
     * 地域ボタンがクリックされた際に呼び出されます。
     */
    onClickRegion(event: Event): void {
        if (!this.multiple) {
            throw new Error('region-select is not allowed when single selection mode.');
        }
        const target = event.target as { checked: boolean; value: RegionEnumCode } | null;
        if (!target) {
            return;
        }

        if (this.isRegionSelected(target.value)) {
            this.selected = _.uniq(_.difference(this.selected, this.regionToPrefCodes(target.value)));
        } else {
            this.selected = _.uniq(_.concat(this.selected, this.regionToPrefCodes(target.value)));
        }
    }

    /**
     * すべて選択ボタンがクリックされた際に呼び出されます。
     */
    onClickSelectAll(): void {
        this.selected = this.prefectureEnum.values.map((each) => each.code);
    }

    /**
     * すべて解除ボタンがクリックされた際に呼び出されます。
     */
    onClickDeselect(): void {
        this.selected = [];
    }

    /**
     * 値をemitします。
     */
    private emitValue(value: PrefectureEnumCode[]) {
        this.$emit('input', value);
    }
}
