import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import type dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TruckRegisterFormModel } from '@/vuex/modules/truck/types';
// @ts-ignore
import UiDateTimePicker from '@/components/UI/DateTimePicker';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';
import { ValidateResult, Validator } from '@/validator';
import { DateUtil } from '@/util';
import { DateTimePickerValue } from '@/components/UI/DateTimePicker/types';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

@Component({
    components: {
        UiDateTimePicker,
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class TruckSpotEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: TruckRegisterFormModel;

    /**
     * 出発日時
     */
    get departureDateTime(): DateTimePickerValue {
        // TODO: 将来的にはDeliveryDateTime型で扱いたい
        return [this.value?.departureMin, this.value?.departureMax];
    }

    set departureDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 出発日時を書き換え
        this.emitNewValue({
            departureMin: dateMin ?? '',
            departureMax: dateMax ?? '',
        });

        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalDateTime.onFieldChange();
        });
    }

    /**
     * 出発地
     */
    get departurePref(): string | undefined {
        return this.value?.departurePref.code;
    }

    set departurePref(newValue: string | undefined) {
        const changed = this.value?.departurePref.code !== newValue;

        this.emitNewValue({
            // 都道府県
            departurePref: newValue ? { code: newValue } : {},
            // 市区町村（都道府県が変更された場合はクリア）
            departureCity: changed ? '' : this.value?.departureCity,
        });

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemDepartureCity.onFieldChange());
    }

    get departureCity(): string {
        return this.value?.departureCity || '';
    }

    set departureCity(newValue: string) {
        this.emitNewValue({ departureCity: newValue });

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemDepartureAddress.onFieldChange());
    }

    /**
     * 到着日時
     */
    get arrivalDateTime(): DateTimePickerValue {
        return [this.value?.arrivalMin, this.value?.arrivalMax];
    }

    set arrivalDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 出発日時を書き換え
        this.emitNewValue({
            arrivalMin: dateMin ?? '',
            arrivalMax: dateMax ?? '',
        });

        // @ts-ignore
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalDateTime.onFieldChange();
        });
    }

    /**
     * 到着地
     */
    get arrivalPref(): string | undefined {
        return this.value?.arrivalPref.code;
    }

    set arrivalPref(newValue: string | undefined) {
        const changed = this.value?.arrivalPref.code !== newValue;

        this.emitNewValue({
            // 都道府県
            arrivalPref: newValue ? { code: newValue } : {},
            // 市区町村（都道府県が変更された場合はクリア）
            arrivalCity: changed ? '' : this.value?.arrivalCity,
        });

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemArrivalCity.onFieldChange());
    }

    get arrivalCity(): string {
        return this.value?.arrivalCity || '';
    }

    set arrivalCity(newValue: string) {
        this.emitNewValue({ arrivalCity: newValue });

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemArrivalAddress.onFieldChange());
    }

    /**
     * バリデーションの結果を取得します。
     */
    get dateTimeRangeValidateResult(): ValidateResult {
        const [departureMin, departureMax] = this.departureDateTime;
        const departure = departureMin && departureMax ? DeliveryDateTime.of(departureMin, departureMax) : null;
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const arrival = arrivalMin && arrivalMax ? DeliveryDateTime.of(arrivalMin, arrivalMax) : null;
        return Validator.validateDateTimeRange(departure, arrival);
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 今日の1時間後から1年後の年末まで
        return [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    }

    // ======================================================
    // Data
    // ======================================================
    cols = {
        first: {
            labelCol: { sm: 6, lg: 10 },
            wrapperCol: { sm: 18, lg: 14 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                sm: { offset: 6, span: 18 },
                lg: { offset: 0, span: 24 },
            },
        },
    };

    departureDateTimeValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateDepartureDateTime(callback as (message?: string) => void),
        },
    ];

    departureAddressValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateDepartureAddress(callback as (message?: string) => void),
        },
    ];

    arrivalDateTimeValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateArrivalDateTime(callback as (message?: string) => void),
        },
    ];

    arrivalAddressValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateArrivalAddress(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 出発日時のバリデーションを行う。
     */
    private validateDepartureDateTime(callback: (message?: string) => void): void {
        const [departureMin, departureMax] = this.departureDateTime;
        const value = DeliveryDateTime.of(departureMin ?? '', departureMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }
    /**
     * 出発場所のバリデーションを行う。
     */
    private validateDepartureAddress(callback: (message?: string) => void): void {
        if (!this.departurePref) {
            callback('都道府県を選択してください。');
        } else if (this.departureCity && this.departureCity.length > Const.MAX_CITY) {
            callback(`市区町村を${Const.MAX_CITY}文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    /**
     * 到着日時のバリデーションを行う。
     */
    private validateArrivalDateTime(callback: (message?: string) => void): void {
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const value = DeliveryDateTime.of(arrivalMin ?? '', arrivalMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else if (!this.dateTimeRangeValidateResult.result) {
            callback(this.dateTimeRangeValidateResult.message);
        } else {
            callback();
        }
    }

    /**
     * 到着場所のバリデーションを行う。
     */
    private validateArrivalAddress(callback: (message?: string) => void): void {
        if (!this.arrivalPref) {
            callback('都道府県を選択してください。');
        } else if (this.arrivalCity && this.arrivalCity.length > Const.MAX_CITY) {
            callback(`市区町村を${Const.MAX_CITY}文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    /**
     * 新しい値を親にemitします。
     */
    private emitNewValue(newValues: Partial<TruckRegisterFormModel>): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;
        this.$emit('input', _.merge(cloned, newValues));
    }
}
