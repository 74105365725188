import { Const } from '@/const';

export class PhoneUtil {
    /**
     * 電話番号をフォーマットします。
     * @param number
     */
    static format(number: string): string {
        // 実装ロジックやメンテナンス方法については、Kotlin版を参照してください。
        // admin-frontにも同じ実装があるので、メンテナンスは一緒に行ってください。

        if (number.length < 10) return number;
        if (number === Const.EMPTY_PHONE_NUMBER) return '';
        const prefix5 = [
            '01267', '01372', '01374', '01377', '01392', '01397', '01398',
            '01456', '01457', '01466', '01547', '01558', '01564', '01586', '01587',
            '01632', '01634', '01635', '01648', '01654', '01655', '01656', '01658',
            '04992', '04994', '04996', '04998', '05769', '05979[1234569]',
            '07468', '08387', '08388', '08396[245789]',
            '08477', '08512', '08514', '09496', '09802', '09912', '09913', '09969'
        ];
        const prefix3 = [
            // 011
            '011[1-8]',
            // 015
            '01541[012345689]',
            '01548',
            '01551[012345689]',
            '01557',
            // 017
            '017[17]',
            // 018
            '018[18]',
            // 019
            '019[69]',
            // 022
            '022[127]',
            '0223[0456789]',
            // 023
            '023[16]',
            // 024
            '024[59]',
            '024177',
            // 025
            '025[123]',
            '0255[01234569]',
            '02560',
            '0257[056789]',
            '02571[012345689]',
            '02580',
            // 026
            '0262',
            '026177',
            '0264[06789]',
            // 027
            '027[123]',
            '0278[089]',
            // 028
            '028[16]',
            '0283[034]',
            '0289[02345]',
            // 029
            '029[28]',
            '029177',
            '0293[056789]',
            // 042
            '042[3457]',
            '04220',
            '0426[123456789]',
            '0428[0456]',
            '04281[012345689]',
            '04291[012345689]',
            '0429[78]',
            // 043
            '043[1234]',
            // 044
            '044',
            // 045
            '045[123456789]',
            // 046
            '046[28]',
            '04640',
            // 047
            '047[34]',
            '047177',
            '0477[012567]',
            // 048
            '048[12456789]',
            // 049
            '049[12]',
            // 052
            '052[123456789]',
            // 053
            '053[45]',
            '0539[23458]',
            '05396[456789]',
            '05397[01235689]',
            // 054
            '054[12369]',
            // 055
            '055177',
            '055[29]',
            // 058
            '058[23]',
            // 059
            '059[123]',
            '05999',
            // 072
            '072177',
            '072[2346789]',
            // 073
            '073[14]',
            // 075
            '075[123456789]',
            // 076
            '076[24]',
            // 077
            '0775',
            // 078
            '078[123456789]',
            // 079
            '0792',
            '0793[0123]',
            '0794[0123459]',
            '0795[0569]',
            '0796[067]',
            // 082
            '082[1258]',
            '0824[0239]',
            '0829[069]',
            '08292[123456789]',
            '08294[123]',
            // 083
            '0832[123456789]',
            '08360',
            '0837[78]',
            '08376[678]',
            '0839[012345789]',
            '08396[036]',
            // 084
            '084[19]',
            // 086
            '086[12]',
            '08636[2345789]',
            '0864[2345678]',
            '08652',
            '08655[23]',
            '08660',
            '08669[178]',
            '08672[23468]',
            '08673[78]',
            '0868[09]',
            '0869[04]',
            '08695[012345678]',
            '0869529',
            '08699[456789]',
            // 087
            '087[18]',
            // 088
            '088[68]',
            // 089
            '089[19]',
            // 092
            '092[123456789]',
            // 093
            '093[123456789]',
            // 095
            '095[18]',
            // 096
            '096[123]',
            // 097
            '097[15]',
            // 098
            '098[189]',
            // 099
            '09917',
            '0992',
            '0998[0123]',
            '099331',
            '09934[357]',
            '0994[078]'
        ];
        const prefix2 = [
            // 03
            '03',
            // 04
            '04200',
            '042917',
            '0429[0234569]',
            '0470[09]',
            '0471[012345689]',
            '04717[012345689]',
            // 06
            '06'
        ];

        const parsers: ((number: string) => string[] | null)[] = [
            // [0ABCD][10桁](5-1-4)
            // - 固定電話 5桁市外局番
            (number) => this.match(number, prefix5) ? this.parse(number, /^(0[0-9]{4})([0-9])([0-9]{4})$/) : null,

            // [0AB][10桁](3-3-4)
            // - 固定電話 3桁市外局番
            (number) => this.match(number, prefix3) ? this.parse(number, /^(0[0-9]{2})([0-9]{3})([0-9]{4})$/) : null,

            // [0A][10桁](2-4-4)
            // - 固定電話 2桁市外局番
            (number) => this.match(number, prefix2) ? this.parse(number, /^(0[0-9])([0-9]{4})([0-9]{4})$/) : null,

            // [0AB0][11桁](4-3-4)
            // - 0800フリーダイヤル
            (number) => this.parse(number, /^(0[1-9][0-9]0)([0-9]{3})([0-9]{4})$/),

            // [0A0][11桁](3-4-4)
            // - IP電話
            // - 携帯電話, PHS
            (number) => this.parse(number, /^(0[1-9]0)([0-9]{4})([0-9]{4})$/),

            // [汎用][10桁](4-2-4)
            // - 固定電話 4桁市外局番
            // - 0120など
            (number) => this.parse(number, /^([0-9]{4})([0-9]{2})([0-9]{4})$/),

            // [汎用][11桁](4-3-4)
            (number) => this.parse(number, /^([0-9]{4})([0-9]{3})([0-9]{4})$/),
        ];

        for (const parser of parsers) {
            const components = parser(number);
            if (components !== null) {
                return components.join('-');
            }
        }

        return number;
    }

    private static match(number: string, patterns: string[]): boolean {
        return patterns.some((pattern) => (new RegExp(`^${ pattern }`)).test(number));
    }

    private static parse(number: string, regex: RegExp): string[] | null {
        const components = number.match(regex)?.slice(1);
        return components !== undefined ? components : null;
    }
}
