import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { BaggageHandlingTypeEnum } from '@/enums/baggage-handling-type.enum';

@Component
export default class BaggageHandlingEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    loadingOptions = BaggageHandlingTypeEnum.values.map((each) => ({
        value: each.code,
        label: each.getLabel(true),
        key: each.code,
    }));

    unloadingOptions = BaggageHandlingTypeEnum.values.map((each) => ({
        value: each.code,
        label: each.getLabel(false),
        key: each.code,
    }));

    get loading(): string | undefined {
        return this.value?.loading?.code;
    }

    set loading(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.loading = BaggageHandlingTypeEnum.valueOf(newValue);
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemLoading.onFieldChange());
    }

    get unloading(): string | undefined {
        return this.value?.unloading?.code;
    }

    set unloading(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.unloading = BaggageHandlingTypeEnum.valueOf(newValue);
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemUnloading.onFieldChange());
    }
}
