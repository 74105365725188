import { QuestionnaireUtil } from '@/util';
import { FormValidatable, FormValidator } from '@/models/validate-helper';

/**
 * アンケート
 */
export interface Questionnaire {
    items: QuestionItem[];
}

/**
 * アンケート設問タイプ
 */
export type QuestionItemType = 'single-select' | 'multiple-select' | 'text';

/**
 * アンケート設問
 */
export interface QuestionItem {
    id: string; // 設問ID
    type: QuestionItemType;
    text: string; // 設問文
    options?: QuestionItemOption[]; // 選択肢リスト - multiple-select, single-selectタイプの場合に有効です
    placeholder?: string; // テキスト入力欄のプレースホルダー - textタイプの場合に有効です
    required: boolean; // 回答必須か否か
}

/**
 * 単一選択／複数選択の選択肢
 */
export interface QuestionItemOption {
    id: string; // 選択肢ID
    text: string;
    allowsFreeText: boolean; // フリーテキスト入力の可否
    required: boolean; // テキスト回答が必須かどうか(allowsFreeText: trueの場合に有効な設定)
}

/**
 * アンケート設問回答フォーム
 */
export type QuestionItemAnswer = QuestionMultipleSelectAnswer | QuestionSingleSelectAnswer | QuestionTextAnswer;

/**
 * アンケート設問回答フォーム(複数選択)
 */
export interface QuestionMultipleSelectAnswer {
    id: string; // 設問ID
    type: 'multiple-select';
    answer?: QuestionItemOptionAnswer[];
}

/**
 * アンケート設問回答フォーム(単一選択)
 */
export interface QuestionSingleSelectAnswer {
    id: string; // 設問ID
    type: 'single-select';
    answer?: QuestionItemOptionAnswer;
}

/**
 * アンケート設問回答フォーム(テキスト)
 */
export interface QuestionTextAnswer {
    id: string; // 設問ID
    type: 'text';
    answer?: string;
}

/**
 * アンケート単一選択／複数選択の選択肢の回答
 */
export interface QuestionItemOptionAnswer {
    id: string; // 選択肢ID
    text?: string; // フリーテキスト
}

export type QuestionItemAnswerValue =
    | undefined
    | string
    | QuestionItemOptionAnswer
    | Array<QuestionItemOptionAnswer | undefined>;

export class QuestionnaireFormModel implements FormValidatable<QuestionnaireFormModel> {
    questions: QuestionItemAnswerFormModel[];

    constructor(params: Partial<QuestionnaireFormModel> | null = null) {
        this.questions = params?.questions ?? [];
    }

    validator(): FormValidator<QuestionnaireFormModel> {
        const rules = {};
        this.questions.forEach(each => {
            // @ts-ignore
            rules[each.name] = [{
                required: each.question.required,
                validator: (_rule: unknown, _value: QuestionnaireFormModel, callback: Function) =>
                    each.validate(callback as (message?: string) => void)
            }];
        });
        return rules;
    }
}

export class QuestionItemAnswerFormModel {
    question: QuestionItem;
    index: number;
    answer: QuestionItemAnswerValue;

    constructor(question: QuestionItem, index: number) {
        this.question = question;
        this.index = index;
        this.answer = QuestionnaireUtil.createAnswerForm(question);
    }

    get label(): string {
        return this.index + 1 + '. ' + this.question.text + (this.question.type === 'multiple-select' ? '（複数選択可）' : '');
    }

    /**
     * FormModelItemで用いるrefNameを取得します。
     */
    get name(): string {
        return `questions.${ this.index }`;
    }

    /**
     * 設問の回答値を取得します。
     */
    get value(): Array<string> | string | undefined {
        switch (this.question.type) {
            case 'multiple-select': {
                const answers = this.answer as Array<QuestionItemOptionAnswer | undefined>;
                return answers.filter((v): v is Exclude<typeof v, undefined> => v !== undefined).map((each) => each.id);
            }
            case 'single-select': {
                const answer = this.answer as QuestionItemOptionAnswer | undefined;
                return answer?.id ?? undefined;
            }
            case 'text': {
                return (this.answer as string | undefined) ?? '';
            }
        }
    }

    set value(newValue: Array<string> | string | undefined) {
        switch (this.question.type) {
            case 'multiple-select': {
                const value = newValue as Array<string>;
                this.answer = value.map((id) => ({ id, text: undefined }));
                break;
            }
            case 'single-select': {
                const optionId = newValue as string;
                this.answer = { id: optionId, text: undefined };
                break;
            }
        }
    }

    /**
     * 設問の回答にある自由記述テキストを取得します。
     */
    freeText(optionId?: string): string {
        switch (this.question.type) {
            case 'multiple-select': {
                const answer = this.answer as Array<QuestionItemOptionAnswer | undefined> | undefined;
                if (!answer) return '';
                const answerOption = answer.find((each) => each && each.id === optionId);
                return answerOption?.text ?? '';
            }
            case 'single-select': {
                const answer = this.answer as QuestionItemOptionAnswer | undefined;
                if (!answer) {
                    return '';
                }
                const allowsFreeText = this.question.options?.find((each) => each.id === answer.id)
                    ?.allowsFreeText;
                return allowsFreeText && answer.text ? answer.text : '';
            }
            case 'text': {
                return (this.answer as string | undefined) ?? '';
            }
        }
    }

    setFreeText(event: InputEvent, optionId?: string): void {
        switch (this.question.type) {
            case 'multiple-select': {
                if (!Array.isArray(this.answer)) return;
                const index = this.answer.findIndex((v) => v && v.id === optionId);
                if (index < 0) return;
                (this.answer as QuestionItemOptionAnswer[])[index].text = (event.target as HTMLInputElement).value;
                break;
            }
            case 'single-select': {
                if (this.isDisabledFreeText(optionId)) return;
                (this.answer as QuestionItemOptionAnswer).text = (event.target as HTMLInputElement).value;
                break;
            }
            case 'text': {
                this.answer = (event.target as HTMLInputElement).value;
                break;
            }
        }
    }

    isDisabledFreeText(optionId?: string): boolean {
        if (!this.question.options) return false;

        if (this.question.type === 'single-select') {
            const option = this.question.options?.find((each) => each.id === this.value);
            return !option || !option.allowsFreeText;
        } else if (this.question.type === 'multiple-select' && optionId) {
            const option = this.question.options?.find((each) => each.id === optionId);
            return !(option && option.allowsFreeText && (this.value as Array<string>).includes(optionId));
        }
        return false;
    }

    validate(callback: (message?: string) => void): void {
        switch (this.question.type) {
            case 'text': {
                const value = this.answer as string;
                if (this.question.required && (!value || value.length === 0)) {
                    callback('回答を入力してください。');
                    return;
                } else if (value.length > 200) {
                    callback('200文字以内で入力してください。');
                    return;
                }
                break;
            }
            case 'single-select': {
                const value = this.answer as undefined | QuestionItemOptionAnswer;
                if (this.question.required && value === undefined) {
                    callback('選択肢から回答を選んでください。');
                    return;
                }
                const selected = this.question.options?.find((each) => each.id === value?.id);
                if (selected && selected.allowsFreeText) {
                    if (selected.required && (!value || !value.text || value.text.length === 0)) {
                        callback(`「${ selected.text }」について、内容を入力してください。`);
                        return;
                    } else if (value && value.text && value.text.length > 200) {
                        callback('200文字以内で入力してください。');
                        return;
                    }
                }
                break;
            }
            case 'multiple-select': {
                const value = this.answer as Array<QuestionItemOptionAnswer | undefined>;
                const selectedOptions = value.filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
                if (this.question.required && selectedOptions.length === 0) {
                    callback('上記のチェックボックスの中から最適な項目にチェックを入れてください。');
                    return;
                }
                selectedOptions.forEach((each) => {
                    const selected = this.question.options?.find((option) => option.id === each?.id);
                    if (!selected) {
                        return;
                    }
                    if (selected && selected.allowsFreeText) {
                        if (selected.required && (!each || !each.text || each.text.length === 0)) {
                            callback(`「${ selected.text }」の自由記入項目を入力してください。`);
                            return;
                        } else if (each && each.text && each.text.length > 200) {
                            callback(`「${ selected.text }」の自由記入項目は、200文字以内で入力してください。`);
                            return;
                        }
                    }
                });
                break;
            }
        }
        callback();
    }
}
