import _ from 'lodash';
import { ref } from 'vue';
import { FileUtil } from '@/util/file';
import { CompanyInvoice } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useNotification } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 請求書をロードする機能を提供します。
 */
export const useInvoice = () => {
    const list = ref<CompanyInvoice[]>([]);

    const notification = useNotification();
    const { state: { loading }, withLoading } = useLoading();

    /**
     * 1年分の請求書発行状況を取得します。
     */
    const load = (year: number) => withLoading(async () => {
        list.value = await Promise.all(_.range(1, 13).map(month => companyApi.invoice(year, month)));
    });

    /**
     * 請求書をダウンロードします。
     */
    const download = async (year: number, month: number) => {
        try {
            const url = await companyApi.issueInvoiceUrl(year, month);
            notification.success({ message: '請求書のダウンロードを開始しました。', description: '' });
            await FileUtil.downloadFile(url, `trabox_${ year }-${ month }.pdf`);
        } catch (e) {
            notification.error({ message: 'ダウンロードできませんでした。時間をおいて再度お試しください。', description: '' });
        }
    };

    return {
        state: {
            loading,
            list,
        },
        load,
        download,
    };
};
