import { DateValue } from '@/models/vo/date';
import { DateTimeValue } from '@/models/vo/datetime';
import _ from 'lodash';
import { DateUtil } from '@/util';
import { CompanyProfileName } from '@/models/company';

export type TransactionPaymentTerm = {
    min: number;
    max: number;
};

export type TransactionPayment = {
    id: number;
    payeeDate: string;
    lastUpdateTm: string;
};


export class TransactionPaymentTermModel {
    list: number[];

    constructor(param: TransactionPaymentTerm) {
        this.list = _.range(param.min, param.max + 1).reverse();
    }

    static empty(): TransactionPaymentTermModel {
        return new TransactionPaymentTermModel({ min: DateUtil.now().year(), max: DateUtil.now().year() });
    }
}

export class TransactionPaymentModel {
    id: number;
    payeeDate: DateValue;
    lastUpdateTm: DateTimeValue;

    constructor(param: TransactionPayment) {
        this.id = param.id;
        this.payeeDate = new DateValue(param.payeeDate);
        this.lastUpdateTm = new DateTimeValue(param.lastUpdateTm);
    }
}

export type TransactionInvoiceTerm = {
    min: number;
    max: number;
};

export type TransactionInvoice = {
    payerCompanyId: number;
    payerDate: string;
    list: TransactionInvoiceDetail[];
};

export type TransactionInvoiceDetail = {
    payeeCompanyId: number;
    name: CompanyProfileName;
    confirmation: {
        confirmed: number;
        unconfirmed: number;
    };
};

export class TransactionInvoiceTermModel {
    list: number[];

    constructor(param: TransactionInvoiceTerm) {
        this.list = _.range(param.min, param.max + 1).reverse();
    }

    static empty(): TransactionPaymentTermModel {
        return new TransactionPaymentTermModel({ min: DateUtil.now().year(), max: DateUtil.now().year() });
    }
}

export class TransactionInvoiceModel {
    payerCompanyId: number;
    payerDate: DateValue;
    list: TransactionInvoiceDetailModel[];

    constructor(param: TransactionInvoice) {
        this.payerCompanyId = param.payerCompanyId;
        this.payerDate = new DateValue(param.payerDate);
        this.list = param.list.map(each => new TransactionInvoiceDetailModel(each));
    }
}

export class TransactionInvoiceDetailModel {
    payeeCompanyId: number;
    name: CompanyProfileName;
    confirmation: TransactionInvoiceDetail['confirmation'];

    constructor(param: TransactionInvoiceDetail) {
        this.payeeCompanyId = param.payeeCompanyId;
        this.name = param.name;
        this.confirmation = param.confirmation;
    }

    get confirmed(): number {
        return this.confirmation.confirmed;
    }

    get unconfirmed(): number {
        return this.confirmation.unconfirmed;
    }

    get count(): number {
        return this.confirmation.confirmed + this.confirmation.unconfirmed;
    }
}
