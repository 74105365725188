import { CompanyNoticeListModel } from '@/vuex/modules/companyNotice/company-notice-list.model';

//
// Action
//
export class ACTION {
    static readonly LOAD_COMPANY_NOTICE = 'LOAD_COMPANY_NOTICE';
    static readonly MARK_AS_READ = 'MARK_AS_READ';
    static readonly START_COMPANY_NOTICE_LISTENER = 'START_COMPANY_NOTICE_LISTENER';
    static readonly STOP_COMPANY_NOTICE_LISTENER = 'STOP_COMPANY_NOTICE_LISTENER';
}

//
// Getter
//
export class GETTER {
    static readonly COMPANY_NOTICE_LIST = 'COMPANY_NOTICE_LIST';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_COMPANY_NOTICE_LIST = 'SET_COMPANY_NOTICE_LIST';
    static readonly SET_COMPANY_NOTICE_LISTENER = 'SET_COMPANY_NOTICE_LISTENER';
}

//
// Store
//
export interface CompanyNoticeState {
    noticeList?: CompanyNoticeListModel;
    noticeListener?: number;
}

//
// Type for store
//
export * from '@/models/company-notice';
