import { ref } from 'vue';
import { BaggageFreightMaster, BaggageFreightMasterQueryForm } from '@/models/baggage';
import { baggageFreightMasterApi } from '@/repository/api/internal/baggage-freight-master';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 荷物参考運賃マスターをロードする機能を提供します。
 */
export const useBaggageFreightMaster = () => {
    const freightMaster = ref<BaggageFreightMaster | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (form: BaggageFreightMasterQueryForm): Promise<void> => {
        await withLoading(async () => {
            freightMaster.value = await baggageFreightMasterApi.query(form);
        });
    };

    const clear = () => {
        freightMaster.value = undefined;
    };

    return {
        state: {
            loading,
            freightMaster,
        },
        load,
        clear,
    };
};
