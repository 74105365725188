<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { BaggageDetailTabKey } from './model';
import { computed } from 'vue';
import BaggageDetailTabContent from './tabs/BaggageDetailTabContent.vue';
import TruckRecommendationsTabContent from './tabs/TruckRecommendationsTabContent.vue';
import NegotiationRequestsTabContent from './tabs/NegotiationRequestsTabContent.vue';
import { useMyBaggageExtension } from '@/composables/my-baggage-extension';
import { TruckRecommendationResult } from '@/composables/my-baggage-truck-recommendation';
import { NegotiationListResult } from '@/composables/negotiation-list';
import { CompanyProfile } from '@/models/company';

const {
    isOpened,
    isOnlineNegotiation,
} = useMyBaggageExtension();

const props = withDefaults(defineProps<{
    visible: boolean;
    tab: BaggageDetailTabKey;
    loading: boolean;
    loadingTruckRecommendations?: boolean;
    loadingNegotiationList?: boolean;
    baggage?: Baggage;
    truckRecommendations?: TruckRecommendationResult[];
    negotiationList?: NegotiationListResult;
    circleCompanyList?: CompanyProfile[];
    canEditBaggage?: boolean;
    canCancelBaggage?: boolean;
}>(), {
    canEditBaggage: false,
    canCancelBaggage: false,
    loadingTruckRecommendations: false,
    loadingNegotiationList: false,
});

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'changeTab', tab: BaggageDetailTabKey): void;
    (e: 'editBaggage', id: number): void;
    (e: 'copyBaggage', id: number): void;
    (e: 'cancelBaggage', id: number): void;
    (e: 'printBaggage', id: number): void;
    (e: 'markUnderNegotiation', id: number): void;
    (e: 'unmarkUnderNegotiation', id: number): void;
    (e: 'selectTruckCompany', value: { companyId: number, truckId: number }): void;
    (e: 'changeNegotiationPage', value: { pageNo: number, pageSize: number }): void,
    (e: 'selectNegotiationCompany', value: { companyId: number, negotiationId: number }): void;
}>();

const selectedTab = computed<BaggageDetailTabKey>({
    get() {
        return props.tab;
    },
    set(value) {
        emit('changeTab', value);
    }
});

const shouldRecommendTrucks = (baggage: Baggage) => {
    return isOpened(baggage);
};

const shouldShowNegotiationRequests = (baggage: Baggage) => {
    return isOpened(baggage) && isOnlineNegotiation(baggage);
};

</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0 }"
              :mask="false"
              :destroy-on-close="true"
              @close="emit('close')">
        <a-tabs class="drawer-content-tabs"
                v-model="selectedTab"
                :animated="false"
                :tabBarGutter="0"
                :tabBarStyle="{ padding: '12px 68px 0px 24px', margin: 0 }">
            <a-tab-pane key="baggage" tab="荷物情報">
                <baggage-detail-tab-content :loading="loading"
                                            :baggage="baggage"
                                            :can-edit-baggage="canEditBaggage"
                                            :can-cancel-baggage="canCancelBaggage"
                                            @editBaggage="(id) => emit('editBaggage', id)"
                                            @copyBaggage="(id) => emit('copyBaggage', id)"
                                            @cancelBaggage="(id) => emit('cancelBaggage', id)"
                                            @printBaggage="(id) => emit('printBaggage', id)"
                                            @markUnderNegotiation="(id) => emit('markUnderNegotiation', id)"
                                            @unmarkUnderNegotiation="(id) => emit('unmarkUnderNegotiation', id)"/>
            </a-tab-pane>

            <a-tab-pane key="truck"
                        tab="空車情報"
                        v-if="baggage && shouldRecommendTrucks(baggage)">
                <truck-recommendations-tab-content :baggage-id="baggage.id"
                                                   :list="truckRecommendations"
                                                   :loading="loadingTruckRecommendations"
                                                   @selectCompany="emit('selectTruckCompany', $event)"/>
            </a-tab-pane>

            <a-tab-pane key="negotiation"
                        tab="対応可否"
                        v-if="baggage && shouldShowNegotiationRequests(baggage)">
                <negotiation-requests-tab-content :baggage-id="baggage.id"
                                                  :list="negotiationList"
                                                  :circle-company-list="circleCompanyList"
                                                  :loading="loadingNegotiationList"
                                                  @selectCompany="emit('selectNegotiationCompany', $event)"
                                                  @changePage="emit('changeNegotiationPage', $event)"/>
            </a-tab-pane>
        </a-tabs>
        <slot name="editor"/>
    </a-drawer>
</template>

<style scoped lang="less">
.drawer-content-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    :deep(.ant-tabs-bar) {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    :deep(.ant-tabs-content) {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
    }

    :deep(.ant-tabs-tabpane-active) {
        height: 100%;
    }
}
</style>
