<script setup lang="ts">

import { useCompanyTruckEventDelete, useCompanyTruckEventMemoUpdate } from '@/composables/company-truck-event';
import { CompanyTruckEvent } from '@/models/company-truck-event';
import { onBeforeMount } from 'vue';
import { useModal } from '@/composables/helper/modal-helper';
import { useFormModel } from '@/composables/form-helper';

const props = defineProps<{
    companyTruckEvent: CompanyTruckEvent;
}>();

const emit = defineEmits<{
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const { state: { loading, form, formValidateRules }, update } = useCompanyTruckEventMemoUpdate();
const { deleteEvent } = useCompanyTruckEventDelete();
const { confirm } = useModal();
const { formModel, submit } = useFormModel();

onBeforeMount(() => {
    form.value.description = props.companyTruckEvent.description;
    form.value.startTm = props.companyTruckEvent.startTm;
    form.value.endTm = props.companyTruckEvent.endTm;
    form.value.title = props.companyTruckEvent.title;
    form.value.description = props.companyTruckEvent.description;
    form.value.location = props.companyTruckEvent.location;
    form.value.companyTruckId = props.companyTruckEvent.companyTruckId;
});

const onSubmit = () => submit(async () =>  {
    await update(props.companyTruckEvent.id, form.value);
    emit('ok');
});

const onCancel = () => {
    emit('cancel');
};

const onDelete = async () => {
    if (!await confirm(
        '削除してよろしいですか？',
        (h) => h('div'),
        '削除する',
        'キャンセル'
    )) {
        return;
    }
    await deleteEvent(props.companyTruckEvent.id);
    emit('ok');
};

</script>

<template>
    <a-spin :spinning="loading">
        <div class="content">
            <a-form-model ref="formModel"
                          :model="form"
                          :rules="formValidateRules"
                          :label-col="{ span: 4 }"
                          :wrapper-col="{ span: 18 }">
                <a-form-model-item label="期間">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="11">
                            <a-input v-model:value="form.startTm"/>
                        </a-col>
                        <a-col :span="1" style="display: flex; justify-content: center">
                            →
                        </a-col>
                        <a-col :span="11">
                            <a-input v-model:value="form.endTm"/>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <a-form-model-item label="タイトル"
                                   prop="title">
                    <a-input v-model:value="form.title"/>
                </a-form-model-item>
                <a-form-model-item label="詳細"
                                   prop="description">
                    <a-textarea v-model:value="form.description"/>
                </a-form-model-item>
                <a-form-model-item label="場所"
                                   prop="location">
                    <a-input v-model:value="form.location"/>
                </a-form-model-item>
            </a-form-model>
        </div>

        <div class="footer">
            <a-button type="primary" @click="onSubmit">編集</a-button>
            <a-button @click="onCancel">キャンセル</a-button>
            <a-button type="danger" @click="onDelete">削除</a-button>
        </div>
    </a-spin>
</template>

<style scoped lang="less">
.footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
</style>
