import { Questionnaire } from '@/vuex/modules/questionnaire/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const questionnaireApi = {
    /**
     * アンケート設問を取得します。
     */
    question(use: 'REVOKE_PREMIUM' | 'DEACTIVATE_COMPANY'): Promise<Questionnaire> {
        return appAxios
            .get<JsonResponseBean<Questionnaire>>('/api/questionnaire/question', { params: { use: use } })
            .then(isSuccess);
    },
};
