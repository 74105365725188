import { PageInfo } from '@/components/UI/PaginationControl/types';
import { useDocumentTitle } from '@/composables/helper/document-helper';
import { useGtm } from '@/composables/helper/gtm-helper';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { BAGGAGE_DRAWER_PROVIDER_KEY, useBaggageDrawerProvider } from '@/composables/provider/baggage-drawer-provider';
import { BAGGAGE_SEARCH_PROVIDER_KEY, useBaggageSearchProvider } from '@/composables/provider/baggage-search-provider';
import { Const } from '@/const';
import type Vue from 'vue';
import { computed, nextTick, onBeforeMount, provide, ref, watch } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Route } from 'vue-router/types/router';
import _ from 'lodash';
import { useBaggageSearch } from '@/composables/baggage-search';
import { useBaggageSearchExcludeCompany } from '@/composables/global/baggage-search-exlucde-company';
import { SortInfo } from '@/_components/ui/types/sort-info';
import { useBaggageSearchCondition } from '@/composables/baggage-search-condition';
import { useBaggageSearchConditionSave } from '@/composables/baggage-search-condition-save';
import { PageUtil } from '@/util';
import { useBaggageAutoSearch } from '@/composables/baggage-auto-search';
import { BaggageSearchFormModel } from '@/models/baggage';
import { Validator } from '@/validator';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

/**
 * 荷物検索ページに必要なデータ・機能を集約して提供する関数
 */
export const useBaggageSearchHelper = () => {
    /* 荷物一覧 */
    const baggageSearchListStore = useBaggageSearch();
    const { state: { form, formValidateRules, baggageList, baggageSortKey }, changePage, changeSort, resetForm } = baggageSearchListStore;
    /* 荷物検索テーブルProvider */
    const baggageSearchProvider = useBaggageSearchProvider(baggageSearchListStore, { newArrival: true, excludeCompany: true, showRecommend: true });
    provide(BAGGAGE_SEARCH_PROVIDER_KEY, baggageSearchProvider);
    const {
        state: { loading, favoriteIdList, list: baggageRow, excludedBaggageCount },
        load: loadList,
        changeReadMarkIdList,
        changeFavoriteMarkIdList,
        selectBaggage,
        changeNegotiation
    } = baggageSearchProvider;
    /* 荷物詳細ドロワー用Provider */
    const baggageDetailProvider = useBaggageDrawerProvider();
    provide(BAGGAGE_DRAWER_PROVIDER_KEY, baggageDetailProvider);
    const {
        state: { baggageFavorite, baggageUnderNegotiation },
        load: loadBaggage,
        clear: clearBaggage,
        markRead,
        changeFavorite
    } = baggageDetailProvider;

    const {
        state: { list: savedBaggageSearchConditions },
        load: loadSearchCondition,
        select: selectSearchCondition,
        save: saveSearchCondition
    } = useBaggageSearchCondition();

    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();

    //
    // helper
    //
    const { currentRoute } = useRoute();
    const { goToNotFound, replaceQuery, goToSettingEmailBaggageAndTruck, } = useRouting();

    const { setTitle } = useDocumentTitle();
    const { gtm } = useGtm();
    const { state: { list: excludedCompanyList }, remove } = useBaggageSearchExcludeCompany();
    const { save: saveCondition, load: loadCondition } = useBaggageSearchConditionSave();
    const { state: { autoSearch }, start: startAutoSearch, stop: stopAutoSearch } = useBaggageAutoSearch(form, async (form: BaggageSearchFormModel) => {
        await search(form.pageNo ?? 1, form.pageSize ?? availablePageSize.value);
        return baggageRow.value;
    });

    //
    // ページ固有の変数
    //
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);
    const visibleSearchDetailCondition = ref<boolean>(false);
    const visibleSearchCondition = ref<boolean>(false);
    const baggageSearchResultRef = ref<Vue>();
    const validationErrorMessage = ref<string | undefined>();
    //
    // computed values
    //
    /**
     * 現在選択中の荷物ID
     */
    const currentBaggageId = computed<number | undefined>({
        get: () => {
            const id = currentRoute.query?.baggageId;
            return id ? Number(id) : undefined;
        },
        set: async (value) => {
            if (routeUpdating.value || currentBaggageId.value === value) return;
            // URLクエリーの更新をリクエスト。onBeforeMountに失敗したら更新されない。
            await replaceQuery(value ? { baggageId: `${ value }` } : {});
        },
    });

    /**
     * ドロワー表示有無
     */
    const drawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && currentBaggageId.value !== undefined;
    });
    const sortInfo = computed<SortInfo>(() => {
        return {
            sortOptions: Const.baggageSortKey.map(each => {
                return {
                    label: each.label,
                    code: each.code,
                    defaultOrder: each.defaultOrder === 'ASC' ? 'ASC' : 'DESC'
                };
            }),
            sortKey: form.value.sortKey,
            sortOrder: form.value.sortOrder,
        };
    });

    const pageInfo = computed<PageInfo>(() => {
        return {
            totalPageCount: baggageList.value.totalPageCount ?? 0,
            totalRecordCount: baggageList.value.totalRecordCount ?? 0,
            currentPage: baggageList.value.currentPageNumber ?? 1,
            currentPageSize: baggageList.value.pageSize ?? Const.DEFAULT_PAGE_SIZE,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });

    const availablePageSize = computed<number>(() => {
        return form.value.pageSize && pageInfo.value.pageSizeOptions.includes(form.value.pageSize.toString() ?? '') ? form.value.pageSize : Const.DEFAULT_PAGE_SIZE;
    });

    const circleOptions = computed(() => {
        return myProfile.value?.circles?.map(each => {
            return {
                label: each.name,
                value: each.id,
                key: each.id
            };
        });
    });

    const isMemberOfSingleCircle = computed(() =>
        circleOptions.value?.length === 1
    );

    //
    // watch
    //
    // baggageIdが変わったときの処理
    // baggageIdが変化した = URLが変わった = 正常に画面遷移が完了した
    watch(currentBaggageId, async (newValue, _oldValue) => {
        const pageTitle = ['荷物検索'];
        if (newValue) {
            pageTitle.unshift('荷物詳細');
            // 既読に変更
            await markRead(newValue);
        }
        // 一覧の選択状態に反映
        selectBaggage(newValue);
        // ブラウザのtitleを変更
        setTitle(pageTitle);
        // GTMへページビューイベントを送信
        gtm.setPage(currentRoute.fullPath, pageTitle.join(Const.PAGE_TITLE_SEPARATOR));
    }, { immediate: true });

    // お気に入り保存済み状態を同期
    watch(baggageFavorite, (newValue) => {
        if (!_.isNil(newValue)) {
            changeFavoriteMarkIdList(newValue.baggageId, newValue.favorite);
        }
    });
    // 商談中状態を同期
    watch(baggageUnderNegotiation, (newValue) => {
        if (!_.isNil(newValue)) {
            changeNegotiation(newValue.baggageId, newValue.underNegotiation);
        }
    });
    watch(favoriteIdList, async (_newIdList) => {
        if (currentBaggageId.value) {
            await changeFavorite();
        }
    });

    const showSearchDetailConditionIfNeeded = () => {
        visibleSearchDetailCondition.value = !_.isNil(form.value?.share) || !_.isEmpty(form.value?.category?.code);
    };

    const scrollToSearchResult = () => {
        if (baggageSearchResultRef.value) {
            PageUtil.scrollToContentTop((baggageSearchResultRef.value?.$el as HTMLDivElement).offsetTop);
        }
    };

    const search = async (pageNo: number, pageSize: number) => {
        if (!validate()) return Promise.resolve(false);

        await loadList(pageNo, pageSize);
        return true;
    };

    const validate = () => {
        const validated = Validator.validateDateRangeForSearch(
            form.value.departureFrom,
            form.value.departureTo,
            form.value.arrivalFrom,
            form.value.arrivalTo
        );
        validationErrorMessage.value = validated.message ?? undefined;
        return validated.result;
    };

    //
    // UIのイベントに対応する関数
    //
    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    const onChangePage = async (param: { pageNo: number, pageSize: number, sortKey: string, sortOrder: 'ASC' | 'DESC' }): Promise<void> => {
        let changeReason: string = '';
        if (form.value.sortKey !== param.sortKey) {
            changeReason = 'sortKey';
        }
        changeSort(param.sortKey, param.sortOrder);
        changePage(param.pageNo, param.pageSize);

        const success = await search(param.pageNo, param.pageSize);
        if (success) {
            saveCondition(form.value);
            switch (changeReason) {
                case 'sortKey':
                    break;
            }
            form.value.pageSize = param.pageSize;
            scrollToSearchResult();
        }
    };

    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    const onClickCloseBaggageDetailDrawer = () => {
        currentBaggageId.value = undefined;
    };

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    const onClickGround = async () => {
        // 連打によるルーティングの多重実行を防止
        if (routeUpdating.value) return;
        if (drawerVisibility.value) {  // ドロワー表示中 = 荷物情報がロード済み
            onClickCloseBaggageDetailDrawer();
        }
    };

    /**
     * 荷物一覧の選択中荷物が変更されると呼び出されます。
     */
    const onSelectedBaggageId = (baggageId: number | undefined) => {
        currentBaggageId.value = baggageId;
    };

    /**
     * 除外企業の削除ボタンが押された際に呼び出されます。
     */
    const onClickRemoveCompany = async (companyId: number) => {
        await remove(companyId);
    };

    /**
     * 検索ボタンが押された際に呼び出されます。
     */
    const onClickSubmit = async () => {
        const success = await search(1, form.value.pageSize ?? availablePageSize.value);
        if (success) {
            saveCondition(form.value);
            scrollToSearchResult();
        }
    };

    const onClickClear = () => {
        resetForm();
        saveCondition(form.value);
    };

    const onClickSaveSearchCondition = async () => {
        await saveSearchCondition(form.value);
    };

    const onClickLoadSearchCondition = async (event: { key: number }) => {
        visibleSearchCondition.value = false;
        const condition = await selectSearchCondition(event.key);
        if (_.isNil(condition)) {
            return;
        }
        const cloned = _.cloneDeep(form.value);
        cloned.applyCondition(condition);
        form.value = cloned;
        const success = await search(1, form.value?.pageSize ?? availablePageSize.value);
        if (success) {
            nextTick(() => showSearchDetailConditionIfNeeded());
        }
    };

    const onClickSearchConditionSetting = async () => {
        await goToSettingEmailBaggageAndTruck();
    };

    const onClickModifySearchForm = async () => {
        PageUtil.scrollToContentTop();
    };

    const onClickRefresh = async () => {
        const success = await search(form.value.pageNo ?? 1, form.value.pageSize ?? availablePageSize.value);
        if (success) {
            scrollToSearchResult();
        }
    };

    const onClickChangeAutoSearch = async (newValue: boolean) => {
        if (newValue) {
            await startAutoSearch();
        } else {
            stopAutoSearch();
        }
    };

    // 検索条件
    const onClickInvertPref = () => {
        const departurePref = form.value.departurePref;

        form.value.departurePref = form.value.arrivalPref;
        form.value.arrivalPref = departurePref;
    };

    //
    // LifeCycle hooks
    //
    onBeforeMount(async () => {
        // 荷物検索コンテナを初期化
        const restoredCondition = loadCondition();
        if (restoredCondition) {
            form.value = restoredCondition;
        }
        if (!myProfile.value) await loadMyProfile();
        if (isMemberOfSingleCircle.value) form.value.circleId = myProfile.value?.circles?.[0]?.id;

        const initialPageSize = availablePageSize.value;

        const success = await search(1, initialPageSize);
        if (success) {
            if (currentBaggageId.value) {
                try {
                    await loadBaggage(currentBaggageId.value);
                    selectBaggage(currentBaggageId.value);
                    changeReadMarkIdList(currentBaggageId.value, true);
                } catch {
                    await goToNotFound();
                }
            }
        }
        // よく使う検索条件ロード
        await loadSearchCondition();
    });

    // クエリーが変化した後にロードだと、GTMイベントの発火タイミングなどの都合が悪いので、
    // クエリー変化時の処理が成功したときのみURLを変化させる
    onBeforeRouteUpdate(async (to, from, next) => {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (routeUpdating.value) {
            next(false);
            return;
        }
        const parseBaggageId = (route: Route): number | undefined =>
            route.query.baggageId ? Number(route.query.baggageId) : undefined;

        const toBaggageId = parseBaggageId(to);

        // toがない場合、一覧に戻る
        if (!toBaggageId) {
            await clearBaggage();
            next();
            return;
        }
        // ドロワー表示 or 更新
        const fromBaggageId = parseBaggageId(from);
        routeUpdating.value = true;
        if (fromBaggageId) {
            _.delay(() => routeUpdating.value = false, 100);
        }
        try {
            await loadBaggage(toBaggageId);
            // 一覧の既読状態を変更する
            changeReadMarkIdList(toBaggageId, true);
            next();
        } catch {
            next(false);
        } finally {
            routeUpdating.value = false;
        }
    });

    return {
        baggageRow,
        form,
        formValidateRules,
        loading,
        currentBaggageId,
        drawerVisibility,
        sortInfo,
        pageInfo,
        baggageSortKey,
        excludedCompanyList,
        excludedBaggageCount,
        visibleSearchDetailCondition,
        visibleSearchCondition,
        savedBaggageSearchConditions,
        baggageSearchResultRef,
        autoSearch,
        validationErrorMessage,
        circleOptions,
        myProfile,
        onChangePage,
        onClickGround,
        onClickCloseBaggageDetailDrawer,
        onSelectedBaggageId,
        onClickRemoveCompany,
        onClickSubmit,
        onClickClear,
        onClickSaveSearchCondition,
        onClickLoadSearchCondition,
        onClickSearchConditionSetting,
        onClickModifySearchForm,
        onClickRefresh,
        onClickChangeAutoSearch,
        // 検索
        onClickInvertPref,
    };
};
