import dayjs from 'dayjs';
import { Agreement } from '@/models/agreement';
import { DateUtil } from '@/util/date';

export class AgreementUtil {
    /**
     * 入金予定日
     * @param arrivalDate
     * @param useGuarantee
     */
    static availablePaymentDateRange(arrivalDate: dayjs.Dayjs, useGuarantee: boolean): [dayjs.Dayjs, dayjs.Dayjs] {
        switch (useGuarantee) {
            case true: {
                // 運賃全額保証を利用する場合（着日の3日後から92日間）
                const startOfRange = arrivalDate.startOf('day').add(3, 'day');
                const endOfRange = startOfRange.add(91, 'day');
                return [startOfRange, endOfRange];
            }
            case false: {
                // 運賃全額保証を利用しない場合（着日から1年間）
                const startOfRange = arrivalDate.startOf('day');
                const endOfRange = startOfRange.add(1, 'year').subtract(1, 'day');
                return [startOfRange, endOfRange];
            }
        }
    }

    /**
     * 成約を変更できるか否かを取得します。
     * @param agreement
     * @param now
     */
    static canEdit(agreement: Agreement, now: dayjs.Dayjs = DateUtil.now()): boolean {
        if (agreement.status.code !== 'ACTIVE') return false;
        return this.isWithinChangeDeadline(agreement, now);
    }

    /**
     * 成約の変更期限内であるか否かを取得します。
     * @param agreement
     * @param now
     */
    static isWithinChangeDeadline(agreement: Agreement, now: dayjs.Dayjs = DateUtil.now()): boolean {
        // 変更期限を過ぎているかチェック
        if (now.isAfter(DateUtil.parseDatetimeText(agreement.changeLimitTm))) return false;
        // 入金予定日を過ぎているかチェック
        if (now.isAfter(DateUtil.parseDateText(agreement.paymentDate).endOf('day'))) return false;
        return true;
    }

    /**
     * 入金予定日を取得します。
     */
    static paymentDate(departureDate: dayjs.Dayjs, cutOffDayCode: number | undefined, paymentMonthCode: number | undefined, paymentDayCode: number | undefined): dayjs.Dayjs {
        // 締め日、支払い月、支払い日が未指定の場合
        if (cutOffDayCode === undefined || paymentMonthCode === undefined || paymentDayCode === undefined) {
            // 発日月の翌々月の末日
            return departureDate.add(2, 'month').endOf('month');
        }

        // 締め月を計算する
        const cutoffMonth = departureDate.date() <= cutOffDayCode ? departureDate.startOf('month') : departureDate.add(1, 'month').startOf('month');
        // 支払月を計算する
        const paymentMonth = cutoffMonth.add(paymentMonthCode, 'month');
        // 支払日を計算する
        const paymentDate = paymentDayCode == 99 ? paymentMonth.endOf('month') : paymentMonth.date(paymentDayCode);

        return paymentDate;
    }
}
