import _ from 'lodash';
import { VNode } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageRegisterForm } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { CompanyStaffNameSuggestionList } from '@/vuex/modules/company/types';

@Component
export default class BaggageStaffNameRegister extends Vue {
    @Prop()
    declare readonly value?: BaggageRegisterForm;
    @Prop()
    declare readonly suggestions?: CompanyStaffNameSuggestionList;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get staffName(): string {
        return this.value?.staffName ?? '';
    }

    set staffName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.staffName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get staffNameSuggestions(): Array<string> {
        return this.suggestions?.staffNames ?? [];
    }

    /**
     * サジェストの項目と入力文字列を照合して絞り込みを行います。
     */
    onClickDelete(value: string, event: MouseEvent): void {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        // 削除APIを実行
        this.$emit('deleteStaffNameSuggestion', value);
    }

    /**
     * サジェストの項目をフィルターします。
     */
    filterOption(input: string, option: VNode): boolean {
        const prop: { title?: string } = option.componentOptions?.propsData ?? {};
        if (!prop) {
            return false;
        }
        return _.isString(prop.title) && prop.title.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.staffName?.trim()) {
            callback('担当者名を入力してください。');
        } else if (this.value.staffName.length > 250) {
            callback('担当者名は250文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
