import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { onBeforeMount, watch } from 'vue';
import { useCompanyProfileUpdate } from '@/composables/company-profile-update';
import { useFormModel } from '@/composables/form-helper';
import _ from 'lodash';

export const useSettingCompanyProfileHelper = () => {
    const { state: { myProfile }, load: loadCompanyMyProfile } = useCompanyMyProfile();
    const { state: { form, formValidateRules, loading, }, update, initialize, } = useCompanyProfileUpdate();

    const { submit, formModel } = useFormModel();

    const onSubmit = async () => {
        await submit(async () => await update());
    };

    watch(() => {
        return { profile: myProfile.value };
    }, ({ profile }) => {
        if (_.isNil(profile)) return;
        initialize(profile);
    }, { immediate: true });

    // 画面表示時は常にロードする
    onBeforeMount(async () => {
        await loadCompanyMyProfile();
    });

    return {
        myProfile,
        form,
        formValidateRules,
        formModel,
        loading,
        onSubmit,
    };
};
