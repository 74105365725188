<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useAgreementPrintHelper } from '@/_pages/Agreement/Print/agreement-print-helper';
import { Const } from '@/const';

const props = defineProps<{
    agreementId: number,
}>();
const {
    onClickPrint,
    onClickClose,
    dispatchForm,
    agreement,
    truckCompany,
    baggageCompany,
    dispatchCompanyName,
    filterOption,
    onClickDelete,
    dispatchedCarNumberList,
    dispatchedDriverNameList,
    informationComment,
} = useAgreementPrintHelper(props.agreementId);
</script>

<template>
    <page-layout :show-header="false">
        <div class="print-container">
            <div class="print">
                <section class="print__header">
                    <h1>成約内容の詳細</h1>
                    <nav class="view-screen-only">
                        <ul class="print__header_actions">
                            <li>
                                <a-button @click="onClickPrint" type="primary" size="large">印刷</a-button>
                            </li>
                            <li>
                                <a-icon @click="onClickClose" type="close" :style="{ fontSize: '20px' }"></a-icon>
                            </li>
                        </ul>
                    </nav>
                </section>
                <section class="print__content">
                    <div class="agreement print__content__table-container">
                        <table class="print__content__table">
                            <colgroup>
                                <col style="width: 20%;"/>
                                <col style="width: 30%;"/>
                                <col style="width: 20%;"/>
                                <col style="width: 30%;"/>
                            </colgroup>
                            <tr>
                                <th>成約番号</th>
                                <td>{{ agreement?.id }}</td>
                                <th>成約日時</th>
                                <td>{{ agreement?.entryTm.format('YYYY年MM月DD日 H:mm') }}</td>
                            </tr>
                            <tr>
                                <th>荷物情報番号</th>
                                <td colspan="3">{{ agreement?.baggageId }}</td>
                            </tr>
                            <tr>
                                <th>成約会社名</th>
                                <td>{{ truckCompany?.name.kanji }}</td>
                                <th>荷主社名</th>
                                <td>{{ baggageCompany?.name.kanji }}</td>
                            </tr>
                            <tr>
                                <th>発日時</th>
                                <td>{{ agreement?.departure.format(Const.DEFAULT_PRINT_DELIVERY_DATE_FORMAT) }}</td>
                                <th>積み時間</th>
                                <td>{{ agreement?.loadingTimeNote }}</td>
                            </tr>
                            <tr>
                                <th>発地</th>
                                <td colspan="3">{{ agreement?.departureLocation }}</td>
                            </tr>
                            <tr>
                                <th>着日時</th>
                                <td>{{ agreement?.arrival.format(Const.DEFAULT_PRINT_DELIVERY_DATE_FORMAT) }}</td>
                                <th>卸し時間</th>
                                <td>{{ agreement?.unloadingTimeNote }}</td>
                            </tr>
                            <tr>
                                <th>着地</th>
                                <td colspan="3">{{ agreement?.arrivalLocation }}</td>
                            </tr>
                            <tr>
                                <th>荷種</th>
                                <td colspan="3">
                                    <span>{{ agreement?.type }} / </span>
                                    <span v-if="agreement?.paletteCountText">{{ agreement?.paletteCountText }} / </span>
                                    <span v-if="agreement?.paletteSizeText">{{ agreement?.paletteSizeText }} / </span>
                                    <span v-if="agreement?.totalCountText">{{ agreement?.totalCountText }} / </span>
                                    <span v-if="agreement?.totalVolumeText">
                                        {{ agreement?.totalVolumeText }}m<sup>3</sup> /
                                    </span>
                                    <span v-if="agreement?.totalWeightText">{{ agreement?.totalWeightText }} / </span>
                                    <span v-if="agreement?.handlingText">{{ agreement?.handlingText }} / </span>
                                    <span>積合：{{ agreement?.share ? '○' : '×' }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>温度帯</th>
                                <td colspan="3">
                                    {{ agreement?.temperatureZoneText }}
                                </td>
                            </tr>
                            <tr>
                                <th>希望車種</th>
                                <td colspan="3">
                                    {{ `重量：${agreement?.truckWeight.label} 車種：${agreement?.truckModel.label}` }}
                                </td>
                            </tr>
                            <tr>
                                <th>車両指定</th>
                                <td colspan="3">
                                    {{
                                        `床高：${agreement?.truckHeight?.label ?? '指定なし'} / 車幅：${agreement?.truckWidth?.label ?? '指定なし'} / 大型：${agreement?.largeTruckText}`
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th>必要装備</th>
                                <td colspan="3">{{ agreement?.truckEquipment }}</td>
                            </tr>
                            <tr>
                                <th>運賃(税別)</th>
                                <td>{{ agreement?.freight.format() }}</td>
                                <th>高速代(税込)</th>
                                <td>{{ agreement?.highwayFareText }}</td>
                            </tr>
                            <tr>
                                <th>待機料(税別)</th>
                                <td>{{ agreement?.waitTimeFeeText }}</td>
                                <th>付帯作業料(税別)</th>
                                <td>{{ agreement?.operationFeeText }}</td>
                            </tr>
                            <tr>
                                <th>搬出料(税込)</th>
                                <td>{{ agreement?.pickingFeeText }}</td>
                                <th>駐車代(税込)</th>
                                <td>{{ agreement?.parkingFeeText }}</td>
                            </tr>
                            <tr>
                                <th>通関料(非課税)</th>
                                <td colspan="3">{{ agreement?.clearanceFeeText }}</td>
                            </tr>
                            <tr>
                                <th>入金予定日</th>
                                <td>{{ agreement?.paymentDate.format('YYYY年MM月DD日') }}</td>
                                <th>成約内容変更期限</th>
                                <td>{{ agreement?.changeLimitTm.format('YYYY年MM月DD日 H:mm') }}</td>
                            </tr>
                            <tr>
                                <th>電話番号</th>
                                <td>{{ baggageCompany?.companyPhone }}</td>
                                <th>FAX番号</th>
                                <td>{{ baggageCompany?.companyFax }}</td>
                            </tr>
                            <tr>
                                <th>備考</th>
                                <td colspan="3" class="baggage-description">{{ agreement?.description }}</td>
                            </tr>
                            <tr>
                                <th>担当者名</th>
                                <td colspan="3">{{ agreement?.staffName }}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="print__content__table-container">
                        <table class="print__content__table">
                            <colgroup>
                                <col style="width: 20%;"/>
                                <col style="width: auto;"/>
                                <col style="width: 20%;"/>
                                <col style="width: auto;"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>会社名</th>
                                <td colspan="3">
                                    <a-input class="row-editable" v-model="dispatchCompanyName"></a-input>
                                </td>
                            </tr>
                            <tr>
                                <th>車種</th>
                                <td colspan="3">
                                    <a-input class="row-editable"></a-input>
                                </td>
                            </tr>
                            <tr>
                                <th>車両ナンバー</th>
                                <td colspan="3">
                                    <a-auto-complete class="row-editable full-width"
                                                     style="width: 100%"
                                                     :default-active-first-option="false"
                                                     :allow-clear="true"
                                                     option-label-prop="title"
                                                     :filter-option="filterOption"
                                                     v-model="dispatchForm.carNumber">
                                        <template #dataSource>
                                            <a-select-option v-for="value in dispatchedCarNumberList"
                                                             :key="value"
                                                             :title="value">
                                                <span>{{ value }}</span>
                                                <a-button style="float: right"
                                                          type="default"
                                                          icon="delete"
                                                          size="small"
                                                          @click="onClickDelete('carNumber', value, $event)"></a-button>
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </td>
                            </tr>
                            <tr>
                                <th>ドライバー名</th>
                                <td>
                                    <a-auto-complete class="row-editable full-width"
                                                     :default-active-first-option="false"
                                                     :allow-clear="true"
                                                     option-label-prop="title"
                                                     :filter-option="filterOption"
                                                     v-model="dispatchForm.driverName">
                                        <template #dataSource>
                                            <a-select-option v-for="value in dispatchedDriverNameList"
                                                             :key="value"
                                                             :title="value">
                                                <span>{{ value }}</span>
                                                <a-button style="float: right"
                                                          type="default"
                                                          icon="delete"
                                                          size="small"
                                                          @click="onClickDelete('driverName', value, $event)"></a-button>
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </td>
                                <th>携帯電話</th>
                                <td>
                                    <a-input class="row-editable"></a-input>
                                </td>
                            </tr>
                            <tr>
                                <th>連絡事項</th>
                                <td colspan="3">
                                    <div class="view-screen-only">
                                        <a-textarea class="row-editable" :auto-size="{ minRows: 3, maxRows: 10 }"
                                                    v-model="informationComment"></a-textarea>
                                    </div>
                                    <div class="information-comment">
                                        <p class="view-print-only">{{ informationComment }}</p>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <section class="print__footer">
                    <small>転載等、一切の二次利用を禁止します。</small>
                </section>

<!--                NOTE 企業情報はprivate-traboxでは利用してないのでコメントアウトしておきます -->
<!--                <section class="print__header">-->
<!--                    <h1>企業情報</h1>-->
<!--                </section>-->
<!--                <section class="print__content" v-if="partnerProfile && partnerConfidence && partnerStatistics">-->
<!--                    <company-print :profile="partnerProfile"-->
<!--                                   :official-company="partnerOfficialCompany"-->
<!--                                   :confidence="partnerConfidence"-->
<!--                                   :statistics="partnerStatistics"/>-->
<!--                </section>-->
<!--                <section class="print__footer">-->
<!--                    <small>転載等、一切の二次利用を禁止します。</small>-->
<!--                </section>-->
            </div>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
@import '../../../../less/common-styles/print-layout.less';

.agreement {
    th {
        white-space: nowrap;
    }

    td {
        word-break: break-all;
    }

    th, td {
        font-size: 13px;
    }
}

.information-comment {
    padding: 5px 12px;

    > p {
        margin: 0;
        word-break: break-all;
        white-space: pre-wrap;
    }
}

.word-break-text {
    white-space: pre-wrap;
    word-break: break-all;
}

.print__content__table-container + .print__content__table-container {
    margin-top: 10px;
}

.full-width {
    width: 100%;
}

.baggage-description {
    white-space: pre-wrap;
}

</style>
