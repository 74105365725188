import _ from 'lodash';
import { FormModel } from 'ant-design-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import * as agreementTypes from '@/vuex/modules/agreement/types';
// @ts-ignore
import UiDrawerLayout from '@/components/UI/Layouts/DrawerLayout';
// @ts-ignore
import Spot from '@/components/Agreement/Edit/Spot';
// @ts-ignore
import Type from '@/components/Agreement/Edit/Type';
// @ts-ignore
import Freight from '@/components/Agreement/Edit/Freight';
// @ts-ignore
import HighwayFare from '@/components/Agreement/Edit/HighwayFare';
// @ts-ignore
import WaitTimeFee from '@/components/Agreement/Edit/WaitTimeFee';
// @ts-ignore
import OperationFee from '@/components/Agreement/Edit/OperationFee';
// @ts-ignore
import PickingFee from '@/components/Agreement/Edit/PickingFee';
// @ts-ignore
import ParkingFee from '@/components/Agreement/Edit/ParkingFee';
// @ts-ignore
import ClearanceFee from '@/components/Agreement/Edit/ClearanceFee';
// @ts-ignore
import PaymentDate from '@/components/Agreement/Edit/PaymentDate';
// @ts-ignore
import BaggageShape from '@/components/Agreement/Edit/Shape';
// @ts-ignore
import PaletteCount from '@/components/Agreement/Edit/PaletteCount';
// @ts-ignore
import PaletteSize from '@/components/Agreement/Edit/PaletteSize';
// @ts-ignore
import BaggageTotalCount from '@/components/Agreement/Edit/TotalCount';
// @ts-ignore
import BaggageTotalVolume from '@/components/Agreement/Edit/TotalVolume';
// @ts-ignore
import BaggageTotalWeight from '@/components/Agreement/Edit/TotalWeight';
// @ts-ignore
import BaggageHandling from '@/components/Agreement/Edit/Handling';
// @ts-ignore
import Description from '@/components/Agreement/Edit/Description';
// @ts-ignore
import TemperatureZonePicker from '@/components/Agreement/Edit/TemperatureZone';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

@Component({
    components: {
        UiDrawerLayout,
        Spot,
        Type,
        Freight,
        HighwayFare,
        WaitTimeFee,
        OperationFee,
        PickingFee,
        ParkingFee,
        ClearanceFee,
        PaymentDate,
        BaggageShape,
        PaletteCount,
        PaletteSize,
        BaggageTotalCount,
        BaggageTotalVolume,
        BaggageTotalWeight,
        BaggageHandling,
        Description,
        TemperatureZonePicker,
    },
})
export default class AgreementEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: agreementTypes.Agreement;
    @Prop({ default: false })
    declare readonly visible: boolean;
    @Prop({ default: false })
    declare readonly submitting?: boolean;
    @Prop()
    declare readonly iAmTransporter?: boolean;

    formModel: agreementTypes.AgreementUpdateForm = {
        arrivalAddress: '',
        arrivalCity: '',
        arrivalMax: '',
        arrivalMin: '',
        arrivalPref: {},
        departureAddress: '',
        departureCity: '',
        departureMax: '',
        departureMin: '',
        departurePref: {},
        freight: '',
        highwayFare: 0,
        highwayFareFlg: false,
        waitTimeFee: undefined,
        operationFee: undefined,
        pickingFee: undefined,
        parkingFee: undefined,
        clearanceFee: undefined,
        paymentDate: '',
        type: '',
        shape: { code: '' },
        temperatureZone: { code: '' },
        description: '',
    };

    private originalFormModel: agreementTypes.AgreementUpdateForm | null = null;

    private previousType = this.formModel.type;

    mounted(): void {
        const departure = (this.agreement?.departureMin && this.agreement?.departureMax) ?
            DeliveryDateTimeRange.of(this.agreement?.departureMin, this.agreement?.departureMax) : null;
        const [departureMin, departureMax] = departure?.rawValuesAsString() ?? ['', ''];

        const arrival = (this.agreement?.arrivalMin && this.agreement?.arrivalMax) ?
            DeliveryDateTimeRange.of(this.agreement?.arrivalMin, this.agreement?.arrivalMax) : null;
        const [arrivalMin, arrivalMax] = arrival?.rawValuesAsString() ?? ['', ''];

        this.formModel.departureMin = departureMin;
        this.formModel.departureMax = departureMax;
        this.formModel.departureType = departure?.type;
        this.formModel.departurePref = { code: this.agreement?.departurePref.code ?? '' };
        this.formModel.departureCity = this.agreement?.departureCity ?? '';
        this.formModel.departureAddress = this.agreement?.departureAddress;
        this.formModel.arrivalMin = arrivalMin;
        this.formModel.arrivalMax = arrivalMax;
        this.formModel.arrivalType = arrival?.type;
        this.formModel.arrivalPref = { code: this.agreement?.arrivalPref.code ?? '' };
        this.formModel.arrivalCity = this.agreement?.arrivalCity ?? '';
        this.formModel.arrivalAddress = this.agreement?.arrivalAddress;
        this.formModel.type = this.agreement?.type ?? '';
        this.formModel.shape = { code: this.agreement?.shape.code ?? '' };
        this.formModel.temperatureZone = { code: this.agreement?.temperatureZone.code ?? '' };
        this.formModel.paletteCount = this.agreement?.paletteCount?.toString();
        this.formModel.paletteHeight = this.agreement?.paletteHeight?.toString();
        this.formModel.paletteWidth = this.agreement?.paletteWidth?.toString();
        this.formModel.totalCount = this.agreement?.totalCount?.toString();
        this.formModel.totalVolume = this.agreement?.totalVolume?.toString();
        this.formModel.totalWeight = this.agreement?.totalWeight?.toString();
        this.formModel.loading = this.agreement?.loading ? { code: this.agreement.loading.code } : undefined;
        this.formModel.unloading = this.agreement?.unloading ? { code: this.agreement.unloading.code } : undefined;
        this.formModel.freight = `${this.agreement?.freight ?? ''}`;
        this.formModel.highwayFare = this.agreement?.highwayFare ?? '';
        this.formModel.highwayFareFlg = this.agreement?.highwayFareFlg ?? false;
        this.formModel.waitTimeFee = this.agreement?.waitTimeFee ?? undefined;
        this.formModel.operationFee = this.agreement?.operationFee ?? undefined;
        this.formModel.pickingFee = this.agreement?.pickingFee ?? undefined;
        this.formModel.parkingFee = this.agreement?.parkingFee ?? undefined;
        this.formModel.clearanceFee = this.agreement?.clearanceFee ?? undefined;
        this.formModel.paymentDate = this.agreement?.paymentDate ?? '';
        this.formModel.description = this.agreement?.description ?? '';

        this.originalFormModel = _.cloneDeep(this.formModel);
    }

    @Watch('formModel')
    onFormModelChange(): void {
        this.$emit('changeFormDirty', true);
    }

    get shouldShowShapeDetailForPalette(): boolean {
        return this.formModel.shape?.code === BaggageShapeEnum.Palette.code;
    }

    get shouldShowShapeDetailForOther(): boolean {
        return this.formModel?.shape?.code === BaggageShapeEnum.Other.code;
    }

    onChangeShape(): void {
        const shape = this.formModel.shape;
        if (!shape) return;
        if (shape.code === BaggageShapeEnum.Palette.code) {
            this.previousType = this.formModel.type;
            this.formModel.type = 'パレット';
        } else {
            this.formModel.type = this.previousType;
        }
    }

    onCancel(): void {
        this.$emit('cancel');
    }

    /**
     * 積合
     */
    get shared(): string {
        return this.agreement?.share ? '○' : '×';
    }

    /**
     * 車種
     */
    get truckModel(): string {
        return this.agreement?.truckModel?.label ?? '';
    }

    /**
     * 重量
     */
    get truckWeight(): string {
        return this.agreement?.truckWeight?.label ?? '';
    }

    /**
     * 車両指定
     */
    get specifiedTruck(): string {
        if (!this.agreement) return '';

        const height = this.agreement?.truckHeight?.label;
        const width = this.agreement?.truckWidth?.label;
        const largeTruckFlg = this.agreement?.largeTruckFlg;
        const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

        return `床高：${ height ?? '指定なし' } / 車幅：${ width ?? '指定なし' } / 大型：${ largeTruckText }`;
    }

    get isChanged(): boolean {
        return !_.isEqual(this.formModel.arrivalAddress, this.originalFormModel?.arrivalAddress) ||
            !_.isEqual(this.formModel.arrivalCity, this.originalFormModel?.arrivalCity) ||
            !_.isEqual(this.formModel.arrivalMax, this.originalFormModel?.arrivalMax) ||
            !_.isEqual(this.formModel.arrivalMin, this.originalFormModel?.arrivalMin) ||
            !_.isEqual(this.formModel.arrivalPref, this.originalFormModel?.arrivalPref) ||
            !_.isEqual(this.formModel.departureAddress, this.originalFormModel?.departureAddress) ||
            !_.isEqual(this.formModel.departureCity, this.originalFormModel?.departureCity) ||
            !_.isEqual(this.formModel.departureMax, this.originalFormModel?.departureMax) ||
            !_.isEqual(this.formModel.departureMin, this.originalFormModel?.departureMin) ||
            !_.isEqual(this.formModel.departurePref, this.originalFormModel?.departurePref) ||
            !_.isEqual(this.formModel.freight, this.originalFormModel?.freight) ||
            !_.isEqual(this.formModel.highwayFare, this.originalFormModel?.highwayFare) ||
            !_.isEqual(this.formModel.highwayFareFlg, this.originalFormModel?.highwayFareFlg) ||
            !_.isEqual(this.formModel.waitTimeFee, this.originalFormModel?.waitTimeFee) ||
            !_.isEqual(this.formModel.operationFee, this.originalFormModel?.operationFee) ||
            !_.isEqual(this.formModel.pickingFee, this.originalFormModel?.pickingFee) ||
            !_.isEqual(this.formModel.parkingFee, this.originalFormModel?.parkingFee) ||
            !_.isEqual(this.formModel.clearanceFee, this.originalFormModel?.clearanceFee) ||
            !_.isEqual(this.formModel.paymentDate, this.originalFormModel?.paymentDate) ||
            !_.isEqual(this.formModel.type, this.originalFormModel?.type) ||
            !_.isEqual(this.formModel.shape.code, this.originalFormModel?.shape?.code) ||
            !_.isEqual(this.formModel.temperatureZone.code, this.originalFormModel?.temperatureZone?.code) ||
            !_.isEqual(this.formModel.paletteCount, this.originalFormModel?.paletteCount) ||
            !_.isEqual(this.formModel.paletteHeight, this.originalFormModel?.paletteHeight) ||
            !_.isEqual(this.formModel.paletteWidth, this.originalFormModel?.paletteWidth) ||
            !_.isEqual(this.formModel.totalCount, this.originalFormModel?.totalCount) ||
            !_.isEqual(this.formModel.totalVolume, this.originalFormModel?.totalVolume) ||
            !_.isEqual(this.formModel.totalWeight, this.originalFormModel?.totalWeight) ||
            !_.isEqual(this.formModel.loading?.code, this.originalFormModel?.loading?.code) ||
            !_.isEqual(this.formModel.unloading?.code, this.originalFormModel?.unloading?.code) ||
            !_.isEqual(this.formModel.description, this.originalFormModel?.description);
    }

    onSubmit(): void {
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.$emit('submit', { agreementId: this.agreement?.id, form: this.formModel });
        });
    }
}
