import { Karte } from '@/karte';
import store from '@/vuex/store';
import * as predictionTypes from '@/vuex/modules/prediction/types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';

// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 荷物の成約予測を取得します。
 */
export const predictAgreementProbability = async (form: PredictionBaggageAgreementForm): Promise<number> => {
    // 成約予測
    const res: number = await store.dispatch(`prediction/${ predictionTypes.ACTION.PREDICT_AGREEMENT_PROBABILITY }`, form);
    // トラッキング
    Karte.trackPredictBaggageAgreement(form, res);

    return res;
};
