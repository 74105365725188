import _ from 'lodash';
import { Const } from '@/const';
import { Util } from '@/util';
import { JsonUtil } from '@/util/json';
import { FormValidatable, FormValidator } from '@/models/validate-helper';

export interface InquiryForm {
    description: string;
    targetCompanyName?: string;
    targetStaffName?: string;
}

/**
 * お問い合わせフォーム
 */
export class InquiryFormModel implements InquiryForm, FormValidatable<InquiryFormModel> {
    description: string;
    _targetCompanyName?: string;
    _targetStaffName?: string;

    /**
     * コンストラクタ
     */
    constructor(param: Partial<InquiryFormModel> | null = null) {
        this.description = param?.description ?? '';
        this.targetCompanyName = param?.targetCompanyName ?? '';
        this.targetStaffName = param?.targetStaffName ?? '';
    }

    get targetCompanyName(): string {
        return this._targetCompanyName?.trim() ?? '';
    }

    set targetCompanyName(value: string) {
        this._targetCompanyName = Util.emptyStringToUndefined(value);
    }

    get targetStaffName(): string {
        return this._targetStaffName?.trim() ?? '';
    }

    set targetStaffName(value: string) {
        this._targetStaffName = Util.emptyStringToUndefined(value);
    }

    public normalize(): void {
        this.description = this.description.trim();
        this.targetCompanyName = this.targetCompanyName.trim();
        this.targetStaffName = this.targetStaffName.trim();
    }

    validator(): FormValidator<InquiryFormModel> {
        return {
            description: [
                {
                    required: true,
                    message: 'お問い合わせ内容を入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                },
                {
                    max: 500,
                    message: '500文字以内で入力してください。',
                },
            ],
            targetCompanyName: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            targetStaffName: [
                {
                    max: 60,
                    message: '60文字以内で入力してください。',
                },
            ],
        };
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

export interface ReportLatePaymentForm {
    description: string;
    targetCompanyName?: string;
    targetStaffName?: string;
    deliveryDate?: string;
    freight?: string;
    paymentDate?: string;
}

export class ReportLatePaymentFormModel implements ReportLatePaymentForm, FormValidatable<ReportLatePaymentFormModel> {
    description: string;
    targetCompanyName?: string;
    targetStaffName?: string;
    deliveryDate?: string;
    _freight?: string;
    paymentDate?: string;

    constructor(param: Partial<ReportLatePaymentFormModel> | null = null) {
        this.description = param?.description ?? '';
        this.targetCompanyName = param?.targetCompanyName ?? '';
        this.targetStaffName = param?.targetStaffName ?? '';
        this.deliveryDate = param?.deliveryDate ?? '';
        this.freight = param?.freight ?? '';
        this.paymentDate = param?.paymentDate ?? '';
    }

    get freight(): string | undefined {
        return this._freight;
    }

    set freight(value: string | undefined) {
        this._freight = undefined;
        this._freight = Util.parseFreightString(value);
    }

    public normalize(): void {
        this.targetCompanyName = this.targetCompanyName?.trim();
        this.description = this.description.trim();
    }

    public isDirty(): boolean {
        return !_.isEmpty(this.description)
            || !_.isEmpty(this.targetCompanyName)
            || !_.isEmpty(this.targetStaffName)
            || !_.isEmpty(this.deliveryDate)
            || !_.isEmpty(this.freight)
            || !_.isEmpty(this.paymentDate);
    }

    validator(): FormValidator<ReportLatePaymentFormModel> {
        return {
            targetCompanyName: [
                {
                    required: true,
                    whitespace: true,
                    message: '入金遅延先会社名を入力してください',
                },
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                },
            ],
            description: [
                {
                    required: true,
                    whitespace: true,
                    message: 'その他状況などを入力してください',
                },
                {
                    max: Const.MAX_REPORT_LATE_PAYMENT_DESCRIPTION_LENGTH,
                    message: `${ Const.MAX_REPORT_LATE_PAYMENT_DESCRIPTION_LENGTH }文字以内で入力してください。`,
                },
            ]
        };
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

export interface TraboxNotice {
    items: TraboxNoticeItem[];
}

export interface TraboxNoticeItem {
    id: number;
    start: string;
    end: string;
    brandNewEnd: string;
    title: string;
    url: string;
}
