import { computed, onBeforeMount, VNode } from 'vue';
import { AgreementUtil, DateUtil, Util } from '@/util';
import { useRouting } from '@/composables/helper/routing';
import { useBaggage } from '@/composables/baggage';
import { useCompanyProfile } from '@/composables/company-profile';
import { useAcceptedAgreementCount } from '@/composables/accepted-agreement';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useGuaranteeMaster } from '@/composables/guarantee-master';
import { useGuaranteedAmount } from '@/composables/guarantee';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestionRemove,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import { DateValue } from '@/models/vo/date';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useAgreementRegister } from '@/composables/agreement-register';
import { useFormModel } from '@/composables/form-helper';
import {
    useBaggageLoadingOptions,
    useBaggageShapeOptions,
    useBaggageTemperatureZoneOptions,
    useBaggageUnloadingOptions
} from '@/composables/option-helper';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DateTimeValue } from '@/models/vo/datetime';
import { Modal } from 'ant-design-vue';
import GuaranteeRegistrationConfirmDialogContent from '@/_components/parts/GuaranteeRegistrationConfirmDialog.vue';
import AgreementRegistrationConfirmDialogContent from '@/_components/parts/AgreementRegistrationConfirmDialog.vue';
import { useCompanyPayment } from '@/composables/company-payment';
import { useTimer } from '@/composables/helper/time-helper';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useGuaranteeRegister } from '@/composables/guarantee-register';
import { useLoading } from '@/composables/helper/loading-helper';
import { useEntitlement } from '@/composables/entitlement';

type SpecifiedTruckItem = { label: string, value: string };

export const useAgreementRegisterHelper = (baggageId: number) => {
    const { goBack, goToNotFound, goToAgreementAcceptedList } = useRouting();
    const { submit, formModel } = useFormModel();
    const { delay } = useTimer();
    const notification = useNotification();
    const message = useMessage();

    const { state: { baggage, loading: loadingBaggage }, load: loadBaggage } = useBaggage();
    const { state: { profile: companyProfile, loading: loadingProfile }, load: loadProfile } = useCompanyProfile();
    const {
        state: { loading: loadingAcceptedAgreementCount },
        load: loadAcceptedAgreementCount
    } = useAcceptedAgreementCount();
    const { state: { profile: myAccountProfile } } = useAccountMyProfile();
    const { state: { contracts }, load: loadContracts } = useCompanyContracts();
    const {
        state: { list: guaranteePrices, loading: loadingGuaranteeMaster },
        load: loadGuaranteeMaster
    } = useGuaranteeMaster();
    const {
        state: { amount: guaranteedAmount, loading: loadingGuaranteedAmount },
        load: loadGuaranteedAmount
    } = useGuaranteedAmount();
    const { state: { payment, loading: loadingPayment }, load: loadPayment } = useCompanyPayment();
    const {
        state: { suggestionList, loading: loadingStaffNameSuggestions },
        load: loadStaffNameSuggestions
    } = useCompanyStaffNameSuggestions();
    const { remove: removeStaffName } = useCompanyStaffNameSuggestionRemove();
    const { register: registerStaffName } = useCompanyStaffNameSuggestionRegister();
    const { register: registerGuarantee } = useGuaranteeRegister();
    const { state: { loading: loadingGuaranteeRegister }, withLoading } = useLoading();

    const {
        state: { form, formValidateRules, loading: loadingRegister, dirty },
        initForm,
        register
    } = useAgreementRegister();
    const { state: { entitlement }, load: loadEntitlement } = useEntitlement();

    const { options: loadingOptions } = useBaggageLoadingOptions();
    const { options: unloadingOptions } = useBaggageUnloadingOptions();
    const { options: shapeOptions } = useBaggageShapeOptions();
    const { options: temperatureZoneOptions } = useBaggageTemperatureZoneOptions();

    const loading = computed(() => {
        return [
            loadingBaggage.value,
            loadingProfile.value,
            loadingAcceptedAgreementCount.value,
            loadingGuaranteeMaster.value,
            loadingGuaranteedAmount.value,
            loadingPayment.value,
            loadingStaffNameSuggestions.value,
            loadingRegister.value,
            loadingGuaranteeRegister.value,
        ].some(each => each);
    });
    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    const selectableDateRange = computed(() => {
        // 成約登録については、今日の1時間後から1年後の年末まで有効
        return [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    });

    const specifiedTruckItems = computed<SpecifiedTruckItem[]>(() => {
        const items: SpecifiedTruckItem[] = [];
        if (baggage.value?.truckHeight) items.push({ label: '床高', value: baggage.value?.truckHeight.label ?? '' });
        if (baggage.value?.truckWidth) items.push({ label: '車幅', value: baggage.value?.truckWidth.label ?? '' });
        if (!_.isNil(baggage.value?.largeTruckFlg)) items.push({
            label: '大型可否',
            value: baggage.value?.largeTruckFlg ? '可' : '不可'
        });
        return items;
    });

    const existsGuarantee = computed(() => contracts.value?.existsGuarantee);

    const isPaymentDateOutOfGuaranteePeriod = computed(() => {
        if (form.value && form.value.paymentDate) {
            const arrivalDate = DateUtil.parseDatetimeText(form.value.arrivalMax);
            const paymentDate = DateUtil.parseDateText(form.value.paymentDate);
            const range = AgreementUtil.availablePaymentDateRange(arrivalDate, true);
            return !DateUtil.isIncluded(paymentDate, range);
        }
        return false;
    });

    const existsEasyPayment = computed(() => entitlement.value?.active('easyPaymentContract') ?? false);

    const canProxy = computed(() => {
        if (_.isEmpty(form.value.departureMin)) return false;
        return entitlement.value?.active('settlementProxy', new DateTimeValue(form.value.departureMin));
    });

    /**
     * サジェストの項目をフィルターします。
     */
    const staffNameFilterOption = (input: string, option: VNode) => {
        const prop: { title?: string } = option.componentOptions?.propsData ?? {};
        if (!prop) {
            return false;
        }
        return _.isString(prop.title) && prop.title.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     */
    const onClickStaffNameDelete = async (value: string, event: MouseEvent) => {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        // 削除APIを実行
        await removeStaffName(value);
        await loadStaffNameSuggestions();
    };

    /**
     * 運賃全額保証サービスの申し込みボタンを押下した際に呼び出されます。
     */
    const onClickApply = async () => {
        if (!await confirmGuarantee()) return;

        await withLoading(async () => {
            try {
                await registerGuarantee();

                notification.success({
                    message: '運賃全額保証サービスの申し込み手続きが完了しました。',
                    description: 'これより運賃全額保証サービスをご利用いただけます。お申し込みありがとうございました。',
                });

                // サーバーサイドの処理完了猶予
                await delay(500);

                await loadContracts();
            } catch {
                notification.error({
                    message: '運賃全額保証サービスの申し込み手続きができませんでした。',
                    description: '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                });
            }
        });
    };

    const confirmGuarantee = () => {
        const buttonProp = (disabled: boolean) => ({ props: { disabled } });

        return new Promise<boolean>((resolve) => {
            const modal = Modal.confirm({
                title: '運賃全額保証サービスを申し込みますか？',
                content: (h: Function) => h(GuaranteeRegistrationConfirmDialogContent, {
                    props: {
                        payment: payment.value,
                        isPremium: contracts.value?.existsPremiumPlan,
                    },
                    on: {
                        input: (checked: boolean) => modal.update({ okButtonProps: buttonProp(!checked) }),
                    },
                }),
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: '運賃全額保証サービスを申し込む',
                cancelText: 'キャンセル',
                autoFocusButton: 'cancel',
                width: 640,
                okButtonProps: buttonProp(true),
            });
        });
    };

    /**
     * 成約ボタンを押下された際に呼び出されます。
     */
    const onSubmit = (applyGuarantee: boolean) => submit(async () => {
        form.value.guarantee = applyGuarantee;

        if (!await confirmAgreement()) return;

        const failedNotificationKey = 'REGISTER_AGREEMENT_ERROR';
        // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        registerStaffName(form.value.truckStaffName ?? '').catch(() => ({}));
        try {
            const agreementId = await register();
            message.success('荷物成約の手続きが完了しました。');
            await goToAgreementAcceptedList(agreementId);
        } catch {
            notification.error({
                key: failedNotificationKey,
                message: '成約を登録できませんでした。',
                description: '再度ご確認ください。',
            });
        }
    });

    const confirmAgreement = () => {
        const title = existsGuarantee.value
            ? `成約確認（運賃全額保証サービスを利用${ form.value.guarantee ? 'して' : 'しないで' }成約）`
            : '成約確認';
        return new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: title,
                content: (h: Function) => h(AgreementRegistrationConfirmDialogContent, {
                    props: {
                        form: form.value,
                        baggage: baggage.value,
                        existsGuarantee: existsGuarantee.value,
                        guaranteedAmount: guaranteedAmount.value,
                        guaranteePrices: guaranteePrices.value,
                        contractList: contracts.value,
                    },
                }),
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: '成約',
                cancelText: '戻る',
                autoFocusButton: 'cancel',
                width: 864,
            });
        });
    };

    onBeforeMount(async () => {
        if (!Util.isNumeric(baggageId)) return await goToNotFound();
        // 荷物関連情報のデータ取得
        const loadForBaggage = async () => {
            await loadBaggage(baggageId);
            if (!baggage.value) throw new Error(`${ baggageId } is not exists.`);
            await Promise.all([
                loadProfile(baggage.value.companyId),
                loadAcceptedAgreementCount(baggage.value.companyId),
            ]);
        };
        // 契約関連情報のデータ取得
        const loadForContract = async () => {
            const today = DateValue.today();
            await Promise.all([
                loadContracts(),
                loadGuaranteeMaster(),
                loadGuaranteedAmount(today.year, today.monthValue),
                loadPayment().catch(() => {
                }),
                loadStaffNameSuggestions(),
            ]);
        };
        try {
            await Promise.all([
                loadForBaggage(),
                loadForContract(),
            ]);
        } catch {
            await goToNotFound();
        }
        if (baggage.value && myAccountProfile.value) {
            const defaultPaymentDate = (): DateValue | undefined => {
                if (!baggage.value) return undefined;
                if (baggage.value.paymentDate && !_.isEmpty(baggage.value.paymentDate)) return new DateValue(baggage.value.paymentDate);
                const departureDate = dayjs(baggage.value?.departureMin);
                const paymentDate = AgreementUtil.paymentDate(departureDate, companyProfile.value?.cutOffDay?.code, companyProfile.value?.paymentMonth?.code, companyProfile.value?.paymentDay?.code);
                return new DateValue(paymentDate);
            };
            const entitlement = await loadEntitlement(false);

            initForm(baggage.value, myAccountProfile.value, defaultPaymentDate(), entitlement);
        }
    });

    onBeforeRouteLeave((_to, _from, next) => {
        if (dirty.value && !confirm('まだ成約は登録できていませんが、よろしいですか？')) {
            return next(false);
        }
        next();
    });

    return {
        loading,
        form,
        formValidateRules,
        formModel,
        baggage,
        selectableDateRange,
        shapeOptions,
        loadingOptions,
        unloadingOptions,
        temperatureZoneOptions,
        specifiedTruckItems,
        staffNameFilterOption,
        suggestionList,
        onClickStaffNameDelete,
        existsGuarantee,
        isPaymentDateOutOfGuaranteePeriod,
        existsEasyPayment,
        canProxy,
        myAccountProfile,
        onClickBack: goBack,
        onClickApply,
        onSubmit,
    };
};
