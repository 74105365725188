import { useLoading } from '@/composables/helper/loading-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { baggageApi } from '@/repository/api/internal/baggage';
import { ref } from 'vue';

export const useBaggageBulkRegister = () => {
    const { state: { loading }, withLoading } = useLoading();
    const circleId = ref<number | undefined>(undefined);
    const message = useMessage();

    const register = (file: File) => withLoading(async () => {
        try {
            await baggageApi.bulkRegister(circleId.value, file);
            message.success('荷物を登録しました。');
        } catch(e: any) {
            message.error(e?.error?.[0] ?? 'CSVファイルの読み込みに失敗しました。');
        }
    });

    return {
        state: {
            loading,
            circleId,
        },
        register,
    };
};
