import { Enum } from '@/types/enum';
import { CompanyNotice, CompanyNoticeStatus, CompanyNoticeType, agreementTypes } from '@/models/company-notice';
import { Agreement } from '@/models/agreement';
import { CompanyProfile } from '@/models/company';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

export class CompanyNoticeModel {
    id: number;
    companyId: number;
    status: Enum<CompanyNoticeStatus>;
    type: Enum<CompanyNoticeType>;
    objectId: number;
    agreement?: Agreement;
    sourceId: number;
    sourceName: string;
    expireTm: string;
    entryTm: string;
    callbackUrl?: string;

    constructor(data: CompanyNotice, agreements: Agreement[], companyProfiles: CompanyProfile[]) {
        this.id = data.id;
        this.companyId = data.companyId;
        this.status = data.status;
        this.type = data.type;

        // 現状、全ての通知タイプのobjectIdは成約IDを意味しています
        this.objectId = data.objectId;
        this.agreement = agreementTypes.includes(this.type.code) ? agreements.find((agreement) => agreement.id === this.objectId) : undefined;

        this.sourceId = data.sourceId;
        this.sourceName = companyProfiles.find((profile) => profile.id === this.sourceId)?.name.kanji ?? '';

        this.expireTm = data.expireTm;
        this.entryTm = data.entryTm;
        this.callbackUrl = data.callbackUrl;

    }

    /**
     * アバターに表示するテキストを取得します。
     */
    get avatarLabel(): string {
        switch (this.type.code) {
            case 'AGREEMENT_AGREED':
                return '登録';
            case 'ACCEPTED_AGREEMENT_CHANGE':
                return '変更';
            case 'AGREED_AGREEMENT_CHANGE':
                return '変更';
            case 'AGREEMENT_REVOKE':
                return '取消';
            case 'AGREEMENT_REVOKED':
                return '取消';
            case 'ONLINE_ORDER_UPDATE':
            case 'ONLINE_ORDER_CREATE':
            case 'ONLINE_ORDER_REPLY':
            case 'ONLINE_ORDER_REPLY_UPDATE':
            case 'ONLINE_ORDER_DELETE':
                return '依頼書';
        }
    }

    /**
     * お知らせタイトルを取得します。
     */
    get title(): string {
        switch (this.type.code) {
            case 'AGREEMENT_AGREED':
                return `自社荷物の成約（${this.objectId}）の登録がありました`;
            case 'ACCEPTED_AGREEMENT_CHANGE':
                return `自社荷物の成約（${ this.objectId }）の変更申請がありました`;
            case 'AGREED_AGREEMENT_CHANGE':
                return `受託荷物の成約（${ this.objectId }）の変更申請がありました`;
            case 'AGREEMENT_REVOKE':
                return `自社荷物の成約（${ this.objectId }）の取消申請がありました`;
            case 'AGREEMENT_REVOKED':
                return `受託荷物の成約（${ this.objectId }）が取り消しされました`;
            case 'ONLINE_ORDER_CREATE':
                return `${ this.sourceName }様から配送依頼書が届いています`;
            case 'ONLINE_ORDER_UPDATE':
                return `${ this.sourceName }様が配送依頼書を更新しました`;
            case 'ONLINE_ORDER_REPLY':
                return `${ this.sourceName }様が車番を返信しました`;
            case 'ONLINE_ORDER_REPLY_UPDATE':
                return `${ this.sourceName }様が車番を更新しました`;
            case 'ONLINE_ORDER_DELETE':
                return `${ this.sourceName }様が配送依頼書を取り消しました`;
        }
    }

    /**
     * お知らせ本文を取得します。
     */
    get bodies(): string[] {
        switch (this.type.code) {
            case 'AGREEMENT_AGREED':
            case 'ACCEPTED_AGREEMENT_CHANGE':
            case 'AGREED_AGREEMENT_CHANGE':
            case 'AGREEMENT_REVOKE':
            case 'AGREEMENT_REVOKED':
                return [`成約企業: ${ this.sourceName }`];
            case 'ONLINE_ORDER_UPDATE':
            case 'ONLINE_ORDER_CREATE':
            case 'ONLINE_ORDER_REPLY':
            case 'ONLINE_ORDER_REPLY_UPDATE':
            case 'ONLINE_ORDER_DELETE':
                return [
                    `${ this.departureDate }発 → ${ this.arrivalDate }着`,
                    `${ this.departureLocation } → ${ this.arrivalLocation }`
                ];
        }
    }

    /**
     * 成約の発日を取得します。
     */
    get departureDate(): string {
        if (!this.agreement) return '';

        const min = this.agreement.departureMin;
        const max = this.agreement.departureMax;
        return DeliveryDateTime.of(min, max)?.date.format('MM/DD') ?? '';
    }

    /**
     * 成約の着日を取得します。
     */
    get arrivalDate(): string {
        if (!this.agreement) return '';

        const min = this.agreement.arrivalMin;
        const max = this.agreement.arrivalMax;
        return DeliveryDateTime.of(min, max)?.date.format('MM/DD') ?? '';
    }

    /**
     * 成約の発地を取得します。
     */
    get departureLocation(): string {
        if (!this.agreement) return '';

        return `${ this.agreement.departurePref.label }${ this.agreement.departureCity }`;
    }

    /**
     * 成約の着地を取得します。
     */
    get arrivalLocation(): string {
        if (!this.agreement) return '';

        return `${ this.agreement.arrivalPref.label }${ this.agreement.arrivalCity }`;
    }

    /**
     * 成約になった荷物が自社荷物なのか受託荷物なのかを取得します
     */
    get baggageAgreementType(): 'CLIENT' | 'IN_HOUSE' {
        switch (this.type.code) {
            case 'AGREEMENT_AGREED':
            case 'ACCEPTED_AGREEMENT_CHANGE':
            case 'AGREEMENT_REVOKE':
            case 'ONLINE_ORDER_REPLY':
            case 'ONLINE_ORDER_REPLY_UPDATE':
                return 'IN_HOUSE';
            case 'AGREED_AGREEMENT_CHANGE':
            case 'AGREEMENT_REVOKED':
            case 'ONLINE_ORDER_CREATE':
            case 'ONLINE_ORDER_UPDATE':
            case 'ONLINE_ORDER_DELETE':
                return 'CLIENT';
        }
    }

    /**
     * 受託荷物か否かを取得します。
     */
    get isClientBaggage(): boolean {
        return this.baggageAgreementType === 'CLIENT';
    }

    /**
     * 自社荷物か否かを取得します。
     */
    get isInHouseBaggage(): boolean {
        return this.baggageAgreementType === 'IN_HOUSE';
    }

    /**
     * 既読か否かを取得します。
     */
    get markedAsRead(): boolean {
        return this.status.code === 'READ';
    }
}
