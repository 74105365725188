import { useCompanyContracts } from '@/composables/global/company-contracts';
import { computed, watch } from 'vue';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useRoute } from '@/composables/helper/route';
import _ from 'lodash';
import { useComponentRef, useElementScroll } from '@/composables/helper/page-helper';
import { useEntitlement } from '@/composables/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';

export const useSettingHelper = () => {
    const { state: { contracts } } = useCompanyContracts();
    const { state: { companyStatus } } = useCompanyMyStatus();
    const { state: { entitlement } } = useEntitlement();
    const isJudgingCompany = computed<boolean>(() => companyStatus.value?.isJudging ?? false);
    const hasPremiumPlan = computed<boolean>(() => contracts.value?.existsPremiumPlan ?? false);
    // TODO: 権限まわりリファクタリング
    const isLocked = computed<boolean>(() => entitlement.value?.locked?.(DateTimeValue.now()) ?? false);

    const menus = computed<SettingMenu[]>(() => {
        const menus: SettingMenu[] = [
            {
                kind: 'menuItem',
                key: 'applyPremium',
                disabled: isJudgingCompany.value || isLocked.value,
                show: !hasPremiumPlan.value,
                link: { to: '/upgrade', label: 'プレミアムプラン申込' },
            },
            {
                kind: 'subMenu',
                key: 'company',
                disabled: isLocked.value,
                show: true,
                title: '企業情報管理',
                menuItems: [
                    {
                        kind: 'menuItem',
                        key: 'companyProfile',
                        paths: ['/setting/company/profile'],
                        disabled: isLocked.value,
                        show: true,
                        link: { to: '/setting/company/profile', label: '基本情報' },
                    },
                    {
                        kind: 'menuItem',
                        key: 'companyProfileExtra',
                        paths: ['/setting/company/profile/extra'],
                        disabled: isLocked.value,
                        show: true,
                        link: { to: '/setting/company/profile/extra', label: '詳細情報' },
                    },
                ],
            },
            {
                kind: 'menuItem',
                key: 'accounts',
                paths: [
                    '/setting/account/list',
                    '/setting/account/create',
                    '/setting/account/create/complete',
                    '/setting/account/edit',
                ],
                disabled: isJudgingCompany.value || isLocked.value,
                show: true,
                link: { to: '/setting/account/list', label: 'ユーザー管理' },
            },
            {
                kind: 'subMenu',
                key: 'mail',
                title: 'メール受信設定',
                disabled: isJudgingCompany.value || isLocked.value,
                show: true,
                menuItems: [
                    {
                        kind: 'menuItem',
                        key: 'matchingMail',
                        paths: ['/setting/email/baggage-and-truck'],
                        disabled: isJudgingCompany.value || isLocked.value,
                        show: true,
                        link: { to: '/setting/email/baggage-and-truck', label: '荷物情報' },
                    },
                ],
            },
            {
                kind: 'menuItem',
                key: 'companyPlace',
                disabled: isLocked.value,
                show: true,
                link: { to: '/setting/company-place', label: '地点マスタ' },
                paths: ['/setting/company-place'],
            },
        ];
        const filterMenu = (subMenus: SettingMenu[]): SettingMenu[] => {
            return subMenus.filter(each => {
                if (each.show) {
                    if (each.kind === 'subMenu') {
                        each.menuItems = filterMenu(each.menuItems);
                    }
                    return true;
                } else {
                    return false;
                }
            });
        };
        const filteredMenu = filterMenu(menus);
        return filteredMenu;
    });
    const { currentRoute } = useRoute();

    const defaultOpenKeys = ['company', 'mail'];
    const selectedKeys = computed<string[]>(() => {
        const currentPath = currentRoute.path.replace('/_', '');

        const itemToMap = (items: SettingMenu[], definition: { path: string, key: string }[] = []): { path: string, key: string }[] => {
            if (_.isEmpty(items)) {
                return definition;
            }
            const [head, ...tail] = items;
            switch (head.kind) {
                case 'menuItem': {
                    const defs: { path: string, key: string }[] = (head.paths ?? []).map(path => {
                        return { path, key: head.key };
                    });
                    return itemToMap(tail, definition.concat(defs));
                }
                case 'subMenu': {
                    return itemToMap([...head.menuItems ?? [], ...tail], definition);
                }
                default: {
                    // @ts-ignore
                    const _exhaustiveCheck: never = head;
                    return definition;
                }
            }
        };

        const definition = itemToMap(menus.value).sort((lhs, rhs) => rhs.path.length - lhs.path.length);
        const foundKey = definition.find(each => currentPath.startsWith(each.path))?.key;
        return foundKey ? [foundKey] : [];
    });
    const { component: contentContainer, el } = useComponentRef();
    const { scrollToTop } = useElementScroll(el);

    watch(() => currentRoute.path, () => {
        scrollToTop();
    });

    return {
        menus,
        selectedKeys,
        defaultOpenKeys,
        contentContainer,
    };
};

type SettingMenuLink = {
    to: string,
    label: string,
};

interface MenuNode {
    key: string;
    paths?: string[];
    disabled: boolean;
    show: boolean;
}

interface SettingMenuItem extends MenuNode {
    kind: 'menuItem';
    link: SettingMenuLink;
}

interface SettingSubMenu extends MenuNode {
    kind: 'subMenu';
    title: string;
    menuItems: SettingMenu[];
}

type SettingMenu = SettingMenuItem | SettingSubMenu;
