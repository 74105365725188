<script setup lang="ts">

import SpotSelectInput from '@/_components/ui/SpotSelectInput.vue';
import DateTimePicker from '@/_components/ui/DateTimePicker.vue';
import { useTruckEditHelper } from '@/_pages/Truck/Edit/truck-edit-helper';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import TruckTypeSelect from '@/_components/ui/TruckTypeSelect.vue';
import FreightInput from '@/_components/ui/FreightInput.vue';
import PageLayout from '@/_components/PageLayout.vue';
import { useFormModelItem } from '@/composables/form-helper';

const props = defineProps<{
    truckId?: number,
}>();

const {
    loading,
    formModel,
    formValidateRules,
    formModelRef,
    suggestionList,
    cols,
    circleOptions,
    onClickBack,
    onSubmit,
} = useTruckEditHelper(props.truckId);
const { formModelItem: formItemDepartureDateTime, onChange: onChangeDepartureDateTime } = useFormModelItem();
const { formModelItem: formItemArrivalDateTime, onChange: onChangeArrivalDateTime } = useFormModelItem();
const onChangeDateTime = () => {
    onChangeDepartureDateTime();
    onChangeArrivalDateTime();
};
</script>

<template>
    <page-layout @back="onClickBack">
        <a-spin :spinning="loading">
            <a-form-model ref="formModelRef"
                          :rules="formValidateRules"
                          :model="formModel"
                          :colon="false"
                          :label-col="{ sm: 6, lg: 4 }"
                          :wrapper-col="{ sm: 18, lg: 20 }">
                <a-card id="jsi-register-form" class="form-card">
                    <!-- 発着日時、発着地 -->
                    <div>
                        <a-row :gutter="[16, 0]">
                            <a-col :sm="24" :lg="10">
                                <a-form-model-item label="空車地"
                                                   :label-col="cols.first.labelCol"
                                                   :wrapper-col="cols.first.wrapperCol"
                                                   prop="departureDateTime"
                                                   ref="formItemDepartureDateTime">
                                    <date-time-picker style="width: 100%"
                                                      :valid-range="formModel.selectableDateRange"
                                                      v-model="formModel.departureDateTime"
                                                      @change="onChangeDateTime"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :sm="24" :lg="14">
                                <spot-select-input label=""
                                                   prop-name="departureSpot"
                                                   :label-col="cols.second.labelCol"
                                                   :wrapper-col="cols.second.wrapperCol"
                                                   :show-address="false"
                                                   v-model="formModel.departureSpot">
                                    <template #prefTitle>
                                        <img src="/img/ic-departure.svg" width="24" height="24" alt="空車地"
                                             style="margin-right: 8px"/>空車地
                                    </template>
                                </spot-select-input>
                            </a-col>
                        </a-row>
                        <a-row :gutter="[16, 0]">
                            <a-col :sm="24" :lg="10">
                                <a-form-model-item label="行先地"
                                                   :label-col="cols.first.labelCol"
                                                   :wrapper-col="cols.first.wrapperCol"
                                                   prop="arrivalDateTime"
                                                   ref="formItemArrivalDateTime">
                                    <date-time-picker style="width: 100%"
                                                      :valid-range="formModel.selectableDateRange"
                                                      v-model="formModel.arrivalDateTime"
                                                      @change="onChangeDateTime"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :sm="24" :lg="14">
                                <spot-select-input label=""
                                                   prop-name="arrivalSpot"
                                                   :label-col="cols.second.labelCol"
                                                   :wrapper-col="cols.second.wrapperCol"
                                                   :show-address="false"
                                                   v-model="formModel.arrivalSpot">
                                    <template #prefTitle>
                                        <img src="/img/ic-arrival.svg" width="24" height="24" alt="行先地"
                                             style="margin-right: 8px"/>行先地
                                    </template>
                                </spot-select-input>
                            </a-col>
                        </a-row>
                    </div>
                    <div>
                        <a-form-model-item label="その他対応可能空車地"
                                           :wrapper-col="{ span: 16 }"
                                           prop="departure">
                            <a-input-group compact>
                                <prefecture-select style="width: 50%"
                                                   :multiple="true"
                                                   placeholder="都道府県（複数選択可）"
                                                   title="その他対応可能空車地"
                                                   v-model="formModel.additionalDeparturePrefCode"/>
                            </a-input-group>
                        </a-form-model-item>
                        <a-form-model-item label="その他対応可能行先地"
                                           :wrapper-col="{ span: 16 }"
                                           prop="departure">
                            <a-input-group compact>
                                <prefecture-select style="width: 50%"
                                                   :multiple="true"
                                                   placeholder="都道府県（複数選択可）"
                                                   title="その他対応可能行先地"
                                                   v-model="formModel.additionalArrivalPrefCode"/>
                            </a-input-group>
                        </a-form-model-item>
                    </div>
                    <truck-type-select v-model="formModel.truckType" prop-name="truckType" option-type="truck"/>
                    <freight-input v-model="formModel.minFreightValue" prop-name="minFreightValue" label="最低運賃"/>
                    <!-- 担当者名 -->
                    <!-- TODO コンポーネント化 -->
                    <a-form-model-item label="担当者"
                                       :wrapper-col="{ span: 16 }"
                                       prop="staffName">
                        <a-auto-complete style="width: 33%"
                                         placeholder="担当者名"
                                         class="row-editable"
                                         :default-active-first-option="false"
                                         :allow-clear="true"
                                         option-label-prop="title"
                                         v-model="formModel.staffName">
                            <template #dataSource>
                                <a-select-option v-for="text in suggestionList.staffNames"
                                                 :key="text"
                                                 :title="text">
                                    <span>{{ text }}</span>
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-model-item>

                    <!-- 備考 -->
                    <a-form-model-item label="備考"
                                       :wrapper-col="{ span: 16 }"
                                       prop="description">
                        <a-textarea placeholder="※注意 連絡先として「他営業所の電話番号」を入力することはご遠慮ください。"
                                    v-model="formModel.description"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"/>
                    </a-form-model-item>

                    <!-- 部屋 -->
                    <a-form-model-item label="部屋"
                                       prop="circleId">
                      <a-select :options="circleOptions"
                                v-model="formModel.circleId"
                                placeholder="部屋を選択"
                                style="width: 300px" />
                    </a-form-model-item>

                    <a-row :gutter="[32, 48]">
                        <a-col :offset="10" :span="14"
                               style="display: flex; align-items: flex-start; text-align: center;">
                            <a-button style="max-width: 160px; margin-right: 22px;"
                                      block
                                      type="primary"
                                      size="large"
                                      @click="onSubmit">登録
                            </a-button>
                        </a-col>
                    </a-row>
                </a-card>
            </a-form-model>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
.ant-form {
    padding: 24px;
}

.form-card {
    padding: 16px 24px;
}

.label {
    text-align: end;
}
</style>
