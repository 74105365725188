<script setup lang="ts">
import { useSettingHelper } from '@/_pages/Setting/setting-helper';

const {
    selectedKeys,
    defaultOpenKeys,
    menus,
    contentContainer,
} = useSettingHelper();
</script>

<template>
    <a-layout-content style="height: 100%;">
        <a-layout style="height: 100%;">
            <a-layout-sider>
                <a-menu theme="light"
                        mode="inline"
                        :selected-keys="selectedKeys"
                        :default-open-keys="defaultOpenKeys">
                    <template v-for="item in menus">
                        <a-menu-item v-if="item.kind === 'menuItem'" :key="item.key" :disabled="item.disabled">
                            <router-link :to="item.link.to">{{ item.link.label }}</router-link>
                        </a-menu-item>
                        <a-sub-menu v-if="item.kind === 'subMenu'" :key="item.key">
                            <template #title>
                                <span>{{ item.title }}</span>
                            </template>
                            <template v-for="subItem in item.menuItems">
                                <a-menu-item v-if="subItem.kind === 'menuItem'" :key="subItem.key" :disabled="subItem.disabled">
                                    <router-link :to="subItem.link.to">{{ subItem.link.label }}</router-link>
                                </a-menu-item>
                            </template>
                        </a-sub-menu>
                    </template>
                </a-menu>
            </a-layout-sider>

            <a-layout ref="contentContainer">
                <router-view/>
            </a-layout>
        </a-layout>
    </a-layout-content>

</template>

<style scoped lang="less">

</style>
