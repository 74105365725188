//
// Action
//
import { TraboxNotice } from '@/models/help';

export class ACTION {
    static readonly SUBMIT_INQUIRY = 'SUBMIT_INQUIRY';
    static readonly REPORT_LATE_PAYMENT = 'REPORT_LATE_PAYMENT';
    static readonly LOAD_NOTICE = 'LOAD_NOTICE';
    static readonly START_NOTICE_LISTENER = 'START_NOTICE_LISTENER';
    static readonly STOP_NOTICE_LISTENER = 'STOP_NOTICE_LISTENER';
}

//
// Getter
//
export class GETTER {
    static readonly NOTICE = 'NOTICE';
    static readonly NOTICE_LISTENER = 'NOTICE_LISTENER';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_NOTICE = 'SET_NOTICE';
    static readonly SET_NOTICE_LISTENER = 'SET_NOTICE_LISTENER';
}

//
// Store
//
export interface HelpState {
    notice: TraboxNotice;
    noticeListener?: number;
}

//
// Type for store
//
export * from '@/models/help';
