import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouting } from '@/composables/helper/routing';

export const useRevokePremiumPlanIntroductionHelper = () => {
    const { goBack, goToRevokePremiumPlanEdit, goToUnsubscribe } = useRouting();
    const { state: { profile }, load: loadAccount } = useAccountMyProfile();
    const { state: { contracts }, load: loadContracts } = useCompanyContracts();

    const premiumPlanLabel = computed(() => contracts.value?.premiumPlanContract?.product.label);
    const agreed = ref<boolean>(false);

    const onClickUnsubscribeLink = async () => {
        await goToUnsubscribe();
    };
    const onClickBack = async () => {
        await goBack();
    };
    const onClickAgree = async () => {
        await goToRevokePremiumPlanEdit();
    };

    onBeforeMount(async () => {
        await Promise.all([loadAccount(), loadContracts()]);
    });

    return {
        profile,
        contracts,
        premiumPlanLabel,
        onClickUnsubscribeLink,
        agreed,
        onClickBack,
        onClickAgree,
    };
};
