import _ from 'lodash';
import { Const } from '@/const';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { Location, NavigationGuardNext, Route } from 'vue-router/types/router';
import {
    CompanyContractActivePlanType,
    CompanyContractListModel
} from '@/vuex/modules/company/company-contract-list.model';
import { AxiosError, AxiosResponse } from 'axios';
import { useLogout } from '@/composables/logout';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { tbxRoutes } from '@/router/routes';
import { useEntitlement } from '@/composables/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';
import { CompanyLockTypeCode } from '@/enums/company-lock.enum';

export interface GuardResult {
    nextRoute?: Partial<Location>;
    guardBy: string;
    canceled: boolean;
}

type RoutingGuardContext = Readonly<{
    route: Route;
    fromRoute?: Route;
    now: DateTimeValue;
}>;

/**
 * 指定されたGuardを実行し、遷移先を決定します。
 */
export const appNavigationGuard = (guards: Array<(context: RoutingGuardContext) => Promise<void | GuardResult>>) => (
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
): void => {
    if (!_to.name) {
        throw new Error('route name is not defined.');
    }
    const context: RoutingGuardContext = { route: _to, fromRoute: _from, now: DateTimeValue.now() };

    guards
        // 設定されたGuardを順に実行
        .reduce<Promise<void | GuardResult>>((prevGuard, guard) => prevGuard.then(() => guard(context)), Promise.resolve()) // すべてのGuardを通過した場合、当該ページへのアクセスを許可
        .then(() => {
            next();
        })
        // Guardによって別ページ遷移を要求した場合 or 後続Guardを呼び出さずにアクセス許可を確定させた場合は `.catch()` 配下が呼び出されます。
        .catch((result: GuardResult | Error) => {
            if (result instanceof Error) {
                next(new Error(`cannot route to defined page. [page] => ${ _to.name }, [error] => ${ result }`));
            } else if (result.nextRoute) {
                next(result.nextRoute);
            } else if (result.canceled) {
                next(false);
            } else {
                next();
            }
        });
};

export class RouteGuard {
    /**
     * 現在のRoute.pathが`/_/`配下 && 遷移先に `/_/`配下のコンポーネントが登録されている場合、遷移先を `/_/`配下に変更する
     * 他のGuardでは、`_` 付きのコンポーネントも通すようにする
     */
    static redirectBackComponentGuard = ({ route, fromRoute }: RoutingGuardContext): Promise<void | GuardResult> => {
        if (fromRoute?.path.startsWith('/_/')) {
            // routerインスタンスはここで取得できないので、backComponentがあるかどうかを判定する
            const nextRouteBackComponent = tbxRoutes
                .flatMap(r => [r, ...r.children ?? []])
                .filter(r => r.backComponent !== undefined)
                .find(r => r.name === route.name);
            if (nextRouteBackComponent !== undefined) {
                return Promise.reject<GuardResult>({
                    nextRoute: { name: `_/${ route.name }` },
                    guardBy: 'redirectBackComponentGuard',
                });
            }
        }
        return Promise.resolve();
    };

    /**
     * アカウントの認証状態を基にしてルーティングをガードします。
     * アクセスを通過させたい場合はPromise.resolve()を、アクセスをGuardでブロックさせたい場合はPromise.reject()を返します。
     */
    static loginAndAuthorityGuard = ({ route }: RoutingGuardContext): Promise<void | GuardResult> => {
        const GO2_NEXT_PASS = (): Promise<void> => Promise.resolve();
        const GO2_NEXT_DONE = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: undefined, guardBy: 'LoginAndAuthorityGuard' });
        const GO2_INDEX = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'Index' }, guardBy: 'LoginAndAuthorityGuard' });
        const GO2_LOGIN = (): Promise<GuardResult> =>
            Promise.reject({
                nextRoute: { name: 'Login', query: { return_to: route.fullPath } },
                guardBy: 'LoginAndAuthorityGuard',
            });
        const GO2_PWD = (): Promise<GuardResult> =>
            Promise.reject({
                nextRoute: { name: 'AccountPasswordRegister', query: route.query },
                guardBy: 'LoginAndAuthorityGuard'
            });
        const GO2_LOGOUT = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'Logout' }, guardBy: 'LoginAndAuthorityGuard' });
        const CANCEL = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: undefined, guardBy: 'LoginAndAuthorityGuard', canceled: true });

        const CHECK = (): Promise<accountTypes.ProfileAuthority> => {
            const { load } = useAccountMyProfile();
            return load().then(profile => profile?.authority.code);
            // return store
            //     .dispatch(`account/${accountTypes.ACTION.LOAD_MY_PROFILE}`)
            //     .then(() => store.getters[`account/${accountTypes.GETTER.PROFILE}`] as AccountProfileModel)
            //     .then((profile: AccountProfileModel) => profile.authority.code);
        };

        // デフォルトのアクセステーブル
        const DEFAULT = { result: { preUser: GO2_PWD, user: GO2_NEXT_PASS, ng: GO2_LOGIN } };

        // アクセス許可設定テーブル
        const TABLE = [
            {
                // 非ログインユーザーでもアクセス可能なPath一覧
                // 以下で指定したRouteNameについては、ログインしていない場合のみアクセス可能
                path: [
                    'Login',
                    'AccountComplete',
                    'AccountVerify',
                    'AdditionalAccountVerify',
                    'AccountPasswordRemind',
                    'AccountPasswordRemindComplete',
                    'AccountPasswordResetVerify',
                    'AccountPasswordResetComplete',
                    'AccountRenewalVerify',
                    'AccountRenewalComplete',
                    'UnsubscribeComplete',
                ],
                result: { preUser: GO2_PWD, user: GO2_INDEX, ng: GO2_NEXT_DONE },
            },
            {
                // 初回パスワード設定のPath。preUserのみアクセス可能とする
                path: ['AccountPasswordRegister'],
                result: { preUser: GO2_NEXT_DONE, user: GO2_INDEX, ng: GO2_LOGIN },
            },
            {
                // ログイン状態関係なくアクセス許可するパス
                path: ['AccountRegister', 'AccountEmailVerify'],
                result: { preUser: GO2_PWD, user: GO2_NEXT_PASS, ng: GO2_NEXT_DONE },
            },
            {
                // ログアウトページのPath。preUser以外はログアウト遷移を許可。
                path: ['Logout'],
                result: { preUser: GO2_PWD, user: GO2_NEXT_DONE, ng: GO2_NEXT_DONE },
            },
        ];

        // 遷移先ルートに応じた許可設定を取得
        const definition = _.defaults(
            _.find(TABLE, (each) => _.includes(each.path, route.name?.replace(/^_/, ''))),
            DEFAULT
        );

        // チェック関数を実行し、結果に応じて関数を実行
        return CHECK()
            .catch(async (error: AxiosError & { response: AxiosResponse }) => {
                const errorCode = error.response.data.code;

                // 要認証であれば、ログアウト処理を行っておく
                if (errorCode === 'AUTH_REQUIRED') {
                    const { logout } = useLogout();
                    await logout();
                    // await store.dispatch(`account/${ accountTypes.ACTION.LOGOUT }`);
                }

                const detectDestination = (code: string): () => Promise<GuardResult> => {
                    type ErrorHandler = { code: string; result: () => Promise<GuardResult> };

                    const errorHandlers: ErrorHandler[] = [
                        { code: 'AUTH_REQUIRED', result: GO2_LOGOUT },
                        { code: 'SESSION_EXPIRED', result: CANCEL },
                    ];

                    return _.find(errorHandlers, { code })?.result ?? definition.result.ng;
                };

                return detectDestination(errorCode)();
            })
            .then(
                (authority): Promise<void | GuardResult> => {
                    switch (authority) {
                        case 'PRE_USER':
                            return definition.result.preUser();
                        case 'USER':
                            return definition.result.user();
                        default:
                            throw new Error('profile authority is not defined.');
                    }
                }
            );
    };

    /**
     * 企業ステータスの状態を基にしてルーティングをガードします。
     * アクセスを通過させたい場合はPromise.resolve()を、アクセスをGuardでブロックさせたい場合はPromise.reject()を返します。
     */
    static companyStatusGuard = ({ route }: RoutingGuardContext): Promise<void | GuardResult> => {
        const nextPage = route.name?.replace(/^_/, '') ?? '';
        if (!nextPage) {
            throw new Error('route name is not defined.');
        }
        const GO2_NEXT_PASS = (): Promise<void> => Promise.resolve();
        const GO2_PWD = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'AccountPasswordRegister' }, guardBy: 'CompanyStatusGuard' });
        const GO2_JUDGING = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'Judging' }, guardBy: 'CompanyStatusGuard' });
        const GO2_LOGOUT = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'Logout' }, guardBy: 'CompanyStatusGuard' });
        const GO2_INDEX = (): Promise<GuardResult> =>
            Promise.reject({ nextRoute: { name: 'Index' }, guardBy: 'CompanyStatusGuard' });
        const CHECK_COMPANY_STATUS = async (): Promise<companyTypes.CompanyStatusCode> => {
            const { load, } = useCompanyMyStatus();
            return load()
                .then(status => status.code)
                .catch(err => {
                    throw new Error(err);
                });
            // return store
            //     .dispatch(`company/${companyTypes.ACTION.LOAD_MY_STATUS}`)
            //     .then(() => store.getters[`company/${companyTypes.GETTER.STATUS}`] as companyTypes.CompanyStatus)
            //     .then((status) => status.code)
            //     .catch((err) => {
            //         throw new Error(err);
            //     });
        };

        // 企業ステータスの違いでアクセスを許可するページを指定
        const allowedPagesByCompanyStatus: { [P in companyTypes.CompanyStatusCode]: Array<string> } = {
            TMP: ['AccountPasswordRegister'],
            JUD: [
                'Judging',
                'SettingCompanyProfile',
                'SettingCompanyProfileExtra',
                'SettingCompanyConfidence',
                'SettingCompanyTransfer',
                'SettingCompanyPayment',
            ],
            ACT: [],
            LFT: [],
            REJ: [],
        };
        return CHECK_COMPANY_STATUS().then(
            (companyStatusCode: companyTypes.CompanyStatusCode): Promise<void | GuardResult> => {
                switch (companyStatusCode) {
                    case 'TMP':
                        if (allowedPagesByCompanyStatus[companyStatusCode].includes(nextPage)) {
                            return GO2_NEXT_PASS();
                        } else {
                            return GO2_PWD();
                        }
                    case 'JUD':
                        if (allowedPagesByCompanyStatus[companyStatusCode].includes(nextPage)) {
                            return GO2_NEXT_PASS();
                        } else {
                            return GO2_JUDGING();
                        }
                    case 'ACT':
                        // 審査中のご案内ページにアクセスした場合は、トップページへ遷移
                        if (nextPage === 'Judging') {
                            return GO2_INDEX();
                        }
                        return GO2_NEXT_PASS();
                    case 'REJ':
                    case 'LFT':
                        if (allowedPagesByCompanyStatus[companyStatusCode].includes(nextPage)) {
                            return GO2_NEXT_PASS();
                        } else {
                            return GO2_LOGOUT();
                        }
                    default:
                        throw new Error(`It is not defined status. [companyStatus] => ${ companyStatusCode }`);
                }
            }
        );
    };

    /**
     * 資格状態を基にしてルーティングをガードします。
     */
    static entitlementGuard = async ({ route, now }: RoutingGuardContext): Promise<void | GuardResult> => {
        //
        // TODO: 他のガード処理をこちらに置き換える予定
        //

        const { load } = useEntitlement();

        const nextPage = route.name?.replace(/^_/, '') ?? '';
        if (!nextPage) {
            throw new Error('route name is not defined.');
        }

        type RouteSetting = {
            // 許可されたルートリスト
            allowedRoutes: string[];
            // デフォルトルート
            defaultRoute: GuardResult;
        };

        const routeSetting = (lockType: CompanyLockTypeCode): RouteSetting => {
            return {
                allowedRoutes: [
                    'Index',
                    'SettingBilling',
                    'Inquiry',
                ],
                defaultRoute: {
                    nextRoute: { name: 'Index' },
                    guardBy: 'EntitlementGuard',
                    canceled: false,
                }
            };
        };

        // 資格取得
        const entitlement = await load(true);

        // ロック中かチェック
        const activeLock = entitlement.activeLock(now);
        if (activeLock !== undefined) {
            //
            // ロック中の場合
            //

            const setting = routeSetting(activeLock.lockType.code);

            // `/setting`へのアクセスをご利用金額ページにリダイレクト
            if (nextPage === 'Setting') {
                return Promise.reject({ nextRoute: { name: 'SettingBilling' }, guardBy: 'EntitlementGuard' });
            }

            // 許可されたページ以外へのアクセスはデフォルトページにリダイレクト
            if (!setting.allowedRoutes.includes(nextPage)) {
                return Promise.reject(setting.defaultRoute);
            }
        } else {
            //
            // 未ロックの場合（ロック予約中も含む）
            //

            // `/`へのリクエストを未ロック時のデフォルトであるマイ荷物・成約ページにリダイレクト
            if (nextPage === 'Index') {
                return Promise.reject({ nextRoute: { name: 'BaggageList' }, guardBy: 'EntitlementGuard' });
            }

            // `/setting`へのアクセスを企業基本情報にリダイレクト
            if (nextPage === 'Setting') {
                return Promise.reject({ nextRoute: { name: 'SettingCompanyProfile' }, guardBy: 'EntitlementGuard' });
            }
        }

        return Promise.resolve();
    };

    /**
     * プランの状態を基にしてルーティングをガードします。
     * フリープランで利用している企業のアカウントは、アクセスできるページを制限します。
     * アクセスを通過させたい場合はPromise.resolve()を、アクセスをGuardでブロックさせたい場合はPromise.reject()を返します。
     */
    static planGuard = ({ route }: RoutingGuardContext): Promise<void | GuardResult> => {
        const nextPage = route.name?.replace(/^_/, '');
        if (!nextPage) {
            throw new Error('route name is not defined.');
        }
        const GO_NEXT_PASS = () => Promise.resolve();
        const GO_UPGRADE_PLAN = () =>
            Promise.reject({
                nextRoute: { name: 'UpgradePlan', query: { type: 'access-premium-page' } },
                guardBy: 'FreePlanGuard',
            });
        const GO_PRIME_IS_OVER = () => {
            window.location.href = Const.externalPageUrl.primeIsOver;
            return Promise.reject();
        };

        const allowedFreePlanPaths: Array<string> = [
            'AgreementAcceptedList',
            'AgreementList',
            'BaggageExpiredList',
            'BaggageList',
            'Index',
            'Inquiry',
            'Judging',
            'MiscService',
            'ReportLatePayment',
            'RevokePremiumPlan',
            'Setting',
            'SettingAccountEdit',
            'SettingAccountList',
            'SettingBilling',
            'SettingCompanyConfidence',
            'SettingCompanyContract',
            'SettingCompanyPayment',
            'SettingCompanyProfile',
            'SettingCompanyProfileExtra',
            'SettingCompanyTransfer',
            'Truck',
            'TruckList',
            'Unsubscribe',
            'UnsubscribeEdit',
            'UpgradePlan',
            'UpgradePlanComplete',
            // 取引
            'SettlementHome',
            'SettlementIncomeList',
            'SettlementOutgoList',
            'SettlementIncomePaper',
            'SettlementOutgoPaper',
        ];

        const check = (plan: CompanyContractActivePlanType): Promise<void | GuardResult> => {
            switch (plan) {
                case 'FREE':
                    // 無料会員OK
                    if (allowedFreePlanPaths.includes(nextPage)) return GO_NEXT_PASS();
                    return GO_UPGRADE_PLAN();
                case 'TRIAL':
                case 'PREMIUM':
                    return GO_NEXT_PASS();
                case 'PRIME':
                    return GO_PRIME_IS_OVER();
            }
        };

        return getContractList()
            .then(list => list?.activePlanType ?? 'FREE')
            .then(currentPlan => check(currentPlan));
    };
}

/**
 * 契約一覧を取得します。
 */
const getContractList = async (): Promise<CompanyContractListModel | undefined> => {
    // // Getter
    // const accessor = (): CompanyContractListModel | undefined => store.getters[`company/${ companyTypes.GETTER.CONTRACT_LIST }`];
    // // Action
    // const loader = (): Promise<void> => store.dispatch(`company/${ companyTypes.ACTION.LOAD_CONTRACTS }`).catch((err) => {
    //     throw new Error(err);
    // });
    //
    // return accessor() ?? await loader().then(accessor);
    const { loadIfNotExists } = useCompanyContracts();
    return loadIfNotExists();
};
