import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { ProductUtil } from '@/util';

@Component
export default class AccountListView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly me?: AccountProfileModel;
    @Prop()
    declare readonly accountList?: types.Profile[];

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '担当者',
            key: 'name',
            scopedSlots: { customRender: 'name' },
        },
        {
            title: '役職',
            dataIndex: 'position.label',
        },
        {
            title: 'メールアドレス',
            scopedSlots: { customRender: 'email' },
        },
        {
            title: '',
            key: 'actions',
            scopedSlots: { customRender: 'actions' },
            align: 'right',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    isMe(record: types.Profile): boolean {
        return this.me?.id === record.id;
    }

    name(record: types.Profile): string {
        if (!this.me) return record.name;

        if (this.isMe(record)) return `${record.name}（本人）`;

        return record.name;
    }

    /**
     * 管理者（メインアカウント）のユーザーを判定します。
     */
    isMainAccount(record: AccountProfileModel): boolean {
        return record.mainAccountFlg ?? false;
    }

    /**
     * 削除可能なアカウントかどうかを判定します。
     */
    isDeletable(record: AccountProfileModel): boolean {
        return this.checkDeletableAccount(record).isDeletable;
    }

    /**
     * 削除ができない場合の理由メッセージを取得する
     */
    getDisabledReasonMessage(record: AccountProfileModel): string {
        return this.checkDeletableAccount(record).reason ?? '';
    }

    /**
     * Profileから当該ユーザーが削除可能かどうかできるか確認する
     */
    checkDeletableAccount(record: AccountProfileModel): { isDeletable: boolean; reason: string | undefined } {
        // 1. 自分自身が管理者（メインアカウント）ではない場合
        if (this.me?.isMainAccount !== true) {
            return {
                isDeletable: false,
                reason: 'ユーザーの削除は管理者の方だけが行えます。管理者にご依頼ください。',
            };
        }
        // 2. 自分自身が管理者で、管理者を削除しようとしている場合
        if (record.isMainAccount) {
            return {
                isDeletable: false,
                reason: '管理者を削除することはできません。',
            };
        }
        // 3. 14日お試し期間中の場合
        else if (
            record.product?.code === ProductUtil.Trial14d.code ||
            record.product?.code === ProductUtil.TrialAccount14d.code
        ) {
            return {
                isDeletable: false,
                reason: 'お試し期間中はユーザーの削除はできません。',
            };
        }
        // 4. 有料別途課金で初月無料期間中の場合
        else if (
            record.product?.code === ProductUtil.AccountX.code ||
            record.product?.code === ProductUtil.PremiumX.code ||
            record.product?.code === ProductUtil.PremiumAccountX.code
        ) {
            return {
                isDeletable: false,
                reason: '初月無料利用期間のため、このユーザーは削除できません。翌月以降に行ってください。',
            };
        }

        return { isDeletable: true, reason: undefined };
    }
}
