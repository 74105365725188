import { useRoute as useVueRoute } from 'vue-router/composables';
import { computed } from 'vue';
import { TbxRouteMeta } from '@/router/routes/types';

/**
 * 現在のルート情報を提供します
 */
export const useRoute = () => {
    const route = useVueRoute();

    /**
     * ルーティングで指定されているレイアウトパターンの名称を取得します。
     */
    const routeLayoutName = computed(() => {
        if (route.name === null) {
            return 'blank';
        }
        const layout = route.meta?.layout?.name ?? '';
        if (layout === 'blank') {
            return 'blank';
        }
        return 'main';
    });

    /**
     * ルーティングで指定されているレイアウトパターンで、
     * コンテンツ部のみ表示する（＝ヘッダーバーやサイドバーは隠す）設定になっているかどうかを取得します。
     */
    const isContentOnlyLayout = computed(() => {
        return route.meta?.layout?.contentOnly ?? false;
    });

    /**
     * ルーティングで指定されているレイアウトパターンで、
     * フッター部を表示しない設定になっているかどうかを取得します。
     */
    const isNoFooterLayout = computed(() => {
        return route.meta?.layout?.noFooter ?? false;
    });

    return {
        currentRoute: route,
        currentRouteMeta: route.meta as TbxRouteMeta,
        routeLayoutName,
        isContentOnlyLayout,
        isNoFooterLayout,
    };
};
