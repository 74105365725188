<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import UiDrawerLayout from '@/_components/ui/layouts/DrawerLayout.vue';
import CompanySummary from '@/_components/parts/CompanySummary.vue';
import BaggageTableView from '@/_components/baggage/BaggageTableView.vue';
import TruckTableView from '@/_components/truck/TruckTableView.vue';
import { useCompanyDrawerHelper } from '@/_components/company/company-drawer-helper';

defineProps<{
    visible: boolean,
}>();
const emits = defineEmits<{
    (e: 'close'): void,
    (e: 'clickBaggage', baggage: Baggage): void,
}>();

const {
    loading,
    profile,
    officialCompany,
    confidence,
    statistics,
    baggageList,
    truckList,
    onClickPrint,
} = useCompanyDrawerHelper();
</script>

<template>
    <a-drawer width="600"
              class="drawer"
              :visible="visible"
              :body-style="{ padding: 0 }"
              :mask="false"
              :closable="false"
              :destroy-on-close="true"
              @close="emits('close')">
        <ui-drawer-layout @close="emits('close')">
            <template #header>
                <h3>{{ profile?.companyName }}</h3>
            </template>
            <a-tabs ref="companyDetailTabs"
                    class="drawer-content-tabs"
                    default-active-key="company"
                    :tabBarStyle="{ padding: '12px 0 0 0' }"
                    :animated="false">
                <!--suppress HtmlUnknownTag -->
                <a-tab-pane key="company" tab="企業情報">
                    <a-spin :spinning="loading">
                        <company-summary :confidence="confidence"
                                         :profile="profile"
                                         :official-company="officialCompany"
                                         :statistics="statistics"
                                         class="drawer-content-tabs__company"
                                         :enable-name-link="false"/>
                        <a-button class="print-company" @click="onClickPrint">印刷</a-button>
                    </a-spin>
                </a-tab-pane>

                <!--suppress HtmlUnknownTag -->
                <a-tab-pane key="baggage" tab="荷物一覧">
                    <a-spin :spinning="loading">
                        <baggage-table-view :baggage-list="baggageList"
                                            @clickBaggage="param => emits('clickBaggage', param)"/>
                    </a-spin>
                </a-tab-pane>

                <!--suppress HtmlUnknownTag -->
                <a-tab-pane key="truck" tab="空車一覧">
                    <a-spin :spinning="loading">
                        <truck-table-view :truck-list="truckList"/>
                    </a-spin>
                </a-tab-pane>
            </a-tabs>
        </ui-drawer-layout>
    </a-drawer>
</template>

<style scoped lang="less">
h3 {
    margin-bottom: 0;
}

.drawer {
    ::v-deep(.ui-drawer-layout__body) {
        padding-top: 0;
    }
}

.drawer-content-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: @padding-lg;

    ::v-deep(.ant-tabs-bar) {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    ::v-deep(.ant-tabs-content) {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    &__company {
        padding-top: @padding-xs;
    }

    .print-company {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
