import { Modal } from 'ant-design-vue';
import { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import store from '@/vuex/store';
// @ts-ignore
import AccountList from '@/components/Account/View/List';
import { ModalUtil } from '@/util';
import { ProductCode } from '@/enums/product.enum';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { AccountProfileListModel } from '@/vuex/modules/account/account-profile-list.model';
import _ from 'lodash';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: 'ユーザー管理',
    components: { AccountList },
    beforeRouteEnter: SettingAccountListPage.beforeRouteEnter,
})
export default class SettingAccountListPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @accountMod.Getter(accountTypes.GETTER.PROFILE_LIST)
    readonly PROFILE_LIST?: AccountProfileListModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Properties
    // ======================================================
    /**
     * ユーザー追加可能か否かを判定します。
     * プレミアムプランかつ管理者でのみユーザー追加が可能
     */
    get canCreateUser(): boolean {
        if (!this.ACCOUNT_PROFILE || !this.CONTRACT_LIST) {
            return false;
        }
        return this.ACCOUNT_PROFILE.isMainAccount && (this.CONTRACT_LIST.existsPremiumPlan);
    }

    /**
     * 管理者ユーザー、もしくは追加ユーザー一覧を取得します。※契約が満了ユーザーを除外します
     */
    get visibleAccounts(): AccountProfileModel[] {
        if (!this.PROFILE_LIST) return [];
        if (!this.CONTRACT_LIST) return [];

        const mainAccount = this.PROFILE_LIST.accounts.filter(each => each.isMainAccount);
        const otherAccounts = this.PROFILE_LIST.accounts.filter(each => {
            return !each.isMainAccount && (each.isAdditionalAccount || each.isWaitingForVerification);
        });
        return _.concat(mainAccount, otherAccounts);
    }

    // ======================================================
    // Data
    // ======================================================
    loading = false;

    // ======================================================
    // Functions
    // ======================================================
    /**
     * アカウントの作成ボタンが押下された際に呼び出されます。
     */
    async onClickCreate(): Promise<void> {
        await this.$router.push({ name: 'SettingAccountCreate' });
    }

    /**
     * アカウントの編集ボタンが押下された際に呼び出されます。
     */
    async onClickEdit(): Promise<void> {
        await this.$router.push({ name: 'SettingAccountEdit' });
    }

    /**
     * アカウントの削除ボタンが押下された際に呼び出されます。
     */
    onClickDelete(record: accountTypes.Profile): void {
        const notifyFailedKey = 'DELETE_ACCOUNT_FAILED';
        const onSuccess = () => {
            this.$message.success(`「${record.name}」さんを削除しました。`);
        };
        const onError = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: `「${record.name}」さんを削除できませんでした。`,
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        const deleteAccount = async () => {
            return SettingAccountListPage.deleteAccount(record.id);
        };

        Modal.confirm({
            title: `「${record.name}」さんを削除しますか？`,
            // eslint-disable-next-line
            content: (h: CreateElement): VNode =>
                h(
                    'div',
                    SettingAccountListPage.getModalContent(record).map((message) => h('p', message))
                ),
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                this.$notification.close(notifyFailedKey);
                await deleteAccount().then(onSuccess).catch(onError);
                return;
            },
        });
    }

    private static getModalContent(record: accountTypes.Profile): Array<string> {
        const message = [];
        // TODO(asamac): クーポン対応時にメッセージングを細分化すること
        switch (record.product?.code as ProductCode) {
            case 'ACCOUNT_1M':
                message.push('このユーザーに紐付くプラン契約は、今月末付けで終了となります。');
                break;
            case 'PREMIUM_ACCOUNT_1M':
            case 'ACCOUNT_6M':
            case 'ACCOUNT_1Y':
            case 'PREMIUM_ACCOUNT_6M':
            case 'PREMIUM_ACCOUNT_1Y':
                message.push('ユーザーは削除されますが、プランの契約は継続されます。');
                break;
        }
        message.push('この操作は元に戻すことができません。ご注意ください。');
        return message;
    }

    /**
     * ユーザー追加ができない場合の理由テキストを取得します。
     */
    get reasonText(): string {
        if (this.CONTRACT_LIST?.existsTrialPlan) {
            return 'お試し期間中につき、ユーザーの追加は行えません。';
        }
        if (!this.ACCOUNT_PROFILE?.isMainAccount) {
            return '新しいユーザーの追加は管理者の方だけが行なえます。管理者にご依頼ください。';
        }
        return '';
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingAccountListPage>
    ): Promise<void> {
        await Promise.all([SettingAccountListPage.loadContractList(), SettingAccountListPage.loadAccountList()])
            .then(() => next())
            .catch(() => next({ name: 'NotFound' }));
    }

    /**
     * 企業契約情報をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }

    /**
     * アカウント一覧をロードします。
     */
    private static loadAccountList(): Promise<void> {
        return store.dispatch(`account/${accountTypes.ACTION.LOAD_PROFILE_LIST}`);
    }

    /**
     * 指定されたIDのアカウントを削除します。
     */
    private static deleteAccount(id: number): Promise<void> {
        return store.dispatch(`account/${accountTypes.ACTION.DELETE_PROFILE}`, id);
    }
}
