<script setup lang="ts">
import { Negotiation } from '@/models/negotiation';
import { CompanyProfile } from '@/models/company';
import { PhoneUtil } from '@/util';
import { DateTimeValue } from '@/models/vo/datetime';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

defineProps<{
    company: CompanyProfile;
    negotiation?: Negotiation;
}>();

const emit = defineEmits<{
    (e: 'selectCompany', value: { companyId: number, negotiationId: number }): void,
}>();

const formatNegotiationTime = (tm: string): string => {
    return new DateTimeValue(tm).format('M月DD日 H:mm');
};
</script>

<template>
    <div>
        <a-row type="flex">
            <a-col flex="auto"><span class="component-heading-h3">{{ company.name.kanji }}</span>
            </a-col>
            <a-col flex="200px" style="padding-right: 16px; text-align: right;">
                {{ negotiation ? formatNegotiationTime(negotiation?.messageSendTm) : '' }}
            </a-col>
        </a-row>
        <a-row class="negotiation-card__message-row">
            <a-col><span class="component-body-s">{{ negotiation?.message ?? '' }}</span></a-col>
        </a-row>
        <a-row class="negotiation-card__contact_information">
        <span>
            <tbx-icon type="phone-filled" class="phone-icon"></tbx-icon>
        </span>
            <span class="phone-number">
            {{ PhoneUtil.format(company.phone.number) }}
        </span>
        <span v-if="negotiation" class="staff-name">
            担当: {{ negotiation?.staffName ?? '' }}
        </span>
        </a-row>
        <a-row>
            <a-col>
                <a-button type="link"
                          class="negotiation-card__company-link"
                          @click="emit('selectCompany', { companyId: company.id, negotiationId: negotiation ? negotiation.id : company.id })">
                    企業情報を見る
                    <a-icon type="right"/>
                </a-button>
            </a-col>
        </a-row>
    </div>
</template>

<style scoped lang="less">
.negotiation-card {
    &__contact_information {
        color: @black;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .phone-icon {
            cursor: default;
            transform: scaleX(-1);
        }

        .phone-number {
            margin-right: 16px;
        }

        .staff-name {
            font-size: 0.875rem;
            line-height: 16px;
            font-weight: normal;
        }
    }

    &__company-link {
        padding: 0;
    }

    &__message-row {
        margin-right: 48px;
    }
}
</style>
