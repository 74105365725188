import { ref } from 'vue';
import { useCompanyNotice } from '@/composables/global/company-notice';

/**
 * 企業お知らせを定期取得する機能を提供します。
 */
export const useCompanyNoticeListener = (intervalTime: number = 30 * 60 * 1000 /* 30 minutes */) => {
    const { load } = useCompanyNotice();
    const id = ref<number | undefined>();
    /**
     * 企業お知らせの定期取得（Listen）を開始します。
     */
    const start = () => {
        stop();
        id.value = window.setInterval(async () => {
            await load();
        }, intervalTime);
    };

    /**
     * 企業お知らせの定期取得（Listen）を停止します。
     */
    const stop = () => {
        if (id.value) {
            window.clearInterval(id.value);
        }
    };

    return {
        start,
        stop,
    };
};
