import { Component, Prop, Vue } from 'vue-property-decorator';
import { Enum } from '@/types/enum';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
// @ts-ignore
import UiDateTimeLabelText from '@/components/UI/DateTimeLabelText';

@Component({
    components: {
        UiDateTimeLabelText,
    },
})
export default class UiDateTimeLocationLabel extends Vue {
    @Prop()
    declare readonly departureMin?: string;
    @Prop()
    declare readonly departureMax?: string;
    @Prop()
    declare readonly departurePref?: Enum<PrefectureEnumCode>;
    @Prop()
    declare readonly departureCity?: string;
    @Prop()
    declare readonly arrivalMin?: string;
    @Prop()
    declare readonly arrivalMax?: string;
    @Prop()
    declare readonly arrivalPref?: Enum<PrefectureEnumCode>;
    @Prop()
    declare readonly arrivalCity?: string;

    /**
     * 発地の都道府県ラベルを取得します。
     */
    get departurePrefLabel(): string {
        return this.departurePref?.label ?? '';
    }

    /**
     * 着地の都道府県ラベルを取得します。
     */
    get arrivalPrefLabel(): string {
        return this.arrivalPref?.label ?? '';
    }
}
