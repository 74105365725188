import Router from 'vue-router';
import { Modal } from 'ant-design-vue';
import { appNavigationGuard, RouteGuard } from '@/router/route-guard';
import { PageUtil } from '@/util';
import { routes } from '@/router/routes';

const router = new Router({
    mode: 'history',
    scrollBehavior: (to, from) => {
        // Pathが変化した際は、画面最上部までスクロール
        if (to.path !== from.path) {
            PageUtil.scrollToContentTop(0, false);
        }

        // vue-routerの作法として記載（ただし内部はwindow.scrollであるため機能しない）
        return { x: 0, y: 0 };
    },
    routes: routes,
});

// ルーティングガードを設定
router.beforeEach((to, from, next) => {
    Modal.destroyAll();

    appNavigationGuard([
        RouteGuard.redirectBackComponentGuard,
        RouteGuard.loginAndAuthorityGuard,
        RouteGuard.companyStatusGuard,
        RouteGuard.entitlementGuard,
        RouteGuard.planGuard,
    ])(to, from, next);
});

export default router;
