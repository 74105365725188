import { useRouting } from '@/composables/helper/routing';
import { useBaggage } from '@/composables/baggage';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { computed, onBeforeMount } from 'vue';
import { useOfficialCompany } from '@/composables/company-official';

export const useBaggageSearchPrintHelper = (baggageId?: number) => {
    const { goToNotFound } = useRouting();
    const { state: { baggage, loading: loadingBaggage }, load: loadBaggage } = useBaggage();
    const { state: { profile, loading: loadingProfile }, load: loadProfile } = useCompanyProfile();
    const { state: { officialCompany, loading: loadingOfficialCompany }, load: loadOfficialCompany } = useOfficialCompany();
    const { state: { confidence, loading: loadingConfidence }, load: loadConfidence } = useCompanyConfidence();
    const { state: { statistics, loading: loadingStatistics }, load: loadStatistics } = useCompanyStatistics();

    const loading = computed(() => loadingBaggage.value || loadingProfile.value || loadingOfficialCompany.value || loadingConfidence.value || loadingStatistics.value);

    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    const onClickPrint = () => {
        window.print();
    };
    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    const onClickClose = () => {
        window.close();
    };

    onBeforeMount(async () => {
        if (!baggageId) {
            await goToNotFound();
            return;
        }
        await loadBaggage(baggageId);
        if (!baggage.value) {
            await goToNotFound();
            return;
        }
        await Promise.all([
            loadProfile(baggage.value.companyId),
            loadOfficialCompany(baggage.value.companyId).catch(() => false),
            loadConfidence(baggage.value.companyId),
            loadStatistics(baggage.value.companyId)
        ]);
    });

    return {
        baggage,
        profile,
        officialCompany,
        confidence,
        statistics,
        loading,
        onClickPrint,
        onClickClose,
    };
};
