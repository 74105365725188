<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useSideMenuService } from '@/composables/service/sidemenu-service';
import { computed } from 'vue';

const props = defineProps<{
    // サイドメニューの開閉に合わせてボディ側のサイズを切り替える為
    value?: boolean
}>();
const emits = defineEmits<{
    (e: 'input', value: boolean): void,
}>();

const shouldShowBanner = computed<boolean>(() => !props.value ?? false);
const toggleIcon = computed<string>(() => props.value ? 'menu-unfold' : 'menu-fold');

const { state: { contracts } } = useCompanyContracts();
const { state: { isJudging } } = useCompanyMyStatus();
/**
 * 通知バー（無料お試し期間中 or 企業審査中である旨の通知）を表示すべきか否かを取得します。
 * レイアウトの調整にのみ使用しています。
 */
const notificationBarVisible = computed<boolean>(() => {
    if (isJudging.value) {
        return true;
    }
    // 無料お試し期間中の場合
    return contracts.value?.existsTrialPlan ?? false;
});

const {
    state: {
        defaultSelectedKeys,
        selectedKeys,
        sideMenuGroups,
    }
} = useSideMenuService();
</script>

<template>
    <a-layout-sider breakpoint="xl"
                    :collapsed="value"
                    :collapsible="true"
                    :collapsed-width="64"
                    :width="180"
                    :trigger="null"
                    :class="{ 'ant-layout-sider--has-notification-bar': notificationBarVisible }"
                    @collapse="emits('input', $event);">
        <a-menu theme="light"
                mode="inline"
                :selected-keys="selectedKeys"
                :default-selected-keys="defaultSelectedKeys">
            <a-menu-item-group v-for="group in sideMenuGroups" :key="group.key">
                <a-menu-item v-for="menu in group.menus" :key="menu.key" :disabled="menu.disable">
                    <router-link :to="menu.path">
                        <tbx-icon :type="menu.icon"/>
                        <span>{{ menu.title }}</span>
                    </router-link>
                </a-menu-item>
            </a-menu-item-group>
            <a-menu-item key="bottomAlignedItems" class="bottom-aligned-items" title="">
                <div v-show="shouldShowBanner" id="sidebar-banner-area" v-embed-tag="'sidebar-banner-area'"/>
                <div class="menuitem-toggle" @click="emits('input', !value)">
                    <tbx-icon :type="toggleIcon"/>
                </div>
            </a-menu-item>
        </a-menu>

    </a-layout-sider>
</template>

<style scoped lang="less">
@header-height: 64px;
@menu-item-width-small: 64px;
@menu-item-width-large: 180px;

.ant-layout-sider {
    position: fixed;
    left: 0;
    height: calc(100% - (@header-height + 1px));
    overflow: auto;

    &--has-notification-bar {
        height: calc(100% - (@header-height + 39px + 1px));
    }

    .ant-layout-header {
        img {
            height: 100%;
        }
    }

    @media print {
        display: none;
    }
}

:deep(.ant-menu-item-group-title) {
    padding: 0;
}

.ant-menu {
    padding-top: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-menu-item-group + .ant-menu-item-group {
        .ant-menu-item {
            margin-bottom: 12px;
        }

        border-top: solid 1px @color-gray-5;
        margin-top: 16px;
        padding-top: 16px;
    }

    .ant-menu-item {
        height: @menu-item-height;
        flex: 0 0 @menu-item-height;

        // Normal State
        .ui-icon {
            fill: @text-color;

            &--collapsed {
                transform: scale(1.143);
            }
        }

        // hover state
        &:hover {
            background: @color-junction-blue-hover;

            .ui-icon {
                fill: @menu-highlight-color;
            }
        }

        // selected state
        &.ant-menu-item-selected {
            background: @color-junction-blue;

            a, span {
                color: @white;
            }

            .ui-icon {
                fill: @white;
            }

            &::after {
                display: none;
            }
        }

        // Disabled State
        &.ant-menu-item-disabled {
            &:hover .ui-icon,
            .ui-icon {
                fill: @disabled-color;
            }
        }
    }

    .bottom-aligned-items {
        margin-top: auto;
        flex: 0 0 auto;
        height: auto;

        #sidebar-banner-area {
            display: block;
            margin-bottom: 12px;

            * + * {
                margin-top: 8px;
            }
        }

        .menuitem-toggle > .anticon {
            font-size: 20px;
        }
    }
}
</style>
