//
// Action
//
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { AccountProfileListModel } from '@/vuex/modules/account/account-profile-list.model';
import {
    AccountChangeEmailVerificationForm,
    AccountPasswordRemindForm, AccountPasswordResetForm,
    AccountPasswordResetVerificationForm,
    AccountPasswordUpdateForm,
    AccountRegisterForm,
    AccountVerificationForm,
    LoginForm,
    Profile
} from '@/models/account';

export class ACTION {
    static readonly VERIFY = 'VERIFY';
    static readonly VERIFY_FOR_ADDITIONAL_ACCOUNT = 'VERIFY_FOR_ADDITIONAL_ACCOUNT';
    static readonly VERIFY_EMAIL_CHANGE = 'VERIFY_EMAIL_CHANGE';
    static readonly ISSUE_SSO_DISPATCH_TOKEN = 'ISSUE_SSO_DISPATCH_TOKEN';
    static readonly ISSUE_SSO_ONLINE_ORDER_TOKEN = 'ISSUE_SSO_ONLINE_ORDER_TOKEN';
    static readonly REGISTER = 'REGISTER';
    static readonly LOGIN = 'LOGIN';
    static readonly LOGOUT = 'LOGOUT';
    static readonly LOAD_MY_PROFILE = 'LOAD_MY_PROFILE';
    static readonly LOAD_PROFILE_LIST = 'LOAD_PROFILE_LIST';
    static readonly UPDATE_PASSWORD = 'UPDATE_PASSWORD';
    static readonly REMIND_PASSWORD = 'REMIND_PASSWORD';
    static readonly VERIFY_RESET_PASSWORD = 'VERIFY_RESET_PASSWORD';
    static readonly RESET_PASSWORD = 'RESET_PASSWORD';
    static readonly CREATE_PROFILE = 'CREATE_PROFILE';
    static readonly UPDATE_PROFILE = 'UPDATE_PROFILE';
    static readonly DELETE_PROFILE = 'DELETE_PROFILE';
}

//
// Signature for Action
//
export type verify = (param: AccountVerificationForm) => Promise<void>;
export type verifyForAdditionalAccount = (param: AccountVerificationForm) => Promise<void>;
export type verifyEmail = (param: AccountChangeEmailVerificationForm) => Promise<void>;
export type register = (param: AccountRegisterForm) => Promise<void>;
export type login = (param: LoginForm) => Promise<void>;
export type logout = () => Promise<void>;
export type loadProfile = () => Promise<Profile>;
export type updatePassword = (param: AccountPasswordUpdateForm) => Promise<void>;
export type remindPassword = (param: AccountPasswordRemindForm) => Promise<void>;
export type verifyResetPassword = (param: AccountPasswordResetVerificationForm) => Promise<void>;
export type resetPassword = (param: AccountPasswordResetForm) => Promise<void>;
export type deleteProfile = () => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly PROFILE = 'PROFILE';
    static readonly PROFILE_LIST = 'PROFILE_LIST';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_PROFILE_MODEL = 'SET_PROFILE_MODEL';
    static readonly SET_PROFILE_LIST_MODEL = 'SET_PROFILE_LIST_MODEL';
}

//
// Store
//
export interface AccountState {
    profile?: AccountProfileModel;
    profileList?: AccountProfileListModel;
}

//
// Type for store
//
export * from '@/models/account';
