import { Component, Prop, Vue } from 'vue-property-decorator';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { DateUtil, Util } from '@/util';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

@Component
export default class AgreementDetailModal extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ default: false })
    declare readonly value: boolean;
    @Prop({ default: true })
    declare readonly loading: boolean;
    @Prop()
    declare readonly guaranteedAmount?: companyTypes.CompanyGuaranteedAmount;
    @Prop()
    declare readonly agreementList?: agreementTypes.AgreementList;

    /**
     * 表示/非表示
     */
    get visible(): boolean {
        return this.value;
    }

    set visible(newValue: boolean) {
        this.$emit('input', newValue);
    }

    /**
     * 年
     */
    get year(): string {
        return `${this.guaranteedAmount?.year ?? ''}`;
    }

    /**
     * 月
     */
    get month(): string {
        return `${this.guaranteedAmount?.month ?? ''}`;
    }

    /**
     * 成約金額
     */
    get totalAmount(): string {
        return this.guaranteedAmount?.amount ? Util.formatNumber(this.guaranteedAmount?.amount) : '';
    }

    /**
     * タイトル
     */
    get modalTitle(): string {
        return this.guaranteedAmount && !this.loading
            ? `${this.year}年${this.month}月 月間成約金額 ${this.totalAmount}円（税別）`
            : '';
    }

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '成約番号',
            dataIndex: 'id',
            align: 'center',
        },
        {
            title: '成約日時',
            scopedSlots: { customRender: 'agreementDate' },
        },
        {
            title: '荷物番号',
            dataIndex: 'baggageId',
        },
        {
            title: '成約会社名',
            dataIndex: 'companyName',
        },
        {
            title: '発日発地',
            key: 'departure',
            scopedSlots: { customRender: 'departure' },
        },
        {
            title: '着日着地',
            key: 'arrival',
            scopedSlots: { customRender: 'arrival' },
        },
        {
            title: '運賃(税別)',
            scopedSlots: { customRender: 'freight' },
            align: 'right',
        },
        {
            title: '高速代(税込)',
            scopedSlots: { customRender: 'highwayFare' },
            align: 'right',
        },
        {
            title: '入金日',
            key: 'paymentDate',
            scopedSlots: { customRender: 'paymentDate' },
        },
        {
            title: '保証',
            key: 'guarantee',
            align: 'center',
            scopedSlots: { customRender: 'guarantee' },
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    agreementDateText(record: agreementTypes.Agreement): string {
        return DateUtil.parseDateText(record.entryTm).format(Const.DEFAULT_VIEW_DATETIME_FORMAT);
    }

    departureText(record: agreementTypes.Agreement): string {
        return DeliveryDateTime.of(record.departureMin, record.departureMax)?.format() ?? '';
    }

    arrivalText(record: agreementTypes.Agreement): string {
        return DeliveryDateTime.of(record.arrivalMin, record.arrivalMax)?.format() ?? '';
    }

    paymentDateText(record: agreementTypes.Agreement): string {
        return DateUtil.parseDateText(record.paymentDate).format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    freightText(record: agreementTypes.Agreement): string {
        return Util.formatNumber(record.freight);
    }

    highwayFareText(record: agreementTypes.Agreement): string {
        return Util.formatNumber(record.highwayFare);
    }

    guarantee(record: agreementTypes.Agreement): string {
        return record.guarantee ? '○' : '×';
    }

    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('onChangePage', {
            year: this.guaranteedAmount?.year,
            month: this.guaranteedAmount?.month,
            pageNo,
            pageSize,
        });
    }
}
