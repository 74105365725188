import _ from 'lodash';
import { FormModel } from 'ant-design-vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/truck/types';
import { Util } from '@/util';
// @ts-ignore
import TruckSpot from '@/components/Truck/Edit/Spot';
// @ts-ignore
import TruckAdditionalSpot from '@/components/Truck/Edit/AdditionalSpot';
// @ts-ignore
import TruckType from '@/components/Truck/Edit/TruckType';
// @ts-ignore
import TruckFreight from '@/components/Truck/Edit/Freight';
// @ts-ignore
import TruckStaffName from '@/components/Truck/Edit/StaffName';
// @ts-ignore
import TruckDescription from '@/components/Truck/Edit/Description';
import * as companyTypes from '@/vuex/modules/company/types';
import { namespace } from 'vuex-class';
import { Karte } from '@/karte';
import {
    clearTruck,
    closeFailedToDeleteStaffNotification,
    closeFailedToRegisterNotification,
    confirmClearForm,
    deleteCompanyStaffNameSuggestion,
    goToTruckList,
    loadCompanyStaffNameSuggestions,
    loadTruck,
    notifyFailedToDeleteStaff,
    notifyFailedToRegister,
    registerCompanyStaffNameSuggestion,
    registerTruck
} from '@/pages/Truck/Register/helpers';

const truckMod = namespace('truck');
const companyMod = namespace('company');

@Component({
    title: '空車登録',
    components: {
        TruckSpot,
        TruckAdditionalSpot,
        TruckType,
        TruckFreight,
        TruckStaffName,
        TruckDescription,
    },
    beforeRouteEnter: TruckRegisterPage.beforeRouteEnter,
})
export default class TruckRegisterPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @truckMod.Getter(types.GETTER.TRUCK)
    readonly SOURCE_TRUCK?: types.Truck;
    @companyMod.Getter(companyTypes.GETTER.STAFF_NAME_SUGGESTION_LIST)
    staffNameSuggestions?: companyTypes.CompanyStaffNameSuggestionList;
    // ======================================================
    // Data
    // ======================================================
    loading = false;
    formModel = new types.TruckRegisterFormModel();
    dirty = false;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly sourceTruckId?: string;

    get truck(): types.TruckRegisterFormModel {
        return this.formModel;
    }

    set truck(newTruck: types.TruckRegisterFormModel) {
        this.formModel = _.cloneDeep(newTruck);
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * コピー元空車情報を取得します。(as function because it's not reactive)
     */
    sourceTruck(): types.TruckRegisterFormModel | undefined {
        if (!this.sourceTruckId || !Util.isNumeric(this.sourceTruckId) || !this.SOURCE_TRUCK) return;

        if (Util.toNumber(this.sourceTruckId) !== this.SOURCE_TRUCK.id) return;

        return types.TruckRegisterFormModel.of(this.SOURCE_TRUCK);
    }

    /**
     * フォームバリデーションを実行します。
     */
    private doValidate(callback: ((result: boolean) => void) | undefined = undefined): void {
        (this.$refs.formModel as FormModel).validate(callback);
    }

    //
    // Lifecycle Methods
    //

    created(): void {
        // フォーム初期化：コピー元、新規の順で初期データを構築
        this.truck = this.sourceTruck() ?? new types.TruckRegisterFormModel();
    }

    mounted(): void {
        // 空車コピーによる新規登録の場合、値がセットされた状態となっているためバリデーションを実行
        if (this.sourceTruck()) {
            this.doValidate();
        }
    }

    //
    // Event Handlers
    //

    @Watch('formModel')
    onFormModelChange(): void {
        this.dirty = true;
    }

    /**
     * 登録ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const onSuccess = async (truckId: number) => {
            this.dirty = false;
            this.$message.success('空車情報を登録しました。');
            // KARTEイベント送信：空きトラック登録
            Karte.trackRegisterTruck(truckId);
            await goToTruckList();
        };

        this.doValidate(async (result) => {
            if (!result) return;

            this.loading = true;
            closeFailedToRegisterNotification();

            // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
            registerCompanyStaffNameSuggestion(this.truck.staffName).catch(() => ({}));

            registerTruck(this.truck.toForm())
                .then(onSuccess)
                .catch(notifyFailedToRegister)
                .finally(() => (this.loading = false));
        });
    }

    /**
     * クリアボタンが押下された際に呼び出されます。
     */
    async onClickClear(): Promise<void> {
        if (!await confirmClearForm()) return;

        await clearTruck();
        this.truck = new types.TruckRegisterFormModel();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     * @param staffName
     */
    async onDeleteStaffNameSuggestion(staffName: string): Promise<void> {
        closeFailedToDeleteStaffNotification();

        deleteCompanyStaffNameSuggestion(staffName)
            .catch(notifyFailedToDeleteStaff)
            .then(loadCompanyStaffNameSuggestions);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<TruckRegisterPage>
    ): Promise<void> {
        const popIfArray = (param: string | Array<string | null>) => _.isArray(param) ? _.last(param) : param;
        const sourceTruckId = popIfArray(to.query.sourceTruckId);

        // クエリパラメーターに `sourceTruckId` が無い場合はそのまま表示
        if (!sourceTruckId) {
            await loadCompanyStaffNameSuggestions();
            return next();
        }

        // `sourceTruckId`に数字以外が含まれている場合は、クエリパラメーターをリセット
        if (!Util.isNumeric(sourceTruckId)) {
            return next({ name: 'TruckRegister' });
        }

        await loadTruck(Util.toNumber(sourceTruckId)).then(async () => {
            await loadCompanyStaffNameSuggestions();
            next();
        }).catch(() => next({ name: 'TruckRegister' }));
    }

    /**
     * ルーティングによってこのページから離れる際に呼び出されます。
     */
    async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<TruckRegisterPage>
    ): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると入力中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    }
}
