<script setup lang="ts">
import { computed, ref } from 'vue';
import { CompanyTruckEventType } from '@/enums/truck-event-type.enum';
import InnerModalRegisterMemo from '@/_pages/Truck/Schedule/EventModal/Register/InnerModalRegisterMemo.vue';
import InnerModalRegisterTruck from '@/_pages/Truck/Schedule/EventModal/Register/InnerModalRegisterTruck.vue';
import { Dayjs } from 'dayjs';

const props = defineProps<{
    visible: boolean,
    startTm: Dayjs,
    endTm: Dayjs,
    companyTruckId: number,
}>();

const emit = defineEmits<{
    (e: 'update:visible', value: boolean): void;
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const visible = computed(({
    get: () => props.visible,
    set: (value) => emit('update:visible', value),
}));

const selectedEventType = ref<CompanyTruckEventType>(CompanyTruckEventType.Memo);

// モーダル右上のイベントタイプ選択時
const onSelectEventType = (arg: any) => {
    const eventType = CompanyTruckEventType.valueOf(arg.key);
    eventType != undefined && (selectedEventType.value = eventType);
};

const innerComponent = computed(() => {
    switch (selectedEventType.value) {
        case CompanyTruckEventType.Memo:
            return InnerModalRegisterMemo;
        case CompanyTruckEventType.Empty:
            return InnerModalRegisterTruck;
        default:
            return InnerModalRegisterMemo;
    }
});

const onOk = () => {
    visible.value = false;
    emit('ok');
};

const onCancel = () => {
    visible.value = false;
    emit('cancel');
};

</script>

<template>
    <a-modal v-model:visible="visible" width="800px" :footer="null">
        <template #title>
            <div class="modal-title-section">
                <span class="title">作成</span>
                <span v-if="selectedEventType.label" class="button">
                    <a-dropdown>
                        <template #overlay>
                            <a-menu @click="onSelectEventType">
                                <a-menu-item key="MEMO">メモ</a-menu-item>
                                <a-menu-item key="EMPTY">空車</a-menu-item>
                            </a-menu>
                        </template>
                        <a-button>
                            {{ selectedEventType.label }}
                            <a-icon type="down"/>
                        </a-button>
                    </a-dropdown>
                </span>
            </div>
        </template>
        <component v-bind:is="innerComponent"
                   submitText="作成する"
                   :startTm="startTm"
                   :endTm="endTm"
                   :companyTruckId="companyTruckId"
                   @ok="onOk"
                   @cancel="onCancel"/>
    </a-modal>
</template>

<style scoped lang="less">
.modal-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;

    .title {
        font-size: 20px;
        font-weight: bold;
    }

    .button {
    }
}
</style>
