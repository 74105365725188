import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { computed, nextTick, onBeforeMount } from 'vue';
import { useQuestionnaire } from '@/composables/questionnaire';
import { usePremiumPlanRevoke } from '@/composables/premium-plan';
import { useFormModel } from '@/composables/form-helper';
import { useRouting } from '@/composables/helper/routing';
import { useModal } from '@/composables/helper/modal-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';

export const useRevokePremiumPlanEditHelper = () => {
    const { goToRevokePremiumPlan, goToRevokePremiumPlanComplete } = useRouting();
    const { confirm, error } = useModal();
    const { formModel: formModelRef, submit } = useFormModel();
    const { state: { profile }, load: loadAccount } = useAccountMyProfile();
    const { state: { contracts }, load: loadContracts } = useCompanyContracts();
    const { state: { loading: loadingQuestionnaire, questionnaire }, load } = useQuestionnaire();
    const {
        state: { loading: loadingRevoke, form, formValidateRules, dirty },
        initForm,
        revoke
    } = usePremiumPlanRevoke();

    const loading = computed(() => loadingQuestionnaire.value || loadingRevoke.value);

    /**
     * 「戻る」ボタンを押下した際に呼び出されます。
     */
    const onClickBack = async () => {
        await goToRevokePremiumPlan();
    };
    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    const onClickConfirm = async () => {
        submit(
            async () => {
                const confirmed = await confirm(
                    'プレミアムプランのご利用を停止を行いますか？',
                    '入力いただいた内容で送信されます。今一度ご確認ください。',
                    'プレミアムプランの利用を停止',
                    'キャンセル',
                    640
                );
                if (confirmed) {
                    await revoke();
                    await goToRevokePremiumPlanComplete();
                }
            },
            async () => {
                await error(
                    'アンケートの中で正しく入力できていない項目があります。',
                    '恐れ入りますが、アンケートの回答を今一度お確かめください。',
                );
            }
        );
    };

    /**
     * 質問毎のバリデーション
     */
    const validate = async (fieldName: string) => {
        await nextTick();
        formModelRef.value?.validateField(fieldName, () => {});
    };

    onBeforeMount(async () => {
        await load('REVOKE_PREMIUM');
        initForm(questionnaire.value?.items ?? []);
        await Promise.all([loadAccount(), loadContracts()]);
    });

    onBeforeRouteLeave((_to, _from, next) => {
        if (!dirty.value) return next();
        if (window.confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) return next();
        next(false);
    });

    return {
        profile,
        contracts,
        loading,
        form,
        formValidateRules,
        formModelRef,
        onClickBack,
        onClickConfirm,
        validate,
    };
};
