import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/agreement/types';
import { AgreementUtil, DateUtil } from '@/util';
import moment from 'moment';
import dayjs from 'dayjs';

@Component
export default class AgreementPaymentDateRegister extends Vue {
    viewDateFormat = Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT;
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementForm;
    @Prop()
    declare readonly defaultValue?: string;
    @Prop()
    declare readonly showGuaranteeWarning?: boolean;

    /**
     * 入金予定日
     */
    get paymentDate(): string {
        return this.value?.paymentDate ?? '';
    }

    set paymentDate(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 入金予定日を書き換え
        cloned.paymentDate = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (this.defaultValue) {
            this.paymentDate = this.defaultValue;
        }
    }

    /**
     * 選択できない日付であるか否かを取得します。
     * @param date
     */
    isDisabledDate(date: moment.Moment): boolean {
        if (!this.value) {
            return false;
        }
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(this.value.arrivalMax), false);
        return !DateUtil.isIncluded(dayjs.unix(date.unix()), range);
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        const arrivalDate = this.value?.arrivalMin ?? '';
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(arrivalDate), false);
        if (!this.paymentDate) {
            callback('日付を選択してください。');
            return;
        } else if (!DateUtil.isIncluded(DateUtil.parseDateText(this.paymentDate), range)) {
            const rangeText = `${range[0].format('YYYY年MM月DD日')}から${range[1].format('YYYY年MM月DD日')}`;
            callback(`入金予定日は${rangeText}の範囲で指定してください。`);
        } else {
            callback();
        }
    }
}
