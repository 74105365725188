import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';
import { Util } from '@/util';

@Component
export default class CompanySalesEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileExtraUpdateForm;

    /**
     * 売上
     */
    get sales(): string {
        const current = this.value?.sales;
        if (current === undefined) {
            return '';
        } else {
            return (current / 10000).toString();
        }
    }

    set sales(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (_.isEmpty(newValue.trim())) {
            cloned.sales = undefined;
        } else {
            const parsed = Util.toNumber(newValue);
            cloned.sales = isNaN(parsed) ? undefined : parsed * 10000;
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (this.value?.sales === undefined) {
            callback();
        } else if (this.value.sales <= 0) {
            callback('売上高は1万円以上で入力してください。');
        } else if (this.value.sales > 10000000000000) {
            callback('売上高は10兆円以内で入力してください。');
        } else {
            callback();
        }
    }
}
