import { ref } from 'vue';
import {
    BaggageSearchCondition,
    BaggageSearchConditionRegisterForm,
    BaggageSearchConditionSaveError,
    BaggageSearchFormModel
} from '@/models/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { baggageApi } from '@/repository/api/internal/baggage';
import _ from 'lodash';
import { Const } from '@/const';
import { createGlobalState } from '@vueuse/core';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { Modal } from 'ant-design-vue';

/**
 * 荷物検索の荷物データをロードする機能を提供します。
 */
export const useBaggageSearchCondition = createGlobalState(() => {
    const message = useMessage();
    const notification = useNotification();
    const { state: { loading }, withLoading } = useLoading();

    const list = ref<BaggageSearchCondition[]>([]);

    const _save = async (form: BaggageSearchFormModel): Promise<number> => {
        // 既に保存中なら後続のアクションを無視する
        if (loading.value) {
            return Promise.reject('LOADING');
        }
        // 保存件数の上限チェック
        if (list.value.length >= Const.MAX_BAGGAGE_SEARCH_CONDITIONS) {
            return Promise.reject('REACHED_LIMIT');
        }
        // 保存用Formに変換
        const registerForm = BaggageSearchConditionRegisterForm.fromCondition(form);
        // 入力チェック
        if (!registerForm.isValid()) {
            return Promise.reject('MISSING_FIELDS');
        }
        return await withLoading(async () => {
            const insertedId = await baggageApi.registerSearchCondition(registerForm);
            await load();
            return insertedId;
        }).catch(() => {
            return Promise.reject('FAILED_TO_SAVE');
        });
    };

    const save = async (form: BaggageSearchFormModel): Promise<number> => {
        const notifyFailedKey = 'SAVE_SEARCH_CONDITION_FAILED';
        notification.close(notifyFailedKey);

        const onSuccess = () => message.success('現在の検索条件を「よく使う検索条件」として保存しました。');
        const onError = (reason: BaggageSearchConditionSaveError) => {
            switch (reason) {
                case 'REACHED_LIMIT':
                    Modal.error({
                        title: '「よく使う検索条件」の登録上限に達しております。',
                        content: 'なにか他の検索条件を削除してから登録してください。',
                    });
                    break;
                case 'MISSING_FIELDS':
                    notification.error({
                        key: notifyFailedKey,
                        message: '「よく使う検索条件」の保存に必要な項目が入力されていません。',
                        description: '発地、着地、車両重量、車種、運賃のいずれかは指定してください。',
                    });
                    break;
                case 'FAILED_TO_SAVE':
                    notification.error({
                        key: notifyFailedKey,
                        message: '「よく使う検索条件」に保存できませんでした。',
                        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                    });
                    break;
                default:
                    break;
            }
        };
        return _save(form)
            .then(onSuccess)
            .catch(onError);
    };

    const load = async () => {
        list.value = await baggageApi.listSearchCondition();
    };

    const select = (conditionId: number): BaggageSearchCondition | undefined => {
        const savedCondition = list.value.find(each => each.id === conditionId);
        if (_.isNil(savedCondition)) return undefined;

        return savedCondition;
    };

    return {
        state: {
            list,
            loading,
        },
        load,
        save,
        select,
    };
});
