/**
 * ロック種別のEnum
 */
export type CompanyLockTypeCode = 'General' | 'LatePayment';

export class CompanyLockTypeEnum {
    constructor(public code: CompanyLockTypeCode, public label: string) {
    }

    static General = new CompanyLockTypeEnum('General', '一般ロック');
    static LatePayment = new CompanyLockTypeEnum('LatePayment', '利用料金支払延滞ロック');

    static valueOf = (code?: string): CompanyLockTypeEnum | undefined =>
        CompanyLockTypeEnum.values.find((value) => value.code === code);

    static codeOf = (code: CompanyLockTypeCode): CompanyLockTypeEnum => {
        switch (code) {
            case 'General':
                return CompanyLockTypeEnum.General;
            case 'LatePayment':
                return CompanyLockTypeEnum.LatePayment;
        }
    };

    static values = [CompanyLockTypeEnum.General, CompanyLockTypeEnum.LatePayment];
}
