import { Route } from 'vue-router/types/router';
import { Modal } from 'ant-design-vue';
import router from '@/router';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
import store from '@/vuex/store';
import { Util } from '@/util';
import _ from 'lodash';
import { CreateElement, VNode } from 'vue';
import { NegotiationListForm } from '@/models/negotiation';
import { NegotiationList } from '@/vuex/modules/negotiation/types';
import { CompanyProfile } from '@/vuex/modules/company/types';

// ======================================================
// Routing Helpers
// ======================================================
/**
 * 荷物詳細画面へ遷移します。
 */
export const goToBaggageDetail = (route: Route, id: number): Promise<Route> =>
    router.push({ path: route.path, query: { baggageId: id.toString() } });

/**
 * 荷物編集画面へ遷移します。
 */
export const goToEditBaggage = (route: Route, id: number): Promise<Route> =>
    router.push({ path: route.path, query: { baggageId: id.toString(), edit: 'true' } });

/**
 * 設定>企業情報管理（詳細情報）へ遷移します。
 */
export const goToSettingCompanyProfileExtra = (): Promise<Route> =>
    router.push({ name: 'SettingCompanyProfileExtra' });

/**
 * Route情報から荷物IDを抽出します。
 */
export const extractBaggageId = (route: Route): number | undefined => {
    const { baggageId } = route.query;

    if (typeof baggageId === 'string' && Util.isNumeric(baggageId)) {
        return Number(baggageId);
    }
};

// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 荷物情報をロードします。
 */
export const loadBaggage = async (id: number): Promise<baggageTypes.Baggage> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_MY_BAGGAGE }`, id);

/**
 * 空車レコメンドをロードします。※公開中以外はクリアされます。
 */
export const loadTruckRecommend = async (): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_TRUCK_RECOMMEND_LIST }`);
    await store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_TRUCK_RECOMMEND_LIST }`);
};

/**
 * 荷物一覧をロードします。
 */
export const loadBaggageList = async (form: baggageTypes.BaggageListForm): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.LIST_BAGGAGE }`, form);

/**
 * 荷物閲覧会員数をロードします。
 */
export const loadBaggageViewCountList = async (list: baggageTypes.Baggage[]): Promise<void> => {
    if (list.length === 0) return;
    const idList = list.map(each => each.id);
    return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_VIEW_MEMBER_COUNT_LIST }`, idList);
};

/**
 * 荷物情報をクリアします。
 */
export const clearBaggage = (): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_BAGGAGE }`);

/**
 * 荷物を削除します。
 */
export const cancelBaggage = (id: number): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.CANCEL_BAGGAGE }`, id);

/**
 * 荷物を商談中にします。
 */
export const markBaggageUnderNegotiation = (id: number): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_UNDER_NEGOTIATION }`, id);

/**
 * 荷物の商談中を解除します。
 */
export const unmarkBaggageUnderNegotiation = (id: number): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.UNMARK_UNDER_NEGOTIATION }`, id);

/**
 * 自分の企業プロフィールをロードします。
 */
export const loadMyProfile = (): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.LOAD_MY_PROFILE }`);

/**
 * 荷物の商談リクエスト一覧をロードします。
 */
export const loadNegotiationList = async (id: number, form: NegotiationListForm): Promise<NegotiationList> => {
    await store.dispatch(`negotiation/${ negotiationTypes.ACTION.LIST_NEGOTIATION }`, { id, form });
    const list = store.getters[`negotiation/${ negotiationTypes.GETTER.NEGOTIATION_LIST }`] as negotiationTypes.NegotiationList;
    const companyIds = list.data.map(each => each.truckCompanyId);
    if (!_.isEmpty(companyIds)) {
        await loadCompanyList(companyIds);
    }
    return list;
};

/**
 * ID指定の企業一覧をロードします。
 */
export const loadCompanyList = async (companyIds: number[]): Promise<CompanyProfile[]> =>
    store.dispatch(`company/${ companyTypes.ACTION.LIST_PROFILE}`, { id: companyIds });

// ======================================================
// Confirmation Helpers
// ======================================================
/**
 * 支払サイト入力誘導モーダルを表示します。
 */
export const askEnteringPaymentTerms = (): Promise<boolean> => new Promise<boolean>((resolve) => Modal.confirm({
    title: '締め日、支払月、支払日の入力が完了していません。',
    content: (h: CreateElement): VNode =>
        h(
            'div',
            [
                '※荷物を登録されているお客様に、信用情報として必ずご入力をお願いしております。',
                h('br'),
                'お手数ですがご入力をお願いします。',
            ],
        ),
    onOk: () => resolve(true),
    onCancel: () => resolve(false),
    okText: '入力する',
    cancelText: '後で',
    autoFocusButton: 'cancel',
}));

// ======================================================
// Model Helpers
// ======================================================
export const isEmptyForm = (form: baggageTypes.BaggageListForm): boolean => {
    return (
        _.isNil(form.id) &&
        _.isEmpty(form.departurePref) &&
        _.isEmpty(form.arrivalPref) &&
        _.isEmpty(form.departureFrom) &&
        _.isEmpty(form.departureTo) &&
        _.isEmpty(form.truckWeight) &&
        _.isEmpty(form.truckModel) &&
        _.isEmpty(form.staffName)
    );
};
