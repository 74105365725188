<script setup lang="ts">
import { computed } from 'vue';
import _ from 'lodash';
import { CompanyStaffNameSuggestionList } from '@/models/company';

const props = withDefaults(defineProps<{
    value?: string,
    placeholder?: string,
    suggestions?: CompanyStaffNameSuggestionList,
    showDelete?: boolean,
}>(), {
    placeholder: '担当者名',
    showDelete: true,
});
const emits = defineEmits<{
    (e: 'input', value: string | undefined): void,
    (e: 'deleteStaffNameSuggestion', value: string): void,
}>();

const staffName = computed<string>({
    get: (): string => props.value?.toString() ?? '',
    set: (value: string) => {
        if (_.isEmpty(value)) {
            emits('input', undefined);
        } else {
            emits('input', value);
        }
    },
});
const staffNameSuggestions = computed(() => props.suggestions?.staffNames ?? []);

const onClickDelete = (value: string, event: MouseEvent) => {
    // 後続のオプション選択処理が行われないようする
    event.preventDefault();
    event.stopPropagation();
    emits('deleteStaffNameSuggestion', value);
};
</script>

<template>
    <a-auto-complete style="width: 33%"
                     :placeholder="placeholder"
                     class="row-editable"
                     :default-active-first-option="false"
                     :allow-clear="true"
                     option-label-prop="title"
                     v-model="staffName">
        <template #dataSource>
            <a-select-option v-for="text in staffNameSuggestions"
                             :key="text"
                             :title="text">
                <span>{{ text }}</span>
                <a-button v-if="showDelete"
                          style="float: right"
                          type="default"
                          icon="delete"
                          size="small"
                          @click="onClickDelete(text, $event)"></a-button>
            </a-select-option>
        </template>
    </a-auto-complete>
</template>

<style scoped lang="less">
</style>
