import { TbxRouteConfig } from '@/router/routes/types';
import InquiryPage from '@/pages/Inquiry/index.vue';
import ReportLatePaymentPage from '@/pages/ReportLatePayment/index.vue';

// リファクタリングページ
import _InquiryPage from '@/_pages/Inquiry/Inquiry.vue';
import _ReportLatePaymentPage from '@/_pages/ReportLatePayment/ReportLatePayment.vue';

export const inquiryRoutes: TbxRouteConfig[] = [
    {
        path: '/inquiry',
        name: 'Inquiry',
        component: _InquiryPage,
        backComponent: InquiryPage,
        meta: { title: 'その他お問い合わせ・ご意見送信' },
    },
    {
        path: '/uncharged',
        name: 'ReportLatePayment',
        component: _ReportLatePaymentPage,
        backComponent: ReportLatePaymentPage,
        meta: { title: '入金遅延連絡' },
    },
];
