export const useTimer = () => {
    /**
     * 指定した時間待機するPromiseを生成します。
     */
    const delay = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

    /**
     * 指定した時間の遅延を伴うPromiseを生成します。
     */
    const lazy = (func: () => Promise<unknown>, latency = 300): Promise<unknown> => new Promise(resolve => {
        // noinspection JSIgnoredPromiseFromCall
        func();
        delay(latency).then(resolve);
    });

    return {
        delay,
        lazy,
    };
};
