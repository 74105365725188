/**
 * トラックの車両重量Enum
 */
export type TruckWeightEnumCode =
    | 'TW01'
    | 'TW02'
    | 'TW03'
    | 'TW04'
    | 'TW05'
    | 'TW11'
    | 'TW12'
    | 'TW06'
    | 'TW13'
    | 'TW14'
    | 'TW07'
    | 'TW15'
    | 'TW16'
    | 'TW17'
    | 'TW18'
    | 'TW08'
    | 'TW09'
    | 'TW10'
    | 'TW19';

export class TruckWeightEnum {
    constructor(public code: TruckWeightEnumCode, public label: string, public orderNo: number) {}

    static Mini = new TruckWeightEnum('TW01', '軽', 2);
    static Weight1t = new TruckWeightEnum('TW02', '1t', 3);
    static Weight2t = new TruckWeightEnum('TW03', '2t', 4);
    static Weight3t = new TruckWeightEnum('TW04', '3t', 5);
    static Weight4t = new TruckWeightEnum('TW05', '4t', 6);
    static Weight5t = new TruckWeightEnum('TW11', '5t', 7);
    static Weight6t = new TruckWeightEnum('TW12', '6t', 8);
    static Weight7t = new TruckWeightEnum('TW06', '7t', 9);
    static Weight8t = new TruckWeightEnum('TW13', '8t', 10);
    static Weight9t = new TruckWeightEnum('TW14', '9t', 11);
    static Weight10t = new TruckWeightEnum('TW07', '10t', 12);
    static Weight11t = new TruckWeightEnum('TW15', '11t', 13);
    static Weight12t = new TruckWeightEnum('TW16', '12t', 14);
    static Weight13t = new TruckWeightEnum('TW17', '13t', 15);
    static Weight14t = new TruckWeightEnum('TW18', '14t', 16);
    static Weight15t = new TruckWeightEnum('TW08', '15t', 17);
    static Trailer = new TruckWeightEnum('TW09', 'トレーラー', 18);
    static Other = new TruckWeightEnum('TW10', '他', 19);
    static Unspecified = new TruckWeightEnum('TW19', '重量問わず', 1);

    static values = [
        TruckWeightEnum.Mini,
        TruckWeightEnum.Weight1t,
        TruckWeightEnum.Weight2t,
        TruckWeightEnum.Weight3t,
        TruckWeightEnum.Weight4t,
        TruckWeightEnum.Weight5t,
        TruckWeightEnum.Weight6t,
        TruckWeightEnum.Weight7t,
        TruckWeightEnum.Weight8t,
        TruckWeightEnum.Weight9t,
        TruckWeightEnum.Weight10t,
        TruckWeightEnum.Weight11t,
        TruckWeightEnum.Weight12t,
        TruckWeightEnum.Weight13t,
        TruckWeightEnum.Weight14t,
        TruckWeightEnum.Weight15t,
        TruckWeightEnum.Trailer,
        TruckWeightEnum.Other,
    ];
    static allValues = [TruckWeightEnum.Unspecified, ...TruckWeightEnum.values];
    // 荷物で登録可能な値
    static registrableValuesForBaggage = [TruckWeightEnum.Unspecified, ...TruckWeightEnum.values];
    // 空車で登録可能な値
    static registrableValuesForTruck = [...TruckWeightEnum.values];

    static valueOf = (code: TruckWeightEnumCode): TruckWeightEnum | undefined =>
        TruckWeightEnum.allValues.find((value) => value.code === code);

    static truckValueOf = (code: TruckWeightEnumCode): TruckWeightEnum | undefined =>
        TruckWeightEnum.registrableValuesForTruck.find((value) => value.code === code);

    static valuesLessThanOrEqualTo = (e: TruckWeightEnum): TruckWeightEnum[] =>
        TruckWeightEnum.values.filter((value) => value <= e);

    static comparatorByOrderNo(e1: TruckWeightEnum, e2: TruckWeightEnum): number {
        if (e1 < e2) return -1;
        if (e1 > e2) return 1;
        return 0;
    }
}
