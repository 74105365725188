import { Negotiation, NegotiationList } from '@/models/negotiation';

//
// Action
//
export class ACTION {
    static readonly LIST_NEGOTIATION = 'LIST_NEGOTIATION';
    static readonly REGISTER_NEGOTIATION = 'REGISTER_NEGOTIATION';
    static readonly QUERY_NEGOTIATION_REQUEST = 'QUERY_NEGOTIATION_REQUEST';
}

//
// Getter
//
export class GETTER {
    static readonly NEGOTIATION_LIST = 'NEGOTIATION_LIST';
    static readonly NEGOTIATION_REQUESTS = 'NEGOTIATION_REQUESTS';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_NEGOTIATION_LIST = 'SET_NEGOTIATION_LIST';
    static readonly SET_NEGOTIATION_REQUESTS = 'SET_NEGOTIATION_REQUESTS';
}

//
// Store
//
export interface NegotiationState {
    negotiationList: NegotiationList;
    negotiationRequests: Negotiation[];
}

//
// Type for store
//
export * from '@/models/negotiation';
