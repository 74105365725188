<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';
import TruckWeightTreeSelect from '@/_components/ui/TruckWeightTreeSelect.vue';
import TruckModelTreeSelect from '@/_components/ui/TruckModelTreeSelect.vue';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import CompanyProfileName from '@/_components/ui/CompanyProfileName.vue';
import DateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import CompanySummary from '@/_components/parts/CompanySummary.vue';
import { useTruckSearchHelper } from '@/_pages/Truck/Search/truck-search-helper';
import { columns } from '@/_pages/Truck/Search/table-definition';
import { useFormModel } from '@/composables/form-helper';

defineProps<{
    companyId?: string
    truckId?: string,
}>();
const {
    form,
    formValidateRules,
    loading,
    searchResultsRef,
    validationErrorMessage,
    sortInfo,
    pageInfo,
    truckListData,
    drawerVisibility,
    loadingCompanyDetail,
    circleOptions,
    profile,
    officialCompany,
    confidence,
    statistics,
    onClickGround,
    onClickSearch,
    onClickClear,
    onChangePage,
    onClickInvertPref,
    isDisabledDate,
    onVisibleChangeContactsTooltip,
    onClickCloseDrawer,
    onClickCompanyLink,
    onClickContact,
    onClickPrint,
} = useTruckSearchHelper();
// 最低運賃の強制半角数字変換処理を入力中から反映させるためにformModelを噛ませる
const { formModel } = useFormModel();
</script>

<template>
    <page-layout :show-header="false" :back-icon="false" @click="onClickGround">
        <div class="board-container">
            <a-card :body-style="{ padding: 0 }">
                <div class="search-form-container">
                    <div class="section-title">検索条件</div>
                    <a-row :gutter="[24, 0]" align="middle">
                      <a-col :span="16">
                        <a-form-model layout="vertical"
                                      :model="form"
                                      :rules="formValidateRules"
                                      ref="formModel">
                          <a-form-model-item label="部屋で絞る">
                            <a-select :options="circleOptions"
                                      v-model="form.circleId"
                                      :allowClear="true"
                                      style="width: 400px" />
                          </a-form-model-item>
                        </a-form-model>
                      </a-col>
                    </a-row>
                    <a-row :gutter="[24, 0]" align="middle">
                        <a-col :span="16">
                            <a-row :gutter="[0, 16]" align="top">
                                <a-col :span="24">
                                    <a-input-group class="app-input-group" compact>
                                        <prefecture-select class="app-input-group__item"
                                                           :multiple="true"
                                                           placeholder="空車地"
                                                           :placeholder-icon="true"
                                                           v-model="form.departurePrefCode">
                                            <template #title><img src="/img/ic-departure.svg" width="24" height="24"
                                                                  alt="空車地" style="margin-right: 8px"/>空車地
                                            </template>
                                        </prefecture-select>
                                        <div class="app-input-group__item btn-prefecture-invert">
                                            <a-tooltip title="発地と着地を入れ替えます">
                                                <a-button icon="swap" @click="onClickInvertPref"></a-button>
                                            </a-tooltip>
                                        </div>
                                        <prefecture-select class="app-input-group__item"
                                                           :multiple="true"
                                                           placeholder="行先地"
                                                           v-model="form.arrivalPrefCode">
                                            <template #title><img src="/img/ic-arrival.svg" width="24" height="24"
                                                                  alt="行先地" style="margin-right: 8px"/>行先地
                                            </template>
                                        </prefecture-select>
                                    </a-input-group>
                                </a-col>
                                <a-col :span="24">
                                    <a-input-group class="app-input-group" compact>
                                        <date-picker class="app-input-group__item"
                                                     placeholder="発日（開始）"
                                                     format="YYYY/M/D"
                                                     :disabled-date="isDisabledDate"
                                                     v-model="form.departureFromValue">
                                            <template #suffixIcon>
                                                <a-icon type="calendar"></a-icon>
                                            </template>
                                        </date-picker>
                                        <span
                                            class="app-input-group__item app-input-group__item--separator">〜</span>
                                        <date-picker class="app-input-group__item"
                                                     placeholder="発日（終了）"
                                                     format="YYYY/M/D"
                                                     :disabled-date="isDisabledDate"
                                                     v-model="form.departureToValue">
                                            <template #suffixIcon></template>
                                        </date-picker>
                                    </a-input-group>
                                </a-col>
                                <a-col :span="24">
                                    <a-input-group class="app-input-group" compact>
                                        <date-picker style="flex: 1;"
                                                     class="app-input-group__item"
                                                     placeholder="着日（開始）"
                                                     format="YYYY/M/D"
                                                     valueFormat="YYYY-MM-DD 00:00:00"
                                                     :disabled-date="isDisabledDate"
                                                     v-model="form.arrivalFromValue">
                                            <template #suffixIcon>
                                                <a-icon type="calendar"></a-icon>
                                            </template>
                                        </date-picker>
                                        <span
                                            class="app-input-group__item app-input-group__item--separator">〜</span>
                                        <date-picker style="flex: 1;"
                                                     class="app-input-group__item"
                                                     placeholder="着日（終了）"
                                                     format="YYYY/M/D"
                                                     valueFormat="YYYY-MM-DD 23:59:59"
                                                     :disabled-date="isDisabledDate"
                                                     v-model="form.arrivalToValue">
                                            <template #suffixIcon></template>
                                        </date-picker>
                                    </a-input-group>
                                    <div>
                                        <span class="error-message">{{ validationErrorMessage }}</span>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row :gutter="[0, 16]" align="top">
                                <a-col :span="24">
                                    <truck-weight-tree-select
                                        v-model="form.truckWeightCode"></truck-weight-tree-select>
                                </a-col>
                                <a-col :span="24">
                                    <truck-model-tree-select
                                        v-model="form.truckModelCode"></truck-model-tree-select>
                                </a-col>
                                <a-col :span="24">
                                    <a-row :gutter="[0, 0]" type="flex" align="middle">
                                        <a-col :span="13">
                                            <a-form-model ref="formModel"
                                                          :model="form"
                                                          :rules="formValidateRules"
                                                          :colon="false">
                                                <a-form-model-item prop="freight">
                                                    <a-input addon-after="円以下" v-model="form.freight"
                                                             placeholder="最低運賃（税別）"/>
                                                </a-form-model-item>
                                            </a-form-model>
                                        </a-col>
                                        <a-col :span="11" style="padding-left: 8px;">
                                            <a-checkbox v-model="form.excludeUndecidedFreight">要相談を除く
                                            </a-checkbox>
                                        </a-col>
                                    </a-row>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </div>

                <a-row type="flex" align="middle" class="search-action-container">
                    <a-col :offset="8" :span="8" style="text-align: center">
                        <a-button type="primary"
                                  block
                                  icon="search"
                                  style="width: 160px"
                                  size="large"
                                  @click="onClickSearch">空車検索
                        </a-button>
                    </a-col>
                    <a-col :span="8" style="text-align: right">
                        <a-button icon="reload" @click="onClickClear">クリア</a-button>
                    </a-col>
                </a-row>
            </a-card>
            <a-card ref="searchResultsRef" class="search-result-container" :body-style="{ padding: 0 }">
                <div class="table-nav">
                    <div class="table-nav-total">検索結果 {{ pageInfo.totalRecordCount }} 件</div>
                    <sort-pagination-control :sort-info="sortInfo" :page-info="pageInfo" @change="onChangePage"/>
                </div>

                <a-table class="app-result-list-table"
                         bordered
                         :columns="columns"
                         :data-source="truckListData"
                         rowKey="truckId"
                         size="middle"
                         :loading="loading"
                         :row-class-name="() => 'app-table-row app-table-row--has-action-column'"
                         :pagination="false">
                    <template #action="text, record">
                        <a-tooltip placement="bottomLeft"
                                   overlayClassName="app-tooltip-phone"
                                   trigger="click"
                                   @visibleChange="onVisibleChangeContactsTooltip($event, record.truck.id)">
                            <a-button icon="phone"></a-button>
                            <template #title>
                                <address class="contact_address">
                                    <div class="contact_address__company-name">
                                        <company-profile-name v-if="record.company?.name"
                                                              :name="record.company.name"/>
                                    </div>
                                    <div class="contact_address__company-phone">{{
                                            record.company?.companyPhone
                                        }}
                                    </div>
                                    <div class="contact_address__staff-name">担当：{{ record.truck.staffName }}</div>
                                </address>
                            </template>
                        </a-tooltip>
                    </template>
                    <template #companyName="text, record">
                        <a @click="onClickCompanyLink($event, record)">
                            <company-profile-name v-if="record.company?.name" :name="record.company.name"/>
                        </a>
                    </template>
                    <template #departureArrival="text, record">
                        <date-time-location-label :departure-min="record.truck.departureMin"
                                                  :departure-max="record.truck.departureMax"
                                                  :departure-pref="record.truck.departurePrefecture"
                                                  :departure-city="record.truck.departureCity"
                                                  :arrival-min="record.truck.arrivalMin"
                                                  :arrival-max="record.truck.arrivalMax"
                                                  :arrival-pref="record.truck.arrivalPrefecture"
                                                  :arrival-city="record.truck.arrivalCity">
                            <template #departureAdditional>
                                <a-tooltip
                                    :title="record.truck.departureAreaText">
                                    <div class="additional-prefecture-text"
                                         v-if="record.truck.additionalDeparturePrefectureText">
                                        （{{ record.truck.additionalDeparturePrefectureText }}）
                                    </div>
                                </a-tooltip>
                            </template>
                            <template #arrivalAdditional>
                                <a-tooltip
                                    :title="record.truck.arrivalAreaText">
                                    <div class="additional-prefecture-text"
                                         v-if="record.truck.additionalArrivalPrefectureText">
                                        （{{ record.truck.additionalArrivalPrefectureText }}）
                                    </div>
                                </a-tooltip>
                            </template>
                        </date-time-location-label>
                    </template>
                    <template #freight="text, record">
                        <span class="freight-text">{{ record.truck.freight.format() }}</span>
                    </template>
                    <template #truckWeight="text, record">
                        <span class="table-row-ellipsis-text">{{ record.truck.truckWeight.label ?? '' }}</span>
                    </template>
                    <template #truckModel="text, record">
                        <span class="table-row-ellipsis-text">{{ record.truck.truckModel.label ?? '' }}</span>
                    </template>
                    <template #description="text, record">
                        <a-tooltip :title="record.truck.description" placement="bottom"
                                   :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                            <span class="table-row-ellipsis-text">{{ record.truck.description }}</span>
                        </a-tooltip>
                    </template>
                </a-table>

                <div class="table-nav">
                    <sort-pagination-control :page-info="pageInfo"
                                             @change="({pageNo, pageSize}) => onChangePage({pageNo, pageSize, sortKey: sortInfo.sortKey, sortOrder: sortInfo.sortOrder})"/>
                </div>
            </a-card>
        </div>

        <a-drawer width="600"
                  class="company-drawer"
                  :visible="drawerVisibility"
                  :body-style="{ padding: 0 }"
                  :mask="false"
                  :closable="true"
                  :destroy-on-close="true"
                  @close="onClickCloseDrawer">
            <template #title>
                <div class="company-drawer__header">
                    <div class="company-drawer__header__title">企業情報：{{ profile?.name?.kanji ?? '' }}</div>
                    <a-button class="company-drawer__header__print" @click="onClickPrint">印刷</a-button>
                </div>
            </template>
            <div class="company-drawer__content">
                <!-- ローディング中 -->
                <div v-if="loadingCompanyDetail" class="loading-container">
                    <a-spin/>
                </div>
                <!-- ローディング完了 -->
                <div v-else>
                    <company-summary :profile="profile"
                                     :official-company="officialCompany"
                                     :confidence="confidence"
                                     :statistics="statistics"
                                     :hideContact="true"
                                     :enable-name-link="false"
                                     @clickContact="onClickContact"></company-summary>
                </div>
            </div>
        </a-drawer>
    </page-layout>
</template>

<style scoped lang="less">
// pages/Truck/Search/style.less
.board-container {
    padding: 24px;
}

.search-result-container {
    margin-top: 24px;
}

// components/Truck/Search/Condition/style.less
.label {
    text-align: end;
}

.search-form-container {
    padding: 16px 24px;

    ::v-deep(.ant-form-item) {
        margin-bottom: 0;
    }
    ::v-deep(.ant-form-item-control) {
        line-height: 0;
    }
}

.search-action-container {
    padding: 16px 24px;
    border-radius: 0 0 @card-radius @card-radius;
    background-color: @color-neutral-2;
    box-shadow: 0px -1px 0px #ebedf0;
}

.section-title {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: @heading-color;
    margin-bottom: 16px;
}

.btn-prefecture-invert {
    flex: none !important;
    width: 32px;
    height: auto;
    border-top: @btn-border-style @btn-border-width @select-border-color;
    border-bottom: @btn-border-style @btn-border-width @select-border-color;

    > .ant-btn {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 14px;
        color: @link-color;
    }
}

.error-message {
    min-height: 22px;
    font-size: 12px;
    line-height: 1.5;
    color: @error-color;
}

// components/Truck/Search/Pagination/style.less
.table-nav {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 16px 24px;

    .table-nav-total {
        margin-right: 16px;
        font-size: 16px;
        font-weight: bold;
    }
}

// components/Truck/View/SearchResultList/style.less
.freight-text {
    font-weight: bold;
}

.additional-prefecture-text {
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 12px;
    max-height: 36px;
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

.contact_address {
    color: @black;
    margin: 0;

    &__company-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
    }

    &__company-phone {
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
    }

    &__staff-name {
        font-size: 14px;
        line-height: 22px;
    }
}

// pages/Truck/Search/CompanyDetailDrawer/style.less
.loading-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.company-drawer {
    &__header {
        display: flex;
        align-items: center;
        flex-grow: 1;

        &__title {
            flex: 1;
            font-size: 16px;
            color: @heading-color;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__print {
            margin-left: auto;
            margin-right: 56px;
        }
    }

    &__content {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
        padding: @padding-lg;
    }
}

</style>
