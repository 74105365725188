import { InjectionKey } from 'vue';
import { message } from 'ant-design-vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { useCompanyBaggageList } from '@/composables/company-baggage-list';
import { useCompanyTruckList } from '@/composables/company-truck-list';
import { useRouting } from '@/composables/helper/routing';
import { useOfficialCompany } from '@/composables/company-official';

/**
 * 企業情報ドロワー用のデータを纏めて受け渡しする機能を提供します。
 */
export const useCompanyDrawerProvider = () => {
    const { state: { loading }, withLoading } = useLoading();
    const { openCompanyPrint } = useRouting();

    // 企業プロフィール、信用情報、統計情報
    const { state: { profile }, load: loadProfile, clear: clearProfile } = useCompanyProfile();
    const { state: { officialCompany }, load: loadOfficialCompany, clear: clearOfficialCompany } = useOfficialCompany();
    const { state: { confidence }, load: loadConfidence, clear: clearConfidence } = useCompanyConfidence();
    const { state: { statistics }, load: loadStatistics, clear: clearStatistics } = useCompanyStatistics();
    // 荷物一覧
    const { state: { list: baggageList }, load: loadBaggageList, clear: clearBaggageList } = useCompanyBaggageList();
    // 空車一覧
    const { state: { list: truckList }, load: loadTruckList, clear: clearTruckList } = useCompanyTruckList();

    /**
     * データをロードします。
     */
    const load = async (id: number): Promise<void> => withLoading(async () => {
        await Promise.all([
            loadProfile(id),
            loadOfficialCompany(id).catch(() => false),
            loadConfidence(id),
            loadStatistics(id),
            loadBaggageList(id),
            loadTruckList(id)
        ]).catch(e => {
            message.error('アクセスしようとした企業は閲覧できません。');
            clear();
            return Promise.reject(e);
        });
    });

    /**
     * データをクリアします。
     */
    const clear = () => {
        clearProfile();
        clearOfficialCompany();
        clearConfidence();
        clearStatistics();
        clearBaggageList();
        clearTruckList();
    };

    const onClickPrint = () => {
        const companyId = profile.value?.id;
        if (!companyId) return;

        openCompanyPrint(companyId);
    };

    return {
        state: {
            loading,
            profile,
            officialCompany,
            confidence,
            statistics,
            baggageList,
            truckList
        },
        load,
        onClickPrint,
    };
};

export type CompanyDrawerProviderType = ReturnType<typeof useCompanyDrawerProvider>;
export const COMPANY_DRAWER_PROVIDER_KEY: InjectionKey<CompanyDrawerProviderType> = Symbol('CompanyDrawerProvider');
