import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';
// @ts-ignore
import TrialFlowStep from '@/components/Common/TrialFlowStep';
import * as types from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { namespace } from 'vuex-class';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

const accountMod = namespace('account');

@Component({
    title: '申し込みの受付が完了しました',
    components: {
        UiPreLoginLayout,
        TrialFlowStep,
        TbxLinkText,
    },
})
export default class JudgingPage extends PageVue {
    @accountMod.Getter(types.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
}
