import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Util } from '@/util';

@Component
export default class AgreementHighwayFare extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get highwayFare(): string {
        if (!this.agreement) return '';

        if (!this.agreement.highwayFareFlg) return 'なし';

        const highwayFare = this.agreement.highwayFare;
        return highwayFare <= 0 ? '金額未定' : `${Util.formatNumber(highwayFare)} 円`;
    }

    get highwayFareCr(): string {
        if (!this.agreementCr) return '';

        if (!this.agreementCr.highwayFareFlg) return 'なし';

        const highwayFare = this.agreementCr.highwayFare;
        return highwayFare <= 0 ? '金額未定' : `${Util.formatNumber(highwayFare)} 円`;
    }

    get changeRequest(): string {
        return this.highwayFare != this.highwayFareCr ? this.highwayFareCr : '';
    }
}
