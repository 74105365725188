import { NotificationOptions  } from 'ant-design-vue/types/notification';
import { useNotification } from '@/composables/helper/page-helper';


export const useNotificationExtension = (key?: string) => {
    const notification = useNotification();

    const sorryNotification = (option: Pick<NotificationOptions, 'key' | 'message'>) => notification.error({
        ...option,
        key: key,
        description:
            '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
    });

    const close = () => {
        if (key !== undefined) {
            notification.close(key);
        }
    };

    return {
        close,
        sorryNotification
    };
};
