import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { TruckRegisterFormModel } from '@/vuex/modules/truck/types';
import { Const } from '@/const';

@Component
export default class TruckDescriptionEdit extends Vue {
    @Prop()
    declare readonly value?: TruckRegisterFormModel;

    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get description(): string {
        return this.value?.description ?? '';
    }

    set description(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.description = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    private validate(callback: (message?: string) => void): void {
        if (this.value?.description && this.value.description.length > Const.MAX_TRUCK_DESCRIPTION_LENGTH) {
            callback(`${Const.MAX_TRUCK_DESCRIPTION_LENGTH}文字以内で入力してください。`);
        } else {
            callback();
        }
    }
}
