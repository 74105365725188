<script setup lang="ts">
import DrawerContentLayout from '../../common/DrawerContentLayout.vue';
import { Karte } from '@/karte';
import { RegionUtil, Util } from '@/util';
import DateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import { Enum } from '@/types/enum';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { useGtm } from '@/composables/helper/gtm-helper';
import { TruckRecommendationResult } from '@/composables/my-baggage-truck-recommendation';
import CompanyContact from '@/_components/ui/CompanyContact.vue';

const props = defineProps<{
    baggageId: number;
    loading: boolean;
    list?: TruckRecommendationResult[];
}>();

const emit = defineEmits<{
    (e: 'selectCompany', value: { companyId: number, truckId: number }): void;
}>();

const { gtm } = useGtm();

const onVisibleChangeContactsTooltip = (visible: boolean, truckId: number): void => {
    if (visible) {
        const id = truckId;
        Karte.trackClickTruckRecommendContact(id, props.baggageId);
        gtm.setEvent('open_truck_contacts', { id, recommend_baggage_id: props.baggageId });
    }
};

const onClickCompany = (companyId: number, truckId: number): void => {
    emit('selectCompany', { companyId, truckId });
};

const additionalPrefectureText = (additionalPrefecture: Array<Enum<PrefectureEnumCode>>): string => {
    if (additionalPrefecture.length === 0) {
        return '';
    }
    const regions = RegionUtil.parseRegionsFromPrefectures(additionalPrefecture.map((each) => each.code));
    return `${ regions.map((each) => each.label).join('、') }も対応可能`;
};

const prefectureTooltipText = (
    prefectureLabel: string,
    city: string | undefined,
    additionalPrefecture: Array<Enum<PrefectureEnumCode>>
): string => {
    const text = additionalPrefectureText(additionalPrefecture);
    if (!text) return '';
    return `${ prefectureLabel }${ city ?? '' }の他、${ text }`;
};

const freightText = (freight?: number): string => {
    if (!freight) {
        return '要相談';
    }
    return `${ Util.formatNumber(freight) }円`;
};

</script>

<template>
    <drawer-content-layout :loading="loading">
        <a-empty v-if="list === undefined || list.length === 0">
            <template #description>
                <span>荷物にマッチする空車はありませんでした</span>
            </template>
        </a-empty>
        <div v-else class="truck-contents">
            <a-row :gutter="[0,16]">
                <a-col v-for="truck in list" :span="24" :key="truck.id">
                    <a-card style="width: 100%">
                        <template #title>
                            <span class="component-note-xs">{{ truck.company.name.kanji }}</span>
                        </template>
                        <template #extra>
                            <a-tooltip placement="bottomLeft"
                                       overlay-class-name="app-tooltip-phone"
                                       trigger="click"
                                       @visibleChange="onVisibleChangeContactsTooltip($event, truck.id)">
                                <a-button icon="phone"></a-button>
                                <template #title>
                                    <company-contact :company="truck.company" :staff-name="truck.staffName"/>
                                </template>
                            </a-tooltip>
                        </template>

                        <date-time-location-label :departure-min="truck.departureMin"
                                                  :departure-max="truck.departureMax"
                                                  :departure-pref="truck.departurePrefecture"
                                                  :departure-city="truck.departureCity"
                                                  :arrival-min="truck.arrivalMin"
                                                  :arrival-max="truck.arrivalMax"
                                                  :arrival-pref="truck.arrivalPrefecture"
                                                  :arrival-city="truck.arrivalCity">
                            <template #departureAdditional>
                                <a-tooltip
                                    :title="prefectureTooltipText(truck.departurePrefecture.label ?? '', truck.departureCity, truck.additionalDeparturePref)">
                                    <div class="component-note-xs additional-prefecture-text"
                                         v-if="additionalPrefectureText(truck.additionalDeparturePref)">
                                        （{{
                                            additionalPrefectureText(truck.additionalDeparturePref)
                                        }}）
                                    </div>
                                </a-tooltip>
                            </template>
                            <template #arrivalAdditional>
                                <a-tooltip
                                    :title="prefectureTooltipText(truck.arrivalPrefecture.label ?? '', truck.arrivalCity, truck.additionalArrivalPref)">
                                    <div class="component-note-xs additional-prefecture-text"
                                         v-if="additionalPrefectureText(truck.additionalArrivalPref)">
                                        （{{
                                            additionalPrefectureText(truck.additionalArrivalPref)
                                        }}）
                                    </div>
                                </a-tooltip>
                            </template>
                        </date-time-location-label>
                        <div class="truck-type">
                            <a-row>
                                <a-col :span="4" class="truck-type__title">重量</a-col>
                                <a-col :span="20" class="component-body-s">{{ truck.truckWeight.label }}</a-col>
                            </a-row>
                            <a-divider dashed/>
                            <a-row>
                                <a-col :span="4" class="truck-type__title">車種</a-col>
                                <a-col :span="20" class="component-body-s">{{ truck.truckModel.label }}</a-col>
                            </a-row>
                            <a-divider dashed/>
                            <a-row>
                                <a-col :span="4" class="truck-type__title">最低運賃</a-col>
                                <a-col :span="20" class="component-body-s">{{
                                        freightText(truck.freight)
                                    }}
                                </a-col>
                            </a-row>
                            <a-divider dashed/>
                            <a-row>
                                <a-col :span="4" class="truck-type__title">備考</a-col>
                                <a-col :span="20" class="component-body-s truck-type__description">
                                    {{ truck.description }}
                                </a-col>
                            </a-row>
                        </div>
                        <div class="truck-company-link">
                            <a-button class="component-body-s truck-company-link__text" type="link"
                                      @click="onClickCompany(truck.company.id, truck.id)">
                                企業情報を見る
                                <a-icon type="right"/>
                            </a-button>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </drawer-content-layout>
</template>

<style scoped lang="less">
.truck-contents {
    :deep(.ant-card-head) {
        border-bottom: 0;
    }

    :deep(.ant-card-body) {
        padding-top: 0;
        padding-bottom: 16px;
    }

    :deep(.ant-divider-horizontal) {
        margin: 4px 0;
    }

    :deep(.ant-btn-link) {
        padding: 0;
    }

    .truck-type {
        margin-top: 16px;
        margin-bottom: 10px;

        &__title {
            font-weight: 600;
        }

        &__description {
            white-space: pre-wrap;
        }
    }

    .truck-company-link {
        margin-top: 8px;

        &__text {
            color: @token-text-color-green;
        }
    }

    .additional-prefecture-text {
        display: -webkit-box;
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-height: 36px;
    }
}

</style>
