<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';
import UiIconBaggage from '@/_components/ui/icons/Baggage.vue';

const props = defineProps<{
    subject?: string;
    location?: string;
    startTime: Date;
    endTime: Date;
    eventType: string;
    existsRecommendation: boolean;
}>();

defineEmits<{
    (e: 'clickEventMatching', event: MouseEvent): void,
}>();

const time = computed(() => {
    const start = dayjs(props.startTime).format('H:mm');
    const duration = dayjs(props.startTime).to(props.endTime, true);
    return `${ start }(${ duration })`;
});

const eventDetailsClass = computed(() => {
    if (props.eventType === 'EMPTY')
        return 'event-details--empty';
    return 'event-details--memo';
});
</script>


<template>
    <div :class="eventDetailsClass">
        <span class="subject">{{ props.subject }}</span>
        <div class="location">{{ props.location }}</div>
        <div class="time">{{ time }}</div>
        <a-tooltip title="マッチした荷物情報" placement="bottom">
          <div v-if="existsRecommendation" class="baggage-badge" @click="$emit('clickEventMatching', $event)">
              <ui-icon-baggage />
          </div>
        </a-tooltip>
    </div>
</template>

<style scoped lang="less">
.event-details--memo, .event-details--empty {
    overflow: hidden;
    padding: 0 4px;
    width: 100%;
}

.event-details--memo {
    background: #6D6D6D;
}

.baggage-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: @primary-color;
    pointer-events: auto;
}

.baggage-badge:hover {
    border: 1px solid white;
}
</style>
