import { companyApi } from '@/repository/api/internal/company';
import { ref } from 'vue';
import { CompanyStaffNameSuggestionList } from '@/models/company';
import { useLoading } from '@/composables/helper/loading-helper';
import { useNotification } from '@/composables/helper/page-helper';

/**
 * 企業担当名サジェストの取得機能を提供します。
 */
export const useCompanyStaffNameSuggestions = () => {
    const suggestionList = ref<CompanyStaffNameSuggestionList>({ staffNames: [] });
    const { state: { loading }, withLoading } = useLoading();
    const load = async () => withLoading(async () => {
        suggestionList.value = await companyApi.loadStaffNameSuggestions();
    });

    return {
        state: {
            loading,
            suggestionList,
        },
        load,
    };
};

/**
 * 企業担当名サジェストの登録機能を提供します。
 */
export const useCompanyStaffNameSuggestionRegister = () => {
    const register = async (staffName: string) => {
        await companyApi.registerStaffNameSuggestion({ staffName });
    };

    return {
        register,
    };
};

/**
 * 企業担当名サジェストの削除機能を提供します。
 */
export const useCompanyStaffNameSuggestionRemove = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_DEL_STAFF = 'DELETE_STAFF_NAME_SUGGESTION_ERROR';
    /**
     * 担当者履歴の削除失敗を通知します。
     */
    const notifyFailedToDeleteStaff = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_DEL_STAFF,
        message: '担当者の履歴を削除できませんでした。',
        description: '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 担当者履歴の削除失敗通知を閉じます。
     */
    const closeFailedToDeleteStaffNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_DEL_STAFF);

    const remove = async (staffName: string) => {
        closeFailedToDeleteStaffNotification();
        await companyApi.deleteStaffNameSuggestion({ staffName })
            .catch(notifyFailedToDeleteStaff);
    };

    return {
        remove,
    };
};
