import { createGlobalState, useLocalStorage } from '@vueuse/core';
import { BaggageRegisterFormModel } from '@/models/baggage';
import { RouteLocationNormalizedLoaded } from 'vue2-helpers/vue-router';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { Dictionary } from 'vue-router/types/router';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum } from '@/enums/baggage-handling-type.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { TruckHeightEnum } from '@/enums/truck-height.enum';
import { TruckWidthEnum } from '@/enums/truck-width.enum';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
import { Util } from '@/util';
import { ref } from 'vue';
import { baggageApi } from '@/repository/api/internal/baggage';
import { Karte } from '@/karte';
import { useLoading } from '@/composables/helper/loading-helper';
import { FormValidator } from '@/models/validate-helper';
import { useNotification } from '@/composables/helper/page-helper';
import _ from 'lodash';
import { useBaggageValidator } from '@/composables/baggage-validator';
import { DateValue } from '@/models/vo/date';

// TODO: 荷物更新に合わせたモデルにする
export const validationRules = (
    form: () => BaggageRegisterFormModel,
    defaultPaymentDate: DateValue | undefined,
): FormValidator<BaggageRegisterFormModel> => {
    // TODO: ここのセットアップ、もう少し良い方法があると思う
    const {
        departureDateTimeAsDateTimeRangePickerValue,
        arrivalDateTimeAsDateTimeRangePickerValue,
        departureLocation,
        arrivalLocation,
        loadingTimeNote,
        unloadingTimeNote,
        shapeAsEnum,
        typeNonnull,
        paletteCount,
        paletteSize,
        totalCount,
        totalVolume,
        truckEquipment,
        truckCount,
        freight,
        highwayFareFlg,
        paymentDateValue,
        description,
        negotiationType,
        staffName,
        shipperName,
        circleId,
        label,
    } = useBaggageValidator();

    const paymentDateContext = () => ({
        paymentDateValue: form().paymentDateValue,
        availablePaymentDateRange: form().availablePaymentDateRange,
        defaultPaymentDate: defaultPaymentDate,
    });

    return {
        departureDateTimeRange: departureDateTimeAsDateTimeRangePickerValue(form),
        arrivalDateTimeRange: arrivalDateTimeAsDateTimeRangePickerValue(form),
        departureLocation: departureLocation(form),
        arrivalLocation: arrivalLocation(form),
        loadingTimeNote: loadingTimeNote(form),
        unloadingTimeNote: unloadingTimeNote(form),
        shape: shapeAsEnum(form),
        type: typeNonnull(form),
        paletteCount: paletteCount(form),
        paletteSize: paletteSize(form),
        totalCount: totalCount(form),
        totalVolume: totalVolume(form),
        truckEquipment: truckEquipment(form),
        truckCount: truckCount(form),
        baggageFreight: freight(form),
        highwayFareFlg: highwayFareFlg(form),
        paymentDate: paymentDateValue(paymentDateContext),
        description: description(form),
        negotiationType: negotiationType(form),
        staffName: staffName(form),
        shipperName: shipperName(form),
        circleId: circleId(form),
        label: label(form),
    };
};

export const useBaggageRegister = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<BaggageRegisterFormModel>(new BaggageRegisterFormModel());
    const notification = useNotification();

    const clear = () => {
        form.value = new BaggageRegisterFormModel();
    };

    const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_BAGGAGE_ERROR';
    const register = () => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);
        try {
            const [baggageId] = await baggageApi.register(form.value.toForm());
            // KARTEイベント送信：荷物登録
            Karte.trackRegisterBaggage(baggageId);
            return baggageId;
        } catch {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
                message: '荷物の登録ができませんでした。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    return {
        state: {
            loading,
            form,
        },
        clear,
        register,
    };
};

export const useBaggageRegisterFormStore = createGlobalState(() => {
    const localValues = useLocalStorage<BaggageRegisterFormModel[]>('baggageRegisterForms', []);

    const save = (form: BaggageRegisterFormModel): void => {
        localValues.value = [form];
    };

    const clear = () => {
        localValues.value = [];
    };

    const load = (): BaggageRegisterFormModel | undefined => {
        if (!_.isEmpty(localValues.value)) {
            return new BaggageRegisterFormModel(localValues.value[0]);
        }
        return undefined;
    };

    return {
        save,
        clear,
        load,
    };
});

export const useBaggageRegisterFormQuery = () => {
    const parse = (route: RouteLocationNormalizedLoaded): baggageTypes.BaggageRegisterFormModel | undefined => {
        const queries = route.query;
        return QueryParser.build(queries.parser)?.parse(queries) ?? undefined;
    };

    return {
        parse,
    };
};

abstract class QueryParser {
    static build(version: any) {
        switch (version) {
            case '1':
                return new QueryParserV1();
            default:
                return undefined;
        }
    }

    public abstract parse(queries: Dictionary<string | (string | null)[]>): baggageTypes.BaggageRegisterFormModel | undefined;
}

class QueryParserV1 extends QueryParser {
    private parsed: boolean = false;

    public parse(queries: Dictionary<string | (string | null)[]>): baggageTypes.BaggageRegisterFormModel | undefined {
        const form = new baggageTypes.BaggageRegisterForm();
        Object.keys(queries).forEach((query, _index) => {
            const param = queries[query];
            switch (query) {
                case 'departureMin':
                    form.departureMin = this.parseString(param);
                    break;
                case 'departureMax':
                    form.departureMax = this.parseString(param);
                    break;
                case 'departurePref':
                    form.departurePref = this.parseEnum<PrefectureEnum>(param, (code) => PrefectureEnum.valueOf(code as PrefectureEnumCode));
                    break;
                case 'departureCity':
                    form.departureCity = this.parseString(param);
                    break;
                case 'departureAddress':
                    form.departureAddress = this.parseString(param);
                    break;
                case 'loadingTimeNote':
                    form.loadingTimeNote = this.parseString(param);
                    break;
                case 'arrivalMin':
                    form.arrivalMin = this.parseString(param);
                    break;
                case 'arrivalMax':
                    form.arrivalMax = this.parseString(param);
                    break;
                case 'arrivalPref':
                    form.arrivalPref = this.parseEnum<PrefectureEnum>(param, (code) => PrefectureEnum.valueOf(code as PrefectureEnumCode));
                    break;
                case 'arrivalCity':
                    form.arrivalCity = this.parseString(param);
                    break;
                case 'arrivalAddress':
                    form.arrivalAddress = this.parseString(param);
                    break;
                case 'unloadingTimeNote':
                    form.unloadingTimeNote = this.parseString(param);
                    break;
                case 'shape':
                    form.shape = this.parseEnumStrict<BaggageShapeEnum>(param, (code) => BaggageShapeEnum.valueOf(code as BaggageShapeEnumCode));
                    break;
                case 'type':
                    form.type = this.parseString(param);
                    break;
                case 'paletteCount':
                    form.paletteCount = this.parseNumberAsStringUndefined(param);
                    break;
                case 'paletteHeight':
                    form.paletteHeight = this.parseNumberAsStringUndefined(param);
                    break;
                case 'paletteWidth':
                    form.paletteWidth = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalCount':
                    form.totalCount = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalVolume':
                    form.totalVolume = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalWeight':
                    form.totalWeight = this.parseNumberAsStringUndefined(param);
                    break;
                case 'loading':
                    form.loading = this.parseEnumStrict<BaggageHandlingTypeEnum>(param, (code) => BaggageHandlingTypeEnum.valueOf(code));
                    break;
                case 'unloading':
                    form.unloading = this.parseEnumStrict<BaggageHandlingTypeEnum>(param, (code) => BaggageHandlingTypeEnum.valueOf(code));
                    break;
                case 'truckWeight':
                    form.truckWeight = this.parseEnum<TruckWeightEnum>(param, (code) => {
                        return TruckWeightEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckModel':
                    form.truckModel = this.parseEnum<TruckModelEnum>(param, (code) => {
                        return TruckModelEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckHeight':
                    form.truckHeight = this.parseEnumStrict<TruckHeightEnum>(param, (code) => {
                        return TruckHeightEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckWidth':
                    form.truckWidth = this.parseEnumStrict<TruckWidthEnum>(param, (code) => {
                        return TruckWidthEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'largeTruckFlg':
                    form.largeTruckFlg = this.parseBooleanUndefined(param);
                    break;
                case 'truckEquipment':
                    form.truckEquipment = this.parseStringUndefined(param);
                    break;
                case 'truckCount':
                    // コンポーネントの仕様上、初期値で上限を超えて設定すると見た目は上限の10だが内部的に上限を超えた値になっている為、
                    // ここで妥当な値を設定する必要がある。
                    form.truckCount = this.parseNumberAsString(param, { defaultValue: '1', maxValue: 10 });
                    break;
                case 'freight':
                    form.freight = this.parseFreight(param);
                    break;
                case 'highwayFareFlg':
                    form.highwayFareFlg = this.parseBooleanUndefined(param);
                    break;
                case 'staffName':
                    form.staffName = this.parseString(param);
                    break;
                case 'description':
                    form.description = this.parseStringUndefined(param);
                    break;
                case 'underNegotiation':
                    form.underNegotiation = this.parseBoolean(param);
                    break;
                case 'negotiationType':
                    form.negotiationType = this.parseEnumStrict<NegotiationTypeEnum>(param, (code) => NegotiationTypeEnum.valueOf(code));
                    break;
                case 'share':
                    form.share = this.parseBoolean(param);
                    break;
                case 'express':
                    form.express = this.parseBoolean(param);
                    break;
                case 'category':
                    form.category = this.parseEnum<BaggageCategoryEnum, BaggageCategoryEnumCode>(param, (code) => BaggageCategoryEnum.valueOf(code as BaggageCategoryEnumCode));
                    break;
                case 'paymentDate':
                    form.paymentDate = this.parseString(param);
                    break;
                default:
                    break;
            }
        });
        return this.parsed ? new BaggageRegisterFormModel(form) : undefined;
    }

    private parseString(value: any | undefined): string {
        this.parsed = true;
        if (value) return Util.castAsString(value) ?? '';
        return '';
    }

    private parseStringUndefined(value: any | undefined): string | undefined {
        this.parsed = true;
        if (value) return Util.castAsString(value);
        return undefined;
    }

    private parseNumberAsString(value: any | undefined, option: {
        defaultValue: string,
        maxValue: number | undefined
    } = {
        defaultValue: '0',
        maxValue: undefined
    }): string {
        this.parsed = true;
        if (value && Util.isNumeric(Util.toDigits(value))) {
            const inputValue = Util.toNumber(Util.toDigits(value));
            if (option.maxValue) {
                return (Math.min(inputValue, option.maxValue)).toString();
            } else {
                return inputValue.toString();
            }
        }
        return option.defaultValue;
    }

    private parseNumberAsStringUndefined(value: any | undefined): string | undefined {
        this.parsed = true;
        if (value && Util.isNumeric(Util.toDigits(value))) return Util.toDigits(value);
        return undefined;
    }

    private parseEnum<E, C = string>(value: any | undefined, parse: (code: string) => E | undefined): { code?: C } {
        this.parsed = true;
        if (value) {
            const code = Util.castAsString(value);
            if (code) return parse(code) ?? { code: undefined };
        }
        return { code: undefined };
    }

    private parseEnumStrict<E>(value: any | undefined, parse: (code: string | undefined) => E | undefined): E | undefined {
        this.parsed = true;
        if (value) {
            const code = Util.castAsString(value);
            return parse(code);
        }
        return undefined;
    }

    private parseBoolean(value: any, defaultValue: boolean = false): boolean {
        this.parsed = true;
        switch (value) {
            case 'T':
                return true;
            case 'F':
                return false;
            default:
                return defaultValue;
        }
    }

    private parseBooleanUndefined(value: any): boolean | undefined {
        this.parsed = true;
        switch (value) {
            case 'T':
                return true;
            case 'F':
                return false;
            default:
                return undefined;
        }
    }

    private parseFreight(value: any): string {
        this.parsed = true;
        return Util.parseFreightString(value);
    }
}
