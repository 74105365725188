import { appAxios, isSuccess, JsonResponseBean } from './base';
import { AgreementOnlineOrderAvailability } from '@/vuex/modules/agreement/types';

export const onlineOrderApi = {
    /**
     * 依頼書の有無を取得します。
     */
    exists(agreementId: number): Promise<boolean> {
        return appAxios.get<JsonResponseBean<boolean>>('/api/online-order/exists', { params: { agreementId: agreementId } }).then(isSuccess);
    },

    /**
     * 成約を行った企業がトラボックス受発注を利用できるか否かを取得します。
     */
    availability(agreementId: number): Promise<AgreementOnlineOrderAvailability> {
        return appAxios.get<JsonResponseBean<AgreementOnlineOrderAvailability>>('/api/online-order/availability', { params: { agreementId: agreementId } }).then(isSuccess);
    },

    /**
     * 自社がトラボックス受発注を利用できるか否かを取得します。
     */
    myAvailability(): Promise<boolean> {
        return appAxios.get<JsonResponseBean<boolean>>('/api/online-order/availability').then(isSuccess);
    },
};
