import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { questionnaireApi } from '@/repository/api/api';
import { ACTION as a, GETTER as g, MUTATION as m, Questionnaire, QuestionnaireState } from './types';

const state: QuestionnaireState = {
    questionnaire: { items: [] },
};

const actions: ActionTree<QuestionnaireState, void> = {
    [a.LOAD_QUESTIONNAIRE]: ({ commit }, use: 'REVOKE_PREMIUM' | 'DEACTIVATE_COMPANY') =>
        questionnaireApi.question(use).then((questionnaire) => commit(m.SET_QUESTIONNAIRE, questionnaire)),
};

const getters: GetterTree<QuestionnaireState, void> = {
    /**
     * アンケートを取得します。
     */
    [g.QUESTIONNAIRE]: (s) => s.questionnaire,
};

const mutations: MutationTree<QuestionnaireState> = {
    /**
     * アンケートを設定します。
     *
     * @param questionnaire アンケート
     */
    [m.SET_QUESTIONNAIRE]: (s, questionnaire: Questionnaire) => (s.questionnaire = questionnaire),
};

export const questionnaire: Module<QuestionnaireState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
