import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { BaggageDetailUtil } from '@/util';

@Component
export default class BaggageType extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get baggageType(): string {
        return this.baggage?.type ?? '';
    }

    get share(): string {
        if (!this.baggage) return '';
        return `積合：${this.baggage.share ? '○' : '×'}`;
    }

    get paletteCount(): string | undefined {
        return BaggageDetailUtil.paletteCount(
            this.baggage?.shape?.code,
            this.baggage?.paletteCount
        );
    }

    get paletteSize(): string | undefined {
        return BaggageDetailUtil.paletteSize(
            this.baggage?.shape?.code,
            this.baggage?.paletteHeight,
            this.baggage?.paletteWidth
        );
    }

    get totalCount(): string | undefined {
        return BaggageDetailUtil.totalCount(
            this.baggage?.shape?.code,
            this.baggage?.totalCount
        );
    }

    get totalVolume(): string | undefined {
        return BaggageDetailUtil.totalVolume(
            this.baggage?.shape?.code,
            this.baggage?.totalVolume
        );
    }

    get totalWeight(): string | undefined {
        return BaggageDetailUtil.totalWeight(this.baggage?.totalWeight);
    }

    get handling(): string | undefined {
        return BaggageDetailUtil.handling(
            this.baggage?.loading?.code,
            this.baggage?.unloading?.code
        );
    }
}
