import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { InquiryForm } from '@/vuex/modules/help/types';

@Component
export default class InquiryTargetStaffNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: InquiryForm;

    get targetStaffName(): string {
        return this.value?.targetStaffName ?? '';
    }

    set targetStaffName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.targetStaffName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            max: 60,
            message: '60文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.targetStaffName = this.targetStaffName.trim();
    }
}
