import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import _ from 'lodash';

@Component
export default class AgreementSpecifiedTruck extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;

    get specifiedTruck(): string {
        if (!this.agreement) return '';

        const height = this.agreement?.truckHeight?.label;
        const width = this.agreement?.truckWidth?.label;
        const largeTruckFlg = this.agreement?.largeTruckFlg;
        const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

        return `床高：${ height ?? '指定なし' } / 車幅：${ width ?? '指定なし' } / 大型：${ largeTruckText }`;
    }
}
