import { ref } from 'vue';
import { useVuexSync } from '@/composables/helper/vuex';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/statistics/types';
import { statisticsApi } from '@/repository/api/internal/statistics';
import { createGlobalState } from '@vueuse/core';
import { GlobalStatistics } from '@/models/statistics';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

/**
 * グローバル統計情報をロードする機能を提供します。
 */
export const useGlobalStatistics = createGlobalState(() => {
    const statistics = ref<GlobalStatistics | undefined>(undefined);
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();

    useVuexSync(statistics, { module: 'statistics', mutation: m.SET_GLOBAL_STATISTICS, getter: g.GLOBAL_STATISTICS });
    const load = async () => {
        if (!myProfile.value) await loadMyProfile();
        statistics.value = await statisticsApi.globalStatistics();
    };

    return {
        state: {
            statistics,
        },
        load,
    };
});
