import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route, Route as VueRoute } from 'vue-router/types/router';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
import store from '@/vuex/store';
import _ from 'lodash';
import { Const } from '@/const';
// @ts-ignore
import UiPaginationControl from '@/components/UI/PaginationControl';
// @ts-ignore
import BaggageTableView from '@/components/Baggage/Search/TableView';
// @ts-ignore
import BaggageSearchDetailDrawer from '@/components/Baggage/Search/DetailDrawer';
import { PageInfo } from '@/components/UI/PaginationControl/types';
import { PageUtil } from '@/util';
import { pagination } from '@/repository/storage/web-storage';
import { gtm } from '@/gtm';
import { Karte } from '@/karte';
import { PageVue } from '@/mixin/PageVue';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { loadNegotiationRequest, requestNegotiation } from '@/pages/Baggage/Search/helpers';

const baggageMod = namespace('baggage');
const accountMod = namespace('account');
const companyMod = namespace('company');
const negotiationMod = namespace('negotiation');

@Component({
    title: '保存した荷物',
    components: {
        UiPaginationControl,
        BaggageTableView,
        BaggageSearchDetailDrawer,
    },
    beforeRouteEnter: BaggageFavoritesPage.beforeRouteEnter,
    beforeRouteLeave: BaggageFavoritesPage.beforeRouteLeave,
})
export default class BaggageFavoritesPage extends PageVue {
    // 荷物一覧
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_LIST)
    readonly BAGGAGE_LIST?: baggageTypes.BaggageList;
    @companyMod.Getter(companyTypes.GETTER.PROFILE_LIST)
    readonly PROFILE_LIST?: companyTypes.CompanyProfile[];
    @baggageMod.Getter(baggageTypes.GETTER.FAVORITE_BAGGAGE_ID_LIST)
    readonly FAVORITE_BAGGAGE_ID_LIST?: number[];
    @baggageMod.Getter(baggageTypes.GETTER.READ_BAGGAGE_ID_LIST)
    readonly READ_BAGGAGE_ID_LIST?: number[];
    @baggageMod.Getter(baggageTypes.GETTER.FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST)
    readonly REFERENCE_FREIGHT_LIST?: baggageTypes.BaggageFreightMaster[];

    // 荷物詳細
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.CONFIDENCE)
    readonly CONFIDENCE?: companyTypes.CompanyConfidence;
    @companyMod.Getter(companyTypes.GETTER.STATISTICS)
    readonly STATISTICS?: companyTypes.CompanyStatistics;
    @baggageMod.Getter(baggageTypes.GETTER.MARKED_AS_FAVORITE_BAGGAGE)
    readonly MARKED_AS_FAVORITE?: boolean;
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_DETAIL_REFERENCE_FREIGHT)
    readonly REFERENCE_FREIGHT?: baggageTypes.BaggageFreightMaster;
    @negotiationMod.Getter(negotiationTypes.GETTER.NEGOTIATION_REQUESTS)
    readonly NEGOTIATION_REQUESTS?: negotiationTypes.Negotiation[];
    // アカウント情報
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly MY_PROFILE?: accountTypes.Profile;

    @Prop()
    declare readonly baggageId?: number;

    loading = true;
    routeUpdating = false;
    cleaningFavoriteBaggages = false;
    // 荷物IDごとのお気に入り登録解除のキャンセルID
    // TODO: リファクタリング - 本当はMapを使いたい！
    unmarkingFavoriteBaggageCancelables: [number, number][] = [];
    left = false;

    get cancelables(): number {
        return this.unmarkingFavoriteBaggageCancelables.length;
    }

    get myCompanyId(): number {
        return this.MY_PROFILE?.companyId ?? 0;
    }

    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get drawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return !_.isNaN(Number(this.baggageId));
    }

    get pageInfo(): PageInfo {
        return {
            totalPageCount: this.BAGGAGE_LIST?.totalPageCount ?? 0,
            totalRecordCount: this.BAGGAGE_LIST?.totalRecordCount ?? 0,
            currentPage: this.BAGGAGE_LIST?.currentPageNumber ?? 1,
            currentPageSize: this.BAGGAGE_LIST?.pageSize ?? Const.DEFAULT_PAGE_SIZE,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    }

    /**
     * お気に入り登録の解除キャンセル可能な荷物IDリスト
     */
    get cancelableUnmarkFavoriteBaggageIdList(): number[] {
        return this.unmarkingFavoriteBaggageCancelables.map((item) => item[0]);
    }

    /**
     * お気に入り登録の解除キャンセルが可能か否か（詳細ドローワー用）
     */
    get cancelableUnmarkFavoriteBaggage(): boolean {
        if (this.baggageId === undefined) return false;
        return this.getCancelId(this.baggageId) !== null;
    }

    get negotiation(): negotiationTypes.Negotiation | undefined {
        const negotiation = (this.NEGOTIATION_REQUESTS ?? []).find(each => each.baggageId === this.BAGGAGE?.id);
        return negotiation;
    }

    //
    // Lifecycle methods
    //

    async mounted(): Promise<void> {
        const pageSize = pagination.getPageSize();
        const initialPageSize =  pageSize && this.pageInfo.pageSizeOptions.includes(pageSize ?? '') ? Number(pageSize) : undefined;
        BaggageFavoritesPage.setPageTitle(this.$route);
        return this.loadFavoriteBaggageList(1, initialPageSize);
    }

    beforeDestroy(): void {
        this.left = true;
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageFavoritesPage>
    ): Promise<void> {
        //
        // ドロワー表示データ処理
        //
        const { baggageId } = to.query;
        if (baggageId) {
            await BaggageFavoritesPage.loadBaggage(Number(baggageId))
                .then(() => BaggageFavoritesPage.markRead(Number(baggageId)))
                .then(() => BaggageFavoritesPage.queryReferenceFreight())
                .catch(() => next({ name: 'NotFound' }));
        }

        next();
    }

    async beforeRouteUpdate(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageFavoritesPage>
    ): Promise<void> {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            next(false);
            return;
        }
        //
        // ドロワー表示データ処理
        //
        const { baggageId } = to.query;
        // 荷物詳細→荷物一覧に戻ってきたとき
        if (!baggageId) {
            // ページタイトルを設定
            BaggageFavoritesPage.setPageTitle(to);
            // 荷物詳細をクリア
            await BaggageFavoritesPage.clearBaggage();
            next();
            return;
        }
        // 荷物詳細を開いた状態で別の荷物詳細へ遷移しようとしたとき
        if (this.drawerVisibility && _from.query.baggageId !== baggageId) {
            this.routeUpdating = true;
        }
        await BaggageFavoritesPage.loadBaggage(Number(baggageId))
            .then(() => {
                // ページタイトルを設定
                BaggageFavoritesPage.setPageTitle(to);
            })
            .then(() => BaggageFavoritesPage.markRead(Number(baggageId)))
            .then(() => BaggageFavoritesPage.queryReferenceFreight())
            .then(() => next())
            .catch(() => {
                this.$message.error('アクセスしようとした荷物情報は見つかりません。');
                next(false);
            })
            .finally(() => {
                this.routeUpdating = false;
            });
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageFavoritesPage>
    ): Promise<void> {
        await Promise.all([
            BaggageFavoritesPage.clearBaggage(),
            BaggageFavoritesPage.clearBaggageList(),
            BaggageFavoritesPage.clearCompanyProfileList(),
            BaggageFavoritesPage.clearReferenceFreight(),
            BaggageFavoritesPage.clearReferenceFreightList(),
        ]);
        next();
    }

    //
    // Event handlers
    //

    /**
     * 荷物が選択された際に呼び出されます。
     */
    async onSelect(baggageId: number): Promise<void> {
        const currentBaggageId = this.$route.query.baggageId;
        if (currentBaggageId !== `${baggageId}`) {
            await this.$router.push({ path: '/baggage/favorites', query: { baggageId: `${baggageId}` } });
        }
        //@ts-ignore
        (this.$refs.baggageDrawer as BaggageSearchDetailDrawer).activeTabKey = 'baggage';
    }

    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    async onChangePage(param: { pageNo: number, pageSize: number }): Promise<void> {
        await this.loadFavoriteBaggageList(param.pageNo, param.pageSize);
        this.scrollToSearchResultsSection();
        pagination.setPageSize(param.pageSize.toString());
    }

    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    onClickCloseBaggageDetailDrawer(): Promise<Route> {
        return this.$router.push({ path: this.$route.path });
    }

    /**
     * 企業名が押下された際に呼び出されます。
     */
    async onClickCompanyName(companyId: string): Promise<void> {
        await this.$router.push({ name: 'CompanySearch', query: { companyId } });
    }

    /**
     * 成約が押下された際に呼び出されます。
     */
    async onClickAgree(baggageId: number): Promise<void> {
        await this.$router.push({ name: 'AgreementRegister', params: { id: `${baggageId}` } });
    }

    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        const route = this.$router.resolve({ name: 'BaggageSearchPrint', params: { baggageId: baggageId.toString() } });
        window.open(route.href, '_blank');
    }

    /**
     * お気に入り「保存」ボタンが押下された際に呼び出されます。
     */
    async onClickMarkFavorite(baggageId: number): Promise<void> {
        await BaggageFavoritesPage.markFavorite(baggageId)
            // KARTEイベント送信： お気に入り荷物保存
            .then(() => Karte.trackAddFavoriteBaggage(baggageId))
            .catch(() => this.$message.error('公開が終了した荷物のため、保存できません。'));

        await Promise.all([
            this.reloadFavoriteBaggageList(),
            this.reloadBaggageIfNeeded(baggageId),
        ]);
    }

    /**
     * お気に入り「保存済」ボタンが押下された際に呼び出されます。
     */
    async onClickUnmarkFavorite(baggageId: number): Promise<void> {
        // アクシデントにより、「解除」してしまう場合に備え、一定時間内であれば「解除」をキャンセルすることができます
        const cancelId = window.setTimeout(async () => {
            // キャンセル可能期間を経過したので、お気に入りを解除する
            await BaggageFavoritesPage.unmarkFavorite(baggageId)
                // KARTEイベント送信： お気に入り荷物解除
                .then(() => Karte.trackRemoveFavoriteBaggage(baggageId))
                .catch(() => this.$message.error('保存解除できませんでした。時間をおいて再度お試しください。'));

            // 一覧および詳細用のデータのリロードを行う
            if (!this.left) {
                await Promise.all([
                    this.reloadFavoriteBaggageList(),
                    this.reloadBaggageIfNeeded(baggageId),
                ]);
            }
            this.deleteCancelId(baggageId);
        }, 2000);

        this.saveCancelId(baggageId, cancelId);
    }

    /**
     * お気に入り登録解除の「キャンセル」ボタンが押下された際に呼び出されます。
     */
    onClickCancelUnmarkFavorite(baggageId: number): void {
        const cancelId = this.getCancelId(baggageId);
        if (cancelId !== null) {
            clearTimeout(cancelId);
            this.deleteCancelId(baggageId);
        }
    }

    /**
     * 「終了した荷物をすべて削除」ボタンが押下された際に呼び出されます。
     */
    async onClickCleanFavoriteBaggages(): Promise<void> {
        this.cleaningFavoriteBaggages = true;
        await BaggageFavoritesPage.cleanFavoriteBaggages()
            .catch(() => {
                this.cleaningFavoriteBaggages = false;
                this.$message.error('保存解除できませんでした。時間をおいて再度お試しください。');
            })
            .finally(() => {
                this.cleaningFavoriteBaggages = false;
                Promise.all([
                    this.reloadFavoriteBaggageList(),
                    this.reloadBaggageIfNeeded(this.baggageId),
                ]);
            });
    }

    /**
     * 商談リクエストを送る際に呼び出されます。
     */
    async onRequestNegotiation(baggageId: number, message: string | undefined): Promise<void> {
        await requestNegotiation(baggageId, message);
    }

    //
    // BaggageSearchContainerPage
    //

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        // 連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            return;
        }
        // Drawerが開いている場合は閉じる
        if (this.drawerVisibility) {
            await this.onClickCloseBaggageDetailDrawer();
        }
    }

    //
    // Helper methods
    //

    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    private scrollToSearchResultsSection(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
    }

    /**
     * 現在のページをリロードします。
     */
    private async reloadFavoriteBaggageList(): Promise<void> {
        const pageInfo = this.pageInfo;
        return this.loadFavoriteBaggageList(pageInfo?.currentPage ?? 1, pageInfo?.currentPageSize);
    }

    private async loadFavoriteBaggageList(
        pageNo: number,
        pageSize?: number
    ): Promise<void> {
        this.loading = true;
        await BaggageFavoritesPage.loadFavoriteBaggageList(pageNo, pageSize ? pageSize : Const.DEFAULT_PAGE_SIZE)
            .catch((err) => {
                this.$message.error(`荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。`);
                return Promise.reject(err);
            })
            .finally(() => (this.loading = false));
    }

    private async reloadBaggageIfNeeded(id: number | undefined): Promise<void> {
        if (!!id && Number(this.baggageId) === id) {
            await BaggageFavoritesPage.loadBaggage(id);
        }
    }

    /**
     * ページタイトルを設定します。
     */
    private static setPageTitle(route: Route): void {
        const { baggageId } = route.query;
        const pageTitle = ['保存した荷物'];
        if (baggageId) {
            pageTitle.unshift('荷物詳細');
        }
        // ページタイトルを設定
        PageUtil.setTitle(pageTitle);
        // GTMへページビューイベントを送信
        gtm.setPage(route.fullPath, pageTitle.join(Const.PAGE_TITLE_SEPARATOR));
    }

    /**
     * お気に入り荷物一覧をロードします。
     */
    private static loadFavoriteBaggageList(
        pageNo: number,
        pageSize: number
    ): Promise<void> {
        const form: baggageTypes.FavoriteBaggageListForm = {
            pageNo: pageNo,
            pageSize: pageSize ? pageSize : Const.DEFAULT_PAGE_SIZE,
        };
        return store.dispatch(`baggage/${baggageTypes.ACTION.LOAD_FAVORITE_BAGGAGE_LIST}`, form)
            .then(() => store.getters[`baggage/${baggageTypes.GETTER.BAGGAGE_LIST}`] as baggageTypes.BaggageList)
            .then((list) => {
                const companyIds = _.uniq(list.data.map((each) => each.companyId));
                // 企業情報をロード
                BaggageFavoritesPage.loadCompanyProfileList(companyIds).catch();
                // 既読状態をロード
                BaggageFavoritesPage.queryReadBaggageList().catch();
                // 参考運賃をロード
                BaggageFavoritesPage.loadReferenceFreightList(list.data).catch();
            })
            .then(() => Promise.resolve());
    }

    /**
     * 企業プロフィールリストをロードします。
     */
    private static loadCompanyProfileList(list: number[]): Promise<void> {
        if (_.isEmpty(list)) return Promise.resolve();
        const form: companyTypes.CompanyProfileListForm = { id: list };
        return store.dispatch(`company/${companyTypes.ACTION.LIST_PROFILE}`, form);
    }

    /**
     * お気に入り登録します。
     */
    private static markFavorite(baggageId: number): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.MARK_FAVORITE}`, baggageId);
    }

    /**
     * お気に入り登録を解除します。
     */
    private static unmarkFavorite(baggageId: number): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.UNMARK_FAVORITE}`, baggageId);
    }

    /**
     * 終了した荷物のお気に入りを一括解除します。
     */
    private static cleanFavoriteBaggages(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.CLEAN_FAVORITE_BAGGAGES}`);
    }

    /**
     * 荷物詳細関連のデータ一式をロードします。
     */
    private static async loadBaggage(id: number): Promise<void> {
        await store.dispatch(`baggage/${baggageTypes.ACTION.LOAD_BAGGAGE}`, id)
            .then((baggage) => {
                const { id, companyId, negotiationType } = baggage;
                const promises = [
                    store.dispatch(`company/${ companyTypes.ACTION.LOAD_PROFILE }`, companyId),
                    store.dispatch(`company/${ companyTypes.ACTION.LOAD_CONFIDENCE }`, companyId),
                    store.dispatch(`company/${ companyTypes.ACTION.LOAD_STATISTICS }`, companyId),
                    store.dispatch(`baggage/${ baggageTypes.ACTION.QUERY_FAVORITE }`, id),
                ];
                if (negotiationType.code === NegotiationTypeEnum.Online.code) {
                    promises.push(loadNegotiationRequest(id));
                }
                return Promise.all(promises);
            });
    }

    /**
     * 荷物情報をクリアします。
     */
    private static clearBaggage(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.CLEAR_BAGGAGE}`);
    }

    /**
     * 荷物一覧をクリアします。
     */
    private static clearBaggageList(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.CLEAR_BAGGAGE_LIST}`);
    }

    /**
     * 企業プロフィールをクリアします。
     */
    private static clearCompanyProfileList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.CLEAR_PROFILE_LIST}`);
    }

    /**
     * 参考運賃をクリアします。
     */
    private static clearReferenceFreight(): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_BAGGAGE_DETAIL_REFERENCE_FREIGHT }`);
    }

    /**
     * 参考運賃一覧をクリアします。
     */
    private static clearReferenceFreightList(): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST }`);
    }

    private saveCancelId(baggageId: number, cancelId: number) {
        this.deleteCancelId(baggageId);
        this.unmarkingFavoriteBaggageCancelables.push([baggageId, cancelId]);
    }

    private deleteCancelId(baggageId: number) {
        this.unmarkingFavoriteBaggageCancelables = this.unmarkingFavoriteBaggageCancelables.filter((item) => item[0] !== baggageId);
    }

    private getCancelId(baggageId: number): number | null {
        const item = this.unmarkingFavoriteBaggageCancelables.find((item) => item[0] == baggageId);
        return item !== undefined ? item[1] : null;
    }

    /**
     * 荷物を既読登録します。
     */
    private static markRead(id: number): void {
        // 詳細画面への遷移をスムーズにするため、既読処理はawaitしない
        store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_READ }`, id)
            .catch(() => { /* エラーは無視する */ });
    }

    /**
     * 荷物の既読状態を取得します。
     */
    private static queryReadBaggageList(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.QUERY_READ}`);
    }

    /**
     * 参考運賃リストを取得します。
     */
    private static loadReferenceFreightList(param: baggageTypes.Baggage[]): Promise<void> {
        if (_.isEmpty(param)) return Promise.resolve();
        const conditions = param.map(each => baggageTypes.BaggageFreightMasterQueryForm.newInstance(each));
        const form = new baggageTypes.BaggageFreightMasterListForm(conditions);
        return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST }`, form);
    }

    /**
     * 参考運賃を取得します。
     */
    private static queryReferenceFreight(): Promise<void> {
        const baggage = store.getters[`baggage/${ baggageTypes.GETTER.BAGGAGE }`] as baggageTypes.Baggage;

        const form = baggageTypes.BaggageFreightMasterQueryForm.newInstance(baggage);

        return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_DETAIL_REFERENCE_FREIGHT }`, form);
    }
}
