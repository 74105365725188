import { computed, ref } from 'vue';
import { BaggageList, BaggageSearchFormModel } from '@/models/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { baggageApi } from '@/repository/api/internal/baggage';
import _ from 'lodash';
import { Const } from '@/const';
import { FormValidateUtil } from '@/models/validate-helper';

/**
 * 荷物検索の荷物データをロードする機能を提供します。
 */
export const useBaggageSearch = () => {
    const defaultData = () => {
        return {
            pageSize: 0,
            totalRecordCount: 0,
            totalPageCount: 0,
            currentPageNumber: 1,
            data: [],
        };
    };

    const baggageList = ref<BaggageList>(defaultData());
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<BaggageSearchFormModel>(new BaggageSearchFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const load = async (pageNo: number, pageSize: number): Promise<void> => withLoading(async () => {
        changeFormValue(newForm => {
            newForm.pageNo = pageNo;
            newForm.pageSize = pageSize;
        });
        await search();
    });

    const search = async () => {
        baggageList.value =await baggageApi.search(form.value);
    };

    const changeFormValue = (callback: (newForm: BaggageSearchFormModel) => void): void => {
        const cloned = _.cloneDeep(form.value);
        callback(cloned);
        form.value = cloned;
    };

    const clear = () => {
        baggageList.value = defaultData();
    };

    const changeNegotiation = (baggageId: number, value: boolean): void => {
        baggageList.value.data.forEach(each => {
            if (each.id === baggageId) each.underNegotiation = value;
        });
    };

    const changePage = (pageNo: number, pageSize: number) => changeFormValue(newForm => {
        newForm.pageNo = pageNo;
        newForm.pageSize = pageSize;
    });

    const changeSort = (key: string, order: 'ASC' | 'DESC') => changeFormValue(newForm => {
        newForm.sortKey = key;
        newForm.sortOrder = order;
    });

    const resetForm = () => {
        const overrides = _.pick<BaggageSearchFormModel, keyof BaggageSearchFormModel>(
            form.value,
            ['pageNo', 'pageSize'],
        );
        form.value = Object.assign(new BaggageSearchFormModel(), overrides);
    };

    const baggageSortKey = Const.baggageSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    return {
        state: {
            loading,
            baggageList,
            form,
            formValidateRules,
            baggageSortKey,
        },
        load,
        search,
        clear,
        changeNegotiation,
        changePage,
        changeSort,
        resetForm,
    };
};
