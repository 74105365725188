import { FormModel } from 'ant-design-vue';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import { Karte } from '@/karte';
import { AgreementUtil, DateUtil } from '@/util';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import store from '@/vuex/store';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { CompanyContractModel } from '@/vuex/modules/company/company-contract.model';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import * as accountTypes from '@/vuex/modules/account/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
// @ts-ignore
import Spot from '@/components/Agreement/Register/Spot';
// @ts-ignore
import BaggageType from '@/components/Agreement/Register/Type';
// @ts-ignore
import BaggageTruck from '@/components/Agreement/Register/Truck';
// @ts-ignore
import BaggageSpecifiedTruck from '@/components/Agreement/Register/SpecifiedTruck';
// @ts-ignore
import BaggageTruckEquipment from '@/components/Agreement/Register/Equipment';
// @ts-ignore
import Freight from '@/components/Agreement/Register/Freight';
// @ts-ignore
import HighwayFare from '@/components/Agreement/Register/HighwayFare';
// @ts-ignore
import Description from '@/components/Agreement/Register/Description';
// @ts-ignore
import PaymentDate from '@/components/Agreement/Register/PaymentDate';
// @ts-ignore
import GuaranteeRegister from '@/components/Agreement/Register/Guarantee';
// @ts-ignore
import ConfirmationModal from '@/components/Agreement/Register/Confirmation';
// @ts-ignore
import Notice from '@/components/Agreement/Register/Notice';
// @ts-ignore
import AgreementGuaranteeUsage from '@/components/Agreement/GuaranteeUsage';
// @ts-ignore
import BaggageShape from '@/components/Agreement/Register/Shape';
// @ts-ignore
import PaletteCount from '@/components/Agreement/Register/PaletteCount';
// @ts-ignore
import PaletteSize from '@/components/Agreement/Register/PaletteSize';
// @ts-ignore
import BaggageTotalCount from '@/components/Agreement/Register/TotalCount';
// @ts-ignore
import BaggageTotalVolume from '@/components/Agreement/Register/TotalVolume';
// @ts-ignore
import BaggageTotalWeight from '@/components/Agreement/Register/TotalWeight';
// @ts-ignore
import BaggageHandling from '@/components/Agreement/Register/Handling';
// @ts-ignore
import TruckStaffName from '@/components/Agreement/Register/TruckStaffName';

const accountMod = namespace('account');
const baggageMod = namespace('baggage');
const companyMod = namespace('company');
const agreementMod = namespace('agreement');
const guaranteeMod = namespace('guarantee');

@Component({
    title: `荷物成約`,
    components: {
        Spot,
        BaggageType,
        BaggageTruck,
        BaggageSpecifiedTruck,
        BaggageTruckEquipment,
        Freight,
        HighwayFare,
        Description,
        PaymentDate,
        GuaranteeRegister,
        ConfirmationModal,
        AgreementGuaranteeUsage,
        Notice,
        BaggageShape,
        PaletteCount,
        PaletteSize,
        BaggageTotalCount,
        BaggageTotalVolume,
        BaggageTotalWeight,
        BaggageHandling,
        TruckStaffName,
    },
    beforeRouteEnter: AgreementRegisterPage.beforeRouteEnter,
})
export default class AgreementRegisterPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    ACCOUNT_PROFILE?: AccountProfileModel;
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    BAGGAGE?: baggageTypes.Baggage;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @companyMod.Getter(companyTypes.GETTER.EASY_PAYMENT_CONTRACT_LIST)
    readonly EASY_PAYMENT_CONTRACT_LIST?: CompanyContractModel[];
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.PAYMENT)
    PAYMENT?: companyTypes.CompanyPayment;
    @companyMod.Getter(companyTypes.GETTER.GUARANTEED_AMOUNT)
    GUARANTEED_AMOUNT?: companyTypes.CompanyGuaranteedAmount;
    @guaranteeMod.Getter(guaranteeTypes.GETTER.GUARANTEE_PRICE_MASTER)
    GUARANTEE_PRICE_MASTER?: guaranteeTypes.GuaranteePriceMaster[];
    @companyMod.Getter(companyTypes.GETTER.STAFF_NAME_SUGGESTION_LIST)
    staffNameSuggestions?: companyTypes.CompanyStaffNameSuggestionList;

    @agreementMod.Action(agreementTypes.ACTION.REGISTER_AGREEMENT)
    registerAgreement!: agreementTypes.registerAgreement;

    @agreementMod.Getter(agreementTypes.GETTER.ACCEPTED_AGREEMENT_COUNT)
    ACCEPTED_AGREEMENT_COUNT?: number;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageId?: number;

    get defaultPaymentDate(): string {
        if (!this.BAGGAGE) return '';
        if (this.BAGGAGE.paymentDate && !_.isEmpty(this.BAGGAGE.paymentDate)) return this.BAGGAGE.paymentDate;
        const departureDate = dayjs(this.BAGGAGE?.departureMin);
        const paymentDate = AgreementUtil.paymentDate(departureDate, this.PROFILE?.cutOffDay?.code, this.PROFILE?.paymentMonth?.code, this.PROFILE?.paymentDay?.code);
        return paymentDate.format('YYYY-MM-DD');
    }

    /**
     * 必要装備情報を取得
     */
    get truckEquipment(): string {
        return this.BAGGAGE?.truckEquipment ?? '';
    }
    /**
     * 契約情報を取得します。
     */
    get companyContracts(): companyTypes.CompanyContract[] {
        return this.CONTRACT_LIST?.contracts ?? [];
    }

    /**
     * 運賃全額保証サービスが利用可能か否かを取得します。
     */
    get existsGuarantee(): boolean {
        return this.CONTRACT_LIST?.existsGuarantee ?? false;
    }

    /**
     * おまかせ請求サービスが利用可能か否かを取得します。
     */
    get existsEasyPayment(): boolean {
        const now = DateUtil.now();
        const isEffective = (param: CompanyContractModel) => param.isAlive(now) || param.willAlive(now);
        return this.EASY_PAYMENT_CONTRACT_LIST?.some(isEffective) ?? false;
    }

    get shouldShowShapeDetailForPalette(): boolean {
        return this.form.shape?.code === BaggageShapeEnum.Palette.code;
    }

    get shouldShowShapeDetailForOther(): boolean {
        return this.form?.shape?.code === BaggageShapeEnum.Other.code;
    }

    // ======================================================
    // Data
    // ======================================================
    dirty = true;
    loading = false;
    confirmModalVisibility = false;
    guaranteeSubmitting = false;

    form: agreementTypes.AgreementForm = {
        baggageId: 0,
        arrivalAddress: '',
        arrivalCity: '',
        arrivalMax: '',
        arrivalMin: '',
        arrivalPref: { code: '' },
        departureAddress: '',
        departureCity: '',
        departureMax: '',
        departureMin: '',
        departurePref: { code: '' },
        type: '',
        shape: { code: '' },
        freight: '',
        highwayFareFlg: false,
        highwayFare: 0,
        waitTimeFee: undefined,
        operationFee: undefined,
        pickingFee: undefined,
        parkingFee: undefined,
        clearanceFee: undefined,
        guarantee: false,
        settlementProxy: false,
        paymentDate: '',
        truckStaffName: '',
        description: '',
    };

    private previousType = this.form.type;

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        // 支払い情報をロード。支払い情報のデータは確認モーダル内でしか使わないため、意図的にmountedでロードしている。
        AgreementRegisterPage.loadCompanyPayment();

        this.form.baggageId = Number(this.baggageId);
        this.form.departureMin = this.BAGGAGE?.departureMin ?? '';
        this.form.departureMax = this.BAGGAGE?.departureMax ?? '';
        this.form.departurePref.code = this.BAGGAGE?.departurePref.code;
        this.form.departureCity = this.BAGGAGE?.departureCity ?? '';
        this.form.departureAddress = this.BAGGAGE?.departureAddress;
        this.form.arrivalMin = this.BAGGAGE?.arrivalMin ?? '';
        this.form.arrivalMax = this.BAGGAGE?.arrivalMax ?? '';
        this.form.arrivalPref.code = this.BAGGAGE?.arrivalPref.code;
        this.form.arrivalCity = this.BAGGAGE?.arrivalCity ?? '';
        this.form.arrivalAddress = this.BAGGAGE?.arrivalAddress;
        this.form.type = this.BAGGAGE?.type ?? '';
        this.form.shape = { code: this.BAGGAGE?.shape.code ?? '' };
        this.form.paletteCount = this.BAGGAGE?.paletteCount?.toString();
        this.form.paletteHeight = this.BAGGAGE?.paletteHeight?.toString();
        this.form.paletteWidth = this.BAGGAGE?.paletteWidth?.toString();
        this.form.totalCount = this.BAGGAGE?.totalCount?.toString();
        this.form.totalVolume = this.BAGGAGE?.totalVolume?.toString();
        this.form.totalWeight = this.BAGGAGE?.totalWeight?.toString();
        this.form.loading = this.BAGGAGE?.loading ? { code: this.BAGGAGE.loading.code } : undefined;
        this.form.unloading = this.BAGGAGE?.unloading ? { code: this.BAGGAGE.unloading.code } : undefined;
        this.form.freight = `${this.BAGGAGE?.freight ?? 0}`;
        this.form.highwayFareFlg = this.BAGGAGE?.highwayFareFlg ?? false;
        this.form.truckStaffName = this.ACCOUNT_PROFILE?.name;
        this.form.description = this.BAGGAGE?.description ?? '';
        this.form.guarantee = false;

        this.trackShowAgreementPage();
    }

    /**
     * 入金予定日が運賃全額保証期間外であるか否かを取得します。
     */
    get isPaymentDateOutOfGuaranteePeriod(): boolean {
        if (this.form && this.form.paymentDate) {
            const arrivalDate = DateUtil.parseDatetimeText(this.form.arrivalMax);
            const paymentDate = DateUtil.parseDateText(this.form.paymentDate);
            const range = AgreementUtil.availablePaymentDateRange(arrivalDate, true);
            return !DateUtil.isIncluded(paymentDate, range);
        }
        return false;
    }

    /**
     * 運賃全額保証サービスお申し込みボタンが押下された際に呼び出されます。
     */
    async onClickApplyGuarantee(close: () => void): Promise<void> {
        const notifyKey = 'REGISTER_GUARANTEE';
        this.$notification.close(notifyKey);

        this.guaranteeSubmitting = true;
        await AgreementRegisterPage.registerGuarantee()
            .catch((err) => {
                this.$notification.error({
                    key: notifyKey,
                    message: '運賃全額保証サービスの申し込み手続きができませんでした。',
                    description:
                        '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                });
                return Promise.reject(err);
            })
            .finally(() => (this.guaranteeSubmitting = false));

        // KARTEイベント送信： 運賃保証申し込み
        Karte.trackApplyGuaranteeService();

        this.$notification.success({
            key: notifyKey,
            message: '運賃全額保証サービスの申し込み手続きが完了しました。',
            description: 'これより運賃全額保証サービスをご利用いただけます。お申し込みありがとうございました。',
        });
        close();
    }

    /**
     * KARTEに「成約画面の表示」イベントを送信します
     */
    trackShowAgreementPage(): void {
        if (!this.ACCOUNT_PROFILE) return;
        if (!this.BAGGAGE) return;
        if (!this.CONTRACT_LIST) return;
        if (_.isNil(this.ACCEPTED_AGREEMENT_COUNT)) return;

        Karte.trackShowAgreementPage({
            baggage_id: Number(this.baggageId),
            baggage_company_id: this.BAGGAGE.companyId,
            truck_company_id: this.ACCOUNT_PROFILE.companyId,
            under_contract_freight_protection: this.CONTRACT_LIST.existsGuarantee,
            agreement_count_with_baggage_company: this.ACCEPTED_AGREEMENT_COUNT,
            agreement_amount: this.BAGGAGE?.freight ?? 0,
        });
    }

    /**
     * ヘッダー部の戻るボタンを押下された際に呼び出されます。
     */
    onClickBack(): void {
        this.$router.go(-1);
    }

    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    onConfirm(applyGuarantee: boolean): void {
        this.form.guarantee = applyGuarantee;

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;
            this.confirmModalVisibility = true;
        });
    }

    /**
     * 成約ボタンが押下された際に呼び出される。
     */
    async onSubmit(): Promise<void> {
        const failedNotificationKey = 'REGISTER_AGREEMENT_ERROR';

        const onSuccess = async (agreementId: number) => {
            this.dirty = false;
            this.confirmModalVisibility = false;
            this.$message.success('荷物成約の手続きが完了しました。');
            await this.$router.push({ name: 'AgreementAcceptedList' });
            // KARTEイベント送信： 荷物成約
            Karte.trackAgreeBaggage(agreementId);
        };

        const notifyFailed = () => {
            this.confirmModalVisibility = false;

            this.$notification.error({
                key: failedNotificationKey,
                message: '成約を登録できませんでした。',
                description: '既に該当の荷物は成約済みになっている可能性があります。再度ご確認ください。',
            });
        };

        this.loading = true;
        this.$notification.close(failedNotificationKey);

        if (this.form.shape?.code === BaggageShapeEnum.Palette.code) {
            this.form.totalCount = undefined;
            this.form.totalVolume = undefined;
        } else if (this.form.shape?.code === BaggageShapeEnum.Other.code) {
            this.form.paletteCount = undefined;
            this.form.paletteHeight = undefined;
            this.form.paletteWidth = undefined;
        }

        // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        AgreementRegisterPage.registerCompanyStaffNameSuggestion(this.form.truckStaffName).catch(() => ({}));

        // TODO エラーハンドリング
        await AgreementRegisterPage.registerAgreement(this.form).then(onSuccess).catch(notifyFailed);

        this.loading = false;
    }

    /**
     * 荷姿が変更された際に呼び出される。
     */
    onChangeShape(): void {
        const shape = this.form.shape;
        if (!shape) return;
        if (shape.code === BaggageShapeEnum.Palette.code) {
            this.previousType = this.form.type;
            this.form.type = 'パレット';
        } else {
            this.form.type = this.previousType;
        }
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<AgreementRegisterPage>
    ): Promise<void> {
        const { id } = to.params;
        if (!id) {
            next({ name: 'NotFound' });
            return;
        }

        const today = DateUtil.now();
        Promise.all([
            AgreementRegisterPage.loadBaggage(Number(id)).then((baggage) => Promise.all([
                    AgreementRegisterPage.loadCompanyProfile(baggage.companyId),
                    AgreementRegisterPage.countAcceptedAgreement(baggage.companyId),
                ])
            ),
            AgreementRegisterPage.loadContractList(),
            AgreementRegisterPage.loadEasyPaymentContractList(),
            AgreementRegisterPage.loadGuaranteePriceMaster(),
            AgreementRegisterPage.loadGuaranteedAmount(today.year(), today.month() + 1),
            AgreementRegisterPage.loadCompanyStaffNameSuggestions(),
        ])
            .catch(() => next({ name: 'NotFound' }))
            .then(() => next());

        // TODO 荷物の状態がclosedならどっか適当な画面へ
    }

    async beforeRouteLeave(
        _to: VueRoute,
        _: VueRoute,
        next: NavigationGuardNext<AgreementRegisterPage>
    ): Promise<void> {
        if (this.dirty && !confirm('まだ成約は登録できていませんが、よろしいですか？')) {
            next(false);
        } else {
            await AgreementRegisterPage.clearBaggage();
            next();
        }
    }

    /**
     * 担当者サジェストの削除ボタンを押下した際に呼び出されます。
     * @param staffName
     */
    async onDeleteStaffNameSuggestion(staffName: string): Promise<void> {
        const notificationKey = 'DELETE_STAFF_NAME_SUGGESTION_ERROR';

        this.$notification.close(notificationKey);

        const onSuccess = () => AgreementRegisterPage.loadCompanyStaffNameSuggestions();
        const onError = () => {
            this.$notification.error({
                key: notificationKey,
                message: '担当者の履歴を削除できませんでした。',
                description: '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
        };

        AgreementRegisterPage.deleteCompanyStaffNameSuggestion(staffName).catch(onError).then(onSuccess);
    }

    /**
     * 荷物をロードします。
     */
    private static loadBaggage(id: number): Promise<baggageTypes.Baggage> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.LOAD_BAGGAGE}`, id);
    }

    /**
     * 荷物をクリアします。
     */
    private static clearBaggage(): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.CLEAR_BAGGAGE}`);
    }

    /**
     * 契約一覧をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }

    /**
     * おまかせ請求契約一覧をロードします。
     */
    private static loadEasyPaymentContractList(): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_EASY_PAYMENT_CONTRACTS }`);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadCompanyProfile(id: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_PROFILE}`, id);
    }

    /**
     * 支払情報をロードします。
     */
    private static loadCompanyPayment(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_PAYMENT}`);
    }

    /**
     * 運賃全額保証利用料金マスタをロードします。
     */
    private static loadGuaranteePriceMaster(): Promise<void> {
        return store.dispatch(`guarantee/${guaranteeTypes.ACTION.LOAD_GUARANTEE_PRICE_MASTER}`);
    }

    /**
     * 指定した年月の運賃保証サービスの月間成約金額をロードします。
     */
    private static loadGuaranteedAmount(year: number, month: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_GUARANTEED_AMOUNT}`, { year, month });
    }

    /**
     * 運賃全額保証サービスを申込みます。
     */
    private static registerGuarantee(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.REGISTER_GUARANTEE}`);
    }

    /**
     * 成約を登録します。
     */
    private static registerAgreement(form: agreementTypes.AgreementForm): Promise<number> {
        return store.dispatch(`agreement/${agreementTypes.ACTION.REGISTER_AGREEMENT}`, form);
    }

    /**
     * 担当者名おすすめ一覧をロードします。
     */
    private static loadCompanyStaffNameSuggestions(): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_STAFF_NAME_SUGGESTIONS }`);
    }

    /**
     * 担当者名を保存します。
     */
    private static registerCompanyStaffNameSuggestion(staffName: string | undefined): Promise<void> {
        if (staffName === undefined || staffName.length === 0) {
            return Promise.resolve();
        }
        return store.dispatch(`company/${ companyTypes.ACTION.REGISTER_STAFF_NAME_SUGGESTION }`, { staffName });
    }

    /**
     * 担当者名を削除します。
     */
    private static deleteCompanyStaffNameSuggestion(staffName: string): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.DELETE_STAFF_NAME_SUGGESTION }`, { staffName });
    }

    /**
     * 指定の荷主企業からの受託回数をロードします。
     */
    private static countAcceptedAgreement(baggageCompanyId: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.COUNT_ACCEPTED_AGREEMENT }`, baggageCompanyId);
    }
}
