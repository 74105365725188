<script setup lang="ts">
import { useTruckHelper } from '@/_pages/Truck/truck-helper';
import { useEnvironment } from '@/composables/global/environment';
import { computed } from 'vue';

const { state: { environment } } = useEnvironment();

// 空車表タブを表示するかどうか
const showTruckScheduler = computed<boolean>(() => {
    if(!environment?.value) return false;
    return environment.value.enableTruckScheduler == 'true';
});

const {
    routeActiveTabKey,
    onChangeTab
} = useTruckHelper();
</script>
<template>
    <a-layout-content>
        <a-layout style="height: 100%">
            <a-tabs class="truck-page-tabs"
                    type="card"
                    :tabBarStyle="{ backgroundColor: 'white', padding: '15px 24px 0', margin: '0' }"
                    :active-key="routeActiveTabKey"
                    @change="onChangeTab">
                <a-tab-pane key="search" tab="空車検索"/>
                <a-tab-pane key="register">
                    <template #tab>
                        <a-icon type="plus"/>空車登録
                    </template>
                </a-tab-pane>
                <a-tab-pane key="list" tab="登録した空車情報"/>
                <a-tab-pane key="company-truck" tab="車両マスタ"/>
                <a-tab-pane v-if=showTruckScheduler key="scheduler" tab="空車表(ベータ）"/>
            </a-tabs>
            <a-layout>
                <router-view/>
            </a-layout>
        </a-layout>
    </a-layout-content>
</template>
<style scoped lang="less">
.truck-page-tabs {
    background: @component-background;

    ::v-deep(.ant-tabs-nav) {
        .ant-tabs-tab:first-child {
            margin-right: 8px;
        }
    }
}
</style>
