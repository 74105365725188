import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementGuarantee extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;

    get guarantee(): string {
        if (!this.agreement || this.agreement.guarantee === undefined) return '';
        return this.agreement.guarantee ? '利用する' : '利用しない';
    }
}
