import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import {
    CompanyContractListModel,
    CompanyContractPremiumPlanRevokeUnavailableCode,
} from '@/vuex/modules/company/company-contract-list.model';

export type RevokeUnavailableCode =
    CompanyContractPremiumPlanRevokeUnavailableCode
    | 'NO_DATA'
    | 'NO_MAIN_ACCOUNT';

export type RevocableReason = { result: boolean; code?: RevokeUnavailableCode };

/**
 * プレミアムプランの解約ができる状態か否かを取得します。
 */
export const getRevocableReason = (
    accountProfile?: AccountProfileModel,
    contractList?: CompanyContractListModel
): RevocableReason => {
    if (!accountProfile || !contractList) {
        return { result: false, code: 'NO_DATA' };
    } else if (!accountProfile.isMainAccount) {
        return { result: false, code: 'NO_MAIN_ACCOUNT' };
    }
    return contractList.canRevokePremiumPlan;
};
