import { useLoading } from '@/composables/helper/loading-helper';
import { AccountPasswordResetFormModel, AccountPasswordUpdateFormModel } from '@/models/account';
import { computed, ref } from 'vue';
import { FormValidateUtil } from '@/models/validate-helper';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountPasswordUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<AccountPasswordUpdateFormModel>(new AccountPasswordUpdateFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const update = (verificationCode: string) => withLoading(async () => {
        await accountApi.updatePassword({
            password: form.value.password,
            verificationCode
        });
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        update,
    };
};

export const useAccountPasswordResetVerify = () => {
    const { state: { loading }, withLoading } = useLoading();

    const verify = (verificationCode?: string) => withLoading(async () => {
        if (!verificationCode?.trim()) throw Error('no verification code.');
        await accountApi.verifyAccountPasswordResetCode({ verificationCode });
    });

    return {
        state: {
            loading,
        },
        verify,
    };
};

export const useAccountPasswordReset = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new AccountPasswordResetFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const reset = (verificationCode?: string) => withLoading(async () => {
        if (!verificationCode?.trim()) throw Error('no verification code.');
        await accountApi.resetPassword({ verificationCode, password: form.value.password });
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        reset,
    };
};
