import _ from 'lodash';

const baggageShapeCodes = ['BS1', 'BS2'] as const;

/**
 * 荷姿のEnum
 */
export type BaggageShapeEnumCode = (typeof baggageShapeCodes)[number];

export function isBaggageShapeCode(code: string): code is BaggageShapeEnumCode {
    return _.includes(baggageShapeCodes, code);
}

export class BaggageShapeEnum {
    constructor(public code: BaggageShapeEnumCode, public label: string) {}

    static Palette = new BaggageShapeEnum('BS1', 'パレット');
    static Other = new BaggageShapeEnum('BS2', 'その他');

    static valueOf = (code?: string): BaggageShapeEnum | undefined =>
        BaggageShapeEnum.values.find((value) => value.code === code);

    static values = [BaggageShapeEnum.Palette, BaggageShapeEnum.Other];
}
