import { computed } from 'vue';
import { Const } from '@/const';

/**
 * 外部URLを提供します。
 */
export const useExternalPageUrl = () => {
    /**
     * 利用規約URL
     */
    const termUrl = computed<string>(() => {
        return Const.externalPageUrl.terms;
    });

    /**
     * 個人情報についてURL
     */
    const privacyPolicyUrl = computed<string>(() => {
        return Const.externalPageUrl.corporatePrivacyPolicy;
    });

    /**
     * グループ個人情報保護方針URL
     */
    const groupPrivacyPolicyUrl = computed<string>(() => {
        return Const.externalPageUrl.groupPrivacyPolicy;
    });

    return {
        state: {
            termUrl,
            privacyPolicyUrl,
            groupPrivacyPolicyUrl,
        }
    };
};
