<script setup lang="ts">
import { computed } from 'vue';
import { SettlementProxyFormItem } from '@/models/agreement';

const props = defineProps<{
    value: SettlementProxyFormItem;
    disabled: boolean;
}>();

const emits = defineEmits<{
    (e: 'input', value: SettlementProxyFormItem): void;
    (e: 'change'): void;
}>();

const use = computed({
    get: () => props.value.use ? 'on' : 'off',
    set: (value) => {
        emits('input', value === 'on' ? { use: true, confirmed: false } : { use: false });
    },
});

const confirmed = computed({
    get: () => props.value.use ? props.value.confirmed : false,
    set: (confirmed) => {
        if (props.value.use) {
            emits('input', { use: true, confirmed: confirmed });
        }
    }
});

</script>

<template>
    <div class="settlement-proxy-container">
        <a-radio-group
            v-model="use"
            :disabled="disabled"
            @change="emits('change')">
            <a-radio value="on">対象</a-radio>
            <a-radio value="off">対象外</a-radio>
        </a-radio-group>

        <a-checkbox v-if="value.use"
                    v-model="confirmed"
                    :disabled="disabled"
                    @change="emits('change')">
            <span>おまかせ請求を利用することを荷主に説明し、了承を得ました。</span>
        </a-checkbox>
    </div>
</template>

<style scoped lang="less">
.settlement-proxy-container {
    display: inline-flex;
    flex-direction: column;

    > * + * {
        margin-top: 10px;
    }
}
</style>
