<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import _ from 'lodash';

const props = defineProps<{
    baggage: Pick<Baggage, 'truckHeight' | 'truckWidth' | 'largeTruckFlg'>;
}>();

const text = computed<string>(() => {
    const height = props.baggage.truckHeight?.label;
    const width = props.baggage.truckWidth?.label;
    const largeTruckFlg = props.baggage.largeTruckFlg;
    const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

    return `床高：${ height ?? '指定なし' } 車幅：${ width ?? '指定なし' } 大型：${ largeTruckText }`;
});

</script>

<template>
    <span>{{ text }}</span>
</template>

<style scoped lang="less">

</style>
