<script setup lang="ts">
import { computed } from 'vue';
import { Util } from '@/util';

const props = withDefaults(defineProps<{
    value: number | undefined,
}>(), {});

const emits = defineEmits<{
    (e: 'input', value: number | undefined): void,
    (e: 'change', value: number | undefined): void,
}>();

const value = computed<string>({
    get: () => props.value?.toString() ?? '',
    set: (value: string) => {
        const num = value === '' ? undefined : Util.toNumber(value);
        emits('input', num);
        emits('change', num);
    },
});

</script>

<template>
    <a-space size="middle">
        <a-input style="max-width: 205px"
                 v-model="value">
        </a-input>
    </a-space>
</template>

<style scoped lang="less">

</style>
