import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import type dayjs from 'dayjs';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { ValidateResult, Validator } from '@/validator';
import { DateUtil } from '@/util';
// @ts-ignore
import DateTimeRangePicker from '@/_components/ui/DateTimeRangePicker.vue';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';
import { Const } from '@/const';
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

@Component({
    components: {
        DateTimeRangePicker,
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class AgreementSpotEdit extends Vue {
    validationRules: { [key: string]: Array<ValidationRule> } = {
        departureDateTimeRange: [
            {
                required: true,
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const { min, max, type } = this.departureDateTimeRange;
                    const value = (min && max && type) ? DeliveryDateTimeRange.typeOf(type, min, max) : null;
                    const validated = Validator.validateDateTimeRangeType(value, null);
                    if (!validated.result) {
                        callback(validated.message);
                    } else {
                        callback();
                    }
                },
            },
        ],
        departurePrefCode: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.departurePrefCode,
                message: '都道府県を選択してください。',
            },
        ],
        departureCity: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.departureCity,
                message: '市区町村名を入力してください。',
            },
            {
                transform: (): string => this.departureCity,
                max: Const.MAX_CITY,
                message: `市区町村名は${Const.MAX_CITY}文字以内で入力してください。`,
            },
        ],
        departureAddress: [
            {
                required: false,
                transform: (): string => this.departureAddress,
                max: Const.MAX_ADDRESS,
                message: `番地・建物は${Const.MAX_ADDRESS}文字以内で入力してください。`,
            },
        ],
        arrivalDateTimeRange: [
            {
                required: true,
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const { min, max, type } = this.arrivalDateTimeRange;
                    const value = (min && max && type) ? DeliveryDateTimeRange.typeOf(type, min, max) : null;
                    const validated = Validator.validateDateTimeRangeType(value, null);
                    if (!validated.result) {
                        callback(validated.message);
                    } else {
                        callback();
                    }
                },
            },
            {
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const rangeValidated = this.dateTimeRangeValidateResult.result;
                    if (rangeValidated) {
                        callback();
                    } else {
                        callback(this.dateTimeRangeValidateResult.message);
                    }
                },
            },
        ],
        arrivalPrefCode: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.arrivalPrefCode,
                message: '都道府県を選択してください。',
            },
        ],
        arrivalCity: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.arrivalCity,
                message: '市区町村名を入力してください。',
            },
            {
                transform: (): string => this.arrivalCity,
                max: Const.MAX_CITY,
                message: `市区町村名は${Const.MAX_CITY}文字以内で入力してください。`,
            },
        ],
        arrivalAddress: [
            {
                required: false,
                transform: (): string => this.arrivalAddress,
                max: Const.MAX_ADDRESS,
                message: `番地・建物は${Const.MAX_ADDRESS}文字以内で入力してください。`,
            },
        ],
    };

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementUpdateForm;
    @Prop()
    declare readonly agreement?: types.Agreement;

    /**
     * 出発日時
     */
    get departureDateTimeRange(): DateTimeRangePickerValue {
        // TODO: 将来的にはDeliveryDateTime型で扱いたい
        return {
            min: this.value?.departureMin,
            max: this.value?.departureMax,
            type: this.value?.departureType,
        };
    }

    set departureDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 出発日時を書き換え
        this.emitNewValue({
            departureMin: min ?? '',
            departureMax: max ?? '',
            departureType: type
        });

        this.changeDepartureOrArrival();
    }

    /**
     * 到着日時
     */
    get arrivalDateTimeRange(): DateTimeRangePickerValue {
        return {
            min: this.value?.arrivalMin,
            max: this.value?.arrivalMax,
            type: this.value?.arrivalType,
        };
    }

    set arrivalDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 到着日時を書き換え
        this.emitNewValue({
            arrivalMin: min ?? '',
            arrivalMax: max ?? '',
            arrivalType: type
        });
        this.changeDepartureOrArrival();
    }

    /**
     * 出発地（都道府県）
     */
    get departurePrefCode(): string {
        return this.value?.departurePref.code ?? '';
    }

    set departurePrefCode(newValue: string) {
        const changed = this.value?.departurePref.code !== newValue;

        // 出発地を書き換え
        this.emitNewValue({
            // 都道府県
            departurePref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            departureCity: changed ? '' : this.value?.departureCity,
            // 番地・建物（都道府県変更された場合はクリア）
            departureAddress: changed ? '' : this.value?.departureAddress,
        });

        this.changeDeparture();
    }

    /**
     * 出発地（市区町村）
     */
    get departureCity(): string {
        return this.value?.departureCity ?? '';
    }

    set departureCity(newValue: string) {
        // 出発地（市区町村）を書き換え
        this.emitNewValue({ departureCity: newValue });

        this.changeDeparture();
    }

    /**
     * 出発地（番地・建物）
     */
    get departureAddress(): string {
        return this.value?.departureAddress ?? '';
    }

    set departureAddress(newValue: string) {
        // 出発地（番地・建物）を書き換え
        this.emitNewValue({ departureAddress: newValue });

        this.changeDeparture();
    }

    /**
     * 到着地（都道府県）
     */
    get arrivalPrefCode(): string {
        return this.value?.arrivalPref.code ?? '';
    }

    set arrivalPrefCode(newValue: string) {
        const changed = this.value?.arrivalPref.code !== newValue;

        // 到着地を書き換え
        this.emitNewValue({
            // 都道府県
            arrivalPref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            arrivalCity: changed ? '' : this.value?.arrivalCity,
            // 番地・建物（都道府県変更された場合はクリア）
            arrivalAddress: changed ? '' : this.value?.arrivalAddress,
        });

        this.changeArrival();
    }

    /**
     * 到着地（市区町村）
     */
    get arrivalCity(): string {
        return this.value?.arrivalCity ?? '';
    }

    set arrivalCity(newValue: string) {
        // 到着地（市区町村）を書き換え
        this.emitNewValue({ arrivalCity: newValue });

        this.changeArrival();
    }

    /**
     * 到着地（番地・建物）
     */
    get arrivalAddress(): string {
        return this.value?.arrivalAddress ?? '';
    }

    set arrivalAddress(newValue: string) {
        // 到着地（番地・建物）を書き換え
        this.emitNewValue({ arrivalAddress: newValue });

        this.changeArrival();
    }

    /**
     * バリデーションの結果を取得します。
     */
    get dateTimeRangeValidateResult(): ValidateResult {
        const { min: departureMin, max: departureMax, type: departureType } = this.departureDateTimeRange;
        const departure = departureMin && departureMax && departureType ?
            DeliveryDateTimeRange.typeOf(departureType, departureMin, departureMax) : null;

        const { min: arrivalMin, max: arrivalMax, type: arrivalType } = this.arrivalDateTimeRange;
        const arrival = arrivalMin && arrivalMax && arrivalType ?
            DeliveryDateTimeRange.typeOf(arrivalType, arrivalMin, arrivalMax) : null;
        return Validator.validateMultipleDateTimeRange(departure, arrival);
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 成約編集については、成約日時の1時間後から1年後の年末まで有効
        const registered = DateUtil.parseDatetimeText(this.agreement?.entryTm ?? '');
        return [registered.add(1, 'hour').startOf('hour'), registered.add(1, 'year').endOf('year')];
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 出発地か到着地が変更された際に呼び出される。
     */
    private changeDepartureOrArrival(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTimeRange.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalDateTimeRange.onFieldChange();
        });
    }

    /**
     * 出発地が変更された際に呼び出される。
     */
    private changeDeparture(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTimeRange.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDeparturePrefCode.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDepartureCity.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDepartureAddress.onFieldChange();
        });
    }

    /**
     * 到着地が変更された際に呼び出される。
     */
    private changeArrival(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemArrivalDateTimeRange.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalPrefCode.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalCity.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalAddress.onFieldChange();
        });
    }

    /**
     * 新しい値を親にemitします。
     */
    private emitNewValue(newValues: Partial<types.AgreementUpdateForm>): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        this.$emit('input', _.merge(cloned, newValues));
    }
}
