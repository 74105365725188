<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    value: boolean,
}>();
const emits = defineEmits<{
    (e: 'input', value: boolean): void,
}>();

const autoSearch = computed<boolean>({
    get: (): boolean => props.value,
    set: (newValue: boolean): void => {
        emits('input', newValue);
    }
});
</script>

<template>
    <a-row type="flex" class="auto-search-container" id="baggage-auto-search">
        <a-col>自動検索</a-col>
        <a-col style="margin-left: 8px;">
            <a-switch v-model="autoSearch"/>
        </a-col>
    </a-row>
</template>

<style scoped lang="less">
.auto-search-container {
    //display: none;
    padding: 0 24px 16px 24px;
    background-color: #fafafa;
    box-shadow: 0 0 0 #ebedf0;
}
</style>
