<script setup lang="ts">
import { Profile } from '@/models/account';
import { AccountProfileModel } from '@/models/account-profile';
import { ProductUtil } from '@/util';

const props = defineProps<{
    me?: AccountProfileModel;
    accountList?: Profile[];
}>();

const emits = defineEmits<{
    (e: 'clickEdit'): void,
    (e: 'clickDelete', record: AccountProfileModel): void,
}>();

const columns = [
    {
        title: '担当者',
        key: 'name',
        scopedSlots: { customRender: 'name' },
    },
    {
        title: '役職',
        dataIndex: 'position.label',
    },
    {
        title: 'メールアドレス',
        scopedSlots: { customRender: 'email' },
    },
    {
        title: '',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
        align: 'right',
    },
];

const isMe = (record: Profile): boolean => {
    return props.me?.id === record.id;
};

const name = (record: Profile): string => {
    if (!props.me) return record.name;

    if (isMe(record)) return `${record.name}（本人）`;

    return record.name;
};

/**
 * 削除可能なアカウントかどうかを判定します。
 */
const isDeletable = (record: AccountProfileModel): boolean => {
    return checkDeletableAccount(record).isDeletable;
};

/**
 * 削除ができない場合の理由メッセージを取得する
 */
const getDisabledReasonMessage = (record: AccountProfileModel): string => {
    return checkDeletableAccount(record).reason ?? '';
};

/**
 * Profileから当該ユーザーが削除可能かどうかできるか確認する
 */
const checkDeletableAccount = (record: AccountProfileModel): { isDeletable: boolean; reason: string | undefined } => {
    // 1. 自分自身が管理者で、管理者を削除しようとしている場合
    if (record.isMainAccount) {
        return {
            isDeletable: false,
            reason: '管理者を削除することはできません。',
        };
    }
    // 2. 14日お試し期間中の場合
    else if (
        record.product?.code === ProductUtil.Trial14d.code ||
        record.product?.code === ProductUtil.TrialAccount14d.code
    ) {
        return {
            isDeletable: false,
            reason: 'お試し期間中はユーザーの削除はできません。',
        };
    }
    // 3. 有料別途課金で初月無料期間中の場合
    else if (
        record.product?.code === ProductUtil.AccountX.code ||
        record.product?.code === ProductUtil.PremiumX.code ||
        record.product?.code === ProductUtil.PremiumAccountX.code
    ) {
        return {
            isDeletable: false,
            reason: '初月無料利用期間のため、このユーザーは削除できません。翌月以降に行ってください。',
        };
    }

    return { isDeletable: true, reason: undefined };
};
</script>

<template>
    <a-table class="account-list-table" :columns="columns" :data-source="accountList" rowKey="id" :pagination="false">
        <!-- 名前 -->
        <template #name="text, record">
            <a-space direction="horizontal" size="small">
                <span class="word-break-text">{{ name(record) }}</span>
                <a-tag color="orange" v-if="record.isWaitingForVerification">認証待ち</a-tag>
                <a-tooltip v-if="record.isMainAccount" title="管理者は、ユーザーの追加と削除が行なえます。">
                    <a-tag color="blue">管理者</a-tag>
                </a-tooltip>
                <a-tag v-if="record.isDummyUser" color="pink">仮ユーザー</a-tag>
            </a-space>
        </template>

        <template #email="text, record">
            <span class="word-break-text">{{ record.email }}</span>
        </template>

        <!-- アクション -->
        <template #actions="text, record">
            <a-button v-if="isMe(record)" icon="edit" @click="emits('clickEdit')">編集</a-button>
            <a-button v-if="isDeletable(record)" icon="delete" class="alert"
                      @click="emits('clickDelete', record)">削除
            </a-button>
            <a-tooltip v-else :title="getDisabledReasonMessage(record)" placement="topLeft">
                <a-button icon="delete" :disabled="true" class="alert">削除</a-button>
            </a-tooltip>
        </template>
    </a-table>
</template>

<style scoped lang="less">
.alert {
    color: #FA541C;
}

.word-break-text {
    white-space: pre-wrap;
    word-break: break-all;
}

.account-list-table {
    .ant-btn {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .ant-btn + * {
        margin-left: 8px;
    }
}
</style>
