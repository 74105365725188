import _ from 'lodash';

export type ViewModelGetHandlers<T> = { [P in keyof T]?: () => T[P] };
export type ViewModelDidChangeHandlers<T> = { [P in keyof T]?: () => void };

export type ViewModelHandlers<T> = {
    getHandlers?: ViewModelGetHandlers<T>,
    didChangeHandlers?: ViewModelDidChangeHandlers<T>
};

export const buildViewModel = <U extends object, T extends U>(
    model: U,
    handlers: ViewModelHandlers<T>
): T => {
    const { getHandlers, didChangeHandlers } = handlers;

    return new Proxy(model, {
        get: (target, prop) => {
            if (getHandlers !== undefined && prop in getHandlers) {
                return getHandlers[prop as keyof T]?.();
            }
            return (model as any)[prop];
        },
        set: (target, prop, value): boolean => {
            if (prop in model) {
                const oldValue = model[prop as keyof U];
                if (!_.isEqual(oldValue, value)) {
                    (model as any)[prop] = value;
                    if (didChangeHandlers !== undefined) {
                        didChangeHandlers[prop as keyof T]?.();
                    }
                }
                return true;
            }
            return false;
        },
    }) as any;
};
