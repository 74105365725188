<script setup lang="ts">
import _ from 'lodash';
import { computed } from 'vue';
import { Util } from '@/util';
import { DateValue } from '@/models/vo/date';
import { FreightValue } from '@/models/vo/freight';

const props = withDefaults(defineProps<{
    myCompanyName: string,
    myStaffName: string,
    myEmail: string,
    targetCompanyName?: string,
    deliveryDate?: string,
    freight?: string,
    paymentDate?: string,
    description?: string,
}>(), {
    myCompanyName: '',
    myStaffName: '',
    myEmail: '',
    targetCompanyName: '',
    deliveryDate: '',
    freight: '',
    paymentDate: '',
    description: '',
});

const deliveryDateValue = computed(() => _.isEmpty(props.deliveryDate) ? undefined : new DateValue(props.deliveryDate));
const paymentDateValue = computed(() => _.isEmpty(props.paymentDate) ? undefined : new DateValue(props.paymentDate));
const freightValue = computed(() => _.isEmpty(props.freight) ? undefined : new FreightValue(Util.toNumber(props.freight)));
</script>

<template>
    <div class="container">
        <h4>法人名・事業者名：</h4>
        <span>{{ myCompanyName }}</span>

        <h4>担当者名：</h4>
        <span>{{ myStaffName }}</span>

        <h4>メールアドレス：</h4>
        <span>{{ myEmail }}</span>

        <h4>入金遅延先会社名：</h4>
        <span>{{ targetCompanyName }}</span>

        <template v-if="deliveryDate">
            <h4>運行日付：</h4>
            <span>{{ deliveryDateValue?.format('YYYY年M月D日(ddd)') }}</span>
        </template>

        <template v-if="freight">
            <h4>運賃：</h4>
            <span>{{ freightValue?.format() }}</span>
        </template>

        <template v-if="paymentDate">
            <h4>支払期日：</h4>
            <span>{{ paymentDateValue?.format('YYYY年M月D日(ddd)') }}</span>
        </template>

        <h4>その他状況など：</h4>
        <span class="description">{{ description }}</span>
    </div>
</template>

<style scoped lang="less">
.container {
    padding-top: @padding-lg;
    max-height: 70vh;
    overflow-y: auto;
}

h4:nth-child(n + 2) {
    margin-top: @padding-md;
}

.description {
    white-space: pre-wrap;
    word-break: break-all;
}
</style>
