import { ref } from 'vue';
import { CompanyProfile, CompanyProfileName } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';
import _ from 'lodash';

/**
 * 企業プロフィール一覧をロードする機能を提供します。
 */
export const useCompanyProfileList = () => {
    const list = ref<CompanyProfile[]>([]);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (companyIds: number[]) => {
        await withLoading(async () => {
            if (_.isEmpty(companyIds)) {
                list.value = [];
            } else {
                list.value = await companyApi.listProfile({ id: companyIds });
            }
        });
    };

    const clear = () => {
        list.value = [];
    };

    return {
        state: {
            list,
            loading,
        },
        load,
        clear,
    };
};

/**
 * パートナー企業プロフィールをロードする機能を提供します。
 */
export const usePartnerCompanyProfileList = () => {
    const list = ref<CompanyProfile[]>([]);

    const { state: { loading }, withLoading } = useLoading();

    const load = (agreementIds: number[]) => withLoading(async () => {
        if (_.isEmpty(agreementIds)) {
            list.value = [];
        } else {
            list.value = await companyApi.listPartnerProfile({ id: agreementIds });
        }
    });

    const name = (companyId: number): CompanyProfileName | undefined => {
        return list.value.find(each => each.id === companyId)?.name;
    };

    return {
        state: {
            loading,
        },
        load,
        name,
    };
};
