import { FormModel } from 'ant-design-vue';
import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import { Const } from '@/const';
// @ts-ignore
import Email from '@/components/Account/Remind/Email';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';
import * as types from '@/vuex/modules/account/types';
import store from '@/vuex/store';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    title: 'パスワード再設定',
    components: {
        TbxLinkText,
        UiPreLoginLayout,
        Email,
    },
})
export default class AccountPasswordRemindPage extends PageVue {
    loading = false;
    supportDesk = Const.supportDeskContacts;
    form: types.AccountPasswordRemindForm = {
        emailAddress: '',
    };

    /**
     * 送信ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const failedNotificationKey = 'REMIND_FAILED';
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(failedNotificationKey);

            const onSuccess = async () => {
                await this.$router.push({ name: 'AccountPasswordRemindComplete' });
            };
            const onNotifyFailed = () => {
                this.$notification.error({
                    key: failedNotificationKey,
                    message: 'パスワード再設定の送信に失敗しました。',
                    description: '入力内容をご確認のうえ、再度お試しください。',
                });
            };

            await AccountPasswordRemindPage.remindPassword(this.form)
                .then(onSuccess)
                .catch(onNotifyFailed)
                .finally(() => (this.loading = false));
        });
    }

    /**
     * 戻るボタンが押下された際に呼び出されます。
     */
    async onClickBackToLogin(): Promise<void> {
        await this.$router.push({ name: 'Login' });
    }

    /**
     * パスワード再設定APIリクエストを送信します。
     */
    private static remindPassword(form: types.AccountPasswordRemindForm): Promise<void> {
        return store.dispatch(`account/${types.ACTION.REMIND_PASSWORD}`, form);
    }
}
