import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { RegionEnum } from '@/enums/region.enum';
import _ from 'lodash';

export class RegionUtil {
    /**
     * 都道府県名の配列をもとに地域名に要約します。
     * @param prefectureCodes 都道府県コード
     */
    static parseRegionsFromPrefectures(prefectureCodes: Array<PrefectureEnumCode>): Array<RegionEnum | PrefectureEnum> {
        // 地域としてまとめられるものは地域名とする
        const regions = RegionEnum.values.filter((each) =>
            each.prefectures.every((regionPref) => prefectureCodes.includes(regionPref))
        );
        // すべての都道府県を選択した場合は「全国」とのみ表示
        if (regions.some((each) => each.code === RegionEnum.All.code)) {
            return [RegionEnum.All];
        }

        const otherPrefectures = _.difference(prefectureCodes, ...regions.map((each) => each.prefectures));
        return [
            ...regions,
            ...otherPrefectures.map((pref) => {
                const value = PrefectureEnum.valueOf(pref);
                if (!value) {
                    throw new Error('given prefecture value is not defined. [value] => ' + pref);
                }
                return value;
            }),
        ];
    }
}
