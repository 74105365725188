import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageFavoriteState } from '@/vuex/modules/baggage/types';

@Component
export default class BaggageDetailFavoriteButton extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    /**
     * 登録状態
     */
    @Prop()
    declare readonly state?: BaggageFavoriteState;

    /**
     * ツールチップを取得します。
     */
    get tooltip(): string | undefined {
        switch (this.state) {
            case 'Unmarked':
                return undefined;
            case 'Marked':
                return '保存を解除する';
            case 'Unmarking':
                return undefined;
            case undefined:
                return undefined;
        }
    }

    /**
     * アイコンを取得します。
     */
    get icon(): Array<string> | undefined {
        switch (this.state) {
            case 'Unmarked':
                return ['far', 'bookmark'];
            case 'Marked':
                return ['fas', 'bookmark'];
            case 'Unmarking':
                return ['far', 'bookmark'];
            case undefined:
                return undefined;
        }
    }

    /**
     * ラベルを取得します。
     */
    get label(): string | undefined {
        switch (this.state) {
            case 'Unmarked':
                return '保存する';
            case 'Marked':
                return '保存済み';
            case 'Unmarking':
                return '保存する';
            case undefined:
                return undefined;
        }
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * ボタンクリックをハンドリングします。
     */
    onClick(e: Event): void {
        e.cancelBubble = true;
        switch (this.state) {
            case 'Unmarked':
                this.$emit('mark');
                break;
            case 'Marked':
                this.$emit('unmark');
                break;
            case 'Unmarking':
                this.$emit('cancelUnmark');
                break;
            case undefined:
                break;
        }
    }
}
