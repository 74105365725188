import { Component, Dictionary, Route, RouteConfigMultipleViews, RouteConfigSingleView } from 'vue-router/types/router';
import _ from 'lodash';

type ComponentTypeKey = 'default' | 'back';

interface TbxRouteConfigSingleView extends RouteConfigSingleView {
    component?: Component;
    backComponent?: Component;
    meta?: TbxRouteMeta;
    children?: TbxRouteConfig[];
}

interface TbxRouteConfigMultipleViews extends RouteConfigMultipleViews {
    component?: Component;
    backComponent?: Dictionary<Component>;
    meta?: TbxRouteMeta;
    children?: TbxRouteConfig[];
}

type RouteMetaLayoutAccountType = 'MAIN_ACCOUNT' | 'ADDITIONAL_ACCOUNT';
type RouteMetaTitleResolveType = (route: Route) => string[];
type RouteMetaTitleFunctionType = () => string[];
type RouteMetaLayoutName = 'blank' | 'main';
interface RouteMetaLayout {
    name: RouteMetaLayoutName;
    contentOnly?: boolean;
    noFooter?: boolean;
    actionType?: string;
    showHelp?: boolean;
}

export interface TbxRouteMeta {
    layout?: RouteMetaLayout;
    title?: string | RouteMetaTitleFunctionType | RouteMetaTitleResolveType;
    activeTabKey?: string;
    accountType?: RouteMetaLayoutAccountType;
    actionType?: string;
}

export type TbxRouteConfig = TbxRouteConfigSingleView | TbxRouteConfigMultipleViews;

export const resolveTitle = (definition: Array<{ name: string, label: string }>, route: Route): string[] => {
    const isDefault = (name: string) => name === 'default';
    const inQuery = (name: string) => _.keys(route.query).includes(name);
    return definition.filter(each => isDefault(each.name) || inQuery(each.name)).map(each => each.label);
};
