import { inject, InjectionKey } from 'vue';

/**
 * VueのInjectをタイプセーフに行います。
 * @param key
 * @param defaultValue
 */
export const injectStrictly = <T>(key: InjectionKey<T>, defaultValue?: T): T => {
    const injected = inject(key, defaultValue);
    if (!injected) {
        throw new Error(`Could not inject ${ key.description }`);
    }
    return injected;
};
