import dayjs from 'dayjs';
import { DateUtil } from '@/util';
import { DateTimeValue } from '@/models/vo/datetime';
import { DateValue } from '@/models/vo/date';

/**
 * 発着日の時間フォーマット
 */
export interface DeliveryTimeFormats {
    day: string;
    morning: string;
    afternoon: string;
    hourly: string;
    just: string;
}

export type DeliveryDateTimeType = 'Day' | 'Morning' | 'Afternoon' | 'Hourly' | 'Just';

export class DeliveryDateTime {
    //
    // Class members
    //

    static readonly DEFAULT_TIME_FORMATS: DeliveryTimeFormats = {
        day: '指定なし',
        morning: '午前',
        afternoon: '午後',
        hourly: 'H時',
        just: 'H時mm分',
    };

    /**
     * 分指定(type: Just)する場合に、指定可能な分リスト
     */
    static readonly ACCEPTABLE_MINUTES = new Set([0, 10, 20, 30, 40, 50]);

    /**
     * [DeliveryDateTime]のインスタンスを生成します。
     * @param from
     * @param to
     */
    static of(from: dayjs.Dayjs | string | DateTimeValue, to: dayjs.Dayjs | string | DateTimeValue): DeliveryDateTime | null {
        const fromDt = this.parseDateTime(from);
        const toDt = this.parseDateTime(to);

        // 同一日であること
        if (!fromDt.isSame(toDt, 'day')) return null;

        const startOfDay = fromDt.startOf('day');
        const endOfMorning = startOfDay.hour(11).minute(59).second(59);
        const noon = startOfDay.hour(12);
        const endOfDay = startOfDay.hour(23).minute(59).second(59);
        const startOfHour = fromDt.startOf('hour');
        const endOfHour = startOfHour.minute(59).second(59);

        // 時間指定なし
        if (fromDt.isSame(startOfDay, 'second') && toDt.isSame(endOfDay, 'second')) {
            return new DeliveryDateTime('Day', startOfDay);
        }
        // 午前指定
        else if (fromDt.isSame(startOfDay, 'second') && toDt.isSame(endOfMorning, 'second')) {
            return new DeliveryDateTime('Morning', startOfDay);
        }
        // 午後指定
        else if (fromDt.isSame(noon, 'second') && toDt.isSame(endOfDay, 'second')) {
            return new DeliveryDateTime('Afternoon', startOfDay);
        }
        // 時指定
        else if (fromDt.isSame(startOfHour, 'second') && toDt.isSame(endOfHour, 'second')) {
            return new DeliveryDateTime('Hourly', fromDt);
        }
        // 時分指定
        else if (
            fromDt.isSame(toDt, 'second') &&
            fromDt.isSame(fromDt.startOf('minute'), 'second') &&
            this.ACCEPTABLE_MINUTES.has(fromDt.minute())
        ) {
            return new DeliveryDateTime('Just', fromDt);
        }
        // 不正パターン
        else {
            return null;
        }
    }

    /**
     * 発着日の時間タイプと日付をもとに、[DeliveryDateTime]のインスタンスを生成します。
     * @param type
     * @param target
     */
    static typeOf(type: DeliveryDateTimeType, target: dayjs.Dayjs | string): DeliveryDateTime | null {
        const targetDt = this.parseDateTime(target);
        const targetStartOfDay = targetDt.startOf('day');
        switch (type) {
            case 'Day':
                return DeliveryDateTime.of(targetStartOfDay, targetStartOfDay.endOf('day'));
            case 'Morning':
                return DeliveryDateTime.of(targetStartOfDay, targetStartOfDay.hour(11).endOf('hour'));
            case 'Afternoon':
                return DeliveryDateTime.of(targetStartOfDay.hour(12), targetStartOfDay.endOf('day'));
            case 'Hourly':
                return DeliveryDateTime.of(targetDt.startOf('hour'), targetDt.endOf('hour'));
            case 'Just':
                return DeliveryDateTime.of(targetDt, targetDt);
            default:
                return null;
        }
    }

    //
    // Instance members
    //

    readonly type: DeliveryDateTimeType;

    readonly datetime: dayjs.Dayjs;

    private constructor(type: DeliveryDateTimeType, datetime: dayjs.Dayjs) {
        this.type = type;
        this.datetime = datetime;
    }

    /**
     * 日付を取得します。（時刻部分は00:00:00）
     */
    get date(): dayjs.Dayjs {
        return this.datetime.startOf('day');
    }

    get dateValue(): DateValue {
        return new DateValue(this.date);
    }

    format(
        dateFormat = 'M/D(ddd)',
        timeFormats: DeliveryTimeFormats = DeliveryDateTime.DEFAULT_TIME_FORMATS,
        separator = ' '
    ): string {
        return this.datetime.format(dateFormat) + separator + this.formatTime(timeFormats);
    }

    formatTime(timeFormats: DeliveryTimeFormats = DeliveryDateTime.DEFAULT_TIME_FORMATS): string {
        switch (this.type) {
            case 'Day':
                return this.datetime.format(timeFormats.day);
            case 'Morning':
                return this.datetime.format(timeFormats.morning);
            case 'Afternoon':
                return this.datetime.format(timeFormats.afternoon);
            case 'Hourly':
                return this.datetime.format(timeFormats.hourly);
            case 'Just':
                return this.datetime.format(timeFormats.just);
        }
    }

    /**
     * API連携用のデータ表現を取得します。
     * @param format
     */
    rawValuesAsString(format = 'YYYY-MM-DD HH:mm:ss'): [string, string] {
        const [from, to] = this.rawValues();
        return [from.format(format), to.format(format)];
    }

    rawValues(): [dayjs.Dayjs, dayjs.Dayjs] {
        switch (this.type) {
            case 'Day':
                return [this.datetime, this.datetime.hour(23).minute(59).second(59)];
            case 'Morning':
                return [this.datetime, this.datetime.hour(11).minute(59).second(59)];
            case 'Afternoon':
                return [this.datetime.hour(12), this.datetime.hour(23).minute(59).second(59)];
            case 'Hourly':
                return [this.datetime, this.datetime.minute(59).second(59)];
            case 'Just':
                return [this.datetime, this.datetime];
        }
    }

    // for development
    toString(): string {
        switch (this.type) {
            case 'Day':
                return `Day(${this.datetime.format('YYYY-MM-DD')})`;
            case 'Morning':
                return `Morning(${this.datetime.format('YYYY-MM-DD')})`;
            case 'Afternoon':
                return `Afternoon(${this.datetime.format('YYYY-MM-DD')})`;
            case 'Hourly':
                return `Hourly(${this.datetime.format('YYYY-MM-DD HH')})`;
            case 'Just':
                return `Just(${this.datetime.format('YYYY-MM-DD HH:mm')})`;
        }
    }

    //
    // comparators
    //

    isBefore(other: DateValue): boolean {
        return this.dateValue.isBefore(other);
    }

    //
    // private members
    //

    private static parseDateTime(datetime: dayjs.Dayjs | string | DateTimeValue): dayjs.Dayjs {
        if (dayjs.isDayjs(datetime)) return datetime;
        if (typeof datetime !== 'string') return datetime.value;
        return DateUtil.parseDatetimeText(datetime);
    }
}
