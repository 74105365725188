<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useInquiryHelper } from '@/_pages/Inquiry/inquiry-helper';

const {
    phase,
    loading,
    profile,
    form,
    formModel,
    formValidateRules,
    onSubmit,
} = useInquiryHelper();
</script>

<template>
    <page-layout :back-icon="false">
        <a-spin :spinning="loading">
            <a-card class="board-container">

                <template v-if="phase === 'init'">
                    <a-row :gutter="[16, 24]">
                        <a-col :span="6" class="label"><span>法人名・事業者名:</span></a-col>
                        <a-col :span="12">{{ profile.companyName }}</a-col>
                    </a-row>

                    <a-row :gutter="[16, 24]">
                        <a-col :span="6" class="label"><span>担当者名:</span></a-col>
                        <a-col :span="12">{{ profile.staffName }}</a-col>
                    </a-row>

                    <a-row :gutter="[16, 24]">
                        <a-col :span="6" class="label"><span>メールアドレス:</span></a-col>
                        <a-col :span="12">{{ profile.email }}</a-col>
                    </a-row>

                    <a-form-model ref="formModel"
                                  :model="form"
                                  :rules="formValidateRules"
                                  :colon="false"
                                  :label-col="{ span: 6 }"
                                  :wrapper-col="{ span: 12 }">
                        <a-form-model-item label="お問い合わせ内容" prop="description">
                            <a-textarea v-model="form.description"
                                        :auto-size="{ minRows: 5, maxRows: 20 }"
                                        @blur="form.normalize()"/>
                        </a-form-model-item>

                        <a-row>
                            <a-col :offset="6" :span="12">
                                <br/>
                                <p>お問い合わせ内容がトラボックス求荷求車をご利用の企業様間でのトラブル・ご照会の場合は、相手先法人名・担当者名の入力をお願いします。</p>
                            </a-col>
                        </a-row>

                        <a-form-model-item label="【相手先】法人名・事業者名" prop="targetCompanyName">
                            <a-input placeholder="○○株式会社 ○○営業所"
                                     v-model="form.targetCompanyName"
                                     @blur="form.normalize()"/>
                        </a-form-model-item>

                        <a-form-model-item label="【相手先】担当者名" prop="targetStaffName">
                            <a-input placeholder="相手先の担当者名"
                                     v-model="form.targetStaffName"
                                     @blur="form.normalize()"/>
                        </a-form-model-item>

                        <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                            <a-button style="max-width: 200px"
                                      type="primary"
                                      size="large"
                                      block
                                      @click="onSubmit">確認
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </template>

                <!-- フォーム送信完了 -->
                <template v-else-if="phase === 'done'">
                    <a-result status="success">
                        <template #title>
                            お問い合わせありがとうございます。<br>
                            送信が完了しました。
                        </template>
                    </a-result>
                </template>
            </a-card>
        </a-spin>
    </page-layout>

</template>

<style scoped lang="less">
.board-container {
    margin: @padding-lg;
    padding: @padding-md @padding-lg;
}

.label {
    color: @ui-color-form-label-text;
    text-align: end;
}

::v-deep(.ant-form-item):first-child {
    margin-top: @padding-md;
}
</style>
