import { Settlement_DRAWER_PROVIDER_KEY } from '@/composables/provider/settlement-drawer-provider';
import { injectStrictly } from '@/util/vue';
import { computed } from 'vue';
import { AmountValue } from '@/models/vo/amount';
import { CancellationFeeValue, FreightValue, HighwayFareValue } from '@/models/vo/freight';
import { useMessage } from '@/composables/helper/page-helper';

export const useSettlementDetailDrawerHelper = () => {
    const message = useMessage();
    const {
        state: {
            entitlement,
            loading, settlement, partner, diff,
            iAmPayer,
            iAmPayee,
            canApprove,
            canReject,
            canCancel,
        },
        load,
        approve,
        confirm,
        reject,
        cancel,
        mark,
        unmark,
    } = injectStrictly(Settlement_DRAWER_PROVIDER_KEY);

    const canEdit = computed(() => {
        // Note: 決済機能一般公開までは変更ボタン自体を制御する
        if (!entitlement.value?.active('settlementProxy')) return false;

        // 取引先が退会済み
        if (partner.value?.active === false) return false;

        return !(canApprove.value || canReject.value || canCancel.value);
    });

    const showNormalAmount = computed(() => {
        if (!settlement.value?.hasCancellationFee) return true;
        return diff.value && !diff.value?.request?.hasCancellationFee;

    });
    const showCancellationAmount = computed(() => {
        if (settlement.value?.hasCancellationFee) return true;
        return diff.value && diff.value?.request?.hasCancellationFee;

    });

    const showHighlight = (model: FreightValue | HighwayFareValue | AmountValue | CancellationFeeValue | undefined) => {
        if (model instanceof HighwayFareValue) {
            return settlement.value?.status.isInitial && Number(model?.value?.amount) > 0;
        } else {
            return settlement.value?.status.isInitial && Number(model?.value) > 0;
        }
    };

    const onApprove = async () => {
        if (!settlement.value || !canApprove.value) return;
        await approve(settlement.value.id);
        await load(settlement.value.id);

        message.success('申請を承認しました。');
    };
    const onReject = async () => {
        if (!settlement.value || !canReject.value) return;
        if (!await confirm()) return;
        await reject(settlement.value.id);
        await load(settlement.value.id);

        message.success('申請を却下しました。');
    };
    const onCancel = async () => {
        if (!settlement.value || !canCancel.value) return;
        await cancel(settlement.value.id);
        await load(settlement.value.id);

        message.success('申請を取り下げました。');
    };
    const toggleConfirmation = async () => {
        if (!settlement.value) return;
        if (settlement.value.confirmed) {
            await unmark(settlement.value.id);
        } else {
            await mark(settlement.value.id);
        }
        await load(settlement.value.id);
    };

    return {
        loading,
        settlement,
        partner,
        diff,
        iAmPayer,
        iAmPayee,
        canEdit,
        canApprove,
        canReject,
        canCancel,
        showNormalAmount,
        showCancellationAmount,
        showHighlight,
        onApprove,
        onReject,
        onCancel,
        toggleConfirmation,
    };
};
