import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';

@Component({
    title: 'パスワードの再設定が完了しました',
    components: {
        UiPreLoginLayout,
    },
})
export default class AccountPasswordResetCompletePage extends PageVue {
    /**
     * ログインページへ移動するボタンを押下した際に呼び出されます。
     */
    onClickLoginButton(): void {
        this.$router.push({ name: 'Login' });
    }
}
