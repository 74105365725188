<script setup lang="ts">
import { injectStrictly } from '@/util/vue';
import { TIMELINE_SCHEDULER_PROVIDER_KEY } from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import { ref } from 'vue';

const {
    state: {
        renderedResources,
        timelineViewHeight,
        eventBus,
    },
} = injectStrictly(TIMELINE_SCHEDULER_PROVIDER_KEY);

const wrapper = ref<HTMLTableElement>();

// ScheduleTableの縦スクロールイベントを受け取り、ResourceHeaderのスクロール同期を行う
eventBus.on('VerticalScrolled', (value: number) => {
    wrapper.value?.scrollTo(0, value);
});

</script>

<template>
    <div class="resource-column-wrap" :style="{height: (timelineViewHeight - 120) + 'px'}" ref="wrapper">
        <table ref="table" class="resource-column-table">
            <tbody>
            <tr v-for="item in renderedResources" :key="item.Id">
                <td class="resource-cells" :style="{ height: `${item.height}px`}">
                    <div class="resource-details">
                        <div class="resource-name"> {{ item.Name }}</div>
                        <div class="resource-designation">{{ item.Subtitle }}</div>
                        {{ item.Description }}
                    </div>
                </td>
            </tr>
            <tr>
                <div style="height: 2em;"></div> <!-- scroll buffer-->
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="less">
.resource-column-wrap {
    overflow: hidden;
    position: relative;
}

.resource-column-table {
    border: 0 none;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    table-layout: fixed;
    width: 100%;
    height: auto;
}

.resource-cells {
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0 1px 1px 0;
    color: rgba(0, 0, 0, 0.87);
    height: 60px;
    padding-left: 15px;
    padding-right: 0;
    vertical-align: middle;
}
</style>
