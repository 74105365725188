import { useLoading } from '@/composables/helper/loading-helper';
import { SettlementChangeType, SettlementModel, SettlementUpdateFormModel } from '@/models/settlement';
import { computed, ref } from 'vue';
import _ from 'lodash';
import { FormValidateUtil } from '@/models/validate-helper';
import { settlementApi } from '@/repository/api/internal/settlement';

export const useSettlementUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<SettlementUpdateFormModel>(new SettlementUpdateFormModel());
    const sourceForm = ref<SettlementUpdateFormModel>(_.cloneDeep(form.value));

    const validationRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed(() => !_.isEqual(form.value, sourceForm.value));
    const changeType = computed<SettlementChangeType>(() => {
        if (sourceForm.value.proxy && !form.value.proxy) return 'EXCLUDE';
        if (!sourceForm.value.proxy && form.value.proxy) return 'INCLUDE';
        return 'CHANGE';
    });

    const initForm = (settlement: SettlementModel) => {
        form.value.isCanceling = settlement.hasCancellationFee;
        form.value.freight = settlement.freight.value ?? 0;
        form.value.highwayFare = settlement.highwayFare.value;
        form.value.waitTimeFee = settlement.waitTimeFee?.value ?? 0;
        form.value.operationFee = settlement.operationFee?.value ?? 0;
        form.value.pickingFee = settlement.pickingFee?.value ?? 0;
        form.value.parkingFee = settlement.parkingFee?.value ?? 0;
        form.value.clearanceFee = settlement.clearanceFee?.value ?? 0;
        form.value.cancellationFee = settlement.cancellationFee?.value ?? 0;
        form.value.proxy = settlement.proxy;
        sourceForm.value = _.cloneDeep(form.value);
    };

    const update = (id: number) => withLoading(async () => {
        switch (changeType.value) {
            case 'CHANGE':
                await settlementApi.requestChange(id, form.value.toApiForm());
                break;
            case 'INCLUDE':
                await settlementApi.requestInclude(id);
                break;
            case 'EXCLUDE':
                await settlementApi.requestExclude(id);
                break;
            default:
                throw new Error(`changeType: ${changeType.value} is not supported`);
        }
        sourceForm.value = _.cloneDeep(form.value);
    });

    return {
        state: {
            loading,
            form,
            validationRules,
            dirty,
            changeType,
        },
        initForm,
        update,
    };
};
