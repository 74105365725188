<script setup lang="ts">
import { Const } from '@/const';
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';
import { useSettingBillingHelper } from '@/_pages/Setting/Billing/setting-billing-helper';
import { columns } from './table-definition';

const {
    selectedYear,
    maxMonth,
    billingTerm,
    billingList,
    agreementList,
    guaranteedAmount,
    loading,
    loadingDetail,
    visibleDetail,
    hasInvoiceIssued,
    baggageCompanyName,
    onClickDownloadInvoice,
    onClickAgreementList,
    onChangeAgreementPage,
} = useSettingBillingHelper();
</script>

<template>
    <setting-layout :show-title="false">
        <div class="container">
            <!-- left pane -->
            <a-timeline class="section-timeline">
                <a-timeline-item v-for="year in billingTerm" :key="year">
                    <span @click="selectedYear = year"
                          class="timeline-item-label"
                          :class="{ 'timeline-item-label--selected': selectedYear === year }">{{ year }}年</span>
                </a-timeline-item>
            </a-timeline>

            <!-- right pane -->
            <div class="section-billing">
                <a-spin :spinning="loading">
                    <h2 class="section-billing--title">{{ selectedYear }}年ご利用金額</h2>
                    <a-alert type="warning">
                        <template #description>
                            ご利用料金の金額掲載につきましては、「トラボックス利用料」は毎月月末締めで翌月1日より掲載されます。また、特記がない限り税込金額で表示しております。<br>
                            料金につきましては、<a :href="Const.externalPageUrl.pricing" target="_blank" class="component-text-link-inside">こちら</a>をご参照ください。
                        </template>
                    </a-alert>

                    <br/>

                    <!-- 請求明細 -->
                    <p v-if="billingList.length === 0">{{ selectedYear }}年のご利用はございません。</p>
                    <a-collapse :active-key="`${selectedYear}-${maxMonth}`" v-else>
                        <a-collapse-panel v-for="billing in billingList"
                                          :key="`${billing.year}-${billing.month}`"
                                          :header="`${billing.year}年${billing.month}月`">
                            <table class="section-billing--list">
                                <tbody>
                                <tr v-for="(each, index) in billing.list" :key="`${each.year}${each.month}_${index}`">
                                    <td class="section-billing--list--date">
                                        <time :datetime="each.issueTm">{{ each.issueTm.format() }}</time>
                                    </td>
                                    <td class="section-billing--list--description">
                                        {{ each.product.label }}
                                        <span v-if="each.product.isGuarantee"
                                              class="text-link component-text-link-inside"
                                              @click="onClickAgreementList(each.year, each.month)">明細</span>
                                    </td>
                                    <td class="section-billing--list--amount">
                                        <span>{{ each.price.price.addAll(each.price.taxPrice).format() }}</span>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="2" class="section-billing--list--total">合計</td>
                                    <td class="section-billing--list--total-amount">
                                        <span>{{ billing.total.format() }}</span>
                                    </td>
                                </tr>
                                <tr v-if="hasInvoiceIssued(billing.year, billing.month)">
                                    <td colspan="3" class="section-billing--list--invoice-button">
                                        <a-button size="default"
                                                  @click="onClickDownloadInvoice(billing.year, billing.month)">
                                            請求書ダウンロード
                                        </a-button>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </a-collapse-panel>
                    </a-collapse>
                </a-spin>
            </div>
        </div>

        <a-modal v-model="visibleDetail"
                 :title="guaranteedAmount?.summary"
                 width="87vw"
                 centered
                 class="agreement-modal"
                 :closable="!loadingDetail"
                 :keyboard="!loadingDetail"
                 :mask-closable="false"
                 :footer="null">

            <h3 class="agreement-modal--title">成約詳細</h3>
            <a-table rowKey="id"
                     class="agreement-modal--table"
                     :columns="columns"
                     :data-source="agreementList.data"
                     :loading="loadingDetail"
                     :pagination="false">
                <template v-slot:agreementDate="text, record">
                    <div>{{ record.entryTm.toDate().format() }}</div>
                </template>
                <template v-slot:baggageCompanyName="text, record">
                    <span>{{ baggageCompanyName(record.baggageCompanyId)?.kanji }}</span>
                </template>
                <template v-slot:departure="text, record">
                    <div>
                        <div>{{ record.departure.format() }}</div>
                        <div>{{ record.departurePref.label }} {{ record.departureCity }}</div>
                    </div>
                </template>
                <template v-slot:arrival="text, record">
                    <div>
                        <div>{{ record.arrival.format() }}</div>
                        <div>{{ record.arrivalPref.label }} {{ record.arrivalCity }}</div>
                    </div>
                </template>
                <template v-slot:paymentDate="text, record">
                    <div>{{ record.paymentDate.format() }}</div>
                </template>
                <template v-slot:freight="text, record">
                    <div>{{ record.freight.format() }}</div>
                </template>
                <template v-slot:highwayFare="text, record">
                    <div>{{ record.highwayFare.format() }}</div>
                </template>
                <template v-slot:guarantee="text, record">
                    <div v-if="record.guarantee">○</div>
                    <div v-else>×</div>
                </template>
            </a-table>
            <a-pagination :style="{ textAlign: 'right' }"
                          v-model="agreementList.currentPageNumber"
                          :total="agreementList.totalRecordCount"
                          :default-page-size="10"
                          :page-size="agreementList.pageSize ? agreementList.pageSize : 10"
                          :show-total="total => `合計 ${total} 件`"
                          @change="pageNo => onChangeAgreementPage(pageNo)"
            />
        </a-modal>
    </setting-layout>
</template>

<style scoped lang="less">
.container {
    display: flex;
    padding: @padding-lg;
    width: 100%;
    background-color: @component-background;

    .section-timeline {
        margin-top: @padding-xs;
        width: 144px;

        .timeline-item-label {
            cursor: pointer;

            &--selected {
                color: @ui-color-selected;
                font-weight: bold;
            }
        }
    }

    .section-billing {
        flex: 1;

        > *:nth-child(n + 2) {
            margin-top: @padding-lg;
        }

        &--title {
            font-weight: 600;
        }

        &--list {
            width: 100%;

            tbody {
                tr {
                    border-bottom: solid 1px @color-neutral-4;
                }
            }

            .mixin-td() {
                padding: 9px @padding-md 9px;
                line-height: 22px;
            }

            &--date {
                .mixin-td;
                width: 160px;
            }

            &--description {
                .mixin-td;
                width: auto;

                .text-link {
                    display: inline-block;
                    cursor: pointer;
                }
            }

            &--amount {
                .mixin-td;
                width: 160px;
                text-align: right;
            }

            &--total {
                .mixin-td;
                font-weight: bold;
            }

            &--total-amount {
                .mixin-td;
                text-align: right;
                font-weight: bold;
            }

            &--invoice-button {
                padding: 0 @padding-md @padding-md;
                text-align: right;
            }
        }
    }
}

.agreement-modal {
    &--title {
        font-weight: 600;
    }

    &--table {
        margin-top: @padding-md;
        margin-bottom: @padding-md;
    }
}
</style>
