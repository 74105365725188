import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { BaggageUtil } from '@/util';

@Component
export default class BaggageDescriptionEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get description(): string {
        return this.value?.description ?? '';
    }

    set description(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.description = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.description = this.description.trim();
    }

    private validate(callback: (message?: string) => void): void {
        const description = this.value?.description?.trim() ?? '';
        if (description.length > 2000) {
            callback('2000文字以内で入力してください。');
            return;
        }
        // NGワードチェック
        const invalidWords = BaggageUtil.validateDescription(description);
        if (invalidWords.length > 0) {
            callback(`${invalidWords.map((word) => `「${word}」`).join('')}は含めないようにしてください。`);
            return;
        }
        callback();
    }
}
