import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';

@Component
export default class BaggageTruckTypeEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    weights = TruckWeightEnum.registrableValuesForBaggage.map((each) => ({
        value: each.code,
        label: each.label,
        key: each.code,
    }));

    models = TruckModelEnum.registrableValuesForBaggage.map((each) => ({
        value: each.code,
        label: each.label,
        key: each.code,
    }));

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get truckModel(): string {
        return this.value?.truckModel.code ?? '';
    }

    set truckModel(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.truckModel = { code: newValue };
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get truckWeight(): string {
        return this.value?.truckWeight.code ?? '';
    }

    set truckWeight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.truckWeight = { code: newValue };
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.truckWeight.code) {
            callback('重量を選択してください。');
        } else if (!this.value?.truckModel.code) {
            callback('車種を選択してください。');
        } else {
            callback();
        }
    }
}
