import _ from 'lodash';
import { computed, onMounted, ref, watch } from 'vue';
import { DateUtil } from '@/util';
import { useCompanyBilling } from '@/composables/company-billing';
import { useAgreementMonthlyList } from '@/composables/agreement-monthly-list';
import { useGuaranteedAmount } from '@/composables/guarantee';
import { useInvoice } from '@/composables/invoice';
import { usePartnerCompanyProfileList } from '@/composables/company-profile-list';
import { Karte } from '@/karte';

export const useSettingBillingHelper = () => {
    const selectedYear = ref(DateUtil.now().year());
    const visibleDetail = ref(false);

    // 請求明細
    const {
        loadTerm: loadBillingTerm,
        loadList: loadBillingList,
        state: {
            term: billingTerm,
            billableList: billingList,
            loading: loadingBilling
        }
    } = useCompanyBilling();
    // 成約
    const {
        load: loadAgreement,
        clear: clearAgreement,
        state: {
            list: agreementList,
            loading: loadingAgreement
        }
    } = useAgreementMonthlyList();
    // パートナー企業プロフィール
    const {
        load: loadProfile,
        name: baggageCompanyName,
    } = usePartnerCompanyProfileList();
    // 運賃全額保証利用額
    const {
        load: loadGuaranteed,
        clear: clearGuaranteed,
        state: {
            amount: guaranteedAmount,
            loading: loadingGuaranteed
        }
    } = useGuaranteedAmount();
    // 請求書
    const {
        load: loadInvoice,
        download: downloadInvoice,
        state: {
            list: invoiceList,
        }
    } = useInvoice();

    const loading = computed(() => loadingBilling.value);
    const loadingDetail = computed(() => loadingAgreement.value || loadingGuaranteed.value);
    const maxMonth = computed(() => Math.max(...billingList.value.map(each => each.month)));

    /**
     * 年が変化した際に呼び出されます。
     */
    watch(selectedYear, async (newValue, oldValue) => {
        if (_.isNil(newValue) || newValue === oldValue) return;

        // 請求明細
        await loadBillingList(newValue);
        // Web請求書発行状況(非同期ロードとするためnot await)
        loadInvoice(newValue).catch();
    }, { immediate: true });

    /**
     * (Web)請求書が発行されたか否かを取得します。
     */
    const hasInvoiceIssued = (year: number, month: number) => {
        return invoiceList.value.some(each => each.year === year && each.month === month && each.issued);
    };

    /**
     * 請求書ダウンロードが押下された際に呼び出されます。
     */
    const onClickDownloadInvoice = async (year: number, month: number) => {
        await downloadInvoice(year, month);
        Karte.trackDownloadInvoice(year, month);
    };

    /**
     * 明細リンクが押下された際に呼び出されます。
     */
    const onClickAgreementList = async (year: number, month: number) => {
        clearGuaranteed();
        clearAgreement();

        visibleDetail.value = true;

        await loadGuaranteed(year, month);
        await loadAgreement(year, month);
        await loadProfile(agreementList.value.data.map(each => each.id));
    };

    /**
     * 明細のページが変更された際に呼び出されます。
     */
    const onChangeAgreementPage = async (pageNo: number) => {
        if (_.isNil(guaranteedAmount.value)) return;

        const { year, month } = guaranteedAmount.value;
        await loadAgreement(year, month, pageNo);
        await loadProfile(agreementList.value.data.map(each => each.id));
    };

    onMounted(async () => {
        await loadBillingTerm();
    });

    return {
        loading,
        loadingDetail,
        visibleDetail,
        billingTerm,
        billingList,
        agreementList,
        guaranteedAmount,
        selectedYear,
        maxMonth,
        hasInvoiceIssued,
        baggageCompanyName,
        onClickDownloadInvoice,
        onClickAgreementList,
        onChangeAgreementPage,
    };
};
