import { useBaggage } from '@/composables/baggage';
import { useCompanyProfile } from '@/composables/company-profile';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { useOfficialCompany } from '@/composables/company-official';

export const useBaggagePrintHelper = (baggageId?: number) => {
    const { goToNotFound } = useRouting();
    const { state: { baggage, loading: loadingBaggage }, load: loadBaggage } = useBaggage();
    const { state: { profile, loading: loadingProfile }, load: loadProfile } = useCompanyProfile();
    const { state: { officialCompany, loading: loadingOfficialCompany }, load: loadOfficialCompany } = useOfficialCompany();

    const loading = computed(() => loadingBaggage.value || loadingProfile.value || loadingOfficialCompany.value);
    const informationComment = ref<string>('');

    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    const onClickPrint = () => {
        window.print();
    };
    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    const onClickClose = () => {
        window.close();
    };

    onBeforeMount(async () => {
        if (!baggageId) {
            await goToNotFound();
            return;
        }
        await loadBaggage(baggageId);
        if (!baggage.value) {
            await goToNotFound();
            return;
        }
        await Promise.all([
            loadProfile(baggage.value.companyId),
            loadOfficialCompany(baggage.value.companyId).catch(() => false)
        ]);
    });

    return {
        baggage,
        profile,
        officialCompany,
        loading,
        informationComment,
        onClickPrint,
        onClickClose,
    };
};
