import _ from 'lodash';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountPasswordUpdateForm } from '@/vuex/modules/account/types';
import { Validator } from '@/validator';

@Component
export default class AccountPasswordEdit extends Vue {
    @Prop()
    declare readonly onPressEnter?: (e: Event) => void;
    @Prop()
    declare readonly value?: AccountPasswordUpdateForm;

    get password(): string {
        return this.value?.password ?? '';
    }

    set password(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.password = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemPassword.onFieldChange());
    }

    passwordConfirmValue = '';

    get passwordConfirm(): string {
        return this.passwordConfirmValue;
    }

    set passwordConfirm(newValue: string) {
        this.passwordConfirmValue = newValue;

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemPasswordConfirm.onFieldChange());
    }

    passwordValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) =>
                this.validatePassword(callback as (message?: string) => void),
        },
    ];

    passwordConfirmValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) =>
                this.validatePasswordConfirm(callback as (message?: string) => void),
        },
    ];

    private validatePassword(callback: (message?: string) => void): void {
        const validated = Validator.validatePasswordFormat(this.password);
        if (validated.result) {
            callback();
        } else {
            callback(validated.message);
        }
    }

    private validatePasswordConfirm(callback: (message?: string) => void): void {
        if (this.password !== this.passwordConfirm) {
            callback('確認のため同じパスワードをもう一度入力してください。');
        } else {
            callback();
        }
    }
}
