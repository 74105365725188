import { Const } from '@/const';
import { useRouting } from '@/composables/helper/routing';
import { useCompanyPayment } from '@/composables/company-payment';
import { computed, onBeforeMount } from 'vue';
import * as _ from 'lodash';

export const useUpgradePlanCompleteHelper = () => {
    const appServiceName = Const.APP_SERVICE_NAME;
    const height = 520;
    const { goToSettingCompanyProfile, goToByName } = useRouting();
    const { state: { loading: loadingPayment, payment }, load } = useCompanyPayment();

    const showAlert = computed(() => !loadingPayment.value && !_.isNil(payment.value));

    const onClickToTop = async () => {
        await goToByName('Index');
    };

    const onClickBack = async () => {
        await goToSettingCompanyProfile();
    };

    const initialize = async (): Promise<void> => {
        await load();
    };

    onBeforeMount(() => initialize());

    return {
        appServiceName,
        height,
        onClickToTop,
        onClickBack,
        showAlert,
        payment,
    };
};
