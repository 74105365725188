import _ from 'lodash';

const negotiationStatusEnumCodes = ['ACTIVE'] as const;

/**
 * 商談ステータスのEnum
 */
export type NegotiationStatusEnumCode = (typeof negotiationStatusEnumCodes)[number];

export function isNegotiationStatusEnumCode(code: string): code is NegotiationStatusEnumCode {
    return _.includes(negotiationStatusEnumCodes, code);
}

export class NegotiationStatusEnum {
    constructor(public code: NegotiationStatusEnumCode, public label: string) {}

    static Active = new NegotiationStatusEnum('ACTIVE', '有効');

    static valueOf = (code?: string): NegotiationStatusEnum | undefined =>
        NegotiationStatusEnum.values.find((value) => value.code === code);

    static values = [NegotiationStatusEnum.Active];
}
