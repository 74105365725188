<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import BaggagePrint from '@/_components/baggage/print/BaggagePrint.vue';
import CompanyPrint from '@/_components/company/print/CompanyPrint.vue';
import { useBaggageSearchPrintHelper } from '@/_pages/Baggage/Search/Print/baggage-search-print-helper';

const props = defineProps<{
    baggageId?: number,
}>();
const {
    baggage,
    profile,
    officialCompany,
    confidence,
    statistics,
    loading,
    onClickPrint,
    onClickClose,
} = useBaggageSearchPrintHelper(props.baggageId);
</script>

<template>
    <page-layout :show-header="false">
        <div class="print-container">
            <div v-if="!loading" class="print">
                <section class="print__header">
                    <h1>荷物情報詳細</h1>
                    <nav class="view-screen-only">
                        <ul class="print__header_actions">
                            <li><a-button @click="onClickPrint" type="primary" size="large">印刷</a-button></li>
                            <li><a-icon  @click="onClickClose" type="close" :style="{ fontSize: '20px' }"></a-icon></li>
                        </ul>
                    </nav>
                </section>
                <section class="print__content">
                    <baggage-print :baggage="baggage"
                                   :official-company="officialCompany"
                                   :profile="profile"
                                   v-if="baggage && profile"/>
                </section>
                <section class="print__footer">
                    <small>転載等、一切の二次利用を禁止します。</small>
                </section>
                <section class="print__header">
                    <h1>企業情報</h1>
                </section>
                <section class="print__content">
                    <company-print :profile="profile"
                                   :official-company="officialCompany"
                                   :confidence="confidence"
                                   :statistics="statistics"
                                   v-if="profile && confidence && statistics"/>
                </section>
                <section class="print__footer">
                    <small>転載等、一切の二次利用を禁止します。</small>
                </section>
            </div>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
@import '../../../../../less/common-styles/print-layout.less';
</style>
