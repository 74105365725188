<script setup lang="ts">
import { computed } from 'vue';
import { LargeTruckAvailability } from '@/models/baggage';

const options: { value: LargeTruckAvailability, label: string }[] = [
    { value: 'available', label: '可' },
    { value: 'unavailable', label: '不可' },
];

const props = defineProps<{
    value?: LargeTruckAvailability
}>();

const emit = defineEmits<{
    (e: 'input', value: LargeTruckAvailability | undefined): void,
}>();

const selectedValue = computed<LargeTruckAvailability | undefined>({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});

</script>

<template>
    <a-select placeholder="大型可否を選択"
              :options="options"
              v-model="selectedValue"
              :allowClear="true"/>
</template>

<style scoped lang="less">

</style>
