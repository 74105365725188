import { Component, Prop, Vue } from 'vue-property-decorator';
import { InquiryForm } from '@/vuex/modules/help/types';

@Component
export default class InquiryTargetStaffName extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly form?: InquiryForm;

    get targetStaffName(): string {
        return this.form?.targetStaffName ?? '';
    }
}
