import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { GuaranteeUtil, Util } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import { CompanyContractModel } from '@/models/company-contract';

@Component
export default class GuaranteeTable extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly contracts?: CompanyContractModel[];
    @Prop()
    declare readonly guaranteedAmount?: companyTypes.CompanyGuaranteedAmount;
    @Prop()
    declare readonly guaranteePriceMaster?: guaranteeTypes.GuaranteePriceMaster[];

    get guarantee(): boolean {
        return this.agreement?.guarantee === true;
    }

    /**
     * 変更前の保証対象金額（累計）
     */
    get beforeGuaranteedAmountText(): string {
        const amount = this.beforeGuaranteedAmount();
        if (amount === undefined) return '';
        return Util.formatNumber(amount);
    }

    /**
     * 変更後の保証対象金額（累計）
     */
    get afterGuaranteedAmountText(): string {
        const amount = this.afterGuaranteedAmount();
        if (amount === undefined) return '';
        return Util.formatNumber(amount);
    }

    /**
     * 変更前の運賃保証サービス料
     */
    get beforeServiceFeeText(): string {
        const amount = this.beforeGuaranteedAmount();
        if (amount === undefined) return '';
        return this.serviceFeeText(amount);
    }

    /**
     * 変更後の運賃保証サービス料
     */
    get afterServiceFeeText(): string {
        const amount = this.afterGuaranteedAmount();
        if (amount === undefined) return '';
        return this.serviceFeeText(amount);
    }

    // ======================================================
    // Functions
    // ======================================================
    private agreementGuaranteedAmount(): number | undefined {
        return this.agreement ? this.agreement.freight + this.agreement.highwayFare : undefined;
    }

    private beforeGuaranteedAmount(): number | undefined {
        return this.guaranteedAmount ? this.guaranteedAmount.amount : undefined;
    }

    private afterGuaranteedAmount(): number | undefined {
        const amount = this.agreementGuaranteedAmount();
        if (!this.guaranteedAmount || amount === undefined) return undefined;
        return this.guaranteedAmount.amount + (this.guarantee ? -amount : amount);
    }

    private serviceFeeText(guaranteedAmount: number): string {
        if (!this.guaranteePriceMaster || !this.contracts) return '';
        const price = GuaranteeUtil.calculateGuaranteePrice(
            guaranteedAmount,
            this.guaranteePriceMaster,
            this.contracts
        );
        return Util.formatNumber(price);
    }
}
