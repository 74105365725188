import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { Util } from '@/util';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';

@Component({
    components: {
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class CompanyLocationEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    /**
     * 郵便番号
     */
    get zipCode(): string | undefined {
        return this.value?.zipCode;
    }

    set zipCode(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.zipCode = Util.emptyStringToUndefined(Util.toDigits(newValue ?? '').substr(0, 7));
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 都道府県
     */
    get prefecture(): string | undefined {
        return this.value?.prefecture?.code;
    }

    set prefecture(newValue: string | undefined) {
        const changed = this.value?.prefecture?.code !== newValue;

        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.prefecture = newValue ? { code: newValue } : undefined;
        cloned.city = changed ? undefined : cloned.city;
        cloned.address = changed ? undefined : cloned.address;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 市区町村
     */
    get city(): string | undefined {
        return this.value?.city;
    }

    set city(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.city = Util.emptyStringToUndefined(newValue ?? '');
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 番地・建物
     */
    get address(): string | undefined {
        return this.value?.address;
    }

    set address(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.address = Util.emptyStringToUndefined(newValue ?? '');
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validateZipCode(callback as (message?: string) => void),
        },
        {
            transform: (): string => this.city ?? '',
            max: 200,
            message: '市区町村は200文字以内で入力してください。',
        },
        {
            transform: (): string => this.address ?? '',
            max: 200,
            message: '番地・建物は200文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 市区町村のフォーカスが外れた際に呼び出されます。
     */
    onBlurCity(): void {
        this.city = this.city?.trim();
    }

    /**
     * 番地・建物のフォーカスが外れた際に呼び出されます。
     */
    onBlurAddress(): void {
        this.address = this.address?.trim();
    }

    private validateZipCode(callback: (message?: string) => void): void {
        const zipCode = this.zipCode ?? '';
        if (zipCode?.length > 0 && !/[0-9]{7}/.test(zipCode)) {
            callback('郵便番号は7桁の数字を入力してください。');
        } else {
            callback();
        }
    }
}
