import { Component, Prop } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import _ from 'lodash';
import Vue from 'vue';
import { Util } from '@/util';

@Component
export default class AgreementOperationFee extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get operationFee(): string {
        if (!this.agreement) return '';

        if (_.isNil(this.agreement.operationFee)) return '金額未定';
        return `${Util.formatNumber(this.agreement.operationFee ?? 0)} 円`;
    }

    get operationFeeCr(): string {
        if (!this.agreementCr) return '';

        if (_.isNil(this.agreementCr.operationFee)) return '金額未定';
        return `${Util.formatNumber(this.agreementCr.operationFee ?? 0)} 円`;
    }

    get changeRequest(): string {
        return this.operationFee != this.operationFeeCr ? this.operationFeeCr : '';
    }
}
