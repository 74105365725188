import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import {
    CompanyPlace,
    CompanyPlaceList,
    CompanyPlacePaginatedList,
    CompanyPlaceRegisterFormValidatableModel,
    CompanyPlaceSearchFormModel, CompanyPlaceUpdateFormValidatableModel
} from '@/models/company-place';
import { companyPlaceApi } from '@/repository/api/internal/company-place';
import { useNotification } from '@/composables/helper/page-helper';
import { FormValidateUtil } from '@/models/validate-helper';
import _ from 'lodash';

export const useCompanyPlaceList = () => {
    const placeList = ref<CompanyPlaceList>({ data: [] });
    const { state: { loading }, withLoading } = useLoading();
    const load = async () => withLoading(async () => {
        placeList.value = await companyPlaceApi.listCompanyPlace();
    });

    return {
        state: {
            loading,
            placeList,
        },
        load,
    };
};

export const useCompanyPlaceSearch = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<CompanyPlacePaginatedList | undefined>();
    const form = ref<CompanyPlaceSearchFormModel>(new CompanyPlaceSearchFormModel());

    const search = (pageNo: number, pageSize: number) => withLoading(async () => {
        form.value.pageNo = pageNo;
        form.value.pageSize = pageSize;
        list.value = await companyPlaceApi.searchCompanyPlace(form.value);
    });

    return {
        state: {
            loading,
            list,
            form,
        },
        search,
    };
};


export const useFindCompanyPlace = () => {
    const { state: { loading }, withLoading } = useLoading();
    const companyPlace = ref<CompanyPlace | undefined>();
    const find = (companyPlaceId: number) => withLoading(async () => {
        companyPlace.value = await companyPlaceApi.find(companyPlaceId);
    });

    return {
        state: {
            loading,
            companyPlace,
        },
        find,
    };
};


export const useCompanyPlaceRegister = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_COMPANY_PLACE_FAILED';
    /**
     * 地点登録失敗を通知します。
     */
    const notifyFailedToRegister = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
        message: '地点情報を登録できませんでした。',
        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 地点登録失敗通知を閉じます。
     */
    const closeFailedToRegisterNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

    const form = ref<CompanyPlaceRegisterFormValidatableModel>(new CompanyPlaceRegisterFormValidatableModel());
    const sourceForm = ref<CompanyPlaceRegisterFormValidatableModel>(new CompanyPlaceRegisterFormValidatableModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (companyPlace: CompanyPlace) => {
        form.value = CompanyPlaceRegisterFormValidatableModel.of(companyPlace);
    };

    const register = async(): Promise<number> => {
        closeFailedToRegisterNotification();
        try {
            const companyPlaceId = await companyPlaceApi.registerCompanyPlace(form.value);
            sourceForm.value = _.cloneDeep(form.value); // NOTE 画面移動時のconfirmation表示を回避するためsourceFormを更新する
            return companyPlaceId;
        } catch (e) {
            notifyFailedToRegister();
            throw e;
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        register,
    };
};

export const useCompanyPlaceUpdate = () => {
    const companyPlaceId = ref<number | undefined>(undefined);
    const form = ref<CompanyPlaceUpdateFormValidatableModel>(new CompanyPlaceUpdateFormValidatableModel());
    const sourceForm = ref<CompanyPlaceUpdateFormValidatableModel>(new CompanyPlaceUpdateFormValidatableModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (companyPlace: CompanyPlace) => {
        companyPlaceId.value = companyPlace.id;
        form.value = CompanyPlaceUpdateFormValidatableModel.of(companyPlace);
        sourceForm.value = _.cloneDeep(form.value);
    };

    const update = async () => {
        if (companyPlaceId.value) {
            await companyPlaceApi.updateCompanyPlace(companyPlaceId.value, form.value.toForm());
            sourceForm.value = _.cloneDeep(form.value); // NOTE 画面移動時のconfirmation表示を回避するためsourceFormを更新する
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        update,
    };
};

export const useCompanyPlaceDestroy = () => {
    const notification = useNotification();
    const notifyFailedKey = 'DELETE_COMPANY_PLACE_FAILED';

    const { state: { loading }, withLoading } = useLoading();

    const destroy = (id: number) => withLoading(async () => {
        notification.close(notifyFailedKey);
        await companyPlaceApi.deleteCompanyPlace(id).catch(() => {
            notification.error({
                key: notifyFailedKey,
                message: '地点情報を削除できませんでした。',
                description: '時間をおいて再度お試しください。'
            });
        });
    });

    return {
        state: {
            loading,
        },
        destroy,
    };
};
