import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportLatePaymentForm } from '@/vuex/modules/help/types';
import { Util } from '@/util';

@Component
export default class ReportLatePaymentFreightEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: ReportLatePaymentForm;

    get freight(): string {
        return this.value?.freight ?? '';
    }

    set freight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.freight = Util.parseFreightString(newValue);

        this.$emit('input', cloned);
    }
}
