<script setup lang="ts">
import RevokeLayout from '@/_pages/Setting/RevokePremiumPlan/_components/RevokeLayout.vue';
import {
    useRevokePremiumPlanIntroductionHelper
} from '@/_pages/Setting/RevokePremiumPlan/Introduction/revoke-premium-plan-introduction-helper';

const {
    profile,
    contracts,
    premiumPlanLabel,
    onClickUnsubscribeLink,
    agreed,
    onClickBack,
    onClickAgree,
} = useRevokePremiumPlanIntroductionHelper();
</script>

<template>
    <revoke-layout :account-profile="profile" :contract-list="contracts">
        <a-card class="body">
            <p v-if="premiumPlanLabel">現在、お客様がご利用の有料サービスのプランは <span class="plan-name">{{ premiumPlanLabel }}</span>となっております。</p>
            <p>日割りの計算は行っておりませんので、 停止受付月の月額利用料金のご請求は発生いたします。<br/>
                また、半年払い・年払い契約でご利用の場合、契約期間内の無料化に伴う返金は対応できかねますので、ご了承ください。</p>

            <p>有料サービスの停止をしますと、以下のサービスはご利用いただけなくなります。</p>
            <ul>
                <li>荷物情報検索</li>
                <li>荷物情報登録</li>
                <li>空車検索</li>
                <li>空車情報登録</li>
                <li>企業検索</li>
            </ul>
            <p>プレミアムプランの停止ではなく、退会を希望される場合は<a @click="onClickUnsubscribeLink" class="component-text-link-inside">こちら</a>からお手続きください。</p>

            <div class="agreement">
                <a-checkbox v-model="agreed" :disabled="!contracts || !contracts.canRevokePremiumPlan.result">上記の内容を確認しました。</a-checkbox>
            </div>

            <nav>
                <ul class="actions">
                    <li class="actions__item">
                        <a-button @click="onClickBack">戻る</a-button>
                    </li>
                    <li class="actions__item">
                        <a-button type="primary" :disabled="!agreed" @click="onClickAgree">プレミアムプランの停止手続きに進む</a-button>
                    </li>
                </ul>
            </nav>
        </a-card>
    </revoke-layout>
</template>

<style scoped lang="less">
.plan-name {
    font-weight: bold;
}

.agreement {
    margin-top: 48px;
    text-align: center;
}

.actions {
    margin-top: 24px;
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;

    .actions__item + .actions__item {
        margin-left: 16px;
    }
}
</style>
