import { computed, onMounted, ref } from 'vue';
import { useFormModel } from '@/composables/form-helper';
import { useModal } from '@/composables/helper/modal-helper';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useInquiryRegister } from '@/composables/inquiry';
import Content from '@/_components/parts/InquiryConfirmDialog.vue';

export const useInquiryHelper = () => {
    const phase = ref<'init' | 'done'>('init');

    const { formModel, submit } = useFormModel();
    const { confirm } = useModal();

    // 企業プロフィール
    const { load, state: { myProfile: companyProfile } } = useCompanyMyProfile();
    // アカウントプロフィール
    const { state: { profile: accountProfile } } = useAccountMyProfile();
    // お問い合わせ
    const { register, state: { loading, form, formValidateRules } } = useInquiryRegister();

    const profile = computed(() => ({
        companyName: companyProfile.value?.name.kanji,
        staffName: accountProfile.value?.name,
        email: accountProfile.value?.email
    }));

    const onSubmit = async () => submit(async () => {
        if (!await confirm(
            '以下の内容で送信してよろしいですか？',
            (h) => h(Content, {
                props: {
                    ...profile.value,
                    ...form.value.toJSON(),
                }
            }),
            '送信',
            'キャンセル',
            600
        )) return;

        await register().then(() => (phase.value = 'done')).catch();
    });

    onMounted(async () => await load());

    return {
        phase,
        loading,
        profile,
        form,
        formModel,
        formValidateRules,
        onSubmit,
    };
};
