import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import BaggagePrint from '@/components/Baggage/Print';
// @ts-ignore
import CompanyPrint from '@/components/Company/Print';

const baggageMod = namespace('baggage');
const companyMod = namespace('company');

@Component({
    title: '荷物検索情報印刷',
    beforeRouteEnter: BaggageSearchPrintPage.beforeRouteEnter,
    components: {
        BaggagePrint,
        CompanyPrint
    },
})
export default class BaggageSearchPrintPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.CONFIDENCE)
    readonly COMPANY_CONFIDENCE?: companyTypes.CompanyConfidence;
    @companyMod.Getter(companyTypes.GETTER.STATISTICS)
    readonly COMPANY_STATISTICS?: companyTypes.CompanyStatistics;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ required: true, type: Number })
    declare readonly baggageId?: number;

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrint(): void {
        window.print();
    }

    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    onClickClose(): void {
        window.close();
    }

    /**
     * 荷物をロードします。
     */
    private static loadBaggage(id: number): Promise<baggageTypes.Baggage> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE }`, id);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadCompanyProfile(id: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_PROFILE }`, id);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadCompanyConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_CONFIDENCE }`, companyId);
    }

    /**
     * 企業統計情報をロードします。
     */
    private static loadCompanyStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_STATISTICS }`, companyId);
    }


    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageSearchPrintPage>
    ): Promise<void> {
        const { baggageId } = to.params;
        const onError = () => next({ name: 'NotFound' });
        if (!baggageId) {
            onError();
        }
        BaggageSearchPrintPage.loadBaggage(Number(baggageId))
            .then((baggage) => {
                return Promise.all([
                    BaggageSearchPrintPage.loadCompanyProfile(baggage.companyId),
                    BaggageSearchPrintPage.loadCompanyConfidence(baggage.companyId),
                    BaggageSearchPrintPage.loadCompanyStatistics(baggage.companyId),
                ]);
            })
            .then(() => next())
            .catch(onError);
    }
}
