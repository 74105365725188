import { readonly, ref } from 'vue';
import { CompanyStatistics } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';

export const useCompanyMyStatistics = () => {
    const myStatistics = ref<CompanyStatistics>();

    const load = async () => {
        myStatistics.value = await companyApi.myStatistics();
    };

    return {
        state: {
            myStatistics: readonly(myStatistics),
        },
        load,
    };
};
