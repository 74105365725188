import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { settlementApi } from '@/repository/api/internal/settlement';
import { TransactionPaymentModel, TransactionPaymentTermModel, TransactionInvoiceTermModel, TransactionInvoiceModel } from '@/models/transaction';
import { DateValue } from '@/models/vo/date';
import { FileUtil } from '@/util/file';
import { useLocalStorage } from '@vueuse/core';
import { useRouting } from '@/composables/helper/routing';

interface storageProp {
    tabKey: 'income' | 'outgo';
}
const storage = useLocalStorage<storageProp>('settlementPaperTabKey', { tabKey: 'income' });

export const useSettlementPaperTabKey = () => {
    const { goToSettlementIncomePaper, goToTransactionInvoice } = useRouting();

    const goToSettlementPaper = async () => {
        switch (storage.value.tabKey) {
            case 'income':
                await goToSettlementIncomePaper();
                break;
            case 'outgo':
                await goToTransactionInvoice();
                break;
        }
    };

    return {
        tabKey: storage,
        goToSettlementPaper,
    };
};

/**
 * 取引支払通知書をロードする機能を提供します。
 */
export const useTransactionPayment = () => {
    const { state: { loading }, withLoading } = useLoading();

    const term = ref<TransactionPaymentTermModel>(TransactionPaymentTermModel.empty());
    const list = ref<TransactionPaymentModel[]>([]);

    /**
     * 発行期間をロードします。
     */
    const loadTerm = async () => withLoading(async () => {
        term.value = await settlementApi.paymentTerm()
            .then(result => new TransactionPaymentTermModel(result));
    });

    /**
     * 一覧をロードします。
     */
    const loadList = async (year: number) => withLoading(async () => {
        list.value = await settlementApi.paymentList(year)
            .then(list => list.map(each => new TransactionPaymentModel(each)));
    });

    const download = async (target: DateValue) => withLoading(async () => {
        const url = await settlementApi.issuePaymentLetterUrl(target);

        await FileUtil.downloadFile(url, `trabox_transaction_payment${ target.year }-${ target.monthValue }${ target.date }.pdf`);
    });

    return {
        state: {
            loading,
            term,
            list,
        },
        loadTerm,
        loadList,
        download,
    };
};

export const useTransactionInvoice = () => {
    const { state: { loading }, withLoading } = useLoading();

    const term = ref<TransactionInvoiceTermModel>(TransactionInvoiceTermModel.empty());
    const list = ref<TransactionInvoiceModel[]>([]);

    /**
     * 請求書発行期間をロードします。
     */
    const loadTerm = () => withLoading(async () => {
        term.value = await settlementApi.invoiceTerm()
            .then(result => new TransactionInvoiceTermModel(result));
    });

    /**
     * 請求書一覧をロードします。
     */
    const loadList = (year: number): Promise<void> => withLoading(async () => {
        const result = await settlementApi.listInvoice(year);
        list.value = result.map(data => new TransactionInvoiceModel(data));
    });

    /**
     * 請求書をダウンロードします。
     */
    const download = async (target: DateValue) => withLoading(async () => {
        const url = await settlementApi.downloadInvoiceUrl(target);

        await FileUtil.downloadFile(url, `trabox_transaction_invoice${ target.year }-${ target.monthValue }-${ target.date }.pdf`);
    });

    /**
     * 企業別明細をダウンロードします。
     */
    const downloadDetail = async (target: DateValue, payeeCompanyId: number) => withLoading(async () => {
        const url = await settlementApi.downloadInvoiceDetailUrl(target, payeeCompanyId);

        await FileUtil.downloadFile(url, `trabox_transaction_invoice_detail${ target.year }-${ target.monthValue }-${ target.date }_${ payeeCompanyId }.pdf`);
    });

    return {
        state: {
            loading,
            term,
            list,
        },
        loadTerm,
        loadList,
        download,
        downloadDetail,
    };
};
