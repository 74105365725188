import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
// @ts-ignore
import RevokeLayout from '@/components/Setting/RevokePremiumPlan/RevokeLayout';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import {
    CompanyContractListModel,
} from '@/vuex/modules/company/company-contract-list.model';
import { PageVue } from '@/mixin/PageVue';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import {
    getRevocableReason,
    RevocableReason,
} from '@/pages/Setting/RevokePremiumPlan/revoke-premium-plan-helpers';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: 'プレミアムプランの停止',
    components: {
        RevokeLayout,
    }
})
export default class RevokePremiumPlanPage extends PageVue {
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    agreed = false;

    /**
     * プレミアムプランの解約ができる状態か否かを取得します。
     */
    get canRevokePremiumPlan(): RevocableReason {
        return getRevocableReason(this.ACCOUNT_PROFILE, this.CONTRACT_LIST);
    }

    /**
     * ご利用中のプレミアムプランの契約名称を取得します。
     */
    get premiumPlanLabel(): string | undefined {
        return this.CONTRACT_LIST?.premiumPlanContract?.product.label;
    }

    /**
     * 「戻る」ボタンをクリックした際に呼び出されます。
     */
    onClickBack(): void {
        this.$router.go(-1);
    }

    /**
     * 「プレミアムプランの停止に進む」ボタンを押下された際に呼び出されます。
     */
    onClickAgree(): void {
        if (!this.agreed) {
            return;
        }
        this.$router.push({ name: 'RevokePremiumPlanEdit' });
    }

    onClickUnsubscribeLink(): void {
        this.$router.push({ name: 'Unsubscribe' });
    }
}
