<script setup lang="ts">
import SearchConditionForm from '../components/baggage/SearchConditionForm.vue';
import BaggageListBanner from '../components/BaggageListBanner.vue';
import BaggageTable from '../components/baggage/table/BaggageTable.vue';
import { useBaggageListOpenedHelper } from './baggage-list-opened-helper';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import BaggageDetailDrawer from '@/_pages/Baggage/List/drawers/BaggageDetail/BaggageDetailDrawer.vue';
import BaggageEditorDrawer from '@/_pages/Baggage/List/drawers/BaggageEditor/BaggageEditorDrawer.vue';
import TruckRecommendationMessage from './TruckRecommendationMessage/Message.vue';
import CompanyDetailDrawer from '@/_pages/Baggage/List/drawers/CompanyDetail/CompanyDetailDrawer.vue';
import type { DetailProps } from './props';

interface Props {
    detail?: DetailProps;
}

const props = defineProps<Props>();

const {
    searchFormVisible,
    isFiltered,
    loading,
    loadingDetail,
    loadingTruckRecommendations,
    loadingNegotiationList,
    form,
    validationMessage,
    list,
    sortInfo,
    pageInfo,
    baggageDrawerVisibility,
    baggageEditorDrawerVisibility,
    truckRecommendationMessageVisibility,
    selectedBaggageDetailTab,
    baggage,
    truckRecommendations,
    negotiationList,
    circleCompanyList,
    resultListRef,
    search,
    changePage,
    clearForm,
    selectBaggage,
    editBaggage,
    copyBaggage,
    cancelBaggage,
    exportBaggage,
    printBaggage,
    markUnderNegotiation,
    unmarkUnderNegotiation,
    closeBaggageView,
    closeBaggageEditor,
    closeTruckCompany,
    closeNegotiationRequestCompany,
    selectTruckCompany,
    selectTruckRecommendationOption,
    selectNegotiationCompany,
    changeNegotiationPage,
    clickRecommendedTruckCompanyContactInfo,
    clickNegotiationRequestCompanyContactInfo,
    onDirty,
    onBaggageUpdated,
} = useBaggageListOpenedHelper(props);

</script>

<template>
    <div>
        <search-condition-form
            v-model="form"
            :validation-message="validationMessage"
            :collapsed="!searchFormVisible"
            :is-filtered="isFiltered"
            @search="search"
            @clear="clearForm"
            @toggleVisibility="searchFormVisible = !searchFormVisible"/>

        <baggage-list-banner/>

        <a-card :body-style="{ padding: 0}" id="jsi-search-results" ref="resultListRef">
            <baggage-table
                :loading="loading"
                :list="list"
                :selected-baggage-id="detail?.baggageId"
                :can-edit="true"
                :can-delete="true"
                @selectBaggage="selectBaggage"
                @editBaggage="editBaggage"
                @copyBaggage="copyBaggage"
                @cancelBaggage="cancelBaggage"
                @exportBaggage="exportBaggage"
                @markUnderNegotiation="markUnderNegotiation"
                @unmarkUnderNegotiation="unmarkUnderNegotiation">
                <template #header>
                    <sort-pagination-control :sort-info="sortInfo" :page-info="pageInfo" @change="changePage"/>
                </template>
                <template #footer>
                    <sort-pagination-control :show-sort="false" :sort-info="sortInfo" :page-info="pageInfo"
                                             @change="changePage"/>
                </template>
            </baggage-table>
        </a-card>

        <!-- 荷物詳細 -->
        <baggage-detail-drawer :visible="baggageDrawerVisibility"
                               :tab="selectedBaggageDetailTab"
                               :baggage="baggage"
                               :truck-recommendations="truckRecommendations"
                               :negotiation-list="negotiationList"
                               :circle-company-list="circleCompanyList"
                               :loading="loadingDetail"
                               :loading-truck-recommendations="loadingTruckRecommendations"
                               :loading-negotiation-list="loadingNegotiationList"
                               :can-edit-baggage="true"
                               :can-cancel-baggage="true"
                               @close="closeBaggageView"
                               @changeTab="selectedBaggageDetailTab = $event"
                               @editBaggage="editBaggage"
                               @copyBaggage="copyBaggage"
                               @cancelBaggage="cancelBaggage"
                               @printBaggage="printBaggage"
                               @markUnderNegotiation="markUnderNegotiation"
                               @unmarkUnderNegotiation="unmarkUnderNegotiation"
                               @selectTruckCompany="selectTruckCompany"
                               @selectNegotiationCompany="selectNegotiationCompany"
                               @changeNegotiationPage="changeNegotiationPage">
            <!-- 荷物編集 -->
            <template #editor v-if="props.detail">
                <baggage-editor-drawer ref="editorRef"
                                       :visible="baggageEditorDrawerVisibility"
                                       :baggage-id="props.detail.baggageId"
                                       @dirty="onDirty"
                                       @done="onBaggageUpdated"
                                       @close="closeBaggageEditor"/>
            </template>
        </baggage-detail-drawer>

        <!-- 空車企業詳細 -->
        <company-detail-drawer :visible="props.detail?.truckCompany !== undefined"
                               :companyId="props.detail?.truckCompany?.companyId"
                               @close="closeTruckCompany"
                               @clickContact="clickRecommendedTruckCompanyContactInfo"/>

        <!-- 商談リクエスト企業詳細 -->
        <company-detail-drawer :visible="props.detail?.negotiationCompany !== undefined"
                               :companyId="props.detail?.negotiationCompany?.companyId"
                               @close="closeNegotiationRequestCompany"
                               @clickContact="clickNegotiationRequestCompanyContactInfo"/>

        <truck-recommendation-message v-model="truckRecommendationMessageVisibility"
                                      :truck-count="truckRecommendations.length"
                                      @select="selectTruckRecommendationOption($event)"/>
    </div>
</template>

<style scoped lang="less">
#jsi-search-results {
    // TODO: 全タブのリファクタリングが終わったら、共通のスタイルに移動する
    // 右下のヘルプボタンと被らないようにスペースをおく
    margin-bottom: 40px;
}
</style>
