import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class AccountTruckCountRegisterer extends Vue {
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: 'トラック保有台数を入力してください。',
        },
        {
            pattern: /^[0-9]+$/,
            message: 'トラック保有台数は数字で入力してください。',
        },
        {
            max: Const.MAX_TRUCK_COUNT_DIGITS,
            message: 'トラック保有台数は最大5桁で入力してください。',
        },
    ];

    get truckCount(): string {
        return this.value?.company.truckCount ?? '';
    }

    set truckCount(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        if (newValue.length > 0) {
            const digits = Util.toNumber(newValue);
            cloned.company.truckCount = isNaN(digits) ? '' : `${digits}`;
        } else {
            cloned.company.truckCount = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
