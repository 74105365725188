import { CompanyNoticeModel } from '@/models/company-notice-model';
import { CompanyNotice } from '@/models/company-notice';
import { Agreement } from '@/models/agreement';
import { CompanyProfile } from '@/models/company';

export class CompanyNoticeListModel {
    items: CompanyNoticeModel[];

    constructor(data: CompanyNotice[], agreements: Agreement[], companyProfiles: CompanyProfile[]) {
        this.items = data.map((each) => new CompanyNoticeModel(each, agreements, companyProfiles));
    }

    get isNotEmpty(): boolean {
        return this.items.length > 0;
    }
}
