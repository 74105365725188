import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageRegisterFormModel } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class PaletteSizeRegister extends Vue {
    @Prop()
    declare readonly value?: BaggageRegisterFormModel;

    get paletteHeight(): string {
        return this.value?.paletteHeight ?? '';
    }

    set paletteHeight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (newValue.length > 0) {
            cloned.paletteHeight = isNaN(Util.toNumber(newValue)) ? '' : Util.toDigits(newValue);
        } else {
            cloned.paletteHeight = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get paletteWidth(): string {
        return this.value?.paletteWidth ?? '';
    }

    set paletteWidth(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (newValue.length > 0) {
            cloned.paletteWidth = isNaN(Util.toNumber(newValue)) ? '' : Util.toDigits(newValue);
        } else {
            cloned.paletteWidth = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    private validate(callback: (message?: string) => void): void {
        const height = this.value?.paletteHeight;
        const width = this.value?.paletteWidth;

        if (height) {
            if (height.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                callback(`パレットサイズ(縦)は最大${Const.MAX_PALETTE_SIZE_DIGITS}桁で入力してください。`);
                return;
            }
            if (isNaN(Util.toNumber(height))) {
                callback(`パレットサイズ(縦)は数字で入力してください。`);
                return;
            }
        }
        if (width) {
            if (width.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                callback(`パレットサイズ(横)は最大${Const.MAX_PALETTE_SIZE_DIGITS}桁で入力してください。`);
                return;
            }
            if (isNaN(Util.toNumber(width))) {
                callback(`パレットサイズ(横)は数字で入力してください。`);
                return;
            }
        }
        callback();
    }
}
