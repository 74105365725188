<script setup lang="ts">
import SearchConditionForm from '../components/baggage/SearchConditionForm.vue';
import BaggageListBanner from '../components/BaggageListBanner.vue';
import BaggageTable from '../components/baggage/table/BaggageTable.vue';
import { useBaggageListCanceledHelper } from './baggage-list-canceled-helper';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import BaggageDetailDrawer from '@/_pages/Baggage/List/drawers/BaggageDetail/BaggageDetailDrawer.vue';
import type { DetailProps } from './props';

interface Props {
    detail?: DetailProps;
}

const props = defineProps<Props>();

const {
    searchFormVisible,
    isFiltered,
    loading,
    loadingDetail,
    form,
    validationMessage,
    list,
    sortInfo,
    pageInfo,
    baggageDrawerVisibility,
    baggage,
    resultListRef,
    search,
    changePage,
    clearForm,
    selectBaggage,
    copyBaggage,
    printBaggage,
    closeBaggageView,
} = useBaggageListCanceledHelper(props);

</script>

<template>
    <div>
        <search-condition-form
            v-model="form"
            :validation-message="validationMessage"
            :collapsed="!searchFormVisible"
            :is-filtered="isFiltered"
            @search="search"
            @clear="clearForm"
            @toggleVisibility="searchFormVisible = !searchFormVisible"/>

        <baggage-list-banner/>

        <a-card :body-style="{ padding: 0}" id="jsi-search-results" ref="resultListRef">
            <baggage-table
                :loading="loading"
                :list="list"
                :selected-baggage-id="detail?.baggageId"
                :can-edit="false"
                :can-delete="false"
                @selectBaggage="selectBaggage"
                @copyBaggage="copyBaggage">
                <template #header>
                    <sort-pagination-control :sort-info="sortInfo" :page-info="pageInfo" @change="changePage"/>
                </template>
                <template #footer>
                    <sort-pagination-control :show-sort="false" :sort-info="sortInfo" :page-info="pageInfo"
                                             @change="changePage"/>
                </template>
            </baggage-table>
        </a-card>

        <!-- 荷物詳細 -->
        <baggage-detail-drawer :visible="baggageDrawerVisibility"
                               tab="baggage"
                               :baggage="baggage"
                               :loading="loadingDetail"
                               :can-edit-baggage="false"
                               :can-cancel-baggage="false"
                               @close="closeBaggageView"
                               @copyBaggage="copyBaggage"
                               @printBaggage="printBaggage">
        </baggage-detail-drawer>
    </div>
</template>

<style scoped lang="less">
#jsi-search-results {
    // TODO: 全タブのリファクタリングが終わったら、共通のスタイルに移動する
    // 右下のヘルプボタンと被らないようにスペースをおく
    margin-bottom: 40px;
}
</style>
