import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import type dayjs from 'dayjs';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AgreementForm } from '@/vuex/modules/agreement/types';
import { Validator } from '@/validator';
import { DateUtil } from '@/util';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { DateTimePickerValue } from '@/components/UI/DateTimePicker/types';
// @ts-ignore
import UiDateTimePicker from '@/components/UI/DateTimePicker';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';
import * as baggageTypes from '@/vuex/modules/baggage/types';

@Component({
    components: {
        UiDateTimePicker,
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class AgreementSpotRegister extends Vue {
    @Prop()
    declare readonly value?: AgreementForm;
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;

    cols = {
        first: {
            labelCol: { xs: 3, md: 9 },
            wrapperCol: { xs: 21, md: 15 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                xs: { offset: 0, span: 24 },
                sm: { offset: 3, span: 21 },
                md: { offset: 0, span: 24 },
            },
        },
    };

    // validation rules
    departureDateTimeValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateDepartureDateTime(callback as (message?: string) => void),
        },
    ];

    departureAddressValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateDepartureAddress(callback as (message?: string) => void),
        },
    ];

    arrivalDateTimeValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateArrivalDateTime(callback as (message?: string) => void),
        },
    ];

    arrivalAddressValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateArrivalAddress(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 出発日時
     */
    get departureDateTime(): DateTimePickerValue {
        // TODO: 将来的にはDeliveryDateTime型で扱いたい
        return [this.value?.departureMin, this.value?.departureMax];
    }

    set departureDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 出発日時を書き換え
        this.emitNewValue({
            departureMin: dateMin ?? '',
            departureMax: dateMax ?? '',
        });

        this.changeDateTimeAll();
    }

    /**
     * 到着日時
     */
    get arrivalDateTime(): DateTimePickerValue {
        return [this.value?.arrivalMin, this.value?.arrivalMax];
    }

    set arrivalDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 到着日時を書き換え
        this.emitNewValue({
            arrivalMin: dateMin ?? '',
            arrivalMax: dateMax ?? '',
        });

        this.changeDateTimeAll();
    }

    /**
     * 出発地（都道府県）
     */
    get departurePrefCode(): string {
        return this.value?.departurePref.code ?? '';
    }

    set departurePrefCode(newValue: string) {
        const changed = this.value?.departurePref.code !== newValue;

        // 出発地を書き換え
        this.emitNewValue({
            // 都道府県
            departurePref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            departureCity: changed ? '' : this.value?.departureCity,
            // 番地・建物（都道府県変更された場合はクリア）
            departureAddress: changed ? '' : this.value?.departureAddress,
        });

        this.changeDepartureAddress();
    }

    /**
     * 出発地（市区町村）
     */
    get departureCity(): string {
        return this.value?.departureCity ?? '';
    }

    set departureCity(newValue: string) {
        // 出発地（市区町村）を書き換え
        this.emitNewValue({ departureCity: newValue });

        this.changeDepartureAddress();
    }

    /**
     * 出発地（番地・建物）
     */
    get departureAddress(): string {
        return this.value?.departureAddress ?? '';
    }

    set departureAddress(newValue: string) {
        // 出発地（番地・建物）を書き換え
        this.emitNewValue({ departureAddress: newValue });

        this.changeDepartureAddress();
    }

    /**
     * 到着地（都道府県）
     */
    get arrivalPrefCode(): string {
        return this.value?.arrivalPref.code ?? '';
    }

    set arrivalPrefCode(newValue: string) {
        const changed = this.value?.arrivalPref.code !== newValue;

        // 到着地を書き換え
        this.emitNewValue({
            // 都道府県
            arrivalPref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            arrivalCity: changed ? '' : this.value?.arrivalCity,
            // 番地・建物（都道府県変更された場合はクリア）
            arrivalAddress: changed ? '' : this.value?.arrivalAddress,
        });

        this.changeArrivalAddress();
    }

    /**
     * 到着地（市区町村）
     */
    get arrivalCity(): string {
        return this.value?.arrivalCity ?? '';
    }

    set arrivalCity(newValue: string) {
        // 到着地（市区町村）を書き換え
        this.emitNewValue({ arrivalCity: newValue });

        this.changeArrivalAddress();
    }

    /**
     * 到着地（番地・建物）
     */
    get arrivalAddress(): string {
        return this.value?.arrivalAddress ?? '';
    }

    set arrivalAddress(newValue: string) {
        // 到着地（番地・建物）を書き換え
        this.emitNewValue({ arrivalAddress: newValue });

        this.changeArrivalAddress();
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 成約登録については、今日の1時間後から1年後の年末まで有効
        return [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    }
    // 荷物から取得可能なReadOnlyプロパティ
    /**
     * 積み時間
     */
    get loadingTimeNote(): string {
        return this.baggage?.loadingTimeNote ?? '';
    }

    get hasLoadingTimeNote(): boolean {
        return !_.isEmpty(this.loadingTimeNote);
    }

    /**
     * 卸し時間
     */
    get unloadingTimeNote(): string {
        return this.baggage?.unloadingTimeNote ?? '';
    }

    get hasUnloadingTimeNote(): boolean {
        return !_.isEmpty(this.unloadingTimeNote);
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 出発地か到着地が変更された際に呼び出される。
     */
    private changeDateTimeAll(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalDateTime.onFieldChange();
        });
    }

    /**
     * 出発地が変更された際に呼び出される。
     */
    private changeDepartureAddress(): void {
        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemDepartureAddress.onFieldChange());
    }

    /**
     * 到着地が変更された際に呼び出される。
     */
    private changeArrivalAddress(): void {
        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemArrivalAddress.onFieldChange());
    }

    /**
     * 出発日時のバリデーションを行います。
     */
    private validateDepartureDateTime(callback: (message?: string) => void): void {
        const value = DeliveryDateTime.of(this.value?.departureMin ?? '', this.value?.departureMax ?? '');
        const validated = Validator.validateDateTime(value, null);
        if (!validated.result) {
            callback(validated.message);
            return;
        }
        callback();
    }

    /**
     * 出発場所のバリデーションを行います。
     */
    private validateDepartureAddress(callback: (message?: string) => void): void {
        if (!this.departurePrefCode) {
            callback('都道府県を選択してください。');
        } else if (!this.departureCity.trim()) {
            callback('市区町村を入力してください。');
        } else if (this.departureCity.length > 200) {
            callback('市区町村名は200文字以内で入力してください。');
        } else if (this.departureAddress.length > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    }

    /**
     * 到着日時のバリデーションを行います。
     */
    private validateArrivalDateTime(callback: (message?: string) => void): void {
        const value = DeliveryDateTime.of(this.value?.arrivalMin ?? '', this.value?.arrivalMax ?? '');
        const validated = Validator.validateDateTime(value, null);
        if (!validated.result) {
            callback(validated.message);
            return;
        }
        const departure = DeliveryDateTime.of(this.value?.departureMin ?? '', this.value?.departureMax ?? '');
        const rangeValidated = Validator.validateDateTimeRange(departure, value);
        if (!rangeValidated.result) {
            callback(rangeValidated.message);
            return;
        }
        callback();
    }

    /**
     * 到着場所のバリデーションを行います。
     */
    private validateArrivalAddress(callback: (message?: string) => void): void {
        if (!this.arrivalPrefCode) {
            callback('都道府県を選択してください。');
        } else if (!this.arrivalCity.trim()) {
            callback('市区町村を入力してください。');
        } else if (this.arrivalCity.length > 200) {
            callback('市区町村名は200文字以内で入力してください。');
        } else if (this.arrivalAddress.length > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    }

    /**
     * 新しい値を親にemitします。
     */
    private emitNewValue(newValues: Partial<AgreementForm>): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        this.$emit('input', _.merge(cloned, newValues));
    }
}
