export const columns = [
    {
        title: '地点名',
        key: 'placeName',
        scopedSlots: { customRender: 'placeName' },
        width: 100,
    },
    {
        title: '地点',
        key: 'location',
        scopedSlots: { customRender: 'location' },
        width: 180,
    }
];
