<script setup lang="ts">
import { columns } from './table-definition';
import { SettlementChangeRequestListModel, SettlementModel } from '@/models/settlement';
import { computed } from 'vue';
import { Util } from '@/util';
import { PageInfo } from '@/_components/ui/types/page-info';
import PaginationControl from '@/_components/ui/PaginationControl.vue';
import { CustomRow } from '@/types/ant-design';

const props = defineProps<{
    list?: SettlementChangeRequestListModel,
    loading: boolean,
}>();
const emits = defineEmits<{
    (e: 'change', value: { pageNo: number, pageSize: number }): void;
    (e: 'selectSettlement', id: number): void;
}>();
const customRow = ({ settlement }: { settlement: SettlementModel }): CustomRow => {
    return {
        on: {
            click: (event) => {
                event.stopPropagation();
                emits('selectSettlement', settlement.id);
            },
        },
    };
};
const totalCount = computed(() => Util.formatNumber(props.list?.totalRecordCount ?? 0));
const pageInfo = computed<PageInfo>(() => ({
    totalPageCount: props.list?.totalPageCount ?? 0,
    totalRecordCount: props.list?.totalRecordCount ?? 0,
    currentPage: props.list?.currentPageNumber ?? 1,
    currentPageSize: props.list?.pageSize ?? 50,
    pageSizeOptions: ['50'],
}));
const changePage = (value: { pageNo: number, pageSize: number }) => {
    emits('change', value);
};
</script>

<template>
    <div>
        <div class="summary">
            <a-space>
                <span class="component-heading-h3">あなたの申請</span>
                <span class="component-heading-h2">{{ totalCount }}件</span>
            </a-space>
        </div>
        <a-table :columns="columns"
                 :data-source="list?.data"
                 :row-key="record => record.settlement.id"
                 :custom-row="customRow"
                 :pagination="false"
                 :loading="loading">
            <template v-slot:changeLimitTm="text, record">
                <span>{{ record.settlement.changeLimitTm.toDate().format('M/D(ddd)') }}</span>
            </template>
            <template v-slot:payeeDate="text, record">
                <span>{{ record.settlement.payeeDate.format('M/D(ddd)') }}</span>
            </template>
            <template v-slot:label="text, record">
                <div>{{ record.settlement.departureLabel }}→</div>
                <div>{{ record.settlement.arrivalLabel }}</div>
            </template>
            <template v-slot:requests="text, record">
                <template v-if="record.settlement.status.isInitial">
                    <div>新規取引</div>
                    <div v-if="record.settlement.cancellationFee">
                        キャンセル料：
                        <span class="change-request">{{ record.settlement.cancellationFee?.format() }}</span>
                    </div>
                    <template v-else>
                        <div>
                            運賃：
                            <span class="change-request">{{ record.settlement.freight.format() }}</span>
                        </div>
                        <div>
                            高速代：
                            <span class="change-request">{{ record.settlement.highwayFare.format() }}</span>
                        </div>
                        <div v-if="record.settlement.waitTimeFee">
                            待機料：
                            <span class="change-request">{{ record.settlement.waitTimeFee?.format() }}</span>
                        </div>
                        <div v-if="record.settlement.operationFee">
                            付帯作業料：
                            <span class="change-request">{{ record.settlement.operationFee?.format() }}</span>
                        </div>
                        <div v-if="record.settlement.pickingFee">
                            搬出料：
                            <span class="change-request">{{ record.settlement.pickingFee?.format() }}</span>
                        </div>
                        <div v-if="record.settlement.parkingFee">
                            駐車代：
                            <span class="change-request">{{ record.settlement.parkingFee?.format() }}</span>
                        </div>
                        <div v-if="record.settlement.clearanceFee">
                            通関料：
                            <span class="change-request">{{ record.settlement.clearanceFee?.format() }}</span>
                        </div>
                    </template>
                </template>
                <template v-else-if="record.diff">
                    <div v-if="record.diff.proxyDiff.hasDiff">
                        おまかせ請求：
                        {{ record.diff.proxyDiff.source ? '対象' : '対象外' }}
                        →
                        <span class="change-request">{{ record.diff.proxyDiff.request ? '対象' : '対象外' }}</span>
                    </div>
                    <div v-if="record.diff.freightDiff.hasDiff">
                        運賃：
                        {{ record.diff.freightDiff.source.format() }}
                        →
                        <span class="change-request">{{ record.diff.freightDiff.request.format() }}</span>
                    </div>
                    <div v-if="record.diff.highwayFareDiff.hasDiff">
                        高速代：
                        {{ record.diff.highwayFareDiff.source.format() }}
                        →
                        <span class="change-request">{{ record.diff.highwayFareDiff.request.format() }}</span>
                    </div>
                    <div v-if="record.diff.waitTimeFeeDiff.hasDiff">
                        待機料：
                        {{ record.diff.waitTimeFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.waitTimeFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                    <div v-if="record.diff.operationFeeDiff.hasDiff">
                        付帯作業料：
                        {{ record.diff.operationFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.operationFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                    <div v-if="record.diff.pickingFeeDiff.hasDiff">
                        搬出料：
                        {{ record.diff.pickingFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.pickingFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                    <div v-if="record.diff.parkingFeeDiff.hasDiff">
                        駐車代：
                        {{ record.diff.parkingFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.parkingFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                    <div v-if="record.diff.clearanceFeeDiff.hasDiff">
                        通関料：
                        {{ record.diff.clearanceFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.clearanceFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                    <div v-if="record.diff.cancellationFeeDiff.hasDiff">
                        キャンセル料：
                        {{ record.diff.cancellationFeeDiff.source?.format() ?? '0円' }}
                        →
                        <span class="change-request">{{
                                record.diff.cancellationFeeDiff.request?.format() ?? '0円'
                            }}</span>
                    </div>
                </template>
            </template>
            <template v-slot:companyName="text, record">
                <span>{{ record.partner.name.kanji }}</span>
            </template>
        </a-table>
        <pagination-control class="pagination-control" :page-info="pageInfo" @change="changePage"/>
    </div>
</template>

<style scoped lang="less">
.summary {
    vertical-align: text-bottom;
    margin-bottom: @padding-md;
}

.change-request {
    background-color: @token-accent-color-warning;
}

.pagination-control {
    padding: @padding-sm;
}
</style>
