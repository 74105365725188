import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Const } from '@/const';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

@Component
export default class AgreementArrival extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get arrival(): string {
        if (!this.agreement) return '';
        return (
            DeliveryDateTimeRange.of(this.agreement.arrivalMin, this.agreement.arrivalMax)?.format(
                Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT
            ) ?? ''
        );
    }

    get arrivalCr(): string {
        if (!this.agreementCr) return '';
        return (
            DeliveryDateTimeRange.of(this.agreementCr.arrivalMin, this.agreementCr.arrivalMax)?.format(
                Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT
            ) ?? ''
        );
    }

    get changeRequest(): string {
        return this.arrival != this.arrivalCr ? this.arrivalCr : '';
    }
}
