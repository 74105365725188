//
// Action
//
export class ACTION {
    static readonly SET_FORM_DIRTY = 'SET_FORM_DIRTY';
}
//
// Signature for Action
//
export type setFormDirty = (isDirty: boolean) => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly FORM_DIRTY = 'FORM_DIRTY';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_FORM_DIRTY = 'SET_FORM_DIRTY';
}

//
// Store
//
export interface FormDirtyState {
    formDirty: boolean;
}
