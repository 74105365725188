import { computed, onBeforeMount } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { useSettlementIncomeList, useSettlementOutgoList } from '@/composables/settlement-list';
import { SettlementStatusEnum } from '@/enums/settlement-status.enum';
import {
    useIncomeSettlementChangeRequestList,
    useOutgoSettlementChangeRequestList,
    useSettlementChangeRequestApprove,
    useSettlementChangeRequestReject
} from '@/composables/settlement-change-request';
import {
    SettlementChangeDiffModel,
    SettlementChangeRequestListModel,
    SettlementChangeRequestModel,
    SettlementModel,
    SettlementPartner
} from '@/models/settlement';
import _ from 'lodash';
import { useMessage } from '@/composables/helper/page-helper';

export const useSettlementHomeHelper = () => {
    const { goToSettlementIncomeDetail, goToSettlementOutgoDetail } = useRouting();
    const message = useMessage();

    const {
        state: { form: incomeSearchForm, list: incomeList, loading: incomeLoading },
        search: searchIncome,
        changePage: changeIncomePage
    } = useSettlementIncomeList();

    const {
        state: { form: outgoSearchForm, list: outgoList, loading: outgoLoading },
        search: searchOutgo,
        changePage: changeOutgoPage
    } = useSettlementOutgoList();

    const {
        state: { loading: loadingIncomeRequest, requests: incomeRequests },
        load: loadIncomeRequest
    } = useIncomeSettlementChangeRequestList();
    const {
        state: { loading: loadingOutgoRequest, requests: outgoRequests },
        load: loadOutgoRequest
    } = useOutgoSettlementChangeRequestList();
    const { state: { loading: loadingApprove }, approve } = useSettlementChangeRequestApprove();
    const { state: { loading: loadingReject }, confirm, reject } = useSettlementChangeRequestReject();

    const loadingOutgoRequestList = computed(() => outgoLoading.value || loadingOutgoRequest.value || loadingApprove.value || loadingReject.value);
    const loadingIncomeRequestList = computed(() => incomeLoading.value || loadingIncomeRequest.value);

    const findOutgoRequest = (settlementId: number) => outgoRequests.value.find(each => each.settlementId === settlementId);
    const findIncomeRequest = (settlementId: number) => incomeRequests.value.find(each => each.settlementId === settlementId);

    const merge = (
        base: { settlement: SettlementModel, partner: SettlementPartner },
        request: SettlementChangeRequestModel | undefined
    ) => {
        return {
            settlement: base.settlement,
            partner: base.partner,
            diff: !_.isNil(request) ? new SettlementChangeDiffModel(base.settlement, request) : undefined
        };
    };

    const outgoUnconfirmedList = computed<SettlementChangeRequestListModel | undefined>(() => {
        if (!outgoList.value) return undefined;
        return {
            pageSize: outgoList.value?.pageSize ?? 50,
            totalPageCount: outgoList.value?.totalPageCount ?? 0,
            totalRecordCount: outgoList.value?.totalRecordCount ?? 0,
            currentPageNumber: outgoList.value?.currentPageNumber ?? 1,
            data: outgoList.value?.data.map(each => merge(each, findOutgoRequest(each.settlement.id))),
        };
    });

    const incomeUnconfirmedList = computed<SettlementChangeRequestListModel | undefined>(() => {
        if (!incomeList.value) return undefined;
        return {
            pageSize: incomeList.value?.pageSize ?? 50,
            totalPageCount: incomeList.value?.totalPageCount ?? 0,
            totalRecordCount: incomeList.value?.totalRecordCount ?? 0,
            currentPageNumber: incomeList.value?.currentPageNumber ?? 1,
            data: incomeList.value?.data.map(each => merge(each, findIncomeRequest(each.settlement.id))),
        };
    });

    const onClickApprove = async (settlementId: number) => {
        await approve(settlementId);
        await loadOutgoRequestList();

        message.success('申請を承認しました。');
    };

    const onClickReject = async (settlementId: number) => {
        if (!await confirm()) return;
        await reject(settlementId);
        await loadOutgoRequestList();

        message.success('申請を却下しました。');
    };

    onBeforeMount(async () => {
        await Promise.all([
            loadOutgoRequestList(),
            loadIncomeRequestList(),
        ]);
    });

    const loadOutgoRequestList = async () => {
        outgoSearchForm.value.status = SettlementStatusEnum.unconfirmedValues;
        await searchOutgo();
        await loadOutgoRequest(outgoList.value?.data.map(each => each.settlement.id) ?? []);
    };
    const changePageOutgoRequestList = async (value: { pageNo: number }) => {
        outgoSearchForm.value.status = SettlementStatusEnum.unconfirmedValues;
        await changeOutgoPage(value);
        await loadOutgoRequest(outgoList.value?.data.map(each => each.settlement.id) ?? []);
    };
    const selectSettlement = async (id: number, type: 'income' | 'outgo') => {
        switch (type) {
            case 'income': await goToSettlementIncomeDetail(id); break;
            case 'outgo':  await goToSettlementOutgoDetail(id);  break;
            default: throw new Error();
        }
    };
    const loadIncomeRequestList = async () => {
        incomeSearchForm.value.status = SettlementStatusEnum.unconfirmedValues;
        await searchIncome();
        await loadIncomeRequest(incomeList.value?.data.map(each => each.settlement.id) ?? []);
    };
    const changePageIncomeRequestList = async (value: { pageNo: number }) => {
        incomeSearchForm.value.status = SettlementStatusEnum.unconfirmedValues;
        await changeIncomePage(value);
        await loadIncomeRequest(incomeList.value?.data.map(each => each.settlement.id) ?? []);
    };

    return {
        loadingOutgoRequestList,
        outgoUnconfirmedList,
        changePageOutgoRequestList,
        selectSettlement,
        onClickApprove,
        onClickReject,
        loadingIncomeRequestList,
        incomeUnconfirmedList,
        changePageIncomeRequestList,
    };
};
