<script setup lang="ts">
import DrawerContentLayout from '../../common/DrawerContentLayout.vue';
import { Baggage } from '@/models/baggage';
import TransportSummary from '@/_components/parts/TransportSummary.vue';
import UnderNegotiationBanner from '@/_components/parts/UnderNegotiationBanner.vue';
import { useMyBaggageExtension } from '@/composables/my-baggage-extension';
import StaffView from '@/_components/parts/baggage/Staff.vue';
import BaggageTypeView from '@/_components/parts/baggage/BaggageType.vue';
import TruckView from '@/_components/parts/baggage/Truck.vue';
import TruckDetailView from '@/_components/parts/baggage/TruckDetail.vue';
import TruckEquipmentView from '@/_components/parts/baggage/TruckEquipment.vue';
import DescriptionView from '@/_components/parts/baggage/Descriptions.vue';
import DeliveryDateTimeView from '@/_components/parts/baggage/DeliveryDateTime.vue';
import LoadingTimeNoteView from '@/_components/parts/baggage/LoadingTimeNote.vue';
import UnloadingTimeNoteView from '@/_components/parts/baggage/UnloadingTimeNote.vue';
import EntryDateTimeView from '@/_components/parts/baggage/EntryDateTime.vue';
import PaymentDateView from '@/_components/parts/baggage/PaymentDate.vue';
import TraboxBaggageIdView from '@/_components/parts/baggage/TraboxBaggageId.vue';
import BaggageTemperatureZoneView from '@/_components/parts/baggage/BaggageTemperatureZone.vue';
import ShipperNameView from '@/_components/parts/baggage/ShipperName.vue';

defineProps<{
    loading: boolean;
    baggage?: Baggage;
    canEditBaggage: boolean;
    canCancelBaggage: boolean;
}>();

const emit = defineEmits<{
    (e: 'editBaggage', id: number): void;
    (e: 'copyBaggage', id: number): void;
    (e: 'cancelBaggage', id: number): void;
    (e: 'printBaggage', id: number): void;
    (e: 'markUnderNegotiation', id: number): void;
    (e: 'unmarkUnderNegotiation', id: number): void;
}>();

const {
    isUnderNegotiation,
    negotiationType,
    shouldShowTraboxTag,
} = useMyBaggageExtension();

</script>

<template>
    <drawer-content-layout :loading="loading">
        <div v-if="baggage" class="container">
            <!-- アクション -->
            <a-row type="flex" justify="end">
                <a-col>
                    <a-space direction="horizontal" class="drawer-header-actions">
                        <a-button v-if="canEditBaggage" @click="emit('editBaggage', baggage.id)">変更</a-button>
                        <a-button @click="emit('copyBaggage', baggage.id)">コピー</a-button>
                        <a-button v-if="canCancelBaggage" type="danger" ghost
                                  @click="emit('cancelBaggage', baggage.id)">削除
                        </a-button>
                        <a-button @click="emit('printBaggage', baggage.id)">印刷</a-button>
                    </a-space>
                </a-col>
            </a-row>

            <div class="baggage-id">
                <span>荷物番号</span>
                <a-space>
                    {{ baggage.id }}
                    <a-tag v-if="shouldShowTraboxTag(baggage)" class="ant-tag-has-color trabox-color-tag">トラボックス掲載中</a-tag>
                </a-space>
            </div>

            <div class="circle-name">
              <span>部屋</span>
              {{ baggage.circle.name }}
            </div>

            <under-negotiation-banner v-if="isUnderNegotiation(baggage)" class="under-negotiation"/>

            <transport-summary :baggage="baggage" hide-reference-freight class="transport-summary"/>

            <a-descriptions bordered :column="1" size="small">
                <a-descriptions-item label="荷主名">
                    <shipper-name-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="担当者">
                    <staff-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="連絡方法">
                    <span>{{ negotiationType(baggage).label }}</span>
                </a-descriptions-item>
                <a-descriptions-item label="荷種">
                    <baggage-type-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="温度帯">
                    <baggage-temperature-zone-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="希望車種">
                    <truck-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="車両指定">
                    <truck-detail-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="必要装備">
                    <truck-equipment-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="備考">
                    <description-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="発着日時">
                    <delivery-date-time-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="積み時間">
                    <loading-time-note-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="卸し時間">
                    <unloading-time-note-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="入金予定日">
                    <payment-date-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="登録日時">
                    <entry-date-time-view :baggage="baggage"/>
                </a-descriptions-item>
                <a-descriptions-item label="トラボックス荷物ID">
                    <trabox-baggage-id-view :baggage="baggage"/>
                </a-descriptions-item>
            </a-descriptions>
        </div>
    </drawer-content-layout>
</template>

<style scoped lang="less">
.container {
    > * + * {
        margin-top: 16px;
    }
}

.drawer-header-actions {
    position: relative;
    margin-top: -8px;

    button {
        margin-left: 4px;
    }
}

.baggage-id {
    color: @heading-color;

    > span {
        margin-right: 1em;
    }
}

.circle-name {
  color: @heading-color;

  > span {
    margin-right: 1em;
  }
}

.negotiation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    button {
        width: 260px;
    }
}

.trabox-color-tag {
    background-color: @color-trabox-green-8;
}
</style>
