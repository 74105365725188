import { ref } from 'vue';
import { MaskedBaggageList, RecentBaggageListForm } from '@/models/baggage';
import { baggageApi } from '@/repository/api/internal/baggage';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 最近見た荷物一覧データをロードする機能を提供します。
 */
export const useBaggageRecentList = () => {
    const defaultData = () => {
        return {
            pageSize: 0,
            totalRecordCount: 0,
            totalPageCount: 0,
            currentPageNumber: 1,
            data: [],
        };
    };

    const baggageList = ref<MaskedBaggageList>(defaultData());
    const { state: { loading }, withLoading } = useLoading();

    const load = async (pageNo: number, pageSize: number): Promise<void> => {
        await withLoading(async () => {
            const form: RecentBaggageListForm = {
                pageNo: pageNo,
                pageSize: pageSize,
            };
            const data = await baggageApi.recentList(form);
            baggageList.value = data;
        });
    };

    const clear = () => {
        baggageList.value = defaultData();
    };

    const changeNegotiation = (baggageId: number, value: boolean): void => {
        baggageList.value.data.forEach(each => {
            if (each.id === baggageId) each.underNegotiation = value;
        });
    };

    return {
        state: {
            loading,
            baggageList,
        },
        load,
        clear,
        changeNegotiation,
    };
};
