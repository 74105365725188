import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { PageVue } from '@/mixin/PageVue';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import Name from '@/components/Account/Edit/Name';
// @ts-ignore
import Email from '@/components/Account/Edit/Email';
// @ts-ignore
import Position from '@/components/Account/Edit/Position';
import { Const } from '@/const';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { FormModel } from 'ant-design-vue';
import { AccountNewMemberResult } from '@/vuex/modules/account/types';

type PageActionType = 'CREATE' | 'EDIT';
const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    components: { Name, Email, Position },
    beforeRouteEnter: SettingAccountCreateOrEditPage.beforeRouteEnter,
})
export default class SettingAccountCreateOrEditPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Properties
    // ======================================================

    // ======================================================
    // Data
    // ======================================================
    actionType?: PageActionType;
    pageTitle = '';
    loading = false;
    form: accountTypes.AccountProfileCreateOrUpdateForm = {
        name: '',
        email: '',
        position: { code: '' },
    };

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.actionType = this.$route.meta?.actionType;
        this.pageTitle = this.getPageTitle(this.$route.meta?.actionType);
    }

    mounted(): void {
        if (this.actionType === 'EDIT' && this.ACCOUNT_PROFILE) {
            this.form.name = this.ACCOUNT_PROFILE.name;
            this.form.email = this.ACCOUNT_PROFILE.email;
            this.form.position = this.ACCOUNT_PROFILE.position;
        }
    }

    /**
     * ページタイトル名を取得する
     */
    getPageTitle(actionType?: PageActionType): string {
        if (actionType === 'CREATE') {
            return 'ユーザー追加';
        } else if (actionType === 'EDIT') {
            return 'ユーザー編集';
        }
        throw new Error(`${actionType} is not defined actionType.`);
    }

    /**
     * submit ボタンのラベルを取得する
     */
    get submitButtonLabel(): string {
        if (this.actionType === 'CREATE') return '追加';
        return '保存';
    }

    /**
     * 戻るボタンが押下された際に呼び出されます。
     */
    async onClickBack(): Promise<void> {
        await this.$router.push({ name: 'SettingAccountList' });
    }

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    async onSubmit(): Promise<void> {
        const notifyKey = 'USER_EDIT_FAILED';
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;
            this.loading = true;
            this.$notification.close(notifyKey);

            if (this.actionType === 'CREATE') {
                const onSuccess = async () => await this.$router.push({ name: 'SettingAccountCreateComplete' });

                const onError = (err: { data: string }) => {
                    if (err.data === 'UNSTABLE') {
                        this.notifyUnstableCreationError(notifyKey);
                    } else {
                        this.notifyCreationError(notifyKey);
                    }
                };
                const formData = {
                    name: this.form.name,
                    emailAddress: this.form.email,
                    position: this.form.position,
                } as accountTypes.AccountProfileCreateForm;

                const createAccount = async () => {
                    return SettingAccountCreateOrEditPage.createAccount(formData);
                };

                await createAccount().then(onSuccess).catch(onError);
            } else if (this.actionType === 'EDIT') {
                const emailUpdated = this.ACCOUNT_PROFILE?.email !== this.form.email;
                const onSuccess = async () => {
                    if (emailUpdated) {
                        this.notifyEmailWarn(notifyKey);
                    } else {
                        this.$message.success('ユーザー情報を更新しました。');
                    }

                    await this.$router.push({ name: 'SettingAccountList' });
                };
                const onError = () => this.notifyEditError(notifyKey);

                const updateAccount = async () => {
                    return SettingAccountCreateOrEditPage.updateAccount(this.form);
                };

                await updateAccount().then(onSuccess).catch(onError);
            }
            this.loading = false;
        });
    }

    /**
     * ユーザー追加エラー（企業グループ不定）を通知します。
     */
    private notifyUnstableCreationError(key: string) {
        const inquiryLabel = 'お問い合わせフォーム';
        const inquiryAttr = {
            href: Const.externalPageUrl.inquiryForm,
            target: '_blank'
        };

        this.$notification.error({
            key: key,
            message: 'ユーザーを追加できませんでした。',
            // @ts-ignore
            description: (h) => h('div', [
                h('div', ['トラボックスへお問い合わせください。']),
                h('a', { attrs: inquiryAttr }, [inquiryLabel])
            ])
        });
    }

    /**
     * ユーザー追加エラーを通知します。
     */
    private notifyCreationError(key: string) {
        this.$notification.error({
            key: key,
            message: 'ユーザーを追加できませんでした。',
            description:
                '入力内容をご確認のうえ、再度お試しください。状況が改善しない場合はお問い合わせください。',
        });
    }

    /**
     * メールアドレス更新警告を通知します。
     */
    private notifyEmailWarn(key: string) {
        this.$notification.info({
            key: key,
            message: 'まだメールアドレスの変更は完了してません。',
            description:
                '確認メールを送信しました。確認メールをご覧いただき、変更手続きを完了させてください。',
        });
    }

    /**
     * ユーザー編集エラーを通知します。
     */
    private notifyEditError(key: string) {
        this.$notification.error({
            key: key,
            message: 'ユーザー情報を変更できませんでした。',
            description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        });
    }

    /**
     * ルーティングを許可するかチェックする
     */
    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingAccountCreateOrEditPage>
    ): Promise<void> {
        await SettingAccountCreateOrEditPage.loadContractList();
        switch (_to.meta?.actionType as PageActionType) {
            case 'CREATE':
            case 'EDIT':
                next();
                break;
            default:
                throw new Error(`actionType is not defined. actionType => ${_to.meta?.actionType}`);
        }
    }

    /**
     * 企業契約情報をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }

    /**
     * アカウント（プロフィール）を追加します。
     */
    private static createAccount(param: accountTypes.AccountProfileCreateForm): Promise<AccountNewMemberResult> {
        return store.dispatch(`account/${ accountTypes.ACTION.CREATE_PROFILE }`, param);
    }

    /**
     * アカウント（プロフィール）を更新します。
     */
    private static updateAccount(param: accountTypes.AccountProfileCreateOrUpdateForm): Promise<void> {
        return store.dispatch(`account/${accountTypes.ACTION.UPDATE_PROFILE}`, param);
    }
}
