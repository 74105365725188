import _ from 'lodash';
import { FormValidationRule, ValidationContext } from '@/models/validate-helper';
import { DateValue } from '@/models/vo/date';
import { TruckType } from '@/_components/ui/types/truck-type';
import { CompanyProfile } from '@/models/company';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';

export const useSettlementIncomeValidator = () => {
    type Rules<T> = FormValidationRule<T>[];

    const payerCompany = (context: ValidationContext<unknown>): Rules<CompanyProfile | undefined> => ([
        {
            required: true,
            message: '荷主を選択してください。',
        },
    ]);

    const departureDate = (context: ValidationContext<{
        departureDate?: DateValue,
    }>): Rules<DateValue | undefined> => ([
        {
            required: true,
            message: '発日を選択してください。',
        },
        {
            validator: (_rule, _value, callback) => {
                const { departureDate } = context();

                if (departureDate !== undefined) {
                    if (!departureDate.isBefore(DateValue.today())) {
                        callback('発日は本日より前の日付を選択してください。');
                        return;
                    }
                }
                callback();
            }
        }
    ]);

    const arrivalDate = (context: ValidationContext<{
        departureDate?: DateValue,
        arrivalDate?: DateValue,
    }>): Rules<DateValue | undefined> => ([
        {
            required: true,
            message: '着日を選択してください。',
        },
        {
            validator: (_rule, _value, callback) => {
                const { departureDate, arrivalDate } = context();

                if (arrivalDate !== undefined) {
                    if (!arrivalDate.isBefore(DateValue.today())) {
                        callback('着日は本日より前の日付を選択してください。');
                        return;
                    }
                }

                // 発日と着日のチェック
                if (arrivalDate !== undefined && departureDate !== undefined) {
                    if (arrivalDate.isBefore(departureDate)) {
                        callback('着日は、発日と同じか後の日を選択してください。');
                        return;
                    }
                }

                callback();
            }
        }
    ]);

    const validateDeliveryLocation = (location: {
        pref: PrefectureEnumCode | undefined,
        city: string | undefined,
        address: string | undefined,
    }, callback: (message?: string) => void) => {
        if (!location.pref) {
            callback('都道府県を選択してください。');
        } else if (!location.city?.trim()) {
            callback('市区町村を入力してください。');
        } else if (location.city?.length > 200) {
            callback('市区町村名は200文字以内で入力してください。');
        } else if ((location.address?.length ?? 0) > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    };

    const departureLocation = (context: ValidationContext<{
        departurePref: PrefectureEnumCode | undefined,
        departureCity: string | undefined,
        departureAddress: string | undefined,
    }>): Rules<undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { departurePref: pref, departureCity: city, departureAddress: address } = context();
                validateDeliveryLocation({ pref, city, address }, callback);
            }
        }
    ]);

    const arrivalLocation = (context: ValidationContext<{
        arrivalPref: PrefectureEnumCode | undefined,
        arrivalCity: string | undefined,
        arrivalAddress: string | undefined,
    }>): Rules<undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { arrivalPref: pref, arrivalCity: city, arrivalAddress: address } = context();
                validateDeliveryLocation({ pref, city, address }, callback);
            }
        }
    ]);

    const truckType = (context: ValidationContext<{
        truckType: TruckType
    }>): Rules<TruckType> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                if (target.truckType.truckWeight === undefined) {
                    callback('車重を選択してください。');
                } else if (target.truckType.truckModel === undefined) {
                    callback('車種を選択してください。');
                } else {
                    callback();
                }
            }
        }
    ]);

    const freight = (context: ValidationContext<{
        isCanceling: boolean,
        freight: number,
        highwayFare: number,
        waitTimeFee: number,
        operationFee: number,
        pickingFee: number,
        parkingFee: number,
        clearanceFee: number,
    }>): Rules<number> => ([
        {
            validator: (_rule, _value, callback) => {
                const {
                    isCanceling,
                    freight,
                    highwayFare,
                    waitTimeFee,
                    operationFee,
                    pickingFee,
                    parkingFee,
                    clearanceFee,
                } = context();

                // キャンセル料入力モードならチェック不要
                if (isCanceling) return callback();

                if (_.sum([
                    freight,
                    highwayFare,
                    waitTimeFee,
                    operationFee,
                    pickingFee,
                    parkingFee,
                    clearanceFee,
                ]) > 0) return callback();

                callback('いずれかの金額を入力してください。');
            },
        },
    ]);

    const cancellationFee = (context: ValidationContext<{
        isCanceling: boolean,
        cancellationFee: number,
    }>): Rules<number> => ([
        {
            validator: (_rule, _value, callback) => {
                const { isCanceling, cancellationFee } = context();

                // キャンセル料入力モード以外はチェック不要
                if (!isCanceling) return callback();

                if (cancellationFee > 0) return callback();

                callback('キャンセル料を入力してください。');
            },
        },
    ]);

    const paymentDate = (context: ValidationContext<{
        defaultPaymentDate?: DateValue,
        paymentDate?: DateValue,
        isUnavailablePaymentDate: (date: DateValue) => boolean
    }>): Rules<DateValue | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { defaultPaymentDate, paymentDate } = context();

                if (!_.isNil(defaultPaymentDate)) return callback();

                if (!_.isNil(paymentDate)) return callback();

                callback('入金予定日を選択してください。');
            }
        },
        {
            validator: (_rule, _value, callback) => {
                const { defaultPaymentDate, paymentDate, isUnavailablePaymentDate } = context();

                const target = paymentDate ?? defaultPaymentDate;

                if (_.isNil(target)) return callback();

                if (!isUnavailablePaymentDate(target)) return callback();

                return callback(`入金予定日を選択し直してください。`);
            }
        }
    ]);

    return {
        payerCompany,
        departureDate,
        arrivalDate,
        departureLocation,
        arrivalLocation,
        truckType,
        freight,
        cancellationFee,
        paymentDate,
    };
};
