import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { agreementApi, companyApi, onlineOrderApi } from '@/repository/api/api';
import {
    ACTION as a,
    Agreement,
    AgreementChangeRequest,
    AgreementForm,
    AgreementList,
    AgreementListAcceptedForm,
    AgreementListForm,
    AgreementOnlineOrderAvailability,
    AgreementSearchAcceptedForm,
    AgreementSearchAgreedForm,
    AgreementState,
    AgreementToggleGuaranteeForm,
    AgreementUpdateForm,
    AgreementPartnerInfo,
    GETTER as g,
    MUTATION as m,
} from './types';
import * as companyTypes from '@/vuex/modules/company/types';

const state: AgreementState = {
    agreement: undefined,
    agreementList: {
        totalPageCount: 0,
        totalRecordCount: 0,
        currentPageNumber: 1,
        pageSize: 10,
        data: [],
    },
    changeRequest: undefined,
    monthlyAgreementList: {
        totalPageCount: 0,
        totalRecordCount: 0,
        currentPageNumber: 1,
        pageSize: 10,
        data: [],
    },
    existsOnlineOrderDirection: false,
    availableOnlineOrderDirection: undefined,
    agreementPartnerInfo: undefined,
    acceptedAgreementCount: undefined,
};

const actions: ActionTree<AgreementState, void> = {
    /**
     * 成約を登録します。
     *
     * @param param 成約登録フォーム
     */
    [a.REGISTER_AGREEMENT]: (_, param: AgreementForm) => agreementApi.register(param),
    /**
     * 成約をロードします。
     *
     * @param param 成約ID
     */
    [a.LOAD_AGREEMENT]: ({ commit }, param: number) =>
        agreementApi.find(param).then((agreement) => {
            commit(m.SET_AGREEMENT, agreement);
            return agreement;
        }),
    /**
     * 成約データをクリアします。
     */
    [a.CLEAR_AGREEMENT]: ({ commit }) => commit(m.SET_AGREEMENT, undefined),
    /**
     * 成約変更リクエストをロードします。
     *
     * @param param 成約ID
     */
    [a.LOAD_AGREEMENT_CHANGE_REQUEST]: ({ commit }, param: number) =>
        agreementApi.findRequest(param).then((request) => commit(m.SET_AGREEMENT_CHANGE_REQUEST, request)),
    /**
     * 成約変更リクエストをクリアします。
     */
    [a.CLEAR_AGREEMENT_CHANGE_REQUEST]: ({ commit }) => commit(m.SET_AGREEMENT_CHANGE_REQUEST, undefined),
    /**
     * 成約を更新します。
     *
     * @param id 成約ID
     * @param param 成約更新フォーム
     */
    [a.UPDATE_AGREEMENT]: (_, { id, param }: { id: number; param: AgreementUpdateForm }) =>
        agreementApi.update(id, param),
    /**
     * 成約を取り消します。
     *
     * @param param 成約ID
     */
    [a.REVOKE_AGREEMENT]: (_, param: number) =>
        agreementApi.revoke(param),
    /**
     * 成約変更リクエストを承認します。
     *
     * @param param 成約ID
     */
    [a.APPROVE_AGREEMENT_CHANGE]: (_, param: number) =>
        agreementApi.approve(param),
    /**
     * 成約変更リクエストを却下します。
     *
     * @param param 成約ID
     */
    [a.REJECT_AGREEMENT_CHANGE]: ({ commit }, param: number) =>
        agreementApi.reject(param).then(() => commit(m.SET_AGREEMENT, undefined)),
    /**
     * 成約変更リクエストを取下げます。
     *
     * @param param 成約ID
     */
    [a.CANCEL_AGREEMENT_CHANGE]: ({ commit }, param: number) =>
        agreementApi.cancel(param).then(() => commit(m.SET_AGREEMENT, undefined)),
    /**
     * 成約一覧をロードします。
     *
     * @param param 成約一覧フォーム
     */
    [a.LIST_AGREEMENT]: ({ commit }, param: AgreementListForm) =>
        agreementApi.list(param).then((list) => commit(m.SET_AGREEMENT_LIST, list)),
    /**
     * 成約一覧（受託）をロードします。
     *
     * @param param 成約一覧（受託）フォーム
     */
    [a.LIST_ACCEPTED_AGREEMENT]: ({ commit }, param: AgreementListAcceptedForm) =>
        agreementApi.listAccepted(param).then((list) => commit(m.SET_AGREEMENT_LIST, list)),
    /**
     * 月ごとの成約一覧（受託）をロードします。
     *
     * @param param 成約一覧（受託）フォーム
     */
    [a.LIST_MONTHLY_ACCEPTED_AGREEMENT]: (
        { commit },
        { year, month, param }: { year: number; month: number; param: AgreementListAcceptedForm }
    ) =>
        agreementApi.listAcceptedMonthly(year, month, param).then((list) => commit(m.SET_MONTHLY_AGREEMENT_LIST, list)),
    /**
     *  指定の荷主企業からの受託回数をロードします。
     *
     * @param param 成約一覧（受託）フォーム
     */
    [a.COUNT_ACCEPTED_AGREEMENT]: ({ commit }, baggageCompanyId: number) =>
        agreementApi.countAccepted(baggageCompanyId).then((count) => commit(m.SET_ACCEPTED_AGREEMENT_COUNT, count)),
    /**
     * 月ごとの成約一覧（受託）をクリアします。
     */
    [a.CLEAR_MONTHLY_ACCEPTED_AGREEMENT]: ({ commit }) =>
        commit(m.SET_MONTHLY_AGREEMENT_LIST, state.monthlyAgreementList),
    /**
     * 成約（自社荷物）を検索します。
     */
    [a.SEARCH_ACCEPTED_AGREEMENT]: ({ commit }, param: AgreementSearchAcceptedForm) =>
        agreementApi.searchAccepted(param).then((list) => commit(m.SET_AGREEMENT_LIST, list)),
    /**
     * 成約（受託）を検索します。
     */
    [a.SEARCH_AGREED_AGREEMENT]: ({ commit }, param: AgreementSearchAgreedForm) =>
        agreementApi.searchAgreed(param).then((list) => commit(m.SET_AGREEMENT_LIST, list)),
    /**
     * 運賃全額保証を変更します。
     * @param param 運賃全額保証利用変更フォーム
     */
    [a.TOGGLE_GUARANTEE]: (_, { id, param }: { id: number; param: AgreementToggleGuaranteeForm }) =>
        agreementApi.toggleGuarantee(id, param),
    /**
     * トラボックス受発注に依頼書が存在するか否かをロードします。
     */
    [a.LOAD_EXISTENCE_ONLINE_ORDER_DIRECTION]: ({ commit }, param: number) =>
        onlineOrderApi.exists(param).then((exists) => commit(m.SET_EXISTENCE_ONLINE_ORDER_DIRECTION, exists)),
    /**
     * トラボックス受発注に依頼書が存在するか否かをクリアします。
     */
    [a.CLEAR_EXISTENCE_ONLINE_ORDER_DIRECTION]: ({ commit }) => commit(m.SET_EXISTENCE_ONLINE_ORDER_DIRECTION, false),
    /**
     * トラボックス受発注の依頼書を利用できるか否かをロードします。
     */
    [a.LOAD_AVAILABILITY_ONLINE_ORDER_DIRECTION]: ({ commit }, param: number) =>
        onlineOrderApi.availability(param).then((value) => commit(m.SET_AVAILABILITY_ONLINE_ORDER_DIRECTION, value)),
    /**
     * トラボックス受発注の依頼書を利用できるか否かをクリアします。
     */
    [a.CLEAR_AVAILABILITY_ONLINE_ORDER_DIRECTION]: ({ commit }) => commit(m.SET_AVAILABILITY_ONLINE_ORDER_DIRECTION, undefined),
    /**
     * 成約パートナー企業の情報をロードします。
     */
    [a.LOAD_AGREEMENT_PARTNER_INFO]: ({ commit }, agreementId: number) =>
        Promise.all([
            agreementApi.partnerCompanyProfile(agreementId),
            agreementApi.partnerCompanyConfidence(agreementId),
            agreementApi.partnerCompanyStatistics(agreementId),
        ])
            .then(async ([profile, confidence, statistics]: [companyTypes.CompanyProfile, companyTypes.CompanyConfidence, companyTypes.CompanyStatistics]) => {
                const officialCompany: companyTypes.OfficialCompany | undefined = await companyApi.officialCompany(profile.id);
                commit(m.SET_AGREEMENT_PARTNER_INFO, { profile, officialCompany, confidence, statistics });
            }),
    /**
     * 成約パートナー企業の情報をクリアします。
     */
    [a.CLEAR_AGREEMENT_PARTNER_INFO]: ({ commit }) => commit(m.SET_AGREEMENT_PARTNER_INFO, undefined),
};

const getters: GetterTree<AgreementState, void> = {
    /**
     * 成約を取得します。
     */
    [g.AGREEMENT]: (s) => s.agreement,
    /**
     * 成約一覧を取得します。
     */
    [g.AGREEMENT_LIST]: (s) => s.agreementList,
    /**
     * 成約変更リクエストを取得します。
     */
    [g.AGREEMENT_CHANGE_REQUEST]: (s) => s.changeRequest,
    /**
     * 成約一覧を取得します。
     */
    [g.MONTHLY_AGREEMENT_LIST]: (s) => s.monthlyAgreementList,
    /**
     * トラボックス受発注に依頼書が存在するか否かを取得します。
     */
    [g.EXISTENCE_ONLINE_ORDER_DIRECTION]: (s) => s.existsOnlineOrderDirection,
    /**
     * トラボックス受発注の依頼書を利用できるか否かを取得します。
     */
    [g.AVAILABILITY_ONLINE_ORDER_DIRECTION]: (s) => s.availableOnlineOrderDirection,
    /**
     * 成約パートナー企業の情報を取得します。
     */
    [g.AGREEMENT_PARTNER_INFO]: (s) => s.agreementPartnerInfo,
    /**
     * 指定の荷主企業からの受託回数を取得します。
     */
    [g.ACCEPTED_AGREEMENT_COUNT]: (s) => s.acceptedAgreementCount,
};

const mutations: MutationTree<AgreementState> = {
    /**
     * 成約を設定します。
     *
     * @param agreement 成約
     */
    [m.SET_AGREEMENT]: (s, agreement?: Agreement) => (s.agreement = agreement),
    /**
     * 成約一覧を設定します。
     *
     * @param list 成約一覧
     */
    [m.SET_AGREEMENT_LIST]: (s, list: AgreementList) => (s.agreementList = list),
    /**
     * 成約変更リクエストを設定します。
     *
     * @param request 成約変更リクエスト
     */
    [m.SET_AGREEMENT_CHANGE_REQUEST]: (s, request?: AgreementChangeRequest) => (s.changeRequest = request),
    /**
     * 年月ごとの成約一覧を設定します。
     *
     * @param list 成約一覧
     */
    [m.SET_MONTHLY_AGREEMENT_LIST]: (s, list: AgreementList) => (s.monthlyAgreementList = list),
    /**
     * トラボックス受発注に依頼書が存在するか否かを設定します。
     */
    [m.SET_EXISTENCE_ONLINE_ORDER_DIRECTION]: (s, exists: boolean) => (s.existsOnlineOrderDirection = exists),
    /**
     * トラボックス受発注の依頼書を利用できるか否かを設定します。
     */
    [m.SET_AVAILABILITY_ONLINE_ORDER_DIRECTION]: (s, value: AgreementOnlineOrderAvailability) => (s.availableOnlineOrderDirection = value),
    /**
     * 成約パートナー企業の情報を設定します。
     */
    [m.SET_AGREEMENT_PARTNER_INFO]: (s, value?: AgreementPartnerInfo) => (s.agreementPartnerInfo = value),
    /**
     * 指定の荷主企業からの受託回数を設定します。
     */
    [m.SET_ACCEPTED_AGREEMENT_COUNT]: (s, count?: number) => (s.acceptedAgreementCount = count),
};

export const agreement: Module<AgreementState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
