import _ from 'lodash';
import store from '@/vuex/store';
import router from '@/router';
import { Karte } from '@/karte';
import { Dictionary, Route } from 'vue-router/types/router';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as predictionTypes from '@/vuex/modules/prediction/types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';
import { notification } from 'ant-design-vue';
import { DateUtil, Util } from '@/util';
import { RouteLocationNormalizedLoaded } from 'vue2-helpers/vue-router';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum } from '@/enums/baggage-handling-type.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckHeightEnum } from '@/enums/truck-height.enum';
import { TruckWidthEnum } from '@/enums/truck-width.enum';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { BaggageRegisterFormModel } from '@/vuex/modules/baggage/types';

// ======================================================
// Routing Helpers

// ======================================================
/**
 * 荷物詳細画面へ遷移します。
 */
export const goToBaggageDetail = (id: number, registered?: boolean): Promise<Route> =>
    router.push({
        path: '/baggage/list/opened',
        query: registered ? { baggageId: `${ id }`, registered: `${ registered }` } : { baggageId: `${ id }` }
    });


// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 荷物を登録します。
 */
export const registerBaggage = (form: baggageTypes.BaggageRegisterForm): Promise<Array<number>> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.REGISTER_BAGGAGE }`, form);

/**
 * 荷物をロードします。
 */
export const loadBaggage = (id: number): Promise<baggageTypes.Baggage> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE }`, id);

/**
 * 荷物情報をVuex上からクリアします。
 */
export const clearBaggage = (): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_BAGGAGE }`);

/**
 * 企業担当者名おすすめ一覧をロードします。
 */
export const registerCompanyStaffNameSuggestion = (staffName: string): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.REGISTER_STAFF_NAME_SUGGESTION }`, { staffName });

/**
 * 企業担当者名おすすめ一覧をロードします。
 */
export const loadCompanyStaffNameSuggestions = (): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.LOAD_STAFF_NAME_SUGGESTIONS }`);

/**
 * 企業担当者名おすすめ項目を削除します。
 */
export const deleteCompanyStaffNameSuggestion = (staffName: string): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.DELETE_STAFF_NAME_SUGGESTION }`, { staffName });

/**
 * 荷物の成約予測を取得します。
 */
export const predictAgreementProbability = async (form: baggageTypes.BaggageRegisterFormModel): Promise<number> => {
    // 成約予測フォーム生成
    const req = createPredictionForm(form);
    // 成約予測
    const res: number = await store.dispatch(`prediction/${ predictionTypes.ACTION.PREDICT_AGREEMENT_PROBABILITY }`, req);
    // トラッキング
    Karte.trackPredictBaggageAgreement(req, res);

    return res;
};

/**
 * 参考運賃を取得します。
 */
export const queryReferenceFreight = async (form: baggageTypes.BaggageRegisterFormModel): Promise<void> => {
    await clearReferenceFreight();

    const request = createBaggageFreightMasterQueryForm(form);
    if (!request) {
        return;
    }

    return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_REGISTER_REFERENCE_FREIGHT }`, request);
};

/**
 * 参考運賃をVuex上からクリアします。
 */
export const clearReferenceFreight = (): Promise<void> =>
    store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_BAGGAGE_REGISTER_REFERENCE_FREIGHT }`);

// ======================================================
// Notification Helpers
// ======================================================
const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_BAGGAGE_ERROR';
const NOTIFICATION_KEY_FAILED_TO_DEL_STAFF = 'DELETE_STAFF_NAME_SUGGESTION_ERROR';

/**
 * 荷物登録失敗を通知します。
 */
export const notifyFailedToRegister = (): void => notification.error({
    key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
    message: '荷物の登録ができませんでした。',
    description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
});

/**
 * 荷物登録失敗通知を閉じます。
 */
export const closeFailedToRegisterNotification = (): void =>
    notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

/**
 * 担当者履歴の削除失敗を通知します。
 */
export const notifyFailedToDeleteStaff = (): void => notification.error({
    key: NOTIFICATION_KEY_FAILED_TO_DEL_STAFF,
    message: '担当者の履歴を削除できませんでした。',
    description: '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
});

/**
 * 担当者履歴の削除失敗通知を閉じます。
 */
export const closeFailedToDeleteStaffNotification = (): void =>
    notification.close(NOTIFICATION_KEY_FAILED_TO_DEL_STAFF);

// ======================================================
// Convenient Helpers
// ======================================================
/**
 * Convert {@link baggageTypes.BaggageRegisterFormModel} to {@link PredictionBaggageAgreementForm}.
 */
const createPredictionForm = (form: baggageTypes.BaggageRegisterFormModel): PredictionBaggageAgreementForm => new PredictionBaggageAgreementForm({
    departure: form.departureMax,
    departurePref: form.departurePref,
    arrival: form.arrivalMax,
    arrivalPref: form.arrivalPref,
    type: form.type,
    truckWeight: form.truckWeight,
    truckModel: form.truckModel,
    share: form.share,
    express: form.express,
    freight: form.freight,
});

/**
 * 成約確率の再予測が必要か否かを取得します。
 */
export const needsRePrediction = (current: baggageTypes.BaggageRegisterFormModel, next: baggageTypes.BaggageRegisterFormModel): boolean => {
    const currentForm = createPredictionForm(current);
    const nextForm = createPredictionForm(next);

    if (!next.isReadyToPredict) return false;

    return !_.isEqual(currentForm, nextForm);
};

/**
 * Convert {@link baggageTypes.BaggageRegisterFormModel} to {@link baggageTypes.BaggageFreightMasterQueryForm}.
 */
const createBaggageFreightMasterQueryForm = (form: baggageTypes.BaggageRegisterFormModel): baggageTypes.BaggageFreightMasterQueryForm | undefined => {
    const departurePref = form.departurePref?.code;
    const arrivalPref = form.arrivalPref?.code;
    const truckWeight = form.truckWeight?.code;
    const truckModel = form.truckModel?.code;

    if (!departurePref || !arrivalPref || !truckWeight || !truckModel) {
        return;
    }

    // 取得するのは1年前の同月
    const lastYear = DateUtil.now().subtract(1, 'year');

    return new baggageTypes.BaggageFreightMasterQueryForm({
        year: lastYear.year(),
        month: lastYear.month() + 1,
        departurePref: { code: departurePref },
        arrivalPref: { code: arrivalPref },
        truckWeight: { code: truckWeight },
        truckModel: { code: truckModel },
    });
};

/**
 * 参考運賃の再取得が必要化否かを取得します。
 */
export const needsReQueryFreight = (current: baggageTypes.BaggageRegisterFormModel, next: baggageTypes.BaggageRegisterFormModel): boolean => {
    const currentForm = createBaggageFreightMasterQueryForm(current);
    const nextForm = createBaggageFreightMasterQueryForm(next);

    return !_.isEqual(currentForm, nextForm);
};

/**
 * クエリーから荷物情報を取得します。
 */
export const baggageFromQuery = (route: RouteLocationNormalizedLoaded): baggageTypes.BaggageRegisterFormModel | undefined => {
    const queries = route.query;
    return QueryParser.build(queries.parser)?.parse(queries) ?? undefined;
};

abstract class QueryParser {
    static build(version: any) {
        switch (version) {
            case '1':
                return new QueryParserV1();
            default:
                return undefined;
        }
    }

    public abstract parse(queries: Dictionary<string | (string | null)[]>): baggageTypes.BaggageRegisterFormModel | undefined;
}

class QueryParserV1 extends QueryParser {
    private parsed: boolean = false;

    public parse(queries: Dictionary<string | (string | null)[]>): baggageTypes.BaggageRegisterFormModel | undefined {
        const form = new baggageTypes.BaggageRegisterForm();
        Object.keys(queries).forEach((query, _index) => {
            const param = queries[query];
            switch (query) {
                case 'departureMin':
                    form.departureMin = this.parseString(param);
                    break;
                case 'departureMax':
                    form.departureMax = this.parseString(param);
                    break;
                case 'departurePref':
                    form.departurePref = this.parseEnum<PrefectureEnum>(param, (code) => PrefectureEnum.valueOf(code as PrefectureEnumCode));
                    break;
                case 'departureCity':
                    form.departureCity = this.parseString(param);
                    break;
                case 'departureAddress':
                    form.departureAddress = this.parseString(param);
                    break;
                case 'loadingTimeNote':
                    form.loadingTimeNote = this.parseString(param);
                    break;
                case 'arrivalMin':
                    form.arrivalMin = this.parseString(param);
                    break;
                case 'arrivalMax':
                    form.arrivalMax = this.parseString(param);
                    break;
                case 'arrivalPref':
                    form.arrivalPref = this.parseEnum<PrefectureEnum>(param, (code) => PrefectureEnum.valueOf(code as PrefectureEnumCode));
                    break;
                case 'arrivalCity':
                    form.arrivalCity = this.parseString(param);
                    break;
                case 'arrivalAddress':
                    form.arrivalAddress = this.parseString(param);
                    break;
                case 'unloadingTimeNote':
                    form.unloadingTimeNote = this.parseString(param);
                    break;
                case 'shape':
                    form.shape = this.parseEnumStrict<BaggageShapeEnum>(param, (code) => BaggageShapeEnum.valueOf(code as BaggageShapeEnumCode));
                    break;
                case 'type':
                    form.type = this.parseString(param);
                    break;
                case 'paletteCount':
                    form.paletteCount = this.parseNumberAsStringUndefined(param);
                    break;
                case 'paletteHeight':
                    form.paletteHeight = this.parseNumberAsStringUndefined(param);
                    break;
                case 'paletteWidth':
                    form.paletteWidth = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalCount':
                    form.totalCount = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalVolume':
                    form.totalVolume = this.parseNumberAsStringUndefined(param);
                    break;
                case 'totalWeight':
                    form.totalWeight = this.parseNumberAsStringUndefined(param);
                    break;
                case 'loading':
                    form.loading = this.parseEnumStrict<BaggageHandlingTypeEnum>(param, (code) => BaggageHandlingTypeEnum.valueOf(code));
                    break;
                case 'unloading':
                    form.unloading = this.parseEnumStrict<BaggageHandlingTypeEnum>(param, (code) => BaggageHandlingTypeEnum.valueOf(code));
                    break;
                case 'truckWeight':
                    form.truckWeight = this.parseEnum<TruckWeightEnum>(param, (code) => {
                        return TruckWeightEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckModel':
                    form.truckModel = this.parseEnum<TruckModelEnum>(param, (code) => {
                        return TruckModelEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckHeight':
                    form.truckHeight = this.parseEnumStrict<TruckHeightEnum>(param, (code) => {
                        return TruckHeightEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'truckWidth':
                    form.truckWidth = this.parseEnumStrict<TruckWidthEnum>(param, (code) => {
                        return TruckWidthEnum.registrableValuesForBaggage.find(value => value.code === code);
                    });
                    break;
                case 'largeTruckFlg':
                    form.largeTruckFlg = this.parseBooleanUndefined(param);
                    break;
                case 'truckEquipment':
                    form.truckEquipment = this.parseStringUndefined(param);
                    break;
                case 'truckCount':
                    // コンポーネントの仕様上、初期値で上限を超えて設定すると見た目は上限の10だが内部的に上限を超えた値になっている為、
                    // ここで妥当な値を設定する必要がある。
                    form.truckCount = this.parseNumberAsString(param, { defaultValue: '1', maxValue: 10 });
                    break;
                case 'freight':
                    form.freight = this.parseFreight(param);
                    break;
                case 'highwayFareFlg':
                    form.highwayFareFlg = this.parseBooleanUndefined(param);
                    break;
                case 'staffName':
                    form.staffName = this.parseString(param);
                    break;
                case 'description':
                    form.description = this.parseStringUndefined(param);
                    break;
                case 'underNegotiation':
                    form.underNegotiation = this.parseBoolean(param);
                    break;
                case 'negotiationType':
                    form.negotiationType = this.parseEnumStrict<NegotiationTypeEnum>(param, (code) => NegotiationTypeEnum.valueOf(code));
                    break;
                case 'share':
                    form.share = this.parseBoolean(param);
                    break;
                case 'express':
                    form.express = this.parseBoolean(param);
                    break;
                case 'category':
                    form.category = this.parseEnum<BaggageCategoryEnum, BaggageCategoryEnumCode>(param, (code) => BaggageCategoryEnum.valueOf(code as BaggageCategoryEnumCode));
                    break;
                case 'paymentDate':
                    form.paymentDate = this.parseString(param);
                    break;
                default:
                    break;
            }
        });
        return this.parsed ? new BaggageRegisterFormModel(form) : undefined;
    }

    private parseString(value: any | undefined): string {
        this.parsed = true;
        if (value) return Util.castAsString(value) ?? '';
        return '';
    }

    private parseStringUndefined(value: any | undefined): string | undefined {
        this.parsed = true;
        if (value) return Util.castAsString(value);
        return undefined;
    }

    private parseNumberAsString(value: any | undefined, option: { defaultValue: string, maxValue: number | undefined } = {
        defaultValue: '0',
        maxValue: undefined
    }): string {
        this.parsed = true;
        if (value && Util.isNumeric(Util.toDigits(value))) {
            const inputValue = Util.toNumber(Util.toDigits(value));
            if (option.maxValue) {
                return (Math.min(inputValue, option.maxValue)).toString();
            } else {
                return inputValue.toString();
            }
        }
        return option.defaultValue;
    }

    private parseNumberAsStringUndefined(value: any | undefined): string | undefined {
        this.parsed = true;
        if (value && Util.isNumeric(Util.toDigits(value))) return Util.toDigits(value);
        return undefined;
    }

    private parseEnum<E, C = string>(value: any | undefined, parse: (code: string) => E | undefined): { code?: C } {
        this.parsed = true;
        if (value) {
            const code = Util.castAsString(value);
            if (code) return parse(code) ?? { code: undefined };
        }
        return { code: undefined };
    }

    private parseEnumStrict<E>(value: any | undefined, parse: (code: string | undefined) => E | undefined): E | undefined {
        this.parsed = true;
        if (value) {
            const code = Util.castAsString(value);
            return parse(code);
        }
        return undefined;
    }

    private parseBoolean(value: any, defaultValue: boolean = false): boolean {
        this.parsed = true;
        switch (value) {
            case 'T':
                return true;
            case 'F':
                return false;
            default:
                return defaultValue;
        }
    }

    private parseBooleanUndefined(value: any): boolean | undefined {
        this.parsed = true;
        switch (value) {
            case 'T':
                return true;
            case 'F':
                return false;
            default:
                return undefined;
        }
    }

    private parseFreight(value: any): string {
        this.parsed = true;
        return Util.parseFreightString(value);
    }
}
