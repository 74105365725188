export const columns = [
    {
        title: '車番',
        key: 'carNumber',
        scopedSlots: { customRender: 'carNumber' },
        width: 180,
    },
    {
        title: '重量',
        key: 'truckWeight',
        scopedSlots: { customRender: 'truckWeight' },
        width: 150,
    },
    {
        title: '車種',
        key: 'truckModel',
        scopedSlots: { customRender: 'truckModel' },
        width: 150,
    },
    {
        title: 'ドライバー名',
        key: 'driverName',
        scopedSlots: { customRender: 'driverName' },
        width: 150,
    },
    {
        title: '電話番号',
        key: 'driverPhoneNumber',
        scopedSlots: { customRender: 'driverPhoneNumber' },
        width: 150,
    },
    {
        title: '備考',
        key: 'description',
        scopedSlots: { customRender: 'description' },
    },
];
