//
// Action
//
import { Truck, TruckList } from '@/models/truck';

export class ACTION {
    static readonly LOAD_TRUCK = 'LOAD_TRUCK';
    static readonly LOAD_MY_TRUCK = 'LOAD_MY_TRUCK';
    static readonly SEARCH_TRUCK = 'SEARCH_TRUCK';
    static readonly REGISTER_TRUCK = 'REGISTER_TRUCK';
    static readonly UPDATE_TRUCK = 'UPDATE_TRUCK';
    static readonly LIST_TRUCK = 'LIST_TRUCK';
    static readonly CLEAR_TRUCK = 'CLEAR_TRUCK';
    static readonly CLEAR_TRUCK_LIST = 'CLEAR_TRUCK_LIST';
    static readonly CLOSE_TRUCK = 'CLOSE_TRUCK';
}

//
// Signature for Action
//
export type searchTruck = () => Promise<void>;
export type registerTruck = () => Promise<void>;
export type listTruck = () => Promise<void>;
export type clearTruckList = () => Promise<void>;
export type closeTruck = () => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly TRUCK = 'TRUCK';
    static readonly TRUCK_LIST = 'TRUCK_LIST';
    static readonly REGISTERED_TRUCK_LIST = 'REGISTERED_TRUCK_LIST';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_TRUCK = 'SET_TRUCK';
    static readonly SET_TRUCK_LIST = 'SET_TRUCK_LIST';
    static readonly SET_REGISTERED_TRUCK_LIST = 'SET_REGISTERED_TRUCK_LIST';
}

//
// Store
//
export interface TruckState {
    truck?: Truck;
    truckList: TruckList;
    registeredTruckList: TruckList;
}

//
// Type for store
//
// 空車検索フォーム
export * from '@/models/truck';
