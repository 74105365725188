<script setup lang="ts">
import { computed } from 'vue';
import { ZipUtil } from '@/util';

interface Props {
    zipCode?: string;
    prefix?: string;
}
const props = withDefaults(defineProps<Props>(), {
    prefix: '〒',
});

const value = computed(() => {
    if (props.zipCode) return ZipUtil.format(props.zipCode);
    return '';
});
</script>

<template>
    <span>
        <span v-if="prefix">{{ prefix }}</span>{{ value }}
    </span>
</template>
