import {
    GlobalStatistics,
} from '@/vuex/modules/statistics/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const statisticsApi = {
    /**
     * グローバル統計情報を取得します。
     */
    globalStatistics(): Promise<GlobalStatistics> {
        return appAxios.get<JsonResponseBean<GlobalStatistics>>('/api/statistics/global').then(isSuccess);
    },
};
