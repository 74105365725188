import { ActionTree, Commit, GetterTree, Module, MutationTree } from 'vuex';
import _ from 'lodash';
import { Const } from '@/const';
import { baggageApi, baggageFreightMasterApi, companyApi, truckApi } from '@/repository/api/api';
import {
    ACTION as a,
    Baggage,
    BaggageFreightMasterListForm,
    BaggageFreightMasterQueryForm,
    BaggageList,
    BaggageListForm,
    BaggageRegisterForm,
    BaggageSearchCondition,
    BaggageSearchConditionNotificationUpdateForm,
    BaggageSearchConditionRegisterForm,
    BaggageSearchContainer,
    BaggageSearchExcludedCompany,
    BaggageSearchForm,
    BaggageState,
    BaggageViewMemberCount,
    FavoriteBaggageListForm,
    GETTER as g,
    MUTATION as m,
    RecentBaggageListForm,
} from './types';
import {
    baggageSearchConditions,
    baggageSearchExcludedCompany,
    lastBaggageSearchDatetimes
} from '@/repository/storage/web-storage';
import { Karte } from '@/karte';
import { BaggageSearchResult } from './baggage-search-result';
import dayjs from 'dayjs';
import { BaggageDetailContainer } from '@/vuex/modules/baggage/baggage-detail-container';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { BaggageUtil } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import * as truckTypes from '@/vuex/modules/truck/types';

const defaultBaggageList = {
    totalPageCount: 0,
    totalRecordCount: 0,
    currentPageNumber: 1,
    pageSize: Const.DEFAULT_PAGE_SIZE,
    data: [],
};

export const defaultBaggageSearchForm = {
    departureFrom: '',
    departureTo: '',
    departurePref: [],
    arrivalFrom: '',
    arrivalTo: '',
    arrivalPref: [],
    category: { code: '' },
    type: '',
    truckWeight: [],
    truckModel: [],
    share: undefined,
    pageNo: 1,
    pageSize: 20,
    freight: '',
    excludeUndecidedFreight: false,
    excludeUndecidedTruckWeight: false,
    excludeUndecidedTruckModel: false,
    onlyStatusOpen: false,
    sortKey: 'DEPARTURE',
    sortOrder: 'ASC',
} as BaggageSearchForm;

const state: BaggageState = {
    baggageSearchContainers: [],
    loadingSavedBaggageSearchConditions: false,
    baggageSearchDetailContainer: undefined,
    baggage: undefined,
    baggageList: _.cloneDeep(defaultBaggageList),
    searchConditionList: [],
    favoriteBaggageIdList: [],
    readBaggageIdList: [],
    markedAsFavoriteBaggage: undefined,
    favoriteBaggageCount: undefined,
    baggageViewMemberCount: [],
    baggageSearchExcludedCompanyList: [],
    baggageRegisterReferenceFreight: undefined,
    favoriteBaggageReferenceFreightList: [],
    recentBaggageReferenceFreightList: [],
    baggageDetailReferenceFreight: undefined,
    truckRecommends: undefined,
};

const actions: ActionTree<BaggageState, void> = {
    /**
     * 荷物検索コンテナを初期化します。
     * @param commit commitメソッド
     */
    [a.INIT_SEARCH_CONTAINERS]: ({ commit }) => {
        const restoredContainers = restoreBaggageSearchContainers();
        // コンテナを1つに限定する。
        // 将来タブ可（複数コンテナ対応）するときに、ここをコンテナが1以上存在するように改修する。
        const containers = [_.first(restoredContainers) ?? defaultBaggageSearchContainer()];
        commit(m.SET_SEARCH_CONTAINERS, containers);
    },
    /**
     * 荷物検索コンテナをクリアします。
     * @param commit commitメソッド
     */
    [a.CLEAR_SEARCH_CONTAINERS]: ({ commit, state }) => {
        //
        // コンテナの後片付けする。
        //

        // 自動検索タイマーを解除する。
        state.baggageSearchContainers.forEach((container) => window.clearInterval(container.autoSearchIntervalId));

        commit(m.SET_SEARCH_CONTAINERS, []);
    },
    /**
     * 指定された荷物検索コンテナの荷物検索条件を更新します。
     * @param commit commitメソッド
     * @param containerIndex 荷物検索コンテナIndex
     * @param condition 荷物検索条件
     */
    [a.UPDATE_BAGGAGE_SEARCH_FORM]: ({ commit }, [containerIndex, condition]: [number, BaggageSearchForm]) => {
        commit(m.SET_BAGGAGE_SEARCH_FORM, [containerIndex, condition]);
    },
    /**
     * 指定された荷物検索コンテナの荷物検索条件をリセットします。
     * @param commit
     * @param state
     * @param containerIndex
     */
    [a.RESET_BAGGAGE_SEARCH_FORM]: ({ commit, state }, containerIndex: number) => {
        // リセットしない項目
        const overrides = _.pick<BaggageSearchForm, keyof BaggageSearchForm>(
            state.baggageSearchContainers[containerIndex].condition,
            ['pageNo', 'pageSize']
        );
        const newForm = Object.assign(_.cloneDeep(defaultBaggageSearchForm), overrides);
        commit(m.SET_BAGGAGE_SEARCH_FORM, [containerIndex, newForm]);
        saveBaggageSearchConditions(state.baggageSearchContainers.map((container) => container.condition));
    },
    /**
     * 選択された「よく使う検索条件」から荷物検索条件を復元します。
     * @param commit commitメソッド
     * @param containerIndex 荷物検索コンテナIndex
     * @param conditionId よく使う検索条件ID
     */
    [a.RESTORE_BAGGAGE_SEARCH_FORM]: ({ commit, state }, [containerIndex, conditionId]: [number, number]) => {
        const savedCondition = state.searchConditionList.find((item) => item.id === conditionId);
        if (savedCondition === undefined) {
            return;
        }

        // フォームを復元する。
        const form = _.cloneDeep(state.baggageSearchContainers[containerIndex].condition);
        form.departurePref = savedCondition.departurePref;
        form.arrivalPref = savedCondition.arrivalPref;
        form.category = savedCondition.category;
        form.truckWeight = savedCondition.truckWeight.map((item) => ({ code: item.code }));
        form.truckModel = savedCondition.truckModel.map((item) => ({ code: item.code }));
        form.share = savedCondition.share;
        form.freight = savedCondition.freight?.toString();
        form.excludeUndecidedFreight = savedCondition.excludeUndecidedFreight;
        form.excludeUndecidedTruckWeight = savedCondition.excludeUndecidedTruckWeight;
        form.excludeUndecidedTruckModel = savedCondition.excludeUndecidedTruckModel;
        form.pageNo = 1;
        commit(m.SET_BAGGAGE_SEARCH_FORM, [containerIndex, form]);

        // 荷物を検索する。
        return searchBaggage(commit, containerIndex, form)
            // KARTEイベント送信： よく使う検索条件から検索
            .then(() => Karte.trackSearchBaggageBySavedSearchCondition(conditionId));
    },
    /**
     * 荷物検索結果一覧のソートキーを変更します。
     * @param containerIndex 荷物検索コンテナIndex
     * @param sortKey ソートキー
     */
    [a.UPDATE_BAGGAGE_SEARCH_SORT_KEY]: ({ commit, state }, [containerIndex, sortKey]: [number, string]) => {
        const condition = _.cloneDeep(state.baggageSearchContainers[containerIndex].condition);
        condition.pageNo = 1;
        condition.sortKey = sortKey;

        // ソートキーに応じた、おすすめの順序を設定
        const sortOrder = Const.baggageSortKey.find(key => key.code === sortKey)?.defaultOrder;
        if (sortOrder) {
            condition.sortOrder = sortOrder;
        }

        // 荷物を検索する。
        return searchBaggage(commit, containerIndex, condition)
            .then(() => {
                Karte.trackSortBaggageList(condition);
                saveBaggageSearchConditions(state.baggageSearchContainers.map((container) => container.condition));
            });
    },
    /**
     * 荷物検索結果一覧のソート方向を変更します。
     * @param containerIndex 荷物検索コンテナIndex
     * @param sortDirection ソート方向
     */
    [a.UPDATE_BAGGAGE_SEARCH_SORT_DIRECTION]: (
        { commit, state },
        [containerIndex, sortDirection]: [number, string]
    ) => {
        const condition = _.cloneDeep(state.baggageSearchContainers[containerIndex].condition);
        condition.pageNo = 1;
        condition.sortOrder = sortDirection;

        // 荷物を検索する。
        return searchBaggage(commit, containerIndex, condition)
            .then(() => {
                Karte.trackSortBaggageList(condition);
                saveBaggageSearchConditions(state.baggageSearchContainers.map((container) => container.condition));
            });
    },
    /**
     * 荷物検索結果をリロードします。
     * @param containerIndex 荷物検索コンテナIndex
     */
    [a.RELOAD_SEARCH_RESULT]: ({ commit, state }, containerIndex: number) => {
        const condition = state.baggageSearchContainers[containerIndex].result?.condition;
        if (condition === undefined) {
            return;
        }

        // 荷物を検索する。
        return searchBaggage(commit, containerIndex, condition)
            // KARTEイベント送信： 荷物検索の更新
            .then(() => Karte.trackSearchBaggageByReload(condition));
    },
    /**
     * 荷物検索結果のページ、ページあたり件数を変更する。
     * @param containerIndex 荷物検索コンテナIndex
     * @param param ページNo, ページサイズ
     */
    [a.CHANGE_SEARCH_RESULT_PAGE]: (
        { commit, state },
        [containerIndex, param]: [number, { pageNo: number, pageSize: number }]
    ) => {
        const condition = state.baggageSearchContainers[containerIndex].result?.condition;
        if (condition === undefined) {
            return;
        }
        const newCondition = { ..._.cloneDeep(condition), ...param };

        // 荷物を検索する。
        return searchBaggage(commit, containerIndex, newCondition)
            .then(() => {
                saveBaggageSearchConditions(state.baggageSearchContainers.map((container) => container.condition));
            });
    },
    /**
     * 初回読み込み
     * @param commit
     */
    [a.FIRST_SEARCH]: ({ commit, state }, containerIndex: number) => {
        // 初回読み込み済みだったらスキップ
        if (state.baggageSearchContainers[containerIndex].result !== undefined) {
            return;
        }
        const condition = state.baggageSearchContainers[containerIndex].condition;
        return searchBaggage(commit, containerIndex, condition);
    },
    /**
     * 荷物検索ページ用の荷物詳細をロードします。
     * @param baggageId 荷物ID
     * @param ref リファラ
     */
    [a.LOAD_SEARCH_BAGGAGE_DETAIL_CONTAINER]: async (
        { commit,state },
        [baggageId, ref]: [number, string | undefined]
    ) => {
        const baggage = await baggageApi.find(baggageId, ref);
        const companyId = baggage.companyId;

        const merge = (param: Partial<BaggageDetailContainer>) =>
            commit(m.SET_SEARCH_BAGGAGE_DETAIL_CONTAINER, _.merge({}, state.baggageSearchDetailContainer, param));

        Promise.resolve()
            .then(() => merge({ baggage }))
            // 企業プロフィール
            .then(() => companyApi.profile(companyId))
            .then(companyProfile => merge({ companyProfile }))
            // 法人データ
            .then(() => companyApi.officialCompany(companyId))
            .then(officialCompany => merge({ officialCompany }))
            // 企業信用情報
            .then(() => companyApi.confidence(companyId))
            .then(confidence => merge({ confidence }))
            // 企業統計情報
            .then(() => companyApi.statistics(companyId))
            .then(statistics => merge({ statistics }))
            // 保存済みか？
            .then(() => baggageApi.queryFavorite({ ids: [baggageId] }))
            .then(list => merge({ markedFavorite: _.includes(list.ids, baggageId) }))
            // 参考運賃
            .then(() => baggageFreightMasterApi.query(BaggageFreightMasterQueryForm.newInstance(baggage)))
            .then(referenceFreight => merge({ referenceFreight }));
        return baggage;
    },
    /**
     * 荷物検索ページ用の荷物詳細をクリアします。
     */
    [a.CLEAR_SEARCH_BAGGAGE_DETAIL_CONTAINER]: ({ commit }) => {
        commit(m.SET_SEARCH_BAGGAGE_DETAIL_CONTAINER, undefined);
    },
    /**
     * 荷物をロードします。
     *
     * @param commit commitメソッド
     * @param baggageId 荷物ID
     */
    [a.LOAD_BAGGAGE]: ({ commit }, baggageId) =>
        baggageApi.find(baggageId, undefined).then((baggage) => {
            commit(m.SET_BAGGAGE, baggage);
            return baggage;
        }),
    /**
     * 自社荷物をロードします。
     *
     * @param commit commitメソッド
     * @param baggageId 荷物ID
     */
    [a.LOAD_MY_BAGGAGE]: ({ commit }, baggageId): Promise<Baggage> =>
        baggageApi.findMyBaggage(baggageId).then((baggage) => {
            commit(m.SET_BAGGAGE, baggage);
            return baggage;
        }),
    /**
     * 荷物をクリアします。
     */
    [a.CLEAR_BAGGAGE]: ({ commit }) => commit(m.SET_BAGGAGE, undefined),
    /**
     * 荷物一覧をロードします。
     *
     * @param commit commitメソッド
     * @param form 荷物一覧取得フォーム
     */
    [a.LIST_BAGGAGE]: ({ commit }, form: BaggageListForm) =>
        baggageApi.list(form).then((list) => commit(m.SET_BAGGAGE_LIST, list)),
    /**
     * 荷物一覧をクリアします。
     */
    [a.CLEAR_BAGGAGE_LIST]: ({ commit }) => commit(m.SET_BAGGAGE_LIST, _.cloneDeep(defaultBaggageList)),
    /**
     * 荷物を検索します。
     *
     * @param containerIndex 荷物検索コンテナIndex
     */
    [a.SEARCH_BAGGAGE]: ({ commit, state }, containerIndex: number) => {
        const condition = _.cloneDeep(state.baggageSearchContainers[containerIndex].condition);
        condition.pageNo = 1;
        return searchBaggage(commit, containerIndex, condition)
            .then(() => {
                // 荷物検索コンテナを保存
                saveBaggageSearchConditions(state.baggageSearchContainers.map((container) => container.condition));
                // KARTEイベント送信： 荷物検索
                Karte.trackSearchBaggageByButton(condition);
            });
    },
    /**
     * 自動荷物検索を開始します。
     * @param containerIndex 荷物検索コンテナIndex
     */
    [a.START_AUTO_SEARCH_BAGGAGE]: ({ commit, state }, containerIndex: number) => {
        // スタート済みの場合はスキップ
        if (state.baggageSearchContainers[containerIndex].autoSearchIntervalId !== undefined) {
            return;
        }

        function search(): Promise<void> {
            const condition = _.cloneDeep(state.baggageSearchContainers[containerIndex].condition);
            condition.pageNo = 1;
            return searchBaggage(commit, containerIndex, condition);
        }

        // 初回の検索
        // 切り替えボタンのアニメーションと検索結果の画面更新が重なると切り替えボタンの挙動がおかしくなる。
        // そのため、初回の検索を少し遅らせて処理が重ならないようにしています。
        setTimeout(async () => {
            if (state.baggageSearchContainers[containerIndex].autoSearchIntervalId !== undefined) {
                await search();
            }
        }, 1000);

        // インターバルを開始して、キャンセル用IDをストアに保存する
        const cancellable = window.setInterval(async () => await search(), 10000);
        commit(m.SET_BAGGAGE_SEARCH_INTERVAL_ID, [containerIndex, cancellable]);
    },
    /**
     * 自動荷物検索を停止します。
     * @param containerIndex 荷物検索コンテナIndex
     */
    [a.STOP_AUTO_SEARCH_BAGGAGE]: ({ commit, state }, containerIndex: number) => {
        const id = state.baggageSearchContainers[containerIndex].autoSearchIntervalId;
        if (id === undefined) {
            return;
        }
        clearInterval(id);
        commit(m.SET_BAGGAGE_SEARCH_INTERVAL_ID, [containerIndex, undefined]);
    },
    /**
     * 荷物を登録します。
     *
     * @param param 荷物登録フォーム
     */
    [a.REGISTER_BAGGAGE]: (_, param: BaggageRegisterForm) => baggageApi.register(param),
    /**
     * 荷物を取り下げ（削除）します。
     */
    [a.CANCEL_BAGGAGE]: (_, param: number) => baggageApi.cancel(param),
    /**
     * よく使う検索条件一覧をロードします。
     *
     * @param commit commitメソッド
     * @param form 荷物一覧取得フォーム
     */
    [a.LOAD_BAGGAGE_FORM_SEARCH_CONDITION]: ({ commit }) =>
        baggageApi.listSearchCondition().then((list) => commit(m.SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST, list)),
    /**
     * 荷物詳細の閲覧会員数をロードします。
     *
     * @param commit commitメソッド
     * @param ids 荷物IDリスト
     */
    [a.LOAD_BAGGAGE_VIEW_MEMBER_COUNT_LIST]: ({ commit }, ids: Array<number>) =>
        baggageApi.countUnique(ids).then((list) => commit(m.SET_BAGGAGE_VIEW_MEMBER_COUNT_LIST, list)),
    /**
     * よく使う検索条件を登録します。
     *
     * @param param 検索条件データ
     */
    [a.SAVE_BAGGAGE_SEARCH_CONDITION]: ({ commit, state }, containerIndex: number) => {
        // 既に保存中なら後続のアクションを無視する
        if (state.loadingSavedBaggageSearchConditions) {
            return;
        }

        // 保存件数の上限チェック
        if (state.searchConditionList.length >= Const.MAX_BAGGAGE_SEARCH_CONDITIONS) {
            return Promise.reject('REACHED_LIMIT');
        }

        // 保存対象の検索条件を取得
        const condition = state.baggageSearchContainers[containerIndex].condition;

        // 保存用Formに変換
        const registerForm = BaggageSearchConditionRegisterForm.fromCondition(condition);

        // 入力チェック
        if (!registerForm.isValid()) {
            return Promise.reject('MISSING_FIELDS');
        }

        // 処理中状態を更新
        commit(m.SET_LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS, true);

        // 保存APIの呼び出し
        return baggageApi.registerSearchCondition(registerForm)
            .then(async (insertedId) => {
                Karte.trackAddBaggageSearchCondition(insertedId);
                // リストの再読み込み
                await baggageApi.listSearchCondition().then((conditions) => {
                    commit(m.SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST, conditions);
                });
                // 追加された検索条件IDリスト
                return insertedId;
            })
            .catch(() => {
                return Promise.reject('FAILED_TO_SAVE');
            })
            .finally(() => {
                // 処理中状態を更新
                commit(m.SET_LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS, false);
            });
    },
    /**
     * よく使う検索条件を削除します。
     *
     * @param param 検索条件データ
     */
    [a.DELETE_BAGGAGE_SEARCH_CONDITION]: ({ commit }, id: number) =>
        baggageApi
            .deleteSearchCondition(id)
            .then(() => baggageApi.listSearchCondition())
            .then((list) => commit(m.SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST, list)),
    /**
     * よく使う検索条件のメール通知設定を変更します。
     *
     * @param param 検索条件データ
     */
    [a.UPDATE_BAGGAGE_SEARCH_CONDITION_NOTIFICATION]: (
        { commit },
        param: { id: number; form: BaggageSearchConditionNotificationUpdateForm }
    ) =>
        baggageApi
            .updateSearchConditionNotification(param.id, param.form)
            .then(() => baggageApi.listSearchCondition())
            .then((list) => commit(m.SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST, list)),
    /**
     * お気に入り登録します。
     *
     * @param id 荷物ID
     */
    [a.MARK_FAVORITE]: ({ commit, state }, id: number) =>
        baggageApi.markFavorite(id)
            // KARTEイベント送信： 荷物の保存
            .then(() => Karte.trackAddFavoriteBaggage(id))
            .finally(() => Promise.all([
                loadFavoriteCount(commit),
                queryFavorites(commit, state),
                queryFavoritesForContainers(commit, state),
            ])),
    /**
     * お気に入り登録を解除します。
     *
     * @param id 荷物ID
     */
    [a.UNMARK_FAVORITE]: ({ commit, state }, id: number) =>
        baggageApi.unmarkFavorite(id)
            // KARTEイベント送信： 荷物の保存解除
            .then(() => Karte.trackRemoveFavoriteBaggage(id))
            .finally(() => Promise.all([
                loadFavoriteCount(commit),
                queryFavorites(commit, state),
                queryFavoritesForContainers(commit, state),
            ])),
    /**
     * 終了した荷物のお気に入りを一括解除します。
     */
    [a.CLEAN_FAVORITE_BAGGAGES]: ({ commit, state }) =>
        baggageApi.cleanFavoriteBaggages()
            .finally(() => Promise.all([
                loadFavoriteCount(commit),
                queryFavorites(commit, state),
                queryFavoritesForContainers(commit, state),
            ])),
    /**
     * お気に入り状態を問い合わせます。
     *
     * @param ids 荷物IDリスト
     */
    [a.QUERY_FAVORITE]: ({ commit }, id: number) =>
        baggageApi
            .queryFavorite({ ids: [id] })
            .then((list) => commit(m.SET_MARKED_AS_FAVORITE_BAGGAGE, _.includes(list.ids, id))),
    /**
     * お気に入り荷物一覧をロードします。
     *
     * @param commit commitメソッド
     * @param form お気に入り荷物一覧取得フォーム
     */
    [a.LOAD_FAVORITE_BAGGAGE_LIST]: ({ commit, state }, form: FavoriteBaggageListForm) =>
        baggageApi.favoriteList(form)
            .then((list) => commit(m.SET_BAGGAGE_LIST, list))
            .then(() => queryFavorites(commit, state)),
    /**
     * 最近見た荷物一覧をロードします。
     */
    [a.LOAD_RECENT_BAGGAGE_READ_LIST]: ({ commit, state }, form: RecentBaggageListForm) =>
        baggageApi.recentList(form)
            .then((list) => commit(m.SET_BAGGAGE_LIST, list))
            .then(() => queryFavorites(commit, state)),
    /**
     * お気に入り荷物件数をロードします。
     */
    [a.LOAD_FAVORITE_BAGGAGE_COUNT]: ({ commit }) => loadFavoriteCount(commit),
    /**
     * 荷物検索の除外企業リストの監視を開始します。
     */
    [a.START_WATCHING_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST]: ({ commit }) => {
        window.addEventListener('storage', (event) => {
            if (event.key === 'baggageSearchExcludedCompanies') {
                commit(m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST, baggageSearchExcludedCompany.list());
            }
        });
    },
    /**
     * 荷物検索の除外企業リストをロードします
     */
    [a.LOAD_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST]: ({ commit }) => {
        commit(m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST, baggageSearchExcludedCompany.list());
    },
    /**
     * 荷物検索の除外企業リストに指定の企業を追加します。
     */
    [a.ADD_BAGGAGE_SEARCH_EXCLUDED_COMPANY]: ({ commit }, company: BaggageSearchExcludedCompany) => {
        baggageSearchExcludedCompany.add(company.id, company.name);
        commit(m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST, baggageSearchExcludedCompany.list());
        Karte.trackAddBaggageSearchExcludeCompany(company.id);
    },
    /**
     * 荷物検索の除外企業リストから指定の企業を削除します。
     */
    [a.REMOVE_BAGGAGE_SEARCH_EXCLUDED_COMPANY]: ({ commit }, id: number) => {
        baggageSearchExcludedCompany.remove(id);
        commit(m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST, baggageSearchExcludedCompany.list());
        Karte.trackRemoveBaggageSearchExcludeCompany(id);
    },
    /**
     * 商談中にします。
     *
     * @param id 荷物ID
     */
    [a.MARK_UNDER_NEGOTIATION]: ({ commit }, id: number) => {
        return baggageApi.markNegotiation(id).then(() => {
            // KARTEイベント送信： 商談中
            Karte.trackTurnOnUnderNegotiationFlg(id);
            // ステートを更新
            commit(m.SET_UNDER_NEGOTIATION_FLG, [id, true]);
        });
    },
    /**
     * 商談中を解除します。
     *
     * @param id 荷物ID
     */
    [a.UNMARK_UNDER_NEGOTIATION]: ({ commit }, id: number) => {
        return baggageApi.unmarkNegotiation(id).then(() => {
            // KARTEイベント送信： 商談中解除
            Karte.trackTurnOffUnderNegotiationFlg(id);
            // ステートを更新
            commit(m.SET_UNDER_NEGOTIATION_FLG, [id, false]);
        });
    },
    /**
     * 既読登録します。
     */
    [a.MARK_READ]: ({ commit, state }, id: number) => baggageApi.markRead(id)
        .finally(() => Promise.all([
            // 既読状態の再取得
            queryRead(commit, state),
            queryReadBaggagesForContainers(commit, state),
        ])),
    /**
     * 既読状態を取得します。
     */
    [a.QUERY_READ]: ({ commit, state }) => queryRead(commit, state),
    /**
     * 参考運賃（荷物登録用）を取得します。
     */
    [a.LOAD_BAGGAGE_REGISTER_REFERENCE_FREIGHT]: ({ commit }, form: BaggageFreightMasterQueryForm) =>
        baggageFreightMasterApi.query(form)
            .then((master) => commit(m.SET_BAGGAGE_REGISTER_REFERENCE_FREIGHT, master)),
    /**
     * 参考運賃（荷物登録用）をクリアします。
     */
    [a.CLEAR_BAGGAGE_REGISTER_REFERENCE_FREIGHT]: ({ commit }) =>
        commit(m.SET_BAGGAGE_REGISTER_REFERENCE_FREIGHT, undefined),
    /**
     * 参考運賃（荷物一覧用）を取得します。
     */
    [a.LOAD_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST]: ({ commit }, form: BaggageFreightMasterListForm) =>
        baggageFreightMasterApi.list(form)
            .then((list) => commit(m.SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST, list)),
    /**
     * 参考運賃（荷物一覧用）をクリアします。
     */
    [a.CLEAR_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST]: ({ commit }) =>
        commit(m.SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST, []),
    /**
     * 参考運賃（荷物詳細用）を取得します。
     */
    [a.LOAD_BAGGAGE_DETAIL_REFERENCE_FREIGHT]: ({ commit }, form: BaggageFreightMasterQueryForm) =>
        baggageFreightMasterApi.query(form)
            .then((master) => commit(m.SET_BAGGAGE_DETAIL_REFERENCE_FREIGHT, master)),
    /**
     * 参考運賃（荷物詳細用）をクリアします。
     */
    [a.CLEAR_BAGGAGE_DETAIL_REFERENCE_FREIGHT]: ({ commit }) =>
        commit(m.SET_BAGGAGE_DETAIL_REFERENCE_FREIGHT, undefined),

    /**
     * 参考運賃（最近見た荷物一覧用）を取得します。
     */
    [a.LOAD_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST]: ({ commit }, form: BaggageFreightMasterListForm) =>
        baggageFreightMasterApi.list(form)
            .then((list) => commit(m.SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST, list)),
    /**
     * 参考運賃（最近見た荷物一覧用）をクリアします。
     */
    [a.CLEAR_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST]: ({ commit }) =>
        commit(m.SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST, []),
    /**
     * 空車レコメンドを取得します。
     */
    [a.LOAD_TRUCK_RECOMMEND_LIST]: ({ commit, state }) => {
        // 読み込まれている荷物情報がない場合はスルー
        if (!state.baggage) return;
        // 荷物情報が公開中以外の場合はスルー
        if (state.baggage.status.code != BaggageStatusEnum.Opened.code) return;
        return truckApi.searchTruck(BaggageUtil.toTruckSearchForm(state.baggage))
            .then(async (list) => {
                const companyIds = _.uniq(list.data.map((each) => each.companyId));
                if (_.isEmpty(companyIds)) return list;
                const form: companyTypes.CompanyProfileListForm = { id: companyIds };
                const companies = await companyApi.listProfile(form);
                return {
                    ...list,
                    data: list.data.map((truck: truckTypes.Truck) => {
                        return _.merge({}, truck, { company: companies.find((each) => each.id === truck.companyId) });
                    })
                };
            })
            .then((list) => commit(m.SET_TRUCK_RECOMMEND_LIST, list));
    },
    /**
     * 空車レコメンドをクリアします。
     */
    [a.CLEAR_TRUCK_RECOMMEND_LIST]: ({ commit }) =>
        commit(m.SET_TRUCK_RECOMMEND_LIST, undefined),
    /**
     * おすすめ帰り便を取得します。
     */
    [a.LOAD_BAGGAGE_RECOMMENDATIONS]: ({ commit }, id: number) =>
        baggageApi.loadBaggageRecommendReturns(id)
            .then(async (recommendations) => {
                const companyIds = _.uniq(recommendations.map((recommendation) => recommendation.baggage.companyId));
                const profiles = companyIds.length > 0 ? await companyApi.listProfile({ id: companyIds }) : [];
                commit(m.SET_BAGGAGE_RECOMMENDATIONS, {
                    recommendations: recommendations,
                    companyProfiles: profiles
                });
            }),
};

function loadFavoriteCount(commit: Commit): Promise<void> {
    return baggageApi.favoriteCount().then((bean) => commit(m.SET_FAVORITE_BAGGAGE_COUNT, bean.count));
}

/**
 * お気に入り状態を問い合わせます。
 */
function queryFavorites(commit: Commit, state: BaggageState): Promise<void> {
    const ids = state.baggageList.data.map((baggage) => baggage.id);
    return baggageApi.queryFavorite({ ids: ids })
        .then((list) => commit(m.SET_FAVORITE_BAGGAGE_ID_LIST, list.ids));
}

/**
 * 荷物検索コンテナのお気に入り状態を問い合わせます。
 */
function queryFavoritesForContainers(commit: Commit, state: BaggageState): Promise<void> {
    // 荷物検索コンテナごとの荷物IDリスト
    const idsByContainer = state.baggageSearchContainers.map((container) => container.result?.baggages.map((baggage) => baggage.id) ?? []);
    // 全ての荷物検索コンテナのお気に入り荷物状態を更新する
    return Promise.all(
        idsByContainer.map((ids) => {
            return baggageApi.queryFavorite({ ids })
                .then((result) => result.ids);
        })
    )
        .then((result) => {
            commit(m.SET_FAVORITE_BAGGAGE_IDS, result);
        });
}

/**
 * 荷物の既読状態を問い合わせます。
 */
function queryRead(commit: Commit, state: BaggageState): Promise<void> {
    const ids = state.baggageList.data.map((baggage) => baggage.id);
    if (ids.length === 0) {
        return Promise.resolve();
    }
    return baggageApi.queryRead({ ids: ids })
        .then((list) => commit(m.SET_READ_BAGGAGE_ID_LIST, list.ids));
}

/**
 * 荷物検索コンテナの既読状態を問い合わせます。
 */
function queryReadBaggagesForContainers(commit: Commit, state: BaggageState): Promise<void> {
    // 荷物検索コンテナごとの荷物IDリスト
    const idsByContainer = state.baggageSearchContainers.map((container) => container.result?.baggages.map((baggage) => baggage.id) ?? []);
    // 全ての荷物検索コンテナのお気に入り荷物状態を更新する
    return Promise.all(
        idsByContainer.map((ids) => {
            return baggageApi.queryRead({ ids })
                .then((result) => result.ids);
        })
    )
        .then((result) => {
            commit(m.SET_READ_BAGGAGE_IDS, result);
        });
}

function searchBaggage(commit: Commit, containerIndex: number, condition: BaggageSearchForm): Promise<void> {
    // ローディング状態を更新する
    commit(m.SET_BAGGAGE_SEARCHING, [containerIndex, true]);

    const createFreightForm = (baggage: Baggage): BaggageFreightMasterQueryForm | undefined => {
        if (baggage.freight) return;
        return BaggageFreightMasterQueryForm.newInstance(baggage);
    };

    // 荷物検索
    return baggageApi.search(condition)
        .then(async (baggageList) => {
            // 関連データのロードする
            const baggageIds = _.uniq(baggageList.data.map((item) => item.id));
            const companyIds = _.uniq(baggageList.data.map((item) => item.companyId));
            const freightForms = _.uniqWith(_.compact(baggageList.data.map(createFreightForm)), _.isEqual);

            const baggageRecommendReturnsCountsPromise = baggageIds.length > 0 ? (baggageApi.baggageRecommendReturnsCounts({ ids: baggageIds })
                // 無言で失敗する
                .catch(() => Promise.resolve([]))) : [];

            const [profiles, favoriteBaggageIds, readBaggageIds, referenceFreights, baggageRecommendReturnsCounts] = await Promise.all([
                // 企業プロフィール
                companyIds.length > 0 ? companyApi.listProfile({ id: companyIds }) : [],
                // お気に入りに登録してある荷物ID
                baggageIds.length > 0 ? baggageApi.queryFavorite({ ids: baggageIds }).then((result) => result.ids) : [],
                // 既読の荷物ID
                baggageIds.length > 0 ? baggageApi.queryRead({ ids: baggageIds }).then((result) => result.ids) : [],
                // 参考運賃
                freightForms.length > 0 ? baggageFreightMasterApi.list({ conditions: freightForms }) : [],
                // おすすめ帰り便の数
                baggageRecommendReturnsCountsPromise
            ]);

            const now = dayjs();
            // 検索日時をローカルストレージへ保存する
            lastBaggageSearchDatetimes.set(containerIndex, now);

            // 検索結果をストアへ保存する
            const result = new BaggageSearchResult(
                now,
                _.cloneDeep(condition),
                baggageList,
                profiles,
                favoriteBaggageIds,
                readBaggageIds,
                referenceFreights,
                baggageRecommendReturnsCounts
            );
            commit(m.SET_BAGGAGE_SEARCH_RESULT, [containerIndex, result, condition]);
        })
        .finally(() => {
            // ローディング状態を更新
            commit(m.SET_BAGGAGE_SEARCHING, [containerIndex, false]);
        });
}

function defaultBaggageSearchContainer(): BaggageSearchContainer {
    return {
        searching: false,
        condition: _.cloneDeep(defaultBaggageSearchForm),
        autoSearchIntervalId: undefined,
        lastSearchDatetime: undefined,
        result: undefined,
    };
}

function restoreBaggageSearchContainers(): BaggageSearchContainer[] {
    const conditions = baggageSearchConditions.get();
    if (conditions === undefined) {
        return [];
    }
    const lastSearchDatetimes = lastBaggageSearchDatetimes.get();
    return conditions.map((condition, index) => {
        return {
            searching: false,
            condition: { ...condition, pageNo: 1 },
            autoSearchIntervalId: undefined,
            lastSearchDatetime: lastSearchDatetimes[index],
            result: undefined
        };
    });
}

function saveBaggageSearchConditions(conditions: BaggageSearchForm[]): void {
    baggageSearchConditions.set(conditions);
}

const getters: GetterTree<BaggageState, void> = {
    /**
     * 荷物検索コンテナを取得します。
     */
    [g.SEARCH_CONTAINERS]: (s) => s.baggageSearchContainers,
    /**
     * よく使う検索条件の処理中状態を取得します。
     */
    [g.LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS]: (s) => s.loadingSavedBaggageSearchConditions,
    /**
     * 荷物検索ページ用の荷物詳細コンテナを設定します。
     */
    [g.SEARCH_BAGGAGE_DETAIL_CONTAINER]: (s) => s.baggageSearchDetailContainer,
    /**
     * 荷物を取得します。
     */
    [g.BAGGAGE]: (s) => s.baggage,
    /**
     * 検索結果を取得します。
     */
    [g.BAGGAGE_LIST]: (s) => s.baggageList,
    /**
     * よく使う検索条件を取得します。
     */
    [g.BAGGAGE_FORM_SEARCH_CONDITION_LIST]: (s) => s.searchConditionList,
    /**
     * お気に入り登録済みか否かを取得します。
     */
    [g.MARKED_AS_FAVORITE_BAGGAGE]: (s) => s.markedAsFavoriteBaggage,
    /**
     * お気に入り登録済み荷物IDを取得します。
     */
    [g.FAVORITE_BAGGAGE_ID_LIST]: (s) => s.favoriteBaggageIdList,
    /**
     * お気に入り荷物件数を取得します。
     */
    [g.FAVORITE_BAGGAGE_COUNT]: (s) => s.favoriteBaggageCount,
    /**
     * 荷物詳細の閲覧会員数を取得します。
     */
    [g.BAGGAGE_VIEW_MEMBER_COUNT_LIST]: (s) => s.baggageViewMemberCount,
    /**
     * 荷物検索除外企業一覧を取得します。
     */
    [g.BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST]: (s) => s.baggageSearchExcludedCompanyList,
    /**
     * 既読荷物IDを取得します。
     */
    [g.READ_BAGGAGE_ID_LIST]: (s) => s.readBaggageIdList,
    /**
     * 参考運賃（荷物登録用）を取得します。
     */
    [g.BAGGAGE_REGISTER_REFERENCE_FREIGHT]: (s) => s.baggageRegisterReferenceFreight,
    /**
     * 参考運賃（荷物一覧用）を取得します。
     */
    [g.FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST]: (s) => s.favoriteBaggageReferenceFreightList,
    /**
     * 参考運賃（荷物詳細用）を取得します。
     */
    [g.BAGGAGE_DETAIL_REFERENCE_FREIGHT]: (s) => s.baggageDetailReferenceFreight,
    /**
     * 空車レコメンドを取得します。
     */
    [g.TRUCK_RECOMMEND_LIST]: (s) => s.truckRecommends,
    /**
     * 帰り便のレコメンドを取得します。
     */
    [g.BAGGAGE_RECOMMENDATIONS]: (s) => s.baggageRecommendations,
};

const mutations: MutationTree<BaggageState> = {
    /**
     * 荷物検索コンテナを設定します。
     * @param containers 荷物検索コンテナのリスト
     */
    [m.SET_SEARCH_CONTAINERS]: (s, containers: BaggageSearchContainer[]) => (s.baggageSearchContainers = containers),
    /**
     * 荷物検索条件を設定します。
     * @param containerIndex 荷物検索コンテナのインデックス
     * @param condition 荷物検索条件
     */
    [m.SET_BAGGAGE_SEARCH_FORM]: (s, [containerIndex, condition]: [number, BaggageSearchForm]) => (s.baggageSearchContainers[containerIndex].condition = condition),
    /**
     * 荷物検索の処理中状態を設定します。
     * @param containerIndex 荷物検索コンテナのインデックス
     * @param searching 処理中か否か
     */
    [m.SET_BAGGAGE_SEARCHING]: (s, [containerIndex, searching]: [number, boolean]) => (s.baggageSearchContainers[containerIndex].searching = searching),
    /**
     * 荷物検索結果を設定します。
     * @param containerIndex 荷物検索コンテナのインデックス
     * @param result 検索結果
     * @param condition 検索条件
     */
    [m.SET_BAGGAGE_SEARCH_RESULT]: (s, [containerIndex, result, condition]: [number, BaggageSearchResult, BaggageSearchForm]) => {
        const lastResult = s.baggageSearchContainers[containerIndex].result;
        if (lastResult !== undefined) {
            s.baggageSearchContainers[containerIndex].lastSearchDatetime = lastResult.datetime;
        }
        s.baggageSearchContainers[containerIndex].result = result;
        s.baggageSearchContainers[containerIndex].condition = condition;
    },
    /**
     * よく使う検索条件の処理中状態を設定します。
     * @param saving 処理中か否か
     */
    [m.SET_LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS]: (s, saving: boolean) => (s.loadingSavedBaggageSearchConditions = saving),
    /**
     * お気に入り荷物IDを設定します。
     * @param idsByContainer 荷物検索コンテナごとのお気に入り荷物IDリスト
     */
    [m.SET_FAVORITE_BAGGAGE_IDS]: (s, idsByContainer: number[][]) => {
        idsByContainer.forEach((ids, index) => {
            s.baggageSearchContainers[index].result = s.baggageSearchContainers[index].result?.withFavoriteBaggageIds(ids);
        });
    },
    /**
     * 商談中状態を設定します。
     * @param s
     * @param baggageId
     * @param value
     */
    [m.SET_UNDER_NEGOTIATION_FLG]: (s, [baggageId, value]: [number, boolean]) => {
        s.baggageSearchContainers.forEach((container) => {
            container.result?.baggageList.data.forEach((baggage) => {
                if (baggage.id === baggageId) {
                    baggage.underNegotiation = value;
                }
            });
        });
        if (s.baggageSearchDetailContainer !== undefined && s.baggageSearchDetailContainer.baggage.id === baggageId) {
            s.baggageSearchDetailContainer.baggage.underNegotiation = value;
        }
        s.baggageList.data.forEach((baggage) => {
            if (baggage.id === baggageId) {
                baggage.underNegotiation = value;
            }
        });
        if (s.baggage?.id === baggageId) {
            s.baggage.underNegotiation = value;
        }
    },
    /**
     * 荷物検索ページ用の荷物詳細コンテナを設定します。
     * @param container 荷物詳細コンテナ
     */
    [m.SET_SEARCH_BAGGAGE_DETAIL_CONTAINER]: (s, container: BaggageDetailContainer | undefined) => {
        s.baggageSearchDetailContainer = container;
    },
    /**
     *
     * @param containerIndex 荷物検索コンテナのインデックス
     * @param id タイマー停止用ID
     */
    [m.SET_BAGGAGE_SEARCH_INTERVAL_ID]: (s, [containerIndex, id]: [number, number | undefined]) => {
        s.baggageSearchContainers[containerIndex].autoSearchIntervalId = id;
    },
    /**
     * 荷物を設定します。
     *
     * @param baggage 荷物
     */
    [m.SET_BAGGAGE]: (s, baggage?: Baggage) => (s.baggage = baggage),
    /**
     * 検索結果を設定します。
     *
     * @param list 検索結果の設定
     */
    [m.SET_BAGGAGE_LIST]: (s, list: BaggageList) => (s.baggageList = list),
    /**
     * 荷物詳細の閲覧会員数を設定します。
     *
     * @param list 閲覧人数の設定
     */
    [m.SET_BAGGAGE_VIEW_MEMBER_COUNT_LIST]: (s, list: Array<BaggageViewMemberCount>) => (s.baggageViewMemberCount = list),
    /**
     * よく使う検索条件一覧を設定します。
     *
     * @param list よく使う検索条件一覧
     */
    [m.SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST]: (s, list: Array<BaggageSearchCondition>) =>
        (s.searchConditionList = list),
    /**
     * お気に入り登録済み状態を設定します。
     */
    [m.SET_MARKED_AS_FAVORITE_BAGGAGE]: (s, marked: boolean) => (s.markedAsFavoriteBaggage = marked),
    /**
     * お気に入り登録済み荷物IDを設定します。
     *
     * @param ids 荷物IDリスト
     */
    [m.SET_FAVORITE_BAGGAGE_ID_LIST]: (s, ids: number[]) => (s.favoriteBaggageIdList = ids),
    /**
     * お気に入り荷物件数を設定します。
     *
     * @param count 件数
     */
    [m.SET_FAVORITE_BAGGAGE_COUNT]: (s, count: number) => (s.favoriteBaggageCount = count),
    /**
     * 荷物検索除外企業一覧を保存します。
     */
    [m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST]: (s, excludedCompanies: BaggageSearchExcludedCompany[]) => (s.baggageSearchExcludedCompanyList = excludedCompanies),
    /**
     * 既読荷物IDを設定します。
     * @param idsByContainer 荷物検索コンテナごとの既読荷物IDリスト
     */
    [m.SET_READ_BAGGAGE_IDS]: (s, idsByContainer: number[][]) => {
        idsByContainer.forEach((ids, index) => {
            s.baggageSearchContainers[index].result = s.baggageSearchContainers[index].result?.withReadBaggageIds(ids);
        });
    },
    /**
     * 既読荷物IDを設定します。
     *
     * @param ids 荷物IDリスト
     */
    [m.SET_READ_BAGGAGE_ID_LIST]: (s, ids: number[]) => (s.readBaggageIdList = ids),
    /**
     * 参考運賃（荷物登録用）を設定します。
     */
    [m.SET_BAGGAGE_REGISTER_REFERENCE_FREIGHT]: (s, param) => (s.baggageRegisterReferenceFreight = param),
    /**
     * 参考運賃（荷物一覧用）を設定します。
     */
    [m.SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST]: (s, list) => (s.favoriteBaggageReferenceFreightList = list),
    /**
     * 参考運賃（最近見た荷物一覧用）を設定します。
     */
    [m.SET_RECENT_BAGGAGE_READ_REFERENCE_FREIGHT_LIST]: (s, list) => (s.recentBaggageReferenceFreightList = list),
    /**
     * 参考運賃（荷物詳細用）を設定します。
     */
    [m.SET_BAGGAGE_DETAIL_REFERENCE_FREIGHT]: (s, param) => (s.baggageDetailReferenceFreight = param),
    /**
     * 空車レコメンドを設定します。
     */
    [m.SET_TRUCK_RECOMMEND_LIST]: (s, param) => (s.truckRecommends = param),
    /**
     * 帰り便のレコメンドを設定します。
     */
    [m.SET_BAGGAGE_RECOMMENDATIONS]: (s, list) => (s.baggageRecommendations = list),
};

export const baggage: Module<BaggageState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
