import axios, { CancelTokenSource } from 'axios';
import {
    BaggageFreightMaster,
    BaggageFreightMasterListForm,
    BaggageFreightMasterQueryForm
} from '@/vuex/modules/baggage/types';
import { appAxios, isSuccess, JsonResponseBean, wrapClass } from './base';

export const baggageFreightMasterApi = {
    _cancelTokens: new Map<string, CancelTokenSource>(),
    /**
     * 荷物運賃を取得します。
     */
    query(form: BaggageFreightMasterQueryForm): Promise<BaggageFreightMaster> {
        this._cancelTokens.get('query')?.cancel();

        const source = axios.CancelToken.source();
        this._cancelTokens.set('query', source);

        return appAxios.post<JsonResponseBean<BaggageFreightMaster>>('/api/freight/baggage/master', form, { cancelToken: source.token })
            .then(isSuccess)
            .then(item => item as BaggageFreightMaster)
            .then(item => wrapClass(BaggageFreightMaster, item));
    },
    /**
     * 荷物運賃一覧を取得します。
     */
    list(form: BaggageFreightMasterListForm): Promise<BaggageFreightMaster[]> {
        this._cancelTokens.get('list')?.cancel();

        const source = axios.CancelToken.source();
        this._cancelTokens.set('list', source);

        return appAxios.post<JsonResponseBean<BaggageFreightMaster[]>>('/api/freight/baggage/master/list', form, { cancelToken: source.token })
            .then(isSuccess)
            .then(item => item as BaggageFreightMaster[])
            .then(item => item.map(each => wrapClass(BaggageFreightMaster, each)));
    }
};
