import _router from '@/router';
import VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { Dictionary, Route, Route as VueRoute, Location as VueLocation } from 'vue-router/types/router';
import _ from 'lodash';
import { DetailProps as OpenedMyBaggageDetailProps } from '@/_pages/Baggage/List/tabs/Opened/props';
import { PreloadProps as PreloadSettlementIncomeListProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/props';
import { PreloadProps as PreloadSettlementOutgoListProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/props';

export type GoToOpenedMyBaggageDetailOptions = Partial<Omit<OpenedMyBaggageDetailProps, 'baggageId'>>;

const tryUseRouter = (): VueRouter => {
    try {
        return useRouter();
    } catch {
        return _router;
    }
};

export const useRouting = () => {
    const router = tryUseRouter();

    /**
     * 一つ前の画面に戻ります
     */
    const goBack = (): void =>
        router.back();

    const openCompanyPrintMe = (): void => {
        const route = router.resolve({ name: 'CompanyPrintMe' });
        window.open(route.href, '_blank');
    };

    const openCompanyPrint = (companyId: number): void => {
        const route = router.resolve({ name: 'CompanyPrint', params: { companyId: `${ companyId }` } });
        window.open(route.href, '_blank');
    };

    const goToByName = (name: string): Promise<VueRoute> =>
        router.push({ name: name });

    /**
     * ログイン画面に遷移します。
     */
    const goToLogin = (): Promise<VueRoute> => goToByName('Login');

    /**
     * ログアウト画面に来ます。
     */
    const goToLogout = (): Promise<VueRoute> =>
        router.push({ name: 'Logout' });

    /**
     * 荷物登録画面へ遷移します
     */
    const goToBaggageRegister = (sourceBaggageId: number | undefined = undefined): Promise<VueRoute> => {
        const query = sourceBaggageId !== undefined ? { sourceBaggageId: `${ sourceBaggageId }` } : undefined;
        return router.push({ name: 'BaggageRegister', query });
    };

    /**
     * マイ荷物画面に遷移します。
     */
    const goToBaggageList = (): Promise<VueRoute> => goToByName('BaggageList');

    /**
     * マイ荷物／成約しなかった荷物に遷移します。
     */
    const goToCanceledBaggageList = (): Promise<VueRoute> => goToByName('BaggageExpiredList');

    /**
     * マイ荷物／募集中／荷物詳細に遷移します。
     */
    const goToOpenedBaggageDetail = (
        baggageId: number,
        options: GoToOpenedMyBaggageDetailOptions | undefined = undefined,
    ): Promise<VueRoute> => {
        const query: VueLocation['query'] = {
            baggageId: `${ baggageId }`,
            // 編集モードか？
            edit: options?.edit ? 'true' : undefined,
            // 荷物登録からの遷移か？
            registered: options?.registered ? 'true' : undefined,
            // 空車レコメンド／企業詳細を表示するか
            companyId: options?.truckCompany?.companyId ? `${ options.truckCompany.companyId }` : undefined,
            truckId: options?.truckCompany?.truckId ? `${ options.truckCompany.truckId }` : undefined,
            // 商談リクエスト／企業詳細を表示するか
            negotiationCompanyId: options?.negotiationCompany?.companyId ? `${ options.negotiationCompany.companyId }` : undefined,
            negotiationId: options?.negotiationCompany?.negotiationId ? `${ options.negotiationCompany.negotiationId }` : undefined,
        };

        return router.push({ name: 'BaggageList', query });
    };

    /**
     * マイ荷物／成約しなかった荷物／荷物詳細に遷移します。
     */
    const goToCanceledBaggageDetail = (
        baggageId: number
    ): Promise<VueRoute> => {
        const query: VueLocation['query'] = {
            baggageId: `${ baggageId }`
        };

        return router.push({ name: 'BaggageExpiredList', query });
    };

    /**
     * 自社荷物の印刷画面へ遷移します。
     */
    const openMyBaggagePrint = (baggageId: number): void => {
        const route = router.resolve({ name: 'BaggagePrint', params: { baggageId: `${ baggageId }` } });
        window.open(route.href, '_blank');
    };

    /**
     * 請求・支払＞ホーム画面へ遷移します。
     */
    const goToSettlementHome = () => goToByName('SettlementHome');

    /**
     * 請求・支払＞入金取引画面へ遷移します。
     */
    const goToSettlementIncomeList = (option?: { preload?: PreloadSettlementIncomeListProps }): Promise<VueRoute> => {
        const query: VueLocation['query'] = {};
        if (option?.preload) {
            query.payeeDate = option.preload.payeeDate?.format();
            query.proxy = option.preload.proxy ? 'true' : undefined;
        }
        return router.push({ name: 'SettlementIncomeList', query });
    };

    /**
     * 請求・支払＞入金取引詳細画面に遷移します。
     */
    const goToSettlementIncomeDetail = (settlementId: number): Promise<VueRoute> => {
        const query: VueLocation['query'] = { settlementId: `${ settlementId }` };

        return router.push({ name: 'SettlementIncomeList', query });
    };

    /**
     * 請求・支払＞入金取引詳細＞変更画面に遷移します。
     */
    const goToSettlementIncomeEditor = (settlementId: number): Promise<VueRoute> => {
        const query: VueLocation['query'] = { settlementId: `${ settlementId }`, edit: 'true' };

        return router.push({ name: 'SettlementIncomeList', query });
    };

    /**
     * 請求・支払＞出金取引画面へ遷移します。
     */
    const goToSettlementOutgoList = (option?: { preload?: PreloadSettlementOutgoListProps }): Promise<VueRoute> => {
        const query: VueLocation['query'] = {};
        if (option?.preload) {
            query.payerDate = option.preload.payerDate?.format();
            query.proxy = option.preload.proxy ? 'true' : undefined;
        }
        return router.push({ name: 'SettlementOutgoList', query });
    };

    /**
     * 請求・支払＞出勤取引詳細画面に遷移します。
     */
    const goToSettlementOutgoDetail = (settlementId: number): Promise<VueRoute> => {
        const query: VueLocation['query'] = { settlementId: `${ settlementId }` };

        return router.push({ name: 'SettlementOutgoList', query });
    };

    /**
     * 請求・支払＞請求書・支払通知書画面へ遷移します。
     */
    const goToSettlementPaper = () => goToByName('SettlementPaper');

    /**
     * 請求・支払＞請求書・支払通知書＞支払通知書画面へ遷移します。
     */
    const goToSettlementIncomePaper = () => goToByName('SettlementIncomePaper');

    /**
     * 請求・支払＞請求書・支払通知書＞請求書画面へ遷移します。
     */
    const goToTransactionInvoice = () => goToByName('SettlementOutgoPaper');

    /**
     * 請求・支払＞取引追加へ遷移します。
     */
    const goToSettlementIncomeRegister = () => goToByName('SettlementIncomeRegister');

    /**
     * アカウント登録完了画面へ遷移します。
     */
    const goToAccountComplete = (view?: 'embed'): Promise<VueRoute> => router.push({
        name: 'AccountComplete',
        query: view ? { view } : {}
    });
    /**
     *
     */
    const goToAccountPasswordRegister = (verificationCode: string): Promise<VueRoute> => router.push({
        name: 'AccountPasswordRegister',
        query: { t: verificationCode }
    });
    /**
     * パスワード再設定画面へ遷移します。
     */
    const goToPasswordRemind = (): Promise<VueRoute> => goToByName('AccountPasswordRemind');

    /**
     * パスワード再設定メール送信完了画面へ遷移します。
     */
    const goToAccountPasswordRemindComplete = (): Promise<VueRoute> => goToByName('AccountPasswordRemindComplete');

    /**
     * パスワード再設定完了画面へ遷移します。
     */
    const goToAccountPasswordResetComplete = (): Promise<VueRoute> => goToByName('AccountPasswordResetComplete');
    /**
     * 転生完了画面に遷移します。
     */
    const goToRenewalComplete = (): Promise<VueRoute> => goToByName('AccountRenewalComplete');

    /**
     * 荷物検索画面に遷移します。現在の画面が荷物検索の場合、何もしません。
     */
    const goToBaggageSearch = (baggageId?: number): Promise<VueRoute> => {
        const query = _.isNil(baggageId) ? undefined : { baggageId: `${ baggageId }` };
        return router.push({ name: 'BaggageSearch', query: query });
    };

    /**
     * 空車検索画面に遷移します。現在の画面が荷物検索の場合、何もしません。
     */
    const goToTruckSearch = (): Promise<VueRoute | void> =>
        router.push({ name: 'TruckSearch' });

    /**
     * 最近見た荷物画面に遷移します。
     * @param baggageId
     */
    const goToBaggageRecentDetail = (baggageId?: number): Promise<VueRoute> =>
        router.push({ name: 'BaggageRecent', query: { baggageId: `${ baggageId }` } });

    /**
     * 成約登録画面に遷移します
     * @param baggageId
     */
    const goToAgreementRegister = (baggageId: number): Promise<VueRoute> =>
        router.push({ name: 'AgreementRegister', params: { id: `${ baggageId }` } });

    /**
     * 荷物詳細印刷画面に遷移します。
     * @param baggageId
     */
    const openBaggagePrint = (baggageId: number): void => {
        const route = router.resolve({ name: 'BaggageSearchPrint', params: { baggageId: `${ baggageId }` } });
        window.open(route.href, '_blank');
    };

    /**
     * 自社荷物の成約画面に遷移します。
     * @param agreementId
     */
    const goToAgreementList = (agreementId: number): Promise<VueRoute> =>
        router.push({ name: 'AgreementList', query: { agreementId: `${ agreementId }` } });

    /**
     * 受託荷物の成約画面に遷移します。
     * @param agreementId
     */
    const goToAgreementAcceptedList = (agreementId: number): Promise<VueRoute> =>
        router.push({ name: 'AgreementAcceptedList', query: { agreementId: `${ agreementId }` } });

    /**
     * 自社荷物の成約画面に遷移します。(別Window)
     * @param agreementId
     */
    const openAgreementList = (agreementId: number): void => {
        const route = router.resolve({ name: 'AgreementList', query: { agreementId: `${ agreementId }` } });
        window.open(route.href, '_blank');
    };

    /**
     * 受託荷物の成約画面に遷移します。(別Window)
     * @param agreementId
     */
    const openAgreementAcceptedList = (agreementId: number): void => {
        const route = router.resolve({ name: 'AgreementAcceptedList', query: { agreementId: `${ agreementId }` } });
        window.open(route.href, '_blank');
    };

    /**
     * 企業詳細ページに遷移します。
     * @param companyId
     */
    const goToCompanyDetail = (companyId: number): Promise<VueRoute> =>
        router.push({ name: 'CompanySearch', query: { companyId: `${ companyId }` } });

    /**
     * 設定画面へ遷移します。
     */
    const goToSettingCompanyProfile = (): Promise<VueRoute | void> => {
        if (router.currentRoute.name === 'SettingCompanyProfile') {
            return Promise.resolve();
        }
        return router.push({ name: 'SettingCompanyProfile' });
    };

    /**
     * 設定>企業情報管理（詳細情報）へ遷移します。
     */
    const goToSettingCompanyProfileExtra = () => goToByName('SettingCompanyProfileExtra');

    /**
     *
     */
    const goToSettingEmailBaggageAndTruck = (): Promise<VueRoute | void> => {
        return router.push({ name: 'SettingEmailBaggageAndTruck' });
    };

    /**
     * プレミアムプラン停止画面へ遷移します。
     */
    const goToRevokePremiumPlan = (): Promise<Route> => router.push({ name: 'RevokePremiumPlan' });

    /**
     * プレミアムプラン停止編集画面へ遷移します。
     */
    const goToRevokePremiumPlanEdit = (): Promise<Route> => router.push({ name: 'RevokePremiumPlanEdit' });

    /**
     * プレミアムプラン停止完了画面へ遷移します。
     */
    const goToRevokePremiumPlanComplete = (): Promise<Route> => router.push({ name: 'RevokePremiumPlanComplete' });

    /**
     * 退会画面へ遷移します。
     */
    const goToUnsubscribe = (): Promise<Route> => router.push({ name: 'Unsubscribe' });

    /**
     * 退会完了画面へ遷移します。
     */
    const goToUnsubscribeComplete = (): Promise<Route> => router.push({ name: 'UnsubscribeComplete' });

    /**
     * 登録した空車画面へ遷移します。
     */
    const goToTruckList = (): Promise<Route> => router.push({ name: 'TruckList' });

    /**
     * 空車登録画面へ遷移します。
     */
    const goToTruckRegister = (sourceTruckId?: number, companyTruckId?: number, agreementId?: number): Promise<Route> => {
        const query = (sourceTruckId?: number, companyTruckId?: number, agreementId?: number) => {
            if (sourceTruckId) {
                return { sourceTruckId: `${ sourceTruckId }` };
            }
            if (companyTruckId) {
                return { companyTruckId: `${ companyTruckId }` };
            }
            if (agreementId) {
                return { sourceAgreementId: `${ agreementId }` };
            }
            return {};
        };

        return router.push({
            name: 'TruckRegister',
            query: query(sourceTruckId, companyTruckId, agreementId),
        });
    };

    /**
     * 空車編集画面へ遷移します。
     */
    const goToTruckEdit = (id: number): Promise<Route> =>
        router.push({ name: 'TruckEdit', params: { truckId: `${ id }` } });

    /**
     * 車両マスタ画面へ遷移します。
     */
    const goToCompanyTruckList = (): Promise<Route> => router.push({ name: 'CompanyTruckList' });

    /**
     * 車両マスタ登録画面へ遷移します。
     */
    const goToCompanyTruckRegister = (sourceCompanyTruckId?: number): Promise<Route> => {
        return router.push({
            name: 'CompanyTruckRegister',
            query: sourceCompanyTruckId ? { sourceCompanyTruckId: `${ sourceCompanyTruckId }` } : {},
        });
    };

    /**
     * 車両マスタ編集画面へ遷移します。
     */
    const goToCompanyTruckEdit = (id: number): Promise<Route> =>
        router.push({ name: 'CompanyTruckEdit', params: { companyTruckId: `${ id }` } });

    /**
     * 空車表画面へ遷移します。
     */
    const goToTruckScheduler = (eventId?: number): Promise<Route> =>
        router.push({
            name: 'TruckScheduler',
            query: eventId ? { eventId: `${ eventId }` } : {},
        });

    /**
     * 地点マスタ画面へ遷移します。
     */
    const goToCompanyPlaceList = (): Promise<Route> => router.push({ name: '_SettingCompanyPlaceList' });

    /**
     * 地点マスタ登録画面へ遷移します。
     */
    const goToCompanyPlaceRegister = (sourceCompanyPlaceId?: number): Promise<Route> => {
        return router.push({
            name: '_SettingCompanyPlaceRegister',
            query: sourceCompanyPlaceId ? { sourceCompanyPlaceId: `${ sourceCompanyPlaceId }` } : {},
        });
    };

    /**
     * 地点マスタ編集画面へ遷移します。
     */
    const goToCompanyPlaceEdit = (id: number): Promise<Route> =>
        router.push({ name: '_SettingCompanyPlaceEdit', params: { companyPlaceId: `${ id }` } });

    /**
     * おすすめ帰り便ページを新規で開きます
     */
    const openBaggageRecommendReturns = (baggageId: number): Window | null => {
        const route = router.resolve({ name: 'BaggageRecommendReturns', params: { baggageId: baggageId.toString() } });
        return openNewPage(route.href);
    };

    /**
     * 配送依頼書のプレビューを新規で開きます
     */
    const openDeliveryOrderPreview = (agreementId: number): Window | null => {
        const route = router.resolve({ name: 'DeliveryOrderPreview', params: { agreementId: agreementId.toString() } });
        return openNewPage(route.href);
    };

    /**
     * 配送依頼書の印刷画面へ遷移します。
     */
    const goToDeliveryOrderPrint = (deliveryOrderId: number): Promise<Route> =>
        router.push({ name: 'DeliveryOrderPrint', params: { deliveryOrderId: `${ deliveryOrderId }` } });


    /**
     * 配送依頼書の編集画面へ遷移します。
     */
    const goToDeliveryOrderEdit = (deliveryOrderId: number): Promise<Route> =>
        router.push({ name: 'DeliveryOrderEdit', params: { deliveryOrderId: `${ deliveryOrderId }` } });

    /**
     * 配送依頼書の車番返信画面へ遷移します。
     */
    const goToDeliveryOrderReply = (deliveryOrderId: number): Promise<Route> =>
        router.push({ name: 'DeliveryOrderReply', params: { deliveryOrderId: `${ deliveryOrderId }` } });

    /**
     * 便利サービストップへ遷移します。
     */
    const goToMiscTop = () => router.push({ name: 'MiscService' });

    /**
     * 指定したURLを_blankで開きます
     */
    const openNewPage = (url: string): Window | null =>
        window.open(url, '_blank');

    /**
     * 同じ画面内でクエリーの変更をします。
     * @param param
     */
    const replaceQuery = (param: Dictionary<string>): Promise<VueRoute> =>
        router.push({ query: param });

    /**
     * 404ページに遷移します。
     */
    const goToNotFound = (): Promise<VueRoute> =>
        router.push({ name: 'NotFound' });

    return {
        goBack,
        openCompanyPrintMe,
        openCompanyPrint,
        goToByName,
        goToBaggageSearch,
        goToTruckSearch,
        goToBaggageRecentDetail,
        goToAgreementRegister,
        openBaggagePrint,
        goToCompanyDetail,
        goToSettingCompanyProfile,
        goToSettingCompanyProfileExtra,
        goToSettingEmailBaggageAndTruck,
        goToAgreementList,
        goToAgreementAcceptedList,
        openAgreementList,
        openAgreementAcceptedList,
        goToRevokePremiumPlan,
        goToRevokePremiumPlanEdit,
        goToRevokePremiumPlanComplete,
        goToUnsubscribe,
        goToUnsubscribeComplete,
        goToTruckList,
        goToTruckRegister,
        goToTruckEdit,
        goToCompanyTruckList,
        goToCompanyTruckRegister,
        goToCompanyTruckEdit,
        goToTruckScheduler,
        goToCompanyPlaceList,
        goToCompanyPlaceRegister,
        goToCompanyPlaceEdit,
        openBaggageRecommendReturns,
        goToMiscTop,
        replaceQuery,
        openNewPage,
        goToNotFound,
        goToLogin,
        goToLogout,
        goToBaggageRegister,
        goToBaggageList,
        goToCanceledBaggageList,
        goToOpenedBaggageDetail,
        goToCanceledBaggageDetail,
        openMyBaggagePrint,
        goToSettlementHome,
        goToSettlementIncomeList,
        goToSettlementIncomeDetail,
        goToSettlementIncomeEditor,
        goToSettlementOutgoList,
        goToSettlementOutgoDetail,
        goToSettlementPaper,
        goToSettlementIncomePaper,
        goToTransactionInvoice,
        goToSettlementIncomeRegister,
        goToAccountComplete,
        goToAccountPasswordRegister,
        goToPasswordRemind,
        goToAccountPasswordRemindComplete,
        goToAccountPasswordResetComplete,
        goToRenewalComplete,
        openDeliveryOrderPreview,
        goToDeliveryOrderPrint,
        goToDeliveryOrderEdit,
        goToDeliveryOrderReply,
    };
};
