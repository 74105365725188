import { ref } from 'vue';
import { createGlobalState } from '@vueuse/core';
import { CompanyContractModel } from '@/models/company-contract';
import { CompanyContractListModel } from '@/models/company-contract-list';
import { companyApi } from '@/repository/api/internal/company';
import { useVuexSync } from '@/composables/helper/vuex';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/company/types';

/**
 * 企業契約一覧を取得する機能を提供します。
 */
export const useCompanyContracts = createGlobalState(() => {
    const contracts = ref<CompanyContractListModel | undefined>(undefined);
    useVuexSync(
        contracts,
        {
            module: 'company',
            mutation: m.SET_CONTRACT_LIST_MODEL,
            getter: g.CONTRACT_LIST,
        },
        (list?: CompanyContractListModel): CompanyContractModel[] => list?.contracts ?? [],
    );

    const load = async (): Promise<CompanyContractListModel> => {
        const data = await companyApi.contract();
        contracts.value = new CompanyContractListModel(data);

        return contracts.value;
    };

    const loadIfNotExists = async (): Promise<CompanyContractListModel> => {
        if (contracts.value === undefined) {
            return load();
        }
        return contracts.value;
    };

    return {
        state: {
            contracts,
        },
        load,
        loadIfNotExists,
    };
});
