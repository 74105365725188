import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateUtil } from '@/util';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

@Component
export default class UiDateTimeLabelText extends Vue {
    @Prop()
    declare readonly dateTimeMin?: string;
    @Prop()
    declare readonly dateTimeMax?: string;

    get deliveryDatetime(): DeliveryDateTime | null {
        if (!this.dateTimeMin || !this.dateTimeMax) return null;
        return DeliveryDateTime.of(this.dateTimeMin, this.dateTimeMax);
    }

    /**
     * フォーマットされた日付を取得します。
     */
    get formatDate(): string {
        return this.deliveryDatetime?.date.format('M/D') ?? '';
    }

    /**
     * フォーマットされた曜日を取得します。
     */
    get formatDayOfWeek(): string {
        const date = this.deliveryDatetime?.date;
        if (!date) {
            return '';
        }
        const label = date.format('ddd');
        return DateUtil.isHoliday(date) ? `${label}・祝` : label;
    }

    /**
     * フォーマットされた時間を取得します。
     */
    get formatTime(): string {
        return this.deliveryDatetime?.formatTime() ?? '';
    }

    /**
     * 曜日表示テキストのスタイルCSS クラスを取得します
     */
    get dayTextStyleClass(): string {
        const date = this.deliveryDatetime?.date;
        if (!date) {
            return '';
        }
        const styleClasses = [`day-text--${date.format('d')}`];
        if (DateUtil.isHoliday(date)) {
            styleClasses.push('day-text--holiday');
        }
        return styleClasses.join(' ');
    }
}
