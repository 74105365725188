<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    value: boolean;
    truckCount: number;
}>();

const emit = defineEmits<{
    (e: 'input', value: boolean): void;
    (e: 'select', value: 'soon' | 'later'): void;
}>();

const visible = computed({
    get() {
        return props.value;
    },
    set(value) {
        emit('input', value);
    },
});

</script>

<template>
    <a-modal v-model="visible" :mask-closable="false" :closable="false" :footer="null">
        <a-row type="flex" justify="center" align="middle" class="announce-image-section">
            <img src="/img/img-truck.png" alt="トラボックス-空車トラック"/>
        </a-row>
        <a-row type="flex" justify="center" align="middle" class="announce-content-section">
            <div class="announce-content-section__text">
                ご登録の荷物を運べるトラックが<span class="announce-content-section__strong">{{
                    truckCount
                }}件</span>あります
            </div>
        </a-row>
        <a-row type="flex" justify="center" align="middle" class="announce-description-section">
            今すぐ空車情報を確認してみましょう！
        </a-row>
        <a-row type="flex" justify="center" align="middle" class="announce-action-section">
            <a-space size="large">
                <a-button @click="emit('select', 'soon')" size="large">今すぐ見る</a-button>
                <a-button @click="emit('select', 'later')" size="large">あとで見る</a-button>
            </a-space>
        </a-row>
    </a-modal>
</template>

<style scoped lang="less">
.announce-image-section {
    margin-top: 8px;

    img {
        width: 116px;
        height: 54px;
    }
}

.announce-content-section {
    margin-top: 40px;

    &__text {
        color: #222222;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
    }

    &__strong {
        color: #007457;
    }
}

.announce-description-section {
    margin-top: 12px;
}

.announce-action-section {
    margin-top: 40px;
}

</style>
