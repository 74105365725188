import { useLoading } from '@/composables/helper/loading-helper';
import { Baggage } from '@/models/baggage';
import { baggageApi } from '@/repository/api/internal/baggage';
import { ref } from 'vue';

/**
 * 荷物詳細データをロードする機能を提供します。
 */
export const useBaggage = () => {
    const baggage = ref<Baggage | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (baggageId: number): Promise<void> => {
        await withLoading(async () => {
            const data = await baggageApi.find(baggageId, undefined);
            baggage.value = data;
        });
    };

    const clear = () => {
        baggage.value = undefined;
    };

    const changeNegotiation = (value: boolean) => {
        if (baggage.value) {
            baggage.value.underNegotiation = value;
        }
    };
    
    return {
        state: {
            loading,
            baggage,
        },
        load,
        clear,
        changeNegotiation,
    };
};
