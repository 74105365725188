<script setup lang="ts">
import { computed, ref } from 'vue';
import _ from 'lodash';
import { Util } from '@/util';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';

const props = withDefaults(defineProps<{
    value: BaggageFreightValue,
}>(), {
});
const emits = defineEmits<{
    (e: 'input', value: BaggageFreightValue): void,
    (e: 'change', value: BaggageFreightValue): void,
}>();

const prev = ref<number>(0);
const freight = computed<string>({
    get: () => props.value.value?.toString() ?? '',
    set: (value: string) => {
        if (props.value.isNegotiate) return;
        const freightString = Util.parseFreightString(value);
        const num = Util.toNumber(_.isEmpty(freightString) ? '0' : freightString);
        onChange(new BaggageFreightValue(num));
        prev.value = num;
    },
});
const checked = computed<boolean>({
    get: () => props.value.isNegotiate,
    set: (value: boolean) => {
        if (value) {
            onChange(new BaggageFreightValue(undefined));
        } else {
            onChange(new BaggageFreightValue(prev.value));
        }
    },
});
const onChange = (value: BaggageFreightValue) => {
    emits('input', value);
    emits('change', value);
};
</script>

<template>
    <a-space size="middle">
        <a-input style="max-width: 205px"
                 suffix="円"
                 placeholder="税別価格"
                 :disabled="checked"
                 v-model="freight">
        </a-input>
    </a-space>
</template>

<style scoped lang="less">

</style>
