import { Component, Prop } from 'vue-property-decorator';
import * as types from '@/vuex/modules/account/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';

export type AccountType = 'MAIN_ACCOUNT' | 'ADDITIONAL_ACCOUNT';

@Component({
    title: '本人確認',
})
export default class AccountVerifyPage extends PageVue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ type: String })
    declare readonly verificationCode?: string;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    succeed = false;
    accountType?: AccountType;

    async mounted(): Promise<void> {
        this.accountType = this.$route.meta?.accountType;
        await this.initialize();
    }

    private async initialize() {
        if (!this.verificationCode?.trim()) {
            return;
        }

        const onSuccess = () => {
            this.succeed = true;
            const query = { t: this.verificationCode };
            // NOTE: 文章が読めるように若干の遅延を入れる
            setTimeout(async () => await this.$router.push({ name: 'AccountPasswordRegister', query }), 1000);
        };
        const onError = () => (this.succeed = false);

        this.loading = true;

        await this.verifyAccount()
            .then(onSuccess)
            .catch(onError)
            .finally(() => (this.loading = false));
    }

    private verifyAccount(): Promise<void> {
        if (!this.verificationCode) throw Error('no verification code.');

        if (this.accountType === 'MAIN_ACCOUNT') {
            return AccountVerifyPage.verify({ verificationCode: this.verificationCode });
        } else if (this.accountType === 'ADDITIONAL_ACCOUNT') {
            return AccountVerifyPage.verifyAdditional({ verificationCode: this.verificationCode });
        }
        throw Error('accountType is not specified.');
    }

    /**
     * 本人認証を行います。
     */
    private static verify(form: types.AccountVerificationForm): Promise<void> {
        return store.dispatch(`account/${types.ACTION.VERIFY}`, form);
    }

    /**
     * アカウント追加の本人認証を行います。
     */
    private static verifyAdditional(form: types.AccountVerificationForm): Promise<void> {
        return store.dispatch(`account/${types.ACTION.VERIFY_FOR_ADDITIONAL_ACCOUNT}`, form);
    }
}
