import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { helpApi } from '@/repository/api/api';
import {
    ACTION as a,
    GETTER as g,
    HelpState,
    InquiryForm,
    MUTATION as m,
    ReportLatePaymentForm
} from './types';

const state: HelpState = {
    notice: { items: [] },
    noticeListener: undefined,
};

const actions: ActionTree<HelpState, void> = {
    /**
     * その他お問い合わせ・ご意見を送信します。
     *
     * @param param その他お問い合わせ・ご意見登録フォーム
     */
    [a.SUBMIT_INQUIRY]: (_, param: InquiryForm) => helpApi.inquiry(param),
    /**
     * 入金遅延連絡を送信します。
     *
     * @param param 入金遅延連絡フォーム
     */
    [a.REPORT_LATE_PAYMENT]: (_, param: ReportLatePaymentForm) => helpApi.reportUnpaid(param),
    /**
     * お知らせをロードします。
     */
    [a.LOAD_NOTICE]: ({ commit }) => helpApi.notice().then((notice) => commit(m.SET_NOTICE, notice)),
    /**
     * お知らせを定期的に取得するリスナーを開始します。
     */
    [a.START_NOTICE_LISTENER]: async ({ commit, dispatch, state }) => {
        // NOTE: 既にsetIntervalが動作している場合は一度停止させる
        if (state.noticeListener) {
            await dispatch(a.STOP_NOTICE_LISTENER);
        }
        const intervalTime = 30 * 60 * 1000; // 30 minutes
        commit(
            m.SET_NOTICE_LISTENER,
            setInterval(() => dispatch(a.LOAD_NOTICE), intervalTime)
        );
    },
    /**
     * お知らせを定期的に取得するリスナーを停止します。
     */
    [a.STOP_NOTICE_LISTENER]: ({ commit, state }) => commit(m.SET_NOTICE_LISTENER, clearInterval(state.noticeListener)),
};

const getters: GetterTree<HelpState, void> = {
    /**
     * お知らせを取得します。
     */
    [g.NOTICE]: (s) => s.notice,
    /**
     * お知らせを定期的に取得するするリスナーを取得します。
     */
    [g.NOTICE_LISTENER]: (s) => s.noticeListener,
};

const mutations: MutationTree<HelpState> = {
    /**
     * お知らせを設定します。
     *
     * @param notice お知らせ
     */
    [m.SET_NOTICE]: (s, notice) => (s.notice = notice),
    /**
     * お知らせを定期的に取得するためのリスナーを設定します。
     * @param intervalId お知らせ定期取得で用いるsetIntervalで発行された `intervalId`
     */
    [m.SET_NOTICE_LISTENER]: (s, intervalId?: number) => (s.noticeListener = intervalId),
};

export const help: Module<HelpState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
