import store from '@/vuex/store';
import * as deliveryOrderTypes from '@/vuex/modules/deliveryOrder/types';

/**
 * 配送依頼書一覧をロードします
 */

export const loadDeliveryOrderList = async (agreementIds: number[]): Promise<void> =>
    store.dispatch(`deliveryOrder/${deliveryOrderTypes.ACTION.LOAD_DELIVERY_ORDER_LIST}`, agreementIds);

/**
 * 成約に紐づく配送依頼書の存在をチェックします
 */
export const checkDeliveryOrderExist = async (agreementId: number): Promise<void> =>
    store.dispatch(`deliveryOrder/${deliveryOrderTypes.ACTION.DELIVERY_ORDER_EXIST}`, agreementId);
