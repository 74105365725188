<script setup lang="ts">
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import { useRouting } from '@/composables/helper/routing';

const { goToByName } = useRouting();
/**
 * トップページへ遷移するボタンをクリックした際に呼び出されます。
 */
const onClickToTopPage = () => goToByName('Index');
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <h1 class="body__title">お探しのページは見つかりません</h1>
            <p>
                アクセスしようとしたページは、すでに削除されたか別のURLに変更となった可能性があります。<br>
                お手数ですがトップページからアクセスしなおしてください。
            </p>
            <div class="body__action">
                <a-button size="large" type="primary" @click="onClickToTopPage">トップページへ
                    <a-icon type="right"/>
                </a-button>
            </div>
        </div>
    </pre-login-layout>

</template>

<style scoped lang="less">
.body__title {
    margin-bottom: @padding-lg;
    font-size: 18px;
    line-height: 30px;
    color: #48483f;
}

.body__action {
    margin-top: 32px;
    text-align: center;
}
</style>
