<script setup lang="ts">
import _ from 'lodash';
import { computed, ref } from 'vue';
import { DateValue } from '@/models/vo/date';
import { FormValidateUtil } from '@/models/validate-helper';
import { SettlementSearchOutgoFormModel } from '@/models/settlement-search';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import DatePicker from '@/_components/ui/DatePicker.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import SettlementIdInput from '@/_components/ui/SettlementIdInput.vue';
import CollapsibleSearchForm from '@/_components/ui/CollapsibleSearchForm.vue';
import { useFormModel } from '@/composables/form-helper';
import { useCompanySearch } from '@/composables/company-search';
import { useMyBaggageSearchFormVisibility } from '@/composables/my-baggage-search-form';

const props = defineProps<{
    value: SettlementSearchOutgoFormModel,
    dateList: DateValue[],
}>();

const emit = defineEmits<{
    (e: 'search'): void;
    (e: 'input', value: SettlementSearchOutgoFormModel): void;
}>();

const { formModel, submit } = useFormModel();

const { load: searchCompany, state: { available: availableSearchCopany, list: companyList } } = useCompanySearch();
const { visible } = useMyBaggageSearchFormVisibility('SETTLEMENT_OUTGO');

// フィルタリング状況
const filtered = ref(props.value.dirty);
// フォーム
const form = computed({
    get: () => props.value,
    set: (value) => emit('input', value),
});
// バリデーションルール
const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
// 企業選択肢
const companySuggestions = computed(() => companyList.value.data.map(each => ({
    text: each.name.kanji,
    value: each.id,
    tips: each.location.prefecture.label,
})));

/**
 * 開閉が切り替わった際に呼び出されます。
 */
const onToggleVisibility = () => visible.value = !visible.value;

/**
 * 出金日が選択された際に呼び出されます。
 */
const onSelectPayerDate = (newValue: string | undefined) =>
    form.value.payerDate = _.isNil(newValue) ? undefined : new DateValue(newValue);

/**
 * 企業を検索します。
 */
const onSearchCompany = _.debounce((needle: string) => searchCompany({ keyword: needle, pageNo: 1 }), 500);

/**
 * 発地が選択された際に呼び出されます。
 */
const onSelectPrefecture = (newValue: PrefectureEnumCode | undefined) =>
    form.value.departurePref = _.isNil(newValue) ? undefined : PrefectureEnum.valueOf(newValue);

/**
 * 検索ボタンが押下された際に呼び出されます。
 */
const onClickSearch = () => {
    filtered.value = props.value.dirty;
    submit(async () => emit('search'));
};

/**
 * クリアボタンが押下された際に呼び出されます。
 */
const onClickClear = () => form.value = new SettlementSearchOutgoFormModel();
</script>

<template>
    <collapsible-search-form :collapsed="visible"
                             :is-filtered="filtered"
                             @toggleVisibility="onToggleVisibility"
                             class="search-container">
        <a-form-model ref="formModel" :model="form" :rules="formValidateRules">
            <!--suppress HtmlDeprecatedAttribute -->
            <a-row type="flex" :gutter="[16, 32]" align="middle">
                <a-col flex="200px">
                    <div>出金日</div>
                    <a-select :allow-clear="true"
                              :value="form.payerDate?.format()"
                              :getPopupContainer="trigger => trigger.parentNode"
                              @change="onSelectPayerDate">
                        <a-select-option v-for="each in dateList"
                                         :key="each.format()"
                                         :value="each.format()">
                            {{ each.format() }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col flex="440px" v-if="availableSearchCopany">
                    <div>取引先名</div>
                    <a-select v-model="form.payeeCompanyId"
                              show-search
                              class="payer-company"
                              placeholder="株式会社トラボックス"
                              :allowClear="true"
                              :filter-option="false"
                              :getPopupContainer="trigger => trigger.parentNode"
                              @search="onSearchCompany">
                        <a-select-option v-for="each in companySuggestions"
                                         :key="each.text"
                                         :value="each.value">
                            <div class="payer-company__choice">
                                <span>{{ each.text }}</span>
                                <span class="payer-company__choice--tips">{{ each.tips }}</span>
                            </div>
                        </a-select-option>
                        <template #suffixIcon></template>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16, 32]" align="middle">
                <a-col flex="200px">
                    <div>発地</div>
                    <a-form-model-item prop="departurePref">
                        <prefecture-select class="app-input-group__item"
                                           :multiple="false"
                                           :placeholder="''"
                                           :placeholder-icon="true"
                                           :allow-clear="true"
                                           :value="form.departurePref?.code"
                                           @change="onSelectPrefecture">
                            <template #title>
                                <img src="/img/ic-departure.svg" width="24" height="24" alt="発地"
                                     style="margin-right: 8px"/>発地
                            </template>
                        </prefecture-select>
                    </a-form-model-item>
                </a-col>
                <a-col flex="500px">
                    <div>発日</div>
                    <a-form-model-item prop="departure">
                        <a-input-group class="app-input-group" compact>
                            <date-picker class="app-input-group__item"
                                         format="YYYY/M/D"
                                         :disabled-date="(each) => each.isAfter(DateValue.today())"
                                         v-model="form.departure.from">
                                <template #suffixIcon>
                                    <a-icon type="calendar"></a-icon>
                                </template>
                            </date-picker>
                            <span class="app-input-group__item app-input-group__item--separator">〜</span>
                            <date-picker class="app-input-group__item"
                                         format="YYYY/M/D"
                                         :disabled-date="(each) => each.isAfter(DateValue.today())"
                                         v-model="form.departure.to">
                            </date-picker>
                        </a-input-group>
                    </a-form-model-item>
                </a-col>
                <a-col flex="200px">
                    <div>取引番号</div>
                    <a-form-model-item prop="settlementId">
                        <settlement-id-input v-model="form.settlementId" @pressEnter="onClickSearch"/>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16, 0]" align="middle">
                <a-col flex="auto">
                    <a-checkbox v-model="form.onlyProxy">おまかせ対象外の取引を非表示</a-checkbox>
                </a-col>
            </a-row>
            <a-row justify="space-between" type="flex" class="submit-container">
                <a-col flex="100px"></a-col>
                <a-col flex="100px">
                    <a-button block @click="onClickSearch">
                        <a-icon type="search"/>
                        検索
                    </a-button>
                </a-col>
                <a-col flex="100px" style="text-align: end">
                    <a-button @click="onClickClear">クリア</a-button>
                </a-col>
            </a-row>
        </a-form-model>
    </collapsible-search-form>
</template>

<style scoped lang="less">
.search-container {
    background-color: @color-neutral-2;

    ::v-deep(.toggle-button) {
        background-color: @color-neutral-2;
    }

    ::v-deep(.search-form-content) {
        margin-left: @padding-lg;
        margin-right: @padding-lg;
    }

    ::v-deep(.ant-select-open .ant-select-arrow-icon svg) {
        transform: none;
    }

    .ant-select {
        width: 100%;
    }

    ::v-deep(.app-input-group__item:first-child.ant-calendar-picker .ant-calendar-picker-input.ant-input) {
        padding-left: @padding-sm + @padding-lg;
    }

    .app-input-group__item--separator {
        background-color: @token-main-color-white;
    }

    .payer-company {
        &__choice {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;

            &--tips {
                font-size: 12px;
            }
        }
        ::v-deep(.ant-select-selection-selected-value) {
            width: 100%;
        }
    }
}
</style>
