<script setup lang="ts">
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import { computed } from 'vue';
import { CompanyPlaceList } from '@/models/company-place';
import { TmpBaggageLocationValue } from '@/models/baggage';
import { PrefectureEnumCode, PrefectureEnum } from '@/enums/prefecture.enum';

const props = withDefaults(defineProps<{
    value: TmpBaggageLocationValue,
    placeList?: CompanyPlaceList
}>(), {});
const emits = defineEmits<{
    (e: 'input', value: TmpBaggageLocationValue): void,
    (e: 'change', value: TmpBaggageLocationValue): void,
    (e: 'blur'): void;
}>();

const placeOptions = computed(() =>
    (props.placeList?.data ?? []).map(each => ({
        label: each.placeName,
        key: each.id,
        value: each.id,
    }))
);

const prefectureCode = computed<PrefectureEnumCode | undefined>({
    get: () => props.value.prefecture?.code,
    set: (value: PrefectureEnumCode | undefined): void => {
        const changed = value !== props.value.prefecture?.code;
        let newValue = props.value.updatePrefecture(value ? PrefectureEnum.valueOf(value) : undefined);
        if (changed) {
            newValue = newValue.updateCity(undefined);
            newValue = newValue.updateAddress(undefined);
        }
        emits('input', newValue);
        emits('change', newValue);
    },
});

const city = computed<string | undefined>({
    get: () => props.value.city,
    set: (value: string | undefined): void => {
        const newValue = props.value.updateCity(value);
        emits('input', newValue);
        emits('change', newValue);
    },
});

const address = computed<string | undefined>({
    get: () => props.value.address,
    set: (value: string | undefined): void => {
        const newValue = props.value.updateAddress(value);
        emits('input', newValue);
        emits('change', newValue);
    },
});

const isCompanyPlace = computed<boolean>({
    get: () => props.value.value.isCompanyPlace ?? false,
    set: (value: boolean): void => {
        const newValue = props.value.updateIsCompanyPlace(value);
        emits('input', newValue);
        emits('change', newValue);
    },
});

const companyPlaceId = computed<number | undefined>({
    get: () => props.value.value.companyPlace?.id,
    set: (value: number | undefined): void => {
        const place = props.placeList?.data?.find(each => each.id === value);
        const newValue = props.value.updateCompanyPlace(place);
        emits('input', newValue);
        emits('change', newValue);
    },
});
</script>

<template>
    <a-row type="flex">
        <a-col>
            <a-space>
                <a-switch checked-children="選択" un-checked-children="入力" v-model="isCompanyPlace" />
                <span></span>
            </a-space>
        </a-col>
        <a-col flex="auto">
            <a-select v-if="isCompanyPlace"
                      :options="placeOptions"
                      style="width: 100%"
                      v-model="companyPlaceId"
                      placeholder="地点名"/>
            <a-input-group v-else compact>
                <prefecture-select style="width: 20%"
                                   :multiple="false"
                                   placeholder="都道府県"
                                   title="発地"
                                   v-model="prefectureCode"/>
                <city-input style="width: 40%"
                            placeholder="市区町村"
                            :pref-code="prefectureCode"
                            @blur="emits('blur')"
                            v-model="city"/>
                <a-input style="width: 40%"
                         placeholder="番地・建物（任意）"
                         @blur="emits('blur')"
                         v-model="address"/>
            </a-input-group>
        </a-col>
    </a-row>
</template>

<style scoped lang="less">
</style>
