<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';

const props = defineProps<{
    baggage: Pick<Baggage, 'description' | 'multipleBaggage'>;
}>();

const description = computed<string | undefined>(() => {
    return props.baggage.description;
});

const isMultiple = computed<boolean>(() => {
    return props.baggage.multipleBaggage;
});

</script>

<template>
    <div>
        <span class="description">{{ description }}</span>
        <div v-if="isMultiple">
            <a-tag class="tag-multiple-baggage">複数台荷物</a-tag>
        </div>
    </div>
</template>

<style scoped lang="less">
.description {
    white-space: pre-wrap;
}
.tag-multiple-baggage {
    background-color: transparent;
}
</style>
