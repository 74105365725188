import { ref } from 'vue';
import { CompanyStatistics } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 企業統計情報をロードする機能を提供します。
 */
export const useCompanyStatistics = () => {
    const statistics = ref<CompanyStatistics | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (companyId: number) => {
        await withLoading(async () => {
            statistics.value = await companyApi.statistics(companyId);
        });
    };

    const clear = () => {
        statistics.value = undefined;
    };

    return {
        state: {
            statistics,
            loading,
        },
        load,
        clear,
    };
};
