import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { Baggage } from '@/models/baggage';
import { baggageApi } from '@/repository/api/internal/baggage';

export const useMyBaggage = () => {
    const { state: { loading }, withLoading } = useLoading();
    const baggage = ref<Baggage | undefined>(undefined);

    const load = (id: number) => withLoading(async () => {
        baggage.value = await baggageApi.findMyBaggage(id);
    });

    const clear = () => {
        baggage.value = undefined;
    };

    return {
        state: {
            baggage,
            loading,
        },
        load,
        clear,
    };
};
