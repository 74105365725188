import { ActionTree, Module } from 'vuex';
import { predictionApi } from '@/repository/api/api';
import { ACTION as a, PredictionState, } from './types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';

const actions: ActionTree<PredictionState, void> = {
    /**
     * 荷物の成約予測を取得します。
     *
     * @param param 荷物成約予測フォーム
     */
    [a.PREDICT_AGREEMENT_PROBABILITY]: (_, param: PredictionBaggageAgreementForm) => predictionApi.baggageAgreement(param),
};

export const prediction: Module<PredictionState, void> = {
    namespaced: true,
    actions,
};
