import { circleApi } from '@/repository/api/internal/circle';
import { useNotification } from '@/composables/helper/page-helper';
import { CircleLabel } from '@/models/circle';
import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 部屋ラベルの取得機能を提供します。
 */
export const useCircleLabels = () => {
    const labelList = ref<CircleLabel[]>([]);
    const { state: { loading }, withLoading } = useLoading();
    const load = async (circleId: number) => withLoading(async () => {
        labelList.value = await circleApi.loadLabels(circleId);
    });

    const clear = () => {
        labelList.value = [];
    };

    return {
        state: {
            loading,
            labelList,
        },
        load,
        clear,
    };
};

/**
 * 部屋ラベルの登録機能を提供します。
 */
export const useCircleLabelRegister = () => {
    const register = async (circleId: number, data: { labelText: string, labelColor: string}) => {
        await circleApi.register(circleId, data);
    };

    return {
        register,
    };
};


/**
 * 部屋ラベルの削除機能を提供します。
 */
export const useCircleLabelRemove = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_DEL_LABEL = 'DELETE_LABEL_ERROR';
    /**
     * ラベル履歴の削除失敗を通知します。
     */
    const notifyFailedToDeleteLabel = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_DEL_LABEL,
        message: 'ラベルの履歴を削除できませんでした。',
        description: '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * ラベル履歴の削除失敗通知を閉じます。
     */
    const closeFailedToDeleteLabelNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_DEL_LABEL);

    const remove = async (circleId: number, id: number) => {
        closeFailedToDeleteLabelNotification();
        await circleApi.deleteLabel(circleId, { id })
            .catch(notifyFailedToDeleteLabel);
    };

    return {
        remove,
    };
};
