import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { CustomRow } from '@/types/ant-design';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
import { Util } from '@/util';

@Component({
    components: {
        UiDateTimeLocationLabel,
    },
})
export default class CompanyBaggageList extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageList?: types.BaggageList;

    get list(): Array<types.Baggage> {
        return this.baggageList?.data ?? [];
    }

    get listLimitOver(): boolean {
        return (this.baggageList?.totalRecordCount ?? 0) > 50;
    }

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '発日時・発地 / 着日時・着地',
            key: 'departureArrival',
            scopedSlots: { customRender: 'departureArrival' },
            width: 228,
            align: 'center',
        },
        {
            title: '運賃',
            dataIndex: 'freight',
            key: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 100,
            align: 'center',
        },
        {
            title: '重量',
            key: 'truckWeight',
            width: 64,
            scopedSlots: { customRender: 'truckWeight' },
        },
        {
            title: '車種',
            key: 'truckModel',
            width: 80,
            scopedSlots: { customRender: 'truckModel' },
        },
        {
            title: '荷種',
            key: 'type',
            width: 80,
            scopedSlots: { customRender: 'type' },
        }
    ];

    // ======================================================
    // Functions
    // ======================================================
    customRow(record: types.Baggage): CustomRow {
        return {
            on: {
                click: (event: Event) => {
                    this.$emit('showBaggage', event, record);
                },
            },
        };
    }

    freightText(record: types.Baggage): string {
        if (!record.freight) return '要相談';
        return `${Util.formatNumber(record.freight)}円`;
    }
}
