import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import { Const } from '@/const';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';

@Component({
    title: 'パスワード再設定メールをお送りしました',
    components: {
        UiPreLoginLayout,
    },
})
export default class AccountPasswordRemindCompletePage extends PageVue {
    supportDesk = Const.supportDeskContacts;
}
