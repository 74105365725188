<script setup lang="ts">
import CollapsibleSearchForm from '@/_components/ui/CollapsibleSearchForm.vue';
import { BaggageListFormModel, baggageListFormDateAvailability } from '@/models/baggage';
import { computed, ComputedRef } from 'vue';
import TruckModelTreeSelect from '@/_components/ui/TruckModelTreeSelect.vue';
import TruckWeightTreeSelect from '@/_components/ui/TruckWeightTreeSelect.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import BaggageIdInput from '@/_components/ui/BaggageIdInput.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';
import { DateValue } from '@/models/vo/date';

const props = defineProps<{
    value: BaggageListFormModel;
    validationMessage?: string;
    collapsed: boolean;
    isFiltered: boolean;
}>();

const emit = defineEmits<{
    (e: 'input', value: BaggageListFormModel): void;
    (e: 'toggleVisibility'): void;
    (e: 'search'): void;
    (e: 'clear'): void;
}>();

const form = computed(() => props.value);

const useHelper = (form: ComputedRef<BaggageListFormModel>, commit: (form: BaggageListFormModel) => void) => {
    const codes = (enums: { code: string }[] | undefined) => (enums?.map((each) => each.code) ?? []);
    const enums = (codes: string[]) => codes.map((code) => ({ code }));

    return {
        departurePref: computed({
            get: () => codes(form.value.departurePref),
            set: (value) => {
                commit({ ...form.value, departurePref: enums(value) });
            }
        }),
        arrivalPref: computed({
            get: () => codes(form.value.arrivalPref),
            set: (value) => {
                commit({ ...form.value, arrivalPref: enums(value) });
            }
        }),
        departureFrom: computed({
            get: () => form.value.departureFrom?.toDate(),
            set: (value) => {
                commit({
                    ...form.value,
                    departureFrom: value?.toDateTime().startOf('date')
                });
            }
        }),
        departureTo: computed({
            get: () => form.value.departureTo?.toDate(),
            set: (value) => {
                commit({
                    ...form.value,
                    departureTo: value?.toDateTime().endOf('date')
                });
            }
        }),
        truckWeight: computed({
            get: () => codes(form.value.truckWeight),
            set: (value) => {
                commit({ ...form.value, truckWeight: enums(value) });
            }
        }),
        truckModel: computed({
            get: () => codes(form.value.truckModel),
            set: (value) => {
                commit({ ...form.value, truckModel: enums(value) });
            }
        }),
        staffName: computed({
            get: () => form.value.staffName,
            set: (newValue) => {
                commit({ ...form.value, staffName: newValue });
            }
        }),
        id: computed({
            get: () => form.value.id,
            set: (newValue) => {
                commit({ ...form.value, id: newValue });
            }
        }),
        swapPrefs: () => {
            commit({
                ...form.value,
                departurePref: form.value.arrivalPref,
                arrivalPref: form.value.departurePref,
            });
        }
    };
};

const {
    departurePref,
    arrivalPref,
    departureFrom,
    departureTo,
    truckWeight,
    truckModel,
    staffName,
    id,
    swapPrefs,
} = useHelper(form, (newForm) => emit('input', newForm));

const isDisabledDate = (date: DateValue) => !baggageListFormDateAvailability(date);

</script>

<template>
    <collapsible-search-form
        :collapsed="collapsed"
        :is-filtered="isFiltered"
        @toggleVisibility="emit('toggleVisibility')">

        <template #default>
            <a-row :gutter="[16, 16]" align="middle">
                <a-col :span="10" :offset="3">
                    <a-input-group class="app-input-group" compact>
                        <prefecture-select class="app-input-group__item"
                                           :multiple="true"
                                           placeholder="発地"
                                           :placeholder-icon="true"
                                           v-model="departurePref">
                            <template #title>
                                <img src="/img/ic-departure.svg" width="24" height="24" alt="発地"
                                     style="margin-right: 8px"/>発地
                            </template>
                        </prefecture-select>
                        <a-tooltip title="発地と着地を入れ替えます">
                            <a-button icon="swap" class="btn-swap" @click="swapPrefs"></a-button>
                        </a-tooltip>
                        <prefecture-select class="app-input-group__item"
                                           :multiple="true"
                                           placeholder="着地"
                                           v-model="arrivalPref">
                            <template #title>
                                <img src="/img/ic-arrival.svg" width="24" height="24" alt="行先地"
                                     style="margin-right: 8px"/>着地
                            </template>
                        </prefecture-select>
                    </a-input-group>
                </a-col>
                <a-col :span="10">
                    <a-input-group class="app-input-group" compact>
                        <date-picker class="app-input-group__item"
                                     placeholder="発日（開始）"
                                     format="YYYY/M/D"
                                     :disabled-date="isDisabledDate"
                                     v-model="departureFrom">
                            <template #suffixIcon>
                                <a-icon type="calendar"></a-icon>
                            </template>
                        </date-picker>
                        <span class="app-input-group__item app-input-group__item--separator">〜</span>
                        <date-picker class="app-input-group__item"
                                     placeholder="（終了）"
                                     format="YYYY/M/D"
                                     :disabled-date="isDisabledDate"
                                     v-model="departureTo">
                        </date-picker>
                    </a-input-group>
                </a-col>
            </a-row>
            <a-row :gutter="[16, 16]" align="middle">
                <!-- 車種 -->
                <a-col :span="5" :offset="3">
                    <truck-weight-tree-select v-model="truckWeight"/>
                </a-col>

                <!-- 車種 -->
                <a-col :span="5">
                    <truck-model-tree-select v-model="truckModel"/>
                </a-col>

                <!-- 担当者名 -->
                <a-col :span="4">
                    <a-input v-model="staffName" placeholder="担当者名" :allowClear="true" size="default"/>
                </a-col>

                <!-- 荷物番号 -->
                <a-col :span="4">
                    <baggage-id-input v-model="id" @pressEnter="emit('search')"/>
                </a-col>
            </a-row>
            <a-row align="middle" v-if="validationMessage">
                <a-col :span="18" :offset="3">
                    <span class="error-message">{{ validationMessage }}</span>
                </a-col>
            </a-row>
            <a-row justify="space-between" type="flex" class="submit-container">
                <a-col flex="100px"></a-col>
                <a-col flex="100px">
                    <a-button block @click="emit('search')">
                        <a-icon type="search"/>
                        検索
                    </a-button>
                </a-col>
                <a-col flex="100px">
                    <a-button @click="emit('clear')">
                        クリア
                    </a-button>
                </a-col>
            </a-row>
        </template>
    </collapsible-search-form>
</template>

<style scoped lang="less">
.btn-swap {
    height: auto;
    color: @link-color;
    box-shadow: none;
}

.error-message {
    min-height: 22px;
    font-size: 12px;
    line-height: 1.5;
    color: @error-color;
}
</style>
