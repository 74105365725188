import { Enum } from '@/types/enum';
import _ from 'lodash';
import { Const } from '@/const';
import { Util } from '@/util';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Validator } from '@/validator';
import { JsonUtil } from '@/util/json';

export type MemberPosition = 'BOSS' | 'LDER' | 'DPER' | 'NONE';
export type MemberStatus = 'REG' | 'VFD' | 'ACT' | 'LFT';
export type ProfileAuthority = 'PRE_USER' | 'USER';
export interface Profile {
    id: number;
    status: Enum<MemberStatus>;
    companyId: number;
    name: string;
    email: string;
    position: Enum<MemberPosition>;
    authority: Enum<ProfileAuthority>;
    mainAccountFlg?: boolean;
    product?: Enum;
}

export interface AccountRegisterForm {
    company: AccountRegisterFormCompany;
    member: AccountRegisterFormMember;
    trace: AccountRegisterFormTrace;
}

const PREFIX_HTTP = 'http://';
const PREFIX_HTTPS = 'https://';

export class AccountRegisterFormModel implements FormValidatable<AccountRegisterFormModel> {
    company: AccountRegisterFormCompany;
    member: AccountRegisterFormMember;
    trace: AccountRegisterFormTrace;

    constructor() {
        this.company = {
            companyName: '',
            zipCode: '',
            location: {
                prefecture: undefined,
                city: '',
                address: '',
            },
            phone: {
                number: '',
                faxNumber: '',
            },
            truckCount: '1',
            url: undefined,
        };
        this.member = {
            emailAddress: '',
            name: '',
        };
        this.trace = {};
    }

    get name(): string {
        return this.member.name ?? '';
    }
    set name(value: string) {
        this.member.name = value.trim();
    }
    get emailAddress(): string {
        return this.member.emailAddress ?? '';
    }
    set emailAddress(value: string) {
        this.member.emailAddress = value.trim();
    }
    get phoneNumber(): string {
        return this.company.phone.number ?? '';
    }
    set phoneNumber(value: string) {
        this.company.phone.number = Util.toDigits(value.trim());
    }
    get faxNumber(): string {
        return this.company.phone.faxNumber ?? '';
    }
    set faxNumber(value: string) {
        this.company.phone.faxNumber = Util.toDigits(value.trim());
    }
    get companyName(): string {
        return this.company.companyName ?? '';
    }
    set companyName(value: string) {
        this.company.companyName = value.trim();
    }
    get zipCode(): string {
        return this.company.zipCode ?? '';
    }
    set zipCode(value: string) {
        this.company.zipCode = Util.toDigits(value).substring(0, 7);
    }
    get prefectureCode(): string | undefined {
        return this.company.location.prefecture?.code ?? undefined;
    }
    set prefectureCode(value: string | undefined) {
        this.company.location.prefecture = { code: value ?? '' };
    }
    get city(): string {
        return this.company.location.city ?? '';
    }
    set city(value: string) {
        this.company.location.city = value.trim();
    }
    get address(): string {
        return this.company.location.address ?? '';
    }
    set address(value: string) {
        this.company.location.address = value.trim();
    }
    get truckCount(): string {
        return this.company.truckCount ?? '';
    }
    set truckCount(value: string) {
        if (value.length > 0) {
            const digits = Util.toNumber(value);
            this.company.truckCount = isNaN(digits) ? '' : `${digits}`;
        } else {
            this.company.truckCount = '';
        }
    }
    get urlScheme(): string {
        const isSecure = this.company.url?.startsWith(PREFIX_HTTPS) ?? true;
        return isSecure ? PREFIX_HTTPS : PREFIX_HTTP;
    }
    set urlScheme(value: string) {
        this.company.url = `${ value }${ this.url }`;
    }
    get url(): string {
        const urls = this.company.url?.split('://') ?? [];
        urls.shift();
        return urls.join('');
    }
    set url(value: string) {
        const newUrl = value.trim().replace(PREFIX_HTTP, '').replace(PREFIX_HTTPS, '');
        this.company.url = _.isEmpty(newUrl) ? undefined : `${ this.urlScheme }${ newUrl }`;
    }

    validator(): FormValidator<AccountRegisterFormModel> {
        return {
            name: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.member.name?.trim()) {
                            callback('担当者名を入力してください。');
                        } else if (this.member.name.length > 60) {
                            callback('担当者名は60文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
            emailAddress: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        const validated = Validator.validateEmail(this.emailAddress);
                        if (!validated.result) {
                            callback(validated.message);
                        } else {
                            callback();
                        }
                    },
                },
                {
                    max: Const.MAX_EMAIL_LENGTH,
                    message: `メールアドレスは${Const.MAX_EMAIL_LENGTH}文字以内で入力してください。`,
                },
            ],
            phoneNumber: [
                {
                    required: true,
                    whitespace: true,
                    message: '電話番号を入力してください。',
                },
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
            faxNumber: [
                {
                    required: true,
                    whitespace: true,
                    message: 'FAX番号を入力してください。',
                },
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `FAX番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: 'FAX番号を正しい形式で入力してください。',
                },
            ],
            companyName: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.company.companyName?.trim()) {
                            callback('法人名・営業所名を入力してください。');
                        } else if (this.company.companyName.length > 200) {
                            callback('法人名・営業所名は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    },
                },
            ],
            zipCode: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.company.zipCode?.trim()) {
                            callback('郵便番号を入力してください。');
                        } else if (this.company.zipCode.length != 7) {
                            callback('郵便番号を正しく入力してください。');
                        } else {
                            callback();
                        }
                    },
                },
            ],
            prefectureCode: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.company.location.prefecture?.code?.trim()) {
                            callback('都道府県を選択してください。');
                        } else {
                            callback();
                        }
                    },
                },
            ],
            city: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.company.location.city?.trim()) {
                            callback('市区町村名を入力してください。');
                        } else if (this.company.location.city.length > 200) {
                            callback('市区町村は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    },
                },
            ],
            address: [
                {
                    required: true,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (!this.company.location.address?.trim()) {
                            callback('番地・建物を入力してください。');
                        } else if (this.company.location.address.length > 200) {
                            callback('番地・建物は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    },
                },
            ],
            truckCount: [
                {
                    required: true,
                    whitespace: true,
                    message: 'トラック保有台数を入力してください。',
                },
                {
                    pattern: /^[0-9]+$/,
                    message: 'トラック保有台数は数字で入力してください。',
                },
                {
                    max: Const.MAX_TRUCK_COUNT_DIGITS,
                    message: 'トラック保有台数は最大5桁で入力してください。',
                },
            ],
            url: [
                {
                    required: false,
                    validator: (_rule, _value, callback: ((message?: string) => void)) => {
                        if (_.isNil(this.company.url)) {
                            return callback();
                        }

                        const validated = Validator.validateUrl(this.company.url);
                        if (!validated.result) {
                            callback(validated.message);
                        } else {
                            callback();
                        }
                    },
                },
            ],
        };
    }
}

export interface AccountVerificationForm {
    verificationCode: string;
}

export interface AccountRegisterFormCompany {
    companyName?: string;
    zipCode?: string;
    location: AccountRegisterFormLocation;
    phone: AccountRegisterFormPhone;
    truckCount?: string;
    url?: string;
}

export interface AccountRegisterFormPhone {
    number?: string;
    faxNumber?: string;
}

export interface AccountRegisterFormLocation {
    prefecture?: Enum;
    city?: string;
    address?: string;
}

export interface AccountRegisterFormMember {
    emailAddress?: string;
    name?: string;
}

export interface AccountRegisterFormTrace {
    entryReferrer?: string;
}

export interface LoginForm {
    emailAddress?: string;
    password?: string;
}

export interface AccountPasswordUpdateForm {
    password?: string;
    verificationCode?: string;
}

export class AccountPasswordUpdateFormModel implements FormValidatable<AccountPasswordUpdateFormModel> {
    password: string = '';
    passwordConfirm: string = '';

    validator(): FormValidator<AccountPasswordUpdateFormModel> {
        return {
            password: [
                {
                    required: true,
                    validator: (_rule, _value, callback: (message?: string) => void) => {
                        const validated = Validator.validatePasswordFormat(this.password);
                        if (validated.result) {
                            callback();
                        } else {
                            callback(validated.message);
                        }
                    },
                },
            ],
            passwordConfirm: [
                {
                    required: true,
                    validator: (_rule, _value, callback: (message?: string) => void) => {
                        if (this.password !== this.passwordConfirm) {
                            callback('確認のため同じパスワードをもう一度入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
        };
    }
}

export interface AccountProfileCreateForm {
    name: string;
    emailAddress: string;
    position: { code: string };
}

export interface AccountProfileCreateOrUpdateForm {
    name: string;
    email: string;
    position: { code: string };
}

// パスワード再設定フォーム
export interface AccountPasswordRemindForm {
    emailAddress: string;
}

export class AccountPasswordRemindFormModel implements FormValidatable<AccountPasswordRemindFormModel>{
    _emailAddress: string = '';

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value.trim();
    }

    validator(): FormValidator<AccountPasswordRemindFormModel> {
        return {
            emailAddress: [
                {
                    required: true,
                    validator: (_rule, _value, callback: (message?: string) => void) => {
                        const validated = Validator.validateEmail(this.emailAddress);
                        if (!validated.result) {
                            callback(validated.message);
                        } else {
                            callback();
                        }
                    },
                }
            ],
        };
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

export interface AccountPasswordResetVerificationForm {
    verificationCode: string;
}

export interface AccountPasswordResetForm {
    verificationCode: string;
    password: string;
}

export class AccountPasswordResetFormModel implements FormValidatable<AccountPasswordResetFormModel> {
    password: string = '';
    passwordConfirm: string = '';

    validator(): FormValidator<AccountPasswordResetFormModel> {
        return {
            password: [
                {
                    required: true,
                    validator: (_rule, _value, callback: (message?: string) => void) => {
                        const validated = Validator.validatePasswordFormat(this.password);
                        if (validated.result) {
                            callback();
                        } else {
                            callback(validated.message);
                        }
                    },
                },
            ],
            passwordConfirm: [
                {
                    required: true,
                    validator: (_rule, _value, callback: (message?: string) => void) => {
                        if (this.password !== this.passwordConfirm) {
                            callback('確認のため同じパスワードをもう一度入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
        };
    }
}

export interface AccountChangeEmailVerificationForm {
    verificationCode: string;
}

export interface AccountNewMemberResult {
    result: string;
}
