<script setup lang="ts">
import DrawerLayout from '@/_components/ui/layouts/DrawerLayout.vue';
import { computed, watchEffect } from 'vue';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import CompanySummary from '@/_components/parts/CompanySummary.vue';
import { useRouting } from '@/composables/helper/routing';
import DrawerContentLayout from '@/_pages/Baggage/List/drawers/common/DrawerContentLayout.vue';

const props = defineProps<{
    visible: boolean;
    companyId?: number;
}>();

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'clickContact', companyId: number): void;
}>();

const {
    state: { profile, loading: loadingProfile },
    load: loadProfile,
    clear: clearProfile,
} = useCompanyProfile();

const {
    state: { confidence, loading: loadingConfidence },
    load: loadConfidence,
    clear: clearConfidence,
} = useCompanyConfidence();

const {
    state: { statistics, loading: loadingStatistics },
    load: loadStatistics,
    clear: clearStatistics,
} = useCompanyStatistics();

const { openCompanyPrint } = useRouting();

watchEffect(async () => {
    if (props.companyId === undefined) {
        clearProfile();
        clearConfidence();
        clearStatistics();
    } else {
        await loadProfile(props.companyId);
        await loadConfidence(props.companyId);
        await loadStatistics(props.companyId);
    }
});

const loading = computed(() => {
    return loadingProfile.value || loadingConfidence.value || loadingStatistics.value;
});

const onClickContact = () => {
    if (props.companyId) {
        emit('clickContact', props.companyId);
    }
};

const onClickPrint = () => {
    if (props.companyId !== undefined) {
        openCompanyPrint(props.companyId);
    }
};

</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0 }"
              :mask="false"
              :destroy-on-close="true"
              :closable="false">
        <drawer-layout @close="emit('close')">
            <template #header>
                <div class="company-drawer__header">
                    <div class="company-drawer__header__title">
                        <a-button type="link" @click="emit('close')"><a-icon type="left" />一覧に戻る</a-button>
                    </div>
                    <a-button class="company-drawer__header__print" @click="onClickPrint">印刷</a-button>
                </div>
            </template>
            <drawer-content-layout :loading="loading">
                <company-summary :profile="profile"
                                 :confidence="confidence"
                                 :statistics="statistics"
                                 :hideContact="true"
                                 :enable-name-link="false"
                                 @clickContact="onClickContact"></company-summary>
            </drawer-content-layout>
        </drawer-layout>

    </a-drawer>
</template>

<style scoped lang="less">
.company-drawer {
    &__header {
        display: flex;
        align-items: center;
        flex-grow: 1;

        &__title {
            flex: 1;
            font-size: 16px;
            color: @heading-color;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__print {
            margin-left: auto;
        }
    }
}
</style>
