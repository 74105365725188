<script setup lang="ts">
import { TruckModelGroupEnum, TruckModelGroupEnumCode } from '@/enums/truck-model-group.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { computed, ref } from 'vue';
import _ from 'lodash';

type CheckedValue = TruckModelGroupEnumCode | TruckModelEnumCode;

interface TruckModelTreeItem {
    title: string;
    key: CheckedValue;
    children?: Array<{ title: string; key: string }>;
}

const props = withDefaults(defineProps<{
    value?: Array<TruckModelEnumCode>,
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    ellipsis?: boolean, // タグが1行に収まらない場合は「・・・」の省略記号を表示するか否か（複数選択モードでのみ機能します）
    placeholder?: string, // select メニューのプレースホルダ
    placement?: string, // ドロップダウン内のコンテンツを表示する位置
}>(), {
    ellipsis: true,
    placeholder: '車種',
    placement: 'bottomLeft',
});
const emits = defineEmits<{ (e: 'input', newValues: TruckModelEnumCode[]): void }>();

const isMenuOpen = ref<boolean>(false); // ドロップダウンメニューの開閉状態
const tags = ref<Element | undefined>();
// トラック車種ツリー
const treeData = computed(() => {
    /**
     * TruckModelGroupEnumをTreeDataItemにマップします。
     */
    const mapToTreeItem = (truckModel: TruckModelGroupEnum): TruckModelTreeItem => {
        return {
            title: truckModel.label,
            key: truckModel.code,
            children: truckModel.truckModels.map((each) => {
                const truckModel = TruckModelEnum.valueOf(each);
                if (!truckModel) {
                    throw new Error('given value is not defined. [value] => ' + each);
                }
                return {
                    title: truckModel.label,
                    key: truckModel.code,
                };
            }),
        };
    };
    return {
        flatVan: [TruckModelGroupEnum.Flat, TruckModelGroupEnum.Van].map((each) => mapToTreeItem(each)),
        others: [
            mapToTreeItem(TruckModelGroupEnum.Wing),
            {
                title: TruckModelEnum.Unic.label,
                key: TruckModelEnum.Unic.code,
            },
            {
                title: TruckModelEnum.Freezer.label,
                key: TruckModelEnum.Freezer.code,
            },
            {
                title: TruckModelEnum.Refrigerator.label,
                key: TruckModelEnum.Refrigerator.code,
            },
            {
                title: TruckModelEnum.Other.label,
                key: TruckModelEnum.Other.code,
            },
        ],
    };
});
const selectStyleClass = computed(() => {
    return {
        'ant-select-lg': props.size === 'large',
        'ant-select-sm': props.size === 'small',
        'ant-select-default': props.size === 'default',
        'ant-select-open': isMenuOpen.value,
    };
});
const labels = computed(() => {
    if (!props.value) {
        return [];
    }
    const selectedTruckModelGroups = TruckModelGroupEnum.values.filter((each) =>
        each.truckModels.every((tmCode) => props.value && props.value.includes(tmCode))
    );
    const selectedValue = _.difference(props.value, ...selectedTruckModelGroups.map((each) => each.truckModels));
    return [
        ...selectedTruckModelGroups,
        ...selectedValue.map((value) => {
            const truckModel = TruckModelEnum.valueOf(value);
            if (!truckModel) {
                throw new Error('given value is not defined. [value] => ' + value);
            }
            return truckModel;
        }),
    ];
});
const checkedKeysForFlatVanTree = computed({
    get: () => {
        if (!props.value) {
            return [];
        }
        const selectedTruckModelGroups = TruckModelGroupEnum.values
            .filter((each) => each.truckModels.every((tmCode) => props.value && props.value.includes(tmCode)))
            .map((each) => each.code);

        return _.concat(
            _.intersection(selectedTruckModelGroups, [TruckModelGroupEnum.Flat.code, TruckModelGroupEnum.Van.code]),
            _.intersection(props.value, [
                ...TruckModelGroupEnum.Flat.truckModels,
                ...TruckModelGroupEnum.Van.truckModels,
            ])
        );
    },
    set: (values: CheckedValue[]) => {
        // 2つのツリーの値を結合して、TruckModelGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, checkedKeysForOthersTree.value),
            ...TruckModelGroupEnum.values.map((each) => each.code)
        ) as TruckModelEnumCode[];
        emits('input', _.uniqBy(newValues, each => each));
    },
});
const checkedKeysForOthersTree = computed({
    get: () => {
        if (!props.value) {
            return [];
        }

        const isWingTruckAllSelected = TruckModelGroupEnum.Wing.truckModels.every(
            (each) => props.value && props.value.includes(each)
        );
        // exclude flat and van truck models
        const others = _.difference(props.value, [
            ...TruckModelGroupEnum.Flat.truckModels,
            ...TruckModelGroupEnum.Van.truckModels,
        ]);
        return _.concat(isWingTruckAllSelected ? [TruckModelGroupEnum.Wing.code] : [], others);
    },
    set: (values: CheckedValue[]) => {
        // 2つのツリーの値を結合して、TruckModelGroupEnumのvalueがあれば除外
        const newValues = _.without(
            _.concat(values, checkedKeysForFlatVanTree.value),
            ...TruckModelGroupEnum.values.map((each) => each.code)
        ) as TruckModelEnumCode[];

        emits('input', _.uniqBy(newValues, each => each));
    },
});
/**
 * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
 */
const isOverflowTag = (index: number): boolean => {
    if (!props.ellipsis) {
        return false;
    }
    const overflowIndex = getOverflowIndex(index);
    return overflowIndex > 0 && index >= overflowIndex;
};
/**
 * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
 */
const getOverflowIndex = (index: number): number => {
    if (index < 0) {
        return -1;
    }
    const tagsElement = tags.value;
    if (!tagsElement) {
        return -1;
    }
    const tagBaseWidth = props.size === 'large' ? 36 : 32;
    const tagSpacing = 4;
    const dropdownIconWidth = 16;
    const widthByLabels = labels.value.map((each) => {
        return tagSpacing + tagBaseWidth + each.label.length * 14;
    });
    return widthByLabels.findIndex(
        (_each, labelIndex, array) =>
            _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
    );
};
/**
 * 選択ラベルをクリックすると呼び出されます。
 */
const onClickChoiceContent = (e: Event): void => {
    // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    e.stopPropagation();
};
/**
 * 画面左側（平・箱）のツリーでチェック操作が行われた際に呼び出されます。
 */
const onCheckFlatVanTree = (checkedKeys: Array<CheckedValue>): void => {
    checkedKeysForFlatVanTree.value = checkedKeys;
};
/**
 * 画面右側（平・箱以外）のツリーでチェック操作が行われた際に呼び出されます。
 */
const onCheckOthersTree = (checkedKeys: Array<CheckedValue>): void => {
    checkedKeysForOthersTree.value = checkedKeys;
};
/**
 * 選択した項目（車種）を取り除きます。
 */
const removeItem = (event: Event, value: TruckModelEnum | TruckModelGroupEnum): void => {
    event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
    const newValues = _.difference(
        props.value ?? [],
        value instanceof TruckModelGroupEnum ? value.truckModels : [value.code]
    );
    emits('input', newValues);
};
/**
 * 確定ボタンを押下すると呼び出されます。
 */
const onClickOk = (): void => {
    isMenuOpen.value = false;
};
</script>
<template>
    <a-dropdown class="ui-tree-select" v-model="isMenuOpen" :trigger="['click']" :placement="placement">
        <div class="ant-select ant-select-enabled" :class="selectStyleClass">
            <div class="ant-select-selection ant-select-selection--multiple">
                <div class="ant-select-selection__rendered" ref="tags">
                    <!-- placeholder -->
                    <div class="ant-select-selection__placeholder"
                         :style="{ display: value.length > 0 ? 'none' : 'block' }"
                         style="user-select: none;">{{ placeholder }}
                    </div>
                    <!-- labels for selected value -->
                    <ul class="ui-tree-select__tags"
                        :class="{'ui-tree-select__tags--overflow': ellipsis && getOverflowIndex(labels.length - 1) > 0 }">
                        <li v-for="(item, itemIndex) in labels"
                            :key="item.code"
                            class="ant-select-selection__choice ui-tree-select__tags__item"
                            :style="{ display: isOverflowTag(itemIndex) ? 'none' : 'list-item' }"
                            style="user-select: none;">
                            <div class="ant-select-selection__choice__content" @click="onClickChoiceContent">
                                {{ item.label }}
                            </div>
                            <span class="ant-select-selection__choice__remove" @click="removeItem($event, item)">
                            <a-icon class="ant-select-arrow-icon" type="close"/>
                        </span>
                        </li>
                        <li v-if="ellipsis" class="ui-tree-select__tags__item ui-tree-select__tags__item--ellipsis">…
                        </li>
                    </ul>
                </div>
                <span class="ant-select-arrow"><a-icon class="ant-select-arrow-icon" type="down"/></span>
            </div>
        </div>
        <!-- Dropdown Contents -->
        <template #overlay>
            <div class="dropdown-container">
                <div class="tree-container">
                    <a-tree class="tree"
                            v-model="checkedKeysForFlatVanTree"
                            :tree-data="treeData.flatVan"
                            :checkable="true"
                            :selectable="false"
                            :default-expand-all="true"
                            @check="onCheckFlatVanTree"/>
                    <a-tree class="tree"
                            v-model="checkedKeysForOthersTree"
                            :tree-data="treeData.others"
                            :checkable="true"
                            :selectable="false"
                            :default-expand-all="true"
                            @check="onCheckOthersTree"/>
                </div>
                <footer class="footer">
                    <nav>
                        <ul class="footer__actions">
                            <li class="footer__actions__ok">
                                <a-button type="primary" @click="onClickOk">確定</a-button>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </div>
        </template>
    </a-dropdown>
</template>
<style scoped lang="less">
.ui-tree-select.ant-select {
    width: 100%;
    height: 100%;

    > .ant-select-selection {
        height: 100%;

        &.ant-select-selection--multiple .ant-select-selection__clear,
        &.ant-select-selection--multiple .ant-select-arrow {
            top: 50%;
        }

        .ant-select-selection__choice__remove {
            &:hover {
                opacity: 0.75;
            }
        }

        .ant-select-selection__placeholder {
            height: 24px;
            line-height: 24px;
            margin-top: -12px;
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    &.ant-select-sm {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 26px;
            }
        }
    }

    &.ant-select-default {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 30px;
            }
        }
    }

    &.ant-select-lg {
        > .ant-select-selection {
            .ant-select-selection__rendered {
                line-height: 38px;
            }
        }
    }
}

.dropdown-container {
    border-radius: @border-radius-base;
    background-color: @select-background;
    box-shadow: @box-shadow-base;
}

.tree-container {
    display: flex;

    > .tree {
        width: 240px;
        padding: (@padding-xs / 2) @padding-xs;
    }

    > .tree + .tree {
        border-left: @border-width-base @border-style-base @border-color-base;
    }

    > .tree:last-child {
        padding-bottom: 48px;
    }
}

.ui-tree-select__tags {
    position: relative;
    top: 3px;
    display: inline-flex;
    overflow-x: hidden;

    .ui-tree-select__tags__item {
        flex: none;
        margin-top: 0;

        &.ui-tree-select__tags__item--overflow {
            display: none;
        }

        &.ui-tree-select__tags__item--ellipsis {
            display: none;
            color: @color-trabox-green-8;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
        }
    }

    &.ui-tree-select__tags--overflow {
        .ui-tree-select__tags__item.ui-tree-select__tags__item--ellipsis {
            display: list-item;
        }
    }
}

.footer {
    border-top: @border-style-base @border-width-base @color-neutral-5;
    padding: 10px 12px;

    &__actions {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        &__ok {
            margin-left: auto;
        }
    }
}
</style>
