import { CompanyContract } from '@/models/company';
import dayjs from 'dayjs';
import { DateUtil, Util } from '@/util';
import { ProductEnum } from '@/enums/product.enum';
import { DateValue } from '@/models/vo/date';
import { DateTimeValue } from '@/models/vo/datetime';

const THE_END_OF_WORLD = DateTimeValue.theEndOfWorld();

export class CompanyContractModel {
    id: number;
    companyId: number;
    applicationId: number;
    memberId: number;
    product: ProductEnum;
    startTm: string;
    endTm: string;
    billingStart?: string;
    billingEnd?: string;
    billingPrice?: { price: number, taxPrice: number, total: number };

    constructor(data: CompanyContract) {
        this.id = data.id;
        this.companyId = data.companyId;
        this.applicationId = data.applicationId;
        this.memberId = data.memberId;
        this.product = Util.requireNotNull(ProductEnum.valueOf(data.product.code));
        this.startTm = data.startTm;
        this.endTm = data.endTm;
        this.billingStart = data.billingStart;
        this.billingEnd = data.billingEnd;
        this.billingPrice = data.billingPrice;
    }

    /**
     * 開始日を取得します。
     */
    get startDate(): DateValue {
        return new DateTimeValue(this.startTm).toDate();
    }

    /**
     * サービス開始日時を取得します。
     */
    get activeStartTm(): DateTimeValue {
        return this.product.calculateActiveStartTm(new DateTimeValue(this.startTm));
    }

    /**
     * 終了日を取得します。
     */
    get endDate(): DateValue {
        return new DateTimeValue(this.endTm).toDate();
    }

    /**
     * 有効であるかを取得します。
     */
    isAlive(criteria: dayjs.Dayjs): boolean {
        const start = DateUtil.parseDatetimeText(this.startTm);
        const end = DateUtil.parseDatetimeText(this.endTm);

        return start.isSameOrBefore(criteria) && criteria.isSameOrBefore(end);
    }

    /**
     * サービスが有効であるかを取得します。
     */
    isActive(criteria: dayjs.Dayjs): boolean {
        const start = this.activeStartTm.value;
        const end = DateUtil.parseDatetimeText(this.endTm);

        return start.isSameOrBefore(criteria) && criteria.isSameOrBefore(end);
    }

    /**
     * サービスが有効であったかを取得します。
     */
    wasActive(criteria: dayjs.Dayjs): boolean {
        const start = this.activeStartTm.value;
        const end = DateUtil.parseDatetimeText(this.endTm);

        return start.isSameOrBefore(criteria) && end.isSameOrBefore(criteria) && start.isSameOrBefore(end);
    }

    /**
     * 未来に有効であるかを取得する。（現時点では有効ではない）
     */
    willAlive(criteria: dayjs.Dayjs): boolean {
        const start = DateUtil.parseDatetimeText(this.startTm);
        const end = DateUtil.parseDatetimeText(this.endTm);

        return start.isAfter(criteria) && end.isAfter(criteria);
    }

    /**
     * 過去に有効であるかを取得します。
     */
    isPast(criteria: dayjs.Dayjs): boolean {
        const start = DateUtil.parseDatetimeText(this.startTm);
        const end = DateUtil.parseDatetimeText(this.endTm);

        return start.isBefore(criteria) && end.isBefore(criteria);
    }

    /**
     * 契約が終了予定か否かを取得します。
     */
    get willTerminate(): boolean {
        return new DateTimeValue(this.endTm).isBefore(THE_END_OF_WORLD);
    }

    /**
     * プレミアムプランであるか否かを取得します。
     */
    get isPremiumPlan(): boolean {
        return this.product.isPremium;
    }

    /**
     * プレミアムプラン（有料別途課金）であるか否かを取得します。
     */
    get isPremiumPlanX(): boolean {
        return this.product.isPremiumX;
    }

    /**
     * プレミアム 1月次契約であるか否かを取得します。
     */
    get isPremium1mPlan(): boolean {
        return this.product.isPremium1m;
    }

    /**
     * プレミアム 有料契約であるか否かを取得します。
     */
    get isPaidPremiumPlan(): boolean {
        return this.product.isPaidPremium;
    }

    /**
     * 14日間お試し契約であるか否かを取得します。
     */
    get isTrial(): boolean {
        return this.product.isTrial;
    }

    /**
     * 14日間お試しアカウント契約であるか否かを取得します。
     */
    get isTrialAccount14d(): boolean {
        return this.product.isTrialAccount;
    }

    /**
     * アカウント契約であるか否かを取得します。
     */
    get isAccount(): boolean {
        return this.product.isAccount;
    }

    /**
     * 有料別途課金アカウント契約であるか否かを取得します。
     */
    get isAccountX(): boolean {
        return this.product.isAccountX;
    }

    /**
     * 無料アカウント契約であるか否かを取得します。
     */
    get isAccountFree(): boolean {
        return this.product.isAccountFree;
    }

    /**
     * アカウント割引契約であるか否かを取得します。
     */
    get isAccountDiscount(): boolean {
        return this.product.isAccountDiscount;
    }

    /**
     * 運賃全額保証契約であるか否かを取得します。
     */
    get isGuarantee(): boolean {
        return this.product.isGuarantee;
    }

    /**
     * トラボックス配車であるか否かを取得します。
     */
    get isTraboxTms(): boolean {
        return this.product.isTraboxTms;
    }

    /**
     * プライム荷主会員であるか否かを取得します。
     */
    get isPrimeBaggageCompany(): boolean {
        return this.product.isPrimeBaggageCompany;
    }

    /**
     * 請求書発行手数料契約であるか否かを取得します。
     */
    get isInvoice(): boolean {
        return this.product.isInvoice;
    }

    /**
     * NTT請求手数料であるか否かを取得します。
     */
    get isInvoiceNtt(): boolean {
        return this.product.isInvoiceNtt;
    }

    /**
     * NTT請求手数料（有料別途課金期間）であるか否かを取得します。
     */
    get isInvoiceNttX(): boolean {
        return this.product.isInvoiceNttX;
    }

    /**
     * おまかせ請求であるか否かを取得します。
     */
    get isEasyPayment(): boolean {
        return this.product.isEasyPayment;
    }

    /**
     * 請求期間（開始日）を取得します。
     */
    get billingStartDate(): DateValue | undefined {
        if (!this.billingStart) return;
        return new DateValue(this.billingStart);
    }

    /**
     * 請求期間（終了日）を取得します。
     */
    get billingEndDate(): DateValue | undefined {
        if (!this.billingEnd) return;
        return new DateValue(this.billingEnd);
    }

    /**
     * 請求金額を取得します。
     */
    get billingTotalPrice(): number | undefined {
        if (!this.billingPrice) return;
        return this.billingPrice.total;
    }
}
