import { injectStrictly } from '@/util/vue';
import { COMPANY_DRAWER_PROVIDER_KEY } from '@/composables/provider/company-drawer-provider';

export const useCompanyDrawerHelper = () => {
    const {
        state: {
            loading,
            profile,
            officialCompany,
            confidence,
            statistics,
            baggageList,
            truckList
        },
        load,
        onClickPrint,
    } = injectStrictly(COMPANY_DRAWER_PROVIDER_KEY);

    return {
        loading,
        profile,
        officialCompany,
        confidence,
        statistics,
        baggageList,
        truckList,
        load,
        onClickPrint,
    };
};
