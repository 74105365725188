import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { BaggageDetailUtil } from '@/util';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';

@Component
export default class AgreementType extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get hasChangeRequest(): boolean {
        if (!this.agreement || !this.agreementCr) return false;
        return !!this.changeRequest ||
            !!this.paletteCountChangeRequest ||
            !!this.paletteSizeChangeRequest ||
            !!this.totalCountChangeRequest ||
            !!this.totalVolumeChangeRequest ||
            !!this.totalWeightChangeRequest ||
            !!this.handlingChangeRequest;
    }

    get changeRequest(): string {
        if (!this.agreement || !this.agreementCr) return '';
        return this.agreement.type != this.agreementCr.type ? this.agreementCr.type : '';
    }

    get paletteCount(): string | undefined {
        return BaggageDetailUtil.paletteCount(
            this.agreement?.shape?.code,
            this.agreement?.paletteCount
        );
    }

    get paletteCountChangeRequest(): string | undefined {
        if (this.agreementCr?.shape?.code !== BaggageShapeEnum.Palette.code) return;
        const changeRequest = BaggageDetailUtil.paletteCount(
            this.agreementCr?.shape?.code,
            this.agreementCr?.paletteCount
        );
        if (changeRequest === this.paletteCount) return undefined;
        return changeRequest ?? 'パレット枚数（目安）：-';
    }

    get paletteSize(): string | undefined {
        return BaggageDetailUtil.paletteSize(
            this.agreement?.shape?.code,
            this.agreement?.paletteHeight,
            this.agreement?.paletteWidth
        );
    }

    get paletteSizeChangeRequest(): string | undefined {
        if (this.agreementCr?.shape?.code !== BaggageShapeEnum.Palette.code) return;
        const changeRequest = BaggageDetailUtil.paletteSize(
            this.agreementCr?.shape?.code,
            this.agreementCr?.paletteHeight,
            this.agreementCr?.paletteWidth
        );
        if (changeRequest === this.paletteSize) return undefined;
        return changeRequest ?? 'パレットサイズ：-';
    }

    get totalCount(): string | undefined {
        return BaggageDetailUtil.totalCount(
            this.agreement?.shape?.code,
            this.agreement?.totalCount
        );
    }

    get totalCountChangeRequest(): string | undefined {
        if (this.agreementCr?.shape?.code !== BaggageShapeEnum.Other.code) return;
        const changeRequest = BaggageDetailUtil.totalCount(
            this.agreementCr?.shape?.code,
            this.agreementCr?.totalCount
        );
        if (changeRequest === this.totalCount) return undefined;
        return changeRequest ?? '荷物の個数（目安）：-';
    }

    get totalVolume(): string | undefined {
        return BaggageDetailUtil.totalVolume(
            this.agreement?.shape?.code,
            this.agreement?.totalVolume
        );
    }

    get totalVolumeChangeRequest(): string | undefined {
        if (this.agreementCr?.shape?.code !== BaggageShapeEnum.Other.code) return;
        const changeRequest = BaggageDetailUtil.totalVolume(
            this.agreementCr?.shape?.code,
            this.agreementCr?.totalVolume
        );
        if (changeRequest === this.totalVolume) return undefined;
        return changeRequest ?? '荷物の体積：-';
    }

    get totalWeight(): string | undefined {
        return BaggageDetailUtil.totalWeight(this.agreement?.totalWeight);
    }

    get totalWeightChangeRequest(): string | undefined {
        const changeRequest = BaggageDetailUtil.totalWeight(this.agreementCr?.totalWeight);
        if (changeRequest === this.totalWeight) return undefined;
        return changeRequest ?? '総重量：-';
    }

    get handling(): string | undefined {
        return BaggageDetailUtil.handling(
            this.agreement?.loading?.code,
            this.agreement?.unloading?.code
        );
    }

    get handlingChangeRequest(): string | undefined {
        const changeRequest = BaggageDetailUtil.handling(
            this.agreementCr?.loading?.code,
            this.agreementCr?.unloading?.code
        );
        if (changeRequest === this.handling) return undefined;
        return changeRequest ?? 'ドライバー作業：-';
    }
}
