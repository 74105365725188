import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportLatePaymentForm } from '@/vuex/modules/help/types';

@Component
export default class ReportLatePaymentTargetCompanyNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: ReportLatePaymentForm;

    get targetCompanyName(): string {
        return this.value?.targetCompanyName ?? '';
    }

    set targetCompanyName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.targetCompanyName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '入金遅延先会社名を入力してください',
        },
        {
            max: 200,
            message: '200文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.targetCompanyName = this.targetCompanyName.trim();
    }
}
