import { useBaggage } from '@/composables/baggage';
import { useBaggageFavorite } from '@/composables/baggage-favorite';
import { useBaggageFreightMaster } from '@/composables/baggage-freight-master';
import { useBaggageMarkFavorite } from '@/composables/baggage-mark-favorite';
import { useBaggageMarkRead } from '@/composables/baggage-mark-read';
import { useBaggageMarkNegotiation } from '@/composables/baggage-negotiation';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { useLoading } from '@/composables/helper/loading-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { BaggageFavoriteState, BaggageFreightMasterQueryForm } from '@/models/baggage';
import { BaggageUtil } from '@/util';
import { computed, InjectionKey } from 'vue';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import _ from 'lodash';
import { useNegotiationRequest } from '@/composables/negotiation-request';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { useNegotiationQuery } from '@/composables/negotiation-query';
import { useOfficialCompany } from '@/composables/company-official';

/**
 * 荷物ドロワー用のデータを纏めて受け渡しする機能を提供します。
 */
export const useBaggageDrawerProvider = () => {
    // 必要なComposables
    const { state: { myCompanyId } } = useCompanyMyProfile();
    const { state: { baggage }, load: loadBaggage, clear: clearBaggage, changeNegotiation } = useBaggage();
    const { state: { profile }, load: loadProfile, clear: clearProfile } = useCompanyProfile();
    const { state: { officialCompany }, load: loadOfficialCompany, clear: clearOfficialCompany } = useOfficialCompany();
    const { state: { confidence }, load: loadConfidence, clear: clearConfidence } = useCompanyConfidence();
    const { state: { statistics }, load: loadStatistics, clear: clearStatistics } = useCompanyStatistics();
    const {
        state: { marked: favoriteMarked },
        load: loadBaggageFavorite,
        clear: clearBaggageFavorite
    } = useBaggageFavorite();
    const {
        state: { freightMaster: referenceFreight },
        load: loadBaggageFreightMaster,
        clear: clearBaggageFreightMaster
    } = useBaggageFreightMaster();
    const { markRead } = useBaggageMarkRead();
    const {
        mark: markFavorite,
        unmark: unmarkFavorite,
        cancelUnmark: cancelUnmarkFavorite,
        unmarkCancellable: unmarkFavoriteCancellable,
        canCancel: canCancelUnmarkFavorite,
    } = useBaggageMarkFavorite();
    const { markNegotiation, unmarkNegotiation, } = useBaggageMarkNegotiation();
    const {
        state: {
            form: negotiationRequestForm,
            formValidateRules: negotiationRequestFormValidateRules,
        },
        request: requestNegotiation,
        updateMessage: updateNegotiationRequestMessage,
    } = useNegotiationRequest();
    const { state: { negotiation }, load: loadNegotiationQuery } = useNegotiationQuery();
    // helper
    const { goToCompanyDetail, goToAgreementRegister, openBaggagePrint } = useRouting();
    const { state: { loading }, withLoading } = useLoading();
    const message = useMessage();

    /**
     * 荷物の保存状態を取得します。
     */
    const favoriteState = computed<BaggageFavoriteState | undefined>(() => {
        if (!baggage.value) return undefined;
        const baggageId = baggage.value.id;
        if (BaggageUtil.isReadyToAgree(baggage.value, myCompanyId.value) && !favoriteMarked.value) {
            // 未保存
            return 'Unmarked';
        } else if (favoriteMarked.value && !canCancelUnmarkFavorite(baggageId)) {
            // 保存済
            return 'Marked';
        } else if (favoriteMarked.value && canCancelUnmarkFavorite(baggageId)) {
            // 保存を解除中
            return 'Unmarking';
        } else {
            // 保存不可の荷物
            return undefined;
        }
    });

    /**
     * 自分の荷物かどうか取得します
     */
    const isMyCompanyBaggage = computed<boolean>(() => {
        return myCompanyId.value === baggage.value?.companyId;
    });

    /**
     * 荷物が成約可能かどうか取得します
     */
    const canAgree = computed<boolean>(() => {
        if (!baggage.value) return false;
        return BaggageUtil.isReadyToAgree(baggage.value, myCompanyId.value);
    });

    /**
     * 「商談中にする」ことが可能かどうか取得します。
     */
    const canMarkNegotiation = computed<boolean>(() => {
        return isMyCompanyBaggage.value && !underNegotiation.value && baggageStatus.value === BaggageStatusEnum.Opened;
    });

    /**
     * 「商談中を解除する」ことが可能かどうか取得します。
     */
    const canUnmarkNegotiation = computed<boolean>(() => {
        return isMyCompanyBaggage.value && underNegotiation.value;
    });

    /**
     * 荷物ステータスを取得します。
     */
    const baggageStatus = computed<BaggageStatusEnum | undefined>(() => {
        if (!baggage.value) return undefined;
        return BaggageStatusEnum.valueOf(baggage.value.status.code);
    });

    const underNegotiation = computed<boolean>(() => baggage.value?.underNegotiation ?? false);

    /**
     * 企業名
     */
    const companyName = computed<string | undefined>(() => {
        return profile.value?.name.kanji ?? '';
    });

    /**
     * 荷物IDと保存状態のペア
     */
    const baggageFavorite = computed<{ baggageId: number, favorite: boolean, state?: BaggageFavoriteState } | undefined>(() => {
        if (loading.value) return undefined;
        if (_.isNil(baggage.value) || _.isNil(favoriteMarked.value)) {
            return undefined;
        }
        return { baggageId: baggage.value.id, favorite: favoriteMarked.value, state: favoriteState.value };
    });

    /**
     * 荷物IDと商談中状態のペア
     */
    const baggageUnderNegotiation = computed<{ baggageId: number, underNegotiation: boolean } | undefined>(() => {
        if (loading.value) return undefined;
        if (_.isNil(baggage.value) || _.isNil(underNegotiation.value)) {
            return undefined;
        }
        return { baggageId: baggage.value.id, underNegotiation: underNegotiation.value };
    });

    //
    // ユーザーイベント
    //
    /**
     * 企業名が押下された際に呼び出されます。
     */
    const clickCompany = async (): Promise<void> => {
        profile.value && await goToCompanyDetail(profile.value.id);
    };

    /**
     * 成約が押下された際に呼び出されます。
     */
    const clickAgree = async (): Promise<void> => {
        baggage.value && await goToAgreementRegister(baggage.value.id);
    };

    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    const clickPrint = (): void => {
        baggage.value && openBaggagePrint(baggage.value.id);
    };

    /**
     * お気に入り「保存」ボタンが押下された際に呼び出されます。
     */
    const clickMarkFavorite = async (): Promise<void> => {
        if (baggage.value) {
            try {
                await markFavorite(baggage.value.id);
                await loadBaggageFavorite(baggage.value.id);
            } catch {
                message.error('公開が終了した荷物のため、保存できません。');
            }
        }
    };

    /**
     * お気に入り「保存済」ボタンが押下された際に呼び出されます。
     */
    const clickUnmarkFavorite = async (callback?: (baggageId: number) => Promise<void>): Promise<void> => {
        if (!baggage.value) return;

        if (callback) {
            await unmarkFavoriteCancellable(baggage.value.id, async (baggageId: number) => {
                await loadBaggageFavorite(baggageId);
                await callback(baggageId);
            });
        } else {
            await unmarkFavorite(baggage.value.id);
            await loadBaggageFavorite(baggage.value.id);
        }
    };

    /**
     * お気に入り登録解除の「キャンセル」ボタンが押下された際に呼び出されます。
     */
    const clickCancelUnmarkFavorite = async (): Promise<void> => {
        if (baggage.value) {
            cancelUnmarkFavorite(baggage.value.id);
        }
    };

    /**
     * 商談中にします。
     */
    const clickMarkUnderNegotiation = async () => {
        if (baggage.value) {
            await markNegotiation(baggage.value.id)
                .then(() => changeNegotiation(true))
                .catch(() => message.error('商談中にできませんでした。時間をおいて再度お試しください。'));
        }
    };

    /**
     * 商談中を解除します。
     */
    const clickUnmarkUnderNegotiation = async () => {
        if (baggage.value) {
            await unmarkNegotiation(baggage.value.id)
                .then(() => changeNegotiation(false))
                .catch(() => message.error('商談中にできませんでした。時間をおいて再度お試しください。'));
        }
    };

    /**
     * 荷物詳細関連のデータ一式をロードします。
     */
    const load = async (baggageId: number): Promise<void> => withLoading(async () => {
        await clear();
        await loadBaggage(baggageId);
        if (baggage.value) {
            const companyId = baggage.value.companyId;
            await Promise.all([
                loadProfile(companyId),
                loadOfficialCompany(companyId).catch(() => false),
                loadConfidence(companyId),
                loadStatistics(companyId),
                loadBaggageFavorite(baggageId),
                loadBaggageFreightMaster(BaggageFreightMasterQueryForm.newInstance(baggage.value)),
            ]);
            if (baggage.value.negotiationType.code === NegotiationTypeEnum.Online.code) {
                await loadNegotiationQuery(baggageId);
                if (baggage.value.id === negotiation.value?.baggageId) {
                    // NOTE 回答済の内容を確認できるよう、フォームに値を設定する。
                    negotiationRequestForm.value.reportStatus = negotiation.value.reportStatus;
                    negotiationRequestForm.value.message = negotiation.value.message;
                } else {
                    // NOTE 他の荷物に対する対応可否を設定しないように値を初期化する。
                    negotiationRequestForm.value.reportStatus = undefined;
                    negotiationRequestForm.value.message = undefined;
                }
            }
        }
    }).catch((e) => {
        message.error('アクセスしようとした荷物情報は見つかりません。');
        clear();
    });

    /**
     * お気に入り状態を変更します。
     */
    const changeFavorite = async () => {
        if (baggage.value) {
            await loadBaggageFavorite(baggage.value.id);
        }
    };

    /**
     * 商談リクエストします。
     */
    const clickRequestNegotiation = async () => {
        if (baggage.value) {
            if (negotiation.value) {
                // 対応可否の更新
                await updateNegotiationRequestMessage(
                    baggage.value.id,
                    negotiation.value.id,
                    negotiation.value.messageId
                );
                return;
            }

            await requestNegotiation(baggage.value.id);
            await loadNegotiationQuery(baggage.value.id);
        }
    };

    /**
     * 荷物情報一式をクリアします。
     */
    const clear = () => withLoading(async () => {
        clearBaggage();
        clearProfile();
        clearOfficialCompany();
        clearConfidence();
        clearStatistics();
        clearBaggageFavorite();
        clearBaggageFreightMaster();
    });

    return {
        state: {
            loading,
            baggage,
            baggageFavorite,
            baggageUnderNegotiation,
            baggageStatus,
            isMyCompanyBaggage,
            underNegotiation,
            favoriteState,
            profile,
            officialCompany,
            companyName,
            confidence,
            statistics,
            referenceFreight,
            negotiation,
            canAgree,
            canMarkNegotiation,
            canUnmarkNegotiation,
            negotiationRequestForm,
            negotiationRequestFormValidateRules,
        },
        markRead,
        load,
        clear,
        clickAgree,
        clickPrint,
        clickCompany,
        clickMarkFavorite,
        clickUnmarkFavorite,
        clickCancelUnmarkFavorite,
        clickMarkUnderNegotiation,
        clickUnmarkUnderNegotiation,
        clickRequestNegotiation,
        changeFavorite,
    };
};

export type BaggageDrawerProviderType = ReturnType<typeof useBaggageDrawerProvider>;
export const BAGGAGE_DRAWER_PROVIDER_KEY: InjectionKey<BaggageDrawerProviderType> = Symbol('BaggageDrawerProvider');
