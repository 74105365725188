import _ from 'lodash';
import 'dayjs/locale/ja';
import { Const } from '@/const';

export class Util {
    static isNumeric(value?: string | number): boolean {
        return /^[0-9]+$/.test(`${ value }`);
    }

    static formatNumber(number: number): string {
        return Intl.NumberFormat().format(number);
    }

    static zeroPadding(number: number, length: number): string {
        return (Array(length).join('0') + number).slice(-length);
    }

    static toHalf(value: string): string {
        return String.fromCharCode(value.charCodeAt(0) - 0xfee0);
    }

    static toDigits(value: string): string {
        return value.replace(/[０-９]/g, Util.toHalf).match(/\d+/g)?.join('') ?? '';
    }

    static toNumber(value: string): number {
        const halfDigits = Util.toDigits(value);
        return _.toNumber(halfDigits);
    }

    // eslint-disable-next-line
    static castAsString(value: any): string | undefined {
        return (typeof value === 'string') ? value : undefined;
    }

    static emptyStringToUndefined(value: string): string | undefined {
        return value.length === 0 ? undefined : value;
    }

    /**
     * 運賃の入力値をパースします。
     * @param freightString ユーザーが入力入力した運賃（文字列）
     */
    static parseFreightString(freightString: unknown): string {
        if (typeof freightString !== 'string') {
            return '';
        } else if (freightString.length === 0) {
            return '';
        }
        const inputted = Util.toNumber(freightString);
        if (isNaN(inputted)) {
            return '';
        } else if (inputted > Const.MAX_FREIGHT) {
            return `${ Const.MAX_FREIGHT }`;
        }
        return Util.toDigits(freightString);
    }

    /**
     * 金額の入力値をパースします。上限を超えた場合は上限値を返します。
     * @param amountString
     * @param max
     */
    static parseAmountString(amountString: unknown, max: number): string {
        if (typeof amountString !== 'string') {
            return '';
        } else if (amountString.length === 0) {
            return '';
        }
        const inputted = Util.toNumber(amountString);
        if (isNaN(inputted)) {
            return '';
        } else if (inputted > max) {
            return `${ max }`;
        }
        return Util.toDigits(amountString);
    }

    /**
     * Use this method to remove underscore "_" from prop when serializing Typed Object
     */
    static serialize<T>(obj: T): Record<string, unknown> {
        const serialized: Record<string, unknown> = Object.assign({}, obj);

        Object.getOwnPropertyNames(serialized).filter(each => each.startsWith('_')).map(prop => {
            // 出力フィールド名
            const newName = prop.substring(1, prop.length);
            // ディスクリプタ
            const descriptor = Object.getOwnPropertyDescriptor(serialized, prop);
            if (!descriptor) return;

            Object.defineProperty(serialized, newName, descriptor);
            delete serialized[prop];
        });

        return serialized;
    }

    /**
     * Ensure the existence of value.
     */
    static requireNotNull<T>(value?: T): NonNullable<T> {
        function assertIsDefined<B>(target?: B): asserts target is NonNullable<B> {
            if (target !== undefined && target !== null) return;
            throw new Error(`Expected 'val' to be defined, but received ${ target }`);
        }

        assertIsDefined(value);

        return value;
    }
}

export * from './agreement';
export * from './baggage';
export * from './baggage-detail';
export * from './date';
export * from './guarantee';
export * from './modal';
export * from './page';
export * from './phone';
export * from './product';
export * from './questionnaire';
export * from './region';
export * from './sound';
export * from './url';
export * from './zip';
