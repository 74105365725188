import _ from 'lodash';
import type moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/truck/types';
import { DateUtil, Util } from '@/util';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiTruckModelTreeSelect from '@/components/UI/TruckModelTreeSelect';
// @ts-ignore
import UiTruckWeightTreeSelect from '@/components/UI/TruckWeightTreeSelect';
import { Validator } from '@/validator';

@Component({
    components: {
        UiPrefectureSelect,
        UiTruckModelTreeSelect,
        UiTruckWeightTreeSelect,
    },
})
export default class TruckSearchConditionEdit extends Vue {
    searchableDate = {
        min: DateUtil.now().startOf('day'),
        max: DateUtil.now().add(1, 'year').endOf('year'),
    };
    validationErrorMessage = '';

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.TruckSearchForm;

    /**
     * 発地を取得します。
     */
    get departurePref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.departurePref?.map((each) => each.code) || [];
    }

    /**
     * 発地を設定します。
     */
    set departurePref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.departurePref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 着地を取得します。
     */
    get arrivalPref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.arrivalPref?.map((each) => each.code) || [];
    }

    /**
     * 着地を設定します。
     */
    set arrivalPref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.arrivalPref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 発日（開始）を取得します。
     */
    get departureFrom(): string {
        return this.value?.departureFrom ?? '';
    }

    /**
     * 発日（開始）を設定します。
     */
    set departureFrom(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureFrom = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 発日（終了）を取得します。
     */
    get departureTo(): string {
        return this.value?.departureTo ?? '';
    }

    /**
     * 発日（終了）を設定します。
     */
    set departureTo(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureTo = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 着日（開始）を取得します。
     */
    get arrivalFrom(): string {
        return this.value?.arrivalFrom ?? '';
    }

    /**
     * 着日（開始）を設定します。
     */
    set arrivalFrom(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.arrivalFrom = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 着日（終了）を取得します。
     */
    get arrivalTo(): string {
        return this.value?.arrivalTo ?? '';
    }

    /**
     * 着日（終了）を設定します。
     */
    set arrivalTo(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.arrivalTo = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 運賃を取得します。
     */
    get minFreight(): string {
        return this.value?.minFreight ?? '';
    }

    /**
     * 運賃を設定します。
     */
    set minFreight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.minFreight = Util.parseFreightString(newValue);

        this.$emit('input', cloned);
    }

    /**
     * 「要相談を除く」を取得します。
     */
    get excludeUndecidedFreight(): boolean {
        return this.value?.excludeUndecidedFreight ?? false;
    }

    /**
     * 「要相談を除く」を設定します。
     */
    set excludeUndecidedFreight(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.excludeUndecidedFreight = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 重量を取得します。
     */
    get truckWeight(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.truckWeight?.map((each) => each.code) || [];
    }

    /**
     * 重量を設定します。
     */
    set truckWeight(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.truckWeight = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 車種を取得します。
     */
    get truckModel(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.truckModel?.map((each) => each.code) || [];
    }

    /**
     * 車種を設定します。
     */
    set truckModel(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.truckModel = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * カレンダーUIにおいて、引数に与えられた日付が選択不可の日付かどうかを判定します。
     * 今日〜1年後の年末までが選択可能な範囲としています。
     */
    isDisabledDate(calendarDate: moment.Moment): boolean {
        const calDt = DateUtil.parseDateText(calendarDate.format('YYYY-MM-DD'));
        return calDt.isBefore(this.searchableDate.min) || calDt.isAfter(this.searchableDate.max);
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureFrom = '';
        cloned.departureTo = '';
        cloned.departurePref = [];
        cloned.arrivalFrom = '';
        cloned.arrivalTo = '';
        cloned.arrivalPref = [];
        cloned.truckWeight = [];
        cloned.truckModel = [];
        cloned.minFreight = '';
        cloned.excludeUndecidedFreight = false;
        cloned.sortKey = 'DEPARTURE';
        cloned.sortOrder = 'ASC';
        this.$emit('input', cloned);
    }

    /**
     * 都道府県の入れ替えボタンが押下された際に呼び出されます。
     */
    onClickInvertPref(): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departurePref = this.arrivalPref.map((code) => ({ code }));
        cloned.arrivalPref = this.departurePref.map((code) => ({ code }));
        this.$emit('input', cloned);
    }

    /**
     * 検索が押下された際に呼び出されます。
     */
    onClickSearch(): void {
        if (this.validate()) {
            this.$emit('submit');
        }
    }

    /**
     * バリデーションを行います。
     */
    private validate(): boolean {
        const validated = Validator.validateDateRangeForSearch(
            this.departureFrom,
            this.departureTo,
            this.arrivalFrom,
            this.arrivalTo
        );
        this.validationErrorMessage = validated.result ? '' : validated.message ?? '';
        return validated.result;
    }
}
