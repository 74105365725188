import { TbxRouteConfig } from '@/router/routes/types';
import CompanyPrintPage from '@/pages/Company/Print/index.vue';
import CompanySearchPage from '@/pages/Company/Search/index.vue';

import _CompanySearchPage from '@/_pages/Company/Search/CompanySearch.vue';
import _CompanyPrintPage from '@/_pages/Company/Print/CompanyPrint.vue';

export const companyRoutes: TbxRouteConfig[] = [
    {
        path: '/company/search',
        name: 'CompanySearch',
        component: _CompanySearchPage,
        backComponent: CompanySearchPage,
        meta: { title: '企業検索' },
        props: (route) => ({ companyId: Number(route.query.companyId) }),
    },
    {
        path: '/company/print',
        name: 'CompanyPrintMe',
        component: _CompanyPrintPage,
        backComponent: CompanyPrintPage,
        meta: { layout: { name: 'main', contentOnly: true }, title: '企業情報印刷' },
    },
    {
        path: '/company/:companyId/print',
        name: 'CompanyPrint',
        component: _CompanyPrintPage,
        backComponent: CompanyPrintPage,
        meta: { layout: { name: 'main', contentOnly: true }, title: '企業情報印刷' },
        props: (route) => ({ companyId: Number(route.params.companyId) }),
    },
];
