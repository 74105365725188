import { computed, onBeforeMount, ref, watch } from 'vue';
import { useBaggageBulkRegister } from '@/composables/baggage-bulk-register';
import { useBaggageBulkFormat } from '@/composables/baggage-bulk-register-format';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

export const useBaggageBulkRegisterHelper = () => {
    const fileList = ref<File[]>([]);
    const submittable = ref<boolean>(false);
    const {
        state: { loading, circleId },
        register,
    } = useBaggageBulkRegister();
    const {
        download: downloadFormat,
    } = useBaggageBulkFormat();
    const { state: { myProfile: myCompanyProfile }, load: loadMyCompanyProfile } = useCompanyMyProfile();
    const circleOptions = computed(() => myCompanyProfile.value?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));
    const isMemberOfSingleCircle = computed(() =>
        myCompanyProfile.value?.circles?.length === 1
    );

    const beforeUpload = (file: File) => {
        fileList.value = [file];
        return false;
    };

    const handleRemove = () => {
        fileList.value = [];
    };

    const handleSubmit = async () => {
        await register(fileList.value[0]);
        fileList.value = [];
    };

    const handleDownloadFormat = async () => {
        await downloadFormat();
    };

    const initialize = async () => {
        if (!myCompanyProfile.value) {
            await loadMyCompanyProfile();
        }
        // 部屋は一つしかない場合に設定する
        if (isMemberOfSingleCircle.value) circleId.value = myCompanyProfile.value?.circles?.[0]?.id;
    };

    onBeforeMount(() => initialize());

    watch([fileList, circleId], () => {
        submittable.value = fileList.value.length > 0 && circleId.value !== undefined;
    });

    return {
        fileList,
        submittable,
        loading,
        circleId,
        circleOptions,
        beforeUpload,
        handleRemove,
        handleSubmit,
        handleDownloadFormat,
    };
};
