import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage, BaggageFreightMaster } from '@/vuex/modules/baggage/types';
import { Util } from '@/util';
import { DeliveryDateTime, DeliveryTimeFormats } from '@/models/vo/delivery-datetime';

@Component({})
export default class TransportSummary extends Vue {
    static readonly DATE_FORMAT: string = 'M/D(ddd)';
    static readonly TIME_FORMATS: DeliveryTimeFormats = {
        day: DeliveryDateTime.DEFAULT_TIME_FORMATS.day,
        morning: DeliveryDateTime.DEFAULT_TIME_FORMATS.morning,
        afternoon: DeliveryDateTime.DEFAULT_TIME_FORMATS.afternoon,
        hourly: 'H:mm',
        just: 'H:mm',
    };
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: Baggage;
    @Prop()
    declare readonly referenceFreight?: BaggageFreightMaster;

    get departureDay(): string {
        if (!this.baggage) return '';
        return TransportSummary.day(this.baggage.departureMin, this.baggage.departureMax);
    }

    get departureTime(): string {
        if (!this.baggage) return '';
        return TransportSummary.time(this.baggage.departureMin, this.baggage.departureMax);
    }

    get arrivalDay(): string {
        if (!this.baggage) return '';
        return TransportSummary.day(this.baggage.arrivalMin, this.baggage.arrivalMax);
    }

    get arrivalTime(): string {
        if (!this.baggage) return '';
        return TransportSummary.time(this.baggage.arrivalMin, this.baggage.arrivalMax);
    }

    get departurePref(): string {
        if (!this.baggage || !this.baggage.departurePref.label) return '';
        return this.baggage.departurePref.label;
    }

    get departureCity(): string {
        if (!this.baggage) return '';
        return this.baggage.departureCity;
    }

    get departureAddress(): string {
        if (!this.baggage) return '';
        return this.baggage.departureAddress ?? '';
    }

    get arrivalPref(): string {
        if (!this.baggage || !this.baggage.arrivalPref.label) return '';
        return this.baggage.arrivalPref.label;
    }

    get arrivalCity(): string {
        if (!this.baggage) return '';
        return this.baggage.arrivalCity;
    }

    get arrivalAddress(): string {
        if (!this.baggage) return '';
        return this.baggage.arrivalAddress ?? '';
    }

    get isFreightUndecided(): boolean | undefined {
        if (!this.baggage) return false;
        const freight = this.baggage?.freight ?? 0;
        return freight <= 0;
    }

    get freight(): string {
        if (!this.baggage) return '';
        const freight = this.baggage?.freight ?? 0;
        return freight > 0 ? `￥${Util.formatNumber(freight)}` : '要相談';
    }

    get highwayFare(): string {
        if (!this.baggage) return '';
        return `高速代${this.baggage.highwayFareFlg ? 'あり' : 'なし'}`;
    }

    get referenceFreightText(): string {
        if (!this.referenceFreight) return '-';
        const freight = this.referenceFreight?.freight ?? 0;
        return freight <= 0 ? '算出できませんでした' : `${ Util.formatNumber(freight) }円`;
    }

    private static day(dateMin: string, dateMax: string): string {
        if (!dateMin || !dateMax) return '';
        const deliveryDatetime = DeliveryDateTime.of(dateMin, dateMax);
        if(!deliveryDatetime) return '';
        return deliveryDatetime.date.format(TransportSummary.DATE_FORMAT);
    }

    private static time(dateMin: string, dateMax: string): string {
        if (!dateMin || !dateMax) return '';
        const deliveryDatetime = DeliveryDateTime.of(dateMin, dateMax);
        if(!deliveryDatetime) return '';
        return deliveryDatetime.formatTime(TransportSummary.TIME_FORMATS);
    }
}
