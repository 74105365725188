<script setup lang="ts">
import SearchIncomeConditionForm from '@/_pages/Baggage/List/tabs/components/settlement/SearchIncomeConditionForm.vue';
import SettlementTable from '@/_pages/Baggage/List/tabs/components/settlement/table/IncomeTable.vue';
import SortPaginationControl from '@/_components/ui/SortPaginationControl.vue';
import { useSettlementIncomeListHelper } from './settlement-income-list-helper';
import SettlementDetailDrawer from '@/_pages/Baggage/List/drawers/SettlementDetail/SettlementDetailDrawer.vue';
import SettlementEditorDrawer from '@/_pages/Baggage/List/drawers/SettlementEditor/SettlementEditorDrawer.vue';
import { PreloadProps, DetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/props';

interface Props {
    preload: PreloadProps;
    detail?: DetailProps;
}

const props = defineProps<Props>();

const {
    loading,
    form,
    payeeDateList,
    list,
    pageInfo,
    settlementDrawerVisibility,
    settlementEditorVisibility,
    search,
    reload,
    changePage,
    selectSettlement,
    onCloseDrawer,
    onCloseEditor,
    onChangeDetail,
    onMarkAsConfirmed,
    onUnMarkAsConfirmed,
} = useSettlementIncomeListHelper(props);
</script>

<template>
    <div>
        <search-income-condition-form v-model="form"
                                      :date-list="payeeDateList"
                                      @search="search"/>
        <settlement-table :loading="loading"
                          :list="list"
                          :selected-settlement-id="detail?.settlementId"
                          @selectSettlement="selectSettlement"
                          @markAsConfirmed="onMarkAsConfirmed"
                          @unmarkAsConfirmed="onUnMarkAsConfirmed">

            <template #header>
                <sort-pagination-control :show-sort="false" :page-info="pageInfo" @change="changePage"/>
            </template>
            <template #footer>
                <sort-pagination-control :show-sort="false" :page-info="pageInfo" @change="changePage"/>
            </template>
        </settlement-table>
        <settlement-detail-drawer :visible="settlementDrawerVisibility" type="income" @close="onCloseDrawer" @change="reload">
            <template #editor v-if="props.detail" >
                <settlement-editor-drawer :visible="settlementEditorVisibility" @close="onCloseEditor" @done="onChangeDetail"/>
            </template>
        </settlement-detail-drawer>
    </div>
</template>

<style scoped lang="less">
</style>
