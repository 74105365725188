import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
import store from '@/vuex/store';
import { PhoneUtil, ZipUtil } from '@/util';

const companyMod = namespace('company');

@Component({
    title: 'お支払い設定',
    beforeRouteEnter: SettingCompanyPaymentPage.beforeRouteEnter,
})
export default class SettingCompanyPaymentPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.PAYMENT)
    readonly PAYMENT?: types.CompanyPayment;

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 銀行振り込みを利用しているか否かを取得します。
     */
    get isBanking(): boolean {
        return this.PAYMENT?.method.code === 'BANK';
    }

    /**
     * 旧式のNTT請求を利用しているか否かを取得します。
     */
    get isOldNtt(): boolean {
        return this.PAYMENT?.method.code === 'NTTE';
    }

    /**
     * 新式のNTTファイナンスを利用しているか否かを取得します。
     */
    get isNewNtt(): boolean {
        return this.PAYMENT?.method.code === 'NTTF';
    }

    /**
     * NTT利用者名を取得します。
     */
    get nttName(): string {
        return this.PAYMENT?.nttName ?? '';
    }

    /**
     * NTT同意者名を取得します。
     */
    get nttAgreedName(): string {
        return this.PAYMENT?.nttAgreedName ?? '';
    }

    /**
     * 課金対象電話番号を取得します。
     */
    get nttPhoneNumberText(): string {
        return PhoneUtil.format(this.PAYMENT?.nttPhoneNumber ?? '');
    }

    /**
     * NTTファイナンス会員IDを取得します。
     */
    get nttFinanceCode(): string {
        return this.PAYMENT?.nttFinanceCode ?? '';
    }

    /**
     * 請求書企業名を取得します。
     */
    get companyName(): string {
        return this.PAYMENT?.companyName?.kanji ?? '';
    }

    /**
     * 請求書宛名を取得します。
     */
    get name(): string {
        return this.PAYMENT?.name ?? '';
    }

    /**
     * 請求書郵便番号を取得します。
     */
    get zipCodeText(): string {
        return ZipUtil.format(this.PAYMENT?.zipCode ?? '');
    }

    /**
     * 請求書都道府県を取得します。
     */
    get prefecture(): string {
        return this.PAYMENT?.prefecture?.label ?? '';
    }

    /**
     * 請求書市区町村を取得します。
     */
    get city(): string {
        return this.PAYMENT?.city ?? '';
    }

    /**
     * 請求書番地・建物を取得します。
     */
    get address(): string {
        return this.PAYMENT?.address ?? '';
    }

    /**
     * 請求書電話番号を取得します。
     */
    get phoneNumberText(): string {
        return PhoneUtil.format(this.PAYMENT?.phoneNumber ?? '');
    }

    /**
     * 請求書FAX番号を取得します。
     */
    get faxNumberText(): string {
        return PhoneUtil.format(this.PAYMENT?.faxNumber ?? '');
    }

    /**
     * 口座番号を取得します。
     */
    get transferAccountNumber(): string {
        return this.PAYMENT?.transferAccountNumber ?? '';
    }

    // ======================================================
    // Data
    // ======================================================
    loading = true;

    // ======================================================
    // Functions
    // ======================================================
    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingCompanyPaymentPage>
    ): Promise<void> {
        await SettingCompanyPaymentPage.loadPayment().catch(() => ({}));

        next((page) => (page.loading = false));
    }

    /**
     * 支払情報をロードします。
     */
    private static loadPayment(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_PAYMENT}`);
    }
}
