import { ref } from 'vue';
import { CompanyGuaranteedAmountModel } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 運賃全額保証の利用額をロードする機能を提供します。
 */
export const useGuaranteedAmount = () => {
    const amount = ref<CompanyGuaranteedAmountModel>();
    const { state: { loading }, withLoading } = useLoading();

    const load = async (year: number, month: number) => withLoading(async () => {
        amount.value = await companyApi.guaranteedAmount(year, month)
            .then(result => new CompanyGuaranteedAmountModel(result));
    });

    const clear = () => {
        amount.value = undefined;
    };

    return {
        state: {
            loading,
            amount,
        },
        load,
        clear,
    };
};
