import { Enum } from '@/types/enum';
import { DateTimeValue } from '@/models/vo/datetime';
import { NegotiationStatusEnum } from '@/enums/negotiation-status.enumts';
import { Util } from '@/util';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { NegotiationReportStatusEnum, NegotiationReportStatusEnumCode } from '@/enums/negotiation-report-status.enum';

/**
 * 商談一覧
 */
export interface NegotiationList {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: Negotiation[];
}

/**
 * 商談
 */
export interface Negotiation {
    id: number,
    baggageId: number,
    baggageCompanyId: number,
    truckCompanyId: number,
    staffName: string,
    entryTm: string,
    messageId: number,
    messageSendTm: string,
    reportStatus: NegotiationReportStatusEnum,
    message?: string,
    status: Enum,
}

export class NegotiationModel {
    id: number;
    baggageId: number;
    baggageCompanyId: number;
    truckCompanyId: number;
    staffName: string;
    entryTm: DateTimeValue;
    messageId: number;
    messageSendTm: DateTimeValue;
    reportStatus: NegotiationReportStatusEnum;
    message?: string;
    status: NegotiationStatusEnum;

    constructor(param: Negotiation) {
        this.id = param.id;
        this.baggageId = param.baggageId;
        this.baggageCompanyId = param.baggageCompanyId;
        this.truckCompanyId = param.truckCompanyId;
        this.staffName = param.staffName;
        this.entryTm = new DateTimeValue(param.entryTm);
        this.messageId = param.messageId;
        this.messageSendTm = new DateTimeValue(param.messageSendTm);
        this.reportStatus = NegotiationReportStatusEnum.codeOf(param.reportStatus.code);
        this.message = param.message;
        this.status = Util.requireNotNull(NegotiationStatusEnum.valueOf(param.status.code));
    }

    get reportSummary(): string {
        const time = this.messageSendTm.format('YYYY年MM月DD日 H時mm分');
        return `${time} 「${this.reportStatus.label}」で報告済み`;
    }
}


export interface NegotiationListForm {
    pageNo: number;
    pageSize: number;
}

/**
 * APIにわたすフォーム
 */
export interface NegotiationRegisterForm {
    baggageId: number,
    staffName: string,
    reportStatus: Enum<NegotiationReportStatusEnumCode>,
    message?: string,
}

/**
 * APIにわたすフォーム(対応可否更新用)
 */
export interface NegotiationMessageUpdateForm {
    baggageId: number,
    staffName: string,
    reportStatus: Enum<NegotiationReportStatusEnumCode>,
    message?: string,
}

/**
 * 画面で利用するフォーム(旧画面用)
 */
export interface NegotiationRequestForm {
    message?: string;
}

/**
 * 画面で利用するフォーム(リファクタリング用)
 */
export class NegotiationRequestFormModel implements FormValidatable<NegotiationRequestFormModel> {
    reportStatus?: Enum<NegotiationReportStatusEnumCode>;
    message?: string;

    get reportStatusCode(): NegotiationReportStatusEnumCode | undefined {
        if (this.reportStatus) {
            return this.reportStatus.code as NegotiationReportStatusEnumCode;
        }
        return undefined;
    }

    set reportStatusCode(value: NegotiationReportStatusEnumCode | undefined) {
        this.reportStatus = NegotiationReportStatusEnum.valueOf(value);
    }

    validator(): FormValidator<NegotiationRequestFormModel> {
        return {
            reportStatusCode: [
                {
                    required: true,
                    message: '対応可否を選択してください。'
                }
            ],
            message: [
                {
                    required: false,
                    max: 250,
                    message: 'メッセージは250文字以内で入力してください。',
                }
            ]
        };
    }
}
