import { Component, Prop, Vue } from 'vue-property-decorator';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyPaymentMethod } from '@/vuex/modules/company/types';
// @ts-ignore
import GuaranteeConfirmModalContent from '@/components/Common/Guarantee/ConfirmModalContent';
import { Const } from '@/const';
import { Enum } from '@/types/enum';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: {
        TbxLinkText,
        GuaranteeConfirmModalContent,
    },
})
export default class AgreementGuaranteeRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly mainAccountFlg?: boolean;
    @Prop()
    declare readonly existsGuarantee?: boolean;
    @Prop()
    declare readonly contracts?: companyTypes.CompanyContract[];
    @Prop()
    declare readonly payment?: companyTypes.CompanyPayment;
    @Prop({ default: false })
    declare readonly submitting: boolean;
    visibleConfirmModal = false;
    guaranteePageUrl = Const.externalPageUrl.guarantee;

    /**
     * 申し込み確認事項を確認済か否か
     */
    confirmedTerms = false;

    /**
     * 管理者アカウントかどうかを取得します。
     */
    get isMainAccount(): boolean {
        return this.mainAccountFlg ?? false;
    }

    /**
     * 支払い方法を取得します。
     */
    get paymentMethod(): Enum<CompanyPaymentMethod> | undefined {
        return this.payment?.method;
    }

    /**
     * お申し込みボタンが押下された際に呼び出されます。
     */
    onClickApply(): void {
        if (!this.isMainAccount || this.existsGuarantee) {
            return;
        }
        // 申し込み前確認モーダルを開きます。
        this.confirmedTerms = false;
        this.visibleConfirmModal = true;
    }

    /**
     * 運賃全額保証サービスのお申し込みボタンが押下された際に呼び出されます。
     */
    onClickRegisterGuarantee(): void {
        this.$emit('clickApply', () => {
            this.visibleConfirmModal = false;
        });
    }

    /**
     * 運賃全額保証サービスの申し込み確認事項の確認on/offイベントをハンドリングします。
     * @param confiemed
     */
    onChangeConfirmed(confiemed: boolean): void {
        this.confirmedTerms = confiemed;
    }
}
