export interface BaggageTypeData {
    title: string;
    items: Array<BaggageTypeDataItem>;
}

export interface BaggageTypeDataItem {
    value: string;
    keywords?: Array<string>;
}

export const BAGGAGE_TYPE_DATA: Array<BaggageTypeData> = [
    {
        title: '主な荷種',
        items: [
            { value: 'ケース', keywords: ['CS', 'C/S', 'ＣＳ', 'Ｃ／Ｓ', 'Ｃ/Ｓ'] },
            { value: 'フレコン' },
            { value: '引越', keywords: ['引っ越し', '引越し'] },
        ],
    },
    {
        title: '工業・建築',
        items: [
            { value: '建材' },
            { value: '建設機械' },
            { value: '機械' },
            { value: '部材' },
            { value: '鋼材' },
            { value: '木材' },
            { value: '骨材' },
            { value: '製材' },
            { value: '鉄筋' },
            { value: '鉄骨' },
            { value: '断熱材' },
            { value: '電線（ドラム）' },
            { value: '電線（バラ）' },
            { value: 'アルミ' },
            { value: 'アルミサッシ' },
            { value: 'ガラス' },
            { value: 'ガラス（板）' },
            { value: '板' },
            { value: '配電盤' },
            { value: 'パイプ' },
            { value: '金型' },
            { value: '装飾金物' },
            { value: '植木' },
            { value: '金属類' },
            { value: '化学薬品' },
            { value: 'ペットボトル' },
        ],
    },
    {
        title: '製品・部品',
        items: [
            { value: '事務機器' },
            { value: 'OA機器' },
            { value: '空調機' },
            { value: '製品（ガラス）' },
            { value: '製品（木）' },
            { value: '製品（プラスチック）' },
            { value: '部品' },
            { value: '部品（自動車）' },
            { value: '部品（精密）' },
            { value: '部品（電気）' },
            { value: '部品（電子）' },
            { value: '機材' },
            { value: '雑貨' },
            { value: '雑貨（日用雑貨）' },
            { value: '車' },
            { value: 'バイク' },
            { value: 'バッテリー' },
            { value: '印刷物' },
            { value: '書籍' },
        ],
    },
    {
        title: '入れ物・包材',
        items: [
            { value: '箱' },
            { value: '木箱' },
            { value: '木枠' },
            { value: '缶' },
            { value: '缶（一斗缶）' },
            { value: '缶（ドラム缶）' },
            { value: 'ファイバードラム' },
            { value: 'ダンボール' },
            { value: 'プラケース' },
            { value: '発泡スチロール' },
            { value: '袋' },
            { value: '紙袋' },
        ],
    },
    {
        title: '什器・家具',
        items: [
            { value: '什器' },
            { value: '家具' },
            { value: '家具（食器棚）' },
            { value: '家具（スチール）' },
            { value: '家具（たんす）' },
            { value: '家具（ピアノ）' },
        ],
    },
    {
        title: '食品',
        items: [
            { value: '食品' },
            { value: '食品（飲料水）' },
            { value: '食品（ジュース）' },
            { value: '食品（鮮魚）' },
            { value: '食品（生鮮）' },
            { value: '食品（青果）' },
            { value: '食品（チルド）' },
            { value: '食品（肉）' },
            { value: '食品（冷蔵）' },
            { value: '食品（冷凍）' },
            { value: '酒類' },
        ],
    },
    {
        title: '形状',
        items: [
            { value: '長尺物' },
            { value: '背高物' },
            { value: '特大品' },
            { value: '重量物' },
            { value: 'ボンベ' },
            { value: 'タンク' },
            { value: '棒' },
            { value: '粉' },
            { value: 'バラ' },
        ],
    },
];
