import store from '@/vuex/store';
import { computed, Ref, watch } from 'vue';
import { isEqual } from 'lodash';

/**
 * VuexをComposition APIから利用する機能を提供します
 */
export const useVuex = () => {
    // TODO: storeを直接触らせない && TypeSafeにVuexを操作する関数を提供したい
    return {
        store,
    };
};

export type VuexSyncOption = {
    module: string,
    getter: string,
    mutation: string,
};

/**
 * Vuexのstoreと同期する機能を提供する関数
 * @param option
 * @param refValue
 * @param toMutation stateの型からmutationの型に変換する関数。
 */
export const useVuexSync = <T, U>(refValue: Ref<T>, option: VuexSyncOption, toMutation: (stateValue: T) => U = (stateValue: T): U => stateValue as any as U): void => {
    // vue2-helperのstateは、vue instance内じゃないと動かない
    // route-guardからも利用されるので、自前で同期の仕組みを構築する
    const { store } = useVuex();

    const computedVuexValue = computed<T>(() => store.getters[`${ option.module }/${ option.getter }`]);
    watch(computedVuexValue, value => {
        if (!isEqual(value, refValue.value)) {
            refValue.value = value;
        }
    });

    watch<T>(refValue, (newValue, _oldValue) => {
        const getterValue = store.getters[`${ option.module }/${ option.getter }`];
        if (!isEqual(newValue, getterValue)) {
            const mutationValue: U = toMutation(newValue);
            store.commit(`${ option.module }/${ option.mutation }`, mutationValue);
        }
    });
};
