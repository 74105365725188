<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';

type KeysRequired =
    | 'temperatureZone';
const props = defineProps<{
    baggage: Pick<Baggage, KeysRequired>;
}>();

const temperatureZone = computed<string | undefined>(() => {
    return props.baggage.temperatureZone.label;
});
</script>

<template>
    <div>
        <span v-if="temperatureZone">{{ temperatureZone }}<br></span>
    </div>
</template>

<style scoped lang="less">

</style>
