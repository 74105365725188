import { BaggageRecommendReturn, BaggageRecommendReturnsCount } from '@/models/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { ref } from 'vue';
import { baggageApi } from '@/repository/api/internal/baggage';

export const useBaggageRecommendations = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<BaggageRecommendReturn[]>([]);
    const counts = ref<BaggageRecommendReturnsCount[]>([]);

    const load = (id: number): Promise<BaggageRecommendReturn[]> => withLoading(async () => {
        list.value = await baggageApi.loadBaggageRecommendReturns(id);
        return list.value;
    });

    const loadCounts = (ids: number[]): Promise<BaggageRecommendReturnsCount[]> => withLoading(async () => {
        counts.value = await baggageApi.baggageRecommendReturnsCounts({ ids: ids });
        return counts.value;
    });

    return {
        state: {
            loading,
            list,
            counts,
        },
        load,
        loadCounts,
    };
};
