<script setup lang="ts">

import { injectStrictly } from '@/util/vue';
import { TIMELINE_SCHEDULER_PROVIDER_KEY } from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import { computed } from 'vue';
import dayjs from 'dayjs';

const {
    state: {
        selectedDate,
    },
    onSelectDate,
} = injectStrictly(TIMELINE_SCHEDULER_PROVIDER_KEY);

const pickerWeek = computed({
    get: () => {
        return selectedDate.value.startOf('week').toISOString();
    },
    set: (newValue: string) => {
        onSelectDate(dayjs(newValue));
    }
});

const onClickNextWeek = () => {
    onSelectDate(dayjs(selectedDate.value).add(1, 'week'));
};

const onClickPreviousWeek = () => {
    onSelectDate(dayjs(selectedDate.value).subtract(1, 'week'));
};

const onClickToday = () => {
    onSelectDate(dayjs());
};

</script>

<template>
    <div class="toolbar-container">
        <a-row type="flex" justify="space-between" align="middle" class="toolbar">

            <a-col type="flex" :span="12" style="padding-left: 20px;">
                <a-row type="flex" justify="start">
                    <a-col>
                        <a-button-group>
                            <a-button icon="left" shape="circle" size="small" @click="onClickPreviousWeek"/>
                            <a-button icon="right" shape="circle" size="small" @click="onClickNextWeek"/>
                        </a-button-group>
                    </a-col>
                    <a-col style="padding-left: 10px;">
                        <a-week-picker size="small"
                                       :allow-clear="false"
                                       placeholder="Select week"
                                       locale="ja"
                                       format="YYYY/MM/DD週"
                                       v-model="pickerWeek"
                        />
                    </a-col>
                </a-row>
            </a-col>

            <a-col :offset="10" :span="2">
                <a-row type="flex" justify="end" style="padding-right: 20px">
                    <a-button size="small" @click="onClickToday">今日</a-button>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<style lang="less" scoped>

.toolbar {
    background-color: #fcfcfc;
    height: 42px;
    border: 0;
    border-bottom: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
    margin-bottom: 3px;
    min-height: 42px;
}

</style>
