/**
 * 口座種別のEnum
 */
export type BankAccountTypeEnumCode = 'BA1' | 'BA2' | 'BA3';
export class BankAccountTypeEnum {
    constructor(public code: BankAccountTypeEnumCode, public label: string) {}

    static Mixture = new BankAccountTypeEnum('BA1', '普通預金');
    static Checking = new BankAccountTypeEnum('BA2', '当座預金');
    static Savings = new BankAccountTypeEnum('BA3', '貯蓄預金');

    static valueOf = (code?: string): BankAccountTypeEnum | undefined =>
        BankAccountTypeEnum.values.find((value) => value.code === code);

    static values = [
        BankAccountTypeEnum.Mixture,
        BankAccountTypeEnum.Checking,
        BankAccountTypeEnum.Savings
    ];
}
