<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import { DateTimeValue } from '@/models/vo/datetime';

const props = defineProps<{
    baggage: Pick<Baggage, 'entryTm'>;
}>();

const entryTmText = computed<string>(() => {
    return new DateTimeValue(props.baggage.entryTm).format('YYYY年M月D日(ddd) H:mm');
});

</script>

<template>
    <span>{{ entryTmText }}</span>
</template>

<style scoped lang="less">

</style>
