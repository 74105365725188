<script setup lang="ts">
import { Baggage, BaggageFreightMaster } from '@/models/baggage';
import { Util } from '@/util';
import { DeliveryDateTimeRange, DeliveryTimeFormats } from '@/models/vo/delivery-datetime-range';
import { computed } from 'vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

const props = withDefaults(defineProps<{
    baggage?: Baggage;
    referenceFreight?: BaggageFreightMaster;
    hideReferenceFreight?: boolean;
}>(), {
    hideReferenceFreight: false,
});

const DATE_FORMAT: string = 'M/D(ddd)';
const TIME_FORMATS: DeliveryTimeFormats = {
    day: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.day,
    morning: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.morning,
    afternoon: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.afternoon,
    hourly: 'H:mm',
    just: 'H:mm',
    period: 'H:mm',
};

const departureDay = computed<string>(() => {
    if (!props.baggage) return '';
    return day(props.baggage.departureMin, props.baggage.departureMax);
});

const departureTime = computed<string>(() => {
    if (!props.baggage) return '';
    return time(props.baggage.departureMin, props.baggage.departureMax);
});

const arrivalDay = computed<string>(() => {
    if (!props.baggage) return '';
    return day(props.baggage.arrivalMin, props.baggage.arrivalMax);
});

const arrivalTime = computed<string>(() => {
    if (!props.baggage) return '';
    return time(props.baggage.arrivalMin, props.baggage.arrivalMax);
});

const departurePref = computed<string>(() => {
    if (!props.baggage || !props.baggage.departurePref.label) return '';
    return props.baggage.departurePref.label;
});

const departureCity = computed<string>(() => {
    if (!props.baggage) return '';
    return props.baggage.departureCity;
});

const departureAddress = computed<string>(() => {
    if (!props.baggage) return '';
    return props.baggage.departureAddress ?? '';
});

const arrivalPref = computed<string>(() => {
    if (!props.baggage || !props.baggage.arrivalPref.label) return '';
    return props.baggage.arrivalPref.label;
});

const arrivalCity = computed<string>(() => {
    if (!props.baggage) return '';
    return props.baggage.arrivalCity;
});

const arrivalAddress = computed<string>(() => {
    if (!props.baggage) return '';
    return props.baggage.arrivalAddress ?? '';
});

const freight = computed<string>(() => {
    if (!props.baggage) return '';
    const freight = props.baggage?.freight ?? 0;
    return freight > 0 ? `￥${Util.formatNumber(freight)}` : '要相談';
});

const highwayFare = computed<string>(() => {
    if (!props.baggage) return '';
    return `高速代${props.baggage.highwayFareFlg ? 'あり' : 'なし'}`;
});

const day = (dateMin: string, dateMax: string): string => {
    if (!dateMin || !dateMax) return '';
    const deliveryDatetime = DeliveryDateTimeRange.of(dateMin, dateMax);
    if (!deliveryDatetime) return '';
    return deliveryDatetime.date.format(DATE_FORMAT);
};

const time = (dateMin: string, dateMax: string): string => {
    if (!dateMin || !dateMax) return '';
    const deliveryDatetime = DeliveryDateTimeRange.of(dateMin, dateMax);
    if (!deliveryDatetime) return '';
    return deliveryDatetime.formatTime(TIME_FORMATS);
};

</script>

<template>
    <div class="container">
        <a-row type="flex" align="middle">
            <a-col flex="0 0 47%">
                <div><span class="date">{{ departureDay }}</span> {{ departureTime }}</div>
                <div class="location">{{ departurePref }} {{ departureCity }} {{ departureAddress }}</div>
            </a-col>
            <a-col flex="0 0 6%" class="caret">
                <tbx-icon class="caret__icon" type="caret-right"/>
            </a-col>
            <a-col flex="0 0 47%">
                <div><span class="date">{{ arrivalDay }}</span> {{ arrivalTime }}</div>
                <div class="location">{{ arrivalPref }} {{ arrivalCity }} {{ arrivalAddress }}</div>
            </a-col>
        </a-row>
        <div>
            <div>
                <span class="freight">{{ freight }}</span>
                <span> {{ highwayFare }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.container {
    margin-bottom: 16px;
    border-radius: 10px;
    padding: @padding-xs 0;
    overflow: hidden;
    border: 3px solid @color-neutral-5;
    color: @heading-color;

    > * {
        padding: @padding-sm @padding-lg;
    }

    > :first-child {
        border-bottom: 3px solid @color-neutral-5;
    }

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
    }
}

.date {
    font-size: @font-size-lg;
    font-weight: bold;
}

.location {
    margin-top: 4px;
    font-size: @font-size-lg;
    word-break: break-all;
}

.caret__icon {
    color: @color-neutral-5;
    font-size: @heading-3-size;
}

.freight {
    font-size: x-large;
    font-weight: bold;
}

.reference-freight {
    margin-top: 4px;

    &__help {
        margin-left: 4px;
        cursor: pointer;
    }
}
</style>
