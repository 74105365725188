import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReportLatePaymentForm } from '@/vuex/modules/help/types';
import { Const } from '@/const';
import { DateUtil } from '@/util';

@Component
export default class ReportLatePaymentDeliveryDate extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly form?: ReportLatePaymentForm;

    get deliveryDate(): string {
        const date = this.form?.deliveryDate;
        if (!date) return '';

        return DateUtil.parseDateText(date).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }
}
