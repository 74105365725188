import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { DateUtil } from '@/util';

@Component
export default class CompanyRegistrationDate extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get registrationDate(): string {
        const dateText = this.profile?.registrationDate ?? '';
        if (!dateText) {
            return '';
        }
        return DateUtil.parseDateText(dateText).format('YYYY/M/D');
    }
}
