export class SoundUtil {
    /**
     * ベルを鳴らします。
     */
    static playBell(): void {
        const audio = new AudioContext();
        const decay = 300;
        const gain = audio.createGain();
        gain.connect(audio.destination);
        gain.gain.value = 1.0;
        gain.gain.setValueAtTime(1.0, audio.currentTime);
        gain.gain.linearRampToValueAtTime(0, audio.currentTime + decay / 1000);
        const osc = audio.createOscillator();
        osc.frequency.value = 800;
        osc.type = 'sine';
        osc.connect(gain);
        osc.start(0);
        window.setTimeout(() => {
            osc.stop(0);
            osc.disconnect(gain);
            gain.disconnect(audio.destination);
        }, decay);
    }
}
