import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage } from '@/vuex/modules/baggage/types';
import { BaggageDetailUtil, DateUtil, PhoneUtil } from '@/util';
import { Const } from '@/const';
import { CompanyProfile } from '@/vuex/modules/company/types';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import _ from 'lodash';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: { TbxLinkText }
})
export default class BaggageDetailView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: Baggage;
    @Prop()
    declare readonly profile?: CompanyProfile;
    @Prop()
    declare readonly isMyBaggage: boolean;

    get companyName(): string | undefined {
        return this.profile?.name.kanji;
    }

    get staffName(): string | undefined {
        return this.baggage?.staffName;
    }

    get paymentDate(): string {
        if (!this.baggage || !this.baggage.paymentDate) return '';
        return DateUtil.parseDateText(this.baggage.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }

    get phoneNumber(): string {
        return PhoneUtil.format(this.profile?.phone.number ?? '');
    }

    get paletteCount(): string | undefined {
        return BaggageDetailUtil.paletteCount(
            this.baggage?.shape?.code,
            this.baggage?.paletteCount
        );
    }

    get paletteSize(): string | undefined {
        return BaggageDetailUtil.paletteSize(
            this.baggage?.shape?.code,
            this.baggage?.paletteHeight,
            this.baggage?.paletteWidth
        );
    }

    get totalCount(): string | undefined {
        return BaggageDetailUtil.totalCount(
            this.baggage?.shape?.code,
            this.baggage?.totalCount
        );
    }

    get totalVolume(): string | undefined {
        return BaggageDetailUtil.totalVolume(
            this.baggage?.shape?.code,
            this.baggage?.totalVolume
        );
    }

    get totalWeight(): string | undefined {
        return BaggageDetailUtil.totalWeight(this.baggage?.totalWeight);
    }

    get handling(): string | undefined {
        return BaggageDetailUtil.handling(
            this.baggage?.loading?.code,
            this.baggage?.unloading?.code
        );
    }

    get baggageType(): string | undefined {
        return this.baggage?.type;
    }

    get baggageTypeDetail(): string | undefined {
        if (!this.baggage) return;
        return Const.SHARE_BAGGAGE_MESSAGE + '：' +
            this.roundOrCross(this.baggage.share) + ' / ' +
            Const.EXPRESS_BAGGAGE_MESSAGE + '：' +
            this.roundOrCross(this.isExpress()) + ' / ' +
            Const.RELOCATION_BAGGAGE_MESSAGE + '：' +
            this.roundOrCross(this.isRelocation());
    }

    get truck(): string {
        if (!this.baggage) return '';
        return `重量：${ this.baggage.truckWeight.label } 車種：${ this.baggage.truckModel.label }`;
    }

    get specifiedTruck(): string {
        if (!this.baggage) return '';
        const height = this.baggage?.truckHeight?.label;
        const width = this.baggage?.truckWidth?.label;
        const largeTruckFlg = this.baggage?.largeTruckFlg;
        const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

        return `床高：${ height ?? '指定なし' } 車幅：${ width ?? '指定なし' } 大型：${ largeTruckText }`;
    }

    get truckEquipment(): string | undefined {
        return this.baggage?.truckEquipment;
    }

    get loadingTimeNote(): string | undefined {
        return this.baggage?.loadingTimeNote;
    }

    get unloadingTimeNote(): string | undefined {
        return this.baggage?.unloadingTimeNote;
    }

    get description(): string | undefined {
        return this.baggage?.description;
    }

    get departureArrivalDate(): string {
        return `${ this.departureDate() } - ${ this.arrivalDate() }`;
    }

    get isMultiple(): boolean {
        return this.baggage?.multipleBaggage ?? false;
    }

    get entryTmText(): string {
        if (!this.baggage) return '';
        return DateUtil.parseDatetimeText(this.baggage.entryTm).format(Const.DEFAULT_DATETIME_WITH_DAY_OF_WEEK_FORMAT);
    }

    get isUnderNegotiation(): boolean {
        return this.baggage?.underNegotiation ?? false;
    }

    get isNegotiationRequest(): boolean {
        return this.baggage?.negotiationType.code === NegotiationTypeEnum.Online.code;
    }

    /**
     * 「この企業の他の荷物をみる」が押下された際に呼び出されます。
     */
    onClickCompanyName(): void {
        if (!this.profile) return;
        this.$emit('clickCompanyName', this.profile?.id);
    }

    /**
     * 「実績をみる」が押下された際に呼び出されます。
     */
    onClickShowAchievement(): void {
        this.$emit('clickShowAchievement');
    }

    /**
     * 「商談リクエストする」が押下された際に呼び出されます。
     * @private
     */
    onClickNegotiationRequest(): void {
        this.$emit('clickNegotiationRequest');
    }

    private isExpress(): boolean {
        return this.baggage?.express ?? false;
    }

    private isRelocation(): boolean {
        return this.baggage?.category?.code === BaggageCategoryEnum.Relocation.code;
    }

    private roundOrCross(isWhat: boolean): string {
        return (isWhat ? '○' : '×');
    }

    private departureDate(): string {
        if (!this.baggage) return '';

        const min = this.baggage.departureMin;
        const max = this.baggage.departureMax;
        if (!min || !max) return '';
        return DeliveryDateTime.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
    }

    private arrivalDate(): string {
        if (!this.baggage) return '';

        const min = this.baggage.arrivalMin;
        const max = this.baggage.arrivalMax;
        if (!min || !max) return '';
        return DeliveryDateTime.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
    }
}
