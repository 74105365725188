import store from '@/vuex/store';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { message } from 'ant-design-vue';

/**
 * 帰り便のレコメンドを取得します。
 */
export const loadBaggageRecommendations = async (baggageId: number): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_RECOMMENDATIONS }`, baggageId)
        .catch(() => message.error('帰り便のレコメンドを取得できません。'));
};

/**
 * 荷物を既読登録します。
 */
export const markRead = async (baggageId: number): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_READ }`, baggageId)
        .catch(() => {}); /* エラーは無視する */
};
