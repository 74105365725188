import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';

@Component
export default class AgreementEquipmentRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;

    get truckEquipment(): string {
        return this.baggage?.truckEquipment ?? '';
    }
}
