import { DirectiveBinding } from 'vue';
import { embedTagApi } from '@/repository/api/api';

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    install(vue: any): void {
        vue.directive('embed-tag', {
            bind(el: HTMLElement, bindings: DirectiveBinding<string>) {
                embedTagApi.find(bindings.value)
                    .then((tag) => {
                        el.innerHTML = tag ?? '';
                    });
            },
        });
    }
};
