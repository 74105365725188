import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { myBaggageSearchFormVisibility, pagination } from '@/repository/storage/web-storage';
import {
    copy,
    goToRegisterBaggage,
    goToRoot,
    lazy,
    openPrintBaggagePage,
    scrollToTopOfResultList
} from '@/pages/Baggage/List/tabs/common-helpers';
import {
    clearBaggage,
    extractBaggageId,
    goToBaggageDetail,
    isEmptyForm,
    loadBaggage,
    loadBaggageList,
    loadBaggageViewCountList,
} from '@/pages/Baggage/List/tabs/baggage-helpers';
import * as baggageTypes from '@/vuex/modules/baggage/types';
// @ts-ignore
import SearchCondition from '@/components/MyBaggage/Search/Condition';
// @ts-ignore
import BaggageList from '@/components/Baggage/View/List';
// @ts-ignore
import BaggageDetailDrawerPage from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent';
import { Const } from '@/const';

const baggageMod = namespace('baggage');

@Component({
    components: {
        SearchCondition,
        BaggageList,
        BaggageDetailDrawerPage,
    },
})
export default class BaggageExpiredListPage extends Vue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_LIST)
    readonly BAGGAGE_LIST?: baggageTypes.BaggageList;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggageId?: string;

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get baggageDrawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return this.baggageId !== undefined;
    }

    /**
     * ページサイズ
     */
    get pageSize(): number {
        return Number(pagination.getPageSize() ?? '20');
    }

    set pageSize(newValue: number) {
        this.value.pageSize = newValue;
        pagination.setPageSize(newValue.toString());
    }

    /**
     * 絞り込み中か否かを取得します。
     */
    get isFiltered(): boolean {
        return !isEmptyForm(this.value);
    }

    // ======================================================
    // Data
    // ======================================================
    loading = true;
    routeUpdating = false;
    searchFormVisible = false;
    initialFormModel: Partial<baggageTypes.BaggageListForm> = {
        id: undefined,
        departurePref: [],
        arrivalPref: [],
        departureFrom: '',
        departureTo: '',
        truckWeight: [],
        truckModel: [],
        staffName: '',
    };
    formModel = _.cloneDeep(this.initialFormModel);
    initialValue: baggageTypes.BaggageListForm = {
        id: undefined,
        status: { code: 'CANCEL' },
        departurePref: [],
        arrivalPref: [],
        departureFrom: '',
        departureTo: '',
        truckWeight: [],
        truckModel: [],
        staffName: '',
        pageNo: 1,
        pageSize: 20,
        sortKey: 'ID',
        sortOrder: 'DESC',
    };
    value: baggageTypes.BaggageListForm = _.cloneDeep(this.initialValue);

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.value.pageSize = this.pageSize;
        this.searchFormVisible = myBaggageSearchFormVisibility.get('EXPIRED');
    }

    /**
     * 検索フォームを開閉する。
     */
    toggleSearchFormVisibility(): void {
        this.searchFormVisible = !this.searchFormVisible;
        myBaggageSearchFormVisibility.set('EXPIRED', this.searchFormVisible);
    }

    /**
     * 検索ボタンが押下された際に呼び出されます。
     */
    async onClickSearch(): Promise<void> {
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // フォーム内容を検索条件へ反映
        this.commitForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        // フォームをクリアするが検索条件には反映しない
        this.formModel = _.cloneDeep(this.initialFormModel);
    }

    /**
     * ソート順が変更された際に呼び出されます。
     */
    async onChangeSort(param: { sortKey: string; sortAscending: boolean }): Promise<void> {
        // ソート順定義を構築
        const definition = new Map<string, string>(Const.baggageSortKey.map(each => [each.code, each.defaultOrder]))
            .set(this.value.sortKey, param.sortAscending ? 'ASC' : 'DESC');
        // ソートキー
        this.value.sortKey = param.sortKey;
        // ソート順
        this.value.sortOrder = definition.get(param.sortKey) ?? this.value.sortOrder;
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);
    }

    /**
     * ページングが変更された際に呼び出されます。
     */
    async onChangePage(param: { pageNo: number; pageSize: number }): Promise<void> {
        // ページ番号
        this.value.pageNo = param.pageNo;
        // ページサイズ
        this.pageSize = param.pageSize;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);

        scrollToTopOfResultList();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物一覧の行が押下された際に呼び出されます。
     */
    async onClickBaggageRow(id: number): Promise<void> {
        if (extractBaggageId(this.$route) === id) {
            return;
        }
        await goToBaggageDetail(this.$route, id);
    }

    /**
     * 荷物をコピーするボタンが押下された際に呼び出されます。
     */
    onClickCopyBaggage(baggageId: number): Promise<Route> {
        return goToRegisterBaggage(baggageId);
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        await goToRoot(this.$route);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        openPrintBaggagePage(baggageId);
    }

    /**
     * ルーティングによってこのページに到達した際に呼び出されます。
     * @param to
     * @param _from
     * @param next
     */
    async beforeRouteEnter(to: Route, _from: Route, next: NavigationGuardNext<BaggageExpiredListPage>): Promise<void> {
        next(async page => {
            await page.loadDataList().catch(page.notifyFailedToLoadData);

            const baggageId = extractBaggageId(to);

            // 一覧表示なら終了
            if (!baggageId) return;

            await lazy(() => loadBaggage(Number(baggageId)).catch(page.notifyFailedToLoadDetailData));
        });
    }

    // noinspection DuplicatedCode
    /**
     * ルーティング情報が変化した際に呼び出されます。
     * @param to
     * @param from
     * @param next
     */
    async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<BaggageExpiredListPage>): Promise<void> {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (this.routeUpdating) return next(false);

        await clearBaggage();

        next();

        const baggageId = extractBaggageId(to);

        // 一覧表示なら終了
        if (!baggageId) return;

        this.routeUpdating = true;

        await lazy(() => loadBaggage(baggageId).catch(this.notifyFailedToLoadDetailData));

        this.routeUpdating = false;
    }

    /**
     * データロード失敗を通知します。
     */
    private notifyFailedToLoadData(): void {
        this.$message.error(`荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。`);
    }

    /**
     * 詳細データロード失敗を通知します。
     */
    private notifyFailedToLoadDetailData(): void {
        this.$message.error(`荷物番号 ${ this.baggageId } の荷物情報を読み込みできませんでした。すでに荷物が削除されている可能性があります。`);
    }

    /**
     * データをロードします。
     */
    private async loadDataList(): Promise<void> {
        this.loading = true;

        // 荷物一覧ロード
        await loadBaggageList(this.value);

        // 荷物閲覧会員数ロード
        await loadBaggageViewCountList(this.BAGGAGE_LIST?.data ?? []);

        this.loading = false;
    }

    /**
     * 検索条件フォーム内容を検索条件として確定させます。
     */
    private commitForm(): void {
        copy(this.value, this.formModel);
    }

    /**
     * 確定済みの検索条件を検索フォームへ戻します。
     */
    private revertForm(): void {
        copy(this.formModel, this.value, _.keys(this.formModel));
    }
}
