import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
// @ts-ignore
import TraCheckbox from '@/components/Baggage/Search/Condition/Checkbox';

@Component({ components: { TraCheckbox } })
export default class BaggageSearchConditionCategoryEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: BaggageCategoryEnumCode;

    // noinspection JSUnusedGlobalSymbols
    /**
     * 引越しのみ
     */
    get onlyRelocation(): boolean {
        return this.value === BaggageCategoryEnum.Relocation.code;
    }

    // noinspection JSUnusedGlobalSymbols
    set onlyRelocation(newValue: boolean) {
        this.$emit('input', newValue ? BaggageCategoryEnum.Relocation.code : '');
    }

    /**
     * 引越しを除く
     */
    get excludeRelocation(): boolean {
        return this.value === BaggageCategoryEnum.Normal.code;
    }

    set excludeRelocation(newValue: boolean) {
        this.$emit('input', newValue ? BaggageCategoryEnum.Normal.code : '');
    }
}
