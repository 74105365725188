import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as types from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

const companyMod = namespace('company');

@Component
export default class TruckPage extends Vue {
    @companyMod.Getter(types.GETTER.CONTRACT_LIST)
    readonly contractListModel?: CompanyContractListModel;

    get routeActiveTabKey(): string {
        return this.$route.meta?.activeTabKey ?? '';
    }

    /**
     * `/truck` ページが呼び出された際に呼び出されます。
     */
    async mounted(): Promise<void> {
        await this.goToDefaultPage();
    }

    /**
     * `/truck/*` が呼び出された際に呼び出されます。
     */
    async updated(): Promise<void> {
        await this.goToDefaultPage();
    }

    async goToDefaultPage(): Promise<void> {
        if (this.$route.name !== 'Truck' || !this.contractListModel) {
            return;
        }

        switch (this.contractListModel.activePlanType) {
            case 'FREE':
                await this.$router.push({ name: 'TruckList' });
                break;
            case 'PREMIUM':
            case 'TRIAL':
                await this.$router.push({ name: 'TruckSearch' });
                break;
            default:
                throw new Error('activePlan is not defined.');
        }
    }

    /**
     * タブでページを切り替えた際に呼び出されます。
     * @param tabKey
     */
    async onChangeTab(tabKey: string): Promise<void> {
        await this.$router.push({ path: `/truck/${tabKey}` });
    }
}
