import _ from 'lodash';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import { Util } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/models/company-contract-list';
import { CompanyContractModel } from '@/models/company-contract';
import { loadContractList } from '@/pages/Setting/Company/Contract/helpers';

const companyMod = namespace('company');

@Component({
    title: 'ご契約内容',
    beforeRouteEnter: SettingContractPage.beforeRouteEnter,
})
export default class SettingContractPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 表示する契約が存在しない
     */
    get noPlan(): boolean {
        if (this.trialPlan) return false;
        if (this.premiumPlan) return false;
        if (this.accounts.length > 0) return false;
        if (this.guarantee) return false;
        // noinspection RedundantIfStatementJS
        if (this.invoice) return false;

        return true;
    }

    /**
     * 14日間お試し契約
     */
    get trialPlan(): CompanyContractModel | undefined {
        return this.CONTRACT_LIST?.trialContract;
    }

    /**
     * プレミアムプラン契約
     */
    get premiumPlan(): CompanyContractModel | undefined {
        return this.CONTRACT_LIST?.premiumPlanContract;
    }

    /**
     * アカウント契約一覧
     */
    get accounts(): CompanyContractModel[] {
        const accounts = this.CONTRACT_LIST?.accountContracts?.filter((each) => !each.isAccountX) ?? [];
        const discount = this.CONTRACT_LIST?.accountDiscountContracts?.length ?? 0;

        // 最も生存期間が長く、最も古いもの順（自動継続のアカウント契約から優先的にディスカウントするため）
        const sortedAccounts = _.orderBy(
            accounts,
            [each => each.endDate.unix, each => each.startDate.unix, each => each.id],
            ['desc', 'asc', 'asc']
        );

        return _.drop(sortedAccounts, discount);
    }

    /**
     * 運賃全額保証契約
     */
    get guarantee(): CompanyContractModel | undefined {
        return this.CONTRACT_LIST?.guaranteeContract;
    }

    /**
     * 請求書発行手数料契約
     */
    get invoice(): CompanyContractModel | undefined {
        const invoice = this.CONTRACT_LIST?.invoiceContract;
        if (!invoice) return;

        if (invoice.isInvoiceNtt) return;
        if (invoice.isInvoiceNttX) return;

        return invoice;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 契約開始日テキストを取得します。
     */
    private startDateText(contract: CompanyContractModel): string {
        return contract.billingStartDate?.format() ?? '';
    }

    /**
     * 契約終了日テキストを取得します。
     */
    private endDateText(contract: CompanyContractModel): string {
        return contract.billingEndDate?.format() ?? '';
    }

    /**
     * 金額テキストを取得します。
     */
    private priceText(contract: CompanyContractModel): string {
        if (_.isNil(contract.billingTotalPrice)) return '';
        return `${ Util.formatNumber(contract.billingTotalPrice) }円`;
    }

    /**
     * ルーティングによってこのページに到達した際に呼び出されます。
     * @param _to
     * @param _from
     * @param next
     */
    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingContractPage>
    ): Promise<void> {
        await loadContractList();
        next();
    }
}
