import { ProductEnum } from '@/enums/product.enum';

export class ProductUtil {
    static Trial14d = ProductEnum.Trial14d;
    static TrialAccount14d = ProductEnum.TrialAccount;
    static PremiumX = ProductEnum.PremiumX;
    static PremiumAccountX = ProductEnum.PremiumAccountX;
    static Premium1m = ProductEnum.Premium1m;
    static PremiumAccount1m = ProductEnum.PremiumAccount1m;
    static Premium6m = ProductEnum.Premium6m;
    static PremiumAccount6m = ProductEnum.PremiumAccount6m;
    static Premium1y = ProductEnum.Premium1y;
    static PremiumAccount1y = ProductEnum.PremiumAccount1y;
    static AccountX = ProductEnum.AccountX;
    static Account1m = ProductEnum.Account1m;
    static Account6m = ProductEnum.Account6m;
    static Account1y = ProductEnum.Account1y;
    static AccountFree = ProductEnum.AccountFree;
    static FreightProtection1m = ProductEnum.FreightProtection1m;
    static FreightProtection6m = ProductEnum.FreightProtection6m;
    static FreightProtection1y = ProductEnum.FreightProtection1y;
    static FreightProtectionPayPerUse = ProductEnum.FreightProtectionPpu;
    static InvoiceNtt = ProductEnum.InvoiceNtt;
    static InvoiceFax = ProductEnum.InvoiceFax;
    static InvoicePost = ProductEnum.InvoicePost;
    static InvoiceFaxPost = ProductEnum.InvoiceFaxPost;
    static InvoicePostForFree = ProductEnum.InvoicePostForFree;
    static InvoiceFree = ProductEnum.InvoiceFree;
    static TraboxTmsX = ProductEnum.TraboxTmsX;
    static PrimeBaggageCompany = ProductEnum.PrimeBaggageCompany;
    static PrimeTruckCompany = ProductEnum.PrimeTruckCompany;
    static PrimeBaggageAccount = ProductEnum.PrimeBaggageAccount;

    /**
     * 運賃全額保証サービスであるか否かを判別します。
     *
     * @param code 商品コード
     */
    static isGuarantee(code: string): boolean {
        return ProductEnum.valueOf(code)?.isGuarantee ?? false;
    }

    /**
     * 14日間お試しサービスであるか否かを判別します。
     *
     * @param code 商品コード
     */
    static isTrial(code: string): boolean {
        return ProductEnum.valueOf(code)?.isTrial ?? false;
    }

    /**
     * プレミアムプランサービスであるか否かを判別します。
     *
     * @param code 商品コード
     */
    static isPremium(code: string): boolean {
        return ProductEnum.valueOf(code)?.isPremium ?? false;
    }

    /**
     * アカウントであるか否かを判別します。
     *
     * @param code 商品コード
     */
    static isAccount(code: string): boolean {
        return ProductEnum.valueOf(code)?.isAccount ?? false;
    }

    /**
     * 請求書発行手数料であるか否かを取得します。
     *
     * @param code 商品コード
     */
    static isInvoice(code: string): boolean {
        return ProductEnum.valueOf(code)?.isInvoice ?? false;
    }
}
