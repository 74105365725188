<script setup lang="ts">

import PageLayout from '@/_components/PageLayout.vue';
import { useDeliveryOrderReplyHelper } from '@/_pages/DeliveryOrder/Reply/delivery-order-reply-helper';

const {
    loading,
    form,
    formModel,
    validationRules,
    onClickBack,
    onSubmit,
    onClickCancel,
} = useDeliveryOrderReplyHelper();

</script>

<template>
    <page-layout :back-icon="true" @back="onClickBack">
        <a-spin :spinning="loading">
            <a-form-model ref="formModel"
                          :model="form"
                          :rules="validationRules"
                          :colon="false"
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }">
                <a-card id="jsi-register-form" class="form-card">
                    <a-form-model-item label="会社名(実運送会社)"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truckCompanyName">
                        <a-input v-model="form.truckCompanyName"
                                 style="width: 50%"/>
                    </a-form-model-item>
                    <a-form-model-item label="車番"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truckNumber">
                        <a-input v-model="form.truckNumber"
                                 style="width: 50%"/>
                    </a-form-model-item>
                    <a-form-model-item label="ドライバー名"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverName">
                        <a-input v-model="form.driverName"
                                 style="width: 30%"/>
                    </a-form-model-item>
                    <a-form-model-item label="ドライバー電話番号"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverPhoneNumber">
                        <a-input v-model="form.driverPhoneNumber"
                                 style="width: 30%"/>
                    </a-form-model-item>
                    <a-row :gutter="[32, 48]">
                        <a-col :offset="10" :span="14"
                               style="display: flex; align-items: flex-start; text-align: center;">
                            <a-button style="max-width: 160px; margin-right: 22px;"
                                      block
                                      type="primary"
                                      size="large"
                                      @click="onSubmit">送信
                            </a-button>
                            <a-button style="max-width: 120px"
                                      block
                                      size="large"
                                      @click="onClickCancel">キャンセル
                            </a-button>
                        </a-col>
                    </a-row>
                </a-card>
            </a-form-model>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
// typescript/pages/Baggage/Register/style.less
.ant-form {
    padding: 24px;
}

.form-card {
    padding: 16px 24px;
}
</style>
