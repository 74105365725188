import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyIso14000Edit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyConfidenceUpdateForm;

    /**
     * ISO14000
     */
    get iso14000(): boolean {
        return this.value?.iso14000 ?? false;
    }

    set iso14000(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.iso14000 = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
