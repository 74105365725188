/**
 * 決済ステータスコード
 */
export type SettlementStatusEnumCode = 'INITIAL' | 'CHANGE' | 'EXCLUDE' | 'INCLUDE' | 'REVOKE' | 'ACTIVE' | 'DELETE';

export class SettlementStatusEnum {
    constructor(public code: SettlementStatusEnumCode, public label: string) {
    }

    get isInitial(): boolean {
        return this.code === SettlementStatusEnum.Initial.code;
    }

    get isUnconfirmed(): boolean {
        return [
            SettlementStatusEnum.Initial,
            SettlementStatusEnum.Change,
            SettlementStatusEnum.Exclude,
            SettlementStatusEnum.Include,
            SettlementStatusEnum.Revoke
        ].map(each => each.code).includes(this.code);
    }

    static Initial = new SettlementStatusEnum('INITIAL', '未確認');
    static Change = new SettlementStatusEnum('CHANGE', '変更申請中');
    static Exclude = new SettlementStatusEnum('EXCLUDE', '除外申請中');
    static Include = new SettlementStatusEnum('INCLUDE', '包含申請中');
    static Revoke = new SettlementStatusEnum('REVOKE', '削除申請中');
    static Active = new SettlementStatusEnum('ACTIVE', '承認済');
    static Delete = new SettlementStatusEnum('DELETE', '削除済');

    static valueOf = (code: string): SettlementStatusEnum | undefined =>
        SettlementStatusEnum.values.find((value) => value.code === code);

    static values = [
        SettlementStatusEnum.Initial,
        SettlementStatusEnum.Change,
        SettlementStatusEnum.Exclude,
        SettlementStatusEnum.Include,
        SettlementStatusEnum.Revoke,
        SettlementStatusEnum.Active,
        SettlementStatusEnum.Delete
    ];

    static unconfirmedValues = this.values.filter(value => value.isUnconfirmed);
}
