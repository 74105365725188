<script setup lang="ts">
import { computed } from 'vue';
import { useTruckWidthForBaggageOptions } from '@/composables/option-helper';
import { TruckWidthEnumCode } from '@/enums/truck-width.enum';

const props = defineProps<{
    value?: TruckWidthEnumCode,
    optionType: 'baggage',
}>();

const emit = defineEmits<{
    (e: 'input', value: TruckWidthEnumCode | undefined): void,
}>();

const options = useTruckWidthForBaggageOptions();

const truckWidth = computed<TruckWidthEnumCode | undefined>({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});

</script>

<template>
    <a-select placeholder="車幅を選択"
              :options="options.options"
              v-model="truckWidth"
              :allowClear="true"/>
</template>

<style scoped lang="less">

</style>
