import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { DateValue } from '@/models/vo/date';
import { settlementApi } from '@/repository/api/internal/settlement';

export const useSettlementIncomeDateList = () => {
    const { state: { loading }, withLoading } = useLoading();

    const list = ref<DateValue[]>([]);

    const load = (): Promise<void> => withLoading(async () => {
        const result = await settlementApi.listIncomeDate();

        list.value = result.map(each => new DateValue(each));
    });

    return {
        state: {
            loading,
            list,
        },
        load,
    };
};

export const useSettlementOutgoDateList = () => {
    const { state: { loading }, withLoading } = useLoading();

    const list = ref<DateValue[]>([]);

    const load = (): Promise<void> => withLoading(async () => {
        const result = await settlementApi.listOutgoDate();

        list.value = result.map(each => new DateValue(each));
    });

    return {
        state: {
            loading,
            list,
        },
        load,
    };
};
