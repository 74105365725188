import _ from 'lodash';
import { FormModel } from 'ant-design-vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as types from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';
// @ts-ignore
import Password from '@/components/Account/Edit/Password';
import { Const } from '@/const';

const accountMod = namespace('account');

@Component({
    title: '初回パスワード設定',
    components: {
        UiPreLoginLayout,
        Password,
    },
})
export default class AccountPasswordRegisterPage extends PageVue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ type: String })
    declare readonly verificationCode?: string;

    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(types.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @accountMod.Action(types.ACTION.UPDATE_PASSWORD)
    updatePassword!: types.updatePassword;

    get name(): string {
        return this.ACCOUNT_PROFILE?.name ?? '';
    }

    // ======================================================
    // Data
    // ======================================================
    appServiceName = Const.APP_SERVICE_NAME;
    loading = false;

    form: types.AccountPasswordUpdateForm = {
        password: '',
    };

    /**
     * ボタンが押下された際に呼び出される。
     */
    onSubmit(): void {
        const notifyFailedKey = 'UPDATE_PASSWORD_FAILED';

        const notifyLoginFailed = () => {
            this.$notification.error({
                key: notifyFailedKey,
                message: 'パスワードの設定ができませんでした。',
                description:
                    '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        };

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            const param = _.merge({}, this.form, { verificationCode: this.verificationCode });

            const success = await this.updatePassword(param)
                .then(() => true)
                .catch(() => false);

            if (success) {
                await this.$router.push({ name: 'BaggageList' });
            } else {
                notifyLoginFailed();
            }

            this.loading = false;
        });
    }
}
