import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { computed, onMounted, onUpdated } from 'vue';
import { TbxRouteMeta } from '@/router/routes/types';
import { useCompanyContracts } from '@/composables/global/company-contracts';

export const useTruckHelper = () => {
    const { currentRoute } = useRoute();
    const { goToTruckSearch, goToTruckRegister, goToTruckList, goToCompanyTruckList, goToTruckScheduler } = useRouting();

    const { state: { contracts } } = useCompanyContracts();
    const routeActiveTabKey = computed(() => (currentRoute.meta as TbxRouteMeta).activeTabKey ?? '');

    const onChangeTab = async (tabKey: string) => {
        switch (tabKey) {
            case 'search':
                await goToTruckSearch();
                return;
            case 'register':
                await goToTruckRegister();
                return;
            case 'list':
                await goToTruckList();
                return;
            case 'company-truck':
                await goToCompanyTruckList();
                return;
            case 'scheduler':
                await goToTruckScheduler();
                return;
            default:
                throw new Error(`IllegalArgument: tabKey=${ tabKey }`);
        }
    };

    onMounted(async () => {
        await goToDefaultPage();
    });

    onUpdated(async () => {
        await goToDefaultPage();
    });

    const goToDefaultPage = async () => {
        if (currentRoute.name !== 'Truck' || !contracts.value) {
            return;
        }
        switch (contracts.value?.activePlanType) {
            case 'FREE':
                await goToTruckList();
                break;
            case 'PREMIUM':
            case 'TRIAL':
                await goToTruckSearch();
                break;
            default:
                throw new Error('activePlan is not defined.');
        }
    };

    return {
        routeActiveTabKey,
        onChangeTab
    };
};
