import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/company/types';

@Component
export default class CompanyPaymentDayEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileExtraUpdateForm;

    /**
     * 支払日
     */
    get paymentDay(): number | undefined {
        return this.value?.paymentDay?.code;
    }

    set paymentDay(newValue: number | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.paymentDay = _.isNumber(newValue) ? { code: newValue } : undefined;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get accountingDay(): Array<{ value: number; label: string; key: number }> {
        return Const.accountingDay.map((each) => ({
            value: each.code,
            label: each.label,
            key: each.code,
        }));
    }
}
