/**
 * 運送区分コード
 */
export type BaggageCategoryEnumCode = 'BC1' | 'BC2';

export class BaggageCategoryEnum {
    constructor(public code: BaggageCategoryEnumCode, public label: string, public formItemLabel: string) {}

    static Normal = new BaggageCategoryEnum('BC1', '一般', '引越しを除く');
    static Relocation = new BaggageCategoryEnum('BC2', '引越し', '引越しのみ');

    static valueOf = (code: BaggageCategoryEnumCode): BaggageCategoryEnum | undefined =>
        BaggageCategoryEnum.values.find((value) => value.code === code);
    static values = [BaggageCategoryEnum.Normal, BaggageCategoryEnum.Relocation];
}
