import { computed, Ref } from 'vue';
import _, { isNaN } from 'lodash';
import dayjs from 'dayjs';
import { AgreementUtil, BaggageUtil, DateUtil, Util } from '@/util';
import { Baggage, NewBaggageUpdateFormModel as BaggageUpdateFormModel } from '@/models/baggage';
import { CompanyProfile } from '@/models/company';
import { DateValue } from '@/models/vo/date';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';

// TODO: 荷物登録、成約登録でも使えるようにする

/**
 * 荷物ライクオブジェクトの編集用ViewModel
 */
export const useBaggageInputViewModel = (
    model: Ref<BaggageUpdateFormModel>,
    baggage: Baggage,
    companyProfile: CompanyProfile,
    validate: (props: (keyof BaggageUpdateFormModel)[]) => void,
    changed: () => void,
    circleChanged: () => void,
) => {

    const validateDeliveryDateTimeRange = () => {
        validate(['departureDateTimeRange', 'arrivalDateTimeRange']);
    };

    const validateDepartureLocation = () => {
        validate(['departurePrefCode', 'departureCity', 'departureAddress']);
    };

    const validateArrivalLocation = () => {
        validate(['arrivalPrefCode', 'arrivalCity', 'arrivalAddress']);
    };

    const normalizeNumericString = (value: string | undefined): string | undefined => {
        if (value === undefined) {
            return undefined;
        }
        return isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    };

    const withChange = <T>(newValue: T, prevValue: T, block: (newValue: T) => void) => {
        if (!_.isEqual(newValue, prevValue)) {
            block(newValue);
            changed();
        }
    };

    const withCircleChange = <T>(newValue: T, prevValue: T, block: (newValue: T) => void) => {
        if (!_.isEqual(newValue, prevValue)) {
            block(newValue);
            circleChanged();
        }
    };

    const departureDateTimeRange = computed({
        get: () => model.value.departureDateTimeRange,
        set: (value) => withChange(value, model.value.departureDateTimeRange, () => {
            model.value.departureDateTimeRange = value;
            validateDeliveryDateTimeRange();
        })
    });

    const arrivalDateTimeRange = computed({
        get: () => model.value.arrivalDateTimeRange,
        set: (value) => withChange(value, model.value.arrivalDateTimeRange, () => {
            model.value.arrivalDateTimeRange = value;
            validateDeliveryDateTimeRange();
        })
    });

    const departurePref = computed({
        get: () => model.value.departurePrefCode,
        set: (value) => withChange(value, model.value.departurePrefCode, () => {
            model.value.departurePrefCode = value;

            // 都道府県が変更になったら、入力済の市区町村以下をクリアする
            model.value.departureCity = '';
            model.value.departureAddress = '';

            validateDepartureLocation();
        })
    });

    const departureCity = computed<string | undefined>({
        get: () => model.value.departureCity,
        set: (value) => withChange(value, model.value.departureCity, () => {
            model.value.departureCity = value;
            validateDepartureLocation();
        })
    });

    const departureAddress = computed({
        get: () => model.value.departureAddress,
        set: (value) => withChange(value, model.value.departureAddress, () => {
            model.value.departureAddress = value;
            validateDepartureLocation();
        })
    });

    const arrivalPref = computed({
        get: () => model.value.arrivalPrefCode,
        set: (value) => withChange(value, model.value.arrivalPrefCode, () => {
            model.value.arrivalPrefCode = value;

            // 都道府県が変更になったら、入力済の市区町村以下をクリアする
            model.value.arrivalCity = '';
            model.value.arrivalAddress = '';

            validateArrivalLocation();
        })
    });

    const arrivalCity = computed({
        get: () => model.value.arrivalCity,
        set: (value) => withChange(value, model.value.arrivalCity, () => {
            model.value.arrivalCity = value;
            validateArrivalLocation();
        })
    });

    const arrivalAddress = computed({
        get: () => model.value.arrivalAddress,
        set: (value) => withChange(value, model.value.arrivalAddress, () => {
            model.value.arrivalAddress = value;
            validateArrivalLocation();
        })
    });

    const loadingTimeNote = computed({
        get: () => model.value.loadingTimeNote,
        set: (value) => withChange(value, model.value.loadingTimeNote, () => {
            model.value.loadingTimeNote = value;
        }),
    });

    const unloadingTimeNote = computed({
        get: () => model.value.unloadingTimeNote,
        set: (value) => withChange(value, model.value.loadingTimeNote, () => {
            model.value.unloadingTimeNote = value;
        })
    });

    const isShapePalette = computed(() => model.value.shape === BaggageShapeEnum.Palette.code);
    const isShapeOther = computed(() => model.value.shape === BaggageShapeEnum.Other.code);
    const shape = computed({
        get: () => model.value.shape,
        set: (value) => withChange(value, model.value.shape, () => {
            model.value.shape = value;
            if (isShapePalette.value) {
                model.value.shapeDetail.BS1.type = 'パレット';
            }
        }),
    });

    const type = computed({
        get: () => model.value.type,
        set: (value) => {
            if (model.value.shape === undefined) {
                return;
            }
            const shape = model.value.shape;
            withChange(value, model.value.shapeDetail[shape].type, () => {
                model.value.shapeDetail[shape].type = value;
                switch (BaggageUtil.includesRelocateWord(value?.trim())) {
                    case true:
                        // 引っ越しという文字が入っていたらカテゴリに引っ越しを設定する
                        model.value.category = BaggageCategoryEnum.Relocation.code;
                        break;
                    case false:
                        if (model.value.category === BaggageCategoryEnum.Relocation.code) {
                            model.value.category = BaggageCategoryEnum.Normal.code;
                        }
                }
            });
        },
    });

    const paletteCount = computed({
        get: () => model.value.paletteCount,
        set: (value) => withChange(normalizeNumericString(value), model.value.shapeDetail.BS1.paletteCount, (newValue) => {
            model.value.shapeDetail.BS1.paletteCount = newValue;
        })
    });

    const paletteHeight = computed({
        get: () => model.value.paletteHeight,
        set: (value) => withChange(normalizeNumericString(value), model.value.shapeDetail.BS1.paletteHeight, (newValue) => {
            model.value.shapeDetail.BS1.paletteHeight = newValue;
        })
    });

    const paletteWidth = computed({
        get: () => model.value.paletteWidth,
        set: (value) => withChange(normalizeNumericString(value), model.value.shapeDetail.BS1.paletteWidth, (newValue) => {
            model.value.shapeDetail.BS1.paletteWidth = newValue;
        })
    });

    const totalCount = computed({
        get: () => model.value.totalCount,
        set: (value) => withChange(normalizeNumericString(value), model.value.shapeDetail.BS2.totalCount, (newValue) => {
            model.value.shapeDetail.BS2.totalCount = newValue;
        })
    });

    const totalVolume = computed({
        get: () => model.value.totalVolume,
        set: (value) => withChange(normalizeNumericString(value), model.value.shapeDetail.BS2.totalVolume, (newValue) => {
            model.value.shapeDetail.BS2.totalVolume = newValue;
        })
    });

    const totalWeight = computed({
        get: () => model.value.totalWeight,
        set: (value) => withChange(normalizeNumericString(value), model.value.totalWeight, (newValue) => {
            model.value.totalWeight = newValue;
        })
    });

    const loadingOperation = computed({
        get: () => model.value.loadingOperation,
        set: (value) => withChange(value, model.value.loadingOperation, () => {
            model.value.loadingOperation = value;
        })
    });

    const unloadingOperation = computed({
        get: () => model.value.unloadingOperation,
        set: (value) => withChange(value, model.value.unloadingOperation, () => {
            model.value.unloadingOperation = value;
        })
    });

    const temperatureZone = computed({
        get: () => model.value.temperatureZone,
        set: (value) => withChange(value, model.value.temperatureZone, () => {
            model.value.temperatureZone = value;
        })
    });

    const truckType = computed({
        get: () => model.value.truckType,
        set: (value) => withChange(value, model.value.truckType, () => {
            model.value.truckType = value;
        })
    });

    const truckHeight = computed({
        get: () => model.value.truckHeight,
        set: (value) => withChange(value, model.value.truckHeight, () => {
            model.value.truckHeight = value;
        })
    });

    const truckWidth = computed({
        get: () => model.value.truckWidth,
        set: (value) => withChange(value, model.value.truckWidth, () => {
            model.value.truckWidth = value;
        })
    });

    const largeTruckAvailability = computed({
        get: () => model.value.largeTruckAvailability,
        set: (value) => withChange(value, model.value.largeTruckAvailability, () => {
            model.value.largeTruckAvailability = value;
        })
    });

    const truckEquipment = computed({
        get: () => model.value.truckEquipment,
        set: (value) => withChange(value, model.value.truckEquipment, () => {
            model.value.truckEquipment = value;
        })
    });

    const baggageFreight = computed({
        get: () => model.value.baggageFreight,
        set: (value) => withChange(value, model.value.baggageFreight, () => {
            model.value.baggageFreight = value;
        })
    });

    const highwayFarePaymentOption = computed({
        get: () => model.value.highwayFarePaymentOption,
        set: (value) => withChange(value, model.value.highwayFarePaymentOption, () => {
            model.value.highwayFarePaymentOption = value;
        })
    });

    const paymentDate = computed({
        get: () => model.value.paymentDate,
        set: (value) => withChange(value, model.value.paymentDate, () => {
            model.value.paymentDate = value;
        })
    });

    const traboxBaggageId = computed({
        get: () => model.value.traboxBaggageId,
        set: (value) => withChange(value, model.value.traboxBaggageId, () => {
            model.value.traboxBaggageId = value;
        })
    });

    const shipperName = computed({
        get: () => model.value.shipperName,
        set: (value) => withChange(value, model.value.shipperName, () => {
            model.value.shipperName = value;
        })
    });

    const circleId = computed({
        get: () => model.value.circleId,
        set: (value) => withCircleChange(value, model.value.circleId, () => {
            model.value.circleId = value;
        })
    });

    const label = computed({
        get: () => model.value.label,
        set: (value) => withChange(value, model.value.label, () => {
            model.value.label = value;
        })
    });

    // TODO: 荷物登録、成約登録にも同じようなロジックがあるのでひとつにまとめる
    const defaultPaymentDate = computed(() => {
        if (model.value.departureDateTimeRange === undefined) {
            return undefined;
        }

        // baggage-register-helper内の実装に準拠
        if (!companyProfile.hasPaymentTerms) return undefined;
        const departureDate = model.value.departureDateTimeRange.dateValue;
        const { cutOffDay, paymentMonth, paymentDay } = companyProfile;
        const paymentDate = AgreementUtil.paymentDate(departureDate.value, cutOffDay?.code, paymentMonth?.code, paymentDay?.code);
        return new DateValue(paymentDate);
    });

    /**
     * 入金予定日として選択できる範囲
     */
    const availablePaymentDateRange = computed(() => {
        // TODO: 着日未入力の場合は、入金予定日の入力ができないようにするほうが良い気がする。
        // TODO: BaggageRegisterForm.availablePaymentDateRange に準拠した実装なので型変換が多くかなり違和感が。。
        const criteria = (model.value.arrivalDateTimeRange ?? model.value.departureDateTimeRange)?.rawValues()?.[1]?.format() ?? '';
        return AgreementUtil.availablePaymentDateRange(new DateValue(criteria).value, false);
    });

    // TODO: 入金予定日入力UIに`availablePaymentDateRange`を渡せばこれは不要なのでは？
    /**
     * 入金予定日として選択できない日付であるか否か
     */
    const isDisabledPaymentDate = (currentDate: DateValue): boolean => {
        return !DateUtil.isIncluded(currentDate.value, availablePaymentDateRange.value);
    };

    const description = computed({
        get: () => model.value.description,
        set: (value) => withChange(value, model.value.description, () => {
            model.value.description = value;
        })
    });

    const isShare = computed({
        get: () => model.value.share,
        set: (value) => withChange(value, model.value.share, () => {
            model.value.share = value;
        })
    });

    const isExpress = computed({
        get: () => model.value.express,
        set: (value) => withChange(value, model.value.express, () => {
            model.value.express = value;
        })
    });

    const category = (isRelocate: boolean) => {
        return (isRelocate || BaggageUtil.includesRelocateWord(model.value.type))
            ? BaggageCategoryEnum.Relocation.code
            : BaggageCategoryEnum.Normal.code;
    };

    const isRelocate = computed({
        get: () => model.value.category === BaggageCategoryEnum.Relocation.code,
        set: (value) => withChange(category(value), model.value.category, (newValue) => {
            model.value.category = newValue;
        })
    });

    const staffName = computed<string | undefined>({
        get: () => model.value.staffName,
        set: (value) => withChange(value ?? '', model.value.staffName, (newValue) => {
            model.value.staffName = newValue;
        })
    });

    const onBlurDepartureCity = () => {
        departureCity.value = departureCity.value?.trim();
    };

    const onBlurDepartureAddress = () => {
        departureAddress.value = departureAddress.value?.trim();
    };

    const onBlurArrivalCity = () => {
        arrivalCity.value = arrivalCity.value?.trim();
    };

    const onBlurArrivalAddress = () => {
        arrivalAddress.value = arrivalAddress.value?.trim();
    };

    const onBlurLoadingTimeNote = () => {
        loadingTimeNote.value = loadingTimeNote.value?.trim();
    };

    const onBlurUnloadingTimeNote = () => {
        unloadingTimeNote.value = unloadingTimeNote.value?.trim();
    };

    const onBlurType = () => {
        type.value = type.value?.trim();
    };

    const onBlurTruckEquipment = () => {
        truckEquipment.value = truckEquipment.value?.trim();
    };

    const onBlurDescription = () => {
        description.value = description.value?.trim();
    };

    const onBlurStaffName = () => {
        staffName.value = staffName.value?.trim();
    };

    const onBlurShipperName = () => {
        shipperName.value = shipperName.value?.trim();
    };

    const selectableDateRange = computed<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
        const registered = DateUtil.parseDatetimeText(baggage.entryTm);
        return [registered.add(1, 'hour').startOf('hour'), registered.add(1, 'year').endOf('year')];
    });

    return {
        departureDateTimeRange,
        arrivalDateTimeRange,
        departurePref,
        departureCity,
        departureAddress,
        arrivalPref,
        arrivalCity,
        arrivalAddress,
        loadingTimeNote,
        unloadingTimeNote,
        shape,
        temperatureZone,
        isShapePalette,
        isShapeOther,
        type,
        paletteCount,
        paletteHeight,
        paletteWidth,
        totalCount,
        totalVolume,
        totalWeight,
        loadingOperation,
        unloadingOperation,
        truckType,
        truckHeight,
        truckWidth,
        largeTruckAvailability,
        truckEquipment,
        baggageFreight,
        highwayFarePaymentOption,
        paymentDate,
        defaultPaymentDate,
        availablePaymentDateRange,
        isDisabledPaymentDate,
        description,
        isShare,
        isExpress,
        isRelocate,
        staffName,
        shipperName,
        circleId,
        label,
        onBlurDepartureCity,
        onBlurDepartureAddress,
        onBlurArrivalCity,
        onBlurArrivalAddress,
        onBlurLoadingTimeNote,
        onBlurUnloadingTimeNote,
        onBlurType,
        onBlurTruckEquipment,
        onBlurDescription,
        onBlurStaffName,
        onBlurShipperName,
        selectableDateRange,
        traboxBaggageId,
    };
};
