<script setup lang="ts">
import { useSettlementPartnerCompanySearch } from '@/composables/settlement-partner-company-search';
import { nextTick, onMounted, ref } from 'vue';
import { CustomRow } from '@/types/ant-design';
import { SettlementPartnerCompanyModel } from '@/models/settlement';
import { CompanyProfileLocation } from '@/models/company';
import PhoneNumberText from '@/_units/PhoneNumberText.vue';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import { Vue } from 'vue-property-decorator';

const emits = defineEmits<{
    (e: 'select', value: SettlementPartnerCompanyModel): void,
    (e: 'selectAgreement', value: { id: number, myBaggage: boolean }): void,
}>();

const { state: { loading, list }, search } = useSettlementPartnerCompanySearch();
const keyword = ref('');
const columns = [
    {
        title: '会社名',
        dataIndex: 'profile.name.kanji',
    },
    {
        title: '住所',
        key: 'address',
        scopedSlots: { customRender: 'address' },
        width: 250,
    },
    {
        title: '電話番号',
        scopedSlots: { customRender: 'tel' },
        width: 150,
    },
    {
        title: '',
        scopedSlots: { customRender: 'action' },
        width: 120,
        className: 'action-cell',
        align: 'center',
    },
];

const customRow = (record: SettlementPartnerCompanyModel): CustomRow => {
    return {
        on: {
            click: async () => {
                emits('select', record);
            },
        },
    };
};

const formatLocation = (location: CompanyProfileLocation): string => {
    return `${location.prefecture.label ?? ''}${location.city}${location.address}`;
};

const clickSearch = () => {
    if (keyword.value.length === 0) return;
    search({ keyword: keyword.value });
};

const clickAgreementLink = (record: SettlementPartnerCompanyModel) => {
    const lastAgreement = record.lastAgreement;
    if (lastAgreement !== undefined) {
        emits('selectAgreement', lastAgreement);
    }
};

const keywordRef = ref<Vue | null>(null);

onMounted(() => {
    // 初期フォーカスを検索フィールドに設定
    const input = keywordRef.value?.$refs.input;
    if (input instanceof HTMLInputElement) {
        nextTick(() => {
            input.focus();
        });
    }
});
</script>

<template>
    <div class="tbx-custom-modal__container">
        <div class="tbx-custom-modal__header">
            <h3>取引する荷主を選択してください</h3>
        </div>

        <div class="tbx-custom-modal__body">
            <div class="search-form">
                <a-input v-model="keyword"
                         ref="keywordRef"
                         :max-length="30"
                         :allow-clear="true"
                         @pressEnter="clickSearch"
                         placeholder="キーワード 例：トラボックス"
                         class="search-form__keyword"/>
                <a-button type="primary"
                          icon="search"
                          :disabled="keyword.length === 0"
                          @click="clickSearch">検索</a-button>
            </div>

            <a-spin :spinning="loading">
                <a-table
                    :data-source="list"
                    :columns="columns"
                    :custom-row="customRow"
                    row-key="id"
                    :pagination="false"
                    size="middle"
                    :scroll="{ y: 300 }"
                    bordered>
                    <template #address="text, record">
                        <span>{{ formatLocation(record.profile.location) }}</span>
                    </template>
                    <template #tel="text, record">
                        <phone-number-text :phone-number="record.profile.phone.number"/>
                    </template>
                    <template #action="text, record">
                        <a-button v-if="record.lastAgreement"
                                  type="link"
                                  class="link-to-last-agreement"
                                  @click.stop="clickAgreementLink(record)"
                        ><tbx-link-text type="product">直近の成約</tbx-link-text></a-button>
                    </template>
                </a-table>
            </a-spin>
        </div>
    </div>
</template>

<style scoped lang="less">


.search-form {
    margin-bottom: 20px;

    .search-form__keyword {
        width: 400px;
    }

    > * + * {
        margin-left: 20px;
    }
}

.link-to-last-agreement {
    padding: 0;
}

:deep(.ant-table) {
    .ant-table-thead {
        > tr {
            > th {
                text-align: center;
                font-weight: @token-font-weight-bold;
            }
        }
    }

    // テーブル領域の高さ調整
    &:not(.ant-table-empty) {
        .ant-table-body {
            min-height: 150px;
        }
    }

    .ant-table-body {
        // antvの設定値を上書き
        max-height: calc(75vh - 250px) !important;
    }
}
</style>
