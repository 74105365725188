<script setup lang="ts">
import { useRouting } from '@/composables/helper/routing';
import { useRoute } from '@/composables/helper/route';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { useGlobalStatisticsService } from '@/composables/service/global-statistics-service';

const {
    state: { statistics, shouldShowGlobalStatistics }
} = useGlobalStatisticsService();

const { goToBaggageSearch, goToTruckSearch } = useRouting();
const { currentRoute } = useRoute();

/**
 * 荷物ボタンが押下された際に呼び出されます。
 */
const onClickBaggageSearch = () => {
    if (currentRoute.name === 'BaggageSearch') {
        return;
    }
    goToBaggageSearch();
};

/**
 * トラックボタンが押下された際に呼び出されます。
 */
const onClickTruckSearch = () => {
    if (currentRoute.name === 'TruckSearch') {
        return;
    }
    goToTruckSearch();
};

</script>

<template>
    <a-button-group v-if="shouldShowGlobalStatistics && statistics">
        <a-button @click="onClickBaggageSearch">
            <tbx-icon type="baggage" class="btn-icon"/>
            <span class="total-count-text">掲載中の荷物数 {{ statistics?.baggage.totalCount }}</span>
        </a-button>
        <a-button @click="onClickTruckSearch">
            <tbx-icon type="truck" class="btn-icon"/>
            <span class="total-count-text">掲載中の空車数 {{ statistics?.truck.totalCount }}</span>
        </a-button>
    </a-button-group>
</template>

<style scoped lang="less">
.ant-btn-group {
    display: flex;
    align-items: center;

    > .ant-btn:first-child:not(:last-child) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    > .ant-btn:last-child:not(:first-child) {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }
}

.ant-btn {
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: @color-junction-blue;

    .btn-icon {
        fill: @color-junction-blue;

        + span {
            margin-left: 0px;
        }
    }
}

.total-count-text {
    display: inline-block;
    padding-left: 8px;
}
</style>
