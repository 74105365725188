import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import _ from 'lodash';
import {
    DeliveryOrderExist,
    DeliveryOrderListViewForm,
    DeliveryOrderModel,
    DeliveryOrderRegisterForm,
    DeliveryOrderReplyForm,
    DeliveryOrderUpdateForm
} from '@/models/delivery-order';
import { deliveryOrderApi } from '@/repository/api/internal/delivery-order';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { FormValidateUtil } from '@/models/validate-helper';

/**
 * MEMO: 成約一覧画面をリファクタリングしたらこれを使う
 */
export const useDeliveryOrderList = () => {
    const { state: { loading }, withLoading } = useLoading();
    const deliveryOrderList = ref<DeliveryOrderModel[]>([]);

    const list = (agreementIds: number[]) => {
        return withLoading(async () => {
            const form = new DeliveryOrderListViewForm(agreementIds);
            deliveryOrderList.value = await deliveryOrderApi.list(form)
                .then((list) => list.map((item) => new DeliveryOrderModel(item)));
        });
    };

    return {
        state: {
            loading,
            deliveryOrderList,
        },
        list,
    };
};

export const useDeliveryOrderFind = () => {
    const { state: { loading }, withLoading } = useLoading();
    const deliveryOrder = ref<DeliveryOrderModel | undefined>();
    const find = (deliveryOrderId: number) => withLoading(async () => {
        deliveryOrder.value = new DeliveryOrderModel(await deliveryOrderApi.find(deliveryOrderId));
    });

    return {
        state: {
            loading,
            deliveryOrder,
        },
        find,
    };
};

export const useDeliveryOrderExist = () => {
    const { state: { loading }, withLoading } = useLoading();
    const deliveryOrderId = ref<DeliveryOrderExist | undefined>();

    const exist = (agreementId: number) => withLoading(async () => {
        deliveryOrderId.value = await deliveryOrderApi.exist(agreementId);
    });

    return {
        state: {
            loading,
            deliveryOrderId,
        },
        exist,
    };
};

export const useDeliveryOrderRegister = () => {
    const form = ref<DeliveryOrderRegisterForm>(new DeliveryOrderRegisterForm());

    const register = async () => {
        // eslint-disable-next-line no-useless-catch
        try {
            const deliveryOrderId = await deliveryOrderApi.register(form.value);
            return deliveryOrderId;
        } catch (e) {
            throw e;
        }
    };

    return {
        state: {
            form,
        },
        register,
    };
};

export const useDeliveryOrderUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<DeliveryOrderUpdateForm>(new DeliveryOrderUpdateForm());
    const sourceForm = ref<DeliveryOrderUpdateForm>(_.cloneDeep(form.value));
    const dirty = computed(() => !_.isEqual(form.value, sourceForm.value));
    const notification = useNotification();
    const validationRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const NOTIFICATION_KEY_FAILED_TO_UPDATE = 'UPDATE_DELIVERY_ORDER_ERROR';

    const update = (deliveryOrderId: number) => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_UPDATE);
        try {
            await deliveryOrderApi.update(deliveryOrderId, form.value.toForm());
            sourceForm.value = _.cloneDeep(form.value);
        } catch (e) {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_UPDATE,
                message: '配送依頼書の更新に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            throw e;
        }
    });

    const initForm = (deliveryOrder: DeliveryOrderModel) => {
        form.value = DeliveryOrderUpdateForm.of(deliveryOrder);
        sourceForm.value = _.cloneDeep(form.value);
    };

    return {
        state: {
            loading,
            form,
            dirty,
            validationRules,
        },
        initForm,
        update,
    };
};

/**
 * 依頼書を送信する
 */
export const useDeliveryOrderSend = () => {
    const { state: { loading }, withLoading } = useLoading();
    const message = useMessage();
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_SEND = 'SEND_DELIVERY_ORDER_ERROR';

    const send = (deliveryOrderId: number) => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_SEND);
        try {
            await deliveryOrderApi.send(deliveryOrderId);
            message.success('配車依頼書を送信しました。');
        } catch (e) {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_SEND,
                message: '配車依頼書の送信に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            throw e;
        }
    });

    return {
        state: {
            loading,
        },
        send,
    };
};

/**
 * 依頼書を既読にする
 */
export const useDeliveryOrderConfirm = () => {
    const { state: { loading }, withLoading } = useLoading();
    const message = useMessage();

    const confirm = (deliveryOrderId: number) => withLoading(async () => {
        try {
            await deliveryOrderApi.confirm(deliveryOrderId);
        } catch (e) {
            message.error('配送依頼書を確認できませんでした');
        }
    });

    return {
        state: {
            loading,
        },
        confirm,
    };
};

/**
 * 依頼書の更新を送信する
 */
export const useDeliveryOrderRevise = () => {
    const { state: { loading }, withLoading } = useLoading();
    const message = useMessage();
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_REVISE = 'REVISE_DELIVERY_ORDER_ERROR';

    const revise = (deliveryOrderId: number) => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_REVISE);
        try {
            await deliveryOrderApi.revise(deliveryOrderId);
            message.success('配車依頼書を送信しました。');
        } catch (e) {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_REVISE,
                message: '配車依頼書の送信に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            throw e;
        }
    });

    return {
        state: {
            loading,
        },
        revise,
    };
};

/**
 * 車番を返信する
 */
export const useDeliveryOrderReply = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<DeliveryOrderReplyForm>(new DeliveryOrderReplyForm());
    const sourceForm = ref<DeliveryOrderReplyForm>(_.cloneDeep(form.value));
    const dirty = computed(() => !_.isEqual(form.value, sourceForm.value));
    const message = useMessage();
    const notification = useNotification();
    const validationRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const NOTIFICATION_KEY_FAILED_TO_REPLY = 'REPLY_DELIVERY_ORDER_ERROR';

    /**
     * 車番返信
     */
    const reply = (deliveryOrderId: number) => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_REPLY);
        try {
            await deliveryOrderApi.reply(deliveryOrderId, form.value);
            message.success('車番を返信しました。');
            sourceForm.value = _.cloneDeep(form.value);
        } catch (e) {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_REPLY,
                message: '車番返信に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            throw e;
        }
    });

    /**
     * 車番変更
     */
    const reviseReply = (deliveryOrderId: number) => withLoading(async () => {
        notification.close(NOTIFICATION_KEY_FAILED_TO_REPLY);
        try {
            await deliveryOrderApi.reviseReply(deliveryOrderId, form.value);
            message.success('車番を返信しました。');
            sourceForm.value = _.cloneDeep(form.value);
        } catch (e) {
            notification.error({
                key: NOTIFICATION_KEY_FAILED_TO_REPLY,
                message: '車番返信に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            throw e;
        }
    });

    const initForm = (deliveryOrder: DeliveryOrderModel) => {
        form.value = DeliveryOrderReplyForm.of(deliveryOrder);
        sourceForm.value = _.cloneDeep(form.value);
    };

    return {
        state: {
            loading,
            form,
            dirty,
            validationRules,
        },
        reply,
        reviseReply,
        initForm,
    };
};

/**
 * 車番返信を確認する
 */
export const useDeliveryOrderConfirmReply = () => {
    const { state: { loading }, withLoading } = useLoading();
    const message = useMessage();

    const confirmReply = (deliveryOrderId: number) => withLoading(async () => {
        try {
            await deliveryOrderApi.confirmReply(deliveryOrderId);
        } catch (e) {
            message.error('配送依頼書を確認できませんでした');
        }
    });

    return {
        state: {
            loading,
        },
        confirmReply,
    };
};
