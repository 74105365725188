/**
 * 日本の地域 Enum
 */
import { PrefectureEnum, PrefectureEnumCode } from './prefecture.enum';

export type RegionEnumCode =
    | 'ALL'
    | 'A01'
    | 'A02'
    | 'A03'
    | 'A04'
    | 'A05'
    | 'A06'
    | 'A07'
    | 'A08'
    | 'A09'
    | 'A10'
    | 'A11';

export class RegionEnum {
    constructor(public code: RegionEnumCode, public label: string, public prefectures: PrefectureEnumCode[]) {}

    static All = new RegionEnum(
        'ALL',
        '全国',
        PrefectureEnum.values.map((each) => each.code)
    );
    static Hokkaido = new RegionEnum('A01', '北海道', [PrefectureEnum.Hokkaido.code]);
    static Tohoku = new RegionEnum('A02', '東北', [
        PrefectureEnum.Aomori.code,
        PrefectureEnum.Iwate.code,
        PrefectureEnum.Miyagi.code,
        PrefectureEnum.Akita.code,
        PrefectureEnum.Yamagata.code,
        PrefectureEnum.Fukushima.code,
    ]);
    static KitaKanto = new RegionEnum('A03', '北関東', [
        PrefectureEnum.Ibaraki.code,
        PrefectureEnum.Tochigi.code,
        PrefectureEnum.Gunma.code,
    ]);
    static Shutoken = new RegionEnum('A04', '首都圏', [
        PrefectureEnum.Saitama.code,
        PrefectureEnum.Chiba.code,
        PrefectureEnum.Tokyo.code,
        PrefectureEnum.Kanagawa.code,
    ]);
    static Koshinetsu = new RegionEnum('A05', '甲信越', [
        PrefectureEnum.Niigata.code,
        PrefectureEnum.Yamanashi.code,
        PrefectureEnum.Nagano.code,
    ]);
    static Hokuriku = new RegionEnum('A06', '北陸', [
        PrefectureEnum.Toyama.code,
        PrefectureEnum.Ishikawa.code,
        PrefectureEnum.Fukui.code,
    ]);
    static Tokai = new RegionEnum('A07', '東海', [
        PrefectureEnum.Gifu.code,
        PrefectureEnum.Shizuoka.code,
        PrefectureEnum.Aichi.code,
        PrefectureEnum.Mie.code,
    ]);
    static Kinki = new RegionEnum('A08', '近畿', [
        PrefectureEnum.Shiga.code,
        PrefectureEnum.Kyoto.code,
        PrefectureEnum.Osaka.code,
        PrefectureEnum.Hyogo.code,
        PrefectureEnum.Nara.code,
        PrefectureEnum.Wakayama.code,
    ]);
    static Chugoku = new RegionEnum('A09', '中国', [
        PrefectureEnum.Tottori.code,
        PrefectureEnum.Shimane.code,
        PrefectureEnum.Okayama.code,
        PrefectureEnum.Hiroshima.code,
        PrefectureEnum.Yamaguchi.code,
    ]);
    static Shikoku = new RegionEnum('A10', '四国', [
        PrefectureEnum.Tokushima.code,
        PrefectureEnum.Kagawa.code,
        PrefectureEnum.Ehime.code,
        PrefectureEnum.Kochi.code,
    ]);
    static KyushuOkinawa = new RegionEnum('A11', '九州・沖縄', [
        PrefectureEnum.Fukuoka.code,
        PrefectureEnum.Saga.code,
        PrefectureEnum.Nagasaki.code,
        PrefectureEnum.Kumamoto.code,
        PrefectureEnum.Oita.code,
        PrefectureEnum.Miyazaki.code,
        PrefectureEnum.Kagoshima.code,
        PrefectureEnum.Okinawa.code,
    ]);

    static prefecutureValueOf = (code: PrefectureEnumCode): RegionEnum | undefined =>
        RegionEnum.values.find((each) => each.prefectures.includes(code));
    static valueOf = (code: RegionEnumCode): RegionEnum | undefined =>
        RegionEnum.values.find((value) => value.code === code);
    /**
     *  地域一覧（「全国」を含まない）
     */
    static regionValues = [
        RegionEnum.Hokkaido,
        RegionEnum.Tohoku,
        RegionEnum.KitaKanto,
        RegionEnum.Shutoken,
        RegionEnum.Koshinetsu,
        RegionEnum.Hokuriku,
        RegionEnum.Tokai,
        RegionEnum.Kinki,
        RegionEnum.Chugoku,
        RegionEnum.Shikoku,
        RegionEnum.KyushuOkinawa,
    ];
    /**
     * 地域一覧（「全国」を含む）
     */
    static values = [RegionEnum.All, ...RegionEnum.regionValues];
}
