import type { Col } from 'ant-design-vue';
import { FormModel } from 'ant-design-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as accountTypes from '@/vuex/modules/account/types';
// @ts-ignore
import CompanyName from '@/components/Account/Register/CompanyName';
// @ts-ignore
import Location from '@/components/Account/Register/Location';
// @ts-ignore
import Phone from '@/components/Account/Register/Phone';
// @ts-ignore
import Name from '@/components/Account/Register/Name';
// @ts-ignore
import EmailAddress from '@/components/Account/Register/Email';
// @ts-ignore
import TruckCount from '@/components/Account/Register/TruckCount';
// @ts-ignore
import Url from '@/components/Account/Register/Url';
// @ts-ignore
import UiIconNewWindow from '@/components/UI/Icons/newwindow';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: {
        TbxLinkText,
        CompanyName,
        Location,
        Phone,
        Name,
        EmailAddress,
        TruckCount,
        Url,
        UiIconNewWindow,
    },
})
export default class AccountRegisterForm extends Vue {
    @Prop()
    declare readonly loading?: boolean;
    @Prop({ default: 'com' })
    declare readonly theme: 'com' | 'nejp';

    externalPageUrl = Const.externalPageUrl;
    form: accountTypes.AccountRegisterForm = {
        company: {
            companyName: '',
            zipCode: '',
            location: {
                prefecture: undefined,
                city: '',
                address: '',
            },
            phone: {
                number: '',
                faxNumber: '',
            },
            truckCount: '1',
            url: undefined,
        },
        member: {
            emailAddress: '',
            name: '',
        },
        trace: {
        },
    };

    /**
     * ne.jpのスタイルが適用されるか否かを取得します。
     */
    get isNeJpTheme(): boolean {
        return this.theme === 'nejp';
    }

    /**
     * リンクカラーを取得します。
     */
    get linkColor(): string {
        return this.isNeJpTheme ? '#006bc7' : '#16856e';
    }

    /**
     * フォームアイテムのサイズを取得します。
     */
    get formItemSize(): 'default' | 'large' {
        return this.isNeJpTheme ? 'large' : 'default';
    }

    /**
     * Formのlabelカラムデータを取得します。
     */
    get labelColumn(): Partial<Col> {
        if (this.isNeJpTheme) {
            return { lg: { span: 24, offset: 0 }, xl: { span: 8, offset: 0 } };
        }
        return { span: 8 };
    }

    /**
     * Formのwrapperカラムデータを取得します。
     */
    get wrapperColumn(): Partial<Col> {
        if (this.isNeJpTheme) {
            return { lg: { span: 24, offset: 0 }, xl: { span: 16, offset: 0 } };
        }
        return { span: 16 };
    }

    /**
     * フォームでバリデーション処理が実行されると呼び出されます。
     */
    onValidateForm(): void {
        this.$emit('onSetDirty', true);
    }

    /**
     * submitボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) {
                return;
            }
            this.$emit('onSubmit', this.form);
        });
    }
}
