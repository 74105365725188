import { Modal } from 'ant-design-vue';
import { ModalUtil } from '@/util';
// @ts-ignore
import Content from '@/pages/Baggage/List/drawers/AgreementDetailDrawerContent/AgreementChangeConfirmModal';

// ======================================================
// Confirmation Helpers
// ======================================================
/**
 * 成約変更注意喚起モーダルを表示します。
 */
export const confirmAgreementChange = (): Promise<boolean> => new Promise<boolean>((resolve) => Modal.confirm({
    icon: ModalUtil.createConfirmRegistrationIcon,
    title: 'ご確認ください',
    content: (h: Function) => h(Content),
    onOk: () => resolve(true),
    onCancel: () => resolve(false),
    okText: '変更を申請する',
    cancelText: '戻る',
    autoFocusButton: 'cancel',
    width: 680,
}));
