import { RenderHeader } from 'ant-design-vue/types/calendar';
import type { Dayjs } from 'dayjs';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { RenderContext } from 'vue/types/options';
import { TimeHourEnum } from '@/enums/time-hour.enum';
import { TimeMinuteEnum } from '@/enums/time-minute.enum';
import { DateUtil } from '@/util';
import { DateTimePickerValue } from '@/components/UI/DateTimePicker/types';
import { DeliveryDateTime, DeliveryDateTimeType } from '@/models/vo/delivery-datetime';
import { Const } from '@/const';
import dayjs from 'dayjs';

@Component({
    components: {
        VNodes: {
            functional: true,
            render: (_h: CreateElement, context: RenderContext) => context.props.vnodes,
        },
    },
})
export default class UiDateTimePicker extends Vue {
    @Prop()
    declare readonly value?: DateTimePickerValue; // `[min, max]` で値を扱う。
    @Prop()
    declare readonly size?: 'default' | 'large' | 'small'; // select メニューのサイズ
    @Prop({ default: false })
    declare readonly allowClear: boolean; // 選択解除を許容するか否か。
    @Prop({ default: '日時を選択' })
    declare readonly placeholder: string; // select メニューのプレースホルダ
    @Prop({ default: 'bottomLeft' })
    declare readonly placement: string; // ドロップダウン内のコンテンツを表示する位置
    @Prop()
    declare readonly disabledDate?: (currentDate: Dayjs) => boolean; // 指定した日付を選択できないようにする関数をバインドできる
    @Prop()
    declare readonly validRange?: [Dayjs | undefined, Dayjs | undefined]; // カレンダーで選択できる範囲。制限を設けない場合はundefined
    @Prop({ default: false })
    declare readonly disabled: boolean; // ドロップダウンを無効（非活性）にするか否か。
    @Prop({ default: false })
    declare readonly onlyJustTime: boolean;
    @Prop({ default: true })
    declare readonly borderLeft: boolean;
    @Prop({ default: true })
    declare readonly borderRight: boolean;
    @Prop({ default: true })
    declare readonly arrow: boolean;

    calendarViewMode: 'month' | 'year' = 'month'; // カレンダーの表示モード。
    isMenuOpen = false; // ドロップダウンメニューの開閉状態
    timeHourEnumValues = TimeHourEnum.values.filter(e => {
        return !this.onlyJustTime || (e !== TimeHourEnum.Day && e !== TimeHourEnum.Morning && e !== TimeHourEnum.Afternoon);
    });
    timeMinuteEnumValues = TimeMinuteEnum.values.filter(e => {
        return !this.onlyJustTime || e !== TimeMinuteEnum.None;
    });

    private calendarValue: moment.Moment = moment().startOf('day'); // カレンダー部で表示/選択されている日付

    /**
     * model(DeliveryDateTime)を取得します。
     */
    get model(): DeliveryDateTime | null {
        const min = this.value ? this.value[0] : '';
        const max = this.value ? this.value[1] : '';
        if (!min || !max) {
            return null;
        }
        return DeliveryDateTime.of(min, max);
    }

    set model(dateTime: DeliveryDateTime | null) {
        const value: DateTimePickerValue = dateTime === null ? [undefined, undefined] : dateTime.rawValuesAsString();
        this.emitValue(value);
    }

    /**
     * カレンダーヘッダー部のタイトルテキストを取得します
     */
    get calendarHeaderTitle(): string {
        if (!this.calendarValue) {
            return '';
        }
        return this.calendarValue.format('YYYY年 M月');
    }

    /**
     * 表示用ラベルを取得します。
     */
    get labelText(): string {
        if (!this.model) {
            return '';
        }
        return this.model.format();
    }

    get borderStyle(): string {
        let style = '';
        if (!this.borderLeft)
            style += 'border-left: 0;';
        if (!this.borderRight)
            style += 'border-right: 0;';
        return style;
    }

    /**
     * Select（ant-select）に付与するstyleClassを取得します。
     */
    get selectStyleClass(): { [key: string]: boolean } {
        return {
            'ant-select-enabled': !this.disabled,
            'ant-select-disabled': this.disabled,
            'ant-select-lg': this.size === 'large',
            'ant-select-sm': this.size === 'small',
            'ant-select-default': this.size === 'default',
            'ant-select-open': this.isMenuOpen,
        };
    }

    /**
     * 時刻（時間）パネルを開くべきかを取得します。
     */
    get shouldOpenHourSelectPanel(): boolean {
        return this.calendarViewMode !== 'year' && this.model !== null;
    }

    /**
     * 時刻（分）パネルを開くべきかを取得します。
     */
    get shouldOpenMinuteSelectPanel(): boolean {
        if (!this.shouldOpenHourSelectPanel || this.model === null) {
            return false;
        }
        return this.model.type === 'Hourly' || this.model.type === 'Just';
    }

    /**
     * 日付を選択できる範囲を取得します
     */
    get toMomentValidRange(): Array<moment.Moment | undefined> | undefined {
        if (!this.validRange) {
            return undefined;
        }
        // convert dayjs to moment object
        return this.validRange.map((value) =>
            value ? moment(value.format(Const.INTERNAL_DATETIME_FORMAT), Const.INTERNAL_DATETIME_FORMAT) : undefined
        );
    }

    created(): void {
        if (this.model) {
            this.calendarValue = moment(this.model.date.format(Const.INTERNAL_DATETIME_FORMAT));
        }
    }

    /**
     * カレンダーで選択した日時がmodelを同一かどうか
     * @param date
     */
    isSelectedDate(date: moment.Moment): boolean {
        if (!this.model) {
            return false;
        }
        return this.model.date.isSame(date.format(Const.INTERNAL_DATE_FORMAT), 'day');
    }

    /**
     * 選択した日時がdisabledかどうかを取得します
     */
    isDisabledHour(type: DeliveryDateTimeType, hour?: number): boolean {
        // 日付を選んでいない場合はdisabled
        if (!this.model) {
            return true;
        }

        let [modelMin] = this.model.rawValues();
        if (type === 'Hourly') {
            modelMin = modelMin.clone().hour(hour as number);
        }
        return this.isDisabledDateTime(DeliveryDateTime.typeOf(type, modelMin));
    }

    /**
     * 選択した時間がselectedかどうかを取得します
     */
    isSelectedHour(timeHour: TimeHourEnum): boolean {
        if (!this.model) {
            return false;
        }
        switch (this.model.type) {
            case 'Hourly':
            case 'Just': {
                const [hour] = this.model.rawValuesAsString('HH');
                return timeHour.hour === Number(hour);
            }
            default:
                return this.model.type === timeHour.type;
        }
    }

    /**
     * 選択した分がdisabledかどうかを取得します
     * @param timeMinute
     */
    isDisabledMinute(timeMinute: TimeMinuteEnum): boolean {
        if (!this.model) {
            return true;
        }
        const [modelMin] = this.model.rawValues();
        if (timeMinute.type === 'Hourly') {
            return this.isDisabledHour(timeMinute.type, modelMin.hour());
        }
        return this.isDisabledDateTime(
            DeliveryDateTime.typeOf(timeMinute.type, modelMin.minute(timeMinute.minute as number))
        );
    }

    /**
     * 選択した分がselectedかどうかを取得します
     */
    isSelectedMinute(timeMinute: TimeMinuteEnum): boolean {
        if (!this.model || this.model.type !== timeMinute.type) {
            return false;
        }

        if (this.model.type === 'Just') {
            const [min] = this.model.rawValuesAsString('m');
            return timeMinute.minute === Number(min);
        }
        return true;
    }

    /**
     * ドロップダウンの開く／閉じるが切り替わった際に呼び出されます。
     */
    onVisibleChange(visible: boolean): void {
        this.isMenuOpen = visible;
    }

    /**
     * カレンダーパネルで前月/前年をクリックした際に呼び出されます。
     */
    onClickPanelPrevButton(render: RenderHeader): void {
        if (!render.onChange) {
            return;
        }
        render.onChange(this.calendarValue.clone().subtract(1, render.type as 'year' | 'month'));
    }

    /**
     * カレンダーの年/月表示を切り替えます
     */
    onClickPanelTypeChange(): void {
        this.calendarViewMode = this.calendarViewMode === 'month' ? 'year' : 'month';
    }

    /**
     * カレンダーパネルで次月/次年をクリックした際に呼び出されます。
     */
    onClickPanelNextButton(render: RenderHeader): void {
        if (!render.onChange) {
            return;
        }
        render.onChange(this.calendarValue.clone().add(1, render.type as 'year' | 'month'));
    }

    /**
     * カレンダーパネルから日付を選択した際に呼び出されます。
     */
    onSelectCalendar(selectedDate: moment.Moment): void {
        // 月を選択する表示モードの状態で、なにか項目を選択した時は、日付選択カレンダーに表示モードを変更
        if (this.calendarViewMode === 'year') {
            this.calendarViewMode = 'month';
            return;
        }

        if (this.model === null) {
            const nextHour = moment().add(1, 'hour');
            const today = moment().startOf('day');
            const targetDay = selectedDate.startOf('day');
            const isToday = today.diff(targetDay) === 0;

            const criteria = dayjs((isToday ? nextHour : selectedDate.clone()).toDate());

            if (this.timeHourEnumValues.includes(TimeHourEnum.Day)) {
                this.model = DeliveryDateTime.typeOf('Day', criteria);
            } else {
                this.model = DeliveryDateTime.typeOf('Hourly', criteria);
            }
        } else {
            const [minTime, maxTime] = this.model.rawValuesAsString(Const.INTERNAL_TIME_FORMAT);
            this.model = DeliveryDateTime.of(
                `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ minTime }`,
                `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ maxTime }`
            );
        }
    }

    /**
     * カレンダーパネルから日付を変更した際に呼び出されます。
     */
    onChangeCalendar(changedDate: moment.Moment): void {
        this.calendarValue = changedDate;
    }

    /**
     * 時(Hour)をクリックした際に呼び出されます。
     */
    onClickSelectHour(timeHour: TimeHourEnum): void {
        if (this.model === null) {
            throw new Error('require set the date before select time.');
        } else if (this.isDisabledHour(timeHour.type, timeHour.hour)) {
            return;
        }

        if (timeHour.type === 'Hourly' && this.model.type === 'Just') {
            this.setTime(this.model.type, timeHour.hour);
        } else {
            this.setTime(timeHour.type, timeHour.hour);
        }
    }

    /**
     * 分(Minute)をクリックした際に呼び出されます。
     */
    onClickSelectMinute(timeMinute: TimeMinuteEnum): void {
        if (
            this.model === null ||
            this.model.type === 'Day' ||
            this.model.type === 'Morning' ||
            this.model.type === 'Afternoon'
        ) {
            throw new Error('require set the date before select time.');
        }
        const [hour] = this.model.rawValuesAsString('HH');
        if (timeMinute.type === 'Hourly') {
            this.setTime(timeMinute.type, Number(hour));
        } else if (timeMinute.type === 'Just' && timeMinute.minute !== undefined) {
            this.setTime(timeMinute.type, Number(hour), timeMinute.minute);
        }
    }

    /**
     * 指定なしボタンを押下した際に呼び出されます。
     */
    onClickDeselect(): void {
        this.model = null;
        this.closeDropdown();
    }

    /**
     * 確定ボタンを押下した際に呼び出されます。
     */
    onClickOk(): void {
        this.closeDropdown();
        return;
    }

    /**
     * ドロップダウンメニューを閉じます。
     */
    private closeDropdown(): void {
        this.isMenuOpen = false;
    }

    /**
     * DisabledなDateTimeかどうかを判定します。
     * @param value
     * @private
     */
    private isDisabledDateTime(value: DeliveryDateTime | null): boolean {
        if (value === null) {
            return true;
        }
        // disabledDate を親からバインドしている場合はチェックを実行
        if (this.disabledDate !== undefined) {
            const isDisabled = value
                .rawValues()
                .map((each) => (this.disabledDate as (value: Dayjs) => boolean)(each))
                .some((each) => each);
            if (isDisabled) {
                return true;
            }
        }
        // validRange をバインドしている場合はRangeのバリデーションを実行
        if (this.validRange !== undefined) {
            const [min, max] = value.rawValues();
            const [rangeStart, rangeEnd] = this.validRange;
            if (rangeStart && rangeEnd) {
                const isValidRange =
                    max.isBetween(rangeStart, rangeEnd, value.type === 'Day' ? 'day' : 'second', '[]');
                if (!isValidRange) return true;
            } else if (rangeStart && rangeStart.isAfter(min)) {
                return true;
            } else if (rangeEnd && rangeEnd.isSameOrBefore(max)) {
                return true;
            }
        }
        return false;
    }

    /**
     * 指定した日をdisabledにします
     * @param currentDate ant-calendarがmoment型を渡してきます
     * @private
     */
    private disabledDateInternal(currentDate: moment.Moment): boolean {
        if (this.disabledDate === undefined) {
            return false;
        }
        return this.disabledDate(DateUtil.parseDatetimeText(currentDate.format(Const.INTERNAL_DATETIME_FORMAT)));
    }

    /**
     * 時刻を設定します。
     * @param type
     * @param hour
     * @param minute
     * @private
     */
    private setTime(type: DeliveryDateTimeType, hour?: number, minute?: number): void {
        if (this.model === null) {
            throw new Error('require set the date before select time.');
        }
        const [newValue] = this.model.rawValues();
        switch (type) {
            case 'Day':
            case 'Morning':
            case 'Afternoon':
                this.model = DeliveryDateTime.typeOf(type, newValue.clone());
                break;
            case 'Hourly':
                if (hour === undefined) {
                    return;
                }
                this.model = DeliveryDateTime.typeOf(type, newValue.clone().hour(hour));
                break;
            case 'Just': {
                let newTime = newValue.clone();
                if (hour !== undefined) {
                    newTime = newTime.hour(hour);
                }
                if (minute !== undefined) {
                    newTime = newTime.minute(minute);
                }
                this.model = DeliveryDateTime.typeOf(type, newTime);
                break;
            }
            default:
                throw new Error('type is invalid.');
        }
    }

    /**
     * 値をemitします。
     * @private
     */
    private emitValue(value: DateTimePickerValue): void {
        this.$emit('input', value);
        this.$emit('change', value);
    }
}
