import { ref } from 'vue';
import { BaggageFreightMaster, BaggageFreightMasterListForm } from '@/models/baggage';
import { baggageFreightMasterApi } from '@/repository/api/internal/baggage-freight-master';
import { useLoading } from '@/composables/helper/loading-helper';
import _ from 'lodash';

/**
 * 荷物参考運賃マスター一覧をロードする機能を提供します。
 */
export const useBaggageFreightMasterList = () => {
    const list = ref<BaggageFreightMaster[]>([]);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (form: BaggageFreightMasterListForm): Promise<void> => {
        await withLoading(async () => {
            if (_.isEmpty(form.conditions)) {
                list.value = [];
            } else {
                list.value = await baggageFreightMasterApi.list(form);
            }
        });
    };

    const clear = () => {
        list.value = [];
    };

    return {
        state: {
            loading,
            list,
        },
        load,
        clear,
    };
};
