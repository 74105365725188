import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyBranchNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    /**
     * 支店名
     */
    get branchName(): string {
        return this.value?.branchName ?? '';
    }

    set branchName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.branchName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.branchName = this.branchName.trim();
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (!this.value?.branchName) {
            callback('支店名を入力してください。');
        } else if ((this.value?.branchName ?? '').trim().length > 100) {
            callback('100文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
