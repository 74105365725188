import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class NegotiationType extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get negotiationType(): string {
        if (!this.baggage) return '';
        return this.baggage.negotiationType.label ?? '';
    }
}
