import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import { DateValue } from '@/models/vo/date';
import { SettlementSearchIncomeApiForm, SettlementSearchOutgoApiForm } from '@/models/settlement-search';
import {
    Settlement,
    SettlementChangeRequest,
    SettlementChangeRequestForm,
    SettlementIncomeRegisterApiForm,
    SettlementList,
    SettlementPartner,
    SettlementPartnerCompany
} from '@/models/settlement';
import {
    TransactionInvoice,
    TransactionInvoiceTerm,
    TransactionPayment,
    TransactionPaymentTerm
} from '@/models/transaction';

export const settlementApi = {
    /**
     * 決済を取得します。
     */
    find(settlementId: number, ref: string | undefined): Promise<{ settlement: Settlement, partner: SettlementPartner }> {
        return appAxios.get<JsonResponseBean<{ settlement: Settlement, partner: SettlementPartner }>>(`/api/settlement/${ settlementId }`).then(isSuccess);
    },
    /**
     * 決済変更リクエストを取得します。
     */
    findRequest(id: number): Promise<SettlementChangeRequest> {
        return appAxios
            .get<JsonResponseBean<SettlementChangeRequest>>(`/api/settlement/${ id }/request`)
            .then(isSuccess);
    },
    /**
     * 入金日一覧を取得します。
     */
    listIncomeDate(): Promise<string[]> {
        return appAxios.get<JsonResponseBean<string[]>>('/api/settlement/income/date-list').then(isSuccess);
    },
    /**
     * 出金日一覧を取得します。
     */
    listOutgoDate(): Promise<string[]> {
        return appAxios.get<JsonResponseBean<string[]>>('/api/settlement/outgo/date-list').then(isSuccess);
    },
    /**
     * 決済代行出金があるか否かをチェックします。
     */
    existsProxyOutgo(): Promise<boolean> {
        return appAxios.get<JsonResponseBean<boolean>>('/api/settlement/outgo/exists').then(isSuccess);
    },
    /**
     * 入金を検索します。
     */
    searchIncome(form: SettlementSearchIncomeApiForm): Promise<SettlementList> {
        return appAxios.post<JsonResponseBean<SettlementList>>('/api/settlement/income/search', form).then(isSuccess);
    },
    /**
     * 出金を検索します。
     */
    searchOutgo(form: SettlementSearchOutgoApiForm): Promise<SettlementList> {
        return appAxios.post<JsonResponseBean<SettlementList>>('/api/settlement/outgo/search', form).then(isSuccess);
    },
    /**
     * 入金の変更リクエスト一覧を取得します。
     */
    listRequestIncome(settlementIds: number[]): Promise<SettlementChangeRequest[]> {
        return appAxios.post<JsonResponseBean<SettlementChangeRequest[]>>('/api/settlement/income/request/list', { settlementIds }).then(isSuccess);
    },
    /**
     * 出金の変更リクエスト一覧を取得します。
     */
    listRequestOutgo(settlementIds: number[]): Promise<SettlementChangeRequest[]> {
        return appAxios.post<JsonResponseBean<SettlementChangeRequest[]>>('/api/settlement/outgo/request/list', { settlementIds }).then(isSuccess);
    },
    /**
     * 変更リクエストを申請する。
     */
    requestChange(settlementId: number, form: SettlementChangeRequestForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/request/change`, form).then(isSuccess);
    },
    /**
     * 包含リクエストを申請する。
     */
    requestInclude(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/request/include`).then(isSuccess);
    },
    /**
     * 除外リクエストを申請する。
     */
    requestExclude(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/request/exclude`).then(isSuccess);
    },
    /**
     * 変更リクエストを承認する。
     */
    approve(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/approve`).then(isSuccess);
    },
    /**
     * 変更リクエストを却下する。
     */
    reject(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/reject`).then(isSuccess);
    },
    /**
     * 変更リクエストを取り下げする。
     */
    cancel(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/cancel`).then(isSuccess);
    },
    /**
     * 確認済にする。
     */
    markAsConfirmed(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/confirmed/mark`).then(isSuccess);
    },
    /**
     * 未確認にする。
     */
    unmarkAsConfirmed(settlementId: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/settlement/${ settlementId }/confirmed/unmark`).then(isSuccess);
    },
    /**
     * 取引支払通知書の発行期間を取得します。
     */
    paymentTerm(): Promise<TransactionPaymentTerm> {
        return appAxios.get<JsonResponseBean<TransactionPaymentTerm>>('/api/settlement/payment-letter/term').then(isSuccess);
    },
    /**
     * 取引支払通知書一覧を取得します。
     */
    paymentList(year: number): Promise<TransactionPayment[]> {
        return appAxios.get<JsonResponseBean<TransactionPayment[]>>(`/api/settlement/payment-letter/${ year }`).then(isSuccess);
    },
    /**
     * 取引支払通知書ダウンロードURLを発行します。
     */
    issuePaymentLetterUrl(target: DateValue): Promise<string> {
        return appAxios.get<JsonResponseBean<string>>(`/api/settlement/payment-letter/${ target.year }/${ target.monthValue }/${ target.date }`).then(isSuccess);
    },
    /**
     * 請求書発行期間を取得します。
     */
    invoiceTerm(): Promise<TransactionInvoiceTerm> {
        return appAxios.get<JsonResponseBean<TransactionInvoiceTerm>>('/api/settlement/invoice/term').then(isSuccess);
    },
    /**
     * 請求書一覧を取得します。
     */
    listInvoice(year: number): Promise<TransactionInvoice[]> {
        return appAxios.get<JsonResponseBean<TransactionInvoice[]>>(`/api/settlement/invoice/${ year }`).then(isSuccess);
    },
    /**
     * 請求書ダウンロードURLを発行します。
     */
    downloadInvoiceUrl(target: DateValue): Promise<string> {
        return appAxios
            .get<JsonResponseBean<string>>(`/api/settlement/invoice/${ target.year }/${ target.monthValue }/${ target.date }`)
            .then(isSuccess);
    },
    /**
     * 企業別明細ダウンロードURLを発行します。
     */
    downloadInvoiceDetailUrl(target: DateValue, partnerCompanyId: number): Promise<string> {
        return appAxios
            .get<JsonResponseBean<string>>(`/api/settlement/invoice-detail/${ target.year }/${ target.monthValue }/${ target.date }/${ partnerCompanyId }`)
            .then(isSuccess);
    },
    /**
     * 取引追加可能な成約パートナー企業を検索します。
     */
    searchPartnerCompany(form: { keyword: string }): Promise<SettlementPartnerCompany[]> {
        return appAxios.post<JsonResponseBean<SettlementPartnerCompany[]>>(`/api/settlement/income/partner-company/search`, form).then(isSuccess);
    },
    /**
     * 取引追加します。
     */
    registerIncome(form: SettlementIncomeRegisterApiForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>(`/api/settlement/income/register`, form).then(isSuccess);
    }
};
