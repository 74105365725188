export const columns = [
    {
        title: '成約番号',
        dataIndex: 'id',
        align: 'center',
    },
    {
        title: '成約日時',
        key: 'agreementDate',
        scopedSlots: { customRender: 'agreementDate' },
    },
    {
        title: '荷物番号',
        dataIndex: 'baggageId',
    },
    {
        title: '成約会社名',
        key: 'baggageCompanyName',
        scopedSlots: { customRender: 'baggageCompanyName' },
    },
    {
        title: '発日発地',
        key: 'departure',
        scopedSlots: { customRender: 'departure' },
    },
    {
        title: '着日着地',
        key: 'arrival',
        scopedSlots: { customRender: 'arrival' },
    },
    {
        title: '運賃(税別)',
        scopedSlots: { customRender: 'freight' },
        align: 'right',
    },
    {
        title: '高速代(税込)',
        scopedSlots: { customRender: 'highwayFare' },
        align: 'right',
    },
    {
        title: '入金日',
        key: 'paymentDate',
        scopedSlots: { customRender: 'paymentDate' },
    },
    {
        title: '保証',
        key: 'guarantee',
        align: 'center',
        scopedSlots: { customRender: 'guarantee' },
    },
];
