import { useLoading } from '@/composables/helper/loading-helper';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountDelete = () => {
    const { state: { loading }, withLoading } = useLoading();
    const deleteAccount = async (id: number): Promise<void> => withLoading( async (): Promise<void> => {
        await accountApi.deleteProfile(id);
    });

    return {
        state: {
            loading,
        },
        deleteAccount,
    };
};
