import { baggageApi } from '@/repository/api/internal/baggage';
import { Karte } from '@/karte';
import { useLoading } from '@/composables/helper/loading-helper';
import { computed } from 'vue';

/**
 * 荷物の商談中登録機能を提供します。
 */
export const useBaggageMarkNegotiation = () => {
    const { state: { loading: loadingMark }, withLoading: withLoadingMark } = useLoading();
    const { state: { loading: loadingUnmark }, withLoading: withLoadingUnmark } = useLoading();

    const loading = computed(() => loadingMark.value || loadingUnmark.value);
    /**
     * 荷物を商談中にします。
     * @param baggageId
     */
    const markNegotiation = async (baggageId: number) => await withLoadingMark(async () => {
        await baggageApi.markNegotiation(baggageId);
        // KARTEイベント送信： 商談中
        Karte.trackTurnOnUnderNegotiationFlg(baggageId);
    });

    /**
     * 荷物の商談中を解除します
     * @param baggageId
     */
    const unmarkNegotiation = async (baggageId: number) => await withLoadingUnmark(async () => {
        await baggageApi.unmarkNegotiation(baggageId);
        // KARTEイベント送信： 商談中
        Karte.trackTurnOffUnderNegotiationFlg(baggageId);
    });

    return {
        state: {
            loading,
        },
        markNegotiation,
        unmarkNegotiation,
    };
};
