<script setup lang="ts">
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';
import { AccountProfileModel } from '@/models/account-profile';
import {
    CompanyContractListModel,
    CompanyContractPremiumPlanRevokeUnavailableCode
} from '@/models/company-contract-list';
import { computed } from 'vue';

const props = defineProps<{
    accountProfile?: AccountProfileModel,
    contractList?: CompanyContractListModel
}>();

type RevokeUnavailableCode =
    CompanyContractPremiumPlanRevokeUnavailableCode
    | 'NO_DATA'
    | 'NO_MAIN_ACCOUNT';

type RevocableReason = { result: boolean; code?: RevokeUnavailableCode };

const canRevokePremiumPlan = computed<RevocableReason>(() => {
    if (!props.accountProfile || !props.contractList) {
        return { result: false, code: 'NO_DATA' };
    } else if (!props.accountProfile.isMainAccount) {
        return { result: false, code: 'NO_MAIN_ACCOUNT' };
    }
    return props.contractList.canRevokePremiumPlan;
});
</script>

<template>
    <setting-layout title="プレミアムプランの停止">
        <template v-if="canRevokePremiumPlan.result">
            <slot />
        </template>
        <template v-else>
            <a-card>
                <!-- // プレミアムプラン契約を持っていない -->
                <a-result v-if="canRevokePremiumPlan.code === 'NO_PREMIUM_PLAN'"
                          status="warning"
                          title="本機能はご利用いただくことはできません。"
                          subTitle="プレミアムプランをご利用の場合のみ、停止手続きができます。"></a-result>
                <!-- // プレミアムプラン契約は持っているが、有料別途期間中である -->
                <a-result v-else-if="canRevokePremiumPlan.code === 'ACTIVE_X'"
                          status="warning">
                    <template #title>
                        プレミアムプランの初月無料利用期間のため、<br>利用停止手続きを行うことはできません。
                    </template>
                    <template #subTitle>
                        来月以降にプレミアムプランの停止手続きを行ってください。
                    </template>
                </a-result>
                <!-- // すでに解約済み or 支払い方法変更中 -->
                <a-result v-else-if="canRevokePremiumPlan.code === 'ALREADY_REVOKED'"
                          status="success">
                    <template #title>
                        すでにプレミアムプラン停止手続きが完了しているか、プラン変更のお申し込みがあるため停止できません。
                    </template>
                </a-result>
                <!-- // 管理者アカウントではないではない -->
                <a-result v-else-if="canRevokePremiumPlan.code === 'NO_MAIN_ACCOUNT'"
                          status="warning"
                          title="プレミアムプランの停止は、管理者の方のみ行えます。"
                          subTitle="お手数ですが、管理者の方にご依頼ください。"></a-result>
            </a-card>
        </template>
    </setting-layout>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}

.ant-result {
    ::v-deep(.ant-result-title) {
        line-height: 36px;
    }

    ::v-deep(.ant-result-subtitle) {
        margin-top: 16px;
        color: @ui-color-text;
    }
}
</style>
