import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyProfile } from '@/vuex/modules/company/types';
import { Baggage } from '@/vuex/modules/baggage/types';
// @ts-ignore
import BaggageId from '@/components/Baggage/View/Id';
// @ts-ignore
import Departure from '@/components/Baggage/View/Departure';
// @ts-ignore
import DepartureLocation from '@/components/Baggage/View/DepartureLocation';
// @ts-ignore
import Arrival from '@/components/Baggage/View/Arrival';
// @ts-ignore
import ArrivalLocation from '@/components/Baggage/View/ArrivalLocation';
// @ts-ignore
import BaggageType from '@/components/Baggage/View/Type';
// @ts-ignore
import Category from '@/components/Baggage/View/Category';
// @ts-ignore
import Truck from '@/components/Baggage/View/Truck';
// @ts-ignore
import SpecifiedTruck from '@/components/Baggage/View/SpecifiedTruck';
// @ts-ignore
import Freight from '@/components/Baggage/View/Freight';
// @ts-ignore
import HighwayFare from '@/components/Baggage/View/HighwayFare';
// @ts-ignore
import StaffName from '@/components/Baggage/View/StaffName';
// @ts-ignore
import Description from '@/components/Baggage/View/Description';
// @ts-ignore
import CompanyName from '@/components/Company/View/Name';
// @ts-ignore
import Phone from '@/components/Company/View/Phone';
// @ts-ignore
import Fax from '@/components/Company/View/Fax';

@Component({
    components: {
        BaggageId,
        Departure,
        DepartureLocation,
        Arrival,
        ArrivalLocation,
        BaggageType,
        Category,
        Truck,
        SpecifiedTruck,
        Freight,
        HighwayFare,
        StaffName,
        Description,
        CompanyName,
        Phone,
        Fax,
    },
})
export default class BaggagePrint extends Vue {
    @Prop()
    declare readonly companyProfile?: CompanyProfile;
    @Prop()
    declare readonly baggage?: Baggage;
}
