<script setup lang="ts">
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import dayjs, { Dayjs } from 'dayjs';
import { computed, nextTick, ref, watch } from 'vue';
import moment from 'moment';
import { DeliveryDateTimeRange, DeliveryDateTimeRangeType } from '@/models/vo/delivery-datetime-range';
import { Const } from '@/const';
import { DateUtil } from '@/util';
import { RenderHeader } from 'ant-design-vue/types/calendar';
import { TimeTypeEnum } from '@/enums/time-type.enum';

const props = withDefaults(defineProps<{
    value?: DateTimeRangePickerValue, // `[min, max]` で値を扱う。
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    allowClear?: boolean,  // 選択解除を許容するか否か。
    placeholder?: string,  // select メニューのプレースホルダ
    placement?: string,  // ドロップダウン内のコンテンツを表示する位置
    disabledDate?: (currentDate: Dayjs) => boolean, // 指定した日付を選択できないようにする関数をバインドできる
    validRange?: [Dayjs | undefined, Dayjs | undefined], // カレンダーで選択できる範囲。制限を設けない場合はundefined
    disabled?: boolean, // ドロップダウンを無効（非活性）にするか否か。
    borderLeft?: boolean,
    borderRight?: boolean,
    arrow?: boolean,
}>(), {
    allowClear: false,
    placeholder: '日時を選択',
    placement: 'bottomLeft',
    disabled: false,
    borderLeft: true,
    borderRight: true,
    arrow: true,
});
const emits = defineEmits<{
    (e: 'input', value: DateTimeRangePickerValue): void,
    (e: 'change', value: DateTimeRangePickerValue): void,
}>();
const isMenuOpen = ref<boolean>(false);
const calendarValue = ref<moment.Moment>(moment().startOf('day')); // カレンダー部で表示/選択されている日付
const isTimeTypePeriod = computed<boolean>(() => model.value?.type === 'Period');
const isTimeTypePeriodOrJust = computed<boolean>(() => model.value?.type === 'Period' || model.value?.type === 'Just');
const timeMin = ref<string>('');
const timeMax = ref<string>('');
const time = computed<string>({
    get: () => {
        return timeMin.value;
    },
    set: (value) => {
        timeMin.value = value;
        timeMax.value = value;
    }
});

const valueToTime = (value: string) => {
    value = value.replace(/[^[0-9][０-９]：:]/g, '');
    value = DateUtil.complementTime(value);
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!timeRegex.test(value))
        value = '';
    return value;
};

const onBlurTimeMin = (): void => {
    timeMin.value = valueToTime(timeMin.value);
    if (timeMin.value) {
        setTime('Period',
            Number(timeMin.value.split(':')[0]),
            Number(timeMin.value.split(':')[1]));
    }
    nextTick(() => {
        timeMin.value = model.value?.datetimeMin.format('HH:mm') ?? '';
        timeMax.value = model.value?.datetimeMax.format('HH:mm') ?? '';
    });
};

const onBlurTimeMax = (): void => {
    timeMax.value = valueToTime(timeMax.value);
    if (timeMax.value) {
        setTime('Period',
            undefined, undefined,
            Number(timeMax.value.split(':')[0]), Number(timeMax.value.split(':')[1]));
    }
    nextTick(() => {
        timeMin.value = model.value?.datetimeMin.format('HH:mm') ?? '';
        timeMax.value = model.value?.datetimeMax.format('HH:mm') ?? '';
    });
};

const onBlurTime = (): void => {
    time.value = valueToTime(time.value);
    if (time.value) {
        setTime('Just',
            Number(time.value.split(':')[0]),
            Number(time.value.split(':')[1]));
    }
    nextTick(() => {
        time.value = model.value?.datetimeMin.format('HH:mm') ?? '';
    });
};

/**
 * model(DeliveryDateTimeRange)を取得します。
 */
const model = computed<DeliveryDateTimeRange | null>({
    get: (): DeliveryDateTimeRange | null => {
        const min = props.value?.min ?? '';
        const max =  props.value?.max ?? '';
        const type = props.value?.type;
        if (!min || !max  || !type) {
            return null;
        }
        return DeliveryDateTimeRange.typeOf(type, min, max);
    },
    set: (dateTimeRange: DeliveryDateTimeRange | null) => {
        const [min, max] = dateTimeRange?.rawValuesAsString() ?? [undefined, undefined];
        const type = dateTimeRange?.type;
        emitValue({
            min,
            max,
            type
        });
    }
});

/**
 * カレンダーヘッダー部のタイトルテキストを取得します
 */
const calendarHeaderTitle = computed(() => calendarValue.value?.format('YYYY年 M月') ?? '');

/**
 * 表示用ラベルを取得します。
 */
const labelText = computed(() => model.value?.format() ?? '');
const borderStyle = computed(() => {
    const styles = [''];
    if (!props.borderLeft) {
        styles.push('border-left: 0;');
    }
    if (!props.borderRight) {
        styles.push('border-right: 0;');
    }
    return styles.join('');
});

/**
 * Select（ant-select）に付与するstyleClassを取得します。
 */
const selectStyleClass = computed<{ [key: string]: boolean }>(() => {
    return {
        'ant-select-enabled': !props.disabled,
        'ant-select-disabled': props.disabled,
        'ant-select-lg': props.size === 'large',
        'ant-select-sm': props.size === 'small',
        'ant-select-default': props.size === 'default',
        'ant-select-open': isMenuOpen.value,
    };
});

/**
 * 日付を選択できる範囲を取得します
 */
const toMomentValidRange = computed<Array<moment.Moment | undefined> | undefined>(() => {
    if (!props.validRange) {
        return undefined;
    }
    // convert dayjs to moment object
    return props.validRange.map((value) =>
        value ? moment(value.format(Const.INTERNAL_DATETIME_FORMAT), Const.INTERNAL_DATETIME_FORMAT) : undefined
    );
});

// created
watch(model, (value) => {
    if (value) {
        calendarValue.value = moment(value.date.format(Const.INTERNAL_DATETIME_FORMAT));
    }
}, { immediate: true });

/**
 * カレンダーで選択した日時がmodelを同一かどうか
 * @param date
 */
const isSelectedDate = (date: moment.Moment): boolean => {
    if (!model.value?.date) {
        return false;
    }
    return model.value.date.isSame(date.format(Const.INTERNAL_DATE_FORMAT));
};

/**
 * 選択した時間がselectedかどうかを取得します
 */
const isSelectedTimeType = (timeType: TimeTypeEnum): boolean => {
    if (!model.value) {
        return false;
    }
    return model.value.type === timeType.type;
};

/**
 * 選択した時期タイプがdisabledかどうかを取得します
 */
const isDisabledTimeType = (type: DeliveryDateTimeRangeType): boolean => {
    // 日付を選んでいない場合はdisabled
    if (!model.value) {
        return true;
    }

    let [modelMin, modelMax] = model.value.rawValues();
    return isDisabledDateTimeRange(DeliveryDateTimeRange.typeOf(type, modelMin, modelMax));
};

/**
 * ドロップダウンメニューを閉じます。
 */
const closeDropdown= (): void => {
    isMenuOpen.value = false;
};

/**
 * DisabledなDateTimeRangeかどうかを判定します。
 * @param value
 * @private
 */
const isDisabledDateTimeRange = (value: DeliveryDateTimeRange | null): boolean => {
    if (value === null) {
        return true;
    }
    // disabledDate を親からバインドしている場合はチェックを実行
    if (props.disabledDate !== undefined) {
        const isDisabled = value
            .rawValues()
            .map((each) => (props.disabledDate as (value: Dayjs) => boolean)(each))
            .some((each) => each);
        if (isDisabled) {
            return true;
        }
    }
    if (value.type === 'Period' || value.type === 'Just') return false;
    // validRange をバインドしている場合はRangeのバリデーションを実行
    if (props.validRange !== undefined) {
        const [min, max] = value.rawValues();
        const [rangeStart, rangeEnd] = props.validRange;
        if (rangeStart && rangeEnd) {
            const isValidRange =
                max.isBetween(rangeStart, rangeEnd, value.type === 'Day' ? 'day' : 'second', '[]');
            if (!isValidRange) return true;
        } else if (rangeStart && rangeStart.isAfter(min)) {
            return true;
        } else if (rangeEnd && rangeEnd.isSameOrBefore(max)) {
            return true;
        }
    }
    return false;
};

/**
 * 指定した日をdisabledにします
 * @param currentDate ant-calendarがmoment型を渡してきます
 * @private
 */
const disabledDateInternal = (currentDate: moment.Moment): boolean => {
    if (props.disabledDate === undefined) {
        return false;
    }
    return props.disabledDate(DateUtil.parseDatetimeText(currentDate.format(Const.INTERNAL_DATETIME_FORMAT)));
};

/**
 * 時刻を設定します。
 * @param type
 * @param hourMin
 * @param minuteMin
 * @param hourMax
 * @param minuteMax
 * @private
 */
const setTime = (type: DeliveryDateTimeRangeType, hourMin?: number, minuteMin?: number, hourMax?: number, minuteMax?: number): void => {
    if (model.value === null) {
        throw new Error('require set the date before select time.');
    }
    const [newValueMin, newValueMax] = model.value.rawValues();

    let newTimeMin = newValueMin.clone();
    if (hourMin !== undefined) newTimeMin = newTimeMin.hour(hourMin);
    if (minuteMin !== undefined) newTimeMin = newTimeMin.minute(minuteMin);

    let newTimeMax = newValueMax.clone();
    if (hourMax !== undefined) newTimeMax = newTimeMax.hour(hourMax);
    if (minuteMax !== undefined) newTimeMax = newTimeMax.minute(minuteMax);

    switch (type) {
        case 'Day':
        case 'Morning':
        case 'Afternoon':
            model.value = DeliveryDateTimeRange.typeOf(type, newValueMin.clone());
            break;
        case 'Just':
            model.value = DeliveryDateTimeRange.typeOf(type, newTimeMin);
            break;
        case 'Period': {
            if (newTimeMax.isBefore(newTimeMin)) {
                const tmp = newTimeMax;
                newTimeMax = newTimeMin;
                newTimeMin = tmp;
            }
            model.value = DeliveryDateTimeRange.typeOf(type, newTimeMin, newTimeMax);
            break;
        }
        default:
            throw new Error('type is invalid.');
    }
};

/**
 * 値をemitします。
 * @private
 */
const emitValue = (value: DateTimeRangePickerValue): void => {
    emits('input', value);
    emits('change', value);
};

//
// イベント
//
/**
 * ドロップダウンの開く／閉じるが切り替わった際に呼び出されます。
 */
const onVisibleChange = (visible: boolean): void => {
    isMenuOpen.value = visible;
    timeMin.value = model.value?.datetimeMin.format('HH:mm') ?? '';
    timeMax.value = model.value?.datetimeMax.format('HH:mm') ?? '';
};

/**
 * カレンダーパネルで前月/前年をクリックした際に呼び出されます。
 */
const onClickPanelPrevButton = (render: RenderHeader): void => {
    if (!render.onChange) {
        return;
    }
    render.onChange(calendarValue.value.clone().subtract(1, render.type as 'year' | 'month'));
};

/**
 * カレンダーパネルで次月/次年をクリックした際に呼び出されます。
 */
const onClickPanelNextButton = (render: RenderHeader): void => {
    if (!render.onChange) {
        return;
    }
    render.onChange(calendarValue.value.clone().add(1, render.type as 'year' | 'month'));
};

/**
 * カレンダーパネルから日付を選択した際に呼び出されます。
 */
const onSelectCalendar = (selectedDate: moment.Moment): void => {
    // 月を選択する表示モードの状態で、なにか項目を選択した時は、日付選択カレンダーに表示モードを変更

    if (model.value === null) {
        const nextHour = moment().add(1, 'hour');
        const today = moment().startOf('day');
        const targetDay = selectedDate.startOf('day');
        const isToday = today.diff(targetDay) === 0;

        const criteria = dayjs((isToday ? nextHour : selectedDate.clone()).toDate());

        model.value = DeliveryDateTimeRange.typeOf('Day', criteria);
    } else {
        const [minTime, maxTime] = model.value.rawValuesAsString(Const.INTERNAL_TIME_FORMAT);
        model.value = DeliveryDateTimeRange.typeOf(
            model.value.type,
            `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ minTime }`,
            `${ selectedDate.format(Const.INTERNAL_DATE_FORMAT) } ${ maxTime }`
        );
    }
};

/**
 * カレンダーパネルから日付を変更した際に呼び出されます。
 */
const onChangeCalendar = (changedDate: moment.Moment): void => {
    calendarValue.value = changedDate;
};

const onClickSelectTimeType = (newTimeType: TimeTypeEnum): void => {
    if (model.value === null) {
        throw new Error('require set the date before select time.');
    } else if (isDisabledTimeType(newTimeType.type)) {
        return;
    }
    setTime(newTimeType.type);
    nextTick(() => {
        timeMin.value = model.value?.datetimeMin.format('HH:mm') ?? '';
        timeMax.value = model.value?.datetimeMax.format('HH:mm') ?? '';
    });
};

/**
 * 指定なしボタンを押下した際に呼び出されます。
 */
const onClickDeselect = (): void => {
    model.value = null;
    closeDropdown();
};

/**
 * 確定ボタンを押下した際に呼び出されます。
 */
const onClickOk = (): void => {
    closeDropdown();
    return;
};

</script>

<template>
    <a-dropdown class="ui-datetime-type-select" :visible="isMenuOpen" :disabled="disabled" :trigger="['click']"
                :placement="placement" @visibleChange="onVisibleChange">
        <div class="ant-select" :class="selectStyleClass">
            <div class="ant-select-selection ant-select-selection--single" :style="borderStyle">
                <div class="ant-select-selection__rendered">
                    <!-- placeholder -->
                    <div class="ant-select-selection__placeholder"
                         :style="{ display: model === null ? 'block' : 'none' }"
                         style="user-select: none;">{{ placeholder }}
                    </div>
                    <!-- value for single -->
                    <div class="ant-select-selection-selected-value"
                         :style="{ display: model === null ? 'none' : 'block' }">{{ labelText }}
                    </div>
                </div>
                <span v-if="arrow" class="ant-select-arrow"><a-icon class="ant-select-arrow-icon" type="down"/></span>
            </div>
        </div>

        <!-- Dropdown Contents -->
        <template #overlay>
            <div class="dropdown-container">
                <div class="datetime-range-container">
                    <div class="datetime-container">
                        <a-calendar class="calendar"
                                    :value="calendarValue"
                                    mode="month"
                                    :fullscreen="false"
                                    :valid-range="toMomentValidRange"
                                    :disabled-date="disabledDateInternal"
                                    @change="onChangeCalendar"
                                    @select="onSelectCalendar">
                            <template #headerRender="render">
                                <nav>
                                    <ul class="calendar-header">
                                        <li class="calendar-header__button">
                                            <a-button type="link" size="large" @click="onClickPanelPrevButton(render)">
                                                <a-icon type="left"/>
                                            </a-button>
                                        </li>
                                        <li class="calendar-header__title"><span class="calendar-header__title__text">{{
                                                calendarHeaderTitle
                                            }}</span></li>
                                        <li class="calendar-header__button">
                                            <a-button type="link" size="large" @click="onClickPanelNextButton(render)">
                                                <a-icon type="right"/>
                                            </a-button>
                                        </li>
                                    </ul>
                                </nav>
                            </template>
                            <template #dateFullCellRender="date">
                                <div class="ant-fullcalendar-date calendar__date"
                                     :class="{ 'calendar__date--selected': isSelectedDate(date) }">
                                    <div class="ant-fullcalendar-value">{{ date.format('D') }}</div>
                                    <div class="ant-fullcalendar-content">
                                        <slot name="dateCellRender"></slot>
                                    </div>
                                </div>
                            </template>
                        </a-calendar>
                        <div class="time-picker-container"
                             :class="{ 'time-picker-container--closed': !isTimeTypePeriodOrJust }">
                            <div v-if="isTimeTypePeriod" style="width: 100%; display: flex; justify-content: space-between; align-items: center">
                                <a-input v-model="timeMin" @blur="onBlurTimeMin" style="width: 45%" placeholder="例：09:00"></a-input>
                                <span>〜</span>
                                <a-input v-model="timeMax" @blur="onBlurTimeMax" style="width: 45%" placeholder="例：11:00"></a-input>
                            </div>
                            <div v-else>
                                <a-input v-model="time" @blur="onBlurTime" style="width: 100%" placeholder="例：09:00"></a-input>
                            </div>
                        </div>
                    </div>
                    <div class="time-type-select">
                        <div class="time-type-select-header"></div>
                        <ul role="menu"
                            tabindex="0"
                            class="time-type-dropdown-menu ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content">
                            <li v-for="item in TimeTypeEnum.values"
                                :key="item.code"
                                role="menuitem"
                                class="time-type-dropdown-menu-item ant-dropdown-menu-item"
                                :class="{
                            'time-type-dropdown-menu-item-selected ant-select-dropdown-menu-item-selected': isSelectedTimeType(item),
                            'time-dropdown-menu-item-disabled ant-select-dropdown-menu-item-disabled': isDisabledTimeType(item.type)
                         }"
                                @click="onClickSelectTimeType(item)">{{ item.label }}
                            </li>
                        </ul>
                    </div>
                </div>
                <footer class="footer">
                    <nav>
                        <ul class="actions">
                            <li v-if="allowClear">
                                <a-button size="small" @click="onClickDeselect">日時を指定しない</a-button>
                            </li>
                            <li class="actions__ok">
                                <a-button type="primary" @click="onClickOk">確定</a-button>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </div>
        </template>
    </a-dropdown>
</template>

<style scoped lang="less">
.ui-datetime-type-select {
    &.ant-input {
        cursor: pointer;
    }
}

.dropdown-container {
    border-radius: @border-radius-base;
    background-color: @select-background;
    box-shadow: @box-shadow-base;
}

.datetime-range-container {
    display: flex;
    width: auto;
}

.datetime-container {
    min-width: 300px;
    border-right: @border-style-base @border-width-base @color-neutral-5;
    transition: width 300ms;
    overflow: hidden;

    &.datetime-container--closed {
        width: 0;
        border-right: none;
    }
}

// カレンダースタイル部
.calendar {

    ::v-deep .ant-fullcalendar-month-panel-cell.ant-fullcalendar-month-panel-cell-disabled {
        .ant-fullcalendar-month .ant-fullcalendar-value {
            color: @disabled-color;
        }
        &.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
            background: rgba(0, 0, 0, 0.1);;
        }
    }

    ::v-deep .ant-fullcalendar-disabled-cell {
        .ant-fullcalendar-date {
            color: @disabled-color;
            background: @disabled-bg;
            border: @border-width-base @border-style-base transparent;

            .ant-fullcalendar-value:hover {
                background: @disabled-bg;
            }
        }

        &.ant-fullcalendar-selected-day .ant-fullcalendar-date .ant-fullcalendar-value {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    ::v-deep .ant-fullcalendar-last-month-cell:not(.ant-fullcalendar-disabled-cell),
    ::v-deep .ant-fullcalendar-next-month-btn-day:not(.ant-fullcalendar-disabled-cell) {
        .ant-fullcalendar-value {
            color: @disabled-color;
            color: @text-color-secondary;
        }
    }

    ::v-deep .ant-fullcalendar-selected-day .ant-fullcalendar-value {
        color: @text-color;
        background: transparent;
    }

    .calendar__date {
        &--selected .ant-fullcalendar-value {
            color: @text-color-inverse;
            background: @primary-color;
        }
    }
}

.calendar-header {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    .calendar-header__button {
        .ant-btn {
            width: 40px;
            padding: 0;
            color: @text-color;

            &:hover {
                color: @primary-color;
            }
        }
    }

    .calendar-header__title {
        flex: 1;
        color: @heading-color;

        .calendar-header__title__text {
            display: inline-block;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
            user-select: none;
        }
    }
}

.time-picker-container {
    height: 52px;
    padding: 10px;
    border-top: @border-style-base @border-width-base @color-neutral-5;

    &.time-picker-container--closed {
        height: 0;
        padding: 0;
        border-top: none;
    }
}

.time-type-select {
    width: 100px;
    border-right: @border-style-base @border-width-base @color-neutral-5;
    transition: width 300ms;

    &.time-type-select--closed {
        width: 0;
        border-right: none;
    }
}

.time-type-select-header {
    border-bottom: @border-style-base @border-width-base @color-neutral-5;
    height: 40px + 1px;
    line-height: 40px;
    text-align: center;
    color: @heading-color;
    overflow: hidden;
}

.time-type-dropdown-menu {
    width: 100%;
    max-height: 272px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    overflow-x: hidden;

    .time-type-dropdown-menu-item {
        padding-top: 3px;
        padding-bottom: 3px;

        &:hover:not(.time-type-dropdown-menu-item-disabled) {
            background-color: @item-hover-bg;
        }

        &.time-type-dropdown-menu-item-selected:not(.time-type-dropdown-menu-item-disabled) {
            background-color: @select-item-active-bg;
        }
    }
}

.footer {
    border-top: @border-style-base @border-width-base @color-neutral-5;
    padding: 10px 12px;
}

.actions {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__ok {
        margin-left: auto;
    }
}
</style>
