<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    value?: string,
    placeholder?: string,
    trimOnBlur?: boolean,
}>(), {
    placeholder: '番地・建物（任意）',
    trimOnBlur: true,
});
const emit = defineEmits<{
    (e: 'input', value: string | undefined): void,
    (e: 'change', value: string | undefined): void,
    (e: 'blur'): void,
}>();

const address = computed<string | undefined>({
    get: () => props.value,
    set: (value): void => {
        emit('input', value);
        emit('change', value);
    },
});

const onBlur = (): void => {
    if (props.trimOnBlur) {
        const value = address.value?.trim();
        address.value = value;
        emit('change', value);
    }
    emit('blur');
};

</script>

<template>
    <a-input v-model="address"
             :placeholder="placeholder"
             @blur="onBlur">
    </a-input>
</template>

<style scoped lang="less">

</style>
