import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { masterApi } from '@/repository/api/api';
import { ACTION as a, GETTER as g, GuaranteePriceMaster, GuaranteeState, MUTATION as m } from './types';

const state: GuaranteeState = {
    priceMasterList: undefined,
};

const actions: ActionTree<GuaranteeState, void> = {
    /**
     * 運賃全額保証利用料金マスタを取得します。
     */
    [a.LOAD_GUARANTEE_PRICE_MASTER]: ({ commit }) =>
        masterApi.guaranteePrice().then((list) => commit(m.SET_GUARANTEE_PRICE_MASTER, list)),
};

const getters: GetterTree<GuaranteeState, void> = {
    [g.GUARANTEE_PRICE_MASTER]: (s) => s.priceMasterList,
};

const mutations: MutationTree<GuaranteeState> = {
    [m.SET_GUARANTEE_PRICE_MASTER]: (s, list: GuaranteePriceMaster[]) => (s.priceMasterList = list),
};

export const guarantee: Module<GuaranteeState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
