import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { Util } from '@/util';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';

@Component({
    components: {
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class CompanyLocationEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileUpdateForm;

    /**
     * 郵便番号
     */
    get zipCode(): string {
        return this.value?.zipCode ?? '';
    }

    set zipCode(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.zipCode = Util.toDigits(newValue).substr(0, 7);
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 都道府県
     */
    get prefecture(): string {
        return this.value?.location.prefecture.code ?? '';
    }

    set prefecture(newValue: string) {
        const changed = this.value?.location.prefecture.code !== newValue;

        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.location.prefecture = { code: newValue };
        cloned.location.city = changed ? '' : cloned.location.city;
        cloned.location.address = changed ? '' : cloned.location.address;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 市区町村
     */
    get city(): string {
        return this.value?.location.city ?? '';
    }

    set city(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.location.city = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * 番地・建物
     */
    get address(): string {
        return this.value?.location.address ?? '';
    }

    set address(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.location.address = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            transform: (): string => this.value?.zipCode ?? '',
            required: true,
            pattern: /[0-9]{7}/,
            message: '郵便番号は7桁の数字を入力してください。',
        },
        {
            transform: (): string => this.value?.location.prefecture.code ?? '',
            required: true,
            message: '都道府県を選択してください',
        },
        {
            transform: (): string => this.value?.location.city ?? '',
            required: true,
            whitespace: true,
            message: '市区町村を入力してください。',
        },
        {
            transform: (): string => this.value?.location.city ?? '',
            max: 200,
            message: '市区町村は200文字以内で入力してください。',
        },
        {
            transform: (): string => this.value?.location.address ?? '',
            required: true,
            whitespace: true,
            message: '番地・建物を入力してください。',
        },
        {
            transform: (): string => this.value?.location.address ?? '',
            max: 200,
            message: '番地・建物は200文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 市区町村のフォーカスが外れた際に呼び出されます。
     */
    onBlurCity(): void {
        this.city = this.city.trim();
    }

    /**
     * 番地・建物のフォーカスが外れた際に呼び出されます。
     */
    onBlurAddress(): void {
        this.address = this.address.trim();
    }
}
