import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import { Const } from '@/const';

@Component({
    title: 'プレミアムプランの停止手続きが完了しました',
})
export default class RevokePremiumPlanCompletePage extends PageVue {
    appServiceName = Const.APP_SERVICE_NAME;
}
