import { Enum } from '@/types/enum';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { DateUtil, RegionUtil, Util } from '@/util';
import { ValidateResult, Validator } from '@/validator';
import _ from 'lodash';
import { PaginatedList } from '@/types/paginated-list';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { DateTimePickerValue } from '@/_components/ui/types/date-time-picker-type';
import dayjs from 'dayjs';
import { Spot } from '@/_components/ui/types/spot-type';
import { TruckType } from '@/_components/ui/types/truck-type';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { FreightValue } from '@/models/vo/freight';
import { Const } from '@/const';
import { CompanyProfile } from '@/models/company';
import { DateValue } from '@/models/vo/date';
import { CompanyTruck } from '@/models/company-truck';
import { AgreementModel } from '@/models/agreement';

export interface TruckSearchForm {
    circleId?: number;
    departureFrom?: string;
    departureTo?: string;
    departurePref?: Array<Enum>;
    arrivalFrom?: string;
    arrivalTo?: string;
    arrivalPref?: Array<Enum>;
    truckWeight?: Array<Enum>;
    truckModel?: Array<Enum>;
    minFreight?: string;
    excludeUndecidedFreight: boolean;
    pageNo: number;
    pageSize: number;
    sortKey: string;
    sortOrder: string;
}

export class TruckSearchFormModel implements TruckSearchForm, FormValidatable<TruckSearchFormModel> {
    circleId?: number;
    departureFrom?: string;
    departureTo?: string;
    departurePref?: Array<Enum>;
    arrivalFrom?: string;
    arrivalTo?: string;
    arrivalPref?: Array<Enum>;
    truckWeight?: Array<Enum>;
    truckModel?: Array<Enum>;
    minFreight?: string;
    excludeUndecidedFreight: boolean;
    pageNo: number;
    pageSize: number;
    sortKey: string;
    sortOrder: 'ASC' | 'DESC';

    constructor(param: Partial<TruckSearchFormModel> | null = null) {
        this.circleId = param?.circleId;
        this.departureFrom = param?.departureFrom;
        this.departureTo = param?.departureTo;
        this.departurePref = param?.departurePref;
        this.arrivalFrom = param?.arrivalFrom;
        this.arrivalTo = param?.arrivalTo;
        this.arrivalPref = param?.arrivalPref;
        this.truckWeight = param?.truckWeight;
        this.truckModel = param?.truckModel;
        this.minFreight = param?.minFreight;
        this.excludeUndecidedFreight = param?.excludeUndecidedFreight ?? false;
        this.pageNo = param?.pageNo ?? 1;
        this.pageSize = param?.pageSize ?? Const.DEFAULT_PAGE_SIZE;
        this.sortKey = param?.sortKey ?? 'DEPARTURE';
        this.sortOrder = param?.sortOrder ?? 'ASC';
    }

    get departureFromValue(): DateValue | undefined {
        return this.departureFrom ? new DateValue(this.departureFrom) : undefined;
    }

    set departureFromValue(newValue: DateValue | undefined) {
        this.departureFrom = newValue ? newValue.toDateTime().startOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get departureToValue(): DateValue | undefined {
        return this.departureTo ? new DateValue(this.departureTo) : undefined;
    }

    set departureToValue(newValue: DateValue | undefined) {
        this.departureTo = newValue ? newValue.toDateTime().endOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get departurePrefCode(): string[] {
        // Enum配列を文字配列へ変換
        return this.departurePref?.map((each) => each.code) || [];
    }

    set departurePrefCode(newValue: string[]) {
        // 文字配列をEnum配列へ変換して更新
        this.departurePref = newValue.map((each) => ({ code: each }));
    }

    get arrivalFromValue(): DateValue | undefined {
        return this.arrivalFrom ? new DateValue(this.arrivalFrom) : undefined;
    }

    set arrivalFromValue(newValue: DateValue | undefined) {
        this.arrivalFrom = newValue ? newValue.toDateTime().startOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get arrivalToValue(): DateValue | undefined {
        return this.arrivalTo ? new DateValue(this.arrivalTo) : undefined;
    }

    set arrivalToValue(newValue: DateValue | undefined) {
        this.arrivalTo = newValue ? newValue.toDateTime().endOf('d').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    get arrivalPrefCode(): string[] {
        // Enum配列を文字配列へ変換
        return this.arrivalPref?.map((each) => each.code) || [];
    }

    set arrivalPrefCode(newValue: string[]) {
        // 文字配列をEnum配列へ変換して更新
        this.arrivalPref = newValue.map((each) => ({ code: each }));
    }

    get truckWeightCode(): string[] {
        return this.truckWeight?.map((each) => each.code) || [];
    }

    set truckWeightCode(newValue: string[]) {
        this.truckWeight = newValue.map((each) => ({ code: each }));
    }

    get truckModelCode(): string[] {
        return this.truckModel?.map((each) => each.code) || [];
    }

    set truckModelCode(newValue: string[]) {
        this.truckModel = newValue.map((each) => ({ code: each }));
    }

    get freight(): string {
        return this.minFreight ?? '';
    }

    set freight(newValue: string) {
        this.minFreight = Util.parseFreightString(newValue);
    }

    validator(): FormValidator<TruckSearchFormModel> {
        return {
            freight: [
                {
                    required: false,
                }
            ]
        };
    }
}

// 空車登録フォーム
export class TruckRegisterForm {
    circleId?: number;
    departureMin?: string;
    departureMax?: string;
    departurePref: { code?: string };
    additionalDeparturePref?: Array<Enum>;
    departureCity?: string;
    arrivalMin?: string;
    arrivalMax?: string;
    arrivalPref: { code?: string };
    additionalArrivalPref?: Array<Enum>;
    arrivalCity?: string;
    truckWeight?: { code?: string };
    truckModel?: { code?: string };
    minFreight?: string;
    staffName: string;
    description?: string;

    constructor(param: Partial<TruckRegisterForm> | null = null) {
        // 部屋ID
        this.circleId = param?.circleId;
        // 発情報
        this.departureMin = param?.departureMin ?? '';
        this.departureMax = param?.departureMax ?? '';
        this.departurePref = param?.departurePref ?? {};
        this.additionalDeparturePref = param?.additionalDeparturePref ?? [];
        this.departureCity = param?.departureCity ?? '';
        // 着情報
        this.arrivalMin = param?.arrivalMin ?? '';
        this.arrivalMax = param?.arrivalMax ?? '';
        this.arrivalPref = param?.arrivalPref ?? {};
        this.additionalArrivalPref = param?.additionalArrivalPref ?? [];
        this.arrivalCity = param?.arrivalCity ?? '';
        // トラック情報
        this.truckWeight = param?.truckWeight ?? {};
        this.truckModel = param?.truckModel ?? {};
        // 運賃
        this.minFreight = param?.minFreight ?? '';
        // 担当者
        this.staffName = param?.staffName ?? '';
        // 備考
        this.description = param?.description ?? '';
    }
}

// 空車登録フォームモデル
export class TruckRegisterFormModel extends TruckRegisterForm {
    /**
     * 要相談チェック状態
     */
    negotiate: boolean;

    /**
     * 発日時
     */
    get departure(): DeliveryDateTime | null | undefined {
        const defaultValue = DeliveryDateTime.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        if (!this.departureMin || !this.departureMax) return defaultValue;

        return DeliveryDateTime.of(this.departureMin, this.departureMax) ?? defaultValue;
    }

    set departure(param: DeliveryDateTime | null | undefined) {
        const defaultValue = DeliveryDateTime.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        const criteria = DateUtil.now().endOf('hour');
        const departure = param && Validator.validateDateTime(param, criteria).result ? param : defaultValue;

        const [min, max] = departure.rawValuesAsString();
        this.departureMin = min;
        this.departureMax = max;
    }

    /**
     * 着日時
     */
    get arrival(): DeliveryDateTime | null | undefined {
        if (!this.arrivalMin || !this.arrivalMax) return null;

        return DeliveryDateTime.of(this.arrivalMin, this.arrivalMax);
    }

    set arrival(param: DeliveryDateTime | null | undefined) {
        if (!param) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const criteria = DateUtil.now().endOf('hour');
        if (!Validator.validateDateTime(param, criteria).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        // 発日時との相対的な妥当性チェック
        if (!Validator.validateDateTimeRange(this.departure, param).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const [min, max] = param.rawValuesAsString();
        this.arrivalMin = min;
        this.arrivalMax = max;
    }

    /**
     * コンストラクタ
     */
    constructor(param: Partial<TruckRegisterFormModel> | null = null) {
        super(param);
        // 発日時は妥当性検査を経て再設定
        this.departure = _.clone(this.departure);
        // 着日時は妥当性検査を経て再設定
        this.arrival = _.clone(this.arrival);
        // 要相談
        this.negotiate = false;
    }

    /**
     * {@link TruckRegisterForm}を生成します。
     */
    toForm(): TruckRegisterForm {
        return new TruckRegisterForm(this);
    }

    static of(truck: Truck): TruckRegisterFormModel {
        const instance = new TruckRegisterFormModel();
        // 発情報
        // 日時は妥当性検査を経て設定
        instance.departure = DeliveryDateTime.of(truck.departureMin, truck.departureMax);
        instance.departurePref = { code: truck.departurePrefecture.code.toString() };
        instance.additionalDeparturePref = _.cloneDeep(truck.additionalDeparturePref);
        instance.departureCity = truck.departureCity;
        // 着情報
        // 日時は妥当性検査を経て設定
        instance.arrival = DeliveryDateTime.of(truck.arrivalMin, truck.arrivalMax);
        instance.arrivalPref = { code: truck.arrivalPrefecture.code.toString() };
        instance.additionalArrivalPref = _.cloneDeep(truck.additionalArrivalPref);
        instance.arrivalCity = truck.arrivalCity;
        // トラック情報
        instance.truckWeight = { code: truck.truckWeight.code.toString() };
        instance.truckModel = { code: truck.truckModel.code.toString() };
        // 運賃
        instance.minFreight = truck.freight?.toString();
        // 担当者
        instance.staffName = truck.staffName;
        // 備考
        instance.description = truck.description;
        // 要相談
        instance.negotiate = _.isNil(truck.freight);
        return instance;
    }
}

// 空車登録フォームモデル（リファクタ版）
export class TruckRegisterFormValidatableModel extends TruckRegisterForm implements FormValidatable<TruckRegisterFormModel> {
    /**
     * 発日時
     */
    get departureDateTime(): DateTimePickerValue {
        return [this.departureMin, this.departureMax];
    }

    set departureDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        this.departureMin = dateMin ?? '';
        this.departureMax = dateMax ?? '';
    }

    get departureSpot(): Spot {
        return {
            pref: this.departurePref?.code ? PrefectureEnum.valueOf(this.departurePref.code as PrefectureEnumCode) : undefined,
            city: this.departureCity,
        };
    }

    set departureSpot(spot: Spot) {
        this.departurePref = { code: spot.pref ? spot.pref?.code as string : undefined };
        this.departureCity = spot?.city ?? '';
    }

    /**
     * 着日時
     */
    get arrivalDateTime(): DateTimePickerValue {
        return [this.arrivalMin, this.arrivalMax];
    }

    set arrivalDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        this.arrivalMin = dateMin ?? '';
        this.arrivalMax = dateMax ?? '';
    }

    get arrivalSpot(): Spot {
        return {
            pref: this.arrivalPref?.code ? PrefectureEnum.valueOf(this.arrivalPref.code as PrefectureEnumCode) : undefined,
            city: this.arrivalCity,
        };
    }

    set arrivalSpot(spot: Spot) {
        this.arrivalPref = { code: spot.pref ? spot.pref?.code as string : undefined };
        this.arrivalCity = spot?.city ?? '';
    }

    get additionalDeparturePrefCode(): string[] {
        return this.additionalDeparturePref?.map(each => each.code) ?? [];
    }

    set additionalDeparturePrefCode(value: string[]) {
        this.additionalDeparturePref = value.map(each => ({ code: each }));
    }

    get additionalArrivalPrefCode(): string[] {
        return this.additionalArrivalPref?.map(each => each.code) ?? [];
    }

    set additionalArrivalPrefCode(value: string[]) {
        this.additionalArrivalPref = value.map(each => ({ code: each }));
    }

    get truckType(): TruckType {
        return {
            truckModel: this.truckModel?.code ? TruckModelEnum.valueOf(this.truckModel.code as TruckModelEnumCode) : undefined,
            truckWeight: this.truckWeight?.code ? TruckWeightEnum.valueOf(this.truckWeight.code as TruckWeightEnumCode) : undefined,
        };
    }

    set truckType(value: TruckType) {
        this.truckModel = value.truckModel ?? { code: undefined };
        this.truckWeight = value.truckWeight ?? { code: undefined };
    }

    get minFreightValue(): FreightValue | undefined {
        // 未入力: minFreightValue = undefined
        // 要相談: minFreightValue = FreightValue(undefined)
        if (_.isNil(this.minFreight)) {
            return new FreightValue(undefined);
        }
        if (_.isEmpty(this.minFreight)) {
            return undefined;
        }
        return new FreightValue(Util.toNumber(this.minFreight));
    }

    set minFreightValue(value: FreightValue | undefined) {
        // 未入力: minFreight = ''
        // 要相談: minFreight = undefined
        if (_.isNil(value)) {
            this.minFreight = '';
        } else if (value.isNegotiate) {
            this.minFreight = undefined;
        } else {
            this.minFreight = value.value?.toString();
        }
    }

    get minFreightDigit(): number {
        return Util.toNumber(this.minFreight ?? '');
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 今日の1時間後から1年後の年末まで
        return [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    }

    /**
     * コンストラクタ
     */
    constructor(param: Partial<TruckRegisterFormModel> | null = null) {
        super(param);
        this.departureDateTime = Util.requireNotNull(DeliveryDateTime.typeOf('Day', DateUtil.now())?.rawValuesAsString());
        this.arrivalDateTime = Util.requireNotNull(DeliveryDateTime.typeOf('Day', DateUtil.now().add(1, 'day'))?.rawValuesAsString());
        // NOTE 初期は要相談にする。private traboxでは最低運賃の入力を不要としたいため。
        this.minFreight = undefined;
    }

    /**
     * {@link TruckRegisterForm}を生成します。
     */
    toForm(): TruckRegisterForm {
        return new TruckRegisterForm(this);
    }

    /**
     * バリデーションの結果を取得します。
     */
    get dateTimeRangeValidateResult(): ValidateResult {
        const [departureMin, departureMax] = this.departureDateTime;
        const departure = departureMin && departureMax ? DeliveryDateTime.of(departureMin, departureMax) : null;
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const arrival = arrivalMin && arrivalMax ? DeliveryDateTime.of(arrivalMin, arrivalMax) : null;
        return Validator.validateDateTimeRange(departure, arrival);
    }

    /**
     * 出発日時のバリデーションを行う。
     */
    private validateDepartureDateTime(callback: (message?: string) => void): void {
        const [departureMin, departureMax] = this.departureDateTime;
        const value = DeliveryDateTime.of(departureMin ?? '', departureMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }

    /**
     * 出発場所のバリデーションを行う。
     */
    private validateDepartureAddress(callback: (message?: string) => void): void {
        if (!this.departurePref.code) {
            callback('都道府県を選択してください。');
        } else if (this.departureCity && this.departureCity.length > Const.MAX_CITY) {
            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    /**
     * 到着日時のバリデーションを行う。
     */
    private validateArrivalDateTime(callback: (message?: string) => void): void {
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const value = DeliveryDateTime.of(arrivalMin ?? '', arrivalMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else if (!this.dateTimeRangeValidateResult.result) {
            callback(this.dateTimeRangeValidateResult.message);
        } else {
            callback();
        }
    }

    /**
     * 到着場所のバリデーションを行う。
     */
    private validateArrivalAddress(callback: (message?: string) => void): void {
        if (!this.arrivalPref.code) {
            callback('都道府県を選択してください。');
        } else if (this.arrivalCity && this.arrivalCity.length > Const.MAX_CITY) {
            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    private validateTruckType(callback: (message?: string) => void): void {
        if (!this.truckWeight?.code) {
            callback('重量を選択してください。');
        } else if (!this.truckModel?.code) {
            callback('車種を選択してください。');
        } else {
            callback();
        }
    }

    private validateMinFreightValue(callback: (message?: string) => void): void {
        if (this.minFreightValue?.isNegotiate) {
            callback();
        } else if (this.minFreightDigit > 0) {
            callback();
        } else {
            callback('有効な金額を入力するか、要相談をチェックしてください。');
        }
    }

    validator(): FormValidator<TruckRegisterFormValidatableModel> {
        return {
            departureDateTime: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateDepartureDateTime(callback),
                }
            ],
            departureSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateDepartureAddress(callback),
                }
            ],
            arrivalDateTime: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateArrivalDateTime(callback),
                }
            ],
            arrivalSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateArrivalAddress(callback),
                }
            ],
            truckType: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateTruckType(callback),
                }
            ],
            minFreightValue: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateMinFreightValue(callback),
                }
            ],
            staffName: [
                {
                    required: true,
                    message: '入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                },
                {
                    max: 250,
                    message: '250文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: Const.MAX_TRUCK_DESCRIPTION_LENGTH,
                    message: `${ Const.MAX_TRUCK_DESCRIPTION_LENGTH }文字以内で入力してください。`,
                }
            ],
            circleId: [
                {
                    required: true,
                    message: `部屋を選択してください。`,
                }
            ],
        };
    }

    static of(truck: Truck): TruckRegisterFormValidatableModel {
        const instance = new TruckRegisterFormValidatableModel();
        instance.circleId = truck.circleId;
        // 発情報
        // 日時は妥当性検査を経て設定
        instance.departureDateTime = DeliveryDateTime.of(truck.departureMin, truck.departureMax)?.rawValuesAsString() ?? [undefined, undefined];
        instance.departurePref = { code: truck.departurePrefecture.code.toString() };
        instance.additionalDeparturePref = _.cloneDeep(truck.additionalDeparturePref);
        instance.departureCity = truck.departureCity;
        // 着情報
        // 日時は妥当性検査を経て設定
        instance.arrivalDateTime = DeliveryDateTime.of(truck.arrivalMin, truck.arrivalMax)?.rawValuesAsString() ?? [undefined, undefined];
        instance.arrivalPref = { code: truck.arrivalPrefecture.code.toString() };
        instance.additionalArrivalPref = _.cloneDeep(truck.additionalArrivalPref);
        instance.arrivalCity = truck.arrivalCity;
        // トラック情報
        instance.truckWeight = { code: truck.truckWeight.code.toString() };
        instance.truckModel = { code: truck.truckModel.code.toString() };
        // 運賃
        instance.minFreight = truck.freight?.toString();
        // 担当者
        instance.staffName = truck.staffName;
        // 備考
        instance.description = truck.description;
        return instance;
    }

    static initializeFromCompanyTruck(companyTruck: CompanyTruck): TruckRegisterFormValidatableModel {
        const instance = new TruckRegisterFormValidatableModel();

        // トラック情報
        instance.truckWeight = { code: companyTruck.truckWeight.code.toString() };
        instance.truckModel = { code: companyTruck.truckModel.code.toString() };
        // 備考
        instance.description = companyTruck.description;
        return instance;
    }

    static initializeFromAgreement(agreement: AgreementModel): TruckRegisterFormValidatableModel {
        const instance = new TruckRegisterFormValidatableModel();

        // 発
        const defaultDeparture = DeliveryDateTime.typeOf('Day', agreement.arrival.date);
        const departure = agreement.arrival.toDeliveryDateTime() ?? defaultDeparture;
        const [ departureMin, departureMax ] = departure?.rawValuesAsString() ?? ['', ''];

        instance.departureMin = departureMin;
        instance.departureMax = departureMax;
        instance.departurePref = agreement.arrivalPref;
        instance.departureCity = agreement.arrivalCity;

        // 着
        const nextDay = DeliveryDateTime.typeOf('Day', agreement.arrival.date.add(1, 'day'));
        const [arrivalMin, arrivalMax] = nextDay?.rawValuesAsString() ?? [undefined, undefined];

        instance.arrivalMin = arrivalMin;
        instance.arrivalMax = arrivalMax;
        instance.arrivalPref = agreement.departurePref;
        instance.arrivalCity = agreement.departureCity;

        // 車量
        const truckWeight = TruckWeightEnum.truckValueOf(agreement.truckWeight.code as TruckWeightEnumCode);
        if (truckWeight) {
            instance.truckWeight = truckWeight;
        }

        // 車種
        const truckModel = TruckModelEnum.truckValueOf(agreement.truckModel.code as TruckModelEnumCode);
        if (truckModel) {
            instance.truckModel = truckModel;
        }

        // 担当者
        instance.staffName = agreement.staffName;

        return instance;
    }
}


// 空車更新フォーム
export class TruckUpdateForm {
    circleId?: number;
    departureMin: string;
    departureMax: string;
    departurePref: { code?: string };
    additionalDeparturePref: Array<Enum>;
    departureCity: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: { code?: string };
    additionalArrivalPref: Array<Enum>;
    arrivalCity: string;
    truckWeight: { code?: string };
    truckModel: { code?: string };
    minFreight?: string;
    staffName: string;
    description: string;

    constructor(param: Partial<TruckUpdateForm> | null = null) {
        // 部屋
        this.circleId = param?.circleId;
        // 発情報
        this.departureMin = param?.departureMin ?? '';
        this.departureMax = param?.departureMax ?? '';
        this.departurePref = param?.departurePref ?? {};
        this.additionalDeparturePref = param?.additionalDeparturePref ?? [];
        this.departureCity = param?.departureCity ?? '';
        // 着情報
        this.arrivalMin = param?.arrivalMin ?? '';
        this.arrivalMax = param?.arrivalMax ?? '';
        this.arrivalPref = param?.arrivalPref ?? {};
        this.additionalArrivalPref = param?.additionalArrivalPref ?? [];
        this.arrivalCity = param?.arrivalCity ?? '';
        // トラック情報
        this.truckWeight = param?.truckWeight ?? {};
        this.truckModel = param?.truckModel ?? {};
        // 運賃
        this.minFreight = param?.minFreight ?? '';
        // 担当者
        this.staffName = param?.staffName ?? '';
        // 備考
        this.description = param?.description ?? '';
    }
}

export class TruckUpdateFormModel extends TruckUpdateForm implements FormValidatable<TruckUpdateFormModel> {
    /**
     * 発日時
     */
    get departure(): DeliveryDateTime | null | undefined {
        const defaultValue = DeliveryDateTime.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        if (!this.departureMin || !this.departureMax) return defaultValue;

        return DeliveryDateTime.of(this.departureMin, this.departureMax) ?? defaultValue;
    }

    set departure(param: DeliveryDateTime | null | undefined) {
        const defaultValue = DeliveryDateTime.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        const criteria = DateUtil.now().endOf('hour');
        const departure = param && Validator.validateDateTime(param, criteria).result ? param : defaultValue;

        const [min, max] = departure.rawValuesAsString();
        this.departureMin = min;
        this.departureMax = max;
    }

    get departureDateTime(): DateTimePickerValue {
        return [this.departureMin, this.departureMax];
    }

    set departureDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        this.departureMin = dateMin ?? '';
        this.departureMax = dateMax ?? '';
    }

    get departureSpot(): Spot {
        return {
            pref: this.departurePref?.code ? PrefectureEnum.valueOf(this.departurePref.code as PrefectureEnumCode) : undefined,
            city: this.departureCity,
        };
    }

    set departureSpot(spot: Spot) {
        this.departurePref = { code: spot.pref ? spot.pref?.code as string : undefined };
        this.departureCity = spot?.city ?? '';
    }

    /**
     * 着日時
     */
    get arrival(): DeliveryDateTime | null | undefined {
        if (!this.arrivalMin || !this.arrivalMax) return null;

        return DeliveryDateTime.of(this.arrivalMin, this.arrivalMax);
    }

    set arrival(param: DeliveryDateTime | null | undefined) {
        if (!param) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const criteria = DateUtil.now().endOf('hour');
        if (!Validator.validateDateTime(param, criteria).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        // 発日時との相対的な妥当性チェック
        if (!Validator.validateDateTimeRange(this.departure, param).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const [min, max] = param.rawValuesAsString();
        this.arrivalMin = min;
        this.arrivalMax = max;
    }

    get arrivalDateTime(): DateTimePickerValue {
        return [this.arrivalMin, this.arrivalMax];
    }

    set arrivalDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        this.arrivalMin = dateMin ?? '';
        this.arrivalMax = dateMax ?? '';
    }

    get arrivalSpot(): Spot {
        return {
            pref: this.arrivalPref?.code ? PrefectureEnum.valueOf(this.arrivalPref.code as PrefectureEnumCode) : undefined,
            city: this.arrivalCity,
        };
    }

    set arrivalSpot(spot: Spot) {
        this.arrivalPref = { code: spot.pref ? spot.pref?.code as string : undefined };
        this.arrivalCity = spot?.city ?? '';
    }

    get additionalDeparturePrefCode(): string[] {
        return this.additionalDeparturePref?.map(each => each.code) ?? [];
    }

    set additionalDeparturePrefCode(value: string[]) {
        this.additionalDeparturePref = value.map(each => ({ code: each }));
    }

    get additionalArrivalPrefCode(): string[] {
        return this.additionalArrivalPref?.map(each => each.code) ?? [];
    }

    set additionalArrivalPrefCode(value: string[]) {
        this.additionalArrivalPref = value.map(each => ({ code: each }));
    }

    get truckType(): TruckType {
        return {
            truckModel: this.truckModel.code ? TruckModelEnum.valueOf(this.truckModel.code as TruckModelEnumCode) : undefined,
            truckWeight: this.truckWeight.code ? TruckWeightEnum.valueOf(this.truckWeight.code as TruckWeightEnumCode) : undefined,
        };
    }

    set truckType(value: TruckType) {
        this.truckModel = value.truckModel ?? { code: undefined };
        this.truckWeight = value.truckWeight ?? { code: undefined };
    }

    get minFreightValue(): FreightValue | undefined {
        // 未入力: minFreightValue = undefined
        // 要相談: minFreightValue = FreightValue(undefined)
        if (_.isNil(this.minFreight)) {
            return new FreightValue(undefined);
        }
        if (_.isEmpty(this.minFreight)) {
            return undefined;
        }
        return new FreightValue(Util.toNumber(this.minFreight));
    }

    set minFreightValue(value: FreightValue | undefined) {
        // 未入力: minFreight = ''
        // 要相談: minFreight = undefined
        if (_.isNil(value)) {
            this.minFreight = '';
        } else if (value.isNegotiate) {
            this.minFreight = undefined;
        } else {
            this.minFreight = value.value?.toString();
        }
    }

    get minFreightDigit(): number {
        return Util.toNumber(this.minFreight ?? '');
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 今日の1時間後から1年後の年末まで
        return [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    }

    /**
     * コンストラクタ
     */
    constructor(param: Partial<TruckUpdateFormModel> | null = null) {
        super(param);
    }

    /**
     * {@link TruckUpdateForm}を生成します。
     */
    toForm(): TruckUpdateForm {
        return new TruckUpdateForm(this);
    }

    /**
     * バリデーションの結果を取得します。
     */
    get dateTimeRangeValidateResult(): ValidateResult {
        const [departureMin, departureMax] = this.departureDateTime;
        const departure = departureMin && departureMax ? DeliveryDateTime.of(departureMin, departureMax) : null;
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const arrival = arrivalMin && arrivalMax ? DeliveryDateTime.of(arrivalMin, arrivalMax) : null;
        return Validator.validateDateTimeRange(departure, arrival);
    }

    /**
     * 出発日時のバリデーションを行う。
     */
    private validateDepartureDateTime(callback: (message?: string) => void): void {
        const [departureMin, departureMax] = this.departureDateTime;
        const value = DeliveryDateTime.of(departureMin ?? '', departureMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else {
            callback();
        }
    }

    /**
     * 出発場所のバリデーションを行う。
     */
    private validateDepartureAddress(callback: (message?: string) => void): void {
        if (!this.departurePref) {
            callback('都道府県を選択してください。');
        } else if (this.departureCity && this.departureCity.length > Const.MAX_CITY) {
            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    /**
     * 到着日時のバリデーションを行う。
     */
    private validateArrivalDateTime(callback: (message?: string) => void): void {
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const value = DeliveryDateTime.of(arrivalMin ?? '', arrivalMax ?? '');
        const validated = Validator.validateDateTime(value);
        if (!validated.result) {
            callback(validated.message);
        } else if (!this.dateTimeRangeValidateResult.result) {
            callback(this.dateTimeRangeValidateResult.message);
        } else {
            callback();
        }
    }

    /**
     * 到着場所のバリデーションを行う。
     */
    private validateArrivalAddress(callback: (message?: string) => void): void {
        if (!this.arrivalPref) {
            callback('都道府県を選択してください。');
        } else if (this.arrivalCity && this.arrivalCity.length > Const.MAX_CITY) {
            callback(`市区町村を${ Const.MAX_CITY }文字以内で入力してください。`);
        } else {
            callback();
        }
    }

    private validateTruckType(callback: (message?: string) => void): void {
        if (!this.truckWeight?.code) {
            callback('重量を選択してください。');
        } else if (!this.truckModel?.code) {
            callback('車種を選択してください。');
        } else {
            callback();
        }
    }

    private validateMinFreightValue(callback: (message?: string) => void): void {
        if (this.minFreightValue?.isNegotiate) {
            callback();
        } else if (this.minFreightDigit > 0) {
            callback();
        } else {
            callback('有効な金額を入力するか、要相談をチェックしてください。');
        }
    }

    validator(): FormValidator<TruckUpdateFormModel> {
        return {
            departureDateTime: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateDepartureDateTime(callback),
                }
            ],
            departureSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateDepartureAddress(callback),
                }
            ],
            arrivalDateTime: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateArrivalDateTime(callback),
                }
            ],
            arrivalSpot: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateArrivalAddress(callback),
                }
            ],
            truckType: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateTruckType(callback),
                }
            ],
            minFreightValue: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateMinFreightValue(callback),
                }
            ],
            staffName: [
                {
                    required: true,
                    message: '入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                },
                {
                    max: 250,
                    message: '250文字以内で入力してください。',
                },
            ],
            description: [
                {
                    max: Const.MAX_TRUCK_DESCRIPTION_LENGTH,
                    message: `${ Const.MAX_TRUCK_DESCRIPTION_LENGTH }文字以内で入力してください。`,
                }
            ],
            circleId: [
                {
                    required: true,
                    message: `部屋を選択してください。`,
                }
            ],
        };
    }

    /**
     * {@link Truck}から{@link TruckUpdateFormModel}を生成します。
     */
    static of(truck: Truck): TruckUpdateFormModel {
        const instance = new TruckUpdateFormModel();
        // 部屋
        instance.circleId = truck.circleId;
        // 発情報
        // 日時は妥当性検査を経て設定
        instance.departure = DeliveryDateTime.of(truck.departureMin, truck.departureMax);
        instance.departurePref = { code: truck.departurePrefecture.code.toString() };
        instance.additionalDeparturePref = _.cloneDeep(truck.additionalDeparturePref);
        instance.departureCity = truck.departureCity ?? '';
        // 着情報
        // 日時は妥当性検査を経て設定
        instance.arrival = DeliveryDateTime.of(truck.arrivalMin, truck.arrivalMax);
        instance.arrivalPref = { code: truck.arrivalPrefecture.code.toString() };
        instance.additionalArrivalPref = _.cloneDeep(truck.additionalArrivalPref);
        instance.arrivalCity = truck.arrivalCity ?? '';
        // トラック情報
        instance.truckWeight = { code: truck.truckWeight.code.toString() };
        instance.truckModel = { code: truck.truckModel.code.toString() };
        // 運賃
        instance.minFreight = truck.freight?.toString();
        // 担当者
        instance.staffName = truck.staffName;
        // 備考
        instance.description = truck.description;
        return instance;
    }
}

// 空車情報一覧のリクエストボディ
export interface TruckListForm {
    pageNo: number;
    pageSize: number;
}

// 空車情報一覧のレスポンスボディ
export interface TruckList extends PaginatedList {
    data: Truck[];
}

export interface TruckWithCompanyList {
    data: (Truck & { company: CompanyProfile | undefined })[] | undefined;
    currentPageNumber?: number | undefined;
    pageSize?: number | undefined;
    totalPageCount?: number | undefined;
    totalRecordCount?: number | undefined;
}

// 空車情報アイテム
export type TruckStatus = 'OPENED' | 'CLOSED';

export interface Truck {
    id: number;
    circleId?: number;
    companyId: number;
    status: TruckStatus;
    departureMin: string;
    departureMax: string;
    departurePrefecture: Enum<PrefectureEnumCode>;
    departureCity?: string;
    additionalDeparturePref: Array<Enum<PrefectureEnumCode>>;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPrefecture: Enum<PrefectureEnumCode>;
    arrivalCity?: string;
    additionalArrivalPref: Array<Enum<PrefectureEnumCode>>;
    truckWeight: Enum;
    truckModel: Enum;
    freight?: number;
    staffName: string;
    description: string;
    externalId?: number;
}

export interface TruckExistsRecommendation {
    id: number;
    existsRecommendation: boolean;
}

export interface TruckExistsRecommendationForm {
    ids: number[];
}

export class TruckModel {
    id: number;
    circleId?: number;
    companyId: number;
    status: TruckStatus;
    departureMin: string;
    departureMax: string;
    departurePrefecture: PrefectureEnum;
    departureCity?: string;
    additionalDeparturePref: Array<PrefectureEnum>;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPrefecture: PrefectureEnum;
    arrivalCity?: string;
    additionalArrivalPref: Array<PrefectureEnum>;
    truckWeight: Enum;
    truckModel: Enum;
    freight: FreightValue;
    staffName: string;
    description: string;
    externalId?: number;

    constructor(param: Truck) {
        this.id = param.id;
        this.circleId = param.circleId;
        this.companyId = param.companyId;
        this.status = param.status;
        this.departureMin = param.departureMin;
        this.departureMax = param.departureMax;
        this.departurePrefecture = Util.requireNotNull(PrefectureEnum.valueOf(param.departurePrefecture.code));
        this.departureCity = param.departureCity;
        this.additionalDeparturePref = param.additionalDeparturePref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code)));
        this.arrivalMin = param.arrivalMin;
        this.arrivalMax = param.arrivalMax;
        this.arrivalPrefecture = Util.requireNotNull(PrefectureEnum.valueOf(param.arrivalPrefecture.code));
        this.arrivalCity = param.arrivalCity;
        this.additionalArrivalPref = param.additionalArrivalPref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code)));
        this.truckWeight = param.truckWeight;
        this.truckModel = param.truckModel;
        this.freight = new FreightValue(param.freight);
        this.staffName = param.staffName;
        this.description = param.description;
        this.externalId = param.externalId;
    }

    /**
     * ツールチップ表示用の都道府県テキストラベル
     */
    get departureAreaText(): string {
        if (!this.additionalDeparturePrefectureText) return '';
        return `${ this.departurePrefecture.label }${ this.departureCity ?? '' }の他、${ this.additionalDeparturePrefectureText }`;
    }

    /**
     * 追加で設定された都道府県
     */
    get additionalDeparturePrefectureText(): string {
        if (this.additionalDeparturePref.length === 0) {
            return '';
        }
        const regions = RegionUtil.parseRegionsFromPrefectures(this.additionalDeparturePref.map((each) => each.code));
        return `${ regions.map((each) => each.label).join('、') }も対応可能`;
    }

    /**
     * ツールチップ表示用の都道府県テキストラベル
     */
    get arrivalAreaText(): string {
        if (!this.additionalArrivalPrefectureText) return '';
        return `${ this.arrivalPrefecture.label }${ this.arrivalCity ?? '' }の他、${ this.additionalArrivalPrefectureText }`;
    }

    /**
     * 追加で設定された都道府県
     */
    get additionalArrivalPrefectureText(): string {
        if (this.additionalArrivalPref.length === 0) {
            return '';
        }
        const regions = RegionUtil.parseRegionsFromPrefectures(this.additionalArrivalPref.map((each) => each.code));
        return `${ regions.map((each) => each.label).join('、') }も対応可能`;
    }
}
