<script setup lang="ts">

import { useCompanyTruckEventDelete, useCompanyTruckEventTruckUpdate } from '@/composables/company-truck-event';
import { CompanyTruckEvent } from '@/models/company-truck-event';
import { Truck } from '@/models/truck';
import { computed, onBeforeMount } from 'vue';
import { useModal } from '@/composables/helper/modal-helper';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import SpotSelectInput from '@/_components/ui/SpotSelectInput.vue';
import { useFormModel } from '@/composables/form-helper';

const props = defineProps<{
    companyTruckEvent: CompanyTruckEvent;
    truck?: Truck;
}>();

const emit = defineEmits<{
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const { state: { loading, form, formValidateRules }, update } = useCompanyTruckEventTruckUpdate();
const { deleteEvent } = useCompanyTruckEventDelete();
const { confirm } = useModal();
const { state: { myProfile: myCompanyProfile }, load: loadMyCompanyProfile } = useCompanyMyProfile();
const { formModel, submit } = useFormModel();

const circleOptions = computed(() => myCompanyProfile.value?.circles?.map((each) => ({
    value: each.id,
    label: each.name,
    key: each.id,
})));

onBeforeMount(async () => {
    if (!myCompanyProfile) await loadMyCompanyProfile();

    form.value.description = props.companyTruckEvent.description;
    form.value.startTm = props.companyTruckEvent.startTm;
    form.value.endTm = props.companyTruckEvent.endTm;
    form.value.title = props.companyTruckEvent.title;
    form.value.description = props.companyTruckEvent.description;
    form.value.companyTruckId = props.companyTruckEvent.companyTruckId;

    if (props.truck) {
        form.value.circleId = props.truck.circleId;
        form.value.departurePref = props.truck.departurePrefecture;
        form.value.departureCity = props.truck.departureCity;
        form.value.arrivalPref = props.truck.arrivalPrefecture;
        form.value.arrivalCity = props.truck.arrivalCity;
        form.value.updateLocation();
    }
});

const onSubmit = () => submit(async () =>  {
    await update(props.companyTruckEvent.id, form.value);
    emit('ok');
});

const onCancel = () => {
    emit('cancel');
};

const onDelete = async () => {
    if (!await confirm(
        '削除してよろしいですか？',
        (h) => h('div'),
        '削除する',
        'キャンセル'
    )) {
        return;
    }
    await deleteEvent(props.companyTruckEvent.id);
    emit('ok');
};

</script>

<template>
    <a-spin :spinning="loading">
        <div class="content">
            <a-form-model ref="formModel"
                          :model="form"
                          :rules="formValidateRules"
                          :label-col="{ span: 4 }"
                          :wrapper-col="{ span: 18 }">
                <a-form-model-item label="期間">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="11">
                            <a-input v-model:value="form.startTm"/>
                        </a-col>
                        <a-col :span="1" style="display: flex; justify-content: center">
                            →
                        </a-col>
                        <a-col :span="11">
                            <a-input v-model:value="form.endTm"/>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <spot-select-input label="空車地"
                                   prop-name="departureSpot"
                                   :show-address="false"
                                   v-model="form.departureSpot">
                    <template #prefTitle>
                        <img src="/img/ic-departure.svg" width="24" height="24" alt="空車地"
                             style="margin-right: 8px"/>空車地
                    </template>
                </spot-select-input>
                <spot-select-input label="行先地"
                                   prop-name="arrivalSpot"
                                   :show-address="false"
                                   v-model="form.arrivalSpot">
                    <template #prefTitle>
                        <img src="/img/ic-arrival.svg" width="24" height="24" alt="行先地"
                             style="margin-right: 8px"/>行先地
                    </template>
                </spot-select-input>
                <a-form-model-item label="タイトル"
                                   prop="title">
                    <a-input v-model:value="form.title"/>
                </a-form-model-item>
                <a-form-model-item label="備考"
                                   prop="description">
                    <a-textarea v-model:value="form.description"/>
                </a-form-model-item>
                <a-form-model-item label="部屋"
                                   prop="circleId">
                    <a-select :options="circleOptions"
                              v-model="form.circleId"
                              placeholder="部屋を選択"
                              style="width: 300px" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <div class="footer">
            <a-button type="primary" @click="onSubmit">編集</a-button>
            <a-button @click="onCancel">キャンセル</a-button>
            <a-button type="danger" @click="onDelete">削除</a-button>
        </div>
    </a-spin>
</template>

<style scoped lang="less">
.footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
</style>
