import { Component } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import UnionName from '@/components/Company/Edit/UnionName';
// @ts-ignore
import MlitGrantNumber from '@/components/Company/Edit/MlitGrantNumber';
// @ts-ignore
import DigitalTachometerCount from '@/components/Company/Edit/DigitalTachometerCount';
// @ts-ignore
import GpsCount from '@/components/Company/Edit/GpsCount';
// @ts-ignore
import GoodMark from '@/components/Company/Edit/GoodMark';
// @ts-ignore
import GreenManagement from '@/components/Company/Edit/GreenManagement';
// @ts-ignore
import Iso9000 from '@/components/Company/Edit/Iso9000';
// @ts-ignore
import Iso14000 from '@/components/Company/Edit/Iso14000';
// @ts-ignore
import Iso39001 from '@/components/Company/Edit/Iso39001';
// @ts-ignore
import InsuranceName from '@/components/Company/Edit/InsuranceName';

const companyMod = namespace('company');

@Component({
    title: '信用情報',
    components: {
        UnionName,
        MlitGrantNumber,
        DigitalTachometerCount,
        GpsCount,
        GoodMark,
        GreenManagement,
        Iso9000,
        Iso14000,
        Iso39001,
        InsuranceName,
    },
    beforeRouteEnter: SettingCompanyConfidencePage.beforeRouteEnter,
})
export default class SettingCompanyConfidencePage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.CONFIDENCE)
    readonly CONFIDENCE?: types.CompanyConfidence;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    form: types.CompanyConfidenceUpdateForm = {
        unionName: undefined,
        mlitGrantNumber: undefined,
        digitalTachometerCount: undefined,
        gpsCount: undefined,
        goodMark: false,
        greenManagement: false,
        iso9000: false,
        iso14000: false,
        iso39001: false,
        insuranceName: undefined,
    };

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (!this.CONFIDENCE) return;

        this.form.unionName = this.CONFIDENCE.unionName;
        this.form.mlitGrantNumber = this.CONFIDENCE.mlitGrantNumber;
        this.form.digitalTachometerCount = this.CONFIDENCE.digitalTachometerCount;
        this.form.gpsCount = this.CONFIDENCE.gpsCount;
        this.form.goodMark = this.CONFIDENCE.goodMark;
        this.form.greenManagement = this.CONFIDENCE.greenManagement;
        this.form.iso9000 = this.CONFIDENCE.iso9000;
        this.form.iso14000 = this.CONFIDENCE.iso14000;
        this.form.iso39001 = this.CONFIDENCE.iso39001;
        this.form.insuranceName = this.CONFIDENCE.insuranceName;
    }

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const notifyFailedKey = 'UPDATE_CONFIDENCE_FAILED';
        const onSuccess = () => {
            this.$message.success('信用情報を更新しました。');
        };

        const notifyFailed = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: '信用情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            await SettingCompanyConfidencePage.updateConfidence(this.form).then(onSuccess).catch(notifyFailed);

            this.loading = false;
        });
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingCompanyConfidencePage>
    ): Promise<void> {
        await SettingCompanyConfidencePage.loadMyConfidence().catch(() => next({ name: 'NotFound' }));

        next();
    }

    /**
     * 自社の信用情報をロードします。
     */
    private static loadMyConfidence(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_CONFIDENCE}`);
    }

    /**
     * 自社の信用情報を更新します。
     */
    private static updateConfidence(form: types.CompanyConfidenceUpdateForm): Promise<void> {
        return store.dispatch(`company/${types.ACTION.UPDATE_CONFIDENCE}`, form);
    }
}
