<script setup lang="ts">
import { computed } from 'vue';
import { CompanyNoticeModel } from '@/models/company-notice-model';
import { useCompanyNoticeService } from '@/composables/service/company-notice-service';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

const { state: { visible, noticeList, shouldShowCompanyNotice }, read } = useCompanyNoticeService();
const items = computed<CompanyNoticeModel[]>(() => noticeList.value?.items ?? []);

/**
 * お知らせが押下された際に呼び出されます。
 */
const onClickNotice = async (item: CompanyNoticeModel): Promise<void> => {
    await read(item);
};
</script>

<template>
    <a-popover trigger="click"
               v-if="shouldShowCompanyNotice"
               v-model="visible"
               overlay-class-name="app-popover-notification">
        <template #content>
            <div class="container">
                <ul class="notice" v-if="items.length > 0">
                    <li class="notice-item" v-for="item in items" :key="item.id" :class="{
                    'notice-item--marked-as-read': item.markedAsRead,
                    'notice-item--baggage-client': item.isClientBaggage,
                    'notice-item--baggage-in-house': item.isInHouseBaggage,
                }">
                        <a class="notice-item__link" @click="onClickNotice(item)">
                            <div class="notice-item-avatar">
                                <a-avatar :size="32" class="notice-item-avatar__icon">{{ item.avatarLabel }}</a-avatar>
                            </div>
                            <div class="notice-item-content">
                                <div class="title">{{ item.title }}</div>
                                <div v-for="(body, bodyIndex) in item.bodies" :key="`${item.id}-${bodyIndex}`">
                                    {{ body }}
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <div class="footer">
                    <a class="footer__close-link" @click="visible = !visible">閉じる</a>
                </div>
            </div>
        </template>

        <a-button class="button-notice" size="large" shape="round">
            <tbx-icon class="button-notice__info" type="info-filled" />
            ご確認いただきたいお知らせが<span class="button-notice__count">{{ items.length }}</span>件あります。
            <span class="button-notice__action">確認する<tbx-icon class="button-notice__action-icon"
                                                              :class="{ 'button-notice__action-icon-open': visible }"
                                                              type="down"/></span>
        </a-button>
    </a-popover>
</template>

<style scoped lang="less">
.notification-popover-overlay {
    .ant-popover-inner-content {
        padding: 0;
    }
}

.container {
    width: 400px;
}

.notice {
    margin: 8px 0 0;
    padding: 0;
    max-height: calc(90vh - (64px * 2));
    overflow-y: auto;
}

.notice-item {
    list-style-type: none;
    display: flex;
    align-items: center;

    .notice-item__link {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 10px 16px;
        color: @black;
        transition: background-color 300ms;

        &:hover {
            background-color: @ui-color-selected-background-hover;
        }
    }

    &--marked-as-read {
        opacity: 0.5;
        background-color: #e9f5ee;

        .notice-item__link:hover {
            background-color: #e9f5ee;
        }
    }

    &--baggage-in-house {
        .notice-item-avatar .notice-item-avatar__icon {
            background-color: @color-blue-2;
        }
    }

    &--baggage-client {
        .notice-item-avatar .notice-item-avatar__icon {
            background-color: @color-purple-2;
        }
    }
}

.notice-item-avatar {
    align-items: center;

    .notice-item-avatar__icon {
        color: @white;
        background-color: @color-trabox-green-8;
    }
}

.notice-item-avatar + .notice-item-content {
    margin-left: 8px;
}

.footer {
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    padding: 10px 16px;
    text-align: right;

    .footer__close-link {
        font-size: 12px;
        line-height: 12px;
    }
}

.button-notice {
    font-size: 14px;
    color: @color-neutral-13;

    &:hover,
    &:focus,
    &:active {
        background: fade(@color-orange, 10%);
        border-color: @color-orange;
        color: @color-neutral-13;
    }

    &__info {
        color: @color-orange;
    }

    &__count {
        margin: 0 4px;
        display: inline-block;
        font-weight: bold;
    }

    &__action {
        margin-left: 8px;
        display: inline-block;
        font-weight: bold;
        color: @color-orange;
    }

    &__action-icon {
        margin-left: 4px;
        display: inline-block;
        font-size: 12px;
    }
}
</style>
