import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useAccountPasswordUpdate } from '@/composables/account-password';
import { useFormModel } from '@/composables/form-helper';
import { Const } from '@/const';
import { useNotification } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';

export const useAccountPasswordRegisterHelper = (verificationCode: string) => {
    const appServiceName = Const.APP_SERVICE_NAME;
    const { state: { profile } } = useAccountMyProfile();
    const { state: { loading, form, formValidateRules }, update } = useAccountPasswordUpdate();
    const { submit, formModel: formModelRef } = useFormModel();
    const notification = useNotification();
    const { goToBaggageList } = useRouting();

    const notifyFailedKey = 'UPDATE_PASSWORD_FAILED';

    const onSubmit = () => submit(async () => {
        notification.close(notifyFailedKey);
        try {
            await update(verificationCode);
            await goToBaggageList().catch(() => {});
        } catch {
            notification.error({
                key: notifyFailedKey,
                message: 'パスワードの設定ができませんでした。',
                description:
                    '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });
    return {
        appServiceName,
        profile,
        loading,
        form,
        formValidateRules,
        formModelRef,
        onSubmit,
    };
};
