<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import TruckTypeSelect from '@/_components/ui/TruckTypeSelect.vue';
import { useCompanyTruckRegisterHelper } from '@/_pages/Truck/CompanyTruck/Register/company-truck-register-helper';

const { onClickBack, } = useCompanyTruckRegisterHelper();

const props = defineProps<{
    sourceCompanyTruckId?: string,
}>();
const {
    loading,
    formModel,
    form,
    formValidateRules,
    onSubmit,
    onClickClear,
} = useCompanyTruckRegisterHelper(Number(props.sourceCompanyTruckId));

</script>

<template>
    <page-layout @back="onClickBack">
        <a-spin :spinning="loading">
            <a-card>
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ sm: 6, lg: 4 }"
                              :wrapper-col="{ sm: 18, lg: 20 }">
                    <a-form-model-item label="車番"
                                       :wrapper-col="{ span: 16 }"
                                       prop="carNumber">
                        <a-input v-model="form.carNumber"
                                 style="width: 50%;" />
                    </a-form-model-item>
                    <truck-type-select v-model="form.truckType" prop-name="truckType" option-type="truck"/>
                    <a-form-model-item label="ドライバー名"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverName">
                        <a-input v-model="form.driverName"
                                 style="width: 50%;" />
                    </a-form-model-item>
                    <a-form-model-item label="ドライバー電話番号"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverPhoneNumber">
                        <a-input v-model="form.driverPhoneNumber"
                                 style="width: 50%;" />
                    </a-form-model-item>
                    <a-form-model-item label="備考"
                                       :wrapper-col="{ span: 16 }"
                                       prop="description">
                        <a-textarea v-model="form.description"></a-textarea>
                    </a-form-model-item>
                    <a-row :gutter="[32, 48]">
                        <a-col style="text-align: center;">
                            <a-button style="max-width: 200px; margin-right: 22px;"
                                      type="primary"
                                      size="large"
                                      block
                                      @click="onSubmit">
                                登録
                            </a-button>
                            <a-button style="max-width: 80px;"
                                      block
                                      size="large"
                                      @click="onClickClear">
                                クリア
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form-model>
            </a-card>
        </a-spin>
    </page-layout>
</template>
