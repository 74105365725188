import {
    Agreement,
    AgreementChangeRequest,
    AgreementForm,
    AgreementList,
    AgreementListAcceptedForm,
    AgreementListForm,
    AgreementSearchAcceptedForm,
    AgreementSearchAgreedForm,
    AgreementSearchForm,
    AgreementToggleGuaranteeForm,
    AgreementUpdateForm
} from '@/vuex/modules/agreement/types';
import { appAxios, isSuccess, JsonResponseBean, wrapClass } from './base';
import { CompanyConfidence, CompanyProfile, CompanyStatistics } from '@/vuex/modules/company/types';

export const agreementApi = {
    /**
     * 成約を登録します。
     */
    register(param: AgreementForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/agreement/register', param).then(isSuccess);
    },
    /**
     * 成約を取得します。
     */
    find(param: number): Promise<Agreement> {
        return appAxios.get<JsonResponseBean<Agreement>>(`/api/agreement/${ param }`).then(isSuccess);
    },
    /**
     * 成約変更リクエストを取得します。
     */
    findRequest(param: number): Promise<AgreementChangeRequest> {
        return appAxios
            .get<JsonResponseBean<AgreementChangeRequest>>(`/api/agreement/${ param }/request`)
            .then(isSuccess);
    },
    /**
     * 成約を更新します。
     */
    update(id: number, param: AgreementUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ id }/update`, param).then(isSuccess);
    },
    /**
     * 成約を取り消します。
     */
    revoke(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ param }/revoke`).then(isSuccess);
    },
    /**
     * 成約変更リクエストを承認します。
     */
    approve(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ param }/approve`).then(isSuccess);
    },
    /**
     * 成約変更リクエストを却下します。
     */
    reject(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ param }/reject`).then(isSuccess);
    },
    /**
     * 成約変更リクエストを取下げます。
     */
    cancel(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ param }/cancel`).then(isSuccess);
    },
    /**
     * 成約決済計上を行います。
     */
    stabilize(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ param }/stabilize`).then(isSuccess);
    },
    /**
     * 一覧を取得します。
     */
    list(param: AgreementListForm): Promise<AgreementList> {
        return appAxios.post<JsonResponseBean<AgreementList>>('/api/agreement/list', param).then(isSuccess);
    },
    /**
     * 一覧（受託）を取得します。
     */
    listAccepted(param: AgreementListAcceptedForm): Promise<AgreementList> {
        return appAxios.post<JsonResponseBean<AgreementList>>('/api/agreement/list/accepted', param).then(isSuccess);
    },
    /**
     * 年月ごとの一覧（受託）を取得します。
     */
    listAcceptedMonthly(year: number, month: number, param: AgreementListAcceptedForm): Promise<AgreementList> {
        return appAxios
            .post<JsonResponseBean<AgreementList>>(`/api/agreement/list/accepted/${ year }/${ month }`, param)
            .then(isSuccess);
    },
    /**
     * 成約IDのリストを指定して自社の成約を検索します。
     */
    search(param: AgreementSearchForm): Promise<Agreement[]> {
        return appAxios.post<JsonResponseBean<Agreement[]>>('/api/agreement/search', param).then(isSuccess);
    },
    /**
     * 成約（自社荷物）を検索します。
     */
    searchAgreed(param: AgreementSearchAgreedForm): Promise<AgreementList> {
        return appAxios.post<JsonResponseBean<AgreementList>>('/api/agreement/search/agreed', param).then(isSuccess);
    },
    /**
     * 成約（受託）を検索します。
     */
    searchAccepted(param: AgreementSearchAcceptedForm): Promise<AgreementList> {
        return appAxios.post<JsonResponseBean<AgreementList>>('/api/agreement/search/accepted', param).then(isSuccess);
    },
    /**
     * 運賃全額保証を変更します。
     */
    toggleGuarantee(id: number, param: AgreementToggleGuaranteeForm): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/agreement/${ id }/guarantee/toggle`, param).then(isSuccess);
    },

    /**
     * 成約パートナー企業のプロフィールを取得します。
     */
    partnerCompanyProfile(id: number): Promise<CompanyProfile> {
        return appAxios.get<JsonResponseBean<CompanyProfile>>(`/api/agreement/${ id }/company/profile`)
            .then(isSuccess)
            .then(item => item as CompanyProfile)
            .then(item => wrapClass(CompanyProfile, item));
    },
    /**
     * 成約パートナー企業の信用情報を取得します。
     */
    partnerCompanyConfidence(id: number): Promise<CompanyConfidence> {
        return appAxios.get<JsonResponseBean<CompanyConfidence>>(`/api/agreement/${ id }/company/confidence`).then(isSuccess);
    },
    /**
     * 成約パートナー企業の統計情報を取得します。
     */
    partnerCompanyStatistics(id: number): Promise<CompanyStatistics> {
        return appAxios.get<JsonResponseBean<CompanyStatistics>>(`/api/agreement/${ id }/company/statistics`).then(isSuccess);
    },
    /**
     * 指定の荷主企業からの受託回数を取得します。
     */
    countAccepted(baggageCompanyId: number): Promise<number> {
        return appAxios.get<JsonResponseBean<number>>(`/api/agreement/count/accepted/${baggageCompanyId}`).then(isSuccess);
    },
};
