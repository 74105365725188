import { defineComponent } from 'vue';

/**
 * 空タグ。SFC直下で複数のコンポーネントを配置したい場合に利用できます。
 */
export default defineComponent({
    name: 'empty-tag',
    setup: (props, { slots }) => {
        return () => slots.default?.();
    },
});
