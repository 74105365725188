import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage } from '@/vuex/modules/baggage/types';

type OptionItem = {
    value?: string,
    label: string,
    key?: string,
    class?: string
};

const ITEMS = [
    {
        value: 'true',
        label: '可',
    },
    {
        value: 'false',
        label: '不可',
    }
] as OptionItem[];

@Component
export default class BaggageLargeTruckFlgRegister extends Vue {
    @Prop()
    declare readonly value?: Baggage;

    items = ITEMS;

    get largeTruckFlg(): string | undefined {
        return BaggageLargeTruckFlgRegister.fromBoolean(this.value?.largeTruckFlg);
    }

    set largeTruckFlg(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.largeTruckFlg = BaggageLargeTruckFlgRegister.toBoolean(newValue);
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    private static toBoolean(value: string | undefined): boolean | undefined {
        if (value?.toLowerCase() === 'true') return true;
        if (value?.toLowerCase() === 'false') return false;
        return undefined;
    }

    private static fromBoolean(value: boolean | undefined): string | undefined {
        if (value == true) return 'true';
        if (value == false) return 'false';
        return undefined;
    }
}
