<script setup lang="ts">
import { BaggageListModel } from '@/models/baggage';
import { ref } from 'vue';
import { useBaggageTableColumns } from './baggage-table-helper';
import { CustomRow } from '@/types/ant-design';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { Util } from '@/util';
import TableRowBadge from '@/_components/ui/TableRowBadge.vue';
import { useMyBaggageExtension } from '@/composables/my-baggage-extension';

type Baggage = BaggageListModel['data'][number];

const { build } = useBaggageTableColumns();

const props = defineProps<{
    list?: BaggageListModel;
    loading: boolean;
    selectedBaggageId?: number;
    canEdit: boolean;
    canDelete: boolean;
}>();

const emit = defineEmits<{
    (e: 'selectBaggage', id: number): void;
    (e: 'markUnderNegotiation', id: number): void;
    (e: 'unmarkUnderNegotiation', id: number): void;
    (e: 'editBaggage', id: number): void;
    (e: 'copyBaggage', id: number): void;
    (e: 'cancelBaggage', id: number): void;
    (e: 'exportBaggage', record: Baggage): void;
}>();

const columns = ref(build(false));

const customRow = (record: Baggage): CustomRow => {
    return {
        on: {
            click: (event) => {
                if (props.selectedBaggageId !== record.id) {
                    event.stopPropagation();
                    emit('selectBaggage', record.id);
                }
            },
        },
    };
};

const rowClassName = (record: Baggage): string => {
    const styleClasses = ['app-table-row', 'app-table-row--clickable'];
    if (record.id === props.selectedBaggageId) {
        styleClasses.push('app-table-row--selected');
    }
    return styleClasses.join(' ');
};

const {
    isUnderNegotiation,
} = useMyBaggageExtension();

const departureText = (record: Baggage): string => {
    return DeliveryDateTimeRange.of(record.departureMin, record.departureMax)?.format() ?? '';
};

const arrivalText = (record: Baggage): string => {
    return DeliveryDateTimeRange.of(record.arrivalMin, record.arrivalMax)?.format() ?? '';
};

const freightText = (record: Baggage): string => {
    if (!record.freight) return '要相談';
    return `${ Util.formatNumber(record.freight) }円`;
};
</script>

<template>
    <div>
        <div class="search-results-header">
            <slot name="header"/>
        </div>

        <a-table ref="tableElement"
                 class="app-result-list-table"
                 bordered
                 :columns="columns"
                 :data-source="list?.data || []"
                 row-key="id"
                 table-layout="fixed"
                 :loading="loading"
                 :custom-row="customRow"
                 size="middle"
                 :scroll="{x: 800}"
                 :row-class-name="rowClassName"
                 :pagination="false">
            <template v-slot:id="text, record">
                <div class="table-column-id">
                    <a-tooltip placement="right" overlay-class-name="table-column-id__tooltip">
                        <template #title>
                            <div class="table-column-id__tooltip__departure">
                                出発：<span>{{ record.departurePref.label }}</span>
                                <span>{{ departureText(record) }}</span>
                            </div>
                            <div class="table-column-id__tooltip__arrival">
                                到着：<span>{{ record.arrivalPref.label }}</span>
                                <span>{{ arrivalText(record) }}</span>
                            </div>
                            <div>{{ freightText(record) }}</div>
                        </template>
                        <div class="table-column-id__container">
                            <table-row-badge
                                v-if="isUnderNegotiation(record)"
                                class="table-column-id__badge"
                                type="dark">商談中
                            </table-row-badge>
                            {{ record.id }}
                        </div>
                    </a-tooltip>
                </div>
            </template>

            <template #departure="text, record">
                <div>
                    {{ departureText(record) }}<br>
                    {{ record.departurePref.label }}{{ record.departureCity }}
                </div>
            </template>

            <template #arrival="text, record">
                {{ arrivalText(record) }}<br>
                {{ record.arrivalPref.label }}{{ record.arrivalCity }}
            </template>


            <template #type="text, record">
                {{ record.type.label }}
                <template v-if="record.type">{{ record.type }}</template>
            </template>

            <template #freight="text, record">
                <div>
                    {{ freightText(record) }}
                </div>
            </template>

            <template #viewersCount="text, record">
                <div>{{ record.viewerCount }}</div>
            </template>

            <template #truckWeight="text, record">
                <div class="table-row-ellipsis-text-at-one-line">{{ record.truckWeight.label }}</div>
            </template>

            <template #truckModel="text, record">
                <div class="table-row-ellipsis-text-at-one-line">{{ record.truckModel.label }}</div>
            </template>

            <template #staffName="text, record">
                <div class="table-row-ellipsis-text-at-one-line">{{ record.staffName }}</div>
            </template>

            <template #description="text, record">
                <a-tag class="tag-multiple-baggage" v-if="record.multipleBaggage">複数台荷物</a-tag>
                <div class="table-row-ellipsis-text">
                    <span>{{ record.description }}</span>
                </div>
                <nav class="app-table-row__actions debug">
                    <ul>
                        <li>
                            <a-tooltip title="この荷物をトラボックスで登録します">
                                <a-button icon="export" @click.stop="emit('exportBaggage', record)">トラボックスで登録</a-button>
                            </a-tooltip>
                        </li>
                        <li v-if="canEdit">
                            <a-tooltip title="この荷物の内容を変更します">
                                <a-button icon="edit" @click.stop="emit('editBaggage', record.id)">変更</a-button>
                            </a-tooltip>
                        </li>
                        <li>
                            <a-tooltip title="この荷物を元にして新しく荷物を登録します">
                                <a-button icon="copy" @click.stop="emit('copyBaggage', record.id)">コピー</a-button>
                            </a-tooltip>
                        </li>
                        <li v-if="canDelete">
                            <a-tooltip title="この荷物情報を削除します">
                                <a-button icon="delete" type="danger" ghost
                                          @click.stop="emit('cancelBaggage', record.id)">削除
                                </a-button>
                            </a-tooltip>
                        </li>
                    </ul>
                </nav>
            </template>
        </a-table>

        <div class="search-results-footer">
            <slot name="footer"/>
        </div>
    </div>
</template>

<style scoped lang="less">
.table-column-id {
    height: 100%;

    &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    &__badge {
        position: relative;
        margin-left: -8px;
        margin-right: 4px;
    }

    &__tooltip__departure, &__tooltip__arrival {
        span + span {
            margin-left: 8px;
        }
    }
}

.tag-multiple-baggage {
    background-color: transparent;
    margin-top: 4px;
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

.table-row-ellipsis-text-at-one-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-height: 42px;
}

.search-results-header {
    display: flex;
    align-items: center;
    padding: @padding-lg @padding-lg 0;
    margin-bottom: 8px;
}

.search-results-footer {
    display: flex;
    align-items: center;
    padding: @padding-sm;
}
</style>
