import _ from 'lodash';
import type { Dayjs } from 'dayjs';
import { DateUtil } from '@/util';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import * as punycode from 'punycode';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

export interface ValidateResult {
    result: boolean;
    message?: string;
}

export class Validator {
    /**
     * 日付のバリデーションを行います。
     * @param value
     * @param baseDate
     */
    static validateDateTime(value?: DeliveryDateTime | null, baseDate: Dayjs | null = DateUtil.now()): ValidateResult {
        if (!value) {
            return { result: false, message: '日時を選択してください。' };
        } else if (!baseDate) {
            return { result: true };
        } else if (value.date.isBefore(baseDate, 'day')) {
            // 過去の日付は選択不可
            return { result: false, message: '本日以降の日付を選択してください。' };
        } else if (value.date.isSame(baseDate, 'day')) {
            // 今日の日付を指定された場合
            switch (value.type) {
                case 'Morning':
                    if (baseDate.hour() >= 12) {
                        return {
                            result: false,
                            message: '本日の午前は選択できません。「指定なし」または具体的な時間を選択してください。',
                        };
                    }
                    break;
                case 'Hourly':
                case 'Just': {
                    const [min] = value.rawValues();
                    if (min.isSameOrBefore(baseDate, 'minute')) {
                        return { result: false, message: '現在時刻よりも後の日時で選択してください。' };
                    }
                }
            }
        }
        return { result: true };
    }

    /**
     * 日付のバリデーションを行います。
     * @param value
     * @param baseDate
     */
    static validateDateTimeRangeType(value?: DeliveryDateTimeRange | null, baseDate: Dayjs | null = DateUtil.now()): ValidateResult {
        if (!value) {
            return { result: false, message: '日時を選択してください。' };
        } else if (!baseDate) {
            return { result: true };
        } else if (value.date.isBefore(baseDate, 'day')) {
            // 過去の日付は選択不可
            return { result: false, message: '本日以降の日付を選択してください。' };
        } else if (value.date.isSame(baseDate, 'day')) {
            // 今日の日付を指定された場合
            switch (value.type) {
                case 'Morning':
                    if (baseDate.hour() >= 12) {
                        return {
                            result: false,
                            message: '本日の午前は選択できません。「指定なし」または具体的な時間を設定してください。',
                        };
                    }
                    break;
                case 'Just': {
                    const [min] = value.rawValues();
                    if (min.isSameOrBefore(baseDate, 'minute')) {
                        return { result: false, message: '現在時刻よりも後の日時で設定してください。' };
                    }
                    break;
                }
                case 'Period': {
                    const [min] = value.rawValues();
                    if (min.isSameOrBefore(baseDate, 'minute')) {
                        return { result: false, message: '現在時刻よりも後の日時で設定してください。' };
                    }
                }
            }
        }
        return { result: true };
    }

    /**
     * 2つの日時を比較して、日時の範囲が正しいかどうか判定します。
     * 開始時間 < 終了時間 となっているかを判定します。
     * 同日発着の場合は、時間の組み合わせ指定が正しいかどうかも判定します。
     * https://docs.google.com/spreadsheets/d/1WEbAQWlsk14IciIk6aEPbX6r_G4y5KR6L7Tu5qPSAhU/edit?usp=sharing
     * @param departure
     * @param arrival
     */
    static validateDateTimeRange(
        departure?: DeliveryDateTime | null,
        arrival?: DeliveryDateTime | null
    ): ValidateResult {
        // 発時刻・着時刻ともに正しく選択されているかをチェック
        if (!departure && !arrival) {
            return { result: false, message: '発日時と着日時を両方とも選択してください' };
        } else if (!departure) {
            return { result: false, message: '発日時を正しく指定してください。' };
        } else if (!arrival) {
            return { result: false, message: '着日時を正しく指定してください。' };
        }

        const [departureMin] = departure.rawValues();
        const [arrivalMin, arrivalMax] = arrival.rawValues();
        // 発着日が同日の場合
        if (departureMin.isSame(arrivalMin, 'day')) {
            const isAfternoonDeparture = departure.rawValues()[0].hour() >= 12; // 発時刻が12時以降か否か
            const isInvalidTimeRange = arrival.rawValues()[0].isSameOrBefore(departure.rawValues()[0]); // 発時刻 < 着時刻の関係性になっていない
            switch (departure.type) {
                // 出発が時間指定なしの場合
                case 'Day':
                    switch (arrival.type) {
                        case 'Morning':
                            // AMは選択不可
                            return {
                                result: false,
                                message: '発時刻を「指定なし」にする場合は、着時刻を「午前」以外から選択してください。',
                            };
                        case 'Hourly':
                        case 'Just':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: `発時刻を「指定なし」で着時刻を設定したい場合は「0時10分以降」から選択してください。`,
                                };
                            }
                            break;
                    }
                    break;
                // 出発が午前の場合
                case 'Morning':
                    switch (arrival.type) {
                        case 'Hourly':
                        case 'Just':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: `発時刻を「午前」で着時刻を設定したい場合は「0時10分以降」から選択してください。`,
                                };
                            }
                            break;
                    }
                    break;
                // 出発が午後の場合
                case 'Afternoon':
                    // 到着
                    switch (arrival.type) {
                        // 時間指定なしとAMは選択不可
                        case 'Day':
                        case 'Morning':
                            return {
                                result: false,
                                message:
                                    '発時刻を「午後」にする場合は、着時刻を「午後」または「12時10分以降」から選択してください。',
                            };
                        // 時指定or時分指定の場合は12時10分以降
                        case 'Hourly':
                        case 'Just':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message:
                                        '発時刻を「午後」にする場合は、着時刻を「午後」または「12時10分以降」から選択してください。',
                                };
                            }
                            break;
                    }
                    break;
                // 出発時間の指定がある場合
                case 'Hourly':
                case 'Just': {
                    switch (arrival.type) {
                        case 'Morning':
                            if (isAfternoonDeparture) {
                                return {
                                    result: false,
                                    message:
                                        '発時刻を「12時以降」にする場合は、着時刻を「午前」以外から選択してください。',
                                };
                            }
                            break;
                        case 'Hourly':
                        case 'Just':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: '着日時は、発日時より後の日時を選択してください。',
                                };
                            }
                            break;
                    }
                    break;
                }
            }
            return { result: true };
        } else {
            // 発着日時が別日の場合
            // 日付の関係性が順当ならOK
            const result = departureMin.isBefore(arrivalMax);
            return {
                result,
                message: result ? undefined : '着日時は、発日時より後の日時を選択してください。',
            };
        }
    }

    /**
     * 2つの日時を比較して、日時の範囲が正しいかどうか判定します。
     * 開始時間 < 終了時間 となっているかを判定します。
     * 同日発着の場合は、時間の組み合わせ指定が正しいかどうかも判定します。
     * https://docs.google.com/spreadsheets/d/1WEbAQWlsk14IciIk6aEPbX6r_G4y5KR6L7Tu5qPSAhU/edit?usp=sharing
     * @param departure
     * @param arrival
     */
    static validateMultipleDateTimeRange(
        departure?: DeliveryDateTimeRange | null,
        arrival?: DeliveryDateTimeRange | null
    ): ValidateResult {
        // 発時刻・着時刻ともに正しく選択されているかをチェック
        if (!departure && !arrival) {
            return { result: false, message: '発日時と着日時を両方とも選択してください' };
        } else if (!departure) {
            return { result: false, message: '発日時を正しく指定してください。' };
        } else if (!arrival) {
            return { result: false, message: '着日時を正しく指定してください。' };
        }

        const [departureMin] = departure.rawValues();
        const [arrivalMin, arrivalMax] = arrival.rawValues();
        // 発着日が同日の場合
        if (departureMin.isSame(arrivalMin, 'day')) {
            const isAfternoonDeparture = departure.rawValues()[0].hour() >= 12; // 発時刻が12時以降か否か
            const isInvalidTimeRange = arrival.rawValues()[0].isSameOrBefore(departure.rawValues()[0]); // 発時刻 < 着時刻の関係性になっていない
            switch (departure.type) {
                // 出発が時間指定なしの場合
                case 'Day':
                    switch (arrival.type) {
                        case 'Morning':
                            // AMは選択不可
                            return {
                                result: false,
                                message: '発時刻を「指定なし」にする場合は、着時刻を「午前」以外から選択してください。',
                            };
                        case 'Just':
                        case 'Period':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: `発時刻を「指定なし」で着時刻を設定したい場合は「0時0分以降」から選択してください。`,
                                };
                            }
                            break;
                    }
                    break;
                // 出発が午前の場合
                case 'Morning':
                    switch (arrival.type) {
                        case 'Just':
                        case 'Period':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: `発時刻を「午前」で着時刻を設定したい場合は「0時0分以降」から入力してください。`,
                                };
                            }
                            break;
                    }
                    break;
                // 出発が午後の場合
                case 'Afternoon':
                    // 到着
                    switch (arrival.type) {
                        // 時間指定なしとAMは選択不可
                        case 'Day':
                        case 'Morning':
                            return {
                                result: false,
                                message:
                                    '発時刻を「午後」にする場合は、着時刻を「午後」または「12時1分以降」から入力してください。',
                            };
                        // 時間指定の場合は12時1分以降
                        case 'Just':
                        case 'Period':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message:
                                        '発時刻を「午後」にする場合は、着時刻を「午後」または「12時1分以降」から入力してください。',
                                };
                            }
                            break;
                    }
                    break;
                // 出発時間の指定がある場合
                case 'Period': {
                    switch (arrival.type) {
                        case 'Morning':
                            if (isAfternoonDeparture) {
                                return {
                                    result: false,
                                    message:
                                        '発時刻を「12時以降」にする場合は、着時刻を「午前」以外から選択してください。',
                                };
                            }
                            break;
                        case 'Just':
                        case 'Period':
                            if (isInvalidTimeRange) {
                                return {
                                    result: false,
                                    message: '着日時は、発日時より後の日時を設定してください。',
                                };
                            }
                            break;
                    }
                    break;
                }
            }
            return { result: true };
        } else {
            // 発着日時が別日の場合
            // 日付の関係性が順当ならOK
            const result = departureMin.isBefore(arrivalMax);
            return {
                result,
                message: result ? undefined : '着日時は、発日時より後の日時を選択してください。',
            };
        }
    }

    /**
     * 荷物/空車検索で用いる「発日と着日」を比較して、日時の範囲が正しいかどうか判定します。
     * 発日 < 着日 となっているかを判定します。
     */
    static validateDateRangeForSearch(
        departureFrom: string | undefined,
        departureTo: string | undefined,
        arrivalFrom: string | undefined,
        arrivalTo: string | undefined,
        labels: { departure: string; arrival: string; } = { departure: '発日', arrival: '着日' },
        baseDate = DateUtil.now()
    ): ValidateResult {
        const depFrom = departureFrom ? DateUtil.parseDatetimeText(departureFrom) : undefined;
        const depTo = departureTo ? DateUtil.parseDatetimeText(departureTo) : undefined;
        const arrFrom = arrivalFrom ? DateUtil.parseDatetimeText(arrivalFrom) : undefined;
        const arrTo = arrivalTo ? DateUtil.parseDatetimeText(arrivalTo) : undefined;

        const isAllValid = _.compact([depFrom, depTo, arrFrom, arrTo]).map((each) => each.isValid()).every((isValid) => isValid);
        if (!isAllValid) {
            return { result: false, message: '日付を正しく指定してください。' };
        }

        const startOfToday = baseDate.startOf('day');
        // 出発日時(to)は「from以降」のみ許可
        if (depTo && depFrom && depTo.isBefore(depFrom)) {
            return { result: false, message: `${labels.departure}（終了）を正しく指定してください。` };
        }

        // 到着日時(to)は「from以降」のみ許可
        if (arrTo && arrFrom && arrTo.isBefore(arrFrom)) {
            return { result: false, message: `${labels.arrival}（終了）を正しく指定してください。` };
        }

        return { result: true };
    }

    /**
     * メールアドレスが正しい形式になっているかを検証します
     */
    static validateEmail(value: string): ValidateResult {
        if (value.length === 0) return { result: false, message: 'メールアドレスを入力してください。' };
        if (value.length > Const.MAX_EMAIL_LENGTH) {
            return {
                result: false,
                message: `${ Const.MAX_EMAIL_LENGTH }文字以内で入力してください。`,
            };
        }
        // eslint-disable-next-line no-control-regex
        const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
        const result = emailRegex.test(value);
        return {
            result,
            message: result ? undefined : 'メールアドレスを正しい形式で入力してください。',
        };
    }

    /**
     * パスワードが正しい形式になっているかを検証します
     * @param value
     */
    static validatePasswordFormat(value: string): ValidateResult {
        const length = value.length;
        if (length === 0) {
            return { result: false, message: 'パスワードを入力してください。' };
        } else if (length < 8 || length > 60) {
            return { result: false, message: 'パスワードは8文字以上60文字以内で入力してください。' };
        }
        const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,60}$/;
        const result = passwordRegex.test(value);
        return {
            result,
            message: result ? undefined : 'パスワードは半角英字、半角数字の組み合わせを入力してください。',
        };
    }

    /**
     * URL文字列を簡易的にパースします。
     * トラボックス公式にはサポートしていないためPolyfillはしない、しかしIE11を救いたいのでURLクラスを利用しない
     */
    private static parseUrl(value: string): Partial<URL> {
        const result: Partial<URL> = {
            protocol: '',
            username: '',
            password: '',
            hostname: '',
            pathname: '',
            search: '',
            hash: '',
        };

        // Protocol
        if (!value.includes('//')) return result;
        result.protocol = value.split('//')[0];

        const restOfProtocol = value.substring(result.protocol.length + 2);

        const domainPart = restOfProtocol.split('/')[0];
        if (_.isEmpty(domainPart)) return result;

        // username/password
        if (domainPart.includes('@')) {
            const userinfo = domainPart.split('@')[0];
            result.username = userinfo.split(':')[0];

            // パスワードは指定されている場合のみ
            if (userinfo.includes(':')) {
                result.password = userinfo.substring(userinfo.indexOf(':') + 1);
            }
        }

        // hostname
        result.hostname = domainPart.includes('@')
            // userinfoがあるなら@以降で、ポート(:)より前
            ? domainPart.substring(domainPart.indexOf('@') + 1).split(':')[0]
            // ないなら、ポート(:)より前
            : domainPart.split(':')[0];

        // hash
        if (restOfProtocol.includes('#')) {
            // #以降(#含む)
            result.hash = restOfProtocol.substring(restOfProtocol.indexOf('#'));
        }

        // query
        if (restOfProtocol.split('#')[0].includes('?')) {
            // ?以降(?含む)、#より前(#含まない)
            result.search = restOfProtocol.split('#')[0].substring(restOfProtocol.indexOf('?'));
        }

        // path
        if (restOfProtocol.split('#')[0].split('?')[0].includes('/')) {
            result.pathname = restOfProtocol.split('#')[0].split('?')[0].substring(restOfProtocol.indexOf('/'));
        }

        return result;
    }

    static validateUrl(value: string): ValidateResult {
        if (value.length > Const.MAX_URL_LENGTH) {
            return {
                result: false,
                message: `${ Const.MAX_URL_LENGTH }文字以内で入力してください。`,
            };
        }

        const ok: ValidateResult = { result: true, message: undefined };
        const ng: ValidateResult = { result: false, message: 'URLを正しい形式で入力してください。' };

        const url = Validator.parseUrl(value) as URL;

        // Protocol
        if (!/https?:/.test(url.protocol)) return ng;

        // User info
        if (!_.isEmpty(url.username)) return ng;
        if (!_.isEmpty(url.password)) return ng;

        // Domain
        const alpha = /[a-zA-Z]/.source;
        const alnum = /[a-zA-Z0-9]/.source;
        const alnumHyphen = /[a-zA-Z0-9-]/.source;

        // 最大63文字、英数字始まり
        const domainLabelRegex = `${ alnum }(${ alnumHyphen }{0,61}${ alnum })?`;
        // 最大63文字、英字始まり
        const topLabelRegex = `${ alpha }(${ alnumHyphen }{0,61}${ alnum })?`;
        const domainNameRegex = new RegExp(`^(${domainLabelRegex}\\.)+${topLabelRegex}\\.?$`);

        if (!domainNameRegex.test(punycode.toASCII(url.hostname))) return ng;

        // Path
        if (url.pathname.includes('//')) return ng;

        // Query Parameter
        if (!_.isEmpty(url.search)) return ng;

        // Hash
        if (!_.isEmpty(url.hash)) return ng;

        return ok;
    }

    /**
     * 入力文字が口座名義で使用可能な文字だけで構成されているかどうか判定します。
     * @param value 入力文字列
     */
    static validateAccountHolder(value: string): ValidateResult {
        const ok: ValidateResult = { result: true, message: undefined };
        const ng: ValidateResult = { result: false, message: '全角カナ・全角アルファベット・全角数字・全角空白・記号「（）￥ー／，．」で入力してください。' };

        const chars = 'ァ-ワン-ヶ゛゜Ａ-Ｚａ-ｚ０-９，．（）￥ー／\u3000';
        const regex = new RegExp(`^[${chars}]+$`);

        if (!regex.test(value)) return ng;

        return ok;
    }
}
