import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import { Const } from '@/const';
import { namespace } from 'vuex-class';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

const companyMod = namespace('company');

@Component({
    title: 'ユーザー追加',
})
export default class SettingAccountCreateCompletePage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Properties
    // ======================================================

    // ======================================================
    // Data
    // ======================================================
    get appServiceName(): string {
        return Const.APP_SERVICE_NAME;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 戻るボタンが押下された際に呼び出されます。
     */
    async onClickBack(): Promise<void> {
        await this.$router.push({ name: 'SettingAccountList' });
    }
}
