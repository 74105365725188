import { TbxRouteConfig } from '@/router/routes/types';
import JudgingPage from '@/pages/Judging/index.vue';

import _JudgingPage from '@/_pages/Judging/Judging.vue';

export const judgingRoutes: TbxRouteConfig[] = [
    {
        path: '/judging',
        name: 'Judging',
        component: _JudgingPage,
        backComponent: JudgingPage,
        meta: { layout: { name: 'blank' }, title: '申し込みの受付が完了しました' },
    },
];
