import { createGlobalState, useLocalStorage } from '@vueuse/core';
import { readonly } from 'vue';
import { useGtm } from '@/composables/helper/gtm-helper';

/**
 * ハイコントラストモード機能を提供します。
 */
export const useHighContrastMode = createGlobalState(() => {
    const highContrastMode = useLocalStorage<boolean>('highContrastMode', false);
    const { gtm } = useGtm();

    const deactivate = () => {
        highContrastMode.value = false;
        gtm.setEvent('change_high_contrast_mode', { active: false });
    };

    const activate = () => {
        highContrastMode.value = true;
        gtm.setEvent('change_high_contrast_mode', { active: true });
    };

    const toggle = () => {
        highContrastMode.value = !highContrastMode.value;
    };

    return {
        state: {
            highContrastMode: readonly(highContrastMode),
        },
        activate,
        deactivate,
        toggle,
    };
});
