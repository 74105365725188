import { useSettlement, useSettlementConfirmationUpdate } from '@/composables/settlement';
import { computed, InjectionKey } from 'vue';
import {
    useSettlementChangeRequest,
    useSettlementChangeRequestApprove,
    useSettlementChangeRequestCancel,
    useSettlementChangeRequestReject
} from '@/composables/settlement-change-request';
import { SettlementChangeDiffModel } from '@/models/settlement';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useEntitlement } from '@/composables/entitlement';

export const useSettlementDrawerProvider = () => {
    const { state: { entitlement } } = useEntitlement();
    const { state: { loading: loadingData, settlement, partner }, load: loadData, clear: clearData } = useSettlement();
    const {
        state: { loading: loadingRequest, request },
        load: loadRequest,
        clear: clearRequest
    } = useSettlementChangeRequest();
    const { state: { myCompanyId } } = useCompanyMyProfile();
    const { state: { loading: loadingApprove }, approve } = useSettlementChangeRequestApprove();
    const { state: { loading: loadingReject }, confirm, reject } = useSettlementChangeRequestReject();
    const { state: { loading: loadingCancel }, cancel } = useSettlementChangeRequestCancel();
    const { state: { loading: loadingConfirmation }, mark, unmark } = useSettlementConfirmationUpdate();

    const loading = computed(() => loadingData.value || loadingRequest.value || loadingApprove.value || loadingReject.value || loadingCancel.value || loadingConfirmation.value);
    const diff = computed<SettlementChangeDiffModel | undefined>(() => {
        if (!settlement.value || !request.value) return undefined;
        return new SettlementChangeDiffModel(settlement.value, request.value);
    });
    const iAmPayer = computed(() => {
        if (!settlement.value) return false;
        return settlement.value.payerCompanyId === myCompanyId.value;
    });
    const iAmPayee = computed(() => {
        if (!settlement.value) return false;
        return settlement.value.payeeCompanyId === myCompanyId.value;
    });
    const canApprove = computed<boolean>(() => {
        // 出金企業であること
        if (!iAmPayer.value) return false;
        // 未承認であること
        if (!settlement.value?.status.isUnconfirmed) return false;
        // 期限切れでないこと
        if (settlement.value?.isExpiredChangeLimit()) return false;
        return true;
    });
    const canReject = computed<boolean>(() => canApprove.value);
    const canCancel = computed<boolean>(() => {
        // 入金企業であること
        if (!iAmPayee.value) return false;
        // 未承認であること
        if (!settlement.value?.status.isUnconfirmed) return false;
        // 期限切れでないこと
        if (settlement.value?.isExpiredChangeLimit()) return false;
        return true;
    });

    const load = async (settlementId: number) => {
        await Promise.all([
            loadData(settlementId),
            loadRequest(settlementId)
        ]);
    };

    const clear = () => {
        clearData();
        clearRequest();
    };

    return {
        state: {
            entitlement,
            loading,
            settlement,
            partner,
            diff,
            iAmPayer,
            iAmPayee,
            canApprove,
            canReject,
            canCancel,
        },
        load,
        clear,
        approve,
        confirm,
        reject,
        cancel,
        mark,
        unmark,
    };
};

export type SettlementDrawerProviderType = ReturnType<typeof useSettlementDrawerProvider>;
export const Settlement_DRAWER_PROVIDER_KEY: InjectionKey<SettlementDrawerProviderType> = Symbol('SettlementDrawerProvider');
