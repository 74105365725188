import { GETTER as g, MUTATION as m } from '@/vuex/modules/baggage/types';
import { createGlobalState, useLocalStorage } from '@vueuse/core';
import { readonly } from 'vue';
import _ from 'lodash';
import { useVuexSync } from '@/composables/helper/vuex';
import { useMessage } from '@/composables/helper/page-helper';
import { BaggageSearchExcludedCompany } from '@/models/baggage';
import { useKarte } from '@/composables/helper/karte-helper';

/**
 * 荷物検索の除外企業リストを取得・追加・削除する機能を提供します
 */
export const useBaggageSearchExcludeCompany = createGlobalState(() => {
    const message = useMessage();
    const { Karte } = useKarte();
    // VueUseの `useStorage` はストレージが直接編集された際にもリアクティブに働く。
    const list = useLocalStorage<Array<BaggageSearchExcludedCompany>>('baggageSearchExcludedCompanies', []);
    useVuexSync(list, {
        module: 'baggage',
        getter: g.BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST,
        mutation: m.SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST,
    });

    const add = (company: BaggageSearchExcludedCompany): void => {
        const companies = list.value;
        companies.push(company);
        list.value = _.uniqBy(companies, 'id');
        Karte.trackAddBaggageSearchExcludeCompany(company.id);
        message.success(`${ company.name }の荷物を検索結果から除外しました。`);
    };

    const remove = (id: number): void => {
        list.value = list.value.filter(c => c.id !== id);
        Karte.trackRemoveBaggageSearchExcludeCompany(id);
    };

    return {
        state: {
            list: readonly(list),
        },
        add,
        remove,
    };
});
