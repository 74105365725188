import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { BaggageUtil } from '@/util';

@Component
export default class BaggageSearchConditionListView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly searchConditionList?: baggageTypes.BaggageSearchCondition[];
    @Prop()
    declare readonly loading?: boolean;
    @Prop()
    declare readonly myCompanyProfile?: companyTypes.CompanyMyProfile;

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: 'よく使う検索条件',
            key: 'name',
            scopedSlots: { customRender: 'name' },
        },
        {
            title: 'メール通知',
            key: 'emailNotification',
            width: 240,
            scopedSlots: { customRender: 'emailNotification' },
            align: 'center',
        },
        {
            title: '',
            key: 'actions',
            scopedSlots: { customRender: 'actions' },
            width: 80,
            align: 'right',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    name(record: baggageTypes.BaggageSearchCondition, myCompanyProfile: companyTypes.CompanyMyProfile | undefined): string {
        if (!myCompanyProfile) return '';
        return BaggageUtil.formatBaggageSearchConditionLabel(record, myCompanyProfile);
    }
}
