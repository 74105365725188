<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useCompanyPlaceEditHelper } from '@/_pages/Setting/CompanyPlace/Edit/company-place-edit-helper';
import CityInput from '@/_components/ui/CityInput.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import { useFormModelItem } from '@/composables/form-helper';

const props = defineProps<{
    companyPlaceId?: number,
}>();

const {
    loading,
    form,
    formModel,
    formValidateRules,
    onSubmit,
    onClickBack
} = useCompanyPlaceEditHelper(props.companyPlaceId);
const { formModelItem: formItemAddress, onChange: onChangeAddress } = useFormModelItem();

</script>

<template>
    <page-layout @back="onClickBack">
        <a-spin :spinning="loading">
            <a-card>
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ sm: 6, lg: 4 }"
                              :wrapper-col="{ sm: 18, lg: 20 }">
                    <a-form-model-item label="地点名"
                                       :wrapper-col="{ span: 16 }"
                                       prop="placeName">
                        <a-input v-model="form.placeName"
                                 style="width: 50%;" />
                    </a-form-model-item>
                    <a-form-model-item label="地点"
                                       :wrapper-col="{ span: 16 }"
                                       prop="address"
                                       ref="formItemAddress">
                        <a-input-group compact>
                            <prefecture-select style="width: 20%"
                                               :multiple="false"
                                               placeholder="都道府県"
                                               title="地点"
                                               v-model="form.prefCode"
                                               @change="onChangeAddress"/>
                            <city-input style="width: 40%"
                                        placeholder="市区町村"
                                        :pref-code="form.prefCode"
                                        v-model="form.city"
                                        @change="onChangeAddress"
                                        @blur="form.normalize()"/>
                            <a-input style="width: 40%"
                                     placeholder="番地・建物（任意）"
                                     v-model="form.address"
                                     @change="onChangeAddress"
                                     @blur="form.normalize()"/>
                        </a-input-group>
                    </a-form-model-item>
                    <a-row :gutter="[32, 48]">
                        <a-col style="text-align: center;">
                            <a-button style="max-width: 200px; margin-right: 22px;"
                                      type="primary"
                                      size="large"
                                      block
                                      @click="onSubmit">
                                更新
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form-model>
            </a-card>
        </a-spin>
    </page-layout>
</template>
