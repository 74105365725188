<script setup lang="ts">
import { useSettlementHomeHelper } from '@/_pages/Baggage/List/tabs/Settlement/tabs/Home/settlement-home-helper';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import SettlementRequestInboxList
    from '@/_pages/Baggage/List/tabs/components/settlement/request/inbox/SettlementRequestInboxList.vue';
import SettlementRequestSentList
    from '@/_pages/Baggage/List/tabs/components/settlement/request/sent/SettlementRequestSentList.vue';

const {
    loadingOutgoRequestList,
    outgoUnconfirmedList,
    changePageOutgoRequestList,
    selectSettlement,
    onClickApprove,
    onClickReject,
    loadingIncomeRequestList,
    incomeUnconfirmedList,
    changePageIncomeRequestList,
} = useSettlementHomeHelper();
</script>

<template>
    <div>
        <div class="board-container component-heading-h2" style="padding-bottom: 0px;">
            <tbx-icon type="file-done"/>
            承認・申請
        </div>
        <div class="board-container">
            <a-space direction="vertical" size="middle" style="width: 100%;">
                <settlement-request-inbox-list :list="outgoUnconfirmedList" :loading="loadingOutgoRequestList"
                                               @approve="onClickApprove" @reject="onClickReject"
                                               @change="changePageOutgoRequestList"
                                               @selectSettlement="selectSettlement($event, 'outgo')"/>
                <settlement-request-sent-list :list="incomeUnconfirmedList" :loading="loadingIncomeRequestList"
                                              @change="changePageIncomeRequestList"
                                              @selectSettlement="selectSettlement($event, 'income')"/>
            </a-space>
        </div>
    </div>
</template>

<style scoped lang="less">
.board-container {
    padding: @padding-lg;
}
</style>
