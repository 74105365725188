<script setup lang="ts">
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';
import PhoneNumberText from '@/_units/PhoneNumberText.vue';
import { useSettingCompanyPaymentHelper } from '@/_pages/Setting/Company/Payment/setting-company-payment-helper';

const {
    loading,
    payment,
} = useSettingCompanyPaymentHelper();
</script>

<template>
    <setting-layout>
        <a-card>
            <a-spin :spinning="loading">
                <a-empty v-if="!loading && !payment">
                    <template #description>
                        <span>お支払い方法は設定されていません。</span>
                    </template>

                </a-empty>

                <template v-if="loading || payment">
                    <a-descriptions title="お支払い">
                        <a-descriptions-item label="方法" :span="3">
                            <template v-if="payment">{{ payment.method.label }}</template>
                        </a-descriptions-item>

                        <!-- NTT請求限定 -->
                        <template v-if="payment?.isOldNtt">
                            <a-descriptions-item label="NTT利用者名" :span="3">
                                <template v-if="payment">{{ payment.nttName }}</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="課金対象電話番号" :span="3">
                                <template v-if="payment">{{ payment.nttPhoneNumberText }}</template>
                            </a-descriptions-item>
                        </template>

                        <!-- NTTファイナンス限定 -->
                        <template v-if="payment?.isNewNtt">
                            <a-descriptions-item label="NTT同意者名" :span="3">
                                <template v-if="payment">{{ payment.nttAgreedName }}</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="課金対象電話番号" :span="3">
                                <template v-if="payment">{{ payment.nttPhoneNumberText }}</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="NTTファイナンス会員ID" :span="3">
                                <template v-if="payment">{{ payment.nttFinanceCode }}</template>
                            </a-descriptions-item>
                        </template>

                        <template v-if="payment?.isBanking">
                            <a-descriptions-item label="銀行・支店" :span="3">
                                <template>三井住友銀行 ドットコム支店(店番号 953)</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="口座種別" :span="3">
                                <template>当座預金</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="口座番号" :span="3">
                                <template v-if="payment?.transferAccountNumber">{{ payment?.transferAccountNumber }}</template>
                            </a-descriptions-item>
                            <a-descriptions-item label="口座名義" :span="3">
                                <template>トラボックス株式会社</template>
                            </a-descriptions-item>
                        </template>
                    </a-descriptions>

                    <br/>

                    <a-descriptions title="請求書" v-if="payment?.isBanking">
                        <a-descriptions-item label="企業名" :span="3">
                            <template v-if="payment">{{ payment.companyName?.kanji }}</template>
                        </a-descriptions-item>
                        <a-descriptions-item label="宛名" :span="3">
                            <template v-if="payment">{{ payment.name }}</template>
                        </a-descriptions-item>

                        <a-descriptions-item label="〒" :span="3">
                            <template v-if="payment">{{ payment.zipCodeText }}</template>
                        </a-descriptions-item>
                        <a-descriptions-item label="都道府県" :span="3">
                            <template v-if="payment">{{ payment.prefecture?.label }}</template>
                        </a-descriptions-item>
                        <a-descriptions-item label="市区町村" :span="3">
                            <template v-if="payment">{{ payment.city }}</template>
                        </a-descriptions-item>
                        <a-descriptions-item label="番地・建物" :span="3">
                            <template v-if="payment">{{ payment.address }}</template>
                        </a-descriptions-item>
                        <a-descriptions-item label="電話番号" :span="3">
                            <phone-number-text :phone-number="payment?.phoneNumber" v-if="payment"/>
                        </a-descriptions-item>
                        <a-descriptions-item label="FAX番号" :span="3" v-if="payment?.faxNumber">
                            <phone-number-text :phone-number="payment?.faxNumber"/>
                        </a-descriptions-item>
                    </a-descriptions>
                </template>
            </a-spin>
        </a-card>
    </setting-layout>
</template>

<style scoped lang="less">
</style>
