import _ from 'lodash';
import { AccountRegisterFormModel } from '@/models/account';
import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new AccountRegisterFormModel());
    const sourceForm = ref(new AccountRegisterFormModel());

    const dirty = computed(() => !_.isEqual(form.value, sourceForm.value));

    const register = () => withLoading(async () => {
        await accountApi.register(form.value);

        reset();
    });

    const reset = () => {
        form.value = new AccountRegisterFormModel();
    };

    return {
        state: {
            loading,
            form,
            dirty,
        },
        register,
    };
};
