import {
    Baggage,
    BaggageList,
    BaggageListForm,
    BaggageListFormModel,
    BaggageReadQuery,
    BaggageReadQueryForm,
    BaggageRecommendReturn,
    BaggageRecommendReturnsCount,
    BaggageRecommendReturnsCountsForm,
    BaggageRegisterForm,
    BaggageSearchCondition,
    BaggageSearchConditionNotificationUpdateForm,
    BaggageSearchConditionRegisterForm,
    BaggageSearchForm,
    BaggageUpdateApiForm,
    BaggageViewMemberCount, FavoriteBaggageCount,
    FavoriteBaggageListForm,
    FavoriteBaggageQuery,
    FavoriteBaggageQueryForm,
    MaskedBaggageList,
    RecentBaggageListForm
} from '@/vuex/modules/baggage/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';
import { Util } from '@/util';

export const baggageApi = {
    /**
     * 荷物を取得します。
     */
    find(baggageId: number, ref: string | undefined): Promise<Baggage> {
        const params = (ref === undefined) ? null : { ref: ref };
        return appAxios.get<JsonResponseBean<Baggage>>(`/api/baggage/${ baggageId }`, { params: params }).then(isSuccess);
    },
    /**
     * 自社荷物を取得します。
     */
    findMyBaggage(param: number): Promise<Baggage> {
        return appAxios.get<JsonResponseBean<Baggage>>(`/api/baggage/${ param }/me`).then(isSuccess);
    },
    /**
     * 荷物一覧を取得します。
     */
    // TODO: リファクタリングが完了したら、BaggageListFormModelを消す
    list(form: BaggageListForm | BaggageListFormModel): Promise<BaggageList> {
        return appAxios.post<JsonResponseBean<BaggageList>>('/api/baggage/list', form).then(isSuccess);
    },
    /**
     * 荷物詳細の閲覧会員数を取得する。
     */
    countUnique(ids: Array<number>): Promise<Array<BaggageViewMemberCount>> {
        const form = { ids: ids };
        return appAxios.post<JsonResponseBean<Array<BaggageViewMemberCount>>>('/api/baggage/count/unique', form).then(isSuccess);
    },
    /**
     * 荷物を検索します。
     */
    search(param: BaggageSearchForm): Promise<BaggageList> {
        return appAxios.post<JsonResponseBean<BaggageList>>('/api/baggage/search', param).then(isSuccess);
    },
    /**
     * 荷物を登録します。
     */
    register(param: BaggageRegisterForm): Promise<number[]> {
        return appAxios.post<JsonResponseBean<number[]>>('/api/baggage/register', param).then(isSuccess);
    },
    /**
     * 複数の荷物を一括登録します。
     */
    bulkRegister(circleId: number | undefined, file: File): Promise<void> {
        const data = new FormData();
        data.append('file', file);
        data.append('circleId', Util.requireNotNull(circleId).toString());
        const headers = { 'content-type': 'multipart/form-data' };
        return appAxios.post<JsonResponseBean<void>>('/api/baggage/bulkRegister', data, { headers }).then(isSuccess);
    },
    // TODO: リファクタリング完了後にパラメータからBaggageUpdateFormを消す
    /**
     * 荷物を更新します。
     */
    update(id: number, param: BaggageUpdateApiForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/update`, param).then(isSuccess);
    },
    /**
     * 荷物を取り下げ（削除）します。
     */
    cancel(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/cancel`).then(isSuccess);
    },
    /**
     * よく使う検索条件一覧を取得します。
     */
    listSearchCondition(): Promise<Array<BaggageSearchCondition>> {
        return appAxios
            .get<JsonResponseBean<Array<BaggageSearchCondition>>>('/api/baggage/search/condition/list')
            .then(isSuccess);
    },
    /**
     * よく使う検索条件に検索条件を登録します。
     */
    registerSearchCondition(param: BaggageSearchConditionRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/baggage/search/condition/register', param).then(isSuccess);
    },
    /**
     * 指定したIDの検索条件をよく使う検索条件から削除します。
     */
    deleteSearchCondition(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/search/condition/${ id }/delete`).then(isSuccess);
    },
    /**
     * よく使う検索条件のメール通知のオン・オフを設定します。
     */
    updateSearchConditionNotification(id: number, param: BaggageSearchConditionNotificationUpdateForm): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/baggage/search/condition/${ id }/update`, param)
            .then(isSuccess);
    },
    /**
     * 荷物を既読登録します。
     */
    markRead(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/read/mark`).then(isSuccess);
    },
    /**
     * 荷物の既読を解除します。
     */
    unmarkRead(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/read/unmark`).then(isSuccess);
    },
    /**
     * 荷物の既読状態を問い合わせます。
     */
    queryRead(param: BaggageReadQueryForm): Promise<BaggageReadQuery> {
        return appAxios.post<JsonResponseBean<BaggageReadQuery>>('/api/baggage/read/query', param).then(isSuccess);
    },
    /**
     * 最近見た荷物一覧を取得します
     */
    recentList(param: RecentBaggageListForm): Promise<MaskedBaggageList> {
        return appAxios.post<JsonResponseBean<MaskedBaggageList>>('/api/baggage/recent/list', param).then(isSuccess);
    },
    /**
     * お気に入り登録します。
     */
    markFavorite(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/favorite/mark`).then(isSuccess);
    },
    /**
     * お気に入り登録を解除します。
     */
    unmarkFavorite(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/favorite/unmark`).then(isSuccess);
    },
    /**
     * 終了した荷物のお気に入りを一括解除します。
     */
    cleanFavoriteBaggages(): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/favorite/clean`).then(isSuccess);
    },
    /**
     * お気に入り状態を問い合わせます。
     */
    queryFavorite(param: FavoriteBaggageQueryForm): Promise<FavoriteBaggageQuery> {
        return appAxios.post<JsonResponseBean<FavoriteBaggageQuery>>('/api/baggage/favorite/query', param).then(isSuccess);
    },
    /**
     * お気に入り荷物一覧を取得します。
     */
    favoriteList(param: FavoriteBaggageListForm): Promise<MaskedBaggageList> {
        return appAxios.post<JsonResponseBean<MaskedBaggageList>>('/api/baggage/favorite/list', param).then(isSuccess);
    },
    /**
     * お気に入り荷物件数を取得します。
     */
    favoriteCount(): Promise<FavoriteBaggageCount> {
        return appAxios.get<JsonResponseBean<FavoriteBaggageCount>>('/api/baggage/favorite/count').then(isSuccess);
    },
    /**
     * 荷物を商談中にします。
     */
    markNegotiation(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/negotiation/mark`).then(isSuccess);
    },
    /**
     * 荷物の商談中を外します。
     */
    unmarkNegotiation(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/baggage/${ id }/negotiation/unmark`).then(isSuccess);
    },
    /**
     * おすすめ帰り便の数を取得します。
     */
    baggageRecommendReturnsCounts(param: BaggageRecommendReturnsCountsForm): Promise<BaggageRecommendReturnsCount[]> {
        return appAxios.post<JsonResponseBean<BaggageRecommendReturnsCount[]>>('/api/baggage-recommend/returns/counts', param).then(isSuccess);
    },
    /**
     * おすすめ帰り便を取得します。
     */
    loadBaggageRecommendReturns(id: number): Promise<BaggageRecommendReturn[]> {
        return appAxios.post<JsonResponseBean<BaggageRecommendReturn[]>>(`/api/baggage-recommend/${ id }/returns`).then(isSuccess);
    }
};
