import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';

@Component({
    title: '運賃全額保証サービスの停止手続きが完了しました',
})
export default class MiscServiceGuaranteeRevokeCompletePage extends PageVue {
    // ======================================================
    // Functions
    // ======================================================
    /**
     * 便利サービストップに戻るボタンを押下された際に呼び出されます。
     */
    async onClickBackToMiscTop(): Promise<void> {
        await this.$router.push({ name: 'MiscService' });
    }
}
