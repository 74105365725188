import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { baggageApi } from '@/repository/api/internal/baggage';
import _ from 'lodash';

/**
 * 荷物のお気に入りIDリストをロードする機能を提供します。
 */
export const useBaggageFavoriteIdList = () => {
    const ids = ref<number[]>([]);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (baggageIds: number[]) => {
        await withLoading(async () => {
            const list = await baggageApi.queryFavorite({ ids: baggageIds });
            ids.value = list.ids;
        });
    };

    const clear = () => {
        ids.value = [];
    };

    const changeMark = (baggageId: number, value: boolean) => {
        if (value) {
            const newIds = _.cloneDeep(ids.value);
            newIds.push(baggageId);
            ids.value = _.uniq(newIds);
        } else {
            ids.value = _.cloneDeep(ids.value.filter(id => baggageId !== id));
        }
    };

    return {
        state: {
            ids,
            loading,
        },
        load,
        clear,
        changeMark,
    };
};
