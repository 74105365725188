import _ from 'lodash';
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { Baggage, BaggageUpdateFormModel } from '@/vuex/modules/baggage/types';
import { CompanyStaffNameSuggestionList, CompanyProfile } from '@/vuex/modules/company/types';
import { createPredictionForm, needsRePrediction } from '@/components/Baggage/Edit/helpers';
import dayjs from 'dayjs';
import { AgreementUtil } from '@/util';
// @ts-ignore
import UiDrawerLayout from '@/components/UI/Layouts/DrawerLayout';
// @ts-ignore
import BaggageId from '@/components/Baggage/View/Id';
// @ts-ignore
import BaggageSpot from '@/components/Baggage/Edit/Spot';
// @ts-ignore
import BaggageFlags from '@/components/Baggage/Edit/Flags';
// @ts-ignore
import BaggageType from '@/components/Baggage/Edit/Type';
// @ts-ignore
import BaggageTruckType from '@/components/Baggage/Edit/TruckType';
// @ts-ignore
import BaggageTruckHeight from '@/components/Baggage/Edit/TruckHeight';
// @ts-ignore
import BaggageTruckWidth from '@/components/Baggage/Edit/TruckWidth';
// @ts-ignore
import BaggageLargeTruck from '@/components/Baggage/Edit/LargeTruck';
// @ts-ignore
import BaggageTruckEquipment from '@/components/Baggage/Edit/TruckEquipment';
// @ts-ignore
import BaggageFreight from '@/components/Baggage/Edit/Freight';
// @ts-ignore
import BaggageHighwayFare from '@/components/Baggage/Edit/HighwayFare';
// @ts-ignore
import BaggageStaffName from '@/components/Baggage/Edit/StaffName';
// @ts-ignore
import BaggageDescription from '@/components/Baggage/Edit/Description';
// @ts-ignore
import BaggageShape from '@/components/Baggage/Edit/Shape';
// @ts-ignore
import PaletteCount from '@/components/Baggage/Edit/PaletteCount';
// @ts-ignore
import PaletteSize from '@/components/Baggage/Edit/PaletteSize';
// @ts-ignore
import BaggageTotalCount from '@/components/Baggage/Edit/TotalCount';
// @ts-ignore
import BaggageTotalVolume from '@/components/Baggage/Edit/TotalVolume';
// @ts-ignore
import BaggageTotalWeight from '@/components/Baggage/Edit/TotalWeight';
// @ts-ignore
import BaggageHandling from '@/components/Baggage/Edit/Handling';
// @ts-ignore
import Prediction from '@/components/Baggage/Edit/Prediction';
// @ts-ignore
import BaggagePaymentDate from '@/components/Baggage/Edit/PaymentDate';


@Component({
    components: {
        UiDrawerLayout,
        BaggageId,
        BaggageSpot,
        BaggageFlags,
        BaggageType,
        BaggageTruckType,
        BaggageTruckHeight,
        BaggageTruckWidth,
        BaggageLargeTruck,
        BaggageTruckEquipment,
        BaggageFreight,
        BaggageHighwayFare,
        BaggageStaffName,
        BaggageDescription,
        BaggageShape,
        PaletteCount,
        PaletteSize,
        BaggageTotalCount,
        BaggageTotalVolume,
        BaggageTotalWeight,
        BaggageHandling,
        Prediction,
        BaggagePaymentDate,
    },
})
export default class BaggageDetailEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: Baggage;
    @Prop()
    declare readonly profile?: CompanyProfile;
    @Prop({ default: false })
    declare readonly visible: boolean;
    @Prop({ default: false })
    declare readonly submitting?: boolean;
    @Prop()
    declare readonly staffNameSuggestions?: CompanyStaffNameSuggestionList;
    @InjectReactive()
    readonly agreementProbability!: number;

    get formModel(): BaggageUpdateFormModel {
        return this.form;
    }

    set formModel(newValue: BaggageUpdateFormModel) {
        this.emitFormDirty(newValue);
        this.requestPrediction(newValue);

        this.form = _.cloneDeep(newValue);
    }

    get defaultPaymentDate(): string | undefined {
        if (!this.form) return undefined;
        if (!this.profile?.hasPaymentTerms) return undefined;
        const departureDate = dayjs(this.form?.departureMax);
        const paymentDate = AgreementUtil.paymentDate(departureDate, this.profile?.cutOffDay?.code, this.profile?.paymentMonth?.code, this.profile?.paymentDay?.code);
        return paymentDate.format('YYYY-MM-DD');
    }

    // ======================================================
    // Data
    // ======================================================
    private form = new BaggageUpdateFormModel();

    // ======================================================
    // Functions
    // ======================================================

    mounted(): void {
        if (!this.baggage) return;
        this.formModel = BaggageUpdateFormModel.of(this.baggage);
    }

    onCancel(): void {
        this.$emit('cancel');
    }

    onSubmit(): void {
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.$emit('submit', { id: this.baggage?.id, form: this.formModel.toForm() });
        });
    }

    /**
     * フォームの変更状態をemitします。
     */
    private emitFormDirty(next: BaggageUpdateFormModel): void {
        if (!this.baggage) return;

        const origin = BaggageUpdateFormModel.of(this.baggage);

        const hasChanged = !_.isEqual(origin, next);

        this.$emit('changeFormDirty', hasChanged);
    }

    /**
     * 成約予測をリクエストします。
     */
    private requestPrediction(next: BaggageUpdateFormModel): void {
        if (!next.isReadyToPredict) return;

        if (!needsRePrediction(this.form, next)) return;

        this.emitRequestPrediction(next);
    }

    /**
     * 成約予測リクエストイベントを発火します。
     */
    private emitRequestPrediction = _.debounce((next: BaggageUpdateFormModel) => {
        this.$emit('requestPrediction', createPredictionForm(next));
    }, 500);
}
