<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { IconType } from '@/_components/ui/types/icon-type';
import { BaggageFavoriteState } from '@/models/baggage';
import { computed } from 'vue';

const props = defineProps<{
    state?: BaggageFavoriteState;
    showLabel?: boolean;
}>();

const emits = defineEmits<{
    (e: 'mark'): void,
    (e: 'unmark'): void,
    (e: 'cancelUnmark'): void,
}>();
/**
 * アイコンを取得します
 */
const icon = computed<IconType | undefined>(() => {
    switch (props.state) {
        case 'Unmarked':
            return 'baggage-favorite';
        case 'Marked':
            return 'baggage-favorite-marked';
        case 'Unmarking':
            return 'baggage-favorite';
        default:
            return undefined;
    }
});

/**
 * ツールチップを取得します
 */
const tooltip = computed<string | undefined>(() => {
    switch (props.state) {
        case 'Unmarked':
            return '保存する';
        case 'Marked':
            return '保存を解除する';
        case 'Unmarking':
            return '保存する';
        default:
            return undefined;
    }
});

/**
 * ラベルを取得します。
 */
const label = computed<string | undefined>(() => {
    switch (props.state) {
        case 'Unmarked':
            return '保存する';
        case 'Marked':
            return '保存済み';
        case 'Unmarking':
            return '保存する';
        default:
            return undefined;
    }
});

const onClick = (e: Event): void => {
    e.cancelBubble = true;
    switch (props.state) {
        case 'Unmarked':
            emits('mark');
            break;
        case 'Marked':
            emits('unmark');
            break;
        case 'Unmarking':
            emits('cancelUnmark');
            break;
        default:
            break;
    }
};
</script>

<template>
    <a-tooltip v-if="state !== undefined" :title="tooltip">
        <a-button size="small"
                  type="link"
                  @click="onClick($event)">
            <tbx-icon :type="icon" class="favorite-icon" />
            <span v-if="showLabel" class="label">{{ label }}</span>
        </a-button>
    </a-tooltip>
</template>

<style scoped lang="less">
button {
    > span {
        margin-left: 8px;
        color: @text-color;
    }

    &:hover {
        > span {
            color: unset;
        }
    }
}
.favorite-icon {
    font-size: 1.3333333333em;
    line-height: 0.75em;
    vertical-align: -0.225em;
}
</style>
