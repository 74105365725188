import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyIso39001Edit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyConfidenceUpdateForm;

    /**
     * ISO39001
     */
    get iso39001(): boolean {
        return this.value?.iso39001 ?? false;
    }

    set iso39001(newValue: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.iso39001 = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
