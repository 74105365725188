import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { gtm } from '@/gtm';
import { Profile } from '@/vuex/modules/account/types';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';

const allowMethod: Array<Partial<Method>> = ['post', 'put', 'delete'];

// アカウント情報一覧API
const isAccountProfileApiRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'get' && config.url === '/api/account/profile';
};
// 契約一覧取得API
const isCompanyContractApiRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'get' && config.url === '/api/company/contract';
};
// ログアウト API
const isLogoutApiRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'post' && config.url === '/api/logout';
};
// 運賃全額保証申し込み API
const isRegisterGuaranteeRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'get' && config.url === '/api/company/application/guarantee/register';
};
// 荷物登録 API
const isRegisterBaggageRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'post' && config.url === '/api/baggage/register';
};
// 車両登録 API
const isRegisterTruckRequest = (config: AxiosRequestConfig): boolean => {
    return config.method === 'post' && config.url === '/api/truck/register';
};

/**
 * GTMへカスタムイベントを送出するべきかどうかを判定します。
 * 送出する条件は以下のすべての条件に合致した場合になります:
 * 1. 叩いたAPIエンドポイントのmethodが、post, put, deleteメソッドであること
 * 2. `/api` からエンドポイントが始まっていること
 * 3. URLに `/list` が含まれていないこと（一覧系エンドポイントを排除）
 * @param config
 */
const shouldSendEvent = (config: AxiosRequestConfig): boolean => {
    if (!config.url || !config.method || !allowMethod.includes(config.method)) {
        return false;
    }
    return config.url.indexOf('/api') === 0 && config.url.indexOf('/list') < 0;
};

export const gtmSuccessResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
    // ログアウト
    if (isLogoutApiRequest(response.config)) {
        clearUserProperty();
    } else if (isAccountProfileApiRequest(response.config) && response.data.data) {
        // ユーザープロフィール取得API: GTMにユーザー属性を設定
        const { id, companyId } = response.data.data as Profile;
        gtm.setUser(companyId, id);
    } else if (isCompanyContractApiRequest(response.config) && response.data.data) {
        // 企業契約情報（プラン）取得API: GTMにユーザー属性を設定
        const model = new CompanyContractListModel(response.data.data);
        gtm.setPlan(model.activePlanType);
    } else if (isRegisterGuaranteeRequest(response.config)) {
        gtm.setEvent('register_guarantee_complete');
    } else if (isRegisterBaggageRequest(response.config) && response.data.data) {
        // 荷物登録イベント
        const count = Array.isArray(response.data.data) ? response.data.data.length : 1;
        gtm.setEvent('register_baggage_complete', { count });
    } else if (isRegisterTruckRequest(response.config)) {
        // 空車登録イベント
        gtm.setEvent('register_truck_complete', { count: 1 });
    } else if (shouldSendEvent(response.config)) {
        // 残りのイベント: GTMに送出すべきか判定してイベントを設定
        gtm.setEvent(`${response.config.method}_http_success`, { url: response.config.url });
    }
    return response;
};

export const gtmErrorResponseInterceptor = (error: AxiosError & { response: AxiosResponse }): Promise<AxiosError> => {
    // APIの認証が通らない場合はユーザー属性情報をクリア
    if (
        error.response.status === 401 ||
        error.response.data.code === 'SESSION_EXPIRED' ||
        isLogoutApiRequest(error.config)
    ) {
        clearUserProperty();
    }
    return Promise.reject(error);
};

/**
 * ユーザー情報をクリアします。
 */
function clearUserProperty() {
    gtm.setUser();
    gtm.setPlan();
}
