import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';
import { useVuexSync } from '@/composables/helper/vuex';
import { CompanyNoticeListModel } from '@/models/company-notice-list';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/companyNotice/types';
import { companyNoticeApi } from '@/repository/api/internal/company-notice';
import { agreementApi } from '@/repository/api/internal/agreement';
import { companyApi } from '@/repository/api/internal/company';
import { CompanyNoticeModel } from '@/models/company-notice-model';
import { Karte } from '@/karte';
import { useGtm } from '@/composables/helper/gtm-helper';

/**
 * 企業お知らせデータを管理する機能を提供します。
 */
export const useCompanyNotice = createGlobalState(() => {
    const noticeList = ref<CompanyNoticeListModel | undefined>(undefined);
    useVuexSync(noticeList, { module: 'companyNotice', mutation: m.SET_COMPANY_NOTICE_LIST, getter: g.COMPANY_NOTICE_LIST });

    const load = async () => {
        const notices = await companyNoticeApi.list();
        const agreementIds = notices.map((item) => item.objectId);
        const [agreements, profileProfiles] = await Promise.all([
            agreementIds.length > 0 ? agreementApi.search({ id: agreementIds }) : [],
            agreementIds.length > 0 ? companyApi.listPartnerProfile({ id: agreementIds }) : []
        ]);

        const data = new CompanyNoticeListModel(notices, agreements, profileProfiles);
        noticeList.value = data;

        return data;
    };

    const { gtm } = useGtm();
    const markAsRead = async (item: CompanyNoticeModel) => {
        Karte.trackClickCompanyNotice(item.type.code, item.objectId);

        if (!item.markedAsRead) {
            await companyNoticeApi.markAsRead(item.id);
            gtm.setEvent('click_company_notice', { id: item.id });
            await load();
        }
    };

    return {
        state: {
            noticeList,
        },
        load,
        markAsRead,
    };
});
