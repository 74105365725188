<script setup lang="ts">
import { CompanyConfidence, CompanyStatistics, CompanyProfile, OfficialCompany } from '@/models/company';
import { Validator } from '@/validator';
import { DateUtil, PhoneUtil, ZipUtil } from '@/util';
import { Const } from '@/const';
import { computed, ref } from 'vue';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

const props = withDefaults(defineProps<{
    confidence?: CompanyConfidence,
    profile?: CompanyProfile,
    officialCompany?: OfficialCompany,
    statistics?: CompanyStatistics,
    enableNameLink: boolean,
    hideContact?: boolean,
}>(), {
    enableNameLink: true,
    hideContact: false,
});

const emits = defineEmits<{
    (e: 'clickCompanyName', companyId: number): void,
    (e: 'clickContact'): void,
}>();

const openedContact = ref<boolean>(false);

const closedContact = computed<boolean>(() => {
    return props.hideContact && !openedContact.value;
});

/**
 * ウェブサイトURLが正しい形式であるか否かを取得します。
 */
const urlValid = computed<boolean >(() => {
    return Validator.validateUrl(props.profile?.url ?? '').result;
});

/**
 * 企業名が押下された際に呼び出されます。
 */
const onClickCompanyName = (): void => {
    if (!props.profile) return;
    emits('clickCompanyName', props.profile.id);
};

const onClickContact = (): void => {
    openedContact.value = true;
    emits('clickContact');
};

/**
 * 郵便番号をフォーマットします。
 * @param zipCode
 */
const formatZipCode = (zipCode: string): string => {
    return ZipUtil.format(zipCode);
};

/**
 * 電話番号をフォーマットします。
 * @param phoneNumber
 */
const formatPhoneNumber = (phoneNumber: string): string => {
    return PhoneUtil.format(phoneNumber);
};

/**
 * 日付を年月でフォーマットします。
 * @param date
 */
const formatYearMonth = (date: string): string => {
    return DateUtil.parseDateText(date).format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
};

/**
 * 金額を万円単位でフォーマットします。
 * @param value
 */
const formatManYen = (value: number): string => {
    return `${(value / 10000).toString()} 万円`;
};

/**
 * boolean値を「有」or「無」でフォーマットします。
 */
const formatYesNo = (value: boolean): string => {
    return value ? '有' : '無';
};
</script>

<template>
    <div class="company-summary">
        <div class="company-summary__title">基本情報</div>
        <a-descriptions bordered :column="1" size="small" v-if="profile">
            <a-descriptions-item label="法人名・事業者名">
                <template v-if="profile.name.kana">
                    <span class="company-summary__caption-text" v-if="profile.name.kana">{{ profile.name.kana }}</span><br>
                </template>
                <template v-if="enableNameLink">
                    <a @click="onClickCompanyName"><tbx-link-text type="product">{{ profile.name.kanji }}</tbx-link-text></a><br/>
                    <a @click="onClickCompanyName"><tbx-link-text type="product">他の荷物をみる</tbx-link-text></a>
                </template>
                <template v-else>
                    <span>{{ profile.name.kanji }}</span>
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="住所">
                <template v-if="profile.zipCode"><span>〒{{ formatZipCode(profile.zipCode) }}</span><br></template>
                <span>{{ profile.location.prefecture.label }}{{ profile.location.city }}{{ profile.location.address }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="電話番号">
                <div v-if="closedContact">
                    <a-button size="small" @click="onClickContact">電話番号を表示する</a-button>
                </div>
                <div v-else>
                    <span v-if="profile.phone.number">{{ formatPhoneNumber(profile.phone.number) }}</span>
                    <span v-else class="empty-text">-</span>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="FAX番号">
                <span v-if="profile.phone.faxNumber">{{ formatPhoneNumber(profile.phone.faxNumber) }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="請求事業者登録番号">
                <span v-if="officialCompany?.taxCompanyId">{{ officialCompany.taxCompanyId }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="業務内容・会社PR">
                <span v-if="profile.description" style="white-space: pre-wrap;">{{ profile.description }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="保有車両台数">
                <span v-if="profile.truckCount">{{ profile.truckCount }} 台</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="ウェブサイトURL">
                <template v-if="profile.url">
                    <a :href="profile.url" v-if="urlValid" target="_blank">
                        <tbx-link-text type="external">{{ profile.url }}</tbx-link-text>
                    </a>
                    <span v-else>{{ profile.url }}</span>
                </template>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="登録年月">
                <span v-if="profile.registrationDate">{{ formatYearMonth(profile.registrationDate) }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>

            <template v-if="statistics">
                <a-descriptions-item>
                    <template #label>直近1ヶ月<br>荷物登録数</template>
                    <span v-if="statistics">{{ statistics.pastMonthBaggageCount }}</span>
                    <span v-else class="empty-text">-</span>
                </a-descriptions-item>
                <a-descriptions-item>
                    <template #label>直近3ヶ月<br>荷物登録数</template>
                    <span v-if="statistics">{{ statistics.pastThreeMonthsBaggageCount }}</span>
                    <span v-else class="empty-text">-</span>
                </a-descriptions-item>
                <a-descriptions-item>
                    <template #label>直近1ヶ月<br>車両登録数</template>
                    <span v-if="statistics">{{ statistics.pastMonthTruckCount }}</span>
                    <span v-else class="empty-text">-</span>
                </a-descriptions-item>
                <a-descriptions-item>
                    <template #label>直近3ヶ月<br>車両登録数</template>
                    <span v-if="statistics">{{ statistics.pastThreeMonthsTruckCount }}</span>
                    <span v-else class="empty-text">-</span>
                </a-descriptions-item>
            </template>
        </a-descriptions>

        <div class="company-summary__title">詳細情報</div>
        <a-descriptions bordered :column="1" size="small" v-if="profile && confidence">
            <a-descriptions-item label="代表者">
                <span v-if="profile.representativeName">{{ profile.representativeName }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="設立">
                <span v-if="profile.establishmentDate">{{ formatYearMonth(profile.establishmentDate) }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="資本金">
                <span v-if="profile.capital">{{ formatManYen(profile.capital) }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="従業員数">
                <span v-if="profile.employeesNumber">{{ profile.employeesNumber.label }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="事業所所在地">
                <span v-if="profile.officeAddress">{{ profile.officeAddress }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="年間売上">
                <span v-if="profile.sales">{{ formatManYen(profile.sales) }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="取引先銀行">
                <span v-if="profile.bank">{{ profile.bank }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="主要取引先">
                <span v-if="profile.customer">{{ profile.customer }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="締め日">
                <span v-if="profile.cutOffDay">当月 {{ profile.cutOffDay.label }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="支払月・支払日">
                <template v-if="profile.paymentMonth || profile.paymentDay">
                    <span v-if="profile.paymentMonth">{{ profile.paymentMonth.label }} </span>
                    <span v-if="profile.paymentDay">{{ profile.paymentDay.label }}</span>
                </template>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="加入組織">
                <span v-if="confidence.unionName">{{ confidence.unionName }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="国交省認可番号">
                <span v-if="confidence.mlitGrantNumber">{{ confidence.mlitGrantNumber }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="営業地域">
                <span v-if="profile.salesArea">{{ profile.salesArea }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="デジタコ搭載数">
                <span v-if="confidence.digitalTachometerCount">{{ confidence.digitalTachometerCount }} 台</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="GPS搭載数">
                <span v-if="confidence.gpsCount">{{ confidence.gpsCount }} 台</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
            <a-descriptions-item label="安全性優良事業所認定">
                <span>{{ formatYesNo(confidence.goodMark) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="グリーン経営認証">
                <span>{{ formatYesNo(confidence.greenManagement) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="ISO9000">
                <span>{{ formatYesNo(confidence.iso9000) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="ISO14000">
                <span>{{ formatYesNo(confidence.iso14000) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="ISO39001">
                <span>{{ formatYesNo(confidence.iso39001) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="荷物保険">
                <span v-if="confidence.insuranceName">{{ confidence.insuranceName }}</span>
                <span v-else class="empty-text">-</span>
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<style scoped lang="less">
.company-summary {
    &__title {
        color: @ui-color-heading-text;
        margin-bottom: 8px;
    }

    * + &__title {
        margin-top: 32px;
    }

    &__caption-text {
        font-size: 12px;
    }

    .ant-descriptions {
        ::v-deep .ant-descriptions-item-label {
            width: 150px;
        }

        ::v-deep .ant-descriptions-item-content {
            width: auto;
            word-break: break-all;
        }
    }
}

.empty-text {
    color: @ui-color-empty-text;
}
</style>
