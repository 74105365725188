<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { CompanyProfile, OfficialCompany } from '@/models/company';
import { computed } from 'vue';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { Const } from '@/const';
import { BaggageDetailUtil, PhoneUtil, Util } from '@/util';
import _ from 'lodash';

const props = defineProps<{
    baggage: Baggage,
    profile: CompanyProfile,
    officialCompany?: OfficialCompany,
}>();
const relocationBaggageLabel = computed(() => props.baggage.category?.code === BaggageCategoryEnum.Relocation.code ? '○' : '×');
const departure = computed(() => {
    const min = props.baggage.departureMin;
    const max = props.baggage.departureMax;
    if (!min || !max) return '';
    return DeliveryDateTimeRange.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
});
const departureLocation = computed(() => {
    const array = [
        props.baggage.departurePref.label,
        props.baggage.departureCity,
        props.baggage.departureAddress ?? '',
    ];
    return array.join('');
});
const arrival = computed(() => {
    const min = props.baggage.arrivalMin;
    const max = props.baggage.arrivalMax;
    if (!min || !max) return '';
    return DeliveryDateTimeRange.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
});
const arrivalLocation = computed(() => {
    const array = [
        props.baggage.arrivalPref.label,
        props.baggage.arrivalCity,
        props.baggage.arrivalAddress ?? ''
    ];
    return array.join('');
});
const paletteCount = computed(() => {
    return BaggageDetailUtil.paletteCount(
        props.baggage.shape.code,
        props.baggage.paletteCount
    );
});
const paletteSize = computed(() => {
    return BaggageDetailUtil.paletteSize(
        props.baggage.shape.code,
        props.baggage.paletteHeight,
        props.baggage.paletteWidth
    );
});
const totalCount = computed(() => {
    return BaggageDetailUtil.totalCount(
        props.baggage.shape.code,
        props.baggage.totalCount
    );
});
const totalVolume = computed(() => {
    return BaggageDetailUtil.totalVolume(
        props.baggage.shape.code,
        props.baggage.totalVolume
    );
});
const totalWeight = computed(() => BaggageDetailUtil.totalWeight(props.baggage.totalWeight));
const handling = computed(() => {
    return BaggageDetailUtil.handling(
        props.baggage.loading?.code,
        props.baggage.unloading?.code
    );
});
const temperatureZone = computed(() => {
    return props.baggage.temperatureZone.label;
});
const share = computed(() => `積合：${props.baggage.share ? '○' : '×'}`);
const truck = computed(() => `重量：${props.baggage.truckWeight.label} 車種：${props.baggage.truckModel.label}`);
const specifiedTruck = computed(() => {
    const height = props.baggage.truckHeight?.label;
    const width = props.baggage.truckWidth?.label;
    const largeTruckFlg = props.baggage.largeTruckFlg;
    const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

    return `床高：${ height ?? '指定なし' } / 車幅：${ width ?? '指定なし' } / 大型：${ largeTruckText }`;
});
const freight = computed(() => {
    const freight = props.baggage.freight ?? 0;
    return freight > 0 ? `${Util.formatNumber(freight)}円` : '要相談';
});
const highwayFare = computed(() => props.baggage.highwayFareFlg ? 'あり' : 'なし');
const phone = computed(() => PhoneUtil.format(props.profile.phone.number ?? ''));
const fax = computed(() => PhoneUtil.format(props.profile.phone.faxNumber ?? ''));
</script>

<template>
    <a-descriptions bordered :column="2" size="small">
        <a-descriptions-item>
            <template #label>
                <span class="row-head">荷物情報番号</span>
            </template>
            <span class="row-value">
            <span>{{ baggage.id }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">引越し案件</span>
            </template>
            <span class="row-value">
            <span>{{ relocationBaggageLabel }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item>
            <template #label>
                <span class="row-head">発日時</span>
            </template>
            <span class="row-value">
            <span>{{ departure }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">積み時間</span>
            </template>
            <span class="row-value">{{ baggage.loadingTimeNote }}</span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">発地</span>
            </template>
            <span class="row-value">
            <span>{{ departureLocation }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item>
            <template #label>
                <span class="row-head">着日時</span>
            </template>
            <span class="row-value">
            <span>{{ arrival }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">卸し時間</span>
            </template>
            <span class="row-value">{{ baggage.unloadingTimeNote }}</span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">着地</span>
            </template>
            <span class="row-value">
            <span>{{ arrivalLocation }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">荷種</span>
            </template>
            <span class="row-value">
            <div>
                <span>{{ baggage.type }} / </span>
                <span v-if="paletteCount">{{ paletteCount }} / </span>
                <span v-if="paletteSize">{{ paletteSize }} / </span>
                <span v-if="totalCount">{{ totalCount }} / </span>
                <span v-if="totalVolume">{{ totalVolume }}m<sup>3</sup> / </span>
                <span v-if="totalWeight">{{ totalWeight }} / </span>
                <span v-if="handling">{{ handling }} / </span>
                <span>{{ share }}</span>
            </div>
        </span>
        </a-descriptions-item>
        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">温度帯</span>
            </template>
            <span class="row-value">
            <span>{{ temperatureZone }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">希望車種</span>
            </template>
            <span class="row-value">
            <span>{{ truck }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">車両指定</span>
            </template>
            <span class="row-value">
                <span>{{ specifiedTruck }}</span>
            </span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">必要装備</span>
            </template>
            <span class="row-value">{{ baggage.truckEquipment }}</span>
        </a-descriptions-item>

        <a-descriptions-item>
            <template #label>
                <span class="row-head">運賃(税別)</span>
            </template>
            <span class="row-value">
            <span>{{ freight }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">高速代</span>
            </template>
            <span class="row-value">
            <span>{{ highwayFare }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item>
            <template #label>
                <span class="row-head">電話番号</span>
            </template>
            <span class="row-value">
            <span>{{ phone }}</span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">FAX番号</span>
            </template>
            <span class="row-value">
            <span>{{ fax }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item>
            <template #label>
                <span class="row-head">荷主会社名</span>
            </template>
            <span class="row-value">
            <span>
                <div>{{ profile.name.kanji }}</div>
                <div v-if="profile.name.kana">{{ profile.name.kana }}</div>
            </span>
        </span>
        </a-descriptions-item>
        <a-descriptions-item>
            <template #label>
                <span class="row-head">担当者名</span>
            </template>
            <span class="row-value">
            <span>{{ baggage.staffName }}</span>
        </span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head wrap">請求事業者<br/>登録番号</span>
            </template>
            <span class="row-value">
                <span v-if="officialCompany?.taxCompanyId">{{ officialCompany.taxCompanyId }}</span>
                <span v-else>-</span>
            </span>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
            <template #label>
                <span class="row-head">備考</span>
            </template>
            <span class="row-value-long">
            <div>
                <a-tag class="tag-multiple-baggage" v-if="baggage.multipleBaggage">複数台荷物</a-tag>
                <div class="baggage-description">{{ baggage.description }}</div>
            </div>
        </span>
        </a-descriptions-item>
    </a-descriptions>
</template>

<style scoped lang="less">
// components/Baggage/Print/style.less
.row-head {
    display: inline-block;
    white-space: nowrap;
    width: 100px;

    &.wrap {
        line-height: 1.2;
    }
}

.row-value {
    display: inline-block;
    word-break: break-all;
}

.row-value-long {
    display: inline-block;
    word-break: break-all;
    white-space: pre-wrap;
}

::v-deep .ant-descriptions-item-label {
    width: 100px;
}
// components/Baggage/View/Description/style.less
.tag-multiple-baggage {
    background-color: transparent;
}

.baggage-description {
    white-space: pre-wrap;
}
// components/Baggage/View/SpecifiedTruck/style.less
// components/Baggage/View/Truck/style.less
.label {
    text-align: end;
}

</style>
