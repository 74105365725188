import _ from 'lodash';
import { ValueObject } from '@/models/vo/value-object';

/**
 * 売上
 */
export class SalesValue extends ValueObject<number | undefined> {
    constructor(value?: number) {
        const rounded = _.isNil(value) || _.isNaN(value) ? undefined : Math.min(value, MaxSales);
        super(rounded);
    }
}

export const MaxSales = 10000000000000;
