<script setup lang="ts">
import { AgreementFormModel } from '@/models/agreement';
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { PrefectureEnum } from '@/enums/prefecture.enum';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { BaggageDetailUtil, DateUtil, GuaranteeUtil, Util } from '@/util';
import _ from 'lodash';
import { Const } from '@/const';
import { CompanyGuaranteedAmountModel } from '@/models/company';
import { GuaranteePriceMaster } from '@/models/guarantee-master';
import { AmountValue } from '@/models/vo/amount';
import { CompanyContractListModel } from '@/models/company-contract-list';
import { BaggageTemperatureZoneEnum, BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';

const props = defineProps<{
    form: AgreementFormModel,
    baggage?: Baggage,
    existsGuarantee: boolean,
    guaranteedAmount?: CompanyGuaranteedAmountModel,
    guaranteePrices: GuaranteePriceMaster[],
    contractList?: CompanyContractListModel,
}>();
const baggageId = computed(() => props.baggage?.id.toString() ?? '');
const departure = computed(() => {
    if (!props.form.departureMin || !props.form.departureMax || !props.form.departureType) return '';
    return DeliveryDateTimeRange.typeOf(props.form.departureType, props.form.departureMin, props.form.departureMax)?.format() ?? '';
});
const departureLocation = computed(() => {
    return [
        PrefectureEnum.valueOf(props.form.departurePrefCode)?.label ?? '',
        props.form.departureCity,
        props.form.departureAddress ?? '',
    ].join('');
});
const arrival = computed(() => {
    if (!props.form.arrivalMin || !props.form.arrivalMax || !props.form.arrivalType) return '';
    return DeliveryDateTimeRange.typeOf(props.form.arrivalType, props.form.arrivalMin, props.form.arrivalMax)?.format() ?? '';
});
const arrivalLocation = computed(() => {
    return [
        PrefectureEnum.valueOf(props.form.arrivalPrefCode)?.label ?? '',
        props.form.arrivalCity,
        props.form.arrivalAddress ?? '',
    ].join('');
});
const paletteCount = computed(() => {
    const shape = BaggageShapeEnum.valueOf(props.form.shape?.code);
    return BaggageDetailUtil.paletteCount(
        shape?.code,
        props.form.paletteCount ? Util.toNumber(props.form.paletteCount) : undefined,
    );
});
const paletteSize = computed(() => {
    const shape = BaggageShapeEnum.valueOf(props.form.shape?.code);
    return BaggageDetailUtil.paletteSize(
        shape?.code,
        props.form.paletteHeight ? Util.toNumber(props.form.paletteHeight) : undefined,
        props.form.paletteWidth ? Util.toNumber(props.form.paletteWidth) : undefined
    );
});
const totalCount = computed(() => {
    const shape = BaggageShapeEnum.valueOf(props.form.shape?.code);
    return BaggageDetailUtil.totalCount(
        shape?.code,
        props.form.totalCount ? Util.toNumber(props.form.totalCount) : undefined
    );
});
const totalVolume = computed(() => {
    const shape = BaggageShapeEnum.valueOf(props.form.shape?.code);
    return BaggageDetailUtil.totalVolume(
        shape?.code,
        props.form.totalVolume ? Util.toNumber(props.form.totalVolume) : undefined
    );
});
const totalWeight = computed(() => {
    return BaggageDetailUtil.totalWeight(
        props.form.totalWeight ? Util.toNumber(props.form.totalWeight) : undefined
    );
});
const handling = computed(() => {
    return BaggageDetailUtil.handling(
        props.form.loading?.code,
        props.form.unloading?.code
    );
});
const temperatureZone = computed(() => {
    if (!props.baggage?.temperatureZone) return '';
    const newTemperatureZone = BaggageTemperatureZoneEnum.valueOf(props.form.temperatureZone?.code as BaggageTemperatureZoneEnumCode);
    return newTemperatureZone?.label ?? '';
});
const hasSpecifiedTruck = computed(() => {
    return [
        props.baggage?.truckHeight,
        props.baggage?.truckWidth,
        props.baggage?.largeTruckFlg
    ].some(each => !_.isNil(each));
});
const specifiedTruckItems = computed(() => {
    const items = [];
    if (props.baggage?.truckHeight) {
        items.push({ label: '床高', value: props.baggage?.truckHeight.label ?? '' });
    }
    if (props.baggage?.truckWidth) {
        items.push({ label: '車幅', value: props.baggage?.truckWidth.label ?? '' });
    }
    if (!_.isNil(props.baggage?.largeTruckFlg)) {
        items.push({ label: '大型可否', value: props.baggage?.largeTruckFlg ? '可' : '不可' });
    }
    return items;
});
const freight = computed(() => `${ Util.formatNumber(Util.toNumber(props.form.freight)) }円`);
const highwayFare = computed(() => {
    if (!props.form.highwayFareFlg) return 'なし';

    const value =
        typeof props.form.highwayFare === 'string' ? Util.toNumber(props.form.highwayFare) : props.form.highwayFare;
    return value === 0 ? '金額未定' : `${ Util.formatNumber(value) }円`;
});
const waitTimeFee = computed(() => {
    if (_.isNil(props.form.waitTimeFee)) return '金額未定';
    const value =
        typeof props.form.waitTimeFee === 'string' ? Util.toNumber(props.form.waitTimeFee) : props.form.waitTimeFee;
    return `${ Util.formatNumber(value) }円`;
});
const operationFee = computed(() => {
    if (_.isNil(props.form.operationFee)) return '金額未定';
    const value =
        typeof props.form.operationFee === 'string' ? Util.toNumber(props.form.operationFee) : props.form.operationFee;
    return `${ Util.formatNumber(value) }円`;
});
const pickingFee = computed(() => {
    if (_.isNil(props.form.pickingFee)) return '金額未定';
    const value =
        typeof props.form.pickingFee === 'string' ? Util.toNumber(props.form.pickingFee) : props.form.pickingFee;
    return `${ Util.formatNumber(value) }円`;
});
const parkingFee = computed(() => {
    if (_.isNil(props.form.parkingFee)) return '金額未定';
    const value =
        typeof props.form.parkingFee === 'string' ? Util.toNumber(props.form.parkingFee) : props.form.parkingFee;
    return `${ Util.formatNumber(value) }円`;
});
const clearanceFee = computed(() => {
    if (_.isNil(props.form.clearanceFee)) return '金額未定';
    const value =
        typeof props.form.clearanceFee === 'string' ? Util.toNumber(props.form.clearanceFee) : props.form.clearanceFee;
    return `${ Util.formatNumber(value) }円`;
});
const paymentDate = computed(() => {
    return !_.isEmpty(props.form.paymentDate)
        ? DateUtil.parseDateText(props.form.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT)
        : '';
});
/**
 * 今月これまでの保証対象累計額
 */
const usedGuaranteedAmount = computed(() => props.guaranteedAmount?.amount ?? new AmountValue(0));
/**
 * 今回の保証対象金額
 */
const newGuaranteedAmount = computed(() => {
    const freight = Util.toNumber(props.form.freight);
    if (isNaN(freight)) return new AmountValue(0);
    const highwayFare = Util.toNumber(props.form.highwayFare.toString());
    const waitTimeFee = props.form.waitTimeFee ?? 0;
    const operationFee = props.form.operationFee ?? 0;
    const pickingFee = props.form.pickingFee ?? 0;
    const parkingFee = props.form.parkingFee ?? 0;
    const clearanceFee = props.form.clearanceFee ?? 0;

    return new AmountValue(freight + highwayFare + waitTimeFee + operationFee + pickingFee + parkingFee + clearanceFee);
});
/**
 * 今月これまでの運賃保証サービス料
 */
const usedServiceFee = computed(() => {
    if (!props.guaranteedAmount || !props.guaranteePrices || !props.contractList) {
        return new AmountValue(0);
    }
    return new AmountValue(
        GuaranteeUtil.calculateGuaranteePrice(
            props.guaranteedAmount.amount.value,
            props.guaranteePrices,
            props.contractList.contracts
        )
    );
});
/**
 * 合計利用金額
 */
const totalServiceFee = computed(() => {
    if (!props.guaranteedAmount || !props.guaranteePrices || !props.contractList) {
        return new AmountValue(0);
    }
    return new AmountValue(
        GuaranteeUtil.calculateGuaranteePrice(
            props.guaranteedAmount.amount.addAll(newGuaranteedAmount.value).value,
            props.guaranteePrices,
            props.contractList.contracts
        )
    );
});
</script>

<template>
    <div>
        <a-row type="flex" align="middle" :gutter="[16, 0]">
            <a-col :span="24">
                <a-alert type="warning" :show-icon="true">
                    <template #icon>
                        <a-icon type="info-circle" class="agreement-alert__icon"/>
                    </template>
                    <template #message>
                        <p class="agreement-alert__message">
                            成約後に内容を修正する際は、相手先企業の承認が必要になります。お手数ですが入力内容を今一度ご確認ください。</p>
                    </template>
                    <template #description>
                        <p class="agreement-alert__description">※成約後の条件変更は着日を含めて5日後が期日となります。</p>
                    </template>
                </a-alert>
            </a-col>
        </a-row>

        <section class="summary">
            <div class="agreement-summary">
                <div class="summary__title">成約内容</div>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>荷物番号：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ baggageId }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>発日時：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ departure }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>発地：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ departureLocation }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]" v-if="form.loadingTimeNote">
                    <a-col :span="8" style="text-align: end">
                        <span>積み時間：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.loadingTimeNote }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>着日時：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ arrival }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>着地：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ arrivalLocation }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]" v-if="form.unloadingTimeNote">
                    <a-col :span="8" style="text-align: end">
                        <span>卸し時間：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.unloadingTimeNote }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="top" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>荷種：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.type }} 積合：{{ baggage?.share ? '○' : '×' }}<br></span>
                        <span v-if="paletteCount">{{ paletteCount }}<br></span>
                        <span v-if="paletteSize">{{ paletteSize }}<br></span>
                        <span v-if="totalCount">{{ totalCount }}<br></span>
                        <span v-if="totalVolume">{{ totalVolume }}m<sup>3</sup><br></span>
                        <span v-if="totalWeight">{{ totalWeight }}<br></span>
                        <span v-if="handling">{{ handling }}<br></span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="top" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>温度帯：</span>
                    </a-col>
                    <a-col :span="16">
                        <span v-if="temperatureZone">{{ temperatureZone }}<br></span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>希望車種：</span>
                    </a-col>
                    <a-col :span="16">
                    <span>重量：{{
                            baggage?.truckWeight?.label ?? ''
                        }} 車種：{{
                            baggage?.truckModel?.label ?? ''
                        }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]" v-if="hasSpecifiedTruck">
                    <a-col :span="8" style="text-align: end">
                        <span>車両指定：</span>
                    </a-col>
                    <a-col :span="16">
                        <span v-for="(item, index) in specifiedTruckItems" :key="item.label">
                            {{ item.label }}：{{ item.value }}
                            <template v-if="index != specifiedTruckItems.length - 1"> </template>
                        </span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]" v-if="baggage?.truckEquipment">
                    <a-col :span="8" style="text-align: end">
                        <span>必要装備：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ baggage?.truckEquipment }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="top" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>備考：</span>
                    </a-col>
                    <a-col :span="16">
                        <p class="description-text">{{ form.description ?? '' }}</p>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>運賃：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ freight }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>高速代：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ highwayFare }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>待機料：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ waitTimeFee }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>付帯作業料：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ operationFee }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>搬出料：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ pickingFee }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>駐車代：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ parkingFee }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>通関料：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ clearanceFee }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>担当者：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.truckStaffName ?? '' }}</span>
                    </a-col>
                </a-row>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>入金予定日：</span>
                    </a-col>
                    <a-col :span="16">
                        <span class="emphasis-text">{{ paymentDate }}</span>
                    </a-col>
                </a-row>
                <a-row v-if="existsGuarantee" type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>運賃全額保証サービス：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.guarantee ? '利用する' : '利用しない' }}</span>
                    </a-col>
                </a-row>
                <a-row v-if="form.settlementProxy" type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="8" style="text-align: end">
                        <span>おまかせ請求：</span>
                    </a-col>
                    <a-col :span="16">
                        <span>{{ form.settlementProxy.use ? '対象' : '対象外' }}</span>
                    </a-col>
                </a-row>
            </div>

            <div v-if="existsGuarantee && form && form.guarantee" class="guarantee-summary">
                <div class="summary__title">運賃全額保証サービスのご利用状況</div>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :span="24">
                        <div class="guarantee-usage">
                            <a-row :gutter="[16, 16]">
                                <!-- 保証対象金額 -->
                                <a-col :span="12">
                                    <div class="guarantee-usage__title">保証対象金額（今月）</div>
                                    <a-descriptions size="small" bordered :column="1" class="guarantee-usage__descriptions">
                                        <a-descriptions-item>
                                            <template #label>
                                                <div class="descriptions-item-title">現在の保証対象金額（累計）</div>
                                            </template>
                                            <div class="descriptions-item-amount">{{ usedGuaranteedAmount.format('', ' 円') }}</div>
                                        </a-descriptions-item>

                                        <a-descriptions-item>
                                            <template #label>
                                                <div class="descriptions-item-title">今回の保証対象金額（今回の運賃）</div>
                                            </template>
                                            <div class="descriptions-item-amount">{{ newGuaranteedAmount.format('', ' 円') }}</div>
                                        </a-descriptions-item>

                                        <a-descriptions-item>
                                            <template #label>
                                                <div class="descriptions-item-title">合計</div>
                                            </template>
                                            <div class="descriptions-item-amount">{{ usedGuaranteedAmount.addAll(newGuaranteedAmount).format('', ' 円') }}</div>
                                        </a-descriptions-item>
                                    </a-descriptions>
                                </a-col>
                                <!-- 運賃保証サービス料 -->
                                <a-col :span="12">
                                    <div class="guarantee-usage__title">運賃保証サービス料（今月）</div>
                                    <a-descriptions size="small" bordered :column="1" class="guarantee-usage__descriptions">
                                        <a-descriptions-item>
                                            <template #label>
                                                <div class="descriptions-item-title">現在の運賃保証サービス料</div>
                                            </template>
                                            <div class="descriptions-item-amount">{{ usedServiceFee.format('', ' 円') }}</div>
                                        </a-descriptions-item>

                                        <a-descriptions-item>
                                            <template #label>
                                                <div class="descriptions-item-title">今回ご利用後の運賃保証サービス料</div>
                                            </template>
                                            <div class="descriptions-item-amount">{{ totalServiceFee.format('', ' 円') }}</div>
                                        </a-descriptions-item>
                                    </a-descriptions>
                                </a-col>
                            </a-row>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </section>
    </div>
</template>

<style scoped lang="less">
.summary__title {
    margin-bottom: 8px;
    font-weight: bold;
}

.emphasis-text {
    display: inline-block;
    padding: 0 4px;
    background-color: @brand-color-trabox-yellow;
    font-weight: bold;
}

.description-text {
    margin: 0;
    white-space: pre-wrap;
}

.agreement-summary {
    margin-top: 24px;
    margin-bottom: 24px;
}

.agreement-alert {
    &__icon {
        color: @ui-color-heading-text;
    }

    &__message {
        margin: 0;
        padding: 8px 0 0;
        font-size: 14px;
        color: @ui-color-heading-text;
    }

    &__description {
        margin: 0;
        color: @ui-color-text;
        font-weight: bold;
    }
}

.guarantee-summary {
    margin-top: 16px;
}

// typescript/components/Agreement/GuaranteeUsage/style.less
.guarantee-usage__title {
    font-size: 12px;
    font-weight: bold;
}

.guarantee-usage__title + .guarantee-usage__descriptions {
    margin-top: 8px;
}

.descriptions-item-title {
    font-size: 12px;
    text-align: center;
}

.descriptions-item-amount {
    text-align: right;
}

.guarantee-usage__descriptions {
    ::v-deep(.ant-descriptions-view table tbody tr) {
        th {
            width: 224px;
            padding-left: 0;
            padding-right: 0;
        }

        td {
            padding-left: 0;
        }
    }
}
</style>
