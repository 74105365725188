import { useFormModel } from '@/composables/form-helper';
import { useAccountPasswordRemind } from '@/composables/account-password-remind';
import { useNotification } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';
import { Const } from '@/const';
import { onMounted, ref } from 'vue';

export const useAccountPasswordRemindHelper = () => {
    const { state: { loading, form, formValidateRules }, remind } = useAccountPasswordRemind();
    const supportDesk = Const.supportDeskContacts;
    const { submit, formModel } = useFormModel();
    const emailInput = ref<HTMLInputElement>();
    const { goToAccountPasswordRemindComplete, goToLogin } = useRouting();
    const notification = useNotification();

    const onSubmit = () => submit(async () => {
        const failedNotificationKey = 'REMIND_FAILED';

        notification.close(failedNotificationKey);
        try {
            await remind();
            await goToAccountPasswordRemindComplete();
        } catch {
            notification.error({
                key: failedNotificationKey,
                message: 'パスワード再設定の送信に失敗しました。',
                description: '入力内容をご確認のうえ、再度お試しください。',
            });
        }
    });

    const onClickBackToLogin = async () => {
        await goToLogin();
    };

    onMounted(() => {
        emailInput.value?.focus();
    });

    return {
        loading,
        form,
        formValidateRules,
        formModel,
        emailInput,
        supportDesk,
        onSubmit,
        onClickBackToLogin,
    };
};
