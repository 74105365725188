import { computed, onBeforeMount, ref, VNode } from 'vue';
import { useAgreement } from '@/composables/agreement';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import {
    useCompanyDispatchedDriverList,
    useCompanyDispatchedTruckList,
    useCompanyDispatchHistoryRegister,
    useCompanyDispatchHistoryRemove
} from '@/composables/company-dispatched';
import { useCompanyProfile } from '@/composables/company-profile';
import { useCompanyConfidence } from '@/composables/company-confidence';
import { useCompanyStatistics } from '@/composables/company-statistics';
import { useNotification } from '@/composables/helper/page-helper';
import _ from 'lodash';
import { useOfficialCompany } from '@/composables/company-official';

export const useAgreementPrintHelper = (agreementId: number) => {
    const notification = useNotification();

    const { state: { agreement }, load: loadAgreement } = useAgreement();
    const { state: { myProfile, myCompanyId }, load: loadMyProfile } = useCompanyMyProfile();
    const { state: { officialCompany: partnerOfficialCompany }, load: loadPartnerOfficialCompany } = useOfficialCompany();
    const {
        state: { list: dispatchedTruckList },
        load: loadCompanyDispatchedTruckList
    } = useCompanyDispatchedTruckList();
    const {
        state: { list: dispatchedDriverList },
        load: loadCompanyDispatchedDriverList
    } = useCompanyDispatchedDriverList();
    const { state: { form: dispatchForm }, register: registerDispatchHistory } = useCompanyDispatchHistoryRegister();
    const { remove: removeDispatchHistory } = useCompanyDispatchHistoryRemove();
    const {
        state: { profile: partnerProfile },
        load: loadPartnerProfile
    } = useCompanyProfile();
    const {
        state: { confidence: partnerConfidence },
        load: loadPartnerConfidence
    } = useCompanyConfidence();
    const {
        state: { statistics: partnerStatistics },
        load: loadPartnerStatistics
    } = useCompanyStatistics();
    const dispatchCompanyName = ref('');
    const informationComment = ref('');

    /**
     * 私は運送会社ですか？
     */
    const iAmTransporter = computed(() => agreement.value?.truckCompanyId === myCompanyId.value);

    const truckCompany = computed(() => {
        if (iAmTransporter.value) return myProfile.value;
        return partnerProfile.value;
    });
    const baggageCompany = computed(() => {
        if (iAmTransporter.value) return partnerProfile.value;
        return myProfile.value;
    });

    const dispatchedCarNumberList = computed(() => {
        if (!iAmTransporter.value) return [];
        return dispatchedTruckList.value?.carNumbers ?? [];
    });
    const dispatchedDriverNameList = computed(() => {
        if (!iAmTransporter.value) return [];
        return dispatchedDriverList.value?.driverNames ?? [];
    });

    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    const onClickPrint = async () => {
        // 操作者が運送会社である場合、配車履歴を保存する
        if (iAmTransporter.value) {
            await registerDispatchHistory();
        }
        // 印刷ダイアログを表示する
        window.print();
    };

    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    const onClickClose = () => {
        window.close();
    };

    /**
     * サジェストの項目と入力文字列を照合して絞り込みを行います。
     */
    const filterOption = (input: string, option: VNode): boolean => {
        const text = option.key;
        if (!text) {
            return false;
        }
        return _.isString(text) && text.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    /**
     * サジェストの削除ボタンが押下された際に呼び出されます。
     */
    const onClickDelete = async (type: 'carNumber' | 'driverName', value: string, event: MouseEvent) => {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        const failedNotificationKey = 'DELETE_DISPATCH_HISTORY_ERROR';
        notification.close(failedNotificationKey);

        const onError = (err: unknown) => {
            notification.error({
                key: failedNotificationKey,
                message: '履歴を削除できませんでした。',
                description:
                    '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            return Promise.reject(err);
        };

        // 削除APIを実行
        switch (type) {
            case 'carNumber':
                await removeDispatchHistory({ carNumber: value }).catch(onError);
                // 車番情報を再読み込み
                await loadCompanyDispatchedTruckList();
                break;
            case 'driverName':
                await removeDispatchHistory({ driverName: value }).catch(onError);
                // ドライバー情報を再読み込み
                await loadCompanyDispatchedDriverList();
                break;
            default:
                throw new Error(`given type is not defined. [type] => ${ type }`);
        }
    };

    onBeforeMount(async () => {
        await Promise.all([
            loadAgreement(agreementId),
            loadMyProfile(),
            loadCompanyDispatchedTruckList(),
            loadCompanyDispatchedDriverList(),
        ]);
        const partnerCompanyId = myProfile.value?.id === agreement.value?.baggageCompanyId ? agreement.value?.truckCompanyId : agreement.value?.baggageCompanyId;
        if (!partnerCompanyId) throw new Error();
        await Promise.all([
            loadPartnerProfile(partnerCompanyId),
            loadPartnerConfidence(partnerCompanyId),
            loadPartnerStatistics(partnerCompanyId),
            loadPartnerOfficialCompany(partnerCompanyId).catch(() => false),
        ]);
        if (iAmTransporter.value) {
            // 操作者が運送会社である場合、自社名をデフォルトで設定する
            dispatchCompanyName.value = truckCompany.value?.companyName ?? '';
        }
    });

    return {
        onClickPrint,
        onClickClose,
        dispatchForm,
        agreement,
        truckCompany,
        baggageCompany,
        dispatchCompanyName,
        filterOption,
        onClickDelete,
        dispatchedCarNumberList,
        dispatchedDriverNameList,
        informationComment,
        partnerProfile,
        partnerOfficialCompany,
        partnerConfidence,
        partnerStatistics,
    };
};
