import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import store from '@/vuex/store';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import UiDrawerLayout from '@/components/UI/Layouts/DrawerLayout';
// @ts-ignore
import CompanySummary from '@/components/Company/Summary';
import { Karte } from '@/karte';
import { gtm } from '@/gtm';

const companyMod = namespace('company');

@Component({
    components: {
        UiDrawerLayout,
        CompanySummary,
    },
})
export default class TruckCompanyDetailDrawerContent extends Vue {
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.CONFIDENCE)
    readonly CONFIDENCE?: companyTypes.CompanyConfidence;
    @companyMod.Getter(companyTypes.GETTER.STATISTICS)
    readonly STATISTICS?: companyTypes.CompanyStatistics;

    @Prop()
    declare readonly companyId?: number;
    @Prop()
    declare readonly truckId?: number;
    @Prop()
    declare readonly baggageId?: string;

    loading = true;

    /**
     * 企業名を取得します。
     */
    get companyName(): string {
        return this.PROFILE?.name.kanji ?? '';
    }

    async created(): Promise<void> {
        if (!this.companyId) {
            return;
        }
        await TruckCompanyDetailDrawerContent.loadCompanyDetail(this.companyId)
            .catch((res) => {
                this.$notification.error({
                    message: '企業情報の読み込みに失敗しました。',
                    description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                });
                return Promise.reject(res);
            })
            .finally(() => (this.loading = false));
    }

    async destroyed(): Promise<void> {
        await TruckCompanyDetailDrawerContent.clearCompanyDetail();
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        this.$emit('close');
    }

    /**
     * ドロワーの印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrint(): void {
        if (!this.companyId) {
            return;
        }
        const route = this.$router.resolve({ name: 'CompanyPrint', params: { companyId: `${this.companyId}` } });
        window.open(route.href, '_blank');
    }

    /**
     * 電話番号を表示するボタンが押下された際に呼び出されます。
     */
    onClickContact(): void {
        if (!this.truckId || !this.baggageId) return;
        const id = this.truckId;
        const baggageId = Number(this.baggageId);
        Karte.trackClickTruckRecommendContact(id, baggageId);
        gtm.setEvent('open_truck_contacts', { id, recommend_baggage_id: baggageId });
    }

    /**
     * 企業詳細情報（プロフィール、信用情報、ご利用情報）をロードします。
     */
    private static loadCompanyDetail(companyId: number): Promise<void[]> {
        return Promise.all([
            TruckCompanyDetailDrawerContent.loadProfile(companyId),
            TruckCompanyDetailDrawerContent.loadCompanyConfidence(companyId),
            TruckCompanyDetailDrawerContent.loadCompanyStatistics(companyId),
        ]);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadProfile(companyId: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_PROFILE}`, companyId);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadCompanyConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONFIDENCE}`, companyId);
    }

    /**
     * 企業のご利用情報をロードします。
     */
    private static loadCompanyStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_STATISTICS}`, companyId);
    }

    /**
     * 企業詳細情報（プロフィール、信用情報、ご利用情報）をクリアします。
     */
    private static clearCompanyDetail(): Promise<void[]> {
        return Promise.all([
            TruckCompanyDetailDrawerContent.clearProfile(),
            TruckCompanyDetailDrawerContent.clearCompanyConfidence(),
            TruckCompanyDetailDrawerContent.clearCompanyStatistics(),
        ]);
    }

    /**
     * 企業プロフィールをクリアします。
     */
    private static clearProfile(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.CLEAR_PROFILE}`);
    }

    /**
     * 企業信用情報をクリアします。
     */
    private static clearCompanyConfidence(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.CLEAR_CONFIDENCE}`);
    }

    /**
     * 企業のご利用情報をクリアします。
     */
    private static clearCompanyStatistics(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.CLEAR_STATISTICS}`);
    }
}
