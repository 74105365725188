<script setup lang="ts">
import _ from 'lodash';
import { QuestionItemAnswerFormModel } from '@/models/questionnaire';

const props = defineProps<{
    value: QuestionItemAnswerFormModel
}>();

const emits = defineEmits<{
    (e: 'input', value: QuestionItemAnswerFormModel): void,
}>();

/**
 * 複数選択プルダウンメニューのチェックボックスを変更した際に呼び出されます。
 */
const onInputMultiSelect = (value: Array<string>) => {
    const cloned = _.cloneDeep(props.value);
    cloned.value = value;
    emits('input', cloned);
};
/**
 * 単一選択プルダウンメニューの値を変更した際に呼び出されます。
 */
const onChangeSingleSelect = (value: string) => {
    const cloned = _.cloneDeep(props.value);
    cloned.value = value;
    emits('input', cloned);
};
/**
 * 自由記述欄を入力した際に呼び出されます。
 */
const onInputFreeText = (event: InputEvent, optionId?: string) => {
    const cloned = _.cloneDeep(props.value);
    cloned.setFreeText(event, optionId);
    emits('input', cloned);
};
</script>

<template>
    <div>
        <!-- type: multi-select -->
        <template v-if="value.question.type === 'multiple-select'">
            <a-checkbox-group style="width: 100%"
                              :checked="value.value"
                              @input="onInputMultiSelect">
                <a-row v-for="(option) in value.question.options" :key="option.id">
                    <a-col :span="24">
                        <a-checkbox :value="option.id"
                                    :name="value.name">
                            {{ option.text }}
                            <template v-if="option.allowsFreeText">（以下に記入をお願いします）</template>
                        </a-checkbox>
                        <div class="question-freetext" v-if="option && option.allowsFreeText">
                            <a-input :value="value.freeText(option.id)"
                                     :disabled="value.isDisabledFreeText(option.id)"
                                     @input="onInputFreeText($event, option.id)"
                                     :placeholder="option.text + 'の内容を入力してください（200文字以内）'"></a-input>
                        </div>
                    </a-col>
                </a-row>
            </a-checkbox-group>
        </template>

        <!-- type: single-select -->
        <template v-if="value.question.type === 'single-select'">
            <a-row>
                <a-col :span="24">
                    <a-select :value="value.value"
                              @change="onChangeSingleSelect"
                              :placeholder="value.question.placeholder ? value.question.placeholder : '選択してください'">
                        <a-select-option v-for="option in value.question.options"
                                         :key="option.id"
                                         :value="option.id"
                                         :index="value.index">{{ option.text }}
                            <template v-if="option.allowsFreeText">（以下に記入をお願いします）</template>
                        </a-select-option>
                    </a-select>
                    <div class="question-freetext" v-if="!value.isDisabledFreeText()">
                        <a-input :value="value.freeText()"
                                 @input="onInputFreeText($event)"
                                 placeholder="内容を入力してください（200文字以内）"></a-input>
                    </div>
                </a-col>
            </a-row>
        </template>

        <!-- type: text -->
        <template v-if="value.question.type === 'text'">
            <a-row>
                <a-col :span="24">
                    <a-textarea :value="value.freeText()"
                                @input="onInputFreeText($event)"
                                :placeholder="value.question.placeholder ? value.question.placeholder : '200文字以内で入力してください'"
                                :auto-size="{ minRows: 5, maxRows: 20 }"/>
                </a-col>
            </a-row>
        </template>
    </div>
</template>

<style scoped lang="less">
.question-freetext {
    margin-top: 8px;
    padding-left: 28px;
    min-width: 320px;

    .ant-input.ant-input-disabled {
        background-color: @input-disabled-bg;
        border-color: @ui-color-border;
    }
}
</style>
