import { GetterTree, Module, MutationTree } from 'vuex';
import {
    EntitlementModel,
    EntitlementState,
    GETTER as g,
    MUTATION as m,
} from './types';

const state: EntitlementState = {
    entitlement: undefined,
};

const getters: GetterTree<EntitlementState, void> = {
    /**
     * 機能権限を取得します。
     */
    [g.ENTITLEMENT]: (s) => s.entitlement,
};

const mutations: MutationTree<EntitlementState> = {
    /**
     * 機能権限を設定します。
     *
     * @param entitlement 機能権限
     */
    [m.SET_ENTITLEMENT]: (s, entitlement: EntitlementModel) => (s.entitlement = entitlement),
};

export const entitlement: Module<EntitlementState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
};
