import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { Const } from '@/const';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
import * as accountTypes from '@/vuex/modules/account/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { Baggage, BaggageList } from '@/vuex/modules/baggage/types';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { TruckList } from '@/vuex/modules/truck/types';
import store from '@/vuex/store';
// @ts-ignore
import UiDrawerLayout from '@/components/UI/Layouts/DrawerLayout';
// @ts-ignore
import CompanyDetail from '@/components/Company/View';
// @ts-ignore
import CompanySummary from '@/components/Company/Summary';
// @ts-ignore
import CompanyBaggage from '@/components/Company/Baggage';
// @ts-ignore
import BaggageView from '@/components/Baggage/View';
// @ts-ignore
import CompanyTruck from '@/components/Company/Truck';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
import { Karte } from '@/karte';
import { PageUtil } from '@/util';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

const companyMod = namespace('company');
const baggageMod = namespace('baggage');
const accountMod = namespace('account');

@Component({
    title: '企業検索',
    components: {
        TbxLinkText,
        UiDrawerLayout,
        CompanyDetail,
        CompanySummary,
        CompanyBaggage,
        CompanyTruck,
        BaggageView,
        UiCityInput,
        UiPrefectureSelect,
    },
    beforeRouteEnter: CompanySearchPage.beforeRouteEnter,
})
export default class CompanySearchPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.PROFILE_SEARCH_LIST)
    readonly PROFILE_SEARCH_LIST?: types.CompanyProfileList;
    @companyMod.Getter(types.GETTER.PROFILE_SEARCH_FORM)
    readonly PROFILE_SEARCH_FORM!: types.CompanyProfileSearchForm;
    @companyMod.Getter(types.GETTER.PROFILE)
    readonly PROFILE?: types.CompanyProfile;
    @companyMod.Getter(types.GETTER.CONFIDENCE)
    readonly CONFIDENCE?: types.CompanyConfidence;
    @companyMod.Getter(types.GETTER.STATISTICS)
    readonly STATISTICS?: types.CompanyStatistics;
    @companyMod.Getter(types.GETTER.BAGGAGE_LIST)
    readonly BAGGAGE_LIST?: BaggageList;
    @companyMod.Getter(types.GETTER.TRUCK_LIST)
    readonly TRUCK_LIST?: TruckList;
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly MY_PROFILE?: accountTypes.Profile;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly companyId?: string;

    /**
     * 企業詳細ドロワーの表示状態を取得します。
     */
    get drawerVisibility(): boolean {
        return !_.isNaN(Number(this.companyId));
    }

    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get baggageDrawerVisibility(): boolean {
        return _.has(this.$route.query, 'baggageId');
    }

    /**
     * 現在詳細Drawerで開いているCompanyIdを取得します。
     */
    get activeCompanyId(): string | null {
        const activeCompanyId = this.$route.query.companyId;
        if (Array.isArray(activeCompanyId)) {
            return activeCompanyId[activeCompanyId.length - 1];
        }
        return activeCompanyId;
    }

    get companyName(): string {
        return this.PROFILE?.name.kanji ?? '';
    }

    get myCompanyId(): number {
        return this.MY_PROFILE?.companyId ?? 0;
    }

    get prefectureCode(): string | undefined {
        return this.form.pref?.code;
    }

    set prefectureCode(newValue: string | undefined) {
        const changed = this.form.pref?.code !== newValue;

        this.form.pref = newValue ? { code: newValue } : undefined;
        this.form.city = changed ? '' : this.form.city;
    }

    get city(): string {
        return this.form.city ?? '';
    }

    set city(newValue: string) {
        this.form.city = newValue.substr(0, 200);
    }

    get paginationVisibility(): boolean {
        return this.PROFILE_SEARCH_LIST ? (this.PROFILE_SEARCH_LIST.data.length > 0) : false;
    }

    // ======================================================
    // Data
    // ======================================================
    routeUpdating = false;
    guaranteeAppliedCompaniesUrl = Const.externalPageUrl.guaranteeAppliedCompanies;
    form: types.CompanyProfileSearchForm = {
        keyword: '',
        pref: undefined,
        city: '',
        pageNo: 1,
        pageSize: 50,
    };

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 選択した企業のDrawerが開いているか否かを取得します。
     * @param companyId
     */
    isSelectedCompany(companyId: number): boolean {
        return this.activeCompanyId === `${companyId}`;
    }

    /**
     * 検索ボタンが押下された際に呼び出されます。
     */
    async onClickSearch(): Promise<void> {
        await CompanySearchPage.searchProfile({ ...this.form, pageNo: 1 });
        await CompanySearchPage.setProfileSearchForm(_.cloneDeep(this.form));
        // KARTEイベント送信： 企業検索
        Karte.trackSearchCompany();
    }

    /**
     * ページネーション操作が行われた際に呼び出されます。
     */
    async onChangePage(pageNo: number): Promise<void> {
        this.form = _.cloneDeep(this.PROFILE_SEARCH_FORM);
        this.form.pageNo = pageNo;
        await CompanySearchPage.searchProfile(this.form);
        PageUtil.scrollToContentTop();
    }

    /**
     * 行が押下された際に呼び出されます。
     */
    async onClickCompany(event: Event, companyId: string): Promise<void> {
        // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
        event.stopPropagation();
        await this.$router.push({ path: this.$route.path, query: { companyId } });
    }

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        // 連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            return;
        }
        // Drawerが開いている場合は閉じる
        if (this.drawerVisibility) {
            await this.onClickCloseDrawer();
        }
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        await this.$router.push({ path: this.$route.path });
    }

    /**
     * 荷物が押下された際に呼び出されます。
     */
    async onClickBaggage(event: Event, baggage: Baggage): Promise<void> {
        await this.$router.push({
            path: this.$route.path,
            query: { companyId: `${ baggage.companyId }`, baggageId: `${ baggage.id }` }
        });
    }

    /**
     * 荷物ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseBaggageDrawer(): Promise<void> {
        await this.$router.push({
            path: this.$route.path,
            query: { companyId: `${ this.companyId }` }
        });
    }

    /**
     * 成約が押下された際に呼び出されます。
     */
    async onClickAgree(e: Event, baggage: Baggage): Promise<void> {
        e.cancelBubble = true;

        await this.$router.push({ name: 'AgreementRegister', params: { id: `${baggage.id}` } });
    }

    /**
     * ドロワーの印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrint(): void {
        if (!this.companyId) {
            return;
        }
        const route = this.$router.resolve({ name: 'CompanyPrint', params: { companyId: this.companyId } });
        window.open(route.href, '_blank');
    }

    /**
     * 「成約」ボタンを表示すべきか否かを取得します。
     */
    shouldShowAgreeButton(record?: Baggage): boolean {
        if (!record) return false;

        const status = BaggageStatusEnum.valueOf(record.status.code);
        if (!status) return false;

        // 公開中の他社荷物のみ表示
        return status.isOpened() && record.companyId !== this.myCompanyId;
    }

    /**
     * ステータスラベルを表示すべきか否かを取得します。
     */
    shouldShowStatusLabel(record?: Baggage): boolean {
        if (!record) return false;

        const status = BaggageStatusEnum.valueOf(record.status.code);
        if (!status) return false;

        return status.isClosed();
    }

    created(): void {
        this.form = _.cloneDeep(this.PROFILE_SEARCH_FORM);
    }

    async beforeRouteUpdate(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<CompanySearchPage>
    ): Promise<void> {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            next(false);
            return;
        }
        //
        // ドロワー表示データ処理
        //
        try {
            const { companyId, baggageId } = to.query;
            this.routeUpdating = true;
            if (companyId && companyId !== _from.query.companyId) {
                await CompanySearchPage.loadCompanyDetail(Number(companyId)).catch((err) => {
                    this.$message.error('アクセスしようとした企業情報は見つかりません。');
                    return Promise.reject(err);
                });
            }
            if (baggageId && baggageId !== _from.query.baggageId) {
                await CompanySearchPage.loadBaggage(Number(baggageId)).catch((err) => {
                    this.$message.error('アクセスしようとした荷物情報は見つかりません。');
                    return Promise.reject(err);
                });
            }
            next();
        } catch (err) {
            next(false);
        } finally {
            this.routeUpdating = false;
        }
    }

    /**
     * 選択した企業が切り替わった時に呼び出されます。
     */
    @Watch('companyId')
    private companyChanged(): void {
        // 選択した企業が切り替わったら、スクロール位置をリセットする
        this.scrollToContentTop();
    }

    /**
     * タブが切り替わった時に呼び出されます。
     */
    private onTabChange(): void {
        // タブが切り替わったら、スクロール位置をリセットする
        this.scrollToContentTop();
    }

    /**
     * 企業プロフィールを検索します。
     */
    private static searchProfile(form: types.CompanyProfileSearchForm): Promise<void> {
        return store.dispatch(`company/${types.ACTION.SEARCH_PROFILE}`, form);
    }

    /**
     * 企業検索フォームを設定します。
     */
    private static setProfileSearchForm(form: types.CompanyProfileSearchForm): Promise<void> {
        return store.dispatch(`company/${ types.ACTION.SET_PROFILE_SEARCH_FORM }`, form);
    }

    /**
     * 企業詳細情報（プロフィール、信用情報、ご利用情報）をロードします。
     */
    private static loadCompanyDetail(companyId: number): Promise<void[]> {
        return Promise.all([
            CompanySearchPage.loadProfile(companyId),
            CompanySearchPage.loadCompanyConfidence(companyId),
            CompanySearchPage.loadCompanyStatistics(companyId),
            CompanySearchPage.loadCompanyBaggageList(companyId),
            CompanySearchPage.loadCompanyTruckList(companyId),
        ]);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadProfile(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_PROFILE}`, companyId);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadCompanyConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_CONFIDENCE}`, companyId);
    }

    /**
     * 企業のご利用情報をロードします。
     */
    private static loadCompanyStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_STATISTICS}`, companyId);
    }

    /**
     * 企業の荷物一覧をロードします。
     */
    private static loadCompanyBaggageList(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_BAGGAGE_LIST}`, companyId);
    }

    /**
     * 企業の空車一覧をロードします。
     */
    private static loadCompanyTruckList(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_TRUCK_LIST}`, companyId);
    }

    /**
     * 企業の空車一覧をロードします。
     */
    private static loadBaggage(baggageId: number): Promise<void> {
        return store.dispatch(`baggage/${baggageTypes.ACTION.LOAD_BAGGAGE}`, baggageId);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<CompanySearchPage>
    ): Promise<void> {
        const { companyId, baggageId } = to.query;
        if (!companyId) {
            next();
            return;
        }

        if (baggageId) {
            await CompanySearchPage.loadBaggage(Number(baggageId)).catch(() => next({ name: 'CompanySearch' }));
        }
        await CompanySearchPage.loadCompanyDetail(Number(companyId)).catch(() => next({ name: 'CompanySearch' }));
        next();
    }

    //
    // Helper methods
    //

    private scrollToContentTop(): void {
        const component = this.$refs.companyDetailTabs as Vue | undefined;
        if (!component) {
            return;
        }
        const el = component.$el.getElementsByClassName('ant-tabs-content')[0] as HTMLDivElement | undefined;
        if (!el) {
            return;
        }
        el.scrollTop = 0;
    }
}
