import { InjectionKey } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { useSettlement } from '@/composables/settlement';
import { useSettlementUpdate } from '@/composables/settlement-update';
import { Util } from '@/util';
import { useNotificationExtension } from '@/composables/helper/notification-extension';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useEntitlement } from '@/composables/entitlement';

export const useSettlementEditorProvider = () => {
    const { state: { entitlement } } = useEntitlement();
    const { state: { loading }, withLoading } = useLoading();
    const { state: { myCompanyId } } = useCompanyMyProfile();
    const { state: { settlement, partner }, load: loadData, clear } = useSettlement();
    const { state: { form, validationRules, dirty, changeType }, initForm, update } = useSettlementUpdate();

    const { close, sorryNotification } = useNotificationExtension('UPDATE_SETTLEMENT_FAILED');

    const load = (id: number) => withLoading(async () => {
        await loadData(id);
        if (!settlement.value) {
            // 読み込み失敗
            close();
            sorryNotification({ message: '取引情報の取得ができませんでした。', });
            throw new Error();
        }
        if (settlement.value?.payeeCompanyId !== myCompanyId.value) {
            // 入金企業でない場合はNG
            sorryNotification({ message: '取引情報の変更権限がありません。', });
            throw new Error();
        }
        if (!settlement.value.isReadyToUpdate) {
            // 現在申請中の場合はNG
            sorryNotification({ message: '現在、変更申請中です。', });
            throw new Error();
        }
        initForm(Util.requireNotNull(settlement.value));
    });

    const request = () => withLoading(async () => {
        const settlementId = Util.requireNotNull(settlement.value?.id);
        try {
            await update(settlementId);
        } catch (e) {
            close();
            sorryNotification({ message: '取引変更申請に失敗しました。', });
            throw e;
        }
    });

    return {
        state: {
            entitlement,
            loading,
            settlement,
            partner,
            form,
            validationRules,
            dirty,
            changeType,
        },
        load,
        clear,
        request,
    };
};

export type SettlementEditorProviderType = ReturnType<typeof useSettlementEditorProvider>;
export const Settlement_EDITOR_PROVIDER_KEY: InjectionKey<SettlementEditorProviderType> = Symbol('SettlementEditorProvider');
