import { ref } from 'vue';
import { CompanyProfile } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';

/**
 * 企業プロフィールをロードする機能を提供します。
 */
export const useCompanyProfile = () => {
    const profile = ref<CompanyProfile | undefined>(undefined);
    const loading = ref<boolean>(false);

    const load = async (companyId: number) => {
        try {
            loading.value = true;
            const data = await companyApi.profile(companyId);
            profile.value = data;
        } finally {
            loading.value = false;
        }
    };

    const clear = () => {
        profile.value = undefined;
    };

    return {
        state: {
            profile,
            loading,
        },
        load,
        clear,
    };
};

/**
 * パートナー企業プロフィールをロードする機能を提供します。
 */
export const usePartnerCompanyProfile = () => {
    const profile = ref<CompanyProfile | undefined>(undefined);
    const loading = ref<boolean>(false);

    /**
     * 成約のパートナー企業情報をロードします。
     * @param agreementId
     */
    const load = async (agreementId: number) => {
        try {
            loading.value = true;
            const data = await companyApi.partnerProfile(agreementId);
            profile.value = data;
        } finally {
            loading.value = false;
        }
    };

    return {
        state: {
            profile,
            loading,
        },
        load,
    };
};
