<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import PaginationControl from '@/_components/ui/PaginationControl.vue';
import DateTimeLocationLabel from '@/_components/ui/DateTimeLocationLabel.vue';
import { useTruckListHelper } from '@/_pages/Truck/List/truck-list-helper';
import { columns } from './table-definition';

const {
    loading,
    pageInfo,
    searchResultsRef,
    truckListData,
    circleName,
    onClickEdit,
    onClickCopy,
    onClickClose,
    onChangePage,
} = useTruckListHelper();
</script>
<template>
    <page-layout :show-header="false" :back-icon="false">
        <div class="board-container">
            <a-card ref="searchResultsRef" class="search-result-container" :body-style="{ padding: 0 }">
                <a-spin :spinning="loading">
                    <a-card :bodyStyle="{ padding: 0 }">
                        <div class="table-nav-pagination">
                            <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                        <a-table class="app-result-list-table"
                                 bordered
                                 :columns="columns"
                                 :data-source="truckListData"
                                 row-key="id"
                                 :pagination="false"
                                 :row-class-name="() => 'app-table-row'">
                            <template v-slot:staffName="text, record">
                                <div class="table-row-ellipsis-text">{{ record.staffName }}</div>
                            </template>
                            <template v-slot:departureArrival="text, record">
                                <date-time-location-label :departure-min="record.departureMin"
                                                          :departure-max="record.departureMax"
                                                          :departure-pref="record.departurePrefecture"
                                                          :departure-city="record.departureCity"
                                                          :arrival-min="record.arrivalMin"
                                                          :arrival-max="record.arrivalMax"
                                                          :arrival-pref="record.arrivalPrefecture"
                                                          :arrival-city="record.arrivalCity">
                                    <template #departureAdditional>
                                        <a-tooltip
                                            :title="record.departureAreaText">
                                            <div class="additional-prefecture-text"
                                                 v-if="record.additionalDeparturePrefectureText">
                                                （{{ record.additionalDeparturePrefectureText }}）
                                            </div>
                                        </a-tooltip>
                                    </template>
                                    <template #arrivalAdditional>
                                        <a-tooltip
                                            :title="record.arrivalAreaText">
                                            <div class="additional-prefecture-text"
                                                 v-if="record.additionalArrivalPrefectureText">
                                                （{{ record.additionalArrivalPrefectureText }}）
                                            </div>
                                        </a-tooltip>
                                    </template>
                                </date-time-location-label>
                            </template>
                            <template v-slot:freight="text, record">
                                <span class="freight-text">{{ record.freight.format() }}</span>
                            </template>
                            <template v-slot:truckWeight="text, record">
                                <span class="table-row-ellipsis-text">{{ record.truckWeight.label ?? '' }}</span>
                            </template>
                            <template v-slot:truckModel="text, record">
                                <span class="table-row-ellipsis-text">{{ record.truckModel.label ?? '' }}</span>
                            </template>
                          <template v-slot:circle="text, record">
                            <span class="table-row-ellipsis-text">{{ circleName(record.circleId) }}</span>
                          </template>
                            <template v-slot:description="text, record">
                                <a-tooltip :title="record.description" placement="bottom"
                                           :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                                    <div class="table-row-ellipsis-text">{{ record.description }}</div>
                                </a-tooltip>
                                <!-- NOTE: 以下のアクションボタン群の要素は、テーブルセルの最も右端のcolumnに配置することでスタイルが当たるようになっている -->
                                <!-- CSS自体は ant-table 自体にもスタイルを当てる必要があったため、global.less に配置している -->
                                <nav class="app-table-row__actions">
                                    <ul>
                                        <li>
                                            <a-tooltip title="この空車の内容を変更します">
                                                <a-button icon="edit" @click="onClickEdit(record.id)">変更
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                        <li>
                                            <a-tooltip title="この空車を元にして新しく空車を登録します">
                                                <a-button icon="copy" @click="onClickCopy(record.id)">コピー
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                        <li>
                                            <a-tooltip placement="topRight" title="この空車情報を削除します">
                                                <a-button icon="delete"
                                                          type="danger"
                                                          ghost
                                                          @click="onClickClose(record.id)">削除
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                    </ul>
                                </nav>
                            </template>
                        </a-table>
                        <div class="table-nav-pagination">
                            <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                    </a-card>
                </a-spin>
            </a-card>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
 //pages/Truck/List/style.less
.board-container {
    padding: 24px;
}

// components/Truck/View/List/style.less
.table-nav-pagination {
    padding: 24px;
    background: @component-background;

    .ant-pagination {
        display: flex;
        justify-content: flex-end;

        > .ant-pagination-options {
            order: 1;
        }

        > :not(.ant-pagination-options) {
            order: 2;
        }
    }
}

.freight-text {
    font-weight: bold;
}

.additional-prefecture-text {
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 12px;
    max-height: 36px;
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

</style>
