import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaggageSearchConditionEditToggleButton extends Vue {
    @Prop()
    declare readonly value?: boolean;

    onClick(): void {
        this.$emit('input', !this.value);
    }
}
