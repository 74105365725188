import { FormModel, Modal } from 'ant-design-vue';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import * as companyTypes from '@/vuex/modules/company/types';
import * as questionnaireTypes from '@/vuex/modules/questionnaire/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import { ModalUtil, QuestionnaireUtil } from '@/util';
// @ts-ignore
import QuestionnaireForm from '@/components/Questionnaire/QuestionnaireForm';
// @ts-ignore
import RevokeLayout from '@/components/Setting/RevokePremiumPlan/RevokeLayout';
import type { QuestionItemAnswerValue } from '@/components/Questionnaire/QuestionnaireForm/script';
import * as accountTypes from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/models/account-profile';
import {
    CompanyContractListModel,
} from '@/models/company-contract-list';
import {
    getRevocableReason,
    RevocableReason,
} from '@/pages/Setting/RevokePremiumPlan/revoke-premium-plan-helpers';

const accountMod = namespace('account');
const companyMod = namespace('company');
const questionnaireMod = namespace('questionnaire');

@Component({
    title: 'プレミアムプランの停止',
    components: {
        QuestionnaireForm,
        RevokeLayout,
    },
    beforeRouteEnter: RevokePremiumPlanEditPage.beforeRouteEnter,
})
export default class RevokePremiumPlanEditPage extends PageVue {
    // ======================================================
    // Data
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @questionnaireMod.Getter(questionnaireTypes.GETTER.QUESTIONNAIRE)
    readonly QUESTIONNAIRE?: questionnaireTypes.Questionnaire;

    loading = false;
    dirty = false;
    underSubmitting = false;

    formModel = {
        answers: [] as QuestionItemAnswerValue[],
    };

    /**
     * プレミアムプランの解約ができる状態か否かを取得します。
     */
    get canRevokePremiumPlan(): RevocableReason {
        // stateの更新がページに反映されてしまうのを防止
        if (this.underSubmitting) return { result: true };
        return getRevocableReason(this.ACCOUNT_PROFILE, this.CONTRACT_LIST);
    }

    /**
     * 設問を取得します。
     */
    get questions(): questionnaireTypes.QuestionItem[] {
        return this.QUESTIONNAIRE?.items ?? [];
    }

    /**
     * 回答を取得します。
     */
    get answers(): QuestionItemAnswerValue[] {
        return this.formModel.answers;
    }

    /**
     * 回答を設定します。
     */
    set answers(value: QuestionItemAnswerValue[]) {
        this.formModel.answers = value;
        this.dirty = true;
    }

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.formModel = {
            answers: this.questions.map(QuestionnaireUtil.createAnswerForm),
        };
    }

    /**
     * 「戻る」ボタンを押下した際に呼び出されます。
     */
    onClickBack(): Promise<Route> {
        return this.$router.push({ name: 'RevokePremiumPlan' });
    }

    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    async onClickConfirm(): Promise<void> {
        const formModel = this.$refs.formModel as FormModel;
        const result = await new Promise((resolve) => formModel.validate((result) => resolve(result)));
        if (!result) {
            Modal.error({
                title: 'アンケートの中で正しく入力できていない項目があります。',
                content: '恐れ入りますが、アンケートの回答を今一度お確かめください。',
            });
            return;
        }
        Modal.confirm({
            width: 640,
            title: 'プレミアムプランのご利用を停止を行いますか？',
            content: '入力いただいた内容で送信されます。今一度ご確認ください。',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: 'プレミアムプランの利用を停止',
            okType: 'primary',
            cancelText: 'キャンセル',
            autoFocusButton: 'cancel',
            onOk: () => this.submit(),
        });
    }

    /**
     * 送信ボタンが押下された際に呼び出される。
     */
    async submit(): Promise<void> {
        const notifyFailedKey = 'REVOKE_PREMIUM_FAILED';
        const onSuccess = () => {
            this.dirty = false;
            this.$router.push({ name: 'RevokePremiumPlanComplete' });
        };
        const onError = () => {
            this.$notification.error({
                key: notifyFailedKey,
                message: 'プレミアムプランの利用停止手続きができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        };

        this.loading = true;
        this.underSubmitting = true;
        this.$notification.close(notifyFailedKey);

        const questionnaireAnswer = this.questions.map((each: questionnaireTypes.QuestionItem, index: number) => {
            const answer = this.formModel.answers[index];
            return {
                id: each.id,
                type: each.type,
                answer: Array.isArray(answer) ? answer.filter((each) => each !== undefined) : answer,
            } as questionnaireTypes.QuestionItemAnswer;
        });
        await RevokePremiumPlanEditPage.revokePremium({ questionnaireAnswer })
            .then(onSuccess)
            .catch(onError)
            .finally(() => (this.loading = false));
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<RevokePremiumPlanEditPage>
    ): Promise<void> {
        const onSuccess = () => next();
        const onError = () => next({ name: 'NotFound' });

        await RevokePremiumPlanEditPage.loadQuestionnaire().then(onSuccess).catch(onError);
    }

    async beforeRouteLeave(
        _to: VueRoute,
        _: VueRoute,
        next: NavigationGuardNext<RevokePremiumPlanEditPage>
    ): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    }

    /**
     * アンケート設問を取得します。
     */
    private static loadQuestionnaire(): Promise<void> {
        return store.dispatch(`questionnaire/${ questionnaireTypes.ACTION.LOAD_QUESTIONNAIRE }`, 'REVOKE_PREMIUM');
    }

    /**
     * プレミアムプランの停止を送信します。
     */
    private static revokePremium(param: companyTypes.CompanyPremiumRevokeForm): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.REVOKE_PREMIUM }`, param);
    }
}
