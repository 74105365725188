/**
 * トラックの車種Enum
 */
export type TruckModelEnumCode =
    | 'TM01'
    | 'TM02'
    | 'TM03'
    | 'TM04'
    | 'TM05'
    | 'TM06'
    | 'TM07'
    | 'TM08'
    | 'TM09'
    | 'TM10'
    | 'TM11'
    | 'TM12'
    | 'TM13'
    | 'TM14'
    | 'TM15'
    | 'TM16'
    | 'TM17'
    | 'MM01'
    | 'MM02';

export class TruckModelEnum {
    constructor(public code: TruckModelEnumCode, public label: string) {}

    static Flat = new TruckModelEnum('TM01', '平');
    static FlatWithLowFloor = new TruckModelEnum('TM02', '平-低床');
    static FlatWithPowerGate = new TruckModelEnum('TM03', '平-パワーゲート');
    static FlatWithAirSuspension = new TruckModelEnum('TM04', '平-エアサス');
    static Van = new TruckModelEnum('TM05', '箱');
    static VanWithLowFloor = new TruckModelEnum('TM06', '箱-低床');
    static VanWithPowerGate = new TruckModelEnum('TM07', '箱-パワーゲート');
    static VanWithAirSuspension = new TruckModelEnum('TM08', '箱-エアサス');
    static Wing = new TruckModelEnum('TM09', 'ウイング');
    static WingWithLowFloor = new TruckModelEnum('TM10', 'ウイング-低床');
    static WingWithPowerGate = new TruckModelEnum('TM11', 'ウイング-パワーゲート');
    static WingWithAirSuspension = new TruckModelEnum('TM12', 'ウイング-エアサス');
    static Unic = new TruckModelEnum('TM13', 'ユニック');
    static Freezer = new TruckModelEnum('TM14', '冷凍');
    static Refrigerator = new TruckModelEnum('TM15', '保冷');
    static Other = new TruckModelEnum('TM16', '他');
    static Unspecified = new TruckModelEnum('TM17', '車種問わず');
    static WingOrFlat = new TruckModelEnum('MM01', 'ウイング又は平');
    static WingOrVan = new TruckModelEnum('MM02', 'ウイング又は箱');

    static values = [
        TruckModelEnum.Flat,
        TruckModelEnum.FlatWithLowFloor,
        TruckModelEnum.FlatWithPowerGate,
        TruckModelEnum.FlatWithAirSuspension,
        TruckModelEnum.Van,
        TruckModelEnum.VanWithLowFloor,
        TruckModelEnum.VanWithPowerGate,
        TruckModelEnum.VanWithAirSuspension,
        TruckModelEnum.Wing,
        TruckModelEnum.WingWithLowFloor,
        TruckModelEnum.WingWithPowerGate,
        TruckModelEnum.WingWithAirSuspension,
        TruckModelEnum.Unic,
        TruckModelEnum.Freezer,
        TruckModelEnum.Refrigerator,
        TruckModelEnum.Other,
    ];
    static allValues = [
        TruckModelEnum.Unspecified,
        ...TruckModelEnum.values,
        TruckModelEnum.WingOrFlat,
        TruckModelEnum.WingOrVan,
    ];
    // 荷物で登録可能な値
    static registrableValuesForBaggage = [
        TruckModelEnum.Unspecified,
        ...TruckModelEnum.values,
        TruckModelEnum.WingOrFlat,
        TruckModelEnum.WingOrVan,
    ];
    // 空車で登録可能な値
    static registrableValuesForTruck = [...TruckModelEnum.values];

    static wingValues = [
        TruckModelEnum.Wing,
        TruckModelEnum.WingWithLowFloor,
        TruckModelEnum.WingWithPowerGate,
        TruckModelEnum.WingWithAirSuspension,
    ];

    static flatValues = [
        TruckModelEnum.Flat,
        TruckModelEnum.FlatWithLowFloor,
        TruckModelEnum.FlatWithPowerGate,
        TruckModelEnum.FlatWithAirSuspension,
    ];

    static vanValues = [
        TruckModelEnum.Van,
        TruckModelEnum.VanWithLowFloor,
        TruckModelEnum.VanWithPowerGate,
        TruckModelEnum.VanWithAirSuspension,
    ];

    static wingOrFlatValues = [...TruckModelEnum.wingValues, ...TruckModelEnum.flatValues];

    static wingOrVanValues = [...TruckModelEnum.wingValues, ...TruckModelEnum.vanValues];

    static valueOf = (code: TruckModelEnumCode): TruckModelEnum | undefined =>
        TruckModelEnum.allValues.find((value) => value.code === code);

    static truckValueOf = (code: TruckModelEnumCode): TruckModelEnum | undefined =>
        TruckModelEnum.registrableValuesForTruck.find((value) => value.code === code);
}
