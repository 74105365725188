import { onMounted, ref, watch } from 'vue';
import { DateUtil } from '@/util';
import _ from 'lodash';
import { DateValue } from '@/models/vo/date';
import { useNotification } from '@/composables/helper/page-helper';
import { useTransactionInvoice } from '@/composables/transaction';

export const useSettlementOutgoPaper = () => {
    const { loadList, loadTerm, download, downloadDetail, state: { list, loading, term } } = useTransactionInvoice();
    const notification = useNotification();

    const selectedYear = ref(DateUtil.now().year());

    const onClickDownload = async (target: DateValue) => {
        try {
            notification.success({ message: 'ダウンロードを開始しました。', description: '' });
            await download(target);
        } catch (e) {
            notification.error({ message: 'ダウンロードできませんでした。時間をおいて再度お試しください。', description: '' });
        }
    };

    const onClickDownloadDetail = async (target: DateValue, payeeCompanyId: number) => {
        try {
            notification.success({ message: 'ダウンロードを開始しました。', description: '' });
            await downloadDetail(target, payeeCompanyId);
        } catch (e) {
            notification.error({ message: 'ダウンロードできませんでした。時間をおいて再度お試しください。', description: '' });
        }
    };

    watch(selectedYear, async (newValue, oldValue) => {
        if (_.isNil(newValue) || newValue === oldValue) return;

        await loadList(newValue);
    }, { immediate: true });

    onMounted(async () => {
        await loadTerm();
    });

    return {
        loading,
        term,
        selectedYear,
        list,
        onClickDownload,
        onClickDownloadDetail,
    };
};
