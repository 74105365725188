import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { DateUtil } from '@/util';
import { Const } from '@/const';

@Component
export default class AgreementChangeLimit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;

    get changeLimit(): string {
        if (!this.agreement) return '';

        return DateUtil.parseDatetimeText(this.agreement.changeLimitTm).format(
            Const.DEFAULT_DATETIME_WITH_DAY_OF_WEEK_FORMAT
        );
    }
}
