<script setup lang="ts">
import { computed } from 'vue';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { DateUtil } from '@/util';

const props = defineProps<{
    dateTimeMin?: string;
    dateTimeMax?: string;
}>();

const deliveryDatetime = computed<DeliveryDateTimeRange | null>(() => {
    if (!props.dateTimeMin || !props.dateTimeMax) return null;
    return DeliveryDateTimeRange.of(props.dateTimeMin, props.dateTimeMax);
});

/**
 * フォーマットされた日付を取得します。
 */
const formatDate = computed<string>(() => deliveryDatetime.value?.date.format('M/D') ?? '');

/**
 * フォーマットされた曜日を取得します。
 */
const formatDayOfWeek = computed<string>(() => {
    const date = deliveryDatetime.value?.date;
    if (!date) {
        return '';
    }
    const label = date.format('ddd');
    return DateUtil.isHoliday(date) ? `${label}・祝` : label;
});

/**
 * フォーマットされた時間を取得します。
 */
const formatTime = computed<string>(() => {
    return deliveryDatetime.value?.formatTime() ?? '';
});

/**
 * 曜日表示テキストのスタイルCSS クラスを取得します
 */
const dayTextStyleClass = computed<string>(() => {
    const date = deliveryDatetime.value?.date;
    if (!date) {
        return '';
    }
    const styleClasses = [`day-text--${date.format('d')}`];
    if (DateUtil.isHoliday(date)) {
        styleClasses.push('day-text--holiday');
    }
    return styleClasses.join(' ');
});

</script>

<template>
<span class="ui-date-time-label-text">
    <span class="date-text">{{ formatDate }}</span>
    <span class="day-text" :class="dayTextStyleClass">{{ formatDayOfWeek }}</span>
    <span class="time-text">{{ formatTime }}</span>
</span>

</template>

<style scoped lang="less">
.ui-date-time-label-text {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: @heading-color;

    > span {
        display: inline-block;
    }

    > span + span {
        margin-left: 4px;
    }
}

.date-text,
.day-text {
    font-weight: bold;
}

.day-text {
    // 日曜日
    &--0:not(&--holiday) {
        color: @ui-color-sunday;
    }

    // 土曜日
    &--6:not(&--holiday) {
        color: @ui-color-saturday;
    }

    // 祝日
    &--holiday {
        color: @ui-color-holiday;
    }
}
</style>
