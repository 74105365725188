import { useLoading } from '@/composables/helper/loading-helper';
import { negotiationApi } from '@/repository/api/internal/negotiation';
import { NegotiationModel } from '@/models/negotiation';
import { ref } from 'vue';
import _ from 'lodash';

/**
 * 荷物1件に対して、自分の商談リクエストを取得します。
 */
export const useNegotiationQuery = () => {
    const negotiation = ref<NegotiationModel | undefined>();
    const { state: { loading }, withLoading } = useLoading();

    const load = (baggageId: number) => withLoading(async () => {
        const entity = (await negotiationApi.query([ baggageId ]) ?? []).find(each => each.baggageId === baggageId);
        if (_.isNil(entity)) {
            negotiation.value = undefined;
        } else {
            negotiation.value = new NegotiationModel(entity);
        }
    });

    return {
        state: {
            negotiation,
            loading,
        },
        load,
    };
};
