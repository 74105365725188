import { useLoading } from '@/composables/helper/loading-helper';
import { companyApi } from '@/repository/api/internal/company';
import { Karte } from '@/karte';

export const useGuaranteeRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const register = () => withLoading(async () => {
        await companyApi.registerGuarantee();

        Karte.trackApplyGuaranteeService();
    });

    return {
        state: {
            loading,
        },
        register,
    };
};
