import { useSettlementOutgoList } from '@/composables/settlement-list';
import { useSettlementOutgoDateList } from '@/composables/settlement-date-list';
import { computed, onBeforeMount, provide, ref } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { PreloadProps, DetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/props';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Settlement_DRAWER_PROVIDER_KEY, useSettlementDrawerProvider } from '@/composables/provider/settlement-drawer-provider';
import { useRouteExtension } from '@/composables/helper/route-extension';
import { useMessage } from '@/composables/helper/page-helper';

export const useSettlementOutgoListHelper = (props: { preload: PreloadProps, detail?: DetailProps }) => {
    const { state: { form, pageInfo, list, loading }, search: searchList, changePage } = useSettlementOutgoList();
    const { state: { list: payerDateList }, load: loadDateList } = useSettlementOutgoDateList();
    const { goToSettlementOutgoList, goToSettlementOutgoDetail, goToNotFound } = useRouting();
    const { settlementOutgoDetailProps } = useRouteExtension();
    const message = useMessage();
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);

    const settlementDrawerProvider = useSettlementDrawerProvider();
    provide(Settlement_DRAWER_PROVIDER_KEY, settlementDrawerProvider);
    const { load: loadSettlement, clear: clearSettlement } = settlementDrawerProvider;

    const search = async () => {
        form.value.pageNo = 1;
        await searchList();
    };

    const reload = async () => {
        await searchList();
    };

    // 詳細ドロワーの表示状態
    const settlementDrawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && props.detail !== undefined;
    });

    const goToSettlementDetail = async (settlementId: number) => {
        // 連打などにより画面遷移が失敗する可能性があるので、遷移失敗の例外をもみ消す
        await goToSettlementOutgoDetail(settlementId).catch(() => false);
    };

    const onCloseDrawer = async () => {
        if (props.detail?.settlementId) {
            await goToSettlementOutgoList();
        }
    };

    onBeforeMount(async () => {
        form.value.payerDate = props.preload.payerDate;
        form.value.onlyProxy = props.preload.proxy;
        await loadDateList();
        await search();

        if (props.detail?.settlementId) {
            try {
                await loadSettlement(props.detail.settlementId);
            } catch {
                await goToNotFound();
            }
        }
    });

    // クエリーが変化した後にロードだと、GTMイベントの発火タイミングなどの都合が悪いので、
    // クエリー変化時の処理が成功したときのみURLを変化させる
    onBeforeRouteUpdate(async (to, from, next) => {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const fromDetailProps = settlementOutgoDetailProps(from);
        const toDetailProps = settlementOutgoDetailProps(to);

        // toがない場合、一覧に戻る
        if (!toDetailProps) {
            clearSettlement();
            next();
            return;
        }
        // 荷物IDが同じ場合
        if (fromDetailProps?.settlementId === toDetailProps.settlementId) {
            next();
            return;
        }

        routeUpdating.value = true;
        try {
            await loadSettlement(toDetailProps.settlementId);
        } catch {
            message.error(`取引番号 ${ toDetailProps.settlementId } の取引情報を読み込みできませんでした。`);
            next(false);
            return;
        } finally {
            routeUpdating.value = false;
        }

        next();
    });

    return {
        loading,
        form,
        pageInfo,
        payerDateList,
        list,
        settlementDrawerVisibility,
        search,
        reload,
        changePage,
        selectSettlement: goToSettlementDetail,
        onCloseDrawer,
    };
};
