import { BaggageSearchForm } from '@/vuex/modules/baggage/types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';

/**
 * 荷物検索条件のKarte送信用フォーマット
 */
export interface KarteBaggageSearchForm {
    id?: number;
    departure_from?: string;
    departure_to?: string;
    departure_pref: string[];
    arrival_from?: string;
    arrival_to?: string;
    arrival_pref: string[];
    category?: string;
    type?: string;
    truck_weight: string[];
    truck_model: string[];
    share?: boolean;
    freight?: string;
    exclude_undecided_freight: boolean;
    sort_type: string;
    sort_direction: 'asc' | 'desc';
}

/**
 * 荷物成約予測リクエストのKarte送信用フォーマット
 */
export class KartePredictionBaggageAgreementForm {
    departure: string;
    departure_pref: string;
    arrival: string;
    arrival_pref: string;
    type: string;
    truckWeight: string;
    truckModel: string;
    share: boolean;
    express: boolean;
    freight?: string;

    constructor(source: PredictionBaggageAgreementForm) {
        this.departure = source.departure;
        this.departure_pref = source.departurePref.code ?? '';
        this.arrival = source.arrival;
        this.arrival_pref = source.arrivalPref.code ?? '';
        this.type = source.type;
        this.truckWeight = source.truckWeight.code ?? '';
        this.truckModel = source.truckModel.code ?? '';
        this.share = source.share;
        this.express = source.express;
        this.freight = source.freight;
    }
}

/**
 * 荷物検索条件をKarte送信用フォーマットに変換する。
 */
export function convertBaggageSearchForm(form: BaggageSearchForm): KarteBaggageSearchForm {
    return {
        id: form.id,
        departure_from: form.departureFrom,
        departure_to: form.departureTo,
        departure_pref: form.departurePref?.map((value) => value.code) ?? [],
        arrival_from: form.arrivalFrom,
        arrival_to: form.arrivalTo,
        arrival_pref: form.arrivalPref?.map((value) => value.code) ?? [],
        category: form.category?.code,
        type: form.type,
        truck_weight: form.truckWeight?.map((value) => value.code) ?? [],
        truck_model: form.truckModel?.map((value) => value.code) ?? [],
        share: form.share,
        freight: form.freight,
        exclude_undecided_freight: form.excludeUndecidedFreight,
        sort_type: form.sortKey,
        sort_direction: form.sortOrder === 'ASC' ? 'asc' : 'desc',
    };
}

/**
 * 空車レコメンド結果のKarte送信用フォーマット
 */
export interface KarteTruckRecommendResult {
    baggage_id: number;
    truck_ids: number[];
}

export interface KarteShowAgreementPageForm {
    // 荷物ID
    baggage_id: number;
    // 荷物登録企業ID
    baggage_company_id: number;
    // 受託企業ID（ログインユーザーの企業ID）
    truck_company_id: number;
    // ログインユーザーの企業が運賃保証に加入中か？
    under_contract_freight_protection: boolean;
    // 荷物登録企業との成約回数
    agreement_count_with_baggage_company: number;
    // 成約金額
    agreement_amount: number;
}
