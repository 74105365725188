import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import {
    CompanyPlace,
    CompanyPlaceList,
    CompanyPlacePaginatedList,
    CompanyPlaceRegisterForm,
    CompanyPlaceSearchForm, CompanyPlaceUpdateForm
} from '@/models/company-place';

export const companyPlaceApi = {
    /**
     * 取得します。
     */
    find(id: number): Promise<CompanyPlace> {
        return appAxios.get<JsonResponseBean<CompanyPlace>>(`/api/company_place/${ id }`).then(isSuccess);
    },
    /**
     * 一覧を取得します。
     */
    listCompanyPlace(): Promise<CompanyPlaceList> {
        return appAxios.get<JsonResponseBean<CompanyPlacePaginatedList>>('/api/company_place/list').then(isSuccess);
    },
    /**
     * 検索します。
     */
    searchCompanyPlace(param: CompanyPlaceSearchForm): Promise<CompanyPlacePaginatedList> {
        return appAxios.post<JsonResponseBean<CompanyPlacePaginatedList>>('/api/company_place/search', param).then(isSuccess);
    },
    /**
     * 登録します。
     */
    registerCompanyPlace(param: CompanyPlaceRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/company_place/register', param).then(isSuccess);
    },
    /**
     * 更新します。
     */
    updateCompanyPlace(id: number, param: CompanyPlaceUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_place/${id}/update`, param).then(isSuccess);
    },
    /**
     * 削除します。
     */
    deleteCompanyPlace(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_place/${id}/delete`).then(isSuccess);
    }
};
