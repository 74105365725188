import _ from 'lodash';

const negotiationReportStatusEnumCodes = ['POSSIBLE', 'REJECT', 'PENDING'] as const;

/**
 * 荷物の運行対応可否ステータスのEnum
 */
export type NegotiationReportStatusEnumCode = (typeof negotiationReportStatusEnumCodes)[number];

export function isNegotiationStatusEnumCode(code: string): code is NegotiationReportStatusEnumCode {
    return _.includes(negotiationReportStatusEnumCodes, code);
}

export class NegotiationReportStatusEnum {
    constructor(public code: NegotiationReportStatusEnumCode, public label: string) {}

    static Possible = new NegotiationReportStatusEnum('POSSIBLE', '可能');
    static Reject = new NegotiationReportStatusEnum('REJECT', '不可');
    static Pending = new NegotiationReportStatusEnum('PENDING', '保留');

    static valueOf = (code?: string): NegotiationReportStatusEnum | undefined =>
        NegotiationReportStatusEnum.values.find((value) => value.code === code);

    static values = [
        NegotiationReportStatusEnum.Possible,
        NegotiationReportStatusEnum.Reject,
        NegotiationReportStatusEnum.Pending,
    ];

    static codeOf = (code: NegotiationReportStatusEnumCode): NegotiationReportStatusEnum => {
        switch (code) {
            case 'POSSIBLE':
                return NegotiationReportStatusEnum.Possible;
            case 'REJECT':
                return NegotiationReportStatusEnum.Reject;
            case 'PENDING':
                return NegotiationReportStatusEnum.Pending;
        }
    };
}
