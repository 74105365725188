import { Enum } from '@/types/enum';
import { MemberPosition } from '@/vuex/modules/account/types';
import { DistributionLevelCode } from '@/components/UI/PrefectureDistributionMap/types';
import { SortOrder } from '@/models/vo/pagination';

export type BaggageSortKeyCode = 'ID' | 'DEPARTURE' | 'ARRIVAL' | 'FREIGHT' | 'DEPARTURE_PREFECTURE' | 'ARRIVAL_PREFECTURE';

export class Const {
    static APP_SERVICE_NAME = 'トラボックス求荷求車';
    static PAGE_TITLE_SEPARATOR = ' - ';
    static DEFAULT_PAGE_SIZE = 20;
    static PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];

    /**
     NOTE Private TRABOXのビジネス要件に従い、99日を設定することで有料別途課金の発生条件を無効化している。
     設定の背景については下記PRを参照のこと。
     https://github.com/trabox-inc/junction/pull/208
     **/
    static MONTHLY_TERM_END_DAY = 99; // 月内の期末日。この日の翌日以降は有料別途課金が適用されます。
    static SETTLEMENT_CHANGE_LIMIT_DAY = 15;
    static DEFAULT_VIEW_DATE_FORMAT = 'YYYY/MM/DD';
    static DEFAULT_VIEW_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';
    static DEFAULT_DATE_YEAR_MONTH_FORMAT = 'YYYY年M月';
    static DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT = 'YYYY年M月D日(ddd)';
    static DEFAULT_VIEW_TIME_FORMAT = 'H:mm';
    static DEFAULT_DATETIME_WITH_DAY_OF_WEEK_FORMAT = `${Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT} ${Const.DEFAULT_VIEW_TIME_FORMAT}`;
    static INTERNAL_DATE_FORMAT = 'YYYY-MM-DD';
    static INTERNAL_TIME_FORMAT = 'HH:mm:ss';
    static INTERNAL_DATETIME_FORMAT = `${Const.INTERNAL_DATE_FORMAT} ${Const.INTERNAL_TIME_FORMAT}`;
    static DEFAULT_SHORT_DATE_WITH_DAY_OF_WEEK_FORMAT = 'M/D(ddd)';
    static DEFAULT_SHORT_DATETIME_WITH_DAY_OF_WEEK_FORMAT = `${Const.DEFAULT_SHORT_DATE_WITH_DAY_OF_WEEK_FORMAT} ${Const.DEFAULT_VIEW_TIME_FORMAT}`;

    // 印刷用日時フォーマット
    static DEFAULT_PRINT_DATE_FORMAT = 'YYYY年M月D日';
    static DEFAULT_PRINT_DATETIME_FORMAT = `${Const.DEFAULT_PRINT_DATE_FORMAT} ${Const.DEFAULT_VIEW_TIME_FORMAT}`;
    static DEFAULT_PRINT_DELIVERY_DATE_FORMAT = 'M月D日(ddd)';
    static DEFAULT_PRINT_YEAR_MONTH_FORMAT = Const.DEFAULT_DATE_YEAR_MONTH_FORMAT;

    static MAX_BAGGAGE_SEARCH_CONDITIONS = 10;
    static MAX_FREIGHT = 300000000; // 取り扱い可能な最大運賃金額
    static MAX_HIGHWAY_FARE = 100000; // 高速代で取り扱い可能な最大金額
    static MAX_WAIT_TIME_FEE = 500000; // 取り扱い可能な最大待機料金額
    static MAX_OPERATION_FEE = 500000; // 取り扱い可能な最大付帯作業料金額
    static MAX_PICKING_FEE = 500000; // 取り扱い可能な最大搬出料金額
    static MAX_PARKING_FEE = 500000; // 取り扱い可能な最大駐車代金額
    static MAX_CANCELLATION_FEE = 100000; // 取り扱い可能な最大キャンセル料金額
    static MAX_CLEARANCE_FEE = 500000; // 取り扱い可能な最大通関料金額
    static MAX_PALETTE_COUNT_DIGITS = 5;
    static MAX_PALETTE_SIZE_DIGITS = 5;
    static MAX_BAGGAGE_COUNT_DIGITS = 5;
    static MAX_BAGGAGE_VOLUME_DIGITS = 5;
    static MAX_BAGGAGE_WEIGHT_DIGITS = 5;
    static MAX_EASY_PAYMENT_AMOUNT = 300000000;
    static MAX_EASY_PAYMENT_HIGHWAY_FARE = 300000000;

    // NOTE: 電話番号の正規表現は https://github.com/sakatam/a-better-jp-phone-regex の正規表現をベース、ハイフンを使わないため判定から `-?` を取り除いている
    static PHONE_NUMBER_REGEX = /^(0([1-9][1-9]\d{3}|[1-9]{2}\d{3}|[1-9]{2}\d\d{2}|[1-9]{2}\d{2}\d)\d{4}|0[789]0\d{4}\d{4}|050\d{4}\d{4})$/;
    static EMPTY_PHONE_NUMBER = '0000000000';
    static MIN_PHONE_NUMBER = 10;
    static MAX_PHONE_NUMBER = 11;
    static MAX_CITY = 200; // 市区町村の最大文字長
    static MAX_ADDRESS = 200; // 番地・建物の最大文字長
    static MAX_LOADING_TIME_NOTE = 250;
    static MAX_UNLOADING_TIME_NOTE = 250;
    static MAX_EMAIL_LENGTH = 250;
    static MAX_TRUCK_COUNT_DIGITS = 5; // 99999
    static MAX_URL_LENGTH = 200;
    static MAX_TRUCK_DESCRIPTION_LENGTH = 2000;
    static MAX_REPORT_LATE_PAYMENT_DESCRIPTION_LENGTH = 3000;
    static MAX_COMPANY_NAME_LENGTH = 200;
    static MAX_MEMBER_NAME_LENGTH = 60;

    static SHARE_BAGGAGE_MESSAGE = '積合';
    static EXPRESS_BAGGAGE_MESSAGE = '至急';
    static RELOCATION_BAGGAGE_MESSAGE = '引越し案件';

    // Issue2773より、お客様反応が良くなかったため、常に2行表示となるように非現実的な値を設定
    static FLEXIBLE_TABLE_MODE_THRESHOLD = 99999;

    static employeeNumbers = [
        { label: '1〜10人', code: 'E10' },
        { label: '11人〜20人', code: 'E20' },
        { label: '21人〜50人', code: 'E50' },
        { label: '51人〜100人', code: 'EA0' },
        { label: '101人以上', code: 'EZZ' },
    ];

    static positions: Array<Enum<MemberPosition>> = [
        { label: '代表者', code: 'BOSS' },
        { label: '役員', code: 'LDER' },
        { label: '配車担当', code: 'DPER' },
        { label: 'その他', code: 'NONE' },
    ];

    static accountingMonth = [
        { label: '当月', code: 0 },
        { label: '翌月', code: 1 },
        { label: '翌々月', code: 2 },
        { label: '翌々々月', code: 3 },
        { label: '翌々々々月', code: 4 },
    ];

    static accountingDay = [
        { label: '末日', code: 99 },
        { label: '1日', code: 1 },
        { label: '2日', code: 2 },
        { label: '3日', code: 3 },
        { label: '4日', code: 4 },
        { label: '5日', code: 5 },
        { label: '6日', code: 6 },
        { label: '7日', code: 7 },
        { label: '8日', code: 8 },
        { label: '9日', code: 9 },
        { label: '10日', code: 10 },
        { label: '11日', code: 11 },
        { label: '12日', code: 12 },
        { label: '13日', code: 13 },
        { label: '14日', code: 14 },
        { label: '15日', code: 15 },
        { label: '16日', code: 16 },
        { label: '17日', code: 17 },
        { label: '18日', code: 18 },
        { label: '19日', code: 19 },
        { label: '20日', code: 20 },
        { label: '21日', code: 21 },
        { label: '22日', code: 22 },
        { label: '23日', code: 23 },
        { label: '24日', code: 24 },
        { label: '25日', code: 25 },
        { label: '26日', code: 26 },
        { label: '27日', code: 27 },
        { label: '28日', code: 28 },
    ];

    static baggageSortKey: { label: string; code: BaggageSortKeyCode, defaultOrder: SortOrder }[] = [
        { label: '新着順', code: 'ID', defaultOrder: 'DESC' },
        { label: '発日時', code: 'DEPARTURE', defaultOrder: 'ASC' },
        { label: '着日時', code: 'ARRIVAL', defaultOrder: 'ASC' },
        { label: '運賃', code: 'FREIGHT', defaultOrder: 'DESC' },
        { label: '発都道府県', code: 'DEPARTURE_PREFECTURE', defaultOrder: 'ASC' },
        { label: '着都道府県', code: 'ARRIVAL_PREFECTURE', defaultOrder: 'ASC' },
    ];

    static agreementSortKey = [
        { label: '新着順', code: 'ID', defaultOrder: 'DESC' },
        { label: '発日時', code: 'DEPARTURE', defaultOrder: 'ASC' },
        { label: '着日時', code: 'ARRIVAL', defaultOrder: 'ASC' },
        { label: '運賃', code: 'FREIGHT', defaultOrder: 'DESC' },
    ];

    static truckSortKey = [
        { label: '新着順', code: 'ID', defaultOrder: 'DESC' },
        { label: '空車時刻', code: 'DEPARTURE', defaultOrder: 'ASC' },
        { label: '行先時刻', code: 'ARRIVAL', defaultOrder: 'ASC' },
        { label: '運賃', code: 'MIN_FREIGHT', defaultOrder: 'DESC' },
    ];

    static externalPageUrl = {
        terms: 'https://www.trabox.ne.jp/corporate/rules.html', // 利用規約
        corporatePrivacyPolicy: 'https://www.trabox.co.jp/privacy_policy.html', // 個人情報について
        groupPrivacyPolicy: 'https://www.visional.inc/ja/privacypolicy.html', // グループ個人情報保護方針
        pricing: 'https://www.trabox.ne.jp/support/01-01.html', // 料金について
        mergersAndAcquisitions: 'https://www.trabox.ne.jp/ma/', // 友好的 M&A 無料相談窓口
        eSohko: 'https://www.trabox.ne.jp/banner/e-sohko/', // 倉庫情報サービス
        inquiryForm: 'https://www.trabox.ne.jp/contact/index.html', // お客様相談室お問い合わせフォーム
        guarantee: 'https://www.trabox.ne.jp/support/hoshou.html', // 運賃全額保証について
        guaranteeAppliedCompanies: 'https://www.trabox.ne.jp/support/performance.html', // 運賃全額保証サービス履行による強制退会企業
        howToChangeAgreement: 'https://support.trabox.ne.jp/hc/ja/articles/4401989362713', // 成約した内容を変更したい
        easyPayment: 'https://support.trabox.ne.jp/hc/ja/articles/15744426660121',
        easyPaymentGuide: 'https://support.trabox.ne.jp/hc/ja/articles/20641560788633',
        primeIsOver: 'https://trabox.zendesk.com/hc/ja/articles/19086875371289',
    };

    static supportDeskContacts = {
        name: 'トラボックスお客様相談室',
        phone: '0120-20-3058',
        email: 'support@trabox.co.jp',
    };

    static baggageDescriptionNgWords = [
        '交換',
        'こうかん',
        'コウカン',
        '交かん',
        '入れ替え',
        'いれかえ',
        '入れかえ',
        'いれ替え',
        '入替',
        '入替え',
        '差し替え',
        '差しかえ',
        'さし替え',
        'さしかえ',
        '差替',
        '差替え',
        '差し換え',
        'さし換え',
        '差換',
        '差換え',
        'トレード',
        'trade',
        'とれーど',
        'バーター',
        'barter',
        'ばーたー',
        'チェンジ',
        'change',
        'ちぇんじ',
    ];

    static companyAchievementThreshold: { code: DistributionLevelCode, threshold: number }[] = [
        { code: 'high', threshold: 48 },
        { code: 'middle', threshold: 12 },
        { code: 'low', threshold: 1 },
    ];
}
