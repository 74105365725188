import { injectStrictly } from '@/util/vue';
import { Settlement_EDITOR_PROVIDER_KEY } from '@/composables/provider/settlement-editor-provider';
import { useFormModel } from '@/composables/form-helper';
import { useProxyOptions } from '@/composables/option-helper';
import { computed, watch } from 'vue';
import { Util } from '@/util';
import { useModal } from '@/composables/helper/modal-helper';
import Content from '@/_components/parts/SettlementChangeRequestConfirmDialog.vue';

export const useSettlementEditorHelper = (done: () => void) => {
    const {
        state: {
            entitlement,
            loading,
            settlement,
            partner,
            form,
            validationRules,
            dirty,
            changeType,
        },
        request,
    } = injectStrictly(Settlement_EDITOR_PROVIDER_KEY);
    const { submit, formModel } = useFormModel();
    const { confirm } = useModal();

    const { options: proxyOptions } = useProxyOptions();

    watch(changeType, (value) => {
        if (!settlement.value) return;
        if (value !== 'CHANGE') {
            form.value.isCanceling = Util.requireNotNull(settlement.value?.hasCancellationFee);
            form.value.freight = Util.requireNotNull(settlement.value?.freight.value);
            form.value.highwayFare = Util.requireNotNull(settlement.value?.highwayFare.value);
            form.value.waitTimeFee = settlement.value?.waitTimeFee?.value ?? 0;
            form.value.operationFee = settlement.value?.operationFee?.value ?? 0;
            form.value.pickingFee = settlement.value?.pickingFee?.value ?? 0;
            form.value.parkingFee = settlement.value?.parkingFee?.value ?? 0;
            form.value.clearanceFee = settlement.value?.clearanceFee?.value ?? 0;
            form.value.cancellationFee = settlement.value?.cancellationFee?.value ?? 0;
        }
        validate();
    });

    const canProxy = computed(() => entitlement.value?.active('settlementProxy') ?? false);

    const validate = () => submit(async () => {});
    const onSubmit = () => submit(async () => {
        const confirmed = await confirm(
            'ご確認ください',
            (h: Function) => h(Content, {}),
            '変更を申請する',
            '戻る',
            700,
        );
        if (!confirmed) return;
        await request().then(() => done());
    });
    return {
        canProxy,
        loading,
        settlement,
        partner,
        form,
        validationRules,
        dirty,
        changeType,
        formModel,
        validate,
        onSubmit,
        proxyOptions,
    };
};
