import { ref } from 'vue';
import { createGlobalState } from '@vueuse/core';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/company/types';
import { CompanyConfidence } from '@/models/company';
import { useVuexSync } from '@/composables/helper/vuex';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 自社の企業信用をロードする機能を提供します。
 */
export const useCompanyMyConfidence = createGlobalState(() => {
    const myConfidence = ref<CompanyConfidence>();
    const { state: { loading }, withLoading } = useLoading();

    useVuexSync(myConfidence, { module: 'company', getter: g.CONFIDENCE, mutation: m.SET_CONFIDENCE });

    const load = () => withLoading(async () => {
        myConfidence.value = await companyApi.myConfidence();
    });

    return {
        state: {
            loading,
            myConfidence,
        },
        load,
    };
});
