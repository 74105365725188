import { BaggageHighwayFarePaymentOption } from '@/models/baggage';

export const useBaggageHighwayFarePaymentOption = () => {
    const fromBoolean = (value: boolean): BaggageHighwayFarePaymentOption => {
        return value ? 'available' : 'unavailable';
    };

    const toBoolean = (value: BaggageHighwayFarePaymentOption): boolean => {
        return value === 'available';
    };

    return {
        fromBoolean,
        toBoolean,
    };
};
