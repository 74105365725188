import {
    Alert,
    AutoComplete,
    Avatar,
    BackTop,
    Button,
    Calendar,
    Card,
    Checkbox,
    Col,
    Collapse,
    ConfigProvider,
    DatePicker,
    Descriptions,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    FormModel,
    Icon,
    Input,
    InputNumber,
    Layout,
    List,
    Menu,
    message,
    Modal,
    notification,
    PageHeader,
    Pagination,
    Popover,
    Progress,
    Radio,
    Result,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Switch,
    Table,
    Tabs,
    Tag,
    Timeline,
    TimePicker,
    Tooltip,
    Tree,
    Upload,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import 'ant-design-vue/lib/locale/ja_JP';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookies';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBookmark as farBookmark,
    faBuilding as farBuilding,
    faClone as farClone,
    faHandshake as farHandshake,
} from '@fortawesome/free-regular-svg-icons';
import {
    faBookmark as fasBookmark,
    faExternalLinkAlt as faExternalLinkAlt,
    faPhoneAlt as fasPhoneAlt,
    faUser as fasUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './class-component-hooks';
import router from './router';
import App from './App.vue';
import { BoardVuex } from './vuex/vuex';
// @ts-ignore
import 'moment/dist/locale/ja';
import EmbedTagPlugin from './vue-plugins/embed-tag';
import MicroCmsTagPlugin from './vue-plugins/microcms-tag';

// Vue Base Plugins
Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.use(BoardVuex);

// Ant Design Components
Vue.use(Alert);
Vue.use(AutoComplete);
Vue.use(Avatar);
Vue.use(BackTop);
Vue.use(Button);
Vue.use(Calendar);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Descriptions);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(Empty);
Vue.use(FormModel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Layout);
Vue.use(List);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(PageHeader);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Result);
Vue.use(Row);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(Space);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(TimePicker);
Vue.use(Tooltip);
Vue.use(Tree);
Vue.use(Upload);

Vue.config.productionTip = false;
// Fontawesome
library.add(
    farBookmark,
    farBuilding,
    farClone,
    fasBookmark,
    fasPhoneAlt,
    farHandshake,
    fasUser,
    faExternalLinkAlt,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(EmbedTagPlugin);
Vue.use(MicroCmsTagPlugin);

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
notification.config({
    duration: 0,
});

message.config({
    duration: 5,
});

// Set Vue Config
Vue.config.productionTip = false;

// Bootstrap App
new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
