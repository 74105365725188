import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';
import { AgreementForm } from '@/vuex/modules/agreement/types';

@Component
export default class AgreementBaggageTotalCountRegister extends Vue {
    @Prop()
    declare readonly value?: AgreementForm;

    get totalCount(): string {
        return this.value?.totalCount ?? '';
    }

    set totalCount(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (newValue.length > 0) {
            cloned.totalCount = isNaN(Util.toNumber(newValue)) ? '' : Util.toDigits(newValue);
        } else {
            cloned.totalCount = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            pattern: /^[0-9]+$/,
            message: '荷物の個数（目安）は数字で入力してください。',
        },
        {
            max: Const.MAX_BAGGAGE_COUNT_DIGITS,
            message: `荷物の個数（目安）は最大${Const.MAX_BAGGAGE_COUNT_DIGITS}桁で入力してください。`,
        },
    ];
}
