//
// Action
//
import { Questionnaire } from '@/models/questionnaire';

export class ACTION {
    static readonly LOAD_QUESTIONNAIRE = 'LOAD_QUESTIONNAIRE';
}

//
// Getter
//
export class GETTER {
    static readonly QUESTIONNAIRE = 'QUESTIONNAIRE';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
}

//
// Store
//
export interface QuestionnaireState {
    questionnaire?: Questionnaire;
}

//
// Type for store
//
export * from '@/models/questionnaire';
