import { computed, ref } from 'vue';
import { useGtm } from '@/composables/helper/gtm-helper';
import { CompanyNoticeModel } from '@/models/company-notice-model';
import { useRouting } from '@/composables/helper/routing';
import { useCompanyNotice } from '@/composables/global/company-notice';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';

export const useCompanyNoticeService = () => {
    const { state: { isActive } } = useCompanyMyStatus();

    const {
        state: { noticeList, },
        markAsRead,
    } = useCompanyNotice();

    const visibleValue = ref<boolean>(false);
    const visible = computed<boolean>({
        get: () => visibleValue.value,
        set: (value: boolean) => {
            visibleValue.value = value;
            if (value) {
                gtm.setEvent('open_company_notice');
            }
        }
    });

    const { gtm } = useGtm();

    /**
     * 企業お知らせを表示するべきか否かを取得します。
     */
    const shouldShowCompanyNotice = computed<boolean>(() => {
        // 企業が有効でなければ非表示
        if (!isActive) return false;

        return noticeList.value?.isNotEmpty ?? false;
    });

    const { goToAgreementList, goToAgreementAcceptedList, openDeliveryOrderPreview } = useRouting();

    const read = async (item: CompanyNoticeModel): Promise<void> => {
        // ポップアップを閉じる
        visible.value = false;

        // 既読とする
        await markAsRead(item);

        switch (item.type.code) {
            case 'AGREEMENT_AGREED':
                await goToAgreementList(item.objectId);
                break;
            case 'ACCEPTED_AGREEMENT_CHANGE':
                await goToAgreementList(item.objectId);
                break;
            case 'AGREED_AGREEMENT_CHANGE':
                await goToAgreementAcceptedList(item.objectId);
                break;
            case 'AGREEMENT_REVOKE':
                await goToAgreementList(item.objectId);
                break;
            case 'AGREEMENT_REVOKED':
                await goToAgreementAcceptedList(item.objectId);
                break;
            case 'ONLINE_ORDER_CREATE':
            case 'ONLINE_ORDER_UPDATE':
            case 'ONLINE_ORDER_REPLY':
            case 'ONLINE_ORDER_REPLY_UPDATE':
            case 'ONLINE_ORDER_DELETE':
                await openDeliveryOrderPreview(item.objectId);
                break;
            default:
                throw new Error('Illegal notice type.');
        }
    };

    return {
        state: {
            visible,
            shouldShowCompanyNotice,
            noticeList,
        },
        read,
    };
};
