import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyConfidence, CompanyProfile, CompanyStatistics } from '@/vuex/modules/company/types';
import { DateUtil, PhoneUtil, ZipUtil } from '@/util';
import { Const } from '@/const';

@Component
export default class CompanyPrint extends Vue {
    @Prop()
    declare readonly profile?: CompanyProfile;
    @Prop()
    declare readonly confidence?: CompanyConfidence;
    @Prop()
    declare readonly statistics?: CompanyStatistics;

    get nameKanji(): string {
        return this.profile?.name.kanji ?? '';
    }

    get nameKana(): string {
        return this.profile?.name.kana ?? '';
    }

    get address(): string {
        if (!this.profile) return '';
        const zip = this.profile.zipCode ?? '';
        const prefecture = this.profile.location.prefecture.label ?? '';
        const city = this.profile.location.city;
        const address = this.profile.location.address;
        return `〒${ ZipUtil.format(zip) } ${ prefecture }${ city }${ address }`;
    }

    get phoneNumber(): string {
        if (!this.profile) return '';
        return PhoneUtil.format(this.profile.phone.number);
    }

    get faxNumber(): string {
        if (!this.profile?.phone.faxNumber) return '';
        return PhoneUtil.format(this.profile?.phone.faxNumber);
    }

    get description(): string {
        return this.profile?.description ?? '';
    }

    get truckCount(): string {
        return this.profile?.truckCount?.toString() ?? '';
    }

    get url(): string {
        return this.profile?.url ?? '';
    }

    get registrationDate(): string {
        if (!this.profile?.registrationDate) {
            return '';
        }
        return DateUtil.parseDateText(this.profile.registrationDate).format(Const.DEFAULT_PRINT_DATE_FORMAT);
    }

    get pastMonthBaggageCount(): string {
        return this.statistics?.pastMonthBaggageCount.toString() ?? '';
    }

    get pastThreeMonthsBaggageCount(): string {
        return this.statistics?.pastThreeMonthsBaggageCount.toString() ?? '';
    }

    get pastMonthTruckCount(): string {
        return this.statistics?.pastMonthTruckCount.toString() ?? '';
    }

    get pastThreeMonthsTruckCount(): string {
        return this.statistics?.pastThreeMonthsTruckCount.toString() ?? '';
    }

    get representativeName(): string {
        return this.profile?.representativeName ?? '';
    }

    get establishmentDate(): string {
        if (!this.profile?.establishmentDate) {
            return '';
        }
        return DateUtil.parseDateText(this.profile.establishmentDate).format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
    }

    get capital(): string {
        const capital = this.profile?.capital;
        if (capital) {
            return `${ (capital / 10000).toString() }万円`;
        } else {
            return '';
        }
    }

    get employeesNumber(): string {
        return this.profile?.employeesNumber?.label ?? '';
    }

    get officeAddress(): string {
        return this.profile?.officeAddress ?? '';
    }

    get sales(): string {
        const sales = this.profile?.sales;
        if (sales) {
            return `${ (sales / 10000).toString() }万円`;
        } else {
            return '';
        }
    }

    get bank(): string {
        return this.profile?.bank ?? '';
    }

    get customer(): string {
        return this.profile?.customer ?? '';
    }

    get cutOffDate(): string {
        return this.profile?.cutOffDay?.label ?? '';
    }

    get paymentDate(): string {
        const month = this.profile?.paymentMonth?.label;
        const day = this.profile?.paymentDay?.label;
        return (month ?? '') + (day ? ' ' + day : '');
    }

    get unionName(): string {
        return this.confidence?.unionName ?? '';
    }

    get mlitGrantNumber(): string {
        return this.confidence?.mlitGrantNumber ?? '';
    }

    get salesArea(): string {
        return this.profile?.salesArea ?? '';
    }

    get digitalTachometerCount(): string {
        return this.confidence?.digitalTachometerCount ? `${ this.confidence?.digitalTachometerCount }台` : '';
    }

    get gpsCount(): string {
        return this.confidence?.gpsCount ? `${ this.confidence?.gpsCount }台` : '';
    }

    get goodMark(): string {
        return CompanyPrint.flagToText(this.confidence?.goodMark);
    }

    get greenManagement(): string {
        return CompanyPrint.flagToText(this.confidence?.greenManagement);
    }

    get iso9000(): string {
        return CompanyPrint.flagToText(this.confidence?.iso9000);
    }

    get iso14000(): string {
        return CompanyPrint.flagToText(this.confidence?.iso14000);
    }

    get iso39001(): string {
        return CompanyPrint.flagToText(this.confidence?.iso39001);
    }

    get insuranceName(): string {
        return this.confidence?.insuranceName ?? '';
    }

    private static flagToText(flag?: boolean): string {
        return flag ? '有' : '無';
    }
}
