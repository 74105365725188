<script setup lang="ts">
import _ from 'lodash';
import { computed, nextTick } from 'vue';
import { Col } from 'ant-design-vue';
import { FormValidateUtil } from '@/models/validate-helper';
import { AccountRegisterFormModel } from '@/models/account';
import { useLocation } from '@/composables/helper/location-helper';
import { usePrefectureOptions } from '@/composables/option-helper';
import { useFormModel, useFormModelItem } from '@/composables/form-helper';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import { Const } from '@/const';

type ThemeType = 'com' | 'nejp';
const themeStyle: Record<ThemeType, { labelColumn: Partial<Col>, wrapperColumn: Partial<Col>, formItemSize: string }> = {
    com: {
        labelColumn: { span: 8 },
        wrapperColumn: { span: 16 },
        formItemSize: 'default'
    },
    nejp: {
        labelColumn: { lg: { span: 24, offset: 0 }, xl: { span: 8, offset: 0 } },
        wrapperColumn: { lg: { span: 24, offset: 0 }, xl: { span: 16, offset: 0 } },
        formItemSize: 'large'
    }
};

const props = withDefaults(defineProps<{
    loading: boolean,
    value: AccountRegisterFormModel,
    theme?: ThemeType,
}>(), {
    theme: 'com'
});
const emits = defineEmits<{
    (e: 'submit'): void,
    (e: 'input', value: AccountRegisterFormModel): void,
}>();

const { submit, formModel: formModelRef } = useFormModel();
const { formModelItem: formItemPrefecture, onChange: onChangePrefecture } = useFormModelItem();
const { formModelItem: formItemCity, onChange: onChangeCity } = useFormModelItem();
const { state: { loading: loadingZipCode }, zipToAddress } = useLocation();
const { options: prefectures } = usePrefectureOptions();

const form = computed({
    get: () => props.value,
    set: (value: AccountRegisterFormModel) => {
        emits('input', value);
    },
});
const formValidationRules = computed(() => FormValidateUtil.toAntValidator(props.value));
const isNeJpTheme = computed(() => props.theme === 'nejp');
const labelColumn = computed(() => themeStyle[props.theme].labelColumn);
const wrapperColumn = computed(() => themeStyle[props.theme].wrapperColumn);
const formItemSize = computed(() => themeStyle[props.theme].formItemSize);

const autoCompleteAddress = async () => {
    if (form.value.zipCode.length === 7) {
        const { prefecture, city, address } = await zipToAddress(form.value.zipCode);

        const cloned = _.cloneDeep(form.value);
        if (prefecture) cloned.prefectureCode = prefecture.code;
        cloned.city = city + address;
        form.value = cloned;

        // 都道府県、市区町村の再バリデーションを促す
        nextTick(() => {
            onChangePrefecture();
            onChangeCity();
        });
    }
};
const onSubmit = () => submit(async () => emits('submit'));
</script>

<template>
    <div class="account-register-form form-theme" :class="{ 'form-theme--nejp': isNeJpTheme }">
        <a-spin :spinning="loading">
            <a-row type="flex" align="middle" justify="center" :gutter="[0, 48]">
                <a-col :span="24">
                    <a-form-model ref="formModelRef"
                                  :model="form"
                                  :rules="formValidationRules"
                                  :colon="false"
                                  :label-col="labelColumn"
                                  :wrapper-col="wrapperColumn">
                        <a-form-model-item label="担当者名" prop="name">
                            <a-input placeholder="フルネーム"
                                     v-model="form.name"
                                     :size="formItemSize"/>
                        </a-form-model-item>
                        <a-form-model-item label="メールアドレス" prop="emailAddress">
                            <a-input placeholder="taro@trabox.com"
                                     v-model="form.emailAddress"
                                     :size="formItemSize"/>
                        </a-form-model-item>
                        <a-form-model-item label="電話番号" prop="phoneNumber">
                            <a-input placeholder="0120203058"
                                     v-model="form.phoneNumber"
                                     :size="formItemSize"/>
                        </a-form-model-item>
                        <a-form-model-item label="FAX番号" prop="faxNumber">
                            <a-input placeholder="0366741308"
                                     v-model="form.faxNumber"
                                     :size="formItemSize"/>
                        </a-form-model-item>
                        <a-form-model-item label="法人名・営業所名" prop="companyName">
                            <a-input placeholder="トラボックス株式会社 本社"
                                     v-model="form.companyName"
                                     :size="formItemSize"/>
                        </a-form-model-item>
                        <a-form-model-item label="郵便番号" prop="zipCode">
                            <a-input placeholder="1230000"
                                     :max-length="8"
                                     :size="formItemSize"
                                     v-model="form.zipCode"
                                     @change="autoCompleteAddress">
                                <template #suffix>
                                    <a-icon type="loading" v-if="loadingZipCode"></a-icon>
                                </template>
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item label="都道府県" prop="prefectureCode" ref="formItemPrefecture">
                            <a-select placeholder="選択してください"
                                      :disabled="loadingZipCode"
                                      :options="prefectures"
                                      :size="formItemSize"
                                      v-model="form.prefectureCode"/>
                        </a-form-model-item>
                        <a-form-model-item label="市区町村" prop="city" ref="formItemCity">
                            <a-input placeholder="渋谷区渋谷"
                                     :disabled="loadingZipCode"
                                     :size="formItemSize"
                                     v-model="form.city"/>
                        </a-form-model-item>
                        <a-form-model-item label="番地・建物" prop="address">
                            <a-input placeholder="1-1-1 クロスタワー12F"
                                     :disabled="loadingZipCode"
                                     :size="formItemSize"
                                     v-model="form.address"/>
                        </a-form-model-item>
                        <a-form-model-item label="トラック保有台数" prop="truckCount">
                            <a-input suffix="台"
                                     v-model="form.truckCount"
                                     :size="formItemSize"
                                     :max-length="5"/>
                        </a-form-model-item>
                        <a-form-model-item label="ホームページアドレス" prop="url">
                            <a-input placeholder="www.trabox.ne.jp" v-model="form.url" :size="formItemSize">
                                <template #addonBefore>
                                    <a-select v-model="form.urlScheme" :size="formItemSize" style="width: 96px;">
                                        <a-select-option value="https://">https://</a-select-option>
                                        <a-select-option value="http://">http://</a-select-option>
                                    </a-select>
                                </template>
                            </a-input>
                        </a-form-model-item>
                    </a-form-model>

                    <div class="account-register-form__actions">
                        <p class="agreement-text"><a :href="Const.externalPageUrl.terms" target="_blank">
                            <tbx-link-text type="external">利用規約</tbx-link-text>
                        </a> および、<a :href="Const.externalPageUrl.corporatePrivacyPolicy" target="_blank">
                            <tbx-link-text type="external">個人情報の取り扱い</tbx-link-text>
                        </a> に
                        </p>
                        <a-button type="primary" :loading="isNeJpTheme && loading" @click="onSubmit">同意して登録する
                            <a-icon type="right" v-if="theme === 'nejp'"/>
                        </a-button>
                    </div>
                </a-col>
            </a-row>
        </a-spin>
    </div>
</template>

<style scoped lang="less">
.account-register-form {
    margin: 0 auto;

    &__actions {
        margin-top: 32px;
        text-align: center;
    }
}

.agreement-text {
    margin-bottom: 24px;

    a {
        display: inline-block;
        padding: 0 4px;
    }
}

// Form Themes
.form-theme {
    width: 464px;
}

// nejp用のフォームスタイル
.form-theme.form-theme--nejp {
    width: 100%;
    padding: 32px;
    background-color: @component-background;
    border-radius: @border-radius-base;

    a {
        color: #006bc7;

        &:hover {
            opacity: 0.5;
        }
    }

    .ant-input {
        width: 100%;
        height: 44px;
        font-size: 16px;
    }

    .ant-btn {
        height: 88px;
        background-color: #ee7600;
        color: #fff;
        border: 2px solid transparent;
        transition: all 0.6s ease 0s;

        &-primary {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 428px;
            max-width: 100%;
            font-size: 24px;
            font-weight: normal;
            border-radius: 54px;
            margin: 0 auto;
            padding: 0;
        }

        &:hover {
            box-shadow: none;
            position: relative;
            background-color: #fff;
            border: 2px solid #ee7600;
            color: #ee7600;
        }
    }

    ::v-deep .ant-form-item {
        .ant-form-item-label {
            text-align: left !important;
        }

        label {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.link-icon {
    display: inline-block;
    width: 16px;
    padding-left: 4px;
}
</style>
