import _ from 'lodash';
import { Column } from 'ant-design-vue/types/table/column';
import { Vue } from 'vue-property-decorator';

// #2773でwidthに応じたレイアウト切り替え機能を無効にしたが、
// 機能復活があるかもしれないので、切り替えの仕組みは残しておく
export const useBaggageTableColumns = () => {
    type AntColumn = Omit<Column, keyof Vue>;
    type CustomOption = { single: number; multiple: number; border?: boolean; title: string; };
    type FlexibleColumn = AntColumn & CustomOption;

    const COLUMN_DEFINITION: Array<FlexibleColumn> = [
        { single: 102, multiple: 102, key: 'id', title: '荷物番号' },
        { single: 150, multiple: 0, key: 'departureDate', title: '発日時', align: 'left', border: false },
        { single: 150, multiple: 0, key: 'arrivalDate', title: '着日時', align: 'left' },
        { single: 135, multiple: 0, key: 'departureLocation', title: '発地', align: 'left', border: false },
        { single: 18, multiple: 0, key: 'arrow', title: '', border: false },
        { single: 159, multiple: 0, key: 'arrivalLocation', title: '着地', align: 'left' },
        { single: 0, multiple: 170, key: 'departure', title: '発日時・発地', align: 'left' },
        { single: 0, multiple: 170, key: 'arrival', title: '着日時・着地', align: 'left' },
        { single: 128, multiple: 128, key: 'type', title: '荷種' },
        { single: 112, multiple: 112, key: 'freight', title: '運賃', align: 'center' },
        { single: 100, multiple: 100, key: 'viewersCount', title: '閲覧人数', align: 'center' },
        { single: 60, multiple: 60, key: 'truckWeight', title: '重量', align: 'center' },
        { single: 108, multiple: 108, key: 'truckModel', title: '車種' },
        { single: 80, multiple: 80, key: 'staffName', title: '担当' },
        { single: 180, multiple: 180, key: 'description', title: '備考' },
    ];

    const widthRatio = (definition: FlexibleColumn, single: boolean) => {
        if (single) {
            const sum = _.sumBy(COLUMN_DEFINITION, each => each.single);
            return definition.single / sum * 100;
        } else {
            const sum = _.sumBy(COLUMN_DEFINITION, each => each.multiple);
            return definition.multiple / sum * 100;
        }
    };

    const columnDefinition = (single: boolean) =>
        COLUMN_DEFINITION.filter(each => single ? each.single > 0 : each.multiple > 0);

    const build = (single: boolean): AntColumn[] => {
        return columnDefinition(single).map(column => ({
            ...column,
            scopedSlots: { customRender: column.key },
            dataIndex: column.key,
            width: widthRatio(column, single),
            className: column.border === false ? 'no-border-right' : '',
        }));
    };

    return {
        build,
    };
};
