import { FormModel } from 'ant-design-vue';
import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { gtm } from '@/gtm';
import store from '@/vuex/store';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import * as types from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiMissionLayout from '@/components/UI/Layouts/MissionLayout';
// @ts-ignore
import Email from '@/components/Account/Login/Email';
// @ts-ignore
import Password from '@/components/Account/Login/Password';
import { useMicroCMS } from '@/composables/helper/microcms-helper';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    title: 'ログイン',
    components: {
        TbxLinkText,
        UiMissionLayout,
        Email,
        Password,
    },
    beforeRouteEnter: LoginPage.beforeRouteEnter,
})
export default class LoginPage extends PageVue {
    // ======================================================
    // Data
    // ======================================================
    loading = false;

    loginFailureReason: '' | 'LOCKED' | 'COMPANY_LOCKED' = '';

    loginForm: types.LoginForm = {
        emailAddress: '',
        password: '',
    };

    /**
     * セッション切れ
     */
    get sessionExpired(): boolean {
        return this.$route.query.expired == 'true';
    }

    /**
     * パスワードロック
     */
    get locked(): boolean {
        return this.loginFailureReason === 'LOCKED';
    }

    /**
     * 企業ロック
     */
    get companyLocked(): boolean {
        return this.loginFailureReason === 'COMPANY_LOCKED';
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const notifyFailedKey = 'LOGIN_FAILED';

        // ログイン成功ハンドラー
        const processSucceed = async () => {
            gtm.setEvent('login');

            const returnTo = this.$route.query.return_to;
            if (_.isString(returnTo) && returnTo.indexOf('/') === 0) {
                await this.$router.push({ path: returnTo });
            } else {
                // ナビゲーションガードでリダイレクトすると例外が発生するので、ここで例外もみ消す。
                // これは期待通りの例外で正常な処理です。
                // https://stackoverflow.com/a/65326844
                await this.$router.push({ name: 'BaggageList' }).catch(() => {});
            }
        };
        // ログイン失敗ハンドラー
        const notifyLoginFailed = () => {
            gtm.setEvent('login_failed');

            // ロック系エラーの場合は、notificationを出さない
            if (!this.locked && !this.companyLocked) {
                this.$notification.error({
                    key: notifyFailedKey,
                    message: 'ログインできません。',
                    description: 'メールアドレスとパスワードをご確認ください。',
                });
            }
        };

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            const success = await LoginPage.login(this.loginForm)
                .then(() => LoginPage.loadContractList())
                .then(() => true)
                .catch((error) => {
                    this.loginFailureReason = error.code;
                    return false;
                });

            if (success) {
                await processSucceed();
            } else {
                notifyLoginFailed();
            }

            this.loading = false;
        });
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<LoginPage>
    ): Promise<void> {
        // 告知エリアの情報を取得
        const { load } = useMicroCMS();
        await load('login-side', to.query);
        next();
    }

    /**
     * ログインします。
     */
    private static login(form: types.LoginForm): Promise<void> {
        return store.dispatch(`account/${types.ACTION.LOGIN}`, form);
    }

    /**
     * 契約一覧をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }
}
