import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';

@Component
export default class CompanyTruckCount extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get truckCount(): string {
        return `${this.profile?.truckCount ?? ''}`;
    }
}
