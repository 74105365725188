/**
 * 日本の都道府県Enum
 */
export type PrefectureEnumCode =
    | 'J01'
    | 'J02'
    | 'J03'
    | 'J04'
    | 'J05'
    | 'J06'
    | 'J07'
    | 'J08'
    | 'J09'
    | 'J10'
    | 'J11'
    | 'J12'
    | 'J13'
    | 'J14'
    | 'J15'
    | 'J16'
    | 'J17'
    | 'J18'
    | 'J19'
    | 'J20'
    | 'J21'
    | 'J22'
    | 'J23'
    | 'J24'
    | 'J25'
    | 'J26'
    | 'J27'
    | 'J28'
    | 'J29'
    | 'J30'
    | 'J31'
    | 'J32'
    | 'J33'
    | 'J34'
    | 'J35'
    | 'J36'
    | 'J37'
    | 'J38'
    | 'J39'
    | 'J40'
    | 'J41'
    | 'J42'
    | 'J43'
    | 'J44'
    | 'J45'
    | 'J46'
    | 'J47';

export class PrefectureEnum {
    constructor(public code: PrefectureEnumCode, public label: string) {}

    /**
     * 都道府県の固有名称を取得します。
     * 北海道を除いて、末尾に付く「都府県」を取り除いたものが返却されます。
     */
    get shortLabel(): string {
        return this.code === PrefectureEnum.Hokkaido.code ? this.label : this.label.substr(0, this.label.length - 1);
    }

    static Hokkaido = new PrefectureEnum('J01', '北海道');
    static Aomori = new PrefectureEnum('J02', '青森県');
    static Iwate = new PrefectureEnum('J03', '岩手県');
    static Miyagi = new PrefectureEnum('J04', '宮城県');
    static Akita = new PrefectureEnum('J05', '秋田県');
    static Yamagata = new PrefectureEnum('J06', '山形県');
    static Fukushima = new PrefectureEnum('J07', '福島県');
    static Ibaraki = new PrefectureEnum('J08', '茨城県');
    static Tochigi = new PrefectureEnum('J09', '栃木県');
    static Gunma = new PrefectureEnum('J10', '群馬県');
    static Saitama = new PrefectureEnum('J11', '埼玉県');
    static Chiba = new PrefectureEnum('J12', '千葉県');
    static Tokyo = new PrefectureEnum('J13', '東京都');
    static Kanagawa = new PrefectureEnum('J14', '神奈川県');
    static Niigata = new PrefectureEnum('J15', '新潟県');
    static Toyama = new PrefectureEnum('J16', '富山県');
    static Ishikawa = new PrefectureEnum('J17', '石川県');
    static Fukui = new PrefectureEnum('J18', '福井県');
    static Yamanashi = new PrefectureEnum('J19', '山梨県');
    static Nagano = new PrefectureEnum('J20', '長野県');
    static Gifu = new PrefectureEnum('J21', '岐阜県');
    static Shizuoka = new PrefectureEnum('J22', '静岡県');
    static Aichi = new PrefectureEnum('J23', '愛知県');
    static Mie = new PrefectureEnum('J24', '三重県');
    static Shiga = new PrefectureEnum('J25', '滋賀県');
    static Kyoto = new PrefectureEnum('J26', '京都府');
    static Osaka = new PrefectureEnum('J27', '大阪府');
    static Hyogo = new PrefectureEnum('J28', '兵庫県');
    static Nara = new PrefectureEnum('J29', '奈良県');
    static Wakayama = new PrefectureEnum('J30', '和歌山県');
    static Tottori = new PrefectureEnum('J31', '鳥取県');
    static Shimane = new PrefectureEnum('J32', '島根県');
    static Okayama = new PrefectureEnum('J33', '岡山県');
    static Hiroshima = new PrefectureEnum('J34', '広島県');
    static Yamaguchi = new PrefectureEnum('J35', '山口県');
    static Tokushima = new PrefectureEnum('J36', '徳島県');
    static Kagawa = new PrefectureEnum('J37', '香川県');
    static Ehime = new PrefectureEnum('J38', '愛媛県');
    static Kochi = new PrefectureEnum('J39', '高知県');
    static Fukuoka = new PrefectureEnum('J40', '福岡県');
    static Saga = new PrefectureEnum('J41', '佐賀県');
    static Nagasaki = new PrefectureEnum('J42', '長崎県');
    static Kumamoto = new PrefectureEnum('J43', '熊本県');
    static Oita = new PrefectureEnum('J44', '大分県');
    static Miyazaki = new PrefectureEnum('J45', '宮崎県');
    static Kagoshima = new PrefectureEnum('J46', '鹿児島県');
    static Okinawa = new PrefectureEnum('J47', '沖縄県');

    static valueOf = (code: PrefectureEnumCode): PrefectureEnum | undefined =>
        PrefectureEnum.values.find((value) => value.code === code);
    static values = [
        PrefectureEnum.Hokkaido,
        PrefectureEnum.Aomori,
        PrefectureEnum.Iwate,
        PrefectureEnum.Miyagi,
        PrefectureEnum.Akita,
        PrefectureEnum.Yamagata,
        PrefectureEnum.Fukushima,
        PrefectureEnum.Ibaraki,
        PrefectureEnum.Tochigi,
        PrefectureEnum.Gunma,
        PrefectureEnum.Saitama,
        PrefectureEnum.Chiba,
        PrefectureEnum.Tokyo,
        PrefectureEnum.Kanagawa,
        PrefectureEnum.Niigata,
        PrefectureEnum.Toyama,
        PrefectureEnum.Ishikawa,
        PrefectureEnum.Fukui,
        PrefectureEnum.Yamanashi,
        PrefectureEnum.Nagano,
        PrefectureEnum.Gifu,
        PrefectureEnum.Shizuoka,
        PrefectureEnum.Aichi,
        PrefectureEnum.Mie,
        PrefectureEnum.Shiga,
        PrefectureEnum.Kyoto,
        PrefectureEnum.Osaka,
        PrefectureEnum.Hyogo,
        PrefectureEnum.Nara,
        PrefectureEnum.Wakayama,
        PrefectureEnum.Tottori,
        PrefectureEnum.Shimane,
        PrefectureEnum.Okayama,
        PrefectureEnum.Hiroshima,
        PrefectureEnum.Yamaguchi,
        PrefectureEnum.Tokushima,
        PrefectureEnum.Kagawa,
        PrefectureEnum.Ehime,
        PrefectureEnum.Kochi,
        PrefectureEnum.Fukuoka,
        PrefectureEnum.Saga,
        PrefectureEnum.Nagasaki,
        PrefectureEnum.Kumamoto,
        PrefectureEnum.Oita,
        PrefectureEnum.Miyazaki,
        PrefectureEnum.Kagoshima,
        PrefectureEnum.Okinawa,
    ];
}
