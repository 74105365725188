import {
    CompanyApplication,
    CompanyBilling,
    CompanyBillingTerm,
    CompanyConfidence,
    CompanyConfidenceUpdateForm,
    CompanyContract,
    CompanyDeleteForm, CompanyDispatchedDriverList, CompanyDispatchedTruckList, CompanyDispatchHistoryRegisterForm,
    CompanyGuaranteedAmount, CompanyInvoice, OfficialCompany,
    CompanyPartnerProfileListForm,
    CompanyPayment,
    CompanyPremiumRegisterForm,
    CompanyPremiumRevokeForm,
    CompanyProfile,
    CompanyProfileExtraUpdateForm,
    CompanyProfileList,
    CompanyProfileListForm,
    CompanyProfileSearchForm,
    CompanyProfileUpdateForm,
    CompanyStaffNameSuggestionDeleteForm,
    CompanyStaffNameSuggestionList,
    CompanyStaffNameSuggestionRegisterForm,
    CompanyStatistics,
    CompanyStatus,
    CompanyTransfer,
    CompanyTransferUpdateForm,
    CompanyMyProfile
} from '@/vuex/modules/company/types';
import {
    addDelay,
    appAxios,
    appAxiosWithoutErrorMessage,
    isSuccess,
    JsonResponseBean,
    wrapClass
} from './base';
import { BaggageList } from '@/vuex/modules/baggage/types';
import { TruckList } from '@/vuex/modules/truck/types';

export const companyApi = {
    /**
     * 自社の企業ステータスを取得します。
     */
    myStatus(): Promise<CompanyStatus> {
        return appAxios.get<JsonResponseBean<CompanyStatus>>('/api/company/status').then(isSuccess);
    },
    /**
     * 退会します。
     */
    delete(param: CompanyDeleteForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/company/delete', param).then(isSuccess);
    },
    /**
     * 自社の企業プロフィールを取得します。
     */
    myProfile(): Promise<CompanyMyProfile> {
        return appAxios.get<JsonResponseBean<CompanyProfile>>('/api/company/profile')
            .then(isSuccess)
            .then(item => item as CompanyMyProfile)
            .then(item => wrapClass(CompanyMyProfile, item));
    },
    /**
     * 企業プロフィールを取得します。
     */
    profile(param: number): Promise<CompanyProfile> {
        return appAxios.get<JsonResponseBean<CompanyProfile>>(`/api/company/${ param }/profile`)
            .then(isSuccess)
            .then(item => item as CompanyProfile)
            .then(item => wrapClass(CompanyProfile, item));
    },
    /**
     * 法人を取得します。
     */
    officialCompany(param: number): Promise<OfficialCompany | undefined> {
        return appAxios.get<JsonResponseBean<OfficialCompany>>(`/api/company/${ param }/corporation`)
            .then(isSuccess)
            .then(item => item as OfficialCompany)
            .then(item => wrapClass(OfficialCompany, item))
            .catch(() => undefined);
    },
    /**
     * 企業プロフィールを検索します。
     */
    searchProfile(param: CompanyProfileSearchForm): Promise<CompanyProfileList> {
        return appAxios.post<JsonResponseBean<CompanyProfile[]>>('/api/company/profile/search', param)
            .then(isSuccess)
            .then(item => item as CompanyProfileList)
            .then(item => wrapClass(CompanyProfileList, {
                ...item,
                data: item.data.map(each => wrapClass(CompanyProfile, each))
            }));
    },
    /**
     * 企業プロフィールを一覧取得します。
     */
    listProfile(param: CompanyProfileListForm): Promise<CompanyProfile[]> {
        return appAxios.post<JsonResponseBean<CompanyProfile[]>>('/api/company/profile/list', param)
            .then(isSuccess)
            .then(item => item as CompanyProfile[])
            .then(item => item.map(each => wrapClass(CompanyProfile, each)));
    },
    /**
     * パートナー企業プロフィールを一覧取得します。
     */
    listPartnerProfile(param: CompanyPartnerProfileListForm): Promise<CompanyProfile[]> {
        return appAxios
            .post<JsonResponseBean<CompanyProfile[]>>('/api/agreement/company/profile', param)
            .then(isSuccess)
            .then(item => item as CompanyProfile[])
            .then(item => item.map(each => wrapClass(CompanyProfile, each)));
    },
    /**
     * パートナー企業プロフィールを取得します。
     */
    partnerProfile(agreementId: number): Promise<CompanyProfile> {
        return appAxios.get(`/api/agreement/${ agreementId }/company/profile`).then(isSuccess);
    },
    /**
     * 企業プロフィールを更新します。
     */
    updateProfile(param: CompanyProfileUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/company/profile/update', param).then(isSuccess);
    },
    /**
     * 企業拡張プロフィールを更新します。
     */
    updateProfileExtra(param: CompanyProfileExtraUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/company/profile/extra/update', param).then(isSuccess);
    },
    /**
     * 自社の信用情報を取得します。
     */
    myConfidence(): Promise<CompanyConfidence> {
        return appAxios.get<JsonResponseBean<CompanyConfidence>>('/api/company/confidence').then(isSuccess);
    },
    /**
     * 企業信用情報を取得します。
     */
    confidence(param: number): Promise<CompanyConfidence> {
        return appAxios.get<JsonResponseBean<CompanyConfidence>>(`/api/company/${ param }/confidence`).then(isSuccess);
    },
    /**
     * 自社の統計情報を取得します。
     */
    myStatistics(): Promise<CompanyStatistics> {
        return appAxios.get<JsonResponseBean<CompanyStatistics>>('/api/company/statistics').then(isSuccess);
    },
    /**
     * 企業統計情報を取得します。
     */
    statistics(param: number): Promise<CompanyStatistics> {
        return appAxios.get<JsonResponseBean<CompanyStatistics>>(`/api/company/${ param }/statistics`).then(isSuccess);
    },
    /**
     * 信用情報を更新します。
     */
    updateConfidence(param: CompanyConfidenceUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/company/confidence/update', param).then(isSuccess);
    },
    /**
     * 支払情報を取得します。
     */
    payment(): Promise<CompanyPayment> {
        return appAxios.get<JsonResponseBean<CompanyPayment>>('/api/company/payment').then(isSuccess);
    },
    /**
     * プレミアムプランを申し込みます。
     */
    registerPremium(param: CompanyPremiumRegisterForm): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/company/application/premium/register`, param)
            .then(addDelay) // NOTE: バックエンド側でcontract周りを変更する処理の都合上、遅延処理を加えている
            .then(isSuccess);
    },
    /**
     * プレミアムプランを停止します。
     */
    revokePremium(param: CompanyPremiumRevokeForm): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/company/contract/premium/revoke`, param)
            .then(addDelay) // NOTE: バックエンド側でcontract周りを変更する処理の都合上、遅延処理を加えている
            .then(isSuccess);
    },
    /**
     * 運賃全額保証サービスを申込みます。
     */
    registerGuarantee(): Promise<void> {
        return appAxios
            .get<JsonResponseBean>('/api/company/application/guarantee/register')
            .then(addDelay) // NOTE: バックエンド側でcontract周りを変更する処理の都合上、遅延処理を加えている
            .then(isSuccess);
    },
    /**
     * 運賃全額保証サービスを停止します。
     */
    revokeGuarantee(): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/company/contract/guarantee/revoke`, {})
            .then(addDelay) // NOTE: バックエンド側でcontract周りを変更する処理の都合上、遅延処理を加えている
            .then(isSuccess);
    },
    /**
     * 企業の申込み一覧を取得します。
     */
    applications(): Promise<CompanyApplication[]> {
        return appAxios.get<JsonResponseBean<CompanyApplication[]>>(`/api/company/application`)
            .then(isSuccess);
    },
    /**
     * 企業契約一覧を取得します。
     */
    contract(): Promise<CompanyContract[]> {
        return appAxios.get<JsonResponseBean<CompanyContract[]>>('/api/company/contract').then(isSuccess);
    },
    /**
     * 企業何年何月契約一覧を取得します。
     */
    contractYearMonth(year: number, month: number): Promise<CompanyContract[]> {
        return appAxios
            .get<JsonResponseBean<CompanyContract[]>>(`/api/company/contract/${ year }/${ month }`)
            .then(isSuccess);
    },
    /**
     * 全期間のおまかせ請求契約一覧を取得します。
     */
    allEasyPaymentContract(): Promise<CompanyContract[]> {
        return appAxios.get<JsonResponseBean<CompanyContract[]>>('/api/company/contract/easy-payment').then(isSuccess);
    },
    /**
     * 請求書発行期間を取得します。
     */
    billingTerm(): Promise<CompanyBillingTerm> {
        return appAxios.get<JsonResponseBean<CompanyBillingTerm>>('/api/company/billing/term').then(isSuccess);
    },
    /**
     * 請求一覧を取得します。
     */
    billing(year: number, month?: number): Promise<CompanyBilling[]> {
        return appAxios
            .get<JsonResponseBean<CompanyBilling[]>>(`/api/company/billing/${ year }${ month ? '/' + month : '' }`)
            .then(isSuccess);
    },
    /**
     * 請求書ダウンロード可否を取得します。
     */
    invoice(year: number, month: number): Promise<CompanyInvoice> {
        return appAxios
            .get<JsonResponseBean<CompanyInvoice>>(`/api/company/invoice/${ year }/${ month }`)
            .then(isSuccess);
    },
    /**
     * 請求書ダウンロードURLを発行します。
     */
    issueInvoiceUrl(year: number, month: number): Promise<string> {
        return appAxios
            .get<JsonResponseBean<string>>(`/api/company/invoice/${ year }/${ month }/download`)
            .then(isSuccess);
    },
    /**
     * 運賃全額保証サービスの月次利用額を取得します。
     */
    guaranteedAmount(year: number, month: number): Promise<CompanyGuaranteedAmount> {
        return appAxios
            .get<JsonResponseBean<CompanyGuaranteedAmount>>(`/api/company/guaranteed/${ year }/${ month }`)
            .then(isSuccess);
    },
    /**
     * 企業担当者名おすすめ一覧をロードします。
     */
    loadStaffNameSuggestions(): Promise<CompanyStaffNameSuggestionList> {
        return appAxiosWithoutErrorMessage
            .get<JsonResponseBean<CompanyStatistics>>(`/api/company/staff/name/suggestion/list`)
            .then(isSuccess);
    },
    /**
     * 企業担当者名おすすめを登録します。
     */
    registerStaffNameSuggestion(param: CompanyStaffNameSuggestionRegisterForm): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/company/staff/name/suggestion/register`, param)
            .then(isSuccess);
    },
    /**
     * 企業担当者名おすすめを削除します。
     */
    deleteStaffNameSuggestion(param: CompanyStaffNameSuggestionDeleteForm): Promise<void> {
        return appAxios
            .post<JsonResponseBean<void>>(`/api/company/staff/name/suggestion/delete`, param)
            .then(isSuccess);
    },
    /**
     * 配車トラック履歴一覧をロードします。
     */
    loadDispatchedTruckHistories(): Promise<CompanyDispatchedTruckList> {
        return appAxiosWithoutErrorMessage
            .get<JsonResponseBean<CompanyDispatchedTruckList>>(`/api/company/dispatch/history/truck`)
            .then(isSuccess);
    },
    /**
     * 配車ドライバー履歴一覧をロードします。
     */
    loadDispatchedDriverHistories(): Promise<CompanyDispatchedDriverList> {
        return appAxiosWithoutErrorMessage
            .get<JsonResponseBean<CompanyDispatchedDriverList>>(`/api/company/dispatch/history/driver`)
            .then(isSuccess);
    },
    /**
     * 配車履歴を登録します。
     */
    registerDispatchHistory(param: CompanyDispatchHistoryRegisterForm): Promise<void> {
        return appAxiosWithoutErrorMessage
            .post<JsonResponseBean<void>>(`/api/company/dispatch/history`, param)
            .then(isSuccess);
    },
    /**
     * 配車履歴を削除します。
     */
    deleteDispatchHistory(param: CompanyDispatchHistoryRegisterForm): Promise<void> {
        return appAxiosWithoutErrorMessage
            .post<JsonResponseBean<void>>(`/api/company/dispatch/history/delete`, param)
            .then(isSuccess);
    },
    /**
     * 企業の荷物一覧を取得します。
     */
    baggageList(id: number): Promise<BaggageList> {
        return appAxios.get<JsonResponseBean<BaggageList>>(`/api/company/${ id }/baggage`).then(isSuccess);
    },
    /**
     * 企業の空車一覧取得します。
     */
    truckList(id: number): Promise<TruckList> {
        return appAxios.get<JsonResponseBean<TruckList>>(`/api/company/${ id }/truck`).then(isSuccess);
    },
    /**
     * 企業振込情報を取得します。
     */
    transfer(): Promise<CompanyTransfer> {
        return appAxios.get<JsonResponseBean<CompanyProfile>>('/api/company/transfer')
            .then(isSuccess)
            .then(item => item as CompanyTransfer)
            .then(item => wrapClass(CompanyTransfer, item));
    },
    /**
     * 企業振込情報を更新します。
     */
    updateTransfer(param: CompanyTransferUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>('/api/company/transfer/update', param).then(isSuccess);
    },
};
