import { ExcludeMethods } from '@/types/lang';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';

export interface FormValidationRule<T> {
    message?: FormValidateMessage;

    /**
     * built-in validation type, available options: https://github.com/yiminghe/async-validator#type
     * @default 'string'
     * @type string
     */
    type?: 'string' | 'number' | 'boolean' | 'method' | 'regexp' | 'integer' | 'float' | 'array' | 'object' | 'enum' | 'date' | 'url' | 'hex' | 'email' | 'any';

    /**
     * indicates whether field is required
     * @default false
     * @type boolean
     */
    required?: boolean;

    /**
     * treat required fields that only contain whitespace as errors
     * @default false
     * @type boolean
     */
    whitespace?: boolean;

    /**
     * validate the exact length of a field
     * @type number
     */
    len?: number;

    /**
     * validate the min length of a field
     * @type number
     */
    min?: number;

    /**
     * validate the max length of a field
     * @type number
     */
    max?: number;

    /**
     * validate the value from a list of possible values
     * @type string | string[]
     */
    enum?: string | string[];

    /**
     * validate from a regular expression
     * @type boolean
     */
    pattern?: RegExp;

    /**
     * transform a value before validation
     * @type Function
     */
    transform?: (value: T | undefined) => T;

    /**
     * custom validate function (Note: callback must be called)
     * @type Function
     */
    validator?: (rule: any, value: T | undefined, callback: ((message?: string) => void)) => void;
}

export type FormValidateMessage = string | (() => string);

type BaseFormValidator<T> = {
    [P in keyof T]: FormValidationRule<T[P]>[];
};

/**
 * FormのValidator。Partialにしないと private フィールドのValidatorまで必要になってしまう・・・
 */
export type FormValidator<T> = Partial<BaseFormValidator<ExcludeMethods<T>>>;

export interface FormValidatable<T> {
    validator: () => FormValidator<T>;
}

export type AntValidationRule = {
    [K in string | symbol | number]: ValidationRule[];
};


export class FormValidateUtil {
    static toAntValidator<T>(form: FormValidatable<T>): AntValidationRule {

        return form.validator() as AntValidationRule;
    }
}

export type ValidationContext<T> = () => T;
