import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { CustomRow } from '@/types/ant-design';
import { Util } from '@/util';
import type { BaggageListTabKey } from '@/pages/Baggage/List/script';
// @ts-ignore
import UiSortPaginationControl from '@/components/UI/SortPaginationControl';
// @ts-ignore
import UiTableRowBadge from '@/components/UI/TableRowBadge';
// @ts-ignore
import UiDateTimeLabelText from '@/components/UI/DateTimeLabelText';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { namespace } from 'vuex-class';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { Column } from 'ant-design-vue/types/table/column';
import _ from 'lodash';

type AntColumn = Omit<Column, keyof Vue>;
type CustomOption = { single: number; multiple: number; border?: boolean; title: string; };
type FlexibleColumn = AntColumn & CustomOption;

/**
 * 列定義
 */
const COLUMN_DEFINITION: Array<FlexibleColumn> = [
    { single: 102, multiple: 102, key: 'id', title: '荷物番号' },
    { single: 150, multiple: 0, key: 'departureDate', title: '発日時', align: 'left', border: false },
    { single: 150, multiple: 0, key: 'arrivalDate', title: '着日時', align: 'left' },
    { single: 135, multiple: 0, key: 'departureLocation', title: '発地', align: 'left', border: false },
    { single: 18, multiple: 0, key: 'arrow', title: '', border: false },
    { single: 159, multiple: 0, key: 'arrivalLocation', title: '着地', align: 'left' },
    { single: 0, multiple: 170, key: 'departure', title: '発日時・発地', align: 'left' },
    { single: 0, multiple: 170, key: 'arrival', title: '着日時・着地', align: 'left' },
    { single: 128, multiple: 128, key: 'type', title: '荷種' },
    { single: 112, multiple: 112, key: 'freight', title: '運賃', align: 'center' },
    { single: 100, multiple: 100, key: 'viewersCount', title: '閲覧人数', align: 'center' },
    { single: 60, multiple: 60, key: 'truckWeight', title: '重量', align: 'center' },
    { single: 108, multiple: 108, key: 'truckModel', title: '車種' },
    { single: 80, multiple: 80, key: 'staffName', title: '担当' },
    { single: 180, multiple: 180, key: 'description', title: '備考' },
];

/**
 * single/multipleに応じた列定義を取得します。
 */
const columnDefinition = (single: boolean) =>
    COLUMN_DEFINITION.filter(each => single ? each.single > 0 : each.multiple > 0);

/**
 * 列幅を比率で取得します。
 */
const widthRatio = (definition: FlexibleColumn, single: boolean) => {
    if (single) {
        const sum = _.sumBy(COLUMN_DEFINITION, each => each.single);
        return definition.single / sum * 100;
    } else {
        const sum = _.sumBy(COLUMN_DEFINITION, each => each.multiple);
        return definition.multiple / sum * 100;
    }
};

const baggageMod = namespace('baggage');

@Component({
    components: {
        UiSortPaginationControl,
        UiTableRowBadge,
        UiDateTimeLabelText,
    },
})
export default class BaggageListView extends Vue {
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_VIEW_MEMBER_COUNT_LIST)
    readonly BAGGAGE_VIEW_MEMBER_COUNT?: Array<baggageTypes.BaggageViewMemberCount>;
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.BaggageList;
    @Prop()
    declare readonly activeTabKey?: BaggageListTabKey;
    @Prop()
    declare readonly sortKey?: string;
    @Prop()
    declare readonly sortAscending?: boolean;
    @Prop({ default: false })
    declare readonly loading: boolean;

    get showAsSingle(): boolean {
        return this.tableWidth > Const.FLEXIBLE_TABLE_MODE_THRESHOLD;
    }

    // ======================================================
    // Data
    // ======================================================
    initialized = false;
    tableWidth = 0;
    columns: Array<FlexibleColumn> = [];

    private invalidateTableLazy = _.debounce((that: BaggageListView) => that.updateColumn(), 200);

    sortOptions = Const.baggageSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    rowClassName = (record: types.Baggage): string => {
        const styleClasses = ['app-table-row'];
        const { baggageId } = this.$route.query;
        if (this.activeTabKey !== 'expired') {
            styleClasses.push('app-table-row--clickable');
        }
        if (`${record.id}` === baggageId) {
            styleClasses.push('app-table-row--selected');
        }
        return styleClasses.join(' ');
    };

    /**
     * 荷物編集アクションの操作可否を取得します。
     */
    get canEdit(): boolean {
        return this.activeTabKey === 'opened';
    }

    /**
     * 荷物コピーアクションの操作可否を取得します。
     */
    get canCopy(): boolean {
        return this.activeTabKey === 'opened' || this.activeTabKey === 'expired';
    }

    /**
     * 荷物取り下げアクションの操作可否を取得します。
     */
    get canCancel(): boolean {
        return this.activeTabKey === 'opened';
    }

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        this.tableWidth = this.$el.clientWidth;

        this.updateColumn();

        window.addEventListener('resize', this.onResizeWindow);

        this.initialized = true;
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.onResizeWindow);
    }

    /**
     * 行をカスタマイズする。
     */
    customRow(record: types.Baggage): CustomRow {
        return {
            on: {
                click: (event) => {
                    // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
                    event.stopPropagation();
                    this.$emit('clickRow', record.id);
                },
            },
        };
    }

    freightText(record: types.Baggage): string {
        if (!record.freight) return '要相談';
        return `${Util.formatNumber(record.freight)}円`;
    }

    /**
     * 荷物詳細の閲覧会員数を取得します。
     */
    viewerCount(record: baggageTypes.Baggage): number {
        return this.BAGGAGE_VIEW_MEMBER_COUNT?.find((memberCount) => memberCount.baggageId == record.id)?.memberCount ?? 0;
    }

    departureText(record: types.Baggage): string {
        return DeliveryDateTime.of(record.departureMin, record.departureMax)?.format() ?? '';
    }

    arrivalText(record: types.Baggage): string {
        return DeliveryDateTime.of(record.arrivalMin, record.arrivalMax)?.format() ?? '';
    }

    /**
     * ページネーションでソートキーか並び順を変更した時に呼び出されます。
     */
    onChangeSort(sortKey: string, sortAscending: boolean): void {
        this.$emit('onChangeSort', { sortKey, sortAscending });
    }

    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('onChangePage', { pageNo, pageSize });
    }

    /**
     * 荷物を編集するボタンが押下された際に呼び出されます。
     */
    onClickEditBaggage(event: MouseEvent, baggageId: number): void {
        // `customRow` で定義された荷物詳細クリックイベントまで伝播しないように制御
        event.stopPropagation();
        this.$emit('editBaggage', baggageId);
    }

    /**
     * 荷物をコピーするボタンが押下された際に呼び出されます。
     */
    onClickCopyBaggage(event: MouseEvent, baggageId: number): void {
        // `customRow` で定義された荷物詳細クリックイベントまで伝播しないように制御
        event.stopPropagation();
        this.$emit('copyBaggage', baggageId);
    }

    /**
     * 荷物を削除するボタンが押下された際に呼び出されます。
     */
    onClickDeleteBaggage(event: MouseEvent, baggageId: number): void {
        // `customRow` で定義された荷物詳細クリックイベントまで伝播しないように制御
        event.stopPropagation();
        this.$emit('deleteBaggage', baggageId);
    }

    /**
     * 「商談中」か否かを取得します。
     */
    underNegotiation(baggage: baggageTypes.Baggage): boolean {
        const isOpened = BaggageStatusEnum.valueOf(baggage.status.code)?.isOpened() ?? false;
        return isOpened && baggage.underNegotiation;
    }

    /**
     * 「商談中にする」の操作可否を取得します。
     */
    canMarkUnderNegotiation(baggage: baggageTypes.Baggage): boolean {
        const isOpened = BaggageStatusEnum.valueOf(baggage.status.code)?.isOpened() ?? false;
        return isOpened && !baggage.underNegotiation;
    }

    /**
     * 「商談中を解除」の操作可否を取得します。
     */
    canUnmarkUnderNegotiation(baggage: baggageTypes.Baggage): boolean {
        const isOpened = BaggageStatusEnum.valueOf(baggage.status.code)?.isOpened() ?? false;
        return isOpened && baggage.underNegotiation;
    }

    /**
     * 「商談中にする」ボタンが押下された際に呼び出されます。
     */
    onClickMarkUnderNegotiation(event: MouseEvent, baggageId: number): void {
        // `customRow` で定義された荷物詳細クリックイベントまで伝播しないように制御
        event.stopPropagation();
        this.$emit('markUnderNegotiation', baggageId);
    }

    /**
     * 「商談中を解除」ボタンが押下された際に呼び出されます。
     */
    onClickUnmarkUnderNegotiation(event: MouseEvent, baggageId: number): void {
        // `customRow` で定義された荷物詳細クリックイベントまで伝播しないように制御
        event.stopPropagation();
        this.$emit('unmarkUnderNegotiation', baggageId);
    }

    /**
     * Windowsサイズが変化した際に呼び出されます。
     */
    onResizeWindow(): void {
        this.tableWidth = this.$el.clientWidth;

        this.invalidateTableLazy(this);
    }

    /**
     * テーブルカラムを更新します。
     */
    private updateColumn(): void {
        this.columns = columnDefinition(this.showAsSingle).map(column => ({
            ...column,
            scopedSlots: { customRender: column.key },
            dataIndex: column.key,
            width: widthRatio(column, this.showAsSingle),
            className: column.border === false ? 'no-border-right' : '',
        }));
    }
}
