<script setup lang="ts">
import { TruckType } from '@/_components/ui/types/truck-type';
import { computed } from 'vue';
import _ from 'lodash';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import {
    useTruckModelOptions,
    useTruckWeightOptions
} from '@/composables/helper/option-helper';
import { useFormModelItem } from '@/composables/form-helper';

const weightOptions = useTruckWeightOptions();
const modelOptions = useTruckModelOptions();

const props = withDefaults(defineProps<{
    value: TruckType,
    propName: string,
    optionType: 'truck' | 'baggage',
    label?: string,
    allowClear?: boolean,
}>(), {
    label: '車両',
    allowClear: true,
});

const weights = computed(() => {
    switch (props.optionType) {
        case 'truck':
            return weightOptions.registrableForTruckOptions;
        case 'baggage':
            return weightOptions.registrableForBaggageOptions;
        default:
            return [];
    }
});

const models = computed(() => {
    switch (props.optionType) {
        case 'truck':
            return modelOptions.registrableForTruckOptions;
        case 'baggage':
            return modelOptions.registrableForBaggageOptions;
        default:
            return [];
    }
});

const emits = defineEmits<{
    (e: 'input', value: TruckType): void,
}>();

const truckWeight = computed<string | undefined>({
    get: () => props.value.truckWeight?.code,
    set: (value: string | undefined) => {
        const truckType = _.cloneDeep(props.value);
        truckType.truckWeight = value ? TruckWeightEnum.valueOf(value as TruckWeightEnumCode) : undefined;
        emits('input', truckType);
    }
});

const truckModel = computed<string | undefined>({
    get: () => props.value.truckModel?.code,
    set: (value: string | undefined) => {
        const truckType = _.cloneDeep(props.value);
        truckType.truckModel = value ? TruckModelEnum.valueOf(value as TruckModelEnumCode) : undefined;
        emits('input', truckType);
    }
});
const { formModelItem, onChange } = useFormModelItem();

</script>

<template>
    <a-form-model-item :label="label"
                       :wrapper-col="{ span: 16 }"
                       :auto-link="false"
                       :prop="propName"
                       ref="formModelItem">
        <a-input-group compact>
            <a-select style="width: 50%"
                      placeholder="重量"
                      :options="weights"
                      :allow-clear="props.allowClear"
                      v-model="truckWeight"
                      @change="onChange"
            />
            <a-select style="width: 50%"
                      placeholder="車種"
                      :options="models"
                      :allow-clear="props.allowClear"
                      v-model="truckModel"
                      @change="onChange"
            />
        </a-input-group>
    </a-form-model-item>
</template>

<style scoped lang="less">

</style>
