import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';

@Component
export default class BaggageCategory extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get relocationBaggageLabel(): string {
        return this.baggage?.category?.code === BaggageCategoryEnum.Relocation.code ? '○' : '×';
    }
}
