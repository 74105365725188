import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageRegisterForm } from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
@Component({
    components: {
        TbxIcon
    }
})
export default class BaggageHighwayFareRegister extends Vue {
    choice = NegotiationTypeEnum.values.map(each => {
        return { label: each.label, value: each.code.toString() };
    });

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: BaggageRegisterForm;

    /**
     * 連絡方法の支払い有無
     */
    get negotiationType(): string | undefined {

        return this.value?.negotiationType?.code;
    }

    set negotiationType(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 連絡方法を書き換え
        cloned.negotiationType = NegotiationTypeEnum.valueOf(newValue);
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get defaultValue(): string {
        return 'TEL';
    }

    // ======================================================
    // Functions
    // ======================================================
    private validate(callback: (message?: string) => void): void {
        if (this.value?.negotiationType === undefined) {
            callback('選択してください。');
        } else {
            callback();
        }
    }
}
