import store from '@/vuex/store';
import * as types from '@/vuex/modules/truck/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { Modal, notification } from 'ant-design-vue';
import { Route } from 'vue-router/types/router';
import router from '@/router';

// ======================================================
// Routing Helpers
// ======================================================
/**
 * 登録した空車一覧画面へ遷移します。
 */
export const goToTruckList = (): Promise<Route> =>
    router.push({ name: 'TruckList' });

// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 空車情報を登録します。
 */
export const registerTruck = (form: types.TruckRegisterForm): Promise<number> =>
    store.dispatch(`truck/${ types.ACTION.REGISTER_TRUCK }`, form);

/**
 * 空車をロードします。
 */
export const loadTruck = (id: number): Promise<void> =>
    store.dispatch(`truck/${ types.ACTION.LOAD_TRUCK }`, id);

/**
 * 空車をVuex上からクリアします。
 */
export const clearTruck = (): Promise<void> =>
    store.dispatch(`truck/${ types.ACTION.CLEAR_TRUCK }`);

/**
 * 企業担当者名おすすめ一覧をロードします。
 */
export const loadCompanyStaffNameSuggestions = (): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.LOAD_STAFF_NAME_SUGGESTIONS }`);

/**
 * 企業担当者名を登録します。
 */
export const registerCompanyStaffNameSuggestion = (staffName: string): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.REGISTER_STAFF_NAME_SUGGESTION }`, { staffName });

/**
 * 企業担当者名おすすめ項目を削除します。
 */
export const deleteCompanyStaffNameSuggestion = (staffName: string): Promise<void> =>
    store.dispatch(`company/${ companyTypes.ACTION.DELETE_STAFF_NAME_SUGGESTION }`, { staffName });

// ======================================================
// Notification Helpers
// ======================================================
const NOTIFICATION_KEY_FAILED_TO_DEL_STAFF = 'DELETE_STAFF_NAME_SUGGESTION_ERROR';
const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_TRUCK_FAILED';

/**
 * 担当者履歴の削除失敗を通知します。
 */
export const notifyFailedToDeleteStaff = (): void => notification.error({
    key: NOTIFICATION_KEY_FAILED_TO_DEL_STAFF,
    message: '担当者の履歴を削除できませんでした。',
    description: '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
});

/**
 * 担当者履歴の削除失敗通知を閉じます。
 */
export const closeFailedToDeleteStaffNotification = (): void =>
    notification.close(NOTIFICATION_KEY_FAILED_TO_DEL_STAFF);

/**
 * 空車登録失敗を通知します。
 */
export const notifyFailedToRegister = (): void => notification.error({
    key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
    message: '空車情報を登録できませんでした。',
    description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
});

/**
 * 空車登録失敗通知を閉じます。
 */
export const closeFailedToRegisterNotification = (): void =>
    notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

// ======================================================
// Confirmation Helpers
// ======================================================
/**
 * 入力内容クリア確認モーダルを表示します。
 */
export const confirmClearForm = (): Promise<boolean> => new Promise<boolean>((resolve) => Modal.confirm({
    title: '入力内容を削除してよろしいですか？',
    onOk: () => resolve(true),
    onCancel: () => resolve(false),
    okText: '削除',
    cancelText: 'キャンセル',
}));
