import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class AgreementFreightRegister extends Vue {
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '運賃を入力してください。',
        },
        {
            pattern: /^[0-9０-９]*$/,
            message: '運賃は数字で入力してください。',
        },
        {
            transform: (value: number | string): string =>
                typeof value === 'number' ? `${value}` : Util.toDigits(value),
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule: ValidationRule, value: string, callback: Function) =>
                this.validate(value, callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementForm;

    /**
     * 運賃
     */
    get freight(): string {
        return this.value?.freight ?? '';
    }

    set freight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.freight = newValue;

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 運賃のフォーカスが外れた際に呼び出されます。
     */
    onBlurFreight(): void {
        const parsed = Util.parseFreightString(this.freight);
        this.freight = isNaN(Util.toNumber(parsed)) ? '' : `${parsed}`;
    }

    /**
     * バリデーションを行う。
     */
    private validate(value: string, callback: (message?: string) => void): void {
        const freight = Number(value);
        if (_.isNaN(freight)) {
            callback(`運賃は数字で入力してください。`);
        } else if (freight > 0 && freight <= Const.MAX_FREIGHT) {
            callback();
        } else {
            callback(`運賃は${Const.MAX_FREIGHT / 100000000}億円以内で入力してください。`);
        }
    }
}
