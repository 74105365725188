import { appAxios, isSuccess, JsonResponseBean } from './base';

export const embedTagApi = {
    /**
     * 埋込tagを取得します。
     */
    find(name: string): Promise<string | undefined> {
        return appAxios.get<JsonResponseBean<string | undefined>>('/api/embed-tag', { params: { name: name } }).then(isSuccess);
    },
};
