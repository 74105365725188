<script setup lang="ts">
import { computed } from 'vue';
import { useBaggageTemperatureZoneOptions } from '@/composables/option-helper';
import { BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';

const props = defineProps<{
    value?: BaggageTemperatureZoneEnumCode,
}>();

const emit = defineEmits<{
    (e: 'input', value: BaggageTemperatureZoneEnumCode | undefined): void,
}>();

const options = useBaggageTemperatureZoneOptions();

const temperatureZone = computed<BaggageTemperatureZoneEnumCode | undefined>({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});

</script>

<template>
    <a-form-model-item label="温度帯" prop="temperatureZone">
        <a-select placeholder="温度帯を選択"
                  :options="options.options"
                  v-model="temperatureZone"/>
    </a-form-model-item>
</template>

<style scoped lang="less">
</style>
