import { Enum } from '@/types/enum';
import { CompanyLockTypeCode, CompanyLockTypeEnum } from '@/enums/company-lock.enum';
import { DateTimeValue } from '@/models/vo/datetime';
import { CompanyStatus, CompanyStatusModel } from '@/models/company-status';

export interface Entitlement {
    companyStatus: CompanyStatus;
    lock?: { lockTm: string, lockType: Enum<CompanyLockTypeCode> },
    searchCompany: boolean,
    existsEasyPaymentInPast: boolean,
    easyPaymentContract?: Term,
    settlementProxy?: Term,
}

type EntitlementChecker = (now: DateTimeValue) => boolean;

/**
 * 期間依存権限リストモデル
 */
type EntitlementTermListModel = {
    // おまかせ請求契約期間
    easyPaymentContract?: TermModel
    // 決済代行利用可能期間
    settlementProxy?: TermModel
};

/**
 * 機能権限リストモデル
 */
type EntitlementFeatureListModel = {
    // 企業検索できるか否か
    searchCompany: EntitlementChecker,
    // 自社情報設定を閲覧できるか否か
    viewSelfProfileSetting: EntitlementChecker,
    // 自社会社概要を印刷できるか否か
    printSelfProfile: EntitlementChecker,
    // 入金遅延連絡できるか否か
    reportLatePayment: EntitlementChecker,
    // 有料サービスを停止できるか否か
    revokePremiumPlan: EntitlementChecker,
    // 運賃全額保証を停止できるか否か
    revokeGuaranteePlan: EntitlementChecker,
    // 取引を閲覧できるか否か
    viewSettlement: EntitlementChecker,
};

export class EntitlementModel {
    private readonly companyStatus: CompanyStatusModel;
    private readonly lock?: CompanyLock;
    private readonly searchCompany: boolean;
    private readonly existsEasyPaymentInPast: boolean;
    // 期間権限
    private readonly terms: EntitlementTermListModel;
    // 機能権限
    private readonly features: EntitlementFeatureListModel = {
        searchCompany: (_: DateTimeValue) => this.searchCompany,
        viewSelfProfileSetting: (now: DateTimeValue): boolean => !this.locked(now),
        printSelfProfile: (now: DateTimeValue) => !this.companyStatus.isJudging && !this.locked(now),
        reportLatePayment: (now: DateTimeValue) => !this.locked(now),
        revokePremiumPlan: (now: DateTimeValue) => !this.locked(now),
        revokeGuaranteePlan: (now: DateTimeValue) => !this.locked(now),
        viewSettlement: (now: DateTimeValue) =>
            this.existsEasyPaymentInPast || this.active('easyPaymentContract', now),
    };

    constructor(param: Entitlement) {
        this.companyStatus = new CompanyStatusModel(param.companyStatus);
        this.lock = param.lock !== undefined ? new CompanyLock(param.lock) : undefined;
        this.searchCompany = param.searchCompany;
        this.existsEasyPaymentInPast = param.existsEasyPaymentInPast;
        this.terms = {
            easyPaymentContract: TermModel.of(param.easyPaymentContract),
            settlementProxy: TermModel.of(param.settlementProxy),
        };
    }

    /**
     * ロックが稼働中か否かを取得する。
     */
    locked(now: DateTimeValue): boolean {
        return this.lock?.locked(now) ?? false;
    }

    /**
     * 予約中ロックを取得する。
     */
    reservedLock(now: DateTimeValue): CompanyLock | undefined {
        if (this.lock === undefined) {
            return undefined;
        }
        return this.lock.locked(now) ? undefined : this.lock;
    }

    /**
     * 稼働中ロックを取得する。
     */
    activeLock(now: DateTimeValue): CompanyLock | undefined {
        if (this.lock === undefined) {
            return undefined;
        }
        return this.lock.locked(now) ? this.lock : undefined;
    }

    /**
     * 機能が利用可能か否かを取得します。
     */
    can = (feature: keyof EntitlementFeatureListModel, now: DateTimeValue = DateTimeValue.now()): boolean =>
        this.features[feature](now);

    /**
     * 権限期間を取得します。
     */
    term = (type: keyof EntitlementTermListModel): TermModel | undefined => this.terms[type];

    /**
     * サービスが利用可能か否かを取得します。
     */
    active = (type: keyof EntitlementTermListModel, now: DateTimeValue = DateTimeValue.now()): boolean =>
        this.term(type)?.includes(now) ?? false;

    //
    // Shorthands
    //
    canViewSelfProfileSetting: EntitlementChecker = (now: DateTimeValue) => this.can('viewSelfProfileSetting', now);
    canPrintSelfProfile: EntitlementChecker = (now: DateTimeValue) => this.can('printSelfProfile', now);
    canReportLatePayment: EntitlementChecker = (now: DateTimeValue) => this.can('reportLatePayment', now);
    canRevokePremiumPlan: EntitlementChecker = (now: DateTimeValue) => this.can('revokePremiumPlan', now);
    canRevokeGuaranteePlan: EntitlementChecker = (now: DateTimeValue) => this.can('revokeGuaranteePlan', now);
}

export class CompanyLock {
    lockTm: DateTimeValue;
    lockType: CompanyLockTypeEnum;

    constructor({ lockTm, lockType }: NonNullable<Entitlement['lock']>) {
        this.lockTm = new DateTimeValue(lockTm);
        this.lockType = CompanyLockTypeEnum.codeOf(lockType.code);
    }

    /**
     * ロックが稼働中か否かを取得する。
     */
    locked(now: DateTimeValue): boolean {
        return now.isSameOrAfter(this.lockTm);
    }
}

type Term = {
    start: string;
    end: string;
};

class TermModel {
    start: DateTimeValue;
    end: DateTimeValue;

    constructor({ start, end }: Term) {
        this.start = new DateTimeValue(start);
        this.end = new DateTimeValue(end);
    }

    includes(datetime: DateTimeValue): boolean {
        return datetime.isBetween(this.start, this.end);
    }

    static of(term: Term | undefined): TermModel | undefined {
        if (term === undefined) return undefined;
        return new TermModel(term);
    }
}
