<script setup lang="ts">
import { useSettingCompanyProfileExtraHelper } from '@/_pages/Setting/Company/ProfileExtra/setting-company-profile-extra-helper';
// Component
import MonthPicker from '@/_components/ui/MonthPicker.vue';
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';

const {
    myProfile,
    form,
    formValidateRules,
    formModel,
    loading,
    employeeNumbers,
    accountingDay,
    accountingMonth,
    disableEstablishmentDate,
    onSubmit,
} = useSettingCompanyProfileExtraHelper();
</script>

<template>
    <setting-layout>
        <a-card>
            <h3>{{ myProfile?.name.kanji }}</h3>
            <br>

            <a-spin :spinning="loading">
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ xs: { span: 3 }, xxl: { span: 2, offset: 2 } }"
                              :wrapper-col="{ xs: { span: 21 }, xxl: { span: 18 } }">
                    <a-form-model-item label="業務内容"
                                       prop="description">
                        <a-textarea v-model="form.description"
                                    placeholder="御社の事業紹介やPRをご入力ください"
                                    :auto-size="{ minRows: 4, maxRows: 8 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="代表者"
                                       :wrapper-col="{ xs: { span: 8 }, xxl: { span: 8 } }"
                                       prop="representativeName">
                        <a-input v-model="form.representativeName"
                                 placeholder="虎箱 太郎"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="設立"
                                       :wrapper-col="{ xs: { span: 8 }, xxl: { span: 8 } }"
                                       prop="establishmentDate">
                        <month-picker placeholder="年月"
                                      format="YYYY年M月"
                                      valueFormat="YYYY-MM-01"
                                      v-model="form.establishmentDateAsDate"
                                      :disabled-date="disableEstablishmentDate"/>
                    </a-form-model-item>
                    <a-form-model-item label="資本金"
                                       :wrapper-col="{ xs: { span: 6 }, xxl: { span: 6 } }"
                                       prop="capital">
                        <a-input v-model="form.capitalText"
                                 suffix="万円"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="従業員数"
                                       :wrapper-col="{ xs: { span: 6 }, xxl: { span: 6 } }"
                                       prop="employeesNumberCode">
                        <a-select size="default"
                                  placeholder="選択してください"
                                  :options="employeeNumbers"
                                  v-model="form.employeesNumberCode"></a-select>
                    </a-form-model-item>
                    <a-form-model-item label="事業所所在地"
                                       :wrapper-col="{ xs: { span: 12 }, xxl: { span: 12 } }"
                                       prop="officeAddress">
                        <a-input v-model="form.officeAddress"
                                 placeholder="東京本社、横浜営業所、名古屋営業所、大阪営業所"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="売上"
                                       :wrapper-col="{ xs: { span: 6 }, xxl: { span: 6 } }"
                                       prop="sales">
                        <a-input v-model="form.salesText"
                                 suffix="万円"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="取引銀行"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="bank">
                        <a-input v-model="form.bank"
                                 placeholder="三井住友銀行渋谷駅前支店"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="主要取引先"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="customer">
                        <a-input v-model="form.customer"
                                 placeholder="公開に差し支えなければご入力ください"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="営業地域"
                                       :wrapper-col="{ xs: { span: 9 }, xxl: { span: 9 } }"
                                       prop="salesArea">
                        <a-input v-model="form.salesArea"
                                 placeholder="首都圏、東海、近畿"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="締め月" :wrapper-col="{ xs: { span: 4 }, xxl: { span: 4 } }">
                        <span>当月</span>
                    </a-form-model-item>

                    <a-form-model-item label="締め日"
                                       :wrapper-col="{ xs: { span: 4 }, xxl: { span: 4 } }"
                                       prop="cutOffDay">
                        <a-select size="default"
                                  placeholder="日を選択"
                                  :options="accountingDay"
                                  v-model="form.cutOffDayCode"></a-select>
                    </a-form-model-item>
                    <a-form-model-item label="支払月"
                                       :wrapper-col="{ xs: { span: 4 }, xxl: { span: 4 } }"
                                       prop="paymentMonth">
                        <a-select size="default"
                                  placeholder="月を選択"
                                  :options="accountingMonth"
                                  v-model="form.paymentMonthCode"></a-select>
                    </a-form-model-item>
                    <a-form-model-item label="支払日"
                                       :wrapper-col="{ xs: { span: 4 }, xxl: { span: 4 } }"
                                       prop="paymentDay">
                        <a-select size="default"
                                  placeholder="日を選択"
                                  :options="accountingDay"
                                  v-model="form.paymentDayCode"></a-select>
                    </a-form-model-item>
                    <br/>

                    <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                        <a-button style="max-width: 200px"
                                  type="primary"
                                  size="large"
                                  block
                                  @click="onSubmit">保存
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-card>
    </setting-layout>
</template>

<style scoped lang="less">

</style>
