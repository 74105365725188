<script setup lang="ts">
import DrawerContentLayout from '@/_pages/Baggage/List/drawers/common/DrawerContentLayout.vue';
import CurrencyInput from '@/_components/ui/CurrencyInput.vue';
import { useSettlementEditorHelper } from '@/_pages/Baggage/List/drawers/SettlementEditor/settlement-editor-helper';
import { computed } from 'vue';
import TbxButton from '@/_components/ui/TbxButton.vue';

defineProps<{
    visible: boolean;
}>();

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'done'): void;
}>();

const done = () => emit('done');

const {
    canProxy,
    loading,
    settlement,
    partner,
    form,
    validationRules,
    dirty,
    changeType,
    formModel,
    validate,
    onSubmit,
    proxyOptions,
} = useSettlementEditorHelper(done);
const isChange = computed(() => changeType.value === 'CHANGE');

const toggleCanceling = () => {
    form.value.isCanceling = !form.value.isCanceling;
    validate();
};
</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0, height: 'calc(100% - 54px)', overflow: 'auto' }"
              :mask-style="{ background: 'transparent' }"
              :destroy-on-close="true"
              @close="emit('close')">
        <template #title>
            <span class="component-heading-h2">取引情報変更</span>
        </template>
        <drawer-content-layout :loading="loading">
            <a-form-model ref="formModel"
                          :model="form"
                          :rules="validationRules"
                          :colon="false"
                          :label-col="{ span: 6, offset: 3 }"
                          :wrapper-col="{ span: 8 }">
                <a-form-model-item label="取引番号" style="margin-bottom: 0">
                    {{ settlement?.id }}
                </a-form-model-item>
                <a-form-model-item label="入金日">
                    {{ settlement?.payeeDate.format('YYYY年M月D日(ddd)') }}
                </a-form-model-item>
                <a-form-model-item label="おまかせ請求" prop="proxy">
                    <a-radio-group v-model="form.proxy" :options="proxyOptions" :disabled="!canProxy"/>
                </a-form-model-item>
                <a-form-model-item label="運賃" prop="freight">
                    <currency-input v-model="form.freight"
                                    suffix="円 (税別)"
                                    :range="form.availableFeeRange('freight')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="高速代" prop="highwayFare">
                    <currency-input v-model="form.highwayFare"
                                    suffix="円 (税込)"
                                    :range="form.availableFeeRange('highwayFare')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="待機料" prop="waitTimeFee">
                    <currency-input v-model="form.waitTimeFee"
                                    suffix="円 (税別)"
                                    :range="form.availableFeeRange('waitTimeFee')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="付帯作業料" prop="operationFee">
                    <currency-input v-model="form.operationFee"
                                    suffix="円 (税別)"
                                    :range="form.availableFeeRange('operationFee')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="搬出料" prop="pickingFee">
                    <currency-input v-model="form.pickingFee"
                                    suffix="円 (税込)"
                                    :range="form.availableFeeRange('pickingFee')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="駐車代" prop="parkingFee">
                    <currency-input v-model="form.parkingFee"
                                    suffix="円 (税込)"
                                    :range="form.availableFeeRange('parkingFee')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="通関料" prop="clearanceFee">
                    <currency-input v-model="form.clearanceFee"
                                    suffix="円 (非課税)"
                                    :range="form.availableFeeRange('clearanceFee')"
                                    :disabled="form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label="キャンセル料" prop="cancellationFee">
                    <currency-input v-model="form.cancellationFee"
                                    suffix="円 (非課税)"
                                    :range="form.availableFeeRange('cancellationFee')"
                                    :disabled="!form.isCanceling || !isChange"/>
                </a-form-model-item>
                <a-form-model-item label=" " prop="isCanceling" :wrapper-col="{ span: 10 }">
                    <a-checkbox :checked="form.isCanceling"
                                @change="toggleCanceling"
                                :disabled="!isChange">
                        キャンセル料を入力する
                    </a-checkbox>
                </a-form-model-item>
                <a-form-model-item label="発日" style="margin-bottom: 0" :wrapper-col="{ span: 10 }">
                    {{ settlement?.departureDate.format('YYYY年M月D日(ddd)') }}
                </a-form-model-item>
                <a-form-model-item label="発地" style="margin-bottom: 0">
                    {{ settlement?.departureLocation }}
                </a-form-model-item>
                <a-form-model-item label="着日" style="margin-bottom: 0" :wrapper-col="{ span: 10 }">
                    {{ settlement?.arrivalDate.format('YYYY年M月D日(ddd)') }}
                </a-form-model-item>
                <a-form-model-item label="着地" style="margin-bottom: 0">
                    {{ settlement?.arrivalLocation }}
                </a-form-model-item>
                <a-form-model-item label="重量" style="margin-bottom: 0">
                    {{ settlement?.truckType.weight.label }}
                </a-form-model-item>
                <a-form-model-item label="車種" style="margin-bottom: 0">
                    {{ settlement?.truckType.model.label }}
                </a-form-model-item>
                <a-form-model-item label="荷主">
                    {{ partner?.name.kanji }}
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                    <tbx-button type="primary" @click="onSubmit" :disabled="!dirty">変更</tbx-button>
                </a-form-model-item>
            </a-form-model>
        </drawer-content-layout>
    </a-drawer>
</template>

<style scoped lang="less">
:deep(.ant-drawer-header) {
    border: none;
}

.tbx-currency-input {
    max-width: 200px;
}
</style>
