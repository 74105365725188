import { onMounted } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { useFormModel } from '@/composables/form-helper';
import { useNotification } from '@/composables/helper/page-helper';
import { useAccountRenewalVerify } from '@/composables/account-renewal-verify';

export const useAccountRenewalVerifyHelper = (code?: string) => {
    const { goToRenewalComplete } = useRouting();
    const notification = useNotification();
    const { formModel, submit } = useFormModel();
    const { state: { form, formValidateRules, loading }, verify } = useAccountRenewalVerify();

    const onSubmit = () => submit(async () => {
        const result = await verify()
            .then(() => true)
            .catch(() => false);

        if (result) {
            await goToRenewalComplete();
        } else {
            notification.error({
                message: 'パスワードの設定ができませんでした。',
                description: '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    onMounted(async () => {
        form.value.verificationCode = code ?? '';
    });

    return {
        loading,
        form,
        formModel,
        formValidateRules,
        onSubmit,
    };
};
