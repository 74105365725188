<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import DateTimeRangePicker from '@/_components/ui/DateTimeRangePicker.vue';
import BaggageTypeInput from '@/_components/ui/BaggageTypeInput.vue';
import BaggageFreightInput from '@/_components/ui/BaggageFreightInput.vue';
import BaggagePaymentDateInput from '@/_components/ui/BaggagePaymentDateInput.vue';
import BaggageLocationInput from '@/_pages/Baggage/Register/_components/BaggageLocationInput.vue';
import { useBaggageRegisterHelper } from '@/_pages/Baggage/Register/baggage-register-helper';
import { useFormModelItem } from '@/composables/form-helper';
import { computed } from 'vue';
import LabelPicker from '@/_components/baggage/LabelPicker.vue';

const props = defineProps<{
    sourceBaggageId?: string
}>();
const {
    loading,
    form,
    formModel,
    formValidateRules,
    cols,
    selectableDateRange,
    specifiedTruckCheck,
    specifiedTruckEditable,
    parseTruckCount,
    shapeOptions,
    temperatureZoneOptions,
    loadingOptions,
    unloadingOptions,
    weightOptions,
    modelOptions,
    heightOptions,
    widthOptions,
    largeTruckFlgOptions,
    circleOptions,
    highwayFareOptions,
    defaultPaymentDate,
    suggestionList,
    labelList,
    placeList,
    circleId,
    staffNameFilterOption,
    onClickStaffNameDelete,
    onClickLabelDelete,
    onSubmit,
    onClickClear,
} = useBaggageRegisterHelper(props.sourceBaggageId);

const { formModelItem: formItemDepartureDateTimeRange, onChange: onChangeDepartureDateTimeRange } = useFormModelItem();
const { formModelItem: formItemArrivalDateTimeRange, onChange: onChangeArrivalDateTimeRange } = useFormModelItem();
const onChangeDateTimeRange = () => {
    onChangeDepartureDateTimeRange();
    onChangeArrivalDateTimeRange();
};
const { formModelItem: formItemDepartureLocation, onChange: onChangeDepartureLocation } = useFormModelItem();
const { formModelItem: formItemArrivalLocation, onChange: onChangeArrivalLocation } = useFormModelItem();
const { formModelItem: formItemType, onChange: onChangeType } = useFormModelItem();
const { formModelItem: formItemPaletteSize, onChange: onChangePaletteSize } = useFormModelItem();
const { formModelItem: formItemTruck, onChange: onChangeTruck } = useFormModelItem();
const { formModelItem: formItemTemperatureZone, onChange: onChangeTemperatureZone } = useFormModelItem();

const descriptionPlaceholder = '※注意　「交換」「交換希望」はトラブルの元になります。ご遠慮ください。';

// a-auto-completeのクリアボタンを押すとundefinedがemitされてくるので、空文字に置き換える
const staffName = computed<string | undefined>({
    get: () => form.value.staffName,
    set: (value) => {
        form.value.staffName = value ?? '';
    }
});

</script>

<template>
    <page-layout :back-icon="false">
        <a-spin :spinning="loading">
            <a-form-model ref="formModel" :model="form" :rules="formValidateRules" :colon="false"
                          :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-card id="jsi-register-form" class="form-card">
                    <!-- 発着日時、発着地 -->
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="発"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               :auto-link="false"
                                               prop="departureDateTimeRange"
                                               ref="formItemDepartureDateTimeRange">
                                <date-time-range-picker v-model="form.departureDateTimeRange"
                                                  :valid-range="selectableDateRange"
                                                  @change="onChangeDateTimeRange"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="departureLocation"
                                               ref="formItemDepartureLocation">
                                <baggage-location-input :placeList="placeList"
                                                        @blur="form.normalize()"
                                                        @change="onChangeDepartureLocation"
                                                        v-model="form.departureLocation"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="24" class="form-item-loading-time">
                            <a-form-model-item label="積み時間"
                                               :wrapper-col="{ span: 15 }"
                                               prop="loadingTimeNote"
                                               ref="formItemLoadingTimeNote">
                                <a-input v-model="form.loadingTimeNote"
                                         placeholder="積み時間を入力してください"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="着"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               prop="arrivalDateTimeRange"
                                               ref="formItemArrivalDateTimeRange">
                                <date-time-range-picker v-model="form.arrivalDateTimeRange"
                                                  :valid-range="selectableDateRange"
                                                  @change="onChangeDateTimeRange"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="arrivalLocation"
                                               ref="formItemArrivalLocation">
                                <baggage-location-input :placeList="placeList"
                                                        @blur="form.normalize()"
                                                        @change="onChangeArrivalLocation"
                                                        v-model="form.arrivalLocation"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="24" class="form-item-unloading-time">
                            <a-form-model-item label="卸し時間"
                                               :wrapper-col="{ span: 15 }"
                                               prop="unloadingTimeNote"
                                               ref="formItemUnloadingTimeNote">
                                <a-input v-model="form.unloadingTimeNote"
                                         placeholder="卸し時間を入力してください"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 荷姿 -->
                    <a-form-model-item label="荷姿"
                                       prop="shape">
                        <a-radio-group name="shape"
                                       :options="shapeOptions"
                                       v-model="form.shapeCode"/>
                    </a-form-model-item>
                    <!-- 荷姿詳細 -->
                    <a-row class="shape-detail-row" v-if="form.hasShape">
                        <a-col :span="20" :offset="3" class="shape-detail-col">
                            <a-form-model-item label="荷種"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="type"
                                               ref="formItemType">
                                <baggage-type-input v-model="form.baggageType"
                                                    @change="onChangeType"
                                                    @blur="form.normalize()"/>
                            </a-form-model-item>
                            <template v-if="form.isShapePalette">
                                <a-form-model-item label="パレット枚数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="paletteCount">
                                    <a-input v-model="form.paletteCountText"
                                             placeholder="パレット枚数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>

                                <a-form-model-item label="パレットサイズ"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 16 }"
                                                   prop="paletteSize"
                                                   ref="formItemPaletteSize">
                                    <a-input-group>
                                        <a-row :gutter="8">
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteHeightText"
                                                         placeholder="縦"
                                                         addonBefore="縦"
                                                         suffix="cm"
                                                         :max-length="5"
                                                         @change="onChangePaletteSize"/>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteWidthText"
                                                         placeholder="横"
                                                         addonBefore="横"
                                                         suffix="cm"
                                                         :max-length="5"
                                                         @change="onChangePaletteSize"/>
                                            </a-col>
                                        </a-row>
                                    </a-input-group>
                                </a-form-model-item>
                            </template>
                            <template v-if="form.isShapeOther">
                                <a-form-model-item label="荷物の個数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalCount">
                                    <a-input v-model="form.totalCountText"
                                             placeholder="荷物の個数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>
                                <a-form-model-item label="荷物の体積"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalVolume">
                                    <a-input v-model="form.totalVolumeText"
                                             placeholder="荷物の体積"
                                             :max-length="5">
                                        <template #suffix>m<sup>3</sup></template>
                                    </a-input>
                                </a-form-model-item>
                            </template>
                            <a-form-model-item label="総重量"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="totalWeight">
                                <a-input v-model="form.totalWeightText"
                                         placeholder="総重量"
                                         suffix="kg"
                                         :max-length="5"
                                />
                            </a-form-model-item>
                            <a-form-model-item label="ドライバー作業"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 20 }"
                                               prop="handling">
                                <a-space>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">積み</span>
                                        <a-select v-model="form.loadingCode"
                                                  style="width: 115px;"
                                                  placeholder="積み"
                                                  :options="loadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">卸し</span>
                                        <a-select v-model="form.unloadingCode"
                                                  style="width: 115px;"
                                                  placeholder="卸し"
                                                  :options="unloadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                </a-space>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-form-model-item label="温度帯"
                                       :wrapper-col="{ span: 16 }"
                                       prop="temperatureZone"
                                       ref="formItemTemperatureZone">
                        <a-select style="width: 50%"
                                  placeholder="温度帯を選択"
                                  :options="temperatureZoneOptions"
                                  v-model="form.temperatureZoneCode"
                                  @change="onChangeTemperatureZone"/>
                    </a-form-model-item>
                    <!-- 車両(重量、車種) -->
                    <a-form-model-item label="希望車両"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truck"
                                       ref="formItemTruck">
                        <a-input-group compact>
                            <a-select style="width: 50%"
                                      placeholder="車両重量を選択"
                                      :options="weightOptions"
                                      v-model="form.truckWeightCode"
                                      @change="onChangeTruck"/>
                            <a-select style="width: 50%"
                                      placeholder="車種を選択"
                                      :options="modelOptions"
                                      v-model="form.truckModelCode"
                                      @change="onChangeTruck"/>
                        </a-input-group>
                    </a-form-model-item>
                    <!-- 車両の指定 -->
                    <a-row class="specify-truck-row">
                        <a-col :span="20" :offset="3" class="specify-truck-col">
                            <a-checkbox v-model="specifiedTruckCheck">使用できる車両を指定する</a-checkbox>
                        </a-col>
                    </a-row>
                    <a-row class="specify-truck-detail-row" v-if="specifiedTruckEditable">
                        <a-col :span="20" :offset="3" class="shape-detail-col">
                            <!-- 床高 -->
                            <a-form-model-item label="床高"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="truckHeight">
                                <a-select style="width: 60%"
                                          placeholder="床高を選択"
                                          :options="heightOptions"
                                          v-model="form.truckHeightCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                            <!-- 車幅 -->
                            <a-form-model-item label="車幅"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="truckWidth">
                                <a-select style="width: 60%"
                                          placeholder="車幅を選択"
                                          :options="widthOptions"
                                          v-model="form.truckWidthCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                            <!-- 大型可否 -->
                            <a-form-model-item label="大型可否"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="largeTruckFlg">
                                <a-select style="width: 60%"
                                          placeholder="大型可否を選択"
                                          :options="largeTruckFlgOptions"
                                          v-model="form.largeTruckFlgCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 必要装備 -->
                    <a-form-model-item label="必要装備"
                                       :wrapper-col="{ span: 18 }"
                                       prop="truckEquipment">
                        <a-textarea placeholder="りん木、コンパネ、発泡、ラップ、ラッシング等"
                                    v-model="form.truckEquipmentText"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>
                    <!-- 台数 -->
                    <a-form-model-item label="台数"
                                       :wrapper-col="{ span: 17 }"
                                       prop="truckCount">
                        <a-input-number v-model="form.truckCountText"
                                        :parser="parseTruckCount"
                                        :min="1"
                                        :max="10"/>
                    </a-form-model-item>

                    <!-- 運賃 -->
                    <a-form-model-item label="運賃"
                                       prop="baggageFreight">
                        <baggage-freight-input v-model="form.baggageFreight" :multi-truck="form.isMultipleTruckCount"/>
                    </a-form-model-item>
                    <!-- 高速代 -->
                    <a-form-model-item label="高速代"
                                       prop="highwayFareFlg">
                        <a-radio-group name="category"
                                       :options="highwayFareOptions"
                                       v-model="form.highwayFareFlg"/>
                    </a-form-model-item>
                    <!-- 入金予定日 -->
                    <a-form-model-item label="入金予定日"
                                       prop="paymentDate"
                                       ref="formItem">
                        <baggage-payment-date-input v-model="form.paymentDateValue"
                                                    :default-payment-date="defaultPaymentDate"
                                                    :is-disabled-date="form.isDisabledPaymentDate"/>
                    </a-form-model-item>
                    <!-- 備考 -->
                    <a-form-model-item label="備考"
                                       :wrapper-col="{ span: 18 }"
                                       prop="description"
                                       ref="formItem">
                        <a-textarea :placeholder="descriptionPlaceholder"
                                    v-model="form.descriptionText"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>

                    <!-- 至急/引っ越し/積合 -->
                    <a-form-model-item :wrapper-col="{ xs: { offset: 0 }, sm: { offset: 3 } }"
                                       prop="express">
                        <a-checkbox class="checkbox-item" v-model="form.express">至急</a-checkbox>
                        <a-checkbox class="checkbox-item" v-model="form.relocate">引越し案件</a-checkbox>
                        <a-checkbox class="checkbox-item" v-model="form.share">積合</a-checkbox>
                    </a-form-model-item>
                    <!-- 荷主名 -->
                    <a-form-model-item label="荷主名"
                                       :wrapper-col="{ span: 16 }"
                                       prop="shipperName">
                        <a-input v-model="form.shipperName"
                                 style="width: 50%"
                                 placeholder="例）アマゾン"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <!-- 担当者 -->
                    <a-form-model-item label="担当者"
                                       :wrapper-col="{ span: 16 }"
                                       prop="staffName">
                        <a-auto-complete style="width: 50%"
                                         placeholder="担当者名"
                                         class="row-editable"
                                         :default-active-first-option="false"
                                         :allow-clear="true"
                                         option-label-prop="title"
                                         :filter-option="staffNameFilterOption"
                                         v-model="staffName"
                                         @blur="form.normalize()">
                            <template #dataSource>
                                <a-select-option v-for="text in suggestionList.staffNames"
                                                 :key="text"
                                                 :title="text">
                                    <span>{{ text }}</span>
                                    <a-button style="float: right"
                                              type="default"
                                              icon="delete"
                                              size="small"
                                              @click="onClickStaffNameDelete(text, $event)"></a-button>
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-model-item>
                    <!-- 部屋 -->
                    <a-form-model-item label="部屋"
                                       prop="circleId">
                        <a-select :options="circleOptions"
                                  v-model="circleId"
                                  placeholder="部屋を選択"
                                  style="width: 300px" />
                    </a-form-model-item>

                    <a-form-model-item label="ラベル"
                                       prop="label">
                      <label-picker v-model="form.label" :label-list="labelList" :show-delete="true" @deleteLabel="onClickLabelDelete"/>
                    </a-form-model-item>

                    <a-row :gutter="[32, 48]">
                        <a-col :offset="10" :span="14"
                               style="display: flex; align-items: flex-start; text-align: center;">
                            <a-button style="max-width: 160px; margin-right: 22px;"
                                      block
                                      type="primary"
                                      size="large"
                                      @click="onSubmit">登録
                            </a-button>
                            <a-button style="max-width: 80px"
                                      block
                                      size="large"
                                      @click="onClickClear">クリア
                            </a-button>
                        </a-col>
                    </a-row>

                    <!-- 注意文言 -->
                    <a-row :gutter="[24, 64]">
                        <a-col :offset="3" :span="18">
                            <a-alert
                                message="注意"
                                description="同じ荷物情報を故意的に繰り返し入力するのはご遠慮ください！荷物情報はメールでも配信されます。同じメールが何通も届くのは、取引の敬遠につながる恐れがあります。複数台数のトラックをお探しのときは、必要な台数をご入力ください。"
                                type="warning"
                                show-icon
                            />
                        </a-col>
                    </a-row>
                </a-card>
            </a-form-model>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
// typescript/pages/Baggage/Register/style.less
.ant-form {
    padding: 24px;
}

.form-card {
    padding: 16px 24px;
}

.shape-detail-row {
    margin-top: -16px;
    margin-bottom: 16px;
}

.shape-detail-col {
    background-color: @color-neutral-3;
    padding-top: 20px;
}

.specify-truck-row {
    margin-top: -16px;
    margin-bottom: 32px;
}

.specify-truck-detail-row {
    margin-top: -24px;
    margin-bottom: 32px;
}

// typescript/components/Baggage/Register/Spot/style.less
.form-item-specific-address {
    margin-left: 20%;
}

.form-item-loading-time {
    margin-top: -12px;
}

.form-item-unloading-time {
    margin-top: -12px;
}

// typescript/components/Baggage/Register/Handling/style.less
.handling-type-label {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    padding: 4px 11px 0 11px;
    height: 32px;
}

// typescript/components/Baggage/Register/Freight/style.less
.reference-freight {
    margin-top: 4px;
    line-height: 1;
    color: @text-color;

    &__help {
        margin-left: 8px;
        cursor: pointer;
    }
}

// typescript/components/Baggage/Register/Flags/style.less
.checkbox-item + .checkbox-item {
    margin-left: 32px;
}

// typescript/components/Baggage/Register/NegotiationType/style.less
.negotiation-type-help {
    margin-top: 12px;
    margin-left: 4px;
    cursor: pointer;

    &__title {
        width: 400px;
    }
}

// typescript/components/Baggage/Register/UnderNegotiation/style.less
.under-negotiation__help {
    margin-left: 4px;
}
</style>
