<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import { Const } from '@/const';
import { useMiscGuaranteeRegister } from './misc-guarantee-register-helper';

const {
    goToMiscTop,
    isMainAccount,
    existsGuarantee,
    guaranteedAmount,
    guaranteeServiceAmount,
    loading,
    onClickRegister
} = useMiscGuaranteeRegister();
</script>

<template>
    <page-layout class="misc" @back="goToMiscTop">
        <a-card class="container">
            <div v-if="existsGuarantee" class="guarantee-usage-container">
                <a-alert type="success">
                    <template #message>運賃全額保証サービスに加入済み</template>
                </a-alert>
                <a-descriptions bordered :column="1">
                    <a-descriptions-item>
                        <template #label>
                            <span class="nowrap">今月これまでの保証対象累計額</span>
                        </template>
                        <span class="wrap">{{ guaranteedAmount?.amount.format() }}</span>
                    </a-descriptions-item>

                    <a-descriptions-item>
                        <template #label>
                            <span class="nowrap">今月これまでの運賃保証サービス料</span>
                        </template>
                        <span class="wrap">{{ guaranteeServiceAmount?.format() }}</span>
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <template v-else>
                <a-spin :spinning="loading">
                    <div class="guarantee-info">
                        <a-alert type="warning">
                            <template #message>運賃全額保証サービスにまだ加入していません。</template>
                        </a-alert>
                        <img class="banner" alt="成約した運賃を全額保証！支払い遅延もカバー！" src="/img/bnr-guarantee-service.svg"/>
                        <p>成約による仲介手数料はかからず、月額5,000円（税別）〜で「トラボックス求車情報」にて「成約（運賃全額保証サービスを利用する）」を選択したすべての運賃を保証するサービスです。</p>
                        <p>保証対象にしたかどうかは荷主側にはわかりませんのでご安心ください。</p>
                        <div class="action-button">
                            <a-alert type="warning" v-if="!isMainAccount">
                                <template #description>
                                    運賃全額保証サービスのお申込みは管理者のみ行なえます。<br/>お手数ですが、管理者の方にご依頼ください。
                                </template>
                            </a-alert>
                            <a-button
                                type="primary"
                                size="large"
                                :disabled="!isMainAccount"
                                @click="onClickRegister">今すぐお申し込み
                            </a-button>
                        </div>
                    </div>
                </a-spin>
            </template>
            <div class="link-container" style="text-align: center">
                <p><a :href="Const.externalPageUrl.guarantee" target="_blank">
                    <tbx-link-text type="external">運賃全額保証サービスの詳細はこちら</tbx-link-text>
                </a></p>
                <p v-if="!existsGuarantee">※運賃全額保証申し込み以前に成約した案件は保証対象になりません。</p>
            </div>
        </a-card>
    </page-layout>
</template>

<style scoped lang="less">
.ant-alert {
    text-align: center;
}

.container {
    margin: 24px;
    padding: 24px;
}

.link-container {
    margin-top: 12px;
}

.guarantee-usage-container {
    .ant-alert + .ant-descriptions {
        margin-top: 8px;
    }

    & + .link-container {
        margin-top: 32px;
    }
}

.guarantee-info {
    max-width: 664px;
    margin: auto;
    padding: 0;

    .banner {
        width: 100%;
        height: auto;
    }

    .ant-alert + .banner,
    .banner + p {
        margin-top: 12px;
    }

    .action-button {
        margin-top: 32px;
        text-align: center;

        .ant-alert {
            margin-bottom: 16px;
        }
    }
}

.board-container {
    padding: 24px;
}

.ant-result {
    ::v-deep .ant-result-title {
        line-height: 36px;
    }

    ::v-deep .ant-result-subtitle {
        margin-top: 16px;
        color: @ui-color-text;
    }
}
</style>
