import { useLocalStorage } from '@vueuse/core';
import { computed, ref } from 'vue';

/**
 * マイ荷物・成約 検索フォームの開閉機能を提供します。
 */
export const useMyBaggageSearchFormVisibility = (key: 'OPENED' | 'CANCELED' | 'AGREED' | 'ACCEPTED' | 'SETTLEMENT_INCOME' | 'SETTLEMENT_OUTGO') => {
    const storage = useLocalStorage<boolean>(`myBaggageSearchFormVisibility.${ key }`, false);

    // ストレージから取得した値を初期値として設定する
    const value = ref(storage.value);

    const visible = computed({
        get: () => {
            return value.value;
        },
        set: (newValue) => {
            value.value = newValue;
            storage.value = newValue;
        }
    });

    return {
        visible
    };
};
