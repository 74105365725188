import { Karte } from '@/karte';
import { baggageApi } from '@/repository/api/internal/baggage';
import { createGlobalState, Fn, Stoppable, useTimeoutFn } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useMessage } from '@/composables/helper/page-helper';
import _ from 'lodash';

/**
 * 荷物のお気に入り登録機能を提供します。
 */
export const useBaggageMarkFavorite = createGlobalState(() => {
    const cancellableQueues = ref<Map<number, { stop: Fn, start: Fn }>>(new Map());
    const cancellableIds = computed<number[]>(() => Array.from(cancellableQueues.value.keys()));
    const message = useMessage();

    /**
     * お気に入り登録します。
     * @param baggageId
     */
    const mark = async (baggageId: number) => {
        await baggageApi.markFavorite(baggageId);
        Karte.trackAddFavoriteBaggage(baggageId);
    };

    /**
     * お気に入り登録を解除します。
     */
    const unmark = async (baggageId: number) => {
        await baggageApi.unmarkFavorite(baggageId)
            .then(() => Karte.trackRemoveFavoriteBaggage(baggageId))
            .catch((e) => {
                message.error('保存解除できませんでした。時間をおいて再度お試しください。');
                throw e;
            });
    };

    const saveCancelId = (baggageId: number, timer: Stoppable): void => {
        const cloneMap = _.clone(cancellableQueues.value);
        cloneMap.set(baggageId, timer);
        cancellableQueues.value = cloneMap;
    };

    const deleteCancelId = (baggageId: number): void => {
        const cloneMap = _.clone(cancellableQueues.value);
        cloneMap.delete(baggageId);
        cancellableQueues.value = cloneMap;
    };

    /**
     * キャンセル可能なお気に入り登録解除処理です。
     * @param baggageId 荷物ID
     * @param callback お気に入り登録解除後の処理。キャンセルされた場合は実行されません。
     */
    const unmarkCancellable = async (baggageId: number, callback: (baggageId: number) => Promise<void>) => {
        const timer = useTimeoutFn(async () => {
            await unmark(baggageId)
                .then(async () => {
                    await callback(baggageId);
                    deleteCancelId(baggageId);
                })
                .catch(() => { /* timer内でエラーになってもキャッチできないので潰す */ });
        }, 2000);
        saveCancelId(baggageId, timer);
    };

    /**
     * お気に入り登録解除をキャンセルします
     * @param baggageId
     */
    const cancelUnmark = (baggageId: number) => {
        const stoppable = cancellableQueues.value.get(baggageId);
        if (stoppable) {
            stoppable.stop();
            deleteCancelId(baggageId);
        }
    };

    /**
     * お気に入り登録解除キャンセルが可能か否かを返します。
     * @param baggageId
     */
    const canCancel = (baggageId: number) => cancellableIds.value.includes(baggageId);

    return {
        state: {
            cancellableIds,
        },
        mark,
        unmark,
        unmarkCancellable,
        cancelUnmark,
        canCancel,
    };
});
