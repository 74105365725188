<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { BaggageDetailUtil } from '@/util';
import { Const } from '@/const';
import { computed } from 'vue';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';

type KeysRequired =
    | 'type'
    | 'express'
    | 'share'
    | 'shape'
    | 'category'
    | 'paletteCount'
    | 'paletteHeight'
    | 'paletteWidth'
    | 'totalCount'
    | 'totalVolume'
    | 'totalWeight'
    | 'loading'
    | 'unloading';
const props = defineProps<{
    baggage: Pick<Baggage, KeysRequired>;
}>();

const paletteCount = computed<string | undefined>(() => {
    return BaggageDetailUtil.paletteCount(
        props.baggage.shape.code,
        props.baggage.paletteCount
    );
});

const paletteSize = computed<string | undefined>(() => {
    return BaggageDetailUtil.paletteSize(
        props.baggage?.shape.code,
        props.baggage?.paletteHeight,
        props.baggage?.paletteWidth
    );
});

const totalCount = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalCount(
        props.baggage.shape.code,
        props.baggage.totalCount
    );
});

const totalVolume = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalVolume(
        props.baggage.shape.code,
        props.baggage.totalVolume
    );
});

const totalWeight = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalWeight(props.baggage.totalWeight);
});

const handling = computed<string | undefined>(() => {
    return BaggageDetailUtil.handling(
        props.baggage.loading?.code,
        props.baggage.unloading?.code
    );
});

const baggageType = computed<string | undefined>(() => {
    return props.baggage.type;
});

const baggageTypeDetail = computed<string | undefined>(() => {
    if (!props.baggage) return;
    return Const.SHARE_BAGGAGE_MESSAGE + '：' +
        roundOrCross(props.baggage.share) + ' / ' +
        Const.EXPRESS_BAGGAGE_MESSAGE + '：' +
        roundOrCross(isExpress()) + ' / ' +
        Const.RELOCATION_BAGGAGE_MESSAGE + '：' +
        roundOrCross(isRelocation());
});

const isExpress = (): boolean => {
    return props.baggage.express;
};

const isRelocation = (): boolean => {
    return props.baggage.category.code === BaggageCategoryEnum.Relocation.code;
};

const roundOrCross = (isWhat: boolean): string => {
    return (isWhat ? '○' : '×');
};
</script>

<template>
    <div>
        <span>{{ baggageType }}</span><br/>
        <span v-if="paletteCount">{{ paletteCount }}<br></span>
        <span v-if="paletteSize">{{ paletteSize }}<br></span>
        <span v-if="totalCount">{{ totalCount }}<br></span>
        <span v-if="totalVolume">{{ totalVolume }}m<sup>3</sup><br></span>
        <span v-if="totalWeight">{{ totalWeight }}<br></span>
        <span v-if="handling">{{ handling }}<br></span>
        <span v-if="baggageTypeDetail">{{ baggageTypeDetail }}<br></span>
    </div>
</template>

<style scoped lang="less">

</style>
