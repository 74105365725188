<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import BaggagePrint from '@/_components/baggage/print/BaggagePrint.vue';
import { useBaggagePrintHelper } from '@/_pages/Baggage/Print/baggage-print-helper';

const props = defineProps<{
    baggageId?: number,
}>();
const {
    baggage,
    profile,
    officialCompany,
    loading,
    informationComment,
    onClickPrint,
    onClickClose,
} = useBaggagePrintHelper(props.baggageId);
</script>

<template>
    <page-layout :show-header="false">
    <div class="print-container">
        <div v-if="!loading" class="print">
            <section class="print__header">
                <h1>荷物情報詳細</h1>
                <nav class="view-screen-only">
                    <ul class="print__header_actions">
                        <li><a-button @click="onClickPrint" type="primary" size="large">印刷</a-button></li>
                        <li><a-icon  @click="onClickClose" type="close" :style="{ fontSize: '20px' }"></a-icon></li>
                    </ul>
                </nav>
            </section>
            <section class="print__content">
                <baggage-print :baggage="baggage"
                               :profile="profile"
                               :official-company="officialCompany"
                               v-if="baggage && profile"/>
                <div class="print__content__table-container">
                    <table class="print__content__table">
                        <colgroup>
                            <col style="width: 150px;"/>
                            <col style="width: auto;"/>
                            <col style="width: 150px;"/>
                            <col style="width: auto;"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>会社名</th>
                            <td colspan="3"><a-input class="row-editable"></a-input></td>
                        </tr>
                        <tr>
                            <th>車種</th>
                            <td colspan="3"><a-input class="row-editable"></a-input></td>
                        </tr>
                        <tr>
                            <th>車両ナンバー</th>
                            <td colspan="3"><a-input class="row-editable"></a-input></td>
                        </tr>
                        <tr>
                            <th>ドライバー名</th>
                            <td><a-input class="row-editable"></a-input></td>
                            <th>携帯電話</th>
                            <td><a-input class="row-editable"></a-input></td>
                        </tr>
                        <tr>
                            <th>連絡事項</th>
                            <td colspan="3">
                                <div class="view-screen-only">
                                    <a-textarea class="row-editable" :auto-size="{ minRows: 3, maxRows: 10 }" v-model="informationComment"></a-textarea>
                                </div>
                                <div class="information-comment">
                                    <p class="view-print-only">{{ informationComment }}</p>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section class="print__footer">
                <small>転載等、一切の二次利用を禁止します。</small>
            </section>
        </div>
    </div>
    </page-layout>
</template>

<style scoped lang="less">
@import '../../../../less/common-styles/print-layout.less';

.information-comment {
    padding: 5px 12px;

    > p {
        margin: 0;
        word-break: break-all;
        white-space: pre-wrap;
    }
}
</style>
