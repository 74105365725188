import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { computed, nextTick, onBeforeMount } from 'vue';
import { useQuestionnaire } from '@/composables/questionnaire';
import { useFormModel } from '@/composables/form-helper';
import { useRouting } from '@/composables/helper/routing';
import { useModal } from '@/composables/helper/modal-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useCompanyDelete } from '@/composables/company-delete';
import { Const } from '@/const';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { useLogout } from '@/composables/logout';

export const useUnsubscribeEditHelper = () => {
    const { goToUnsubscribe, goToUnsubscribeComplete } = useRouting();
    const { confirm, error } = useModal();
    const { formModel: formModelRef, submit } = useFormModel();
    const { state: { profile }, load: loadAccount } = useAccountMyProfile();
    const { state: { contracts }, load: loadContracts } = useCompanyContracts();
    const { state: { loading: loadingQuestionnaire, questionnaire }, load } = useQuestionnaire();
    const { logout } = useLogout();
    const {
        state: { form, formValidateRules, loading: loadingUnsubscribe, dirty, },
        initForm,
        deleteCompany,
    } = useCompanyDelete();

    const appServiceName = Const.APP_SERVICE_NAME;
    const loading = computed(() => loadingQuestionnaire.value || loadingUnsubscribe.value);

    /**
     * 退会可能かどうかを取得します。
     */
    const canUnsubscribe = computed<boolean>(() => {
        if (!profile.value || !contracts.value) {
            return false;
        }
        return profile.value.isMainAccount && !contracts.value.existsPremiumPlanX;
    });

    /**
     * 「戻る」ボタンを押下した際に呼び出されます。
     */
    const onClickBack = async () => {
        await goToUnsubscribe();
    };
    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    const onClickConfirm = async () => {
        submit(
            async () => {
                const confirmed = await confirm(
                    `${ appServiceName }の退会手続きを実行します。よろしいですか？`,
                    'この手続きは取り消しできません。今一度ご確認ください。',
                    `${ appServiceName }を退会する`,
                    'キャンセル',
                    640,
                    'danger'
                );
                if (confirmed) {
                    deleteCompany()
                        .then(async () => {
                            await logout();
                            goToUnsubscribeComplete();
                        });
                }
            },
            async () => {
                await error(
                    'アンケートの中で正しく入力できていない項目があります。',
                    '恐れ入りますが、アンケートの回答を今一度お確かめください。',
                );
            }
        );
    };

    /**
     * 質問毎のバリデーション
     */
    const validate = async (fieldName: string) => {
        await nextTick();
        formModelRef.value?.validateField(fieldName, () => {});
    };

    onBeforeMount(async () => {
        await load('DEACTIVATE_COMPANY');
        initForm(questionnaire.value?.items ?? []);
        await Promise.all([loadAccount(), loadContracts()]);
    });

    onBeforeRouteLeave((_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
        if (dirty.value && !window.confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    });

    return {
        profile,
        contracts,
        canUnsubscribe,
        loading,
        form,
        formValidateRules,
        formModelRef,
        onClickBack,
        onClickConfirm,
        validate,
    };
};
