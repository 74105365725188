import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
import { FormModel, Modal } from 'ant-design-vue';
// @ts-ignore
import DetailList from '@/components/Baggage/Search/DetailDrawer/DetailList';
// @ts-ignore
import FavoriteButton from '@/components/Baggage/Search/DetailDrawer/FavoriteButton';
// @ts-ignore
import CompanySummary from '@/components/Company/Summary';
import { BaggageUtil, PageUtil } from '@/util';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
// @ts-ignore
import TransportSummary from '@/components/Baggage/Search/DetailDrawer/TransportSummary';
// @ts-ignore
import AchievementSummary from '@/components/Company/AchievementSummary';
// @ts-ignore
import NegotiationRequestMessage from '@/components/Baggage/Search/DetailDrawer/NegotiationRequstMessage';
import { Karte } from '@/karte';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { NegotiationRequestForm } from '@/models/negotiation';
import _ from 'lodash';

@Component({
    components: {
        DetailList,
        CompanySummary,
        AchievementSummary,
        TransportSummary,
        FavoriteButton,
        NegotiationRequestMessage,
    }
})
export default class BaggageSearchDetailDrawer extends Vue {
    @Prop()
    declare readonly visible?: boolean;
    @Prop()
    declare readonly baggage?: baggageTypes.Baggage;
    @Prop()
    declare readonly profile?: companyTypes.CompanyProfile;
    @Prop()
    declare readonly officialCompany?: companyTypes.OfficialCompany;
    @Prop()
    declare readonly confidence?: companyTypes.CompanyConfidence;
    @Prop()
    declare readonly statistics?: companyTypes.CompanyStatistics;
    @Prop()
    declare readonly myCompanyId?: number;
    @Prop()
    declare readonly markedAsFavorite?: boolean;
    @Prop()
    declare readonly cancelableUnmarkFavoriteBaggage?: boolean;
    @Prop()
    declare readonly referenceFreight?: baggageTypes.BaggageFreightMaster;
    @Prop()
    declare readonly negotiation?: negotiationTypes.Negotiation;
    @Prop({ default: true })
    declare readonly showExcludeCompanyButton?: boolean;

    activeTabKey: 'baggage' | 'company' = 'baggage';

    negotiationRequestForm: NegotiationRequestForm = { message: undefined };

    /**
     * 企業名
     */
    get companyName(): string {
        if (!this.profile) return '';
        return this.profile.name.kanji;
    }

    /**
     * 「成約」ボタンを表示すべきか否かを取得します。
     */
    get shouldShowAgreeButton(): boolean {
        if (!this.baggage || !this.myCompanyId) return false;
        return BaggageUtil.isReadyToAgree(this.baggage, this.myCompanyId);
    }

    /**
     * 荷物の保存状態を取得します。
     */
    get favoriteState(): baggageTypes.BaggageFavoriteState | undefined {
        if (!this.baggage || !this.myCompanyId) return undefined;
        if (BaggageUtil.isReadyToAgree(this.baggage, this.myCompanyId) && !this.isMarkedAsFavorite()) {
            // 未保存
            return 'Unmarked';
        } else if (this.isMarkedAsFavorite() && !this.isAbleToCancelUnmarkFavorite()) {
            // 保存済
            return 'Marked';
        } else if (this.isMarkedAsFavorite() && this.isAbleToCancelUnmarkFavorite()) {
            // 保存を解除中
            return 'Unmarking';
        } else {
            // 保存不可の荷物
            return undefined;
        }
    }

    /**
     * ステータスラベルを表示すべきか否かを取得します。
     */
    get shouldShowStatusLabel(): boolean {
        if (!this.baggage) return false;
        const status = BaggageStatusEnum.valueOf(this.baggage.status.code);
        if (!status) return false;
        return status.isClosed() || status.isCancel();
    }

    /**
     * 取り下げ済ラベルを表示すべきか否かを取得します。
     */
    get shouldShowCanceledLabel(): boolean {
        if (!this.baggage) return false;
        const status = BaggageStatusEnum.valueOf(this.baggage.status.code);
        if (!status) return false;
        return status.isCancel();
    }

    /**
     * 荷物IDを取得します。
     */
    get baggageId(): string {
        if(!this.baggage) return '';
        return this.baggage.id.toString();
    }

    get isMyCompanyBaggage(): boolean {
        return this.myCompanyId === this.baggage?.companyId;
    }

    /**
     * 「この企業の荷物をすべて非表示にする」を表示すべきか否かを取得します。
     */
    get shouldShowExcludeCompanyButton(): boolean {
        return !this.isMyCompanyBaggage && (this.showExcludeCompanyButton ?? false);
    }

    /**
     * 「商談中」か否かを取得します。
     */
    get underNegotiation(): boolean {
        return this.baggage?.underNegotiation ?? false;
    }

    /**
     * 「商談中にする」を表示すべきか否かを取得します。
     */
    get shouldShowMarkNegotiationButton(): boolean {
        return this.isMyCompanyBaggage && !this.underNegotiation;
    }

    /**
     * 「商談中を解除」を表示すべきか否かを取得します。
     */
    get shouldShowUnmarkNegotiationButton(): boolean {
        return this.isMyCompanyBaggage && this.underNegotiation;
    }

    get isNegotiationRequest(): boolean {
        return !this.isMyCompanyBaggage && this.baggage?.negotiationType.code === NegotiationTypeEnum.Online.code;
    }

    get isAlreadyRequestNegotiation(): boolean {
        return this.isNegotiationRequest && !_.isNil(this.negotiation);
    }

    get canRequestNegotiation(): boolean {
        if (!this.isNegotiationRequest) return false;
        if (!this.baggage || !this.myCompanyId) return false;
        return BaggageUtil.isReadyToAgree(this.baggage, this.myCompanyId);
    }

    //
    // Event handlers
    //

    onClickCloseDrawer(): void {
        this.$emit('close');
    }

    onClickAgree(event: Event): void {
        event.cancelBubble = true;
        if (!this.baggage) return;
        this.$emit('agree', this.baggage.id);
    }

    onClickPrint(): void {
        if (!this.baggage) return;
        this.$emit('print', this.baggage.id);
    }

    async onClickAddExcludedCompany(): Promise<void> {
        const companyId = this.baggage?.companyId;
        const companyName = this.profile?.name?.kanji;
        if (companyId && companyName) {
            await this.$store.dispatch(
                `baggage/${ baggageTypes.ACTION.ADD_BAGGAGE_SEARCH_EXCLUDED_COMPANY }`,
                { id: companyId, name: companyName }
            );
            this.$message.success(`${ companyName }の荷物を検索結果から除外しました。`);
        }
    }

    /**
     * 荷物が切り替わった時に呼び出されます。
     */
    @Watch('baggage.id')
    baggageChanged(): void {
        // 荷物タブに戻す
        this.activeTabKey = 'baggage';
        // スクロール位置を先頭にする
        this.scrollToContentTop();
    }

    markFavorite(): void {
        if (!this.baggage) return;
        this.$emit('markFavorite', this.baggage.id);
    }

    unmarkFavorite(): void {
        if (!this.baggage) return;
        this.$emit('unmarkFavorite', this.baggage.id);
    }

    cancelUnmarkFavorite(): void {
        if (!this.baggage) return;
        this.$emit('cancelUnmarkFavorite', this.baggage.id);
    }

    onClickMarkNegotiationButton(): void {
        if (!this.baggage) return;
        this.$emit('markUnderNegotiation', this.baggage.id);
    }

    onClickUnmarkNegotiationButton(): void {
        if (!this.baggage) return;
        this.$emit('unmarkUnderNegotiation', this.baggage.id);
    }

    /**
     * 企業名が押下された際に呼び出されます。
     */
    onClickCompanyName(companyId: string): void {
        this.$emit('clickCompanyName', companyId);
    }

    /**
     * 荷物情報タブで「実績をみる」が押下された際に呼び出されます。
     */
    onClickShowAchievement(): void {
        Karte.trackClickShowCompanyPerformanceLink();
        this.activeTabKey = 'company';
        this.scrollToContentTop();
    }

    onTabChange(): void {
        this.scrollToContentTop();
    }

    /**
     * BaggageDetail内の商談リクエスト押下時に呼び出されます。
     */
    onClickNegotiationRequestScroll(): void {
        this.scrollToNegotiationRequest();
    }

    /**
     * フォームの商談リクエスト押下時に呼び出されます。
     */
    async onClickNegotiationRequest(): Promise<void> {
        if (!await this.confirmRequestForm()) return;
        if (!this.baggage?.id) return;

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;
            this.$emit('requestNegotiation', this.baggage?.id, this.negotiationRequestForm.message);
        });
    }

    //
    // Helper methods
    //

    private scrollToContentTop(): void {
        const el = this.getAntTabEl();
        if (!el) {
            return;
        }
        PageUtil.scrollElementTo(el, 0);
    }

    private scrollToNegotiationRequest(): void {
        const container = this.$refs.negotiationRequestFormBlockRef as HTMLDivElement | undefined;
        if (!container) {
            return;
        }
        const el = this.getAntTabEl();
        if (!el) {
            return;
        }
        // 40は上部ヘッダーの分。
        PageUtil.scrollElementTo(el, container.offsetTop, true);
    }

    private getAntTabEl(): HTMLDivElement | undefined {
        const component = this.$refs.baggageDetailTabs as Vue | undefined;
        if (!component) {
            return;
        }
        return component.$el.getElementsByClassName('ant-tabs-content')[0] as HTMLDivElement | undefined;
    }

    private isMarkedAsFavorite(): boolean {
        return this.markedAsFavorite === true;
    }

    private isAbleToCancelUnmarkFavorite(): boolean {
        return this.cancelableUnmarkFavoriteBaggage ?? false;
    }

    /**
     * 入力内容確認モーダルを表示します。
     */
    private confirmRequestForm(): Promise<boolean> {
        return new Promise<boolean>((resolve) => Modal.confirm({
            title: '商談リクエストを送りますか？',
            content: '送信後は取り下げることが出来ませんので、運べなくなった場合は電話を受けた際に直接その旨をお伝えください。',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '送信',
            cancelText: 'キャンセル',
        }));
    }
}
