import Vue, { computed, getCurrentInstance, ref, Ref } from 'vue';
import { Message } from 'ant-design-vue/types/message';
import { Notification } from 'ant-design-vue/types/notification';
import { useScroll } from '@vueuse/core';

/**
 * Vueに登録されたmessageインスタンスを返します。
 * この関数はComposition APIのsetup関数内で利用してください
 */
export const useMessage: () => Message = () => {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error(`Should be used in setup().`);
    }

    return instance.proxy.$message;
};

/**
 * Vueに登録されたNotificationインスタンスを返します。
 * この関数はComposition APIのsetup関数内で利用してください
 */
export const useNotification: () => Notification = () => {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error(`Should be used in setup().`);
    }

    return instance.proxy.$notification;
};

export const useElementScroll = (el: Ref<HTMLElement | undefined>, smoothScroll: boolean = true) => {
    const { y } = useScroll(el, { behavior: smoothScroll ? 'smooth' : 'auto' });

    const scrollToTop = () => {
        y.value = 0;
    };

    const scrollToContentTop = (offset?: number, smoothScroll = true): void => {
        const margin = 32;
        const top = offset && offset >= margin ? offset - margin : 0;
        y.value = top;
    };

    const scrollToContent = (value: number): void => {
        y.value = value;
    };

    return {
        el,
        scrollToTop,
        scrollToContentTop,
        scrollToContent,
    };
};

export const useComponentRef = () => {
    const component = ref<Vue | undefined>();
    const el = computed<HTMLElement | undefined>(() => component.value?.$el as HTMLElement | undefined);

    return {
        component,
        el,
    };
};
