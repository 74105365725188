import { BaggageUpdateFormModel } from '@/vuex/modules/baggage/types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';
import _ from 'lodash';

// ======================================================
// Convenient Helpers
// ======================================================
/**
 * Convert {@link BaggageUpdateFormModel} to {@link PredictionBaggageAgreementForm}.
 */
export const createPredictionForm = (form: BaggageUpdateFormModel): PredictionBaggageAgreementForm => new PredictionBaggageAgreementForm({
    departure: form.departureMax,
    departurePref: form.departurePref,
    arrival: form.arrivalMax,
    arrivalPref: form.arrivalPref,
    type: form.type,
    truckWeight: form.truckWeight,
    truckModel: form.truckModel,
    share: form.share,
    express: form.express,
    freight: form.freight,
});

/**
 * 成約確率の再予測が必要か否かを取得します。
 */
export const needsRePrediction = (current: BaggageUpdateFormModel, next: BaggageUpdateFormModel): boolean => {
    const currentForm = createPredictionForm(current);
    const nextForm = createPredictionForm(next);

    if (!next.isReadyToPredict) return false;

    return !_.isEqual(currentForm, nextForm);
};
