import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { CompanyStaffNameSuggestionList } from '@/vuex/modules/company/types';

@Component
export default class BaggageStaffNameEdit extends Vue {
    @Prop()
    declare readonly value?: BaggageUpdateForm;
    @Prop()
    declare readonly suggestions?: CompanyStaffNameSuggestionList;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get staffName(): string {
        return this.value?.staffName ?? '';
    }

    set staffName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.staffName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get staffNameSuggestions(): { value: string; text: string }[] {
        const suggestions = this.suggestions?.staffNames ?? [];
        return suggestions.map((staffName) => ({ value: staffName, text: staffName }));
    }

    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.staffName = this.staffName.trim();
    }

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.staffName?.trim()) {
            callback('入力してください。');
        } else if (this.value.staffName.length > 250) {
            callback('250文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
