/**
 * トラックの車両重量グループEnum
 * 重量をUI上グルーピングする際に用いる。グルーピングはフロント側のみの概念であり、バックエンド側では用いていない。
 */
import { TruckWeightEnum, TruckWeightEnumCode } from './truck-weight.enum';

export type TruckWeightGroupEnumCode = 'SMALL' | 'MEDIUM' | 'LARGE';

export class TruckWeightGroupEnum {
    constructor(
        public code: TruckWeightGroupEnumCode,
        public label: string,
        public truckWeights: TruckWeightEnumCode[]
    ) {}

    static Small = new TruckWeightGroupEnum('SMALL', '小', [
        TruckWeightEnum.Weight1t.code,
        TruckWeightEnum.Weight2t.code,
        TruckWeightEnum.Weight3t.code,
    ]);
    static Medium = new TruckWeightGroupEnum('MEDIUM', '中', [
        TruckWeightEnum.Weight4t.code,
        TruckWeightEnum.Weight5t.code,
        TruckWeightEnum.Weight6t.code,
        TruckWeightEnum.Weight7t.code,
        TruckWeightEnum.Weight8t.code,
        TruckWeightEnum.Weight9t.code,
    ]);
    static Large = new TruckWeightGroupEnum('LARGE', '大', [
        TruckWeightEnum.Weight10t.code,
        TruckWeightEnum.Weight11t.code,
        TruckWeightEnum.Weight12t.code,
        TruckWeightEnum.Weight13t.code,
        TruckWeightEnum.Weight14t.code,
        TruckWeightEnum.Weight15t.code,
    ]);

    static valueOf = (code: string): TruckWeightGroupEnum | undefined =>
        TruckWeightGroupEnum.values.find((value) => value.code === code);
    static values = [TruckWeightGroupEnum.Small, TruckWeightGroupEnum.Medium, TruckWeightGroupEnum.Large];
}
