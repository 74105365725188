<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from '@/composables/helper/route';
import { useSettlementPaperTabKey } from '@/composables/transaction';

type TabKey = 'income' | 'outgo';
const { currentRoute } = useRoute();
const { tabKey, goToSettlementPaper } = useSettlementPaperTabKey();
const activeTabKey = computed(() => currentRoute.meta?.activeTabKey?.split('/').pop());

/**
 * 選択しているタブを保存しておく。
 */
const saveTabKey = (key: TabKey) => tabKey.value.tabKey = key;

/**
 * タブが変更された際に呼び出されます。
 */
const onChangeTab = async (key: TabKey) => {
    saveTabKey(key);

    await goToSettlementPaper();
};
</script>

<template>
    <div class="board-container">
        <a-radio-group size="large"
                       :value="activeTabKey"
                       @change="onChangeTab($event.target.value)"
                       class="menu">
            <a-radio-button value="income">支払通知書</a-radio-button>
            <a-radio-button value="outgo">請求書</a-radio-button>
        </a-radio-group>

        <router-view></router-view>
    </div>
</template>

<style scoped lang="less">
.board-container {
    padding: @padding-lg;

    .menu {
        margin-bottom: @padding-lg + @padding-xs;
    }
}
</style>
