<script setup lang="ts">
import { computed } from 'vue';
import { Util } from '@/util';

const props = withDefaults(defineProps<{
    value?: number;
    placeholder?: string;
    allowClear?: boolean;
    size?: 'large' | 'default' | 'small';
}>(), {
    placeholder: '荷物番号',
    allowClear: true,
    size: 'default',
});

const emits = defineEmits<{
    (e: 'input', value: number | undefined): void;
    (e: 'blur'): void;
    (e: 'pressEnter'): void;
}>();

const value = computed<string>({
    get: () => props.value?.toString() ?? '',
    set: (newValue) => {
        const id = Util.toNumber(newValue);
        emits('input', id > 0 ? id : undefined);
    }
});
</script>

<template>
    <a-input v-model="value"
             placeholder="荷物番号"
             :allow-clear="allowClear"
             :size="size"
             :max-length="16"
             @blur="emits('blur')"
             @pressEnter="emits('pressEnter')"
    />
</template>

<style scoped lang="less">

</style>
