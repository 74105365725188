<script setup lang="ts">
import Toolbar from '@/_components/ui/timeline-scheduler/Toolbar.vue';
import DateHeader from './DateHeader.vue';
import ResourceHeader from './ResourceHeader.vue';
import ScheduleTable from './ScheduleTable.vue';
import {
    TIMELINE_SCHEDULER_PROVIDER_KEY,
    TimelineDateRangeType,
    TimelineEventType,
    TimelineResourceType,
    TimelineSelectedCellType,
    useTimelineScheduleProvider
} from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import { onMounted, provide, ref, watch } from 'vue';

const props = withDefaults(defineProps<{
    width?: string;
    height?: string;
    resourceList: TimelineResourceType[];
    eventList: TimelineEventType[];
    selectedDate: Date;
}>(), {
    width: '100%',
    height: '100%',
});

const emits = defineEmits<{
    (e: 'date-changed', event: TimelineDateRangeType): void;
    (e: 'event-click', event: TimelineEventType): void;
    (e: 'event-matching-click', eventId: number): void;
    (e: 'event-dblclick', event: TimelineEventType): void;
    (e: 'cell-click', event: TimelineSelectedCellType): void;
    (e: 'cell-selected', event: TimelineSelectedCellType): void;
}>();

const provider = useTimelineScheduleProvider();
provide(TIMELINE_SCHEDULER_PROVIDER_KEY, provider);
const { updateEventList, updateResourceList, onTimelineViewResize, state: { eventBus } } = provider;

// TimelineSchedulerのイベントを親コンポーネントへ伝搬する
eventBus.on('EventClick', (event: TimelineEventType) => emits('event-click', event));
eventBus.on('EventMatchingClick', (eventId: number) => emits('event-matching-click', eventId));
eventBus.on('EventDoubleClick', (event: TimelineEventType) => emits('event-dblclick', event));
eventBus.on('CellClick', (event: TimelineSelectedCellType) => emits('cell-click', event));
eventBus.on('CellSelected', (event: TimelineSelectedCellType) => emits('cell-selected', event));
eventBus.on('DateRangeChanged', (event: TimelineDateRangeType) => emits('date-changed', event));

watch(() => props.eventList, (newVal) => {
    updateEventList(newVal);
});

watch(() => props.resourceList, (newVal) => {
    updateResourceList(newVal);
});
const scheduler = ref<HTMLDivElement>();

onMounted(() => {
    window.addEventListener('resize', onResize);
    onResize();
    updateEventList(props.eventList);
    updateResourceList(props.resourceList);
});

const onResize = () => {
    onTimelineViewResize((scheduler.value?.getBoundingClientRect().height) ?? 0);
    eventBus.emit('Resized');
};
</script>

<template>
    <div ref="scheduler"
         class="scheduler"
         :style="{ width: props.width, height: props.height}">
        <Toolbar/>
        <div class="table-container">
            <div class="table-wrap timeline-view">
                <table ref="table" class="schedule-table outer-table">
                    <tbody>
                    <tr>
                        <td class="resource-left-td"></td>
                        <td>
                            <DateHeader/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ResourceHeader/>
                        </td>
                        <td>
                            <ScheduleTable/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.schedule-table {
    border: 0 none;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    table-layout: fixed;
    width: 100%;
    height: 100%;
}

.scheduler {
    font-size: 12px;
    display: block;
    outline: medium none;
    overflow: hidden;
    position: relative;
}

.outer-table > tbody > tr > td {
    padding: 0;
    vertical-align: top;
}

.resource-left-td {
    background: #fff;
    border-color: rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0 1px 1px 0;
    color: rgba(0, 0, 0, 0.87);
    vertical-align: middle;
    width: 200px;
}

.scheduler > tbody > tr > th, .scheduler .schedule-table > tbody > tr > td {
    box-sizing: border-box;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
