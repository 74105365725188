<script setup lang="ts">
import { computed, ref } from 'vue';
import { injectStrictly } from '@/util/vue';
import {
    getScrollbarWidth,
    TIMELINE_SCHEDULER_PROVIDER_KEY
} from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import dayjs, { Dayjs } from 'dayjs';
import EventBox from '@/_components/ui/timeline-scheduler/EventBox.vue';

const {
    state: {
        renderedResources,
        renderedEvents,
        slotList,
        slotWidth,
        slotCountInDate,
        timelineHeight,
        timelineVerticalScrollBarWidth,
        timelineViewHeight,
        timelineViewMinWidth,
        timelineCurrentPosition,
        eventBus,
    },
    updateTimelineWidth,
    onClickEvent,
    onClickEventMatching,
    onCellSelected,
} = injectStrictly(TIMELINE_SCHEDULER_PROVIDER_KEY);

const currentTimeStyle = computed(() => {
    return { left: `${ timelineCurrentPosition.value }px`, height: `${ timelineHeight.value }px` };
});

// 日付表示テーブルの幅の取得用
const table = ref<HTMLTableElement>();
const wrap = ref<HTMLDivElement>();

eventBus.on('Resized', () => {
    onResize();
});

const onResize = () => {
    const tableElement = table.value;
    const divElement = wrap.value;
    if (!tableElement || !divElement) return;

    if (divElement.scrollHeight != divElement.clientHeight) {
        // スクロールバーが表示されている場合は、プラットフォームのスクロールバーの幅を取得して更新
        timelineVerticalScrollBarWidth.value = getScrollbarWidth();
    } else {
        timelineVerticalScrollBarWidth.value = 0;
    }

    // 新しい表示幅を取得して更新
    const tableWidth = tableElement.offsetWidth;
    if (tableWidth > timelineViewMinWidth) {
        updateTimelineWidth(tableWidth);
    }
};

const onScroll = (e: Event) => {
    // 縦スクロールイベントを発行、ResourceHeaderのスクロール同期を行う
    const scrollTop = (e.target as HTMLElement).scrollTop;
    eventBus.emit('VerticalScrolled', scrollTop);

    // 横スクロールイベントを発行、DateHeaderのスクロール同期を行う
    const scrollLeft = (e.target as HTMLElement).scrollLeft;
    eventBus.emit('HorizontalScrolled', scrollLeft);
};

class CellSelectionModel {
    private slotSeconds = 24 * 60 * 60 / slotCountInDate;
    private startDate?: Dayjs;
    private resourceId?: number;
    private dragging: boolean = false;

    startDraggingFrom(cell: HTMLElement): void {
        const date = cell.dataset['date'];
        const resourceId = cell.dataset['resourceId'];
        if (!date || !resourceId) return;

        // 範囲選択を開始する
        this.clearSelectedCell();
        this.startDate = dayjs.unix(parseInt(date));
        this.resourceId = parseInt(resourceId);
        this.dragging = true;
        this.setSelectedCell(cell);
    }

    updateDraggingOn(cell: HTMLElement): void {
        if (!this.dragging) return;

        // リソースIDが変化した場合、範囲選択をリセットする
        if (this.resourceId !== parseInt(cell.dataset['resourceId'] ?? '')) {
            this.stopDraggingAt();
            this.startDraggingFrom(cell);
        }

        // 早くカーソルをずらす場合に間のセルを全て選択する
        this.queryIntermediateCells(cell).forEach((otherCell) => {
            this.setSelectedCell(otherCell);
        });
    }

    // ドラッグをスタート地点のせルから今ホバーしているセルまでのセルを取得する
    queryIntermediateCells(cell: HTMLElement) {
        const date = cell.dataset['date'];
        if (!date) return [];
        const cellDate = dayjs.unix(parseInt(date));
        return Array.from(window.document.querySelectorAll(`[data-resource-id="${this.resourceId}"]`))
            .map((e) => e as HTMLElement)
            .filter((otherCell) => {
                const date = otherCell.dataset['date'];
                if (!date || !this.startDate) return false;
                const otherCellDate = dayjs.unix(parseInt(date));
                return otherCellDate >= this.startDate && otherCellDate <= cellDate;
            });
    }

    stopDragging() {
        this.dragging = false;
    }

    stopDraggingAt(cell?: HTMLElement): void {
        const endDate = cell?.dataset['date'];

        if (this.dragging && this.startDate && endDate &&
            this.resourceId === parseInt(cell.dataset['resourceId'] ?? '')) {

            // 範囲選択を終了する
            this.dragging = false;

            // 選択したセルの範囲を取得してイベントを発行する
            onCellSelected(
                {
                    startDate: this.startDate,
                    endDate: dayjs.unix(parseInt(endDate)).add(this.slotSeconds, 'second'),
                    resourceId: this.resourceId,
                }
            );
        }
    }

    isDragging(): boolean {
        return this.dragging;
    }

    setSelectedCell(cell: HTMLElement): void {
        cell.classList.add('selected-cell');
    }

    clearSelectedCell(): void {
        window.document.querySelectorAll('.selected-cell').forEach((cell) => {
            cell.classList.remove('selected-cell');
        });
        this.resourceId = undefined;
        this.startDate = undefined;
    }
}

const cellSelectionModel = new CellSelectionModel();

const onMouseDown = (e: Event) => {
    const cell = e.target as HTMLElement;
    cellSelectionModel.startDraggingFrom(cell);
};

const onMouseOver = (e: Event) => {
    if (cellSelectionModel.isDragging()) {
        const cell = e.target as HTMLElement;
        cellSelectionModel.updateDraggingOn(cell);
    }
};

const onMouseUp = (e: Event) => {
    const cell = e.target as HTMLElement;
    if (cellSelectionModel.isDragging()) {
        cellSelectionModel.stopDraggingAt(cell);
    }
};

const onMouseLeave = () => {
    cellSelectionModel.clearSelectedCell();
    cellSelectionModel.stopDragging();
};

</script>
<template>
    <div ref="wrap" class="content-wrap" :style="{ height: (timelineViewHeight -120 )+ 'px'}" @scroll="onScroll">
        <table ref="table" class="schedule-table" @mouseleave="onMouseLeave">
            <tbody>
            <tr v-for="resource in renderedResources" :key="resource.Id">
                <td v-for="item in slotList"
                    :key="item.date.toString()"
                    class="work-cells"
                    :data-resource-id="resource.Id"
                    :data-date="item.date.unix()"
                    :style="{ height: `${resource.height}px`, width: `${slotWidth}px`}"
                    @mousedown="onMouseDown"
                    @mouseover="onMouseOver"
                    @mouseup="onMouseUp"
                >
                </td>
            </tr>
            </tbody>
        </table>
        <div class="event-table">
            <div v-for="item in renderedEvents"
                 :key="item.Id"
                 class="event"
                 :class="{ 'event-selected': item.selected }"
                 :style="{
                         left: `${item.left}px`,
                         right: '0px',
                         top: `${item.top}px`,
                         width: `${item.width}px`,
                 }"
                 @click="onClickEvent(item.Id)"
            >
                <EventBox :subject="item.Subject"
                          :location="item.Location"
                          :startTime="item.StartTime"
                          :endTime="item.EndTime"
                          :eventType="item.EventType"
                          :existsRecommendation="item.ExistsRecommendation"
                          @clickEventMatching="onClickEventMatching(item.Id, $event)"
                />
            </div>
        </div>
        <div v-if="timelineCurrentPosition" class="current-timeline" :style="currentTimeStyle"></div>
    </div>
</template>

<style scoped lang="less">
.content-wrap {
    position: relative;
    overflow: auto;
}

.event {
    background: @primary-color;
    border: 1px solid #e8eaf6;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: absolute;
    height: 60px !important;
}

.event-selected {
    border: 0;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
}

.schedule-table {
    border: 0 none;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    table-layout: fixed;
    width: 100%;
    cursor: pointer;
}

.work-cells {
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 0 0 1px 1px;
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
}

.selected-cell {
    background: #e0e0e0;
    color: #000;
}

.selected-cell:hover {
    background: #e0e0e0;
}

.current-timeline {
    position: absolute;
    top: 0;
    border-left: 1px solid #e3165b;
}
</style>
