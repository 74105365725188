import { appAxios, isSuccess, JsonResponseBean } from './base';
import {
    Negotiation,
    NegotiationList,
    NegotiationListForm,
    NegotiationMessageUpdateForm,
    NegotiationRegisterForm
} from '@/models/negotiation';

export const negotiationApi = {
    /**
     * 荷物に来た商談リクエスト一覧を取得する
     */
    list(baggageId: number, form: NegotiationListForm): Promise<NegotiationList> {
        return appAxios.post<JsonResponseBean<NegotiationList>>(`/api/negotiation/baggage/${ baggageId }/list`, form).then(isSuccess);
    },

    /**
     * 商談をリクエストする
     * @param form
     */
    register(form: NegotiationRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/negotiation/register', form).then(isSuccess);
    },

    /**
     * 対応可否を更新する
     */
    updateMessage(negotiationId: number, messageId: number, form: NegotiationMessageUpdateForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>(
            `/api/negotiation/${ negotiationId }/message/${ messageId }/update`, form).then(isSuccess);
    },

    /**
     * 荷物ID指定で自分が商談リクエストしたかどうかを取得する
     */
    query(baggageIds: number[]): Promise<Array<Negotiation>> {
        return appAxios.post<JsonResponseBean<Array<Negotiation>>>('/api/negotiation/baggage/query', { ids: baggageIds }).then(isSuccess);
    }
};
