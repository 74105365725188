<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import DateTimeRangePicker from '@/_components/ui/DateTimeRangePicker.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import BaggageTypeInput from '@/_components/ui/BaggageTypeInput.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

import { useAgreementRegisterHelper } from '@/_pages/Agreement/Register/agreement-register-helper';
import { useFormModelItem } from '@/composables/form-helper';
import AgreementSettlementProxyInput from '@/_components/ui/AgreementSettlementProxyInput.vue';

const props = defineProps<{
    baggageId: number,
}>();

const {
    loading,
    form,
    formValidateRules,
    formModel,
    baggage,
    selectableDateRange,
    shapeOptions,
    loadingOptions,
    unloadingOptions,
    temperatureZoneOptions,
    specifiedTruckItems,
    staffNameFilterOption,
    onClickStaffNameDelete,
    suggestionList,
    existsGuarantee,
    canProxy,
    onClickBack,
    onSubmit,
} = useAgreementRegisterHelper(props.baggageId);
const cols = {
    first: {
        labelCol: { xs: 3, md: 9 },
        wrapperCol: { xs: 21, md: 15 },
    },
    second: {
        labelCol: { span: 0 },
        wrapperCol: {
            xs: { offset: 0, span: 24 },
            sm: { offset: 3, span: 21 },
            md: { offset: 0, span: 24 },
        },
    },
};
const amountCols = {
    first: {
        labelCol: { xs: 3, md: 9 },
        wrapperCol: { xs: 21, md: 15 },
    },
    second: {
        labelCol: { xs: 3 },
        wrapperCol: { xs: 21 },
    },
};
const { formModelItem: formItemDepartureDateTimeRange, onChange: onChangeDepartureDateTimeRange } = useFormModelItem();
const { formModelItem: formItemArrivalDateTimeRange, onChange: onChangeArrivalDateTimeRange } = useFormModelItem();
const onChangeDateTimeRange = () => {
    onChangeDepartureDateTimeRange();
    onChangeArrivalDateTimeRange();
};
const onChangeDeparture = () => {
    onChangeDateTimeRange();
    if (form.value.settlementProxy) {
        if (!canProxy.value) {
            // 発日がおまかせ請求サービス期間外に変更された場合、おまかせ請求対象にできないようにする
            form.value.settlementProxy = { use: false };
        } else {
            // 発日がおまかせ請求サービス期間内に変更された場合、おまかせ請求対象に補完する
            form.value.settlementProxy = { use: true, confirmed: false };
        }
    }
};
const { formModelItem: formItemDepartureAddress, onChange: onChangeDepartureAddress } = useFormModelItem();
const { formModelItem: formItemArrivalAddress, onChange: onChangeArrivalAddress } = useFormModelItem();
const { formModelItem: formItemType, onChange: onChangeType } = useFormModelItem();
const { formModelItem: formItemHighwayFare, onChange: onChangeHighwayFare } = useFormModelItem();
const { formModelItem: formItemTemperatureZone, onChange: onChangeTemperatureZone } = useFormModelItem();
const onChangeShape = () => {
    onChangeType();
};
const onChangeNoHighwayFare = () => {
    form.value.noHighwayFare = !form.value.noHighwayFare;
    onChangeHighwayFare();
};

const descriptionPlaceholder = '※注意　「交換」「交換希望」はトラブルの元になります。ご遠慮ください。';
</script>

<template>
    <page-layout @back="onClickBack">
        <a-spin :spinning="loading">
            <a-form-model ref="formModel" :model="form" :rules="formValidateRules" :colon="false"
                          :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-card class="form-card">
                    <!-- 発着 -->
                    <a-row :gutter="[16, 0]" class="baggage-agreement-spot-row">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="発"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               prop="departureDateTimeRange"
                                               ref="formItemDepartureDateTimeRange">
                                <date-time-range-picker v-model="form.departureDateTimeRange" :valid-range="selectableDateRange"
                                                  @change="onChangeDeparture"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="departureAddress"
                                               ref="formItemDepartureAddress">
                                <a-input-group compact>
                                    <prefecture-select style="width: 20%"
                                                       :multiple="false"
                                                       placeholder="都道府県"
                                                       title="発地"
                                                       v-model="form.departurePrefCode"
                                                       @change="onChangeDepartureAddress"/>
                                    <city-input style="width: 40%"
                                                placeholder="市区町村"
                                                :pref-code="form.departurePrefCode"
                                                v-model="form.departureCity"
                                                @change="onChangeDepartureAddress"
                                                @blur="form.normalize()"/>
                                    <a-input style="width: 40%"
                                             placeholder="番地・建物（任意）"
                                             v-model="form.departureAddress"
                                             @change="onChangeDepartureAddress"
                                             @blur="form.normalize()"/>
                                </a-input-group>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]" class="loading-time-row" v-if="form.hasLoadingTimeNote">
                        <a-col :offset="3" :xs="16" :md="16" class="loading-time-row--text">
                            <span class="label-title">積み時間：</span>
                            <span>{{ form.loadingTimeNote }}</span>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16, 0]" class="baggage-agreement-spot-row">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="着"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               prop="arrivalDateTimeRange"
                                               ref="formItemArrivalDateTimeRange">
                                <date-time-range-picker v-model="form.arrivalDateTimeRange" :valid-range="selectableDateRange"
                                                  @change="onChangeDateTimeRange"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="arrivalAddress"
                                               ref="formItemArrivalAddress">
                                <a-input-group compact>
                                    <prefecture-select style="width: 20%"
                                                       :multiple="false"
                                                       placeholder="都道府県"
                                                       title="着地"
                                                       v-model="form.arrivalPrefCode"
                                                       @change="onChangeArrivalAddress"/>
                                    <city-input style="width: 40%"
                                                placeholder="市区町村"
                                                :pref-code="form.arrivalPrefCode"
                                                v-model="form.arrivalCity"
                                                @change="onChangeArrivalAddress"
                                                @blur="form.normalize()"/>
                                    <a-input style="width: 40%"
                                             placeholder="番地・建物（任意）"
                                             v-model="form.arrivalAddress"
                                             @change="onChangeArrivalAddress"
                                             @blur="form.normalize()"/>
                                </a-input-group>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]" class="loading-time-row" v-if="form.hasUnloadingTimeNote">
                        <a-col :offset="3" :xs="16" :md="16" class="loading-time-row--text">
                            <span class="label-title">卸し時間：</span>
                            <span>{{ form.unloadingTimeNote }}</span>
                        </a-col>
                    </a-row>
                    <!-- 荷姿 -->
                    <a-form-model-item label="荷姿"
                                       prop="shape"
                                       ref="formItem">
                        <a-radio-group name="shape"
                                       :options="shapeOptions"
                                       v-model="form.shapeCode"
                                       @change="onChangeShape"/>
                    </a-form-model-item>
                    <a-row class="shape-detail-row">
                        <a-col :span="3"></a-col>
                        <a-col :span="20" class="shape-detail-col">
                            <a-form-model-item label="荷種"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 18 }"
                                               prop="type"
                                               ref="formItemType">
                                <a-row type="flex" align="middle" :gutter="[16, 0]">
                                    <a-col :span="8">
                                        <baggage-type-input v-model="form.type"
                                                            @change="onChangeType"
                                                            @blur="form.normalize()"/>
                                    </a-col>
                                    <a-col :offset="0" :span="16">
                                        <a-space size="large" direction="horizontal">
                                            <div style="display: inline-block">
                                                <span class="label-title">積合：</span><span>{{
                                                    baggage?.share ? '○' : '×'
                                                }}</span>
                                            </div>
                                        </a-space>
                                    </a-col>
                                </a-row>
                            </a-form-model-item>
                            <template v-if="form.isShapePalette">
                                <a-form-model-item label="パレット枚数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="paletteCount"
                                                   ref="formItem">
                                    <a-input v-model="form.paletteCountText"
                                             placeholder="パレット枚数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>
                                <a-form-model-item label="パレットサイズ"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 16 }"
                                                   prop="paletteSize"
                                                   ref="formItem">
                                    <a-input-group>
                                        <a-row :gutter="8">
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteHeightText"
                                                         placeholder="縦"
                                                         addonBefore="縦"
                                                         suffix="cm"
                                                         :max-length="5"
                                                />
                                            </a-col>
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteWidthText"
                                                         placeholder="横"
                                                         addonBefore="横"
                                                         suffix="cm"
                                                         :max-length="5"
                                                />
                                            </a-col>
                                        </a-row>
                                    </a-input-group>
                                </a-form-model-item>
                            </template>
                            <template v-if="form.isShapeOther">
                                <a-form-model-item label="荷物の個数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalCount">
                                    <a-input v-model="form.totalCountText"
                                             placeholder="荷物の個数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>
                                <a-form-model-item label="荷物の体積"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalVolume">
                                    <a-input v-model="form.totalVolumeText"
                                             placeholder="荷物の体積"
                                             :max-length="5">
                                        <template #suffix>m<sup>3</sup></template>
                                    </a-input>
                                </a-form-model-item>
                            </template>
                            <a-form-model-item label="総重量"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="totalWeight">
                                <a-input v-model="form.totalWeightText"
                                         placeholder="総重量"
                                         suffix="kg"
                                         :max-length="5"
                                />
                            </a-form-model-item>
                            <a-form-model-item label="ドライバー作業"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 20 }"
                                               prop="handling">
                                <a-space>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">積み</span>
                                        <a-select v-model="form.loadingCode"
                                                  style="width: 115px;"
                                                  placeholder="積み"
                                                  :options="loadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">卸し</span>
                                        <a-select v-model="form.unloadingCode"
                                                  style="width: 115px;"
                                                  placeholder="卸し"
                                                  :options="unloadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                </a-space>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 温度帯-->
                    <a-form-model-item label="温度帯"
                                       prop="temperatureZone"
                                       ref="formItemTemperatureZone"
                                       :label-col="{ span: 3 }"
                                       :wrapper-col="{ span: 21 }">
                        <a-select style="width: 50%"
                                  placeholder="温度帯を選択"
                                  :options="temperatureZoneOptions"
                                  v-model="form.temperatureZoneCode"
                                  @change="onChangeTemperatureZone"/>
                    </a-form-model-item>
                    <a-form-model-item label="希望車種"
                                       :label-col="{ span: 3 }"
                                       :wrapper-col="{ span: 21 }">
                        <div>
                            <span>重量 {{ baggage?.truckWeight?.label ?? '' }}</span> /
                            <span>車種 {{ baggage?.truckModel?.label ?? '' }}</span>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="車両指定"
                                       :label-col="{ span: 3 }"
                                       :wrapper-col="{ span: 21 }"
                                       class="specify-truck-row"
                                       v-if="specifiedTruckItems.length > 0">
                        <div>
                            <span v-for="(item, index) in specifiedTruckItems" :key="item.label">
                                {{ item.label }} {{ item.value }}
                                <template v-if="index != specifiedTruckItems.length - 1"> / </template>
                            </span>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="必要装備"
                                       :label-col="{ span: 3 }"
                                       :wrapper-col="{ span: 21 }"
                                       class="truck-equipment-row"
                                       v-if="baggage?.truckEquipment">
                        <span>{{ baggage?.truckEquipment }}</span>
                    </a-form-model-item>
                    <!-- 備考 -->
                    <a-form-model-item label="備考"
                                       prop="description">
                        <a-textarea :placeholder="descriptionPlaceholder"
                                    v-model="form.description"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>

                    <div class="focused-container">
                        <a-row class="focused-row">
                            <a-col :xs="24" :md="8">
                                <!-- 運賃 -->
                                <a-form-model-item label="運賃"
                                                   prop="freight"
                                                   :label-col="amountCols.first.labelCol"
                                                   :wrapper-col="amountCols.first.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(税別)"
                                             v-model="form.freightValue"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :xs="24" :md="16">
                                <!-- 高速代 -->
                                <a-form-model-item label="高速代"
                                                   prop="highwayFare"
                                                   ref="formItemHighwayFare"
                                                   :label-col="amountCols.second.labelCol"
                                                   :wrapper-col="amountCols.second.wrapperCol">
                                    <a-space size="middle" direction="horizontal">
                                        <a-input class="input"
                                                 suffix="円(税込)"
                                                 :placeholder="form.noHighwayFare ? '' : '金額未定'"
                                                 :disabled="form.noHighwayFare"
                                                 v-model="form.highwayFareValue"
                                                 @change="onChangeHighwayFare"/>
                                        <a-checkbox class="checkbox" :checked="form.noHighwayFare"
                                                    @change="onChangeNoHighwayFare">
                                            支払いなし
                                        </a-checkbox>
                                    </a-space>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row class="focused-row">
                            <a-col :xs="24" :md="8">
                                <!-- 待機料 -->
                                <a-form-model-item label="待機料"
                                                   prop="waitTimeFee"
                                                   :label-col="amountCols.first.labelCol"
                                                   :wrapper-col="amountCols.first.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(税別)"
                                             placeholder="金額未定"
                                             v-model="form.waitTimeFeeValue"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :xs="24" :md="16">
                                <!-- 付帯作業料 -->
                                <a-form-model-item label="付帯作業料"
                                                   prop="operationFee"
                                                   :label-col="amountCols.second.labelCol"
                                                   :wrapper-col="amountCols.second.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(税別)"
                                             placeholder="金額未定"
                                             v-model="form.operationFeeValue"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row class="focused-row">
                            <a-col :xs="24" :md="8">
                                <!-- 搬出料 -->
                                <a-form-model-item label="搬出料"
                                                   prop="pickingFee"
                                                   :label-col="amountCols.first.labelCol"
                                                   :wrapper-col="amountCols.first.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(税込)"
                                             placeholder="金額未定"
                                             v-model="form.pickingFeeValue"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col :xs="24" :md="16">
                                <!-- 駐車代 -->
                                <a-form-model-item label="駐車代"
                                                   prop="parkingFee"
                                                   :label-col="amountCols.second.labelCol"
                                                   :wrapper-col="amountCols.second.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(税込)"
                                             placeholder="金額未定"
                                             v-model="form.parkingFeeValue"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row class="focused-row">
                            <a-col :xs="24" :md="8">
                                <!-- 通関料 -->
                                <a-form-model-item label="通関料"
                                                   prop="clearanceFee"
                                                   :label-col="amountCols.first.labelCol"
                                                   :wrapper-col="amountCols.first.wrapperCol">
                                    <a-input class="input"
                                             suffix="円(非課税)"
                                             placeholder="金額未定"
                                             v-model="form.clearanceFeeValue"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <!-- 担当者 -->
                        <a-form-model-item label="担当者"
                                           :wrapper-col="{ span: 16 }"
                                           prop="truckStaffName"
                                           class="focused-row">
                            <a-auto-complete style="width: 50%"
                                             placeholder="担当者名"
                                             class="row-editable"
                                             :default-active-first-option="false"
                                             :allow-clear="true"
                                             option-label-prop="title"
                                             :filter-option="staffNameFilterOption"
                                             v-model="form.truckStaffName"
                                             @blur="form.normalize()">
                                <template #dataSource>
                                    <a-select-option v-for="text in suggestionList.staffNames"
                                                     :key="text"
                                                     :title="text">
                                        <span>{{ text }}</span>
                                        <a-button style="float: right"
                                                  type="default"
                                                  icon="delete"
                                                  size="small"
                                                  @click="onClickStaffNameDelete(text, $event)"/>
                                    </a-select-option>
                                </template>
                            </a-auto-complete>
                        </a-form-model-item>
                        <!-- 支払い予定日 -->
                        <a-form-model-item label="入金予定日"
                                           class="focused-row"
                                           prop="paymentDate"
                                           ref="formItem">
                            <a-space size="middle" direction="horizontal">
                                <date-picker placeholder="入金予定日"
                                             :input-read-only="true"
                                             format="YYYY年M月D日(ddd)"
                                             :allowClear="false"
                                             :disabledDate="form.isDisabledPaymentDate"
                                             valueFormat="YYYY-MM-DD"
                                             v-model="form.paymentDateValue">
                                    <template #suffixIcon>
                                        <tbx-icon type="calendar"/>
                                    </template>
                                </date-picker>
                                <a-alert type="warning" message="相手先企業に確認してご入力ください"/>
                            </a-space>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.settlementProxy"
                                           label="おまかせ請求"
                                           class="focused-row"
                                           prop="settlementProxy">
                            <agreement-settlement-proxy-input v-model="form.settlementProxy" :disabled="!canProxy"/>
                        </a-form-model-item>
                    </div>

                    <a-row type="flex" align="middle" :gutter="[16, 0]" style="margin-top: 32px">
                        <a-col :offset="3" :span="18" style="text-align: center;">
                            <a-space direction="horizontal" size="large">
                                <a-button :type="existsGuarantee ? 'secondary' : 'primary'"
                                          size="large"
                                          block
                                          @click="onSubmit(false)">成約する
                                </a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                </a-card>
            </a-form-model>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
// typescript/pages/Agreement/Register/style.less
.ant-form {
    padding: 24px;
}

.form-card {
    padding: 16px 0;
}

.shape-detail-row {
    margin-top: -16px;
    margin-bottom: 16px;
}

.shape-detail-col {
    background-color: @color-neutral-3;
    padding-top: 20px;
}

.specify-truck-row, .truck-equipment-row {
    margin-bottom: 24px;

    &--label {
        padding-right: 10px;
        text-align: end;
    }

    &--text {
    }
}

.focused-container {
    // 背景色が幅全開を占めるようにする
    background-color: #f1ffa9;
    margin: 0 -24px 24px;
    padding: 24px 24px 24px;

    // 最後の子エレメントの余分なマージンを消す
    > *:last-child.ant-form-item {
        margin-bottom: 0;
    }

    // フォームアイテムのラベルを強調する
    :deep(.ant-form-item-label) {
        font-weight: bold;
    }
}

// typescript/components/Agreement/Register/Spot/style.less
.loading-time-row, .unloading-time-row {
    margin-top: -20px;
    margin-bottom: 16px;

    &--label {
        text-align: end;
    }

    &--text {
        text-align: start;
    }
}

.label-title {
    color: @heading-color;
}

.baggage-agreement-spot-row {
    margin-bottom: 20px;

    .ant-form-item {
        margin-bottom: 4px;
    }
}

// typescript/components/Agreement/Register/Handling/style.less
.handling-type-label {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    padding: 4px 11px 0 11px;
    height: 32px;
}

// typescript/components/Agreement/Register/SpecifiedTruck/style.less
.specify-truck-row {
    margin-top: -24px;
}

// typescript/components/Agreement/Register/Equipment/style.less
.truck-equipment-row {
    margin-top: -24px;
}

.input {
    max-width: 180px;
}

.checkbox {
    font-weight: normal;
}

.ant-alert {
    font-weight: normal;
}

.guarantee-warning {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
}

// typescript/components/Agreement/Register/Guarantee/style.less
.guarantee {
    background-color: #ffffff;
    position: absolute;
    top: -13px;
}

.ant-card {
    transition: height 600ms ease-in-out;
    min-height: 72px;
}

.guarantee-usage-summary {
    .ant-alert {
        margin-bottom: 16px;
    }
}

.guarantee-info {
    .ant-alert {
        margin-bottom: 16px;
    }

    .banner {
        width: 460px;
        height: 103px;
        margin-bottom: 16px;

        &__img {
            width: 100%;
            height: auto;
        }
    }

    .apply-note {
        font-weight: bold;
        margin-top: 8px;
    }
}

// Descriptions のスタイル
.descriptions-title {
    font-size: 12px;
    font-weight: bold;
}

.descriptions-title + .ant-descriptions {
    margin-top: 8px;
}

.descriptions-item-title {
    font-size: 12px;
    text-align: center;
}

.descriptions-item-amount {
    text-align: right;

    &:not(:empty)::after {
        display: inline-block;
        content: '\5186'; // 円
        margin-left: 4px;
    }
}

.descriptions-title {
    font-size: 12px;
    font-weight: bold;
}

.descriptions-title + .ant-descriptions {
    margin-top: 8px;
}

.descriptions-item-title {
    font-size: 12px;
    text-align: center;
}

.descriptions-item-amount {
    text-align: right;

    &:not(:empty)::after {
        display: inline-block;
        content: '\5186'; // 円
        margin-left: 4px;
    }
}
</style>
