import { CompanyNotice } from '@/vuex/modules/companyNotice/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const companyNoticeApi = {
    /**
     * 企業お知らせ一覧を取得します。
     */
    list(): Promise<CompanyNotice[]> {
        return appAxios.get<JsonResponseBean<CompanyNotice[]>>('/api/company/notice/list').then(isSuccess);
    },
    /**
     * 企業お知らせを既読にします。
     */
    markAsRead(param: number): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/company/notice/${ param }/read`).then(isSuccess);
    },
};
