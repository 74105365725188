import { computed, ref } from 'vue';
import { helpApi } from '@/repository/api/internal/help';
import { ReportLatePaymentFormModel } from '@/models/help';
import { FormValidateUtil } from '@/models/validate-helper';
import { useLoading } from '@/composables/helper/loading-helper';

export const useReportLatePaymentRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new ReportLatePaymentFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const register = () => withLoading(async () => {
        await helpApi.reportUnpaid(form.value);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        register,
    };
};
