import { Component, Prop, Vue } from 'vue-property-decorator';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { miscApi, PrefCityResult } from '@/repository/api/api';

@Component
export default class UiCityInput extends Vue {
    @Prop()
    declare value?: string;
    @Prop()
    declare prefCode?: PrefectureEnumCode;
    @Prop({ default: '市区町村' })
    declare placeholder: string;
    cityData: PrefCityResult[] = [];
    dataSource: { value: string; text: string }[] = [];
    private prevPrefCode: PrefectureEnumCode | '' = '';

    /**
     * 市区町村名テキスト
     */
    get city(): string {
        return this.value ?? '';
    }

    set city(newValue: string) {
        const result = this.cityData.find((each) => each.code === newValue);
        this.$emit('input', result?.name ?? newValue);
    }

    /**
     * オートコンプリートのサジェストに必要なデータソースを設定します。
     * @param value
     */
    setDataSource(value?: string): void {
        if (value === undefined) {
            this.dataSource = [];
            return;
        }
        this.dataSource = this.cityData
            .filter((each) => each.name.indexOf(value) >= 0)
            .map((each) => ({ value: each.code, text: each.name }));
    }

    /**
     * 入力フィールドをfocusした際に呼び出されます。
     */
    async onFocusInput(): Promise<void> {
        if (!this.prefCode) {
            this.setDataSource();
            this.cityData = [];
            this.prevPrefCode = '';
            return;
        }
        if (this.prevPrefCode !== this.prefCode) {
            this.setDataSource();
            this.cityData = await UiCityInput.getCityList(this.prefCode);
            this.prevPrefCode = this.prefCode;
        }
        this.setDataSource(this.city);
    }

    /**
     * 入力フィールドに文字を入力すると呼び出されます。
     * @param value
     */
    async onSearchCity(value: string): Promise<void> {
        this.setDataSource(value);
    }

    /**
     * Blur時に呼び出されます。
     */
    onBlur(): void {
        this.$emit('blur');
    }

    /**
     * 指定した都道府県コードから該当する市区町村リストを取得します。
     * @param prefCode
     * @private
     */
    private static getCityList(prefCode: PrefectureEnumCode): Promise<PrefCityResult[]> {
        return miscApi.listCity(prefCode);
    }
}
