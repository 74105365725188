import { SelectOption } from '@/types/ant-design';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';

const labelCodeToOptions = <T>(enums: { label: string, code: T }[]): SelectOption<T>[] => {
    return enums.map((each) => { return { value: each.code, label: each.label, key: each.code }; });
};

export const useTruckWeightOptions = () => {
    return {
        registrableForTruckOptions: labelCodeToOptions<string>(TruckWeightEnum.registrableValuesForTruck),
        registrableForBaggageOptions: labelCodeToOptions<string>(TruckWeightEnum.registrableValuesForBaggage),
    };
};

export const useTruckModelOptions = () => {
    return {
        registrableForTruckOptions: labelCodeToOptions<string>(TruckModelEnum.registrableValuesForTruck),
        registrableForBaggageOptions: labelCodeToOptions<string>(TruckModelEnum.registrableValuesForBaggage),
    };
};
