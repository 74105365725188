import { useLoading } from '@/composables/helper/loading-helper';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountEmailVerify = () => {
    const { state: { loading }, withLoading } = useLoading();

    const verify = (verificationCode: string) => withLoading(async () => {
        if (!verificationCode.trim()) throw Error('no verification code.');

        await accountApi.verifyEmail({ verificationCode });
    });

    return {
        state: {
            loading,
        },
        verify,
    };
};
