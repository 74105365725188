import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import TraCheckbox from '@/components/Baggage/Search/Condition/Checkbox';

@Component({ components: { TraCheckbox } })
export default class BaggageSearchConditionShareEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: boolean;

    // noinspection JSUnusedGlobalSymbols
    /**
     * 積合のみ
     */
    get onlyShare(): boolean {
        return this.value ?? false;
    }

    // noinspection JSUnusedGlobalSymbols
    set onlyShare(newValue: boolean) {
        this.$emit('input', newValue ? true : undefined);
    }

    /**
     * 積合を除く
     */
    get excludeShare(): boolean {
        return this.value === false;
    }

    set excludeShare(newValue: boolean) {
        this.$emit('input', newValue ? false : undefined);
    }
}
