import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { RenderContext } from 'vue/types/options';
// @ts-ignore
import JapanMap from '@/components/UI/PrefectureSelect/JapanMap';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { RegionEnum } from '@/enums/region.enum';
import { RegionUtil } from '@/util';

@Component({
    components: {
        JapanMap,
        VNodes: {
            functional: true,
            render: (_h: CreateElement, context: RenderContext) => context.props.vnodes,
        },
    },
})
export default class UiPrefectureSelect extends Vue {
    @Prop()
    declare readonly value?: PrefectureEnumCode | Array<PrefectureEnumCode>;
    @Prop()
    declare readonly size?: 'default' | 'large' | 'small'; // select メニューのサイズ
    @Prop({ default: true })
    declare readonly multiple: boolean; // 複数選択を許容するか否か
    @Prop({ default: false })
    declare readonly allowClear: boolean; // 選択解除を許容するか否か（単一選択モードでのみ機能します）
    @Prop({ default: true })
    declare readonly ellipsis: boolean; // タグが1行に収まらない場合は「・・・」の省略記号を表示するか否か（複数選択モードでのみ機能します）
    @Prop({ default: '都道府県' })
    declare readonly placeholder: string; // select メニューのプレースホルダ
    @Prop({ default: false })
    declare readonly placeholderIcon: boolean; // メニューの前にプレースホルダアイコンを表示するか否か
    @Prop({ default: '' })
    declare readonly title: string; // 都道府県マップに表示するタイトルテキスト
    @Prop({ default: 'bottomCenter' })
    declare readonly placement: string; // ドロップダウン内のコンテンツ（＝日本地図）を表示する位置

    isMenuOpen = false; // ドロップダウンメニューの開閉状態

    /**
     * 都道府県の値
     * propsで受ける `value` は PrefectureEnumCode or Array<PrefectureEnumCode> となるため、
     * Component 内部では Array<PrefectureEnumCode> として扱えるようにする
     */
    get prefectureValues(): PrefectureEnumCode[] {
        if (!this.value) {
            return [];
        } else if (this.multiple && _.isArray(this.value)) {
            return this.value;
        } else if (_.isString(this.value)) {
            // 文字列でバインドされた場合、内部では配列として扱う
            return [this.value];
        }
        return [];
    }

    set prefectureValues(value: PrefectureEnumCode[]) {
        this.$emit('input', this.multiple ? _.cloneDeep(value) : value[0]);

        if (!this.multiple) {
            this.closeDropdown();
        }
    }

    /**
     * ドロップダウンメニューの表示用ラベル（単一選択時）
     */
    get labelForSingleChoice(): string {
        return this.labels.map((each) => each.label).join('、');
    }

    /**
     * プルダウンメニューの表示用ラベルの値
     * 地域名または都道府県名を返します。
     */
    get labels(): Array<RegionEnum | PrefectureEnum> {
        return RegionUtil.parseRegionsFromPrefectures(this.prefectureValues);
    }

    set labels(values: Array<RegionEnum | PrefectureEnum>) {
        const selectedPrefectures = values.map((v) => {
            if (v instanceof RegionEnum) {
                return v.prefectures;
            }
            return v.code;
        });
        this.$emit('input', _.uniq([_.flatten(selectedPrefectures)]));
    }

    /**
     * プルダウンメニュー（ant-select）に付与するstyleClassを取得します。
     */
    get selectStyleClass(): { [key: string]: boolean } {
        return {
            'ant-select-lg': this.size === 'large',
            'ant-select-sm': this.size === 'small',
            'ant-select-default': this.size === 'default',
            'ant-select-open': this.isMenuOpen,
        };
    }

    created(): void {
        if (this.multiple && this.value && !_.isArray(this.value)) {
            throw new Error('v-model(value) must be array object when multiple selection mode.');
        } else if (!this.multiple && this.value && !_.isString(this.value)) {
            throw new Error('v-model(value) must be string when single selection mode.');
        }
    }

    mounted(): void {
        this.$nextTick(() => {
            // DOM表示後に再代入することで強制的にラベルの幅計算を行う。
            this.prefectureValues = _.cloneDeep(this.prefectureValues);
        });
    }

    /**
     * 選択した項目（都道府県）を取り除きます。
     */
    removeItem(event: Event, value: RegionEnum | PrefectureEnum): void {
        event.stopPropagation(); // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        this.prefectureValues = _.difference(
            this.prefectureValues,
            value instanceof RegionEnum ? value.prefectures : [value.code]
        );
    }

    /**
     * ドロップダウンメニューを閉じます。
     */
    closeDropdown(): void {
        this.isMenuOpen = false;
    }

    /**
     * ラベルが枠からはみ出てしまう項目のインデックスを取得します。
     */
    getOverflowIndex(index: number): number {
        if (index < 0) {
            return -1;
        }
        const tagsElement = this.$refs.tags as Element | undefined;
        if (!tagsElement) {
            // 初期表示では常にここに来てしまう
            return -1;
        }
        const tagBaseWidth = this.size === 'large' ? 36 : 32;
        const tagSpacing = 4;
        const dropdownIconWidth = 16;
        const widthByLabels = this.labels.map((each) => {
            const label = each instanceof PrefectureEnum ? each.shortLabel : each.label;
            return tagSpacing + tagBaseWidth + label.length * 14;
        });
        return widthByLabels.findIndex(
            (_each, labelIndex, array) =>
                _.sum(_.slice(array, 0, labelIndex + 1)) >= tagsElement.clientWidth - dropdownIconWidth
        );
    }

    /**
     * 該当の項目がプルダウンメニューの枠からはみ出てしまうか否かを取得します。
     */
    isOverflowTag(index: number): boolean {
        if (!this.ellipsis) {
            return false;
        }
        const overflowIndex = this.getOverflowIndex(index);
        return overflowIndex > 0 && index >= overflowIndex;
    }

    /**
     * 都道府県ラベルをクリックすると呼び出されます。
     */
    onClickChoiceContent(e: Event): void {
        // ドロップダウンが勝手に開いてしまわないようにイベントを抑制
        e.stopPropagation();
    }

    /**
     * 都道府県選択状態でフォーム内右寄りの閉じるボタン（バツ印）をクリックすると呼び出されます。
     */
    onClickClear(e: Event): void {
        this.$emit('input', undefined);
        e.stopPropagation();
    }
}
