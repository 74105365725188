import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UiTableRowBadge extends Vue {
    @Prop({ default: 'left' })
    declare readonly align: 'left';

    @Prop()
    declare readonly type?: string;
}
