/**
 * 時間を管理するマスタEnum
 * このEnum値はフロント内部でのみ利用している概念であり、バックエンド側では用いていない。
 */
export type TimeMinuteEnumCode =
    | 'None' // 分の指定なし
    | 'M00'
    | 'M10'
    | 'M20'
    | 'M30'
    | 'M40'
    | 'M50';

export class TimeMinuteEnum {
    constructor(
        public code: TimeMinuteEnumCode,
        public label: string,
        public type: 'Hourly' | 'Just',
        public minute?: number
    ) {}

    static None = new TimeMinuteEnum('None', '指定なし', 'Hourly');
    static M00 = new TimeMinuteEnum('M00', '00分', 'Just', 0);
    static M10 = new TimeMinuteEnum('M10', '10分', 'Just', 10);
    static M20 = new TimeMinuteEnum('M20', '20分', 'Just', 20);
    static M30 = new TimeMinuteEnum('M30', '30分', 'Just', 30);
    static M40 = new TimeMinuteEnum('M40', '40分', 'Just', 40);
    static M50 = new TimeMinuteEnum('M50', '50分', 'Just', 50);

    static valueOf = (code: TimeMinuteEnumCode): TimeMinuteEnum | undefined =>
        TimeMinuteEnum.values.find((value) => value.code === code);
    static values = [
        TimeMinuteEnum.None,
        TimeMinuteEnum.M00,
        TimeMinuteEnum.M10,
        TimeMinuteEnum.M20,
        TimeMinuteEnum.M30,
        TimeMinuteEnum.M40,
        TimeMinuteEnum.M50,
    ];
}
