import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import _ from 'lodash';

@Component
export default class BaggageSpecifiedTruck extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get specifiedTruck(): string {
        if (!this.baggage) return '';

        const height = this.baggage?.truckHeight?.label;
        const width = this.baggage?.truckWidth?.label;
        const largeTruckFlg = this.baggage?.largeTruckFlg;
        const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

        return `床高：${ height ?? '指定なし' } / 車幅：${ width ?? '指定なし' } / 大型：${ largeTruckText }`;
    }
}
