import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementShare extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;

    get shared(): string {
        if (!this.agreement) return '';
        return this.agreement.share ? '○' : '×';
    }
}
