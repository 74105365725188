import { Enum } from '@/types/enum';
import { PhoneUtil, Util } from '@/util';
import { DateValue } from '@/models/vo/date';
import { DateTimeValue } from '@/models/vo/datetime';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { CancellationFeeValue, FreightValue } from '@/models/vo/freight';
import { TruckHeightEnum, TruckHeightEnumCode } from '@/enums/truck-height.enum';
import { TruckWidthEnum, TruckWidthEnumCode } from '@/enums/truck-width.enum';
import { SettlementStatusEnum, SettlementStatusEnumCode } from '@/enums/settlement-status.enum';
import { AmountValue } from '@/models/vo/amount';
import { CompanyProfile, CompanyProfileName, CompanyProfilePhone } from '@/models/company';
import _ from 'lodash';
import { TruckType } from '@/_components/ui/types/truck-type';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Const } from '@/const';
import { availableSettlementFeeRange } from '@/composables/settlement-income-register-viewmodel';

export type SettlementList = {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: { settlement: Settlement, partner: SettlementPartner }[];
};

export type Settlement = {
    id: number;
    payeeCompanyId: number;
    payerCompanyId: number;
    status: Enum<SettlementStatusEnumCode>;
    agreementId?: number;
    proxy: boolean;
    payeeDate: string;
    payerDate: string;
    departureDate: string;
    departurePref: Enum<PrefectureEnumCode>;
    departureCity: string;
    departureAddress?: string;
    arrivalDate: string;
    arrivalPref: Enum<PrefectureEnumCode>;
    arrivalCity: string;
    arrivalAddress?: string;
    loadingTimeNote?: string;
    unloadingTimeNote?: string;
    category: Enum<BaggageCategoryEnumCode>;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum<TruckWeightEnumCode>;
    truckModel: Enum<TruckModelEnumCode>;
    truckHeight?: Enum<TruckHeightEnumCode>;
    truckWidth?: Enum<TruckWidthEnumCode>;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    freight: number;
    highwayFare: number;
    highwayFareFlg: boolean;
    waitTimeFee?: number;
    operationFee?: number;
    pickingFee?: number;
    parkingFee?: number;
    clearanceFee?: number;
    cancellationFee?: number;
    staffName: string;
    truckStaffName?: string;
    description?: string;
    confirmed: boolean;
    agreementTm: string;
    lastUpdateTm: string;
    changeLimitTm: string;
};

export type SettlementPartner = {
    name: CompanyProfileName;
    phone: CompanyProfilePhone;
    active?: boolean;
};

export type SettlementChangeRequest = {
    id: number;
    settlementId: number;
    type: Enum;
    status: Enum;
    proxy: boolean;
    freight: number;
    highwayFareFlg: boolean;
    highwayFare: number;
    waitTimeFee?: number;
    operationFee?: number;
    pickingFee?: number;
    parkingFee?: number;
    clearanceFee?: number;
    cancellationFee?: number;
    requestTm: string;
};

export type SettlementTotalAmount = {
    date: string;
    totalAmount: number;
};

export class SettlementListModel {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: { settlement: SettlementModel, partner: SettlementPartner }[];

    constructor(list: SettlementList) {
        this.pageSize = list.pageSize;
        this.totalPageCount = list.totalPageCount;
        this.totalRecordCount = list.totalRecordCount;
        this.currentPageNumber = list.currentPageNumber;
        this.data = list.data.map(each => ({
            settlement: new SettlementModel(each.settlement),
            partner: new SettlementPartnerModel(each.partner),
        }));
    }

    find(settlementId: number): SettlementModel | undefined {
        return this.data.find(each => each.settlement.id === settlementId)?.settlement;
    }

    static empty(): SettlementListModel {
        return new SettlementListModel({
            pageSize: 50,
            totalPageCount: 1,
            totalRecordCount: 0,
            currentPageNumber: 1,
            data: [],
        });
    }
}

export class SettlementModel {
    id: number;
    payeeCompanyId: number;
    payerCompanyId: number;
    status: SettlementStatusEnum;
    agreementId?: number;
    proxy: boolean;
    payeeDate: DateValue;
    payerDate: DateValue;
    departureDate: DateValue;
    departurePref: PrefectureEnum;
    departureCity: string;
    departureAddress?: string;
    arrivalDate: DateValue;
    arrivalPref: PrefectureEnum;
    arrivalCity: string;
    arrivalAddress?: string;
    loadingTimeNote?: string;
    unloadTimeNote?: string;
    category: BaggageCategoryEnum;
    type: string;
    shape: BaggageShapeEnum;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loadingOperation?: BaggageHandlingTypeEnum;
    unloadingOperation?: BaggageHandlingTypeEnum;
    truckType: {
        weight: TruckWeightEnum;
        model: TruckModelEnum;
    };
    truckHeight?: TruckHeightEnum;
    truckWidth?: TruckWidthEnum;
    largeTruck?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    freight: FreightValue;
    highwayFare: AmountValue;
    waitTimeFee?: AmountValue;
    operationFee?: AmountValue;
    pickingFee?: AmountValue;
    parkingFee?: AmountValue;
    clearanceFee?: AmountValue;
    cancellationFee?: CancellationFeeValue;
    staffName: string;
    truckStaffName?: string;
    description?: string;
    confirmed: boolean;
    agreementTm: DateTimeValue;
    lastUpdateTm: DateTimeValue;
    changeLimitTm: DateTimeValue;

    constructor(param: Settlement) {
        this.id = param.id;
        // 初期化
        this.payeeCompanyId = param.payeeCompanyId;
        this.payerCompanyId = param.payerCompanyId;
        this.status = Util.requireNotNull(SettlementStatusEnum.valueOf(param.status.code));
        this.agreementId = param.agreementId;
        this.proxy = param.proxy;
        this.payeeDate = new DateValue(param.payeeDate);
        this.payerDate = new DateValue(param.payerDate);
        this.departureDate = new DateValue(param.departureDate);
        this.departurePref = Util.requireNotNull(PrefectureEnum.valueOf(param.departurePref.code));
        this.departureCity = param.departureCity;
        this.departureAddress = param.departureAddress;
        this.arrivalDate = new DateValue(param.arrivalDate);
        this.arrivalPref = Util.requireNotNull(PrefectureEnum.valueOf(param.arrivalPref.code));
        this.arrivalCity = param.arrivalCity;
        this.arrivalAddress = param.arrivalAddress;
        this.loadingTimeNote = param.loadingTimeNote;
        this.unloadTimeNote = param.unloadingTimeNote;
        this.category = Util.requireNotNull(BaggageCategoryEnum.valueOf(param.category.code));
        this.type = param.type;
        this.shape = Util.requireNotNull(BaggageShapeEnum.valueOf(param.shape.code));
        this.paletteCount = param.paletteCount;
        this.paletteHeight = param.paletteHeight;
        this.paletteWidth = param.paletteWidth;
        this.totalCount = param.totalCount;
        this.totalVolume = param.totalVolume;
        this.totalWeight = param.totalWeight;
        this.loadingOperation = BaggageHandlingTypeEnum.valueOf(param.loading?.code);
        this.unloadingOperation = BaggageHandlingTypeEnum.valueOf(param.unloading?.code);
        this.truckType = {
            weight: Util.requireNotNull(TruckWeightEnum.valueOf(param.truckWeight.code)),
            model: Util.requireNotNull(TruckModelEnum.valueOf(param.truckModel.code)),
        };
        this.truckHeight = param.truckHeight ? Util.requireNotNull(TruckHeightEnum.valueOf(param.truckHeight.code)) : undefined;
        this.truckWidth = param.truckWidth ? Util.requireNotNull(TruckWidthEnum.valueOf(param.truckWidth.code)) : undefined;
        this.largeTruck = param.largeTruckFlg;
        this.truckEquipment = param.truckEquipment;
        this.share = param.share;
        this.express = param.express;
        this.freight = new FreightValue(param.freight);
        this.highwayFare = new AmountValue(param.highwayFare);
        this.waitTimeFee = param.waitTimeFee ? new AmountValue(param.waitTimeFee) : undefined;
        this.operationFee = param.operationFee ? new AmountValue(param.operationFee) : undefined;
        this.pickingFee = param.pickingFee ? new AmountValue(param.pickingFee) : undefined;
        this.parkingFee = param.parkingFee ? new AmountValue(param.parkingFee) : undefined;
        this.clearanceFee = param.clearanceFee ? new AmountValue(param.clearanceFee) : undefined;
        this.cancellationFee = param.cancellationFee ? new CancellationFeeValue(param.cancellationFee) : undefined;
        this.staffName = param.staffName;
        this.truckStaffName = param.truckStaffName;
        this.description = param.description;
        this.confirmed = param.confirmed;
        this.agreementTm = new DateTimeValue(param.agreementTm);
        this.lastUpdateTm = new DateTimeValue(param.lastUpdateTm);
        this.changeLimitTm = new DateTimeValue(param.changeLimitTm);
    }

    get departureLabel(): string {
        const departureDate = this.departureDate.format('M/D(ddd)');
        const departureLocation = `${ this.departurePref.label }${ this.departureCity }`;
        return `${ departureDate } ${ departureLocation }`;
    }

    get departureLocation(): string {
        return this.departurePref.label + this.departureCity + (this.departureAddress ?? '');
    }

    get arrivalLabel(): string {
        const arrivalDate = this.arrivalDate.format('M/D(ddd)');
        const arrivalLocation = `${ this.arrivalPref.label }${ this.arrivalCity }`;
        return `${ arrivalDate } ${ arrivalLocation }`;
    }

    get arrivalLocation(): string {
        return this.arrivalPref.label + this.arrivalCity + (this.arrivalAddress ?? '');
    }

    get totalAmount(): AmountValue {
        if (this.hasCancellationFee) {
            return new AmountValue(this.cancellationFee?.value ?? 0);
        } else {
            return new AmountValue(Util.requireNotNull(this.freight.value)
                + (this.highwayFare.value)
                + (this.waitTimeFee?.value ?? 0)
                + (this.operationFee?.value ?? 0)
                + (this.pickingFee?.value ?? 0)
                + (this.parkingFee?.value ?? 0)
                + (this.clearanceFee?.value ?? 0));
        }
    }

    get hasCancellationFee(): boolean {
        return (this.cancellationFee?.value ?? 0) > 0;
    }

    isExpiredChangeLimit(now: DateTimeValue = DateTimeValue.now()): boolean {
        return this.changeLimitTm.isBefore(now);
    }

    toggleConfirmed(value: boolean | undefined = undefined): void {
        this.confirmed = value ?? !this.confirmed;
    }

    /**
     * 変更申請可能か否かを取得する。
     */
    get isReadyToUpdate(): boolean {
        return this.status.code === SettlementStatusEnum.Active.code;
    }
}

export class SettlementPartnerModel {
    name: CompanyProfileName;
    phone: CompanyProfilePhone;
    active?: boolean;

    constructor(param: SettlementPartner) {
        this.name = param.name;
        this.phone = param.phone;
        this.active = param.active;
    }

    get formattedPhoneNumber(): string {
        return PhoneUtil.format(this.phone.number);
    }

    get formattedFaxNumber(): string {
        return PhoneUtil.format(this.phone.faxNumber);
    }
}

export class SettlementChangeRequestModel {
    id: number;
    settlementId: number;
    type: Enum;
    status: Enum;
    proxy: boolean;
    freight: FreightValue;
    highwayFare: AmountValue;
    waitTimeFee?: AmountValue;
    operationFee?: AmountValue;
    pickingFee?: AmountValue;
    parkingFee?: AmountValue;
    clearanceFee?: AmountValue;
    cancellationFee?: CancellationFeeValue;
    requestTm: DateTimeValue;

    constructor(param: SettlementChangeRequest) {
        this.id = param.id;
        this.settlementId = param.settlementId;
        this.type = param.type;
        this.status = param.status;
        this.proxy = param.proxy;
        this.freight = new FreightValue(param.freight);
        this.highwayFare = new AmountValue(param.highwayFare);
        this.waitTimeFee = param.waitTimeFee ? new AmountValue(param.waitTimeFee) : undefined;
        this.operationFee = param.operationFee ? new AmountValue(param.operationFee) : undefined;
        this.pickingFee = param.pickingFee ? new AmountValue(param.pickingFee) : undefined;
        this.parkingFee = param.parkingFee ? new AmountValue(param.parkingFee) : undefined;
        this.clearanceFee = param.clearanceFee ? new AmountValue(param.clearanceFee) : undefined;
        this.cancellationFee = param.cancellationFee ? new CancellationFeeValue(param.cancellationFee) : undefined;
        this.requestTm = new DateTimeValue(param.requestTm);
    }

    get totalAmount(): AmountValue {
        if (this.hasCancellationFee) {
            return new AmountValue(this.cancellationFee?.value ?? 0);
        } else {
            return new AmountValue(Util.requireNotNull(this.freight.value)
                + (this.highwayFare.value)
                + (this.waitTimeFee?.value ?? 0)
                + (this.operationFee?.value ?? 0)
                + (this.pickingFee?.value ?? 0)
                + (this.parkingFee?.value ?? 0)
                + (this.clearanceFee?.value ?? 0));
        }
    }

    get hasCancellationFee(): boolean {
        return (this.cancellationFee?.value ?? 0) > 0;
    }
}

export class SettlementDiffValue<T> {
    source?: T;
    request?: T;

    constructor(source?: T, request?: T) {
        this.source = source;
        this.request = request;
    }

    get hasDiff(): boolean {
        return !_.isEqual(this.source, this.request);
    }
}

export class SettlementChangeDiffModel {
    source: SettlementModel;
    request: SettlementChangeRequestModel;

    constructor(source: SettlementModel, request: SettlementChangeRequestModel) {
        this.source = source;
        this.request = request;
    }

    get proxyDiff(): SettlementDiffValue<boolean> {
        return new SettlementDiffValue(this.source.proxy, this.request.proxy);
    }

    get freightDiff(): SettlementDiffValue<FreightValue> {
        return new SettlementDiffValue(this.source.freight, this.request.freight);
    }

    get highwayFareDiff(): SettlementDiffValue<AmountValue> {
        return new SettlementDiffValue(this.source.highwayFare, this.request.highwayFare);
    }

    get waitTimeFeeDiff(): SettlementDiffValue<AmountValue | undefined> {
        return new SettlementDiffValue(this.source.waitTimeFee, this.request.waitTimeFee);
    }

    get operationFeeDiff(): SettlementDiffValue<AmountValue | undefined> {
        return new SettlementDiffValue(this.source.operationFee, this.request.operationFee);
    }

    get pickingFeeDiff(): SettlementDiffValue<AmountValue | undefined> {
        return new SettlementDiffValue(this.source.pickingFee, this.request.pickingFee);
    }

    get parkingFeeDiff(): SettlementDiffValue<AmountValue | undefined> {
        return new SettlementDiffValue(this.source.parkingFee, this.request.parkingFee);
    }

    get clearanceFeeDiff(): SettlementDiffValue<AmountValue | undefined> {
        return new SettlementDiffValue(this.source.clearanceFee, this.request.clearanceFee);
    }

    get cancellationFeeDiff(): SettlementDiffValue<CancellationFeeValue | undefined> {
        return new SettlementDiffValue(this.source.cancellationFee, this.request.cancellationFee);
    }

    get totalAmountDiff(): SettlementDiffValue<AmountValue> {
        return new SettlementDiffValue(this.source.totalAmount, this.request.totalAmount);
    }
}

export interface SettlementChangeRequestListModel {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: { settlement: SettlementModel, partner: SettlementPartner, diff?: SettlementChangeDiffModel }[];
}

export type SettlementPartnerCompany = {
    profile: CompanyProfile;
    statistics: {
        partnerCompanyId: number;
        lastAcceptedAgreement: { id: number, entryTm: string } | undefined;
        lastAgreedAgreement: { id: number, entryTm: string } | undefined;
        acceptedCount: number;
        agreedCount: number;
    } | undefined;
};

export class SettlementPartnerCompanyModel {
    profile: CompanyProfile;
    statistics: SettlementPartnerCompanyStatisticsModel | undefined;

    constructor(param: SettlementPartnerCompany) {
        this.profile = new CompanyProfile(param.profile);
        this.statistics = param.statistics ? new SettlementPartnerCompanyStatisticsModel(param.statistics) : undefined;
    }

    get id(): number {
        return this.profile.id;
    }

    /**
     * 最新の成約を取得する。
     */
    get lastAgreement(): SettlementPartnerCompanyStatisticsLastAgreement | undefined {
        if (this.statistics === undefined) {
            return undefined;
        }
        const accepted = this.statistics.lastAcceptedAgreement;
        const agreed = this.statistics.lastAgreedAgreement;

        return SettlementPartnerCompanyStatisticsLastAgreement.latest(_.compact([accepted, agreed]));
    }
}

export class SettlementPartnerCompanyStatisticsModel {
    partnerCompanyId: number;
    lastAcceptedAgreement: SettlementPartnerCompanyStatisticsLastAgreement | undefined;
    lastAgreedAgreement: SettlementPartnerCompanyStatisticsLastAgreement | undefined;
    acceptedCount: number;
    agreedCount: number;

    constructor(param: NonNullable<SettlementPartnerCompany['statistics']>) {
        this.partnerCompanyId = param.partnerCompanyId;

        this.lastAcceptedAgreement = _.isNil(param.lastAcceptedAgreement) ? undefined : new SettlementPartnerCompanyStatisticsLastAgreement({
            ...param.lastAcceptedAgreement,
            myBaggage: false,
        });

        this.lastAgreedAgreement = _.isNil(param.lastAgreedAgreement) ? undefined : new SettlementPartnerCompanyStatisticsLastAgreement({
            ...param.lastAgreedAgreement,
            myBaggage: true,
        });

        this.acceptedCount = param.acceptedCount;
        this.agreedCount = param.agreedCount;
    }
}

export class SettlementPartnerCompanyStatisticsLastAgreement {
    id: number;
    entryTm: DateTimeValue;
    myBaggage: boolean;

    constructor(param: { id: number, entryTm: string, myBaggage: boolean }) {
        this.id = param.id;
        this.entryTm = new DateTimeValue(param.entryTm);
        this.myBaggage = param.myBaggage;
    }

    static latest(agreements: SettlementPartnerCompanyStatisticsLastAgreement[]): SettlementPartnerCompanyStatisticsLastAgreement | undefined {
        return _.maxBy(agreements, each => each.entryTm.unix);
    }
}

export interface SettlementIncomeRegisterModel {
    payerCompany?: CompanyProfile;
    departureDate?: DateValue;
    arrivalDate?: DateValue;
    departurePref: PrefectureEnumCode | undefined,
    departureCity: string | undefined,
    departureAddress: string | undefined,
    arrivalPref: PrefectureEnumCode | undefined,
    arrivalCity: string | undefined,
    arrivalAddress: string | undefined,
    truckType: TruckType;
    paymentDate?: DateValue;
    isCanceling: boolean;
    freight: number;
    highwayFare: number;
    waitTimeFee: number;
    operationFee: number;
    pickingFee: number;
    parkingFee: number;
    clearanceFee: number;
    cancellationFee: number;
}

export type SettlementIncomeRegisterApiForm = {
    payerCompanyId: number;
    payerDate: string;
    departureDate: string;
    departurePref: Enum<PrefectureEnumCode>;
    departureCity: string;
    departureAddress: string | undefined;
    arrivalDate: string;
    arrivalPref: Enum<PrefectureEnumCode>;
    arrivalCity: string;
    arrivalAddress: string | undefined;
    truckWeight: Enum<TruckWeightEnumCode>;
    truckModel: Enum<TruckModelEnumCode>;
    freight: number;
    highwayFare: number;
    waitTimeFee: number;
    operationFee: number;
    pickingFee: number;
    parkingFee: number;
    clearanceFee: number;
    cancellationFee: number;
};

export type SettlementChangeType = 'APPEND' | 'CHANGE' | 'INCLUDE' | 'EXCLUDE' | 'REVOKE';
export type SettlementChangeRequestForm = {
    freight: number;
    highwayFareFlg: boolean;
    highwayFare: number;
    waitTimeFee: number;
    operationFee: number;
    pickingFee: number;
    parkingFee: number;
    clearanceFee: number;
    cancellationFee: number;
};

export class SettlementUpdateFormModel implements FormValidatable<SettlementUpdateFormModel> {
    _isCanceling: boolean = false;
    freight: number = 0;
    highwayFare: number = 0;
    waitTimeFee: number = 0;
    operationFee: number = 0;
    pickingFee: number = 0;
    parkingFee: number = 0;
    clearanceFee: number = 0;
    cancellationFee: number = 0;
    proxy: boolean = true;

    get isCanceling(): boolean {
        return this._isCanceling;
    }

    set isCanceling(value: boolean) {
        this._isCanceling = value;
        if (value) {
            this.freight = 0;
            this.highwayFare = 0;
            this.waitTimeFee = 0;
            this.operationFee = 0;
            this.pickingFee = 0;
            this.parkingFee = 0;
            this.clearanceFee = 0;
        } else {
            this.cancellationFee = 0;
        }
    }

    availableFeeRange = availableSettlementFeeRange;

    validator(): FormValidator<SettlementUpdateFormModel> {
        return {
            freight: [{
                required: !this.isCanceling,
                validator: (_rule, _value, callback) => {
                    if (!this.isCanceling && this.freight === 0) {
                        callback('運賃を入力してください');
                    }
                    if (this.freight > Const.MAX_FREIGHT) {
                        callback(`運賃は${ Const.MAX_FREIGHT / 100000000 }億円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            highwayFare: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.highwayFare > Const.MAX_HIGHWAY_FARE) {
                        callback(`高速代は${ Const.MAX_HIGHWAY_FARE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            waitTimeFee: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.waitTimeFee > Const.MAX_WAIT_TIME_FEE) {
                        callback(`待機料は${ Const.MAX_WAIT_TIME_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            operationFee: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.operationFee > Const.MAX_OPERATION_FEE) {
                        callback(`付帯作業料は${ Const.MAX_OPERATION_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            pickingFee: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.pickingFee > Const.MAX_PICKING_FEE) {
                        callback(`搬出料は${ Const.MAX_PICKING_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            parkingFee: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.parkingFee > Const.MAX_PARKING_FEE) {
                        callback(`駐車代は${ Const.MAX_PARKING_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            clearanceFee: [{
                required: false,
                validator: (_rule, _value, callback) => {
                    if (this.clearanceFee > Const.MAX_CLEARANCE_FEE) {
                        callback(`通関料は${ Const.MAX_CLEARANCE_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
            cancellationFee: [{
                required: this.isCanceling,
                validator: (_rule, _value, callback) => {
                    if (this.isCanceling && this.cancellationFee === 0) {
                        callback('キャンセル料を入力してください');
                    }
                    if (this.cancellationFee > Const.MAX_CANCELLATION_FEE) {
                        callback(`キャンセル料は${ Const.MAX_CANCELLATION_FEE / 10000 }万円以内で入力してください。`);
                    }
                    callback();
                },
            }],
        };
    }

    toApiForm(): SettlementChangeRequestForm {
        return {
            freight: this.freight,
            highwayFareFlg: true,
            highwayFare: this.highwayFare,
            waitTimeFee: this.waitTimeFee,
            operationFee: this.operationFee,
            pickingFee: this.pickingFee,
            parkingFee: this.parkingFee,
            clearanceFee: this.clearanceFee,
            cancellationFee: this.cancellationFee,
        };
    }
}
