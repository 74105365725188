import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageHighwayFareView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get highwayFare(): string {
        if (!this.baggage) return '';

        return this.baggage.highwayFareFlg ? 'あり' : 'なし';
    }
}
