<script setup lang="ts">
//@ts-ignore
import jaLocale from 'ant-design-vue/es/locale/ja_JP';
import AppMainLayout from '@/_components/root/AppMainLayout.vue';
// import AppMainLayout from '@/components/Root/AppMainLayout/index.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from '@/composables/helper/route';
import { useEnvironment } from '@/composables/global/environment';
import { useEventListener } from '@vueuse/core';
import { useBaggageSearchExcludeCompany } from '@/composables/global/baggage-search-exlucde-company';

const { routeLayoutName, isContentOnlyLayout } = useRoute();
const locale = ref(jaLocale);

// モバイル系のviewport unitに対応するための設定
// https://stackoverflow.com/questions/23223157/how-to-fix-vhviewport-unit-css-in-mobile-safari
const updateViewport = (): void => {
    const vh = window.innerHeight / 100;
    const root = document.documentElement;
    root.style.setProperty('--vh', `${vh}px`);
};

const { state: { loadingEnvironmentState, }, loadEnvironment } = useEnvironment();

// 荷物検索ローカルストレージの
useBaggageSearchExcludeCompany();

onMounted(async () => {
    updateViewport();
    await loadEnvironment();
});

const cleanup = useEventListener('resize', (event)=> {
    updateViewport();
});

onUnmounted(() => {
    cleanup();
});

</script>

<template>
    <a-config-provider :auto-insert-space-in-button="false" :locale="locale">
        <section>
            <div class="loading" v-if="loadingEnvironmentState === 'loading'">
                <a-spin/>
            </div>
            <template v-else-if="loadingEnvironmentState === 'loaded'">
                <app-main-layout v-if="routeLayoutName === 'main'" :content-only="isContentOnlyLayout"/>
                <div v-else>
                    <router-view/>
                </div>
            </template>
            <div class="failed-loading" v-else-if="loadingEnvironmentState === 'failed'">
                <p>初期化に失敗しました。時間をおいて再度お試しください。</p>
            </div>
        </section>
    </a-config-provider>
</template>

<style scoped lang="less">
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.failed-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
</style>
