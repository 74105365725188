import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { Util } from '@/util';

@Component
export default class StaffNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    get staffName(): string | undefined {
        return this.value?.staffName;
    }

    set staffName(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.staffName = Util.emptyStringToUndefined(newValue ?? '');
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            max: 200,
            message: '200文字以内で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.staffName = this.staffName?.trim();
    }
}
