import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage } from '@/vuex/modules/baggage/types';
import _ from 'lodash';

@Component
export default class BaggageUnderNegotiationRegister extends Vue {
    @Prop()
    declare readonly value?: Baggage;

    get underNegotiation(): boolean {
        return this.value?.underNegotiation ?? false;
    }

    set underNegotiation(checked: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.underNegotiation = checked;

        this.emitValue(cloned);
    }

    private emitValue(value: Baggage) {
        this.$emit('input', value);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
