import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import {
    copy,
    goToRegisterBaggage,
    goToRoot,
    lazy,
    scrollToTopOfResultList
} from '@/pages/Baggage/List/tabs/common-helpers';
import {
    clearAgreement,
    clearAgreementChangeRequest,
    clearCompanyProfile,
    extractAgreementId,
    goToAgreementDetail,
    loadAgreement,
    loadAgreementAgreedList,
    loadAgreementChangeRequest,
    loadAgreementViewCountList,
    loadCompanyProfile,
    loadCompanyProfileList,
    loadGuaranteedAmount,
    loadGuaranteePriceMaster,
    loadPastContracts,
    searchAgreementAgreed
} from '@/pages/Baggage/List/tabs/agreement-helpers';
import * as companyTypes from '@/vuex/modules/company/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as deliveryOrderTypes from '@/vuex/modules/deliveryOrder/types';
import { myBaggageSearchFormVisibility, pagination } from '@/repository/storage/web-storage';
// @ts-ignore
import AgreementSearchCondition from '@/components/Agreement/Search/Condition';
// @ts-ignore
import AgreementList from '@/components/Agreement/View/List';
// @ts-ignore
import AgreementDetailDrawerPage from '@/pages/Baggage/List/drawers/AgreementDetailDrawerContent';
import { Const } from '@/const';
import { loadDeliveryOrderList } from '@/pages/Baggage/List/tabs/delivery-order-helper';
import { Util } from '@/util';

const companyMod = namespace('company');
const agreementMod = namespace('agreement');
const deliveryOrderMod = namespace('deliveryOrder');

@Component({
    title: '自社荷物の成約',
    components: {
        AgreementList,
        AgreementSearchCondition,
        AgreementDetailDrawerPage,
    },
    beforeRouteEnter: AgreementAgreedListPage.beforeRouteEnter,
})
export default class AgreementAgreedListPage extends Vue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT)
    readonly AGREEMENT?: agreementTypes.Agreement;
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT_LIST)
    readonly AGREEMENT_LIST?: agreementTypes.AgreementList;
    @companyMod.Getter(companyTypes.GETTER.PROFILE_LIST)
    readonly PROFILE_LIST?: companyTypes.CompanyProfile[];
    @deliveryOrderMod.Getter(deliveryOrderTypes.GETTER.DELIVERY_ORDER_LIST)
    readonly DELIVERY_ORDER_LIST?: deliveryOrderTypes.DeliveryOrderList;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreementId?: string;

    // noinspection JSUnusedGlobalSymbols
    /**
     * 成約ドロワーの表示状態を取得します。
     */
    get agreementDrawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return !_.isNaN(Number(this.agreementId));
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * editクエリパラメーターを持っているか（編集中ページにアクセス中か否か）を取得します。
     */
    get hasEditQueryParam(): boolean {
        return _.has(this.$route.query, 'edit');
    }

    /**
     * ページサイズ
     */
    get pageSize(): number {
        return Number(pagination.getPageSize() ?? '20');
    }

    set pageSize(newValue: number) {
        this.value.pageSize = newValue;
        pagination.setPageSize(newValue.toString());
    }

    /**
     * 絞り込み中か否かを取得します。
     */
    get isFiltered(): boolean {
        return !AgreementAgreedListPage.isEmptyForm(this.value);
    }

    // ======================================================
    // Data
    // ======================================================
    loading = true;
    routeUpdating = false;
    searchFormVisible = false;
    initialFormModel: Partial<agreementTypes.AgreementSearchAgreedForm> = {
        arrivalPref: [],
        departurePref: [],
        departureFrom: undefined,
        departureTo: undefined,
        truckModel: [],
        truckWeight: [],
        staffName: '',
        partnerCompanyName: '',
        addressText: '',
        baggageId: undefined,
        agreementId: undefined,
    };
    formModel = _.cloneDeep(this.initialFormModel);
    initialValue: agreementTypes.AgreementSearchAgreedForm = {
        arrivalPref: [],
        departurePref: [],
        departureFrom: undefined,
        departureTo: undefined,
        truckModel: [],
        truckWeight: [],
        staffName: '',
        partnerCompanyName: '',
        addressText: '',
        baggageId: undefined,
        agreementId: undefined,
        pageNo: 1,
        pageSize: 20,
        sortKey: 'ID',
        sortOrder: 'DESC'
    };
    value = _.cloneDeep(this.initialValue);

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.value.pageSize = this.pageSize;
        this.searchFormVisible = myBaggageSearchFormVisibility.get('AGREED');
    }

    /**
     * 検索フォームを開閉する。
     */
    toggleSearchFormVisibility(): void {
        this.searchFormVisible = !this.searchFormVisible;
        myBaggageSearchFormVisibility.set('AGREED', this.searchFormVisible);
    }

    /**
     * 検索ボタンが押下された際に呼び出されます。
     */
    async onClickSearch(): Promise<void> {
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // フォーム内容を検索条件へ反映
        this.commitForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        // フォームをクリアするが検索条件には反映しない
        this.formModel = _.cloneDeep(this.initialFormModel);
    }

    /**
     * ソート順が変更された際に呼び出されます。
     */
    async onChangeSort(param: { sortKey: string; sortAscending: boolean }): Promise<void> {
        // ソート順定義を構築
        const definition = new Map(Const.agreementSortKey.map(each => [each.code, each.defaultOrder]))
            .set(this.value.sortKey, param.sortAscending ? 'ASC' : 'DESC');
        // ソートキー
        this.value.sortKey = param.sortKey;
        // ソート順
        this.value.sortOrder = definition.get(param.sortKey) ?? this.value.sortOrder;
        // ページ番号(1にリセット)
        this.value.pageNo = 1;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);
    }

    /**
     * ページングが変更された際に呼び出されます。
     */
    async onChangePage(param: { pageNo: number; pageSize: number }): Promise<void> {
        // ページ番号
        this.value.pageNo = param.pageNo;
        // ページサイズ
        this.pageSize = param.pageSize;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);

        scrollToTopOfResultList();
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 成約一覧の行が押下された際に呼び出されます。
     */
    async onClickAgreementRow(agreementId: number): Promise<void> {
        if (extractAgreementId(this.$route) === agreementId) {
            return;
        }
        await goToAgreementDetail(this.$route, agreementId);
    }

    /**
     * 荷物をコピーするボタンが押下された際に呼び出されます。
     */
    onClickCopyBaggage(baggageId: number): Promise<Route> {
        return goToRegisterBaggage(baggageId);
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        await goToRoot(this.$route);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 詳細ページから更新処理があった際に呼び出されます。
     */
    async onUpdateList(): Promise<void> {
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.loadDataList().catch(this.notifyFailedToLoadData);
    }

    /**
     * ルーティングによってこのページに到達した際に呼び出されます。
     * @param to
     * @param _from
     * @param next
     */
    static async beforeRouteEnter(to: Route, _from: Route, next: NavigationGuardNext<AgreementAgreedListPage>): Promise<void> {
        next(async page => {
            await page.loadDataList().catch(page.notifyFailedToLoadData);

            const agreementId = extractAgreementId(to);

            // 一覧表示なら終了
            if (!agreementId) return;

            await lazy(() => page.loadDataDetail(agreementId).catch(page.notifyFailedToLoadDetailData));
        });
    }

    /**
     * ルーティング情報が変化した際に呼び出されます。
     * @param to
     * @param _from
     * @param next
     */
    async beforeRouteUpdate(to: Route, _from: Route, next: NavigationGuardNext<AgreementAgreedListPage>): Promise<void> {
        if (this.routeUpdating) return next(false);

        next();

        const agreementId = extractAgreementId(to);

        // 編集画面への遷移などであれば終了
        if (Number(this.agreementId) === agreementId) return;

        // 以降は、閲覧中の成約詳細が変化するパターン

        await this.clearDataDetail();

        if (!agreementId) return;

        this.routeUpdating = true;

        await lazy(() => this.loadDataDetail(agreementId).catch(this.notifyFailedToLoadData));

        this.routeUpdating = false;
    }

    /**
     * データロード失敗を通知します。
     */
    private notifyFailedToLoadData(): void {
        this.$message.error(`成約一覧を読み込みできませんでした。時間をおいて再度お試しください。`);
    }

    /**
     * 詳細データロード失敗を通知します。
     */
    private notifyFailedToLoadDetailData(): void {
        this.$message.error(`成約番号 ${ this.agreementId } の成約情報を読み込みできませんでした。`);
    }

    /**
     * データをロードします。
     */
    private async loadDataList(): Promise<void> {
        this.loading = true;

        // 成約一覧をロード
        if (AgreementAgreedListPage.isEmptyForm(this.value)) {
            await loadAgreementAgreedList(this.value);
        } else {
            await searchAgreementAgreed(this.value);
        }

        // 依頼書一覧をロード
        const agreementIds = Util.requireNotNull(this.AGREEMENT_LIST).data.map(agreement => agreement.id);
        await loadDeliveryOrderList(agreementIds);

        // 企業プロフィールをロード
        await loadCompanyProfileList(this.AGREEMENT_LIST?.data ?? []);

        // 荷物閲覧会員数をロード
        await loadAgreementViewCountList(this.AGREEMENT_LIST?.data ?? []);

        // 運賃全額保証マスタデータロード
        await loadGuaranteePriceMaster().catch(() => ({ /* ignore */ }));

        // 運賃全額保証金額ロード
        await loadGuaranteedAmount().catch(() => ({ /* ignore */ }));

        this.loading = false;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * 詳細データをロードします。
     */
    private async loadDataDetail(id: number): Promise<void> {
        // 成約情報を読み込み
        const agreement = await loadAgreement(id);

        // 成約当時の契約情報をロード
        await loadPastContracts(agreement);

        // 成約変更申請情報を読み込み
        await loadAgreementChangeRequest(id);

        // パートナー企業を読み込み
        await loadCompanyProfile(agreement);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * 詳細データをクリアします。
     */
    private async clearDataDetail(): Promise<void> {
        await clearAgreement();
        await clearAgreementChangeRequest();
        await clearCompanyProfile();
    }

    /**
     * 検索条件フォーム内容を検索条件として確定させます。
     */
    private commitForm(): void {
        copy(this.value, this.formModel);
    }

    /**
     * 確定済みの検索条件を検索フォームへ戻します。
     */
    private revertForm(): void {
        copy(this.formModel, this.value, _.keys(this.formModel));
    }

    private static isEmptyForm(form: agreementTypes.AgreementSearchAgreedForm) {
        return (
            _.isNil(form.baggageId) &&
            _.isNil(form.agreementId) &&
            _.isEmpty(form.departurePref) &&
            _.isEmpty(form.arrivalPref) &&
            _.isEmpty(form.departureFrom) &&
            _.isEmpty(form.departureTo) &&
            _.isEmpty(form.truckWeight) &&
            _.isEmpty(form.truckModel) &&
            _.isEmpty(form.staffName) &&
            _.isEmpty(form.partnerCompanyName) &&
            _.isEmpty(form.addressText)
        );
    }
}
