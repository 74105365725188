import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import { ReportLatePaymentForm } from '@/vuex/modules/help/types';

@Component
export default class ReportLatePaymentDeliveryDateEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: ReportLatePaymentForm;
    viewDateFormat = Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT;

    /**
     * 入金予定日
     */
    get deliveryDate(): string {
        return this.value?.deliveryDate ?? '';
    }

    set deliveryDate(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運行日付を書き換え
        cloned.deliveryDate = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
