import { Component, Prop } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';

// @ts-ignore
import BaggageSearchDetailDrawer from '@/components/Baggage/Search/DetailDrawer';
// @ts-ignore
import UiIconBaggage from '@/components/UI/Icons/baggage';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
// @ts-ignore
import BaggageMap from '@/components/Baggage/Search/Map';
import { NavigationGuardNext, Route, Route as VueRoute } from 'vue-router/types/router';
import { Util } from '@/util';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { BaggageDetailContainer } from '@/models/baggage-detail-container';
import { namespace } from 'vuex-class';
import * as companyTypes from '@/vuex/modules/company/types';
import _ from 'lodash';
import * as accountTypes from '@/vuex/modules/account/types';
import {
    clearDetailBaggage,
    loadDetailBaggage,
    onClickCloseBaggageDetailDrawer,
    markFavorite,
    unmarkFavorite,
    onClickAgree,
    markUnderNegotiation,
    unmarkUnderNegotiation,
    onClickPrintBaggage,
    onClickCompanyName,
    setPageTitle,
    onClickGround
} from '@/pages/Baggage/Search/helpers';
import store from '@/vuex/store';
import { BaggageHandlingTypeEnum } from '@/enums/baggage-handling-type.enum';
import { loadBaggageRecommendations, markRead } from '@/pages/Baggage/Search/Recommend/Returns/helpers';
import { BaggageRecommendReturn } from '@/vuex/modules/baggage/types';

const baggageMod = namespace('baggage');
const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: 'おすすめ帰り便 (ベータ版)',
    components: {
        BaggageSearchDetailDrawer,
        UiIconBaggage,
        UiDateTimeLocationLabel,
        BaggageMap
    },
    beforeRouteEnter: BaggageRecommendReturnsPage.beforeRouteEnter,
})
export default class BaggageRecommendReturnsPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_RECOMMENDATIONS)
    readonly BAGGAGE_RECOMMENDATIONS?: baggageTypes.BaggageRecommendReturnResult;
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.CONFIDENCE)
    readonly COMPANY_CONFIDENCE?: companyTypes.CompanyConfidence;
    @companyMod.Getter(companyTypes.GETTER.STATISTICS)
    readonly COMPANY_STATISTICS?: companyTypes.CompanyStatistics;
    // 荷物詳細コンテナ
    @baggageMod.Getter(baggageTypes.GETTER.SEARCH_BAGGAGE_DETAIL_CONTAINER)
    readonly DETAIL_CONTAINER?: BaggageDetailContainer;
    // アカウント情報
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly MY_PROFILE?: accountTypes.Profile;

    // ======================================================
    // Data
    // ======================================================
    routeUpdating = false;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ required: true, type: Number })
    declare readonly baggageId?: number;
    @Prop()
    declare readonly recommendationId?: number;

    /**
     * 帰り便のおすすめ
     */
    get recommendations(): Array<BaggageRecommendReturn & { company?: companyTypes.CompanyProfile }> | undefined {
        /* 企業を探す関数 */
        const findCompany = (id: number) => this.BAGGAGE_RECOMMENDATIONS?.companyProfiles?.find((each) => each.id === id);
        /* 企業プロフィールをマージする関数 */
        const mergeCompany = (recommendation: BaggageRecommendReturn) => {
            return _.merge({}, recommendation, { company: findCompany(recommendation.baggage.companyId) });
        };

        return this.BAGGAGE_RECOMMENDATIONS?.recommendations
            ?.map(mergeCompany);
    }

    /**
     * 選択されたおすすめ帰り便のCSS
     * @param baggage
     */
    matchClass(baggage?: baggageTypes.Baggage): string {
        return baggage?.id == this.recommendationId ? 'match-selected' : 'match';
    }

    /**
     * 運賃
     */
    freightText(baggage?: baggageTypes.Baggage): string {
        if (!baggage?.freight) return '要相談';
        return `${ Util.formatNumber(baggage?.freight) }円`;
    }

    /**
     * 合計運賃
     */
    totalFreightText(recommendation: baggageTypes.Baggage): string {
        if (!this.BAGGAGE?.freight || !recommendation.freight) return '-';
        return `${ Util.formatNumber(this.BAGGAGE?.freight + recommendation.freight) }円`;
    }

    /**
     * ドライバー作業
     */
    handling(baggage?: baggageTypes.Baggage): string {
        const loading = BaggageHandlingTypeEnum.valueOf(baggage?.loading?.code);
        const unloading = BaggageHandlingTypeEnum.valueOf(baggage?.unloading?.code);
        const loadingText = loading?.getLabel(true) ?? '-';
        const unloadingText = unloading?.getLabel(false) ?? '-';
        return `積み：${ loadingText } / 卸し：${ unloadingText }`;
    }

    /**
     * 車両テキスト
     * @param baggage
     */
    truckWeightText(baggage?: baggageTypes.Baggage): string {
        return '重量：' + baggage?.truckWeight.label ?? '-';
    }

    /**
     * 車種テキスト
     * @param baggage
     */
    truckModelText(baggage?: baggageTypes.Baggage): string {
        return '車種：' + baggage?.truckModel.label ?? '-';
    }

    /**
     * 車両・車種テキスト
     * @param baggage
     */
    truckWeightModelText(baggage?: baggageTypes.Baggage): string {
        return this.truckWeightText(baggage) + ' / ' + this.truckModelText(baggage);
    }

    /**
     * 荷種
     * @param baggage
     */
    baggageType(baggage?: baggageTypes.Baggage): string {
        return baggage?.type ?? '';
    }

    /**
     * 自社企業IDを取得します。
     */
    get myCompanyId(): number {
        return this.MY_PROFILE?.companyId ?? 0;
    }

    /**
     * 荷物ドロワーの表示状態を取得します。
     */
    get drawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return !_.isNaN(Number(this.recommendationId));
    }

    // 荷物詳細ページ用のプロパティ
    get detailBaggage(): baggageTypes.Baggage | undefined {
        return this.DETAIL_CONTAINER?.baggage;
    }

    get detailCompanyProfile(): companyTypes.CompanyProfile | undefined {
        return this.DETAIL_CONTAINER?.companyProfile;
    }

    get detailOfficialCompany(): companyTypes.OfficialCompany | undefined {
        return this.DETAIL_CONTAINER?.officialCompany;
    }

    get detailCompanyConfidence(): companyTypes.CompanyConfidence | undefined {
        return this.DETAIL_CONTAINER?.confidence;
    }

    get detailCompanyStatistics(): companyTypes.CompanyStatistics | undefined {
        return this.DETAIL_CONTAINER?.statistics;
    }

    get detailMarkedFavorite(): boolean {
        return this.DETAIL_CONTAINER?.markedFavorite ?? false;
    }

    get detailReferenceFreight(): baggageTypes.BaggageFreightMaster | undefined {
        return this.DETAIL_CONTAINER?.referenceFreight;
    }

    // ======================================================
    // Functions
    // ======================================================
    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageRecommendReturnsPage>
    ): Promise<void> {
        const { baggageId } = to.params;
        const onError = () => next({ name: 'NotFound' });
        if (!baggageId) {
            onError();
        }
        await Promise.all([
                BaggageRecommendReturnsPage.loadBaggage(Number(baggageId)),
                markRead(Number(baggageId))
            ])
            .then((result) => {
                const baggage = result[0];
                return Promise.all([
                    BaggageRecommendReturnsPage.loadCompanyProfile(baggage.companyId),
                    BaggageRecommendReturnsPage.loadCompanyConfidence(baggage.companyId),
                    BaggageRecommendReturnsPage.loadCompanyStatistics(baggage.companyId),
                    loadBaggageRecommendations(baggage.id)
                ]);
            })
            .then(() => next(async () => {
                // ドロワー表示データ処理
                //
                const { baggageId } = to.query;
                if (baggageId) {
                    await Promise.all([
                            loadDetailBaggage(Number(baggageId), undefined),
                            markRead(Number(baggageId))
                        ])
                        .catch(onError);
                }
            }))
            .catch(onError);
    }

    async beforeRouteUpdate(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageRecommendReturnsPage>
    ): Promise<void> {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            next(false);
            return;
        }
        //
        // ドロワー表示データ処理
        //
        const { baggageId, ref } = to.query;
        // 荷物詳細→荷物一覧に戻ってきたとき
        if (!baggageId) {
            // ページタイトルを設定
            setPageTitle(to);
            // 荷物詳細をクリア
            await clearDetailBaggage();
            next();
            return;
        }
        // 荷物詳細を開いた状態で別の荷物詳細へ遷移しようとしたとき
        if (this.drawerVisibility && _from.query.baggageId !== baggageId) {
            this.routeUpdating = true;
            await clearDetailBaggage();
        }

        next();

        // 少しだけドロワーの開閉アニメーションを見せるためにdelay
        _.delay(() => (this.routeUpdating = false), 100);

        await Promise.all([
                loadDetailBaggage(Number(baggageId), Util.castAsString(ref)),
                markRead(Number(baggageId))
            ])
            .then(() => {
                // ページタイトルを設定
                setPageTitle(to);
            })
            .catch(() => {
                this.$message.error('アクセスしようとした荷物情報は見つかりません。');
            });
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<BaggageRecommendReturnsPage>
    ): Promise<void> {
        await Promise.all([
            clearDetailBaggage()
        ]);
        next();
    }

    /**
     * おすすめ帰り便がクリックされる際に呼び出されます。
     */
    private async onClickMatch(event: Event, baggage: baggageTypes.Baggage): Promise<void> {
        event.stopPropagation();
        const currentBaggageId = this.$route.query.baggageId;
        if (currentBaggageId !== `${ baggage.id }`) {
            await this.$router.push({ path: this.$route.path, query: { baggageId: baggage.id.toString() } });
        }
    }

    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    private onClickCloseBaggageDetailDrawer(): Promise<Route> {
        return onClickCloseBaggageDetailDrawer(this.$route);
    }

    /**
     * 荷物をお気に入り保存する。
     * @param baggageId
     */
    async markFavorite(baggageId: number): Promise<void> {
        await markFavorite(baggageId, this.recommendationId);
    }

    /**
     * 荷物をお気に入り解除する。
     * @param baggageId
     */
    async unmarkFavorite(baggageId: number): Promise<void> {
        await unmarkFavorite(baggageId, this.recommendationId);
    }

    /**
     * 成約が押下された際に呼び出されます。
     */
    async onClickAgree(baggageId: number): Promise<void> {
        await onClickAgree(baggageId);
    }

    /**
     * 「商談中にする」ボタンが押下された際に呼び出されます。
     */
    async markUnderNegotiation(baggageId: number): Promise<void> {
        await markUnderNegotiation(baggageId);
    }

    /**
     * 「商談中を解除」ボタンが押下された際に呼び出されます。
     */
    async unmarkUnderNegotiation(baggageId: number): Promise<void> {
        await unmarkUnderNegotiation(baggageId);
    }

    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        await onClickPrintBaggage(baggageId);
    }

    /**
     * 企業名が押下された際に呼び出されます。
     */
    async onClickCompanyName(companyId: string): Promise<void> {
        await onClickCompanyName(companyId);
    }

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        await onClickGround(this.routeUpdating, this.drawerVisibility, this.$route);
    }

    /**
     * 荷物をロードします。
     */
    private static loadBaggage(id: number): Promise<baggageTypes.Baggage> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE }`, id);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadCompanyProfile(id: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_PROFILE }`, id);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadCompanyConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_CONFIDENCE }`, companyId);
    }

    /**
     * 企業統計情報をロードします。
     */
    private static loadCompanyStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_STATISTICS }`, companyId);
    }
}
