import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomRow } from '@/types/ant-design';
import { AgreementUtil, DateUtil, Util } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import { Const } from '@/const';

const baggageMod = namespace('baggage');
// @ts-ignore
import UiAgreementTypeTag from '@/components/UI/AgreementTypeTag';
// @ts-ignore
import UiSortPaginationControl from '@/components/UI/SortPaginationControl';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as deliveryOrderTypes from '@/vuex/modules/deliveryOrder/types';
import { namespace } from 'vuex-class';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

type AgreementWithTruckCompany = agreementTypes.Agreement & { truckCompany: companyTypes.CompanyProfile | undefined };

@Component({
    components: {
        UiSortPaginationControl,
        UiAgreementTypeTag,
    },
})
export default class AgreementListView extends Vue {
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_VIEW_MEMBER_COUNT_LIST)
    readonly BAGGAGE_VIEW_MEMBER_COUNT?: Array<baggageTypes.BaggageViewMemberCount>;
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: agreementTypes.AgreementList;
    @Prop()
    declare readonly companyList?: companyTypes.CompanyProfile[];
    @Prop()
    declare readonly deliveryOrderList?: deliveryOrderTypes.DeliveryOrderModel[];
    @Prop()
    declare readonly sortKey?: string;
    @Prop()
    declare readonly sortAscending?: boolean;
    @Prop({ default: false })
    declare readonly loading: boolean;

    get list(): Array<AgreementWithTruckCompany> {
        /* 企業を探す関数 */
        const findCompany = (id: number) => this.companyList?.find((each) => each.id === id);

        /* 配送依頼書を探す関数 */
        const findDeliveryOrder = (agreementId: number) => {
            if (this.deliveryOrderList && this.deliveryOrderList.length > 0) {
                return this.deliveryOrderList.find((each) => each.agreementId === agreementId);
            }
            return undefined;
        };

        /* 成約と企業プロフィールをマージする関数 */
        const mergeOthers = (agreement: agreementTypes.Agreement) => {
            const company = findCompany(agreement.truckCompanyId);
            const deliveryOrder = findDeliveryOrder(agreement.id);
            return _.merge(
                {},
                agreement,
                {
                    truckCompany: company,
                    deliveryOrderStatus: deliveryOrder ? deliveryOrder.status.forShipper : ''
                }
            );
        };

        return this.agreement?.data?.map(mergeOthers) ?? [];
    }

    // ======================================================
    // Data
    // ======================================================
    sortOptions = Const.agreementSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    rowClassName = (record: agreementTypes.Agreement): string => {
        const styleClasses = ['app-table-row', 'app-table-row--clickable'];
        const { agreementId } = this.$route.query;
        if (`${ record.id }` === agreementId) {
            styleClasses.push('app-table-row--selected');
        }
        return styleClasses.join(' ');
    };
    columns = [
        {
            title: '成約番号',
            scopedSlots: { customRender: 'id' },
            width: 128,
        },
        {
            title: '状態',
            key: 'status',
            dataIndex: 'status.label',
            scopedSlots: { customRender: 'status' },
            width: 164,
        },
        {
            title: '荷物番号',
            dataIndex: 'baggageId',
            width: 120,
        },
        {
            title: '運送会社',
            key: 'truckCompany',
            scopedSlots: { customRender: 'truckCompany' },
            width: 200,
        },
        {
            title: '依頼書',
            key: 'deliveryOrderStatus',
            scopedSlots: { customRender: 'deliveryOrderStatus' },
            width: 200,
        },
        {
            title: '発日時・発地',
            key: 'departure',
            scopedSlots: { customRender: 'departure' },
            width: 170,
        },
        {
            title: '着日時・着地',
            key: 'arrival',
            scopedSlots: { customRender: 'arrival' },
            width: 170,
        },
        {
            title: '運賃',
            key: 'freight',
            dataIndex: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 128,
        },
        {
            title: '高速代',
            key: 'highwayFare',
            dataIndex: 'highwayFare',
            scopedSlots: { customRender: 'highwayFare' },
            width: 128,
        },
        {
            title: '重量',
            key: 'truckWeight',
            dataIndex: 'truckWeight',
            scopedSlots: { customRender: 'truckWeight' },
            width: 64,
        },
        {
            title: '車種',
            key: 'truckModel',
            dataIndex: 'truckModel',
            scopedSlots: { customRender: 'truckModel' },
            width: 108,
        },
        {
            title: '入金予定日',
            key: 'paymentDate',
            scopedSlots: { customRender: 'paymentDate' },
            width: 140,
        },
        {
            title: '閲覧人数',
            key: 'viewersCount',
            scopedSlots: { customRender: 'viewersCount' },
        },
    ];

    /**
     * 行をカスタマイズする。
     */
    customRow(record: agreementTypes.Agreement): CustomRow {
        return {
            on: {
                click: (event: Event) => {
                    // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
                    event.stopPropagation();
                    this.$emit('clickRow', record.id);
                },
            },
        };
    }

    shouldShowStatus(record: agreementTypes.Agreement): boolean {
        if (record.status.code === 'ACTIVE' || record.status.code === 'STABLE') return false;
        // 変更申請、取消申請があったが、変更期限を過ぎている場合
        if (
            (record.status.code === 'CHANGE' || record.status.code === 'REVOKE') &&
            !AgreementUtil.isWithinChangeDeadline(record)
        ) {
            return false;
        }
        return true;
    }

    shouldShowChangeLimitDatetime(record: agreementTypes.Agreement): boolean {
        return (record.status.code === 'CHANGE' || record.status.code === 'REVOKE') && AgreementUtil.isWithinChangeDeadline(record);
    }

    truckCompanyText(record: { truckCompany?: companyTypes.CompanyProfile }): string {
        return record.truckCompany?.name.kanji ?? '';
    }

    departureText(record: agreementTypes.Agreement): string {
        return DeliveryDateTimeRange.of(record.departureMin, record.departureMax)?.format() ?? '';
    }

    arrivalText(record: agreementTypes.Agreement): string {
        return DeliveryDateTimeRange.of(record.arrivalMin, record.arrivalMax)?.format() ?? '';
    }

    freightText(record: agreementTypes.Agreement): string {
        return Util.formatNumber(record.freight);
    }

    highwayFareText(record: agreementTypes.Agreement): string {
        if (!record.highwayFareFlg) return 'なし';
        const highwayFare = record.highwayFare;
        return highwayFare <= 0 ? '金額未定' : `${ Util.formatNumber(highwayFare) }円`;
    }

    truckWeightText(record: agreementTypes.Agreement): string {
        return record.truckWeight.label ?? '';
    }

    truckModelText(record: agreementTypes.Agreement): string {
        return record.truckModel?.label ?? '';
    }

    paymentDateText(record: agreementTypes.Agreement): string {
        return DateUtil.parseDateText(record.paymentDate).format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    /**
     * 荷物詳細の閲覧会員数を取得します。
     */
    viewerCount(record: agreementTypes.Agreement): number {
        return this.BAGGAGE_VIEW_MEMBER_COUNT?.find((memberCount) => memberCount.baggageId == record.baggageId)?.memberCount ?? 0;
    }

    changeLimitDate(record: agreementTypes.Agreement): string {
        return DateUtil.parseDatetimeText(record.changeLimitTm).format(Const.DEFAULT_SHORT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }

    /**
     * 運送企業名をクリックすると呼び出されます。
     */
    async onClickCompanyText(event: Event, record: agreementTypes.Agreement): Promise<void> {
        event.stopPropagation();
        this.$emit('clickRow', record.id, 'company');
    }

    /**
     * ページネーションでソートキーか並び順を変更した時に呼び出されます。
     */
    onChangeSort(sortKey: string, sortAscending: boolean): void {
        this.$emit('onChangeSort', { sortKey, sortAscending });
    }

    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('onChangePage', { pageNo, pageSize });
    }
}
