import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { GETTER as g, GlobalStatistics, MUTATION as m, StatisticsState } from './types';

const state: StatisticsState = {
    globalStatistics: undefined,
};

const actions: ActionTree<StatisticsState, void> = {
};

const getters: GetterTree<StatisticsState, void> = {
    [g.GLOBAL_STATISTICS]: (s) => s.globalStatistics,
};

const mutations: MutationTree<StatisticsState> = {
    [m.SET_GLOBAL_STATISTICS]: (s, statistics: GlobalStatistics) => (s.globalStatistics = statistics),
};

export const statistics: Module<StatisticsState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
