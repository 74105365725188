<script setup lang="ts">
import { CompanyProfile } from '@/models/company';
import { Baggage } from '@/models/baggage';
import { PhoneUtil } from '@/util';
import { computed } from 'vue';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import StaffView from '@/_components/parts/baggage/Staff.vue';
import BaggageTypeView from '@/_components/parts/baggage/BaggageType.vue';
import TruckView from '@/_components/parts/baggage/Truck.vue';
import TruckDetailView from '@/_components/parts/baggage/TruckDetail.vue';
import TruckEquipmentView from '@/_components/parts/baggage/TruckEquipment.vue';
import DescriptionView from '@/_components/parts/baggage/Descriptions.vue';
import DeliveryDateTimeView from '@/_components/parts/baggage/DeliveryDateTime.vue';
import LoadingTimeNoteView from '@/_components/parts/baggage/LoadingTimeNote.vue';
import UnloadingTimeNoteView from '@/_components/parts/baggage/UnloadingTimeNote.vue';
import EntryDateTimeView from '@/_components/parts/baggage/EntryDateTime.vue';
import TraboxBaggageIdView from '@/_components/parts/baggage/TraboxBaggageId.vue';
import BaggageTemperatureZoneView from '@/_components/parts/baggage/BaggageTemperatureZone.vue';
import ShipperNameView from '@/_components/parts/baggage/ShipperName.vue';

const props = withDefaults(defineProps<{
    baggage?: Baggage,
    profile?: CompanyProfile,
    isMyBaggage?: boolean,
}>(), {
    isMyBaggage: false,
});

const emits = defineEmits<{
    (e: 'clickCompanyName', companyId: number): void,
    (e: 'clickShowAchievement'): void,
    (e: 'clickNegotiationRequest'): void,
}>();

const companyName = computed<string | undefined>(() => {
    return props.profile?.name.kanji;
});

const phoneNumber = computed<string>(() => {
    return PhoneUtil.format(props.profile?.phone.number ?? '');
});

const isNegotiationTypeOnline = computed<boolean>(() => {
    return props.baggage?.negotiationType.code === NegotiationTypeEnum.Online.code;
});

const isUnderNegotiation = computed<boolean>(() => {
    return props.baggage?.underNegotiation ?? false;
});

/**
 * 商談リクエストするボタン押下時に呼び出されます。
 */
const onClickNegotiationRequest = (): void => {
    emits('clickNegotiationRequest');
};
</script>

<template>
    <a-descriptions bordered :column="1" size="small">
        <a-descriptions-item label="企業名">
            <span>{{ companyName }}</span><br/>
            <a-space>
            </a-space>
        </a-descriptions-item>
        <a-descriptions-item label="荷主名">
            <shipper-name-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="担当者">
            <staff-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="電話番号" v-if="isNegotiationTypeOnline">
            <div class="negotiation-type">
                <font-awesome-icon :icon="['fas', 'phone-alt']"/>
                <span>{{ phoneNumber }}</span>
            </div>
        </a-descriptions-item>
        <a-descriptions-item :label="isNegotiationTypeOnline ? '対応可否表明' : '連絡方法'">
            <div class="negotiation-type">
                <template v-if="isNegotiationTypeOnline">
                    <template v-if="isMyBaggage">
                        <span>オンラインで受付</span>
                    </template>
                    <template v-else>
                        <a-button type="primary" @click="onClickNegotiationRequest">対応可否</a-button>
                    </template>
                </template>
                <template v-else>
                    <font-awesome-icon :icon="['fas', 'phone-alt']"/>
                    <span>{{ phoneNumber }}</span>
                </template>
                <p v-if="isUnderNegotiation && !isMyBaggage">すでに商談が進行中です<br>商談していないお客様は、電話連絡をお控えください
                </p>
            </div>
        </a-descriptions-item>
        <a-descriptions-item label="荷種">
            <baggage-type-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="温度帯">
            <baggage-temperature-zone-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="希望車種">
            <truck-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="車両指定">
            <truck-detail-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="必要装備">
            <truck-equipment-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="備考">
            <description-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="発着日時">
            <delivery-date-time-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="積み時間">
            <loading-time-note-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="卸し時間">
            <unloading-time-note-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="登録日時">
            <entry-date-time-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
        <a-descriptions-item label="トラボックス荷物ID">
            <trabox-baggage-id-view v-if="baggage" :baggage="baggage"/>
        </a-descriptions-item>
    </a-descriptions>
</template>

<style scoped lang="less">
.staff-name {
    svg {
        margin-right: 4px;
    }

    span + svg {
        margin-left: 16px;
    }

    p {
        margin-bottom: 0;
        color: red;
    }
}

.btn-under-negotiation {
    margin-left: 16px;
}

.negotiation-type {
    svg {
        margin-right: 4px;
    }

    span + svg {
        margin-left: 16px;
    }

    p {
        margin-bottom: 0;
        color: red;
    }

    &__help {
        margin-left: 8px;
        cursor: pointer;
    }
}
</style>
