import { Enum } from '@/types/enum';
import { PaginatedList } from '@/types/paginated-list';
import { Const } from '@/const';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { TruckType } from '@/_components/ui/types/truck-type';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { PhoneUtil } from '@/util';

export interface CompanyTruckSearchForm {
    pageNo: number;
    pageSize: number;
}

export class CompanyTruckSearchFormModel implements CompanyTruckSearchForm {
    pageNo: number;
    pageSize: number;

    constructor(param: Partial<CompanyTruckSearchForm> | null = null) {
        this.pageNo = param?.pageNo ?? 1;
        this.pageSize = param?.pageSize ?? Const.DEFAULT_PAGE_SIZE;
    }
}

export interface CompanyTruckList extends PaginatedList {
    data: CompanyTruck[];
}

export interface CompanyTruck {
    id: number;
    companyId: number;
    carNumber: string;
    truckWeight: Enum;
    truckModel: Enum;
    driverName: string;
    driverPhoneNumber: string;
    description: string;
}

export class CompanyTruckModel {
    id: number;
    companyId: number;
    carNumber: string;
    truckWeight: Enum;
    truckModel: Enum;
    driverName: string;
    driverPhoneNumber: string;
    description: string;

    constructor(param: CompanyTruck) {
        this.id = param.id;
        this.companyId = param.companyId;
        this.carNumber = param.carNumber;
        this.truckWeight = param.truckWeight;
        this.truckModel = param.truckModel;
        this.driverName = param.driverName;
        this.driverPhoneNumber = PhoneUtil.format(param.driverPhoneNumber);
        this.description = param.description;
    }
}

// 車両登録フォーム
export class CompanyTruckRegisterForm {
    carNumber: string;
    truckWeight?: { code?: string };
    truckModel?: { code?: string };
    driverName: string;
    driverPhoneNumber: string;
    description: string;

    constructor(param: Partial<CompanyTruckRegisterForm> | null = null) {
        this.carNumber = param?.carNumber ?? '';
        this.truckWeight = param?.truckWeight ?? {};
        this.truckModel = param?.truckModel ?? {};
        this.driverName = param?.driverName ?? '';
        this.driverPhoneNumber = param?.driverPhoneNumber ?? '';
        this.description = param?.description ?? '';
    }
}

// 車両登録フォームモデル
export class CompanyTruckRegisterFormValidatableModel
    extends CompanyTruckRegisterForm
    implements FormValidatable<CompanyTruckRegisterFormValidatableModel>{

    constructor(param: Partial<CompanyTruckRegisterForm> | null = null) {
        super(param);
    }

    get truckType(): TruckType {
        return {
            truckModel: this.truckModel?.code ? TruckModelEnum.valueOf(this.truckModel.code as TruckModelEnumCode) : undefined,
            truckWeight: this.truckWeight?.code ? TruckWeightEnum.valueOf(this.truckWeight.code as TruckWeightEnumCode) : undefined
        };
    }

    set truckType(value: TruckType) {
        this.truckWeight = value.truckWeight ?? { code: undefined };
        this.truckModel = value.truckModel ?? { code: undefined };
    }

    /**
     * {@link CompanyTruckRegisterForm}を生成します
     */
    toForm(): CompanyTruckRegisterForm {
        return new CompanyTruckRegisterForm(this);
    }

    private validateTruckType(callback: (message?: string) => void): void {
        if (!this.truckWeight?.code) {
            callback('重量を選択してください。');
        } else if (!this.truckModel?.code) {
            callback('車種を選択してください。');
        } else {
            callback();
        }
    }

    validator(): FormValidator<CompanyTruckRegisterFormValidatableModel> {
        return {
            carNumber: [
                {
                    required: true,
                    message: '車番を入力してください。'
                }
            ],
            truckType: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateTruckType(callback),
                }
            ],
            driverPhoneNumber: [
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
            description: [
                {
                    max: Const.MAX_TRUCK_DESCRIPTION_LENGTH,
                    message: `${Const.MAX_TRUCK_DESCRIPTION_LENGTH}文字以内で入力してください。`,
                }
            ],
        };
    }

    static of(companyTruck: CompanyTruck): CompanyTruckRegisterFormValidatableModel {
        const instance = new CompanyTruckRegisterFormValidatableModel();
        instance.carNumber = companyTruck.carNumber;
        instance.truckWeight = { code: companyTruck.truckWeight.code.toString() };
        instance.truckModel = { code: companyTruck.truckModel.code.toString() };
        instance.driverName = companyTruck.driverName;
        instance.driverPhoneNumber = companyTruck.driverPhoneNumber;
        instance.description = companyTruck.description;
        return instance;
    }
}


// 車両更新フォーム
export class CompanyTruckUpdateForm {
    carNumber: string;
    truckWeight?: { code?: string };
    truckModel?: { code?: string };
    driverName: string;
    driverPhoneNumber: string;
    description: string;

    constructor(param: Partial<CompanyTruckUpdateForm> | null = null) {
        this.carNumber = param?.carNumber ?? '';
        this.truckWeight = param?.truckWeight ?? {};
        this.truckModel = param?.truckModel ?? {};
        this.driverName = param?.driverName ?? '';
        this.driverPhoneNumber = param?.driverPhoneNumber ?? '';
        this.description = param?.description ?? '';
    }
}

export class CompanyTruckUpdateFormModel
    extends CompanyTruckUpdateForm
    implements FormValidatable<CompanyTruckUpdateFormModel> {

    constructor(param: Partial<CompanyTruckUpdateForm> | null = null) {
        super(param);
    }

    get truckType(): TruckType {
        return {
            truckModel: this.truckModel?.code ? TruckModelEnum.valueOf(this.truckModel.code as TruckModelEnumCode) : undefined,
            truckWeight: this.truckWeight?.code ? TruckWeightEnum.valueOf(this.truckWeight.code as TruckWeightEnumCode) : undefined
        };
    }

    set truckType(value: TruckType) {
        this.truckWeight = value.truckWeight ?? { code: undefined };
        this.truckModel = value.truckModel ?? { code: undefined };
    }

    /**
     * {@link CompanyTruckUpdateForm}を生成します
     */
    toForm(): CompanyTruckUpdateForm {
        return new CompanyTruckUpdateForm(this);
    }

    private validateTruckType(callback: (message?: string) => void): void {
        if (!this.truckWeight?.code) {
            callback('重量を選択してください。');
        } else if (!this.truckModel?.code) {
            callback('車種を選択してください。');
        } else {
            callback();
        }
    }

    validator(): FormValidator<CompanyTruckUpdateFormModel> {
        return {
            carNumber: [
                {
                    required: true,
                    message: '車番を入力してください。',
                }
            ],
            truckType: [
                {
                    required: true,
                    validator: (_rule, value, callback) => {
                        this.validateTruckType(callback);
                    },
                }
            ],
            driverPhoneNumber: [
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
            description: [
                {
                    max: Const.MAX_TRUCK_DESCRIPTION_LENGTH,
                    message: `${Const.MAX_TRUCK_DESCRIPTION_LENGTH}文字以内で入力してください。`,
                }
            ],
        };
    }

    static of(companyTruck: CompanyTruck): CompanyTruckRegisterFormValidatableModel {
        const instance = new CompanyTruckRegisterFormValidatableModel();
        instance.carNumber = companyTruck.carNumber;
        instance.truckWeight = { code: companyTruck.truckWeight.code.toString() };
        instance.truckModel = { code: companyTruck.truckModel.code.toString() };
        instance.driverName = companyTruck.driverName;
        instance.driverPhoneNumber = companyTruck.driverPhoneNumber;
        instance.description = companyTruck.description;
        return instance;
    }
}
