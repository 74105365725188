<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import { useAccountRenewalVerifyHelper } from './account-renewal-verify-helper';

const props = defineProps<{
    verificationCode?: string,
}>();

const {
    loading,
    form,
    formModel,
    formValidateRules,
    onSubmit
} = useAccountRenewalVerifyHelper(props.verificationCode);
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <h1 class="body__title">パスワード再設定</h1>
            <a-spin :spinning="loading">
                <p>新しいパスワードを入力してください。</p>
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false">
                    <div class="form__fields">
                        <a-form-model-item prop="password">
                            <a-input-password placeholder="パスワード"
                                              v-model="form.password"
                                              @pressEnter="onSubmit">
                                <template v-slot:prefix>
                                    <tbx-icon type="lock"/>
                                </template>
                            </a-input-password>
                        </a-form-model-item>

                        <a-form-model-item prop="passwordConfirm">
                            <a-input-password placeholder="パスワード（確認用）"
                                              v-model="form.passwordConfirm"
                                              @pressEnter="onSubmit">
                                <template v-slot:prefix>
                                    <tbx-icon type="lock"/>
                                </template>
                            </a-input-password>
                        </a-form-model-item>
                    </div>
                    <nav>
                        <ul class="form__actions">
                            <li>
                                <a-button type="primary" @click="onSubmit">パスワードを再設定</a-button>
                            </li>
                        </ul>
                    </nav>
                </a-form-model>
            </a-spin>
        </div>
    </pre-login-layout>
</template>

<style scoped lang="less">
.body__container {
    width: 400px;
    min-height: 288px;

    .body__title {
        margin-bottom: @padding-lg;
        font-size: 18px;
        line-height: 30px;
        color: #48483f;
    }

    .form__fields {
        min-height: 132px;
    }

    .form__actions {
        margin: @padding-lg 0 0;
        padding: 0;
        list-style-type: none;

        > li {
            text-align: center;

            .ant-btn {
                min-width: 128px;
            }
        }

        > li + li {
            margin-top: @padding-sm;
        }
    }
}
</style>
