import { Baggage, BaggageUpdateApiForm, NewBaggageUpdateFormModel as BaggageUpdateFormModel } from '@/models/baggage';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { isTruckHeightEnumCode } from '@/enums/truck-height.enum';
import { isTruckWidthEnumCode } from '@/enums/truck-width.enum';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { DateValue } from '@/models/vo/date';
import { useLargeTruckAvailabilityConverter } from '@/composables/large-truck-availability';
import { useBaggageHighwayFarePaymentOption } from '@/composables/highway-fare-payment-option';
import dayjs from 'dayjs';
import { FormValidator } from '@/models/validate-helper';
import { useBaggageValidator } from '@/composables/baggage-validator';
import { useLoading } from '@/composables/helper/loading-helper';
import { baggageApi } from '@/repository/api/internal/baggage';
import { Util } from '@/util';
/**
 * 荷物から更新モデルを生成する
 */
export const buildFormModel = (baggage: Baggage): BaggageUpdateFormModel => {
    const { fromBoolean: convertToLargeTruckAvailability } = useLargeTruckAvailabilityConverter();
    const { fromBoolean: convertToBaggageHighwayFarePaymentOption } = useBaggageHighwayFarePaymentOption();

    return {
        circleId: baggage.circle?.id,
        departureDateTimeRange: DeliveryDateTimeRange.of(baggage.departureMin, baggage.departureMax) ?? undefined,
        arrivalDateTimeRange: DeliveryDateTimeRange.of(baggage.arrivalMin, baggage.arrivalMax) ?? undefined,
        // TODO: Baggageの型指定を改善する
        departurePrefCode: baggage.departurePref.code as PrefectureEnumCode,
        departureCity: baggage.departureCity,
        departureAddress: baggage.departureAddress,
        loadingTimeNote: baggage.loadingTimeNote,
        arrivalPrefCode: baggage.arrivalPref.code as PrefectureEnumCode,
        arrivalCity: baggage.arrivalCity,
        arrivalAddress: baggage.arrivalAddress,
        unloadingTimeNote: baggage.unloadingTimeNote,
        shape: baggage.shape.code,
        shapeDetail: {
            'BS1': {
                type: baggage.shape.code === 'BS1' ? baggage.type : undefined,
                paletteCount: baggage.paletteCount?.toString(),
                paletteHeight: baggage.paletteHeight?.toString(),
                paletteWidth: baggage.paletteWidth?.toString(),
            },
            'BS2': {
                type: baggage.shape.code === 'BS2' ? baggage.type : undefined,
                totalCount: baggage.totalCount?.toString(),
                totalVolume: baggage.totalVolume?.toString(),
            },
        },
        temperatureZone: baggage.temperatureZone.code,
        totalWeight: baggage.totalWeight?.toString(),
        get type() {
            if (this.shape === undefined) {
                return undefined;
            }
            return this.shapeDetail[this.shape].type;
        },
        get paletteCount() {
            if (this.shape === BaggageShapeEnum.Palette.code) {
                return this.shapeDetail['BS1'].paletteCount;
            }
            return undefined;
        },
        get paletteHeight() {
            if (this.shape === BaggageShapeEnum.Palette.code) {
                return this.shapeDetail['BS1'].paletteHeight;
            }
            return undefined;
        },
        get paletteWidth() {
            if (this.shape === BaggageShapeEnum.Palette.code) {
                return this.shapeDetail['BS1'].paletteWidth;
            }
            return undefined;
        },
        get paletteSize() {
            if (this.shape === BaggageShapeEnum.Palette.code) {
                return {
                    paletteHeight: this.shapeDetail['BS1'].paletteHeight,
                    paletteWidth: this.shapeDetail['BS1'].paletteWidth,
                };
            } else {
                return {
                    paletteHeight: undefined,
                    paletteWidth: undefined,
                };
            }
        },
        get totalCount() {
            if (this.shape === BaggageShapeEnum.Other.code) {
                return this.shapeDetail['BS2'].totalCount;
            }
            return undefined;
        },
        get totalVolume() {
            if (this.shape === BaggageShapeEnum.Other.code) {
                return this.shapeDetail['BS2'].totalVolume;
            }
            return undefined;
        },
        loadingOperation: baggage.loading?.code,
        unloadingOperation: baggage.unloading?.code,
        truckType: {
            truckWeight: baggage.truckWeight.code,
            truckModel: baggage.truckModel.code,
        },
        truckHeight: isTruckHeightEnumCode(baggage.truckHeight?.code) ? baggage.truckHeight?.code : undefined,
        truckWidth: isTruckWidthEnumCode(baggage.truckWidth?.code) ? baggage.truckWidth?.code : undefined,
        largeTruckAvailability: convertToLargeTruckAvailability(baggage.largeTruckFlg),
        truckEquipment: baggage.truckEquipment,
        baggageFreight: new BaggageFreightValue(baggage.freight),
        highwayFarePaymentOption: convertToBaggageHighwayFarePaymentOption(baggage.highwayFareFlg),
        paymentDate: baggage.paymentDate ? new DateValue(baggage.paymentDate) : undefined,
        category: baggage.category.code,
        share: baggage.share,
        express: baggage.express,
        staffName: baggage.staffName,
        description: baggage.description,
        underNegotiation: baggage.underNegotiation,
        traboxBaggageId: baggage.traboxBaggageId,
        shipperName: baggage.shipperName,
        label: {
            labelText: baggage.labelText,
            labelColor: baggage.labelColor,
        },
    };
};

export const buildValidationRules = (
    form: () => BaggageUpdateFormModel,
    availablePaymentDateRange: () => [dayjs.Dayjs, dayjs.Dayjs],
    defaultPaymentDate: DateValue | undefined,
): FormValidator<BaggageUpdateFormModel> => {
    const {
        departureDateTimeRange,
        arrivalDateTimeRange,
        departurePref,
        departureCity,
        departureAddress,
        arrivalPref,
        arrivalCity,
        arrivalAddress,
        loadingTimeNote,
        unloadingTimeNote,
        shape,
        type,
        paletteCount,
        paletteSize,
        totalCount,
        totalVolume,
        totalWeight,
        truckEquipment,
        freight,
        highwayFarePaymentOption,
        paymentDate,
        description,
        staffName,
        traboxBaggageId,
        shipperName,
        label,
    } = useBaggageValidator();

    const context = () => ({
        ...form(),
        availablePaymentDateRange: availablePaymentDateRange(),
        defaultPaymentDate: defaultPaymentDate,
    });

    return {
        departureDateTimeRange: departureDateTimeRange(context),
        arrivalDateTimeRange: arrivalDateTimeRange(context),
        departurePrefCode: departurePref(context),
        departureCity: departureCity(context),
        departureAddress: departureAddress(context),
        loadingTimeNote: loadingTimeNote(context),
        arrivalPrefCode: arrivalPref(context),
        arrivalCity: arrivalCity(context),
        arrivalAddress: arrivalAddress(context),
        unloadingTimeNote: unloadingTimeNote(context),
        shape: shape(context),
        type: type(context),
        paletteCount: paletteCount(context),
        paletteSize: paletteSize(context),
        totalCount: totalCount(context),
        totalVolume: totalVolume(context),
        totalWeight: totalWeight(context),
        truckEquipment: truckEquipment(context),
        baggageFreight: freight(context),
        highwayFarePaymentOption: highwayFarePaymentOption(context),
        paymentDate: paymentDate(context),
        description: description(context),
        staffName: staffName(context),
        traboxBaggageId: traboxBaggageId(context),
        shipperName: shipperName(context),
        label: label(context),
    };
};

/**
 * API用のフォームを生成する
 */
export const convertToApiForm = (form: BaggageUpdateFormModel): BaggageUpdateApiForm | undefined => {
    const { toBoolean: convertLargeTruckAvailabilityConverter } = useLargeTruckAvailabilityConverter();
    const { toBoolean: convertBaggageHighwayFarePaymentOption } = useBaggageHighwayFarePaymentOption();

    // TODO: バリデーションの仕組みと組み合わせて、ここのnullableチェックを効率化できそう
    // requiredを指定しているプロパティ値からnullableを外したオブジェクトを生成するイメージ

    try {
        const departureDateTime = Util.requireNotNull(form.departureDateTimeRange?.rawValuesAsString());
        const arrivalDateTime = Util.requireNotNull(form.arrivalDateTimeRange?.rawValuesAsString());

        return {
            circleId: Util.requireNotNull(form.circleId),
            departureMin: departureDateTime[0],
            departureMax: departureDateTime[1],
            departurePref: { code: Util.requireNotNull(form.departurePrefCode) },
            departureCity: Util.requireNotNull(form.departureCity),
            departureAddress: form.departureAddress,
            loadingTimeNote: form.loadingTimeNote,
            arrivalMin: arrivalDateTime[0],
            arrivalMax: arrivalDateTime[1],
            arrivalPref: { code: Util.requireNotNull(form.arrivalPrefCode) },
            arrivalCity: Util.requireNotNull(form.arrivalCity),
            arrivalAddress: form.arrivalAddress,
            unloadingTimeNote: form.unloadingTimeNote,
            category: { code: Util.requireNotNull(form.category) },
            type: Util.requireNotNull(form.type),
            shape: { code: Util.requireNotNull(form.shape) },
            paletteCount: form.paletteCount,
            paletteHeight: form.paletteHeight,
            paletteWidth: form.paletteWidth,
            totalCount: form.totalCount,
            totalVolume: form.totalVolume,
            totalWeight: form.totalWeight,
            loading: form.loadingOperation ? { code: form.loadingOperation } : undefined,
            unloading: form.unloadingOperation ? { code: form.unloadingOperation } : undefined,
            temperatureZone: { code: Util.requireNotNull(form.temperatureZone) },
            truckWeight: { code: Util.requireNotNull(form.truckType.truckWeight) },
            truckModel: { code: Util.requireNotNull(form.truckType.truckModel) },
            truckHeight: form.truckHeight ? { code: form.truckHeight } : undefined,
            truckWidth: form.truckWidth ? { code: form.truckWidth } : undefined,
            largeTruckFlg: convertLargeTruckAvailabilityConverter(form.largeTruckAvailability),
            truckEquipment: form.truckEquipment,
            share: form.share,
            express: form.express,
            freight: form.baggageFreight?.value?.toString() ?? '',
            highwayFareFlg: convertBaggageHighwayFarePaymentOption(Util.requireNotNull(form.highwayFarePaymentOption)),
            staffName: form.staffName,
            description: form.description,
            underNegotiation: form.underNegotiation,
            paymentDate: form.paymentDate?.format('YYYY-MM-DD'),
            traboxBaggageId: form.traboxBaggageId,
            shipperName: form.shipperName,
            labelText: form.label.labelText,
            labelColor: form.label.labelColor,
        };
    } catch {
        return undefined;
    }
};

export const useBaggageUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();

    const update = async (baggageId: number, form: BaggageUpdateApiForm) => withLoading(async () => {
        await baggageApi.update(baggageId, form);
    });

    return {
        state: {
            loading,
        },
        update
    };
};
