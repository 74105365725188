import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import type dayjs from 'dayjs';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage, BaggageUpdateForm } from '@/vuex/modules/baggage/types';
import { ValidateResult, Validator } from '@/validator';
import { DateUtil } from '@/util';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { DateTimePickerValue } from '@/components/UI/DateTimePicker/types';
// @ts-ignore
import UiDateTimePicker from '@/components/UI/DateTimePicker';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiCityInput from '@/components/UI/CityInput';
import { Const } from '@/const';

@Component({
    components: {
        UiDateTimePicker,
        UiPrefectureSelect,
        UiCityInput,
    },
})
export default class BaggageSpotEdit extends Vue {
    validationRules: { [key: string]: Array<ValidationRule> } = {
        departureDateTime: [
            {
                required: true,
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const [departureMin, departureMax] = this.departureDateTime;
                    const value = DeliveryDateTime.of(departureMin ?? '', departureMax ?? '');
                    const validated = Validator.validateDateTime(value);
                    if (!validated.result) {
                        callback(validated.message);
                    } else {
                        callback();
                    }
                },
            },
        ],
        departurePrefCode: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.departurePrefCode,
                message: '都道府県を選択してください。',
            },
        ],
        departureCity: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.departureCity,
                message: '市区町村名を入力してください。',
            },
            {
                transform: (): string => this.departureCity,
                max: Const.MAX_CITY,
                message: `市区町村名は${Const.MAX_CITY}文字以内で入力してください。`,
            },
        ],
        departureAddress: [
            {
                required: false,
                transform: (): string => this.departureAddress,
                max: Const.MAX_ADDRESS,
                message: `番地・建物は${Const.MAX_ADDRESS}文字以内で入力してください。`,
            },
        ],
        loadingTimeNote: [
            {
                required: false,
                transform: (): string | undefined => this.loadingTimeNote,
                max: Const.MAX_LOADING_TIME_NOTE,
                message: `積み時間は${Const.MAX_LOADING_TIME_NOTE}文字以内で入力してください。`,
            },

        ],
        arrivalDateTime: [
            {
                required: true,
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const [arrivalMin, arrivalMax] = this.arrivalDateTime;
                    const value = DeliveryDateTime.of(arrivalMin ?? '', arrivalMax ?? '');
                    const validated = Validator.validateDateTime(value);
                    if (!validated.result) {
                        callback(validated.message);
                    } else {
                        callback();
                    }
                },
            },
            {
                // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
                validator: (_rule, _value, callback: Function) => {
                    const rangeValidated = this.dateTimeRangeValidateResult.result;
                    if (rangeValidated) {
                        callback();
                    } else {
                        callback(this.dateTimeRangeValidateResult.message);
                    }
                },
            },
        ],
        arrivalPrefCode: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.arrivalPrefCode,
                message: '都道府県を選択してください。',
            },
        ],
        arrivalCity: [
            {
                required: true,
                whitespace: true,
                transform: (): string => this.arrivalCity,
                message: '市区町村名を入力してください。',
            },
            {
                transform: (): string => this.arrivalCity,
                max: Const.MAX_CITY,
                message: `市区町村名は${Const.MAX_CITY}文字以内で入力してください。`,
            },
        ],
        arrivalAddress: [
            {
                required: false,
                transform: (): string => this.arrivalAddress,
                max: Const.MAX_ADDRESS,
                message: `番地・建物は${Const.MAX_ADDRESS}文字以内で入力してください。`,
            },
        ],
        unloadingTimeNote: [
            {
                required: false,
                transform: (): string | undefined => this.unloadingTimeNote,
                max: Const.MAX_UNLOADING_TIME_NOTE,
                message: `卸し時間は${Const.MAX_LOADING_TIME_NOTE}文字以内で入力してください。`,
            },

        ],
    };

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: Baggage;
    @Prop()
    declare readonly value?: BaggageUpdateForm;

    /**
     * 出発日時
     */
    get departureDateTime(): DateTimePickerValue {
        // TODO: 将来的にはDeliveryDateTime型で扱いたい
        return [this.value?.departureMin, this.value?.departureMax];
    }

    set departureDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 出発日時を書き換え
        this.emitNewValue({
            departureMin: dateMin ?? '',
            departureMax: dateMax ?? '',
        });

        this.changeDepartureOrArrival();
    }

    /**
     * 到着日時
     */
    get arrivalDateTime(): DateTimePickerValue {
        return [this.value?.arrivalMin, this.value?.arrivalMax];
    }

    set arrivalDateTime(value: DateTimePickerValue) {
        const [dateMin, dateMax] = value;
        // 到着日時を書き換え
        this.emitNewValue({
            arrivalMin: dateMin ?? '',
            arrivalMax: dateMax ?? '',
        });

        this.changeDepartureOrArrival();
    }

    /**
     * 出発地（都道府県）
     */
    get departurePrefCode(): string {
        return this.value?.departurePref.code ?? '';
    }

    set departurePrefCode(newValue: string) {
        const changed = this.value?.departurePref.code !== newValue;

        // 出発地を書き換え
        this.emitNewValue({
            // 都道府県
            departurePref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            departureCity: changed ? '' : this.value?.departureCity,
            // 番地・建物（都道府県変更された場合はクリア）
            departureAddress: changed ? '' : this.value?.departureAddress,
        });

        this.changeDeparture();
    }

    /**
     * 出発地（市区町村）
     */
    get departureCity(): string {
        return this.value?.departureCity ?? '';
    }

    set departureCity(newValue: string) {
        // 出発地（市区町村）を書き換え
        this.emitNewValue({ departureCity: newValue });

        this.changeDeparture();
    }

    /**
     * 出発地（番地・建物）
     */
    get departureAddress(): string {
        return this.value?.departureAddress ?? '';
    }

    set departureAddress(newValue: string) {
        // 出発地（番地・建物）を書き換え
        this.emitNewValue({ departureAddress: newValue });

        this.changeDeparture();
    }

    /**
     * 積み時間
     */
    get loadingTimeNote(): string | undefined {
        return this.value?.loadingTimeNote;
    }

    set loadingTimeNote(newValue: string | undefined) {
        this.emitNewValue({ loadingTimeNote: newValue });
        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemLoadingTimeNote.onFieldChange());
    }

    /**
     * 到着地（都道府県）
     */
    get arrivalPrefCode(): string {
        return this.value?.arrivalPref.code ?? '';
    }

    set arrivalPrefCode(newValue: string) {
        const changed = this.value?.arrivalPref.code !== newValue;

        // 到着地を書き換え
        this.emitNewValue({
            // 都道府県
            arrivalPref: { code: newValue },
            // 市区町村（都道府県が変更された場合はクリア）
            arrivalCity: changed ? '' : this.value?.arrivalCity,
            // 番地・建物（都道府県変更された場合はクリア）
            arrivalAddress: changed ? '' : this.value?.arrivalAddress,
        });

        this.changeArrival();
    }

    /**
     * 到着地（市区町村）
     */
    get arrivalCity(): string {
        return this.value?.arrivalCity ?? '';
    }

    set arrivalCity(newValue: string) {
        // 到着地（市区町村）を書き換え
        this.emitNewValue({ arrivalCity: newValue });

        this.changeArrival();
    }

    /**
     * 到着地（番地・建物）
     */
    get arrivalAddress(): string {
        return this.value?.arrivalAddress ?? '';
    }

    set arrivalAddress(newValue: string) {
        // 到着地（番地・建物）を書き換え
        this.emitNewValue({ arrivalAddress: newValue });

        this.changeArrival();
    }

    /**
     * 卸し時間
     */
    get unloadingTimeNote(): string | undefined {
        return this.value?.unloadingTimeNote;
    }

    set unloadingTimeNote(newValue: string | undefined) {
        this.emitNewValue({ unloadingTimeNote: newValue });
        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemUnloadingTimeNote.onFieldChange());
    }

    /**
     * バリデーションの結果を取得します。
     */
    get dateTimeRangeValidateResult(): ValidateResult {
        const [departureMin, departureMax] = this.departureDateTime;
        const departure = departureMin && departureMax ? DeliveryDateTime.of(departureMin, departureMax) : null;
        const [arrivalMin, arrivalMax] = this.arrivalDateTime;
        const arrival = arrivalMin && arrivalMax ? DeliveryDateTime.of(arrivalMin, arrivalMax) : null;
        return Validator.validateDateTimeRange(departure, arrival);
    }

    /**
     * 出発日時・到着日時を選択できる日付の範囲を取得します。
     */
    get selectableDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 荷物編集は今日の1時間後から1年後の年末まで有効
        const registered = DateUtil.parseDatetimeText(this.baggage?.entryTm ?? '');
        return [registered.add(1, 'hour').startOf('hour'), registered.add(1, 'year').endOf('year')];
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 出発地か到着地が変更された際に呼び出される。
     */
    private changeDepartureOrArrival(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalDateTime.onFieldChange();
        });
    }

    /**
     * 出発地が変更された際に呼び出される。
     */
    private changeDeparture(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemDepartureDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDeparturePrefCode.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDepartureCity.onFieldChange();
            // @ts-ignore
            this.$refs.formItemDepartureAddress.onFieldChange();
        });
    }

    /**
     * 到着地が変更された際に呼び出される。
     */
    private changeArrival(): void {
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.formItemArrivalDateTime.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalPrefCode.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalCity.onFieldChange();
            // @ts-ignore
            this.$refs.formItemArrivalAddress.onFieldChange();
        });
    }

    /**
     * 新しい値を親にemitします。
     */
    private emitNewValue(newValues: Partial<BaggageUpdateForm>): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        this.$emit('input', _.merge(cloned, newValues));
    }
}
