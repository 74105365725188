import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import {
    CompanyTruckEvent,
    CompanyTruckEventMemoRegisterForm,
    CompanyTruckEventSearchForm,
    CompanyTruckEventMemoUpdateForm,
    CompanyTruckEventTruckRegisterForm,
    CompanyTruckEventTruckUpdateForm,
} from '@/models/company-truck-event';

export const companyTruckEventApi = {
    /**
     * 取得します。
     */
    find(id: number): Promise<CompanyTruckEvent> {
        return appAxios.get<JsonResponseBean<CompanyTruckEvent>>(`/api/company_truck_event/${ id }`).then(isSuccess);
    },
    /**
     * 検索します。
     */
    search(form: CompanyTruckEventSearchForm): Promise<CompanyTruckEvent[]> {
        return appAxios.post<JsonResponseBean<CompanyTruckEvent[]>>('/api/company_truck_event/search', form).then(isSuccess);
    },
    /**
     * メモを登録します。
     */
    registerMemo(param: CompanyTruckEventMemoRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/company_truck_event/memo/register', param).then(isSuccess);
    },
    /**
     * 空車を登録します。
     */
    registerTruck(param: CompanyTruckEventTruckRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/company_truck_event/truck/register', param).then(isSuccess);
    },
    /**
     * メモを更新します。
     */
    updateMemo(id: number, param: CompanyTruckEventMemoUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_truck_event/memo/${id}`, param).then(isSuccess);
    },
    /**
     * 空車を更新します。
     */
    updateTruck(id: number, param: CompanyTruckEventTruckUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_truck_event/truck/${id}`, param).then(isSuccess);
    },
    /**
     * 削除します。
     */
    delete(id: number): Promise<void> {
        return appAxios.delete<JsonResponseBean<void>>(`/api/company_truck_event/${id}`).then(isSuccess);
    }
};
