import { computed, onBeforeMount } from 'vue';
import { Modal } from 'ant-design-vue';
import { useRouting } from '@/composables/helper/routing';
import { useNotification } from '@/composables/helper/page-helper';
import { useTimer } from '@/composables/helper/time-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useGuaranteeMaster } from '@/composables/guarantee-master';
import { useCompanyPayment } from '@/composables/company-payment';
import { useGuaranteeRegister } from '@/composables/guarantee-register';
import { useGuaranteedAmount } from '@/composables/guarantee';
import { DateValue } from '@/models/vo/date';
import { AmountValue } from '@/models/vo/amount';
import { GuaranteeUtil } from '@/util';
import Content from '@/_components/parts/GuaranteeRegistrationConfirmDialog.vue';

export const useMiscGuaranteeRegister = () => {
    const notification = useNotification();
    const { goToMiscTop } = useRouting();
    const { delay } = useTimer();
    const { state: { loading }, withLoading } = useLoading();
    const { state: { profile } } = useAccountMyProfile();

    // 運賃全額保証関連
    const { load: loadGuaranteeMaster, state: { list: guaranteeMaster } } = useGuaranteeMaster();
    const { load: loadGuaranteedAmount, state: { amount: guaranteedAmount } } = useGuaranteedAmount();
    const { register } = useGuaranteeRegister();

    // 企業関連
    const {
        load: loadContract,
        loadIfNotExists: loadContractIfNotExists,
        state: { contracts }
    } = useCompanyContracts();
    const { load: loadPayment, state: { payment } } = useCompanyPayment();

    // 管理者であるか
    const isMainAccount = computed(() => profile.value?.isMainAccount ?? false);
    // 運賃全額保証加入済みであるか
    const existsGuarantee = computed(() => contracts.value?.existsGuarantee ?? false);
    // プレミアムプラン加入済みであるか
    const existsPremium = computed(() => contracts.value?.existsPremiumPlan ?? false);

    // 運賃全額保証サービス利用料
    const guaranteeServiceAmount = computed(() => new AmountValue(GuaranteeUtil.calculateGuaranteePrice(
        guaranteedAmount.value?.amount.value ?? 0,
        guaranteeMaster.value ?? [],
        contracts.value?.contracts ?? []
    )));

    /**
     * 登録ボタンが押下された際に呼び出されます。
     */
    const onClickRegister = async () => {
        if (!await confirmGuarantee()) return;

        await registerGuarantee();
    };

    /**
     * 申込確認を行います。
     */
    const confirmGuarantee = () => {
        const buttonProp = (disabled: boolean) => ({ props: { disabled } });

        return new Promise<boolean>((resolve) => {
            const modal = Modal.confirm({
                title: '運賃全額保証サービスを申し込みますか？',
                content: (h: Function) => h(Content, {
                    props: {
                        payment: payment.value,
                        isPremium: existsPremium.value,
                    },
                    on: {
                        input: (checked: boolean) => modal.update({ okButtonProps: buttonProp(!checked) }),
                    },
                }),
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: '運賃全額保証サービスを申し込む',
                cancelText: 'キャンセル',
                autoFocusButton: 'cancel',
                width: 640,
                okButtonProps: buttonProp(true),
            });
        });
    };

    /**
     * 申込みます。
     */
    const registerGuarantee = () => withLoading(async () => {
        try {
            await register();

            notification.success({
                message: '運賃全額保証サービスの申し込み手続きが完了しました。',
                description: 'これより運賃全額保証サービスをご利用いただけます。お申し込みありがとうございました。',
            });

            // サーバーサイドの処理完了猶予
            await delay(500);

            await loadContract();
        } catch {
            notification.error({
                message: '運賃全額保証サービスの申し込み手続きができませんでした。',
                description: '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    onBeforeMount(async () => {
        const today = DateValue.today();

        await loadGuaranteeMaster().catch();
        await loadGuaranteedAmount(today.year, today.monthValue).catch();
        await loadContractIfNotExists().catch();
        await loadPayment().catch();
    });

    return {
        goToMiscTop,
        isMainAccount,
        guaranteedAmount,
        guaranteeServiceAmount,
        existsGuarantee,
        loading,
        onClickRegister,
    };
};
