<script setup lang="ts">
import { Const } from '@/const';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

const guideUrl = Const.externalPageUrl.easyPaymentGuide;

</script>

<template>
    <a :href="guideUrl" target="_blank">
        <tbx-link-text type="external">請求・支払について</tbx-link-text>
    </a>
</template>

<style scoped lang="less">
</style>
