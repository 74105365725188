import { TbxRouteConfig } from '@/router/routes/types';
import UpgradePlanPage from '@/pages/UpgradePlan/Register/index.vue';
import _UpgradePlanCompletePage from '@/_pages/UpgradePlan/Complete/UpgradePlanComplete.vue';

export const upgradeRoutes: TbxRouteConfig[] = [
    {
        path: '/upgrade',
        name: 'UpgradePlan',
        component: UpgradePlanPage,
        meta: { layout: { name: 'main', contentOnly: true, showHelp: true } },
    },
    {
        path: '/upgrade/complete',
        name: 'UpgradePlanComplete',
        component: _UpgradePlanCompletePage,
        meta: {
            title: 'プレミアムプランのお申し込みが完了しました',
            layout: { name: 'main', contentOnly: true, showHelp: true }
        },
    },
];
