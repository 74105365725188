import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class CompanyFaxEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    @Prop({ default: false })
    declare readonly mandatory: boolean;

    /**
     * FAX番号
     */
    get faxNumber(): string | undefined {
        return this.value?.faxNumber;
    }

    set faxNumber(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.faxNumber = Util.emptyStringToUndefined(newValue ?? '');
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            transform: (): string => Util.toDigits(this.faxNumber ?? ''),
            min: Const.MIN_PHONE_NUMBER,
            max: Const.MAX_PHONE_NUMBER,
            message: `FAX番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
        },
        {
            transform: (): string => Util.toDigits(this.faxNumber ?? ''),
            pattern: Const.PHONE_NUMBER_REGEX,
            message: 'FAX番号を正しい形式で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * FAX番号のフォーカスが外れた際に呼び出されます。
     */
    onBlurFaxNumber(): void {
        this.faxNumber = Util.toDigits(this.faxNumber?.trim() ?? '');
    }
}
