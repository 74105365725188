import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';
// @ts-ignore
import TrialFlowStep from '@/components/Common/TrialFlowStep';
import { Const } from '@/const';
import { URLUtil } from '@/util';

@Component({
    title: 'お送りしたメールから申し込み手続きを進めてください',
    components: {
        UiPreLoginLayout,
        TrialFlowStep,
    },
})
export default class AccountCompletePage extends PageVue {
    supportDesk = Const.supportDeskContacts;

    get position(): string {
        const query = URLUtil.parseQueryValue(this.$route.query.view);
        return query === 'embed' ? 'bottom' : 'center';
    }
}
