import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import * as types from './types';

const { ACTION: a, GETTER: g, MUTATION: m } = types;

const state: types.RoutingState = {
    routing: false,
};

const actions: ActionTree<types.RoutingState, void> = {
    /**
     * ルーティングを開始します。
     */
    [a.START_ROUTING]: ({ commit }) => commit(m.SET_ROUTING, true),
    /**
     * ルーティングを終了します。
     */
    [a.END_ROUTING]: ({ commit }) => commit(m.SET_ROUTING, false),
};

const getters: GetterTree<types.RoutingState, void> = {
    /**
     * ルーティング状態を取得します。
     */
    [g.ROUTING]: (s) => s.routing,
};

const mutations: MutationTree<types.RoutingState> = {
    /**
     * ルーティング状態を設定します。
     *
     * @param routing ルーティング状態
     */
    [m.SET_ROUTING]: (s, routing) => (s.routing = routing),
};

export const routing: Module<types.RoutingState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
