import { useTitle } from '@vueuse/core';
import { readonly } from 'vue';
import { Const } from '@/const';

export const useDocumentTitle = () => {
    const title = useTitle();

    const setTitle = (newTitle: string | string[]): void => {
        if (!newTitle) return;

        const pageTitle = Array.isArray(newTitle) ? newTitle.join(Const.PAGE_TITLE_SEPARATOR) : newTitle;
        title.value = `${ pageTitle }${ Const.PAGE_TITLE_SEPARATOR }${ Const.APP_SERVICE_NAME }`;
    };

    return {
        title: readonly(title),
        setTitle,
    };
};
