import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { Const } from '@/const';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as types from '@/vuex/modules/truck/types';
// @ts-ignore
import TruckListView from '@/components/Truck/View/List';
import { Modal } from 'ant-design-vue';
import { ModalUtil, PageUtil } from '@/util';
import { pagination } from '@/repository/storage/web-storage';
import { goToEditTruck, goToRegisterTruck, } from '@/pages/Truck/List/helpers';

const truckMod = namespace('truck');

@Component({
    title: '登録した空車情報',
    components: { TruckListView },
    beforeRouteEnter: TruckListPage.beforeRouteEnter,
    beforeRouteLeave: TruckListPage.beforeRouteLeave,
})
export default class TruckListPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @truckMod.Getter(types.GETTER.REGISTERED_TRUCK_LIST)
    readonly REGISTERED_TRUCK_LIST?: types.TruckList;

    // ======================================================
    // Data
    // ======================================================
    loading = false;

    static get pageSize(): number {
        const pageSize = pagination.getPageSize();
        if (pageSize && Const.PAGE_SIZE_OPTIONS.includes(pageSize ?? '')) {
            return Number(pageSize);
        } else {
            pagination.clearPageSize();
            return Const.DEFAULT_PAGE_SIZE;
        }
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    async onChangePage(pageNo: number, pageSize: number): Promise<void> {
        this.loading = true;
        await TruckListPage.loadTruckList(pageNo, pageSize).finally(() => (this.loading = false));
        this.scrollToSearchResultsSection();
        pagination.setPageSize(pageSize.toString());
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * トラック情報の変更ボタンが押下された際に呼び出されます。
     */
    async onEditTruck(id: number): Promise<VueRoute> {
        return goToEditTruck(id);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * トラック情報のコピーボタンが押下された際に呼び出されます。
     */
    async onCopyTruck(id: number): Promise<VueRoute> {
        return goToRegisterTruck(id);
    }

    /**
     * トラック情報の削除ボタンが押下された際に呼び出されます。
     */
    async onCloseTruck(id: number): Promise<void> {
        const notifyFailedKey = 'DELETE_TRUCK_FAILED';

        const onSuccess = () => {
            this.loading = false;
            this.$message.success('登録した空車情報を削除しました。');
        };
        const onError = () => {
            this.loading = false;
            this.$notification.error({
                key: notifyFailedKey,
                message: `空車情報を削除できませんでした。`,
                description: `時間をおいて再度お試しください。`,
            });
        };

        Modal.confirm({
            title: `選択した空車情報を本当に削除しますか？`,
            content: 'この操作は元に戻すことができません。ご注意ください。',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                this.loading = true;
                this.$notification.close(notifyFailedKey);
                await TruckListPage.closeTruck(id, {
                    pageNo: this.REGISTERED_TRUCK_LIST?.currentPageNumber ?? 1,
                    pageSize: this.REGISTERED_TRUCK_LIST?.pageSize ?? Const.DEFAULT_PAGE_SIZE,
                })
                    .then(onSuccess)
                    .catch(onError);
            },
        });
    }

    /**
     * 空車情報一覧をロードします。
     */
    private static loadTruckList(pageNo = 1, pageSize = Const.DEFAULT_PAGE_SIZE): Promise<void> {
        const form: types.TruckListForm = {
            pageNo,
            pageSize,
        };
        return store.dispatch(`truck/${types.ACTION.LIST_TRUCK}`, form);
    }

    /**
     * 空車情報を削除します。
     */
    private static closeTruck(id: number, param: { pageNo: number; pageSize: number }): Promise<void> {
        return store.dispatch(`truck/${types.ACTION.CLOSE_TRUCK}`, { id, param });
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<TruckListPage>
    ): Promise<void> {
        await TruckListPage.loadTruckList(1, TruckListPage.pageSize);
        next();
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<TruckListPage>
    ): Promise<void> {
        await store.dispatch(`truck/${types.ACTION.CLEAR_TRUCK_LIST}`);
        next();
    }

    /**
     * 検索結果リストの先頭へスクロールします。
     */
    scrollToSearchResultsSection(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
    }
}
