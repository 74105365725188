import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as truckTypes from '@/vuex/modules/truck/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyProfile } from '@/vuex/modules/company/types';
import * as formDirtyTypes from '@/vuex/modules/formDirty/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
// @ts-ignore
import UiDrawerLayout from '@/components/UI/Layouts/DrawerLayout';
// @ts-ignore
import BaggageDetailView from '@/components/Baggage/View';
// @ts-ignore
import BaggageDetailEditDrawer from '@/components/Baggage/Edit';
import type { BaggageListTabKey } from '@/pages/Baggage/List/script';
import { Karte } from '@/karte';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
// @ts-ignore
import TruckCompanyDetailDrawerContent from '@/pages/Baggage/List/drawers/BaggageDetailDrawerContent/TruckCompanyDetailDrawerContent';
// @ts-ignore
import SortPaginationControl from '@/components/UI/SortPaginationControl';
import { Enum } from '@/types/enum';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { PhoneUtil, RegionUtil, Util } from '@/util';
import { DateTimeValue } from '@/models/vo/datetime';

import { gtm } from '@/gtm';
import router from '@/router';

const baggageMod = namespace('baggage');
const companyMod = namespace('company');
const formDirtyMod = namespace('formDirty');
const negotiationMod = namespace('negotiation');

export type BaggageDetailTabKey = 'baggage' | 'truck' | 'negotiation';
export type NegotiationViewResult = negotiationTypes.Negotiation & { company?: companyTypes.CompanyProfile };

@Component({
    components: {
        UiDrawerLayout,
        BaggageDetailView,
        BaggageDetailEditDrawer,
        UiDateTimeLocationLabel,
        TruckCompanyDetailDrawerContent,
        SortPaginationControl,
    },
})
export default class BaggageDetailDrawerContent extends Vue {
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE)
    readonly BAGGAGE?: baggageTypes.Baggage;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    MY_PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;
    @baggageMod.Action(baggageTypes.ACTION.UPDATE_BAGGAGE)
    readonly updateBaggage!: baggageTypes.updateBaggage;
    @formDirtyMod.Action(formDirtyTypes.ACTION.SET_FORM_DIRTY)
    readonly setFormDirty!: formDirtyTypes.setFormDirty;
    @companyMod.Getter(companyTypes.GETTER.STAFF_NAME_SUGGESTION_LIST)
    staffNameSuggestions?: companyTypes.CompanyStaffNameSuggestionList;
    @companyMod.Action(companyTypes.ACTION.REGISTER_STAFF_NAME_SUGGESTION)
    readonly registerStaffNameSuggestion!: companyTypes.registerStaffNameSuggestion;
    @negotiationMod.Getter(negotiationTypes.GETTER.NEGOTIATION_LIST)
    readonly NEGOTIATION_LIST?: negotiationTypes.NegotiationList;
    @companyMod.Getter(companyTypes.GETTER.PROFILE_LIST)
    readonly COMPANY_PROFILE_LIST?: companyTypes.CompanyProfile[];

    @Prop()
    declare readonly baggageId?: string;
    @Prop()
    declare readonly activeTabKey?: BaggageListTabKey;
    @Prop({ default: false })
    declare readonly editorVisible: boolean;
    @Prop({ default: 'baggage' })
    declare readonly detailTabKey: BaggageDetailTabKey;
    @Prop()
    readonly truckRecommendList?: truckTypes.TruckWithCompanyList;

    actionSubmitting = false;

    truckCompanyId?: number = undefined;
    truckId?: number = undefined;

    get loading(): boolean {
        return !this.BAGGAGE;
    }

    get loadingTruck(): boolean {
        return !this.truckRecommendList;
    }

    get loadingNegotiationRequest(): boolean {
        return !this.NEGOTIATION_LIST;
    }

    /**
     * 荷物アクションのボタンメニューを出すべきか否かを取得します。
     */
    get shouldShowActionMenu(): boolean {
        return !!this.BAGGAGE;
    }

    /**
     * 荷物自体を操作できる（編集/削除できる）か否かを取得します。
     */
    get canModifyBaggage(): boolean {
        // TODO このコンポーネントでタブを意識するのはおかしいので修正
        return this.activeTabKey === 'opened';
    }

    /**
     * 商談中を操作できるかを取得します。
     */
    get canMarkNegotiation(): boolean {
        // TODO このコンポーネントでタブを意識するのはおかしいので修正
        return this.activeTabKey === 'opened';
    }

    /**
     * 商談中か否かを取得します。
     */
    get underNegotiation(): boolean {
        if (!this.BAGGAGE?.status?.code) return false;
        const isOpened = BaggageStatusEnum.valueOf(this.BAGGAGE.status.code)?.isOpened() ?? false;
        return isOpened && this.BAGGAGE.underNegotiation;
    }

    /**
     * 商談リクエストタブを表示するか否かを取得します。
     */
    get shouldShowNegotiationRequest(): boolean {
        return this.activeTabKey === 'opened' && this.BAGGAGE?.negotiationType?.code === NegotiationTypeEnum.Online.code;
    }

    get tabKey(): BaggageDetailTabKey {
        return this.detailTabKey;
    }

    set tabKey(value: BaggageDetailTabKey) {
        this.$emit('changeTabKey', value);
    }

    get negotiations(): NegotiationViewResult[] {
        return (this.NEGOTIATION_LIST?.data ?? [])
            .map(each => {
                const company = this.COMPANY_PROFILE_LIST?.find(company => company.id === each.truckCompanyId);
                return { ...each, company };
            });
    }

    /**
     * 荷物の変更ボタンが押下された際に呼び出されます。
     */
    async onClickEditBaggage(): Promise<void> {
        // TODO emitへリファクタリング
        if (this.activeTabKey !== 'opened' || !this.BAGGAGE) return;

        const query = _.set(_.clone(this.$route.query), 'edit', true);
        await this.$router.push({ path: this.$route.path, query });
    }

    /**
     * 荷物をコピーするボタンが押下された際に呼び出されます。
     */
    async onClickCopyBaggage(baggageId: number): Promise<void> {
        // TODO emitへリファクタリング
        await this.$router.push({ path: '/baggage/register', query: { sourceBaggageId: baggageId.toString() } });
    }

    /**
     * 荷物を削除するボタンが押下された際に呼び出されます。
     */
    onClickDeleteBaggage(baggageId: number): void {
        if (this.activeTabKey !== 'opened' || !this.BAGGAGE) return;

        this.$emit('deleteBaggage', baggageId);
    }

    /**
     * 荷物の印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrintBaggage(baggageId: number): Promise<void> {
        this.$emit('printBaggage', baggageId);
    }

    /**
     * 荷物の変更ボタンが押下された際に呼び出されます。
     */
    async onClickSubmitBaggageEdit(param: { id: number; form: baggageTypes.BaggageUpdateForm }): Promise<void> {
        // TODO emitへリファクタリング
        if (this.activeTabKey !== 'opened') {
            throw new Error('activeTabKey must be opened.');
        }

        const notifyFailedKey = 'UPDATE_BAGGAGE_FAILED';
        const onSuccess = async () => {
            this.$message.success('荷物情報を変更しました。');
            // KARTEイベント送信：荷物情報変更
            Karte.trackEditBaggage(param.id);
            await this.setFormDirty(false);
            this.$emit('reloadList');
            await this.$router.push({ path: this.$route.path, query: { baggageId: `${ param.id }` } });
        };
        const onError = () => {
            this.$notification.error({
                key: notifyFailedKey,
                message: '荷物情報の変更ができませんでした。',
                description: '入力内容をご確認のうえ、もう一度お試しください。',
            });
        };

        this.actionSubmitting = true;
        this.$notification.close(notifyFailedKey);

        await Promise.all([
                this.updateBaggage(param),
                this.registerStaffNameSuggestion({ staffName: param.form.staffName }),
            ])
            .then(onSuccess)
            .catch(onError)
            .finally(() => (this.actionSubmitting = false));
    }

    /**
     * フォームがdirty状態になった際に呼び出されます。
     * @param value
     */
    async onChangeFormDirty(value: boolean): Promise<void> {
        await this.setFormDirty(value);
    }

    /**
     * 成約確率の予測がリクエストされた際に呼び出されます。
     */
    async onRequestPrediction(value: PredictionBaggageAgreementForm): Promise<void> {
        this.$emit('requestPrediction', value);
    }

    /**
     * 商談リクエスト一覧のページネーションが変更された際に呼び出されます。
     */
    async onChangePage(pageNo: number, pageSize: number): Promise<void> {
        this.$emit('changeNegotiaionListPage', pageNo, pageSize);
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async closeDrawer(): Promise<void> {
        // TODO emitへリファクタリング
        await this.$router.push({ path: this.$route.path });
    }

    /**
     * 子ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseChildDrawer(): Promise<void> {
        // TODO emitへリファクタリング
        const query = _.omit(this.$route.query, 'edit');
        await this.$router.push({ path: this.$route.path, query });
    }

    /**
     * 「商談中にする」ボタンが押下された際に呼び出されます。
     */
    onClickMarkUnderNegotiation(baggageId: number): void {
        this.$emit('markUnderNegotiation', baggageId);
    }

    /**
     * 「商談中を解除」ボタンが押下された際に呼び出されます。
     */
    onClickUnmarkUnderNegotiation(baggageId: number): void {
        this.$emit('unmarkUnderNegotiation', baggageId);
    }

    // 空車情報タブでの利用
    /**
     * ツールチップ表示用の都道府県テキストラベル
     */
    prefectureTooltipText(
        prefectureLabel: string,
        city: string | undefined,
        additionalPrefecture: Array<Enum<PrefectureEnumCode>>
    ): string {
        const additionalPrefectureText = this.additionalPrefectureText(additionalPrefecture);
        if (!additionalPrefectureText) return '';
        return `${ prefectureLabel }${ city ?? '' }の他、${ additionalPrefectureText }`;
    }

    /**
     * 追加で設定された都道府県
     */
    additionalPrefectureText(additionalPrefecture: Array<Enum<PrefectureEnumCode>>): string {
        if (additionalPrefecture.length === 0) {
            return '';
        }
        const regions = RegionUtil.parseRegionsFromPrefectures(additionalPrefecture.map((each) => each.code));
        return `${ regions.map((each) => each.label).join('、') }も対応可能`;
    }

    /**
     * 運賃
     */
    freightText(freight?: number): string {
        if (!freight) {
            return '要相談';
        }
        return `${ Util.formatNumber(freight) }円`;
    }

    /**
     * 企業の連絡先電話番号
     */
    companyPhone(company?: CompanyProfile): string {
        return PhoneUtil.format(company?.phone.number ?? '');
    }

    formatNegotiationTime(tm: string): string {
        return new DateTimeValue(tm).format('M月DD日 H:mm');
    }

    /**
     * 連絡先ツールチップをクリックした際に呼び出されます。
     */
    onVisibleChangeContactsTooltip(visible: boolean, truckId: number): void {
        if (visible) {
            const id = truckId;
            const baggageId = Number(this.baggageId);
            Karte.trackClickTruckRecommendContact(id, baggageId);
            gtm.setEvent('open_truck_contacts', { id, recommend_baggage_id: baggageId });
        }
    }

    /**
     * 企業のリンクをクリックすると呼び出されます。
     */
    async onClickCompanyLink(companyId: number, truckId: number): Promise<void> {
        if (!this.baggageId) return;
        await router.push({
            path: this.$route.path,
            query: { baggageId: this.baggageId, companyId: `${ companyId }`, truckId: `${ truckId }` }
        });
    }

    /**
     * 商談リクエストの連絡先ツールチップをクリックした際に呼び出されます。
     * @param visible
     * @param negotiationId
     */
    onVisibleChangeNegotiationContactsTooltip(visible: boolean, truckCompanyId: number): void {
        if (visible) {
            const baggageId = Number(this.baggageId);
            Karte.trackClickNegotiationRequestContact(baggageId, truckCompanyId);
            gtm.setEvent('open_negotiation_contacts', { baggage_id: baggageId, truck_company_id: truckCompanyId });
        }
    }

    /**
     * 商談リクエスト企業のリンクをクリックすると呼び出されます。
     */
    async onClickNegotiationCompanyLink(companyId: number, negotiationId: number): Promise<void> {
        if (!this.baggageId) return;
        await router.push({
            path: this.$route.path,
            query: { baggageId: this.baggageId, negotiationCompanyId: `${ companyId }`, negotiationId: `${ negotiationId }` }
        });
    }
}
