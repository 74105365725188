export const columns = [
    {
        key: 'confirmationMemo',
        align: 'center',
        slots: { title: 'titleHeader' },
        scopedSlots: { customRender: 'confirmationMemo' },
        width: 78,
    },
    {
        title: '取引番号',
        key: 'settlementId',
        scopedSlots: { customRender: 'settlementId' },
        width: 130,
    },
    {
        title: '支払日',
        key: 'payerDate',
        scopedSlots: { customRender: 'payerDate' },
        width: 122,
    },
    {
        title: '状態',
        key: 'status',
        scopedSlots: { customRender: 'status' },
        width: 84,
        align: 'center',
    },
    {
        title: '運行',
        key: 'label',
        scopedSlots: { customRender: 'label' },
    },
    {
        title: '合計金額',
        key: 'totalAmount',
        align: 'left',
        scopedSlots: { customRender: 'totalAmount' },
        width: 152,
    },
    {
        title: '取引先名',
        key: 'companyName',
        scopedSlots: { customRender: 'companyName' },
    }
];
