import { ref } from 'vue';
import { companyApi } from '@/repository/api/internal/company';
import { BaggageList } from '@/models/baggage';

/**
 * 企業荷物をロードする機能を提供します。
 */
export const useCompanyBaggageList = () => {
    const list = ref<BaggageList | undefined>(undefined);
    const loading = ref<boolean>(false);

    const load = async (companyId: number) => {
        try {
            loading.value = true;
            const data = await companyApi.baggageList(companyId);
            list.value = data;
        } finally {
            loading.value = false;
        }
    };

    const clear = () => {
        list.value = undefined;
    };

    return {
        state: {
            list,
            loading,
        },
        load,
        clear,
    };
};
