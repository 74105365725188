import { accountApi } from '@/repository/api/internal/account';

/**
 * ログアウトする機能を提供します。
 */
export const useLogout = () => {
    const logout = async () => {
        await accountApi.logout();
    };

    return {
        logout,
    };
};
