import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';
import { Const } from '@/const';
import { AgreementForm } from '@/vuex/modules/agreement/types';

@Component
export default class AgreementBaggageTotalVolumeRegister extends Vue {
    @Prop()
    declare readonly value?: AgreementForm;

    get totalVolume(): string {
        return this.value?.totalVolume ?? '';
    }

    set totalVolume(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (newValue.length > 0) {
            cloned.totalVolume = isNaN(Util.toNumber(newValue)) ? '' : Util.toDigits(newValue);
        } else {
            cloned.totalVolume = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    validationRules: Array<ValidationRule> = [
        {
            pattern: /^[0-9]+$/,
            message: '荷物の体積は数字で入力してください。',
        },
        {
            max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
            message: `荷物の体積は最大${Const.MAX_BAGGAGE_VOLUME_DIGITS}桁で入力してください。`,
        },
    ];
}
