<script setup lang="ts">
import { useHomeHelper } from '@/_pages/Home/home-helper';
import PageLayout from '@/_components/PageLayout.vue';

const {
    activeLock,
    contactInfo,
} = useHomeHelper();

</script>

<template>
    <page-layout :show-header="false">
        <a-card v-if="activeLock" class="lock-message">
            <div v-if="activeLock.lockType.code === 'General'" class="lock-message--general">
                トラボックスから貴社へ連絡が取れないため、利用を制限しております。<br>
                お客様相談室までご連絡をお願いします。
            </div>
            <div v-if="activeLock.lockType.code === 'LatePayment'" class="lock-message--late-payment">
                トラボックスご利用料金未納のため利用を制限しております。<br>
                <strong>ご請求書を確認し、お支払いをお願いします。</strong><br>
                なお、入金の確認は<strong>「ご入金日の翌営業日」</strong>となります。<br>
                ご不明な点がございましたら、お客様相談室までご連絡をお願いします。
            </div>

            <div class="lock-message--contact">
                お客様相談室
                <a-descriptions :column="1" :colon="true">
                    <a-descriptions-item label="TEL">
                        <span>{{ contactInfo.phone }}（平日9:00-17:00）</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="mail">
                        <span>{{ contactInfo.email }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="web">
                        <router-link to="Inquiry">{{ contactInfo.inquiryUrl }}</router-link>
                    </a-descriptions-item>
                </a-descriptions>
            </div>
        </a-card>
    </page-layout>
</template>

<style scoped lang="less">
.lock-message {
    margin: 20px;
    padding: 20px;

    &--general, &--late-payment {
        text-align: center;
        line-height: 2.0;

        strong {
            color: @color-red;
            font-weight: 700;
        }
    }

    &--contact {
        width: fit-content;
        margin: 20px auto auto auto;

        > .ant-descriptions {

            :deep(table) {
                width: auto;

                td {
                    padding-bottom: 0;
                }
            }
        }
    }
}
</style>
