<script setup lang="ts">
import { useBaggageSearchExcludeCompany } from '@/composables/global/baggage-search-exlucde-company';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { computed } from 'vue';

const props = defineProps<{
    companyId?: number;
    companyName?: string;
}>();

const { state: { myProfile } } = useCompanyMyProfile();
const { add: addExcludedCompany } = useBaggageSearchExcludeCompany();

/**
 * 「この企業の荷物をすべて非表示にする」を表示すべきか否かを取得します。
 */
const shouldShowExcludeCompanyButton = computed<boolean>(() => {
    return myProfile.value !== undefined && props.companyId !== undefined && (myProfile.value.id !== props.companyId);
});

const onClickAddExcludedCompany = async () => {
    if (props.companyId && props.companyName) {
        addExcludedCompany({ id: props.companyId, name: props.companyName });
    }
};

</script>

<template>
    <div v-if="shouldShowExcludeCompanyButton" class="excluded-company">
        <a @click="onClickAddExcludedCompany">この企業の荷物を検索結果から除外する</a>
    </div>
</template>

<style scoped lang="less">
.excluded-company {
    margin-top: 16px;
    text-align: right;
}
</style>
