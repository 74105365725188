import { Environment } from '@/vuex/modules/environment/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const environmentApi = {
    /**
     * 環境情報を取得します。
     */
    load(): Promise<Environment> {
        return appAxios.get<JsonResponseBean<Environment>>('/api/environment').then(isSuccess);
    },
};
