import { Baggage, BaggageSearchFormModel, MaskedBaggage } from '@/models/baggage';
import { computed, onBeforeUnmount, Ref, ref } from 'vue';
import _ from 'lodash';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useKarte } from '@/composables/helper/karte-helper';
import { BaggageRow } from '@/composables/provider/baggage-search-provider';
import { SoundUtil, Util } from '@/util';
import { useRouting } from '@/composables/helper/routing';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

export const useBaggageAutoSearch = (form: Ref<BaggageSearchFormModel>, searchFn: (form: BaggageSearchFormModel) => Promise<BaggageRow[]>) => {
    const message = useMessage();
    const notification = useNotification();
    const { Karte } = useKarte();
    const { goToBaggageSearch } = useRouting();

    const NOTIFICATION_KEY = 'BAGGAGE_AUTO_SEARCH_PERMISSION';

    const id = ref<number | undefined>();
    const autoSearch = computed<boolean>(() => !_.isNil(id.value));

    const initNotification = async (): Promise<boolean> => {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            notifyFailed();
            return false;
        }

        return true;
    };
    const prepareStart = async (): Promise<boolean> => {
        if (!Notification) {
            return false;
        }
        return await initNotification();
    };

    const notifyFailed = () => {
        notification.info({
            key: NOTIFICATION_KEY,
            message: '通知の送信が許可されていないため自動検索できません。',
            description: 'ブラウザの設定で通知の送信を許可してください。',
        });
    };

    const notifyTerminate = () => {
        if (!id.value) return;
        message.info('自動検索を停止しました。');
    };

    const notifySearch = async (form: BaggageSearchFormModel, notify: boolean = true): Promise<void> => {
        const condition = _.cloneDeep(form);
        condition.pageNo = 1;
        if (!notify) {
            return;
        }

        const baggageList = await searchFn(condition);
        const newBaggageList = baggageList.filter(baggage => baggage.newFlg);
        if (newBaggageList.length === 0) {
            return;
        }
        newBaggageList.forEach(baggage => notifyNewBaggage(baggage));
        SoundUtil.playBell();
    };

    const notifyNewBaggage = (baggage: BaggageRow): void => {
        const onclickNotification = async (event: Event) => {
            event.preventDefault();
            await goToBaggageSearch(baggage.id).catch(() => {
                // すでに開いている場合があるのでcatch
            });
            window.focus();
            Karte.trackClickBaggageAutoSearchNotification(baggage.id);
        };
        const notification = new Notification(createNotification(baggage));
        notification.onclick = onclickNotification;

        Karte.trackReceiveBaggageAutoSearchNotification(baggage.id);
    };

    const start = async () => {
        if (!await prepareStart()) {
            return;
        }
        // スタート済みの場合はスキップ
        if (id.value) {
            return;
        }
        // 初回の検索
        // 切り替えボタンのアニメーションと検索結果の画面更新が重なると切り替えボタンの挙動がおかしくなる。
        // そのため、初回の検索を少し遅らせて処理が重ならないようにしています。
        setTimeout(async () => {
            await notifySearch(form.value, false);
        });
        // インターバルを開始して、キャンセル用IDをストアに保存する
        id.value = window.setInterval(async () => {
            await notifySearch(form.value);
        }, 10000);
        Karte.trackTurnOnBaggageAutoSearch();
    };

    const stop = (manual: boolean = true) => {
        if (id.value) {
            window.clearInterval(id.value);
        }
        id.value = undefined;
        if (manual) {
            Karte.trackTurnOffBaggageAutoSearch();
        }
    };

    onBeforeUnmount(() => {
        stop(false);
        notifyTerminate();
        notification.close(NOTIFICATION_KEY);
    });

    return {
        state: {
            autoSearch,
        },
        start,
        stop,
    };
};

const createNotification = (baggage: BaggageRow): string => {
    // 出発日
    const departureDate = toDepartureDate(baggage);
    // 出発/到着都道府県
    const prefecture = toPrefecture(baggage);
    // 運賃
    const freight = toFreight(baggage);

    return `${ departureDate }${ prefecture }${ freight }`;
};

/**
 * 荷物情報を出発日テキストへ変換します。
 */
const toDepartureDate = (baggage: Baggage | MaskedBaggage): string => {
    if (!baggage.departureMin || !baggage.departureMax) return '';
    const date = DeliveryDateTime.of(baggage.departureMin, baggage.departureMin)?.date?.format('M/D');
    return date ? `[${ date }]` : '';
};

/**
 * 荷物情報を出発/到着都道府県テキストへ変換します。
 */
const toPrefecture = (baggage: Baggage | MaskedBaggage): string => {
    const departure = baggage.departurePref?.label;
    const arrival = baggage.arrivalPref?.label;
    if (!departure || !arrival) return '';
    return `${ departure ?? '' }→${ arrival ?? '' }`;
};

/**
 * 荷物情報を運賃テキストへ変換します。
 */
const toFreight = (baggage: baggageTypes.Baggage | MaskedBaggage): string => {
    const freight = baggage.freight ? `${ Util.formatNumber(baggage.freight) }円` : '要相談';
    return `(${ freight })`;
};
