import { computed, onBeforeMount } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables';
import { useDeliveryOrderFind, useDeliveryOrderReply } from '@/composables/delivery-order';
import { useFormModel } from '@/composables/form-helper';
import { useModal } from '@/composables/helper/modal-helper';

export const useDeliveryOrderReplyHelper = () => {
    const { state: { loading: findLoading, deliveryOrder }, find: findDeliveryOrder } = useDeliveryOrderFind();
    const {
        state: {
            loading: replyLoading,
            form,
            dirty,
            validationRules
        },
        reply: replyDeliveryOrder,
        reviseReply,
        initForm
    } = useDeliveryOrderReply();
    const { submit, formModel } = useFormModel();
    const router = useRouter();
    const route = useRoute();
    const modal = useModal();
    const deliveryOrderId = Number(route.params.deliveryOrderId);

    const loading = computed(() => replyLoading.value || findLoading.value);

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 依頼書返信確認モーダルを表示します。
     */
    const confirmReply = (): Promise<boolean> => modal.confirm(
        '車番を送信してもよろしいですか？',
        '',
        '送信',
        'キャンセル'
    );

    const onClickBack = () => {
        router.go(-1);
    };

    const onSubmit = () => submit(async () => {
        if (!await confirmReply()) return;

        // 荷主が返信を確認済なら車番変更
        if (deliveryOrder.value?.status.isReplyRead) {
            // eslint-disable-next-line no-useless-catch
            try {
                await reviseReply(deliveryOrderId);
                router.go(-1);
            } catch (e) {
                throw e;
            }
            return;
        }

        // 未返信なら車番返信
        // eslint-disable-next-line no-useless-catch
        try {
            await replyDeliveryOrder(deliveryOrderId);
            router.go(-1);
        } catch (e) {
            throw e;
        }
    });

    const onClickCancel = () => {
        router.go(-1);
    };

    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');

    const initialize = async () => {
        await findDeliveryOrder(deliveryOrderId);
        if (deliveryOrder.value) {
            initForm(deliveryOrder.value);
        }
    };

    onBeforeMount(() => initialize());

    onBeforeRouteLeave((to, from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        form,
        formModel,
        dirty,
        validationRules,
        onClickBack,
        onSubmit,
        onClickCancel,
    };
};
