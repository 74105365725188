import { computed, ref } from 'vue';
import {
    Truck,
    TruckList,
    TruckSearchFormModel,
    TruckRegisterFormValidatableModel,
    TruckUpdateFormModel,
    TruckListForm,
    TruckExistsRecommendation,
    TruckExistsRecommendationForm
} from '@/models/truck';
import { FormValidateUtil } from '@/models/validate-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { truckApi } from '@/repository/api/internal/truck';
import _ from 'lodash';
import { useNotification } from '@/composables/helper/page-helper';
import { Const } from '@/const';
import { CompanyTruck } from '@/models/company-truck';
import { AgreementModel } from '@/models/agreement';

export const useMyTruck = () => {
    const { state: { loading, }, withLoading } = useLoading();
    const truck = ref<Truck | undefined>();
    const load = (truckId: number) => withLoading(async() => {
        truck.value = await truckApi.findMyTruck(truckId);
    });

    return {
        state: {
            loading,
            truck,
        },
        load,
    };
};

export const useMyTruckList = () => {
    const { state: { loading, }, withLoading } = useLoading();
    const list = ref<TruckList | undefined>();
    const form = ref<TruckListForm>({ pageNo: 1, pageSize: Const.DEFAULT_PAGE_SIZE });

    const load = (pageNo: number, pageSize: number) => withLoading(async () => {
        form.value = { pageNo, pageSize };
        list.value = await truckApi.listTruck({ pageNo, pageSize });
    });

    return {
        state: {
            loading,
            list,
            form,
        },
        load,
    };
};

export const useMyTruckExistsRecommendations = () => {
    const { state: { loading, }, withLoading } = useLoading();
    const list = ref<TruckExistsRecommendation[] | undefined>();
    const form = ref<TruckExistsRecommendationForm>({ ids: [] });

    const load = (ids: number[]) => withLoading(async () => {
        form.value = { ids: ids };
        list.value = await truckApi.existsRecommendations(form.value);
    });

    return {
        state: {
            loading,
            list,
            form,
        },
        load,
    };
};

export const useMyTruckRegister = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_TRUCK_FAILED';
    /**
     * 空車登録失敗を通知します。
     */
    const notifyFailedToRegister = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
        message: '空車情報を登録できませんでした。',
        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 空車登録失敗通知を閉じます。
     */
    const closeFailedToRegisterNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

    const form = ref<TruckRegisterFormValidatableModel>(new TruckRegisterFormValidatableModel());
    const sourceForm = ref<TruckRegisterFormValidatableModel>(new TruckRegisterFormValidatableModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (truck: Truck) => {
        form.value = TruckRegisterFormValidatableModel.of(truck);
    };

    const initFormFromCompanyTruck = (companyTruck: CompanyTruck) => {
        form.value = TruckRegisterFormValidatableModel.initializeFromCompanyTruck(companyTruck);
    };

    const initFormFromAgreement = (agreement: AgreementModel) => {
        form.value = TruckRegisterFormValidatableModel.initializeFromAgreement(agreement);
    };

    const initCircleId = (circleId: number | undefined) => {
        form.value.circleId = circleId;
        sourceForm.value.circleId = circleId;
    };

    const initStaffName = (staffName: string) => {
        form.value.staffName = staffName;
        sourceForm.value.staffName = staffName;
    };

    const register = async (): Promise<number> => {
        closeFailedToRegisterNotification();
        try {
            const truckId = await truckApi.registerTruck(form.value);
            sourceForm.value = _.cloneDeep(form.value);
            return truckId;
        } catch (e) {
            notifyFailedToRegister();
            throw e;
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        initFormFromCompanyTruck,
        initFormFromAgreement,
        register,
        initStaffName,
        initCircleId
    };
};

export const useMyTruckUpdate = () => {
    const truckId = ref<number | undefined>(undefined);
    const form = ref<TruckUpdateFormModel>(new TruckUpdateFormModel());
    const sourceForm = ref<TruckUpdateFormModel>(new TruckUpdateFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (truck: Truck) => {
        truckId.value = truck.id;
        form.value = TruckUpdateFormModel.of(truck);
        sourceForm.value = _.cloneDeep(form.value);
    };

    const update = async () => {
        if (truckId.value) {
            await truckApi.updateTruck(truckId.value, form.value.toForm());
            sourceForm.value = _.cloneDeep(form.value);
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        update,
    };
};

export const useMyTruckClose = () => {
    const notification = useNotification();
    const notifyFailedKey = 'DELETE_TRUCK_FAILED';

    const { state: { loading, }, withLoading } = useLoading();

    const close = (id: number) => withLoading(async () => {
        notification.close(notifyFailedKey);
        await truckApi.closeTruck(id).catch(() => {
            notification.error({
                key: notifyFailedKey,
                message: `空車情報を削除できませんでした。`,
                description: `時間をおいて再度お試しください。`,
            });
        });
    });

    return {
        state: {
            loading
        },
        close
    };
};

export const useTruckSearch = () => {
    const { state: { loading, }, withLoading } = useLoading();
    const list = ref<TruckList | undefined>();
    const form = ref<TruckSearchFormModel>(new TruckSearchFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const search = (pageNo: number, pageSize: number, sortKey: string, sortOrder: 'ASC' | 'DESC') => withLoading(async() => {
        form.value.pageNo = pageNo;
        form.value.pageSize = pageSize;
        form.value.sortKey = sortKey;
        form.value.sortOrder = sortOrder;
        list.value = await truckApi.searchTruck(form.value);
    });

    const clearForm = () => form.value = new TruckSearchFormModel({
        pageNo: form.value.pageNo,
        pageSize: form.value.pageSize,
        sortKey: form.value.sortKey,
        sortOrder: form.value.sortOrder
    });

    return {
        state: {
            loading,
            list,
            form,
            formValidateRules,
        },
        search,
        clearForm,
    };
};
