import { Profile } from '@/models/account';
import { AccountProfileModel } from '@/models/account-profile';

export class AccountProfileListModel {
    accounts: AccountProfileModel[];

    constructor(data: Profile[]) {
        this.accounts = data.map((each) => new AccountProfileModel(each));
    }
}
