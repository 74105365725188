import { SelectOption } from '@/types/ant-design';
import { Const } from '@/const';
import { BankAccountTypeEnum } from '@/enums/bank-account-type.enum';
import { PrefectureEnum } from '@/enums/prefecture.enum';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum } from '@/enums/baggage-handling-type.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { TruckHeightEnum } from '@/enums/truck-height.enum';
import { TruckWidthEnum } from '@/enums/truck-width.enum';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';
import { NegotiationReportStatusEnum } from '@/enums/negotiation-report-status.enum';
import { BaggageTemperatureZoneEnum } from '@/enums/baggage-temperature-zone.enum';

const labelCodeToOptions = <T>(enums: { label: string, code: T }[]): SelectOption<T>[] => {
    return enums.map((each) => { return { value: each.code, label: each.label, key: each.code }; });
};

export const useEmployeeNumbersOptions = () => {
    return {
        options: labelCodeToOptions<string>(Const.employeeNumbers),
    };
};

export const useAccountingDayOptions = () => {
    return {
        options: labelCodeToOptions<number>(Const.accountingDay),
    };
};

export const useAccountingMonthOptions = () => {
    return {
        options: labelCodeToOptions<number>(Const.accountingMonth),
    };
};

export const useBankAccountTypeOptions = () => {
    return {
        options: labelCodeToOptions(BankAccountTypeEnum.values),
    };
};

export const usePrefectureOptions = () => {
    return {
        options: labelCodeToOptions(PrefectureEnum.values),
    };
};

export const useBaggageShapeOptions = () => {
    return {
        options: labelCodeToOptions(BaggageShapeEnum.values),
    };
};

export const useBaggageLoadingOptions = () => {
    return {
        options: BaggageHandlingTypeEnum.values.map((each) => ({
            value: each.code,
            label: each.getLabel(true),
            key: each.code,
        })),
    };
};

export const useBaggageUnloadingOptions = () => {
    return {
        options: BaggageHandlingTypeEnum.values.map((each) => ({
            value: each.code,
            label: each.getLabel(false),
            key: each.code,
        })),
    };
};

export const useBaggageTemperatureZoneOptions = () => {
    return {
        options: labelCodeToOptions(BaggageTemperatureZoneEnum.registrableValuesForBaggage),
    };
};

export const useTruckWeightRegistrableOptions = () => {
    return {
        options: labelCodeToOptions(TruckWeightEnum.registrableValuesForBaggage),
    };
};

export const useTruckModelRegistrableOptions = () => {
    return {
        options: labelCodeToOptions(TruckModelEnum.registrableValuesForBaggage),
    };
};

export const useTruckHeightForBaggageOptions = () => {
    return {
        options: labelCodeToOptions(TruckHeightEnum.registrableValuesForBaggage),
    };
};

export const useTruckWidthForBaggageOptions = () => {
    return {
        options: labelCodeToOptions(TruckWidthEnum.registrableValuesForBaggage),
    };
};

export const useBaggageLargeTruckFlgOptions = () => {
    return {
        options: [
            {
                value: 'true',
                label: '可',
            },
            {
                value: 'false',
                label: '不可',
            }
        ]
    };
};

export const useBaggageHighwayFareOptions = () => {
    return {
        options: [
            { label: '別途支払う', value: true },
            { label: '支払わない', value: false },
        ]
    };
};

export const useNegotiationTypeOptions = () => {
    return {
        options: labelCodeToOptions(NegotiationTypeEnum.values),
    };
};

export const useNegotiationReportStatusOptions = () => {
    return {
        options: labelCodeToOptions(NegotiationReportStatusEnum.values)
    };
};

export const useProxyOptions = () => {
    return {
        options: [
            { value: true, label: '対象' },
            { value: false, label: '対象外' }
        ]
    };
};
