import { TbxRouteConfig } from '@/router/routes/types';
import UnSubscribePage from '@/pages/UnSubscribe/Index/index.vue';
import _UnSubscribePage from '@/_pages/UnSubscribe/Index/UnSubscribeIndex.vue';
import UnSubscribeEditPage from '@/pages/UnSubscribe/Edit/index.vue';
import _UnSubscribeEditPage from '@/_pages/UnSubscribe/Edit/UnSubscribeEdit.vue';
import UnSubscribeCompletePage from '@/pages/UnSubscribe/Complete/index.vue';
import _UnSubscribeCompletePage from '@/_pages/UnSubscribe/Complete/UnSubscribeComplete.vue';

export const unsubRoutes: TbxRouteConfig[] = [
    {
        path: '/unsub',
        name: 'Unsubscribe',
        component: _UnSubscribePage,
        backComponent: UnSubscribePage,
        meta: { title: '退会' },
    },
    {
        path: '/unsub/edit',
        name: 'UnsubscribeEdit',
        component: _UnSubscribeEditPage,
        backComponent: UnSubscribeEditPage,
        meta: { title: '退会' },
    },
    {
        path: '/unsub/complete',
        name: 'UnsubscribeComplete',
        component: _UnSubscribeCompletePage,
        backComponent: UnSubscribeCompletePage,
        meta: { title: '退会手続きが完了しました', layout: { name: 'blank' } },
    },
];
