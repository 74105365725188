import { Baggage } from '@/models/baggage';

export const goToTraboxBaggageRegisterPage = (baggage: Baggage, userName: string, ultraboxUrl: string): void => {
    const query = generateTraboxBaggageRegisterQuery(baggage, userName);
    window.open(`${ultraboxUrl}/baggage/register${query}`, '_blank');
};

const generateTraboxBaggageRegisterQuery = (baggage: Baggage, userName: string) => {
    return (
        `?parser=1` +
        `&arrivalAddress=${baggage.arrivalAddress ?? ''}` +
        `&arrivalCity=${baggage.arrivalCity}` +
        `&arrivalMax=${baggage.arrivalMax}` +
        `&arrivalMin=${baggage.arrivalMin}` +
        `&arrivalPref=${baggage.arrivalPref.code ?? ''}` +
        `&category=${baggage.category.code}` +
        `&departureAddress=${baggage.departureAddress ?? ''}` +
        `&departureCity=${baggage.departureCity}` +
        `&departureMax=${baggage.departureMax}` +
        `&departureMin=${baggage.departureMin}` +
        `&departurePref=${baggage.departurePref.code ?? ''}` +
        `&description=${baggage.description ?? ''}` +
        `&express=${baggage.express ? 'T' : 'F'}` +
        `&freight=${baggage.freight ?? ''}` +
        `&highwayFareFlg=${baggage.highwayFareFlg ? 'T' : 'F'}` +
        `&largeTruckFlg=${baggage.largeTruckFlg ? 'T' : 'F'}` +
        `&loading=${baggage.loading?.code ?? ''}` +
        `&loadingTimeNote=${baggage.loadingTimeNote ?? ''}` +
        `&negotiationType=TEL` +
        `&paletteCount=${baggage.paletteCount ?? ''}` +
        `&paletteHeight=${baggage.paletteHeight ?? ''}` +
        `&paletteWidth=${baggage.paletteWidth ?? ''}` +
        `&paymentDate=${baggage.paymentDate ?? ''}` +
        `&shape=${baggage.shape.code}` +
        `&share=${baggage.share ? 'T' : 'F'}` +
        `&staffName=${userName}` +
        `&totalCount=${baggage.totalCount ?? ''}` +
        `&totalVolume=${baggage.totalVolume ?? ''}` +
        `&totalWeight=${baggage.totalWeight ?? ''}` +
        `&truckEquipment=${baggage.truckEquipment ?? ''}` +
        `&truckHeight=${baggage.truckHeight?.code ?? ''}` +
        `&truckModel=${baggage.truckModel.code}` +
        `&truckWeight=${baggage.truckWeight.code}` +
        `&truckWidth=${baggage.truckWidth?.code ?? ''}` +
        `&type=${baggage.type}` +
        `&unloading=${baggage.unloading?.code ?? ''}` +
        `&unloadingTimeNote=${baggage.unloadingTimeNote ?? ''}`
    );
};
