import { computed, onBeforeMount } from 'vue';
import { Const } from '@/const';
import { useMyTruckClose, useMyTruckList } from '@/composables/truck';
import { TruckModel } from '@/models/truck';
import { usePagination } from '@/composables/global/pagination';
import { useRouting } from '@/composables/helper/routing';
import { Modal } from 'ant-design-vue';
import { ModalUtil, PageUtil } from '@/util';
import { useComponentRef, useMessage } from '@/composables/helper/page-helper';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

export const useTruckListHelper = () => {
    const message = useMessage();
    const { goToTruckRegister, goToTruckEdit } = useRouting();
    const { state: { pageSize } } = usePagination();
    const { state: { loading: loadingList, list, form }, load } = useMyTruckList();
    const { state: { loading: loadingClose }, close } = useMyTruckClose();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const loading = computed(() => loadingList.value || loadingClose.value);
    const pageInfo = computed(() => {
        return {
            totalPageCount: list.value?.totalPageCount ?? 0,
            totalRecordCount: list.value?.totalRecordCount ?? 0,
            currentPage: list.value?.currentPageNumber ?? 1,
            currentPageSize: form.value.pageSize,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });
    const truckListData = computed<TruckModel[]>(() => {
        return list.value?.data?.map(each => new TruckModel(each)) ?? [];
    });

    const { component: searchResultsRef, el } = useComponentRef();
    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    const scrollToSearchResults = () => {
        // TODO useElementScroll()に移行
        PageUtil.scrollToContentTop(el.value?.offsetTop);
    };

    const circleName = (circleId?: number) => {
        return myProfile.value?.circles?.find(each => each.id === circleId)?.name ?? '';
    };

    const onClickEdit = async (id: number) => {
        await goToTruckEdit(id);
    };
    const onClickCopy = async (id: number) => {
        await goToTruckRegister(id);
    };
    const onClickClose = async (id: number) => {
        Modal.confirm({
            title: `選択した空車情報を本当に削除しますか？`,
            content: 'この操作は元に戻すことができません。ご注意ください。',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                await close(id).then(() => {
                    message.success('登録した空車情報を削除しました。');
                });

                await load(pageInfo.value.currentPage, pageInfo.value.currentPageSize);
            },
        });
    };

    const onChangePage = async (param: { pageNo: number, pageSize: number }) => {
        await load(param.pageNo, param.pageSize);
        scrollToSearchResults();
        pageSize.value = param.pageSize;
    };

    onBeforeMount(async () => {
        const initialPageSize = pageSize.value ?? Const.DEFAULT_PAGE_SIZE;
        await Promise.all([
            loadMyProfile(),
            load(1, initialPageSize)
        ]);
    });

    return {
        loading,
        pageInfo,
        searchResultsRef,
        truckListData,
        circleName,
        onClickEdit,
        onClickCopy,
        onClickClose,
        onChangePage,
    };
};
