import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { ProductUtil } from '@/util';
import { BillingCycleEnum, InvoiceEnum } from '@/components/UpgradePlan/View/enums';
import * as types from '@/vuex/modules/company/types';
// @ts-ignore
import UpgradePlanContainer from '@/components/UpgradePlan/Container';
// @ts-ignore
import PlanOverview from '@/components/UpgradePlan/View/Overview';

@Component({ components: { PlanOverview, UpgradePlanContainer } })
export default class UpgradePlan extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly isMainAccount?: boolean;
    @Prop()
    declare readonly hasPremiumPlan?: boolean;
    @Prop()
    declare readonly isSubmitting?: boolean;
    @Prop()
    declare readonly value?: types.CompanyPremiumRegisterForm;

    /**
     * ページのコンテンツ種類を取得します。
     * DIRECT: 設定ページ→プレミアムプラン申し込み
     * ACCESS_PREMIUM_PAGE: PlanGuardによってプレミアムプラン申し込みページへ強制遷移された場合。（＝クエリパラメーターが付加されている場合）
     */
    get pageViaType(): 'DIRECT' | 'ACCESS_PREMIUM_PAGE' {
        return this.$route.query.type === 'access-premium-page' ? 'ACCESS_PREMIUM_PAGE' : 'DIRECT';
    }

    /**
     * 請求期間
     */
    get plan(): string {
        return this.value?.plan.code ?? '';
    }

    set plan(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.plan = { code: newValue };

        // 請求期間が切り替わった場合は、請求書送付をリセット
        cloned.invoice = {
            code: {
                [BillingCycleEnum.Monthly.code]: InvoiceEnum.Free,
                [BillingCycleEnum.HalfYearly.code]: InvoiceEnum.PostForFree,
                [BillingCycleEnum.Annually.code]: InvoiceEnum.PostForFree,
            }[newValue].code,
        };

        this.$emit('input', cloned);
    }

    /**
     * 請求書送付
     */
    get invoice(): string {
        return this.value?.invoice.code ?? '';
    }

    set invoice(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.invoice = { code: newValue };

        this.$emit('input', cloned);
    }

    /**
     * 請求期間の選択肢
     */
    get planChoice(): BillingCycleEnum[] {
        return BillingCycleEnum.values;
    }

    /**
     * 請求期間で月払いが選択されているか否かを判定します。
     */
    get isMonthly(): boolean {
        return this.plan === ProductUtil.Premium1m.code;
    }

    get isPost(): boolean {
        return this.invoice == ProductUtil.InvoicePost.code;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 請求書郵送のチェックボックスを変更すると呼び出されます
     */
    onChangeInvoice(): void {
        if (this.isPost) {
            this.invoice = ProductUtil.InvoiceFree.code;
        } else {
            this.invoice = ProductUtil.InvoicePost.code;
        }
    }

    /**
     * お申し込みボタンをクリックすると呼び出されます
     */
    onClickRegister(): void {
        this.$emit('submit');
    }
}
