import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

@Component
export default class BaggageArrival extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get arrival(): string {
        if (!this.baggage) return '';

        const min = this.baggage.arrivalMin;
        const max = this.baggage.arrivalMax;
        if (!min || !max) return '';
        return DeliveryDateTime.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
    }
}
