import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { BillingCycleEnum, InvoiceEnum } from '@/components/UpgradePlan/View/enums';
import { ProductUtil, Util } from '@/util';

@Component
export default class UpgradePlanConfirmModalContent extends Vue {
    @Prop()
    declare readonly form?: types.CompanyPremiumRegisterForm;

    /**
     * プレミアムプラン
     */
    get premiumPlan(): BillingCycleEnum | undefined {
        if (this.form?.plan.code === undefined || !ProductUtil.isPremium(this.form.plan.code)) {
            return undefined;
        }
        return BillingCycleEnum.valueOf(this.form.plan.code);
    }

    /**
     * 請求方法
     */
    get invoice(): InvoiceEnum | undefined {
        if (this.form?.invoice.code === undefined || !ProductUtil.isInvoice(this.form.invoice.code)) {
            return undefined;
        }
        return InvoiceEnum.valueOf(this.form.invoice.code);
    }

    /**
     * 請求期間
     */
    get billingPeriod(): string | undefined {
        return this.premiumPlan?.label;
    }

    /**
     * 請求書送付方法
     */
    get invoiceType(): string | undefined {
        const invoice = this.invoice;
        if (invoice === undefined) {
            return undefined;
        }
        switch (invoice.code) {
            case 'INVOICE_FAX':
                return undefined;
            case 'INVOICE_POST':
            case 'INVOICE_POST_FOR_FREE':
                return '郵送する';
            case 'INVOICE_FAX_POST':
                return undefined;
            case 'INVOICE_FREE':
                return '郵送しない';
            case 'INVOICE_NTT':
                return undefined;
        }
    }

    /**
     * プレミアムプラン料金
     */
    get planFee(): string | undefined {
        const plan = this.premiumPlan;
        if (plan === undefined) {
            return undefined;
        }
        const price = `${Util.formatNumber(plan.price)}円`;
        switch (plan.code) {
            case 'PREMIUM_1M':
                return `${price}/月`;
            default:
                return price;
        }
    }

    /**
     * 請求手数料
     */
    get invoiceFee(): string | undefined {
        const invoice = this.invoice;
        if (invoice === undefined) {
            return undefined;
        }
        return invoice.billingFee > 0 ? `${Util.formatNumber(invoice.billingFee)}円` : '無料';
    }

    /**
     * 合計金額
     */
    get totalFee(): string | undefined {
        const plan = this.premiumPlan;
        const invoice = this.invoice;
        if (plan === undefined || invoice === undefined) {
            return undefined;
        }
        return `${Util.formatNumber(plan.price + invoice.billingFee)}円`;
    }
}
