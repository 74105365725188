import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import { DateUtil, Util } from '@/util';
import { InvoiceCode, PremiumPlanCode } from '@/enums/product.enum';
import { BillingCycleEnum, InvoiceEnum } from '@/components/UpgradePlan/View/enums';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: {
        TbxLinkText,
    },
})
export default class PlanOverview extends Vue {
    @Prop()
    declare readonly plan: PremiumPlanCode;
    @Prop()
    declare readonly invoice: InvoiceCode;
    pricingPageUrl = Const.externalPageUrl.pricing;

    /**
     * ご利用プランを{@link BillingCycleEnum}として取得します。
     */
    get planAsEnum(): BillingCycleEnum {
        const plan = BillingCycleEnum.valueOf(this.plan);

        if (!plan) throw Error(`Invalid plan code => ${this.plan}`);

        return plan;
    }

    /**
     * 請求手数料を{@link InvoiceEnum}として取得します。
     */
    get invoiceAsEnum(): InvoiceEnum {
        const invoice = InvoiceEnum.valueOf(this.invoice);

        if (!invoice) throw Error(`Invalid invoice code => ${this.invoice}`);

        return invoice;
    }

    /**
     * 開始日
     */
    get startDate(): string {
        return DateUtil.now().format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    /**
     * 終了日
     */
    get endDate(): string {
        return this.planAsEnum.calculateEndDate().format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    /**
     * 期間
     */
    get periodMonth(): number {
        return this.planAsEnum.month;
    }

    /**
     * ご利用料金
     */
    get planFee(): string {
        return `${Util.formatNumber(this.planAsEnum.price)}円`;
    }

    /**
     * 請求手数料
     */
    get billingFee(): string {
        return this.invoiceAsEnum.billingFee > 0 ? `${Util.formatNumber(this.invoiceAsEnum.billingFee)}円` : '無料';
    }

    /**
     * 合計金額
     */
    get totalFee(): string {
        return `${Util.formatNumber(this.planAsEnum.price + this.invoiceAsEnum.billingFee)}円`;
    }

    /**
     * 支払い期日
     */
    get paymentDueDate(): string {
        return this.planAsEnum.calculatePaymentDueDate().format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    /**
     * 請求期間で月払いが選択されているか否かを判定します。
     */
    get isMonthly(): boolean {
        return this.plan === BillingCycleEnum.Monthly.code;
    }
}
