<script setup lang="ts">
import { useBaggageLoadingOptions, useBaggageUnloadingOptions } from '@/composables/option-helper';
import { BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { computed } from 'vue';

const props = defineProps<{
    value?: BaggageHandlingTypeEnumCode
    type: 'loading' | 'unloading';
}>();

const emit = defineEmits<{
    (e: 'input', value: BaggageHandlingTypeEnumCode | undefined): void;
}>();

const { options: loadingOptions } = useBaggageLoadingOptions();
const { options: unloadingOptions } = useBaggageUnloadingOptions();
const operations = computed(() => props.type === 'loading' ? loadingOptions : unloadingOptions);

const label = computed(() => props.type === 'loading' ? '積み' : '卸し');
const selectedValue = computed({
    get: () => props.value,
    set: (value) => {
        emit('input', value);
    }
});

</script>

<template>
    <span class="ant-input-group ant-input-group-compact">
        <span class="handling-type-label" style="width: 55px;">{{ label }}</span>
            <a-select v-model="selectedValue"
                      style="width: 110px;"
                      :placeholder="label"
                      :options="operations"
                      :allowClear="true"/>
     </span>
</template>

<style scoped lang="less">

.handling-type-label {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    padding: 4px 11px 0 11px;
    height: 32px;
}

</style>
