import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { AgreementUtil, DateUtil } from '@/util';
import { Const } from '@/const';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';

@Component
export default class AgreementPaymentDate extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;
    @Prop()
    declare readonly iAmTransporter?: boolean;

    get paymentDate(): string {
        if (!this.agreement) return '';
        return DateUtil.parseDateText(this.agreement.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }

    get paymentDateCr(): string {
        if (!this.agreementCr) return '';
        return DateUtil.parseDateText(this.agreementCr.paymentDate).format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }

    private get arrival(): string {
        if (!this.agreement) return '';
        return DeliveryDateTime.of(this.agreement.arrivalMin, this.agreement.arrivalMax)?.format() ?? '';
    }

    private get arrivalCr(): string {
        if (!this.agreementCr) return '';
        return DeliveryDateTime.of(this.agreementCr.arrivalMin, this.agreementCr.arrivalMax)?.format() ?? '';
    }

    get changeRequest(): string {
        return this.paymentDate != this.paymentDateCr || this.arrival != this.arrivalCr ? this.paymentDateCr : '';
    }

    get shouldEmphasizeChangeRequest(): boolean {
        return this.paymentDate !== this.paymentDateCr;
    }

    /**
     * 入金予定日が運賃全額保証期間外であるか否かを取得します。
     */
    get isPaymentDateOutOfGuaranteePeriod(): boolean {
        if (this.agreement && this.agreement.guarantee) {
            const arrivalDate = DateUtil.parseDatetimeText(this.agreement.arrivalMax);
            const paymentDate = DateUtil.parseDateText(this.agreement.paymentDate);
            const range = AgreementUtil.availablePaymentDateRange(arrivalDate, true);
            return !DateUtil.isIncluded(paymentDate, range);
        }
        return false;
    }

    /**
     * 変更後の入金予定日が運賃全額保証期間外であるか否かを取得します。
     */
    get isChangePaymentDateOutOfGuaranteePeriod(): boolean {
        if (this.agreementCr && this.agreement && this.agreement.guarantee) {
            const arrivalDate = DateUtil.parseDatetimeText(this.agreementCr.arrivalMax ?? this.agreement.arrivalMax);
            const paymentDate = DateUtil.parseDateText(this.agreementCr.paymentDate ?? this.agreement.paymentDate);
            const range = AgreementUtil.availablePaymentDateRange(arrivalDate, true);
            return !DateUtil.isIncluded(paymentDate, range);
        }
        return false;
    }
}
