import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { CompanyPaymentMethod } from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import { namespace } from 'vuex-class';
import { DateUtil, GuaranteeUtil, Util } from '@/util';
import store from '@/vuex/store';
import { Const } from '@/const';
// @ts-ignore
import GuaranteeConfirmModalContent from '@/components/Common/Guarantee/ConfirmModalContent';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { Enum } from '@/types/enum';
import { Karte } from '@/karte';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

const accountMod = namespace('account');
const companyMod = namespace('company');
const guaranteeMod = namespace('guarantee');

@Component({
    title: '運賃全額保証サービス',
    components: {
        TbxLinkText,
        GuaranteeConfirmModalContent,
    },
    beforeRouteEnter: MiscServiceGuaranteePage.beforeRouteEnter,
    beforeRouteLeave: MiscServiceGuaranteePage.beforeRouteLeave,
})
export default class MiscServiceGuaranteePage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @companyMod.Getter(companyTypes.GETTER.PAYMENT)
    readonly PAYMENT?: companyTypes.CompanyPayment;
    @companyMod.Getter(companyTypes.GETTER.GUARANTEED_AMOUNT)
    readonly GUARANTEED_AMOUNT?: companyTypes.CompanyGuaranteedAmount;
    @guaranteeMod.Getter(guaranteeTypes.GETTER.GUARANTEE_PRICE_MASTER)
    readonly GUARANTEE_PRICE_MASTER?: guaranteeTypes.GuaranteePriceMaster[];

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 契約一覧
     */
    get contracts(): companyTypes.CompanyContract[] {
        return this.CONTRACT_LIST?.contracts ?? [];
    }

    /**
     * 運賃全額保証に加入済みか否か
     */
    get existsGuarantee(): boolean {
        return this.CONTRACT_LIST?.existsGuarantee ?? false;
    }

    /**
     * 支払い方法
     */
    get paymentMethod(): Enum<CompanyPaymentMethod> | undefined {
        return this.PAYMENT?.method;
    }

    /**
     * 今月これまでの保証対象累計額
     */
    get usedGuaranteedAmountText(): string | undefined {
        if (!this.GUARANTEED_AMOUNT) {
            return undefined;
        }
        return Util.formatNumber(this.GUARANTEED_AMOUNT.amount);
    }

    /**
     * 今月これまでの運賃保証サービス料
     */
    get usedServiceFeeText(): string | undefined {
        if (!this.GUARANTEED_AMOUNT || !this.GUARANTEE_PRICE_MASTER || !this.CONTRACT_LIST) {
            return undefined;
        }
        const price = GuaranteeUtil.calculateGuaranteePrice(
            this.GUARANTEED_AMOUNT.amount,
            this.GUARANTEE_PRICE_MASTER,
            this.CONTRACT_LIST.contracts
        );
        return Util.formatNumber(price);
    }

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    guaranteePageUrl = Const.externalPageUrl.guarantee;
    visibleConfirmModal = false;

    /**
     * 申し込み確認事項を確認済か否か
     */
    confirmedTerms = false;

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        // 支払い情報をロード。支払い情報のデータは確認モーダル内でしか使わないため、意図的にmountedでロードしている。
        MiscServiceGuaranteePage.loadCompanyPayment();
    }

    /**
     * 便利サービストップに戻るボタンを押下された際に呼び出されます。
     */
    async onClickBackToMiscTop(): Promise<void> {
        await this.$router.push({ name: 'MiscService' });
    }

    /**
     * 運賃全額保証サービスのお申し込みボタンが押下された際に呼び出されます。
     */
    async onClickRegister(): Promise<void> {
        if (!this.ACCOUNT_PROFILE?.isMainAccount) {
            return;
        }
        // 申し込み前確認モーダルを開きます。
        this.confirmedTerms = false;
        this.visibleConfirmModal = true;
    }

    /**
     * 運賃全額保証サービスの申し込み確認事項の確認on/offイベントをハンドリングします。
     * @param confiemed
     */
    onChangeConfirmed(confiemed: boolean): void {
        this.confirmedTerms = confiemed;
    }

    /**
     * 運賃全額保証サービスを申し込みます。
     */
    registerGuarantee(): Promise<void> {
        const notifyKey = 'REGISTER_GUARANTEE';
        this.$notification.close(notifyKey);
        this.loading = true;
        return (
            MiscServiceGuaranteePage.registerGuarantee()
                // TODO revise
                .then(() => new Promise((resolve) => setTimeout(resolve, 500)))
                .then(() => {
                    this.$notification.success({
                        key: notifyKey,
                        message: '運賃全額保証サービスの申し込み手続きが完了しました。',
                        description:
                            'これより運賃全額保証サービスをご利用いただけます。お申し込みありがとうございました。',
                    });
                    // KARTEイベント送信： 運賃保証申し込み
                    Karte.trackApplyGuaranteeService();
                })
                .then(() => MiscServiceGuaranteePage.loadContractList())
                .catch(() => {
                    this.$notification.error({
                        key: notifyKey,
                        message: '運賃全額保証サービスの申し込み手続きができませんでした。',
                        description:
                            '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.visibleConfirmModal = false;
                })
        );
    }

    /**
     * 契約一覧をロードします。
     */
    private static loadContractList(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_CONTRACTS}`);
    }

    /**
     * 支払情報をロードします。
     */
    private static loadCompanyPayment(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_PAYMENT}`);
    }

    /**
     * 運賃全額保証利用料金マスタをロードします。
     */
    private static loadGuaranteePriceMaster(): Promise<void> {
        return store.dispatch(`guarantee/${guaranteeTypes.ACTION.LOAD_GUARANTEE_PRICE_MASTER}`);
    }

    /**
     * 今月の運賃保証サービスの月間成約金額をロードします。
     */
    private static loadGuaranteedAmount(): Promise<void> {
        const today = DateUtil.now();
        const year = today.year();
        const month = today.month() + 1;
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_GUARANTEED_AMOUNT}`, { year, month });
    }

    /**
     * 運賃全額保証サービスに申込みます。
     */
    private static registerGuarantee(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.REGISTER_GUARANTEE}`);
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<MiscServiceGuaranteePage>
    ): Promise<void> {
        Promise.all([
            MiscServiceGuaranteePage.loadGuaranteePriceMaster(),
            MiscServiceGuaranteePage.loadGuaranteedAmount(),
        ])
            .then(() => next())
            .catch(() => next({ name: 'NotFound' }));
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<MiscServiceGuaranteePage>
    ): Promise<void> {
        next();
    }
}
