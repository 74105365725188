import { BaggageSearchForm } from '@/vuex/modules/baggage/types';
import {
    convertBaggageSearchForm,
    KarteBaggageSearchForm,
    KartePredictionBaggageAgreementForm,
    KarteShowAgreementPageForm,
    KarteTruckRecommendResult
} from '@/karte.types';
import { PredictionBaggageAgreementForm } from '@/vuex/modules/prediction/form';

export class Karte {
    /**
     * KARTEに「荷物登録」イベントを送信します
     * @param baggageId 登録した荷物ID(複数荷物登録の場合は最初の荷物ID)
     */
    static trackRegisterBaggage(baggageId: number): void {
        this.sendEvent('register_baggage', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物取下げ」イベントを送信します
     * @param baggageId 取下げた荷物ID
     */
    static trackCancelBaggage(baggageId: number): void {
        this.sendEvent('cancel_baggage', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物情報変更」イベントを送信します
     * @param baggageId 変更した荷物ID
     */
    static trackEditBaggage(baggageId: number): void {
        this.sendEvent('edit_baggage', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物検索ボタン押下」イベントを送信します
     */
    static trackSearchBaggageByButton(form: BaggageSearchForm): void {
        this.sendEvent('search_baggage_by_button', convertBaggageSearchForm(form));
    }

    /**
     * KARTEに「よく使う検索条件から検索」イベントを送信します
     * @param baggageSearchConditionId 検索条件ID
     */
    static trackSearchBaggageBySavedSearchCondition(baggageSearchConditionId: number): void {
        this.sendEvent('search_baggage_by_saved_search_condition', { baggage_search_condition_id: baggageSearchConditionId });
    }

    /**
     * KARTEに「荷物検索のソート条件変更」イベントを送信します
     * @param condition 荷物検索条件
     */
    static trackSortBaggageList(form: BaggageSearchForm): void {
        this.sendEvent('sort_baggage_list', convertBaggageSearchForm(form));
    }

    /**
     * KARTEに「検索更新ボタン押下」イベントを送信します
     */
    static trackSearchBaggageByReload(form: BaggageSearchForm): void {
        this.sendEvent('search_baggage_by_reload', convertBaggageSearchForm(form));
    }

    /**
     * KARTEに「よく使う検索条件に追加」イベントを送信します
     * @param baggageSearchConditionId 検索条件ID
     */
    static trackAddBaggageSearchCondition(baggageSearchConditionId: number): void {
        this.sendEvent('add_baggage_search_condition', { baggage_search_condition_id: baggageSearchConditionId });
    }

    /**
     * KARTEに「よく使う検索条件を解除」イベントを送信します
     * @param baggageSearchConditionId 検索条件ID
     */
    static trackRemoveBaggageSearchCondition(baggageSearchConditionId: number): void {
        this.sendEvent('remove_baggage_search_condition', { baggage_search_condition_id: baggageSearchConditionId });
    }

    /**
     * KARTEに「荷物メール通知を設定」イベントを送信します
     * @param baggageSearchConditionId 通知設定をした検索条件ID
     */
    static trackSetBaggageMailNotification(baggageSearchConditionId: number): void {
        this.sendEvent('set_baggage_mail_notification', { baggage_search_condition_id: baggageSearchConditionId });
    }

    /**
     * KARTEに「空きトラック登録」イベントを送信します
     * @param truckId トラックID
     */
    static trackRegisterTruck(truckId: number): void {
        this.sendEvent('register_truck', { truck_id: truckId });
    }

    /**
     * KARTEに「空きトラック検索ボタン押下」イベントを送信します
     */
    static trackSearchTruck(): void {
        this.sendEvent('search_truck');
    }

    /**
     * KARTEに「お気に入り荷物保存」イベントを送信します
     * @param baggageId 変更した荷物ID
     */
    static trackAddFavoriteBaggage(baggageId: number): void {
        this.sendEvent('add_favorite_baggage', { baggage_id: baggageId });
    }

    /**
     * KARTEに「お気に入り荷物解除」イベントを送信します
     * @param baggageId 変更した荷物ID
     */
    static trackRemoveFavoriteBaggage(baggageId: number): void {
        this.sendEvent('remove_favorite_baggage', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物成約」イベントを送信します
     * @param agreementId 荷物成約ID
     */
    static trackAgreeBaggage(agreementId: number): void {
        this.sendEvent('agree_baggage', { agreement_id: agreementId });
    }

    /**
     * KARTEに「成約取消申請」イベントを送信します
     * @param agreementId 荷物成約ID
     */
    static trackRequestCancelAgreement(agreementId: number): void {
        this.sendEvent('request_cancel_agreement', { agreement_id: agreementId });
    }

    /**
     * KARTEに「成約変更申請」イベントを送信します
     * @param agreementId 荷物成約ID
     */
    static trackRequestChangeAgreement(agreementId: number): void {
        this.sendEvent('request_change_agreement', { agreement_id: agreementId });
    }

    /**
     * KARTEに「成約取消完了」イベントを送信します
     * @param agreementId 荷物成約ID
     */
    static trackCompleteCancelAgreement(agreementId: number): void {
        this.sendEvent('complete_cancel_agreement', { agreement_id: agreementId });
    }

    /**
     * KARTEに「成約変更完了」イベントを送信します
     * @param agreementId 荷物成約ID
     */
    static trackCompleteChangeAgreement(agreementId: number): void {
        this.sendEvent('complete_change_agreement', { agreement_id: agreementId });
    }

    /**
     * KARTEに「企業検索」イベントを送信します
     */
    static trackSearchCompany(): void {
        this.sendEvent('search_company');
    }

    /**
     * KARTEに「運賃保証申し込み」イベントを送信します
     */
    static trackApplyGuaranteeService(): void {
        this.sendEvent('apply_guarantee_service');
    }

    /**
     * KARTEに「トラボックス受発注サービスへのSSO」イベントを送信します
     */
    static trackOpenOnlineOrderService(agreementId: number, baggageCompanyId: number, truckCompanyId: number): void {
        this.sendEvent('open_online_order_service', { agreement_id: agreementId, baggage_company_id: baggageCompanyId, truck_company_id: truckCompanyId });
    }

    /**
     * KARTEに「トラボックス受発注サービスへのSSO不可」イベントを送信します
     */
    static trackFailOpenOnlineOrderService(agreementId: number, baggageCompanyId: number, truckCompanyId: number): void {
        this.sendEvent('fail_open_online_order_service', { agreement_id: agreementId, baggage_company_id: baggageCompanyId, truck_company_id: truckCompanyId });
    }

    /**
     * KARTEに「企業お知らせの選択」イベントを送信します
     */
    static trackClickCompanyNotice(type: string, objectId: number): void {
        this.sendEvent('click_company_notice', { type: type, object_id: objectId });
    }

    /**
     * KARTEに「荷物一覧除外企業追加」イベントを送信します
     */
    static trackAddBaggageSearchExcludeCompany(excludedCompanyId: number): void {
        this.sendEvent('add_excluded_companies', { company_id: excludedCompanyId });
    }

    /**
     * KARTEに「荷物一覧除外企業削除」イベントを送信します
     */
    static trackRemoveBaggageSearchExcludeCompany(excludedCompanyId: number): void {
        this.sendEvent('remove_excluded_companies', { company_id: excludedCompanyId });
    }

    /**
     * KARTEに「荷物自動検索ON」イベントを送信します
     */
    static trackTurnOnBaggageAutoSearch(): void {
        this.sendEvent('turn_on_auto_search');
    }

    /**
     * KARTEに「荷物自動検索OFF」イベントを送信します
     */
    static trackTurnOffBaggageAutoSearch(): void {
        this.sendEvent('turn_off_auto_search');
    }

    /**
     * KARTEに「荷物自動検索通知を受信」イベントを送信します
     */
    static trackReceiveBaggageAutoSearchNotification(baggageId: number): void {
        this.sendEvent('receive_auto_search_notification', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物自動検索通知のクリック」イベントを送信します
     */
    static trackClickBaggageAutoSearchNotification(baggageId: number): void {
        this.sendEvent('click_auto_search_notification', { baggage_id: baggageId });
    }

    /**
     * KARTEに「お知らせのクリック」イベントを送信します
     */
    static trackClickGlobalNotice(noticeId: number, noticeUrl: string): void {
        this.sendEvent('click_global_notice', { notice_id: noticeId, notice_url: noticeUrl });
    }

    /**
     * KARTEに「商談中にする」イベントを送信します
     */
    static trackTurnOnUnderNegotiationFlg(baggageId: number): void {
        this.sendEvent('turn_on_under_negotiation_flg', { baggage_id: baggageId });
    }

    /**
     * KARTEに「商談中を解除」イベントを送信します
     */
    static trackTurnOffUnderNegotiationFlg(baggageId: number): void {
        this.sendEvent('turn_off_under_negotiation_flg', { baggage_id: baggageId });
    }

    /**
     * KARTEに「荷物の成約予測実行」イベントを送信します。
     */
    static trackPredictBaggageAgreement(form: PredictionBaggageAgreementForm, probability: number): void {
        this.sendEvent('predict_baggage_agreement', {
            ...(new KartePredictionBaggageAgreementForm(form)),
            probability
        });
    }

    /**
     * KARTEに「空車検索結果の電話ボタンのクリック」イベントを送信します
     */
    static trackClickTruckContact(truckId: number): void {
        this.sendEvent('click_truck_contact', { truck_id: truckId });
    }

    /**
     * KARTEに「空車レコメンド結果の電話ボタンのクリック」イベントを送信します
     */
    static trackClickTruckRecommendContact(truckId: number, recommendBaggageId: number): void {
        this.sendEvent('click_truck_contact', { truck_id: truckId, recommend_baggage_id: recommendBaggageId });
    }

    /**
     * KARTEに「空車レコメンド結果の表示」イベントを送信します
     */
    static trackShowTruckRecommend(baggageId: number, truckIds: number[]): void {
        this.sendEvent('show_truck_recommend', { baggage_id: baggageId, truck_ids: truckIds });
    }

    /**
     * KARTEに「商談リクエストの電話ボタンのクリック」イベントを送信します
     */
    static trackClickNegotiationRequestContact(baggageId: number, companyId: number): void {
        this.sendEvent('click_negotiation_contact', { baggage_id: baggageId, truck_company_id: companyId });
    }

    /**
     * KARTEに「実績をみるのクリック」イベントを送信します
     */
    static trackClickShowCompanyPerformanceLink(): void {
        this.sendEvent('click_show_company_performance_link');
    }

    /**
     * KARTEに「請求書をダウンロード」イベントを送信します
     */
    static trackDownloadInvoice(year: number, month: number): void {
        this.sendEvent('download_invoice', { year, month });
    }

    /**
     * KARTEに「空車レコメンドアナウンスのクリック」イベントを送信します
     */
    static trackClickTruckRecommendAnnounce(baggageId: number, type: 'soon' | 'later'): void {
        this.sendEvent('click_truck_recommend_announce', { baggage_id: baggageId, type: type });
    }


    /**
     * KARTEに「成約画面の表示」イベントを送信します
     */
    static trackShowAgreementPage(form: KarteShowAgreementPageForm): void {
        this.sendEvent('show_agreement_page', form);
    }

    /**
     * KARTEにイベントを送信します
     * @param eventId ロギングするイベントID
     * @param params 送信するパラメータ群
     * @private
     */
    private static sendEvent(eventId: string,
        params?: { [key: string]: (string | number | boolean) } |
        KarteBaggageSearchForm |
        KarteTruckRecommendResult |
        KarteShowAgreementPageForm): void {
        try {
            if ('tracker' in window) {
                tracker.track(eventId, params);
            } else if ('krt' in window) {
                krt('send', eventId, params);
            }
        } catch (e) {
            if ('tracker' in window) {
                // @ts-ignore
                tracker.track('_error', { error_message: e.message });
            } else if ('krt' in window) {
                // @ts-ignore
                krt('send', '_error', { error_message: e.message });
            }
        }
    }
}
