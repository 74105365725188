import _ from 'lodash';
import { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { BaggageDetailUtil, DateUtil, PhoneUtil, Util } from '@/util';
import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
// @ts-ignore
import CompanyPrint from '@/components/Company/Print';
import OpenOnlineOrderButton from '@/components/UI/OpenOnlineOrderButton/index.vue';
import * as types from '@/vuex/modules/company/types';
import { Const } from '@/const';
import { CompanyDispatchHistoryRegisterForm } from '@/vuex/modules/company/types';
import { OnlineOrderService, OpenOnlineOrderError } from '@/domain/service/OnlineOrderService';
import { Karte } from '@/karte';

const agreementMod = namespace('agreement');
const companyMod = namespace('company');

/**
 * 配車フォーム
 */
interface DispatchForm {
    companyName: string
    carNumber: string
    driverName: string
}

@Component({
    title: '成約情報印刷',
    components: {
        CompanyPrint,
        OpenOnlineOrderButton,
    },
    beforeRouteEnter: AgreementPrintPage.beforeRouteEnter,
    beforeRouteLeave: AgreementPrintPage.beforeRouteLeave,
})
export default class AgreementPrintPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT)
    readonly AGREEMENT?: agreementTypes.Agreement;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly MY_PROFILE?: companyTypes.CompanyProfile;
    @companyMod.Getter(companyTypes.GETTER.PROFILE_LIST)
    readonly PROFILE_LIST?: companyTypes.CompanyProfile[];
    @companyMod.Getter(types.GETTER.CONFIDENCE)
    readonly partnerCompanyConfidence?: types.CompanyConfidence;
    @companyMod.Getter(types.GETTER.STATISTICS)
    readonly partnerCompanyStatistics?: types.CompanyStatistics;
    @companyMod.Getter(types.GETTER.DISPATCHED_TRUCK_LIST)
    readonly DISPATCHED_TRUCK_LIST?: types.CompanyDispatchedTruckList;
    @companyMod.Getter(types.GETTER.DISPATCHED_DRIVER_LIST)
    readonly DISPATCHED_DRIVER_LIST?: types.CompanyDispatchedDriverList;
    @agreementMod.Getter(agreementTypes.GETTER.EXISTENCE_ONLINE_ORDER_DIRECTION)
    readonly EXISTENCE_ONLINE_ORDER_DIRECTION?: boolean;
    @agreementMod.Getter(agreementTypes.GETTER.AVAILABILITY_ONLINE_ORDER_DIRECTION)
    readonly AVAILABILITY_ONLINE_ORDER_DIRECTION?: agreementTypes.AgreementOnlineOrderAvailability;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ required: true, type: Number })
    declare readonly agreementId?: number;

    dispatchForm: DispatchForm = {
        companyName: '',
        carNumber: '',
        driverName: '',
    };
    openingOnlineOrder = false;

    get companies(): companyTypes.CompanyProfile[] {
        let companies: companyTypes.CompanyProfile[] = [];
        if (this.PROFILE_LIST) {
            companies = [...this.PROFILE_LIST];
        }
        if (this.MY_PROFILE) {
            companies.push(this.MY_PROFILE);
        }
        return companies;
    }

    get partnerCompanyProfile(): companyTypes.CompanyProfile | undefined {
        return this.AGREEMENT?.baggageCompanyId === this.MY_PROFILE?.id ? this.truckCompany : this.baggageCompany;
    }

    get baggageCompany(): companyTypes.CompanyProfile | undefined {
        return this.companies.find((each) => each.id === this.AGREEMENT?.baggageCompanyId);
    }

    get truckCompany(): companyTypes.CompanyProfile | undefined {
        return this.companies.find((each) => each.id === this.AGREEMENT?.truckCompanyId);
    }

    get baggageCompanyName(): string {
        return this.baggageCompany?.name.kanji ?? '';
    }

    get truckCompanyName(): string {
        return this.truckCompany?.name.kanji ?? '';
    }

    get id(): string {
        return this.AGREEMENT?.id.toString() ?? '';
    }

    get entryTm(): string {
        if (!this.AGREEMENT) return '';
        return DateUtil.parseDatetimeText(this.AGREEMENT.entryTm).format(Const.DEFAULT_PRINT_DATETIME_FORMAT);
    }

    get baggageId(): string {
        return this.AGREEMENT?.baggageId.toString() ?? '';
    }

    get type(): string {
        return this.AGREEMENT?.type ?? '';
    }

    get paletteCount(): string | undefined {
        return BaggageDetailUtil.paletteCount(
            this.AGREEMENT?.shape?.code,
            this.AGREEMENT?.paletteCount
        );
    }

    get paletteSize(): string | undefined {
        return BaggageDetailUtil.paletteSize(
            this.AGREEMENT?.shape?.code,
            this.AGREEMENT?.paletteHeight,
            this.AGREEMENT?.paletteWidth
        );
    }

    get totalCount(): string | undefined {
        return BaggageDetailUtil.totalCount(
            this.AGREEMENT?.shape?.code,
            this.AGREEMENT?.totalCount
        );
    }

    get totalVolume(): string | undefined {
        return BaggageDetailUtil.totalVolume(
            this.AGREEMENT?.shape?.code,
            this.AGREEMENT?.totalVolume
        );
    }

    get totalWeight(): string | undefined {
        return BaggageDetailUtil.totalWeight(this.AGREEMENT?.totalWeight);
    }

    get handling(): string | undefined {
        return BaggageDetailUtil.handling(
            this.AGREEMENT?.loading?.code,
            this.AGREEMENT?.unloading?.code
        );
    }

    get share(): string {
        if (!this.AGREEMENT) return '';
        return `積合：${this.AGREEMENT.share ? '○' : '×'}`;
    }

    get paymentDate(): string {
        if (!this.AGREEMENT) return '';
        return DateUtil.parseDateText(this.AGREEMENT.paymentDate).format(Const.DEFAULT_PRINT_DATE_FORMAT);
    }

    get changeLimitTm(): string {
        if (!this.AGREEMENT) return '';
        return DateUtil.parseDatetimeText(this.AGREEMENT.changeLimitTm).format(Const.DEFAULT_PRINT_DATETIME_FORMAT);
    }

    get freight(): string {
        const freight = this.AGREEMENT?.freight ?? 0;
        return freight > 0 ? `${Util.formatNumber(freight)}円` : '要相談';
    }

    get highwayFare(): string {
        if (!this.AGREEMENT) return '';

        if (!this.AGREEMENT.highwayFareFlg) return 'なし';
        const highwayFare = this.AGREEMENT.highwayFare;
        return highwayFare <= 0 ? '金額未定' : `${Util.formatNumber(highwayFare)}円`;
    }

    get truckWeightAndModel(): string {
        if (!this.AGREEMENT) return '';
        const weight = this.AGREEMENT?.truckWeight.label ?? '';
        const model = this.AGREEMENT?.truckModel.label ?? '';
        return `重量：${ weight } 車種：${ model }`;
    }

    get description(): string {
        return this.AGREEMENT?.description ?? '';
    }

    get staffName(): string {
        return this.AGREEMENT?.staffName ?? '';
    }

    get departureText(): string {
        const min = this.AGREEMENT?.departureMin;
        const max = this.AGREEMENT?.departureMax;

        if (!min || !max) {
            return '';
        }

        return this.AGREEMENT ? DeliveryDateTime.of(min, max)?.format(Const.DEFAULT_PRINT_DELIVERY_DATE_FORMAT) ?? '' : '';
    }

    get arrivalText(): string {
        const min = this.AGREEMENT?.arrivalMin;
        const max = this.AGREEMENT?.arrivalMax;

        if (!min || !max) {
            return '';
        }

        return this.AGREEMENT ? DeliveryDateTime.of(min, max)?.format(Const.DEFAULT_PRINT_DELIVERY_DATE_FORMAT) ?? '' : '';
    }

    get phoneNumber(): string {
        if (!this.baggageCompany) return '';
        return PhoneUtil.format(this.baggageCompany.phone.number);
    }

    get faxNumber(): string {
        if (!this.baggageCompany) return '';
        return PhoneUtil.format(this.baggageCompany.phone.faxNumber);
    }

    get departureAddress(): string {
        return (
            (this.AGREEMENT?.departurePref.label ?? '') +
            (this.AGREEMENT?.departureCity ?? '') +
            (this.AGREEMENT?.departureAddress ?? '')
        );
    }

    get arrivalAddress(): string {
        return (
            (this.AGREEMENT?.arrivalPref.label ?? '') +
            (this.AGREEMENT?.arrivalCity ?? '') +
            (this.AGREEMENT?.arrivalAddress ?? '')
        );
    }

    /**
     * 積み時間
     */
    get loadingTimeNote(): string {
        return this.AGREEMENT?.loadingTimeNote ?? '';
    }

    /**
     * 卸し時間
     */
    get unloadingTimeNote(): string {
        return this.AGREEMENT?.unloadingTimeNote ?? '';
    }

    /**
     * 車両指定
     */
    get specifiedTruck(): string {
        if (!this.AGREEMENT) return '';

        const height = this.AGREEMENT?.truckHeight?.label;
        const width = this.AGREEMENT?.truckWidth?.label;
        const largeTruckFlg = this.AGREEMENT?.largeTruckFlg;
        const largeTruckText = _.isNil(largeTruckFlg) ? '指定なし' : largeTruckFlg ? '可' : '不可';

        return `床高：${ height ?? '指定なし' } / 車幅：${ width ?? '指定なし' } / 大型：${ largeTruckText }`;
    }

    /**
     * 必要装備
     */
    get truckEquipment(): string {
        return this.AGREEMENT?.truckEquipment ?? '';
    }

    /**
     * 私は運送会社ですか？
     */
    get iAmTransporter(): boolean {
        if (!this.truckCompany || !this.MY_PROFILE) return false;
        return this.truckCompany.id === this.MY_PROFILE?.id;
    }

    get dispatchedCarNumberList(): string[] {
        if (!this.iAmTransporter) return [];
        return this.DISPATCHED_TRUCK_LIST?.carNumbers ?? [];
    }

    get dispatchedDriverNameList(): string[] {
        if (!this.iAmTransporter) return [];
        return this.DISPATCHED_DRIVER_LIST?.driverNames ?? [];
    }

    /**
     * 依頼書ボタンを表示するか否かを取得します。
     */
    get shouldShowLinkToOnlineOrder(): boolean {
        if (!this.AGREEMENT || !this.MY_PROFILE || !this.AVAILABILITY_ONLINE_ORDER_DIRECTION) {
            return false;
        }
        return OnlineOrderService.shouldShowLinkToOnlineOrder(this.AGREEMENT, this.MY_PROFILE, this.EXISTENCE_ONLINE_ORDER_DIRECTION ?? false);
    }

    // ======================================================
    // Data
    // ======================================================
    informationComment = '';

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (this.iAmTransporter) {
            // 操作者が運送会社である場合、自社名をデフォルトで設定する
            this.dispatchForm.companyName = this.truckCompanyName;
        }
    }

    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    async onClickPrint(): Promise<void> {
        // 操作者が運送会社である場合、配車履歴を保存する
        if (this.iAmTransporter) {
            const form: CompanyDispatchHistoryRegisterForm = {
                carNumber: this.dispatchForm.carNumber,
                driverName: this.dispatchForm.driverName,
            };
            await AgreementPrintPage.registerDispatchHistory(form).catch(() => { /* 履歴登録の成否は無視する */});
        }
        // 印刷ダイアログを表示する
        window.print();
    }

    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    onClickClose(): void {
        window.close();
    }

    /**
     * 依頼書ボタンが押下された際に呼び出されます。
     */
    async onClickOpenOnlineOrder(): Promise<void> {
        if (!this.AGREEMENT || !this.MY_PROFILE || !this.AVAILABILITY_ONLINE_ORDER_DIRECTION) {
            return;
        }
        const agreement = this.AGREEMENT;

        //
        // トラボックス受発注へSSOする
        //
        this.openingOnlineOrder = true;
        await OnlineOrderService.openOnlineOrder(agreement, this.MY_PROFILE, this.EXISTENCE_ONLINE_ORDER_DIRECTION ?? false, this.AVAILABILITY_ONLINE_ORDER_DIRECTION)
            .then(() => {
                // トラッキング
                Karte.trackOpenOnlineOrderService(agreement.id, agreement.baggageCompanyId, agreement.truckCompanyId);
            })
            .catch((error: OpenOnlineOrderError) => {
                // 通知
                OnlineOrderService.handleOpenOnlineOrderError(error, this.$notification);
                // トラッキング
                Karte.trackFailOpenOnlineOrderService(agreement.id, agreement.baggageCompanyId, agreement.truckCompanyId);
            })
            .finally(() => (this.openingOnlineOrder = false));
    }

    /**
     * サジェストの削除ボタンが押下された際に呼び出されます。
     */
    async onClickDelete(type: 'carNumber' | 'driverName', value: string, event: MouseEvent): Promise<void> {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        const failedNotificationKey = 'DELETE_DISPATCH_HISTORY_ERROR';
        this.$notification.close(failedNotificationKey);

        const onError = (err: unknown) => {
            this.$notification.error({
                key: failedNotificationKey,
                message: '履歴を削除できませんでした。',
                description:
                    '時間をおいて再度お試しください。何度試しても状況が改善しない場合はお問い合わせください。',
            });
            return Promise.reject(err);
        };

        // 削除APIを実行
        switch (type) {
            case 'carNumber':
                await AgreementPrintPage.deleteDispatchHistory({ carNumber: value }).catch(onError);
                // 車番情報を再読み込み
                await AgreementPrintPage.loadDispatchedTruckHistories();
                break;
            case 'driverName':
                await AgreementPrintPage.deleteDispatchHistory({ driverName: value }).catch(onError);
                // ドライバー情報を再読み込み
                await AgreementPrintPage.loadDispatchedDriverHistories();
                break;
            default:
                throw new Error(`given type is not defined. [type] => ${type}`);
        }
    }

    /**
     * サジェストの項目と入力文字列を照合して絞り込みを行います。
     */
    filterOption(input: string, option: VNode): boolean {
        const text = option.key;
        if (!text) {
            return false;
        }
        return _.isString(text) && text.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    /**
     * 成約をロードします。
     */
    private static loadAgreement(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_AGREEMENT }`, id);
    }

    /**
     * 成約をクリアします。
     */
    private static clearAgreement(): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_AGREEMENT }`);
    }

    /**
     * 自社のプロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${ types.ACTION.LOAD_MY_PROFILE }`);
    }

    /**
     * 企業プロフィールリストをロードします。
     */
    private static loadCompanyProfileList(id: number): Promise<void> {
        const form: companyTypes.CompanyPartnerProfileListForm = { id: [id] };
        return store.dispatch(`company/${ companyTypes.ACTION.LIST_PARTNER_PROFILE }`, form);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${ types.ACTION.LOAD_CONFIDENCE }`, companyId);
    }

    /**
     * 企業統計情報をロードします。
     */
    private static loadStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${ types.ACTION.LOAD_STATISTICS }`, companyId);
    }

    /**
     * 配車履歴を登録します。
     */
    private static registerDispatchHistory(form: CompanyDispatchHistoryRegisterForm): Promise<void> {
        // 未入力なら登録処理をスキップ
        if (_.trim(form.carNumber).length == 0 && _.trim(form.driverName).length == 0) {
            return Promise.resolve();
        }
        return store.dispatch(`company/${companyTypes.ACTION.REGISTER_DISPATCH_HISTORY}`, form);
    }

    /**
     * 配車履歴を削除します。
     */
    private static deleteDispatchHistory(form: CompanyDispatchHistoryRegisterForm): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.DELETE_DISPATCH_HISTORY}`, form);
    }

    /**
     * 車番情報の入力履歴をロードします。
     */
    private static loadDispatchedTruckHistories(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_DISPATCHED_TRUCK_HISTORIES}`);
    }

    /**
     * ドライバー情報の入力履歴をロードします。
     */
    private static loadDispatchedDriverHistories(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_DISPATCHED_DRIVER_HISTORIES}`);
    }

    /**
     * トラボックス受発注に依頼書が存在するか否かをロードします。
     */
    private static loadExistenceOnlineOrderDirection(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_EXISTENCE_ONLINE_ORDER_DIRECTION }`, id);
    }

    /**
     * トラボックス受発注に依頼書が存在するか否かをクリアします。
     */
    private static clearExistenceOnlineOrderDirection(): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_EXISTENCE_ONLINE_ORDER_DIRECTION }`);
    }

    /**
     * トラボックス受発注の依頼書を利用できるか否かをロードします。
     */
    static loadAvailabilityOnlineOrderDirection(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_AVAILABILITY_ONLINE_ORDER_DIRECTION }`, id);
    }

    /**
     * トラボックス受発注の依頼書を利用できるか否かをクリアします。
     */
    static clearAvailabilityOnlineOrderDirection(): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_AVAILABILITY_ONLINE_ORDER_DIRECTION }`);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<AgreementPrintPage>
    ): Promise<void> {
        const { agreementId } = to.params;
        const onError = () => next({ name: 'NotFound' });
        if (!agreementId) {
            onError();
        }

        Promise.all([
            AgreementPrintPage.loadMyProfile(),
            AgreementPrintPage.loadAgreement(Number(agreementId)),
            AgreementPrintPage.loadDispatchedTruckHistories(),
            AgreementPrintPage.loadDispatchedDriverHistories(),
            AgreementPrintPage.loadExistenceOnlineOrderDirection(Number(agreementId)),
            AgreementPrintPage.loadAvailabilityOnlineOrderDirection(Number(agreementId))
        ])
            .then(async () => {
                const myProfile = await store.getters[`company/${ companyTypes.GETTER.MY_PROFILE }`] as companyTypes.CompanyProfile;
                const agreement = await store.getters[`agreement/${ agreementTypes.GETTER.AGREEMENT }`] as agreementTypes.Agreement;
                const partnerCompanyId = myProfile.id === agreement.baggageCompanyId ? agreement.truckCompanyId : agreement.baggageCompanyId;
                return Promise.resolve([agreement.id, partnerCompanyId] as [number, number]);
            })
            .then(([id, partnerCompanyId]) => {
                return Promise.all([
                    AgreementPrintPage.loadCompanyProfileList(id),
                    AgreementPrintPage.loadConfidence(partnerCompanyId),
                    AgreementPrintPage.loadStatistics(partnerCompanyId),
                ]);
            })
            .then(() => next())
            .catch(onError);
    }

    static async beforeRouteLeave(_to: VueRoute, _from: VueRoute, next: NavigationGuardNext<AgreementPrintPage>): Promise<void> {
        await AgreementPrintPage.clearAgreement();
        await AgreementPrintPage.clearExistenceOnlineOrderDirection();
        await AgreementPrintPage.clearAvailabilityOnlineOrderDirection();
        next();
    }
}
