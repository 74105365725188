import { createGlobalState } from '@vueuse/core';
import { accountApi } from '@/repository/api/internal/account';
import { AccountProfileModel } from '@/models/account-profile';
import { MUTATION as m, GETTER as g } from '@/vuex/modules/account/types';
import { ref } from 'vue';
import { useVuexSync } from '@/composables/helper/vuex';

/**
 * 自分の会員プロフィールを取得する機能を提供します。
 */
export const useAccountMyProfile = createGlobalState(() => {
    const profile = ref<AccountProfileModel | undefined>(undefined);

    useVuexSync(profile, { module: 'account', getter: g.PROFILE, mutation: m.SET_PROFILE_MODEL });

    const load = async (): Promise<AccountProfileModel> => {
        const data = await accountApi.myProfile();
        profile.value = new AccountProfileModel(data);

        return profile.value;
    };
    return {
        state: {
            profile,
        },
        load,
    };
});
