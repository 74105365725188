import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import moment from 'moment';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { Const } from '@/const';

@Component
export default class CompanyEstablishmentEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileExtraUpdateForm;

    /**
     * 設立年月
     */
    get establishment(): string {
        return this.value?.establishmentDate ?? '';
    }

    set establishment(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.establishmentDate = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    establishmentDateFormat = Const.DEFAULT_DATE_YEAR_MONTH_FORMAT;
    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 指定された日付が選択可能か否かを取得します。
     */
    disabledDate(date: moment.Moment): boolean {
        // 設立年月で選択可能な範囲は、1700年〜今月を範囲とする。
        // 1700年としているのは、ご利用企業様に享保元年(1716年)創業の企業がいらっしゃるため。
        return !date.isBetween(
            moment('1700-01-01', Const.INTERNAL_DATE_FORMAT),
            moment().endOf('month'),
            'month',
            '[]'
        );
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (_.isEmpty(this.value?.establishmentDate)) {
            callback();
        } else if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(this.value?.establishmentDate ?? '')) {
            callback('設立の年月を選択しなおしてください。');
        } else {
            callback();
        }
    }
}
