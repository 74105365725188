import { ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { agreementApi } from '@/repository/api/internal/agreement';

export const useAcceptedAgreementCount = () => {
    const { state: { loading }, withLoading } = useLoading();
    const count = ref(0);

    const load = (companyId: number) => withLoading(async () => {
        count.value = await agreementApi.countAccepted(companyId);
    });

    const clear = () => count.value = 0;

    return {
        state: {
            loading,
            count,
        },
        load,
        clear,
    };
};
