import { ref } from 'vue';
import { companyApi } from '@/repository/api/internal/company';
import { TruckList } from '@/models/truck';

/**
 * 企業空車をロードする機能を提供します。
 */
export const useCompanyTruckList = () => {
    const list = ref<TruckList | undefined>(undefined);
    const loading = ref<boolean>(false);

    const load = async (companyId: number) => {
        try {
            loading.value = true;
            list.value = await companyApi.truckList(companyId);
        } finally {
            loading.value = false;
        }
    };

    const clear = () => {
        list.value = undefined;
    };

    return {
        state: {
            list,
            loading,
        },
        load,
        clear,
    };
};
