import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useFormModel } from '@/composables/form-helper';
import { useNotification } from '@/composables/helper/page-helper';
import { useModal } from '@/composables/helper/modal-helper';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useReportLatePaymentRegister } from '@/composables/report-late-payment';
import Content from '@/_components/parts/ReportLatePaymentConfirmDialog.vue';

export const useReportLatePaymentHelper = () => {
    const phase = ref<'init' | 'done'>('init');

    const { formModel, submit } = useFormModel();
    const { confirm } = useModal();
    const { error } = useNotification();

    // 企業プロフィール
    const { load, state: { myProfile: companyProfile } } = useCompanyMyProfile();
    // アカウントプロフィール
    const { state: { profile: accountProfile } } = useAccountMyProfile();
    // 入金遅延連絡
    const { register, state: { loading, form, formValidateRules } } = useReportLatePaymentRegister();

    const profile = computed(() => ({
        myCompanyName: companyProfile.value?.name.kanji,
        myStaffName: accountProfile.value?.name,
        myEmail: accountProfile.value?.email
    }));

    const onSubmit = async () => submit(async () => {
        if (!await confirm(
            '以下の内容で送信してよろしいですか？',
            (h) => h(Content, {
                props: {
                    ...profile.value,
                    ...form.value.toJSON(),
                },
            }),
            '送信',
            'キャンセル',
            800,
        )) return;

        try {
            await register()
                .then(() => formModel.value?.resetFields())
                .then(() => phase.value = 'done');
        } catch {
            error({
                message: '入金遅延連絡の送信ができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    });

    onMounted(async () => await load());

    onBeforeRouteLeave((_to, _from, next) => {
        if (!form.value.isDirty()) return next();
        if (window.confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) return next();

        return next(false);
    });

    return {
        phase,
        loading,
        profile,
        form,
        formModel,
        formValidateRules,
        onSubmit,
    };
};
