import { FormModel } from 'ant-design-vue';
import { Component, Prop } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiPreLoginLayout from '@/components/UI/Layouts/PreLoginLayout';
// @ts-ignore
import AccountResetPassword from '@/components/Account/Reset/Password';
import store from '@/vuex/store';
import * as accountTypes from '@/vuex/modules/account/types';

@Component({
    title: 'パスワードの再設定',
    components: {
        UiPreLoginLayout,
        AccountResetPassword,
    },
})
export default class AccountPasswordResetVerifyPage extends PageVue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ type: String })
    declare readonly verificationCode?: string;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    verified = false;
    submitting = false;
    form: accountTypes.AccountPasswordResetForm = {
        verificationCode: '',
        password: '',
    };

    async mounted(): Promise<void> {
        await this.initialize();
    }

    private async initialize() {
        if (!this.verificationCode?.trim()) {
            return;
        }
        this.loading = true;

        this.form.verificationCode = this.verificationCode;

        await AccountPasswordResetVerifyPage.verifyAccountResetPasswordCode(this.verificationCode)
            .then(() => (this.verified = true))
            .catch(() => (this.verified = false))
            .finally(() => (this.loading = false));
    }

    /**
     * パスワード再設定ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const failedNotificationKey = 'RESET_FAILED';
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result: boolean) => {
            if (!result) return;

            this.submitting = true;
            this.$notification.close(failedNotificationKey);

            const onSuccess = async () => {
                await this.$router.push({ name: 'AccountPasswordResetComplete' });
            };
            const onNotifyFailed = () => {
                this.$notification.error({
                    key: failedNotificationKey,
                    message: 'パスワードの再設定に失敗しました。',
                    description: '入力内容をご確認のうえ、再度お試しください。',
                });
            };

            await AccountPasswordResetVerifyPage.resetPassword(this.form)
                .then(onSuccess)
                .catch(onNotifyFailed)
                .finally(() => (this.submitting = false));
        });
    }

    /**
     * パスワードリセット用のトークン `verificationCode` を検証します。
     */
    private static verifyAccountResetPasswordCode(verificationCode: string): Promise<void> {
        return store.dispatch(`account/${accountTypes.ACTION.VERIFY_RESET_PASSWORD}`, { verificationCode });
    }

    /**
     * パスワード再設定APIリクエストを送信します。
     */
    private static resetPassword(form: accountTypes.AccountPasswordResetForm): Promise<void> {
        return store.dispatch(`account/${accountTypes.ACTION.RESET_PASSWORD}`, form);
    }
}
