import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import Description from '@/components/Company/Edit/Description';
// @ts-ignore
import RepresentativeName from '@/components/Company/Edit/RepresentativeName';
// @ts-ignore
import Establishment from '@/components/Company/Edit/Establishment';
// @ts-ignore
import Capital from '@/components/Company/Edit/Capital';
// @ts-ignore
import EmployeeNumber from '@/components/Company/Edit/EmployeeNumber';
// @ts-ignore
import OfficeAddress from '@/components/Company/Edit/OfficeAddress';
// @ts-ignore
import Sales from '@/components/Company/Edit/Sales';
// @ts-ignore
import Bank from '@/components/Company/Edit/Bank';
// @ts-ignore
import Customer from '@/components/Company/Edit/Customer';
// @ts-ignore
import SalesArea from '@/components/Company/Edit/SalesArea';
// @ts-ignore
import CutOffDay from '@/components/Company/Edit/CutOffDay';
// @ts-ignore
import PaymentMonth from '@/components/Company/Edit/PaymentMonth';
// @ts-ignore
import PaymentDay from '@/components/Company/Edit/PaymentDay';
import { FormModel } from 'ant-design-vue';

const companyMod = namespace('company');

@Component({
    title: '企業詳細情報',
    components: {
        Description,
        RepresentativeName,
        Establishment,
        Capital,
        EmployeeNumber,
        OfficeAddress,
        Sales,
        Bank,
        Customer,
        SalesArea,
        CutOffDay,
        PaymentMonth,
        PaymentDay,
    },
    beforeRouteEnter: SettingCompanyProfileExtraPage.beforeRouteEnter,
})
export default class SettingCompanyProfileExtraPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.MY_PROFILE)
    readonly PROFILE?: types.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    form: types.CompanyProfileExtraUpdateForm = new types.CompanyProfileExtraUpdateForm({
        description: undefined,
        representativeName: undefined,
        establishmentDate: undefined,
        capital: undefined,
        employeesNumber: undefined,
        officeAddress: undefined,
        sales: undefined,
        bank: undefined,
        customer: undefined,
        salesArea: undefined,
        cutOffDay: undefined,
        paymentMonth: undefined,
        paymentDay: undefined,
    });

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (!this.PROFILE) return;

        this.form.description = this.PROFILE.description;
        this.form.representativeName = this.PROFILE.representativeName;
        this.form.establishmentDate = this.PROFILE.establishmentDate;
        this.form.capital = this.PROFILE.capital;
        this.form.employeesNumber = this.PROFILE.employeesNumber;
        this.form.officeAddress = this.PROFILE.officeAddress;
        this.form.sales = this.PROFILE.sales;
        this.form.bank = this.PROFILE.bank;
        this.form.customer = this.PROFILE.customer;
        this.form.salesArea = this.PROFILE.salesArea;
        this.form.cutOffDay = this.PROFILE.cutOffDay;
        this.form.paymentMonth = this.PROFILE.paymentMonth;
        this.form.paymentDay = this.PROFILE.paymentDay;
    }

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const notifyFailedKey = 'UPDATE_COMPANY_PROFILE_FAILED';
        const onSuccess = () => {
            this.$message.success('企業情報を更新しました。');
        };

        const notifyFailed = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: '企業情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            await SettingCompanyProfileExtraPage.updateProfile(this.form).then(onSuccess).catch(notifyFailed);

            this.loading = false;
        });
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingCompanyProfileExtraPage>
    ): Promise<void> {
        await SettingCompanyProfileExtraPage.loadMyProfile().catch(() => next({ name: 'NotFound' }));

        next();
    }

    /**
     * 自分の企業プロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * 企業プロフィールを更新します。
     */
    private static updateProfile(form: types.CompanyProfileExtraUpdateForm): Promise<void> {
        return store.dispatch(`company/${types.ACTION.UPDATE_PROFILE_EXTRA}`, form);
    }
}
