import { appAxios, jsonpAxios } from './base';
import _ from 'lodash';

interface ZipCloudResult {
    address1: string;
    address2: string;
    address3: string;
    kana1: string;
    kana2: string;
    kana3: string;
    prefcode: string;
    zipcode: string;
}

interface ZipCloudResponse {
    message: string | null;
    results: ZipCloudResult[] | null;
    status: 200 | 400 | 500;
}

export interface PrefCityResult {
    code: string;
    name: string;
}

export const miscApi = {
    /**
     * 郵便番号から住所を取得します。
     *
     * @param zip ハイフン無し数字7桁
     */
    zipToAddress(zip: string): Promise<ZipCloudResult | undefined> {
        return jsonpAxios
            .get<ZipCloudResponse>('https://zipcloud.ibsnet.co.jp/api/search', { params: { zipcode: zip } })
            .then((result) => _.first(result.data.results));
    },
    /**
     * 都道府県コードから該当する市区町村一覧JSONを取得します。
     * 市区町村マスタはFrontAPIではなく、WebFrontで静的なJSONで持っています。
     * レスポンスデータをメンテナンスする際は public/js/city/*.json を参照してください。
     * @param prefCode 都道府県コード
     */
    listCity(prefCode: string): Promise<PrefCityResult[]> {
        return appAxios
            .get<{ data: PrefCityResult[] }>(`/js/city/${ prefCode.toLowerCase() }.json`)
            .then((res) => res.data.data)
            .catch(() => []);
    },
};
