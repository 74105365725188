import { computed, ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { settlementApi } from '@/repository/api/internal/settlement';
import { useEntitlement } from '@/composables/entitlement';

/**
 * マイ荷物・成約＞請求・支払タブの利用可否を取得します。
 *
 * 該当機能をおまかせ請求契約利用者、およびその相手となる荷主に限定する期間においてのみ利用することを想定。
 * 故に、Entitlementドメインではなく、単一の機能として実現しています。（＝将来は不要になる予定）
 */
export const useSettlementAvailability = () => {
    const { state: { loading }, withLoading } = useLoading();
    const { state: { entitlement } } = useEntitlement();

    // 荷主の立場として、決済代行な取引が存在するか否か
    const existsProxyOutgo = ref(false);
    // 運送の立場として、おまかせ請求契約が存在するか否か
    const existsContract = ref(false);

    const available = computed(() => existsContract.value || existsProxyOutgo.value);

    const load = () => withLoading(async () => {
        existsProxyOutgo.value = await settlementApi.existsProxyOutgo();
        existsContract.value = entitlement.value?.can('viewSettlement') ?? false;
    });

    return {
        state: {
            loading,
            available,
        },
        load,
    };
};
