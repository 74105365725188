import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { Util } from '@/util';
import { Const } from '@/const';

@Component
export default class CompanyPhoneEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyTransferUpdateForm;

    /**
     * 電話番号
     */
    get phoneNumber(): string | undefined {
        return this.value?.phoneNumber;
    }

    set phoneNumber(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.phoneNumber = Util.emptyStringToUndefined(newValue ?? '');
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            transform: (): string => Util.toDigits(this.phoneNumber ?? ''),
            min: Const.MIN_PHONE_NUMBER,
            max: Const.MAX_PHONE_NUMBER,
            message: `電話番号は${Const.MIN_PHONE_NUMBER}桁〜${Const.MAX_PHONE_NUMBER}桁で入力してください。`,
        },
        {
            transform: (): string => Util.toDigits(this.phoneNumber ?? ''),
            pattern: Const.PHONE_NUMBER_REGEX,
            message: '電話番号を正しい形式で入力してください。',
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 電話番号のフォーカスが外れた際に呼び出されます。
     */
    onBlurPhoneNumber(): void {
        this.phoneNumber = Util.toDigits(this.phoneNumber?.trim() ?? '');
    }
}
