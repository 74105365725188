import { AgreementFormModel, SettlementProxyFormItem } from '@/models/agreement';
import { computed, ref } from 'vue';
import { FormValidateUtil } from '@/models/validate-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { Baggage } from '@/models/baggage';
import { AccountProfileModel } from '@/models/account-profile';
import { DateValue } from '@/models/vo/date';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { agreementApi } from '@/repository/api/internal/agreement';
import _ from 'lodash';
import { EntitlementModel } from '@/models/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

export const useAgreementRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new AgreementFormModel());
    const sourceForm = ref(_.cloneDeep(form.value));
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed(() => !_.isEqual(form.value, sourceForm.value));

    const initForm = (baggage: Baggage, accountProfile: AccountProfileModel, defaultPaymentDate: DateValue | undefined, entitlement: EntitlementModel) => {
        const departure = DeliveryDateTimeRange.of(baggage.departureMin, baggage.departureMax);
        const [ departureMin, departureMax ] = departure?.rawValuesAsString() ?? [ '', '' ];
        const arrival = DeliveryDateTimeRange.of(baggage.arrivalMin, baggage.arrivalMax);
        const [ arrivalMin, arrivalMax ] = arrival?.rawValuesAsString() ?? [ '', '' ];

        form.value.baggageId = baggage.id;
        form.value.departureMin = departureMin;
        form.value.departureMax = departureMax;
        form.value.departureType = departure?.type;
        form.value.departurePref.code = baggage.departurePref.code;
        form.value.departureCity = baggage.departureCity ?? '';
        form.value.departureAddress = baggage.departureAddress;
        form.value.loadingTimeNote = baggage.loadingTimeNote;
        form.value.arrivalMin = arrivalMin;
        form.value.arrivalMax = arrivalMax;
        form.value.arrivalType = arrival?.type;
        form.value.arrivalPref.code = baggage.arrivalPref.code;
        form.value.arrivalCity = baggage.arrivalCity ?? '';
        form.value.arrivalAddress = baggage.arrivalAddress;
        form.value.unloadingTimeNote = baggage.unloadingTimeNote;
        form.value.type = baggage.type ?? '';
        form.value.shape = { code: baggage.shape.code ?? '' };
        form.value.temperatureZone = { code: baggage.temperatureZone.code ?? '' };
        form.value.paletteCount = baggage.paletteCount?.toString();
        form.value.paletteHeight = baggage.paletteHeight?.toString();
        form.value.paletteWidth = baggage.paletteWidth?.toString();
        form.value.totalCount = baggage.totalCount?.toString();
        form.value.totalVolume = baggage.totalVolume?.toString();
        form.value.totalWeight = baggage.totalWeight?.toString();
        form.value.loading = baggage.loading ? { code: baggage.loading.code } : undefined;
        form.value.unloading = baggage.unloading ? { code: baggage.unloading.code } : undefined;
        form.value.freight = `${baggage.freight ?? 0}`;
        form.value.highwayFareFlg = baggage.highwayFareFlg ?? false;
        form.value.truckStaffName = accountProfile.name;
        form.value.description = baggage.description ?? '';
        form.value.guarantee = false;
        form.value.settlementProxy = determineSettlementProxy(entitlement);
        form.value.paymentDateValue = defaultPaymentDate;

        sourceForm.value = _.cloneDeep(form.value);
    };

    const register = () => withLoading(async () => {
        if (form.value.shape?.code === BaggageShapeEnum.Palette.code) {
            form.value.totalCount = undefined;
            form.value.totalVolume = undefined;
        } else if (form.value.shape?.code === BaggageShapeEnum.Other.code) {
            form.value.paletteCount = undefined;
            form.value.paletteHeight = undefined;
            form.value.paletteWidth = undefined;
        }
        const id = await agreementApi.register(form.value.toForm());

        sourceForm.value = _.cloneDeep(form.value);

        return id;
    });

    const determineSettlementProxy = (entitlement: EntitlementModel): SettlementProxyFormItem | undefined => {
        // おまかせ利用不可
        if (!entitlement.active('easyPaymentContract')) return undefined;

        // 発日未指定
        if (_.isEmpty(form.value.departureMin)) return { use: false };

        // 発日時点で決済代行できるか否か
        const criteria = new DateTimeValue(form.value.departureMin);
        const isSettlementProxyActive = entitlement.active('settlementProxy', criteria);

        return { use: isSettlementProxyActive, confirmed: false };
    };

    return {
        state: {
            form,
            formValidateRules,
            loading,
            dirty,
        },
        initForm,
        register,
    };
};
