import { FormModel } from 'ant-design-vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as helpTypes from '@/vuex/modules/help/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
// @ts-ignore
import CompanyNameEdit from '@/components/Inquiry/Edit/TargetCompanyName';
// @ts-ignore
import StaffNameEdit from '@/components/Inquiry/Edit/TargetStaffName';
// @ts-ignore
import DescriptionEdit from '@/components/Inquiry/Edit/Description';
// @ts-ignore
import CompanyName from '@/components/Inquiry/View/TargetCompanyName';
// @ts-ignore
import StaffName from '@/components/Inquiry/View/TargetStaffName';
// @ts-ignore
import Description from '@/components/Inquiry/View/Description';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: 'その他お問い合わせ・ご意見送信',
    components: {
        CompanyNameEdit,
        StaffNameEdit,
        DescriptionEdit,
        CompanyName,
        StaffName,
        Description,
    },
    beforeRouteEnter: InquiryPage.beforeRouteEnter,
})
export default class InquiryPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    subTitle = 'ご利用に関してのお問い合わせ・ご意見を送信できます。';
    loading = false;
    dirty = false;

    form: helpTypes.InquiryForm = {
        description: '',
    };

    state: 'form' | 'confirm' | 'complete' = 'form';

    get isTargetNameInputted(): boolean {
        return !!this.form.targetCompanyName?.length || !!this.form.targetStaffName?.length;
    }

    // ======================================================
    // Functions
    // ======================================================
    @Watch('form')
    onFormChange(): void {
        this.dirty = true;
    }

    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    onConfirm(): void {
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate((result) => {
            if (!result) return;
            this.state = 'confirm';
        });
    }

    /**
     * 戻るボタンが押下された際に呼び出される。
     */
    onBack(): void {
        this.state = 'form';
    }

    /**
     * 送信ボタンが押下された際に呼び出される。
     */
    async onSubmit(): Promise<void> {
        const notifyFailedKey = 'INQUIRE_FAILED';
        const onSuccess = () => (this.state = 'complete');

        const onError = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: 'お問い合わせの送信ができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        this.loading = true;
        this.$notification.close(notifyFailedKey);

        await InquiryPage.inquire(this.form).then(onSuccess).catch(onError);

        this.dirty = false;
        this.loading = false;
    }

    async beforeRouteLeave(_to: VueRoute, _: VueRoute, next: NavigationGuardNext<InquiryPage>): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<InquiryPage>
    ): Promise<void> {
        await InquiryPage.loadMyProfile()
            .then(() => next())
            .catch(() => next({ name: 'NotFound' }));
    }

    /**
     * 自分の企業プロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * お問い合わせを送信します。
     */
    private static inquire(param: helpTypes.InquiryForm): Promise<void> {
        return store.dispatch(`help/${helpTypes.ACTION.SUBMIT_INQUIRY}`, param);
    }
}
