import { Component, Prop, Vue } from 'vue-property-decorator';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { DistributionLevel, DistributionLevelCode, PrefectureDistributions } from './types';

/**
 * 4段階でレベル分けされた都道府県別の分布図を作成するUIコンポーネント
 */
@Component
export default class PrefectureDistributionMap extends Vue {

    @Prop()
    declare readonly prefectureDistributions?: PrefectureDistributions;

    prefectureEnum = PrefectureEnum;

    /**
     * 分布レベルを取得する
     */
    getLevel(code: PrefectureEnumCode): DistributionLevelCode {
        return this.prefectureDistributions?.[code]?.code ?? DistributionLevel.None.code;
    }
}
