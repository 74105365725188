import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { Const } from '@/const';

export type DistributionLevelCode = 'high' | 'middle' | 'low' | 'none';

/**
 * 分布レベル
 */
export class DistributionLevel {
    constructor(public code: DistributionLevelCode) {
    }

    /**
     * 指定したコードに対応するDistributionLevelを取得します
     */
    static codeOf(code: DistributionLevelCode): DistributionLevel {
        return new DistributionLevel(code);
    }

    /**
     * 指定した値に対するDistributionLevelを取得します。
     */
    static valueOf(value: number): DistributionLevel {
        const hit = Const.companyAchievementThreshold.find(each => (value >= each.threshold));
        if (!hit) return DistributionLevel.None;

        return DistributionLevel.codeOf(hit.code);
    }

    static High = new DistributionLevel('high');
    static Middle = new DistributionLevel('middle');
    static Low = new DistributionLevel('low');
    static None = new DistributionLevel('none');
}

export type PrefectureDistributions = { [code in PrefectureEnumCode]: DistributionLevel };
