import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AgreementUtil, DateUtil } from '@/util';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { Const } from '@/const';
import * as accountTypes from '@/vuex/modules/account/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import * as formDirtyTypes from '@/vuex/modules/formDirty/types';
import * as deliveryOrderTypes from '@/vuex/modules/deliveryOrder/types';
// @ts-ignore
import AgreementDetailView from '@/components/Agreement/View';
// @ts-ignore
import CompanySummary from '@/components/Company/Summary';
// @ts-ignore
import AgreementEditDrawer from '@/components/Agreement/Edit';
// @ts-ignore
import GuaranteeTable from '@/components/Agreement/GuaranteeTable';
import store from '@/vuex/store';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { Modal } from 'ant-design-vue';
import { Karte } from '@/karte';
import { BaggageShapeEnum } from '@/enums/baggage-shape.enum';
import { confirmAgreementChange } from '@/pages/Baggage/List/drawers/AgreementDetailDrawerContent/helper';
import { useAgreementStabilize } from '@/composables/agreement';
import { CompanyContractModel } from '@/models/company-contract';
import { useRouting } from '@/composables/helper/routing';

const accountMod = namespace('account');
const agreementMod = namespace('agreement');
const companyMod = namespace('company');
const formDirtyMod = namespace('formDirty');
const deliveryOrderMod = namespace('deliveryOrder');

@Component({
    components: {
        AgreementDetailView,
        CompanySummary,
        AgreementEditDrawer,
        GuaranteeTable,
    },
})
export default class AgreementDetailDrawerContent extends Vue {
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly MY_COMPANY_PROFILE?: companyTypes.CompanyProfile;
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT)
    readonly AGREEMENT?: agreementTypes.Agreement;
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT_CHANGE_REQUEST)
    readonly AGREEMENT_CHANGE_REQUEST?: agreementTypes.AgreementChangeRequest;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @companyMod.Getter(companyTypes.GETTER.PAST_CONTRACT_LIST)
    readonly PAST_CONTRACT_LIST?: CompanyContractListModel;
    @companyMod.Getter(companyTypes.GETTER.EASY_PAYMENT_CONTRACT_LIST)
    readonly EASY_PAYMENT_CONTRACT_LIST?: CompanyContractModel[];
    @formDirtyMod.Action(formDirtyTypes.ACTION.SET_FORM_DIRTY)
    readonly setFormDirty!: formDirtyTypes.setFormDirty;
    @agreementMod.Getter(agreementTypes.GETTER.AGREEMENT_PARTNER_INFO)
    readonly AGREEMENT_PARTNER_INFO?: agreementTypes.AgreementPartnerInfo;
    @deliveryOrderMod.Getter(deliveryOrderTypes.GETTER.DELIVERY_ORDER_EXIST)
    readonly DELIVERY_ORDER_EXIST?: boolean;

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreementId?: string;
    @Prop()
    declare readonly contracts?: companyTypes.CompanyContract[];
    @Prop()
    declare readonly guaranteedAmount?: companyTypes.CompanyGuaranteedAmount;
    @Prop()
    declare readonly guaranteePriceMaster?: guaranteeTypes.GuaranteePriceMaster[];
    @Prop({ default: false })
    declare readonly visible: boolean;
    @Prop({ default: false })
    declare readonly editorVisible: boolean;
    appServiceName = Const.APP_SERVICE_NAME;
    guaranteePageUrl = Const.externalPageUrl.guarantee;
    activeTabKey: 'agreement' | 'company' = 'agreement';
    agreementToggleGuaranteeModalVisibility = false;
    actionSubmitting = false;
    guaranteeToggleSubmitting = false;
    notificationKey = 'AGREEMENT_UPDATE_FAILED';
    loadingStabilizing = false;
    isMenuOpen = false;

    get loading(): boolean {
        return !this.AGREEMENT || this.loadingStabilizing;
    }

    get loadingCompany(): boolean {
        return !this.partnerCompanyProfile;
    }

    /**
     * 荷物IDを取得します。
     */
    get baggageId(): number {
        if (!this.AGREEMENT) throw new Error('agreement is not defined.');
        return this.AGREEMENT.baggageId;
    }

    /**
     * 相手先企業IDを取得します。
     * 自社の荷物の場合は、運送企業ID、
     * 受託した荷物の場合は、荷主企業IDが返却されます。
     */
    get partnerCompanyId(): number {
        if (!this.AGREEMENT) {
            throw new Error('agreement is not defined.');
        } else if (!this.ACCOUNT_PROFILE) {
            throw new Error('profile is not defined.');
        }
        // 自社の荷物の場合
        if (this.AGREEMENT.baggageCompanyId === this.ACCOUNT_PROFILE.companyId) {
            return this.AGREEMENT.truckCompanyId; // 運送企業ID
        } else if (this.AGREEMENT.truckCompanyId === this.ACCOUNT_PROFILE.companyId) {
            return this.AGREEMENT.baggageCompanyId; // 荷主企業ID
        } else {
            throw new Error('company cannot be specified.');
        }
    }

    /**
     * 変更申請中であるか否かを取得します。
     */
    get hasChanged(): boolean | undefined {
        return (
            this.AGREEMENT &&
            this.AGREEMENT.status.code === 'CHANGE' &&
            AgreementUtil.isWithinChangeDeadline(this.AGREEMENT)
        );
    }

    /**
     * 取消申請中であるか否かを取得します。
     */
    get hasRevoked(): boolean | undefined {
        return (
            this.AGREEMENT &&
            this.AGREEMENT.status.code === 'REVOKE' &&
            AgreementUtil.isWithinChangeDeadline(this.AGREEMENT)
        );
    }

    /**
     * 変更可能であるか否かを取得します。
     */
    get canEdit(): boolean {
        return this.AGREEMENT != null && AgreementUtil.canEdit(this.AGREEMENT);
    }

    /**
     * 取消可能であるか否かを取得します。
     */
    get canRevoke(): boolean {
        return this.AGREEMENT != null && AgreementUtil.canEdit(this.AGREEMENT);
    }

    /**
     * 承認可能であるか否かを取得します。
     */
    get canApprove(): boolean {
        if (!this.AGREEMENT_CHANGE_REQUEST || !this.ACCOUNT_PROFILE) return false;
        return this.AGREEMENT_CHANGE_REQUEST.actorCompanyId != this.ACCOUNT_PROFILE.companyId;
    }

    /**
     * 却下可能であるか否かを取得します。
     */
    get canReject(): boolean {
        if (!this.AGREEMENT_CHANGE_REQUEST || !this.ACCOUNT_PROFILE) return false;
        return this.AGREEMENT_CHANGE_REQUEST.actorCompanyId != this.ACCOUNT_PROFILE.companyId;
    }

    /**
     * 取下げ可能であるか否かを取得します。
     */
    get canCancel(): boolean {
        if (!this.AGREEMENT_CHANGE_REQUEST || !this.ACCOUNT_PROFILE) return false;
        return this.AGREEMENT_CHANGE_REQUEST.actorCompanyId == this.ACCOUNT_PROFILE.companyId;
    }

    /**
     * 運賃全額保証を変更可能か否かを取得します。
     */
    get canToggleGuarantee(): boolean {
        const canToggle = () => {
            if (!this.AGREEMENT) {
                return false;
            }
            if (this.AGREEMENT.guarantee) {
                // 「利用あり」から「利用なし」の場合
                return true;
            } else {
                // 「利用あり」から「利用なし」の場合
                // 運賃全額保証を利用可能な入金予定日の範囲を取得
                const range = AgreementUtil.availablePaymentDateRange(
                    DateUtil.parseDatetimeText(this.AGREEMENT.arrivalMax),
                    true
                );
                return DateUtil.isIncluded(DateUtil.parseDateText(this.AGREEMENT.paymentDate), range);
            }
        };
        return (
            this.AGREEMENT != null &&
            this.AGREEMENT.status.code !== 'DELETE' &&
            DateUtil.parseDatetimeText(this.AGREEMENT.entryTm).add(5, 'day').startOf('day').isAfter(DateUtil.now()) &&
            this.guaranteeContracted &&
            canToggle()
        );
    }

    /**
     * 私は運送会社ですか？
     */
    get iAmTransporter(): boolean {
        if (!this.AGREEMENT || !this.ACCOUNT_PROFILE) return false;
        return this.AGREEMENT.truckCompanyId === this.ACCOUNT_PROFILE.companyId;
    }

    get toggleGuaranteeText(): string {
        if (!this.AGREEMENT) return '';
        return this.AGREEMENT.guarantee ? '利用しない' : '利用する';
    }

    get isAgreementCurrentMonth(): boolean {
        if (!this.AGREEMENT) return false;
        const date = DateUtil.parseDatetimeText(this.AGREEMENT.entryTm);
        const now = DateUtil.now();
        return date.year() === now.year() && date.month() === now.month();
    }

    /**
     * 私は荷主ですか？
     */
    get iAmBaggageCompany(): boolean {
        return !this.iAmTransporter;
    }

    /**
     * 依頼書にアクセスできるか判定します。
     */
    get canAccessDeliveryOrder(): boolean {
        if (this.iAmBaggageCompany) return true;
        if (this.iAmTransporter && this.DELIVERY_ORDER_EXIST) return true;

        return false;
    }

    /**
     * 運賃全額保証サービス加入済みか否かを取得します。（成約当初、および現在の双方で契約が必要）
     */
    get guaranteeContracted(): boolean {
        if (!this.AGREEMENT) return false;
        if (!this.CONTRACT_LIST || !this.PAST_CONTRACT_LIST) return false;

        const entryTm = DateUtil.parseDatetimeText(this.AGREEMENT.entryTm);

        const hasContract = this.CONTRACT_LIST.contracts.some(
            (each) => each.isGuarantee && each.isAlive(DateUtil.now())
        );
        const hadContract = this.PAST_CONTRACT_LIST.contracts.some((each) => each.isGuarantee && each.isAlive(entryTm));

        return hasContract && hadContract;
    }

    /**
     * 契約情報を取得します。
     */
    get companyContracts(): companyTypes.CompanyContract[] {
        return this.CONTRACT_LIST?.contracts ?? [];
    }

    /**
     * 成約パートナー企業のプロフィールを取得します。
     */
    get partnerCompanyProfile(): companyTypes.CompanyProfile | undefined {
        return this.AGREEMENT_PARTNER_INFO?.profile;
    }

    /**
     * 成約パートナー企業の法人データを取得します。
     */
    get partnerOfficialCompany(): companyTypes.OfficialCompany | undefined {
        return this.AGREEMENT_PARTNER_INFO?.officialCompany;
    }

    /**
     * 成約パートナー企業の信用情報を取得します。
     */
    get partnerCompanyConfidence(): companyTypes.CompanyConfidence | undefined {
        return this.AGREEMENT_PARTNER_INFO?.confidence;
    }

    /**
     * 成約パートナー企業の統計情報を取得します。
     */
    get partnerCompanyStatistics(): companyTypes.CompanyStatistics | undefined {
        return this.AGREEMENT_PARTNER_INFO?.statistics;
    }

    /**
     * 変更／取消申請の有効期限
     */
    get formattedChangeLimitDateTime(): string | undefined {
        if (this.AGREEMENT === undefined) {
            return undefined;
        }
        return DateUtil.parseDatetimeText(this.AGREEMENT.changeLimitTm).format(Const.DEFAULT_SHORT_DATETIME_WITH_DAY_OF_WEEK_FORMAT);
    }

    // ======================================================
    // Functions - UI Manipulation
    // ======================================================
    /**
     * タブコンテンツを最上部へスクロールします。
     */
    private scrollToContentTop(): void {
        const component = this.$refs.agreementDetailTabs as Vue | undefined;
        if (!component) {
            return;
        }
        const el = component.$el.getElementsByClassName('ant-tabs-content')[0] as HTMLDivElement | undefined;
        if (!el) {
            return;
        }
        el.scrollTop = 0;
    }

    /**
     * ドロワーを閉じます。
     */
    private async closeDrawer(): Promise<void> {
        await this.$router.push({ path: this.$route.path });
    }

    // ======================================================
    // Functions - UI Event Handler
    // ======================================================
    /**
     * タブが切り替わった際に呼び出されます。
     */
    async onChangeTab(tabName: 'agreement' | 'company'): Promise<void> {
        this.activeTabKey = tabName;
        this.$nextTick(() => this.scrollToContentTop());
    }

    /**
     * フォームがdirty状態になった際に呼び出されます。
     * @param value
     */
    async onChangeFormDirty(value: boolean): Promise<void> {
        await this.setFormDirty(value);
    }

    /**
     * 子ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseChildDrawer(): Promise<void> {
        const query = _.omit(this.$route.query, 'edit');
        await this.$router.push({ path: this.$route.path, query });
    }

    /**
     * 帰りの空車を登録するボタンが押下された際に呼び出されます。
     */
    async onClickRegisterReturnTruck(): Promise<void> {
        if (!this.AGREEMENT) return;
        const { goToTruckRegister } = useRouting();
        await goToTruckRegister(undefined, undefined, this.AGREEMENT.id);
    }

    /**
     * 依頼書ボタンが押下された際に呼び出されます。
     */
    async onClickDisplayDeliveryOrder(agreementId: number): Promise<void> {
        if (!this.AGREEMENT) return;

        const route = this.$router.resolve({
            name: 'DeliveryOrderPreview',
            params: { agreementId: agreementId.toString() }
        });
        window.open(route.href, '_blank');
    }

    /**
     * 成約の変更ボタンが押下された際に呼び出されます。
     */
    async onClickChangeAgreement(): Promise<void> {
        this.closeMenu();
        if (!this.AGREEMENT) return;

        const query = _.set(_.clone(this.$route.query), 'edit', true);
        await this.$router.push({ path: this.$route.path, query });
    }

    /**
     * 成約の取消ボタンが押下された際に呼び出されます。
     */
    async onClickRevokeAgreement(agreementId: number): Promise<void> {
        this.closeErrorNotification();
        this.closeMenu();

        // 取り消し確認ダイアログを表示
        if (!await this.confirmRevokingAgreement()) return;

        // Actionを実行するとStoreの値が空になり値が取れなくなるため代入
        const iAmTransporter = this.iAmTransporter;

        const trackCancelAgreement = () => {
            if (iAmTransporter) Karte.trackRequestCancelAgreement(agreementId);
            else Karte.trackCompleteCancelAgreement(agreementId);
        };

        const onSuccess = () => {
            // 成功メッセージを通知
            this.$message.success(`成約の取り消し${ iAmTransporter ? '申請' : '' }を行ないました。`);

            this.$emit('reloadList');
        };

        const onError = () =>
            this.showErrorNotification(`問題が発生したため、成約の取り消し${ iAmTransporter ? '申請' : '' }を行なえませんでした。`);

        this.actionSubmitting = true;

        await AgreementDetailDrawerContent.revokeAgreement(agreementId)
            .then(onSuccess)
            .then(trackCancelAgreement)
            .then(this.onRevokeAgreementCompleted)
            .catch(onError);

        this.actionSubmitting = false;
    }

    /**
     * 荷物のコピーボタンが押下された際に呼び出されます。
     */
    onClickCopyBaggage(): void {
        this.$emit('copyBaggage', this.baggageId);
    }

    /**
     * 成約の印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrintAgreement(agreementId: number): void {
        const route = this.$router.resolve({ name: 'AgreementPrint', params: { agreementId: agreementId.toString() } });
        window.open(route.href, '_blank');
    }

    /**
     * ボタンメニューを閉じます。
     */
    private closeMenu(): void {
        this.isMenuOpen = false;
    }

    /**
     * 成約の変更申請ボタンが押下された際に呼び出されます。
     */
    async onClickSubmitAgreementChange(param: {
        agreementId: number;
        form: agreementTypes.AgreementUpdateForm;
    }): Promise<void> {
        if (!this.agreementId || Number(this.agreementId) !== param.agreementId) {
            return;
        }

        this.closeErrorNotification();

        const trackChangeAgreement = () => {
            // KARTEイベント送信： 成約変更申請
            Karte.trackRequestChangeAgreement(param.agreementId);
        };

        const onSuccess = async () => {
            this.$message.success('成約の変更申請を行ないました。');

            await this.setFormDirty(false);
            this.$emit('reloadList');
        };

        const onError = () => this.showErrorNotification('問題が発生したため、成約の変更申請が行なえませんでした。');

        // 成約変更注意喚起モーダルを表示
        if (!await confirmAgreementChange()) {
            return;
        }

        this.actionSubmitting = true;

        if (param.form.shape?.code === BaggageShapeEnum.Palette.code) {
            param.form.totalCount = undefined;
            param.form.totalVolume = undefined;
        } else if (param.form.shape?.code === BaggageShapeEnum.Other.code) {
            param.form.paletteCount = undefined;
            param.form.paletteHeight = undefined;
            param.form.paletteWidth = undefined;
        }

        await AgreementDetailDrawerContent.updateAgreement(param.agreementId, param.form)
            .then(onSuccess)
            .then(trackChangeAgreement)
            .then(this.onChangeAgreementCompleted)
            .catch(onError);

        this.actionSubmitting = false;
    }

    /**
     * 成約変更リクエストの承認ボタンが押下された際に呼び出されます。
     */
    async onClickApproveAgreementChange(agreementId: number): Promise<void> {
        this.closeErrorNotification();

        // KARTEイベント送信： 成約変更/取消完了
        const trackApproveAgreement = () => {
            if (this.hasRevoked) Karte.trackCompleteCancelAgreement(agreementId);
            else Karte.trackCompleteChangeAgreement(agreementId);
        };

        const onSuccess = () => {
            this.$message.success('成約変更申請を承認しました。');

            this.$emit('reloadList');
        };

        const onError = () => this.showErrorNotification('問題が発生したため、成約変更申請の承認を行なえませんでした。');

        this.actionSubmitting = true;

        await AgreementDetailDrawerContent.approveAgreementChange(agreementId)
            .then(onSuccess)
            .then(trackApproveAgreement)
            .then(this.onApproveAgreementCompleted)
            .catch(onError);

        this.actionSubmitting = false;
    }

    /**
     * 成約変更リクエストの却下ボタンが押下された際に呼び出されます。
     */
    async onClickRejectAgreementChange(agreementId: number): Promise<void> {
        this.closeErrorNotification();

        const onSuccess = async () => {
            this.$message.success('成約変更申請を却下しました。');

            this.$emit('reloadList');

            await this.$router.push({ path: this.$route.path });
        };

        const onError = () => this.showErrorNotification('問題が発生したため、成約変更申請の却下を行なえませんでした。');

        this.actionSubmitting = true;

        await AgreementDetailDrawerContent.rejectAgreementChange(agreementId)
            .then(onSuccess)
            .catch(onError);

        this.actionSubmitting = false;
    }

    /**
     * 成約変更リクエストの取下ボタンが押下された際に呼び出されます。
     */
    async onClickCancelAgreementChange(agreementId: number): Promise<void> {
        this.closeErrorNotification();

        const onSuccess = async () => {
            this.$message.success('成約変更申請を取り下げました。');

            this.$emit('reloadList');

            await this.$router.push({ path: this.$route.path });
        };

        const onError = () => this.showErrorNotification('問題が発生したため、成約変更申請の取下が行なえませんでした。');

        this.actionSubmitting = true;

        await AgreementDetailDrawerContent.cancelAgreementChange(agreementId)
            .then(onSuccess)
            .catch(onError);

        this.actionSubmitting = false;
    }

    async onStabilize(): Promise<void> {
        if (!this.AGREEMENT) return;
        const { stabilize } = useAgreementStabilize();
        try {
            this.actionSubmitting = true;
            this.loadingStabilizing = true;
            await stabilize(this.AGREEMENT.id);
            this.$message.success('請求に追加されました。');
            this.$emit('reloadList');
            await this.$router.push({ path: this.$route.path });
        } finally {
            this.actionSubmitting = false;
            this.loadingStabilizing = false;
        }
    }

    /**
     * 成約の運賃全額保証変更ボタンが押下された際に呼び出されます。
     */
    onClickShowAgreementToggleGuarantee(): void {
        this.agreementToggleGuaranteeModalVisibility = true;
    }

    /**
     * 成約の運賃全額保証変更モーダルのキャンセルボタンが押下された際に呼び出されます。
     */
    onClickCancelAgreementToggleGuarantee(): void {
        this.agreementToggleGuaranteeModalVisibility = false;
    }

    /**
     * 成約の運賃全額保証変更モーダルの保存ボタンが押下された際に呼び出されます。
     */
    async onClickOkAgreementToggleGuarantee(): Promise<void> {
        this.agreementToggleGuaranteeModalVisibility = false;

        if (!this.AGREEMENT) return;

        const enable = !this.AGREEMENT.guarantee;

        const onSuccess = async () => {
            this.$message.success(`運賃全額保証サービスの利用設定を「${ enable ? '利用する' : '利用しない' }」に変更しました。`);

            this.$emit('reloadList');

            // NOTE: 本当はDrawerを閉じないインタラクションとしたい
            await this.$router.push({ path: this.$route.path });
        };

        const onError = () => this.$notification.error({ message: '運賃全額保証の利用を変更できませんでした。', description: '' });

        this.guaranteeToggleSubmitting = true;

        await AgreementDetailDrawerContent.toggleGuarantee(this.AGREEMENT.id, { enable })
            .then(onSuccess)
            .catch(onError);

        this.guaranteeToggleSubmitting = false;
    }

    /**
     * 荷物の取下げボタン(再度掲載しない)が押下された際に呼び出されます。
     */
    private async onClickCancelBaggage(): Promise<void> {
        const onSuccess = () => {
            this.$message.success('荷物を削除しました。');
        };

        await AgreementDetailDrawerContent.cancelBaggage(this.baggageId)
            .then(onSuccess)
            .catch(() => ({/* ignore error */ }));
    }

    // ======================================================
    // Functions - Business Event Handler
    // ======================================================
    /**
     * 成約の取り消しが完了した際に呼び出されます。
     */
    private async onRevokeAgreementCompleted(): Promise<void> {
        // 荷物の再掲載確認
        if (await this.confirmCancelBaggage()) {
            await this.onClickCancelBaggage();
        }

        // 運送会社ならここで終了
        if (this.iAmTransporter) {
            await this.$router.push({ path: this.$route.path });
            return;
        }
    }

    /**
     * 成約の変更が完了した際に呼び出されます。
     */
    private async onChangeAgreementCompleted(): Promise<void> {
        await this.$router.push({ path: this.$route.path });
    }

    /**
     * 成約変更リクエストの承認が完了した際に呼び出されます。
     */
    private async onApproveAgreementCompleted(): Promise<void> {
        // 荷物の再掲載確認
        if (this.hasRevoked && await this.confirmCancelBaggage()) {
            await this.onClickCancelBaggage();
        }

        // 運送会社ならここで終了
        if (this.iAmTransporter) {
            await this.$router.push({ path: this.$route.path });
            return;
        }
    }

    // ======================================================
    // Functions - Notification
    // ======================================================
    /**
     * エラーNotificationを表示します。
     * @param message
     */
    private showErrorNotification(message: string): void {
        this.$notification.error({
            key: this.notificationKey,
            message,
            description:
                '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        });
    }

    /**
     * エラーNotificationの表示を閉じます。
     */
    private closeErrorNotification(): void {
        this.$notification.close(this.notificationKey);
    }

    // ======================================================
    // Functions - Modal
    // ======================================================
    /**
     * 成約取消確認モーダルを表示します。
     */
    private confirmRevokingAgreement(): Promise<boolean> {
        const { title, content } = (this.iAmTransporter) ? {
            title: 'この成約を取り消しますか？',
            content: '成約を取り消したい旨を相手先企業に通知します。相手先企業が承認すると、成約の取り消しが確定します。',
        } : {
            title: 'この成約を取り消しますか？',
            content: '成約を取り消すと、相手先企業にも取り消した旨が通知され、この成約は無効となります。また、この操作は元に戻すことができません。',
        };

        return new Promise<boolean>((resolve) => Modal.confirm({
            title,
            content,
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: '成約を取り消す',
            cancelText: 'キャンセル',
        }));
    }

    /**
     * 荷物の取下確認モーダルを表示します。(true:取下げる false:取下げない)
     */
    private async confirmCancelBaggage(): Promise<boolean> {
        if (this.iAmTransporter) return false;

        return new Promise<boolean>((resolve) => Modal.confirm({
            title: 'この荷物を再度掲載しますか？',
            content: '',
            onOk: () => resolve(false),
            onCancel: () => resolve(true),
            okText: '再度掲載する',
            cancelText: '掲載しない',
        }));
    }

    // ======================================================
    // Functions - Data Manipulation
    // ======================================================
    /**
     * 成約を更新します。
     */
    private static updateAgreement(id: number, param: agreementTypes.AgreementUpdateForm) {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.UPDATE_AGREEMENT }`, { id, param });
    }

    /**
     * 成約を取り消します。
     */
    private static revokeAgreement(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.REVOKE_AGREEMENT }`, id);
    }

    /**
     * 成約変更リクエストを承認します。
     */
    private static approveAgreementChange(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.APPROVE_AGREEMENT_CHANGE }`, id);
    }

    /**
     * 成約変更リクエストを却下します。
     */
    private static rejectAgreementChange(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.REJECT_AGREEMENT_CHANGE }`, id);
    }

    /**
     * 成約変更リクエストを取下げます。
     */
    private static cancelAgreementChange(id: number): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.CANCEL_AGREEMENT_CHANGE }`, id);
    }

    /**
     * 運賃全額保証を変更します。
     */
    private static toggleGuarantee(id: number, param: agreementTypes.AgreementToggleGuaranteeForm): Promise<void> {
        return store.dispatch(`agreement/${ agreementTypes.ACTION.TOGGLE_GUARANTEE }`, { id, param });
    }

    /**
     * 荷物を取り下げます。
     */
    protected static cancelBaggage(id: number): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.CANCEL_BAGGAGE }`, id);
    }
}
