import { computed } from 'vue';
import { DateTimeValue } from '@/models/vo/datetime';
import { useEntitlement } from '@/composables/entitlement';
import { useRouter } from 'vue-router/composables';
import { Const } from '@/const';
import _ from 'lodash';

export const useHomeHelper = () => {
    const { state: { entitlement } } = useEntitlement();
    const router = useRouter();

    const now = computed(() => DateTimeValue.now());
    const activeLock = computed(() => entitlement.value?.activeLock(now.value));
    const contactInfo = computed(() => {
        const location = router.resolve({ name: 'Inquiry' });
        return {
            ..._.pick(Const.supportDeskContacts, ['phone', 'email']),
            inquiryUrl: new URL(location.href, window.location.origin).href,
        };
    });

    return {
        activeLock,
        contactInfo,
    };
};
