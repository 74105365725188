//
// Action
//
export class ACTION {
    static readonly LOAD_ENVIRONMENT = 'LOAD_ENVIRONMENT';
}

//
// Getter
//
export class GETTER {
    static readonly ENVIRONMENT = 'ENVIRONMENT';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_ENVIRONMENT = 'SET_ENVIRONMENT';
}

//
// Store
//
export interface EnvironmentState {
    environment?: Environment;
}

//
// Type for store
//

export interface Environment {
    traboxDispatchUrl: string;
    traboxOnlineOrderUrl: string;
    ultraboxUrl: string;
    enableTruckScheduler: string;
}
