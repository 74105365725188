<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useAccountEmailVerifyHelper } from '@/_pages/Account/Email/Verify/account-email-verify-helper';

const props = defineProps<{
    verificationCode?: string,
}>();
const {
    loading,
    succeed,
} = useAccountEmailVerifyHelper(props.verificationCode);
</script>

<template>
    <page-layout :show-header="false" :back-icon="false">
        <a-card class="container">
            <h1>メールアドレス認証</h1>
            <a-spin :spinning="loading">
                <div v-if="loading">メールアドレス認証を行っています...</div>
                <div v-else-if="succeed">
                    <p>メールアドレス認証が完了しました。</p>
                    <p>初期パスワードの設定画面に移動します。画面はそのままでお待ちください...</p>
                </div>
                <div v-else>
                    <p>
                        メールアドレス認証に失敗しました。<br />
                        確認メールからもう一度アクセスしなおしてください。
                    </p>
                    <p>
                        何度試しても変わらない場合は、契約管理者にお問い合わせください。
                    </p>
                </div>
            </a-spin>
        </a-card>
    </page-layout>
</template>

<style scoped lang="less">
.container {
    min-width: 600px;
    width: 50vw;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin: 16px 0 24px;
    }
}
</style>
