import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { VNode } from 'vue';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestionItem, QuestionItemOptionAnswer } from '@/vuex/modules/questionnaire/types';

export type QuestionItemAnswerValue =
    | undefined
    | string
    | QuestionItemOptionAnswer
    | Array<QuestionItemOptionAnswer | undefined>;

@Component
export default class QuestionnaireForm extends Vue {
    @Prop()
    declare readonly questions: Array<QuestionItem>;
    @Prop()
    declare readonly value: Array<QuestionItemAnswerValue>;

    created(): void {
        if (!this.questions || !Array.isArray(this.questions)) {
            throw new Error('設問が定義されていません。');
        }
    }

    /**
     * FormModelItemで用いるrefNameを取得します。
     */
    getFormModelItemRefName(questionIndex: number): string {
        return `answers.${questionIndex}`;
    }

    /**
     * 設問の回答値を取得します。
     */
    getSelectedAnswer(questionIndex: number): Array<string> | string | undefined {
        const question = this.questions[questionIndex];
        switch (question.type) {
            case 'multiple-select': {
                const answers = this.value[questionIndex] as Array<QuestionItemOptionAnswer | undefined>;
                return answers.filter((v): v is Exclude<typeof v, undefined> => v !== undefined).map((each) => each.id);
            }
            case 'single-select': {
                const answer = this.value[questionIndex] as QuestionItemOptionAnswer | undefined;
                return answer?.id ?? undefined;
            }
            case 'text': {
                return (this.value[questionIndex] as string | undefined) ?? '';
            }
        }
    }

    /**
     * 設問の回答にある自由記述テキストを取得します。
     */
    getAnswerFreeText(questionIndex: number, optionId?: string): string {
        const question = this.questions[questionIndex];
        switch (question.type) {
            case 'multiple-select': {
                const answer = this.value[questionIndex] as Array<QuestionItemOptionAnswer | undefined> | undefined;
                if (!answer) return '';
                const answerOption = answer.find((each) => each && each.id === optionId);
                return answerOption?.text ?? '';
            }
            case 'single-select': {
                const answer = this.value[questionIndex] as QuestionItemOptionAnswer | undefined;
                if (!answer) {
                    return '';
                }
                const allowsFreeText = this.questions[questionIndex].options?.find((each) => each.id === answer.id)
                    ?.allowsFreeText;
                return allowsFreeText && answer.text ? answer.text : '';
            }
            case 'text': {
                return (this.value[questionIndex] as string | undefined) ?? '';
            }
        }
    }

    /**
     * 設問に応じたバリデーションルールを取得します。
     */
    getValidationRule(questionIndex: number): Array<ValidationRule> {
        return [
            {
                required: this.questions[questionIndex].required,
                // eslint-disable-next-line @typescript-eslint/ban-types
                validator: (_rule: unknown, value: QuestionItemAnswerValue, callback: Function) =>
                    this.validate(questionIndex, value, callback as (message?: string) => void),
            },
        ];
    }

    /**
     * フリーテキスト入力領域をdisabledにするかどうかを判定します。
     */
    isDisabledFreeText(questionIndex: number, optionId?: string): boolean {
        const question = this.questions[questionIndex];
        if (!question || !question.options) {
            return false;
        }
        const selectedAnswer = this.getSelectedAnswer(questionIndex);
        if (question.type === 'single-select') {
            const option = question.options.find((each) => each.id === selectedAnswer);
            return !option || !option.allowsFreeText;
        } else if (question.type === 'multiple-select' && optionId) {
            const option = question.options.find((each) => each.id === optionId);
            return !(option && option.allowsFreeText && (selectedAnswer as Array<string>).includes(optionId));
        }
        return false;
    }

    /**
     * 複数選択プルダウンメニューのチェックボックスを変更した際に呼び出されます。
     */
    onInputMultiSelect(questionIndex: number, value: Array<string>): void {
        const cloned = _.cloneDeep(this.value);
        cloned[questionIndex] = value.map((id) => ({ id, text: undefined }));
        this.$emit('input', cloned);
        this.changeField(questionIndex);
    }

    /**
     * 単一選択プルダウンメニューの値を変更した際に呼び出されます。
     */
    onChangeSingleSelect(optionId: string, options: VNode): void {
        const questionIndex = options.data?.attrs?.index ?? undefined;
        if (!questionIndex) {
            throw new Error('questionIndex is not defined.');
        }
        const cloned = _.cloneDeep(this.value);
        cloned[questionIndex] = { id: optionId, text: undefined };
        this.$emit('input', cloned);
        this.changeField(questionIndex);
    }

    /**
     * 自由記述欄を入力した際に呼び出されます。
     */
    onInputFreeText(event: InputEvent, questionIndex: number, optionId?: string): void {
        const question = this.questions[questionIndex];
        const cloned = _.cloneDeep(this.value);
        const clonedAnswer = cloned[questionIndex];
        switch (question.type) {
            case 'multiple-select': {
                if (!Array.isArray(clonedAnswer)) {
                    return;
                }
                const answerIndex = clonedAnswer.findIndex((v) => v && v.id === optionId);
                if (answerIndex < 0) {
                    return;
                }
                (clonedAnswer as QuestionItemOptionAnswer[])[
                    answerIndex
                ].text = (event.target as HTMLInputElement).value;
                cloned[questionIndex] = clonedAnswer;
                break;
            }
            case 'single-select':
                if (this.isDisabledFreeText(questionIndex)) {
                    return;
                }
                (clonedAnswer as QuestionItemOptionAnswer).text = (event.target as HTMLInputElement).value;
                cloned[questionIndex] = clonedAnswer;
                break;
            case 'text':
                cloned[questionIndex] = (event.target as HTMLInputElement).value;
        }
        this.$emit('input', cloned);
        this.changeField(questionIndex);
    }

    /**
     * バリデーションを実行します。
     */
    private validate(
        questionIndex: number,
        _value: QuestionItemAnswerValue,
        callback: (message?: string) => void
    ): void {
        const question = this.questions[questionIndex];
        if (!question) {
            throw new Error(`question is not defined. [questionIndex] => ${questionIndex}`);
        }
        switch (question.type) {
            case 'text': {
                const value = _value as string;
                if (question.required && (!value || value.length === 0)) {
                    callback('回答を入力してください。');
                    return;
                } else if (value.length > 200) {
                    callback('200文字以内で入力してください。');
                    return;
                }
                break;
            }
            case 'single-select': {
                const value = _value as undefined | QuestionItemOptionAnswer;
                if (question.required && value === undefined) {
                    callback('選択肢から回答を選んでください。');
                    return;
                }
                const selected = question.options?.find((each) => each.id === value?.id);
                if (selected && selected.allowsFreeText) {
                    if (selected.required && (!value || !value.text || value.text.length === 0)) {
                        callback(`「${selected.text}」について、内容を入力してください。`);
                        return;
                    } else if (value && value.text && value.text.length > 200) {
                        callback('200文字以内で入力してください。');
                        return;
                    }
                }
                break;
            }
            case 'multiple-select': {
                const value = _value as Array<QuestionItemOptionAnswer | undefined>;
                const selectedOptions = value.filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
                if (question.required && selectedOptions.length === 0) {
                    callback('上記のチェックボックスの中から最適な項目にチェックを入れてください。');
                    return;
                }
                selectedOptions.forEach((each) => {
                    const selected = question.options?.find((option) => option.id === each?.id);
                    if (!selected) {
                        return;
                    }
                    if (selected && selected.allowsFreeText) {
                        if (selected.required && (!each || !each.text || each.text.length === 0)) {
                            callback(`「${selected.text}」の自由記入項目を入力してください。`);
                            return;
                        } else if (each && each.text && each.text.length > 200) {
                            callback(`「${selected.text}」の自由記入項目は、200文字以内で入力してください。`);
                            return;
                        }
                    }
                });
                break;
            }
        }
        callback();
    }

    /**
     * フィールドの値の変更を反映します。
     */
    private changeField(questionIndex: number): void {
        const formItemRef = this.$refs[this.getFormModelItemRefName(questionIndex)];
        if (formItemRef && Array.isArray(formItemRef) && formItemRef.length > 0) {
            // @ts-ignore
            this.$nextTick(() => formItemRef[0].onFieldChange());
        }
    }
}
