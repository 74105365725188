<script setup lang="ts">
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { computed, ref } from 'vue';
import { RegionEnum, RegionEnumCode } from '@/enums/region.enum';
import _ from 'lodash';

const props = withDefaults(defineProps<{
    value?: PrefectureEnumCode[],
    title?: string, // 表示タイトル
    multiple?: boolean, // 地域選択を表示するか否か
}>(), {
    title: '',
});
const emits = defineEmits<{
    (e: 'input', value: PrefectureEnumCode[]): void,
}>();
const regionEnum = RegionEnum;
const prefectureEnum = PrefectureEnum;
const highlightPrefectures = ref<PrefectureEnumCode[]>([]);

const selected = computed<PrefectureEnumCode[]>({
    get: () => props.value ?? [],
    set: (value: PrefectureEnumCode[]): void => {
        emits('input', value);
    },
});

/**
 * 地域を都道府県コードリストへ変換します。
 */
const regionToPrefCodes = (region: RegionEnumCode): PrefectureEnumCode[] => {
    return RegionEnum.valueOf(region)?.prefectures ?? [];
};

/**
 * 都道府県ボタンに対して追加で付与すべきスタイル用Classを取得します。
 */
const getStyleClass = (code: PrefectureEnumCode): { highlight: boolean; selected: boolean } => {
    return {
        highlight: highlightPrefectures.value.includes(code),
        selected: selected.value.includes(code),
    };
};

/**
 * 指定地域が選択状態にあるか否かを取得します。
 */
const isRegionSelected = (code: RegionEnumCode): boolean => {
    return regionToPrefCodes(code).every((each) => selected.value.includes(each));
};

/**
 * 指定地域の都道府県が一部だけ選択状態なっているか否かを取得します。
 */
const isRegionIndeterminate = (code: RegionEnumCode): boolean => {
    if (isRegionSelected(code)) {
        return false;
    }
    return regionToPrefCodes(code).some((each) => selected.value.includes(each));
};

/**
 * 都道府県ボタンがクリックされた際に呼び出されます。
 */
const onClickPrefecture = (code: PrefectureEnumCode): void => {
    if (!props.multiple) {
        selected.value = [code];
        return;
    }

    if (selected.value.includes(code)) {
        selected.value = _.uniq(_.difference(selected.value, [code]));
    } else {
        selected.value = _.uniq(_.concat(selected.value, [code]));
    }
};

/**
 * 地域ボタンがhoverされた際に呼び出されます。
 */
const onMouseOverRegion = (regionCode: RegionEnumCode): void => {
    highlightPrefectures.value = RegionEnum.valueOf(regionCode)?.prefectures ?? [];
};

/**
 * 地域ボタンのhoverが解除された際に呼び出されます。
 */
const onMouseLeaveRegion = (): void => {
    highlightPrefectures.value = [];
};

/**
 * 地域ボタンがクリックされた際に呼び出されます。
 */
const onClickRegion = (event: Event): void => {
    if (!props.multiple) {
        throw new Error('region-select is not allowed when single selection mode.');
    }

    const target = event.target as { checked: boolean; value: RegionEnumCode } | null;
    if (!target) {
        return;
    }

    if (isRegionSelected(target.value)) {
        selected.value = _.uniq(_.difference(selected.value, regionToPrefCodes(target.value)));
    } else {
        selected.value = _.uniq(_.concat(selected.value, regionToPrefCodes(target.value)));
    }
};

/**
 * すべて選択ボタンがクリックされた際に呼び出されます。
 */
const onClickSelectAll = (): void => {
    selected.value = PrefectureEnum.values.map((each) => each.code);
};

/**
 * すべて解除ボタンがクリックされた際に呼び出されます。
 */
const onClickDeselect = (): void => {
    selected.value = [];
};
</script>

<template>
    <div class="container">
        <aside class="side" v-if="multiple">
            <div class="title">
                <template v-if="title">{{ title }}</template>
                <slot v-else name="title"></slot>
            </div>
            <div class="regions">
                <a-space size="small" direction="vertical">
                <span v-for="item in regionEnum.regionValues"
                      :key="item.code"
                      @mouseover="onMouseOverRegion(item.code)"
                      @mouseleave="onMouseLeaveRegion">
                    <a-checkbox :value="item.code"
                                :checked="isRegionSelected(item.code)"
                                :indeterminate="isRegionIndeterminate(item.code)"
                                @change="onClickRegion">{{ item.label }}
                    </a-checkbox>
                </span>
                </a-space>
            </div>
        </aside>
        <section class="japan-map">
            <section class="header">
                <nav class="header__map-buttons" v-if="multiple">
                    <button type="button" class="map-button map-button--primary" :disabled="selected.length === prefectureEnum.values.length" @click="onClickSelectAll()">全国を選択</button>
                    <button type="button" class="map-button map-button--warning" :disabled="selected.length === 0" @click="onClickDeselect()">すべて解除</button>
                </nav>
                <div v-else class="title header__title">
                    <template v-if="title">{{ title }}</template>
                    <slot v-else name="title"></slot>
                </div>
            </section>

            <div class="pref hokkaido"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Hokkaido.code)" @click="onClickPrefecture(prefectureEnum.Hokkaido.code)">{{ prefectureEnum.Hokkaido.shortLabel }}</button></div>
            <!-- row -->
            <div class="umi tsugaru"></div>
            <!-- row -->
            <div class="pref aomori"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Aomori.code)" @click="onClickPrefecture(prefectureEnum.Aomori.code)">{{ prefectureEnum.Aomori.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref akita"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Akita.code)" @click="onClickPrefecture(prefectureEnum.Akita.code)">{{ prefectureEnum.Akita.shortLabel }}</button></div>
            <div class="pref iwate"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Iwate.code)" @click="onClickPrefecture(prefectureEnum.Iwate.code)">{{ prefectureEnum.Iwate.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref ishikawa"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Ishikawa.code)" @click="onClickPrefecture(prefectureEnum.Ishikawa.code)">{{ prefectureEnum.Ishikawa.shortLabel }}</button></div>
            <div class="pref yamagata"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Yamagata.code)" @click="onClickPrefecture(prefectureEnum.Yamagata.code)">{{ prefectureEnum.Yamagata.shortLabel }}</button></div>
            <div class="pref miyagi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Miyagi.code)" @click="onClickPrefecture(prefectureEnum.Miyagi.code)">{{ prefectureEnum.Miyagi.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref fukui"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Fukui.code)" @click="onClickPrefecture(prefectureEnum.Fukui.code)">{{ prefectureEnum.Fukui.shortLabel }}</button></div>
            <div class="pref toyama"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Toyama.code)" @click="onClickPrefecture(prefectureEnum.Toyama.code)">{{ prefectureEnum.Toyama.shortLabel }}</button></div>
            <div class="pref niigata"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Niigata.code)" @click="onClickPrefecture(prefectureEnum.Niigata.code)">{{ prefectureEnum.Niigata.shortLabel }}</button></div>
            <div class="pref fukushima"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Fukushima.code)" @click="onClickPrefecture(prefectureEnum.Fukushima.code)">{{ prefectureEnum.Fukushima.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref shimane"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Shimane.code)" @click="onClickPrefecture(prefectureEnum.Shimane.code)">{{ prefectureEnum.Shimane.shortLabel }}</button></div>
            <div class="pref tottori"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Tottori.code)" @click="onClickPrefecture(prefectureEnum.Tottori.code)">{{ prefectureEnum.Tottori.shortLabel }}</button></div>
            <div class="pref hyogo"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Hyogo.code)" @click="onClickPrefecture(prefectureEnum.Hyogo.code)">{{ prefectureEnum.Hyogo.shortLabel }}</button></div>
            <div class="pref kyoto"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kyoto.code)" @click="onClickPrefecture(prefectureEnum.Kyoto.code)">{{ prefectureEnum.Kyoto.shortLabel }}</button></div>
            <div class="pref shiga"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Shiga.code)" @click="onClickPrefecture(prefectureEnum.Shiga.code)">{{ prefectureEnum.Shiga.shortLabel }}</button></div>
            <div class="pref gifu"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Gifu.code)" @click="onClickPrefecture(prefectureEnum.Gifu.code)">{{ prefectureEnum.Gifu.shortLabel }}</button></div>
            <div class="pref nagano"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Nagano.code)" @click="onClickPrefecture(prefectureEnum.Nagano.code)">{{ prefectureEnum.Nagano.shortLabel }}</button></div>
            <div class="pref gunma"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Gunma.code)" @click="onClickPrefecture(prefectureEnum.Gunma.code)">{{ prefectureEnum.Gunma.shortLabel }}</button></div>
            <div class="pref tochigi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Tochigi.code)" @click="onClickPrefecture(prefectureEnum.Tochigi.code)">{{ prefectureEnum.Tochigi.shortLabel }}</button></div>
            <div class="pref ibaraki"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Ibaraki.code)" @click="onClickPrefecture(prefectureEnum.Ibaraki.code)">{{ prefectureEnum.Ibaraki.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref yamaguchi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Yamaguchi.code)" @click="onClickPrefecture(prefectureEnum.Yamaguchi.code)">{{ prefectureEnum.Yamaguchi.shortLabel }}</button></div>
            <div class="pref hiroshima"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Hiroshima.code)" @click="onClickPrefecture(prefectureEnum.Hiroshima.code)">{{ prefectureEnum.Hiroshima.shortLabel }}</button></div>
            <div class="pref okayama"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Okayama.code)" @click="onClickPrefecture(prefectureEnum.Okayama.code)">{{ prefectureEnum.Okayama.shortLabel }}</button></div>
            <div class="pref osaka"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Osaka.code)" @click="onClickPrefecture(prefectureEnum.Osaka.code)">{{ prefectureEnum.Osaka.shortLabel }}</button></div>
            <div class="pref nara"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Nara.code)" @click="onClickPrefecture(prefectureEnum.Nara.code)">{{ prefectureEnum.Nara.shortLabel }}</button></div>
            <div class="pref yamanashi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Yamanashi.code)" @click="onClickPrefecture(prefectureEnum.Yamanashi.code)">{{ prefectureEnum.Yamanashi.shortLabel }}</button></div>
            <div class="pref saitama"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Saitama.code)" @click="onClickPrefecture(prefectureEnum.Saitama.code)">{{ prefectureEnum.Saitama.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref wakayama"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Wakayama.code)" @click="onClickPrefecture(prefectureEnum.Wakayama.code)">{{ prefectureEnum.Wakayama.shortLabel }}</button></div>
            <div class="pref mie"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Mie.code)" @click="onClickPrefecture(prefectureEnum.Mie.code)">{{ prefectureEnum.Mie.shortLabel }}</button></div>
            <div class="pref aichi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Aichi.code)" @click="onClickPrefecture(prefectureEnum.Aichi.code)">{{ prefectureEnum.Aichi.shortLabel }}</button></div>
            <div class="pref shizuoka"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Shizuoka.code)" @click="onClickPrefecture(prefectureEnum.Shizuoka.code)">{{ prefectureEnum.Shizuoka.shortLabel }}</button></div>
            <div class="pref kanagawa"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kanagawa.code)" @click="onClickPrefecture(prefectureEnum.Kanagawa.code)">{{ prefectureEnum.Kanagawa.shortLabel }}</button></div>
            <div class="pref tokyo"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Tokyo.code)" @click="onClickPrefecture(prefectureEnum.Tokyo.code)">{{ prefectureEnum.Tokyo.shortLabel }}</button></div>
            <div class="pref chiba"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Chiba.code)" @click="onClickPrefecture(prefectureEnum.Chiba.code)">{{ prefectureEnum.Chiba.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref nagasaki"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Nagasaki.code)" @click="onClickPrefecture(prefectureEnum.Nagasaki.code)">{{ prefectureEnum.Nagasaki.shortLabel }}</button></div>
            <div class="pref fukuoka"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Fukuoka.code)" @click="onClickPrefecture(prefectureEnum.Fukuoka.code)">{{ prefectureEnum.Fukuoka.shortLabel }}</button></div>
            <div class="pref ehime"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Ehime.code)" @click="onClickPrefecture(prefectureEnum.Ehime.code)">{{ prefectureEnum.Ehime.shortLabel }}</button></div>
            <div class="pref kagawa"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kagawa.code)" @click="onClickPrefecture(prefectureEnum.Kagawa.code)">{{ prefectureEnum.Kagawa.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref saga"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Saga.code)" @click="onClickPrefecture(prefectureEnum.Saga.code)">{{ prefectureEnum.Saga.shortLabel }}</button></div>
            <div class="pref oita"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Oita.code)" @click="onClickPrefecture(prefectureEnum.Oita.code)">{{ prefectureEnum.Oita.shortLabel }}</button></div>
            <div class="pref kochi"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kochi.code)" @click="onClickPrefecture(prefectureEnum.Kochi.code)">{{ prefectureEnum.Kochi.shortLabel }}</button></div>
            <div class="pref tokushima"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Tokushima.code)" @click="onClickPrefecture(prefectureEnum.Tokushima.code)">{{ prefectureEnum.Tokushima.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref kumamoto"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kumamoto.code)" @click="onClickPrefecture(prefectureEnum.Kumamoto.code)">{{ prefectureEnum.Kumamoto.shortLabel }}</button></div>
            <div class="pref miyazaki"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Miyazaki.code)" @click="onClickPrefecture(prefectureEnum.Miyazaki.code)">{{ prefectureEnum.Miyazaki.shortLabel }}</button></div>
            <!-- row -->
            <div class="pref kagoshima"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Kagoshima.code)" @click="onClickPrefecture(prefectureEnum.Kagoshima.code)">{{ prefectureEnum.Kagoshima.shortLabel }}</button></div>
            <!-- row -->
            <div class="umi shina"></div>
            <!-- row -->
            <div class="pref okinawa"><button type="button" class="map-button" :class="getStyleClass(prefectureEnum.Okinawa.code)" @click="onClickPrefecture(prefectureEnum.Okinawa.code)">{{ prefectureEnum.Okinawa.shortLabel }}</button></div>
            <div class="action-close" v-if="multiple"><a-button type="primary" block @click="$emit('close')">確定</a-button></div>
        </section>
    </div>
</template>

<style scoped lang="less">
// Variables
// Colors
@item-normal-background-color: @color-neutral-2;
@item-normal-border-color: @color-neutral-5;
@item-normal-text-color: @text-color;
@item-secondary-background-color: @orange-1;
@item-secondary-border-color: @orange-3;
@item-secondary-text-color: @orange-6;
@item-selected-background-color: @primary-color;
@item-selected-border-color: @primary-color;
@item-selected-text-color: @text-color-inverse;

// Layout
@map-grid-gap: 2px;
@map-grid-template-row: 24px;
@map-grid-template-umi-row: 16px;
@map-item-width-s: 44px;
@map-item-width-m: 52px;

.container {
    display: flex;
    align-items: stretch;

    .side {
        min-width: 128px;
        border-right: solid 1px @color-neutral-4;
    }
}

.title {
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    color: fade(@primary-color, 85%);
}

.title + .regions {
    border-top: solid 1px @color-neutral-4;
}

.regions {
    padding: 16px 24px;
}

.japan-map {
    /* autoprefixer grid: no-autoplace */
    display: grid;
    gap: @map-grid-gap;
    grid-template-columns: @map-item-width-s @map-item-width-s @map-item-width-s @map-item-width-s @map-item-width-s @map-item-width-m @map-item-width-s @map-item-width-s @map-item-width-s @map-item-width-m @map-item-width-s @map-item-width-s;
    grid-template-rows: @map-grid-template-row @map-grid-template-row @map-grid-template-umi-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-row @map-grid-template-umi-row @map-grid-template-row auto;
    width: auto;
    padding: 16px @padding-lg;

    .map-button {
        display: block;
        padding: 0 8px;
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        line-height: 24px;

        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
        border-color: @item-normal-border-color;
        background-color: @item-normal-background-color;
        color: @item-normal-text-color;
        outline: 0;
        cursor: pointer;
        transition: all 300ms @ease-in-out;

        &.highlight,
        &:hover:not(:disabled) {
            box-shadow: 0 0 0 2px fade(@primary-color, 40%);
        }

        &.selected {
            border-color: @item-selected-border-color;
            background: @item-selected-background-color;
            color: @item-selected-text-color;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &--primary {
            background-color: fade(@primary-color, 10%);
            border-color: @primary-color;
            color: @primary-color;

            &:hover:not(:disabled) {
                box-shadow: 0 0 0 2px fade(@primary-color, 40%);
            }
        }

        &--warning {
            background-color: @item-secondary-background-color;
            border-color: @item-secondary-border-color;
            color: @item-secondary-text-color;

            &:hover:not(:disabled) {
                box-shadow: 0 0 0 2px fade(@item-secondary-text-color, 40%);
            }
        }
    }

    .pref {
        background: @component-background;
        text-align: center;
        border-radius: 4px;

        .map-button {
            padding-left: 0;
            padding-right: 0;
        }

        &.selected {
            border: solid 1px @item-selected-border-color;
            color: @item-selected-text-color;
            background: @item-selected-background-color;
        }
    }

    .header {
        grid-column-start: 1;
        grid-column-end: 11;
        grid-row-start: 1;
        grid-row-end: 3;

        &__title {
            padding: 8px 0 0 0;
        }

        // NOTE: z-indexを指定しないとIE11でクリックできないため記述している
        &__map-buttons {
            position: relative;
            z-index: 2;
        }

        .map-button {
            display: inline-block;
            width: auto;
        }

        .map-button + .map-button  {
            margin-left: 8px;
        }
    }

    .hokkaido {
        grid-column-start: 11;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .aomori {
        grid-column-start: 11;
        grid-column-end: 13;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    .akita {
        grid-column-start: 11;
        grid-column-end: 12;
        grid-row-start: 5;
        grid-row-end: 6;
    }

    .iwate {
        grid-column-start: 12;
        grid-column-end: 13;
        grid-row-start: 5;
        grid-row-end: 6;
    }

    .ishikawa {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 6;
        grid-row-end: 7;
    }

    .yamagata {
        grid-column-start: 11;
        grid-column-end: 12;
        grid-row-start: 6;
        grid-row-end: 7;
    }

    .miyagi {
        grid-column-start: 12;
        grid-column-end: 13;
        grid-row-start: 6;
        grid-row-end: 7;
    }

    .fukui {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 7;
        grid-row-end: 8;
    }

    .toyama {
        grid-column-start: 8;
        grid-column-end: 9;
        grid-row-start: 7;
        grid-row-end: 8;
    }

    .niigata {
        grid-column-start: 9;
        grid-column-end: 11;
        grid-row-start: 7;
        grid-row-end: 8;
    }

    .fukushima {
        grid-column-start: 11;
        grid-column-end: 13;
        grid-row-start: 7;
        grid-row-end: 8;
    }

    .shimane {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .tottori {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .hyogo {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 8;
        grid-row-end: 10;
    }

    .kyoto {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .shiga {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .gifu {
        grid-column-start: 8;
        grid-column-end: 9;
        grid-row-start: 8;
        grid-row-end: 10;
    }

    .nagano {
        grid-column-start: 9;
        grid-column-end: 10;
        grid-row-start: 8;
        grid-row-end: 10;
    }

    .gunma {
        grid-column-start: 10;
        grid-column-end: 11;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .tochigi {
        grid-column-start: 11;
        grid-column-end: 12;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .ibaraki {
        grid-column-start: 12;
        grid-column-end: 13;
        grid-row-start: 8;
        grid-row-end: 10;
    }

    .yamaguchi {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .hiroshima {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .okayama {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .osaka {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .nara {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .yamanashi {
        grid-column-start: 10;
        grid-column-end: 11;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .saitama {
        grid-column-start: 11;
        grid-column-end: 12;
        grid-row-start: 9;
        grid-row-end: 10;
    }

    .wakayama {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .mie {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .aichi {
        grid-column-start: 8;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .shizuoka {
        grid-column-start: 9;
        grid-column-end: 10;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .kanagawa {
        grid-column-start: 10;
        grid-column-end: 11;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .tokyo {
        grid-column-start: 11;
        grid-column-end: 12;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .chiba {
        grid-column-start: 12;
        grid-column-end: 13;
        grid-row-start: 10;
        grid-row-end: 11;
    }

    .nagasaki {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 11;
        grid-row-end: 12;
    }

    .fukuoka {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 11;
        grid-row-end: 12;
    }

    .ehime {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 11;
        grid-row-end: 12;
    }

    .kagawa {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 11;
        grid-row-end: 12;
    }

    .saga {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 12;
        grid-row-end: 13;
    }

    .oita {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 12;
        grid-row-end: 13;
    }

    .kochi {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 12;
        grid-row-end: 13;
    }

    .tokushima {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 12;
        grid-row-end: 13;
    }

    .kumamoto {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 13;
        grid-row-end: 14;

    }

    .miyazaki {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 13;
        grid-row-end: 14;

    }

    .kagoshima {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 14;
        grid-row-end: 15;
    }

    .umi {
        &.tsugaru {
            grid-column-start: 11;
            grid-column-end: 13;
            grid-row-start: 3;
            grid-row-end: 4;
        }

        &.shina {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .okinawa {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 16;
        grid-row-end: 17;
    }

    // 全選択解除ボタン
    .action-close {
        grid-column-start: 11;
        grid-column-end: 13;
        grid-row-start: 15;
        grid-row-end: 17;
        align-self: end;
    }
}
</style>
