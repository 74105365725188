import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';
import { DateUtil, Util } from '@/util';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';
// @ts-ignore
import UiTruckModelTreeSelect from '@/components/UI/TruckModelTreeSelect';
// @ts-ignore
import UiTruckWeightTreeSelect from '@/components/UI/TruckWeightTreeSelect';
import moment from 'moment';

@Component({
    components: {
        UiPrefectureSelect,
        UiTruckModelTreeSelect,
        UiTruckWeightTreeSelect,
    }
})
export default class MyBaggageSearchCondition extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.BaggageListForm;
    @Prop({ default: false })
    declare readonly searchFormVisible: boolean;
    @Prop({ default: false })
    declare readonly isFiltered: boolean;

    validationErrorMessage = '';

    /**
     * 荷物番号を取得します。
     */
    get id(): string {
        const digits = this.value?.id;
        return digits ? String(digits) : '';
    }

    /**
     * 荷物番号を設定します。
     */
    set id(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 荷物番号を書き換え
        // 0より大きい数値だったら入力を受け入れ、それ以外は拒否しundefinedとする。
        const id = Util.toNumber(newValue);
        cloned.id = id > 0 ? id : undefined;

        this.$emit('input', cloned);
    }

    /**
     * 発地を取得します。
     */
    get departurePref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.departurePref?.map((each) => each.code) || [];
    }

    /**
     * 発地を設定します。
     */
    set departurePref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.departurePref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 着地を取得します。
     */
    get arrivalPref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.arrivalPref?.map((each) => each.code) || [];
    }

    /**
     * 着地を設定します。
     */
    set arrivalPref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.arrivalPref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 発日（開始）を取得します。
     */
    get departureFrom(): string {
        return this.value?.departureFrom ?? '';
    }

    /**
     * 発日（開始）を設定します。
     */
    set departureFrom(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureFrom = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 発日（終了）を取得します。
     */
    get departureTo(): string {
        return this.value?.departureTo ?? '';
    }

    /**
     * 発日（終了）を設定します。
     */
    set departureTo(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departureTo = newValue;

        this.$emit('input', cloned);
    }

    /**
     * 重量を取得します。
     */
    get truckWeight(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.truckWeight?.map((each) => each.code) || [];
    }

    /**
     * 重量を設定します。
     */
    set truckWeight(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.truckWeight = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);
    }

    /**
     * 車種を取得します。
     */
    get truckModel(): string[] {
        return this.value?.truckModel?.map((each) => each.code) || [];
    }

    /**
     * 車種を設定します。
     */
    set truckModel(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.truckModel = newValue.map((each) => ({ code: each }));

        this.$emit('input', cloned);
    }

    /**
     * 担当者名を取得します。
     */
    get staffName(): string {
        return this.value?.staffName ?? '';
    }

    /**
     * 担当者名を設定します。
     */
    set staffName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.staffName = newValue;

        this.$emit('input', cloned);
    }

    // ======================================================
    // Functions
    // ======================================================
    // noinspection DuplicatedCode
    /**
     * 都道府県の入れ替えボタンが押下された際に呼び出されます。
     */
    onClickInvertPref(): void {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.departurePref = this.arrivalPref.map((code) => ({ code }));
        cloned.arrivalPref = this.departurePref.map((code) => ({ code }));
        this.$emit('input', cloned);
    }

    /**
     * カレンダーUIにおいて、引数に与えられた日付が選択不可の日付かどうかを判定します。
     * 〜翌年末までが選択可能な範囲としています。
     */
    isDisabledDate(calendarDate: moment.Moment): boolean {
        const calDt = DateUtil.parseDateText(calendarDate.format('YYYY-MM-DD'));
        return calDt.isAfter(DateUtil.now().add(1, 'year').endOf('year'));
    }

    /**
     * 検索が押下された際に呼び出されます。
     */
    onClickSubmit(searchConditionId?: number): void {
        if (this.validate()) {
            this.$emit('clickSearch', searchConditionId);
        }
    }

    /**
     * クリアが押下された際に呼び出されます。
     */
    onClickClear(): void {
        this.$emit('clickClear');
    }

    toggleSearchFormVisibility(): void {
        this.$emit('toggleSearchFormVisibility');
    }

    /**
     * バリデーションを行います。
     */
    validate(): boolean {
        this.validationErrorMessage = '';

        if (this.departureFrom && this.departureTo) {
            const depFrom = DateUtil.parseDatetimeText(this.departureFrom);
            const depTo = DateUtil.parseDatetimeText(this.departureTo);

            // 出発日時(to)は「from以降」のみ許可
            if (depTo.isBefore(depFrom)) {
                this.validationErrorMessage = '発日（終了）を正しく指定してください。';
                return false;
            }
        }

        if (this.staffName.length > 250) {
            this.validationErrorMessage = '担当者名は250文字以内で入力してください。';
            return false;
        }

        return true;
    }
}
