import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/account/types';
import _ from 'lodash';

@Component
export default class AccountNameEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AccountProfileCreateOrUpdateForm;

    /**
     * 氏名
     */
    get name(): string {
        return this.value?.name ?? '';
    }

    set name(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.name = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 値が変更された際に呼び出される。
     */
    onBlur(): void {
        this.name = this.name.trim();
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (!this.value?.name?.trim()) {
            callback('入力してください。');
        } else if (this.value?.name.length > 60) {
            callback('60文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
