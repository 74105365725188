<script setup lang="ts">
import BaggageSearchDetailDrawer from '@/_components/baggage/BaggageSearchDetailDrawer.vue';
import BaggageSearchTableView from '@/_components/baggage/BaggageSearchTableView.vue';
import PaginationControl from '@/_components/ui/PaginationControl.vue';
import { useBaggageRecentHelper } from '@/_pages/Baggage/Recent/baggage-recent-helper';

defineProps<{
    baggageId?: number;
}>();
const {
    pageInfo,
    drawerVisibility,
    onChangePage,
    onClickGround,
    onClickCloseBaggageDetailDrawer,
    onSelectedBaggageId,
} = useBaggageRecentHelper();

</script>

<template>
    <a-layout-content style="height: 100%;" @click="onClickGround">
        <a-page-header :ghost="false" :title="'最近見た荷物'"/>

        <div class="board-container">
            <a-card id="jsi-search-results" class="board-container__item"
                    :body-style="{ position: 'relative', padding: 0 }">

                <div class="search-results-header">
                    <div class="app-table-pagination">
                        <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                    </div>
                </div>
                <baggage-search-table-view @selected="onSelectedBaggageId"/>
                <div class="search-results-footer">
                    <div class="app-table-pagination">
                        <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                    </div>
                </div>
            </a-card>
        </div>
        <baggage-search-detail-drawer :visible="drawerVisibility" @close="onClickCloseBaggageDetailDrawer"/>
    </a-layout-content>
</template>

<style scoped lang="less">
@drawer-footer-height: 72px;

.board-container {
    padding: 24px;

    &__item + &__item {
        margin-top: 16px;
    }
}

.search-results-table {
    color: @black;

    .ant-table-bordered .ant-table-tbody tr td {
        border-right-style: dashed !important;
    }
}

.search-results-header {
    display: flex;
    align-items: center;
    padding: @padding-lg @padding-lg 0;
    margin-bottom: 8px;

    .clean-favorite-baggages {
        margin-left: auto;
    }

    .clean-favorite-baggages + .app-table-pagination {
        margin-left: 0;
    }
}

.search-results-footer {
    display: flex;
    align-items: center;
    padding: @padding-sm;
}

.search-results-count {
    font-size: 16px;
    font-weight: bold;
}

.search-results-sorting {
    width: initial;
    margin-left: 16px;
}
</style>
