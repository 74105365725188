import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/account/types';
import store from '@/vuex/store';

@Component({
    title: 'ログアウト',
})
export default class LogoutPage extends PageVue {
    mounted(): void {
        LogoutPage.logout().finally(() => {
            LogoutPage.resetAllState();
            this.$router.push({ name: 'Login' });
        });
    }

    /**
     * データストアをリセットします。
     */
    private static resetAllState(): void {
        store.commit('resetAllState');
    }

    /**
     * ログアウトします。
     */
    private static logout(): Promise<void> {
        return store.dispatch(`account/${types.ACTION.LOGOUT}`);
    }
}
