import { useRouting } from '@/composables/helper/routing';
import { useCompanyPlaceUpdate, useFindCompanyPlace } from '@/composables/company-place';
import { useLoading } from '@/composables/helper/loading-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { useFormModel } from '@/composables/form-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { computed, nextTick, onBeforeMount, ref } from 'vue';

export const useCompanyPlaceEditHelper = (companyPlaceId?: number) => {
    const { goBack, goToCompanyPlaceList } = useRouting();
    const { state : { form, formValidateRules, dirty }, initForm, update } = useCompanyPlaceUpdate();
    const { state: { loading: loadingCompanyPlace, companyPlace }, find: loadCompanyPlace } = useFindCompanyPlace();
    const { state: { loading: loadingCompanyPlaceEdit }, withLoading } = useLoading();
    const { formModel, submit } = useFormModel();
    const message = useMessage();

    const loading = computed<boolean>(() =>
        [loadingCompanyPlace, loadingCompanyPlaceEdit].some(each => each.value)
    );

    const initialized = ref<boolean>(false);

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');

    // ======================================================
    // Event
    // ======================================================
    const initialize = async (): Promise<void> => {
        if (companyPlaceId) {
            await loadCompanyPlace(companyPlaceId).catch(() => goToCompanyPlaceList());
            if (companyPlace.value) {
                initForm(companyPlace.value);
                nextTick(async () => {
                    formModel.value?.validate(() => {
                        // callbackがないとerrorとなるため、empty functionを入れる
                    });
                });
            }
        }
        initialized.value = true;
    };

    /**
     * 更新ボタンが押下された際に呼び出されます。
     */
    const onSubmit = () => submit(async () => withLoading(async () => {
        const onSuccess = async () => {
            message.success('地点情報を更新しました。');
            return goToCompanyPlaceList();
        };

        await update().then(onSuccess);
    }));

    const onClickBack = () => {
        goBack();
    };

    // ======================================================
    // LifeCycle
    // ======================================================
    onBeforeMount(() => initialize());

    onBeforeRouteLeave((to, from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        form,
        formModel,
        formValidateRules,
        onClickBack,
        onSubmit,
    };
};
