import { EntitlementModel } from '@/models/entitlement';
//
// Action
//

//
// Parameter for Action
//

//
// Signature for Action
//

//
// Getter
//
export class GETTER {
    static readonly ENTITLEMENT = 'ENTITLEMENT';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_ENTITLEMENT = 'SET_ENTITLEMENT';
}

//
// Store
//
export interface EntitlementState {
    entitlement?: EntitlementModel;
}

//
// Type for store
//
export * from '@/models/entitlement';
