import {
    Truck,
    TruckExistsRecommendation,
    TruckExistsRecommendationForm,
    TruckList,
    TruckListForm,
    TruckRegisterForm,
    TruckSearchForm,
    TruckUpdateForm
} from '@/vuex/modules/truck/types';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const truckApi = {
    /**
     * 取得します。
     */
    find(id: number): Promise<Truck> {
        return appAxios.get<JsonResponseBean<Truck>>(`/api/truck/${ id }`).then(isSuccess);
    },
    /**
     * 自社空車を取得します。
     */
    findMyTruck(id: number): Promise<Truck> {
        return appAxios.get<JsonResponseBean<Truck>>(`/api/truck/${ id }/me`).then(isSuccess);
    },
    /**
     * 検索します。
     */
    searchTruck(param: TruckSearchForm): Promise<TruckList> {
        return appAxios.post<JsonResponseBean<TruckList>>('/api/truck/search', param).then(isSuccess);
    },
    /**
     * 登録します。
     */
    registerTruck(param: TruckRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/truck/register', param).then(isSuccess);
    },
    /**
     * 更新します。
     */
    updateTruck(id: number, param: TruckUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean>(`/api/truck/${id}/update`, param).then(isSuccess);
    },
    /**
     * 一覧を取得します。
     */
    listTruck(param: TruckListForm): Promise<TruckList> {
        return appAxios.post<JsonResponseBean<TruckList>>('/api/truck/list', param).then(isSuccess);
    },
    /**
     * 公開を停止します。
     */
    closeTruck(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/truck/${ id }/close`).then(isSuccess);
    },
    /**
     * 取得します。
     */
    existsRecommendations(param: TruckExistsRecommendationForm): Promise<TruckExistsRecommendation[]> {
        return appAxios.post<JsonResponseBean<Truck>>(`/api/truck/exists-recommendations`, param).then(isSuccess);
    },
};
