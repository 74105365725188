<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import CompanyPrint from '@/_components/company/print/CompanyPrint.vue';
import { useCompanyPrintHelper } from '@/_pages/Company/Print/company-print-helper';

const {
    companyConfidence,
    companyProfile,
    officialCompany,
    companyStatistics,
    loading,
    onClickClose,
    onClickPrint
} = useCompanyPrintHelper();
</script>

<template>
    <page-layout :show-header="false">
        <div class="print-container">
            <div class="print">
                <section class="print__header">
                    <h1>企業情報印刷</h1>
                    <nav class="view-screen-only">
                        <ul class="print__header_actions">
                            <li>
                                <a-button @click="onClickPrint" type="primary" size="large">印刷</a-button>
                            </li>
                            <li>
                                <a-icon @click="onClickClose" type="close" :style="{ fontSize: '20px' }"></a-icon>
                            </li>
                        </ul>
                    </nav>
                </section>
                <section class="print__content">
                    <a-spin :spinning="loading">
                        <template v-if="companyProfile && companyConfidence && companyStatistics">
                            <company-print :profile="companyProfile"
                                           :official-company="officialCompany"
                                           :confidence="companyConfidence"
                                           :statistics="companyStatistics"></company-print>
                        </template>
                    </a-spin>
                </section>
                <section class="print__footer">
                    <small>転載等、一切の二次利用を禁止します。</small>
                </section>
            </div>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
@import '@/../less/common-styles/print-layout.less';
</style>
