import { MemberPosition, MemberStatus, Profile, ProfileAuthority } from '@/models/account';
import { ProductUtil } from '@/util';
import { Enum } from '@/types/enum';
import _ from 'lodash';

export class AccountProfileModel implements Profile {
    id: number;
    status: Enum<MemberStatus>;
    companyId: number;
    name: string;
    email: string;
    position: Enum<MemberPosition>;
    authority: Enum<ProfileAuthority>;
    mainAccountFlg?: boolean;
    product?: Enum;

    constructor(data: Profile) {
        this.id = data.id;
        this.status = data.status;
        this.companyId = data.companyId;
        this.name = data.name;
        this.email = data.email;
        this.position = data.position;
        this.authority = data.authority;
        this.mainAccountFlg = data.mainAccountFlg;
        this.product = data.product;
    }

    /**
     * 管理者アカウント（メインアカウント）か否かを取得します。
     */
    get isMainAccount(): boolean {
        return this.mainAccountFlg === true;
    }

    /**
     * 一般会員か否かを取得します。
     */
    get isRegularUser(): boolean {
        return !this.isMainAccount;
    }

    /**
     * このユーザーアカウントがアカウント追加オプションによって追加されたユーザーか否かを取得します。
     */
    get isAdditionalAccount(): boolean {
        if (!this.product) {
            return false;
        }
        return ProductUtil.isAccount(this.product.code);
    }

    /**
     * 認証待ち状態であるか否かを取得します。
     */
    get isWaitingForVerification(): boolean {
        return this.status.code === 'REG';
    }

    /**
     * ダミー会員であるか否かを取得します。
     */

    get isDummyUser(): boolean {
        return _.endsWith(this.email, '@trbx.net');
    }
}
