import { Component, Vue } from 'vue-property-decorator';
import { highContrastMode } from '@/repository/storage/web-storage';
import { PageUtil } from '@/util';

@Component
export default class UiDrawerLayout extends Vue {
    private isHighContrast = highContrastMode.getHighContrastMode();

    /**
     * app-themeスタイルクラスを取得します。
     */
    get appThemeClasses(): { [key: string]: boolean } {
        return PageUtil.createAppThemeClassNames(this.isHighContrast);
    }
}
