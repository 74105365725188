import { DeliveryDateTime } from '@/models/vo/delivery-datetime';
import { Validator } from '@/validator';
import { FormValidationRule, ValidationContext } from '@/models/validate-helper';
import { DateTimePickerValue } from '@/_components/ui/types/date-time-picker-type';
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { Const } from '@/const';
import { Enum } from '@/types/enum';
import { BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageUtil, DateUtil, Util } from '@/util';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { DateValue } from '@/models/vo/date';
import dayjs from 'dayjs';
import { NegotiationTypeEnumCode } from '@/enums/negotiation-type.enum';
import { BaggageHighwayFarePaymentOption, TmpBaggageLocationValue } from '@/models/baggage';
import { DeliveryDateTimeRange, DeliveryDateTimeRangeType } from '@/models/vo/delivery-datetime-range';

export const useBaggageValidator = () => {
    type Rules<T> = FormValidationRule<T>[];

    const departureDateTime = (context: ValidationContext<{
        departureDateTime?: DeliveryDateTime,
    }>): Rules<DeliveryDateTime | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { departureDateTime } = context();

                const validated = Validator.validateDateTime(departureDateTime);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    const departureDateTimeRange = (context: ValidationContext<{
        departureDateTimeRange?: DeliveryDateTimeRange,
    }>): Rules<DeliveryDateTimeRange | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { departureDateTimeRange } = context();

                const validated = Validator.validateDateTimeRangeType(departureDateTimeRange);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    // TODO: これは廃止する
    // 代わりにDeliveryDateTime版のdepartureDateTimeを使ってください
    const departureDateTimeAsDateTimePickerValue = (context: ValidationContext<{
        departureMin: string,
        departureMax: string,
    }>): Rules<DateTimePickerValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                const value = DeliveryDateTime.of(target.departureMin ?? '', target.departureMax ?? '');
                const validated = Validator.validateDateTime(value);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    const departureDateTimeAsDateTimeRangePickerValue = (context: ValidationContext<{
        departureMin: string,
        departureMax: string,
        departureType?: DeliveryDateTimeRangeType,
    }>): Rules<DateTimeRangePickerValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                const value = (target.departureMin && target.departureMax && target.departureType) ?
                    DeliveryDateTimeRange.typeOf(target.departureType, target.departureMin, target.departureMax) : null;
                const validated = Validator.validateDateTimeRangeType(value);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    const arrivalDateTime = (context: ValidationContext<{
        departureDateTime?: DeliveryDateTime,
        arrivalDateTime?: DeliveryDateTime,
    }>): Rules<DeliveryDateTime | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { departureDateTime, arrivalDateTime } = context();

                const validated = Validator.validateDateTime(arrivalDateTime);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                const rangeValidated = Validator.validateDateTimeRange(departureDateTime, arrivalDateTime);
                if (!rangeValidated.result) {
                    callback(rangeValidated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    const arrivalDateTimeRange = (context: ValidationContext<{
        departureDateTimeRange?: DeliveryDateTimeRange,
        arrivalDateTimeRange?: DeliveryDateTimeRange,
    }>): Rules<DeliveryDateTimeRange | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { departureDateTimeRange, arrivalDateTimeRange } = context();

                const validated = Validator.validateDateTimeRangeType(arrivalDateTimeRange);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                const rangeValidated = Validator.validateMultipleDateTimeRange(departureDateTimeRange, arrivalDateTimeRange);
                if (!rangeValidated.result) {
                    callback(rangeValidated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    // TODO: これは廃止する
    // 代わりにDeliveryDateTime版のarrivalDateTimeを使ってください
    const arrivalDateTimeAsDateTimePickerValue = (context: ValidationContext<{
        departureMin: string;
        departureMax: string;
        arrivalMin: string;
        arrivalMax: string;
    }>): Rules<DateTimePickerValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                const arrival = DeliveryDateTime.of(target.arrivalMin ?? '', target.arrivalMax ?? '');
                const validated = Validator.validateDateTime(arrival);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                const departure = DeliveryDateTime.of(target.departureMin ?? '', target.departureMax ?? '');
                const rangeValidated = Validator.validateDateTimeRange(departure, arrival);
                if (!rangeValidated.result) {
                    callback(rangeValidated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    const arrivalDateTimeAsDateTimeRangePickerValue = (context: ValidationContext<{
        departureMin: string,
        departureMax: string,
        departureType?: DeliveryDateTimeRangeType,
        arrivalMin: string,
        arrivalMax: string,
        arrivalType?: DeliveryDateTimeRangeType,
    }>): Rules<DateTimeRangePickerValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                const arrival = (target.arrivalMin && target.arrivalMax && target.arrivalType) ?
                    DeliveryDateTimeRange.typeOf(target.arrivalType, target.arrivalMin, target.arrivalMax) : null;
                const validated = Validator.validateDateTimeRangeType(arrival);
                if (!validated.result) {
                    callback(validated.message);
                    return;
                }
                const departure = (target.departureMin && target.departureMax && target.departureType) ?
                    DeliveryDateTimeRange.typeOf(target.departureType, target.departureMin, target.departureMax) : null;
                const rangeValidated = Validator.validateMultipleDateTimeRange(departure, arrival);
                if (!rangeValidated.result) {
                    callback(rangeValidated.message);
                    return;
                }
                callback();
            }
        }
    ]);

    // TODO: 発着地のバリデーションの粒度をあわせる
    // 荷物登録は[都道府県、市区町村、その他]をワンセット、荷物編集は別々となっている

    const departurePref = (context: ValidationContext<{
        departurePrefCode?: PrefectureEnumCode,
    }>): Rules<PrefectureEnumCode | undefined> => ([
        {
            required: true,
            message: '都道府県を選択してください。',
        },
    ]);

    const departureCity = (context: ValidationContext<{
        departureCity?: string,
    }>): Rules<string | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();
                if (!target.departureCity?.trim()) {
                    callback('市区町村を入力してください。');
                } else if (target.departureCity?.length > 200) {
                    callback('市区町村名は200文字以内で入力してください。');
                } else {
                    callback();
                }
            },
        },
    ]);

    const departureAddress = (context: ValidationContext<{
        departureAddress?: string,
    }>): Rules<string | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();
                if ((target.departureAddress?.length ?? 0) > 200) {
                    callback('番地・建物は200文字以内で入力してください。');
                } else {
                    callback();
                }
            },
        },
    ]);

    const arrivalPref = (context: ValidationContext<{
        arrivalPrefCode?: PrefectureEnumCode,
    }>): Rules<PrefectureEnumCode | undefined> => ([
        {
            required: true,
            message: '都道府県を選択してください。',
        },
    ]);

    const arrivalCity = (context: ValidationContext<{
        arrivalCity?: string,
    }>): Rules<string | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();
                if (!target.arrivalCity?.trim()) {
                    callback('市区町村を入力してください。');
                } else if (target.arrivalCity?.length > 200) {
                    callback('市区町村名は200文字以内で入力してください。');
                } else {
                    callback();
                }
            },
        },
    ]);

    const arrivalAddress = (context: ValidationContext<{
        arrivalAddress?: string,
    }>): Rules<string | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();
                if ((target.arrivalAddress?.length ?? 0) > 200) {
                    callback('番地・建物は200文字以内で入力してください。');
                } else {
                    callback();
                }
            },
        },
    ]);

    // 都道府県、市区町村、その他が一つの`form-model-item`に包含されているケース用（荷物登録）
    const departureLocation = (context: ValidationContext<{
        departureLocation: TmpBaggageLocationValue
    }>): Rules<TmpBaggageLocationValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const {
                    departureLocation
                } = context();

                if (departureLocation.value.isCompanyPlace) {
                    if (!departureLocation.value.companyPlace?.id) {
                        callback('地点名を選択してください。');
                    } else {
                        callback();
                    }
                } else {
                    if (!departureLocation.value.prefecture?.code) {
                        callback('都道府県を選択してください。');
                    } else if (!departureLocation.value.city?.trim()) {
                        callback('市区町村を入力してください。');
                    } else if (departureLocation.value.city?.length > 200) {
                        callback('市区町村名は200文字以内で入力してください。');
                    } else if ((departureLocation.value.address?.length ?? 0) > 200) {
                        callback('番地・建物は200文字以内で入力してください。');
                    } else {
                        callback();
                    }
                }
            }
        }
    ]);

    // 都道府県、市区町村、その他が一つの`form-model-item`に包含されているケース用（荷物登録）
    const arrivalLocation = (context: ValidationContext<{
        arrivalLocation: TmpBaggageLocationValue
    }>): Rules<TmpBaggageLocationValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const {
                    arrivalLocation
                } = context();

                if (arrivalLocation.value.isCompanyPlace) {
                    if (!arrivalLocation.value.companyPlace?.id) {
                        callback('地点名を選択してください。');
                    } else {
                        callback();
                    }
                } else {
                    if (!arrivalLocation.value.prefecture?.code) {
                        callback('都道府県を選択してください。');
                    } else if (!arrivalLocation.value.city?.trim()) {
                        callback('市区町村を入力してください。');
                    } else if (arrivalLocation.value.city?.length > 200) {
                        callback('市区町村名は200文字以内で入力してください。');
                    } else if ((arrivalLocation.value.address?.length ?? 0) > 200) {
                        callback('番地・建物は200文字以内で入力してください。');
                    } else {
                        callback();
                    }
                }
            }
        }
    ]);

    const loadingTimeNote = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            max: Const.MAX_LOADING_TIME_NOTE,
            message: `積み時間は${ Const.MAX_LOADING_TIME_NOTE }文字以内で入力してください。`,
        }
    ]);

    const unloadingTimeNote = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            max: Const.MAX_UNLOADING_TIME_NOTE,
            message: `卸し時間は${ Const.MAX_UNLOADING_TIME_NOTE }文字以内で入力してください。`,
        }
    ]);

    // TODO: shapeのバリデーション方法を統一する
    const shape = (_context: ValidationContext<unknown>): Rules<BaggageShapeEnumCode | undefined> => ([
        {
            required: true,
            message: '荷姿を選択してください。',
        }
    ]);

    const shapeAsEnum = (_context: ValidationContext<unknown>): Rules<Enum<BaggageShapeEnumCode> | undefined> => ([
        {
            required: true,
            message: '荷姿を選択してください。',
        }
    ]);

    // TODO: type, typeNonnullを統合する
    const type = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            required: true,
            whitespace: true,
            message: '荷種を入力してください。',
        },
        {
            max: 250,
            message: '荷種は250文字以内で入力してください。',
        },
    ]);

    const typeNonnull = (_context: ValidationContext<unknown>): Rules<string> => ([
        {
            required: true,
            whitespace: true,
            message: '荷種を入力してください。',
        },
        {
            max: 250,
            message: '荷種は250文字以内で入力してください。',
        },
    ]);

    const paletteCount = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            pattern: /^[0-9]+$/,
            message: 'パレット枚数（目安）は数字で入力してください。',
        },
        {
            max: Const.MAX_PALETTE_COUNT_DIGITS,
            message: `パレット枚数（目安）は最大${ Const.MAX_PALETTE_COUNT_DIGITS }桁で入力してください。`,
        },
    ]);

    const paletteSize = (context: ValidationContext<{
        paletteHeight?: string;
        paletteWidth?: string;
    }>): Rules<{ paletteHeight: string | undefined, paletteWidth: string | undefined }> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                const { paletteHeight: height, paletteWidth: width } = context();
                if (height) {
                    if (height.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                        callback(`パレットサイズ(縦)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                        return;
                    }

                    if (!Util.isNumeric(height)) {
                        callback(`パレットサイズ(縦)は数字で入力してください。`);
                        return;
                    }
                }
                if (width) {
                    if (width.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                        callback(`パレットサイズ(横)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                        return;
                    }
                    if (!Util.isNumeric(width)) {
                        callback(`パレットサイズ(横)は数字で入力してください。`);
                        return;
                    }
                }
                callback();
            }
        },
    ]);

    const totalCount = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            pattern: /^[0-9]+$/,
            message: '荷物の個数（目安）は数字で入力してください。',
        },
        {
            max: Const.MAX_BAGGAGE_COUNT_DIGITS,
            message: `荷物の個数（目安）は最大${ Const.MAX_BAGGAGE_COUNT_DIGITS }桁で入力してください。`,
        },
    ]);

    const totalVolume = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            pattern: /^[0-9]+$/,
            message: '荷物の体積は数字で入力してください。',
        },
        {
            max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
            message: `荷物の体積は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
        },
    ]);

    const totalWeight = (_context: ValidationContext<unknown>): Rules<string | undefined> => ([
        {
            pattern: /^[0-9]+$/,
            message: '総重量は数字で入力してください。',
        },
        {
            max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
            message: `総重量は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
        },
    ]);

    const truckEquipment = (context: ValidationContext<{
        truckEquipment?: string;
    }>): Rules<string | undefined> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                const target = context();

                const truckEquipment = target.truckEquipment?.trim() ?? '';
                if (truckEquipment.length > 250) {
                    callback('250文字以内で入力してください。');
                    return;
                }
                callback();
            },
        },
    ]);

    const truckCount = (context: ValidationContext<{
        truckCount: string;
    }>): Rules<string> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const target = context();

                const count = Util.toNumber(target.truckCount);
                if (0 < count && count <= 10) {
                    callback();
                } else {
                    callback('台数は1〜10台の間で入力してください。');
                }
            },
        }
    ]);

    const freight = (context: ValidationContext<{
        baggageFreight: BaggageFreightValue;
    }>): Rules<BaggageFreightValue> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { baggageFreight } = context();

                if (baggageFreight.isNegotiate) {
                    callback();
                } else if ((baggageFreight.value ?? 0) > 0) {
                    callback();
                } else {
                    callback('金額を入力するか、要相談をチェックしてください。');
                }
            },
        }
    ]);

    const highwayFarePaymentOption = (context: ValidationContext<{
        highwayFarePaymentOption?: BaggageHighwayFarePaymentOption
    }>): Rules<BaggageHighwayFarePaymentOption | undefined> => ([
        {
            required: true,
            message: '選択してください。',
        }
    ]);

    // TODO: 廃止する use highwayFarePaymentOption instead.
    // `boolean | undefined`はコンポーネントで扱うのが難しいので廃止する方向で
    const highwayFareFlg = (context: ValidationContext<{
        highwayFareFlg?: boolean;
    }>): Rules<boolean | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { highwayFareFlg } = context();

                if (highwayFareFlg === undefined) {
                    callback('選択してください。');
                } else {
                    callback();
                }
            }
        }
    ]);

    const paymentDate = (context: ValidationContext<{
        paymentDate?: DateValue,
        availablePaymentDateRange: [dayjs.Dayjs, dayjs.Dayjs];
        defaultPaymentDate: DateValue | undefined
    }>): Rules<DateValue | undefined> => ([
        {
            required: context().defaultPaymentDate === undefined,
            message: '入金予定日を選択してください。',
        },
        {
            validator: (_rule, _value, callback) => {
                const { paymentDate, availablePaymentDateRange } = context();

                if (paymentDate === undefined) {
                    callback();
                    return;
                }

                if (!DateUtil.isIncluded(paymentDate.value, availablePaymentDateRange)) {
                    const rangeText = availablePaymentDateRange
                        .map(each => new DateValue(each).format('YYYY年MM月DD日'))
                        .join('から');
                    return callback(`入金予定日は${ rangeText }の範囲で指定してください。`);
                }

                callback();
            }
        }
    ]);

    // TODO: プロパティ名として`paymentDateValue`はちょっとよく無いので廃止する
    const paymentDateValue = (context: ValidationContext<{
        paymentDateValue?: DateValue;
        availablePaymentDateRange: [dayjs.Dayjs, dayjs.Dayjs];
        defaultPaymentDate: DateValue | undefined;
    }>): Rules<string | undefined> => ([
        {
            required: context().defaultPaymentDate === undefined,
            message: '入金予定日を選択してください。',
        },
        {
            validator: (_rule, _value, callback) => {
                const { paymentDateValue, availablePaymentDateRange } = context();

                if (paymentDateValue === undefined) {
                    callback();
                    return;
                }

                if (!DateUtil.isIncluded(paymentDateValue.value, availablePaymentDateRange)) {
                    const rangeText = availablePaymentDateRange
                        .map(each => new DateValue(each).format('YYYY年MM月DD日'))
                        .join('から');
                    return callback(`入金予定日は${ rangeText }の範囲で指定してください。`);
                }

                callback();
            }
        }
    ]);

    const description = (context: ValidationContext<{
        description?: string;
    }>): Rules<string | undefined> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                const target = context();

                const description = target.description?.trim() ?? '';
                if (description.length > 2000) {
                    callback('2000文字以内で入力してください。');
                    return;
                }
                // NGワードチェック
                // TODO: BaggageUtilにあるバリデーションを、こちらのファイルに移動する
                const invalidWords = BaggageUtil.validateDescription(description);
                if (invalidWords.length > 0) {
                    callback(`${ invalidWords.map((word) => `「${ word }」`).join('') }は含めないようにしてください。`);
                    return;
                }
                callback();
            }
        },
    ]);

    const negotiationType = (context: ValidationContext<{
        negotiationType?: Enum<NegotiationTypeEnumCode>;
    }>): Rules<Enum<NegotiationTypeEnumCode> | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { negotiationType } = context();

                if (negotiationType === undefined) {
                    callback('選択してください。');
                } else {
                    callback();
                }
            }
        },
    ]);

    const staffName = (context: ValidationContext<{
        staffName: string;
    }>): Rules<string> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { staffName } = context();

                if (!staffName.trim()) {
                    callback('担当者名を入力してください。');
                } else if (staffName.length > 250) {
                    callback('担当者名は250文字以内で入力してください。');
                } else {
                    callback();
                }
            }
        },
    ]);

    const shipperName = (context: ValidationContext<{
        shipperName: string | undefined;
    }>): Rules<string | undefined> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                const { shipperName } = context();

                if (shipperName && shipperName.length > 250) {
                    callback('荷主名は250文字以内で入力してください。');
                } else {
                    callback();
                }
            }
        },
    ]);

    const traboxBaggageId = (context: ValidationContext<{
        traboxBaggageId: number | undefined;
    }>): Rules<number | undefined> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                callback();
            },
        }
    ]);

    const circleId = (context: ValidationContext<{
        circleId: number | undefined;
    }>): Rules<number | undefined> => ([
        {
            required: true,
            validator: (_rule, _value, callback) => {
                const { circleId } = context();

                if (circleId === undefined) {
                    callback('部屋を選択してください。');
                } else {
                    callback();
                }
            }
        },
    ]);

    const label = (context: ValidationContext<{
        label: { labelText?: string, labelColor?: string}
    }>): Rules<{ labelText?: string, labelColor?: string }> => ([
        {
            required: false,
            validator: (_rule, _value, callback) => {
                const { label } = context();

                if (label.labelText) {
                    if (label.labelText.length > 250) {
                        callback('ラベルのテキストは250文字以内で入力してください。');
                    } else if (!label.labelColor) {
                        callback('ラベルの色を選択してください。');
                    } else {
                        callback();
                    }
                } else {
                    if (label.labelColor) {
                        callback('ラベルのテキストを入力してください。');
                    } else {
                        callback();
                    }
                }
            }
        },
    ]);

    return {
        departureDateTime,
        departureDateTimeRange,
        departureDateTimeAsDateTimePickerValue,
        departureDateTimeAsDateTimeRangePickerValue,
        arrivalDateTime,
        arrivalDateTimeRange,
        arrivalDateTimeAsDateTimePickerValue,
        arrivalDateTimeAsDateTimeRangePickerValue,
        departurePref,
        departureCity,
        departureAddress,
        departureLocation,
        arrivalPref,
        arrivalCity,
        arrivalAddress,
        arrivalLocation,
        loadingTimeNote,
        unloadingTimeNote,
        shape,
        shapeAsEnum,
        type,
        typeNonnull,
        paletteCount,
        paletteSize,
        totalCount,
        totalVolume,
        totalWeight,
        truckEquipment,
        truckCount,
        freight,
        highwayFarePaymentOption,
        highwayFareFlg,
        paymentDate,
        paymentDateValue,
        description,
        negotiationType,
        staffName,
        shipperName,
        traboxBaggageId,
        circleId,
        label,
    };
};
