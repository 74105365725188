import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { NegotiationRequestForm } from '@/models/negotiation';

@Component
export default class NegotiationRequestMessageEdit extends Vue {
    @Prop()
    declare readonly value?: NegotiationRequestForm;

    validationRules: Array<ValidationRule> = [
        {
            required: false,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get message(): string {
        return this.value?.message ?? '';
    }

    set message(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.message = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.message = this.message.trim();
    }

    private validate(callback: (message?: string) => void): void {
        const description = this.value?.message?.trim() ?? '';
        if (description.length > 250) {
            callback('250文字以内で入力してください。');
            return;
        }
        callback();
    }
}
