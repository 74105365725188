import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyProfile } from '@/vuex/modules/company/types';
// @ts-ignore
import Name from '@/components/Company/View/Name';
// @ts-ignore
import Location from '@/components/Company/View/Location';
// @ts-ignore
import Phone from '@/components/Company/View/Phone';
// @ts-ignore
import Fax from '@/components/Company/View/Fax';
// @ts-ignore
import Description from '@/components/Company/View/Description';
// @ts-ignore
import TruckCount from '@/components/Company/View/TruckCount';
// @ts-ignore
import Url from '@/components/Company/View/Url';
// @ts-ignore
import RegistrationDate from '@/components/Company/View/RegistrationDate';

@Component({
    components: {
        Name,
        Location,
        Phone,
        Fax,
        Description,
        TruckCount,
        Url,
        RegistrationDate,
    },
})
export default class CompanyDetailView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: CompanyProfile;
}
