import { DeliveryOrderList } from '@/models/delivery-order';

export class ACTION {
    static readonly LOAD_DELIVERY_ORDER_LIST = 'LOAD_DELIVERY_ORDER_LIST';
    static readonly DELIVERY_ORDER_EXIST = 'DELIVERY_ORDER_EXIST';
}

// Getter
export class GETTER {
    static readonly DELIVERY_ORDER_LIST = 'DELIVERY_ORDER_LIST';
    static readonly DELIVERY_ORDER_EXIST = 'DELIVERY_ORDER_EXIST';
}

// Mutation
export class MUTATION {
    static readonly SET_DELIVERY_ORDER_LIST = 'SET_DELIVERY_ORDER_LIST';
    static readonly SET_DELIVERY_ORDER_EXIST = 'SET_DELIVERY_ORDER_EXIST';
}

// Store
export interface DeliveryOrderState {
    deliveryOrderList: DeliveryOrderList;
    deliveryOrderExist: boolean;
}

// Type for store
export * from '@/models/delivery-order';
