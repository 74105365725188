import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaggageSearchConditionEditCheckbox extends Vue {
    @Prop()
    declare readonly value?: boolean;

    get checked(): boolean | undefined {
        return this.value;
    }

    set checked(newValue: boolean | undefined) {
        this.$emit('input', newValue);
    }
}
