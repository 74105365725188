<script setup lang="ts">
import { highContrastMode } from '@/repository/storage/web-storage';
import { PageUtil } from '@/util';
import { computed } from 'vue';

const isHighContrast = highContrastMode.getHighContrastMode();

defineProps<{
    title?: string;
}>();

/**
 * app-themeスタイルクラスを取得します。
 */
const appThemeClasses = computed<{ [key: string]: boolean }>(() => {
    return PageUtil.createAppThemeClassNames(isHighContrast);
});
</script>

<template>
    <section class="ui-drawer-layout" :class="appThemeClasses">
        <div class="ui-drawer-layout__header">
            <div class="ui-drawer-header">
                <div class="ui-drawer-header__title">
                    <!-- props.titleがある場合は、その描画を優先する。無い場合はslot。 -->
                    <div v-if="title" class="ui-drawer-header__title__default">
                        <div class="ui-drawer-header__title__default__label">
                            <span>{{ title }}</span>
                        </div>
                        <nav class="ui-drawer-header__title__default_label__actions">
                            <slot name="actions"></slot>
                        </nav>
                    </div>
                    <slot v-else name="header"></slot>
                </div>
                <a-button icon="close" class="ui-drawer-header__close ui-drawer-close ant-drawer-close"
                          @click="$emit('close')"></a-button>
            </div>
        </div>
        <div class="ui-drawer-layout__body">
            <slot></slot>
        </div>
        <slot name="footer"></slot>
    </section>
</template>

<style scoped lang="less">
.ui-drawer-layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    &__header {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    &__body {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
        padding: 0;
    }
}

.ui-drawer-header {
    height: @ui-drawer-header-height;
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-bottom: @border-width-base @border-style-base @border-color-split;
    background-color: @component-background;

    &__title {
        padding-left: @padding-lg;
        padding-right: @padding-sm;
        width: calc(100% - @ui-drawer-header-height);

        &__default {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__label {
                position: relative;
                margin: 12px 0 0;
                padding: 12px 0;

                > span {
                    margin: 0 16px;
                    color: @primary-color;
                    text-shadow: 0 0 0.25px currentColor;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    background-color: @primary-color;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    &__close {
        margin-left: auto;
    }
}

.ui-drawer-close {
    position: static;
    box-shadow: none;
    height: @ui-drawer-header-height - 1px;
    border-radius: 0;
    transition: all 300ms;

    &:hover {
        background: @item-hover-bg;
        color: @ui-color-selected;
    }
}
</style>
