import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage } from '@/vuex/modules/baggage/types';
import { TruckHeightEnum } from '@/enums/truck-height.enum';

type OptionItem = {
    value?: string,
    label: string,
    key?: string,
    class?: string
};

const HEIGHTS = TruckHeightEnum.registrableValuesForBaggage.map((each) => ({
    value: each.code,
    label: each.label,
    key: each.code,
})) as OptionItem[];

@Component
export default class BaggageTruckHeightRegister extends Vue {
    @Prop()
    declare readonly value?: Baggage;

    heights = HEIGHTS;

    get truckHeight(): string | undefined {
        return this.value?.truckHeight?.code;
    }

    set truckHeight(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;
        if (newValue) {
            cloned.truckHeight = { code: newValue };
        } else {
            cloned.truckHeight = undefined;
        }
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }
}
