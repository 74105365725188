import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import CompanyPrint from '@/components/Company/Print';

const companyMod = namespace('company');

@Component({
    title: '企業情報印刷',
    components: {
        CompanyPrint,
    },
    beforeRouteEnter: CompanyPrintPage.beforeRouteEnter,
    beforeRouteUpdate: CompanyPrintPage.beforeRouteUpdate,
})
export default class CompanyPrintPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.MY_PROFILE)
    readonly MY_PROFILE?: types.CompanyProfile;
    @companyMod.Getter(types.GETTER.PROFILE)
    readonly PROFILE?: types.CompanyProfile;
    @companyMod.Getter(types.GETTER.CONFIDENCE)
    readonly confidence?: types.CompanyConfidence;
    @companyMod.Getter(types.GETTER.STATISTICS)
    readonly statistics?: types.CompanyStatistics;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ type: Number })
    declare readonly companyId?: number;

    get profile(): types.CompanyProfile | undefined {
        return this.companyId ? this.PROFILE : this.MY_PROFILE;
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 印刷ボタンが押下された際に呼び出されます。
     */
    onClickPrint(): void {
        window.print();
    }

    /**
     * 閉じるボタンを押下された際に呼び出されます。
     */
    onClickClose(): void {
        window.close();
    }

    /**
     * 自社のプロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * 企業プロフィールをロードします。
     */
    private static loadProfile(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_PROFILE}`, companyId);
    }

    /**
     * 自社の信用情報をロードします。
     */
    private static loadMyConfidence(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_CONFIDENCE}`);
    }

    /**
     * 企業信用情報をロードします。
     */
    private static loadConfidence(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_CONFIDENCE}`, companyId);
    }

    /**
     * 自社の企業統計情報をロードします。
     */
    private static loadMyStatistics(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_STATISTICS}`);
    }

    /**
     * 企業統計情報をロードします。
     */
    private static loadStatistics(companyId: number): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_STATISTICS}`, companyId);
    }

    static async beforeRouteEnter(
        to: VueRoute,
        from: VueRoute,
        next: NavigationGuardNext<CompanyPrintPage>
    ): Promise<void> {
        return CompanyPrintPage.beforeRouteUpdate(to, from, next);
    }

    static async beforeRouteUpdate(
        to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<CompanyPrintPage>
    ): Promise<void> {
        const { companyId } = to.params;

        await Promise.all(
            companyId
                ? [
                    CompanyPrintPage.loadProfile(Number(companyId)),
                    CompanyPrintPage.loadConfidence(Number(companyId)),
                    CompanyPrintPage.loadStatistics(Number(companyId)),
                ]
                : [
                    CompanyPrintPage.loadMyProfile(),
                    CompanyPrintPage.loadMyConfidence(),
                    CompanyPrintPage.loadMyStatistics(),
                ]
        )
            .then(() => next())
            .catch(() => next({ name: 'NotFound' }));
    }
}
