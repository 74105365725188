import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomRow } from '@/types/ant-design';
import { AgreementUtil, DateUtil, GuaranteeUtil, Util } from '@/util';
import * as companyTypes from '@/vuex/modules/company/types';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import * as deliveryOrderTypes from '@/vuex/modules/deliveryOrder/types';
// @ts-ignore
import UiAgreementTypeTag from '@/components/UI/AgreementTypeTag';
// @ts-ignore
import UiSortPaginationControl from '@/components/UI/SortPaginationControl';
import { Const } from '@/const';
import { CompanyContractModel } from '@/models/company-contract';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';

@Component({
    components: {
        UiSortPaginationControl,
        UiAgreementTypeTag,
    },
})
export default class AgreementAcceptedListView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: agreementTypes.AgreementList;
    @Prop()
    declare readonly companyList?: companyTypes.CompanyProfile[];
    @Prop()
    declare readonly contracts?: CompanyContractModel[];
    @Prop()
    declare readonly guaranteedAmount?: companyTypes.CompanyGuaranteedAmount;
    @Prop()
    declare readonly guaranteePriceMaster?: guaranteeTypes.GuaranteePriceMaster[];
    @Prop()
    declare readonly deliveryOrderList?: deliveryOrderTypes.DeliveryOrderModel[];
    @Prop()
    declare readonly sortKey?: string;
    @Prop()
    declare readonly sortAscending?: boolean;
    @Prop({ default: false })
    declare readonly loading: boolean;

    get list(): agreementTypes.Agreement[] | undefined {
        /* 企業を探す関数 */
        const findCompany = (id: number) => this.companyList?.find((each) => each.id === id);

        /* 配送依頼書を探す関数 */
        const findDeliveryOrder = (agreementId: number) => {
            if (this.deliveryOrderList && this.deliveryOrderList.length > 0) {
                return this.deliveryOrderList.find((each) => each.agreementId === agreementId);
            }
            return undefined;
        };

        /* 成約と企業プロフィールをマージする関数 */
        const mergeOthers = (agreement: agreementTypes.Agreement) => {
            const company = findCompany(agreement.baggageCompanyId);
            const deliveryOrder = findDeliveryOrder(agreement.id);

            return _.merge(
                {},
                agreement,
                {
                    baggageCompany: company,
                    deliveryOrderStatus: deliveryOrder ? deliveryOrder.status.forTransporter : ''
                });
        };

        return this.agreement?.data?.map(mergeOthers);
    }

    /**
     * 運賃全額保証サービスに加入済みか否かを取得します。
     */
    get existsGuarantee(): boolean {
        if (!this.contracts) return false;

        return this.contracts.findIndex((each) => each.isGuarantee) >= 0;
    }

    /**
     * 今月これまでの保証対象累計額
     */
    get usedGuaranteedAmountText(): string | undefined {
        if (!this.guaranteedAmount) {
            return undefined;
        }
        return Util.formatNumber(this.guaranteedAmount.amount);
    }

    /**
     * 今月これまでの運賃保証サービス料
     */
    get usedServiceFeeText(): string | undefined {
        if (!this.guaranteedAmount || !this.guaranteePriceMaster || !this.contracts) {
            return undefined;
        }
        const price = GuaranteeUtil.calculateGuaranteePrice(
            this.guaranteedAmount.amount,
            this.guaranteePriceMaster,
            this.contracts
        );
        return Util.formatNumber(price);
    }

    // ======================================================
    // Data
    // ======================================================
    sortOptions = Const.agreementSortKey.map((each) => {
        return { label: each.label, value: each.code, key: each.code };
    });

    rowClassName = (record: agreementTypes.Agreement): string => {
        const styleClasses = ['app-table-row', 'app-table-row--clickable'];
        const { agreementId } = this.$route.query;
        if (`${record.id}` === agreementId) {
            styleClasses.push('app-table-row--selected');
        }
        return styleClasses.join(' ');
    };
    columns = [
        {
            title: '成約番号',
            scopedSlots: { customRender: 'id' },
            width: 128,
        },
        {
            title: '状態',
            key: 'status',
            dataIndex: 'status.label',
            scopedSlots: { customRender: 'status' },
            width: 164,
        },
        {
            title: '荷物番号',
            dataIndex: 'baggageId',
            width: 120,
        },
        {
            title: '荷主',
            key: 'baggageCompany',
            scopedSlots: { customRender: 'baggageCompany' },
            width: 200,
        },
        {
            title: '依頼書',
            key: 'deliveryOrderStatus',
            scopedSlots: { customRender: 'deliveryOrderStatus' },
            width: 200,
        },
        {
            title: '発日時・発地',
            key: 'departure',
            scopedSlots: { customRender: 'departure' },
            width: 170,
        },
        {
            title: '着日時・着地',
            key: 'arrival',
            scopedSlots: { customRender: 'arrival' },
            width: 170,
        },
        {
            title: '運賃',
            key: 'freight',
            dataIndex: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 128,
        },
        {
            title: '高速代',
            key: 'highwayFare',
            dataIndex: 'highwayFare',
            scopedSlots: { customRender: 'highwayFare' },
            width: 128,
        },
        {
            title: '重量',
            key: 'truckWeight',
            dataIndex: 'truckWeight',
            scopedSlots: { customRender: 'truckWeight' },
            width: 64,
        },
        {
            title: '車種',
            key: 'truckModel',
            dataIndex: 'truckModel',
            scopedSlots: { customRender: 'truckModel' },
            width: 108,
        },
        {
            title: '入金予定日',
            key: 'paymentDate',
            scopedSlots: { customRender: 'paymentDate' },
            width: 128,
        },
    ];

    /**
     * 行をカスタマイズする。
     */
    customRow(record: agreementTypes.Agreement): CustomRow {
        return {
            on: {
                click: (event: Event) => {
                    // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
                    event.stopPropagation();
                    this.$emit('clickRow', record.id);
                },
            },
        };
    }

    shouldShowStatus(record: agreementTypes.Agreement): boolean {
        if (record.status.code === 'ACTIVE' || record.status.code === 'STABLE') return false;
        // 変更申請、取消申請があったが、変更期限を過ぎている場合
        if (
            (record.status.code === 'CHANGE' || record.status.code === 'REVOKE') &&
            !AgreementUtil.isWithinChangeDeadline(record)
        ) {
            return false;
        }
        return true;
    }

    shouldShowChangeLimitDatetime(record: agreementTypes.Agreement): boolean {
        return (record.status.code === 'CHANGE' || record.status.code === 'REVOKE') && AgreementUtil.isWithinChangeDeadline(record);
    }

    baggageCompanyText(record: { baggageCompany?: companyTypes.CompanyProfile }): string {
        return record.baggageCompany?.name.kanji ?? '';
    }

    shipperText(record: agreementTypes.Agreement): string {
        return record.shipperName ? `（${record.shipperName}）` : '';
    }

    departureText(record: agreementTypes.Agreement): string {
        return DeliveryDateTimeRange.of(record.departureMin, record.departureMax)?.format() ?? '';
    }

    arrivalText(record: agreementTypes.Agreement): string {
        return DeliveryDateTimeRange.of(record.arrivalMin, record.arrivalMax)?.format() ?? '';
    }

    freightText(record: agreementTypes.Agreement): string {
        return Util.formatNumber(record.freight);
    }

    highwayFareText(record: agreementTypes.Agreement): string {
        if (!record.highwayFareFlg) return 'なし';
        const highwayFare = record.highwayFare;
        return highwayFare <= 0 ? '金額未定' : `${Util.formatNumber(highwayFare)}円`;
    }

    truckWeightText(record: agreementTypes.Agreement): string {
        return record.truckWeight.label ?? '';
    }

    truckModelText(record: agreementTypes.Agreement): string {
        return record.truckModel?.label ?? '';
    }

    paymentDateText(agreement: agreementTypes.Agreement): string {
        return DateUtil.parseDateText(agreement.paymentDate).format('YYYY/MM/DD');
    }

    changeLimitDate(record: agreementTypes.Agreement): string {
        return DateUtil.parseDatetimeText(record.changeLimitTm).format(Const.DEFAULT_SHORT_DATE_WITH_DAY_OF_WEEK_FORMAT);
    }

    /**
     * 荷主企業名をクリックすると呼び出されます。
     */
    async onClickBaggageCompanyText(event: Event, record: agreementTypes.Agreement): Promise<void> {
        event.stopPropagation();
        this.$emit('clickRow', record.id, 'company');
    }

    /**
     * ページネーションでソートキーか並び順を変更した時に呼び出されます。
     */
    onChangeSort(sortKey: string, sortAscending: boolean): void {
        this.$emit('onChangeSort', { sortKey, sortAscending });
    }

    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('onChangePage', { pageNo, pageSize });
    }
}
