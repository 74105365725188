import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { Util } from '@/util';
import * as types from '@/vuex/modules/agreement/types';
import _ from 'lodash';
import { Const } from '@/const';

@Component
export default class AgreementWaitTimeFeeEdit extends Vue {
    validationRules: Array<ValidationRule> = [
        {
            pattern: /^[0-9０-９]*$/,
            message: '待機料は数字で入力してください。',
        },
        {
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule: ValidationRule, _value: unknown, callback: Function) =>
                this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementUpdateForm;

    /**
     * 待機料
     */
    get waitTimeFee(): number | string {
        if (!this.value) return '';
        return this.value?.waitTimeFee?.toString() ?? '';
    }

    set waitTimeFee(newValue: number | string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        const parse = (newValue: number | string) => {
            const value = Util.parseAmountString(newValue, Const.MAX_WAIT_TIME_FEE);
            if (_.isEmpty(value)) {
                return undefined;
            }
            const parsed = Util.toNumber(value);
            return isNaN(parsed) ? undefined : parsed;
        };

        // 待機料を書き換え
        cloned.waitTimeFee = parse(newValue);

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        if (_.isEmpty(this.value?.waitTimeFee)) return callback();
        const waitTimeFee = this.value?.waitTimeFee ?? 0;
        if (waitTimeFee >= 0 && waitTimeFee <= Const.MAX_WAIT_TIME_FEE) {
            callback();
        } else {
            callback(`待機料は${Const.MAX_WAIT_TIME_FEE / 10000}万円以内で入力してください。`);
        }
    }
}
