import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageArrivalLocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get arrivalLocation(): string {
        if (!this.baggage) return '';

        const array = [this.baggage.arrivalPref.label, this.baggage.arrivalCity, this.baggage.arrivalAddress ?? ''];
        return array.join('');
    }
}
