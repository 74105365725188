import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import * as types from '@/vuex/modules/company/types';
// @ts-ignore
import BankName from '@/components/Company/Edit/BankName';
// @ts-ignore
import BranchName from '@/components/Company/Edit/BranchName';
// @ts-ignore
import AccountType from '@/components/Company/Edit/AccountType';
// @ts-ignore
import AccountNumber from '@/components/Company/Edit/AccountNumber';
// @ts-ignore
import AccountHolder from '@/components/Company/Edit/AccountHolder';
// @ts-ignore
import CompanyName from '@/components/Company/Edit/PaymentNotice/CompanyName';
// @ts-ignore
import StaffName from '@/components/Company/Edit/PaymentNotice/StaffName';
// @ts-ignore
import Location from '@/components/Company/Edit/PaymentNotice/Location';
// @ts-ignore
import Phone from '@/components/Company/Edit/PaymentNotice/Phone';
// @ts-ignore
import Fax from '@/components/Company/Edit/PaymentNotice/Fax';
import { FormModel, Modal } from 'ant-design-vue';
import { BankAccountTypeEnum } from '@/enums/bank-account-type.enum';
import { loadMyProfile, loadTransfer, updateTransfer } from '@/pages/Setting/Company/Transfer/helpers';
import _ from 'lodash';
import { CreateElement, VNode } from 'vue';

const companyMod = namespace('company');

@Component({
    title: '振込先口座情報',
    components: {
        BankName,
        BranchName,
        AccountType,
        AccountNumber,
        AccountHolder,
        CompanyName,
        StaffName,
        Location,
        Phone,
        Fax,
    },
    beforeRouteEnter: SettingCompanyTransferPage.beforeRouteEnter,
})
export default class SettingCompanyTransferPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.TRANSFER)
    readonly BANK_ACCOUNT?: types.CompanyTransfer;
    @companyMod.Getter(types.GETTER.MY_PROFILE)
    readonly PROFILE?: types.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    form: types.CompanyTransferUpdateForm = {
        bankName: '',
        branchName: '',
        type: BankAccountTypeEnum.Mixture,
        accountNumber: '',
        accountHolder: '',
        companyName: undefined,
        staffName: undefined,
        zipCode: undefined,
        prefecture: { code: '' },
        city: undefined,
        address: undefined,
        phoneNumber: undefined,
        faxNumber: undefined,
    };

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (!this.BANK_ACCOUNT) return;

        this.form.bankName = this.BANK_ACCOUNT.bankName;
        this.form.branchName = this.BANK_ACCOUNT.branchName;
        this.form.type = this.BANK_ACCOUNT.type;
        this.form.accountNumber = this.BANK_ACCOUNT.accountNumber;
        this.form.accountHolder = this.BANK_ACCOUNT.accountHolder;
        this.form.companyName = this.BANK_ACCOUNT.companyName;
        this.form.staffName = this.BANK_ACCOUNT.staffName;
        this.form.zipCode = this.BANK_ACCOUNT.zipCode;
        this.form.prefecture = this.BANK_ACCOUNT.prefecture;
        this.form.city = this.BANK_ACCOUNT.city;
        this.form.address = this.BANK_ACCOUNT.address;
        this.form.phoneNumber = this.BANK_ACCOUNT.phoneNumber;
        this.form.faxNumber = this.BANK_ACCOUNT.faxNumber;
    }

    onClickAutoFill(): void {
        if (!this.PROFILE) return;
        const confirmed = confirm('すでに入力済みのデータが上書きされますがよろしいですか？');
        if (!confirmed) return;
        this.form.companyName = this.PROFILE.name.kanji;
        this.form.staffName = 'ご担当者';
        this.form.zipCode = this.PROFILE.zipCode;
        this.form.prefecture = this.PROFILE.location.prefecture;
        this.form.city = this.PROFILE.location.city;
        this.form.address = this.PROFILE.location.address;
        this.form.phoneNumber = this.PROFILE.phone.number;
        this.form.faxNumber = this.PROFILE.phone.faxNumber;
    }

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const notifyFailedKey = 'UPDATE_BANK_ACCOUNT_FAILED';
        const onSuccess = () => {
            this.$message.success('振込先口座情報を更新しました。');
        };
        const notifyFailed = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: '振込先口座情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;
            // 支払先通知情報に未入力がある場合
            if (!this.isCompletedPaymentNoticeSettings()) {
                const response = await new Promise((resolve) => Modal.confirm({
                    width: 470,
                    content: (h: CreateElement): VNode =>
                        h(
                            'div',
                            [
                                '見積を登録するには、支払通知先情報が必要となります。',
                                h('br'),
                                'このまま保存しますか？',
                            ],
                        ),
                    okText: '保存する',
                    cancelText: 'キャンセル',
                    onOk: () => resolve(true),
                    onCancel: () => resolve(false),
                }));
                if (!response) return;
            }

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            await updateTransfer(this.form).then(onSuccess).catch(notifyFailed);

            this.loading = false;
        });
    }

    // Form側で定義するのが理想だが、リファクタの際に対応する
    private isCompletedPaymentNoticeSettings(): boolean {
        // 支払先通知情報項目
        const paymentNoticeFields = [
            this.form.companyName,
            this.form.staffName,
            this.form.zipCode,
            this.form.prefecture,
            this.form.city,
            this.form.address,
            this.form.phoneNumber,
            this.form.faxNumber
        ];
        return paymentNoticeFields.every(field => !_.isEmpty(field));
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingCompanyTransferPage>
    ): Promise<void> {
        await Promise.all([loadTransfer(), loadMyProfile()]);
        next();
    }
}
