<script setup lang="ts">
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import TrialFlowStep from '@/_components/parts/TrialFlowStep.vue';
import { computed } from 'vue';
import { URLUtil } from '@/util';
import { useRoute } from '@/composables/helper/route';

const { currentRoute } = useRoute();
const position = computed(() => {
    const query = URLUtil.parseQueryValue(currentRoute.query.view);
    return query === 'embed' ? 'bottom' : 'center';
});
</script>

<template>
    <pre-login-layout :position="position">
        <div class="body__container">
            <h1 class="body__title">お送りしたメールから申し込み手続きを進めてください</h1>
            <p class="body__description">
                入力いただいたメールアドレスに、受信確認用のメールを送信しましたのでご確認ください。<br>
                お送りしたメールに記載されたURLをクリックして、パスワードの設定を行ってください。</p>
            <trial-flow-step :current-step="2"></trial-flow-step>
            <p class="flow__description">10分経過してもメールが届かない場合には、 トラボックスまでご連絡ください。</p>
        </div>
    </pre-login-layout>

</template>

<style scoped lang="less">
.body__container {
    width: 800px;
}

.body__title {
    margin-bottom: @padding-lg;
    font-size: 18px;
    line-height: 30px;
    color: #48483f;
}

.flow {
    margin-top: 64px;
}

.flow__description {
    margin-top: 24px;
    text-align: left;
}

</style>
