<script setup lang="ts">
import DrawerContentLayout from '../../common/DrawerContentLayout.vue';
import { NegotiationListResult } from '@/composables/negotiation-list';
import { computed } from 'vue';
import { NegotiationReportStatusEnum } from '@/enums/negotiation-report-status.enum';
import NegotiationRequestContent from '@/_pages/Baggage/List/drawers/BaggageDetail/tabs/NegotiationRequestContent.vue';
import { CompanyProfile } from '@/models/company';

const props = defineProps<{
    baggageId: number;
    loading: boolean;
    list?: NegotiationListResult;
    circleCompanyList?: CompanyProfile[];
}>();

const emit = defineEmits<{
    (e: 'selectCompany', value: { companyId: number, negotiationId: number }): void,
    (e: 'changePage', value: { pageNo: number, pageSize: number }): void,
}>();

// 対応可能のリスト
const possibleCompanies = computed(() =>
    props.list ? props.list.data.filter(
        negotiation => negotiation.reportStatus.code === NegotiationReportStatusEnum.Possible.code) : []);

// 対応不可のリスト
const rejectedCompanies = computed(() =>
    props.list ? props.list.data.filter(
        negotiation => negotiation.reportStatus.code === NegotiationReportStatusEnum.Reject.code) : []);

// 保留のリスト
const pendingCompanies = computed(() =>
    props.list ? props.list.data.filter(
        negotiation => negotiation.reportStatus.code === NegotiationReportStatusEnum.Pending.code) : []);

const noReplyCompanies = computed(() => {
    const repliedCompanyIds = props.list ? props.list.data.map(negotiation => negotiation.truckCompanyId) : [];
    // circleCompanyListからrepliedCompanyIdsに含まれない企業を抽出
    const noReplyList = props.circleCompanyList ? props.circleCompanyList.filter(company => !repliedCompanyIds.includes(company.id)) : [];
    return noReplyList;
});

</script>

<template>
    <drawer-content-layout :loading="loading">
        <div>
            <!-- 対応可能のリスト -->
            <div class="report-group-area">
                <h3>対応可</h3>
                <span v-if="possibleCompanies.length === 0">対応可の企業はありません。</span>
                <a-card v-else v-for="negotiation in possibleCompanies" :key="negotiation.id" class="negotiation-card">
                    <negotiation-request-content
                        :negotiation="negotiation"
                        :company="negotiation.company"
                        @selectCompany="emit('selectCompany', $event)"/>
                </a-card>
            </div>
            <!-- 対応不可のリスト -->
            <div class="report-group-area">
                <h3>対応不可</h3>
                <span v-if="rejectedCompanies.length === 0">対応不可の企業はありません。</span>
                <a-card v-else v-for="negotiation in rejectedCompanies" :key="negotiation.id" class="negotiation-card">
                    <negotiation-request-content
                        :negotiation="negotiation"
                        :company="negotiation.company"
                        @selectCompany="emit('selectCompany', $event)"/>
                </a-card>
            </div>
            <!-- 保留のリスト -->
            <div class="report-group-area">
                <h3>保留</h3>
                <span v-if="pendingCompanies.length === 0">保留の企業はありません。</span>
                <a-card v-else v-for="negotiation in pendingCompanies" :key="negotiation.id" class="negotiation-card">
                    <negotiation-request-content
                        :negotiation="negotiation"
                        :company="negotiation.company"
                        @selectCompany="emit('selectCompany', $event)"/>
                </a-card>
            </div>
            <!-- 未回答のリスト -->
            <div class="report-group-area">
                <h3>未回答</h3>
                <span v-if="noReplyCompanies.length === 0">未回答の企業はありません。</span>
                <a-card v-else v-for="company in noReplyCompanies" :key="company.id" class="negotiation-card">
                    <negotiation-request-content
                        :negotiation="undefined"
                        :company="company"
                        @selectCompany="emit('selectCompany', $event)"/>
                </a-card>
            </div>

            <!-- NOTE Paginationは消して、常にtotalで100件を表示する。1つのCircleに100件以上の企業が入ることはないだろうという想定
                 いずれ復活させる可能性もあるのでコメントアウトしておきます。
             -->
            <!--            <sort-pagination-control-->
            <!--                :show-sort="false"-->
            <!--                :page-info="pageInfo"-->
            <!--                :loading="loading"-->
            <!--                @change="emit('changePage', $event)"/>-->
        </div>
    </drawer-content-layout>
</template>

<style scoped lang="less">
.negotiation-card {
    margin-bottom: 16px;

    :deep(.ant-card-body) {
        padding: 16px;
    }

    :deep(.ant-row + .ant-row) {
        margin-top: 16px;
    }
}

.report-group-area {
    margin-bottom: 24px;
}
</style>
