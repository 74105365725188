import { CreateElement, onUnmounted, ref, VNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { ModalConfirm, ModalOptions } from 'ant-design-vue/types/modal';

export const useModal = () => {
    const modal = ref<ModalConfirm | undefined>();

    // antへの依存を下げるため、最小限必要なオプションだけに絞る（必要あれば追加してくスタイルで）
    type OptionsKey =
        'title' |
        'content' |
        'okText' |
        'cancelText' |
        'width' |
        'okType' |
        'keyboard' |
        'autoFocusButton' |
        'icon';
    type Options = Pick<ModalOptions, OptionsKey>;

    const confirmWithOptions = (options: Options): Promise<boolean> => new Promise((resolve) => {
        modal.value = Modal.confirm({
            ...options,
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
        });
    });

    /**
     * モーダルを表示します。
     */
    const confirm = (
        title: string,
        content: (string | VNode | ((h: CreateElement) => VNode)),
        okText = 'OK',
        cancelText = 'Cancel',
        width: number | undefined = undefined,
        okType?: string,
        icon?: string | ((h: CreateElement) => VNode),
    ): Promise<boolean> => confirmWithOptions({
        title,
        content,
        okText,
        okType,
        cancelText,
        autoFocusButton: 'cancel',
        width,
        icon,
    });

    const error = (
        title: string,
        content: (string | VNode | ((h: CreateElement) => VNode)),
    ): Promise<void> => new Promise<void>(resolve => {
        modal.value = Modal.error({
            title,
            content,
            onOk: () => resolve(),
        });
    });

    onUnmounted(() => modal.value?.destroy());

    return { confirm, confirmWithOptions, error };
};

export type TbxCustomModalOptions<T> = Pick<ModalOptions, 'width' | 'maskClosable'> & {
    content: (h: Function, resolve: (value: T | undefined) => void) => VNode,
};

export const useCustomModal = () => {
    const showModal = <T>(options: TbxCustomModalOptions<T>): Promise<T | undefined> => {
        return new Promise(outerResolve => {
            const modal = ref<ModalConfirm | undefined>();

            const innerResolve = (value: T | undefined) => {
                if (modal.value !== undefined) {
                    modal.value.destroy();
                }
                outerResolve(value);
            };

            modal.value = Modal.confirm({
                content: (h: Function) => options.content(h, innerResolve),
                onCancel: () => {
                    outerResolve(undefined);
                },
                maskClosable: options.maskClosable,
                width: options.width,
                // 共通スタイルは`less/global.less`で設定
                class: 'tbx-custom-modal',
            });
        });
    };

    return { showModal };
};
