<script setup lang="ts">
import { computed } from 'vue';
import { Util } from '@/util';

const props = withDefaults(defineProps<{
    value?: number;
    allowClear?: boolean,
}>(), {
    allowClear: true,
});

const emits = defineEmits<{
    (e: 'input', value: number | undefined): void;
    (e: 'pressEnter'): void;
}>();

const id = computed({
    get: () => props.value?.toString() ?? '',
    set: (newValue) => {
        const id = Util.toNumber(newValue);
        emits('input', id > 0 ? id : undefined);
    }
});
</script>

<template>
    <a-input v-model="id"
             :allow-clear="allowClear"
             :max-length="16"
             @pressEnter="emits('pressEnter')"/>
</template>

<style scoped lang="less">
</style>
