import { TbxRouteConfig } from '@/router/routes/types';
import { Route } from 'vue-router/types/router';
import TruckPage from '@/pages/Truck/index.vue';
import TruckSearchPage from '@/pages/Truck/Search/index.vue';
import TruckRegisterPage from '@/pages/Truck/Register/index.vue';
import TruckListPage from '@/pages/Truck/List/index.vue';

import _TruckPage from '@/_pages/Truck/Truck.vue';
import _TruckSearchPage from '@/_pages/Truck/Search/TruckSearch.vue';
import _TruckRegisterPage from '@/_pages/Truck/Register/TruckRegister.vue';
import _TruckEditPage from '@/_pages/Truck/Edit/TruckEdit.vue';
import _TruckListPage from '@/_pages/Truck/List/TruckList.vue';
import _CompanyTruckListPage from '@/_pages/Truck/CompanyTruck/List/CompanyTruckList.vue';
import _CompanyTruckRegisterPage from '@/_pages/Truck/CompanyTruck/Register/CompanyTruckRegister.vue';
import _CompanyTruckEditPage from '@/_pages/Truck/CompanyTruck/Edit/CompanyTruckEdit.vue';
import _TruckSchedulerPage from '@/_pages/Truck/Schedule/Scheduler.vue';

export const truckRoutes: TbxRouteConfig[] = [
    {
        path: '/truck',
        name: 'Truck',
        component: _TruckPage,
        backComponent: TruckPage,
        children: [
            {
                path: 'search',
                name: 'TruckSearch',
                component: _TruckSearchPage,
                backComponent: TruckSearchPage,
                meta: { activeTabKey: 'search', title: '空車検索' },
                props: (route: Route) => ({ companyId: route.query.companyId, truckId: route.query.truckId }),
            },
            {
                path: 'register',
                name: 'TruckRegister',
                component: _TruckRegisterPage,
                backComponent: TruckRegisterPage,
                props: (route: Route) => ({
                    sourceTruckId: route.query.sourceTruckId,
                    companyTruckId: route.query.companyTruckId,
                    sourceAgreementId: route.query.sourceAgreementId,
                }),
                meta: { activeTabKey: 'register', title: '空車登録' },
            },
            {
                path: 'list',
                name: 'TruckList',
                component: _TruckListPage,
                backComponent: TruckListPage,
                meta: { activeTabKey: 'list', title: '登録した空車情報' },
            },
            {
                path: 'company-truck',
                name: 'CompanyTruckList',
                component: _CompanyTruckListPage,
                meta: { activeTabKey: 'company-truck', title: '車両マスタ' },
            },
            {
                path: 'scheduler',
                name: 'TruckScheduler',
                component: _TruckSchedulerPage,
                meta: { activeTabKey: 'scheduler', title: '空車表' },
                props: (route: Route) => ({ eventId: route.query.eventId }),
            }
        ],
    },
    {
        path: '/truck/:truckId/edit',
        name: 'TruckEdit',
        component: _TruckEditPage,
        props: (route: Route) => ({ truckId: Number(route.params.truckId) }),
        meta: {
            title: '空車編集',
        }
    },
    {
        path: '/truck/company-truck/register',
        name: 'CompanyTruckRegister',
        component: _CompanyTruckRegisterPage,
        props: (route: Route) => ({
            sourceCompanyTruckId: route.query.sourceCompanyTruckId,
        }),
        meta: {
            title: '車両追加',
        }
    },
    {
        path: '/truck/company-truck/:companyTruckId/edit',
        name: 'CompanyTruckEdit',
        component: _CompanyTruckEditPage,
        props: (route: Route) => ({
            companyTruckId: Number(route.params.companyTruckId),
        }),
        meta: {
            title: '車両編集',
        }
    },
];
