import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateUtil, ProductUtil } from '@/util';
import { Const } from '@/const';
import { CompanyContract, CompanyPaymentMethod } from '@/vuex/modules/company/types';
import { Enum } from '@/types/enum';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

export type DescriptionType = 'TRIAL' | 'PREMIUM';

@Component({
    components: { TbxLinkText }
})
export default class GuaranteeConfirmModalContent extends Vue {
    @Prop()
    declare readonly contractList?: CompanyContract[];
    @Prop()
    declare readonly contractStartDate?: string;
    @Prop()
    declare readonly paymentMethod?: Enum<CompanyPaymentMethod>;
    guaranteePageUrl = Const.externalPageUrl.guarantee;

    private confirmedTerms = false;

    get confirmed(): boolean {
        return this.confirmedTerms;
    }

    set confirmed(newValue: boolean) {
        this.confirmedTerms = newValue;
        this.$emit('onChangeConfirmed', newValue);
    }

    /**
     * 申し込み前の確認テキストを種類を取得します。
     */
    get descriptionType(): DescriptionType {
        if (!this.contractList || !Array.isArray(this.contractList)) {
            throw new Error('contactList is not defined.');
        }
        if (this.contractList.some((contract: CompanyContract) => ProductUtil.isTrial(contract.product.code))) {
            return 'TRIAL';
        } else if (
            this.contractList.some((contract: CompanyContract) => ProductUtil.isPremium(contract.product.code))
        ) {
            return 'PREMIUM';
        }
        throw new Error('Unable to detect Plan from contractList.');
    }

    /**
     * いま申し込んだ場合、有料別途課金が適用されるか否かを取得します。
     */
    get isApplyPremiumFreeMonth(): boolean {
        return DateUtil.now().get('date') > Const.MONTHLY_TERM_END_DAY;
    }

    /**
     * 運賃全額保証サービスの課金開始月を取得します。
     */
    get billingStartForGuarantee(): string {
        return DateUtil.now().format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
    }

    /**
     * プレミアムプランの課金開始月を取得します。
     */
    get billingStartForPremiumPlan(): string {
        return DateUtil.now()
            .startOf('month')
            .add(this.isApplyPremiumFreeMonth ? 1 : 0, 'month')
            .format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
    }

    /**
     * プレミアムプラン契約が可能な否かを取得します
     */
    get canContractPremiumPlan(): boolean {
        if (!this.contractList || !Array.isArray(this.contractList)) {
            throw new Error('contactList is not defined.');
        }
        return !this.contractList.some((each) => ProductUtil.isPremium(each.product.code));
    }

    mounted(): void {
        switch (this.descriptionType) {
            case 'TRIAL':
            case 'PREMIUM':
                break;
            default:
                throw new Error(`description type is not defined. [descriptionType] => ${this.descriptionType}`);
        }
    }
}
