import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as companyTypes from '@/vuex/modules/company/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
// @ts-ignore
import GuaranteeConfirmModalContent from '@/components/Common/Guarantee/ConfirmModalContent';
import * as accountTypes from '@/vuex/modules/account/types';
import { AccountProfileModel } from '@/models/account-profile';
import {
    CompanyContractGuaranteeRevokeUnavailableCode,
    CompanyContractListModel
} from '@/models/company-contract-list';

const accountMod = namespace('account');
const companyMod = namespace('company');

type RevokeUnavailableCode =
    CompanyContractGuaranteeRevokeUnavailableCode
    | 'NO_DATA'
    | 'NO_MAIN_ACCOUNT';

type RevocableReason = { result: boolean; code?: RevokeUnavailableCode };

@Component({
    title: '運賃全額保証サービスの停止',
    components: {
        GuaranteeConfirmModalContent,
    },
})
export default class MiscServiceGuaranteeRevokeEditPage extends PageVue {

    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 運賃全額保証サービスの解約ができる状態か否かを取得します。
     */
    get canRevokeGuarantee(): RevocableReason {
        // stateの更新がページに反映されてしまうのを防止
        if (this.underSubmitting) {
            return { result: true };
        } else if (!this.ACCOUNT_PROFILE || !this.CONTRACT_LIST) {
            return { result: false, code: 'NO_DATA' };
        } else if (!this.ACCOUNT_PROFILE.isMainAccount) {
            return { result: false, code: 'NO_MAIN_ACCOUNT' };
        }
        return this.CONTRACT_LIST.canRevokeGuarantee;
    }

    /**
     * 運賃全額保証サービスの製品名を取得
     */
    get guaranteeProductName(): string | undefined {
        return this.CONTRACT_LIST?.guaranteeContract?.product?.label;
    }

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    underSubmitting = false;
    visibleConfirmModal = false;

    /**
     * 申し込み確認事項を確認済か否か
     */
    confirmedTerms = false;

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 便利サービストップに戻るボタンを押下された際に呼び出されます。
     */
    async onClickBackToMiscTop(): Promise<void> {
        await this.$router.push({ name: 'MiscService' });
    }

    /**
     * 運賃全額保証サービスのお申し込みボタンが押下された際に呼び出されます。
     */
    async onClickRevoke(): Promise<void> {
        if (!this.ACCOUNT_PROFILE?.isMainAccount) {
            return;
        }
        // 申し込み前確認モーダルを開きます。
        this.visibleConfirmModal = true;
    }

    /**
     * 運賃全額保証サービスの申し込み確認事項の確認on/offイベントをハンドリングします。
     * @param confirmed
     */
    onChangeConfirmed(confirmed: boolean): void {
        this.confirmedTerms = confirmed;
    }

    /**
     * 運賃全額保証サービスを停止を申し込みます。
     */
    submit(): Promise<void> {
        const notifyKey = 'REVOKE_GUARANTEE';
        this.$notification.close(notifyKey);
        this.loading = true;
        this.underSubmitting = true;
        return (
            MiscServiceGuaranteeRevokeEditPage.revokeGuarantee()
                .then(() => {
                    this.$router.push({ name: 'MiscServiceGuaranteeRevokeComplete' });
                })
                .catch(() => {
                    this.$notification.error({
                        key: notifyKey,
                        message: '運賃全額保証サービスの停止手続きができませんでした。',
                        description:
                            '申し訳ありませんが、時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.visibleConfirmModal = false;
                })
        );
    }

    /**
     * 運賃全額保証サービスを停止します。
     */
    private static revokeGuarantee(): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.REVOKE_GUARANTEE }`);
    }
}
