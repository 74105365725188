import { createGlobalState } from '@vueuse/core';
import { DeliveryOrderPrintModel } from '@/models/delivery-order';
import { ref } from 'vue';

/**
 * 配送依頼遺書を印刷内容を保存する機能を提供します。
 */
export const useDeliveryOrderPrintSave = createGlobalState(() => {
    const doc = ref<DeliveryOrderPrintModel>();

    const saveItem = (document: DeliveryOrderPrintModel) => {
        doc.value = document;
    };

    const clear = () => {
        doc.value = undefined;
    };

    return {
        state: {
            doc
        },
        saveItem,
        clear
    };
});
