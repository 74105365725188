import {
    ACTION as a,
    GETTER as g,
    MUTATION as m,
    NegotiationList, NegotiationListForm, NegotiationRegisterForm,
    NegotiationState,
} from './types';
import { Const } from '@/const';
import _ from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { negotiationApi } from '@/repository/api/internal/negotiation';

const defaultNegotiationList = {
    totalPageCount: 0,
    totalRecordCount: 0,
    currentPageNumber: 1,
    pageSize: Const.DEFAULT_PAGE_SIZE,
    data: [],
};

const state: NegotiationState = {
    negotiationList: _.cloneDeep(defaultNegotiationList),
    negotiationRequests: [],
};

const actions: ActionTree<NegotiationState, void> = {
    [a.LIST_NEGOTIATION]: ({ commit }, param: { id: number, form: NegotiationListForm}) =>
        negotiationApi.list(param.id, param.form).then(list => commit(m.SET_NEGOTIATION_LIST, list)),

    [a.REGISTER_NEGOTIATION]: ({ commit }, param: NegotiationRegisterForm) =>
        negotiationApi.register(param),

    [a.QUERY_NEGOTIATION_REQUEST]: ({ commit }, param: { baggageIds: number[] }) =>
        negotiationApi.query(param.baggageIds).then(negotiations => commit(m.SET_NEGOTIATION_REQUESTS, negotiations)),
};

const getters: GetterTree<NegotiationState, void> = {
    [g.NEGOTIATION_LIST]: (s) => s.negotiationList,
    [g.NEGOTIATION_REQUESTS]: (s) => s.negotiationRequests,
};

const mutations: MutationTree<NegotiationState> = {
    [m.SET_NEGOTIATION_LIST]: (s, list: NegotiationList) => {
        s.negotiationList = list;
    },
    [m.SET_NEGOTIATION_REQUESTS]: (s, negotiations) => s.negotiationRequests = negotiations,
};

export const negotiation: Module<NegotiationState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
