import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Const } from '@/const';
import { Util } from '@/util';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: { TbxIcon, TbxLinkText },
})
export default class AgreementFreight extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;
    @Prop()
    declare readonly iAmTransporter?: boolean;

    externalPages = Const.externalPageUrl;

    get freight(): string {
        const freight = this.agreement?.freight ?? 0;
        return freight > 0 ? `${Util.formatNumber(freight)} 円` : '要相談';
    }

    get freightCr(): string {
        if (!this.agreementCr) return '';
        const freight = this.agreementCr.freight;
        return freight > 0 ? `${Util.formatNumber(freight)} 円` : '要相談';
    }

    get changeRequest(): string {
        return this.freight != this.freightCr ? this.freightCr : '';
    }
}
