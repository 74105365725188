<script setup lang="ts">
import { CompanyConfidence, CompanyProfile, CompanyStatistics, OfficialCompany } from '@/models/company';
import { computed } from 'vue';
import { DateUtil, PhoneUtil, ZipUtil } from '@/util';
import { Const } from '@/const';

const props = defineProps<{
    profile: CompanyProfile,
    officialCompany?: OfficialCompany,
    confidence: CompanyConfidence,
    statistics: CompanyStatistics,
}>();

const nameKanji = computed(() => props.profile.name.kanji);
const nameKana = computed(() => props.profile.name.kana ?? '');
const address = computed(() => {
    const zip = props.profile.zipCode;
    const prefecture = props.profile.location.prefecture.label ?? '';
    const city = props.profile.location.city;
    const address = props.profile.location.address;
    return `〒${ ZipUtil.format(zip) } ${ prefecture }${ city }${ address }`;
});
const phoneNumber = computed(() => PhoneUtil.format(props.profile.phone.number));
const faxNumber = computed(() => PhoneUtil.format(props.profile.phone.faxNumber));
const taxCompanyId = computed(() => props.officialCompany?.taxCompanyId);
const description = computed(() => props.profile.description ?? '');
const truckCount = computed(() => props.profile.truckCount.toString());
const url = computed(() => props.profile.url ?? '');
const registrationDate = computed(() => DateUtil.parseDateText(props.profile.registrationDate).format(Const.DEFAULT_PRINT_DATE_FORMAT));
const pastMonthBaggageCount = computed(() => props.statistics.pastMonthBaggageCount.toString());
const pastThreeMonthsBaggageCount = computed(() => props.statistics.pastThreeMonthsBaggageCount.toString());
const pastMonthTruckCount = computed(() => props.statistics.pastMonthTruckCount.toString());
const pastThreeMonthsTruckCount = computed(() => props.statistics.pastThreeMonthsTruckCount.toString());
const representativeName = computed(() => props.profile.representativeName ?? '');
const establishmentDate = computed(() => {
    if (!props.profile.establishmentDate) {
        return '';
    }
    return DateUtil.parseDateText(props.profile.establishmentDate).format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
});
const capital = computed(() => {
    const capital = props.profile.capital;
    if (capital) {
        return `${ (capital / 10000).toString() }万円`;
    } else {
        return '';
    }
});
const employeesNumber = computed(() => props.profile.employeesNumber?.label ?? '');
const officeAddress = computed(() => props.profile.officeAddress ?? '');
const sales = computed(() => {
    const sales = props.profile.sales;
    if (sales) {
        return `${ (sales / 10000).toString() }万円`;
    } else {
        return '';
    }
});
const bank = computed(() => props.profile.bank ?? '');
const customer = computed(() => props.profile.customer ?? '');
const cutOffDate = computed(() => props.profile.cutOffDay?.label ?? '');
const paymentDate = computed(() => {
    const month = props.profile.paymentMonth?.label ?? '';
    const day = props.profile.paymentDay?.label ?? '';
    return `${ month } ${ day }`;
});
const unionName = computed(() => props.confidence.unionName ?? '');
const mlitGrantNumber = computed(() => props.confidence.mlitGrantNumber ?? '');
const salesArea = computed(() => props.profile.salesArea ?? '');
const digitalTachometerCount = computed(() => props.confidence.digitalTachometerCount ? `${ props.confidence.digitalTachometerCount }台` : '');
const gpsCount = computed(() => props.confidence.gpsCount ? `${ props.confidence.gpsCount }台` : '');
const goodMark = computed(() => flagToText(props.confidence.goodMark));
const greenManagement = computed(() => flagToText(props.confidence.greenManagement));
const iso9000 = computed(() => flagToText(props.confidence.iso9000));
const iso14000 = computed(() => flagToText(props.confidence.iso14000));
const iso39001 = computed(() => flagToText(props.confidence.iso39001));
const insuranceName = computed(() => props.confidence.insuranceName ?? '');
const flagToText = (flag?: boolean): string => flag ? '有' : '無';
</script>

<template>
    <div class="corporate">
        <div class="corporate__profile">
            <h2>基本情報</h2>
            <div class="corporate__table-container">
                <table class="corporate__table">
                    <colgroup>
                        <col style="width: 152px;"/>
                        <col style="width: auto;"/>
                    </colgroup>
                    <tr>
                        <th>法人名・事業者名</th>
                        <td>{{ nameKanji }}</td>
                    </tr>
                    <tr>
                        <th>ふりがな</th>
                        <td>{{ nameKana }}</td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td>{{ address }}</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>{{ phoneNumber }}</td>
                    </tr>
                    <tr>
                        <th>FAX番号</th>
                        <td>{{ faxNumber }}</td>
                    </tr>
                    <tr>
                        <th>請求事業者<br>登録番号</th>
                        <td>{{ taxCompanyId }}</td>
                    </tr>
                    <tr>
                        <th>業務内容・<br>会社PR</th>
                        <td><span class="word-break-text">{{ description }}</span></td>
                    </tr>
                    <tr>
                        <th>保有車両台数</th>
                        <td>{{ truckCount }}</td>
                    </tr>
                    <tr>
                        <th>ウェブサイトURL</th>
                        <td><span class="word-break-text">{{ url }}</span></td>
                    </tr>
                    <tr>
                        <th>登録年月</th>
                        <td>{{ registrationDate }}</td>
                    </tr>
                    <tr>
                        <th>過去1ヵ月の<br/>
                            荷物登録件数
                        </th>
                        <td>{{ pastMonthBaggageCount }}</td>
                    </tr>
                    <tr>
                        <th>過去3ヵ月の<br/>
                            荷物登録件数
                        </th>
                        <td>{{ pastThreeMonthsBaggageCount }}</td>
                    </tr>
                    <tr>
                        <th>過去1ヵ月の<br/>
                            車両登録件数
                        </th>
                        <td>{{ pastMonthTruckCount }}</td>
                    </tr>
                    <tr>
                        <th>過去3ヵ月の<br/>
                            車両登録件数
                        </th>
                        <td>{{ pastThreeMonthsTruckCount }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="corporate_extra-confidence">
            <h2>詳細情報</h2>
            <div class="corporate__table-container">
                <table class="corporate__table">
                    <colgroup>
                        <col style="width: 176px;"/>
                        <col style="width: auto;"/>
                    </colgroup>
                    <tr>
                        <th>代表者</th>
                        <td>{{ representativeName }}</td>
                    </tr>
                    <tr>
                        <th>設立</th>
                        <td>{{ establishmentDate }}</td>
                    </tr>
                    <tr>
                        <th>資本金</th>
                        <td>{{ capital }}</td>
                    </tr>
                    <tr>
                        <th>従業員数</th>
                        <td>{{ employeesNumber }}</td>
                    </tr>
                    <tr>
                        <th>事業所所在地</th>
                        <td>{{ officeAddress }}</td>
                    </tr>
                    <tr>
                        <th>年間売上高</th>
                        <td>{{ sales }}</td>
                    </tr>
                    <tr>
                        <th>取引先銀行</th>
                        <td>{{ bank }}</td>
                    </tr>
                    <tr>
                        <th>主要取引先</th>
                        <td>{{ customer }}</td>
                    </tr>
                    <tr>
                        <th>締め日</th>
                        <td>{{ cutOffDate }}</td>
                    </tr>
                    <tr>
                        <th>支払月・支払日</th>
                        <td>{{ paymentDate }}</td>
                    </tr>
                    <tr>
                        <th>加入組織</th>
                        <td>{{ unionName }}</td>
                    </tr>
                    <tr>
                        <th>国交省認可番号</th>
                        <td>{{ mlitGrantNumber }}</td>
                    </tr>
                    <tr>
                        <th>営業地域</th>
                        <td>{{ salesArea }}</td>
                    </tr>
                    <tr>
                        <th>デジタコ搭載数</th>
                        <td>{{ digitalTachometerCount }}</td>
                    </tr>
                    <tr>
                        <th>GPS搭載数</th>
                        <td>{{ gpsCount }}</td>
                    </tr>
                    <tr>
                        <th>安全性優良事業所認定</th>
                        <td>{{ goodMark }}</td>
                    </tr>
                    <tr>
                        <th>グリーン経営認証</th>
                        <td>{{ greenManagement }}</td>
                    </tr>
                    <tr>
                        <th>ISO9000</th>
                        <td>{{ iso9000 }}</td>
                    </tr>
                    <tr>
                        <th>ISO14000</th>
                        <td>{{ iso14000 }}</td>
                    </tr>
                    <tr>
                        <th>ISO39001</th>
                        <td>{{ iso39001 }}</td>
                    </tr>
                    <tr>
                        <th>荷物保険</th>
                        <td>{{ insuranceName }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.corporate {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    gap: 0 24px;
    grid-template-areas: 'left right';

    .corporate__profile {
        grid-area: left;
    }

    .corporate_extra-confidence {
        grid-area: right;
    }

    .corporate__table-container {
        border: 1px solid #e8e8e8;
        border-radius: 4px;
    }

    .corporate__table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid #e8e8e8;

            &:last-child {
                border-bottom: none;
            }

            th, td {
                padding: 8px 16px;
            }

            th {
                font-weight: normal;
                background-color: #fafafa;
                border-right: 1px solid #e8e8e8;
            }

            td {
                border-right: none;
            }
        }
    }
}

.word-break-text {
    white-space: pre-wrap;
    word-break: break-all;
}
</style>
