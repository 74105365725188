import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ACTION as a, FormDirtyState, GETTER as g, MUTATION as m } from './types';

const state: FormDirtyState = {
    formDirty: false,
};

const actions: ActionTree<FormDirtyState, void> = {
    [a.SET_FORM_DIRTY]: ({ commit }, param: boolean) => commit(m.SET_FORM_DIRTY, param),
};

const getters: GetterTree<FormDirtyState, void> = {
    [g.FORM_DIRTY]: (s) => s.formDirty,
};

const mutations: MutationTree<FormDirtyState> = {
    [m.SET_FORM_DIRTY]: (s, isDirty) => (s.formDirty = isDirty),
};

export const formDirty: Module<FormDirtyState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
