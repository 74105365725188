<script setup lang="ts">
import { BaggageDetailUtil } from '@/util';
import { Const } from '@/const';
import { computed } from 'vue';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { SettlementModel } from '@/models/settlement';

type KeysRequired =
    | 'type'
    | 'express'
    | 'share'
    | 'shape'
    | 'category'
    | 'paletteCount'
    | 'paletteHeight'
    | 'paletteWidth'
    | 'totalCount'
    | 'totalVolume'
    | 'totalWeight'
    | 'loadingOperation'
    | 'unloadingOperation';
const props = defineProps<{
    settlement: Pick<SettlementModel, KeysRequired>;
}>();

const paletteCount = computed<string | undefined>(() => {
    return BaggageDetailUtil.paletteCount(
        props.settlement.shape.code,
        props.settlement.paletteCount
    );
});

const paletteSize = computed<string | undefined>(() => {
    return BaggageDetailUtil.paletteSize(
        props.settlement?.shape.code,
        props.settlement?.paletteHeight,
        props.settlement?.paletteWidth
    );
});

const totalCount = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalCount(
        props.settlement.shape.code,
        props.settlement.totalCount
    );
});

const totalVolume = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalVolume(
        props.settlement.shape.code,
        props.settlement.totalVolume
    );
});

const totalWeight = computed<string | undefined>(() => {
    return BaggageDetailUtil.totalWeight(props.settlement.totalWeight);
});

const handling = computed<string | undefined>(() => {
    return BaggageDetailUtil.handling(
        props.settlement.loadingOperation?.code,
        props.settlement.unloadingOperation?.code
    );
});

const baggageType = computed<string | undefined>(() => {
    return props.settlement.type;
});

const baggageTypeDetail = computed<string | undefined>(() => {
    if (!props.settlement) return;
    return Const.SHARE_BAGGAGE_MESSAGE + '：' +
        roundOrCross(props.settlement.share) + ' / ' +
        Const.EXPRESS_BAGGAGE_MESSAGE + '：' +
        roundOrCross(isExpress()) + ' / ' +
        Const.RELOCATION_BAGGAGE_MESSAGE + '：' +
        roundOrCross(isRelocation());
});

const isExpress = (): boolean => {
    return props.settlement.express;
};

const isRelocation = (): boolean => {
    return props.settlement.category.code === BaggageCategoryEnum.Relocation.code;
};

const roundOrCross = (isWhat: boolean): string => {
    return (isWhat ? '○' : '×');
};
</script>

<template>
    <div>
        <span>{{ baggageType }}</span><br/>
        <span v-if="paletteCount">{{ paletteCount }}<br></span>
        <span v-if="paletteSize">{{ paletteSize }}<br></span>
        <span v-if="totalCount">{{ totalCount }}<br></span>
        <span v-if="totalVolume">{{ totalVolume }}m<sup>3</sup><br></span>
        <span v-if="totalWeight">{{ totalWeight }}<br></span>
        <span v-if="handling">{{ handling }}<br></span>
        <span v-if="baggageTypeDetail">{{ baggageTypeDetail }}<br></span>
    </div>
</template>

<style scoped lang="less">

</style>
