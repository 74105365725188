import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    CompanyAgreementAggregationPrefectureData,
    CompanyProfile,
    CompanyStatistics
} from '@/vuex/modules/company/types';
import { DateUtil, Util } from '@/util';
import { Const } from '@/const';
import { DistributionLevel, PrefectureDistributions } from '@/components/UI/PrefectureDistributionMap/types';
import _ from 'lodash';

// @ts-ignore
import AchievementBaggageIcon from '@/components/UI/Icons/AchievementBaggage';
// @ts-ignore
import AchievementTruckIcon from '@/components/UI/Icons/AchievementTruck';
// @ts-ignore
import PrefectureDistributionMap from '@/components/UI/PrefectureDistributionMap';

export type AchievementType = 'truck' | 'baggage';

@Component({
    components: {
        AchievementBaggageIcon,
        AchievementTruckIcon,
        PrefectureDistributionMap,
    }
})
export default class CompanyAchievementSummary extends Vue {
    @Prop()
    declare readonly profile?: CompanyProfile;
    @Prop()
    declare readonly statistics?: CompanyStatistics;
    @Prop({ default: false })
    declare readonly hideBaggageAchievement?: boolean;

    achievementTypeInput: AchievementType = 'baggage';

    set achievementType(value: AchievementType) {
        if (this.hideBaggageAchievement) {
            this.achievementTypeInput = 'truck';
        } else {
            this.achievementTypeInput = value;
        }
    }

    get achievementType(): AchievementType {
        if (this.hideBaggageAchievement) {
            return 'truck';
        }
        return this.achievementTypeInput;
    }

    /**
     * 集計期間を取得します。
     */
    get aggregationPeriod(): { start: string, end: string } | undefined {
        return this.statistics?.agreementAggregation?.aggregationPeriod;
    }

    /**
     * 委託.荷物登録数を取得します。
     */
    get totalBaggageCount(): number | undefined {
        return this.statistics?.agreementAggregation?.totalBaggageCount;
    }

    /**
     * 委託.成約数を取得します。
     */
    get totalBaggageAgreementCount(): number | undefined {
        return this.statistics?.agreementAggregation?.totalBaggageAgreementCount;
    }

    /**
     * 受託.成約数を取得します。
     */
    get totalTruckAgreementCount(): number | undefined {
        return this.statistics?.agreementAggregation?.totalTruckAgreementCount;
    }

    /**
     * 都道府県別実績レベルを取得します
     */
    get prefectureAchievements(): PrefectureDistributions | undefined {
        const prefectures = this.statistics?.agreementAggregation?.prefectures;
        return _.mapValues(prefectures, (each) => this.getAchievementLevel(each));
    }

    /**
     * トラボックス登録年月を取得します。
     */
    get registrationYearMonth(): string {
        if (!this.profile) return '';
        return this.formatYearMonth(this.profile.registrationDate);
    }

    /**
     * 年月フォーマットします。
     */
    formatYearMonth(date: string): string {
        return DateUtil.parseDateText(date).format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
    }

    /**
     * 数値フォーマットします。
     */
    formatNumber(value: number): string {
        return Util.formatNumber(value);
    }

    /**
     * 企業実績変更時、実績種別を委託に戻す(別の荷物を選択した再表示時は委託に戻す)
     */
    @Watch('statistics')
    onChangeCompanyStatistic(): void {
        this.achievementType = 'baggage';
    }

    /**
     * 実績レベルを取得する
     */
    private getAchievementLevel(data: CompanyAgreementAggregationPrefectureData): DistributionLevel {
        switch (this.achievementType) {
            case 'baggage':
                return DistributionLevel.valueOf(data.baggageAgreementCount);
            case 'truck':
                return DistributionLevel.valueOf(data.truckAgreementCount);
        }
    }
}
