import { ref } from 'vue';
import { GuaranteePriceMaster } from '@/models/guarantee-master';
import { useLoading } from '@/composables/helper/loading-helper';
import { masterApi } from '@/repository/api/internal/master';

export const useGuaranteeMaster = () => {
    const list = ref<GuaranteePriceMaster[]>([]);
    const { state: { loading }, withLoading } = useLoading();

    const load = (): Promise<void> => withLoading(async () => {
        list.value = await masterApi.guaranteePrice();
    });

    return {
        state: {
            loading,
            list,
        },
        load,
    };
};
