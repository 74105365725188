import { computed, onUnmounted, ref } from 'vue';
import { CompanyProfile, CompanyProfileUpdateForm } from '@/models/company';
import { PhoneUtil, Util } from '@/util';
import _ from 'lodash';
import { companyApi } from '@/repository/api/internal/company';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useMessage, useNotification } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { FormValidateUtil } from '@/models/validate-helper';

/**
 * 企業プロフィールを更新する機能を提供します。
 */
export const useCompanyProfileUpdate = () => {
    const { load } = useCompanyMyProfile();
    const message = useMessage();
    const notification = useNotification();
    const { state: { loading }, withLoading } = useLoading();

    const form = ref<CompanyProfileUpdateForm>(new CompanyProfileUpdateForm({
        zipCode: '',
        location: { prefecture: { code: '' }, city: '', address: '' },
        phone: { number: '', faxNumber: '' },
        truckCount: '0',
        url: undefined,
    }));

    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const initialize = (profile: CompanyProfile): void => {
        form.value = _.merge(form.value, {
            zipCode: profile.zipCode,
            location: profile.location,
            phone: {
                number: profile.phone.number,
                faxNumber: Util.toDigits(PhoneUtil.format(profile.phone.faxNumber)),
            },
            truckCount: `${ profile.truckCount }`,
            url: profile.url,
        });
    };

    const update = async () => {
        try {
            await withLoading(async () => {
                await companyApi.updateProfile(form.value);
                await load();
            });
            message.success('企業情報を更新しました。');
        } catch {
            notification.error({
                key: 'UPDATE_PROFILE_FAILED',
                message: '企業情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        }
    };

    onUnmounted(() => {
        notification.close('UPDATE_PROFILE_FAILED');
    });

    return {
        state: {
            form,
            formValidateRules,
            loading,
        },
        initialize,
        update,
    };
};
