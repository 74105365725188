import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    BaggageFreightMaster,
    BaggageRegisterFormModel,
} from '@/vuex/modules/baggage/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Util } from '@/util';

@Component
export default class BaggageFreightRegister extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: BaggageRegisterFormModel;
    @Prop()
    declare readonly referenceFreight?: BaggageFreightMaster;

    get freight(): string {
        return this.value?.freight ?? '';
    }

    set freight(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 運賃を書き換え
        cloned.freight = Util.parseFreightString(newValue);

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get freightDigits(): number {
        return Util.toNumber(this.freight);
    }

    get checked(): boolean {
        return this.value?.negotiate ?? false;
    }

    set checked(checked: boolean) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 入力フィールドの値を一時保存
        if (checked) {
            this.previousValue.freight = this.freight;
        }
        // 運賃を書き換え
        cloned.freight = checked ? '' : this.previousValue.freight;
        cloned.negotiate = checked;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get shouldShowLabel(): boolean {
        return this.value?.isMultipleTruckCount ?? false;
    }

    get formattedFreight(): string | undefined {
        const freight = this.referenceFreight?.freight ?? 0;
        return freight <= 0 ? '' : `${ Util.formatNumber(freight) }円`;
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    private previousValue: Partial<BaggageRegisterFormModel> = {
        freight: '',
    };

    // ======================================================
    // Functions
    // ======================================================
    private validate(callback: (message?: string) => void): void {
        if (this.checked) {
            callback();
        } else if (this.freightDigits > 0) {
            callback();
        } else {
            callback('金額を入力するか、要相談をチェックしてください。');
        }
    }
}
