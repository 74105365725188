/**
 * 荷物詳細コンテナをクリアします。
 */
import store from '@/vuex/store';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as negotiationTypes from '@/vuex/modules/negotiation/types';
import * as accountTypes from '@/vuex/modules/account/types';
import { Route } from 'vue-router/types/router';
import router from '@/router';
import { message } from 'ant-design-vue';
import { PageUtil } from '@/util';
import { gtm } from '@/gtm';
import { Const } from '@/const';
import { AccountProfileModel } from '@/models/account-profile';
import { Baggage } from '@/vuex/modules/baggage/types';
import { NegotiationTypeEnum } from '@/enums/negotiation-type.enum';

export const reloadBaggageIfNeeded = async (id: number, baggageId: number | undefined): Promise<void> => {
    if (Number(baggageId) === id) {
        await loadDetailBaggage(id, undefined);
    }
};

/**
 * ページタイトルを設定します。
 */
export const setPageTitle = (route: Route): void => {
    const { baggageId } = route.query;
    const pageTitle = ['荷物検索'];
    if (baggageId) {
        pageTitle.unshift('荷物詳細');
    }
    // ページタイトルを設定
    PageUtil.setTitle(pageTitle);
    // GTMへページビューイベントを送信
    gtm.setPage(route.fullPath, pageTitle.join(Const.PAGE_TITLE_SEPARATOR));
};

/**
 * 荷物詳細コンテナをクリアします。
 */
export const clearDetailBaggage = (): Promise<void> => {
    return store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_SEARCH_BAGGAGE_DETAIL_CONTAINER }`);
};

/**
 * 荷物詳細ドロワーを閉じる際に呼び出されます。
 */
export const onClickCloseBaggageDetailDrawer = (route: Route): Promise<Route> => {
    return router.push({ path: route.path });
};

/**
 * 荷物詳細関連のデータ一式をロードします。
 */
export const loadDetailBaggage = async (id: number, ref: string | undefined): Promise<void> => {
    const baggage = await store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_SEARCH_BAGGAGE_DETAIL_CONTAINER }`, [id, ref]) as Baggage;
    if (baggage.negotiationType.code === NegotiationTypeEnum.Online.code) {
        await loadNegotiationRequest(id);
    }
};

/**
 * 荷物をお気に入り保存する。
 * @param baggageId
 * @param reloadBaggageIfNeeded
 */
export const markFavorite = async (id: number, baggageId: number | undefined): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_FAVORITE }`, id)
        .catch(() => message.error('公開が終了した荷物のため、保存できません。'));
    await reloadBaggageIfNeeded(id, baggageId);
};

/**
 * 荷物をお気に入り解除する。
 * @param baggageId
 * @param reloadBaggageIfNeeded
 */
export const unmarkFavorite = async (id: number, baggageId: number | undefined): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.UNMARK_FAVORITE }`, id)
        .catch(() => message.error('保存解除できませんでした。時間をおいて再度お試しください。'));
    await reloadBaggageIfNeeded(id, baggageId);
};

/**
 * 成約が押下された際に呼び出されます。
 */
export const onClickAgree = async (baggageId: number): Promise<void> => {
    await router.push({ name: 'AgreementRegister', params: { id: `${ baggageId }` } });
};

/**
 * 「商談中にする」ボタンが押下された際に呼び出されます。
 */
export const markUnderNegotiation = async (baggageId: number): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.MARK_UNDER_NEGOTIATION }`, baggageId)
        .catch(() => message.error('公開が終了した荷物のため、商談中にできません。'));
};

/**
 * 「商談中を解除」ボタンが押下された際に呼び出されます。
 */
export const unmarkUnderNegotiation = async (baggageId: number): Promise<void> => {
    await store.dispatch(`baggage/${ baggageTypes.ACTION.UNMARK_UNDER_NEGOTIATION }`, baggageId)
        .catch(() => message.error('公開が終了した荷物のため、商談中を解除できません。'));
};

/**
 * 荷物の印刷ボタンが押下された際に呼び出されます。
 */
export const onClickPrintBaggage = async (baggageId: number): Promise<void> => {
    const route = router.resolve({ name: 'BaggageSearchPrint', params: { baggageId: baggageId.toString() } });
    window.open(route.href, '_blank');
};

/**
 * 企業名が押下された際に呼び出されます。
 */
export const onClickCompanyName = async (companyId: string): Promise<void> => {
    await router.push({ name: 'CompanySearch', query: { companyId } });
};

/**
 * ページ全体のどこかでクリックされると呼び出されます。
 */
export const onClickGround = async(routeUpdating: boolean, drawerVisibility: boolean, route: Route): Promise<void> => {
    // 連打によるルーティングの多重実行を防止
    if (routeUpdating) {
        return;
    }
    // Drawerが開いている場合は閉じる
    if (drawerVisibility) {
        await onClickCloseBaggageDetailDrawer(route);
    }
};

/**
 * 商談リクエストされると呼び出されます。
 */
export const requestNegotiation = async (baggageId: number, messageBody?: string): Promise<void> => {
    try {
        // 自分の名前を取得
        const profile = await store.getters[`account/${ accountTypes.GETTER.PROFILE }`] as AccountProfileModel;
        await store.dispatch(`negotiation/${ negotiationTypes.ACTION.REGISTER_NEGOTIATION }`, {
            baggageId,
            staffName: profile.name,
            message: messageBody,
        });
        message.success('商談をリクエストしました。');
    } catch {
        message.error('商談リクエストできませんでした。時間をおいて再度お試しください。');
    } finally {
        await loadNegotiationRequest(baggageId);
    }
};

export const loadNegotiationRequest = (baggageId: number): Promise<void> => {
    return store.dispatch(`negotiation/${ negotiationTypes.ACTION.QUERY_NEGOTIATION_REQUEST}`, {
        baggageIds: [ baggageId ],
    });
};
