import { createGlobalState } from '@vueuse/core';
import { computed, ref } from 'vue';
import { companyApi } from '@/repository/api/internal/company';
import { CompanyStatusModel } from '@/models/company-status';
import { useVuexSync } from '@/composables/helper/vuex';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/company/types';

/**
 * 自社の企業ステータスを取得する機能を提供します。
 */
export const useCompanyMyStatus = createGlobalState(() => {
    const companyStatus = ref<CompanyStatusModel | undefined>(undefined);

    useVuexSync(companyStatus, { module: 'company', mutation: m.SET_STATUS, getter: g.STATUS });

    /**
     * 企業ステータスが有効か否かを取得します。
     */
    const isActive = computed<boolean>(() => {
        return companyStatus.value?.isActive ?? false;
    });

    /**
     * 企業ステータスが審査中か否かを取得します。
     */
    const isJudging = computed<boolean>(() => {
        return companyStatus.value?.isJudging ?? false;
    });

    const load = async (): Promise<CompanyStatusModel> => {
        const data = await companyApi.myStatus();
        companyStatus.value = new CompanyStatusModel(data);
        return companyStatus.value;
    };

    const loadIfNotExists = async (): Promise<CompanyStatusModel> => {
        if (companyStatus.value === undefined) {
            return await load();
        }
        return Promise.resolve(companyStatus.value);
    };

    return {
        state: {
            companyStatus,
            isActive,
            isJudging,
        },
        load,
        loadIfNotExists,
    };
});
