import _ from 'lodash';

interface ValueObjectProps {
    [index: string]: any;
}

/**
 * ValueObject用基底クラス
 */
export abstract class ValueObject<T extends ValueObjectProps | string | number | boolean | undefined> {
    public readonly value: T;

    protected constructor(_value: T) {
        this.value = Object.freeze(_value);
    }

    equals(vo?: ValueObject<T>): boolean {
        if (!vo) {
            return false;
        }
        return _.isEqual(this.value, vo.value);
    }
}
