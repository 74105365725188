<script setup lang="ts">
import { useRouting } from '@/composables/helper/routing';
import { useAccountMenuService } from '@/composables/service/account-menu-service';
import { Vue } from 'vue-property-decorator';

const {
    name,
    menuVisible,
    highContrastMenuItemKey,
    highContrastMode,
    canViewSetting,
    externalPage: {
        privacyPolicyUrl,
        groupPrivacyPolicyUrl,
    },
    toggleHighContrastMode,
} = useAccountMenuService();
const {
    goToLogout,
    goToSettingCompanyProfile,
} = useRouting();

/**
 * ログアウトが押下された際に呼び出されます。
 */
const onClickLogout = async (): Promise<void> => {
    menuVisible.value = false;
    await goToLogout();
};

/**
 * メニューの開閉状態が変わった際に呼び出されます。
 * @param visible
 */
const onChangeMenuVisible = (visible: boolean): void => {
    menuVisible.value = visible;
};

/**
 * メニューアイテムをクリックした際に呼び出されます。
 */
const onClickMenuItem = (data: { key: string; keyPath: Array<string>; item: Vue; domEvent: Event }): void => {
    // ハイコントラストモードのメニューやスイッチをクリックした際は閉じないようにする
    if (data.key === highContrastMenuItemKey.value) {
        return;
    }
    if (menuVisible.value) {
        menuVisible.value = false;
    }
};

/**
 * 設定ページリンクが押下された際に呼び出されます。
 */
const onClickGoToSettings = async (): Promise<void> => {
    menuVisible.value = false;
    await goToSettingCompanyProfile();
};

/**
 * ハイコントラストモードのスイッチが変更された際に呼び出されます。
 */
const onClickHighContractMode = () => {
    toggleHighContrastMode();
};
</script>

<template>
    <a-dropdown :trigger="['click']" :visible="menuVisible" @visibleChange="onChangeMenuVisible">
        <div class="user-account-menu" :class="{ 'user-account-menu--open': menuVisible }">
            <a-avatar icon="user" class="user-account-menu__avatar"/>
            <span class="user-account-menu__label">{{ name }}</span>
        </div>
        <template #overlay>
            <a-menu @click="onClickMenuItem($event)">
                <a-menu-item :key="highContrastMenuItemKey" class="high-contrast-menu-item">ハイコントラストモード
                    <a-switch :checked="highContrastMode" @click="onClickHighContractMode"
                              class="high-contrast-menu-item__switch"></a-switch>
                </a-menu-item>
                <a-menu-divider></a-menu-divider>
                <a-menu-item :disabled="!canViewSetting" @click="onClickGoToSettings">設定</a-menu-item>
                <a-menu-divider></a-menu-divider>
                <a-menu-item>
                    <a :href="privacyPolicyUrl" target="_blank" rel="noopener">個人情報について</a>
                </a-menu-item>
                <a-menu-item>
                    <a :href="groupPrivacyPolicyUrl" target="_blank" rel="noopener">グループ個人情報保護方針</a>
                </a-menu-item>
                <a-menu-divider></a-menu-divider>
                <a-menu-item @click="onClickLogout">
                    <span class="menu-item-label-logout">ログアウト</span>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<style scoped lang="less">
.user-account-menu {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 64px;
    cursor: pointer;
    transition: all 300ms @ease-in-out;

    &:hover,
    &:active {
        background-color: @ui-color-selected-background-hover;
    }

    &--open {
        background-color: @item-hover-bg;
    }

    &__avatar {
        flex: 0 0 32px;
        background-color: @color-junction-blue;
    }

    &__label {
        margin-left: 8px;
    }
}

.menu-item-label-logout {
    color: @color-red;
}

.high-contrast-menu-item {
    cursor: default;

    &:hover {
        background-color: inherit;
    }

    &__switch {
        cursor: pointer;
        margin-left: 8px;
    }
}
</style>
