import { useLoading } from '@/composables/helper/loading-helper';
import { SettlementChangeRequestModel } from '@/models/settlement';
import { ref } from 'vue';
import { settlementApi } from '@/repository/api/internal/settlement';
import { useModal } from '@/composables/helper/modal-helper';
import _ from 'lodash';

export const useSettlementChangeRequest = () => {
    const { state: { loading }, withLoading } = useLoading();
    const request = ref<SettlementChangeRequestModel>();

    const load = (settlementId: number) => withLoading(async () => {
        const result = await settlementApi.findRequest(settlementId);
        request.value =  !_.isNil(result) ? new SettlementChangeRequestModel(result) : undefined;
    });

    const clear = () => {
        request.value = undefined;
    };

    return {
        state: {
            loading,
            request,
        },
        load,
        clear,
    };
};

export const useIncomeSettlementChangeRequestList = () => {
    const { state: { loading }, withLoading } = useLoading();
    const requests = ref<SettlementChangeRequestModel[]>([]);

    const load = (ids: number[]) => withLoading(async () => {
        if (ids.length === 0) return [];
        const result = await settlementApi.listRequestIncome(ids);
        requests.value = result.map(each => new SettlementChangeRequestModel(each));
    });

    return {
        state: {
            loading,
            requests,
        },
        load,
    };
};

export const useOutgoSettlementChangeRequestList = () => {
    const { state: { loading }, withLoading } = useLoading();
    const requests = ref<SettlementChangeRequestModel[]>([]);

    const load = (ids: number[]) => withLoading(async () => {
        if (ids.length === 0) return [];
        const result = await settlementApi.listRequestOutgo(ids);
        requests.value = result.map(each => new SettlementChangeRequestModel(each));
    });

    return {
        state: {
            loading,
            requests,
        },
        load,
    };
};

export const useSettlementChangeRequestApprove = () => {
    const { state: { loading }, withLoading } = useLoading();

    const approve = (settlementId: number) => withLoading(async () => {
        await settlementApi.approve(settlementId);
    });

    return {
        state: {
            loading,
        },
        approve,
    };
};

export const useSettlementChangeRequestReject = () => {
    const { state: { loading }, withLoading } = useLoading();
    const modal = useModal();

    const confirm = async () => {
        return await modal.confirm(
            '申請を却下しますか？',
            (h: Function) => h('div', [
                '却下すると、申請前の内容で請求されます。',
                h('br'),
                '取引追加申請の場合は、取引内容が削除されます。',
            ]),
            '却下',
            'キャンセル',
            450,
        );
    };

    const reject = (settlementId: number) => withLoading(async () => {
        await settlementApi.reject(settlementId);
    });

    return {
        state: {
            loading,
        },
        confirm,
        reject,
    };
};

export const useSettlementChangeRequestCancel = () => {
    const { state: { loading }, withLoading } = useLoading();

    const cancel = (settlementId: number) => withLoading(async () => {
        await settlementApi.cancel(settlementId);
    });

    return {
        state: {
            loading,
        },
        cancel,
    };
};
