import Vue from 'vue';
import Vuex, { MutationTree } from 'vuex';
import _ from 'lodash';
import { account } from '@/vuex/modules/account';
import { company } from '@/vuex/modules/company';
import { baggage } from '@/vuex/modules/baggage';
import { agreement } from '@/vuex/modules/agreement';
import { truck } from '@/vuex/modules/truck';
import { routing } from '@/vuex/modules/routing';
import { help } from '@/vuex/modules/help';
import { statistics } from '@/vuex/modules/statistics';
import { guarantee } from '@/vuex/modules/guarantee';
import { prediction } from '@/vuex/modules/prediction';
import { questionnaire } from '@/vuex/modules/questionnaire';
import { companyNotice } from '@/vuex/modules/companyNotice';
import { deliveryOrder } from '@/vuex/modules/deliveryOrder';
import { formDirty } from '@/vuex/modules/formDirty';
import { environment } from '@/vuex/modules/environment';
import { negotiation } from '@/vuex/modules/negotiation';
import { entitlement } from '@/vuex/modules/entitlement';

Vue.use(Vuex);

export const defaultModules = {
    account,
    company,
    baggage,
    agreement,
    truck,
    routing,
    help,
    statistics,
    guarantee,
    prediction,
    questionnaire,
    companyNotice,
    deliveryOrder,
    formDirty,
    environment,
    negotiation,
    entitlement,
};

/**
 * resetAllStateアクション時にリセットしないモジュール
 */
const modulesToPreventFromResetting = ['environment'];

export default new Vuex.Store({
    modules: _.cloneDeep(defaultModules),
    mutations: {
        resetAllState: (state) => _.forOwn(defaultModules, (value, key) => {
            if (!modulesToPreventFromResetting.includes(key)) {
                state[key] = _.cloneDeep(value.state);
            }
        }),
        // eslint-disable-next-line
    } as MutationTree<any>,
});
