<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import { Const } from '@/const';

const props = defineProps<{
    baggage: Baggage;
}>();

const departureDate = (): string => {
    if (!props.baggage) return '';

    const min = props.baggage.departureMin;
    const max = props.baggage.departureMax;
    if (!min || !max) return '';
    return DeliveryDateTimeRange.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
};
const arrivalDate = (): string => {
    if (!props.baggage) return '';

    const min = props.baggage.arrivalMin;
    const max = props.baggage.arrivalMax;
    if (!min || !max) return '';
    return DeliveryDateTimeRange.of(min, max)?.format(Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT) ?? '';
};

const text = computed<string>(() => {
    return `${ departureDate() } - ${ arrivalDate() }`;
});

</script>

<template>
    <span>{{ text }}</span>
</template>

<style scoped lang="less">

</style>
