<script setup lang="ts">
import { ButtonType } from '@/_components/ui/types/button-type';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    type: ButtonType,
    size?: 'large' | 'default' | 'small',
    className?: string,
    disabled?: boolean,
}>(), {
    size: 'default',
    className: 'component-button-text-m',
    disabled: false,
});
const emits = defineEmits<{
    (e: 'click', event: Event): void,
}>();
const antButtonType = computed(() => {
    switch (props.type) {
        case 'primary':
            return 'primary';
        case 'secondary':
            return 'default';
        case 'danger':
            return 'danger';
        case 'text':
            return 'link';
        default:
            return 'default';
    }
});
</script>

<template>
    <a-button :type="antButtonType" :class="className" :size="size" :disabled="disabled" @click="emits('click', $event)">
        <slot />
    </a-button>
</template>

<style scoped lang="less">
.ant-btn {
    border-radius: 4px;
    background-color: @token-main-color-white;
    color: @token-text-color-normal;
    border: 1px solid @token-component-color-border;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), @token-main-color-white;
    }

    &-primary {
        background-color: @token-main-color-trabox-green;
        color: @token-text-color-white;
        border: 1px solid @token-main-color-trabox-green;

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), @token-main-color-trabox-green;
        }
    }

    &-danger {
        background-color: @token-main-color-white;
        color: @token-text-color-danger;
        border: 1px solid @token-text-color-danger;

        &:hover {
            background: linear-gradient(0deg, rgba(245, 34, 45, 0.05), rgba(245, 34, 45, 0.05)), @token-text-color-white;
        }
    }

    &-link {
        background-color: @token-main-color-white;
        color: @token-text-color-normal;
        border: 0;

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), @token-text-color-white;
        }
    }

    &:disabled {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), @token-main-color-white;
        color: @token-text-color-disabled;
        border: 1px solid @token-component-color-border;
    }
}
</style>
