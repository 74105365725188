import { Enum } from '@/types/enum';
import { PaginatedList } from '@/types/paginated-list';
import { Const } from '@/const';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';

export interface CompanyPlaceSearchForm {
    pageNo: number;
    pageSize: number;
}

export class CompanyPlaceSearchFormModel implements CompanyPlaceSearchForm {
    pageNo: number;
    pageSize: number;

    constructor(param: Partial<CompanyPlaceSearchForm> | null = null) {
        this.pageNo = param?.pageNo ?? 1;
        this.pageSize = param?.pageSize ?? Const.DEFAULT_PAGE_SIZE;
    }
}

export interface CompanyPlacePaginatedList extends PaginatedList {
    data: CompanyPlace[];
}

export interface CompanyPlaceList {
    data: CompanyPlace[];
}

export interface CompanyPlace {
    id: number;
    companyId: number;
    placeName: string;
    pref: Enum;
    city: string;
    address?: string;
}

export class CompanyPlaceModel {
    id: number;
    companyId: number;
    placeName: string;
    pref: Enum;
    city: string;
    address?: string;

    constructor(param: CompanyPlace) {
        this.id = param.id;
        this.companyId = param.companyId;
        this.placeName = param.placeName;
        this.pref = param.pref;
        this.city = param.city;
        this.address = param.address;
    }
}

// 地点登録フォーム
export class CompanyPlaceRegisterForm {
    placeName: string;
    pref: { code?: string };
    city?: string;
    address?: string;

    constructor(param: Partial<CompanyPlaceRegisterForm> | null = null) {
        this.placeName = param?.placeName ?? '';
        this.pref = param?.pref ?? {};
        this.city = param?.city ?? '';
        this.address = param?.address ?? '';
    }
}

// 地点登録フォームモデル
export class CompanyPlaceRegisterFormValidatableModel
    extends CompanyPlaceRegisterForm
    implements FormValidatable<CompanyPlaceRegisterFormValidatableModel>{

    constructor(param: Partial<CompanyPlaceRegisterForm> | null = null) {
        super(param);
    }

    get prefCode(): PrefectureEnumCode {
        return this.pref.code as PrefectureEnumCode;
    }

    set prefCode(value: PrefectureEnumCode) {
        const changed = this.pref.code !== value;
        this.pref = { code: value };
        if (changed) {
            this.city = '';
            this.address = '';
        }
    }

    /**
     * {@link CompanyPlaceRegisterForm}を生成します
     */
    toForm(): CompanyPlaceRegisterForm {
        return new CompanyPlaceRegisterForm(this);
    }

    private validateAddress(callback: (message?: string) => void): void {
        if (!this.prefCode) {
            callback('都道府県を選択してください。');
        } else if (!this.city?.trim()) {
            callback('市区町村を入力してください。');
        } else if (this.city.length > 200) {
            callback('市区町村名は200文字以内で入力してください。');
        } else if ((this.address?.length ?? 0) > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    }

    validator(): FormValidator<CompanyPlaceRegisterFormValidatableModel> {
        return {
            placeName: [
                {
                    required: true,
                    message: '地点名を入力してください。'
                }
            ],
            address: [
                {
                    required: true,
                    validator: (_rule, _value, callback: Function) =>
                        this.validateAddress(callback as (message?: string) => void),
                }
            ],
        };
    }

    static of(companyPlace: CompanyPlace): CompanyPlaceRegisterFormValidatableModel {
        const instance = new CompanyPlaceRegisterFormValidatableModel();
        instance.placeName = companyPlace.placeName;
        instance.pref = { code: companyPlace.pref.code.toString() };
        instance.city = companyPlace.city;
        instance.address = companyPlace.address;
        return instance;
    }

    public normalize(): void {
        this.city = this.city?.trim();
        this.address = this.address?.trim();
    }
}


// 地点更新フォーム
export class CompanyPlaceUpdateForm {
    placeName: string;
    pref: { code?: string };
    city?: string;
    address?: string;

    constructor(param: Partial<CompanyPlaceUpdateForm> | null = null) {
        this.placeName = param?.placeName ?? '';
        this.pref = param?.pref ?? {};
        this.city = param?.city ?? '';
        this.address = param?.address ?? '';
    }
}

export class CompanyPlaceUpdateFormValidatableModel
    extends CompanyPlaceUpdateForm
    implements FormValidatable<CompanyPlaceUpdateFormValidatableModel> {

    constructor(param: Partial<CompanyPlaceUpdateForm> | null = null) {
        super(param);
    }

    get prefCode(): PrefectureEnumCode {
        return this.pref.code as PrefectureEnumCode;
    }

    set prefCode(value: PrefectureEnumCode) {
        const changed = this.pref.code !== value;
        this.pref = { code: value };
        if (changed) {
            this.city = '';
            this.address = '';
        }
    }

    /**
     * {@link CompanyPlaceUpdateForm}を生成します
     */
    toForm(): CompanyPlaceUpdateForm {
        return new CompanyPlaceUpdateForm(this);
    }

    private validateAddress(callback: (message?: string) => void): void {
        if (!this.prefCode) {
            callback('都道府県を選択してください。');
        } else if (!this.city?.trim()) {
            callback('市区町村を入力してください。');
        } else if (this.city.length > 200) {
            callback('市区町村名は200文字以内で入力してください。');
        } else if ((this.address?.length ?? 0) > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    }


    validator(): FormValidator<CompanyPlaceUpdateFormValidatableModel> {
        return {
            placeName: [
                {
                    required: true,
                    message: '地点名を入力してください。'
                }
            ],
            address: [
                {
                    required: true,
                    validator: (_rule, _value, callback: Function) =>
                        this.validateAddress(callback as (message?: string) => void),
                }
            ],
        };
    }

    static of(companyPlace: CompanyPlace): CompanyPlaceUpdateFormValidatableModel {
        const instance = new CompanyPlaceUpdateFormValidatableModel();
        instance.placeName = companyPlace.placeName;
        instance.pref = { code: companyPlace.pref.code.toString() };
        instance.city = companyPlace.city;
        instance.address = companyPlace.address;
        return instance;
    }

    public normalize(): void {
        this.city = this.city?.trim();
        this.address = this.address?.trim();
    }
}
