/**
 * トラックの床高Enum
 */
export type TruckWidthEnumCode =
    | 'TW01'
    | 'TW02'
    | 'TW03'
    | 'TW99';

export class TruckWidthEnum {
    constructor(public code: TruckWidthEnumCode, public label: string, public orderNo: number) {}

    static StandardOnly = new TruckWidthEnum('TW01', '標準のみ', 1);
    static OverSemiWide = new TruckWidthEnum('TW02', 'セミワイド以上', 1);
    static FullWideOnly = new TruckWidthEnum('TW03', 'フルワイドのみ', 1);
    static Unspecified = new TruckWidthEnum('TW99', '問わず', 1);

    static valueOf = (code: TruckWidthEnumCode): TruckWidthEnum | undefined =>
        TruckWidthEnum.allValues.find((value) => value.code === code);

    static values = [
        TruckWidthEnum.StandardOnly,
        TruckWidthEnum.OverSemiWide,
        TruckWidthEnum.FullWideOnly,
    ];
    static allValues = [
        ...TruckWidthEnum.values,
        TruckWidthEnum.Unspecified,
    ];
    // 荷物で登録可能な値
    static registrableValuesForBaggage = [TruckWidthEnum.Unspecified, ...TruckWidthEnum.values];
}

export const isTruckWidthEnumCode = (value: unknown): value is TruckWidthEnumCode => {
    return typeof value === 'string' && TruckWidthEnum.allValues.find((item) => item.code === value) !== undefined;
};
