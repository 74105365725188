import { TbxRouteConfig } from '@/router/routes/types';
import SettingPage from '@/pages/Setting/index.vue';
import SettingAccountListPage from '@/pages/Setting/Account/List/index.vue';
import _SettingAccountListPage from '@/_pages/Setting/Account/List/SettingAccountList.vue';
import SettingAccountCreateOrEditPage from '@/pages/Setting/Account/CreateOrEdit/index.vue';
import SettingAccountCreateCompletePage from '@/pages/Setting/Account/CreateComplete/index.vue';
import SettingCompanyProfilePage from '@/pages/Setting/Company/Profile/index.vue';
import SettingCompanyProfileExtraPage from '@/pages/Setting/Company/ProfileExtra/index.vue';
import SettingCompanyConfidencePage from '@/pages/Setting/Company/Confidence/index.vue';
import SettingCompanyContractPage from '@/pages/Setting/Company/Contract/index.vue';
import SettingCompanyTransferPage from '@/pages/Setting/Company/Transfer/index.vue';
import SettingCompanyPaymentPage from '@/pages/Setting/Company/Payment/index.vue';
import SettingBillingPage from '@/pages/Setting/Billing/index.vue';
import SettingEmailBaggageAndTruckPage from '@/pages/Setting/Email/BaggageAndTruck/index.vue';
import RevokePremiumPlanPage from '@/pages/Setting/RevokePremiumPlan/Introduction/index.vue';
import RevokePremiumPlanFormPage from '@/pages/Setting/RevokePremiumPlan/Edit/index.vue';
import RevokePremiumPlanCompletePage from '@/pages/Setting/RevokePremiumPlan/Complete/index.vue';
import _RevokePremiumPlanPage from '@/_pages/Setting/RevokePremiumPlan/Introduction/RevokePremiumPlanIntroduction.vue';
import _RevokePremiumPlanFormPage from '@/_pages/Setting/RevokePremiumPlan/Edit/RevokePremiumPlanEdit.vue';
import _RevokePremiumPlanCompletePage from '@/_pages/Setting/RevokePremiumPlan/Complete/RevokePremiumPlanComplete.vue';

// リファクタリングページ
import _SettingPage from '@/_pages/Setting/Setting.vue';
import _SettingCompanyProfilePage from '@/_pages/Setting/Company/Profile/SettingCompanyProfile.vue';
import _SettingCompanyProfileExtraPage from '@/_pages/Setting/Company/ProfileExtra/SettingCompanyProfileExtra.vue';
import _SettingBillingPage from '@/_pages/Setting/Billing/SettingBilling.vue';
import _SettingCompanyConfidencePage from '@/_pages/Setting/Company/Confidence/SettingCompanyConfidence.vue';
import _SettingCompanyTransferPage from '@/_pages/Setting/Company/Transfer/SettingCompanyTransfer.vue';
import _SettingCompanyPaymentPage from '@/_pages/Setting/Company/Payment/SettingCompanyPayment.vue';
import _SettingCompanyPlaceListPage from '@/_pages/Setting/CompanyPlace/List/CompanyPlaceList.vue';
import _SettingCompanyPlaceRegisterPage from '@/_pages/Setting/CompanyPlace/Register/CompanyPlaceRegister.vue';
import _SettingCompanyPlaceEditPage from '@/_pages/Setting/CompanyPlace/Edit/CompanyPlaceEdit.vue';
import { Route } from 'vue-router/types/router';

export const settingRoutes: TbxRouteConfig[] = [
    {
        path: '/setting',
        name: 'Setting',
        component: _SettingPage,
        backComponent: SettingPage,
        children: [
            {
                path: 'account/list',
                name: 'SettingAccountList',
                component: _SettingAccountListPage,
                backComponent: SettingAccountListPage,
                meta: {
                    title: 'ユーザー管理',
                }
            },
            {
                path: 'account/create',
                name: 'SettingAccountCreate',
                component: SettingAccountCreateOrEditPage,
                meta: {
                    title: 'ユーザー追加',
                    actionType: 'CREATE'
                },
            },
            {
                path: 'account/create/complete',
                name: 'SettingAccountCreateComplete',
                component: SettingAccountCreateCompletePage,
                meta: {
                    title: 'ユーザー追加・確認メール送信',
                }
            },
            {
                path: 'account/edit',
                name: 'SettingAccountEdit',
                component: SettingAccountCreateOrEditPage,
                meta: {
                    title: 'ユーザー編集',
                    actionType: 'EDIT'
                },
            },
            {
                path: 'company/profile',
                name: 'SettingCompanyProfile',
                component: _SettingCompanyProfilePage,
                backComponent: SettingCompanyProfilePage,
                meta: {
                    title: '企業基本情報',
                }
            },
            {
                path: 'company/profile/extra',
                name: 'SettingCompanyProfileExtra',
                component: _SettingCompanyProfileExtraPage,
                backComponent: SettingCompanyProfileExtraPage,
                meta: {
                    title: '企業詳細情報',
                }
            },
            {
                path: 'company/confidence',
                name: 'SettingCompanyConfidence',
                component: _SettingCompanyConfidencePage,
                backComponent: SettingCompanyConfidencePage,
                meta: {
                    title: '企業信用情報',
                },
            },
            {
                path: 'company/contract',
                name: 'SettingCompanyContract',
                component: SettingCompanyContractPage,
                meta: {
                    title: 'ご契約内容',
                },
            },
            {
                path: 'company/transfer',
                name: 'SettingCompanyTransfer',
                component: _SettingCompanyTransferPage,
                backComponent: SettingCompanyTransferPage,
                meta: { title: '振込先口座情報' }
            },
            {
                path: 'company/payment',
                name: 'SettingCompanyPayment',
                component: _SettingCompanyPaymentPage,
                backComponent: SettingCompanyPaymentPage,
                meta: { title: 'お支払い設定' }
            },
            {
                path: 'billing',
                name: 'SettingBilling',
                component: _SettingBillingPage,
                backComponent: SettingBillingPage,
                meta: {
                    title: 'ご利用金額',
                }
            },
            {
                path: 'email/baggage-and-truck',
                name: 'SettingEmailBaggageAndTruck',
                component: SettingEmailBaggageAndTruckPage,
                meta: {
                    title: '荷物情報のメール受信設定',
                }
            },
            {
                path: 'premium/revoke',
                name: 'RevokePremiumPlan',
                component: _RevokePremiumPlanPage,
                backComponent: RevokePremiumPlanPage,
                meta: {
                    title: 'プレミアムプランの停止',
                }
            },
            {
                path: 'premium/revoke/edit',
                name: 'RevokePremiumPlanEdit',
                component: _RevokePremiumPlanFormPage,
                backComponent: RevokePremiumPlanFormPage,
                meta: {
                    title: 'プレミアムプランの停止'
                }
            },
            {
                path: 'premium/revoke/complete',
                name: 'RevokePremiumPlanComplete',
                component: _RevokePremiumPlanCompletePage,
                backComponent: RevokePremiumPlanCompletePage,
                meta: {
                    title: 'プレミアムプランの停止手続きが完了しました'
                }
            },
            {
                path: 'company-place',
                name: '_SettingCompanyPlaceList',
                component: _SettingCompanyPlaceListPage,
                backComponent: _SettingCompanyPlaceListPage,
                meta: {
                    title: '地点マスタ'
                }
            },
            {
                path: 'company-place/register',
                name: '_SettingCompanyPlaceRegister',
                component: _SettingCompanyPlaceRegisterPage,
                props: (route: Route) => ({
                    sourceCompanyPlaceId: route.query.sourceCompanyPlaceId,
                }),
                meta: {
                    title: '地点追加',
                }
            },
            {
                path: 'company-place/:companyPlaceId/edit',
                name: '_SettingCompanyPlaceEdit',
                component: _SettingCompanyPlaceEditPage,
                props: (route: Route) => ({
                    companyPlaceId: Number(route.params.companyPlaceId),
                }),
                meta: {
                    title: '地点編集',
                }
            },
        ],
    },
];
