import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';
import { helpApi } from '@/repository/api/internal/help';
import { GETTER as g, MUTATION as m, TraboxNoticeItem } from '@/vuex/modules/help/types';
import { useVuexSync } from '@/composables/helper/vuex';
import { TraboxNotice } from '@/models/help';
import { useGtm } from '@/composables/helper/gtm-helper';
import { useKarte } from '@/composables/helper/karte-helper';

/**
 * お知らせをロードする機能を提供します。
 */
export const useNotice = createGlobalState(() => {
    const notice = ref<TraboxNotice>({ items: [] });
    useVuexSync(notice, { module: 'help', mutation: m.SET_NOTICE, getter: g.NOTICE });
    const { gtm } = useGtm();
    const { Karte } = useKarte();
    const load = async () => {
        const data = await helpApi.notice();
        notice.value = data;
    };

    const markRead = (item: TraboxNoticeItem): void => {
        const id = item.id;
        gtm.setEvent('click_trabox_notice', { id });
        Karte.trackClickGlobalNotice(id, item.url);
    };

    return {
        state: {
            notice,
        },
        load,
        markRead,
    };
});
