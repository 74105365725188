import { computed, ref } from 'vue';
import { FormValidateUtil } from '@/models/validate-helper';
import { CompanyConfidence, CompanyConfidenceUpdateFormModel } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 企業信用情報をロードする機能を提供します。
 */
export const useCompanyConfidence = () => {
    const confidence = ref<CompanyConfidence | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (companyId: number) => {
        await withLoading(async () => {
            const data = await companyApi.confidence(companyId);
            confidence.value = data;
        });
    };

    const clear = () => {
        confidence.value = undefined;
    };

    return {
        state: {
            confidence,
            loading,
        },
        load,
        clear,
    };
};

/**
 * 企業信用情報を更新する機能を提供します。
 */
export const useCompanyConfidenceUpdate = () => {
    const form = ref(new CompanyConfidenceUpdateFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const { state: { loading }, withLoading } = useLoading();

    const initForm = (model: CompanyConfidence) => {
        form.value = new CompanyConfidenceUpdateFormModel(model);
    };

    const update = () => withLoading(async () => {
        await companyApi.updateConfidence(form.value);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        initForm,
        update,
    };
};
