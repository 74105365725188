import { computed, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { SettlementSearchIncomeFormModel, SettlementSearchOutgoFormModel } from '@/models/settlement-search';
import { SettlementListModel } from '@/models/settlement';
import { useLoading } from '@/composables/helper/loading-helper';
import { useRouting } from '@/composables/helper/routing';
import { settlementApi } from '@/repository/api/internal/settlement';
import { PageInfo } from '@/_components/ui/types/page-info';

const storage = useLocalStorage<'income' | 'outgo'>('settlementListTabKey', 'income');

export const useSettlementListTabKey = () => {
    const { goToSettlementIncomeList, goToSettlementOutgoList } = useRouting();

    const goToSettlementList = async () => {
        switch (storage.value) {
            case 'income':
                await goToSettlementIncomeList();
                break;
            case 'outgo':
                await goToSettlementOutgoList();
                break;
        }
    };

    return {
        tabKey: storage,
        goToSettlementList,
    };
};

export const useSettlementIncomeList = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new SettlementSearchIncomeFormModel());
    const list = ref<SettlementListModel>();

    // ページング情報
    const pageInfo = computed<PageInfo>(() => ({
        totalPageCount: list.value?.totalPageCount ?? 0,
        totalRecordCount: list.value?.totalRecordCount ?? 0,
        currentPage: list.value?.currentPageNumber ?? 1,
        currentPageSize: list.value?.pageSize ?? 50,
        pageSizeOptions: ['50'],
    }));

    const search = (): Promise<void> => withLoading(async () => {
        const result = await settlementApi.searchIncome(form.value.convertToApiForm());

        list.value = new SettlementListModel(result);
    });

    const toggleConfirmed = (id: number, value: boolean): void => {
        list.value?.find(id)?.toggleConfirmed(value);
    };

    const changePage = async (value: { pageNo: number }): Promise<void> => {
        form.value.pageNo = value.pageNo;

        await search();
    };

    return {
        state: {
            loading,
            form,
            pageInfo,
            list,
        },
        search,
        changePage,
        toggleConfirmed,
    };
};

export const useSettlementOutgoList = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new SettlementSearchOutgoFormModel());
    const list = ref<SettlementListModel>();

    // ページング情報
    const pageInfo = computed<PageInfo>(() => ({
        totalPageCount: list.value?.totalPageCount ?? 0,
        totalRecordCount: list.value?.totalRecordCount ?? 0,
        currentPage: list.value?.currentPageNumber ?? 1,
        currentPageSize: list.value?.pageSize ?? 50,
        pageSizeOptions: ['50'],
    }));

    const search = (): Promise<void> => withLoading(async () => {
        const result = await settlementApi.searchOutgo(form.value.convertToApiForm());

        list.value = new SettlementListModel(result);
    });

    const changePage = async (value: { pageNo: number }): Promise<void> => {
        form.value.pageNo = value.pageNo;

        await search();
    };

    return {
        state: {
            loading,
            form,
            pageInfo,
            list,
        },
        search,
        changePage,
    };
};
