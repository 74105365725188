import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';
import { Util } from '@/util';

@Component
export default class CompanyTruckCountEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileUpdateForm;

    /**
     * トラック台数
     */
    get truckCount(): string {
        return this.value?.truckCount ?? '';
    }

    set truckCount(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // トラック保有台数を書き換え
        if (newValue.length > 0) {
            const digits = Util.toNumber(newValue);
            cloned.truckCount = isNaN(digits) ? '' : `${digits}`;
        } else {
            cloned.truckCount = '';
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            whitespace: true,
            message: '保有台数を入力してください。',
        },
        {
            pattern: /^[0-9]+$/,
            message: '保有台数は数字で入力してください。',
        },
        {
            max: 5,
            message: '保有台数は最大5桁で入力してください。',
        },
    ];
}
