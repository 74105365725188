//
// Action
//
import { CompanyContractModel } from '@/vuex/modules/company/company-contract.model';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { CompanyApplicationListModel } from '@/vuex/modules/company/company-application-list.model';
import { BaggageList } from '@/vuex/modules/baggage/types';
import { TruckList } from '@/vuex/modules/truck/types';
import {
    CompanyBilling,
    CompanyBillingTerm,
    CompanyConfidence,
    CompanyDispatchedDriverList,
    CompanyDispatchedTruckList,
    CompanyGuaranteedAmount,
    CompanyInvoice,
    CompanyPayment,
    CompanyProfile,
    CompanyProfileList,
    CompanyProfileSearchForm,
    CompanyStaffNameSuggestionList,
    CompanyStaffNameSuggestionRegisterForm,
    CompanyStatistics,
    CompanyStatus,
    CompanyTransfer
} from '@/models/company';

export class ACTION {
    static readonly LOAD_MY_STATUS = 'LOAD_MY_STATUS';
    static readonly DELETE = 'DELETE';
    static readonly LOAD_MY_PROFILE = 'LOAD_MY_PROFILE';
    static readonly LOAD_PROFILE = 'LOAD_PROFILE';
    static readonly CLEAR_PROFILE = 'CLEAR_PROFILE';
    static readonly SEARCH_PROFILE = 'SEARCH_PROFILE';
    static readonly LIST_PROFILE = 'LIST_PROFILE';
    static readonly CLEAR_PROFILE_LIST = 'CLEAR_PROFILE_LIST';
    static readonly LIST_PARTNER_PROFILE = 'LIST_PARTNER_PROFILE';
    static readonly UPDATE_PROFILE = 'UPDATE_PROFILE';
    static readonly UPDATE_PROFILE_EXTRA = 'UPDATE_PROFILE_EXTRA';
    static readonly LOAD_MY_CONFIDENCE = 'LOAD_MY_CONFIDENCE';
    static readonly LOAD_CONFIDENCE = 'LOAD_CONFIDENCE';
    static readonly CLEAR_CONFIDENCE = 'CLEAR_CONFIDENCE';
    static readonly UPDATE_CONFIDENCE = 'UPDATE_CONFIDENCE';
    static readonly LOAD_MY_STATISTICS = 'LOAD_MY_STATISTICS';
    static readonly LOAD_STATISTICS = 'LOAD_STATISTICS';
    static readonly CLEAR_STATISTICS = 'CLEAR_STATISTICS';
    static readonly LOAD_PAYMENT = 'LOAD_PAYMENT';
    static readonly REGISTER_PREMIUM = 'REGISTER_PREMIUM';
    static readonly REVOKE_PREMIUM = 'REVOKE_PREMIUM';
    static readonly REGISTER_GUARANTEE = 'REGISTER_GUARANTEE';
    static readonly REVOKE_GUARANTEE = 'REVOKE_GUARANTEE';
    static readonly LOAD_CONTRACTS = 'LOAD_CONTRACTS';
    static readonly LOAD_PAST_CONTRACTS = 'LOAD_PAST_CONTRACTS';
    static readonly LOAD_EASY_PAYMENT_CONTRACTS = 'LOAD_EASY_PAYMENT_CONTRACTS';
    static readonly LOAD_BILLING_TERM = 'LOAD_BILLING_TERM';
    static readonly LIST_BILLING_HISTORY = 'LIST_BILLING_HISTORY';
    static readonly ADD_INVOICE = 'ADD_INVOICE';
    static readonly ISSUE_INVOICE_URL = 'ISSUE_INVOICE_URL';
    static readonly CLEAR_INVOICE = 'CLEAR_INVOICE';
    static readonly LOAD_GUARANTEED_AMOUNT = 'LOAD_GUARANTEED_AMOUNT';
    static readonly REGISTER_STAFF_NAME_SUGGESTION = 'REGISTER_STAFF_NAME_SUGGESTION';
    static readonly DELETE_STAFF_NAME_SUGGESTION = 'DELETE_STAFF_NAME_SUGGESTION';
    static readonly LOAD_STAFF_NAME_SUGGESTIONS = 'LOAD_STAFF_NAME_SUGGESTIONS';
    static readonly REGISTER_DISPATCH_HISTORY = 'REGISTER_DISPATCH_HISTORY';
    static readonly DELETE_DISPATCH_HISTORY = 'DELETE_DISPATCH_HISTORY';
    static readonly LOAD_DISPATCHED_TRUCK_HISTORIES = 'LOAD_DISPATCHED_TRUCK_HISTORIES';
    static readonly LOAD_DISPATCHED_DRIVER_HISTORIES = 'LOAD_DISPATCHED_DRIVER_HISTORIES';
    static readonly LOAD_BAGGAGE_LIST = 'LOAD_BAGGAGE_LIST';
    static readonly LOAD_TRUCK_LIST = 'LOAD_TRUCK_LIST';
    static readonly SET_PROFILE_SEARCH_FORM = 'SET_PROFILE_SEARCH_FORM';
    static readonly LOAD_TRANSFER = 'LOAD_TRANSFER';
    static readonly UPDATE_TRANSFER = 'UPDATE_TRANSFER';
    static readonly LOAD_APPLICATION_LIST = 'LOAD_APPLICATION_LIST';
}

//
// Signature for Action
//
export type loadMyStatus = () => Promise<CompanyStatus>;
export type loadMyProfile = () => Promise<CompanyProfile>;
export type loadProfile = () => Promise<CompanyProfile>;
export type clearProfile = () => Promise<void>;
export type registerPremium = () => Promise<void>;
export type registerGuarantee = () => Promise<void>;
export type listContracts = () => Promise<CompanyContractListModel>;
export type loadBillingTerm = () => Promise<CompanyBillingTerm>;
export type listBilling = () => Promise<CompanyBilling>;
export type loadGuaranteedAmount = () => Promise<CompanyGuaranteedAmount>;
export type registerStaffNameSuggestion = (form: CompanyStaffNameSuggestionRegisterForm) => Promise<void>;
export type deleteStaffNameSuggestion = (param: { staffName: string }) => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly STATUS = 'STATUS';
    static readonly MY_PROFILE = 'MY_PROFILE';
    static readonly PROFILE = 'PROFILE';
    static readonly PROFILE_LIST = 'PROFILE_LIST';
    static readonly PROFILE_SEARCH_LIST = 'PROFILE_SEARCH_LIST';
    static readonly PROFILE_SEARCH_FORM = 'PROFILE_SEARCH_FORM';
    static readonly CONFIDENCE = 'CONFIDENCE';
    static readonly STATISTICS = 'STATISTICS';
    static readonly CONTRACT_LIST = 'CONTRACT_LIST';
    static readonly PAST_CONTRACT_LIST = 'PAST_CONTRACT_LIST';
    static readonly EASY_PAYMENT_CONTRACT_LIST = 'EASY_PAYMENT_CONTRACT_LIST';
    static readonly PAYMENT = 'PAYMENT';
    static readonly BILLING_TERM = 'BILLING_TERM';
    static readonly BILLING_LIST = 'BILLING_LIST';
    static readonly INVOICE_LIST = 'INVOICE_LIST';
    static readonly GUARANTEED_AMOUNT = 'GUARANTEED_AMOUNT';
    static readonly STAFF_NAME_SUGGESTION_LIST = 'STAFF_NAME_SUGGESTION_LIST';
    static readonly DISPATCHED_TRUCK_LIST = 'DISPATCHED_TRUCK_LIST';
    static readonly DISPATCHED_DRIVER_LIST = 'DISPATCHED_DRIVER_LIST';
    static readonly BAGGAGE_LIST = 'BAGGAGE_LIST';
    static readonly TRUCK_LIST = 'TRUCK_LIST';
    static readonly TRANSFER = 'TRANSFER';
    static readonly APPLICATION_LIST = 'APPLICATION_LIST';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_STATUS = 'SET_STATUS';
    static readonly SET_MY_PROFILE = 'SET_MY_PROFILE';
    static readonly SET_PROFILE = 'SET_PROFILE';
    static readonly SET_PROFILE_LIST = 'SET_PROFILE_LIST';
    static readonly SET_PROFILE_SEARCH_LIST = 'SET_PROFILE_SEARCH_LIST';
    static readonly SET_PROFILE_SEARCH_FORM = 'SET_PROFILE_SEARCH_FORM';
    static readonly SET_CONFIDENCE = 'SET_CONFIDENCE';
    static readonly SET_STATISTICS = 'SET_STATISTICS';
    static readonly SET_CONTRACT_LIST_MODEL = 'SET_CONTRACT_LIST_MODEL';
    static readonly SET_PAST_CONTRACT_LIST_MODEL = 'SET_PAST_CONTRACT_LIST_MODEL';
    static readonly SET_EASY_PAYMENT_CONTRACT_LIST = 'SET_EASY_PAYMENT_CONTRACT_LIST';
    static readonly SET_PAYMENT = 'SET_PAYMENT';
    static readonly SET_BILLING_TERM = 'SET_BILLING_TERM';
    static readonly SET_BILLING_HISTORY_LIST = 'SET_BILLING_HISTORY_LIST';
    static readonly SET_INVOICE_LIST = 'SET_INVOICE_LIST';
    static readonly SET_GUARANTEED_AMOUNT = 'SET_GUARANTEED_AMOUNT';
    static readonly SET_STAFF_NAME_SUGGESTION_LIST = 'SET_STAFF_NAME_SUGGESTION_LIST';
    static readonly SET_DISPATCHED_TRUCK_LIST = 'SET_DISPATCHED_TRUCK_LIST';
    static readonly SET_DISPATCHED_DRIVER_LIST = 'SET_DISPATCHED_DRIVER_LIST';
    static readonly SET_BAGGAGE_LIST = 'SET_BAGGAGE_LIST';
    static readonly SET_TRUCK_LIST = 'SET_TRUCK_LIST';
    static readonly SET_TRANSFER = 'SET_TRANSFER';
    static readonly SET_APPLICATION_LIST = 'SET_APPLICATION_LIST';
}

//
// Store
//
export interface CompanyState {
    status?: CompanyStatus;
    myProfile?: CompanyProfile;
    profile?: CompanyProfile;
    profileList: CompanyProfile[];
    profileSearchList: CompanyProfileList;
    profileSearchForm: CompanyProfileSearchForm;
    confidence?: CompanyConfidence;
    statistics?: CompanyStatistics;
    pastContractList?: CompanyContractListModel;
    easyPaymentContractList: CompanyContractModel[];
    contractList?: CompanyContractListModel;
    payment?: CompanyPayment;
    billingTerm?: CompanyBillingTerm;
    billing?: CompanyBilling[];
    invoice?: CompanyInvoice[];
    guaranteedAmount?: CompanyGuaranteedAmount;
    staffNameSuggestionList?: CompanyStaffNameSuggestionList;
    dispatchedTruckList?: CompanyDispatchedTruckList;
    dispatchedDriverList?: CompanyDispatchedDriverList;
    baggageList: BaggageList;
    truckList: TruckList;
    transfer?: CompanyTransfer;
    applicationList?: CompanyApplicationListModel;
}

//
// Type for store
//
export * from '@/models/company';
