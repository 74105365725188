import { Component, Prop } from 'vue-property-decorator';
import * as types from '@/vuex/modules/account/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';

@Component({
    title: 'メールアドレス認証',
})
export default class AccountEmailVerifyPage extends PageVue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop({ type: String })
    declare readonly verificationCode?: string;

    // ======================================================
    // Data
    // ======================================================
    loading = true;
    succeed = false;

    async mounted(): Promise<void> {
        if (!this.verificationCode?.trim()) {
            this.loading = false;
            return;
        }

        const onSuccess = () => {
            this.succeed = true;
            // NOTE: 文章が読めるように若干の遅延を入れる
            setTimeout(async () => await this.$router.push({ name: 'Login' }), 1000);
        };
        const onError = () => (this.succeed = false);

        this.loading = true;

        await AccountEmailVerifyPage.verifyEmail({ verificationCode: this.verificationCode })
            .then(onSuccess)
            .catch(onError);

        this.loading = false;
    }

    /**
     * 本人認証します。
     */
    private static verifyEmail(form: types.AccountChangeEmailVerificationForm): Promise<void> {
        return store.dispatch(`account/${types.ACTION.VERIFY_EMAIL_CHANGE}`, form);
    }
}
