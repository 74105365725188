import _ from 'lodash';
import dayjs, { OpUnitType } from 'dayjs';
import { ValueObject } from '@/models/vo/value-object';
import '@/models/vo/dayjs-init';
import { DateTimeValue } from '@/models/vo/datetime';
import { DateUtil } from '@/util';

export type DateFormatter =
    'YYYY/MM/DD'
    | 'YYYY年MM月DD日'
    | 'YYYY-MM-DD'
    | 'YYYY/MM'
    | 'YYYY年MM月'
    | 'YYYY年M月D日(ddd)'
    | 'M月D日'
    | 'M/D(ddd)';
export type ManipulateType = 'day' | 'month' | 'year';

const START_OF_DAY = '00:00:00 +09:00';
const PARSE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

/**
 * 日付ValueObject
 */
export class DateValue extends ValueObject<dayjs.Dayjs> {
    constructor(value: string | Date | dayjs.Dayjs) {
        const data = (
            typeof value === 'string' ? dayjs(`${ value } ${ START_OF_DAY }`, PARSE_FORMAT)
                : value instanceof Date ? dayjs(value)
                    : value
        ).tz().startOf('day');
        super(data);
    }

    get unix(): number {
        return this.value.unix();
    }

    get year(): number {
        return this.value.year();
    }

    get monthValue(): number {
        return this.value.month() + 1;
    }

    get date(): number {
        return this.value.date();
    }

    get day(): number {
        return this.value.day();
    }

    subtract(value: number, unit?: ManipulateType): DateValue {
        const newDayjsValue = this.value.subtract(value, unit);
        return new DateValue(newDayjsValue);
    }

    add(value: number, unit?: ManipulateType): DateValue {
        return new DateValue(this.value.add(value, unit));
    }

    addBusinessDays(days: number, includesThis: boolean = false): DateValue {
        if (days < 0) {
            throw new Error('illegal arguments passed');
        }

        const increment = (value: DateValue, days: number): DateValue => {
            const isBusinessDay = DateUtil.isBusinessDay(value.value);
            if (isBusinessDay && days === 1) {
                return value;
            }
            return increment(value.add(1, 'day'), isBusinessDay ? days - 1 : days);
        };

        return increment(includesThis ? this : this.add(1, 'day'), days);
    }

    setDate(value: number): DateValue {
        return new DateValue(this.value.date(value));
    }

    enfOf(unit: 'month'): DateValue {
        return new DateValue(this.value.endOf(unit));
    }

    startOf(unit: OpUnitType): DateValue {
        return new DateValue(this.value.startOf(unit));
    }

    endOf(unit: OpUnitType): DateValue {
        return new DateValue(this.value.endOf(unit));
    }

    isSame(that: DateValue, unit?: ManipulateType): boolean {
        return this.value.isSame(that.value, unit);
    }

    isBefore(that: DateValue, unit?: ManipulateType): boolean {
        return this.value.isBefore(that.value, unit);
    }

    isSameOrBefore(that: DateValue, unit?: ManipulateType): boolean {
        return this.value.isSameOrBefore(that.value, unit);
    }

    isAfter(that: DateValue, unit?: ManipulateType): boolean {
        return this.value.isAfter(that.value, unit);
    }

    isSameOrAfter(that: DateValue, unit?: ManipulateType): boolean {
        return this.value.isSameOrAfter(that.value, unit);
    }

    isBetween(begin: DateValue, end: DateValue, unit?: ManipulateType, includingBorder?: '()' | '[]' | '[)' | '(]'): boolean {
        return this.value.isBetween(begin.value, end.value, unit, includingBorder);
    }

    format(formatter: DateFormatter = 'YYYY/MM/DD'): string {
        return this.value.format(formatter);
    }

    /**
     * 指定した日時からの残り月数を取得します。
     * 月だけをみて比較します。
     */
    monthsLeft(current: DateValue): number {
        return this.value.startOf('month').diff(current.value.startOf('month'), 'month', false);
    }

    toDateTime(): DateTimeValue {
        return new DateTimeValue(this.value);
    }

    static today(): DateValue {
        return new DateValue(dayjs().tz());
    }

    static max(...values: DateValue[]): DateValue {
        return _.maxBy(values, (each) => each.unix) ?? values[0];
    }

    static min(...values: DateValue[]): DateValue {
        return _.minBy(values, (each) => each.unix) ?? values[0];
    }
}
