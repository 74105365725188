import { Component, Prop } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import * as types from '@/vuex/modules/account/types';
import { PageVue } from '@/mixin/PageVue';
// @ts-ignore
import UiMissionLayout from '@/components/UI/Layouts/MissionLayout';
// @ts-ignore
import AccountTrial from '@/components/Account/Trial';
// @ts-ignore
import AccountRegisterForm from '@/components/Account/Register';
// @ts-ignore
import CompanyName from '@/components/Account/Register/CompanyName';
// @ts-ignore
import Location from '@/components/Account/Register/Location';
// @ts-ignore
import Phone from '@/components/Account/Register/Phone';
// @ts-ignore
import Name from '@/components/Account/Register/Name';
// @ts-ignore
import EmailAddress from '@/components/Account/Register/Email';
// @ts-ignore
import TruckCount from '@/components/Account/Register/TruckCount';
// @ts-ignore
import Url from '@/components/Account/Register/Url';
import { URLUtil } from '@/util';

const accountMod = namespace('account');

export type FormStyle = string | 'nejp_trial';

@Component({
    title: '14日間無料お試し申し込みフォーム',
    components: {
        UiMissionLayout,
        AccountRegisterForm,
        AccountTrial,
        CompanyName,
        Location,
        Phone,
        Name,
        EmailAddress,
        TruckCount,
        Url,
    },
})
export default class AccountRegisterPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Action(types.ACTION.REGISTER)
    register!: types.register;

    // ======================================================
    // Properties
    // ======================================================
    @Prop({ default: '' })
    private formStyle!: FormStyle;

    /**
     * サービスサイト（trabox.ne.jp）のフォームタイプにすべきか否かを取得します
     */
    get isServiceSiteFormStyle(): boolean {
        return this.formStyle === 'nejp_trial';
    }

    /**
     * iframeで埋め込まれた登録フォームか否かを取得します。
     * landingページについては /account/register?view=embed でアクセスされるようになっています
     */
    get isEmbedView(): boolean {
        const viewQuery = URLUtil.parseQueryValue(this.$route.query.view);
        return viewQuery === 'embed';
    }

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    dirty = false;

    mounted(): void {
        window.addEventListener('beforeunload', AccountRegisterPage.unloaded, false);
    }

    beforeDestroy(): void {
        AccountRegisterPage.removeUnloadedEventListener();
    }

    /**
     * フォームでバリデーション処理が実行されると呼び出されます。
     */
    onValidateForm(dirty: boolean): void {
        // NOTE: バリデーションが実行された＝値が変更されたことになるため、dirtyフラグを立てる
        this.dirty = dirty;
    }

    /**
     * ボタンが押下された際に呼び出される。
     */
    async onSubmit(formValue: types.AccountRegisterForm): Promise<void> {
        const notifyFailedKey = 'REGISTER_FAILED';
        const notifyRegisterFailed = () => {
            this.$notification.error({
                key: notifyFailedKey,
                message: 'アカウントの登録ができませんでした。',
                description:
                    'ご入力いただいたメールアドレスはすでに登録されています。過去に登録されたことのあるメールアドレスをご利用になりたい場合は、パスワードの再設定をお試しください。',
                btn: (h: Function) => {
                    return h(
                        'a-button',
                        {
                            props: {
                                type: 'secondary',
                                size: 'small',
                            },
                            on: {
                                click: async () => {
                                    this.$notification.close(notifyFailedKey);
                                    await this.$router.push({ name: 'AccountPasswordRemind' });
                                },
                            },
                        },
                        'パスワードの再設定を行う'
                    );
                },
            });
        };

        this.loading = true;
        this.$notification.close(notifyFailedKey);

        const success = await this.register(formValue)
            .then(() => true)
            .catch(() => false);

        if (success) {
            this.dirty = false;
            const query = this.isEmbedView ? { view: 'embed' } : {};
            await this.$router.push({ name: 'AccountComplete', query });
        } else {
            notifyRegisterFailed();
        }
        this.loading = false;
    }

    /**
     * ルーティングによってこのページから離れる際に呼び出されます。
     */
    async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<AccountRegisterPage>
    ): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると入力中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            AccountRegisterPage.removeUnloadedEventListener();
            next();
        }
    }

    /**
     * イベントリスナを削除します。
     */
    private static removeUnloadedEventListener(): void {
        window.removeEventListener('beforeunload', AccountRegisterPage.unloaded, false);
    }

    /**
     * ページ離脱時に確認メッセージを返却します。
     * @param e
     */
    private static unloaded = function (e: BeforeUnloadEvent): string {
        e.preventDefault();
        const confirmMessage = 'お申し込みが完了していません。このページから移動しますか？';
        e.returnValue = confirmMessage;
        return confirmMessage;
    };
}
