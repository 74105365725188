import { GuaranteePriceMaster } from '@/models/guarantee-master';
import { appAxios, isSuccess, JsonResponseBean } from './base';

export const masterApi = {
    /**
     * 運賃全額保証利用料金マスタを取得する。
     */
    guaranteePrice(): Promise<GuaranteePriceMaster[]> {
        return appAxios.get<JsonResponseBean<GuaranteePriceMaster[]>>('/api/master/guarantee').then(isSuccess);
    },
};
