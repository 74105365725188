import { useDeliveryOrderUpdate, useDeliveryOrderFind } from '@/composables/delivery-order';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables';
import { computed, onBeforeMount, ref } from 'vue';
import { DateUtil } from '@/util';
import {
    useBaggageLargeTruckFlgOptions,
    useBaggageLoadingOptions,
    useBaggageShapeOptions,
    useBaggageTemperatureZoneOptions,
    useBaggageUnloadingOptions,
    useTruckHeightForBaggageOptions,
    useTruckModelRegistrableOptions,
    useTruckWeightRegistrableOptions,
    useTruckWidthForBaggageOptions,
} from '@/composables/option-helper';
import { useFormModel } from '@/composables/form-helper';

export const useDeliveryOrderEditHelper = () => {
    const { state: { loading: loadingFind, deliveryOrder }, find: findDeliveryOrder } = useDeliveryOrderFind();
    const { state: { loading: loadingUpdate, validationRules, dirty, form }, update: updateDeliveryOrder, initForm } = useDeliveryOrderUpdate();
    const { options: shapeOptions } = useBaggageShapeOptions();
    const { options: loadingOptions } = useBaggageLoadingOptions();
    const { options: unloadingOptions } = useBaggageUnloadingOptions();
    const { options: temperatureZoneOptions } = useBaggageTemperatureZoneOptions();
    const { options: weightOptions } = useTruckWeightRegistrableOptions();
    const { options: modelOptions } = useTruckModelRegistrableOptions();
    const { options: heightOptions } = useTruckHeightForBaggageOptions();
    const { options: widthOptions } = useTruckWidthForBaggageOptions();
    const { options: largeTruckFlgOptions } = useBaggageLargeTruckFlgOptions();
    const { submit, formModel } = useFormModel();

    const route = useRoute();
    const router = useRouter();
    const deliveryOrderId = Number(route.params.deliveryOrderId);
    const cols = {
        first: {
            labelCol: { xs: 3, md: 9 },
            wrapperCol: { xs: 21, md: 15 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                xs: { offset: 0, span: 24 },
                sm: { offset: 3, span: 21 },
                md: { offset: 0, span: 24 },
            },
        },
    };
    const selectableDateRange = [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];

    const loading = computed(() => {
        return loadingFind.value || loadingUpdate.value;
    });
    const specifiedTruckChecked = ref(false);
    const specifiedTruckCheck = computed({
        get: () => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck,
        set: (value: boolean) => {
            if (!value) form.value.clearSpecifiedTruck();
            specifiedTruckChecked.value = value;
        },
    });
    const specifiedTruckEditable = computed(() => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck);

    /**
     * ヘッダの矢印ボタン
     */
    const onClickBack = () => {
        router.go(-1);
    };

    /**
     * 更新ボタン
     */
    const onSubmit = () => submit(async () => {
        // eslint-disable-next-line no-useless-catch
        try {
            await updateDeliveryOrder(deliveryOrderId);
            router.go(-1);
        } catch (e) {
            throw e;
        }
    });

    /**
     * キャンセルボタン
     */
    const onClickCancel = () => {
        router.go(-1);
    };

    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');

    const initialize = async () => {
        await findDeliveryOrder(deliveryOrderId);
        if (deliveryOrder.value) {
            initForm(deliveryOrder.value);
        }
    };

    onBeforeMount(() => initialize());

    onBeforeRouteLeave((to, from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        form,
        cols,
        selectableDateRange,
        shapeOptions,
        loadingOptions,
        unloadingOptions,
        temperatureZoneOptions,
        weightOptions,
        modelOptions,
        heightOptions,
        widthOptions,
        largeTruckFlgOptions,
        specifiedTruckCheck,
        specifiedTruckEditable,
        formModel,
        validationRules,
        onClickBack,
        onSubmit,
        onClickCancel,
    };
};
