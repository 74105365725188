<script setup lang="ts">

const props = withDefaults(defineProps<{
    currentStep?: number,
}>(), {
    currentStep: 1,
});
const inProgressStep = (step: number) => {
    return step === props.currentStep;
};
</script>

<template>
    <div class="flow">
        <p class="flow__title">ご利用開始までの流れ</p>
        <ol class="flow__steps">
            <li class="flow__step" :class="{ 'flow__step--in-progress': inProgressStep(1) } ">
                <a-icon class="flow__step__icon" v-if="inProgressStep(1)" type="form" />
                <a-icon class="flow__step__icon" v-else type="form" />
                <span class="flow__step__label">フォームの<br>入力完了</span>
                <a-icon class="flow__step__arrow" type="right" />
            </li>
            <li class="flow__step" :class="{ 'flow__step--in-progress': inProgressStep(2) } ">
                <a-icon class="flow__step__icon" v-if="inProgressStep(2)" theme="outlined" type="mail" />
                <a-icon class="flow__step__icon" v-else type="mail" />
                <span class="flow__step__label">メールの確認および<br/>パスワードの設定</span>
                <a-icon class="flow__step__arrow" type="right" />
            </li>
            <li class="flow__step" :class="{ 'flow__step--in-progress': inProgressStep(3) } ">
                <a-icon class="flow__step__icon" v-if="inProgressStep(3)" theme="filled" type="check-circle" />
                <a-icon class="flow__step__icon" v-else type="check-circle" />
                <span class="flow__step__label">本申し込みの完了</span>
                <a-icon class="flow__step__arrow" type="right" />
            </li>
            <li class="flow__step" :class="{ 'flow__step--in-progress': inProgressStep(4) } ">
                <a-icon class="flow__step__icon" v-if="inProgressStep(4)" type="audit" />
                <a-icon class="flow__step__icon" v-else type="audit" />
                <span class="flow__step__label">審査</span>
                <a-icon class="flow__step__arrow" type="right" />
            </li>
            <li class="flow__step" :class="{ 'flow__step--in-progress': inProgressStep(5) } ">
                <a-icon class="flow__step__icon" v-if="inProgressStep(5)" theme="filled" type="phone" />
                <a-icon class="flow__step__icon" v-else type="phone" />
                <span class="flow__step__label">審査完了後、<br/>担当より開始のご連絡</span>
            </li>
        </ol>
    </div>

</template>

<style scoped lang="less">
.flow {
    .flow__title {
        margin-bottom: 24px;
        font-size: 16px;
        text-align: center;
    }

    .flow__steps {
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .flow__step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-right: 16px;

        &:last-child {
            padding-right: 0;
        }

        &__icon {
            font-size: 48px;
        }

        &__label {
            margin-top: 16px;
            text-align: center;
        }

        &__arrow {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: flex-start;
            margin: auto;
            padding-top: 16px;
            font-size: 18px;
            color: @color-neutral-7;
        }

        &--in-progress {
            color: @color-trabox-green-10;
        }
    }
}
</style>
