import _ from 'lodash';

const baggageHandlingTypeCodes = ['BH1', 'BH2', 'BH3', 'BH4'] as const;

/**
 * ドライバー作業のEnum
 */
export type BaggageHandlingTypeEnumCode = (typeof baggageHandlingTypeCodes)[number];

export function isBaggageHandlingTypeCode(code: string): code is BaggageHandlingTypeEnumCode {
    return _.includes(baggageHandlingTypeCodes, code);
}

export class BaggageHandlingTypeEnum {
    constructor(public code: BaggageHandlingTypeEnumCode, public getLabel: (loading: boolean) => string) {}

    static Forklift = new BaggageHandlingTypeEnum('BH1', () => 'フォーク');
    static Manual = new BaggageHandlingTypeEnum('BH2', (loading) => loading ? '手積み' : '手卸し');
    static None = new BaggageHandlingTypeEnum('BH3', () => 'なし');
    static Crane = new BaggageHandlingTypeEnum('BH4', () => 'クレーン');

    static valueOf = (code?: string): BaggageHandlingTypeEnum | undefined =>
        BaggageHandlingTypeEnum.values.find((value) => value.code === code);

    static values = [BaggageHandlingTypeEnum.Forklift, BaggageHandlingTypeEnum.Crane, BaggageHandlingTypeEnum.Manual, BaggageHandlingTypeEnum.None];
}
