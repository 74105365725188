<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import DateTimeRangePicker from '@/_components/ui/DateTimeRangePicker.vue';
import LocationInput from '@/_pages/DeliveryOrder/Edit/_components/LocationInput.vue';
import BaggageTypeInput from '@/_components/ui/BaggageTypeInput.vue';
import FreightInput from '@/_pages/DeliveryOrder/Edit/_components/FreightInput.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { useDeliveryOrderEditHelper } from '@/_pages/DeliveryOrder/Edit/delivery-order-edit-helper';
import { useFormModelItem } from '@/composables/form-helper';

const {
    loading,
    form,
    formModel,
    validationRules,
    cols,
    selectableDateRange,
    shapeOptions,
    loadingOptions,
    unloadingOptions,
    temperatureZoneOptions,
    weightOptions,
    modelOptions,
    heightOptions,
    widthOptions,
    largeTruckFlgOptions,
    specifiedTruckCheck,
    specifiedTruckEditable,
    onClickBack,
    onSubmit,
    onClickCancel,
} = useDeliveryOrderEditHelper();

const { formModelItem: formItemDepartureDateTimeRange, onChange: onChangeDepartureDateTimeRange } = useFormModelItem();
const { formModelItem: formItemArrivalDateTimeRange, onChange: onChangeArrivalDateTimeRange } = useFormModelItem();
const onChangeDateTimeRange = () => {
    onChangeDepartureDateTimeRange();
    onChangeArrivalDateTimeRange();
};
const { formModelItem: formItemDepartureLocation, onChange: onChangeDepartureLocation } = useFormModelItem();
const { formModelItem: formItemArrivalLocation, onChange: onChangeArrivalLocation } = useFormModelItem();
const { formModelItem: formItemType, onChange: onChangeType } = useFormModelItem();
const { formModelItem: formItemPaletteSize, onChange: onChangePaletteSize } = useFormModelItem();
const { formModelItem: formItemTemperatureZone, onChange: onChangeTemperatureZone } = useFormModelItem();
const { formModelItem: formItemTruck, onChange: onChangeTruck } = useFormModelItem();

</script>

<template>
    <page-layout :back-icon="true" @back="onClickBack">
        <a-spin :spinning="loading">
            <a-form-model ref="formModel" :model="form" :rules="validationRules" :colon="false"
                          :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-card id="jsi-register-form" class="form-card">
                    <!-- 発着日時、発着地 -->
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="発"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               :auto-link="false"
                                               prop="departureDateTimeRange"
                                               ref="formItemDepartureDateTimeRange">
                                <date-time-range-picker v-model="form.departureDateTimeRange"
                                                  :valid-range="selectableDateRange"
                                                  @change="onChangeDateTimeRange"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="departureLocation"
                                               ref="formItemDepartureLocation">
                                <location-input @blur="form.normalize()"
                                                @change="onChangeDepartureLocation"
                                                v-model="form.departureLocation"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="24" class="form-item-loading-time">
                            <a-form-model-item label="積み時間"
                                               :wrapper-col="{ span: 15 }"
                                               prop="loadingTimeNote"
                                               ref="formItemLoadingTimeNote">
                                <a-input v-model="form.loadingTimeNote"
                                         placeholder="積み時間を入力してください"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 着日時/着地 -->
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="8">
                            <a-form-model-item label="着"
                                               :label-col="cols.first.labelCol"
                                               :wrapper-col="cols.first.wrapperCol"
                                               prop="arrivalDateTimeRange"
                                               ref="formItemArrivalDateTimeRange">
                                <date-time-range-picker v-model="form.arrivalDateTimeRange"
                                                  :valid-range="selectableDateRange"
                                                  @change="onChangeDateTimeRange"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :xs="24" :md="16">
                            <a-form-model-item label=""
                                               :label-col="cols.second.labelCol"
                                               :wrapper-col="cols.second.wrapperCol"
                                               prop="arrivalLocation"
                                               ref="formItemArrivalLocation">
                                <location-input @blur="form.normalize()"
                                                @change="onChangeArrivalLocation"
                                                v-model="form.arrivalLocation"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16, 0]">
                        <a-col :xs="24" :md="24" class="form-item-unloading-time">
                            <a-form-model-item label="卸し時間"
                                               :wrapper-col="{ span: 15 }"
                                               prop="unloadingTimeNote"
                                               ref="formItemUnloadingTimeNote">
                                <a-input v-model="form.unloadingTimeNote"
                                         placeholder="卸し時間を入力してください"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 荷姿 -->
                    <a-form-model-item label="荷姿"
                                       prop="shape">
                        <a-radio-group name="shape"
                                       :options="shapeOptions"
                                       v-model="form.shapeCode"/>
                    </a-form-model-item>
                    <!-- 荷姿詳細 -->
                    <a-row class="shape-detail-row" v-if="form.hasShape">
                        <a-col :span="20" :offset="3" class="shape-detail-col">
                            <a-form-model-item label="荷種"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="type"
                                               ref="formItemType">
                                <baggage-type-input v-model="form.baggageType"
                                                    @change="onChangeType"
                                                    @blur="form.normalize()"/>
                            </a-form-model-item>
                            <template v-if="form.isShapePalette">
                                <a-form-model-item label="パレット枚数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="paletteCount">
                                    <a-input v-model="form.paletteCountText"
                                             placeholder="パレット枚数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>

                                <a-form-model-item label="パレットサイズ"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 16 }"
                                                   prop="paletteSize"
                                                   ref="formItemPaletteSize">
                                    <a-input-group>
                                        <a-row :gutter="8">
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteHeightText"
                                                         placeholder="縦"
                                                         addonBefore="縦"
                                                         suffix="cm"
                                                         :max-length="5"
                                                         @change="onChangePaletteSize"/>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-input v-model="form.paletteWidthText"
                                                         placeholder="横"
                                                         addonBefore="横"
                                                         suffix="cm"
                                                         :max-length="5"
                                                         @change="onChangePaletteSize"/>
                                            </a-col>
                                        </a-row>
                                    </a-input-group>
                                </a-form-model-item>
                            </template>
                            <template v-if="form.isShapeOther">
                                <a-form-model-item label="荷物の個数（目安）"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalCount">
                                    <a-input v-model="form.totalCountText"
                                             placeholder="荷物の個数（目安）"
                                             :max-length="5"
                                    />
                                </a-form-model-item>
                                <a-form-model-item label="荷物の体積"
                                                   :label-col="{ span: 4 }"
                                                   :wrapper-col="{ span: 8 }"
                                                   prop="totalVolume">
                                    <a-input v-model="form.totalVolumeText"
                                             placeholder="荷物の体積"
                                             :max-length="5">
                                        <template #suffix>m<sup>3</sup></template>
                                    </a-input>
                                </a-form-model-item>
                            </template>
                            <a-form-model-item label="総重量"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="totalWeight">
                                <a-input v-model="form.totalWeightText"
                                         placeholder="総重量"
                                         suffix="kg"
                                         :max-length="5"
                                />
                            </a-form-model-item>
                            <a-form-model-item label="ドライバー作業"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 20 }"
                                               prop="handling">
                                <a-space>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">積み</span>
                                        <a-select v-model="form.loadingCode"
                                                  style="width: 115px;"
                                                  placeholder="積み"
                                                  :options="loadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                    <span class="ant-input-group ant-input-group-compact">
                                        <span class="handling-type-label" style="width: 55px;">卸し</span>
                                        <a-select v-model="form.unloadingCode"
                                                  style="width: 115px;"
                                                  placeholder="卸し"
                                                  :options="unloadingOptions"
                                                  :allowClear="true"/>
                                    </span>
                                </a-space>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-form-model-item label="温度帯"
                                       :wrapper-col="{ span: 16 }"
                                       prop="temperatureZone"
                                       ref="formItemTemperatureZone">
                        <a-select style="width: 50%"
                                  placeholder="温度帯を選択"
                                  :options="temperatureZoneOptions"
                                  v-model="form.temperatureZoneCode"
                                  @change="onChangeTemperatureZone"/>
                    </a-form-model-item>
                    <!-- 車両(重量、車種) -->
                    <a-form-model-item label="希望車両"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truck"
                                       ref="formItemTruck">
                        <a-input-group compact>
                            <a-select style="width: 50%"
                                      placeholder="車両重量を選択"
                                      :options="weightOptions"
                                      v-model="form.truckWeightCode"
                                      @change="onChangeTruck"/>
                            <a-select style="width: 50%"
                                      placeholder="車種を選択"
                                      :options="modelOptions"
                                      v-model="form.truckModelCode"
                                      @change="onChangeTruck"/>
                        </a-input-group>
                    </a-form-model-item>
                    <!-- 車両の指定 -->
                    <a-row class="specify-truck-row">
                        <a-col :span="20" :offset="3" class="specify-truck-col">
                            <a-checkbox v-model="specifiedTruckCheck">使用できる車両を指定する</a-checkbox>
                        </a-col>
                    </a-row>
                    <a-row class="specify-truck-detail-row" v-if="specifiedTruckEditable">
                        <a-col :span="20" :offset="3" class="shape-detail-col">
                            <!-- 床高 -->
                            <a-form-model-item label="床高"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="truckHeight">
                                <a-select style="width: 60%"
                                          placeholder="床高を選択"
                                          :options="heightOptions"
                                          v-model="form.truckHeightCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                            <!-- 車幅 -->
                            <a-form-model-item label="車幅"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="truckWidth">
                                <a-select style="width: 60%"
                                          placeholder="車幅を選択"
                                          :options="widthOptions"
                                          v-model="form.truckWidthCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                            <!-- 大型可否 -->
                            <a-form-model-item label="大型可否"
                                               :label-col="{ span: 4 }"
                                               :wrapper-col="{ span: 8 }"
                                               prop="largeTruckFlg">
                                <a-select style="width: 60%"
                                          placeholder="大型可否を選択"
                                          :options="largeTruckFlgOptions"
                                          v-model="form.largeTruckFlgCode"
                                          :allowClear="true"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <!-- 必要装備 -->
                    <a-form-model-item label="必要装備"
                                       :wrapper-col="{ span: 18 }"
                                       prop="truckEquipment">
                        <a-textarea placeholder="りん木、コンパネ、発泡、ラップ、ラッシング等"
                                    v-model="form.truckEquipmentText"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>
                    <!-- 運賃 -->
                    <a-form-model-item label="運賃"
                                       prop="baggageFreight">
                        <freight-input v-model="form.baggageFreight"/>
                    </a-form-model-item>
                    <!-- 注意事項 -->
                    <a-form-model-item label="注意事項"
                                       :wrapper-col="{ span: 18 }"
                                       prop="description"
                                       ref="formItem">
                        <a-textarea v-model="form.descriptionText"
                                    :auto-size="{ minRows: 2, maxRows: 5 }"
                                    @blur="form.normalize()"/>
                    </a-form-model-item>
                    <!-- 作成日を追加 -->
                    <a-form-model-item label="作成日"
                                       :wrapper-col="{ span: 16 }"
                                       prop="creteDate">
                        <date-picker placeholder="日付を選択"
                                     :input-read-only="true"
                                     format="YYYY年M月D日(ddd)"
                                     :allowClear="false"
                                     :show-today="false"
                                     v-model="form.createDateValue">
                            <template #suffixIcon>
                                <tbx-icon type="calendar"/>
                            </template>
                        </date-picker>
                    </a-form-model-item>
                    <!-- 担当者 -->
                    <a-form-model-item label="担当者"
                                       :wrapper-col="{ span: 16 }"
                                       prop="staffName">
                        <a-input v-model="form.staffName"
                                 @blur="form.normalize()"
                                 style="width: 30%"/>
                    </a-form-model-item>
                    <!-- 注意文言 -->
                    <a-row :gutter="[24, 64]">
                        <a-col :offset="3" :span="18">
                            <a-alert
                                message="以降は協力会社から受領した情報を記入してください。"
                                type="info"
                                show-icon
                            />
                        </a-col>
                    </a-row>
                    <!-- 協力会社車両・ドライバー情報 -->
                    <a-form-model-item label="会社名(実運送会社)"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truckCompanyName">
                        <a-input v-model="form.truckCompanyName"
                                 style="width: 50%"/>
                    </a-form-model-item>
                    <a-form-model-item label="車番"
                                       :wrapper-col="{ span: 16 }"
                                       prop="truckNumber">
                        <a-input v-model="form.truckNumber"
                                 style="width: 50%"/>
                    </a-form-model-item>
                    <a-form-model-item label="ドライバー名"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverName">
                        <a-input v-model="form.driverName"
                                 style="width: 30%"/>
                    </a-form-model-item>
                    <a-form-model-item label="ドライバー電話番号"
                                       :wrapper-col="{ span: 16 }"
                                       prop="driverPhoneNumber">
                        <a-input v-model="form.driverPhoneNumber"
                                 style="width: 30%"/>
                    </a-form-model-item>

                    <a-row :gutter="[32, 48]">
                        <a-col :offset="10" :span="14"
                               style="display: flex; align-items: flex-start; text-align: center;">
                            <a-button style="max-width: 160px; margin-right: 22px;"
                                      block
                                      type="primary"
                                      size="large"
                                      @click="onSubmit">更新
                            </a-button>
                            <a-button style="max-width: 120px"
                                      block
                                      size="large"
                                      @click="onClickCancel">キャンセル
                            </a-button>
                        </a-col>
                    </a-row>
                </a-card>
            </a-form-model>
        </a-spin>
    </page-layout>
</template>

<style scoped lang="less">
// typescript/pages/Baggage/Register/style.less
.ant-form {
    padding: 24px;
}

.form-card {
    padding: 16px 24px;
}

.shape-detail-row {
    margin-top: -16px;
    margin-bottom: 16px;
}

.shape-detail-col {
    background-color: @color-neutral-3;
    padding-top: 20px;
}

.specify-truck-row {
    margin-top: -16px;
    margin-bottom: 32px;
}

.specify-truck-detail-row {
    margin-top: -24px;
    margin-bottom: 32px;
}

// typescript/components/Baggage/Register/Handling/style.less
.handling-type-label {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    padding: 4px 11px 0 11px;
    height: 32px;
}
</style>
