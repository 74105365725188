<script setup lang="ts">
import { CompanyProfile } from '@/models/company';
import { PhoneUtil } from '@/util';
import { computed } from 'vue';

const props = defineProps<{
    company: CompanyProfile;
    staffName: string;
}>();

const companyPhone = computed(() => PhoneUtil.format(props.company.phone.number));

</script>

<template>
    <address class="company-contact">
        <div class="company-contact__company-name">
            {{ company.name.kanji }}
        </div>
        <div class="company-contact__company-phone">
            {{ companyPhone }}
        </div>
        <div class="company-contact__staff-name">
            担当：{{ staffName }}
        </div>
    </address>
</template>

<style scoped lang="less">

.company-contact {
    color: @black;
    margin: 0;

    &__company-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
    }

    &__company-phone {
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
    }

    &__staff-name {
        font-size: 14px;
        line-height: 22px;
    }
}

</style>
