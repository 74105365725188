import { useVuexSync } from '@/composables/helper/vuex';
import { CompanyMyProfile } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { GETTER as g, MUTATION as m } from '@/vuex/modules/company/types';
import { createGlobalState } from '@vueuse/core';
import { computed, readonly, ref } from 'vue';

/**
 * 自社の企業プロフィールをロードする機能を提供します。
 */
export const useCompanyMyProfile = createGlobalState(() => {
    // TODO: モデルとして再作成する
    const myProfile = ref<CompanyMyProfile>();

    useVuexSync(myProfile, { module: 'company', getter: g.MY_PROFILE, mutation: m.SET_MY_PROFILE });
    /* 自社の企業ID。存在しない場合は0として扱う。 */
    const myCompanyId = computed<number>(() => myProfile.value?.id ?? 0);

    const load = async () => {
        const data = await companyApi.myProfile();
        myProfile.value = data;
    };

    return {
        state: {
            myProfile: readonly(myProfile),
            myCompanyId,
        },
        load,
    };
});
