import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { PhoneUtil } from '@/util';

@Component
export default class CompanyPhoneNumber extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get phone(): string {
        return PhoneUtil.format(this.profile?.phone.number ?? '');
    }
}
