import { Component, Prop, Vue } from 'vue-property-decorator';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
import { CustomRow } from '@/types/ant-design';
import { Column } from 'ant-design-vue/types/table/column';
import _ from 'lodash';
import { BaggageDetailUtil, BaggageUtil, Util } from '@/util';
import { Const } from '@/const';
// @ts-ignore
import UiTableRowBadge from '@/components/UI/TableRowBadge';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
// @ts-ignore
import UiDateTimeLabelText from '@/components/UI/DateTimeLabelText';
// @ts-ignore
import FavoriteButton from '@/components/Baggage/Search/TableView/FavoriteButton';
import TbxIcon from '@/_components/ui/TbxIcon.vue';

type AntColumn = Omit<Column, keyof Vue>;
type CustomOption = { single: number; multiple: number; border?: boolean; title: string; };
type FlexibleColumn = AntColumn & CustomOption;

/**
 * 列定義
 */
const COLUMN_DEFINITION: Array<FlexibleColumn> = [
    { single: 70, multiple: 70, key: 'action', title: '', border: false },
    { single: 150, multiple: 150, key: 'company', title: '企業名', ellipsis: true },
    { single: 160, multiple: 0, key: 'departureDate', title: '発日時', border: false },
    { single: 160, multiple: 0, key: 'arrivalDate', title: '着日時', align: 'left' },
    { single: 131, multiple: 0, key: 'departureLocation', title: '発地', align: 'left', border: false },
    { single: 18, multiple: 0, key: 'arrow', title: '', border: false },
    { single: 131, multiple: 0, key: 'arrivalLocation', title: '着地', align: 'left' },
    { single: 0, multiple: 290, key: 'departureArrival', title: '発日時・発地 / 着日時・着地', align: 'center' },
    { single: 100, multiple: 100, key: 'freight', title: '運賃', align: 'center' },
    { single: 50, multiple: 50, key: 'share', title: '積合', align: 'center' },
    { single: 70, multiple: 70, key: 'truckWeight', title: '重量', align: 'center' },
    { single: 80, multiple: 80, key: 'truckModel', title: '車種' },
    { single: 120, multiple: 120, key: 'type', title: '荷種' },
    { single: 70, multiple: 70, key: 'handling', title: 'ドライバー作業', align: 'center' },
    { single: 130, multiple: 130, key: 'description', title: '備考' },
];

/**
 * おすすめ帰り便がある列定義
 */
const RECOMMEND_COLUMN_DEFINITION: Array<FlexibleColumn> = [
    { single: 70, multiple: 70, key: 'action', title: '', border: false },
    { single: 150, multiple: 150, key: 'company', title: '企業名', ellipsis: true },
    { single: 160, multiple: 0, key: 'departureDate', title: '発日時', border: false },
    { single: 160, multiple: 0, key: 'arrivalDate', title: '着日時', align: 'left' },
    { single: 131, multiple: 0, key: 'departureLocation', title: '発地', align: 'left', border: false },
    { single: 18, multiple: 0, key: 'arrow', title: '', border: false },
    { single: 131, multiple: 0, key: 'arrivalLocation', title: '着地', align: 'left' },
    { single: 0, multiple: 290, key: 'departureArrival', title: '発日時・発地 / 着日時・着地', align: 'center' },
    { single: 100, multiple: 100, key: 'freight', title: '運賃', align: 'center' },
    { single: 50, multiple: 50, key: 'share', title: '積合', align: 'center' },
    { single: 70, multiple: 70, key: 'truckWeight', title: '重量', align: 'center' },
    { single: 80, multiple: 80, key: 'truckModel', title: '車種' },
    { single: 120, multiple: 120, key: 'type', title: '荷種' },
    { single: 70, multiple: 70, key: 'handling', title: 'ドライバー作業', align: 'center' },
    { single: 130, multiple: 130, key: 'description', title: '備考' },
    { single: 70, multiple: 70, key: 'recommendations', title: 'おすすめ帰り便' },
];

@Component({
    components: {
        UiTableRowBadge,
        UiDateTimeLocationLabel,
        UiDateTimeLabelText,
        FavoriteButton,
        TbxIcon,
    }
})
export default class BaggageTableView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    /**
     * 荷物リスト
     */
    @Prop()
    declare readonly baggageList?: baggageTypes.Baggage[];
    /**
     * 企業プロフィールリスト
     */
    @Prop()
    declare readonly companyProfileList?: companyTypes.CompanyProfile[];
    /**
     * 既読荷物IDリスト
     */
    @Prop({ default: () => ([]) })
    declare readonly readBaggageIdList: number[];
    /**
     * お気に入り登録済み荷物IDリスト
     */
    @Prop()
    declare readonly favoriteBaggageIdList?: number[];
    /**
     * 新着荷物IDリスト
     */
    @Prop()
    declare readonly newBaggageIdList?: number[];
    /**
     * 参考運賃リスト
     */
    @Prop()
    declare readonly referenceFreights?: baggageTypes.BaggageFreightMaster[];
    /**
     * お気に入り登録の解除キャンセル可能な荷物IDリスト
     */
    @Prop()
    declare readonly cancelableUnmarkFavoriteBaggageIdList?: number[];
    /**
     * 読み込み中か否か
     */
    @Prop()
    declare readonly loading?: boolean;
    /**
     * 自社企業ID
     */
    @Prop()
    declare readonly myCompanyId?: number;

    /**
     * おすすめ帰り便
     */
    @Prop()
    declare readonly baggageRecommendReturnsCounts?: baggageTypes.BaggageRecommendReturnsCount[];
    /**
     * おすすめ帰り便があるテーブル
     */
    @Prop({ default: false })
    declare readonly hasRecommend: boolean;

    get showAsSingle(): boolean {
        return this.tableWidth > Const.FLEXIBLE_TABLE_MODE_THRESHOLD;
    }

    get list(): Array<baggageTypes.Baggage & { company?: companyTypes.CompanyProfile }> | undefined {
        /* 企業を探す関数 */
        const findCompany = (id: number) => this.companyProfileList?.find((each) => each.id === id);
        /* 参考運賃を探す関数 */
        const findReferenceFreight = (baggage: baggageTypes.Baggage) =>
            this.referenceFreights?.find((each) => each.matchFor(baggage));
        /* おすすめ帰り便の数を探す関数 */
        const findRecommendReturnsCount = (id: number) => this.baggageRecommendReturnsCounts?.find((each) => each.id === id);

        /* 企業プロフィールをマージする関数 */
        const mergeCompany = (baggage: baggageTypes.Baggage) => {
            return _.merge({}, baggage, { company: findCompany(baggage.companyId) });
        };
        /* 参考運賃をマージする関数 */
        const mergeFreight = (baggage: baggageTypes.Baggage) => {
            return _.merge({}, baggage, { referenceFreight: findReferenceFreight(baggage) });
        };
        /* おすすめ帰り便の数をマージする関数 */
        const mergeRecommendReturnsCount = (baggage: baggageTypes.Baggage) => {
            return _.merge({}, baggage, { recommendationCount: findRecommendReturnsCount(baggage.id) });
        };

        return this.baggageList
            ?.map(mergeCompany)
            .map(mergeFreight)
            .map(mergeRecommendReturnsCount);
    }

    // ======================================================
    // Data
    // ======================================================
    initialized = false;
    tableWidth = 0;
    columns: Array<FlexibleColumn> = [];

    private invalidateTableLazy = _.debounce((that: BaggageTableView) => that.updateColumn(), 200);

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        this.tableWidth = this.$el.clientWidth;

        this.updateColumn();

        window.addEventListener('resize', this.onResizeWindow);

        this.initialized = true;
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.onResizeWindow);
    }

    /**
     * 行をカスタマイズする。
     */
    customRow(record: baggageTypes.Baggage): CustomRow {
        return {
            on: {
                click: (event: Event) => {
                    // `onClickGround` で実行されるDrawerクローズ処理と二重実行されないようにイベント伝搬をストップ
                    event.stopPropagation();

                    return this.$emit('select', record.id);
                },
            },
        };
    }

    rowClassName = (record: baggageTypes.Baggage): string => {
        const styleClasses = ['app-table-row', 'app-table-row--clickable', 'app-table-row--has-action-column'];
        const { baggageId } = this.$route.query;
        if (`${record.id}` === baggageId) {
            styleClasses.push('app-table-row--selected');
        }
        // 公開中荷物
        if (BaggageUtil.isOpen(record)) {
            styleClasses.push('baggage-row--open');
        }
        // 既読荷物
        if (this.readBaggageIdList.includes(record.id)) {
            styleClasses.push('baggage-row--marked-as-read');
        }
        return styleClasses.join(' ');
    };

    /**
     * 「商談中」バッジを表示すべきか否かを取得します。
     */
    shouldShowUnderNegotiationBadge(record: baggageTypes.Baggage): boolean {
        return record.underNegotiation;
    }

    /**
     * 「新」バッジを表示すべきか否かを取得します。
     */
    shouldShowNewBadge(record: baggageTypes.Baggage): boolean {
        if (this.shouldShowUnderNegotiationBadge(record)) return false;
        if (!this.newBaggageIdList) return false;
        return _.includes(this.newBaggageIdList, record.id);
    }

    /**
     * 荷物の保存状態を取得します。
     */
    favoriteState(record: baggageTypes.Baggage): baggageTypes.BaggageFavoriteState | undefined {
        if (this.isReadyToAgree(record) && !this.isMarkedAsFavorite(record)) {
            // 未保存
            return 'Unmarked';
        } else if (this.isMarkedAsFavorite(record) && !this.isAbleToCancelUnmarkFavorite(record)) {
            // 保存済
            return 'Marked';
        } else if (this.isMarkedAsFavorite(record) && this.isAbleToCancelUnmarkFavorite(record)) {
            // 保存を解除中
            return 'Unmarking';
        } else {
            // 保存不可の荷物
            return undefined;
        }
    }

    /**
     * 会社名を取得します。
     */
    companyName(record: { company?: companyTypes.CompanyProfile }): string {
        return record.company?.name.kanji ?? '';
    }

    /**
     * 運賃が未定（要相談）であるか否かを取得します。
     */
    isFreightUndecided(record: baggageTypes.Baggage): boolean {
        return !record.freight;
    }

    /**
     * 運賃
     */
    freightText(record: baggageTypes.Baggage): string {
        if (!record.freight) return '要相談';
        return `${Util.formatNumber(record.freight)}円`;
    }

    /**
     * 参考運賃
     */
    referenceFreightText(record: baggageTypes.Baggage & { referenceFreight: baggageTypes.BaggageFreightMaster | undefined }): string {
        if (record.freight) return '';
        const referenceFreight = record.referenceFreight?.freight;
        return referenceFreight ? `${ Util.formatNumber(referenceFreight) }円` : '-';
    }

    /**
     * おすすめ帰り便
     */
    recommendationCount(record: { recommendationCount?: baggageTypes.BaggageRecommendReturnsCount }): string {
        return record.recommendationCount?.count.toString() ?? '';
    }

    /**
     * ドライバー作業
     */
    handlingOverview(record: baggageTypes.Baggage): string {
        return BaggageDetailUtil.handlingOverview(record.loading?.code, record.unloading?.code);
    }

    /**
     * ドライバー作業（詳細）
     */
    handlingDetail(record: baggageTypes.Baggage): string[] | undefined {
        return BaggageDetailUtil.handlingDetail(record.loading?.code, record.unloading?.code);
    }

    //
    // Event handlers
    //

    /**
     * お気に入り「保存」ボタンが押下された際に呼び出されます。
     */
    markFavorite(baggageId: number): void {
        this.$emit('markFavorite', baggageId);
    }

    /**
     * お気に入り「保存済」ボタンが押下された際に呼び出されます。
     */
    unmarkFavorite(baggageId: number): void {
        this.$emit('unmarkFavorite', baggageId);
    }

    /**
     * お気に入り「解除キャンセル」ボタンが押下された際に呼び出されます。
     */
    cancelUnmarkFavorite(baggageId: number): void {
        this.$emit('cancelUnmarkFavorite', baggageId);
    }

    /**
     * Windowsサイズが変化した際に呼び出されます。
     */
    onResizeWindow(): void {
        this.tableWidth = this.$el.clientWidth;

        this.invalidateTableLazy(this);
    }

    /**
     * おすすめ帰り便のリンクがクリックされた際に呼び出されます。
     */
    onClickRecommendations(event: Event, baggageId: number): void {
        event.stopPropagation();
        const route = this.$router.resolve({ name: 'BaggageRecommendReturns', params: { baggageId: baggageId.toString() } });
        window.open(route.href, '_blank');
    }


    //
    // Helper methods
    //

    /**
     * 成約可能か否かを取得します。
     */
    private isReadyToAgree(record: baggageTypes.Baggage): boolean {
        if (!this.myCompanyId) return false;
        return BaggageUtil.isReadyToAgree(record, this.myCompanyId);
    }

    /**
     * お気に入り登録済みか否かを取得します。
     */
    private isMarkedAsFavorite(record: baggageTypes.Baggage): boolean {
        if (!this.favoriteBaggageIdList) return false;
        return _.includes(this.favoriteBaggageIdList, record.id);
    }

    private isAbleToCancelUnmarkFavorite(record: baggageTypes.Baggage): boolean {
        return this.cancelableUnmarkFavoriteBaggageIdList?.includes(record.id) ?? false;
    }

    /**
     * single/multipleに応じた列定義を取得します。
     */
    private columnDefinition(single: boolean) {
        const definition = this.hasRecommend ? RECOMMEND_COLUMN_DEFINITION : COLUMN_DEFINITION;
        return definition.filter(each => single ? each.single > 0 : each.multiple > 0);
    }

    /**
     * 列幅を比率で取得します。
     */
    private widthRatio (definition: FlexibleColumn, single: boolean) {
        const columnDefinition = this.hasRecommend ? RECOMMEND_COLUMN_DEFINITION : COLUMN_DEFINITION;
        if (single) {
            const sum = _.sumBy(columnDefinition, each => each.single);
            return definition.single / sum * 100;
        } else {
            const sum = _.sumBy(columnDefinition, each => each.multiple);
            return definition.multiple / sum * 100;
        }
    }

    /**
     * テーブルカラムを更新します。
     */
    private updateColumn(): void {
        this.columns = this.columnDefinition(this.showAsSingle).map(column => ({
            ...column,
            scopedSlots: { customRender: column.key },
            dataIndex: column.key,
            width: this.widthRatio(column, this.showAsSingle),
            className: column.border === false ? 'no-border-right' : '',
        }));
    }
}
