import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/company/types';
import { Validator } from '@/validator';

@Component
export default class CompanyUrl extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly profile?: types.CompanyProfile;

    get url(): string {
        return this.profile?.url ?? '';
    }

    get valid(): boolean {
        return Validator.validateUrl(this.profile?.url ?? '').result;
    }
}
