import { CompanyApplication } from '@/models/company';
import { CompanyApplicationModel } from '@/models/company-application';

export class CompanyApplicationListModel {
    applications: CompanyApplicationModel[];

    constructor(data: CompanyApplication[]) {
        this.applications = data.map((each) => new CompanyApplicationModel(each));
    }
}
