import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { accountApi, companyApi } from '@/repository/api/api';
import {
    ACTION as a,
    CompanyApplication,
    CompanyBilling,
    CompanyBillingTerm,
    CompanyTransfer,
    CompanyTransferUpdateForm,
    CompanyConfidence,
    CompanyConfidenceUpdateForm,
    CompanyContract,
    CompanyDeleteForm,
    CompanyGuaranteedAmount,
    CompanyPartnerProfileListForm,
    CompanyPayment,
    CompanyProfile,
    CompanyProfileExtraUpdateForm,
    CompanyProfileList,
    CompanyProfileListForm,
    CompanyProfileSearchForm,
    CompanyProfileUpdateForm,
    CompanyStaffNameSuggestionList,
    CompanyStaffNameSuggestionRegisterForm,
    CompanyStaffNameSuggestionDeleteForm,
    CompanyDispatchHistoryRegisterForm,
    CompanyDispatchedTruckList,
    CompanyDispatchedDriverList,
    CompanyState,
    CompanyStatistics,
    CompanyStatus,
    CompanyInvoice,
    GETTER as g,
    MUTATION as m,
} from './types';
import { CompanyContractModel } from '@/vuex/modules/company/company-contract.model';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { CompanyApplicationListModel } from '@/vuex/modules/company/company-application-list.model';
import { BaggageList } from '@/vuex/modules/baggage/types';
import { TruckList } from '@/vuex/modules/truck/types';
import { Const } from '@/const';
import _ from 'lodash';

const defaultCompanyProfileSearchList = {
    totalPageCount: 0,
    totalRecordCount: 0,
    currentPageNumber: 1,
    data: [],
};

const defaultCompanyProfileSearchForm = {
    keyword: '',
    pref: undefined,
    city: '',
    pageNo: 1,
    pageSize: 50,
};
const state: CompanyState = {
    status: undefined,
    myProfile: undefined,
    profile: undefined,
    profileList: [],
    profileSearchList: _.cloneDeep(defaultCompanyProfileSearchList),
    profileSearchForm: _.cloneDeep(defaultCompanyProfileSearchForm),
    confidence: undefined,
    statistics: undefined,
    contractList: undefined,
    pastContractList: undefined,
    easyPaymentContractList: [],
    payment: undefined,
    billingTerm: undefined,
    billing: undefined,
    invoice: [],
    guaranteedAmount: undefined,
    // guaranteedList: [],
    staffNameSuggestionList: undefined,
    dispatchedTruckList: undefined,
    dispatchedDriverList: undefined,
    baggageList: {
        currentPageNumber: 1,
        pageSize: Const.DEFAULT_PAGE_SIZE,
        totalPageCount: 0,
        totalRecordCount: 0,
        data: [],
    },
    truckList: {
        currentPageNumber: 1,
        pageSize: Const.DEFAULT_PAGE_SIZE,
        totalPageCount: 0,
        totalRecordCount: 0,
        data: [],
    },
    transfer: undefined,
    applicationList: undefined,
};

const actions: ActionTree<CompanyState, void> = {
    /**
     * 自社の企業ステータスをロードします。
     */
    [a.LOAD_MY_STATUS]: ({ commit }) => companyApi.myStatus().then((status) => commit(m.SET_STATUS, status)),
    /**
     * 退会します。
     */
    [a.DELETE]: (_, param: CompanyDeleteForm) => companyApi.delete(param).then(() => accountApi.logout()),
    /**
     * 自分の企業プロフィールをロードします。
     */
    [a.LOAD_MY_PROFILE]: ({ commit }) => companyApi.myProfile().then((profile) => commit(m.SET_MY_PROFILE, profile)),
    /**
     * 指定した企業IDの企業プロフィールをロードします。
     */
    [a.LOAD_PROFILE]: ({ commit }, id: number) =>
        companyApi.profile(id).then((profile) => commit(m.SET_PROFILE, profile)),
    /**
     * 企業プロフィールをクリアします。
     */
    [a.CLEAR_PROFILE]: ({ commit }) => commit(m.SET_PROFILE, undefined),
    /**
     * 企業プロフィールを検索します。
     */
    [a.SEARCH_PROFILE]: ({ commit }, param: CompanyProfileSearchForm) =>
        companyApi.searchProfile(param).then((list) => commit(m.SET_PROFILE_SEARCH_LIST, list)),
    /**
     * 企業プロフィールを一覧取得します。
     */
    [a.LIST_PROFILE]: ({ commit }, param: CompanyProfileListForm) =>
        companyApi.listProfile(param).then((list) => commit(m.SET_PROFILE_LIST, list)),
    /**
     * 企業プロフィールを一覧クリアします。
     */
    [a.CLEAR_PROFILE_LIST]: ({ commit }) => commit(m.SET_PROFILE_LIST, []),
    /**
     * パートナー企業プロフィールを一覧取得します。
     */
    [a.LIST_PARTNER_PROFILE]: ({ commit }, param: CompanyPartnerProfileListForm) =>
        companyApi.listPartnerProfile(param).then((list) => commit(m.SET_PROFILE_LIST, list)),
    /**
     * 企業プロフィールを更新します。
     */
    [a.UPDATE_PROFILE]: ({ commit }, param: CompanyProfileUpdateForm) =>
        companyApi
            .updateProfile(param)
            .then(() => companyApi.myProfile())
            .then((profile) => commit(m.SET_MY_PROFILE, profile)),
    /**
     * 拡張企業プロフィールを更新します。
     */
    [a.UPDATE_PROFILE_EXTRA]: ({ commit }, param: CompanyProfileExtraUpdateForm) =>
        companyApi
            .updateProfileExtra(param)
            .then(() => companyApi.myProfile())
            .then((profile) => commit(m.SET_MY_PROFILE, profile)),
    /**
     * 自社の信用情報をロードします。
     */
    [a.LOAD_MY_CONFIDENCE]: ({ commit }) =>
        companyApi.myConfidence().then((confidence) => commit(m.SET_CONFIDENCE, confidence)),
    /**
     * 企業信用情報をロードします。
     */
    [a.LOAD_CONFIDENCE]: ({ commit }, id: number) =>
        companyApi.confidence(id).then((confidence) => commit(m.SET_CONFIDENCE, confidence)),
    /**
     * 企業信用情報をクリアします。
     */
    [a.CLEAR_CONFIDENCE]: ({ commit }) => commit(m.SET_CONFIDENCE, undefined),
    /**
     * 信用情報を更新します。
     */
    [a.UPDATE_CONFIDENCE]: ({ commit }, param: CompanyConfidenceUpdateForm) =>
        companyApi
            .updateConfidence(param)
            .then(() => companyApi.myConfidence())
            .then((confidence) => commit(m.SET_CONFIDENCE, confidence)),
    /**
     * 自社の統計情報をロードします。
     */
    [a.LOAD_MY_STATISTICS]: ({ commit }) =>
        companyApi.myStatistics().then((statistics) => commit(m.SET_STATISTICS, statistics)),
    /**
     * 企業統計情報をロードします。
     */
    [a.LOAD_STATISTICS]: ({ commit }, id: number) =>
        companyApi.statistics(id).then((statistics) => commit(m.SET_STATISTICS, statistics)),
    /**
     * 企業統計情報をクリアします。
     */
    [a.CLEAR_STATISTICS]: ({ commit }) => commit(m.SET_STATISTICS, undefined),
    /**
     * 支払情報をロードします。
     */
    [a.LOAD_PAYMENT]: ({ commit }) => companyApi.payment().then((payment) => commit(m.SET_PAYMENT, payment)),
    /**
     * プレミアムプランに申し込みます。
     */
    [a.REGISTER_PREMIUM]: ({ commit }, param) =>
        companyApi
            .registerPremium(param)
            // 契約一覧をリロード
            .then(() => companyApi.contract())
            .then((list) => commit(m.SET_CONTRACT_LIST_MODEL, list)),
    /**
     * プレミアムプランを停止します。
     */
    [a.REVOKE_PREMIUM]: ({ commit }, param) =>
        companyApi
            .revokePremium(param)
            // 契約一覧をリロード
            .then(() => companyApi.contract())
            .then((list) => commit(m.SET_CONTRACT_LIST_MODEL, list)),
    /**
     * 運賃全額保証サービスを申込みます。
     */
    [a.REGISTER_GUARANTEE]: ({ commit }) =>
        companyApi
            .registerGuarantee()
            // 契約一覧をリロード
            .then(() => companyApi.contract())
            .then((list) => commit(m.SET_CONTRACT_LIST_MODEL, list)),
    /**
     * 運賃全額保証サービスを停止します。
     */
    [a.REVOKE_GUARANTEE]: ({ commit }) =>
        companyApi
            .revokeGuarantee()
            // 契約一覧をリロード
            .then(() => companyApi.contract())
            .then((list) => commit(m.SET_CONTRACT_LIST_MODEL, list)),
    /**
     * 企業契約一覧をロードします。
     */
    [a.LOAD_CONTRACTS]: ({ commit }) => companyApi.contract().then((list) => commit(m.SET_CONTRACT_LIST_MODEL, list)),
    /**
     * 企業過去契約一覧をロードします。
     */
    [a.LOAD_PAST_CONTRACTS]: ({ commit }, param) =>
        companyApi
            .contractYearMonth(param.year, param.month)
            .then((list) => commit(m.SET_PAST_CONTRACT_LIST_MODEL, list)),
    /**
     * おまかせ請求契約一覧をロードします。
     */
    [a.LOAD_EASY_PAYMENT_CONTRACTS]: ({ commit }) =>
        companyApi.allEasyPaymentContract().then((list) => commit(m.SET_EASY_PAYMENT_CONTRACT_LIST, list)),
    /**
     * 請求書発行期間を取得します。
     */
    [a.LOAD_BILLING_TERM]: ({ commit }) =>
        companyApi.billingTerm().then((billingTerm) => commit(m.SET_BILLING_TERM, billingTerm)),
    /**
     * 指定した月の請求一覧を取得します。
     */
    [a.LIST_BILLING_HISTORY]: ({ commit }, param) =>
        companyApi.billing(param.year, param.month).then((list) => commit(m.SET_BILLING_HISTORY_LIST, list)),
    /**
     * 請求書をクリアします。
     */
    [a.CLEAR_INVOICE]: ({ commit }, param) =>
        commit(m.SET_INVOICE_LIST, []),
    /**
     * 指定した年月の請求書を取得します。
     */
    [a.ADD_INVOICE]: async ({ commit, getters }, param) => {
        const invoiceList = getters[g.INVOICE_LIST] as CompanyInvoice[];
        const invoice = await companyApi.invoice(param.year, param.month);
        const newList = _.sortBy(
            [
                invoice,
                ...invoiceList.filter(invoice => !(invoice.year === param.year && invoice.month === param.month))
            ], ['year', 'month'])
            .reverse();
        commit(m.SET_INVOICE_LIST, newList);
        return newList;
    },
    [a.ISSUE_INVOICE_URL]: ({ commit }, param) => {
        return companyApi.issueInvoiceUrl(param.year, param.month);
    },
    /**
     * 指定した月の運賃全額保証サービスの利用額を取得します。
     */
    [a.LOAD_GUARANTEED_AMOUNT]: ({ commit }, param) =>
        companyApi
            .guaranteedAmount(param.year, param.month)
            .then((guaranteedAmount) => commit(m.SET_GUARANTEED_AMOUNT, guaranteedAmount)),
    /**
     * 企業担当者名おすすめ一覧をロードします。
     */
    [a.LOAD_STAFF_NAME_SUGGESTIONS]: ({ commit }) =>
        companyApi
            .loadStaffNameSuggestions()
            .then((staffNames) => commit(m.SET_STAFF_NAME_SUGGESTION_LIST, staffNames)),
    /**
     * 企業担当者名おすすめを登録します。
     */
    [a.REGISTER_STAFF_NAME_SUGGESTION]: (_, param: CompanyStaffNameSuggestionRegisterForm) =>
        companyApi.registerStaffNameSuggestion(param),
    /**
     * 企業担当者名おすすめを削除します。
     */
    [a.DELETE_STAFF_NAME_SUGGESTION]: (_, param: CompanyStaffNameSuggestionDeleteForm) =>
        companyApi.deleteStaffNameSuggestion(param),
    /**
     * 配車トラック履歴一覧をロードします。
     */
    [a.LOAD_DISPATCHED_TRUCK_HISTORIES]: ({ commit }) =>
        companyApi
            .loadDispatchedTruckHistories()
            .then((list) => commit(m.SET_DISPATCHED_TRUCK_LIST, list)),
    /**
     * 配車ドライバー履歴一覧をロードします。
     */
    [a.LOAD_DISPATCHED_DRIVER_HISTORIES]: ({ commit }) =>
        companyApi
            .loadDispatchedDriverHistories()
            .then((list) => commit(m.SET_DISPATCHED_DRIVER_LIST, list)),
    /**
     * 配車履歴を登録します。
     */
    [a.REGISTER_DISPATCH_HISTORY]: (_, param: CompanyDispatchHistoryRegisterForm) =>
        companyApi.registerDispatchHistory(param),
    /**
     * 配車履歴を削除します。
     */
    [a.DELETE_DISPATCH_HISTORY]: (_, param: CompanyDispatchHistoryRegisterForm) =>
        companyApi.deleteDispatchHistory(param),
    /**
     * 指定した企業IDの荷物一覧をロードします。
     */
    [a.LOAD_BAGGAGE_LIST]: ({ commit }, id: number) =>
        companyApi.baggageList(id).then((baggageList) => commit(m.SET_BAGGAGE_LIST, baggageList)),
    /**
     * 指定した企業IDの空車一覧をロードします。
     */
    [a.LOAD_TRUCK_LIST]: ({ commit }, id: number) =>
        companyApi.truckList(id).then((truckList) => commit(m.SET_TRUCK_LIST, truckList)),
    /**
     * 企業プロフィール検索フォームを設定します。
     *
     * @param form 企業プロフィール検索フォーム
     */
    [a.SET_PROFILE_SEARCH_FORM]: ({ commit }, form: CompanyProfileSearchForm) => commit(m.SET_PROFILE_SEARCH_FORM, form),
    /**
     * 企業振込情報をロードします。
     */
    [a.LOAD_TRANSFER]: ({ commit }) => companyApi.transfer().then((transfer) => commit(m.SET_TRANSFER, transfer)),
    /**
     * 企業振込情報を更新します。
     */
    [a.UPDATE_TRANSFER]: ({ commit }, param: CompanyTransferUpdateForm) =>
        companyApi
            .updateTransfer(param)
            .then(() => companyApi.transfer())
            .then((transfer) => commit(m.SET_TRANSFER, transfer)),
    /**
     * 企業申込一覧をロードします。
     */
    [a.LOAD_APPLICATION_LIST]: ({ commit }) =>
        companyApi.applications().then((applications) => commit(m.SET_APPLICATION_LIST, applications)),
};

const getters: GetterTree<CompanyState, void> = {
    /**
     * 企業ステータスを取得します。
     */
    [g.STATUS]: (s) => s.status,
    /**
     * 自社の企業プロフィールを取得します。
     */
    [g.MY_PROFILE]: (s) => s.myProfile,
    /**
     * 企業プロフィールを取得します。
     */
    [g.PROFILE]: (s) => s.profile,
    /**
     * 企業プロフィール一覧を取得します。
     */
    [g.PROFILE_LIST]: (s) => s.profileList,
    /**
     * 企業プロフィール検索一覧を取得します。
     */
    [g.PROFILE_SEARCH_LIST]: (s) => s.profileSearchList,
    /**
     * 企業プロフィール検索フォームを取得します。
     */
    [g.PROFILE_SEARCH_FORM]: (s) => s.profileSearchForm,
    /**
     * 信用情報を取得します。
     */
    [g.CONFIDENCE]: (s) => s.confidence,
    /**
     * 統計情報を取得します。
     */
    [g.STATISTICS]: (s) => s.statistics,
    /**
     * 支払情報を取得します。
     */
    [g.PAYMENT]: (s) => s.payment,
    /**
     * 企業契約一覧を取得します。
     */
    [g.CONTRACT_LIST]: (s) => s.contractList,
    /**
     * 企業過過去去契約一覧を取得します。
     */
    [g.PAST_CONTRACT_LIST]: (s) => s.pastContractList,
    /**
     * おまかせ請求契約一覧を取得します。
     */
    [g.EASY_PAYMENT_CONTRACT_LIST]:(s) => s.easyPaymentContractList,
    /**
     * 請求書発行期間を取得します。
     */
    [g.BILLING_TERM]: (s) => s.billingTerm,
    /**
     * 請求書発行期間を取得します。
     */
    [g.BILLING_LIST]: (s) => s.billing,
    /**
     * 請求書ダウンロードを取得します。
     */
    [g.INVOICE_LIST]: (s) => s.invoice,
    /**
     * 月次成約の合計金額を取得します。
     */
    [g.GUARANTEED_AMOUNT]: (s) => s.guaranteedAmount,
    /**
     * 月次成約の詳細一覧を取得します。
     */
    // [g.GUARANTEED_LIST]: (s) => s.guaranteedList,
    /**
     * 企業担当者名おすすめ一覧を取得します。
     */
    [g.STAFF_NAME_SUGGESTION_LIST]: (s) => s.staffNameSuggestionList,
    /**
     * 配車トラック履歴一覧を取得します。
     */
    [g.DISPATCHED_TRUCK_LIST]: (s) => s.dispatchedTruckList,
    /**
     * 配車ドライバー履歴一覧を取得します。
     */
    [g.DISPATCHED_DRIVER_LIST]: (s) => s.dispatchedDriverList,
    /**
     * 企業荷物一覧を取得します。
     */
    [g.BAGGAGE_LIST]: (s) => s.baggageList,
    /**
     * 企業空車一覧を取得します。
     */
    [g.TRUCK_LIST]: (s) => s.truckList,
    /**
     * 企業振込情報を取得します。
     */
    [g.TRANSFER]: (s) => s.transfer,
    /**
     * 企業申込一覧を取得します。
     */
    [g.APPLICATION_LIST]: (s) => s.applicationList,
};

const mutations: MutationTree<CompanyState> = {
    /**
     * 企業ステータスを設定します。
     *
     * @param status 企業ステータス
     */
    [m.SET_STATUS]: (s, status?: CompanyStatus) => (s.status = status),
    /**
     * 自社の企業プロフィールを設定します。
     *
     * @param profile 企業プロフィール
     */
    [m.SET_MY_PROFILE]: (s, profile?: CompanyProfile) => (s.myProfile = profile),
    /**
     * 企業プロフィールを設定します。
     *
     * @param profile 企業プロフィール
     */
    [m.SET_PROFILE]: (s, profile?: CompanyProfile) => (s.profile = profile),
    /**
     * 企業プロフィール一覧を設定します。
     *
     * @param list 企業プロフィール一覧
     */
    [m.SET_PROFILE_LIST]: (s, list: CompanyProfile[]) => (s.profileList = list),
    /**
     * 企業プロフィール検索一覧を設定します。
     *
     * @param list 企業プロフィール検索一覧
     */
    [m.SET_PROFILE_SEARCH_LIST]: (s, list: CompanyProfileList) => (s.profileSearchList = list),
    /**
     * 企業プロフィール検索フォームを設定します。
     *
     * @param form 企業プロフィール検索フォーム
     */
    [m.SET_PROFILE_SEARCH_FORM]: (s, form: CompanyProfileSearchForm) => (s.profileSearchForm = form),
    /**
     * 信用情報を設定します。
     *
     * @param confidence 信用情報
     */
    [m.SET_CONFIDENCE]: (s, confidence?: CompanyConfidence) => (s.confidence = confidence),
    /**
     * 統計情報を設定します。
     *
     * @param statistics 統計情報
     */
    [m.SET_STATISTICS]: (s, statistics?: CompanyStatistics) => (s.statistics = statistics),
    /**
     * 支払情報を設定します。
     *
     * @param payment 支払情報
     */
    [m.SET_PAYMENT]: (s, payment?: CompanyPayment) => (s.payment = payment),
    /**
     * 企業契約一覧モデルを設定します。
     *
     * @param list 企業契約一覧
     */
    [m.SET_CONTRACT_LIST_MODEL]: (s, list: CompanyContract[]) => (s.contractList = new CompanyContractListModel(list)),
    /**
     * 企業過去契約一覧モデルを設定します。
     *
     * @param list 企業過去契約一覧
     */
    [m.SET_PAST_CONTRACT_LIST_MODEL]: (s, list: CompanyContract[]) =>
        (s.pastContractList = new CompanyContractListModel(list)),
    /**
     * おまかせ請求契約一覧モデルを設定します。
     *
     * @param list おまかせ請求契約一覧
     */
    [m.SET_EASY_PAYMENT_CONTRACT_LIST]: (s, list: CompanyContract[]) =>
        (s.easyPaymentContractList = list.map(each => new CompanyContractModel(each))),
    /**
     * 請求書発行期間を設定します。
     *
     * @param billingTerm 請求書発行期間
     */
    [m.SET_BILLING_TERM]: (s, billingTerm: CompanyBillingTerm) => (s.billingTerm = billingTerm),
    /**
     * 対象期間の請求明細一覧を設定します
     *
     * @param list 請求明細一覧
     */
    [m.SET_BILLING_HISTORY_LIST]: (s, list: CompanyBilling[]) => (s.billing = list),
    /**
     * 対象年の請求書一覧を設定します
     *
     * @param list 請求書一覧
     */
    [m.SET_INVOICE_LIST]: (s, list: CompanyInvoice[]) => (s.invoice = list),
    /**
     * 運賃全額保証サービスの利用額を設定します。
     *
     * @param guaranteedAmount 運賃全額保証サービスの利用額
     */
    [m.SET_GUARANTEED_AMOUNT]: (s, guaranteedAmount: CompanyGuaranteedAmount) =>
        (s.guaranteedAmount = guaranteedAmount),
    /**
     * 企業担当者名おすすめ一覧を設定します。
     */
    [m.SET_STAFF_NAME_SUGGESTION_LIST]: (s, staffNameSuggestionList: CompanyStaffNameSuggestionList) =>
        (s.staffNameSuggestionList = staffNameSuggestionList),
    /**
     * 配車トラック履歴一覧を設定します。
     */
    [m.SET_DISPATCHED_TRUCK_LIST]: (s, list: CompanyDispatchedTruckList) =>
        (s.dispatchedTruckList = list),
    /**
     * 配車ドライバー履歴一覧を設定します。
     */
    [m.SET_DISPATCHED_DRIVER_LIST]: (s, list: CompanyDispatchedDriverList) =>
        (s.dispatchedDriverList = list),
    /**
     * 企業荷物一覧を設定します。
     */
    [m.SET_BAGGAGE_LIST]: (s, list: BaggageList) => (s.baggageList = list),
    /**
     * 企業空車一覧を設定します。
     */
    [m.SET_TRUCK_LIST]: (s, list: TruckList) => (s.truckList = list),
    /**
     * 企業振込情報を設定します。
     */
    [m.SET_TRANSFER]: (s, transfer: CompanyTransfer) => (s.transfer = transfer),
    /**
     * 申込み一覧を設定します。
     */
    [m.SET_APPLICATION_LIST]: (s, list: CompanyApplication[]) => (s.applicationList = new CompanyApplicationListModel(list)),
};

export const company: Module<CompanyState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
