<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from '@/composables/helper/route';
import { useSettlementListTabKey } from '@/composables/settlement-list';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { useRouting } from '@/composables/helper/routing';
import { useEntitlement } from '@/composables/entitlement';

type TabKey = 'income' | 'outgo';

const { currentRoute } = useRoute();
const { tabKey, goToSettlementList } = useSettlementListTabKey();
const { goToSettlementIncomeRegister } = useRouting();
const { state: { entitlement } } = useEntitlement();

const activeTabKey = computed(() => currentRoute.meta?.activeTabKey?.split('/').pop());
const canRegisterSettlement = computed(() => entitlement.value?.active('settlementProxy'));

/**
 * 選択しているタブを保存しておく。
 */
const saveTabKey = (key: TabKey) => tabKey.value = key;

/**
 * タブが変更された際に呼び出されます。
 */
const onChangeTab = async (key: TabKey) => {
    saveTabKey(key);

    await goToSettlementList();
};
</script>

<template>
    <div class="board-container">
        <div class="settlement-list__header">
            <a-radio-group size="large"
                           :value="activeTabKey"
                           @change="onChangeTab($event.target.value)"
                           class="menu">
                <a-radio-button value="income">入金</a-radio-button>
                <a-radio-button value="outgo">出金</a-radio-button>
            </a-radio-group>
            <a-button v-if="activeTabKey === 'income' && canRegisterSettlement"
                      size="large"
                      @click="goToSettlementIncomeRegister">
                <tbx-icon type="settlement-add-income"/>取引追加
            </a-button>
        </div>
        <router-view></router-view>
    </div>
</template>

<style scoped lang="less">
.board-container {
    padding: @padding-lg;

    .menu {
        padding-bottom: @padding-lg;

        .ant-radio-button-wrapper {
            padding: 0 @padding-lg;
        }
    }
}

.settlement-list {
    &__header {
        display: flex;
        justify-content: space-between;
    }
}
</style>
