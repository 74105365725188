<script setup lang="ts">
import EmptyTag from '@/_components/EmptyTag';
import { AmountValue } from '@/models/vo/amount';
import { computed } from 'vue';
import { Util } from '@/util';
import _ from 'lodash';

const props = defineProps<{
    amount: number | undefined | AmountValue;
}>();

const amountValue = computed<number | undefined>(() => {
    if (_.isNil(props.amount)) {
        return undefined;
    }
    if (typeof props.amount === 'number') {
        return props.amount;
    }
    return props.amount.value;
});

const text = computed<string>(() => {
    return amountValue.value ? `${ Util.formatNumber(amountValue.value) }円` : '-';
});
</script>

<template>
    <empty-tag>{{ text }}</empty-tag>
</template>

<style scoped lang="less">

</style>
