import { CompanyProfile } from '@/models/company';
import {
    Baggage,
    BaggageFreightMaster,
    BaggageList,
    BaggageSearchForm,
    BaggageRecommendReturnsCount
} from '@/models/baggage';
import dayjs from 'dayjs';

export class BaggageSearchResult {
    // 検索日時
    datetime: dayjs.Dayjs;

    // 検索条件
    condition: BaggageSearchForm;

    // 検索結果
    baggageList: BaggageList;
    companyProfiles: CompanyProfile[];
    favoriteBaggageIds: number[];
    readBaggageIds: number[];
    referenceFreights: BaggageFreightMaster[];
    baggageRecommendReturnsCounts: BaggageRecommendReturnsCount[];

    constructor(
        datetime: dayjs.Dayjs,
        condition: BaggageSearchForm,
        baggageList: BaggageList,
        companyProfiles: CompanyProfile[],
        favoriteBaggageIds: number[],
        readBaggageIds: number[],
        referenceFreights: BaggageFreightMaster[],
        baggageRecommendReturnsCounts: BaggageRecommendReturnsCount[],
    ) {
        this.datetime = datetime;
        this.condition = condition;
        this.baggageList = baggageList;
        this.companyProfiles = companyProfiles;
        this.favoriteBaggageIds = favoriteBaggageIds;
        this.readBaggageIds = readBaggageIds;
        this.referenceFreights = referenceFreights;
        this.baggageRecommendReturnsCounts = baggageRecommendReturnsCounts;
    }

    get pageInfo(): BaggageSearchResultPageInfo {
        return {
            pageSize: this.baggageList.pageSize,
            totalPageCount: this.baggageList.totalPageCount,
            totalRecordCount: this.baggageList.totalRecordCount,
            currentPageNumber: this.baggageList.currentPageNumber,
        };
    }

    get baggages(): Baggage[] {
        return this.baggageList.data;
    }

    withFavoriteBaggageIds(favoriteBaggageIds: number[]): BaggageSearchResult {
        return new BaggageSearchResult(
            this.datetime,
            this.condition,
            this.baggageList,
            this.companyProfiles,
            favoriteBaggageIds,
            this.readBaggageIds,
            this.referenceFreights,
            this.baggageRecommendReturnsCounts,
        );
    }

    withReadBaggageIds(readBaggageIds: number[]): BaggageSearchResult {
        return new BaggageSearchResult(
            this.datetime,
            this.condition,
            this.baggageList,
            this.companyProfiles,
            this.favoriteBaggageIds,
            readBaggageIds,
            this.referenceFreights,
            this.baggageRecommendReturnsCounts,
        );
    }
}

export interface BaggageSearchResultPageInfo {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
}
