import { Component, Prop, Vue } from 'vue-property-decorator';
import { AgreementUtil, DateUtil, PhoneUtil, ZipUtil } from '@/util';
import { Const } from '@/const';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as entitlementTypes from '@/vuex/modules/entitlement/types';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import Departure from '@/components/Agreement/View/Departure';
// @ts-ignore
import DepartureLocation from '@/components/Agreement/View/DepartureLocation';
// @ts-ignore
import Arrival from '@/components/Agreement/View/Arrival';
// @ts-ignore
import ArrivalLocation from '@/components/Agreement/View/ArrivalLocation';
// @ts-ignore
import Type from '@/components/Agreement/View/Type';
// @ts-ignore
import Truck from '@/components/Agreement/View/Truck';
// @ts-ignore
import SpecifiedTruck from '@/components/Agreement/View/SpecifiedTruck';
// @ts-ignore
import Freight from '@/components/Agreement/View/Freight';
// @ts-ignore
import HighwayFare from '@/components/Agreement/View/HighwayFare';
// @ts-ignore
import WaitTimeFee from '@/components/Agreement/View/WaitTimeFee';
// @ts-ignore
import OperationFee from '@/components/Agreement/View/OperationFee';
// @ts-ignore
import PickingFee from '@/components/Agreement/View/PickingFee';
// @ts-ignore
import ParkingFee from '@/components/Agreement/View/ParkingFee';
// @ts-ignore
import ClearanceFee from '@/components/Agreement/View/ClearanceFee';
// @ts-ignore
import Relocation from '@/components/Agreement/View/Relocation';
// @ts-ignore
import Share from '@/components/Agreement/View/Share';
// @ts-ignore
import PaymentDate from '@/components/Agreement/View/PaymentDate';
// @ts-ignore
import Guarantee from '@/components/Agreement/View/Guarantee';
// @ts-ignore
import ChangeLimit from '@/components/Agreement/View/ChangeLimit';
// @ts-ignore
import ChangeLimitAlert from '@/components/Agreement/View/ChangeLimitAlert';
// @ts-ignore
import GuaranteeTable from '@/components/Agreement/GuaranteeTable';
// @ts-ignore
import Description from '@/components/Agreement/View/Description';
// @ts-ignore
import TemperatureZone from '@/components/Agreement/View/TemperatureZone';
import TbxButton from '@/_components/ui/TbxButton.vue';
import { DateTimeValue } from '@/models/vo/datetime';
import { CompanyContractModel } from '@/models/company-contract';
import _ from 'lodash';
import { namespace } from 'vuex-class';

const entitlementMod = namespace('entitlement');

@Component({
    components: {
        Departure,
        DepartureLocation,
        Arrival,
        ArrivalLocation,
        Type,
        Truck,
        SpecifiedTruck,
        Freight,
        HighwayFare,
        WaitTimeFee,
        OperationFee,
        PickingFee,
        ParkingFee,
        ClearanceFee,
        Relocation,
        Share,
        PaymentDate,
        Guarantee,
        ChangeLimit,
        ChangeLimitAlert,
        GuaranteeTable,
        Description,
        TbxButton,
        TemperatureZone,
    },
})
export default class AgreementDetailView extends Vue {
    @Prop()
    declare readonly myCompanyProfile?: companyTypes.CompanyProfile;
    @Prop()
    declare readonly companyProfile?: companyTypes.CompanyProfile;
    @Prop()
    declare readonly agreement?: agreementTypes.Agreement;
    @Prop()
    declare readonly agreementChangeRequest?: agreementTypes.AgreementChangeRequest;
    @Prop()
    declare readonly canToggleGuarantee?: boolean;
    @Prop()
    declare readonly iAmTransporter?: boolean;
    @Prop()
    declare readonly easyPaymentContracts?: CompanyContractModel[];
    @entitlementMod.Getter(entitlementTypes.GETTER.ENTITLEMENT)
    readonly ENTITLEMENT?: entitlementTypes.EntitlementModel;

    /**
     * 指定した企業IDの企業名を取得します。
     * @param companyId
     */
    getCompanyName(companyId: number): string {
        const company = [this.myCompanyProfile, this.companyProfile].find((each) => each && each.id === companyId);
        return company?.name.kanji ?? '';
    }

    /**
     * 指定した企業IDの連絡先電話番号を取得します。
     * @param companyId
     */
    getPhone(companyId: number): companyTypes.CompanyProfilePhone {
        const company = [this.myCompanyProfile, this.companyProfile].find((each) => each && each.id === companyId);
        if (!company) {
            return { number: '', faxNumber: '' };
        }
        return company.phone;
    }

    /**
     * 郵便番号をフォーマットします。
     * @param zipCode
     */
    formatZipCode(zipCode: string): string {
        return ZipUtil.format(zipCode);
    }

    /**
     * 電話番号をフォーマットします。
     * @param phoneNumber
     */
    formatPhoneNumber(phoneNumber: string): string {
        return PhoneUtil.format(phoneNumber);
    }

    /**
     * 日時をフォーマットします。
     * @param dateTime
     */
    formatDateTime(dateTime: string): string {
        return DateUtil.parseDatetimeText(dateTime).format(Const.DEFAULT_DATETIME_WITH_DAY_OF_WEEK_FORMAT);
    }

    /**
     * 相手先企業IDを取得します。
     * 自社の荷物の場合は、運送企業ID、
     * 受託した荷物の場合は、荷主企業IDが返却されます。
     */
    get partnerCompanyId(): number | undefined {
        if (!this.agreement || !this.myCompanyProfile) {
            return;
        }
        // 自社の荷物の場合
        if (this.agreement.baggageCompanyId === this.myCompanyProfile.id) {
            return this.agreement.truckCompanyId; // 運送企業ID
        } else if (this.agreement.truckCompanyId === this.myCompanyProfile.id) {
            return this.agreement.baggageCompanyId; // 荷主企業ID
        } else {
            throw new Error('company cannot be specified.');
        }
    }

    /**
     * 運賃全額保証サービスの保証期間外に期間に該当するか否かを取得します。
     */
    get isPaymentDateOutOfGuaranteePeriod(): boolean {
        if (this.iAmTransporter && this.agreement && this.agreement.guarantee) {
            const range = AgreementUtil.availablePaymentDateRange(
                DateUtil.parseDatetimeText(this.agreement.arrivalMax),
                true
            );
            return !DateUtil.isIncluded(DateUtil.parseDateText(this.agreement.paymentDate), range);
        }
        return false;
    }

    get isReadyToStable(): boolean {
        if (!this.agreement) return false;
        // 運送企業でない場合は、NG
        if (!this.iAmTransporter) return false;
        // ステータスがACTIVE以外の場合は、NG
        if (this.agreement.status.code !== 'ACTIVE') return false;
        // おまかせ請求契約を一度も契約したことがない場合は、NG
        if (_.isEmpty(this.easyPaymentContracts)) return false;

        const now = DateTimeValue.now();
        // 着発日時を過ぎていない場合は、NG
        if (!now.isAfter(new DateTimeValue(this.agreement.arrivalMax))) return false;
        // 変更期限日時を過ぎている場合は、NG
        if (now.isAfter(new DateTimeValue(this.agreement.changeLimitTm))) return false;
        // 発日がおまかせ請求契約のサービス期間外なら、NG
        /*
         * 本来はおまかせ請求に関係なくOK（= APIでは制限していない）だが
         * リリース初期はボタン押下とおまかせ請求利用が、ほぼ同じ意味に捉えられると想定し
         * おまかせサービス期間外の成約にはボタンを見せないようにしている。
         */
        const departure = new DateTimeValue(this.agreement.departureMin);
        if (_.isNil(this.easyPaymentContracts?.find(each => each.isActive(departure.value)))) return false;

        return true;
    }

    /**
     * 決済代行ステータス表示の必要性を取得します。
     */
    get needToShowSettlementProxy(): boolean {
        if (_.isNil(this.agreement)) return false;
        // 運送企業でない場合は、見せない
        if (!this.iAmTransporter) return false;
        // 計上済み or 削除済みの場合は、見せない（＝有効、変更申請中、取消申請中のみ見せる）
        if (['STABLE', 'DELETE'].includes(this.agreement.status.code)) return false;
        // 取引が閲覧できないなら、見せない
        if (!this.canViewSettlement) return false;

        return true;
    }

    /**
     * 取引を閲覧可能かどうかを取得します。
     */
    get canViewSettlement(): boolean {
        return this.ENTITLEMENT?.can('viewSettlement') ?? false;
    }

    /**
     * 請求に追加するボタンをクリックすると呼び出されます。
     */
    onClickStabilize(): void {
        this.$emit('stabilize');
    }

    /**
     * 運賃全額保証サービスの利用設定を変更ボタンをクリックすると呼び出されます。
     */
    onClickChangeGuarantee(): void {
        this.$emit('showConfirmToggleGuarantee');
    }
}
