import _ from 'lodash';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import * as companyTypes from '@/vuex/modules/company/types';
import { Const } from '@/const';
import { DateUtil, ProductUtil, Util } from '@/util';

interface CompanyBillingView {
    year: number;
    month: number;
    code: string;
    label: string;
    total: number;
    data: companyTypes.CompanyBilling[];
    issuedInvoice: boolean;
}

@Component
export default class BillingView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly selectedYear: number;
    @Prop()
    declare readonly billingList?: companyTypes.CompanyBilling[];
    @Prop()
    declare readonly invoiceList?: companyTypes.CompanyInvoice[];
    currentYearMonth = '';

    /**
     * 年月をキーにグルーピングした表示用オブジェクトを取得します。
     */
    get billingViewList(): CompanyBillingView[] {
        // 年月単位でGroupBy
        const listGroupByMonth = _.groupBy<companyTypes.CompanyBilling>(
            this.billingList,
            (record) => String(record.year) + _.padStart(String(record.month), 2, '0')
        );
        // 最新の月順でソートして表示用オブジェクトを作成
        return Object.values(listGroupByMonth)
            .reverse()
            .map((items) => ({
                year: items[0].year,
                month: items[0].month,
                code: `${ items[0].year }-${ items[0].month }`,
                label: `${ items[0].year }年${ items[0].month }月`,
                total: BillingView.calculateTotal(items),
                data: items,
            }))
            .map(data => {
                const invoice = this.invoiceList?.find(invoice => invoice.year === data.year && invoice.month === data.month);
                return {
                    ...data,
                    issuedInvoice: invoice?.issued ?? false,
                };
            });
    }

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (!this.billingViewList || !this.billingViewList[0]) {
            return;
        }
        this.currentYearMonth = this.billingViewList[0].code;
    }

    @Watch('currentYearMonth', { immediate: true })
    onChangeCurrentYearMonth(newValue: string | string[] | undefined, oldValue: string | string[] | undefined): void {
        const diff = _.compact(_.difference(
            _.isString(newValue) ? [newValue] : newValue ?? [],
            _.isString(oldValue) ? [oldValue] : oldValue ?? []
        ));
        if (diff.length === 0) {
            return;
        }
        const [_yearMonth, year, month] = diff[0].match(/(\d+)[-](\d+)/) ?? [];
        this.$emit('openNewYearMonth', year, month);
    }

    /**
     * 課金発生日時を表示用にフォーマットします。
     * @param issueTm 課金発生日時
     */
    formatIssueTimeText(issueTm: string): string {
        return DateUtil.parseDatetimeText(issueTm).format(Const.DEFAULT_VIEW_DATE_FORMAT);
    }

    /**
     * 金額を表示用にフォーマットします。
     * @param price 金額
     */
    formatPrice(price: number): string {
        return Util.formatNumber(price);
    }

    /**
     * 運賃保証サービス対象の明細かどうかを判定します。
     * @param code ProductCode
     */
    isGuaranteeProduct(code: string): boolean {
        return ProductUtil.isGuarantee(code);
    }

    /**
     * 運賃保証の明細モーダルをクリックすると呼び出されます。
     * @param record ご利用明細
     */
    onClickAgreementDetail(record: companyTypes.CompanyBilling): void {
        this.$emit('openGuaranteedModal', record);
    }

    /**
     * 請求書ダウンロードボタンがクリックされると呼び出されます。
     * @param record
     */
    onClickInvoiceDownload(record: CompanyBillingView): void {
        this.$emit('clickInvoiceDownload', record.year, record.month);
    }
    /**
     * 合計金額を取得します。
     */
    private static calculateTotal(records: companyTypes.CompanyBilling[]): number {
        return _.sumBy(records, (record) => record.price.price + record.price.taxPrice);
    }
}
