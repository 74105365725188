import { LargeTruckAvailability } from '@/models/baggage';

export const useLargeTruckAvailabilityConverter = () => {
    const fromBoolean = (value: boolean | undefined): LargeTruckAvailability | undefined => {
        if (value === undefined) {
            return undefined;
        } else {
            return value ? 'available' : 'unavailable';
        }
    };

    const toBoolean = (value: LargeTruckAvailability | undefined): boolean | undefined => {
        if (value === undefined) {
            return undefined;
        } else {
            return value === 'available';
        }
    };

    return {
        fromBoolean,
        toBoolean,
    };
};
