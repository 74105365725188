<script setup lang="ts">
import DatePicker from '@/_components/ui/DatePicker.vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { DateValue } from '@/models/vo/date';
import { computed, ref } from 'vue';
import _ from 'lodash';

const props = defineProps<{
    value?: DateValue,
    defaultPaymentDate?: DateValue,
    isDisabledDate?: (currentDate: DateValue) => boolean,
}>();
const emits = defineEmits<{
    (e: 'input', value: DateValue | undefined): void,
    (e: 'change', value: DateValue | undefined): void,
}>();

// TODO: 「上書きするのか」「デフォルトの日時なのか」などをモデルで表現する

const edit = ref(props.value !== undefined);
const paymentDate = computed({
    get: () => {
        if (!editPaymentDate.value) {
            return props.defaultPaymentDate ?? undefined;
        }
        return props.value;
    },
    set: (value: DateValue | undefined) => {
        onChange(value);
    },
});
const editPaymentDate = computed({
    get: () => {
        if (!props.defaultPaymentDate) {
            return true;
        }
        return edit.value;
    },
    set: (value: boolean) => {
        edit.value = value;
        if (edit.value) {
            paymentDate.value = props.defaultPaymentDate ?? undefined;
        } else {
            paymentDate.value = undefined;
        }
    },
});
const disabled = computed(() => !editPaymentDate.value);
const editablePaymentDate = computed(() => !_.isEmpty(props.defaultPaymentDate));

const onChange = (value: DateValue | undefined) => {
    emits('input', value);
    emits('change', value);
};
</script>

<template>
    <a-space size="middle">
        <date-picker placeholder="日付を選択"
                     :input-read-only="true"
                     format="YYYY年M月D日(ddd)"
                     :allowClear="false"
                     :disabledDate="isDisabledDate"
                     :disabled="disabled"
                     :show-today="false"
                     v-model="paymentDate"
        >
            <template #suffixIcon>
                <tbx-icon type="calendar"/>
            </template>
        </date-picker>
        <span style="white-space: nowrap">
          <a-checkbox v-model="editPaymentDate" v-if="editablePaymentDate">入金予定日を変更する</a-checkbox>
        </span>
    </a-space>
</template>

<style scoped lang="less">

</style>
