<script setup lang="ts">
import ExcludeCompany from '@/_components/global/ExcludeCompany.vue';
import DetailList from '@/_components/parts/DetailList.vue';
import TransportSummary from '@/_components/parts/TransportSummary.vue';
import FavoriteButton from '@/_components/ui/FavoriteButton.vue';
import { useBaggageSearchDetailDrawerHelper } from '@/_components/baggage/baggage-search-detail-drawer-helper';
import UnderNegotiationBanner from '@/_components/parts/UnderNegotiationBanner.vue';

const props = withDefaults(defineProps<{
    visible: boolean,
    mask?: boolean,
    visibleExcludeCompany?: boolean,
    completeUnmarkFavoriteFn?: (baggageId: number) => Promise<void>,
}>(), {
    visible: false,
    mask: false,
    visibleExcludeCompany: true,
});
const emits = defineEmits<{
    (e: 'close'): void,
    (e: 'markFavorite', baggageId: number): void,
}>();

const {
    activeTabKey,
    baggage,
    baggageId,
    profile,
    favoriteState,
    underNegotiation,
    referenceFreight,
    isMyCompanyBaggage,
    companyName,
    shouldShowStatusLabel,
    shouldShowCanceledLabel,
    shouldShowAgreeButton,
    shouldShowNegotiationRequest,
    shouldShowTraboxTag,
    isAlreadyRequestNegotiation,
    canRequestNegotiation,
    negotiation,
    negotiationRequestForm,
    negotiationRequestFormValidateRules,
    negotiationRequestFormModel,
    negotiationRequestFormBlockRef,
    baggageDetailTabsRef,
    negotiationReportStatusOptions,
    onTabChange,
    onClickShowAchievement,
    onClickAgree,
    onClickNegotiationRequestDetail,
    onClickNegotiationRequest,
    clickMarkFavorite,
    clickUnmarkFavorite,
    clickCancelUnmarkFavorite,
    clickCompany,
    clickPrint,
} = useBaggageSearchDetailDrawerHelper();

const onClickUnmarkFavorite = () => {
    clickUnmarkFavorite(async (baggageId: number) => {
        if (props.completeUnmarkFavoriteFn) {
            await props.completeUnmarkFavoriteFn(baggageId);
        }
    });
};

const onClickMarkFavorite = async () => {
    if (baggageId.value) {
        await clickMarkFavorite();
        emits('markFavorite', baggageId.value);
    }
};

/*
  NOTE 対応可否の回答結果をフォームの値に復元するようにしたため、回答済の状態がわかりづらくなった。
  対策として、対応可否を送信したらDrawerを閉じるようにした。
*/
const onClickNegotiationReport = async () => {
    await onClickNegotiationRequest(() => emits('close'));
};

</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0 }"
              :mask="mask"
              :mask-style="{ background: 'transparent' }"
              :destroy-on-close="true"
              ref="root"
              @close="emits('close')">
        <a-tabs ref="baggageDetailTabsRef"
                class="drawer-content-tabs"
                :class="{ 'drawer-content-tabs--show-footer': shouldShowStatusLabel }"
                default-active-key="baggage"
                v-model="activeTabKey"
                @change="onTabChange"
                :tabBarStyle="{ padding: '12px 68px 0px 24px', margin: 0 }"
                :animated="false">
            <a-tab-pane key="baggage" tab="荷物情報">
                <a-spin :spinning="!baggage || !profile ">
                    <div class="header-secondary">
                        <div class="header-secondary__baggage-id">
                            <span style="margin-right: 1em">荷物番号</span>
                            <a-space>
                                {{ baggageId }}
                                <a-tag v-if="shouldShowTraboxTag" class="ant-tag-has-color trabox-color-tag">トラボックス掲載中</a-tag>
                            </a-space>
                        </div>
                        <div class="header-secondary__favorite-buttons">
                            <favorite-button :state="favoriteState"
                                             :show-label="true"
                                             @mark="onClickMarkFavorite"
                                             @unmark="onClickUnmarkFavorite"
                                             @cancelUnmark="clickCancelUnmarkFavorite"/>
                        </div>
                    </div>
                    <div class="notice-agreed" v-if="shouldShowStatusLabel">
                        <span v-if="shouldShowCanceledLabel">取り下げ済み</span>
                        <span v-else>成約済み</span>
                    </div>
                    <under-negotiation-banner v-if="underNegotiation" class="under-negotiation"/>
                    <transport-summary :baggage="baggage" :reference-freight="referenceFreight"/>
                    <div class="agreement-button">
                        <a-button type="primary"
                                  size="large"
                                  shape="round"
                                  class="agreement-button__agreement"
                                  @click="onClickAgree($event)"
                                  v-if="shouldShowAgreeButton">成約へ進む
                        </a-button>
                    </div>

                    <detail-list :baggage="baggage"
                                 :profile="profile"
                                 :isMyBaggage="isMyCompanyBaggage"
                                 @clickCompanyName="clickCompany"
                                 @clickShowAchievement="onClickShowAchievement"
                                 @clickNegotiationRequest="onClickNegotiationRequestDetail"/>
                    <exclude-company v-if="visibleExcludeCompany"
                                     :company-id="profile?.id"
                                     :company-name="companyName"/>

                    <div v-if="shouldShowNegotiationRequest"
                         class="negotiation-request-form-block"
                         ref="negotiationRequestFormBlockRef">
                        <div class="negotiation-request-form-header">
                            <h2 class="component-heading-h2">対応可否</h2>
                            <div v-if="isAlreadyRequestNegotiation">{{ negotiation?.reportSummary ?? '' }}</div>
                        </div>
                        <template v-if="canRequestNegotiation">
                            <div class="negotiation-request-form-block__body component-body-s">
                                <p>この荷物の運搬が可能かどうかをお知らせください。
                                </p>
                            </div>
                            <a-form-model ref="negotiationRequestFormModel"
                                          :model="negotiationRequestForm"
                                          :rules="negotiationRequestFormValidateRules">
                                <a-form-model-item prop="reportStatusCode">
                                    <a-radio-group name="reportStatusCode"
                                                   :options="negotiationReportStatusOptions"
                                                   v-model="negotiationRequestForm.reportStatusCode"/>
                                </a-form-model-item>
                                <a-form-model-item prop="message">
                                    <a-textarea v-model="negotiationRequestForm.message"
                                                :auto-size="{ minRows: 5, maxRows: 5 }"
                                                placeholder="(例) 積合で対応可能です。"
                                                :max-length="250"/>
                                </a-form-model-item>
                                <div class="negotiation-request-form-block__submit">
                                    <a-button
                                        type="default"
                                        size="large"
                                        @click="onClickNegotiationReport">送信
                                    </a-button>
                                </div>
                            </a-form-model>
                        </template>
                        <template v-else>
                            <div class="negotiation-request-form-block__body component-body-s">
                                この荷物は対応可否の受け付けを終了しました。
                            </div>
                        </template>
                    </div>

                </a-spin>
            </a-tab-pane>

            <template #tabBarExtraContent>
                <a-space v-if="baggage" direction="horizontal" class="drawer-header-actions">
                    <a-button v-if="baggage && shouldShowAgreeButton"
                              @click="clickPrint">印刷
                    </a-button>
                </a-space>
            </template>
        </a-tabs>
    </a-drawer>
</template>

<style scoped lang="less">
@drawer-footer-height: 72px;

::v-deep .ant-drawer-content-wrapper {
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2) !important;
}

// Header Tab Layout
.drawer-content-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    ::v-deep .ant-tabs-bar {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    ::v-deep .ant-tabs-content {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
        padding: @padding-lg;
    }
}

// Drawer
.drawer-header-actions {
    position: relative;
    top: -6px;
}

.header-primary {
    &__company-name {
        font-size: @font-size-lg;
        font-weight: bold;
        color: @heading-color;
        margin-bottom: @padding-xs;
    }
}

.header-secondary {
    width: 100%;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;

    &__baggage-id {
        white-space: nowrap;
        vertical-align: center;
        color: @heading-color;
    }

    &__favorite-buttons {
        vertical-align: center;
        text-align: center;
        margin-bottom: 8px;
    }
}

.notice-agreed {
    margin-top: 16px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(61, 61, 61, 1);
    border-color: rgba(61, 61, 61, 1);
    width: 100%;
    height: 48px;
    font-size: medium;
}

.agreement-button {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    &__agreement {
        align: center;
        color: black;
        background-color: rgba(247, 184, 58, 1);
        border-color: rgba(247, 184, 58, 1);
        width: 260px;
    }

    &__inactive {
        align: center;
        color: white;
        background-color: rgba(214, 214, 214, 1);
        border-color: rgba(214, 214, 214, 1);
        width: 260px;
    }

    &__mark-negotiation {
        width: 260px;
    }

    &__unmark-negotiation {
        width: 260px;
    }
}

.excluded-company {
    margin-top: 16px;
    text-align: right;
}

.under-negotiation {
    margin-top: 8px;
    margin-bottom: 24px;
}

.negotiation-request-form-header {
    display: flex;
    justify-content: space-between;
}

.negotiation-request-form-block {
    margin-top: 48px;

    &__body ul {
        padding-left: 0;
        list-style: none;
    }

    &__submit {
        display: grid;
        place-items: center;

        & p {
            margin-top: 4px;
        }
    }
}

.trabox-color-tag {
    background-color: @color-trabox-green-8;
}
</style>
