<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { useRouting } from '@/composables/helper/routing';
import TruckTypeSelect from '@/_components/ui/TruckTypeSelect.vue';
import BaggagePaymentDateInput from '@/_components/ui/BaggagePaymentDateInput.vue';
import ActionLayout from '@/_components/ui/layouts/ActionLayout.vue';
import { useSettlementIncomeRegisterHelper } from './settlement-income-register-helper';
import CurrencyInput from '@/_components/ui/CurrencyInput.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import LocationAddressInput from '@/_components/ui/LocationAddressInput.vue';
import DatePicker from '@/_components/ui/DatePicker.vue';

const { goToSettlementIncomeList } = useRouting();

const {
    loadingRegister,
    viewModel,
    formModel,
    validationRules,
    pickPartnerCompany,
    onSubmit,
} = useSettlementIncomeRegisterHelper();

const backToPreviousPage = async () => {
    await goToSettlementIncomeList().catch(() => {
        // ページ離脱をキャンセルする場合に備えてエラーをもみ消し
    });
};

</script>

<template>
    <page-layout @back="backToPreviousPage">
        <a-card>
            <a-spin :spinning="loadingRegister">
                <a-form-model ref="formModel"
                              :model="viewModel"
                              :rules="validationRules"
                              class="form"
                              :colon="false"
                              :label-col="{ span: 4 }"
                              :wrapper-col="{ span: 20 }">

                    <a-form-model-item label="荷主"
                                       prop="payerCompany"
                                       class="payer-company-form-item">
                        <a-select :open="false"
                                  :value="viewModel.payerCompany?.name.kanji"
                                  @focus="pickPartnerCompany"/>
                        <template #extra>
                            <span>※過去に成約したことがない企業は登録できません</span>
                        </template>
                    </a-form-model-item>

                    <a-form-model-item label="発日"
                                       prop="departureDate"
                                       class="departure-date-form-item">
                        <date-picker v-model="viewModel.departureDate"
                                     placeholder="発日を選択"
                                     :input-read-only="true"
                                     format="YYYY年M月D日(ddd)"
                                     :allowClear="false"
                                     :disabledDate="viewModel.isUnavailableDeliveryDate"/>
                    </a-form-model-item>

                    <a-form-model-item label="発地"
                                       prop="departureLocation"
                                       :auto-link="false"
                                       class="location-form-item">
                        <a-input-group compact>
                            <prefecture-select v-model="viewModel.departurePref"
                                               :multiple="false"
                                               placeholder="都道府県"
                                               title="発地"/>
                            <city-input v-model="viewModel.departureCity"
                                        :pref-code="viewModel.departurePref"
                                        :trim-on-blur="true"/>
                            <location-address-input v-model="viewModel.departureAddress"/>
                        </a-input-group>
                    </a-form-model-item>

                    <a-form-model-item label="着日"
                                       prop="arrivalDate"
                                       class="arrival-date-form-item">
                        <date-picker v-model="viewModel.arrivalDate"
                                     placeholder="着日を選択"
                                     :input-read-only="true"
                                     format="YYYY年M月D日(ddd)"
                                     :allowClear="false"
                                     :disabledDate="viewModel.isUnavailableDeliveryDate"/>
                    </a-form-model-item>

                    <a-form-model-item label="着地"
                                       prop="arrivalLocation"
                                       class="location-form-item">
                        <a-input-group compact>
                            <prefecture-select v-model="viewModel.arrivalPref"
                                               :multiple="false"
                                               placeholder="都道府県"
                                               title="発地"/>
                            <city-input v-model="viewModel.arrivalCity"
                                        :pref-code="viewModel.arrivalPref"
                                        placeholder="市区町村"
                                        :trim-on-blur="true"/>
                            <location-address-input v-model="viewModel.arrivalAddress"/>
                        </a-input-group>
                    </a-form-model-item>

                    <truck-type-select prop-name="truckType"
                                       option-type="baggage"
                                       class="truck-type-form-item"
                                       v-model="viewModel.truckType"
                                       :allow-clear="true"/>

                    <section class="fee-section">
                        <a-form-model-item label="運賃" prop="freight">
                            <currency-input v-model="viewModel.freight"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('freight')"
                                            suffix="円 (税別)"/>
                        </a-form-model-item>

                        <a-form-model-item label="高速代" prop="highwayFare">
                            <currency-input v-model="viewModel.highwayFare"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('highwayFare')"
                                            suffix="円 (税込)"/>
                        </a-form-model-item>

                        <a-form-model-item label="待機料" prop="waitTimeFee">
                            <currency-input v-model="viewModel.waitTimeFee"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('waitTimeFee')"
                                            suffix="円 (税別)"/>
                        </a-form-model-item>

                        <a-form-model-item label="付帯作業料" prop="operationFee">
                            <currency-input v-model="viewModel.operationFee"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('operationFee')"
                                            suffix="円 (税別)"/>
                        </a-form-model-item>

                        <a-form-model-item label="搬出料" prop="pickingFee">
                            <currency-input v-model="viewModel.pickingFee"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('pickingFee')"
                                            suffix="円 (税込)"/>
                        </a-form-model-item>

                        <a-form-model-item label="駐車代" prop="parkingFee">
                            <currency-input v-model="viewModel.parkingFee"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('parkingFee')"
                                            suffix="円 (税込)"/>
                        </a-form-model-item>

                        <a-form-model-item label="通関料">
                            <currency-input v-model="viewModel.clearanceFee"
                                            :disabled="viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('clearanceFee')"
                                            suffix="円 (非課税)"/>
                        </a-form-model-item>

                        <a-form-model-item label="キャンセル料"
                                           class="cancellation-fee-form-item"
                                           prop="cancellationFee">
                            <currency-input v-model="viewModel.cancellationFee"
                                            :disabled="!viewModel.isCanceling"
                                            :range="viewModel.availableFeeRange('cancellationFee')"
                                            suffix="円 (非課税)"/>
                        </a-form-model-item>

                        <a-form-model-item label="キャンセル"
                                           class="is-canceling-form-item"
                                           prop="isCanceling">
                            <a-checkbox v-model="viewModel.isCanceling">キャンセル料を入力する</a-checkbox>
                        </a-form-model-item>
                    </section>

                    <a-form-model-item label="入金予定日"
                                       prop="paymentDate"
                                       class="payment-date-form-item">
                        <baggage-payment-date-input v-model="viewModel.paymentDate"
                                                    :default-payment-date="viewModel.defaultPaymentDate"
                                                    :is-disabled-date="viewModel.isUnavailablePaymentDate"/>
                    </a-form-model-item>

                    <a-alert type="warning"
                             message="トラボックスからお客様への入金日について"
                             class="payment-date-notes"
                             :show-icon="true">
                        <template #description>
                            <a-descriptions :column="1" size="small" :colon="false">
                                <a-descriptions-item label="入金日">
                                    <span>{{ viewModel.nextPaymentDateFromTrabox.format('YYYY年M月D日(ddd)') }}</span>
                                </a-descriptions-item>
                                <a-descriptions-item label="承認期限">
                                    <span>{{ viewModel.nextLimitDate.format('YYYY年M月D日(ddd)') }}</span>
                                </a-descriptions-item>
                            </a-descriptions>
                            <div>※承認期限を過ぎると、翌月に繰り越されます。</div>
                        </template>
                    </a-alert>

                    <action-layout>
                        <a-button type="primary"
                                  size="large"
                                  @click="onSubmit">取引追加
                        </a-button>
                    </action-layout>

                </a-form-model>
            </a-spin>
        </a-card>
    </page-layout>
</template>

<style scoped lang="less">
:deep(.page-layout__body) {
    margin: 24px;
}

// フォームを中央寄せに配置
.form {
    max-width: 800px;
    margin: 0 auto;

    .fee-section {
        margin: 40px 0;
    }
}

.payer-company-form-item {
    :deep(.ant-form-item-control) {
        width: 400px;
    }
}

.location-form-item {
    .ant-input-group {
        > *:nth-child(1) {
            width: 150px;
        }

        > *:nth-child(2) {
            width: 200px;
        }

        > *:nth-child(3) {
            width: calc(100% - 350px);
        }
    }
}

.departure-date-form-item,
.arrival-date-form-item {
    :deep(.ant-select) {
        width: 200px;
    }
}

.truck-type-form-item {
    :deep(.ant-input-group) {
        display: inline-flex;
        width: auto;
    }

    :deep(.ant-select) {
        width: calc((400px - 10px) / 2) !important;
    }

    :deep(.ant-select:not(:first-child)) {
        margin-left: 10px;
    }
}

.fee-section {
    :deep(.ant-form-item-control) {
        width: 244px;
    }
}

.cancellation-fee-form-item {
    margin-bottom: 0;
}

.is-canceling-form-item {
    :deep(.ant-form-item-label) {
        > label {
            display: none;
        }
    }
}

.payment-date-form-item {
    :deep(.ant-calendar-picker) {
        width: 200px;
    }
}

// 入金予定日の注意文言
.payment-date-notes {
    :deep(.ant-descriptions-item-label) {
        min-width: 70px;
    }

    :deep(.ant-descriptions-item-content) {
        &:before {
            content: ':';
        }

        span {
            margin-left: 8px;
        }
    }
}

// 追加ボタンの調整
.action-layout {
    margin-top: 40px;
}
</style>
