<script setup lang="ts">
import SettingLayout from '@/_pages/Setting/_components/SettingLayout.vue';
import { useSettingCompanyConfidenceHelper } from './setting-company-confidence-helper';

const {
    form,
    formModel,
    formValidateRules,
    loading,
    onSubmit
} = useSettingCompanyConfidenceHelper();
</script>

<template>
    <setting-layout>
        <a-card>
            <a-spin :spinning="loading">
                <a-form-model ref="formModel"
                              :model="form"
                              :rules="formValidateRules"
                              :colon="false"
                              :label-col="{ xs: { span: 6 }, xxl: { span: 5, offset: 2 } }"
                              :wrapper-col="{ xs: { span: 18 }, xxl: { span: 15 } }">
                    <a-form-model-item label="加入組織" prop="unionName" :wrapper-col="{span: 9}">
                        <a-input placeholder="東京都トラック協会"
                                 v-model="form.unionName"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="国交省認可番号" prop="mlitGrantNumber" :wrapper-col="{span: 9}">
                        <a-input v-model="form.mlitGrantNumber"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="デジタコ搭載数" prop="digitalTachometerCount" :wrapper-col="{span: 3}">
                        <a-input v-model="form.digitalTachometerCount"
                                 suffix="台"
                                 :max-length="5"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="GPS搭載数" prop="gpsCount" :wrapper-col="{span: 3}">
                        <a-input v-model="form.gpsCount"
                                 suffix="台"
                                 :max-length="5"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>
                    <a-form-model-item label="安全性優良事業所認定" prop="goodMark" :wrapper-col="{span: 3}">
                        <a-switch checked-children="有"
                                  un-checked-children="無"
                                  v-model="form.goodMark"/>
                    </a-form-model-item>
                    <a-form-model-item label="グリーン経営認証" prop="greenManagement" :wrapper-col="{span: 3}">
                        <a-switch checked-children="有"
                                  un-checked-children="無"
                                  v-model="form.greenManagement"/>
                    </a-form-model-item>
                    <a-form-model-item label="ISO9000" prop="iso9000" :wrapper-col="{span: 3}">
                        <a-switch checked-children="有"
                                  un-checked-children="無"
                                  v-model="form.iso9000"/>
                    </a-form-model-item>
                    <a-form-model-item label="ISO14000" prop="iso14000" :wrapper-col="{span: 3}">
                        <a-switch checked-children="有"
                                  un-checked-children="無"
                                  v-model="form.iso14000"/>
                    </a-form-model-item>
                    <a-form-model-item label="ISO39001" prop="iso39001" :wrapper-col="{span: 3}">
                        <a-switch checked-children="有"
                                  un-checked-children="無"
                                  v-model="form.iso39001"/>
                    </a-form-model-item>
                    <a-form-model-item label="保険会社名" prop="insuranceName" :wrapper-col="{span: 9}">
                        <a-input v-model="form.insuranceName"
                                 @blur="form.normalize()"/>
                    </a-form-model-item>

                    <br/>

                    <a-form-model-item :wrapper-col="{ span: 24 }" style="text-align: center">
                        <a-button style="max-width: 200px"
                                  type="primary"
                                  size="large"
                                  block
                                  @click="onSubmit">保存
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-card>
    </setting-layout>
</template>

<style scoped lang="less">
</style>
