<script setup lang="ts">
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { columns } from './outgo-table-definition';
import { CustomRow } from '@/types/ant-design';
import { SettlementListModel, SettlementModel } from '@/models/settlement';

// propsを定義
const props = withDefaults(defineProps<{
    list?: SettlementListModel;
    loading: boolean;
    selectedSettlementId?: number;
}>(), {
    list: () => SettlementListModel.empty(),
});

// emitを定義
const emit = defineEmits<{
    (e: 'selectSettlement', id: number): void;
}>();

const customRow = ({ settlement }: { settlement: SettlementModel }): CustomRow => {
    return {
        on: {
            click: (event) => {
                if (props.selectedSettlementId !== settlement.id) {
                    event.stopPropagation();
                    emit('selectSettlement', settlement.id);
                }
            },
        },
    };
};

const rowClassName = ({ settlement }: { settlement: SettlementModel }): string => {
    const styleClasses = ['app-table-row', 'app-table-row--clickable'];
    if (settlement.id === props.selectedSettlementId) {
        styleClasses.push('app-table-row--selected');
    }
    return styleClasses.join(' ');
};
</script>

<template>
    <div>
        <div class="search-results-header">
            <slot name="header"/>
        </div>

        <a-table ref="tableElement"
                 class="app-result-list-table"
                 bordered
                 :columns="columns"
                 :data-source="props.list.data"
                 :row-key="record => record.settlement.id"
                 table-layout="fixed"
                 :loading="props.loading"
                 :custom-row="customRow"
                 size="middle"
                 :scroll="{x: 800}"
                 :row-class-name="rowClassName"
                 :pagination="false">
            <template v-slot:titleHeader>
                <a-tooltip placement="top" :arrow-point-at-center="true" :overlay-style="{ maxWidth: '330px' }">
                    <template v-slot:title>
                        チェックマークが付いているものは、運送会社が請求内容の確認を済ませたものとなります。
                    </template>
                    確認
                    <tbx-icon type="question"/>
                </a-tooltip>
            </template>

            <template v-slot:confirmationMemo="text, record">
                <tbx-icon type="check-circle-filled"
                          class="confirmation-icon"
                          v-if="record.settlement.confirmed && record.settlement.proxy"/>
            </template>

            <template v-slot:settlementId="text, record">
                <span v-if="record.settlement.proxy">{{ record.settlement.id }}</span>
                <span v-else>おまかせ対象外</span>
            </template>

            <template v-slot:payerDate="text, record">
                <span v-if="record.settlement.proxy">{{ record.settlement.payerDate.format() }}</span>
            </template>

            <template v-slot:status="text, record">
                <a-tag v-if="record.settlement.status.isUnconfirmed" color="orange" class="tag">未承認</a-tag>
            </template>

            <template v-slot:label="text, record">
                <div>{{ record.settlement.departureLabel }}→</div>
                <div>{{ record.settlement.arrivalLabel }}</div>
            </template>

            <template v-slot:totalAmount="text, record">
                <span>{{ record.settlement.totalAmount.format() }}</span>
            </template>

            <template v-slot:companyName="text, record">
                <span>{{ record.partner.name.kanji }}</span>
            </template>
        </a-table>

        <div class="search-results-footer">
            <slot name="footer"/>
        </div>
    </div>
</template>

<style scoped lang="less">
.search-results-header {
    display: flex;
    align-items: center;
    padding: @padding-lg @padding-lg 0;
    margin-bottom: 8px;
}

.search-results-footer {
    display: flex;
    align-items: center;
    padding: @padding-sm;
}

.app-table-row {
    .confirmation-icon {
        font-size: 18px;
        color: @token-main-color-trabox-green;
    }

    .tag {
        margin: 0;
    }
}
</style>
