import { useSettlementIncomeList } from '@/composables/settlement-list';
import { useSettlementIncomeDateList } from '@/composables/settlement-date-list';
import { computed, onBeforeMount, provide, ref } from 'vue';
import { useRouting } from '@/composables/helper/routing';
import { PreloadProps, DetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/props';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import {
    Settlement_DRAWER_PROVIDER_KEY,
    useSettlementDrawerProvider
} from '@/composables/provider/settlement-drawer-provider';
import { useRouteExtension } from '@/composables/helper/route-extension';
import { useMessage } from '@/composables/helper/page-helper';
import { useSettlementConfirmationUpdate } from '@/composables/settlement';
import {
    Settlement_EDITOR_PROVIDER_KEY,
    useSettlementEditorProvider
} from '@/composables/provider/settlement-editor-provider';

export const useSettlementIncomeListHelper = (props: { preload: PreloadProps, detail?: DetailProps }) => {
    const {
        state: { form, pageInfo, list, loading },
        search: searchList,
        changePage,
        toggleConfirmed: toggleListConfirmed,
    } = useSettlementIncomeList();
    const { state: { list: payeeDateList }, load: loadDateList } = useSettlementIncomeDateList();
    const { mark, unmark } = useSettlementConfirmationUpdate();
    const { goToSettlementIncomeList, goToSettlementIncomeDetail, goToNotFound } = useRouting();
    const { settlementIncomeDetailProps } = useRouteExtension();
    const message = useMessage();
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);

    const settlementDrawerProvider = useSettlementDrawerProvider();
    provide(Settlement_DRAWER_PROVIDER_KEY, settlementDrawerProvider);
    const { load: loadSettlement, clear: clearSettlement } = settlementDrawerProvider;
    const settlementEditorProvider = useSettlementEditorProvider();
    provide(Settlement_EDITOR_PROVIDER_KEY, settlementEditorProvider);
    const { state: { dirty }, load: loadSettlementEditor, clear: clearSettlementEditor } = settlementEditorProvider;

    const search = async () => {
        form.value.pageNo = 1;
        await searchList();
    };

    const reload = async () => {
        await searchList();
    };

    // 詳細ドロワーの表示状態
    const settlementDrawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && props.detail !== undefined;
    });
    const settlementEditorVisibility = computed<boolean>(() => {
        return settlementDrawerVisibility.value && (props.detail?.edit ?? false);
    });

    const goToSettlementDetail = async (settlementId: number) => {
        // 連打などにより画面遷移が失敗する可能性があるので、遷移失敗の例外をもみ消す
        await goToSettlementIncomeDetail(settlementId).catch(() => {
        });
    };

    const onCloseDrawer = async () => {
        if (props.detail?.settlementId) {
            await goToSettlementIncomeList();
        }
    };

    const onCloseEditor = async () => {
        if (props.detail?.settlementId) {
            await goToSettlementIncomeDetail(props.detail.settlementId);
        }
    };

    const onChangeDetail = async () => {
        if (props.detail?.settlementId) {
            await Promise.all([
                loadSettlement(props.detail.settlementId),
                reload()
            ]);
            await goToSettlementIncomeDetail(props.detail.settlementId);
        }
    };

    const onMarkAsConfirmed = async (settlementId: number) => {
        await mark(settlementId);
        toggleListConfirmed(settlementId, true);
    };

    const onUnMarkAsConfirmed = async (settlementId: number) => {
        await unmark(settlementId);
        toggleListConfirmed(settlementId, false);
    };

    onBeforeMount(async () => {
        form.value.payeeDate = props.preload.payeeDate;
        form.value.onlyProxy = props.preload.proxy;
        await loadDateList();
        await search();

        if (props.detail?.settlementId) {
            try {
                await loadSettlement(props.detail.settlementId);
                if (props.detail?.edit) {
                    await loadSettlementEditor(props.detail.settlementId);
                }
            } catch {
                await goToNotFound();
            }
        }
    });

    // クエリーが変化した後にロードだと、GTMイベントの発火タイミングなどの都合が悪いので、
    // クエリー変化時の処理が成功したときのみURLを変化させる
    onBeforeRouteUpdate(async (to, from, next) => {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (routeUpdating.value) {
            return next(false);
        }

        const fromDetailProps = settlementIncomeDetailProps(from);
        const toDetailProps = settlementIncomeDetailProps(to);

        // toがない場合、一覧に戻る
        if (!toDetailProps) {
            clearSettlement();
            clearSettlementEditor();
            return next();
        }
        // toが編集モードでない場合
        if (!toDetailProps.edit) {
            if (fromDetailProps?.edit && dirty.value) {
                if (!confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
                    return next(false);
                }
            }
            clearSettlementEditor();
        }
        // 荷物IDが同じ場合
        if (fromDetailProps?.settlementId === toDetailProps.settlementId) {
            // 編集モードに変わった場合
            if (!fromDetailProps?.edit && toDetailProps.edit) {
                await loadSettlementEditor(toDetailProps.settlementId);
            }
            return next();
        }

        routeUpdating.value = true;
        try {
            await loadSettlement(toDetailProps.settlementId);
            if (toDetailProps.edit) {
                await loadSettlementEditor(toDetailProps.settlementId);
            }
        } catch {
            message.error(`取引番号 ${ toDetailProps.settlementId } の取引情報を読み込みできませんでした。`);
            return next(false);
        } finally {
            routeUpdating.value = false;
        }

        next();
    });

    return {
        loading,
        form,
        pageInfo,
        payeeDateList,
        list,
        settlementDrawerVisibility,
        settlementEditorVisibility,
        search,
        reload,
        changePage,
        selectSettlement: goToSettlementDetail,
        onCloseDrawer,
        onCloseEditor,
        onChangeDetail,
        onMarkAsConfirmed,
        onUnMarkAsConfirmed,
    };
};
