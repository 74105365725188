import { useRoute } from '@/composables/helper/route';
import { onMounted, ref } from 'vue';
import { useAccountVerify } from '@/composables/account-verify';
import { useRouting } from '@/composables/helper/routing';
import { useTimer } from '@/composables/helper/time-helper';

export const useAccountVerifyHelper = (verificationCode?: string) => {
    const { currentRouteMeta } = useRoute();
    const { state: { loading }, verify } = useAccountVerify(currentRouteMeta?.accountType);
    const { delay } = useTimer();
    const { goToAccountPasswordRegister } = useRouting();
    const succeed = ref(false);

    const initialize = async () => {
        if (!verificationCode?.trim()) return;
        try {
            await verify(verificationCode);
            succeed.value = true;
            // NOTE: 文章が読めるように若干の遅延を入れる
            await delay(1000);
            await goToAccountPasswordRegister(verificationCode);
        } catch {
            succeed.value = false;
        }
    };

    onMounted(() => initialize());

    return {
        loading,
        succeed,
    };
};
