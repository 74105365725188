import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { Util } from '@/util';
import { miscApi } from '@/repository/api/api';
import { PrefectureEnum } from '@/enums/prefecture.enum';

@Component
export default class AccountLocationRegisterer extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    /**
     * 郵便番号
     */
    get zipCode(): string {
        return this.value?.company.zipCode ?? '';
    }

    set zipCode(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        const digits = Util.toDigits(newValue).substr(0, 7);
        cloned.company.zipCode = digits;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemZipCode.onFieldChange());

        if (digits.length === 7) {
            this.autoCompleteAddress(digits);
        }
    }

    /**
     * 都道府県
     */
    get prefecture(): string | undefined {
        return this.value?.company.location.prefecture?.code ?? undefined;
    }

    set prefecture(newValue: string | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.location.prefecture = { code: newValue ?? '' };
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemPrefecture.onFieldChange());
    }

    get prefectures(): Array<{ value: string; label: string; key: string }> {
        return PrefectureEnum.values.map((each) => ({
            value: each.code,
            label: each.label,
            key: each.code,
        }));
    }

    /**
     * 市区町村
     */
    get city(): string {
        return this.value?.company.location.city ?? '';
    }

    set city(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.location.city = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemCity.onFieldChange());
    }

    /**
     * 番地・建物
     */
    get address(): string {
        return this.value?.company.location.address ?? '';
    }

    set address(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.location.address = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemAddress.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    isLoadingZipCode = false;
    zipValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateZipCode(callback as (message?: string) => void),
        },
    ];
    prefectureValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validatePrefecture(callback as (message?: string) => void),
        },
    ];
    cityValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validateCity(callback as (message?: string) => void),
        },
    ];
    addressValidationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) =>
                this.validateAddress(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    private autoCompleteAddress(zip: string): void {
        this.isLoadingZipCode = true;
        miscApi
            .zipToAddress(zip)
            .then((result) => {
                if (!result) return;
                const prefLabel = result?.address1 ?? '';
                const city = (result?.address2 ?? '') + (result?.address3 ?? '');

                const cloned = _.cloneDeep(this.value);
                if (!cloned) return;

                cloned.company.location.prefecture =
                    PrefectureEnum.values.find((each) => each.label === prefLabel) ??
                    cloned.company.location.prefecture;
                cloned.company.location.city = city;

                this.$emit('input', cloned);

                this.$nextTick(() => {
                    // @ts-ignore
                    this.$refs.formItemPrefecture.onFieldChange();
                    // @ts-ignore
                    this.$refs.formItemCity.onFieldChange();
                });
            })
            .catch()
            .finally(() => (this.isLoadingZipCode = false));
    }

    private validateZipCode(callback: (message?: string) => void): void {
        if (!this.value?.company.zipCode?.trim()) {
            callback('郵便番号を入力してください。');
        } else if (this.value.company.zipCode.length != 7) {
            callback('郵便番号を正しく入力してください。');
        } else {
            callback();
        }
    }

    private validatePrefecture(callback: (message?: string) => void): void {
        if (!this.value?.company.location.prefecture?.code?.trim()) {
            callback('都道府県を選択してください。');
        } else {
            callback();
        }
    }

    private validateCity(callback: (message?: string) => void): void {
        if (!this.value?.company.location.city?.trim()) {
            callback('市区町村名を入力してください。');
        } else if (this.value.company.location.city.length > 200) {
            callback('市区町村は200文字以内で入力してください。');
        } else {
            callback();
        }
    }

    private validateAddress(callback: (message?: string) => void): void {
        if (!this.value?.company.location.address?.trim()) {
            callback('番地・建物を入力してください。');
        } else if (this.value.company.location.address.length > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
