<script setup lang="ts">
import DrawerLayout from '@/_components/ui/layouts/DrawerLayout.vue';
import { useBaggageEditorHelper } from '@/_pages/Baggage/List/drawers/BaggageEditor/baggage-editor-helper';
import DeliveryDateTimeRangePicker from '@/_components/ui/DeliveryDateTimeRangePicker.vue';
import PrefectureSelect from '@/_components/ui/PrefectureSelect.vue';
import CityInput from '@/_components/ui/CityInput.vue';
import { useBaggageShapeOptions, } from '@/composables/option-helper';
import BaggageTypeInput from '@/_components/ui/BaggageTypeInput.vue';
import { computed } from 'vue';
import BaggageHandlingOperationPicker from '@/_components/ui/BaggageHandlingOperationPicker.vue';
import TruckTypeSelect from '@/_components/ui/TruckTypeSelect.vue';
import type { TruckType } from '@/_components/ui/types/truck-type';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import TruckHeightPicker from '@/_components/ui/TruckHeightPicker.vue';
import TruckWidthPicker from '@/_components/ui/TruckWidthPicker.vue';
import LargeTruckAvailabilityPicker from '@/_components/ui/LargeTruckAvailabilityPicker.vue';
import TruckEquipmentInput from '@/_components/ui/TruckEquipmentInput.vue';
import BaggageDescriptionInput from '@/_components/ui/BaggageDescriptionInput.vue';
import BaggageFreightInput from '@/_components/ui/BaggageFreightInput.vue';
import BaggageHighwayFarePaymentOptionPicker from '@/_components/ui/BaggageHighwayFarePaymentOptionPicker.vue';
import BaggagePaymentDateInput from '@/_components/ui/BaggagePaymentDateInput.vue';
import { Baggage } from '@/models/baggage';
import { CompanyMyProfile } from '@/models/company';
import TraboxBaggageIdInput from '@/_components/ui/TraboxBaggageIdInput.vue';
import TemperatureZonePicker from '@/_components/ui/TemperatureZonePicker.vue';
import LabelPicker from '@/_components/baggage/LabelPicker.vue';

const props = defineProps<{
    baggage: Baggage;
    companyProfile: CompanyMyProfile;
    stateChanged: (state: 'editing' | 'updating' | 'finished') => void
}>();

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'dirty'): void;
}>();

const {
    model,
    viewModel,
    formValidateRules,
    formModel,
    staffNameSuggestionList,
    circleOptions,
    labelList,
    update,
} = useBaggageEditorHelper(
    props.baggage,
    props.companyProfile,
    () => {
        emit('dirty');
    },
    props.stateChanged,
);

// Note: 分割代入しないと機能しない。何か良い方法がないだろうか。
const {
    departureDateTimeRange,
    arrivalDateTimeRange,
    departurePref,
    departureCity,
    departureAddress,
    arrivalPref,
    arrivalCity,
    arrivalAddress,
    loadingTimeNote,
    unloadingTimeNote,
    shape,
    temperatureZone,
    isShapePalette,
    isShapeOther,
    type,
    paletteCount,
    paletteHeight,
    paletteWidth,
    totalCount,
    totalVolume,
    totalWeight,
    loadingOperation,
    unloadingOperation,
    truckType,
    truckHeight,
    truckWidth,
    largeTruckAvailability,
    truckEquipment,
    baggageFreight,
    highwayFarePaymentOption,
    paymentDate,
    defaultPaymentDate,
    isDisabledPaymentDate,
    description,
    isShare,
    isExpress,
    isRelocate,
    staffName,
    shipperName,
    circleId,
    label,
    onBlurDepartureCity,
    onBlurDepartureAddress,
    onBlurArrivalCity,
    onBlurArrivalAddress,
    onBlurLoadingTimeNote,
    onBlurUnloadingTimeNote,
    onBlurType,
    onBlurTruckEquipment,
    onBlurDescription,
    onBlurStaffName,
    onBlurShipperName,
    selectableDateRange,
    traboxBaggageId,
} = viewModel;

// TODO: 型をあわせる(荷物登録との調整が必要)
// コンポーネントで扱う型との違いを吸収するためのアダプター
const truckTypeAdapter = computed<TruckType>({
    get: () => {
        return {
            truckModel: TruckModelEnum.valueOf(truckType.value.truckModel) ?? TruckModelEnum.Unspecified,
            truckWeight: TruckWeightEnum.valueOf(truckType.value.truckWeight) ?? TruckWeightEnum.Unspecified,
        };
    },
    set: (value) => {
        truckType.value = {
            truckModel: value.truckModel?.code ?? TruckModelEnum.Unspecified.code,
            truckWeight: value.truckWeight?.code ?? TruckWeightEnum.Unspecified.code
        };
    }
});

const { options: shapeOptions } = useBaggageShapeOptions();

</script>

<template>
    <drawer-layout @close="emit('close')" title="荷物情報変更">
        <template #actions>
            <a-button type="primary"
                      @click="update">変更
            </a-button>
        </template>

        <a-form-model ref="formModel"
                      class="baggage-edit-form"
                      :model="model"
                      :rules="formValidateRules"
                      :colon="false"
                      :label-col="{ span: 7, offset: 0 }"
                      :wrapper-col="{ span: 17 }">
            <a-form-model-item label="荷物番号">
                <span>{{ baggage.id }}</span>
            </a-form-model-item>
            <a-form-model-item label="部屋">
              <a-select :options="circleOptions"
                        v-model="circleId" />
            </a-form-model-item>
            <a-form-model-item label="ラベル"
                               prop="label">
              <label-picker v-model="label" :label-list="labelList" :show-delete="false" />
            </a-form-model-item>
            <a-form-model-item label="発日時/発地"
                               prop="departureDateTimeRange"
                               class="form__departure-date-time">
                <delivery-date-time-range-picker v-model="departureDateTimeRange"
                                           :valid-range="selectableDateRange"/>
            </a-form-model-item>

            <!-- 出発地 -->
            <a-form-model-item label="都道府県"
                               prop="departurePrefCode"
                               class="form__pref">
                <prefecture-select v-model="departurePref"
                                   :multiple="false"
                                   placeholder="都道府県"
                                   title="発地"/>
            </a-form-model-item>

            <a-form-model-item label="市区町村"
                               prop="departureCity"
                               class="form__city">
                <city-input v-model="departureCity"
                            :pref-code="model.departurePrefCode"
                            placeholder="市区町村"
                            @blur="onBlurDepartureCity"></city-input>
            </a-form-model-item>

            <a-form-model-item label="番地・建物"
                               prop="departureAddress"
                               class="form__address">
                <a-input v-model="departureAddress"
                         placeholder="番地・建物（任意）"
                         @blur="onBlurDepartureAddress"/>
            </a-form-model-item>

            <a-form-model-item label="積み時間"
                               prop="loadingTimeNote">
                <a-input v-model="loadingTimeNote"
                         placeholder="積み時間を入力してください"
                         @blur="onBlurLoadingTimeNote"/>
            </a-form-model-item>

            <a-form-model-item label="着日時/着地"
                               prop="arrivalDateTimeRange"
                               class="form__arrival-date-time">
                <delivery-date-time-range-picker v-model="arrivalDateTimeRange"
                                           :valid-range="selectableDateRange"/>
            </a-form-model-item>

            <!-- 到着地 -->
            <a-form-model-item label="都道府県"
                               prop="arrivalPrefCode"
                               class="form__pref">
                <prefecture-select v-model="arrivalPref"
                                   :multiple="false"
                                   placeholder="都道府県"
                                   title="着地"/>
            </a-form-model-item>

            <a-form-model-item label="市区町村"
                               prop="arrivalCity"
                               class="form__city">
                <city-input v-model="arrivalCity"
                            :pref-code="model.arrivalPrefCode"
                            placeholder="市区町村"
                            @blur="onBlurArrivalCity"></city-input>
            </a-form-model-item>

            <a-form-model-item label="番地・建物"
                               prop="arrivalAddress"
                               class="form__address">
                <a-input v-model="arrivalAddress"
                         placeholder="番地・建物（任意）"
                         @blur="onBlurArrivalAddress"/>
            </a-form-model-item>

            <a-form-model-item label="卸し時間"
                               prop="unloadingTimeNote">
                <a-input v-model="unloadingTimeNote"
                         placeholder="卸し時間を入力してください"
                         @blur="onBlurUnloadingTimeNote"/>
            </a-form-model-item>

            <a-form-model-item label="荷姿"
                               prop="shape">
                <a-radio-group name="shape"
                               :options="shapeOptions"
                               v-model="shape"/>
            </a-form-model-item>

            <a-form-model-item label="荷種"
                               prop="type">
                <baggage-type-input v-model="type"
                                    @blur="onBlurType"/>
            </a-form-model-item>

            <a-form-model-item v-if="isShapePalette"
                               label="パレット枚数（目安）"
                               prop="paletteCount"
                               class="form__palette-count">
                <a-input v-model="paletteCount"
                         placeholder="パレット枚数（目安）"
                         :max-length="5"/>
            </a-form-model-item>

            <a-form-model-item v-if="isShapePalette"
                               label="パレットサイズ"
                               prop="paletteSize">
                <!-- TODO: 別コンポーネントに切り出す -->
                <a-input-group>
                    <a-row :gutter="8">
                        <a-col :span="9">
                            <a-input v-model="paletteHeight"
                                     placeholder="縦"
                                     addonBefore="縦"
                                     suffix="cm"
                                     :max-length="5"/>
                        </a-col>
                        <a-col :span="9">
                            <a-input v-model="paletteWidth"
                                     placeholder="横"
                                     addonBefore="横"
                                     suffix="cm"
                                     :max-length="5"/>
                        </a-col>
                    </a-row>
                </a-input-group>
            </a-form-model-item>

            <a-form-model-item v-if="isShapeOther"
                               label="荷物の個数（目安）"
                               prop="totalCount"
                               class="form__total-count">
                <a-input v-model="totalCount"
                         placeholder="荷物の個数（目安）"
                         :max-length="5"/>
            </a-form-model-item>

            <a-form-model-item v-if="isShapeOther"
                               label="荷物の体積"
                               prop="totalVolume"
                               class="form__total-volume">
                <a-input v-model="totalVolume"
                         placeholder="荷物の個数（目安）"
                         :max-length="5"/>
                <template #suffix>m<sup>3</sup></template>
            </a-form-model-item>

            <a-form-model-item label="総重量"
                               prop="totalWeight"
                               class="form__total-weight">
                <a-input v-model="totalWeight"
                         placeholder="総重量"
                         suffix="kg"
                         :max-length="5"/>
            </a-form-model-item>

            <a-form-model-item label="ドライバー作業"
                               prop="loadingOperation"
                               class="form__loading-operation">
                <baggage-handling-operation-picker type="loading"
                                                   v-model="loadingOperation"/>
            </a-form-model-item>

            <a-form-model-item label="ドライバー作業(卸し)"
                               prop="unloading"
                               class="form__unloading-operation">
                <baggage-handling-operation-picker type="unloading"
                                                   v-model="unloadingOperation"/>
            </a-form-model-item>

            <temperature-zone-picker v-model="temperatureZone" class="form__temperature-zone"/>

            <!-- TODO: UIコンポーネントにa-form-model-itemは含めない方が再利用しやすいと思う -->
            <truck-type-select v-model="truckTypeAdapter"
                               option-type="baggage"
                               prop-name="truckType"
                               :allow-clear="false"
                               class="form__truck-type"/>

            <a-form-model-item label="床高"
                               prop="truckHeight"
                               class="form__truck-height">
                <truck-height-picker v-model="truckHeight"
                                     option-type="baggage"/>
            </a-form-model-item>

            <a-form-model-item label="車幅"
                               prop="truckWidth"
                               class="form__truck-width">
                <truck-width-picker v-model="truckWidth"
                                    option-type="baggage"/>
            </a-form-model-item>

            <a-form-model-item label="大型可否"
                               prop="largeTruckAvailability"
                               class="form__large-truck-availability">
                <large-truck-availability-picker v-model="largeTruckAvailability"/>
            </a-form-model-item>

            <a-form-model-item label="必要装備"
                               prop="truckEquipment">
                <truck-equipment-input v-model="truckEquipment"
                                       :auto-size="{ minRows: 2, maxRows: 5 }"
                                       @blur="onBlurTruckEquipment"/>
            </a-form-model-item>

            <a-form-model-item label="運賃"
                               prop="baggageFreight"
                               class="form__baggage-freight">
                <baggage-freight-input v-model="baggageFreight" :multi-truck="false"/>
            </a-form-model-item>

            <a-form-model-item label="高速代"
                               prop="highwayFarePaymentOption">
                <baggage-highway-fare-payment-option-picker v-model="highwayFarePaymentOption"/>
            </a-form-model-item>

            <a-form-model-item label="入金予定日"
                               prop="paymentDate"
                               ref="formItem"
                               class="form__payment-date">
                <baggage-payment-date-input v-model="paymentDate"
                                            :default-payment-date="defaultPaymentDate"
                                            :is-disabled-date="isDisabledPaymentDate"/>
            </a-form-model-item>

            <a-form-model-item label="備考"
                               prop="description">
                <baggage-description-input v-model="description"
                                           :auto-size="{ minRows: 2, maxRows: 5 }"
                                           @blur="onBlurDescription"/>
            </a-form-model-item>

            <a-form-model-item label="フラグ"
                               class="form__flags">
                <a-checkbox v-model="isExpress">至急</a-checkbox>
                <a-checkbox v-model="isRelocate">引越し案件</a-checkbox>
                <a-checkbox v-model="isShare">積合</a-checkbox>
            </a-form-model-item>

            <a-form-model-item label="荷主名"
                               prop="shipperName">
                <a-input v-model="shipperName"
                         placeholder="例）アマゾン"
                         @blur="onBlurShipperName"/>
            </a-form-model-item>

            <a-form-model-item label="担当者"
                               prop="staffName">
                <a-auto-complete placeholder="担当者名"
                                 :data-source="staffNameSuggestionList.staffNames"
                                 v-model="staffName"
                                 @blur="onBlurStaffName">
                </a-auto-complete>
            </a-form-model-item>
            <a-form-model-item label="トラボックス荷物番号"
                               prop="traboxBaggageId">
                <trabox-baggage-id-input v-model="traboxBaggageId"/>
            </a-form-model-item>
        </a-form-model>
    </drawer-layout>
</template>

<style scoped lang="less">
.baggage-edit-form {
    padding: 10px 20px;
}

// 編集項目のラベルを消す
.form__pref, .form__city, .form__address, .form__flags, .form__unloading-operation {
    :deep(.ant-form-item-label) {
        > label {
            display: none;
        }
    }
}

.form__city {
    :deep(.ant-form-item-children) {
        &:first-child {
            background-color: #2d9cdb;
        }
    }
}

// TODO: もうちょっと簡単にサイズ調整できるようにする(antのクラスを意識しないで調整したい)
// ドローワーの中で表示、フルサイズでの表示、それぞれのサイズを共通cssで処理したい

// UIのサイズ調整
// `wrapper-col`で調整すると、エラーメッセージの幅も狭まってしまうため、cssで調整する
.form__pref,
.form__truck-height,
.form__truck-width,
.form__large-truck-availability {
    :deep(.ant-select) {
        width: 200px;
    }
}

.form__departure-date-time,
.form__arrival-date-time {
  :deep(.ant-select) {
    width: 300px;
  }
}

.form__palette-count,
.form__total-count,
.form__total-volume,
.form__payment-date {
    :deep(.ant-input) {
        width: 200px;
    }
}

.form__total-weight,
.form__baggage-freight {
    :deep(.ant-input-affix-wrapper) {
        width: 200px;
    }
}

.form__temperature-zone {
    :deep(.ant-select) {
        width: 200px;
    }
}

// 希望トラックの重量、車種を縦配置にする（車種選択UIが狭すぎて文字が見えないため）
.form__truck-type {
    :deep(.ant-input-group) {
        display: inline-flex;
        flex-direction: column;

        > .ant-select + .ant-select {
            margin-top: 24px;
        }

        > .ant-select {
            width: 200px !important;
        }
    }
}

.flags__label {
    :deep(.ant-form-item-children) {
        width: 80%;
        display: inline-flex;
        justify-content: space-between;
    }
}
</style>
