import { Component, Prop, Vue } from 'vue-property-decorator';
import { BAGGAGE_TYPE_DATA, BaggageTypeData } from './baggage-type-data';

@Component
export default class UiBaggageTypeInput extends Vue {
    @Prop()
    declare value?: string;
    @Prop({ default: '荷種' })
    declare placeholder: string;
    dataSource: Array<BaggageTypeData> = [];

    /**
     * 荷種テキスト
     */
    get baggageType(): string {
        return this.value ?? '';
    }

    set baggageType(newValue: string) {
        this.$emit('input', newValue);
    }

    created(): void {
        if (!this.value || this.value.length === 0) {
            this.dataSource = BAGGAGE_TYPE_DATA;
        }
    }

    /**
     * 入力フィールドに文字を入力すると呼び出されます。
     * @param searchText
     */
    onSearch(searchText: string): void {
        const value = searchText.trim();
        if (value.length === 0) {
            this.dataSource = BAGGAGE_TYPE_DATA;
            return;
        }
        this.dataSource = BAGGAGE_TYPE_DATA.map((category) => {
            return {
                title: category.title,
                items: category.items.filter((each) =>
                    [each.value, ...(each.keywords ?? [])].some((keyword) => keyword.indexOf(value) >= 0)
                ),
            };
        }).filter((each) => each.items.length > 0);
    }

    onFocus(): void {
        if (!this.baggageType) this.dataSource = BAGGAGE_TYPE_DATA;
    }
}
