/**
 * 荷物の温度帯Enum
 */
export type BaggageTemperatureZoneEnumCode =
    | 'AMBIENT'
    | 'CONTROLLED'
    | 'REFRIGERATED'
    | 'FROZEN';


export class BaggageTemperatureZoneEnum {
    constructor(public code: BaggageTemperatureZoneEnumCode, public label: string) {
    }

    static Ambient = new BaggageTemperatureZoneEnum('AMBIENT', '常温');
    static Controlled = new BaggageTemperatureZoneEnum('CONTROLLED', '定温');
    static Refrigerated = new BaggageTemperatureZoneEnum('REFRIGERATED', '冷蔵');
    static Frozen = new BaggageTemperatureZoneEnum('FROZEN', '冷凍');


    static valueOf = (code: BaggageTemperatureZoneEnumCode): BaggageTemperatureZoneEnum | undefined =>
        BaggageTemperatureZoneEnum.values.find((value) => value.code === code);
    static values = [
        BaggageTemperatureZoneEnum.Ambient,
        BaggageTemperatureZoneEnum.Controlled,
        BaggageTemperatureZoneEnum.Refrigerated,
        BaggageTemperatureZoneEnum.Frozen,
    ];

    // 荷物で登録可能な値
    static registrableValuesForBaggage = [...BaggageTemperatureZoneEnum.values];
}
