import dayjs from 'dayjs';
import { DateUtil, ProductUtil } from '@/util';
import { Const } from '@/const';
import { InvoiceCode, PremiumPlanCode } from '@/enums/product.enum';

/**
 * 請求期間を管理するマスタEnum
 */
export class BillingCycleEnum {
    private static monthlyPrice = 6000;

    code: PremiumPlanCode;
    label: string;
    month: number;
    price: number;

    constructor(code: PremiumPlanCode, label: string, month: number, price: number) {
        this.code = code;
        this.label = label;
        this.month = month;
        this.price = price;
    }

    static Monthly = new BillingCycleEnum(
        ProductUtil.Premium1m.code as PremiumPlanCode,
        '月払い',
        1,
        BillingCycleEnum.monthlyPrice
    );
    static HalfYearly = new BillingCycleEnum(
        ProductUtil.Premium6m.code as PremiumPlanCode,
        '半年払い',
        6,
        BillingCycleEnum.monthlyPrice * 6
    );
    static Annually = new BillingCycleEnum(
        ProductUtil.Premium1y.code as PremiumPlanCode,
        '年払い',
        12,
        BillingCycleEnum.monthlyPrice * 12
    );

    static valueOf = (code: string): BillingCycleEnum | undefined =>
        BillingCycleEnum.values.find((value) => value.code === code);
    static values = [BillingCycleEnum.Monthly, BillingCycleEnum.HalfYearly, BillingCycleEnum.Annually];

    /**
     * 終了日を算出します。
     *
     * @param criteria 開始日（指定されなければ、現在時刻）
     */
    calculateEndDate(criteria: dayjs.Dayjs = DateUtil.now()): dayjs.Dayjs {
        // 月の後半に申し込もうとした場合は、有料別途課金になるため、1ヶ月調整
        const adjustment = criteria.get('date') > Const.MONTHLY_TERM_END_DAY ? 1 : 0;

        return criteria
            .startOf('month')
            .add(this.month + adjustment, 'month')
            .subtract(1, 'millisecond');
    }

    /**
     * 支払期日を算出します。
     *
     * @param criteria 申込日（指定されなければ、現在時刻）
     */
    calculatePaymentDueDate(criteria: dayjs.Dayjs = DateUtil.now()): dayjs.Dayjs {
        // 月の後半に申し込もうとした場合は、有料別途課金になるため、1ヶ月調整
        const adjustment = criteria.get('date') > Const.MONTHLY_TERM_END_DAY ? 1 : 0;

        return criteria.add(1 + adjustment, 'month').endOf('month');
    }
}

/**
 * 請求書送付の種類を管理するマスタEnum
 */
export class InvoiceEnum {
    code: InvoiceCode;
    label: string;
    billingFee: number;

    constructor(code: InvoiceCode, label: string, billingFee: number) {
        this.code = code;
        this.label = label;
        this.billingFee = billingFee;
    }

    static Fax = new InvoiceEnum(ProductUtil.InvoiceFax.code as InvoiceCode, 'FAX', 220);
    static Post = new InvoiceEnum(ProductUtil.InvoicePost.code as InvoiceCode, '郵送あり', 300);
    static FaxPost = new InvoiceEnum(ProductUtil.InvoiceFaxPost.code as InvoiceCode, '両方', 300);
    static PostForFree = new InvoiceEnum(ProductUtil.InvoicePostForFree.code as InvoiceCode, '郵送のみ', 0);
    static Free = new InvoiceEnum(ProductUtil.InvoiceFree.code as InvoiceCode, '郵送なし', 0);

    static valueOf = (code: string): InvoiceEnum | undefined =>
        InvoiceEnum.values.find((value) => value.code === code);
    static values = [InvoiceEnum.Fax, InvoiceEnum.Post, InvoiceEnum.FaxPost, InvoiceEnum.PostForFree, InvoiceEnum.Free];
    static valuesForMonthly = [InvoiceEnum.Free, InvoiceEnum.Post];
    static valuesForHalfYearlyAndAnnually = [InvoiceEnum.PostForFree];
}
