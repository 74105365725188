<script setup lang="ts">
import { Baggage } from '@/models/baggage';
import { computed } from 'vue';
import { useEnvironment } from '@/composables/global/environment';

const props = defineProps<{
    baggage: Pick<Baggage, 'traboxBaggageId'>;
}>();

const shouldShowLink = computed<boolean>(() => {
    return props.baggage?.traboxBaggageId !== undefined;
});

// Rootコンポーネントでロードされています。
const { state: { environment } } = useEnvironment();

const ultraboxUrl = computed<string>(() => {
    if (!environment.value) return '';
    return `${environment.value?.ultraboxUrl}/baggage/list/opened`;
});

</script>

<template>
    <a v-if="shouldShowLink" :href="ultraboxUrl" target="_blank">{{ baggage.traboxBaggageId ?? '' }}</a>
</template>

<style scoped lang="less">
</style>
