import { DeliveryDateTimeRangeType } from '@/models/vo/delivery-datetime-range';

/**
 * 荷物の時間Enum
 */
export type TimeTypeEnumCode =
    | 'UNSPECIFIED'
    | 'MORNING'
    | 'AFTERNOON'
    | 'JUST'
    | 'PERIOD';


export class TimeTypeEnum {
    constructor(
        public code: TimeTypeEnumCode,
        public label: string,
        public type: DeliveryDateTimeRangeType,
    ) {}

    static Unspecified = new TimeTypeEnum('UNSPECIFIED', '指定なし', 'Day');
    static Morning = new TimeTypeEnum('MORNING', '午前', 'Morning');
    static Afternoon = new TimeTypeEnum('AFTERNOON', '午後', 'Afternoon');
    static Just = new TimeTypeEnum('JUST', '指定', 'Just');
    static Period = new TimeTypeEnum('PERIOD', '期間', 'Period');


    static valueOf = (code: TimeTypeEnumCode): TimeTypeEnum | undefined =>
        TimeTypeEnum.values.find((value) => value.code === code);
    static values = [
        TimeTypeEnum.Unspecified,
        TimeTypeEnum.Morning,
        TimeTypeEnum.Afternoon,
        TimeTypeEnum.Just,
        TimeTypeEnum.Period,
    ];

    // 荷物で登録可能な値
    static registrableValuesForBaggage = [...TimeTypeEnum.values];
}
