import { computed, ref } from 'vue';
import { useLoading } from '@/composables/helper/loading-helper';
import { AccountRenewalVerificationFormModel } from '@/models/account-renewal';
import { FormValidateUtil } from '@/models/validate-helper';
import { accountApi } from '@/repository/api/internal/account';

export const useAccountRenewalVerify = () => {
    const { state: { loading }, withLoading } = useLoading();

    const form = ref(new AccountRenewalVerificationFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const verify = () => withLoading(async () => {
        await accountApi.verifyAccountRenewalCode(form.value);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        verify,
    };
};
