import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountRegisterForm } from '@/vuex/modules/account/types';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import _ from 'lodash';

@Component
export default class AccountCompanyNameRegisterer extends Vue {
    @Prop()
    declare readonly value?: AccountRegisterForm;
    @Prop({ default: 'default' })
    declare readonly formItemSize: string;

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    get companyName(): string {
        return this.value?.company.companyName ?? '';
    }

    set companyName(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.company.companyName = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    /**
     * フォーカスが外れた際に呼び出されます。
     */
    onBlur(): void {
        this.companyName = this.companyName.trim();
    }

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.company.companyName?.trim()) {
            callback('法人名・営業所名を入力してください。');
        } else if (this.value.company.companyName.length > 200) {
            callback('法人名・営業所名は200文字以内で入力してください。');
        } else {
            callback();
        }
    }
}
