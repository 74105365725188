<script setup lang="ts">
import { useCompanyTruckEventTruckRegister } from '@/composables/company-truck-event';
import { DateTimeValue } from '@/models/vo/datetime';
import { computed, onBeforeMount } from 'vue';
import SpotSelectInput from '@/_components/ui/SpotSelectInput.vue';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useFormModel } from '@/composables/form-helper';

const props = defineProps<{
    startTm: DateTimeValue,
    endTm: DateTimeValue,
    companyTruckId: number,
}>();

const emit = defineEmits<{
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const { state: { form, formValidateRules }, register } = useCompanyTruckEventTruckRegister();
const { state: { profile: accountProfile } } = useAccountMyProfile();
const { state: { myProfile: myCompanyProfile }, load: loadMyCompanyProfile } = useCompanyMyProfile();
const { formModel, submit } = useFormModel();

const circleOptions = computed(() => myCompanyProfile.value?.circles?.map((each) => ({
    value: each.id,
    label: each.name,
    key: each.id,
})));

const isMemberOfSingleCircle = computed(() =>
    myCompanyProfile.value?.circles?.length === 1
);

onBeforeMount(async () => {
    if (!myCompanyProfile) await loadMyCompanyProfile();

    // 選択されたセルの情報をフォームにセット
    form.value.companyTruckId = props.companyTruckId;
    form.value.startTm = props.startTm.format('YYYY-MM-DD HH:mm:ss');
    form.value.endTm = props.endTm.format('YYYY-MM-DD HH:mm:ss');

    form.value.staffName = accountProfile.value?.name;
    if (isMemberOfSingleCircle.value) form.value.circleId = myCompanyProfile.value?.circles?.[0]?.id;
});

const onSubmit = () => submit(async () =>  {
    await register(form.value);
    emit('ok');
});

const onCancel = () => {
    emit('cancel');
};

</script>

<template>
  <div>
    <div class="content">
      <a-form-model ref="formModel"
                    :model="form"
                    :rules="formValidateRules"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 18 }">
                <a-form-model-item label="期間">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="11">
                            <a-input v-model:value="form.startTm"/>
                        </a-col>
                        <a-col :span="1" style="display: flex; justify-content: center">
                            →
                        </a-col>
                        <a-col :span="11">
                            <a-input v-model:value="form.endTm"/>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <spot-select-input label="空車地"
                                   prop-name="departureSpot"
                                   :show-address="false"
                                   v-model="form.departureSpot">
                    <template #prefTitle>
                        <img src="/img/ic-departure.svg" width="24" height="24" alt="空車地"
                             style="margin-right: 8px"/>空車地
                    </template>
                </spot-select-input>
                <spot-select-input label="行先地"
                                   prop-name="arrivalSpot"
                                   :show-address="false"
                                   v-model="form.arrivalSpot">
                    <template #prefTitle>
                        <img src="/img/ic-arrival.svg" width="24" height="24" alt="行先地"
                             style="margin-right: 8px"/>行先地
                    </template>
                </spot-select-input>
                <a-form-model-item label="タイトル"
                                   prop="title">
                    <a-input v-model:value="form.title"/>
                </a-form-model-item>
                <a-form-model-item label="備考"
                                   prop="description">
                    <a-textarea v-model:value="form.description"/>
                </a-form-model-item>
                <a-form-model-item label="部屋"
                                   prop="circleId">
                    <a-select :options="circleOptions"
                              v-model="form.circleId"
                              placeholder="部屋を選択"
                              style="width: 300px" />
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="footer">
            <a-button type="primary" @click="onSubmit">空車を作成</a-button>
            <a-button @click="onCancel">キャンセル</a-button>
        </div>
    </div>
</template>

<style scoped lang="less">
.footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
</style>
