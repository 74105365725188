import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';
import { Const } from '@/const';
import { Util } from '@/util';

@Component
export default class CompanyDigitalTachometerCountEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyConfidenceUpdateForm;

    /**
     * デジタコ搭載数
     */
    get digitalTachometerCount(): string {
        return this.value?.digitalTachometerCount?.toString() ?? '';
    }

    set digitalTachometerCount(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        if (_.isEmpty(newValue.trim())) {
            cloned.digitalTachometerCount = undefined;
        } else {
            const parsed = Util.toNumber(newValue);
            cloned.digitalTachometerCount = isNaN(parsed) ? undefined : parsed;
        }

        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: false,
            transform: (value: number | undefined): string | undefined =>
                value === undefined ? undefined : `${value}`,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, value: string | undefined, callback: Function) =>
                this.validate(value, callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Functions
    // ======================================================
    /**
     * バリデーションを行う。
     */
    private validate(value: string | undefined, callback: (message?: string) => void): void {
        if (value === undefined) {
            callback();
        } else if (value.length > Const.MAX_TRUCK_COUNT_DIGITS) {
            callback('デジタコ搭載数は0以上10万未満の数値で入力してください。');
        } else {
            callback();
        }
    }
}
