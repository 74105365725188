import _ from 'lodash';
import { WebStorageBase } from './web-storage.base';
import dayjs from 'dayjs';
import {
    BaggageRegisterFormModel,
    BaggageSearchExcludedCompany,
    BaggageSearchForm,
} from '@/vuex/modules/baggage/types';
import { usePagination } from '@/composables/global/pagination';

class LocalStorage extends WebStorageBase {
    constructor() {
        super(window.localStorage);
    }
}

const localStorage = new LocalStorage();

// composableに処理を委譲
const { state: { pageSize: composablePageSize }, clear } = usePagination();
export const pagination = {
    /**
     * ページサイズを取得します。
     */
    getPageSize: (): string | undefined => {
        if (_.isNil(composablePageSize.value)) {
            return undefined;
        }
        return composablePageSize.value.toString();
    },

    /**
     * ページサイズを設定します。
     */
    setPageSize(pageSize: string): void {
        composablePageSize.value = Number(pageSize);
    },

    /**
     * ページサイズ情報をクリアします。
     */
    clearPageSize(): void {
        clear();
    },
};

export const highContrastMode = {
    /**
     * ハイコントラストモードの設定状態を取得します。
     */
    getHighContrastMode: (): boolean => {
        return localStorage.getItem('highContrastMode') === 'true';
    },

    /**
     * ハイコントラストモードを設定します。
     */
    setHighContrastMode(isActive: boolean): void {
        return localStorage.setItem('highContrastMode', isActive ? 'true' : 'false');
    },
};

export const baggageSearchExcludedCompany = {
    /**
     * 荷物検索で非表示にする企業一覧を取得します
     */
    list(): Array<BaggageSearchExcludedCompany> {
        const item = localStorage.getItem('baggageSearchExcludedCompanies');
        const value = item ? JSON.parse(item) : [];
        return (_.isArray(value) ? value : []) as Array<BaggageSearchExcludedCompany>;
    },

    /**
     * 荷物検索で非表示にする企業を追加します
     */
    add(id: number, name: string): BaggageSearchExcludedCompany[] {
        const companies = this.list();
        companies.push({ id: id, name: name });
        const uniqCompanies = _.uniqBy(companies, 'id');
        localStorage.setItem('baggageSearchExcludedCompanies', JSON.stringify(uniqCompanies));
        return uniqCompanies;
    },

    /**
     * 荷物検索で非表示にする企業を削除します
     */
    remove(id: number): BaggageSearchExcludedCompany[] {
        const companies = this.list().filter(c => c.id !== id);
        localStorage.setItem('baggageSearchExcludedCompanies', JSON.stringify(companies));
        return companies;
    },
};

export const baggageRegisterForm = {
    /**
     * 取得します。
     */
    get: (): BaggageRegisterFormModel | undefined => {
        const value = localStorage.getItem('baggageRegisterForm');
        return value ? new BaggageRegisterFormModel(JSON.parse(value)) : undefined;
    },

    /**
     * 設定します。
     */
    set(formData: BaggageRegisterFormModel): void {
        return localStorage.setItem('baggageRegisterForm', JSON.stringify(formData));
    },

    /**
     * クリアします。
     */
    clear(): void {
        return localStorage.removeItem('baggageRegisterForm');
    },
};

/**
 * 荷物検索条件
 */
export const baggageSearchConditions = {
    get: (): BaggageSearchForm[] | undefined => {
        const sessionValue = sessionStorage.getItem('baggageSearchForms');
        if (sessionValue) return JSON.parse(sessionValue) as BaggageSearchForm[];

        const localValue = localStorage.getItem('baggageSearchForms');
        return localValue ? JSON.parse(localValue) as BaggageSearchForm[] : undefined;
    },

    set(conditions: BaggageSearchForm[]): void {
        sessionStorage.setItem('baggageSearchForms', JSON.stringify(conditions));
        localStorage.setItem('baggageSearchForms', JSON.stringify(conditions));
    },
};

/**
 * 最後に荷物検索した日時
 */
export const lastBaggageSearchDatetimes = {
    get: (): dayjs.Dayjs[] => {
        const json = localStorage.getItem('lastBaggageSearchDatetimes');
        if (!json) { return []; }
        const parsed = JSON.parse(json);
        return (_.isArray(parsed) ? parsed : []).map((value) => dayjs(value));
    },

    set(index: number, datetime: dayjs.Dayjs): void {
        const values = this.get();
        values[index] = datetime;
        localStorage.setItem('lastBaggageSearchDatetimes', JSON.stringify(values));
    }
};

/**
 * マイ荷物の検索フォームの表示状態
 */
export const myBaggageSearchFormVisibility = {
    get: (key: MyBaggageSearchFormVisibilityKey): boolean => {
        return localStorage.getItem(`myBaggageSearchFormVisibility.${key}`) === 'true';
    },

    set(key: MyBaggageSearchFormVisibilityKey, value: boolean): void {
        localStorage.setItem(`myBaggageSearchFormVisibility.${key}`, value ? 'true' : 'false');
    }
};

/**
 * マイ荷物の検索フォームの表示状態のキー
 * Opened - 募集中の荷物
 * Expired - 成約しなかった荷物
 */
export type MyBaggageSearchFormVisibilityKey = 'OPENED' | 'EXPIRED' | 'AGREED' | 'ACCEPTED';
