<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { Util } from '@/util';
import { BAGGAGE_TYPE_DATA, BaggageTypeData } from '@/components/UI/BaggageTypeInput/baggage-type-data';
import _ from 'lodash';

const props = withDefaults(defineProps<{
    value?: string,
    placeholder?: string,
}>(), {
    placeholder: '荷種'
});
const emits = defineEmits<{
    (e: 'input', value: string | undefined) : void,
    (e: 'change', value: string | undefined) : void,
    (e: 'blur'): void
}>();
const baggageType = computed({
    get: (): string => {
        return props.value ?? '';
    },
    set: (value: string) => {
        emits('input', Util.emptyStringToUndefined(value));
        emits('change', Util.emptyStringToUndefined(value));
    }
});
const dataSource = ref<Array<BaggageTypeData>>([]);

onBeforeMount(() => {
    if (_.isEmpty(props.value)) {
        dataSource.value = BAGGAGE_TYPE_DATA;
    }
});

const onSearch = (searchText: string) => {
    const value = searchText.trim();
    if (_.isEmpty(value)) {
        dataSource.value = BAGGAGE_TYPE_DATA;
        return;
    }
    dataSource.value = BAGGAGE_TYPE_DATA.map((category) => {
        return {
            title: category.title,
            items: category.items.filter((each) =>
                [each.value, ...(each.keywords ?? [])].some((keyword) => keyword.indexOf(value) >= 0)
            ),
        };
    }).filter((each) => each.items.length > 0);
};

const onFocus = () => {
    if (_.isEmpty(baggageType.value)) dataSource.value = BAGGAGE_TYPE_DATA;
};
</script>

<template>
    <a-auto-complete v-model="baggageType"
                     :data-source="dataSource"
                     :placeholder="placeholder"
                     @search="onSearch"
                     @focus="onFocus"
                     @blur="() => emits('blur')"
                     class="ui-baggage-input">
        <template #dataSource>
            <a-select-opt-group v-for="category in dataSource" :key="category.title">
                <template #label><span>{{ category.title }}</span></template>
                <a-select-option v-for="item in category.items" :key="`${category.title}_${item.value}`" :value="item.value">{{ item.value }}</a-select-option>
            </a-select-opt-group>
        </template>
    </a-auto-complete>

</template>

<style scoped lang="less">

</style>
