import { computed, onMounted, provide, ref } from 'vue';
import {
    EVENT_SCHEDULER_PROVIDER_KEY,
    useEventScheduleProvider
} from '@/_pages/Truck/Schedule/event-schedule-provider';
import {
    TimelineDateRangeType,
    TimelineEventType,
    TimelineSelectedCellType
} from '@/_components/ui/timeline-scheduler/timeline-scheduler-provider';
import { useRouting } from '@/composables/helper/routing';
import { useCompanyTruckEvent } from '@/composables/company-truck-event';
import { useBaggageSearch } from '@/composables/baggage-search';
import { PageInfo } from '@/_components/ui/types/page-info';
import { Const } from '@/const';
import { useCompanyProfileList } from '@/composables/company-profile-list';
import _ from 'lodash';
import { Baggage } from '@/models/baggage';
import { CompanyProfile } from '@/models/company';
import { useMyTruck } from '@/composables/truck';
import { Util } from '@/util';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { onBeforeRouteUpdate } from 'vue-router/composables';

export const useSchedulerHelper = (
    props: { eventId?: string }
) => {
    const createEventModalVisible = ref<boolean>(false);
    const editEventModalVisible = ref<boolean>(false);
    const { goToBaggageSearch } = useRouting();
    const { state: { companyTruckEvent }, load: loadCompanyTruckEvent } = useCompanyTruckEvent();
    const { state: { truck }, load: loadMyTruck } = useMyTruck();
    const baggageSearchListStore = useBaggageSearch();
    const { state: { baggageList, form: baggageSearchForm }, search: baggageSearch } = baggageSearchListStore;
    const {
        state: { list: companyProfileList, },
        load: loadCompanyProfileList,
    } = useCompanyProfileList();
    const loadingMatches = ref<boolean>(false);
    const routeUpdating = ref(false);

    const eventScheduleProvider = useEventScheduleProvider();
    provide(EVENT_SCHEDULER_PROVIDER_KEY, eventScheduleProvider);

    const {
        state: {
            loading,
            selectedDate,
            selectedCell,
            selectedEvent,
            resourceList,
            eventSettings,
        },
        refreshEventList,
        refreshResourceList,
    } = eventScheduleProvider;

    const { goToTruckScheduler, goToNotFound } = useRouting();

    const loadMatches = async (eventId: number) => {
        loadingMatches.value = true;
        await loadCompanyTruckEvent(eventId);

        await loadMyTruck(Util.requireNotNull(companyTruckEvent.value?.truckId));

        // 「他」は何もマッチしない
        if (truck.value?.truckModel === TruckModelEnum.Other) return;

        baggageSearchForm.value.applyTruck(Util.requireNotNull(truck.value));
        await baggageSearch();

        const companyIds = _.uniq(baggageList.value.data.map(baggage => baggage.companyId));
        await loadCompanyProfileList(companyIds);
        loadingMatches.value = false;
    };

    onMounted(async () => {
        // 初期読み込みはリソースのみをマウント時に行う。
        // イベントについては期間の取得のために、Schedule ComponentのToolbarレンダリング時にhookしてある。
        await Promise.all([
            refreshResourceList(),
            refreshEventList(),
        ]);

        if (props.eventId === undefined) return;
        try {
            await loadMatches(Number(props.eventId));
        } catch (e) {
            await goToNotFound();
        }
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (routeUpdating.value) {
            next(false);
            return;
        }

        if (to.query.eventId === undefined) {
            next();
            return;
        }

        if (to.query.eventId === from.query.eventId) {
            next();
            return;
        }

        routeUpdating.value = true;
        try {
            await loadMatches(Number(to.query.eventId));
        } catch (e) {
            await goToNotFound();
        } finally {
            routeUpdating.value = false;
        }

        next();
    });


    const matchingList = computed<{ baggage: Baggage, companyProfile?: CompanyProfile }[]>(() =>
        baggageList.value.data.map(baggage => {
            const companyProfile = companyProfileList.value.find(companyProfile => companyProfile.id === baggage.companyId);
            return { baggage, companyProfile: companyProfile };
        })
    );

    const eventMatchingDrawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && props.eventId !== undefined;
    });

    const pageInfo = computed<PageInfo>(() => {
        return {
            totalPageCount: baggageList.value.totalPageCount ?? 0,
            totalRecordCount: baggageList.value.totalRecordCount ?? 0,
            currentPage: baggageList.value.currentPageNumber ?? 1,
            currentPageSize: baggageList.value.pageSize ?? Const.DEFAULT_PAGE_SIZE,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });

    const onChangePage = async (param: {
        pageNo: number,
        pageSize: number,
        sortKey: string,
        sortOrder: 'ASC' | 'DESC'
    }): Promise<void> => {
        baggageSearchForm.value.pageNo = param.pageNo;
        baggageSearchForm.value.pageSize = param.pageSize;
        baggageSearchForm.value.sortKey = param.sortKey;
        baggageSearchForm.value.sortOrder = param.sortOrder;
        await loadMatches(Number(props.eventId));
    };

    // イベントモーダルを開く
    // TODO: 現在は、providerに保持しているデータによってモーダルが挙動を変える必要がある、
    //  将来的には、modalを作る際に挙動を決めれるように、動的にModalコンポーネントを作るようにする。
    const openCreateEventModal = () => {
        createEventModalVisible.value = true;
    };

    const openEditEventModal = () => {
        editEventModalVisible.value = true;
    };

    // セルの部分選択時
    const onSelect = (data: TimelineSelectedCellType) => {
        selectedCell.value = data;
        openCreateEventModal();
    };

    // セルのクリック時
    const onCellClick = (data: TimelineSelectedCellType) => {
        selectedCell.value = data;
        openCreateEventModal();
    };

    // イベント選択時
    const onEventClick = (event: TimelineEventType) => {
        selectedEvent.value = {
            Id: event.Id,
            StartTime: event.StartTime,
            EndTime: event.EndTime,
            Subject: event.Subject,
            Location: event.Location,
            EventType: event.EventType,
            Description: event.Description,
            CompanyTruckId: event.CompanyTruckId,
        } as TimelineEventType;
        openEditEventModal();
    };

    const onEventMatchingClick = async (eventId: number) => {
        await goToTruckScheduler(eventId).catch(() => {
        });
    };

    const onClickGround = async () => {
        if (eventMatchingDrawerVisibility.value) {
            await closeEventMatching();
        }
    };

    const onClickMatch = async (baggage: Baggage) => {
        await goToBaggageSearch(baggage.id);
    };

    const onEventModalOk = async () => {
        await refreshEventList();
        selectedEvent.value = undefined;
        selectedCell.value = undefined;
    };

    const onEventModalCancel = () => {
        selectedEvent.value = undefined;
        selectedCell.value = undefined;
    };

    const onDateChanged = async (event: TimelineDateRangeType) => {
        selectedDate.value = event.startDate.toDate();
        await refreshEventList();
    };

    const closeEventMatching = async () => {
        if (props?.eventId) {
            await goToTruckScheduler();
        }
    };

    return {
        loading,
        loadingMatches,
        selectedDate,
        selectedCell,
        selectedEvent,
        resourceList,
        matchingList,
        eventSettings,
        createEventModalVisible,
        editEventModalVisible,
        eventMatchingDrawerVisibility,
        pageInfo,
        onChangePage,
        onSelect,
        onEventClick,
        onEventMatchingClick,
        onClickGround,
        onClickMatch,
        onCellClick,
        onDateChanged,
        onEventModalOk,
        onEventModalCancel,
        closeEventMatching,
    };
};
