import { useLoading } from '@/composables/helper/loading-helper';
import { SettlementIncomeRegisterApiForm } from '@/models/settlement';
import { settlementApi } from '@/repository/api/internal/settlement';

export const useSettlementIncomeRegister = () => {
    const { state: { loading }, withLoading } = useLoading();

    const register = (form: SettlementIncomeRegisterApiForm) => withLoading(async () => {
        return await settlementApi.registerIncome(form);
    });

    return {
        state: {
            loading,
        },
        register,
    };
};
