import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/truck/types';
import { RegionUtil, Util } from '@/util';
// @ts-ignore
import UiDateTimeLocationLabel from '@/components/UI/DateTimeLocationLabel';
import { Enum } from '@/types/enum';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import { Const } from '@/const';

@Component({
    components: {
        UiDateTimeLocationLabel,
    },
})
export default class TruckListView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly loading: boolean;
    @Prop()
    declare readonly truckList?: types.TruckList;

    get truckListData(): types.Truck[] {
        return this.truckList?.data ?? [];
    }

    // ======================================================
    // Data
    // ======================================================
    columns = [
        {
            title: '担当者',
            key: 'staffName',
            scopedSlots: { customRender: 'staffName' },
            width: 92,
        },
        {
            title: '空車日時・空車地 / 行先日時・行先地',
            key: 'departureArrival',
            scopedSlots: { customRender: 'departureArrival' },
            align: 'center',
            width: 352,
        },
        {
            title: '運賃',
            key: 'freight',
            scopedSlots: { customRender: 'freight' },
            width: 112,
        },
        {
            title: '重量',
            key: 'truckWeight',
            scopedSlots: { customRender: 'truckWeight' },
            width: 88,
        },
        {
            title: '車種',
            key: 'truckModel',
            scopedSlots: { customRender: 'truckModel' },
            width: 108,
        },
        {
            title: '備考',
            key: 'description',
            scopedSlots: { customRender: 'description' },
        },
    ];
    pageSizeOptions = Const.PAGE_SIZE_OPTIONS;

    /**
     * 車種
     */
    truckModelText(record: types.Truck): string {
        return record.truckModel.label ?? '';
    }

    /**
     * 重量
     */
    truckWeightText(record: types.Truck): string {
        return record.truckWeight.label ?? '';
    }

    /**
     * 運賃
     */
    freightText(record: types.Truck): string {
        if (!record.freight) {
            return '要相談';
        }
        return `${Util.formatNumber(record.freight)}円`;
    }

    /**
     * 追加で設定された都道府県
     */
    additionalPrefectureText(additionalPrefecture: Array<Enum<PrefectureEnumCode>>): string {
        if (additionalPrefecture.length === 0) {
            return '';
        }
        const regions = RegionUtil.parseRegionsFromPrefectures(additionalPrefecture.map((each) => each.code));
        return `${regions.map((each) => each.label).join('、')}も対応可能`;
    }

    /**
     * ツールチップ表示用の都道府県テキストラベル
     */
    prefectureTooltipText(
        prefectureLabel: string,
        city: string | undefined,
        additionalPrefecture: Array<Enum<PrefectureEnumCode>>
    ): string {
        const cityText = city ?? '';
        const additionalPrefectureText = this.additionalPrefectureText(additionalPrefecture);
        if (!additionalPrefectureText) return '';
        return `${prefectureLabel}${cityText}の他、${additionalPrefectureText}`;
    }

    /**
     * ページネーションでページ番号 または 1ページあたり表示件数を変更した時に呼び出されます。
     */
    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('onChangePage', pageNo, pageSize);
    }
}
