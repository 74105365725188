import { DirectiveBinding } from 'vue';
import { useMicroCMS } from '@/composables/helper/microcms-helper';
import _ from 'lodash';

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    install(vue: any): void {
        const updateElement = (el: HTMLElement, bindings: DirectiveBinding<string>): void => {
            const { getData } = useMicroCMS();
            const contentName = bindings.arg;
            const key = bindings.value;
            if (!contentName || _.isEmpty(contentName) || !key || _.isEmpty(key)) {
                return;
            }

            const data = getData(contentName) ?? {};
            if (_.isEmpty(data)) {
                return;
            }

            const value = data[key] ?? '';
            if (_.isEmpty(bindings.modifiers)) {
                el.innerHTML = value;
            } else {
                Object.keys(bindings.modifiers ?? {}).forEach(attr => {
                    el.setAttribute(attr, value);
                });
            }
        };

        vue.directive('microcms-tag', {
            componentUpdated(el: HTMLElement, bindings: DirectiveBinding<string>) {
                updateElement(el, bindings);
            },
            bind(el: HTMLElement, bindings: DirectiveBinding<string>) {
                updateElement(el, bindings);
            },
        });
    }
};
