import { useLoading } from '@/composables/helper/loading-helper';
import { miscApi } from '@/repository/api/internal/misc';
import { PrefectureEnum } from '@/enums/prefecture.enum';

export const useLocation = () => {
    const { state: { loading }, withLoading } = useLoading();

    const zipToAddress = (zip: string) => withLoading(async () => {
        const result = await miscApi.zipToAddress(zip);
        const prefLabel = result?.address1 ?? '';
        const prefecture = PrefectureEnum.values.find((each) => each.label === prefLabel);
        const city = result?.address2 ?? '';
        const address = result?.address3 ?? '';
        return { prefecture, city, address };
    });

    return {
        state: {
            loading,
        },
        zipToAddress
    };
};
