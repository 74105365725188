import { ref } from 'vue';
import { FormModel } from 'ant-design-vue';
import { FormModelItem } from 'ant-design-vue/types/form-model/form-item';

export function useFormModel() {
    const formModel = ref<FormModel>();
    const submit = (callback: () => Promise<void>, failureCallback?: () => Promise<void>) => {
        formModel.value?.validate(async (result) => {
            if (result) {
                await callback();
            } else {
                if (failureCallback) await failureCallback();
            }
        });
    };
    return {
        formModel,
        submit,
    };
}

export function useFormModelItem() {
    const formModelItem = ref<FormModelItem>();
    const onChange = () => {
        // @ts-ignore
        formModelItem.value?.onFieldChange();
    };

    return {
        formModelItem,
        onChange,
    };
}
