import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';
import { Const } from '@/const';
import { AgreementUtil, DateUtil } from '@/util';

@Component
export default class AgreementPaymentDateEdit extends Vue {
    viewDateFormat = Const.DEFAULT_DATE_WITH_DAY_OF_WEEK_FORMAT;
    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule: ValidationRule, _value: string, callback: Function) =>
                this.validate(callback as (message?: string) => void),
        },
    ];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AgreementUpdateForm;
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly iAmTransporter?: boolean;

    /**
     * 入金予定日
     */
    get paymentDate(): string {
        return this.value?.paymentDate ?? '';
    }

    set paymentDate(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 入金予定日を書き換え
        cloned.paymentDate = newValue;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    // ======================================================
    // Functions
    // ======================================================
    /**
     * 選択できない日付であるか否かを取得します。
     * @param date
     */
    isDisabledDate(date: moment.Moment): boolean {
        if (!this.value) {
            return false;
        }
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(this.value.arrivalMax), false);
        return !DateUtil.isIncluded(dayjs.unix(date.unix()), range);
    }

    /**
     * 入金予定日が運賃全額保証期間外であるか否かを取得します。
     */
    get isPaymentDateOutOfGuaranteePeriod(): boolean {
        if (this.value && this.agreement && this.agreement.guarantee) {
            const arrivalDate = DateUtil.parseDatetimeText(this.value.arrivalMax);
            const paymentDate = DateUtil.parseDateText(this.value.paymentDate);
            const range = AgreementUtil.availablePaymentDateRange(arrivalDate, true);
            return !DateUtil.isIncluded(paymentDate, range);
        }
        return false;
    }

    /**
     * バリデーションを行う。
     */
    private validate(callback: (message?: string) => void): void {
        const arrivalDate = this.value?.arrivalMin ?? '';
        // 指定可能な入金予定日の範囲を取得する
        const range = AgreementUtil.availablePaymentDateRange(DateUtil.parseDatetimeText(arrivalDate), false);
        if (!this.paymentDate) {
            callback('日付を選択してください。');
        } else if (!DateUtil.isIncluded(DateUtil.parseDateText(this.paymentDate), range)) {
            const rangeText = `${range[0].format('YYYY年MM月DD日')}から${range[1].format('YYYY年MM月DD日')}`;
            callback(`入金予定日は${rangeText}の範囲で指定してください。`);
        } else {
            callback();
        }
    }
}
