import { notification } from 'ant-design-vue';
import { AxiosError, AxiosResponse } from 'axios';
import router from '@/router';

const httpErrorResponseInterceptor = (error: AxiosError & { response: AxiosResponse }): Promise<AxiosError> => {
    if (error.response.data.code == 'SESSION_EXPIRED') {
        // セッション切れのためログイン画面に遷移します
        return router.push({ name: 'Login', query: { expired: 'true' } }).then(() => Promise.reject(error));
    }
    // エラーメッセージを表示
    if (shouldShowErrorMessage(error)) {
        showErrorMessage(error.response);
    }
    return Promise.reject(error);
};

/**
 * エラーメッセージを表示するべきか否かを決定します。
 * @param error
 */
function shouldShowErrorMessage(error: AxiosError): boolean {
    // アカウント情報一覧APIで403レスポンス
    const isAccountProfileApiRequest = (error: AxiosError): boolean => {
        return (
            error.response?.status === 403 &&
            error.config.method === 'get' &&
            error.config.url === '/api/account/profile'
        );
    };
    return !isAccountProfileApiRequest(error);
}

/**
 * HTTPステータスをもとにエラーメッセージを表示します。
 * @param response
 */
function showErrorMessage(response: AxiosResponse): void {
    // 同じエラーメッセージを複数表示させないために、クローズ処理を入れます
    const notificationKey = response.data.error ? response.data.error : `${response.status}`;
    notification.close(notificationKey);

    switch (response.status) {
        case 403:
            notification.error({
                key: notificationKey,
                message: 'ページを表示する権限がありません。',
                description: '',
            });
            break;
    }
}

export default httpErrorResponseInterceptor;
