import _ from 'lodash';
import { Route } from 'vue-router/types/router';
import * as agreementTypes from '@/vuex/modules/agreement/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as guaranteeTypes from '@/vuex/modules/guarantee/types';
import store from '@/vuex/store';
import { DateUtil, Util } from '@/util';
import router from '@/router';
import * as baggageTypes from '@/vuex/modules/baggage/types';

// ======================================================
// Routing Helpers
// ======================================================
/**
 * 成約詳細画面へ遷移します。
 */
export const goToAgreementDetail = (route: Route, id: number): Promise<Route> =>
    router.push({ path: route.path, query: { agreementId: id.toString() } });

/**
 * Route情報から成約IDを抽出します。
 */
export const extractAgreementId = (route: Route): number | undefined => {
    const { agreementId } = route.query;

    if (typeof agreementId === 'string' && Util.isNumeric(agreementId)) {
        return Number(agreementId);
    }
};

// ======================================================
// Data Loading Helpers
// ======================================================
/**
 * 成約情報をロードします。
 */
export const loadAgreement = async (id: number): Promise<agreementTypes.Agreement> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_AGREEMENT }`, id);

/**
 * 成約情報をクリアします。
 */
export const clearAgreement = (): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_AGREEMENT }`);

/**
 * 受託成約一覧をロードします。
 */
export const loadAgreementAcceptedList = async (form: agreementTypes.AgreementListAcceptedForm): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.LIST_ACCEPTED_AGREEMENT }`, form);

/**
 * 受託成約を検索します。
 */
export const searchAgreementAccepted = async (form: agreementTypes.AgreementSearchAcceptedForm): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.SEARCH_ACCEPTED_AGREEMENT }`, form);

/**
 * 自社荷物成約一覧をロードします。
 */
export const loadAgreementAgreedList = async (form: agreementTypes.AgreementListForm): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.LIST_AGREEMENT }`, form);

/**
 * 自社荷物成約を検索します。
 */
export const searchAgreementAgreed = async (form: agreementTypes.AgreementSearchAgreedForm): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.SEARCH_AGREED_AGREEMENT }`, form);

/**
 * 荷物閲覧会員数をロードします。
 */
export const loadAgreementViewCountList = async (list: agreementTypes.Agreement[]): Promise<void> => {
    if (list.length === 0) return;
    const idList = list.map(each => each.baggageId);
    return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_VIEW_MEMBER_COUNT_LIST }`, idList);
};

/**
 * 成約変更リクエストをロードします。
 */
export const loadAgreementChangeRequest = async (agreementId: number): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_AGREEMENT_CHANGE_REQUEST }`, agreementId);

/**
 * 成約変更リクエストをクリアします。
 */
export const clearAgreementChangeRequest = (): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_AGREEMENT_CHANGE_REQUEST }`);

/**
 * 企業プロフィール一覧をロードします。
 */
export const loadCompanyProfileList = async (list: agreementTypes.Agreement[]): Promise<void> => {
    if (_.isEmpty(list)) return Promise.resolve();
    const form: companyTypes.CompanyPartnerProfileListForm = { id: list.map(each => each.id) };
    return store.dispatch(`company/${ companyTypes.ACTION.LIST_PARTNER_PROFILE }`, form);
};

/**
 * 企業プロフィールをロードします。
 */
export const loadCompanyProfile = async (agreement: agreementTypes.Agreement): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.LOAD_AGREEMENT_PARTNER_INFO }`, agreement.id);

/**
 * 企業プロフィールをクリアします。
 */
export const clearCompanyProfile = (): Promise<void> =>
    store.dispatch(`agreement/${ agreementTypes.ACTION.CLEAR_AGREEMENT_PARTNER_INFO }`);

/**
 * 過去の契約一覧をロードします。
 */
export const loadPastContracts = async (agreement: agreementTypes.Agreement): Promise<void> => {
    const entryTm = DateUtil.parseDatetimeText(agreement.entryTm);
    const payload = { year: entryTm.year(), month: entryTm.month() + 1 };
    return store.dispatch(`company/${ companyTypes.ACTION.LOAD_PAST_CONTRACTS }`, payload);
};

/**
 * 全期間のおまかせ請求契約一覧をロードします。
 */
export const loadEasyPaymentContracts = async (): Promise<void> => {
    return store.dispatch(`company/${ companyTypes.ACTION.LOAD_EASY_PAYMENT_CONTRACTS }`);
};

/**
 * 今月の運賃保証サービスの月間成約金額をロードします。
 */
export const loadGuaranteedAmount = async (): Promise<void> => {
    const today = DateUtil.now();
    const year = today.year();
    const month = today.month() + 1;
    return store.dispatch(`company/${ companyTypes.ACTION.LOAD_GUARANTEED_AMOUNT }`, { year, month });
};

/**
 * 運賃全額保証利用料金マスタをロードします。
 */
export const loadGuaranteePriceMaster = (): Promise<void> =>
    store.dispatch(`guarantee/${ guaranteeTypes.ACTION.LOAD_GUARANTEE_PRICE_MASTER }`);
