import { Enum } from '@/types/enum';
import { ExcludeMethods } from '@/types/lang';
import _ from 'lodash';
import { Const } from '@/const';
import { DateUtil, PhoneUtil, Util, ZipUtil } from '@/util';
import { PrefectureEnumCode } from '@/enums/prefecture.enum';
import * as questionnaireTypes from '@/models/questionnaire';
import { BankAccountTypeEnum, BankAccountTypeEnumCode } from '@/enums/bank-account-type.enum';
import { ProductCode, ProductEnum } from '@/enums/product.enum';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Validator } from '@/validator';
import { JsonUtil } from '@/util/json';
import { DateValue } from '@/models/vo/date';
import { AmountValue } from '@/models/vo/amount';
import { DateTimeValue } from '@/models/vo/datetime';
import { CapitalValue, MaxCapital } from '@/models/vo/capital';
import { MaxSales, SalesValue } from '@/models/vo/sales';
import { Circle } from '@/models/circle';

export * from '@/models/company-status';

export interface CompanyProfileName {
    kanji: string;
    kana?: string;
}

export interface CompanyProfilePhone {
    number: string;
    faxNumber: string;
}

export interface CompanyProfileLocation {
    prefecture: Enum;
    city: string;
    address: string;
}

export class CompanyProfileList {
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: CompanyProfile[];

    constructor(param: ExcludeMethods<CompanyProfileList>) {
        this.totalPageCount = param.totalPageCount;
        this.totalRecordCount = param.totalRecordCount;
        this.currentPageNumber = param.currentPageNumber;
        this.data = param.data;
    }
}

export class CompanyProfile {
    id: number;
    name: CompanyProfileName;
    phone: CompanyProfilePhone;
    zipCode: string;
    location: CompanyProfileLocation;
    truckCount: number;
    url?: string;
    description?: string;
    representativeName?: string;
    establishmentDate?: string;
    capital?: number;
    employeesNumber?: Enum;
    officeAddress?: string;
    sales?: number;
    bank?: string;
    customer?: string;
    salesArea?: string;
    cutOffDay?: { code: number; label: string };
    paymentMonth?: { code: number; label: string };
    paymentDay?: { code: number; label: string };
    officialCompanyId?: string;
    registrationDate: string;

    constructor(param: ExcludeMethods<CompanyProfile>) {
        this.id = param.id;
        this.name = param.name;
        this.phone = param.phone;
        this.zipCode = param.zipCode;
        this.location = param.location;
        this.truckCount = param.truckCount;
        this.url = param.url;
        this.description = param.description;
        this.representativeName = param.representativeName;
        this.establishmentDate = param.establishmentDate;
        this.capital = param.capital;
        this.employeesNumber = param.employeesNumber;
        this.officeAddress = param.officeAddress;
        this.sales = param.sales;
        this.bank = param.bank;
        this.customer = param.customer;
        this.salesArea = param.salesArea;
        this.cutOffDay = param.cutOffDay;
        this.paymentMonth = param.paymentMonth;
        this.paymentDay = param.paymentDay;
        this.officialCompanyId = param.officialCompanyId;
        this.registrationDate = param.registrationDate;
    }

    /**
     * 支払期間（締め日、支払月、支払日）が入力済みであるか否かを取得する。
     */
    get hasPaymentTerms(): boolean {
        return this.cutOffDay !== undefined && this.paymentMonth !== undefined && this.paymentDay !== undefined;
    }

    /**
     * 企業の名前
     */
    get companyName(): string {
        return this.name.kanji;
    }

    /**
     * 企業の連絡先電話番号
     */
    get companyPhone(): string {
        return PhoneUtil.format(this.phone.number ?? '');
    }

    /**
     * 企業の連絡先FAX番号
     */
    get companyFax(): string {
        return PhoneUtil.format(this.phone.faxNumber ?? '');
    }
}

export class CompanyMyProfile {
    id: number;
    name: CompanyProfileName;
    phone: CompanyProfilePhone;
    zipCode: string;
    location: CompanyProfileLocation;
    truckCount: number;
    url?: string;
    description?: string;
    representativeName?: string;
    establishmentDate?: string;
    capital?: number;
    employeesNumber?: Enum;
    officeAddress?: string;
    sales?: number;
    bank?: string;
    customer?: string;
    salesArea?: string;
    cutOffDay?: { code: number; label: string };
    paymentMonth?: { code: number; label: string };
    paymentDay?: { code: number; label: string };
    officialCompanyId?: string;
    registrationDate: string;
    circles: readonly Circle[];

    constructor(param: ExcludeMethods<CompanyMyProfile>) {
        this.id = param.id;
        this.name = param.name;
        this.phone = param.phone;
        this.zipCode = param.zipCode;
        this.location = param.location;
        this.truckCount = param.truckCount;
        this.url = param.url;
        this.description = param.description;
        this.representativeName = param.representativeName;
        this.establishmentDate = param.establishmentDate;
        this.capital = param.capital;
        this.employeesNumber = param.employeesNumber;
        this.officeAddress = param.officeAddress;
        this.sales = param.sales;
        this.bank = param.bank;
        this.customer = param.customer;
        this.salesArea = param.salesArea;
        this.cutOffDay = param.cutOffDay;
        this.paymentMonth = param.paymentMonth;
        this.paymentDay = param.paymentDay;
        this.officialCompanyId = param.officialCompanyId;
        this.registrationDate = param.registrationDate;
        this.circles = param.circles;
    }

    /**
     * 支払期間（締め日、支払月、支払日）が入力済みであるか否かを取得する。
     */
    get hasPaymentTerms(): boolean {
        return this.cutOffDay !== undefined && this.paymentMonth !== undefined && this.paymentDay !== undefined;
    }

    /**
     * 企業の名前
     */
    get companyName(): string {
        return this.name.kanji;
    }

    /**
     * 企業の連絡先電話番号
     */
    get companyPhone(): string {
        return PhoneUtil.format(this.phone.number ?? '');
    }

    /**
     * 企業の連絡先FAX番号
     */
    get companyFax(): string {
        return PhoneUtil.format(this.phone.faxNumber ?? '');
    }
}

export class OfficialCompany {
    id: number;
    name: string;
    prefecture: Enum;
    city: string;
    address: string;
    representativeName: string;
    phoneNumber: string;
    corporateNumber: string;
    taxCompanyId: string;
    taxCompanyIdTm: string;
    lastUpdateTm: string;

    constructor(param: ExcludeMethods<OfficialCompany>) {
        this.id = param.id;
        this.name = param.name;
        this.prefecture = param.prefecture;
        this.city = param.city;
        this.address = param.address;
        this.representativeName = param.representativeName;
        this.phoneNumber = param.phoneNumber;
        this.corporateNumber = param.corporateNumber;
        this.taxCompanyId = param.taxCompanyId;
        this.taxCompanyIdTm = param.taxCompanyIdTm;
        this.lastUpdateTm = param.lastUpdateTm;
    }
}

export interface CompanyContract {
    id: number;
    companyId: number;
    applicationId: number;
    memberId: number;
    product: Enum<ProductCode>;
    startTm: string;
    endTm: string;
    billingStart?: string;
    billingEnd?: string;
    billingPrice?: { price: number, taxPrice: number, total: number };
}

export interface CompanyProfileSearchForm {
    keyword: string;
    pref?: Enum;
    city?: string;
    pageNo?: number;
    pageSize?: number;
}

export class CompanyProfileSearchFormModel implements CompanyProfileSearchForm {
    keyword: string;
    pref?: Enum;
    _city?: string;
    pageNo?: number;
    pageSize?: number;

    constructor(param: Partial<CompanyProfileSearchForm>) {
        this.keyword = param.keyword ?? '';
        this.pref = param.pref;
        this._city = param.city;
        this.pageNo = param.pageNo ?? 1;
        this.pageSize = param.pageSize ?? 50;
    }

    get prefectureCode(): PrefectureEnumCode | undefined {
        return this.pref?.code as PrefectureEnumCode;
    }

    set prefectureCode(newValue: PrefectureEnumCode | undefined) {
        const changed = this.pref?.code !== newValue;

        this.pref = newValue ? { code: newValue } : undefined;
        this.city = changed ? '' : this.city;
    }

    get city(): string {
        return this._city ?? '';
    }

    set city(newValue: string) {
        this._city = newValue.substr(0, 200);
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

export interface CompanyProfileListForm {
    id: number[];
}

export interface CompanyPartnerProfileListForm {
    id: number[];
}

export class CompanyProfileUpdateForm implements FormValidatable<CompanyProfileUpdateForm> {
    static PREFIX_HTTP = 'http://';
    static PREFIX_HTTPS = 'https://';

    phone: { number: string; faxNumber: string };
    _zipCode: string;
    location: CompanyProfileLocation;
    _truckCount: string;
    url?: string;

    constructor(param: ExcludeMethods<Partial<CompanyProfileUpdateForm>>) {
        this.phone = {
            number: param.phone?.number ?? '',
            faxNumber: param.phone?.faxNumber ?? '',
        };
        this._zipCode = param.zipCode ?? '';
        this.location = {
            prefecture: param.location?.prefecture ?? { code: '' },
            city: param.location?.city ?? '',
            address: param.location?.address ?? '',
        };
        this._truckCount = param.truckCount ?? '';
        this.url = param.url;
    }


    get phoneNumber(): string {
        return this.phone.number;
    }

    set phoneNumber(value: string) {
        this.phone = {
            ...this.phone,
            number: Util.toDigits(value),
        };
    }

    get faxNumber(): string {
        return Util.toDigits(PhoneUtil.format(this.phone.faxNumber));
    }

    set faxNumber(value: string) {
        this.phone = {
            ...this.phone,
            faxNumber: Util.toDigits(value)
        };
    }

    get zipCode(): string {
        return this._zipCode;
    }

    set zipCode(value: string) {
        this._zipCode = Util.toDigits(value).substring(0, 7);
    }

    get prefecture(): PrefectureEnumCode {
        return this.location.prefecture.code as PrefectureEnumCode;
    }

    set prefecture(value: PrefectureEnumCode) {
        const changed = this.location.prefecture.code !== value;
        if (changed) {
            this.location = {
                prefecture: { code: value },
                city: '',
                address: '',
            };
        }
    }

    get city(): string {
        return this.location.city;
    }

    set city(value: string) {
        this.location = {
            ...this.location,
            city: value,
        };
    }

    get address(): string {
        return this.location.address;
    }

    set address(value: string) {
        this.location = {
            ...this.location,
            address: value,
        };
    }

    get truckCount(): string {
        return this._truckCount;
    }

    set truckCount(value: string) {
        if (_.isEmpty(value)) {
            this._truckCount = '';
        } else {
            this._truckCount = `${ Util.toNumber(value) }`;
        }
    }

    get urlScheme(): string {
        const isSecure = this.url?.startsWith(CompanyProfileUpdateForm.PREFIX_HTTPS) ?? true;
        return isSecure ? CompanyProfileUpdateForm.PREFIX_HTTPS : CompanyProfileUpdateForm.PREFIX_HTTP;
    }

    set urlScheme(value: string) {
        this.url = `${ value }${ this.urlPath }`;
    }

    get urlPath(): string {
        const urls = this.url?.split('://') ?? [];
        urls.shift();
        return urls.join('');
    }

    set urlPath(value: string) {
        const newUrl = value.trim().replace(CompanyProfileUpdateForm.PREFIX_HTTP, '').replace(CompanyProfileUpdateForm.PREFIX_HTTPS, '');
        this.url = _.isEmpty(newUrl) ? undefined : `${ this.urlScheme }${ newUrl }`;
    }

    public normalize(): void {
        this.phoneNumber = Util.toDigits(this.phoneNumber.trim());
        this.faxNumber = Util.toDigits(this.faxNumber.trim());
    }

    toJSON(): Record<string, unknown> {
        const cloned = _.cloneDeep(this);

        // FAX番号がEmptyの場合は、EMPTY_PHONE_NUMBERへ書き換える
        cloned.phone.faxNumber = ((value: string) =>
            _.isEmpty(value) ? Const.EMPTY_PHONE_NUMBER : value)(cloned.phone.faxNumber);
        // _から始まるプロパティを排除してJSONにする
        return JsonUtil.serialize(cloned);
    }

    validator(): FormValidator<CompanyProfileUpdateForm> {
        return {
            phoneNumber: [
                {
                    required: true,
                    whitespace: true,
                    message: '電話番号を入力してください。',
                },
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                }
            ],
            faxNumber: [
                {
                    required: true,
                    whitespace: true,
                    message: 'FAX番号を入力してください。',
                },
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `FAX番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: 'FAX番号を正しい形式で入力してください。',
                },
            ],
            location: [
                {
                    required: true,
                    validator: (_rule, value, callback) => {
                        if (!/[0-9]{7}/.test(this.zipCode)) return callback('郵便番号は7桁の数字を入力してください。');
                        if (_.isEmpty(this.location.prefecture.code)) return callback('都道府県を選択してください。');
                        if (_.isEmpty(this.location.city)) return callback('市区町村を入力してください。');
                        if (this.location.city.length > 200) return callback('市区町村は200文字以内で入力してください。');
                        if (_.isEmpty(this.location.address)) return callback('番地・建物を入力してください。');
                        if (this.location.address.length > 200) return callback('番地・建物は200文字以内で入力してください。');
                        callback();
                    },
                }
            ],
            truckCount: [
                {
                    required: true,
                    whitespace: true,
                    message: '保有台数を入力してください。',
                },
                {
                    pattern: /^[0-9]+$/,
                    message: '保有台数は数字で入力してください。',
                },
                {
                    max: 5,
                    message: '保有台数は最大5桁で入力してください。',
                },
            ],
            url: [
                {
                    required: false,
                    validator: (_rule, value, callback) => {
                        if (value === undefined) return callback();
                        const validated = Validator.validateUrl(value);
                        if (!validated.result) return callback(validated.message);
                        callback();
                    },
                }
            ]
        };
    }
}

export class CompanyProfileExtraUpdateForm implements FormValidatable<CompanyProfileExtraUpdateForm> {
    description?: string;
    representativeName?: string;
    establishmentDate?: string;
    capital?: number;
    employeesNumber?: { code: string };
    officeAddress?: string;
    sales?: number;
    bank?: string;
    customer?: string;
    salesArea?: string;
    cutOffDay?: { code: number };
    paymentMonth?: { code: number };
    paymentDay?: { code: number };

    constructor(param: ExcludeMethods<Partial<CompanyProfileExtraUpdateForm>>) {
        this.description = param.description;
        this.representativeName = param.representativeName;
        this.establishmentDate = param.establishmentDate;
        this.capital = param.capital;
        this.employeesNumber = param.employeesNumber;
        this.officeAddress = param.officeAddress;
        this.sales = param.sales;
        this.bank = param.bank;
        this.customer = param.customer;
        this.salesArea = param.salesArea;
        this.cutOffDay = param.cutOffDay;
        this.paymentMonth = param.paymentMonth;
        this.paymentDay = param.paymentDay;
    }

    get employeesNumberCode(): string {
        return this.employeesNumber?.code ?? '';
    }

    set employeesNumberCode(value: string) {
        this.employeesNumber = { code: value };
    }

    get cutOffDayCode(): number | undefined {
        return this.cutOffDay?.code;
    }

    set cutOffDayCode(value: number | undefined) {
        this.cutOffDay = _.isNumber(value) ? { code: value } : undefined;
    }

    get paymentDayCode(): number | undefined {
        return this.paymentDay?.code;
    }

    set paymentDayCode(value: number | undefined) {
        this.paymentDay = _.isNumber(value) ? { code: value } : undefined;
    }

    get paymentMonthCode(): number | undefined {
        return this.paymentMonth?.code;
    }

    set paymentMonthCode(value: number | undefined) {
        this.paymentMonth = _.isNumber(value) ? { code: value } : undefined;
    }

    get establishmentDateAsDate(): DateValue | undefined {
        return this.establishmentDate ? new DateValue(this.establishmentDate) : undefined;
    }

    set establishmentDateAsDate(value: DateValue | undefined) {
        this.establishmentDate = value?.format('YYYY-MM-DD');
    }

    get capitalText(): string {
        const current = this.capital;
        if (current === undefined) {
            return '';
        } else {
            return (current / 10000).toString();
        }
    }

    set capitalText(newValue: string) {
        if (_.isEmpty(newValue.trim())) {
            this.capital = undefined;
        } else {
            const parsed = Util.toNumber(newValue);
            this.capital = new CapitalValue(parsed * 10000).value;
        }
    }

    get salesText(): string {
        const current = this.sales;
        if (current === undefined) {
            return '';
        } else {
            return (current / 10000).toString();
        }
    }

    set salesText(newValue: string) {
        if (_.isEmpty(newValue.trim())) {
            this.sales = undefined;
        } else {
            const parsed = Util.toNumber(newValue);
            this.sales = new SalesValue(parsed * 10000).value;
        }
    }

    public normalize(): void {
        this.description = this.description?.trim();
        this.representativeName = this.representativeName?.trim();
        this.officeAddress = this.officeAddress?.trim();
        this.bank = this.bank?.trim();
        this.customer = this.customer?.trim();
        this.salesArea = this.salesArea?.trim();
    }

    validator(): FormValidator<CompanyProfileExtraUpdateForm> {
        return {
            description: [{ max: 20000, message: '20,000文字以内で入力してください。' }],
            representativeName: [{ max: 200, message: '200文字以内で入力してください。' }],
            establishmentDate: [{ pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, message: '設立の年月を選択しなおしてください。' }],
            capital: [
                {
                    required: false,
                    validator: (_rule, value, callback: Function) => {
                        if (value === undefined) {
                            callback();
                        } else if (value <= 0) {
                            callback('資本金は1万円以上で入力してください。');
                        } else if (value > MaxCapital) {
                            callback('資本金は10兆円以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                },
            ],
            officeAddress: [{ max: 200, message: '200文字以内で入力してください。' }],
            sales: [
                {
                    required: false,
                    validator: (_rule, value, callback: Function) => {
                        if (value === undefined) {
                            callback();
                        } else if (value <= 0) {
                            callback('売上は1万円以上で入力してください。');
                        } else if (value > MaxSales) {
                            callback('売上は10兆円以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                },
            ],
            bank: [{ max: 100, message: '100文字以内で入力してください。' }],
            customer: [{ max: 100, message: '100文字以内で入力してください。' }],
            salesArea: [{ max: 200, message: '200文字以内で入力してください。' }],
        };
    }
}

export interface CompanyStaffNameSuggestionRegisterForm {
    staffName?: string;
}

export interface CompanyStaffNameSuggestionDeleteForm {
    staffName: string;
}

export interface CompanyDispatchHistoryRegisterForm {
    carNumber?: string;
    driverName?: string;
}

export interface CompanyConfidence {
    id: number;
    unionName?: string;
    mlitGrantNumber?: string;
    digitalTachometerCount?: number;
    gpsCount?: number;
    goodMark: boolean;
    greenManagement: boolean;
    iso9000: boolean;
    iso14000: boolean;
    iso39001: boolean;
    insuranceName?: string;
}

/**
 * 企業統計
 */
export interface CompanyStatistics {
    pastMonthBaggageCount: number;
    pastThreeMonthsBaggageCount: number;
    pastMonthTruckCount: number;
    pastThreeMonthsTruckCount: number;
    agreementAggregation?: CompanyAgreementAggregation;
}

/**
 * 企業成約実績都道県別データ
 */
export interface CompanyAgreementAggregationPrefectureData {
    baggageAgreementCount: number;
    truckAgreementCount: number;
}

/**
 * 企業成約実績
 */
export interface CompanyAgreementAggregation {
    aggregationPeriod: { start: string, end: string },
    totalBaggageCount: number;
    totalBaggageAgreementCount: number;
    totalTruckAgreementCount: number;
    prefectures: { [code in PrefectureEnumCode]: CompanyAgreementAggregationPrefectureData };
}

export interface CompanyConfidenceUpdateForm {
    unionName?: string;
    mlitGrantNumber?: string;
    digitalTachometerCount?: number;
    gpsCount?: number;
    goodMark: boolean;
    greenManagement: boolean;
    iso9000: boolean;
    iso14000: boolean;
    iso39001: boolean;
    insuranceName?: string;
}

export class CompanyConfidenceUpdateFormModel implements CompanyConfidenceUpdateForm, FormValidatable<CompanyConfidenceUpdateFormModel> {
    unionName?: string;
    mlitGrantNumber?: string;
    private _digitalTachometerCount?: number;
    private _gpsCount?: number;
    goodMark: boolean;
    greenManagement: boolean;
    iso9000: boolean;
    iso14000: boolean;
    iso39001: boolean;
    insuranceName?: string;

    constructor(param: Partial<CompanyConfidence> | null = null) {
        this.unionName = param?.unionName;
        this.mlitGrantNumber = param?.mlitGrantNumber;
        this.digitalTachometerCount = param?.digitalTachometerCount;
        this.gpsCount = param?.gpsCount;
        this.goodMark = param?.goodMark ?? false;
        this.greenManagement = param?.greenManagement ?? false;
        this.iso9000 = param?.iso9000 ?? false;
        this.iso14000 = param?.iso14000 ?? false;
        this.iso39001 = param?.iso39001 ?? false;
        this.insuranceName = param?.insuranceName;
    }

    get digitalTachometerCount(): number | undefined {
        return this._digitalTachometerCount;
    }

    set digitalTachometerCount(value: number | string | undefined) {
        if (_.isNil(value)) {
            this._digitalTachometerCount = undefined;
        } else {
            this._digitalTachometerCount = Util.toNumber(`${ value }`);
        }
    }

    get gpsCount(): number | undefined {
        return this._gpsCount;
    }

    set gpsCount(value: number | string | undefined) {
        if (_.isNil(value)) {
            this._gpsCount = undefined;
        } else {
            this._gpsCount = Util.toNumber(`${ value }`);
        }
    }

    public normalize(): void {
        this.unionName = this.unionName?.trim();
        this.mlitGrantNumber = this.mlitGrantNumber?.trim();
        this.insuranceName = this.insuranceName?.trim();
    }

    toJSON(): Record<string, unknown> {
        return JsonUtil.serialize(this);
    }

    validator(): FormValidator<CompanyConfidenceUpdateFormModel> {
        return {
            unionName: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                },
            ],
            mlitGrantNumber: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                },
            ],
            digitalTachometerCount: [
                {
                    validator: (rule, value, callback) => {
                        if (_.isNil(value)) return callback();

                        if (value > 99999) {
                            callback('デジタコ搭載数は0以上10万未満の数値で入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
            gpsCount: [
                {
                    validator: (rule, value, callback) => {
                        if (_.isNil(value)) return callback();

                        if (value > 99999) {
                            callback('GPS搭載数は0以上10万未満の数値で入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
            insuranceName: [
                {
                    max: 200,
                    message: '200文字以内で入力してください。',
                    transform: (value: string | undefined): string => value?.trim() ?? '',
                }
            ],
        };
    }
}

export type CompanyPaymentMethod = 'BANK' | 'NTTE' | 'NTTF'; // 支払い方法
export interface CompanyPayment {
    id: number;
    method: Enum<CompanyPaymentMethod>;
    nttName?: string;
    nttAgreedName?: string;
    nttPhoneNumber?: string;
    nttFinanceCode?: string;
    name?: string;
    companyName?: { kanji: string };
    zipCode?: string;
    prefecture?: Enum;
    city?: string;
    address?: string;
    phoneNumber?: string;
    faxNumber?: string;
    transferAccountNumber: string;
}

export class CompanyPaymentModel {
    id: number;
    method: Enum<CompanyPaymentMethod>;
    nttName?: string;
    nttAgreedName?: string;
    nttPhoneNumber?: string;
    nttFinanceCode?: string;
    name?: string;
    companyName?: { kanji: string };
    zipCode?: string;
    prefecture?: Enum;
    city?: string;
    address?: string;
    phoneNumber?: string;
    faxNumber?: string;
    transferAccountNumber: string;

    constructor(param: CompanyPayment) {
        this.id = param.id;
        this.method = param.method;
        this.nttName = param.nttName;
        this.nttAgreedName = param.nttAgreedName;
        this.nttPhoneNumber = param.nttPhoneNumber;
        this.nttFinanceCode = param.nttFinanceCode;
        this.name = param.name;
        this.companyName = param.companyName;
        this.zipCode = param.zipCode;
        this.prefecture = param.prefecture;
        this.city = param.city;
        this.address = param.address;
        this.phoneNumber = param.phoneNumber;
        this.faxNumber = param.faxNumber;
        this.transferAccountNumber = param.transferAccountNumber;
    }

    /**
     * 銀行振り込みを利用しているか否かを取得します。
     */
    get isBanking(): boolean {
        return this.method.code === 'BANK';
    }

    /**
     * 旧式のNTT請求を利用しているか否かを取得します。
     */
    get isOldNtt(): boolean {
        return this.method.code === 'NTTE';
    }

    /**
     * 新式のNTTファイナンスを利用しているか否かを取得します。
     */
    get isNewNtt(): boolean {
        return this.method.code === 'NTTF';
    }

    /**
     * 課金対象電話番号を取得します。
     */
    get nttPhoneNumberText(): string {
        return PhoneUtil.format(this.nttPhoneNumber ?? '');
    }

    /**
     * 請求書郵便番号を取得します。
     */
    get zipCodeText(): string {
        return ZipUtil.format(this.zipCode ?? '');
    }
}

export interface CompanyPremiumRegisterForm {
    plan: Enum;
    invoice: Enum;
}

export interface CompanyDeleteForm {
    questionnaire: questionnaireTypes.QuestionItemAnswer[];
}

/**
 * プレミアムプラン停止フォーム
 */
export interface CompanyPremiumRevokeForm {
    questionnaireAnswer: questionnaireTypes.QuestionItemAnswer[];
}

export interface CompanyBillingTerm {
    min: number;
    max: number;
}

export interface CompanyBilling {
    id: number;
    year: number;
    month: number;
    product: Enum<ProductCode>;
    price: {
        price: number;
        taxPrice: number;
    };
    issueTm: string;
}

export class CompanyBillingModel {
    id: number;
    year: number;
    month: number;
    product: ProductEnum;
    price: {
        price: AmountValue;
        taxPrice: AmountValue;
    };
    issueTm: DateValue;

    constructor(param: CompanyBilling) {
        this.id = param.id;
        this.year = param.year;
        this.month = param.month;
        this.product = Util.requireNotNull(ProductEnum.valueOf(param.product.code));
        this.price = {
            price: new AmountValue(param.price.price),
            taxPrice: new AmountValue(param.price.taxPrice),
        };
        this.issueTm = new DateValue(DateUtil.parseDatetimeText(param.issueTm));
    }
}

export class CompanyBillingMonthlyModel {
    year: number;
    month: number;
    total: AmountValue;
    list: CompanyBillingModel[];

    private constructor(year: number, month: number, param: CompanyBilling[]) {
        this.year = year;
        this.month = month;
        this.total = new AmountValue(_.sumBy(param, each => each.price.price + each.price.taxPrice));
        this.list = param.map(each => new CompanyBillingModel(each));
    }

    static create(param: CompanyBilling[]): CompanyBillingMonthlyModel[] {
        const toKey = (model: CompanyBilling) => `${ model.year }${ model.month.toString().padStart(2, '0') }`;

        const grouped = _.groupBy(param, each => toKey(each));

        return Object.values(grouped).reverse().map(each => {
            const { year, month } = each[0];
            return new CompanyBillingMonthlyModel(year, month, each);
        });
    }
}

export interface CompanyInvoice {
    id: number;
    year: number;
    month: number;
    issued: boolean;
}

export interface CompanyGuaranteedAmount {
    id: number;
    year: number;
    month: number;
    amount: number;
    updateTm: string;
}

export class CompanyGuaranteedAmountModel {
    id: number;
    year: number;
    month: number;
    amount: AmountValue;
    updateTm: DateTimeValue;

    constructor(param: CompanyGuaranteedAmount) {
        this.id = param.id;
        this.year = param.year;
        this.month = param.month;
        this.amount = new AmountValue(param.amount);
        this.updateTm = new DateTimeValue(DateUtil.parseDatetimeText(param.updateTm));
    }

    get summary(): string {
        return `${ this.year }年${ this.month }月 月間成約金額 ${ this.amount.format() }（税別）`;
    }
}

export interface CompanyStaffNameSuggestionList {
    staffNames: string[];
}

export interface CompanyDispatchedTruckList {
    carNumbers: string[];
}

export interface CompanyDispatchedDriverList {
    driverNames: string[];
}

export class CompanyTransfer {
    id: number;
    bankName: string;
    branchName: string;
    type: Enum<BankAccountTypeEnumCode>;
    accountNumber: string;
    accountHolder: string;
    companyName?: string;
    staffName?: string;
    zipCode?: string;
    prefecture?: Enum<PrefectureEnumCode>;
    city?: string;
    address?: string;
    phoneNumber?: string;
    faxNumber?: string;

    constructor(param: ExcludeMethods<CompanyTransfer>) {
        this.id = param.id;
        this.bankName = param.bankName;
        this.branchName = param.branchName;
        this.type = param.type;
        this.accountNumber = param.accountNumber;
        this.accountHolder = param.accountHolder;
        this.companyName = param.companyName;
        this.staffName = param.staffName;
        this.zipCode = param.zipCode;
        this.prefecture = param.prefecture;
        this.city = param.city;
        this.address = param.address;
        this.phoneNumber = param.phoneNumber;
        this.faxNumber = param.faxNumber;
    }
}

export interface CompanyTransferUpdateForm {
    bankName: string;
    branchName: string;
    type: Enum<BankAccountTypeEnumCode>;
    accountNumber: string;
    accountHolder: string;
    companyName?: string;
    staffName?: string;
    zipCode?: string;
    prefecture?: { code: string };
    city?: string;
    address?: string;
    phoneNumber?: string;
    faxNumber?: string;
}

export class CompanyTransferUpdateFormModel implements FormValidatable<CompanyTransferUpdateFormModel> {
    bankName: string;
    branchName: string;
    type: Enum<BankAccountTypeEnumCode>;
    private _accountNumber: string;
    private _accountHolder: string;
    private _companyName?: string;
    private _staffName?: string;
    private _zipCode?: string;
    prefecture?: { code: string };
    private _city?: string;
    private _address?: string;
    private _phoneNumber?: string;
    private _faxNumber?: string;

    constructor(param: Partial<CompanyTransfer | null> = null) {
        this.bankName = param?.bankName ?? '';
        this.branchName = param?.branchName ?? '';
        this.type = param?.type ?? BankAccountTypeEnum.Mixture;
        this._accountNumber = param?.accountNumber ?? '';
        this._accountHolder = param?.accountHolder ?? '';
        this.companyName = param?.companyName;
        this.staffName = param?.staffName;
        this.zipCode = param?.zipCode;
        this.prefecture = param?.prefecture;
        this.city = param?.city;
        this.address = param?.address;
        this.phoneNumber = param?.phoneNumber;
        this.faxNumber = param?.faxNumber;
    }

    get bank(): string {
        return this.bankName ?? '';
    }

    set bank(value: string) {
        this.bankName = value.trim();
    }

    get bankAccountType(): string {
        return this.type.code ?? '';
    }

    set bankAccountType(value: string) {
        const val = BankAccountTypeEnum.valueOf(value);
        if (val) this.type = val;
    }

    get accountNumber(): string {
        return this._accountNumber ?? '';
    }

    set accountNumber(value: string) {
        this._accountNumber = Util.toDigits(value.trim()).substr(0, 7);
    }

    get accountHolder(): string {
        return this._accountHolder ?? '';
    }

    set accountHolder(value: string) {
        this._accountHolder = value.trim();
    }

    get companyName(): string | undefined {
        return this._companyName;
    }

    set companyName(value: string | undefined) {
        this._companyName = Util.emptyStringToUndefined(value ?? '');
    }

    get staffName(): string | undefined {
        return this._staffName;
    }

    set staffName(value: string | undefined) {
        this._staffName = Util.emptyStringToUndefined(value ?? '');
    }

    get zipCode(): string | undefined {
        return this._zipCode;
    }

    set zipCode(value: string | undefined) {
        this._zipCode = Util.emptyStringToUndefined(Util.toDigits(value ?? '').substr(0, 7));
    }

    // validator用に定義
    get location(): any {
        return {
            prefecture: this.prefecture,
            city: this.city,
            address: this.address
        };
    }

    get prefectureCode(): PrefectureEnumCode | undefined {
        return this.prefecture?.code as PrefectureEnumCode;
    }

    set prefectureCode(value: PrefectureEnumCode | undefined) {
        const changed = this.prefectureCode !== value;
        this.prefecture = value ? { code: value } : undefined;
        if (changed) {
            this.city = undefined;
            this.address = undefined;
        }
    }

    get city(): string | undefined {
        return this._city;
    }

    set city(value: string | undefined) {
        this._city = Util.emptyStringToUndefined(value ?? '');
    }

    get address(): string | undefined {
        return this._address;
    }

    set address(value: string | undefined) {
        this._address = Util.emptyStringToUndefined(value ?? '');
    }

    get phoneNumber(): string | undefined {
        return this._phoneNumber;
    }

    set phoneNumber(value: string | undefined) {
        this._phoneNumber = Util.emptyStringToUndefined(Util.toDigits(value?.trim() ?? ''));
    }

    get faxNumber(): string | undefined {
        return this._faxNumber;
    }

    set faxNumber(value: string | undefined) {
        this._faxNumber = Util.emptyStringToUndefined(Util.toDigits(value?.trim() ?? ''));
    }

    isCompletedPaymentNoticeSettings(): boolean {
        // 支払先通知情報項目
        const paymentNoticeFields = [
            this.companyName,
            this.staffName,
            this.zipCode,
            this.prefectureCode,
            this.city,
            this.address,
            this.phoneNumber,
            this.faxNumber
        ];
        return paymentNoticeFields.every(field => !_.isEmpty(field));
    }

    validator(): FormValidator<CompanyTransferUpdateFormModel> {
        return {
            bank: [{
                required: true,
                validator: (_rule, value, callback: ((message?: string) => void)) => {
                    if (!this.bankName) {
                        callback('銀行を入力してください。');
                    } else if ((this.bankName ?? '').trim().length > 100) {
                        callback('100文字以内で入力してください。');
                    } else {
                        callback();
                    }
                },
            }],
            branchName: [{
                required: true,
                validator: (_rule, _value, callback: ((message?: string) => void)) => {
                    if (!this.branchName) {
                        callback('支店名を入力してください。');
                    } else if ((this.branchName ?? '').trim().length > 100) {
                        callback('100文字以内で入力してください。');
                    } else {
                        callback();
                    }
                },
            }],
            bankAccountType: [{
                required: true,
                validator: (_rule, _value, callback: ((message?: string) => void)) => {
                    if (!this.type.code) {
                        callback('口座種別を選択してください。');
                    } else {
                        callback();
                    }
                },
            }],
            accountNumber: [{
                required: true,
                validator: (_rule, _value, callback: ((message?: string) => void)) => {
                    if (!this.accountNumber) {
                        callback('口座番号を入力してください。');
                    } else if ((this.accountNumber ?? '').trim().length !== 7) {
                        callback('7桁で入力してください。');
                    } else {
                        callback();
                    }
                },
            }],
            accountHolder: [{
                required: true,
                validator: (_rule, _value, callback: ((message?: string) => void)) => {
                    if (!this.accountHolder) {
                        callback('口座名義を入力してください。');
                    } else {
                        const result = Validator.validateAccountHolder(this.accountHolder ?? '');
                        if (!result.result) {
                            callback(result.message);
                        } else {
                            const normalizedLength = this.accountHolder?.normalize('NFD').length ?? 0;
                            if (normalizedLength > 48) {
                                callback(`濁点・半濁点・記号を1文字とした${ 48 }文字以内で入力してください。`);
                            }
                            callback();
                        }
                    }
                },
            }],
            companyName: [{
                max: 200,
                message: '200文字以内で入力してください。',
            }],
            staffName: [{
                max: 200,
                message: '200文字以内で入力してください。',
            }],
            location: [{
                validator: (_rule, _value, callback: ((message?: string) => void)) => {
                    if (!_.isEmpty(this.zipCode) && !/[0-9]{7}/.test(this.zipCode ?? '')) return callback('郵便番号は7桁の数字を入力してください。');
                    if ((this.city ?? '').length > 200) return callback('市区町村は200文字以内で入力してください。');
                    if ((this.address ?? '').length > 200) return callback('番地・建物は200文字以内で入力してください。');
                    callback();
                },
            }],
            phoneNumber: [
                {
                    transform: (): string => Util.toDigits(this.phoneNumber ?? ''),
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    transform: (): string => Util.toDigits(this.phoneNumber ?? ''),
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
            faxNumber: [
                {
                    transform: (): string => Util.toDigits(this.faxNumber ?? ''),
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `FAX番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    transform: (): string => Util.toDigits(this.faxNumber ?? ''),
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: 'FAX番号を正しい形式で入力してください。',
                },
            ]
        };
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

export interface CompanyApplication {
    id: number;
    companyId: number;
    company?: CompanyProfile;
    memberId: number;
    status: Enum;
    product: Enum;
    entryDatetime: string;
}
