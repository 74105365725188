import { FormModel, Modal } from 'ant-design-vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as questionnaireTypes from '@/vuex/modules/questionnaire/types';
// @ts-ignore
import QuestionnaireForm from '@/components/Questionnaire/QuestionnaireForm';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { ModalUtil, QuestionnaireUtil } from '@/util';
import type { QuestionItemAnswerValue } from '@/components/Questionnaire/QuestionnaireForm/script';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { CompanyContractListModel } from '@/vuex/modules/company/company-contract-list.model';
import { Const } from '@/const';

const accountMod = namespace('account');
const companyMod = namespace('company');
const questionnaireMod = namespace('questionnaire');

@Component({
    title: '退会',
    components: {
        QuestionnaireForm,
    },
    beforeRouteEnter: UnSubscribeEditPage.beforeRouteEnter,
})
export default class UnSubscribeEditPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.CONTRACT_LIST)
    readonly CONTRACT_LIST?: CompanyContractListModel;
    @questionnaireMod.Getter(questionnaireTypes.GETTER.QUESTIONNAIRE)
    readonly QUESTIONNAIRE?: questionnaireTypes.Questionnaire;

    // ======================================================
    // Data
    // ======================================================
    appServiceName = Const.APP_SERVICE_NAME;
    formModel = {
        answers: [] as QuestionItemAnswerValue[],
    };
    loading = false;
    dirty = false;

    // ======================================================
    // Properties
    // ======================================================
    /**
     * 退会可能かどうかを取得します。
     */
    get canUnsubscribe(): boolean {
        if (!this.ACCOUNT_PROFILE || !this.CONTRACT_LIST) {
            return false;
        }
        return this.ACCOUNT_PROFILE.isMainAccount && !this.CONTRACT_LIST.existsPremiumPlanX;
    }

    /**
     * 設問を取得します。
     */
    get questions(): questionnaireTypes.QuestionItem[] {
        return this.QUESTIONNAIRE?.items ?? [];
    }

    /**
     * 回答を取得します。
     */
    get answers(): QuestionItemAnswerValue[] {
        return this.formModel.answers;
    }

    /**
     * 回答を設定します。
     */
    set answers(value: QuestionItemAnswerValue[]) {
        this.formModel.answers = value;
        this.dirty = true;
    }

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        this.formModel = {
            answers: this.questions.map(QuestionnaireUtil.createAnswerForm),
        };
    }

    /**
     * 「戻る」ボタンを押下した際に呼び出されます。
     */
    onClickBack(): Promise<Route> {
        return this.$router.push({ name: 'Unsubscribe' });
    }

    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    async onClickConfirm(): Promise<void> {
        const formModel = this.$refs.formModel as FormModel;
        const result = await new Promise((resolve) => formModel.validate((result) => resolve(result)));
        if (!result) {
            Modal.error({
                title: 'アンケートの中で正しく入力できていない項目があります。',
                content: '恐れ入りますが、アンケートの回答を今一度お確かめください。',
            });
            return;
        }
        Modal.confirm({
            width: 640,
            title: `${this.appServiceName}の退会手続きを実行します。よろしいですか？`,
            content: 'この手続きは取り消しできません。今一度ご確認ください。',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: `${this.appServiceName}を退会する`,
            okType: 'danger',
            cancelText: 'キャンセル',
            autoFocusButton: 'cancel',
            onOk: () => this.onSubmit(),
        });
    }

    /**
     * 退会ボタンを押下した際に呼び出される。
     */
    onSubmit(): Promise<Route | void> {
        const notifyFailedKey = 'DELETE_COMPANY_FAILED';

        const onSuccess = () => {
            this.dirty = false;
            return this.$router.push({ name: 'UnsubscribeComplete' });
        };
        const onError = () => {
            this.$notification.error({
                key: notifyFailedKey,
                message: '退会手続きができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        };

        this.loading = true;
        this.$notification.close(notifyFailedKey);

        const form: companyTypes.CompanyDeleteForm = {
            questionnaire: this.questions.map((each: questionnaireTypes.QuestionItem, index: number) => {
                const answer = this.answers[index];
                return {
                    id: each.id,
                    type: each.type,
                    answer: Array.isArray(answer) ? answer.filter((each) => each !== undefined) : answer,
                } as questionnaireTypes.QuestionItemAnswer;
            }),
        };
        return UnSubscribeEditPage.deleteCompany(form)
            .then(onSuccess)
            .catch(onError)
            .finally(() => (this.loading = false));
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<UnSubscribeEditPage>
    ): Promise<void> {
        const onSuccess = () => next();
        const onError = () => next({ name: 'NotFound' });
        await UnSubscribeEditPage.loadQuestionnaire().then(onSuccess).catch(onError);
    }

    async beforeRouteLeave(_to: VueRoute, _: VueRoute, next: NavigationGuardNext<UnSubscribeEditPage>): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    }

    /**
     * アンケート設問を取得します。
     */
    private static loadQuestionnaire(): Promise<void> {
        return store.dispatch(`questionnaire/${questionnaireTypes.ACTION.LOAD_QUESTIONNAIRE}`, 'DEACTIVATE_COMPANY');
    }

    /**
     * 退会します。
     */
    private static deleteCompany(param: companyTypes.CompanyDeleteForm): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.DELETE}`, param);
    }
}
