import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import type Vue from 'vue';
import { useBaggageList } from '@/composables/baggage-list';
import { useMyBaggageSearchFormVisibility } from '@/composables/my-baggage-search-form';
import { useBaggageCancel } from '@/composables/baggage-cancel';
import { useMessage } from '@/composables/helper/page-helper';
import { useRouting, GoToOpenedMyBaggageDetailOptions } from '@/composables/helper/routing';
import { useBaggageMarkNegotiation } from '@/composables/baggage-negotiation';
import { BaggageSortKeyCode } from '@/const';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router/composables';
import _ from 'lodash';
import { useTimer } from '@/composables/helper/time-helper';
import { useRouteExtension } from '@/composables/helper/route-extension';
import { useNotificationExtension } from '@/composables/helper/notification-extension';
import { useMyBaggage } from '@/composables/my-baggage';
import { BaggageDetailTabKey } from '@/_pages/Baggage/List/drawers/BaggageDetail/model';
import { useLoading } from '@/composables/helper/loading-helper';
import { useMyBaggageTruckRecommendation } from '@/composables/my-baggage-truck-recommendation';
import { useMyBaggageExtension } from '@/composables/my-baggage-extension';
import { Karte } from '@/karte';
import { useNegotiationList } from '@/composables/negotiation-list';
import { gtm } from '@/gtm';
import type { DetailProps } from './props';
import { isLeavingEditor } from './props';
import { PageUtil, Util } from '@/util';
import { useConfirmLeavingPage } from '@/composables/helper/modal-extension';
import { useCircleCompanyList } from '@/composables/circle-company';
import { goToTraboxBaggageRegisterPage } from '@/util/trabox-baggage';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { Baggage } from '@/models/baggage';
import { useEnvironment } from '@/composables/global/environment';

export const useBaggageListOpenedHelper = (
    props: { detail?: DetailProps }
) => {
    const {
        state: {
            loading: loadingBaggageList,
            form,
            isFiltered,
            validationMessage,
            list,
            sortInfo,
            pageInfo,
        },
        search: searchBaggage,
        reload: reloadBaggageList,
        changePage: changeBaggageListPage,
        clearForm,
    } = useBaggageList('OPENED');
    const {
        state: { baggage },
        load: loadMyBaggage,
        clear: clearMyBaggage
    } = useMyBaggage();
    const {
        isOnlineNegotiation,
    } = useMyBaggageExtension();
    const {
        state: {
            loading: loadingTruckRecommendations,
            list: truckRecommendations,
        },
        search: searchTruckRecommendations,
        clear: clearTruckRecommendations,
    } = useMyBaggageTruckRecommendation();
    const {
        state: {
            loading: loadingNegotiationList,
            list: negotiationList,
        },
        load: loadNegotiationList,
        clear: clearNegotiationList,
    } = useNegotiationList();
    const {
        state: {
            list: circleCompanyList,
        },
        load: loadCircleCompanyList,
    } = useCircleCompanyList();
    const { state: { loading: loadingBaggageCancel }, cancel } = useBaggageCancel();
    const { visible: searchFormVisible } = useMyBaggageSearchFormVisibility('OPENED');
    const {
        markNegotiation,
        unmarkNegotiation
    } = useBaggageMarkNegotiation();
    const { confirm: confirmLeavingPage } = useConfirmLeavingPage();
    const message = useMessage();
    const { sorryNotification, close: closeNotification } = useNotificationExtension('BaggageOpenedListPage');
    const {
        goToBaggageList,
        goToBaggageRegister,
        goToOpenedBaggageDetail: goToBaggageDetail,
        openMyBaggagePrint,
        goToNotFound,
    } = useRouting();
    const { lazy } = useTimer();
    const { openedMyBaggageDetailProps } = useRouteExtension();
    const { state: { loading: loadingDetail }, withMuteableLoading } = useLoading();
    const { state: { profile: accountMyProfile }, load: loadMyAccountProfile } = useAccountMyProfile();
    const { state: { environment }, loadEnvironment } = useEnvironment();

    const resultListRef = ref<Vue>();

    const routeUpdating = ref(false);

    // 詳細ドロワーの表示状態
    const baggageDrawerVisibility = computed(() => {
        return !routeUpdating.value && props.detail !== undefined;
    });

    // 編集ドロワーの表示状態
    const baggageEditorDrawerVisibility = computed(() => {
        return baggageDrawerVisibility.value && props.detail?.edit === true;
    });

    // 空車レコメンドモーダルの表示状態
    const truckRecommendationMessageVisibility = ref(false);

    // 一覧のローディング状態
    const loading = computed(() =>
        loadingBaggageList.value ||
        loadingBaggageCancel.value
    );

    // 詳細ドロワー内の表示タブ状態
    const selectedBaggageDetailTab = ref<BaggageDetailTabKey>('baggage');

    const cancelBaggage = async (baggageId: number) => {
        closeNotification();
        try {
            if (!await cancel(baggageId)) {
                return;
            }
            await closeBaggageView();
            message.success('選択した荷物を削除しました。');
        } catch {
            sorryNotification({ message: '選択した荷物を削除できませんでした。' });
        }

        await reload();
    };

    const exportBaggage = async (record: Baggage) => {
        if (!accountMyProfile.value) await loadMyAccountProfile();
        if (!environment.value) await loadEnvironment();
        const ultraboxUrl = Util.requireNotNull(environment.value?.ultraboxUrl);
        const userName = accountMyProfile.value?.name ?? '';
        goToTraboxBaggageRegisterPage(record, userName, ultraboxUrl);
    };

    const setUnderNegotiation = async (baggageId: number, mark: boolean) => {
        if (mark) {
            await markNegotiation(baggageId);
        } else {
            await unmarkNegotiation(baggageId);
        }
        await reload(true);
        await reloadDetailIfPresent(baggageId, true);
    };

    const search = async () => {
        try {
            await searchBaggage();
        } catch {
            notifyFailedToLoadData();
        }
    };

    const reload = async (mute: boolean = false) => {
        try {
            await reloadBaggageList(mute);
        } catch {
            notifyFailedToLoadData();
        }
    };

    const changePage = async (value: {
        pageNo: number,
        pageSize: number,
        sortKey?: BaggageSortKeyCode,
        sortOrder?: 'ASC' | 'DESC'
    }) => {
        try {
            await changeBaggageListPage(value);
            scrollToTopOfResultList();
        } catch {
            notifyFailedToLoadData();
        }
    };

    const goToOpenedBaggageDetail = async (
        baggageId: number,
        options: GoToOpenedMyBaggageDetailOptions | undefined = undefined
    ) => {
        // 連打などにより画面遷移が失敗する可能性があるので、遷移失敗の例外をもみ消す
        await goToBaggageDetail(baggageId, options).catch(() => {
        });
    };

    const closeBaggageView = async () => {
        if (props.detail?.baggageId) {
            await goToBaggageList();
        }
    };

    const closeBaggageEditor = async () => {
        if (props.detail?.baggageId) {
            // 編集モードからの離脱キャンセルに備えてエラーをもみ消し
            await goToOpenedBaggageDetail(props.detail.baggageId).catch(() => {
            });
        }
    };

    /**
     * 空車企業詳細ドロワーを閉じる
     */
    const closeTruckCompany = async () => {
        if (props.detail?.baggageId) {
            await goToOpenedBaggageDetail(props.detail.baggageId, _.omit(props.detail, 'truckCompany'));
        }
    };

    /**
     * 商談リクエスト企業詳細ドロワーを閉じる
     */
    const closeNegotiationRequestCompany = async () => {
        if (props.detail?.baggageId) {
            await goToOpenedBaggageDetail(props.detail.baggageId);
        }
    };

    /**
     * 空車レコメンドモーダルのオプションが選択された。
     */
    const selectTruckRecommendationOption = (value: 'soon' | 'later') => {
        const tabs = { soon: 'truck', later: 'baggage' } as const;
        selectedBaggageDetailTab.value = tabs[value];
        truckRecommendationMessageVisibility.value = false;
        if (props.detail?.baggageId) {
            Karte.trackClickTruckRecommendAnnounce(props.detail.baggageId, value);
        }
    };

    /**
     * 空車情報の会社が選択された。
     */
    const selectTruckCompany = async (value: { companyId: number, truckId: number }) => {
        if (props.detail?.baggageId) {
            await goToOpenedBaggageDetail(props.detail.baggageId, { truckCompany: value });
        }
    };

    /**
     * 商談リクエストの会社が選択された。
     */
    const selectNegotiationCompany = async (value: { companyId: number, negotiationId: number }) => {
        if (props.detail?.baggageId) {
            await goToOpenedBaggageDetail(props.detail.baggageId, { negotiationCompany: value });
        }
    };
    /**
     * 商談リクエストのページが変更された。
     * @param value
     */
    const changeNegotiationPage = async (value: { pageNo: number, pageSize: number }) => {
        if (props.detail?.baggageId) {
            await loadNegotiationList(props.detail.baggageId, value.pageNo, value.pageSize);
        }
    };

    /**
     * 空車レコメンド企業の連絡先がクリックされた。
     */
    const clickRecommendedTruckCompanyContactInfo = () => {
        if (!props.detail?.truckCompany) {
            return;
        }
        Karte.trackClickTruckRecommendContact(props.detail.truckCompany.truckId, props.detail.baggageId);
        gtm.setEvent('open_truck_contacts', {
            id: props.detail.truckCompany.truckId,
            recommend_baggage_id: props.detail.baggageId
        });
    };

    /**
     * 商談リクエスト企業の連絡先がクリックされた。
     */
    const clickNegotiationRequestCompanyContactInfo = () => {
        if (!props.detail?.negotiationCompany) {
            return;
        }
        Karte.trackClickNegotiationRequestContact(props.detail.baggageId, props.detail.negotiationCompany.companyId);
        gtm.setEvent('open_negotiation_contacts', {
            baggage_id: props.detail.baggageId,
            truck_company_id: props.detail.negotiationCompany.companyId
        });
    };

    const notifyFailedToLoadData = () => {
        message.error('荷物一覧を読み込みできませんでした。時間をおいて再度お試しください。');
    };

    const scrollToTopOfResultList = () => {
        if (resultListRef.value) {
            PageUtil.scrollToContentTop((resultListRef.value?.$el as HTMLDivElement).offsetTop);
        }
    };

    const clearDetail = () => {
        clearMyBaggage();
    };

    const loadDetail = async (baggageId: number, mute: boolean = false) => {
        await withMuteableLoading(mute, async () => {
            await loadMyBaggage(baggageId);
            if (baggage.value) {
                await searchTruckRecommendations(baggage.value);
            } else {
                clearTruckRecommendations();
            }
            if (baggage.value && isOnlineNegotiation(baggage.value)) {
                Promise.all(
                    [
                        // NOTE 対応可、対応不可、保留を1つのページ内で一覧表示するため100件を取得する
                        // ひとまず、1Circleに100社超の企業が存在することはない、という想定で開発を進める
                        loadNegotiationList(baggageId, 1, 100),
                        loadCircleCompanyList(Util.requireNotNull(baggage.value.circle?.id))
                    ]
                ).then(r => null);
            } else {
                clearNegotiationList();
            }
        });
    };

    /**
     * 指定した荷物IDがドロワーで表示されている場合にリロードする
     */
    const reloadDetailIfPresent = async (baggageId: number, mute: boolean = false) => {
        if (props.detail?.baggageId === baggageId) {
            await loadDetail(baggageId, mute);
        }
    };

    // TODO: dirty処理をcomposableする

    const isDirty = ref(false);

    const onDirty = () => {
        isDirty.value = true;
    };

    watch(() => props.detail, (newValue, oldValue) => {
        // 同じ荷物IDで編集モードに変更が無い場合は、dirty状態をリセットしない
        if (newValue?.baggageId === oldValue?.baggageId && newValue?.edit === true && oldValue?.edit === true) {
            return;
        }
        // dirty状態をリセット
        isDirty.value = false;
    });

    /**
     * 荷物が更新された
     */
    const onBaggageUpdated = async () => {
        isDirty.value = false;
        if (props.detail) {
            await goToOpenedBaggageDetail(props.detail.baggageId);
            await reloadDetailIfPresent(props.detail.baggageId, true);
            await reload(true);
        }
    };

    const shouldStayEditing = async () => {
        if (props.detail?.edit && isDirty.value) {
            return !(await confirmLeavingPage());
        }
        return false;
    };

    onMounted(async () => {
        await search();

        if (props.detail?.baggageId) {
            try {
                await loadDetail(props.detail.baggageId);
            } catch {
                await goToNotFound();
            }
        }
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const fromDetailProps = openedMyBaggageDetailProps(from);
        const toDetailProps = openedMyBaggageDetailProps(to);

        // 編集モードに留まるか？
        if (isLeavingEditor(fromDetailProps, toDetailProps)) {
            if (await shouldStayEditing()) {
                next(false);
                return;
            }
        }

        // 荷物が非選択になる場合
        if (toDetailProps === undefined) {
            clearDetail();
            next();
            return;
        }

        // 荷物IDが同じ場合
        if (fromDetailProps?.baggageId === toDetailProps.baggageId) {
            next();
            return;
        }

        // 選択荷物に変化がある場合
        // - 未選択状態から荷物を選択した場合
        // - 異なる荷物を選択した場合
        routeUpdating.value = true;
        selectedBaggageDetailTab.value = 'baggage';
        try {
            await lazy(async () => {
                await loadDetail(toDetailProps.baggageId);
            });
        } catch {
            message.error(`荷物番号 ${ toDetailProps.baggageId } の荷物情報を読み込みできませんでした。すでに荷物が削除されている可能性があります。`);
            next(false);
            return;
        } finally {
            routeUpdating.value = false;
        }

        next();
    });

    onBeforeRouteLeave(async (_to, _from, next) => {
        // 編集モードに留まるか？
        if (await shouldStayEditing()) {
            next(false);
        }
        next();
    });

    watchEffect(async () => {
        if (!props.detail?.registered || truckRecommendations.value === undefined || truckRecommendations.value.length === 0) {
            return;
        }

        // 荷物登録直後から遷移してきた時に、マッチする空車レコメンドがある場合
        truckRecommendationMessageVisibility.value = true;
    });

    return {
        searchFormVisible,
        isFiltered,
        loading,
        loadingDetail,
        loadingTruckRecommendations,
        loadingNegotiationList,
        form,
        validationMessage,
        list,
        sortInfo,
        pageInfo,
        baggageDrawerVisibility,
        baggageEditorDrawerVisibility,
        truckRecommendationMessageVisibility,
        selectedBaggageDetailTab,
        baggage,
        truckRecommendations,
        negotiationList,
        circleCompanyList,
        resultListRef,
        search,
        changePage,
        clearForm,
        selectBaggage: goToOpenedBaggageDetail,
        editBaggage: (id: number) => goToOpenedBaggageDetail(id, { edit: true }),
        copyBaggage: goToBaggageRegister,
        cancelBaggage,
        exportBaggage,
        printBaggage: openMyBaggagePrint,
        markUnderNegotiation: (id: number) => setUnderNegotiation(id, true),
        unmarkUnderNegotiation: (id: number) => setUnderNegotiation(id, false),
        closeBaggageView,
        closeBaggageEditor,
        closeTruckCompany,
        closeNegotiationRequestCompany,
        selectTruckCompany,
        selectTruckRecommendationOption,
        selectNegotiationCompany,
        changeNegotiationPage,
        clickRecommendedTruckCompanyContactInfo,
        clickNegotiationRequestCompanyContactInfo,
        onDirty,
        onBaggageUpdated,
    };
};
