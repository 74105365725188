export const columns = [
    {
        title: '承認期限',
        key: 'changeLimitTm',
        align: 'center',
        scopedSlots: { customRender: 'changeLimitTm' },
        width: 122,
    },
    {
        title: '支払日',
        key: 'payerDate',
        scopedSlots: { customRender: 'payerDate' },
        width: 122,
    },
    {
        title: '運行',
        key: 'label',
        scopedSlots: { customRender: 'label' },
    },
    {
        title: '申請内容',
        key: 'requests',
        scopedSlots: { customRender: 'requests' },
    },
    {
        title: '取引先名',
        key: 'companyName',
        scopedSlots: { customRender: 'companyName' },
    },
    {
        title: '',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
        width: 75,
    },
];
