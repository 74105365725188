import { useLoading } from '@/composables/helper/loading-helper';
import { ref } from 'vue';
import { SettlementModel, SettlementPartnerModel } from '@/models/settlement';
import { settlementApi } from '@/repository/api/internal/settlement';

/**
 * 決済詳細データをロードする機能を提供します。
 */
export const useSettlement = () => {
    const settlement = ref<SettlementModel | undefined>(undefined);
    const partner = ref<SettlementPartnerModel | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = async (settlementId: number): Promise<void> => {
        await withLoading(async () => {
            const data = await settlementApi.find(settlementId, undefined);
            settlement.value = new SettlementModel(data.settlement);
            partner.value = new SettlementPartnerModel(data.partner);
        });
    };

    const clear = () => {
        settlement.value = undefined;
        partner.value = undefined;
    };

    return {
        state: {
            loading,
            settlement,
            partner,
        },
        load,
        clear,
    };
};

export const useSettlementConfirmationUpdate = () => {
    const { state: { loading }, withLoading } = useLoading();

    const mark = (id: number) => withLoading(async () => {
        await settlementApi.markAsConfirmed(id);
    });

    const unmark = (id: number) => withLoading(async () => {
        await settlementApi.unmarkAsConfirmed(id);
    });

    return {
        state: {
            loading,
        },
        mark,
        unmark,
    };
};
