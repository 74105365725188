import { Baggage } from '@/models/baggage';
import { CustomRow } from '@/types/ant-design';

export const columns = [
    {
        title: '発日時・発地 / 着日時・着地',
        key: 'departureArrival',
        scopedSlots: { customRender: 'departureArrival' },
        width: 228,
        align: 'center',
    },
    {
        title: '運賃',
        dataIndex: 'freight',
        key: 'freight',
        scopedSlots: { customRender: 'freight' },
        width: 100,
        align: 'center',
    },
    {
        title: '重量',
        key: 'truckWeight',
        width: 64,
        scopedSlots: { customRender: 'truckWeight' },
    },
    {
        title: '車種',
        key: 'truckModel',
        width: 75,
        scopedSlots: { customRender: 'truckModel' },
    },
    {
        title: '荷種',
        key: 'type',
        width: 75,
        scopedSlots: { customRender: 'type' },
    }
];

export const createCustomRow = (record: Baggage, onClickRow: (baggage: Baggage) => void): CustomRow => {
    return {
        on: {
            click: (event) => {
                onClickRow(record);
                event.stopPropagation();
            },
        },
    };
};
