import { useLoading } from '@/composables/helper/loading-helper';
import { computed, ref } from 'vue';
import {
    CompanyTruck,
    CompanyTruckList,
    CompanyTruckRegisterFormValidatableModel,
    CompanyTruckSearchFormModel, CompanyTruckUpdateFormModel
} from '@/models/company-truck';
import { companyTruckApi } from '@/repository/api/internal/company-truck';
import { useNotification } from '@/composables/helper/page-helper';
import { FormValidateUtil } from '@/models/validate-helper';
import _ from 'lodash';

export const useCompanyTruckSearch = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<CompanyTruckList | undefined>();
    const form = ref<CompanyTruckSearchFormModel>(new CompanyTruckSearchFormModel());

    const search = (pageNo: number, pageSize: number) => withLoading(async () => {
        form.value.pageNo = pageNo;
        form.value.pageSize = pageSize;
        list.value = await companyTruckApi.searchCompanyTruck(form.value);
    });

    return {
        state: {
            loading,
            list,
            form,
        },
        search,
    };
};


export const useFindCompanyTruck = () => {
    const { state: { loading }, withLoading } = useLoading();
    const companyTruck = ref<CompanyTruck | undefined>();
    const find = (companyTruckId: number) => withLoading(async () => {
        companyTruck.value = await companyTruckApi.find(companyTruckId);
    });

    return {
        state: {
            loading,
            companyTruck,
        },
        find,
    };
};


export const useCompanyTruckRegister = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_COMPANY_TRUCK_FAILED';
    /**
     * 車両登録失敗を通知します。
     */
    const notifyFailedToRegister = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
        message: '車両情報を登録できませんでした。',
        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 車両登録失敗通知を閉じます。
     */
    const closeFailedToRegisterNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

    const form = ref<CompanyTruckRegisterFormValidatableModel>(new CompanyTruckRegisterFormValidatableModel());
    const sourceForm = ref<CompanyTruckRegisterFormValidatableModel>(new CompanyTruckRegisterFormValidatableModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (companyTruck: CompanyTruck) => {
        form.value = CompanyTruckRegisterFormValidatableModel.of(companyTruck);
    };

    const register = async(): Promise<number> => {
        closeFailedToRegisterNotification();
        try {
            const companyTruckId = await companyTruckApi.registerCompanyTruck(form.value);
            sourceForm.value = _.cloneDeep(form.value); // NOTE 画面移動時のconfirmation表示を回避するためsourceFormを更新する
            return companyTruckId;
        } catch (e) {
            notifyFailedToRegister();
            throw e;
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        register,
    };
};

export const useCompanyTruckUpdate = () => {
    const companyTruckId = ref<number | undefined>(undefined);
    const form = ref<CompanyTruckUpdateFormModel>(new CompanyTruckUpdateFormModel());
    const sourceForm = ref<CompanyTruckUpdateFormModel>(new CompanyTruckUpdateFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (companyTruck: CompanyTruck) => {
        companyTruckId.value = companyTruck.id;
        form.value = CompanyTruckUpdateFormModel.of(companyTruck);
        sourceForm.value = _.cloneDeep(form.value);
    };

    const update = async () => {
        if (companyTruckId.value) {
            await companyTruckApi.updateCompanyTruck(companyTruckId.value, form.value.toForm());
            sourceForm.value = _.cloneDeep(form.value); // NOTE 画面移動時のconfirmation表示を回避するためsourceFormを更新する
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        update,
    };
};

export const useCompanyTruckDestroy = () => {
    const notification = useNotification();
    const notifyFailedKey = 'DELETE_COMPANY_TRUCK_FAILED';

    const { state: { loading }, withLoading } = useLoading();

    const destroy = (id: number) => withLoading(async () => {
        notification.close(notifyFailedKey);
        await companyTruckApi.deleteCompanyTruck(id).catch(() => {
            notification.error({
                key: notifyFailedKey,
                message: '車両情報を削除できませんでした。',
                description: '時間をおいて再度お試しください。'
            });
        });
    });

    return {
        state: {
            loading,
        },
        destroy,
    };
};
