//
// Action
//
import { GlobalStatistics } from '@/models/statistics';

export class ACTION {
}

//
// Signature for Action
//
export type loadGlobalStatistics = () => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly GLOBAL_STATISTICS = 'GLOBAL_STATISTICS';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_GLOBAL_STATISTICS = 'SET_GLOBAL_STATISTICS';
}

//
// Store
//
export interface StatisticsState {
    globalStatistics?: GlobalStatistics;
}
export * from '@/models/statistics';
