import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/agreement/types';

@Component
export default class AgreementTemperatureZone extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly agreement?: types.Agreement;
    @Prop()
    declare readonly agreementCr?: types.AgreementChangeRequest;

    get hasChangeRequest(): boolean {
        if (!this.agreement || !this.agreementCr) return false;
        return !!this.temperatureZoneChangeRequest;
    }

    get temperatureZone(): string | undefined {
        return this.agreement?.temperatureZone?.label;
    }

    get temperatureZoneChangeRequest(): string | undefined {
        const changeRequest = this.agreementCr?.temperatureZone?.label;
        if (changeRequest === this.temperatureZone) return undefined;
        return changeRequest ?? '';
    }
}
