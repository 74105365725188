import _ from 'lodash';
import { Enum } from '@/types/enum';
import { DateUtil, PhoneUtil, Util } from '@/util';
import { Validator } from '@/validator';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { TmpBaggageLocationValue } from '@/models/baggage';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { DateValue } from '@/models/vo/date';
import { DateTimeValue } from '@/models/vo/datetime';
import { TruckHeightEnum, TruckHeightEnumCode } from '@/enums/truck-height.enum';
import { TruckWidthEnum, TruckWidthEnumCode } from '@/enums/truck-width.enum';
import { CompanyProfile } from '@/models/company';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Const } from '@/const';
import {
    DeliveryDateTimeRange,
    DeliveryDateTimeRangeType,
    DeliveryTimeFormats
} from '@/models/vo/delivery-datetime-range';
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import { DeliveryOrderStatusEnum, DeliveryOrderStatusEnumCode } from '@/enums/delivery-order-status.enum';

export interface DeliveryOrderList {
    data: DeliveryOrderModel[];
}

export interface DeliveryOrder {
    id: number;
    status: Enum<DeliveryOrderStatusEnumCode>;
    agreementId: number;
    baggageCompanyId: number;
    truckCompanyId: number;
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum<TruckWeightEnumCode>;
    truckModel: Enum<TruckModelEnumCode>;
    truckHeight?: Enum<TruckHeightEnumCode>;
    truckWidth?: Enum<TruckWidthEnumCode>;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    freight?: number;
    description?: string;
    createDate: string;
    staffName: string;
    truckCompanyName?: string;
    truckNumber?: string;
    driverName?: string;
    driverPhoneNumber?: string;
    lastSentTm?: string;
    lastRepliedTm?: string;
}

export interface DeliveryOrderExist {
    id: number;
}

const DATE_FORMAT = 'YYYY年M月D日(ddd)';
const TIME_FORMATS: DeliveryTimeFormats = {
    day: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.day,
    morning: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.morning,
    afternoon: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.afternoon,
    hourly: 'H:mm',
    just: 'H:mm',
    period: DeliveryDateTimeRange.DEFAULT_TIME_FORMATS.period
};

export class DeliveryOrderModel {
    id: number;
    status: DeliveryOrderStatusEnum;
    agreementId: number;
    baggageCompanyId: number;
    truckCompanyId: number;
    departureDateTime: DeliveryDateTimeRange;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalDateTime: DeliveryDateTimeRange;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight?: TruckWeightEnum;
    truckModel?: TruckModelEnum;
    truckHeight?: TruckHeightEnum;
    truckWidth?: TruckWidthEnum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    freight?: number;
    description?: string;
    createDate: string;
    staffName: string;
    truckCompanyName?: string;
    truckNumber?: string;
    driverName?: string;
    driverPhoneNumber?: string;
    lastSentTm?: DateTimeValue;
    lastRepliedTm?: DateTimeValue;

    constructor(param: DeliveryOrder) {
        this.id = param.id;
        this.status = DeliveryOrderStatusEnum.codeOf(param.status.code);
        this.agreementId = param.agreementId;
        this.baggageCompanyId = param.baggageCompanyId;
        this.truckCompanyId = param.truckCompanyId;
        this.departureDateTime = Util.requireNotNull(DeliveryDateTimeRange.of(param.departureMin, param.departureMax));
        this.departurePref = param.departurePref;
        this.departureCity = param.departureCity;
        this.departureAddress = param.departureAddress;
        this.loadingTimeNote = param.loadingTimeNote;
        this.arrivalDateTime = Util.requireNotNull(DeliveryDateTimeRange.of(param.arrivalMin, param.arrivalMax));
        this.arrivalPref = param.arrivalPref;
        this.arrivalCity = param.arrivalCity;
        this.arrivalAddress = param.arrivalAddress;
        this.unloadingTimeNote = param.unloadingTimeNote;
        this.type = param.type;
        this.shape = param.shape;
        this.temperatureZone = param.temperatureZone;
        this.paletteCount = param.paletteCount;
        this.paletteHeight = param.paletteHeight;
        this.paletteWidth = param.paletteWidth;
        this.totalCount = param.totalCount;
        this.totalVolume = param.totalVolume;
        this.totalWeight = param.totalWeight;
        this.loading = param.loading;
        this.unloading = param.unloading;
        this.truckWeight = TruckWeightEnum.valueOf(param.truckWeight.code);
        this.truckModel = TruckModelEnum.valueOf(param.truckModel.code);
        this.truckHeight = param.truckHeight ? TruckHeightEnum.valueOf(param.truckHeight.code) : undefined;
        this.truckWidth = param.truckWidth ? TruckWidthEnum.valueOf(param.truckWidth.code) : undefined;
        this.largeTruckFlg = param.largeTruckFlg;
        this.truckEquipment = param.truckEquipment;
        this.freight = param.freight;
        this.description = param.description;
        this.createDate = param.createDate;
        this.staffName = param.staffName;
        this.truckCompanyName = param.truckCompanyName;
        this.truckNumber = param.truckNumber;
        this.driverName = param.driverName;
        this.driverPhoneNumber = param.driverPhoneNumber;
        this.lastSentTm = param.lastSentTm ? new DateTimeValue(param.lastSentTm) : undefined;
        this.lastRepliedTm = param.lastRepliedTm ? new DateTimeValue(param.lastRepliedTm) : undefined;
    }

    /**
     * 管理番号
     */
    get manageNumber(): string {
        const date = DateTimeValue.now();
        return `${ date.format('YYYYMMDD') }-${ this.id }`;
    }

    /**
     * 作成日
     */
    get formattedCreateDate(): string {
        return new DateValue(this.createDate).format('YYYY年M月D日(ddd)');
    }

    /**
     * 出発日時
     */
    get formattedDepartureDateTime(): string {
        const date = this.departureDateTime.date.format(DATE_FORMAT);
        const time = this.departureDateTime.formatTime(TIME_FORMATS);
        return `${ date } ${ time }`;
    }

    /**
     * 到着日時
     */
    get formattedArrivalDateTime(): string {
        const date = this.arrivalDateTime.date.format(DATE_FORMAT);
        const time = this.arrivalDateTime.formatTime(TIME_FORMATS);
        return `${ date } ${ time }`;
    }

    /**
     * 積み地
     */
    get formattedDepartureLocation(): string {
        const loadingTimeNote = this.loadingTimeNote ? `${ this.loadingTimeNote }\n` : '';
        const location = `${ this.departurePref.label }${ this.departureCity }${ this.departureAddress ?? '' }\n`;
        const loading = this.loading ? `ドライバー作業: ${ this.loading?.label ?? '' }` : '';
        return `${ loadingTimeNote }${ location }${ loading }`;
    }

    /**
     * 卸し地
     */
    get formattedArrivalLocation(): string {
        const unLoadingTimeNote = this.unloadingTimeNote ? `${ this.unloadingTimeNote }\n` : '';
        const location = `${ this.arrivalPref.label }${ this.arrivalCity }${ this.arrivalAddress ?? '' }\n`;
        const unLoading = this.unloading ? `ドライバー作業: ${ this.unloading?.label ?? '' }` : '';
        return `${ unLoadingTimeNote }${ location }${ unLoading }`;
    }

    /**
     * 荷種
     */
    get formattedBaggageType(): string {
        // 温度帯
        const temperatureZone = this.temperatureZone ? `\n温度帯: ${ this.temperatureZone.label }` : '';

        // パレット
        const paletteCount = this.paletteCount ? `/${ this.paletteCount }枚` : '';
        const paletteHeight = this.paletteHeight ? `/${ this.paletteHeight }cm` : '';
        const paletteWidth = this.paletteWidth ? `/${ this.paletteWidth }cm` : '';
        const paletteAttributes = `${ this.type }${ paletteCount }${ paletteHeight }${ paletteWidth }${ temperatureZone }`;

        // その他
        const totalCount = this.totalCount ? `/${ this.totalCount }個` : '';
        const totalVolume = this.totalVolume ? `/${ this.totalVolume }m³` : '';
        const othersAttributes = `${ this.type }${ totalCount }${ totalVolume }${ temperatureZone }`;

        if (this.shape.code === BaggageShapeEnum.Palette.code) {
            return paletteAttributes;
        }
        return othersAttributes;
    }

    /**
     * 総重量
     */
    get formattedTotalWeight(): string | undefined {
        return this.totalWeight ? `${ this.totalWeight }kg` : undefined;
    }

    /**
     * 注意事項
     */
    get formattedDescription(): string {
        const truckEquipment = this.truckEquipment ? `\n車両装備: ${ this.truckEquipment }` : '';
        return `${ this.description ?? '' }${ truckEquipment }`;
    }

    /**
     * 希望車両情報
     */
    get formattedTruckAttributes(): string {
        const weight = (truckWeight: TruckWeightEnum) => {
            if (truckWeight.code === TruckWeightEnum.Other.code) {
                return '重量:問わず';
            }
            return truckWeight.label;
        };

        const model = (truckModel: TruckModelEnum) => {
            if (truckModel.code === TruckModelEnum.Other.code) {
                return '車種:問わず';
            }
            return truckModel.label;
        };

        const height = (truckHeight: TruckHeightEnum) => {
            if (truckHeight.code === TruckHeightEnum.Unspecified.code) {
                return '床高:問わず';
            }
            return truckHeight.label;
        };

        const width = (truckWidth: TruckWidthEnum) => {
            if (truckWidth.code === TruckWidthEnum.StandardOnly.code) {
                return '幅:標準のみ';
            }
            if (truckWidth.code === TruckWidthEnum.Unspecified.code) {
                return '幅:問わず';
            }
            return truckWidth.label;
        };

        const largeTruck = (largeTruckAvailable?: boolean): string | undefined => {
            if (largeTruckAvailable) {
                return largeTruckAvailable ? '/大型可' : '/大型不可';
            }
            return undefined;
        };

        const truckWeight = this.truckWeight ? `${ weight(this.truckWeight) }` : '';
        const truckModel = this.truckModel ? `/${ model(this.truckModel) }` : '';
        const truckHeight = this.truckHeight ? `/${ height(this.truckHeight) }` : '';
        const truckWidth = this.truckWidth ? `/${ width(this.truckWidth) }` : '';
        const largeTruckFine = largeTruck(this.largeTruckFlg) ?? '';
        return `${ truckWeight }${ truckModel }${ truckHeight }${ truckWidth }${ largeTruckFine }`;
    }

    /**
     * 運賃
     */
    get formattedFreight(): string {
        return this.freight ? `${ this.freight.toLocaleString() }円` : '';
    }

    /**
     * ドライバー電話番号
     */
    get formattedDriverPhoneNumber(): string {
        return this.driverPhoneNumber ? PhoneUtil.format(this.driverPhoneNumber) : '';
    }

    /**
     * 最終送信日時
     */
    get lastSentDateTime(): string {
        return this.lastSentTm?.format() ?? '';
    }

    /**
     * 最終返信日時
     */
    get lastRepliedDateTime(): string {
        return this.lastRepliedTm?.format() ?? '';
    }
}

/**
 * 配送依頼書印刷モデル
 */
export class DeliveryOrderPrintModel {
    content: DeliveryOrderModel;
    myCompanyProfile: CompanyProfile;
    partnerCompanyProfile: CompanyProfile;

    constructor(content: DeliveryOrderModel, myCompanyProfile: CompanyProfile, partnerCompanyProfile: CompanyProfile) {
        this.content = content;
        this.myCompanyProfile = myCompanyProfile;
        this.partnerCompanyProfile = partnerCompanyProfile;
    }
}

export class DeliveryOrderListViewForm {
    agreementIds: number[];

    constructor(agreementIds: number[]) {
        this.agreementIds = agreementIds;
    }
}

export class DeliveryOrderRegisterForm {
    agreementId?: number;
    staffName?: string;

    constructor(param: DeliveryOrderRegisterForm | null = null) {
        this.agreementId = param?.agreementId;
        this.staffName = param?.staffName;
    }
}

export class DeliveryOrderUpdateForm implements FormValidatable<DeliveryOrderUpdateForm> {
    departureMin: string;
    departureMax: string;
    departureType?: DeliveryDateTimeRangeType;
    departurePref: { code?: string };
    departureCity?: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalType?: DeliveryDateTimeRangeType;
    arrivalPref: { code?: string };
    arrivalCity?: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    type: string;
    shape?: Enum<BaggageShapeEnumCode>;
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    temperatureZone: { code?: string };
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    freight: string;
    description?: string;
    createDate: string;
    staffName: string;
    truckCompanyName?: string;
    truckNumber?: string;
    driverName?: string;
    driverPhoneNumber?: string;
    departureLocation: TmpBaggageLocationValue;
    arrivalLocation: TmpBaggageLocationValue;
    // 変更前荷種
    previousType: string;

    constructor(param: Partial<DeliveryOrderUpdateForm> | null = null) {
        this.departureMin = param?.departureMin ?? '';
        this.departureMax = param?.departureMax ?? '';
        this.departurePref = param?.departurePref ?? {};
        this.departureCity = param?.departureCity ?? '';
        this.departureAddress = param?.departureAddress ?? '';
        this.loadingTimeNote = param?.loadingTimeNote;
        this.arrivalMin = param?.arrivalMin ?? '';
        this.arrivalMax = param?.arrivalMax ?? '';
        this.arrivalPref = param?.arrivalPref ?? {};
        this.arrivalCity = param?.arrivalCity ?? '';
        this.arrivalAddress = param?.arrivalAddress ?? '';
        this.unloadingTimeNote = param?.unloadingTimeNote;
        this.type = param?.type ?? '';
        this.shape = param?.shape;
        this.paletteCount = param?.paletteCount ?? '';
        this.paletteHeight = param?.paletteHeight ?? '';
        this.paletteWidth = param?.paletteWidth ?? '';
        this.totalCount = param?.totalCount ?? '';
        this.totalVolume = param?.totalVolume ?? '';
        this.totalWeight = param?.totalWeight ?? '';
        this.loading = param?.loading;
        this.unloading = param?.unloading;
        this.temperatureZone = param?.temperatureZone ?? {};
        this.truckWeight = param?.truckWeight ?? { code: 'TW19' };
        this.truckModel = param?.truckModel ?? { code: 'TM17' };
        this.truckHeight = param?.truckHeight;
        this.truckWidth = param?.truckWidth;
        this.largeTruckFlg = param?.largeTruckFlg;
        this.truckEquipment = param?.truckEquipment;
        this.freight = param?.freight ?? '0';
        this.description = param?.description ?? '';
        this.createDate = param?.createDate ?? '';
        this.staffName = param?.staffName ?? '';
        this.truckCompanyName = param?.truckCompanyName;
        this.truckNumber = param?.truckNumber;
        this.driverName = param?.driverName;
        this.driverPhoneNumber = param?.driverPhoneNumber;
        this.departureLocation = new TmpBaggageLocationValue(
            param?.departureLocation?.value ? param.departureLocation.value : {}
        );
        this.arrivalLocation = new TmpBaggageLocationValue(
            param?.arrivalLocation?.value ? param.arrivalLocation.value : {}
        );
        this.previousType = param?.previousType ?? '';
    }

    /**
     * 発日時
     */
    get departure(): DeliveryDateTimeRange | null | undefined {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        if (!this.departureMin || !this.departureMax || !this.departureType) return defaultValue;

        return DeliveryDateTimeRange.typeOf(this.departureType, this.departureMin, this.departureMax) ?? defaultValue;
    }

    set departure(param: DeliveryDateTimeRange | null | undefined) {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        const departure = param && Validator.validateDateTimeRangeType(param, null).result ? param : defaultValue;

        const [min, max] = departure.rawValuesAsString();
        this.departureMin = min;
        this.departureMax = max;
        this.departureType = departure.type;
    }

    get departureDateTimeRange(): DateTimeRangePickerValue {
        return {
            min: this.departureMin,
            max: this.departureMax,
            type: this.departureType
        };
    }

    set departureDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 出発日時を書き換え
        this.departureMin = min ?? '';
        this.departureMax = max ?? '';
        this.departureType = type;
    }

    get departurePrefCode(): PrefectureEnumCode {
        return this.departurePref.code as PrefectureEnumCode;
    }

    set departurePrefCode(value: PrefectureEnumCode) {
        const changed = this.departurePref.code !== value;
        this.departurePref = { code: value };
        if (changed) {
            this.departureCity = '';
            this.departureAddress = '';
        }
    }

    /**
     * 着日時
     */
    get arrival(): DeliveryDateTimeRange | null | undefined {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now().add(1, 'day'));
        if (!defaultValue) throw new Error('Invalid DeliveryDateTime construction.');

        if (!this.arrivalMin || !this.arrivalMax || !this.arrivalType) return defaultValue;

        return DeliveryDateTimeRange.typeOf(this.arrivalType, this.arrivalMin, this.arrivalMax) ?? defaultValue;
    }

    set arrival(param: DeliveryDateTimeRange | null | undefined) {
        if (!param) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            this.arrivalType = undefined;
            return;
        }

        if (!Validator.validateDateTimeRangeType(param, null).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            this.arrivalType = undefined;
            return;
        }

        // 発日時との相対的な妥当性チェック
        if (!Validator.validateMultipleDateTimeRange(this.departure, param).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            this.arrivalType = undefined;
            return;
        }

        const [min, max] = param.rawValuesAsString();
        this.arrivalMin = min;
        this.arrivalMax = max;
        this.arrivalType = param.type;
    }

    get arrivalDateTimeRange(): DateTimeRangePickerValue {
        return {
            min: this.arrivalMin,
            max: this.arrivalMax,
            type: this.arrivalType
        };
    }

    set arrivalDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 到着日時を書き換え
        this.arrivalMin = min ?? '';
        this.arrivalMax = max ?? '';
        this.arrivalType = type;
    }

    get arrivalPrefCode(): PrefectureEnumCode {
        return this.arrivalPref.code as PrefectureEnumCode;
    }

    set arrivalPrefCode(value: PrefectureEnumCode) {
        const changed = this.arrivalPref.code !== value;
        this.arrivalPref = { code: value };
        if (changed) {
            this.arrivalCity = '';
            this.arrivalAddress = '';
        }
    }

    get shapeCode(): BaggageShapeEnumCode | undefined {
        return this.shape?.code as BaggageShapeEnumCode;
    }

    set shapeCode(value: BaggageShapeEnumCode | undefined) {
        this.shape = BaggageShapeEnum.valueOf(value);
        // 荷姿の変更時の荷種の補完
        switch (this.shape?.code) {
            case BaggageShapeEnum.Palette.code:
                this.previousType = this.type.trim();
                this.type = 'パレット';
                break;
            case BaggageShapeEnum.Other.code:
                this.type = this.previousType;
                break;
            default:
                break;
        }
    }

    get baggageType(): string | undefined {
        return this.type;
    }

    set baggageType(value: string | undefined) {
        this.type = value ?? '';
    }

    get temperatureZoneCode(): string {
        return this.temperatureZone.code ?? '';
    }

    set temperatureZoneCode(value: string) {
        this.temperatureZone = { code: value };
    }

    get paletteCountText(): string {
        return this.paletteCount ?? '';
    }

    set paletteCountText(value: string) {
        this.paletteCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    // Note: 本来不要なのだけど、antdのバリデーション処理をするために定義している
    // TODO: 要リファクタリング
    get paletteSize(): { paletteHeight: string | undefined, paletteWidth: string | undefined } {
        return {
            paletteHeight: this.paletteHeight,
            paletteWidth: this.paletteWidth,
        };
    }

    get paletteHeightText(): string {
        return this.paletteHeight ?? '';
    }

    set paletteHeightText(value: string) {
        this.paletteHeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteWidthText(): string {
        return this.paletteWidth ?? '';
    }

    set paletteWidthText(value: string) {
        this.paletteWidth = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalCountText(): string {
        return this.totalCount ?? '';
    }

    set totalCountText(value: string) {
        this.totalCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalVolumeText(): string {
        return this.totalVolume ?? '';
    }

    set totalVolumeText(value: string) {
        this.totalVolume = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalWeightText(): string {
        return this.totalWeight ?? '';
    }

    set totalWeightText(value: string) {
        this.totalWeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get loadingCode(): string | undefined {
        return this.loading?.code;
    }

    set loadingCode(value: string | undefined) {
        this.loading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get unloadingCode(): string | undefined {
        return this.unloading?.code;
    }

    set unloadingCode(value: string | undefined) {
        this.unloading = BaggageHandlingTypeEnum.valueOf(value);
    }

    // Note: 本来不要なのだけど、antdのバリデーション処理をするために定義している
    // TODO: 要リファクタリング
    get truck(): { weight?: string, model?: string } {
        return {
            weight: this.truckWeight.code,
            model: this.truckModel.code,
        };
    }

    get truckWeightCode(): string {
        return this.truckWeight.code ?? '';
    }

    set truckWeightCode(value: string) {
        this.truckWeight = { code: value };
    }

    get truckModelCode(): string {
        return this.truckModel.code ?? '';
    }

    set truckModelCode(value: string) {
        this.truckModel = { code: value };
    }

    get truckHeightCode(): string | undefined {
        return this.truckHeight?.code;
    }

    set truckHeightCode(value: string | undefined) {
        this.truckHeight = value ? { code: value } : undefined;
    }

    get truckWidthCode(): string | undefined {
        return this.truckWidth?.code;
    }

    set truckWidthCode(value: string | undefined) {
        this.truckWidth = value ? { code: value } : undefined;
    }

    get largeTruckFlgCode(): string | undefined {
        if (this.largeTruckFlg == true) return 'true';
        if (this.largeTruckFlg == false) return 'false';
        return undefined;
    }

    set largeTruckFlgCode(value: string | undefined) {
        const toBoolean = (value: string | undefined) => {
            if (value?.toLowerCase() === 'true') return true;
            if (value?.toLowerCase() === 'false') return false;
            return undefined;
        };
        this.largeTruckFlg = toBoolean(value);
    }

    get truckEquipmentText(): string {
        return this.truckEquipment ?? '';
    }

    set truckEquipmentText(value: string) {
        this.truckEquipment = value;
    }

    get baggageFreight(): BaggageFreightValue {
        return new BaggageFreightValue(Util.toNumber(this.freight ?? '0'));
    }

    set baggageFreight(value: BaggageFreightValue) {
        this.freight = value.value?.toString() ?? '';
    }

    get descriptionText(): string {
        return this.description ?? '';
    }

    set descriptionText(value: string) {
        this.description = value;
    }

    get createDateValue(): DateValue {
        return new DateValue(this.createDate);
    }

    set createDateValue(value: DateValue) {
        this.createDate = value.format('YYYY-MM-DD');
    }

    /**
     * 荷姿が指定されているか否か
     */
    get hasShape(): boolean {
        return !!this.shape;
    }

    /**
     * 車両指定に関係する項目のいずれかが登録されているか
     */
    get hasAnySpecifiedTruck(): boolean {
        return !_.isNil(this.truckHeight) || !_.isNil(this.truckWidth) || !_.isNil(this.largeTruckFlg);
    }

    /**
     * 荷姿がパレットであるか否か
     */
    get isShapePalette(): boolean {
        return this.shape?.code === BaggageShapeEnum.Palette.code;
    }

    /**
     * 荷姿がその他であるか否か
     */
    get isShapeOther(): boolean {
        return this.shape?.code === BaggageShapeEnum.Other.code;
    }

    clearSpecifiedTruck(): void {
        this.truckHeight = undefined;
        this.truckWidth = undefined;
        this.largeTruckFlg = undefined;
    }

    public normalize(): void {
        this.departureCity = this.departureCity?.trim();
        this.departureAddress = this.departureAddress?.trim();
        this.arrivalCity = this.arrivalCity?.trim();
        this.arrivalAddress = this.arrivalAddress?.trim();
        // this.baggageType = this.baggageType?.trim();
        this.truckEquipmentText = this.truckEquipmentText.trim();
        this.descriptionText = this.descriptionText.trim();
        this.staffName = this.staffName?.trim();
    }

    /**
     * {@link DeliveryOrderUpdateForm}を生成します。
     */
    toForm(): DeliveryOrderUpdateForm {
        const form = new DeliveryOrderUpdateForm(this);

        if (this.isShapePalette) {
            form.totalCount = undefined;
            form.totalVolume = undefined;
        }
        // 荷姿：その他ならパレット枚数（目安）、サイズをクリア
        if (this.isShapeOther) {
            form.paletteCount = undefined;
            form.paletteHeight = undefined;
            form.paletteWidth = undefined;
        }

        form.departurePref = { code: this.departureLocation.prefecture?.code };
        form.departureCity = this.departureLocation.city;
        form.departureAddress = this.departureLocation.address;

        form.arrivalPref = { code: this.arrivalLocation.prefecture?.code };
        form.arrivalCity = this.arrivalLocation.city;
        form.arrivalAddress = this.arrivalLocation.address;

        return form;
    }

    /**
     * {@link DeliveryOrderModel}から{@link DeliveryOrderUpdateForm}を生成します。
     */
    static of(deliveryOrder: DeliveryOrderModel): DeliveryOrderUpdateForm {
        const instance = new DeliveryOrderUpdateForm();
        // 発情報
        // 発日時は妥当性検査を経て設定
        instance.departure = deliveryOrder.departureDateTime;
        instance.departurePref = { code: deliveryOrder.departurePref.code.toString() };
        instance.departureCity = deliveryOrder.departureCity;
        instance.departureAddress = deliveryOrder.departureAddress;
        instance.departureLocation = new TmpBaggageLocationValue({
            prefecture: PrefectureEnum.valueOf(deliveryOrder.departurePref.code as PrefectureEnumCode),
            city: deliveryOrder.departureCity,
            address: deliveryOrder.departureAddress,
        });
        instance.loadingTimeNote = deliveryOrder.loadingTimeNote;
        // 着情報
        // 着日時は妥当性検査を経て設定
        instance.arrival = deliveryOrder.arrivalDateTime;
        instance.arrivalPref = { code: deliveryOrder.arrivalPref.code.toString() };
        instance.arrivalCity = deliveryOrder.arrivalCity;
        instance.arrivalAddress = deliveryOrder.arrivalAddress;
        instance.arrivalLocation = new TmpBaggageLocationValue({
            prefecture: PrefectureEnum.valueOf(deliveryOrder.arrivalPref.code as PrefectureEnumCode),
            city: deliveryOrder.arrivalCity,
            address: deliveryOrder.arrivalAddress,
        });
        instance.unloadingTimeNote = deliveryOrder.unloadingTimeNote;
        // 荷種
        instance.type = deliveryOrder.type;
        // 荷姿
        instance.shape = deliveryOrder.shape;
        // パレット情報
        instance.paletteCount = deliveryOrder.paletteCount?.toString();
        instance.paletteHeight = deliveryOrder.paletteHeight?.toString();
        instance.paletteWidth = deliveryOrder.paletteWidth?.toString();
        // その他情報
        instance.totalCount = deliveryOrder.totalCount?.toString();
        instance.totalVolume = deliveryOrder.totalVolume?.toString();
        // 総重量
        instance.totalWeight = deliveryOrder.totalWeight?.toString();
        // ドライバー作業
        instance.loading = deliveryOrder.loading;
        instance.unloading = deliveryOrder.unloading;
        instance.temperatureZone = deliveryOrder.temperatureZone;
        // トラック情報
        instance.truckWeight = { code: Util.requireNotNull(deliveryOrder.truckWeight).code.toString() };
        instance.truckModel = { code: Util.requireNotNull(deliveryOrder.truckModel).code.toString() };
        instance.truckHeight = deliveryOrder.truckHeight;
        instance.truckWidth = deliveryOrder.truckWidth;
        instance.largeTruckFlg = deliveryOrder.largeTruckFlg;
        instance.truckEquipment = deliveryOrder.truckEquipment;
        instance.freight = deliveryOrder.freight ? deliveryOrder.freight.toString() : '';
        instance.description = deliveryOrder.description;
        // 配送依頼書のヘッダー情報
        instance.createDate = deliveryOrder.createDate;
        instance.staffName = deliveryOrder.staffName;
        // 協力会社の車両・ドライバー情報
        instance.truckCompanyName = deliveryOrder.truckCompanyName;
        instance.truckNumber = deliveryOrder.truckNumber;
        instance.driverName = deliveryOrder.driverName;
        instance.driverPhoneNumber = deliveryOrder.driverPhoneNumber;
        return instance;
    }

    validator(): FormValidator<DeliveryOrderUpdateForm> {
        return {
            departureDateTimeRange: [
                {
                    required: true,
                    message: '発日時を入力してください。',
                }
            ],
            arrivalDateTimeRange: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const departureDateTime = this.departure;
                        const arrivalDateTime = this.arrival;

                        const rangeValidated = Validator.validateMultipleDateTimeRange(departureDateTime, arrivalDateTime);
                        if (!rangeValidated.result) {
                            callback(rangeValidated.message);
                            return;
                        }
                        callback();
                    }
                }
            ],
            departureLocation: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const departureLocation = _value;

                        if (!departureLocation?.value.prefecture?.code) {
                            callback('都道府県を選択してください。');
                        } else if (!departureLocation.value.city?.trim()) {
                            callback('市区町村を入力してください。');
                        } else if (departureLocation.value.city?.length > 200) {
                            callback('市区町村名は200文字以内で入力してください。');
                        } else if ((departureLocation.value.address?.length ?? 0) > 200) {
                            callback('番地・建物は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                }
            ],
            arrivalLocation: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const arrivalLocation = _value;

                        if (!arrivalLocation?.value.prefecture?.code) {
                            callback('都道府県を選択してください。');
                        } else if (!arrivalLocation.value.city?.trim()) {
                            callback('市区町村を入力してください。');
                        } else if (arrivalLocation.value.city?.length > 200) {
                            callback('市区町村名は200文字以内で入力してください。');
                        } else if ((arrivalLocation.value.address?.length ?? 0) > 200) {
                            callback('番地・建物は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                }
            ],
            loadingTimeNote: [
                {
                    max: Const.MAX_LOADING_TIME_NOTE,
                    message: `積み時間は${ Const.MAX_LOADING_TIME_NOTE }文字以内で入力してください。`,
                }
            ],
            unloadingTimeNote: [
                {
                    max: Const.MAX_UNLOADING_TIME_NOTE,
                    message: `卸し時間は${ Const.MAX_UNLOADING_TIME_NOTE }文字以内で入力してください。`,
                }
            ],
            shape: [
                {
                    required: true,
                    message: '荷姿を選択してください。',
                }
            ],
            type: [
                {
                    required: true,
                    whitespace: true,
                    message: '荷種を入力してください。',
                },
                {
                    max: 250,
                    message: '荷種は250文字以内で入力してください。',
                },
            ],
            paletteCount: [
                {
                    pattern: /^[0-9]+$/,
                    message: 'パレット枚数（目安）は数字で入力してください。',
                },
                {
                    max: Const.MAX_PALETTE_COUNT_DIGITS,
                    message: `パレット枚数（目安）は最大${ Const.MAX_PALETTE_COUNT_DIGITS }桁で入力してください。`,
                },
            ],
            paletteSize: [
                {
                    validator: (_rule, _value, callback) => {
                        const height = _value?.paletteHeight ?? 0;
                        const width = _value?.paletteWidth ?? 0;
                        if (height) {
                            if (height.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                                callback(`パレットサイズ(縦)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                                return;
                            }

                            if (!Util.isNumeric(height)) {
                                callback(`パレットサイズ(縦)は数字で入力してください。`);
                                return;
                            }
                        }
                        if (width) {
                            if (width.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                                callback(`パレットサイズ(横)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                                return;
                            }
                            if (!Util.isNumeric(width)) {
                                callback(`パレットサイズ(横)は数字で入力してください。`);
                                return;
                            }
                        }
                        callback();
                    }
                },
            ],
            totalCount: [
                {
                    pattern: /^[0-9]+$/,
                    message: '荷物の個数（目安）は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_COUNT_DIGITS,
                    message: `荷物の個数（目安）は最大${ Const.MAX_BAGGAGE_COUNT_DIGITS }桁で入力してください。`,
                },
            ],
            totalVolume: [
                {
                    pattern: /^[0-9]+$/,
                    message: '荷物の体積は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
                    message: `荷物の体積は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
                },
            ],
            totalWeight: [
                {
                    pattern: /^[0-9]+$/,
                    message: '総重量は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
                    message: `総重量は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
                },
            ],
            truckEquipment: [
                {
                    validator: (_rule, _value, callback) => {
                        const truckEquipmentText = this.truckEquipmentText?.trim() ?? '';
                        if (truckEquipmentText.length > 250) {
                            callback('250文字以内で入力してください。');
                        }
                        callback();
                    }
                }
            ],
            baggageFreight: [
                {
                    required: true,
                    message: '運賃を入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if ((_value?.value ?? 0) <= 0) {
                            callback('有効な金額を入力してください。');
                        }
                        callback();
                    }
                },
            ],
            description: [
                {
                    validator: (_rule, _value, callback) => {
                        const description = this.description?.trim() ?? '';
                        if (description.length > 2000) {
                            callback('注意事項は2000文字以内で入力してください。');
                        }
                        callback();
                    }
                },
            ],
            staffName: [
                {
                    required: true,
                    message: '担当者名を入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (this.staffName.length > 250) {
                            callback('担当者名は250文字以内で入力してください。');
                        }
                        callback();
                    }
                },
            ],
            driverPhoneNumber: [
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
        };
    }
}

export class DeliveryOrderReplyForm implements FormValidatable<DeliveryOrderReplyForm> {
    truckCompanyName?: string;
    truckNumber?: string;
    driverName?: string;
    driverPhoneNumber?: string;

    constructor(param: Partial<DeliveryOrderUpdateForm> | null = null) {
        this.truckCompanyName = param?.truckCompanyName ?? '';
        this.truckNumber = param?.truckNumber ?? '';
        this.driverName = param?.driverName ?? '';
        this.driverPhoneNumber = param?.driverPhoneNumber ?? '';
    }

    /**
     * {@link DeliveryOrderModel}から{@link DeliveryOrderReplyForm}を生成します。
     */
    static of(deliveryOrder: DeliveryOrderModel): DeliveryOrderReplyForm {
        const instance = new DeliveryOrderReplyForm();
        instance.truckCompanyName = deliveryOrder.truckCompanyName;
        instance.truckNumber = deliveryOrder.truckNumber;
        instance.driverName = deliveryOrder.driverName;
        instance.driverPhoneNumber = deliveryOrder.driverPhoneNumber;
        return instance;
    }

    validator(): FormValidator<DeliveryOrderReplyForm> {
        return {
            truckCompanyName: [
                {
                    required: true,
                    message: '会社名(実運送会社名)を入力してください。',
                }
            ],
            truckNumber: [
                {
                    required: true,
                    message: '車番を入力してください。',
                }
            ],
            driverName: [
                {
                    required: true,
                    message: 'ドライバー名を入力してください。',
                }
            ],
            driverPhoneNumber: [
                {
                    required: true,
                    message: '電話番号を入力してください。',
                },
                {
                    min: Const.MIN_PHONE_NUMBER,
                    max: Const.MAX_PHONE_NUMBER,
                    message: `電話番号は${ Const.MIN_PHONE_NUMBER }桁〜${ Const.MAX_PHONE_NUMBER }桁で入力してください。`,
                },
                {
                    pattern: Const.PHONE_NUMBER_REGEX,
                    message: '電話番号を正しい形式で入力してください。',
                },
            ],
        };
    }
}
