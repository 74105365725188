<script setup lang="ts">
import { computed } from 'vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { LinkType } from '@/_components/ui/types/link-types';
import { IconType } from '@/_components/ui/types/icon-type';

const props = defineProps<{
    type: LinkType,
}>();

const linkToIcon: { link: LinkType, icon: IconType | undefined }[] = [
    { link: 'product', icon: 'right' },
    { link: 'external', icon: 'external-link' },
];

const iconType = computed<IconType | undefined>(
    () => linkToIcon.find(map => map.link === props.type)?.icon ?? undefined
);

const showIcon = computed<boolean>(() => iconType.value !== undefined);

</script>

<template>
    <span class="text-link">
        <slot/>
        <template v-if="showIcon">
            <tbx-icon :type="iconType" class="postfix"/>
        </template>
    </span>
</template>

<style scoped lang="less">
.text-link {
    color: @token-text-color-link;
    padding-right: 4px;

    .postfix {
        position: relative;
        left: 2px;
    }

    &:hover {
        color: @token-text-color-link-hover;
        text-decoration-line: underline;
    }
}
</style>
