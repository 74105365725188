/**
 * 荷物ステータスEnum
 */
export type BaggageStatusEnumCode = 'OPENED' | 'CANCEL' | 'CLOSED' | 'EXPIRE';

export class BaggageStatusEnum {
    constructor(public code: BaggageStatusEnumCode, public label: string) {
    }

    static Opened = new BaggageStatusEnum('OPENED', '公開中');
    static Cancel = new BaggageStatusEnum('CANCEL', '取り下げ済み');
    static Closed = new BaggageStatusEnum('CLOSED', '成約済み');
    static Expire = new BaggageStatusEnum(('EXPIRE'), '期限切れ');
    static valueOf = (code: BaggageStatusEnumCode): BaggageStatusEnum | undefined =>
        BaggageStatusEnum.values.find((value) => value.code === code);
    static values = [BaggageStatusEnum.Opened, BaggageStatusEnum.Cancel, BaggageStatusEnum.Closed];

    static codeOf = (code: BaggageStatusEnumCode): BaggageStatusEnum => {
        switch (code) {
            case 'OPENED':
                return BaggageStatusEnum.Opened;
            case 'CANCEL':
                return BaggageStatusEnum.Cancel;
            case 'CLOSED':
                return BaggageStatusEnum.Closed;
            case 'EXPIRE':
                return BaggageStatusEnum.Expire;
        }
    };

    /**
     * 公開中であるか否かを取得します。
     */
    isOpened(): boolean {
        return this.code === BaggageStatusEnum.Opened.code;
    }

    /**
     * 取り下げ済みであるか否かを取得します。
     */
    isCancel(): boolean {
        return this.code === BaggageStatusEnum.Cancel.code;
    }

    /**
     * 成約済みであるか否かを取得します。
     */
    isClosed(): boolean {
        return this.code === BaggageStatusEnum.Closed.code;
    }
}
