<script setup lang="ts">
/**
  * DateTimeRangePickerをDeliveryDateTimeRangeで利用するためのラッパー
  */
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import { Dayjs } from 'dayjs';
import { DeliveryDateTimeRange } from '@/models/vo/delivery-datetime-range';
import DateTimeRangePicker from '@/_components/ui/DateTimeRangePicker.vue';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    value?: DeliveryDateTimeRange,
    size?: 'default' | 'large' | 'small', // select メニューのサイズ
    allowClear?: boolean,  // 選択解除を許容するか否か。
    placeholder?: string,  // select メニューのプレースホルダ
    placement?: string,  // ドロップダウン内のコンテンツを表示する位置
    disabledDate?: (currentDate: Dayjs) => boolean, // 指定した日付を選択できないようにする関数をバインドできる
    validRange?: [Dayjs | undefined, Dayjs | undefined], // カレンダーで選択できる範囲。制限を設けない場合はundefined
    disabled?: boolean, // ドロップダウンを無効（非活性）にするか否か。
    onlyJustTime?: boolean,
    borderLeft?: boolean,
    borderRight?: boolean,
    arrow?: boolean,
}>(), {
    // 未指定時のデフォルト値をDateTimePickerに合わせる
    borderLeft: true,
    borderRight: true,
});

const emit = defineEmits<{
    (e: 'input', value: DeliveryDateTimeRange | undefined): void,
    (e: 'change', value: DeliveryDateTimeRange | undefined): void,
}>();

const mapToDeliveryDateTimeRange = (value: DateTimeRangePickerValue): DeliveryDateTimeRange | undefined => {
    if (value.min && value.max && value.type) {
        return DeliveryDateTimeRange.typeOf(value.type, value.min, value.max) ?? undefined;
    } else {
        return undefined;
    }
};

const datetime = computed<DateTimeRangePickerValue | undefined>({
    get: () => {
        if (!props.value) return undefined;
        const [min, max] = props.value.rawValuesAsString();
        return { min, max, type: props.value?.type };
    },
    set: (value) => {
        emit('input', value ? mapToDeliveryDateTimeRange(value) : undefined);
    }
});

const change = (value: DateTimeRangePickerValue) => {
    emit('change', mapToDeliveryDateTimeRange(value));
};

</script>

<template>
    <date-time-range-picker v-model="datetime"
                      :size="size"
                      :allow-clear="allowClear"
                      :placeholder="placeholder"
                      :placement="placement"
                      :disabled-date="disabledDate"
                      :valid-range="validRange"
                      :disabled="disabled"
                      :only-just-time="onlyJustTime"
                      :border-right="borderRight"
                      :border-left="borderLeft"
                      :arrow="arrow"
                      @change="change"/>
</template>

<style scoped lang="less">
</style>
