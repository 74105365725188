<script setup lang="ts">
import { FreightValue } from '@/models/vo/freight';
import { computed } from 'vue';
import { Util } from '@/util';
import _ from 'lodash';
import { useFormModelItem } from '@/composables/form-helper';

const props = withDefaults(defineProps<{
    value: FreightValue | undefined,
    propName: string,
    label?: string,
}>(), {
    label: '運賃',
});
const emits = defineEmits<{
    (e: 'input', value: FreightValue | undefined): void,
}>();

const freight = computed<string>({
    get: (): string => props.value?.value?.toString() ?? '',
    set: (value: string) => {
        if (_.isEmpty(value)) {
            emits('input', undefined);
        } else {
            const freightString = Util.parseFreightString(value);
            const freight = new FreightValue(Util.toNumber(freightString));
            emits('input', freight);
        }
    },
});

const checked = computed<boolean>({
    get: (): boolean => props.value?.isNegotiate ?? false,
    set: (value: boolean) => {
        if (value) {
            const freight = new FreightValue(undefined);
            emits('input', freight);
        } else {
            emits('input', undefined);
        }
    },
});

const { formModelItem, onChange } = useFormModelItem();
</script>

<template>
    <a-form-model-item :label="label"
                       :auto-link="false"
                       :prop="propName"
                       ref="formModelItem">
        <a-space size="middle">
            <a-input style="max-width: 160px"
                     suffix="円"
                     placeholder="税別価格"
                     :disabled="checked"
                     v-model="freight"
                     @change="onChange"
            >
            </a-input>
            <a-checkbox v-model="checked" @change="onChange">要相談</a-checkbox>
        </a-space>
    </a-form-model-item>
</template>

<style scoped lang="less">

</style>
