<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import PaginationControl from '@/_components/ui/PaginationControl.vue';
import { useCompanyTruckListHelper } from '@/_pages/Truck/CompanyTruck/List/company-truck-list-helper';
import { columns } from './table-definition';
import { useRouting } from '@/composables/helper/routing';

const {
    loading,
    pageInfo,
    searchResultsRef,
    onChangePage,
    companyTruckListData,
    onClickRegisterEmptyTruck,
    onClickEdit,
    onClickCopy,
    onClickDelete,
} = useCompanyTruckListHelper();
const { goToCompanyTruckRegister } = useRouting();

</script>

<template>
    <page-layout :show-header="false" :back-icon="false">
        <div class="board-container">
            <a-card ref="searchResultsRef" class="search-result-container" :body-style="{ padding: 0 }">
                <a-spin :spinning="loading">
                    <a-card :bodyStyle="{ padding: 0 }">
                        <div class="header-action-area table-nav-pagination">
                                <a-button type="primary"
                                          icon="plus"
                                          @click="goToCompanyTruckRegister">
                                    車両追加
                                </a-button>
                                <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                        <a-table class="app-result-list-table"
                                 bordered
                                 :columns="columns"
                                 :data-source="companyTruckListData"
                                 row-key="id"
                                 :pagination="false"
                                 :row-class-name="() => 'app-table-row'">
                            <template v-slot:carNumber="text, record">
                                <div class="table-row-ellipsis-text">{{ record.carNumber }}</div>
                            </template>
                            <template v-slot:truckWeight="text, record">
                                <div class="table-row-ellipsis-text">{{ record.truckWeight.label ?? '' }}</div>
                            </template>
                            <template v-slot:truckModel="text, record">
                                <div class="table-row-ellipsis-text">{{ record.truckModel.label ?? '' }}</div>
                            </template>
                            <template v-slot:driverName="text, record">
                                <div class="table-row-ellipsis-text">{{ record.driverName ?? '' }}</div>
                            </template>
                            <template v-slot:driverPhoneNumber="text, record">
                                <div class="table-row-ellipsis-text">{{ record.driverPhoneNumber ?? '' }}</div>
                            </template>
                            <template v-slot:description="text, record">
                                <a-tooltip :title="record.description" placement="bottom"
                                           :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap'}">
                                    <div class="table-row-ellipsis-text">{{ record.description ?? '' }}</div>
                                </a-tooltip>
                                <!-- NOTE: 以下のアクションボタン群の要素は、テーブルセルの最も右端のcolumnに配置することでスタイルが当たるようになっている -->
                                <!-- CSS自体は ant-table 自体にもスタイルを当てる必要があったため、global.less に配置している -->
                                <nav class="app-table-row__actions">
                                    <ul>
                                        <li>
                                            <a-tooltip title="この車両を利用して空車を登録します">
                                                <a-button icon="plus"
                                                          @click="onClickRegisterEmptyTruck(record.id)">空車登録
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                        <li>
                                            <a-tooltip title="この車両の内容を変更します">
                                                <a-button icon="edit" @click="onClickEdit(record.id)">変更
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                        <li>
                                            <a-tooltip title="この車両を元にして新しく車両を登録します">
                                                <a-button icon="copy" @click="onClickCopy(record.id)">コピー
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                        <li>
                                            <a-tooltip placement="topRight" title="この車両を削除します">
                                                <a-button icon="delete"
                                                          type="danger"
                                                          ghost
                                                          @click="onClickDelete(record.id)">削除
                                                </a-button>
                                            </a-tooltip>
                                        </li>
                                    </ul>
                                </nav>
                            </template>
                        </a-table>
                        <div class="table-nav-pagination">
                            <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                        </div>
                    </a-card>
                </a-spin>
            </a-card>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
//pages/Truck/List/style.less
.board-container {
    padding: 24px;
}

.header-action-area {
    display: flex;
    justify-content: space-between;
}

// components/Truck/View/List/style.less
.table-nav-pagination {
    padding: 24px;
    background: @component-background;

    .ant-pagination {
        display: flex;
        justify-content: flex-end;

        > .ant-pagination-options {
            order: 1;
        }

        > :not(.ant-pagination-options) {
            order: 2;
        }
    }
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}
</style>
