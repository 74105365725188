export type CompanyStatusCode = 'TMP' | 'JUD' | 'ACT' | 'LFT' | 'REJ';

export interface CompanyStatus {
    code: CompanyStatusCode;
    label: string;
}

export class CompanyStatusModel implements CompanyStatus {
    code: CompanyStatusCode;
    label: string;

    constructor(data: CompanyStatus) {
        this.code = data.code;
        this.label = data.label;
    }

    /**
     * 企業ステータスが審査中か否かを取得します。
     */
    get isJudging(): boolean {
        return this.code === 'JUD' ?? false;
    }

    /**
     * 企業ステータスが有効か否かを取得します。
     */
    get isActive(): boolean {
        return this.code === 'ACT' ?? false;
    }

}
