<script setup lang="ts">
import DeliveryOrderSheet from '@/_pages/DeliveryOrder/components/DeliveryOrderSheet.vue';
import { useDeliveryOrderPrintHelper } from '@/_pages/DeliveryOrder/Print/delivery-order-print-helper';

const { doc } = useDeliveryOrderPrintHelper();

</script>

<template>
    <div class="page print-container">
        <delivery-order-sheet
            v-if="doc"
            :delivery-order="doc.content"
            :my-company-profile="doc.myCompanyProfile"
            :partner-company-profile="doc.partnerCompanyProfile"
        />
    </div>
</template>

<style scoped lang="less">
@import '../../../../less/common-styles/print-layout.less';

.page {
    page: page-style-portrait;
}
</style>
