import { BaggageDetailContainer } from '@/vuex/modules/baggage/baggage-detail-container';
import {
    Baggage,
    BaggageFreightMaster,
    BaggageList,
    BaggageRecommendReturnResult,
    BaggageSearchCondition,
    BaggageSearchContainer,
    BaggageSearchExcludedCompany,
    BaggageUpdateForm,
    BaggageViewMemberCount
} from '@/models/baggage';
import { TruckWithCompanyList } from '@/models/truck';

//
// Action
//
export class ACTION {
    static readonly INIT_SEARCH_CONTAINERS = 'INIT_SEARCH_CONTAINERS';
    static readonly FIRST_SEARCH = 'FIRST_SEARCH';
    static readonly CLEAR_SEARCH_CONTAINERS = 'CLEAR_SEARCH_CONTAINERS';
    static readonly UPDATE_BAGGAGE_SEARCH_FORM = 'UPDATE_BAGGAGE_SEARCH_FORM';
    static readonly RESET_BAGGAGE_SEARCH_FORM = 'RESET_BAGGAGE_SEARCH_FORM';
    static readonly RESTORE_BAGGAGE_SEARCH_FORM = 'RESTORE_BAGGAGE_SEARCH_FORM';
    static readonly UPDATE_BAGGAGE_SEARCH_SORT_KEY = 'UPDATE_BAGGAGE_SEARCH_SORT_KEY';
    static readonly UPDATE_BAGGAGE_SEARCH_SORT_DIRECTION = 'UPDATE_BAGGAGE_SEARCH_SORT_DIRECTION';
    static readonly RELOAD_SEARCH_RESULT = 'RELOAD_SEARCH_RESULT';
    static readonly CHANGE_SEARCH_RESULT_PAGE = 'CHANGE_SEARCH_RESULT_PAGE';
    static readonly LOAD_SEARCH_BAGGAGE_DETAIL_CONTAINER = 'LOAD_SEARCH_BAGGAGE_DETAIL_CONTAINER';
    static readonly CLEAR_SEARCH_BAGGAGE_DETAIL_CONTAINER = 'CLEAR_SEARCH_BAGGAGE_DETAIL_CONTAINER';
    static readonly LOAD_BAGGAGE = 'LOAD_BAGGAGE';
    static readonly LOAD_MY_BAGGAGE = 'LOAD_MY_BAGGAGE';
    static readonly CLEAR_BAGGAGE = 'CLEAR_BAGGAGE';
    static readonly LIST_BAGGAGE = 'LIST_BAGGAGE';
    static readonly CLEAR_BAGGAGE_LIST = 'CLEAR_BAGGAGE_LIST';
    static readonly SEARCH_BAGGAGE = 'SEARCH_BAGGAGE';
    static readonly START_AUTO_SEARCH_BAGGAGE = 'START_AUTO_SEARCH_BAGGAGE';
    static readonly STOP_AUTO_SEARCH_BAGGAGE = 'STOP_AUTO_SEARCH_BAGGAGE';
    static readonly REGISTER_BAGGAGE = 'REGISTER_BAGGAGE';
    static readonly UPDATE_BAGGAGE = 'UPDATE_BAGGAGE';
    static readonly CANCEL_BAGGAGE = 'CANCEL_BAGGAGE';
    static readonly LOAD_BAGGAGE_FORM_SEARCH_CONDITION = 'LOAD_BAGGAGE_FORM_SEARCH_CONDITION';
    static readonly SAVE_BAGGAGE_SEARCH_CONDITION = 'SAVE_BAGGAGE_SEARCH_CONDITION';
    static readonly UPDATE_BAGGAGE_SEARCH_CONDITION_NOTIFICATION = 'UPDATE_BAGGAGE_SEARCH_CONDITION_NOTIFICATION';
    static readonly DELETE_BAGGAGE_SEARCH_CONDITION = 'DELETE_BAGGAGE_SEARCH_CONDITION';
    static readonly MARK_FAVORITE = 'MARK_FAVORITE';
    static readonly UNMARK_FAVORITE = 'UNMARK_FAVORITE';
    static readonly MARK_UNDER_NEGOTIATION = 'MARK_UNDER_NEGOTIATION';
    static readonly UNMARK_UNDER_NEGOTIATION = 'UNMARK_UNDER_NEGOTIATION';
    static readonly CLEAN_FAVORITE_BAGGAGES = 'CLEAN_FAVORITE_BAGGAGES';
    static readonly QUERY_FAVORITE = 'QUERY_FAVORITE';
    static readonly LOAD_FAVORITE_BAGGAGE_LIST = 'LOAD_FAVORITE_BAGGAGE_LIST';
    static readonly LOAD_RECENT_BAGGAGE_READ_LIST = 'LOAD_RECENT_BAGGAGE_READ_LIST';
    static readonly LOAD_FAVORITE_BAGGAGE_COUNT = 'LOAD_FAVORITE_BAGGAGE_COUNT';
    static readonly LOAD_BAGGAGE_VIEW_MEMBER_COUNT_LIST = 'LOAD_BAGGAGE_VIEW_MEMBER_COUNT_LIST';
    static readonly START_WATCHING_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST = 'START_WATCHING_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST';
    static readonly LOAD_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST = 'LOAD_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST';
    static readonly ADD_BAGGAGE_SEARCH_EXCLUDED_COMPANY = 'ADD_BAGGAGE_SEARCH_EXCLUDED_COMPANY';
    static readonly REMOVE_BAGGAGE_SEARCH_EXCLUDED_COMPANY = 'REMOVE_BAGGAGE_SEARCH_EXCLUDED_COMPANY';
    static readonly MARK_READ = 'MARK_READ';
    static readonly QUERY_READ = 'QUERY_READ';
    static readonly LOAD_BAGGAGE_REGISTER_REFERENCE_FREIGHT = 'LOAD_BAGGAGE_REGISTER_REFERENCE_FREIGHT';
    static readonly CLEAR_BAGGAGE_REGISTER_REFERENCE_FREIGHT = 'CLEAR_BAGGAGE_REGISTER_REFERENCE_FREIGHT';
    static readonly LOAD_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST = 'LOAD_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly CLEAR_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST = 'CLEAR_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly LOAD_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST = 'LOAD_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly CLEAR_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST = 'CLEAR_RECENT_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly LOAD_BAGGAGE_DETAIL_REFERENCE_FREIGHT = 'LOAD_BAGGAGE_DETAIL_REFERENCE_FREIGHT';
    static readonly CLEAR_BAGGAGE_DETAIL_REFERENCE_FREIGHT = 'CLEAR_BAGGAGE_DETAIL_REFERENCE_FREIGHT';
    static readonly LOAD_TRUCK_RECOMMEND_LIST = 'LOAD_TRUCK_RECOMMEND_LIST';
    static readonly CLEAR_TRUCK_RECOMMEND_LIST = 'CLEAR_TRUCK_RECOMMEND_LIST';
    static readonly LOAD_BAGGAGE_RECOMMENDATIONS = 'LOAD_BAGGAGE_RECOMMENDATIONS';
}

//
// Signature for Action
//
export type updateBaggage = (param: { id: number; form: BaggageUpdateForm }) => Promise<void>;

//
// Getter
//
export class GETTER {
    static readonly SEARCH_CONTAINERS = 'SEARCH_CONTAINERS';
    static readonly LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS = 'LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS';
    static readonly SEARCH_BAGGAGE_DETAIL_CONTAINER = 'SEARCH_BAGGAGE_DETAIL_CONTAINER';
    static readonly BAGGAGE = 'BAGGAGE';
    static readonly BAGGAGE_LIST = 'BAGGAGE_LIST';
    static readonly BAGGAGE_FORM_SEARCH_CONDITION_LIST = 'BAGGAGE_FORM_SEARCH_CONDITION_LIST';
    static readonly FAVORITE_BAGGAGE_ID_LIST = 'FAVORITE_BAGGAGE_ID_LIST';
    static readonly MARKED_AS_FAVORITE_BAGGAGE = 'MARKED_AS_FAVORITE_BAGGAGE';
    static readonly FAVORITE_BAGGAGE_COUNT = 'FAVORITE_BAGGAGE_COUNT';
    static readonly BAGGAGE_VIEW_MEMBER_COUNT_LIST = 'BAGGAGE_VIEW_MEMBER_COUNT_LIST';
    static readonly BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST = 'BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST';
    static readonly READ_BAGGAGE_ID_LIST = 'READ_BAGGAGE_ID_LIST';
    static readonly BAGGAGE_REGISTER_REFERENCE_FREIGHT = 'BAGGAGE_REGISTER_REFERENCE_FREIGHT';
    static readonly FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST = 'FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly BAGGAGE_DETAIL_REFERENCE_FREIGHT = 'BAGGAGE_DETAIL_REFERENCE_FREIGHT';
    static readonly TRUCK_RECOMMEND_LIST = 'TRUCK_RECOMMEND_LIST';
    static readonly BAGGAGE_RECOMMENDATIONS = 'BAGGAGE_RECOMMENDATIONS';
}

//
// Mutation
//
export class MUTATION {
    static readonly SET_SEARCH_CONTAINERS = 'SET_SEARCH_CONTAINERS';
    static readonly SET_BAGGAGE_SEARCH_FORM = 'SET_BAGGAGE_SEARCH_FORM';
    static readonly SET_BAGGAGE_SEARCHING = 'SET_BAGGAGE_SEARCH_LOADING';
    static readonly SET_BAGGAGE_SEARCH_RESULT = 'SET_BAGGAGE_SEARCH_RESULT';
    static readonly SET_LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS = 'SET_LOADING_SAVED_BAGGAGE_SEARCH_CONDITIONS';
    static readonly SET_FAVORITE_BAGGAGE_IDS = 'SET_FAVORITE_BAGGAGE_IDS';
    static readonly SET_UNDER_NEGOTIATION_FLG = 'SET_UNDER_NEGOTIATION_FLG';
    static readonly SET_SEARCH_BAGGAGE_DETAIL_CONTAINER = 'SET_SEARCH_BAGGAGE_DETAIL_CONTAINER';
    static readonly SET_BAGGAGE_SEARCH_INTERVAL_ID = 'SET_BAGGAGE_SEARCH_INTERVAL_ID';
    static readonly SET_BAGGAGE = 'SET_BAGGAGE';
    static readonly SET_BAGGAGE_LIST = 'SET_BAGGAGE_LIST';
    static readonly SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST = 'SET_BAGGAGE_FORM_SEARCH_CONDITION_LIST';
    static readonly SET_FAVORITE_BAGGAGE_ID_LIST = 'SET_FAVORITE_BAGGAGE_ID_LIST';
    static readonly SET_MARKED_AS_FAVORITE_BAGGAGE = 'SET_MARKED_AS_FAVORITE_BAGGAGE';
    static readonly SET_FAVORITE_BAGGAGE_COUNT = 'SET_FAVORITE_BAGGAGE_COUNT';
    static readonly SET_BAGGAGE_VIEW_MEMBER_COUNT_LIST = 'SET_BAGGAGE_VIEW_MEMBER_COUNT_LIST';
    static readonly SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST = 'SET_BAGGAGE_SEARCH_EXCLUDED_COMPANY_LIST';
    static readonly SET_READ_BAGGAGE_IDS = 'SET_READ_BAGGAGE_IDS';
    static readonly SET_READ_BAGGAGE_ID_LIST = 'SET_READ_BAGGAGE_ID_LIST';
    static readonly SET_BAGGAGE_REGISTER_REFERENCE_FREIGHT = 'SET_BAGGAGE_REGISTER_REFERENCE_FREIGHT';
    static readonly SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST = 'SET_FAVORITE_BAGGAGE_REFERENCE_FREIGHT_LIST';
    static readonly SET_RECENT_BAGGAGE_READ_REFERENCE_FREIGHT_LIST = 'SET_RECENT_BAGGAGE_READ_REFERENCE_FREIGHT_LIST';
    static readonly SET_BAGGAGE_DETAIL_REFERENCE_FREIGHT = 'SET_BAGGAGE_SEARCH_DRAWER_REFERENCE_FREIGHT';
    static readonly SET_TRUCK_RECOMMEND_LIST = 'SET_TRUCK_RECOMMEND_LIST';
    static readonly SET_BAGGAGE_RECOMMENDATIONS = 'SET_BAGGAGE_RECOMMENDATIONS';
}

//
// Store
//
export interface BaggageState {
    // 荷物検索コンテナリスト
    baggageSearchContainers: BaggageSearchContainer[];
    // 荷物検索ページの荷物詳細コンテナ
    baggageSearchDetailContainer?: BaggageDetailContainer;
    // よく使う荷物検索のロード中フラグ
    loadingSavedBaggageSearchConditions: boolean;
    baggage?: Baggage;
    baggageList: BaggageList;
    searchConditionList: BaggageSearchCondition[];
    favoriteBaggageIdList: number[];
    readBaggageIdList: number[];
    markedAsFavoriteBaggage?: boolean;
    favoriteBaggageCount?: number;
    baggageViewMemberCount: BaggageViewMemberCount[];
    baggageSearchExcludedCompanyList: BaggageSearchExcludedCompany[];
    // 荷物登録-参考運賃
    baggageRegisterReferenceFreight?: BaggageFreightMaster;
    // 保存した荷物-参考運賃
    favoriteBaggageReferenceFreightList: BaggageFreightMaster[];
    // 最近見た荷物一覧-参考運賃
    recentBaggageReferenceFreightList: BaggageFreightMaster[];
    // 荷物検索/保存した荷物ドロワー-参考運賃
    baggageDetailReferenceFreight?: BaggageFreightMaster;
    // 空車レコメンド
    truckRecommends?: TruckWithCompanyList;
    baggageRecommendations?: BaggageRecommendReturnResult;
}

//
// Type for store
//
export * from '@/models/baggage';
