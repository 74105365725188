import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyEmployeeNumberEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileExtraUpdateForm;

    /**
     * 従業員数
     */
    get employeesNumber(): string {
        return this.value?.employeesNumber?.code ?? '';
    }

    set employeesNumber(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.employeesNumber = { code: newValue };
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get employeeNumbers(): Array<{ value: string; label: string; key: string }> {
        return Const.employeeNumbers.map((each) => ({
            value: each.code,
            label: each.label,
            key: each.code,
        }));
    }
}
