import { computed } from 'vue';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useGlobalStatistics } from '@/composables/global/global-statistics';

export const useGlobalStatisticsService = () => {
    const { state: { isJudging } } = useCompanyMyStatus();
    const { state: { statistics } } = useGlobalStatistics();

    /**
     * 荷物数/空車数統計を表示するべきか否かを取得します。
     */
    const shouldShowGlobalStatistics = computed<boolean>(() => {
        // 企業が審査中なら非表示
        return !isJudging.value;
    });

    return {
        state: {
            statistics,
            shouldShowGlobalStatistics,
        },
    };

};
