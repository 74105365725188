import router from '@/router';
import { Route } from 'vue-router/types/router';
import _ from 'lodash';
import { PageUtil } from '@/util';

// ======================================================
// Routing Helpers
// ======================================================
/**
 * パラメータ無しの現在Pathへ遷移します。
 */
export const goToRoot = (route: Route): Promise<Route> => router.push({ path: route.path });

/**
 * 荷物登録画面へ遷移します。
 */
export const goToRegisterBaggage = (id: number): Promise<Route> =>
    router.push({ path: '/baggage/register', query: { sourceBaggageId: id.toString() } });

/**
 * 荷物印刷画面を開きます。
 */
export const openPrintBaggagePage = (id: number): void => {
    const route = router.resolve({ name: 'BaggagePrint', params: { baggageId: id.toString() } });
    window.open(route.href, '_blank');
};

// ======================================================
// Convenient Helpers
// ======================================================
const delay = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

/**
 * 指定した時間の遅延を伴うPromiseを生成します。
 */
export const lazy = (func: () => Promise<unknown>, latency = 300): Promise<unknown> => new Promise(resolve => {
    // noinspection JSIgnoredPromiseFromCall
    func();
    delay(latency).then(resolve);
});

/**
 * 指定されたフィールドをコピーします。
 */
export const copy = (to: unknown, from: unknown, fields: string[] = _.keys(from)): void => {
    // @ts-ignore
    Object.assign(to, _.pick(from, fields));
};

/**
 * 検索結果リストの先頭へスクロールします。
 */
export const scrollToTopOfResultList = (): void => {
    PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
};
