<script setup lang="ts">
import { useRouting } from '@/composables/helper/routing';
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';

const { goToLogin } = useRouting();
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <h1 class="body__title">パスワードの再設定が完了しました</h1>
            <p>新しいパスワードでログインしてください。</p>
            <nav class="actions">
                <a-button type="primary" @click="() => goToLogin()">ログインページへ移動する</a-button>
            </nav>
        </div>
    </pre-login-layout>
</template>

<style scoped lang="less">
.body__container {
    width: 400px;
    min-height: 288px;
}

.body__title {
    margin-bottom: @padding-lg;
    font-size: 18px;
    line-height: 30px;
    color: @token-text-color-sub1;
}

.actions {
    margin: (@padding-lg * 2) auto 0;
    text-align: center;
}
</style>
