import { FormModel } from 'ant-design-vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import * as accountTypes from '@/vuex/modules/account/types';
import * as companyTypes from '@/vuex/modules/company/types';
import * as helpTypes from '@/vuex/modules/help/types';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
// @ts-ignore
import CompanyNameEdit from '@/components/ReportLatePayment/Edit/TargetCompanyName';
// @ts-ignore
import DeliveryDateEdit from '@/components/ReportLatePayment/Edit/DeliveryDate';
// @ts-ignore
import FreightEdit from '@/components/ReportLatePayment/Edit/Freight';
// @ts-ignore
import PaymentDateEdit from '@/components/ReportLatePayment/Edit/PaymentDate';
// @ts-ignore
import DescriptionEdit from '@/components/ReportLatePayment/Edit/Description';
// @ts-ignore
import CompanyName from '@/components/ReportLatePayment/View/TargetCompanyName';
// @ts-ignore
import DeliveryDate from '@/components/ReportLatePayment/View/DeliveryDate';
// @ts-ignore
import Freight from '@/components/ReportLatePayment/View/Freight';
// @ts-ignore
import PaymentDate from '@/components/ReportLatePayment/View/PaymentDate';
// @ts-ignore
import Description from '@/components/ReportLatePayment/View/Description';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { PageUtil } from '@/util';

type PageState = 'form' | 'confirm' | 'complete';

const accountMod = namespace('account');
const companyMod = namespace('company');

@Component({
    title: '入金遅延連絡',
    components: {
        CompanyNameEdit,
        DeliveryDateEdit,
        FreightEdit,
        PaymentDateEdit,
        DescriptionEdit,
        CompanyName,
        DeliveryDate,
        Freight,
        PaymentDate,
        Description,
    },
    beforeRouteEnter: ReportLatePaymentPage.beforeRouteEnter,
})
export default class ReportLatePaymentPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @accountMod.Getter(accountTypes.GETTER.PROFILE)
    readonly ACCOUNT_PROFILE?: AccountProfileModel;
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly COMPANY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    subTitle = '取引先からの入金の遅れをトラボックスお客様相談室へ連絡します。';
    loading = false;
    dirty = false;

    form: helpTypes.ReportLatePaymentForm = {
        description: '',
    };

    private currentState: PageState = 'form';

    get state(): PageState {
        return this.currentState;
    }

    set state(newValue: PageState) {
        this.currentState = newValue;
        PageUtil.scrollToContentTop(0, false);
    }

    // ======================================================
    // Functions
    // ======================================================
    @Watch('form')
    onFormChange(): void {
        this.dirty = true;
    }

    /**
     * 確認ボタンが押下された際に呼び出される。
     */
    onConfirm(): void {
        const formModel = this.$refs.formModel as FormModel;
        formModel.validate((result) => {
            if (!result) return;
            this.state = 'confirm';
        });
    }

    /**
     * 戻るボタンが押下された際に呼び出される。
     */
    onBack(): void {
        this.state = 'form';
    }

    /**
     * 送信ボタンが押下された際に呼び出される。
     */
    async onSubmit(): Promise<void> {
        const notifyFailedKey = 'REPORT_UNPAID_SUBMIT_FAILED';
        const onSuccess = async () => (this.state = 'complete');

        const onError = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: '入金遅延連絡の送信ができませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        this.loading = true;
        this.$notification.close(notifyFailedKey);

        await ReportLatePaymentPage.reportUnpaid(this.form).then(onSuccess).catch(onError);

        this.dirty = false;
        this.loading = false;
    }

    async beforeRouteLeave(
        _to: VueRoute,
        _: VueRoute,
        next: NavigationGuardNext<ReportLatePaymentPage>
    ): Promise<void> {
        if (this.dirty && !confirm('画面を移動すると編集中の内容は失われます。よろしいですか？')) {
            next(false);
        } else {
            next();
        }
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<ReportLatePaymentPage>
    ): Promise<void> {
        await ReportLatePaymentPage.loadMyProfile()
            .then(() => next())
            .catch(() => next({ name: 'NotFound' }));
    }

    /**
     * 自分の企業プロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${companyTypes.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * 入金遅延連絡を送信します。
     */
    private static reportUnpaid(param: helpTypes.ReportLatePaymentForm): Promise<void> {
        return store.dispatch(`help/${helpTypes.ACTION.REPORT_LATE_PAYMENT}`, param);
    }
}
