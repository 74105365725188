import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { accountApi } from '@/repository/api/api';
import {
    AccountChangeEmailVerificationForm,
    AccountPasswordRemindForm,
    AccountPasswordResetForm,
    AccountPasswordResetVerificationForm,
    AccountPasswordUpdateForm,
    AccountProfileCreateForm,
    AccountProfileCreateOrUpdateForm,
    AccountRegisterForm,
    AccountState,
    AccountVerificationForm,
    ACTION as a,
    GETTER as g,
    LoginForm,
    MUTATION as m,
    Profile,
} from './types';
import { AccountProfileModel } from '@/vuex/modules/account/account-profile.model';
import { AccountProfileListModel } from '@/vuex/modules/account/account-profile-list.model';

const state: AccountState = {
    profile: undefined,
    profileList: undefined,
};

const actions: ActionTree<AccountState, void> = {
    /**
     * トラボックス配車用のSSOトークンを発行します。
     */
    [a.ISSUE_SSO_DISPATCH_TOKEN]: () => accountApi.issueSsoDispatchToken(),
    /**
     * トラボックス受発注用のSSOトークンを発行します。
     */
    [a.ISSUE_SSO_ONLINE_ORDER_TOKEN]: () => accountApi.issueSsoOnlineOrderToken(),
    /**
     * 新規登録します。
     */
    [a.REGISTER]: (_, param: AccountRegisterForm) => accountApi.register(param),
    /**
     * 本人認証します。
     */
    [a.VERIFY]: (_, param: AccountVerificationForm) => accountApi.verify(param),
    /**
     * 追加アカウントの本人認証をします。
     */
    [a.VERIFY_FOR_ADDITIONAL_ACCOUNT]: (_, param: AccountVerificationForm) =>
        accountApi.verifyForAdditionalAccount(param),
    /**
     * メールアドレス認証をします。
     */
    [a.VERIFY_EMAIL_CHANGE]: (_, param: AccountChangeEmailVerificationForm) => accountApi.verifyEmail(param),
    /**
     * パスワードリセットを行うための認証コードの検証をします。
     */
    [a.VERIFY_RESET_PASSWORD]: (_, param: AccountPasswordResetVerificationForm) =>
        accountApi.verifyAccountPasswordResetCode(param),
    /**
     * 新しいパスワードを設定します。
     */
    [a.RESET_PASSWORD]: (_, param: AccountPasswordResetForm) => accountApi.resetPassword(param),
    /**
     * ログインします。
     */
    [a.LOGIN]: (_, param: LoginForm) => {
        const email = param.emailAddress;
        const password = param.password;

        if (!email || !password) return Promise.reject();

        return accountApi.login(email, password, '');
    },
    /**
     * ログアウトします。
     */
    [a.LOGOUT]: () => accountApi.logout(),
    /**
     * 自分の会員プロフィールをロードします。
     */
    [a.LOAD_MY_PROFILE]: ({ commit }) => accountApi.myProfile().then((profile) => commit(m.SET_PROFILE_MODEL, profile)),
    /**
     * 企業のアカウント一覧（プロフィール一覧）をロードします。
     */
    [a.LOAD_PROFILE_LIST]: ({ commit }) =>
        accountApi.profileList().then((list) => commit(m.SET_PROFILE_LIST_MODEL, list)),
    /**
     * 会員パスワードを更新します。
     */
    [a.UPDATE_PASSWORD]: (_, param: AccountPasswordUpdateForm) => accountApi.updatePassword(param),
    /**
     * 会員パスワードの再設定依頼を行います。
     */
    [a.REMIND_PASSWORD]: (_, param: AccountPasswordRemindForm) => accountApi.remindPassword(param),
    /**
     * アカウント（プロフィール）を新規作成します。
     */
    [a.CREATE_PROFILE]: ({ commit }, param: AccountProfileCreateForm) =>
        accountApi
            .createProfile(param)
            .then( async (result) => {
                await accountApi.profileList()
                    .then((profileList) => commit(m.SET_PROFILE_LIST_MODEL, profileList));
                return result;
            }),
    /**
     * アカウント（プロフィール）を更新します。
     */
    [a.UPDATE_PROFILE]: ({ commit }, param: AccountProfileCreateOrUpdateForm) =>
        accountApi
            .updateProfile(param)
            .then(() => accountApi.myProfile())
            .then((profile) => commit(m.SET_PROFILE_MODEL, profile)),
    /**
     * アカウント（プロフィール）を削除します。
     */
    [a.DELETE_PROFILE]: ({ commit }, id: number) =>
        accountApi
            .deleteProfile(id)
            .then(() => accountApi.profileList())
            .then((profileList) => commit(m.SET_PROFILE_LIST_MODEL, profileList)),
};

const getters: GetterTree<AccountState, void> = {
    /**
     * 会員プロフィールを取得します。
     */
    [g.PROFILE]: (s) => s.profile,
    /**
     * アカウント一覧（プロフィール一覧）を取得します。
     */
    [g.PROFILE_LIST]: (s) => s.profileList,
};

const mutations: MutationTree<AccountState> = {
    /**
     * 会員プロフィールを設定します。
     *
     * @param profile 会員プロフィール
     */
    [m.SET_PROFILE_MODEL]: (s, profile: Profile) => (s.profile = new AccountProfileModel(profile)),
    /**
     * アカウント一覧（プロフィール一覧）を設定します。
     *
     * @param list アカウント一覧（プロフィール一覧）
     */
    [m.SET_PROFILE_LIST_MODEL]: (s, list: Profile[]) => (s.profileList = new AccountProfileListModel(list)),
};

export const account: Module<AccountState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
