<script setup lang="ts">
import { useCompanyTruckEventMemoRegister } from '@/composables/company-truck-event';
import { useFormModel } from '@/composables/form-helper';
import { onBeforeMount } from 'vue';
import { Dayjs } from 'dayjs';

const props = defineProps<{
    startTm: Dayjs,
    endTm: Dayjs,
    companyTruckId: number,
}>();

const emit = defineEmits<{
    (e: 'ok'): void;
    (e: 'cancel'): void;
}>();

const { state: { form, formValidateRules }, register } = useCompanyTruckEventMemoRegister();
const { formModel, submit } = useFormModel();

onBeforeMount(() => {
    // 選択されたセルの情報をフォームにセット
    form.value.companyTruckId = props.companyTruckId;
    form.value.startTm = props.startTm.format('YYYY-MM-DD HH:mm:ss');
    form.value.endTm = props.endTm.format('YYYY-MM-DD HH:mm:ss');
});

const onSubmit = () => submit(async () =>  {
    await register(form.value);
    emit('ok');
});

const onCancel = () => {
    emit('cancel');
};

</script>

<template>
    <div>
        <div class="content">
            <a-form-model ref="formModel"
                          :model="form"
                          :rules="formValidateRules"
                          :label-col="{ span: 4 }"
                          :wrapper-col="{ span: 18 }">
                <a-form-model-item label="期間">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="11">
                            <a-input v-model:value="form.startTm"/>
                        </a-col>
                        <a-col :span="1" style="display: flex; justify-content: center">
                            →
                        </a-col>
                        <a-col :span="11">
                            <a-input v-model:value="form.endTm"/>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <a-form-model-item label="タイトル"
                                   prop="title">
                    <a-input v-model:value="form.title"/>
                </a-form-model-item>
                <a-form-model-item label="詳細"
                                   prop="description">
                    <a-textarea v-model:value="form.description"/>
                </a-form-model-item>
                <a-form-model-item label="場所"
                                   prop="location">
                    <a-input v-model:value="form.location"/>
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="footer">
            <a-button type="primary" @click="onSubmit">メモを作成</a-button>
            <a-button @click="onCancel">キャンセル</a-button>
        </div>
    </div>
</template>

<style scoped lang="less">
.footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
</style>
