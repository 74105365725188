import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';
import {
    CompanyTruck,
    CompanyTruckList,
    CompanyTruckRegisterForm,
    CompanyTruckSearchForm, CompanyTruckUpdateForm
} from '@/models/company-truck';

export const companyTruckApi = {
    /**
     * 取得します。
     */
    find(id: number): Promise<CompanyTruck> {
        return appAxios.get<JsonResponseBean<CompanyTruck>>(`/api/company_truck/${ id }`).then(isSuccess);
    },
    /**
     * 検索します。
     */
    searchCompanyTruck(param: CompanyTruckSearchForm): Promise<CompanyTruckList> {
        return appAxios.post<JsonResponseBean<CompanyTruckList>>('/api/company_truck/search', param).then(isSuccess);
    },
    /**
     * 登録します。
     */
    registerCompanyTruck(param: CompanyTruckRegisterForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/company_truck/register', param).then(isSuccess);
    },
    /**
     * 更新します。
     */
    updateCompanyTruck(id: number, param: CompanyTruckUpdateForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_truck/${id}/update`, param).then(isSuccess);
    },
    /**
     * 削除します。
     */
    deleteCompanyTruck(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/company_truck/${id}/delete`).then(isSuccess);
    }
};
