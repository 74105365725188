<script setup lang="ts">
import { computed } from 'vue';
import { PhoneUtil } from '@/util';

interface Props {
    phoneNumber?: string
}

const props = defineProps<Props>();

const value = computed(() => {
    if (props.phoneNumber) return PhoneUtil.format(props.phoneNumber);
    return '';
});
</script>
<template>
    <span class="nowrap">{{ value }}</span>
</template>
<style scoped lang="less">
.nowrap {
    white-space: nowrap;
}
</style>
