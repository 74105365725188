<script setup lang="ts">
import { computed } from 'vue';
import { CompanyContractModel } from '@/models/company-contract';
import { DateUtil } from '@/util';
import { useCompanyContracts } from '@/composables/global/company-contracts';

const { state: { contracts } } = useCompanyContracts();
const trialContract = computed<CompanyContractModel | undefined>(() => {
    return contracts.value?.trialContract;
});

/**
 * 表示すべきか否かを取得する。
 */
const shouldShow = computed<boolean>(() => {
    return !!trialContract.value;
});

/**
 * お試し期間の残日数を取得する。
 */
const daysLeft = computed<number | undefined>(() => {
    return trialContract.value ? DateUtil.formatDaysLeft(trialContract.value.endTm) : undefined;
});

</script>

<template>
    <div class="trial-notification-container" v-if="shouldShow">
        <a-alert type="warning">
            <template #message>
                <template v-if="daysLeft <= 1">無料お試し期間は<span class="text-days-left">本日まで</span>です。</template>
                <template v-else>無料お試し期間は残り<span class="text-days-left">{{ daysLeft }}</span>日です。</template>
                プレミアムプランのお申し込みは
                <router-link to="/upgrade" class="text-link component-text-link-inside">こちら</router-link>
                です。
            </template>
        </a-alert>
    </div>
</template>

<style scoped lang="less">
.trial-notification-container {
    .ant-alert {
        text-align: center;
    }

    .text-link,
    .text-days-left {
        display: inline-block;
        margin: 0 4px;
        font-weight: bold;
    }
}
</style>
