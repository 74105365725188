import { Modal } from 'ant-design-vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import store from '@/vuex/store';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import * as companyTypes from '@/vuex/modules/company/types';
// @ts-ignore
import BaggageSearchConditionList from '@/components/BaggageSearchCondition/View/List';
import { ModalUtil } from '@/util';
import { Karte } from '@/karte';

const baggageMod = namespace('baggage');
const companyMod = namespace('company');

@Component({
    title: '荷物情報のメール受信設定',
    components: {
        BaggageSearchConditionList,
    },
    beforeRouteEnter: SettingEmailBaggageAndTruckPage.beforeRouteEnter,
})
export default class SettingEmailBaggageAndTruckPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @baggageMod.Getter(baggageTypes.GETTER.BAGGAGE_FORM_SEARCH_CONDITION_LIST)
    readonly SEARCH_CONDITION_LIST?: baggageTypes.BaggageSearchCondition[];
    @companyMod.Getter(companyTypes.GETTER.MY_PROFILE)
    readonly MY_COMPANY_PROFILE?: companyTypes.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    loading = false;

    // ======================================================
    // Functions
    // ======================================================
    /**
     * よく使う検索条件アイテムの削除ボタンが押下された際に呼び出されます。
     */
    async onClickDeleteSearchCondition(record: baggageTypes.BaggageSearchCondition): Promise<void> {
        const notifyFailedKey = 'DELETE_SEARCH_CONDITION_FAILED';
        const onSuccess = () => {
            this.loading = false;
            this.$message.success('よく使う検索条件を削除しました。');
            // KARTEイベント送信：よく使う検索条件を解除
            Karte.trackRemoveBaggageSearchCondition(record.id);
        };
        const onError = () => {
            this.loading = false;
            this.$notification.error({
                key: notifyFailedKey,
                message: 'よく使う検索条件を削除できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        };

        Modal.confirm({
            title: `選択した検索条件を本当に削除しますか？`,
            content: 'この操作は元に戻すことができません。ご注意ください。',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                this.loading = true;
                this.$notification.close(notifyFailedKey);
                await SettingEmailBaggageAndTruckPage.deleteSearchCondition(record.id).then(onSuccess).catch(onError);
            },
        });
    }

    /**
     * 「メールを受信する」「受信しない」ボタンを押下すると呼び出されます。
     */
    async onClickChangeNotification(data: { id?: number; notification: boolean }): Promise<void> {
        if (!data.id) {
            return;
        }
        const conditionId = data.id;
        const notifyFailedKey = 'CHANGE_NOTIFICATION_FAILED';
        const onSuccess = () => {
            this.loading = false;
            this.$message.success('荷物情報のメール受信設定を更新しました。');
            // KARTEイベント送信：荷物メール通知を設定
            if (data.notification) {
                Karte.trackSetBaggageMailNotification(conditionId);
            }
        };
        const notifyFailed = () => {
            this.loading = false;
            this.$notification.error({
                key: notifyFailedKey,
                message: '荷物情報のメール受信設定を更新できませんでした。時間をおいて再度お試しください。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });
        };

        this.loading = true;
        this.$notification.close(notifyFailedKey);
        await SettingEmailBaggageAndTruckPage.updateSearchCondition(data.id, { notification: data.notification })
            .then(onSuccess)
            .catch(notifyFailed);
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingEmailBaggageAndTruckPage>
    ): Promise<void> {
        await SettingEmailBaggageAndTruckPage.loadSearchCondition().catch(() => next({ name: 'NotFound' }));
        next();
    }

    /**
     * 「よく使う検索条件」をロードします。
     */
    private static loadSearchCondition(): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.LOAD_BAGGAGE_FORM_SEARCH_CONDITION }`);
    }

    /**
     * 「よく使う検索条件」を削除します。
     */
    private static deleteSearchCondition(id: number): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.DELETE_BAGGAGE_SEARCH_CONDITION }`, id);
    }

    /**
     * メール配信を行う「よく使う検索条件」の設定を更新します。
     */
    private static updateSearchCondition(
        id: number,
        form: baggageTypes.BaggageSearchConditionNotificationUpdateForm
    ): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.UPDATE_BAGGAGE_SEARCH_CONDITION_NOTIFICATION }`, {
            id,
            form,
        });
    }
}
