import { computed, nextTick, onMounted, ref, VNode, watch } from 'vue';
import { useBaggage } from '@/composables/baggage';
import { DateUtil, Util } from '@/util';
import {
    validationRules,
    useBaggageRegister,
    useBaggageRegisterFormQuery,
    useBaggageRegisterFormStore
} from '@/composables/baggage-register';
import { useRoute } from '@/composables/helper/route';
import { BaggageRegisterFormModel } from '@/models/baggage';
import {
    useBaggageHighwayFareOptions,
    useBaggageLargeTruckFlgOptions,
    useBaggageLoadingOptions,
    useBaggageShapeOptions,
    useBaggageTemperatureZoneOptions,
    useBaggageUnloadingOptions,
    useNegotiationTypeOptions,
    useTruckHeightForBaggageOptions,
    useTruckModelRegistrableOptions,
    useTruckWeightRegistrableOptions,
    useTruckWidthForBaggageOptions
} from '@/composables/option-helper';
import { useBaggageFreightMaster } from '@/composables/baggage-freight-master';
import { DateValue } from '@/models/vo/date';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestionRemove,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import _ from 'lodash';
import { useFormModel } from '@/composables/form-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { useRouting } from '@/composables/helper/routing';
import { useModal } from '@/composables/helper/modal-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useAccountMyProfile } from '@/composables/global/account-my-profile';
import { useCompanyPlaceList } from '@/composables/company-place';
import { useCircleLabelRegister, useCircleLabelRemove, useCircleLabels } from '@/composables/circle-label';

export const useBaggageRegisterHelper = (sourceBaggageId?: string) => {
    const { currentRoute } = useRoute();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const { state: { profile: accountMyProfile }, load: loadMyAccountProfile } = useAccountMyProfile();
    const { state: { baggage, loading: loadingSource }, load: loadSource } = useBaggage();
    const { parse } = useBaggageRegisterFormQuery();
    const { save: saveStored, clear: clearStored, load: loadStored } = useBaggageRegisterFormStore();
    const {
        state: { freightMaster: referenceFreight },
        load: loadFreightMaster,
        clear: clearFreightMaster
    } = useBaggageFreightMaster();
    const { state: { suggestionList }, load: loadStaffNameSuggestions } = useCompanyStaffNameSuggestions();
    const { state: { placeList }, load: loadCompanyPlaceList } = useCompanyPlaceList();
    const { remove: removeStaffName } = useCompanyStaffNameSuggestionRemove();
    const { register: registerStaffName } = useCompanyStaffNameSuggestionRegister();

    const { state: { labelList }, load: loadCircleLabels, clear: clearCircleLabels } = useCircleLabels();
    const { remove: removeCircleLabel } = useCircleLabelRemove();
    const { register: registerCircleLabel } = useCircleLabelRegister();
    const {
        state: { loading: loadingRegister, form },
        clear: clearForm,
        register
    } = useBaggageRegister();
    const { submit, formModel } = useFormModel();
    const cols = {
        first: {
            labelCol: { xs: 3, md: 9 },
            wrapperCol: { xs: 21, md: 15 },
        },
        second: {
            labelCol: { span: 0 },
            wrapperCol: {
                xs: { offset: 0, span: 24 },
                sm: { offset: 3, span: 21 },
                md: { offset: 0, span: 24 },
            },
        },
    };
    const selectableDateRange = [DateUtil.now().add(1, 'hour').startOf('hour'), DateUtil.now().add(1, 'year').endOf('year')];
    const specifiedTruckChecked = ref(false);
    const specifiedTruckCheck = computed({
        get: () => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck,
        set: (value: boolean) => {
            if (!value) form.value.clearSpecifiedTruck();
            specifiedTruckChecked.value = value;
        },
    });
    const specifiedTruckEditable = computed(() => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck);

    const parseTruckCount = BaggageRegisterFormModel.parseTruckCount;

    const { options: shapeOptions } = useBaggageShapeOptions();
    const { options: loadingOptions } = useBaggageLoadingOptions();
    const { options: unloadingOptions } = useBaggageUnloadingOptions();
    const { options: weightOptions } = useTruckWeightRegistrableOptions();
    const { options: modelOptions } = useTruckModelRegistrableOptions();
    const { options: heightOptions } = useTruckHeightForBaggageOptions();
    const { options: widthOptions } = useTruckWidthForBaggageOptions();
    const { options: largeTruckFlgOptions } = useBaggageLargeTruckFlgOptions();
    const { options: highwayFareOptions } = useBaggageHighwayFareOptions();
    const { options: negotiationTypeOptions } = useNegotiationTypeOptions();
    const { options: temperatureZoneOptions } = useBaggageTemperatureZoneOptions();
    const circleOptions = computed(() => myProfile.value?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));
    const isMemberOfSingleCircle = computed(() =>
        myProfile.value?.circles?.length === 1
    );

    const message = useMessage();
    const { confirm } = useModal();
    const { goToOpenedBaggageDetail, replaceQuery } = useRouting();

    const loading = computed(() => loadingSource.value || loadingRegister.value);

    const circleId = computed({
        get: () => form.value.circleId,
        set: async (value: number | undefined) => {
            form.value.circleId = value;
            if (value !== undefined) {
                await loadCircleLabels(value);
            } else {
                clearCircleLabels();
            }
        },
    });

    /**
     * 参考運賃取得パラメータ※取得するのは1年前の同月
     */
    const freightMasterQuery = computed(() => form.value.freightMasterQuery(DateValue.today().add(-1, 'year')));
    /**
     * 参考運賃取得パラメータの変更で参考運賃を再取得します。
     */
    watch(freightMasterQuery, async () => {
        await loadReferenceFreight();
    });

    /**
     * 参考運賃を取得します。
     */
    const loadReferenceFreight = async () => {
        clearFreightMaster();
        if (form.value.canShowReferenceFreight && freightMasterQuery.value) {
            await loadFreightMaster(freightMasterQuery.value);
        }
    };

    /**
     * サジェストの項目をフィルターします。
     */
    const staffNameFilterOption = (input: string, option: VNode) => {
        const prop: { title?: string } = option.componentOptions?.propsData ?? {};
        if (!prop) {
            return false;
        }
        return _.isString(prop.title) && prop.title.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     */
    const onClickStaffNameDelete = async (value: string, event: MouseEvent) => {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        // 削除APIを実行
        await removeStaffName(value);
        await loadStaffNameSuggestions();
    };

    /**
     * ラベル項目を削除ボタンを押下した際に呼び出されます。
     */
    const onClickLabelDelete = async (id: number) => {
        if (form.value.circleId !== undefined) {
            await removeCircleLabel(form.value.circleId, id);
            await loadCircleLabels(form.value.circleId);
        }
    };

    /**
     * 入金予定日の初期値を取得します。
     */
    const defaultPaymentDate = computed(() => {
        // NOTE private traboxでは、入金日の管理は不要なので、入金日は一律翌月末日とする
        const paymentDate = DateUtil.lastDayOfNextMonth();
        return new DateValue(paymentDate);
    });

    const formValidateRules = ref(validationRules(
        () => form.value,
        defaultPaymentDate.value
    ));
    // TODO: 荷物（コピーの場合）、企業プロフィールの読み込みをLoadingレイヤーに移譲する（荷物編集のような実装にする）
    // workaround
    // 本来は企業プロフィール読み込み完了後に一度だけバリデーションルールを生成したいのだが、
    // 企業プロフィールのロードはmount後に始まるため、ここでウォッチしてルール生成している。
    watch(() => defaultPaymentDate.value, (defaultPaymentDate) => {
        formValidateRules.value = validationRules(
            () => form.value,
            defaultPaymentDate
        );
    });

    /**
     * 登録ボタンが押下された際に呼び出される。
     */
    const onSubmit = () => submit(async () => {
        // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        registerStaffName(form.value.staffName).catch(() => {
        });
        if (form.value.circleId && form.value.labelText && form.value.labelColor)
            registerCircleLabel(form.value.circleId, {
                labelText: form.value.labelText,
                labelColor: form.value.labelColor
            }).catch(() => {});
        // 荷物登録
        const baggageId = await register();
        message.success('荷物の登録が完了しました。');

        if (baggageId) await goToOpenedBaggageDetail(baggageId, { registered: true });

        clearStored();
    });

    /**
     * クリアボタンを謳歌した際に呼び出されます。
     */
    const onClickClear = async () => {
        if (!await confirm('入力内容を削除してよろしいですか？', '', '削除', 'キャンセル')) return;
        clearForm();
        clearStored();
    };

    /**
     * ページ離脱時の処理
     */
    const unloaded = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        saveStored(form.value);
    };

    /**
     * コピー元荷物情報を取得します。
     */
    const sourceBaggage = () => {
        if (baggage.value) return BaggageRegisterFormModel.of(baggage.value);
        return undefined;
    };
    /**
     * クエリーで指定された荷物情報を取得します。
     */
    const queryBaggage = () => {
        return parse(currentRoute);
    };
    /**
     * 保存済み荷物情報を取得します。
     */
    const storedBaggage = () => {
        return loadStored();
    };

    onMounted(async () => {
        ////
        // sourceBaggageIdに関する初期処理
        ////
        const initSource = async () => {
            if (!sourceBaggageId) return;
            // sourceBaggageId に数字以外が含まれている場合は、クエリパラメーターをリセット
            if (!Util.isNumeric(sourceBaggageId)) return await replaceQuery({});
            try {
                await loadSource(Util.toNumber(sourceBaggageId));
            } catch {
                // 他企業の荷物や削除された荷物の場合は、クエリパラメーターをリセット
                await replaceQuery({});
            }
        };
        await initSource();
        if (!myProfile.value) await loadMyProfile();
        if (!accountMyProfile.value) await loadMyAccountProfile();
        await loadStaffNameSuggestions();
        await loadCompanyPlaceList();
        form.value.placeList = placeList.value;
        window.addEventListener('beforeunload', unloaded, false);
        // コピー元、クエリー、保存済み、新規の優先順位で初期データを構築
        form.value = sourceBaggage() ?? queryBaggage() ?? storedBaggage() ?? form.value;
        // 担当者の初期値として、ログインユーザー名を設定する
        form.value.staffName = accountMyProfile.value?.name ?? '';
        // 部屋は一つしかない場合に設定する
        if (isMemberOfSingleCircle.value) form.value.circleId = myProfile.value?.circles?.[0]?.id;

        if (form.value.circleId)
            await loadCircleLabels(form.value.circleId);

        // クエリーからの新規登録の場合は入金予定日をクリアーしない
        if (!currentRoute.query.parser) {
            form.value.paymentDateValue = undefined;
        }
        nextTick(() => {
            // 荷物コピーによる新規登録の場合、値がセットされた状態となっているためバリデーションを実行
            // クエリーからの新規登録の場合、値がセットされた状態となっているためバリデーションを実行
            if (sourceBaggage() || queryBaggage()) {
                submit(async () => {});
            }
        });
    });

    onBeforeRouteLeave((_to, _from, next) => {
        saveStored(form.value);
        window.removeEventListener('beforeunload', unloaded, false);
        next();
    });

    return {
        loading,
        form,
        formModel,
        formValidateRules,
        cols,
        selectableDateRange,
        specifiedTruckCheck,
        specifiedTruckEditable,
        parseTruckCount,
        shapeOptions,
        temperatureZoneOptions,
        loadingOptions,
        unloadingOptions,
        weightOptions,
        modelOptions,
        heightOptions,
        widthOptions,
        largeTruckFlgOptions,
        highwayFareOptions,
        negotiationTypeOptions,
        circleOptions,
        referenceFreight,
        defaultPaymentDate,
        suggestionList,
        labelList,
        placeList,
        circleId,
        staffNameFilterOption,
        onClickStaffNameDelete,
        onClickLabelDelete,
        onSubmit,
        onClickClear,
    };
};
