import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/account/types';
import _ from 'lodash';
import { Const } from '@/const';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';

@Component
export default class AccountPositionEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.AccountProfileCreateOrUpdateForm;

    /**
     * 役職
     */
    get position(): string {
        return this.value?.position.code ?? '';
    }

    set position(newValue: string) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.position = { code: newValue };
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get positions(): Array<{ value: string; label: string; key: string }> {
        return Const.positions.map((each) => ({
            value: each.code,
            label: each.label ?? '',
            key: each.code,
        }));
    }

    validationRules: Array<ValidationRule> = [
        {
            required: true,
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            validator: (_rule, _value, callback: Function) => this.validate(callback as (message?: string) => void),
        },
    ];

    private validate(callback: (message?: string) => void): void {
        if (!this.value?.position.code) {
            callback('役職を選択してください。');
        } else {
            callback();
        }
    }
}
