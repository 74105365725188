import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import * as formDirtyTypes from '@/vuex/modules/formDirty/types';
import { PageUtil } from '@/util';
import { gtm } from '@/gtm';
import { Const } from '@/const';
import store from '@/vuex/store';
import * as companyTypes from '@/vuex/modules/company/types';
import * as baggageTypes from '@/vuex/modules/baggage/types';
import Tabs from '@/_pages/Baggage/List/components/Tabs.vue';

export type BaggageListTabKey = 'opened' | 'agreed' | 'expired' | 'accepted' | 'settlement';

const formDirtyMod = namespace('formDirty');

@Component({
    components: { Tabs },
    beforeRouteEnter: BaggageListPage.beforeRouteEnter
})
// NOTE: 開いているタブによって動的にページタイトル名を設定したいため、このページでは例外的にPageVueは継承せずVueを継承して用いている
export default class BaggageListPage extends Vue {
    @formDirtyMod.Getter(formDirtyTypes.GETTER.FORM_DIRTY)
    readonly formDirty!: boolean;
    @formDirtyMod.Action(formDirtyTypes.ACTION.SET_FORM_DIRTY)
    readonly setFormDirty!: formDirtyTypes.setFormDirty;

    private routeUpdating = false;

    /**
     * 現在アクティブなタブキーを取得します。
     */
    get activeTabKey(): string {
        return _.first(this.$route.meta?.activeTabKey?.split('/')) ?? '';
    }

    mounted(): void {
        BaggageListPage.setPageTitle(this.$route);
    }

    /**
     * タブが切り替わった際に呼び出されます。
     */
    async onChangeTab(activeKey: BaggageListTabKey): Promise<void> {
        if (!activeKey) return;
        this.routeUpdating = true;
        try {
            switch (activeKey) {
                case 'opened':
                    await this.$router.push({ name: 'BaggageList' });
                    break;
                case 'expired':
                    await this.$router.push({ name: 'BaggageExpiredList' });
                    break;
                case 'agreed':
                    await this.$router.push({ name: 'AgreementList' });
                    break;
                case 'accepted':
                    await this.$router.push({ name: 'AgreementAcceptedList' });
                    break;
                case 'settlement':
                    await this.$router.push({ name: 'SettlementHome' });
                    break;
            }
        } catch {
            // リダイレクトの例外をもみ消す
        } finally {
            this.routeUpdating = false;
        }
    }

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        // 連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            return;
        }
        if (this.$route.query.baggageId || this.$route.query.agreementId) {
            await this.$router.push({ path: `/baggage/list/${ this.activeTabKey }` });
        }
    }

    /**
     * 編集ページから離脱する状態かどうかを取得します。
     * @param _from
     * @param _to
     */
    isLeaveEditPage(_from: Route, _to: Route): boolean {
        const current = Array.isArray(_from.query.edit) ? _from.query.edit[0] : _from.query.edit;
        const next = Array.isArray(_to.query.edit) ? _to.query.edit[0] : _to.query.edit;
        return !!current && !next;
    }

    /**
     * 編集ページまで行く状態かどうかを取得します。
     * @param _from
     * @param _to
     */
    static isEnterEditPage(_from: Route, _to: Route): boolean {
        const current = Array.isArray(_from.query.edit) ? _from.query.edit[0] : _from.query.edit;
        const next = Array.isArray(_to.query.edit) ? _to.query.edit[0] : _to.query.edit;
        return !current && !!next;
    }

    /**
     * フォーム編集中に離脱しようとした場合、確認のアラートを表示します。
     */
    confirmLeaveForm(): boolean {
        if (this.formDirty) {
            const confirmed = confirm(
                '入力途中の項目があります。画面を移動すると入力中の内容は失われます。よろしいですか？'
            );
            if (confirmed) {
                this.setFormDirty(false).then();
            }
            return confirmed;
        }
        return true;
    }

    static async beforeRouteEnter(_to: Route, _from: Route, next: NavigationGuardNext<BaggageListPage>): Promise<void> {
        if (BaggageListPage.isEnterEditPage(_from, _to)) {
            BaggageListPage.loadCompanyStaffNameSuggestions()
                .then(() => next())
                .catch(() => {
                    next({ name: 'UpgradePlan' });
                });
        } else {
            next();
        }
    }

    async beforeRouteUpdate(_to: Route, _from: Route, next: NavigationGuardNext<BaggageListPage>): Promise<void> {
        if (this.isLeaveEditPage(_from, _to)) {
            if (!(await this.confirmLeaveForm())) {
                next(false);
                return;
            }
        }
        if (BaggageListPage.isEnterEditPage(_from, _to)) {
            await BaggageListPage.loadCompanyStaffNameSuggestions().catch(() => next({ name: 'UpgradePlan' }));
        }

        await this.setFormDirty(false);
        if (_from.path !== _to.path) {
            await BaggageListPage.clearBaggageList();
        }

        BaggageListPage.setPageTitle(_to);
        next();
    }

    async beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext<BaggageListPage>): Promise<void> {
        if (this.isLeaveEditPage(_from, _to)) {
            if (!(await this.confirmLeaveForm())) {
                next(false);
                return;
            }
        }

        await this.setFormDirty(false);
        await BaggageListPage.clearBaggageList();
        next();
    }

    private static setPageTitle(route: Route): void {
        const title = route.matched
            .flatMap(each => {
                // `/_`モードで動いている場合、トップレベルルートのタイトル処理をスキップする
                return typeof each.meta.title === 'function' ? each.meta.title(route) : [];
            })
            .reverse()
            .join(Const.PAGE_TITLE_SEPARATOR);

        PageUtil.setTitle(title);
        // GTMへページビューイベントを送信
        gtm.setPage(route.fullPath, title);
    }

    /**
     * 企業担当者名おすすめ一覧をロードします。
     */
    private static loadCompanyStaffNameSuggestions(): Promise<void> {
        return store.dispatch(`company/${ companyTypes.ACTION.LOAD_STAFF_NAME_SUGGESTIONS }`);
    }

    /**
     * 荷物一覧をクリアします。
     */
    private static clearBaggageList(): Promise<void> {
        return store.dispatch(`baggage/${ baggageTypes.ACTION.CLEAR_BAGGAGE_LIST }`);
    }
}
