import { Component, Prop, Vue } from 'vue-property-decorator';
import { RevocableReason } from '@/pages/Setting/RevokePremiumPlan/revoke-premium-plan-helpers';

@Component({
    title: 'プレミアムプランの停止',
})
export default class RevokeLayout extends Vue {
    @Prop()
    declare readonly canRevokePremiumPlan: RevocableReason;
}
