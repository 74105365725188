import { Component, Prop, Vue } from 'vue-property-decorator';
import { Baggage } from '@/vuex/modules/baggage/types';
import { DateUtil } from '@/util';
// @ts-ignore
import BaggageId from '@/components/Baggage/View/Id';
// @ts-ignore
import Departure from '@/components/Baggage/View/Departure';
// @ts-ignore
import DepartureLocation from '@/components/Baggage/View/DepartureLocation';
// @ts-ignore
import Arrival from '@/components/Baggage/View/Arrival';
// @ts-ignore
import ArrivalLocation from '@/components/Baggage/View/ArrivalLocation';
// @ts-ignore
import Express from '@/components/Baggage/View/Express';
// @ts-ignore
import BaggageType from '@/components/Baggage/View/Type';
// @ts-ignore
import Category from '@/components/Baggage/View/Category';
// @ts-ignore
import Truck from '@/components/Baggage/View/Truck';
// @ts-ignore
import SpecifiedTruck from '@/components/Baggage/View/SpecifiedTruck';
// @ts-ignore
import Freight from '@/components/Baggage/View/Freight';
// @ts-ignore
import HighwayFare from '@/components/Baggage/View/HighwayFare';
// @ts-ignore
import StaffName from '@/components/Baggage/View/StaffName';
// @ts-ignore
import Description from '@/components/Baggage/View/Description';
// @ts-ignore
import BaggagePaymentDate from '@/components/Baggage/View/PaymentDate';
// @ts-ignore
import NegotiationType from '@/components/Baggage/View/NegotiationType';
import { Const } from '@/const';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';

@Component({
    components: {
        BaggageId,
        Departure,
        DepartureLocation,
        Arrival,
        ArrivalLocation,
        Express,
        BaggageType,
        Category,
        Truck,
        SpecifiedTruck,
        Freight,
        HighwayFare,
        StaffName,
        NegotiationType,
        Description,
        BaggagePaymentDate,
    },
})
export default class BaggageDetailView extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: Baggage;
    @Prop()
    declare readonly canMarkNegotiation?: boolean;
    @Prop({ default: false })
    declare readonly myBaggage: boolean;

    get entryTmText(): string {
        if (!this.baggage) return '';
        return DateUtil.parseDatetimeText(this.baggage.entryTm).format(Const.DEFAULT_DATETIME_WITH_DAY_OF_WEEK_FORMAT);
    }

    get showStatus(): boolean {
        if (!this.baggage?.status?.code) return false;
        return BaggageStatusEnum.valueOf(this.baggage.status.code)?.isOpened() ?? false;
    }

    get status(): string {
        return this.underNegotiation ? '商談中' : '募集中';
    }

    get underNegotiation(): boolean {
        return this.baggage?.underNegotiation ?? false;
    }

    get showPaymentDate(): boolean {
        return this.myBaggage;
    }

    onClickMarkUnderNegotiation(): void {
        if (!this.baggage) return;
        this.$emit('markUnderNegotiation', this.baggage.id);
    }

    onClickUnmarkUnderNegotiation(): void {
        if (!this.baggage) return;
        this.$emit('unmarkUnderNegotiation', this.baggage.id);
    }
}
