import { useCustomModal } from '@/composables/helper/modal-helper';
import { SettlementPartnerCompanyModel } from '@/models/settlement';
import SettlementPartnerCompanyPicker from '@/_components/settlement/SettlementPartnerCompanyPicker.vue';

export const useSettlementPartnerCompanyPicker = () => {
    const { showModal } = useCustomModal();

    const pick = async (
        agreementSelected: (value: { id: number, myBaggage: boolean }) => void
    ): Promise<SettlementPartnerCompanyModel | undefined> => {
        return showModal<SettlementPartnerCompanyModel>({
            content: (h, resolve) => h(SettlementPartnerCompanyPicker, {
                on: {
                    select: (value: SettlementPartnerCompanyModel) => {
                        resolve(value);
                    },
                    selectAgreement: (value: { id: number, myBaggage: boolean }) => {
                        agreementSelected(value);
                    },
                },
            }),
            width: 840,
            maskClosable: true,
        });
    };

    return { pick };
};
