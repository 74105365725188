import _ from 'lodash';
import { Const } from '@/const';
import { Baggage, BaggageSearchCondition, MaskedBaggage } from '@/models/baggage';
import { RegionUtil } from '@/util/region';
import { BaggageCategoryEnum } from '@/enums/baggage-category.enum';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { Util } from '@/util/index';
import { TruckSearchForm } from '@/models/truck';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { TruckModelGroupEnum } from '@/enums/truck-model-group.enum';
import { DateTimeValue } from '@/models/vo/datetime';
import { TruckWeightGroupEnum } from '@/enums/truck-weight-group.enum';
import { CompanyMyProfile } from '@/models/company';

export class BaggageUtil {
    /**
     * 引っ越しという言葉が含まれているかどうかを判定します。
     * @param text
     */
    static includesRelocateWord(text?: string): boolean {
        if (!text) {
            return false;
        }
        return /引[っ]?越/.test(text);
    }

    /**
     * 備考に含まれているNGワードを取得します。
     *
     * @param text 荷物.備考
     */
    static validateDescription(text: string): string[] {
        // 例：[["交換"], ["こうかん", "こうかん"], null, ["Change", "change"], null]
        const matches = _.map(Const.baggageDescriptionNgWords, (word) => text.match(new RegExp(word, 'ig')));

        // 例：[["交換"], ["こうかん", "こうかん"], ["Change", "change"]]
        const compacted = _.compact(matches);

        // 例：["交換", "こうかん" "こうかん", "Change", "change"]
        const flattened = _.flatMap(compacted);

        // 例：["交換", "こうかん", "Change", "change"]
        return _.uniq(flattened);
    }

    /**
     * 検索条件のラベル名をフォーマットします。
     */
    static formatBaggageSearchConditionLabel(searchCondition: BaggageSearchCondition, myProfile: CompanyMyProfile): string {
        const labels = [];
        if (searchCondition.circleId !== undefined) {
            const circleName = myProfile.circles.find((each) => each.id === searchCondition.circleId)?.name;
            labels.push('部屋: ' + circleName);
        }
        if (searchCondition.departurePref.length > 0) {
            const departures = RegionUtil.parseRegionsFromPrefectures(
                searchCondition.departurePref.map((each) => each.code)
            );
            labels.push('発地: ' + departures.map((each) => each.label).join('、'));
        }
        if (searchCondition.arrivalPref.length > 0) {
            const arrivals = RegionUtil.parseRegionsFromPrefectures(
                searchCondition.arrivalPref.map((each) => each.code)
            );
            labels.push('着地: ' + arrivals.map((each) => each.label).join('、'));
        }
        if (searchCondition.truckWeight.length > 0 || searchCondition.excludeUndecidedTruckWeight) {
            const sortedWeights = _.sortBy(searchCondition.truckWeight, [
                (each) => Util.toDigits(each.label ?? '').length === 0,
                (each) => Util.toNumber(each.label ?? ''),
            ]);
            labels.push('重量: ' + sortedWeights.map((each) => each.label ?? '').join('、') +
                (searchCondition.excludeUndecidedTruckWeight ? '（問わずを除く）' : '')
            );
        }
        if (searchCondition.truckModel.length > 0 || searchCondition.excludeUndecidedTruckModel) {
            labels.push('車種: ' + searchCondition.truckModel.map((each) => each.label ?? '').join('、') +
                (searchCondition.excludeUndecidedTruckModel ? '（問わずを除く）' : '')
            );
        }
        if (searchCondition.freight) {
            labels.push(
                `運賃: ${ Util.formatNumber(searchCondition.freight) }円以上${
                    searchCondition.excludeUndecidedFreight ? '（要相談を除く）' : ''
                }`
            );
        } else if (searchCondition.excludeUndecidedFreight) {
            labels.push('運賃: （要相談を除く）');
        }
        if (!_.isNil(searchCondition.share)) {
            labels.push(searchCondition.share ? '積合のみ' : '積合を除く');
        }
        if (searchCondition.category) {
            const enumLabel = BaggageCategoryEnum.valueOf(searchCondition.category.code);
            if (enumLabel) {
                labels.push(enumLabel.formItemLabel);
            }
        }
        return labels.join(' / ');
    }

    /**
     * 成約可能か否かを取得します。
     */
    static isReadyToAgree(baggage: Baggage | MaskedBaggage, myCompanyId: number): boolean {
        if (!baggage.status) return false;
        const status = BaggageStatusEnum.valueOf(baggage.status.code);
        if (!status) return false;

        // 公開中の他社荷物のみ表示
        return status.isOpened() && baggage.companyId !== myCompanyId;
    }

    /**
     * 荷物が公開中か否かを取得します。
     */
    static isOpen(baggage: Baggage | MaskedBaggage): boolean {
        if (!baggage.status) return false;
        const status = BaggageStatusEnum.valueOf(baggage.status.code);
        if (!status) return false;
        return status.isOpened();
    }

    /**
     * 空車検索フォームに変換します。
     */
    static toTruckSearchForm(baggage: Baggage): TruckSearchForm {
        /**
         * 荷物の希望重量以上を取得します。※他は他のみ、問わずは空で返します。
         */
        const toTruckWeight = (baggage: Baggage): Array<TruckWeightEnum> => {
            // 問わず
            if (baggage.truckWeight.code == TruckWeightEnum.Unspecified.code) return [];

            const weight = Util.requireNotNull(TruckWeightEnum.valueOf(baggage.truckWeight.code as TruckWeightEnumCode));
            // トレーラー
            if (weight == TruckWeightEnum.Trailer) return [TruckWeightEnum.Trailer];
            // 他
            if (weight == TruckWeightEnum.Other) return [TruckWeightEnum.Other];
            // 大型：不可(※希望車種が大型以外の場合)
            const excludeLargeTruck = !_.isNil(baggage.largeTruckFlg)
                && !baggage.largeTruckFlg
                && !TruckWeightGroupEnum.Large.truckWeights.includes(weight.code);
            return TruckWeightEnum.values
                // 「トレーラー」「他」は除外
                .filter(e => e != TruckWeightEnum.Trailer)
                .filter(e => e != TruckWeightEnum.Other)
                // 大型：不可の場合は除外
                .filter(e => !excludeLargeTruck || !TruckWeightGroupEnum.Large.truckWeights.includes(e.code))
                // 「対象コード」以上の重量で絞り込み
                .filter(e => weight.orderNo <= e.orderNo);
        };
        /**
         * 荷物の希望車種をグループ等を加味して取得します。
         */
        const toTruckModel = (baggage: Baggage): Array<TruckModelEnum> => {
            // 車種グループのメインとして認識する車種のマッピング
            const toGroupModels = (truckModelCode: string): TruckModelGroupEnum[] => {
                switch (truckModelCode) {
                    case TruckModelEnum.Flat.code:
                        return [TruckModelGroupEnum.Flat];
                    case TruckModelEnum.Van.code:
                        return [TruckModelGroupEnum.Van];
                    case TruckModelEnum.Wing.code:
                        return [TruckModelGroupEnum.Wing];
                    case TruckModelEnum.WingOrFlat.code:
                        return [TruckModelGroupEnum.Wing, TruckModelGroupEnum.Flat];
                    case TruckModelEnum.WingOrVan.code:
                        return [TruckModelGroupEnum.Wing, TruckModelGroupEnum.Van];
                    default:
                        return [];
                }
            };
            // 車種グループに属するものを返す
            const fromGroupModels = (...groups: TruckModelGroupEnum[]): Array<TruckModelEnum> =>
                _.compact(groups.flatMap(e => e.truckModels)
                    .map(tCode => TruckModelEnum.values.find(e => e.code == tCode)));

            const code = baggage.truckModel.code;
            // 問わず
            if (code == TruckModelEnum.Unspecified.code) return [];

            // 平 / 箱 / ウイング / ウイング又は平 は車種グループに属するもので返す
            const groups = toGroupModels(code);
            if (!_.isEmpty(groups)) {
                return fromGroupModels(...groups);
            }
            // 車種グループのメイン以外
            return _.compact([TruckModelEnum.valueOf(code as TruckModelEnumCode)]);
        };
        return {
            departureFrom: '',
            departureTo: new DateTimeValue(baggage.departureMax).endOf('date').format('YYYY-MM-DD HH:mm:ss'),
            departurePref: [baggage.departurePref],
            arrivalFrom: new DateTimeValue(baggage.arrivalMin).startOf('date').format('YYYY-MM-DD HH:mm:ss'),
            arrivalTo: '',
            arrivalPref: [baggage.arrivalPref],
            truckWeight: toTruckWeight(baggage),
            truckModel: toTruckModel(baggage),
            minFreight: baggage.freight?.toString(),
            excludeUndecidedFreight: false,
            pageNo: 1,
            pageSize: 10,
            sortKey: 'ID',
            sortOrder: 'DESC',
        };
    }
}
