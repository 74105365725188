<script setup lang="ts">
import PageLayout from '@/_components/PageLayout.vue';
import { UnSubscribeIndexHelper } from '@/_pages/UnSubscribe/Index/unsubscribe-index-helper';

const {
    state: {
        profile,
        contracts,
    },
    appServiceName,
    agreed,
    hasActivePremiumPlan,
    onClickBack,
    onClickToRevokePremium,
    onClickStartUnsubscribe,
} = UnSubscribeIndexHelper();
</script>

<template>
    <page-layout :back-icon="false">
        <div class="board-container">
            <a-card :bodyStyle="{padding: '44px'}" v-if="profile && profile.isMainAccount">
                <a-result title="退会">
                    <template #icon>
                        <a-icon type="question-circle" theme="filled" class="title-icon" />
                    </template>
                </a-result>

                <a-row type="flex" justify="center" :gutter="[0, 24]">
                    <a-col :span="24" :md="20" :lg="16" :xl="14" :xxl="12">
                        <p>{{ appServiceName }}を退会する前に、以下注意事項のご確認をお願いします。</p>

                        <ul class="notice">
                            <li class="notice__item">退会をすると、その直後から<span class="highlight-text">{{ appServiceName }}へのログインができなくなります。</span>また、ユーザー管理で追加したユーザーさまもログインできなくなります。</li>
                            <li class="notice__item">過去の成約情報等、<span class="highlight-text">重要な情報の閲覧もできなくなります。</span></li>

                            <!-- プレミアムプラン契約を保持している場合のみ表示 -->
                            <template v-if="hasActivePremiumPlan">
                                <li class="notice__item">日割りの計算は行っておりませんので、<span class="highlight-text">退会月の月額利用料金のご請求は発生してしまいます</span>ので、ご了承ください。</li>
                                <li class="notice__item">半年払い・年払い契約でご利用の場合、<span class="highlight-text">契約期間内の無料化に伴う返金は対応できかねます</span>ので、ご了承ください。</li>
                            </template>
                        </ul>

                        <!-- プレミアムプラン契約を保持している場合のみ表示 -->
                        <template v-if="hasActivePremiumPlan">
                            <p>今後も{{ appServiceName }}をご利用いただく可能性がある方、またはプレミアムプランの停止をご希望の方は、 以下の「有料サービス停止手続きに進む」ボタンをクリックして、プレミアムプランの停止ページへお進みください。</p>
                            <nav>
                                <ul class="actions">
                                    <li class="actions__item">
                                        <a-button @click="onClickToRevokePremium">有料サービス停止手続きに進む</a-button>
                                    </li>
                                </ul>
                            </nav>
                        </template>

                        <a-alert type="warning" v-if="contracts && contracts.existsPremiumPlanX">
                            <template #description>
                            <span>プレミアムプランの初月無料利用期間のため、退会手続きを行うことはできません。<br/>
                                来月以降に退会手続きを行ってください。</span>
                            </template>
                        </a-alert>
                        <template v-else>
                            <div class="agreement">
                                <p>上記の注意事項をご確認のうえ、退会手続きにお進みください。</p>
                                <a-checkbox v-model="agreed">上記の内容を確認しました。</a-checkbox>
                            </div>
                        </template>

                        <nav>
                            <ul class="actions">
                                <li class="actions__item">
                                    <a-button @click="onClickBack">戻る</a-button>
                                </li>
                                <li class="actions__item">
                                    <a-button type="primary" :disabled="!agreed || (contracts && contracts.existsPremiumPlanX)" @click="onClickStartUnsubscribe">退会手続きに進む</a-button>
                                </li>
                            </ul>
                        </nav>
                    </a-col>
                </a-row>
            </a-card>

            <a-card v-else>
                <a-result status="warning"
                          title="退会手続きは、管理者の方のみ行えます。"
                          subTitle="お手数ですが、管理者の方にご依頼ください。"></a-result>
            </a-card>
        </div>
    </page-layout>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}

.title-icon {
    color: @color-trabox-green-10 !important;
}

.notice {
    .notice__item + .notice__item {
        margin-top: 8px;
    }
}

.highlight-text {
    display: inline-block;
    padding: 0 4px;
    background-color: @brand-color-trabox-yellow;
    font-weight: bold;
}

.agreement {
    margin-top: 48px;
    text-align: center;
}

.actions {
    margin-top: 24px;
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;

    .actions__item + .actions__item {
        margin-left: 16px;
    }
}
</style>
