import { Component, Prop, Vue } from 'vue-property-decorator';
import { Const } from '@/const';
import * as types from '@/vuex/modules/company/types';
import _ from 'lodash';

@Component
export default class CompanyPaymentMonthEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: types.CompanyProfileExtraUpdateForm;

    /**
     * 支払月
     */
    get paymentMonth(): number | undefined {
        return this.value?.paymentMonth?.code;
    }

    set paymentMonth(newValue: number | undefined) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        cloned.paymentMonth = _.isNumber(newValue) ? { code: newValue } : undefined;
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItem.onFieldChange());
    }

    get accountingMonth(): Array<{ value: number; label: string; key: number }> {
        return Const.accountingMonth.map((each) => ({
            value: each.code,
            label: each.label,
            key: each.code,
        }));
    }
}
