import { Component } from 'vue-property-decorator';
import { PageVue } from '@/mixin/PageVue';
import { Const } from '@/const';

@Component({
    title: '便利サービス',
})
export default class MiscServicePage extends PageVue {
    externalPages = Const.externalPageUrl;
}
