import { CompanyContract } from '@/models/company';
import { CompanyContractModel } from '@/models/company-contract';

export type CompanyContractActivePlanType = 'FREE' | 'TRIAL' | 'PREMIUM' | 'PRIME';
export type CompanyContractRevokeUnavailableCode = 'ALREADY_REVOKED';
export type CompanyContractPremiumPlanRevokeUnavailableCode =
    CompanyContractRevokeUnavailableCode
    | 'ACTIVE_X'
    | 'NO_PREMIUM_PLAN';
export type CompanyContractGuaranteeRevokeUnavailableCode =
    CompanyContractRevokeUnavailableCode
    | 'PREMIUM_PLAN_ACTIVE_X'
    | 'NO_GUARANTEE';


export class CompanyContractListModel {
    contracts: CompanyContractModel[];

    constructor(data: CompanyContract[]) {
        this.contracts = data.map((each) => new CompanyContractModel(each));
    }

    /**
     * 現在契約中の料金プランを取得します。
     */
    get activePlanType(): CompanyContractActivePlanType {
        if (this.existsPremiumPlan) {
            return 'PREMIUM';
        } else if (this.existsTrialPlan) {
            return 'TRIAL';
        } else if (this.existsPrimeBaggageCompany) {
            return 'PRIME';
        } else {
            return 'FREE';
        }
    }

    /**
     * プレミアムプランを契約中かどうかを取得します。
     */
    get existsPremiumPlan(): boolean {
        return this.premiumPlanContract !== undefined;
    }

    /**
     * プレミアムプランを有料別途課金で契約しているかどうかを取得します。
     */
    get existsPremiumPlanX(): boolean {
        return this.premiumPlanContract?.isPremiumPlanX ?? false;
    }

    /**
     * 14日間お試しを利用中かどうかを取得します。
     */
    get existsTrialPlan(): boolean {
        // NOTE: Trial14dは企業審査完了後に付与される。そのため、TrialAccount14dを用いて判定している。
        return this.contracts.some((each) => each.isTrialAccount14d);
    }

    /**
     * 契約中のプレミアムプラン情報を取得します。
     */
    get premiumPlanContract(): CompanyContractModel | undefined {
        return this.contracts.find((each) => each.isPremiumPlan);
    }

    /**
     * アカウント契約一覧を取得します。
     */
    get accountContracts(): CompanyContractModel[] {
        return this.contracts.filter((each) => each.isAccount);
    }

    /**
     * アカウント割引契約一覧を取得します。
     */
    get accountDiscountContracts(): CompanyContractModel[] {
        return this.contracts.filter(each => each.isAccountDiscount);
    }

    /**
     * プレミアムプランのご利用停止が可能か否かを取得します。
     */
    get canRevokePremiumPlan(): { result: boolean; code?: CompanyContractPremiumPlanRevokeUnavailableCode } {
        if (!this.existsPremiumPlan) {
            // プレミアムプラン契約を持っていない
            return { result: false, code: 'NO_PREMIUM_PLAN' };
        } else if (this.existsPremiumPlanX) {
            // 有料別途課金状態の契約を持っている
            return { result: false, code: 'ACTIVE_X' };
        }
        const paidPremium = this.contracts.find((each) => each.isPaidPremiumPlan);
        if (!paidPremium || paidPremium.willTerminate) {
            // プレミアムプランをすでに解約済みか否か
            return { result: false, code: 'ALREADY_REVOKED' };
        }
        return { result: true };
    }

    /**
     * 14日間お試し期間中の場合、お試しの契約情報を取得します。
     */
    get trialContract(): CompanyContractModel | undefined {
        return this.contracts.find((each) => each.isTrial) ?? undefined;
    }

    /**
     * プライム荷主契約情報を取得します。
     */
    get primeBaggageContract(): CompanyContractModel | undefined {
        return this.contracts.find((each) => each.isPrimeBaggageCompany);
    }

    /**
     * 運賃全額保証サービス契約を取得します。
     */
    get guaranteeContract(): CompanyContractModel | undefined {
        return this.contracts.find((each) => each.isGuarantee);
    }

    /**
     * 運賃全額保証サービスに加入済みか否かを取得します。
     */
    get existsGuarantee(): boolean {
        return this.guaranteeContract !== undefined;
    }

    /**
     * 運賃全額保証サービスの利用停止が可能か否かを取得します。
     */
    get canRevokeGuarantee(): { result: boolean; code?: CompanyContractGuaranteeRevokeUnavailableCode } {
        if (!this.existsGuarantee) {
            // 運賃全額保証サービスの契約を持っていない
            return { result: false, code: 'NO_GUARANTEE' };
        }
        if (!this.guaranteeContract || this.guaranteeContract.willTerminate) {
            // 運賃全額保証サービスをすでに解約済みか否か
            return { result: false, code: 'ALREADY_REVOKED' };
        }
        if (this.existsPremiumPlanX) {
            // 有料別途課金状態の契約を持っている
            return { result: false, code: 'PREMIUM_PLAN_ACTIVE_X' };
        }
        return { result: true };
    }

    /**
     * トラボックス配車契約済みか否かを取得します。
     */
    get existsTms(): boolean {
        return this.contracts.some((each) => each.isTraboxTms);
    }

    /**
     * プライム荷主会員契約済みか否かを取得します。
     */
    get existsPrimeBaggageCompany(): boolean {
        return this.primeBaggageContract !== undefined;
    }

    /**
     * おまかせ請求契約済みか否かを取得します。
     */
    get existsEasyPayment(): boolean {
        return this.contracts.some((each) => each.isEasyPayment);
    }

    /**
     * 請求書発行手数料契約を取得します。
     */
    get invoiceContract(): CompanyContractModel | undefined {
        return this.contracts.find((each) => each.isInvoice);
    }
}
