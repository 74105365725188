import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { PageVue } from '@/mixin/PageVue';
import { PhoneUtil, Util } from '@/util';
import * as types from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import Location from '@/components/Company/Edit/Location';
// @ts-ignore
import Phone from '@/components/Company/Edit/Phone';
// @ts-ignore
import Fax from '@/components/Company/Edit/Fax';
// @ts-ignore
import TruckCount from '@/components/Company/Edit/TruckCount';
// @ts-ignore
import Url from '@/components/Company/Edit/Url';
import { FormModel } from 'ant-design-vue';

const companyMod = namespace('company');

@Component({
    title: '企業基本情報',
    components: { Location, Phone, Fax, TruckCount, Url },
    beforeRouteEnter: SettingCompanyProfilePage.beforeRouteEnter,
})
export default class SettingCompanyProfilePage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @companyMod.Getter(types.GETTER.MY_PROFILE)
    readonly PROFILE?: types.CompanyProfile;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    form: types.CompanyProfileUpdateForm = new types.CompanyProfileUpdateForm({
        zipCode: '',
        location: { prefecture: { code: '' }, city: '', address: '' },
        phone: { number: '', faxNumber: '' },
        truckCount: '0',
        url: undefined,
    });

    // ======================================================
    // Functions
    // ======================================================
    mounted(): void {
        if (!this.PROFILE) return;

        this.form.zipCode = this.PROFILE.zipCode;
        this.form.location = this.PROFILE.location;
        this.form.phone.number = this.PROFILE.phone.number;
        this.form.phone.faxNumber = Util.toDigits(PhoneUtil.format(this.PROFILE.phone.faxNumber));
        this.form.truckCount = `${this.PROFILE.truckCount}`;
        this.form.url = this.PROFILE.url;
    }

    /**
     * 保存ボタンが押下された際に呼び出されます。
     */
    onSubmit(): void {
        const notifyFailedKey = 'UPDATE_PROFILE_FAILED';
        const onSuccess = () => {
            this.$message.success('企業情報を更新しました。');
        };
        const notifyFailed = () =>
            this.$notification.error({
                key: notifyFailedKey,
                message: '企業情報を更新できませんでした。',
                description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
            });

        const formModel = this.$refs.formModel as FormModel;
        formModel.validate(async (result) => {
            if (!result) return;

            this.loading = true;
            this.$notification.close(notifyFailedKey);

            await SettingCompanyProfilePage.updateProfile(this.form).then(onSuccess).catch(notifyFailed);

            this.loading = false;
        });
    }

    static async beforeRouteEnter(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<SettingCompanyProfilePage>
    ): Promise<void> {
        await SettingCompanyProfilePage.loadMyProfile().catch(() => next({ name: 'NotFound' }));

        next();
    }

    /**
     * 自分の企業プロフィールをロードします。
     */
    private static loadMyProfile(): Promise<void> {
        return store.dispatch(`company/${types.ACTION.LOAD_MY_PROFILE}`);
    }

    /**
     * 企業プロフィールを更新します。
     */
    private static updateProfile(form: types.CompanyProfileUpdateForm): Promise<void> {
        return store.dispatch(`company/${types.ACTION.UPDATE_PROFILE}`, form);
    }
}
