import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/vuex/modules/baggage/types';

@Component
export default class BaggageDepartureLocation extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly baggage?: types.Baggage;

    get departureLocation(): string {
        if (!this.baggage) return '';

        const array = [
            this.baggage.departurePref.label,
            this.baggage.departureCity,
            this.baggage.departureAddress ?? '',
        ];
        return array.join('');
    }
}
