import { DateTimeValue } from '@/models/vo/datetime';

const TrialCode = 'TRIAL_14D';
const TrialAccountCode = 'TRIAL_ACCOUNT_14D';
const PremiumXCode = 'PREMIUM_X';
const Premium1mCode = 'PREMIUM_1M';
const Premium6mCode = 'PREMIUM_6M';
const Premium1yCode = 'PREMIUM_1Y';
const PremiumAccountXCode = 'PREMIUM_ACCOUNT_X';
const PremiumAccount1mCode = 'PREMIUM_ACCOUNT_1M';
const PremiumAccount6mCode = 'PREMIUM_ACCOUNT_6M';
const PremiumAccount1yCode = 'PREMIUM_ACCOUNT_1Y';
const AccountXCode = 'ACCOUNT_X';
const Account1mCode = 'ACCOUNT_1M';
const Account6mCode = 'ACCOUNT_6M';
const Account1yCode = 'ACCOUNT_1Y';
const AccountFreeCode = 'ACCOUNT_FREE';
const AccountDiscountCode = 'ACCOUNT_DISCOUNT';
const FreightProtection1mCode = 'FREIGHT_PROTECTION_1M';
const FreightProtection6mCode = 'FREIGHT_PROTECTION_6M';
const FreightProtection1yCode = 'FREIGHT_PROTECTION_1Y';
const FreightProtectionPpuCode = 'FREIGHT_PROTECTION_PPU';
const InvoiceNttCode = 'INVOICE_NTT';
const InvoiceFaxCode = 'INVOICE_FAX';
const InvoicePostCode = 'INVOICE_POST';
const InvoiceFaxPostCode = 'INVOICE_FAX_POST';
const InvoicePostForFreeCode = 'INVOICE_POST_FOR_FREE';
const InvoiceFreeCode = 'INVOICE_FREE';
const InvoiceNttXCode = 'INVOICE_NTT_X';
const InvoiceFaxXCode = 'INVOICE_FAX_X';
const InvoicePostXCode = 'INVOICE_POST_X';
const InvoiceFaxPostXCode = 'INVOICE_FAX_POST_X';
const InvoiceFreeXCode = 'INVOICE_FREE_X';
const TraboxTmsXCode = 'TRABOX_TMS_X';
const PrimeBaggageCompanyCode = 'PRIME_BAGGAGE_COMPANY';
const PrimeTruckCompanyCode = 'PRIME_TRUCK_COMPANY';
const PrimeBaggageAccountCode = 'PRIME_BAGGAGE_ACCOUNT';
const EasyPaymentCode = 'EASY_PAYMENT';
const Premium1mDiscountCode = 'PREMIUM_1M_DISCOUNT';
const FreightProtection1mDiscountCode = 'FREIGHT_PROTECTION_1M_DISCOUNT';

const ProductCodeList = [
    TrialCode,
    TrialAccountCode,
    PremiumXCode,
    Premium1mCode,
    Premium6mCode,
    Premium1yCode,
    PremiumAccountXCode,
    PremiumAccount1mCode,
    PremiumAccount6mCode,
    PremiumAccount1yCode,
    AccountXCode,
    Account1mCode,
    Account6mCode,
    Account1yCode,
    AccountFreeCode,
    AccountDiscountCode,
    FreightProtection1mCode,
    FreightProtection6mCode,
    FreightProtection1yCode,
    FreightProtectionPpuCode,
    InvoiceNttCode,
    InvoiceFaxCode,
    InvoicePostCode,
    InvoiceFaxPostCode,
    InvoicePostForFreeCode,
    InvoiceFreeCode,
    InvoiceNttXCode,
    InvoiceFaxXCode,
    InvoicePostXCode,
    InvoiceFaxPostXCode,
    InvoiceFreeXCode,
    TraboxTmsXCode,
    PrimeBaggageCompanyCode,
    PrimeTruckCompanyCode,
    PrimeBaggageAccountCode,
    EasyPaymentCode,
    Premium1mDiscountCode,
    FreightProtection1mDiscountCode,
] as const;
export type ProductCode = typeof ProductCodeList[number];

// プレミアムプラン
const PremiumCodeList = [Premium1mCode, Premium6mCode, Premium1yCode] as const;
export type PremiumPlanCode = typeof PremiumCodeList[number];

// 請求書発行手数料
const InvoiceCodeList = [
    InvoiceNttCode,
    InvoiceFaxCode,
    InvoicePostCode,
    InvoiceFaxPostCode,
    InvoicePostForFreeCode,
    InvoiceFreeCode,
    InvoiceNttXCode,
    InvoiceFaxXCode,
    InvoicePostXCode,
    InvoiceFaxPostXCode,
    InvoiceFreeXCode,
] as const;
export type InvoiceCode = typeof InvoiceCodeList[number];

export class ProductEnum {
    constructor(
        public code: ProductCode,
        public label: string,
        public activeStartCalculator = (contractStartTm: DateTimeValue): DateTimeValue => contractStartTm
    ) {
    }
    static Trial14d = new ProductEnum(TrialCode, 'プレミアムプラン　14日間　利用料サービス');
    static TrialAccount = new ProductEnum(TrialAccountCode, '14日間お試しユーザー');
    static PremiumX = new ProductEnum(PremiumXCode, 'プレミアムプラン（初月無料）');
    static Premium1m = new ProductEnum(Premium1mCode, 'プレミアムプラン　1ヶ月');
    static Premium6m = new ProductEnum(Premium6mCode, 'プレミアムプラン　6ヶ月');
    static Premium1y = new ProductEnum(Premium1yCode, 'プレミアムプラン　12ヶ月');
    static PremiumAccountX = new ProductEnum(PremiumAccountXCode, '有料別途課金ユーザー（初月サービス）');
    static PremiumAccount1m = new ProductEnum(PremiumAccount1mCode, 'プレミアムプランユーザー（月払い）');
    static PremiumAccount6m = new ProductEnum(PremiumAccount6mCode, 'プレミアムプランユーザー（半年）');
    static PremiumAccount1y = new ProductEnum(PremiumAccount1yCode, 'プレミアムプランユーザー（年）');
    static AccountX = new ProductEnum(AccountXCode, '有料別途課金追加ユーザー（初月サービス）');
    static Account1m = new ProductEnum(Account1mCode, 'ユーザー追加オプション　1ヶ月');
    static Account6m = new ProductEnum(Account6mCode, 'ユーザー追加オプション　6ヶ月');
    static Account1y = new ProductEnum(Account1yCode, 'ユーザー追加オプション　12ヶ月');
    static AccountFree = new ProductEnum(AccountFreeCode, 'ユーザー追加オプション　1ヶ月　利用料サービス');
    static AccountDiscount = new ProductEnum(AccountDiscountCode, 'ユーザー追加オプション　割引');
    static FreightProtection1m = new ProductEnum(FreightProtection1mCode, '運賃全額保証サービス　基本料金　1ヶ月');
    static FreightProtection6m = new ProductEnum(FreightProtection6mCode, '運賃全額保証サービス　基本料金　6ヶ月');
    static FreightProtection1y = new ProductEnum(FreightProtection1yCode, '運賃全額保証サービス　基本料金　12ヶ月');
    static FreightProtectionPpu = new ProductEnum(FreightProtectionPpuCode, '運賃全額保証サービス　従量料金');
    static InvoiceNtt = new ProductEnum(InvoiceNttCode, 'NTT請求手数料');
    static InvoiceFax = new ProductEnum(InvoiceFaxCode, '請求書送付手数料　FAX');
    static InvoicePost = new ProductEnum(InvoicePostCode, '請求書送付手数料　郵送');
    static InvoiceFaxPost = new ProductEnum(InvoiceFaxPostCode, '請求書送付手数料　FAX・郵送');
    static InvoicePostForFree = new ProductEnum(InvoicePostForFreeCode, '請求書送付手数料　郵送　手数料サービス');
    static InvoiceFree = new ProductEnum(InvoiceFreeCode, '請求書発行手数料なし（Web）');
    static InvoiceNttX = new ProductEnum(InvoiceNttXCode, 'NTT請求手数料（初月無料）');
    static InvoiceFaxX = new ProductEnum(InvoiceFaxXCode, '請求書送付手数料　FAX（初月無料）');
    static InvoicePostX = new ProductEnum(InvoicePostXCode, '請求書送付手数料　郵送（初月無料）');
    static InvoiceFaxPostX = new ProductEnum(InvoiceFaxPostXCode, '請求書送付手数料　FAX・郵送（初月無料）');
    static InvoiceFreeX = new ProductEnum(InvoiceFreeXCode, '請求書発行手数料なし（Web）');
    static TraboxTmsX = new ProductEnum(TraboxTmsXCode, 'トラボックス配車');
    static PrimeBaggageCompany = new ProductEnum(PrimeBaggageCompanyCode, 'プライム荷主');
    static PrimeTruckCompany = new ProductEnum(PrimeTruckCompanyCode, 'プライム運送');
    static PrimeBaggageAccount = new ProductEnum(PrimeBaggageAccountCode, 'プライム荷主ユーザー');
    static EasyPayment = new ProductEnum(EasyPaymentCode, 'おまかせ請求', param =>
        (param.isAfter(param.startOfMonth))
            ? param.add(1, 'month').startOfMonth
            : param.startOfMonth
    );
    static Premium1mDiscount = new ProductEnum(Premium1mDiscountCode, 'プレミアムプラン　1ヶ月　割引');
    static FreightProtection1mDiscount = new ProductEnum(FreightProtection1mDiscountCode, '運賃全額保証サービス　基本料金　1ヶ月　割引');
    static values = [
        ProductEnum.Trial14d,
        ProductEnum.TrialAccount,
        ProductEnum.PremiumX,
        ProductEnum.Premium1m,
        ProductEnum.Premium6m,
        ProductEnum.Premium1y,
        ProductEnum.PremiumAccountX,
        ProductEnum.PremiumAccount1m,
        ProductEnum.PremiumAccount6m,
        ProductEnum.PremiumAccount1y,
        ProductEnum.AccountX,
        ProductEnum.Account1m,
        ProductEnum.Account6m,
        ProductEnum.Account1y,
        ProductEnum.AccountFree,
        ProductEnum.AccountDiscount,
        ProductEnum.FreightProtection1m,
        ProductEnum.FreightProtection6m,
        ProductEnum.FreightProtection1y,
        ProductEnum.FreightProtectionPpu,
        ProductEnum.InvoiceNtt,
        ProductEnum.InvoiceFax,
        ProductEnum.InvoicePost,
        ProductEnum.InvoiceFaxPost,
        ProductEnum.InvoicePostForFree,
        ProductEnum.InvoiceFree,
        ProductEnum.InvoiceNttX,
        ProductEnum.InvoiceFaxX,
        ProductEnum.InvoicePostX,
        ProductEnum.InvoiceFaxPostX,
        ProductEnum.InvoiceFreeX,
        ProductEnum.TraboxTmsX,
        ProductEnum.PrimeBaggageCompany,
        ProductEnum.PrimeTruckCompany,
        ProductEnum.PrimeBaggageAccount,
        ProductEnum.EasyPayment,
        ProductEnum.Premium1mDiscount,
        ProductEnum.FreightProtection1mDiscount,
    ];

    static valueOf = (code: string): ProductEnum | undefined =>
        ProductEnum.values.find((value) => value.code === code);

    /**
     * 14日間お試しサービスであるか否かを判別します。
     */
    get isTrial(): boolean {
        return [TrialCode].includes(this.code);
    }

    /**
     * 14日間お試しユーザーであるか否かを判別します。
     */
    get isTrialAccount(): boolean {
        return [TrialAccountCode].includes(this.code);
    }

    /**
     * プレミアムプランサービスであるか否かを判別します。
     */
    get isPremium(): boolean {
        return [PremiumXCode, Premium1mCode, Premium6mCode, Premium1yCode].includes(this.code);
    }

    /**
     * プレミアムプラン（初月無料）であるか否かを判別します。
     */
    get isPremiumX(): boolean {
        return [PremiumXCode].includes(this.code);
    }

    /**
     * プレミアムプラン 1ヶ月であるか否かを判別します。
     */
    get isPremium1m(): boolean {
        return [Premium1mCode].includes(this.code);
    }

    /**
     * プレミアムプラン（有料支払い中）であるか否かを判別します
     */
    get isPaidPremium(): boolean {
        return [Premium1mCode, Premium6mCode, Premium1yCode].includes(this.code);
    }

    /**
     * アカウントであるか否かを判別します。
     */
    get isAccount(): boolean {
        return [AccountXCode, Account1mCode, Account6mCode, Account1yCode, AccountFreeCode].includes(this.code);
    }

    /**
     * 有料別途課金アカウントであるか否かを取得します。
     */
    get isAccountX(): boolean {
        return [AccountXCode].includes(this.code);
    }

    /**
     * 無料アカウントであるか否かを判別します。
     */
    get isAccountFree(): boolean {
        return [AccountFreeCode].includes(this.code);
    }

    /**
     * アカウント割引であるか否かを判別します。
     */
    get isAccountDiscount(): boolean {
        return [AccountDiscountCode].includes(this.code);
    }

    /**
     * 運賃全額保証サービスであるか否かを判別します。
     */
    get isGuarantee(): boolean {
        return [FreightProtection1mCode, FreightProtection6mCode, FreightProtection1yCode].includes(this.code);
    }

    /**
     * 請求書発行手数料であるか否かを判別します。
     */
    get isInvoice(): boolean {
        return [
            InvoiceNttCode,
            InvoiceFaxCode,
            InvoicePostCode,
            InvoiceFaxPostCode,
            InvoicePostForFreeCode,
            InvoiceFreeCode,
            InvoiceNttXCode,
            InvoiceFaxXCode,
            InvoicePostXCode,
            InvoiceFaxPostXCode,
            InvoiceFreeXCode,
        ].includes(this.code);
    }

    /**
     * NTT請求手数料であるか否かを判別します。
     */
    get isInvoiceNtt(): boolean {
        return [InvoiceNttCode].includes(this.code);
    }

    /**
     * NTT請求手数料（有料別途課金期間）であるか否かを判別します。
     */
    get isInvoiceNttX(): boolean {
        return [InvoiceNttXCode].includes(this.code);
    }

    /**
     * トラボックス配車であるか否かを判別します。
     */
    get isTraboxTms(): boolean {
        return [TraboxTmsXCode].includes(this.code);
    }

    /**
     * プライム荷主ユーザーであるか否かを判別します。
     */
    get isPrimeBaggageCompany(): boolean {
        return [PrimeBaggageCompanyCode].includes(this.code);
    }

    /**
     * プライム荷主ユーザーであるか否かを判別します。
     */
    get isPrimeBaggageAccount(): boolean {
        return [PrimeBaggageAccountCode].includes(this.code);
    }

    /**
     * おまかせ請求であるか否かを判別します。
     */
    get isEasyPayment(): boolean {
        return [EasyPaymentCode].includes(this.code);
    }

    /**
     * サービス開始日時を計算します。
     */
    calculateActiveStartTm(contractStartTm: DateTimeValue): DateTimeValue {
        return this.activeStartCalculator(contractStartTm);
    }
}
