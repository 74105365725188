import _ from 'lodash';
import { ValidationRule } from 'ant-design-vue/types/form-model/form';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TruckRegisterFormModel } from '@/vuex/modules/truck/types';
// @ts-ignore
import UiPrefectureSelect from '@/components/UI/PrefectureSelect';

@Component({
    components: {
        UiPrefectureSelect,
    },
})
export default class TruckAdditionalSpotEdit extends Vue {
    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly value?: TruckRegisterFormModel;

    get additionalDeparturePref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.additionalDeparturePref?.map((each) => each.code) || [];
    }

    set additionalDeparturePref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.additionalDeparturePref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemAdditionalDeparture.onFieldChange());
    }

    get additionalArrivalPref(): string[] {
        // Enum配列を文字配列へ変換
        return this.value?.additionalArrivalPref?.map((each) => each.code) || [];
    }

    set additionalArrivalPref(newValue: string[]) {
        const cloned = _.cloneDeep(this.value);
        if (!cloned) return;

        // 文字配列をEnum配列へ変換して更新
        cloned.additionalArrivalPref = newValue.map((each) => ({ code: each }));
        this.$emit('input', cloned);

        // @ts-ignore
        this.$nextTick(() => this.$refs.formItemAdditionalArrival.onFieldChange());
    }

    // ======================================================
    // Data
    // ======================================================
    validationRules: Array<ValidationRule> = [
        {
            required: false,
        },
    ];
}
