<script setup lang="ts">
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';
import { useAccountPasswordRemindHelper } from '@/_pages/Account/Password/Remind/account-password-remind-helper';

const {
    loading,
    form,
    formValidateRules,
    formModel,
    emailInput,
    onSubmit,
    onClickBackToLogin,
} = useAccountPasswordRemindHelper();
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <h1 class="body__title">パスワード再設定</h1>
            <div class="form">
                <a-spin :spinning="loading">
                    <a-form-model ref="formModel" :model="form" :rules="formValidateRules" :colon="false">
                        <div class="form__fields">
                            <a-form-model-item prop="emailAddress">
                                <a-input ref="emailInput"
                                         placeholder="メールアドレス"
                                         icon
                                         v-model="form.emailAddress">
                                    <template #prefix>
                                        <a-icon type="mail"/>
                                    </template>
                                </a-input>
                            </a-form-model-item>

                        </div>
                        <nav>
                            <ul class="form__actions">
                                <li>
                                    <a-button type="primary" @click="onSubmit">送信</a-button>
                                </li>
                                <li><a @click="onClickBackToLogin">
                                    <tbx-link-text type="product">ログインに戻る</tbx-link-text>
                                </a></li>
                            </ul>
                        </nav>
                    </a-form-model>
                </a-spin>
            </div>
        </div>
        <template #footer>
            <address class="footer">なお、メールへの連絡が届かない場合には、お手数ですがトラボックスまでご連絡をお願いします。
            </address>
        </template>
    </pre-login-layout>

</template>

<style scoped lang="less">
.body__container {
    width: 400px;
    min-height: 216px;
}

.body__title {
    margin-bottom: @padding-lg;
    font-size: 18px;
    line-height: 30px;
    color: #48483f;
}

.footer {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
}

.form {
    .form__fields {
        min-height: 60px;
    }

    .form__actions {
        margin: @padding-lg 0 0;
        padding: 0;
        list-style-type: none;

        > li {
            text-align: center;

            .ant-btn {
                min-width: 128px;
            }
        }

        > li + li {
            margin-top: @padding-sm;
        }
    }
}
</style>
