<script lang="ts" setup>
import { BaggageSearchExcludedCompany } from '@/models/baggage';
import { computed, ref, watch } from 'vue';
const props = defineProps<{
    excludedBaggageCount: number,
    excludedCompanyList: BaggageSearchExcludedCompany[] | undefined,
}>();

const emits = defineEmits<{
    (e: 'removeCompany', companyId: number): void,
}>();

const excludedCompanyCount = computed<number>(() => {
    return props.excludedCompanyList?.length ?? 0;
});
const anyExcludedCompanies = computed<boolean>(() => {
    return excludedCompanyCount.value > 0;
});
const anyExcludedBaggage = computed<boolean>(() => {
    return props.excludedBaggageCount > 0;
});
const visibleExcludedCompanies = ref<boolean>(false);
const onClickShowExcludedCompanies = () => {
    visibleExcludedCompanies.value = !visibleExcludedCompanies.value;
};
const onClickRemoveCompany = (companyId: number): void => {
    emits('removeCompany', companyId);
};
watch(excludedCompanyCount, (value) => {
    if (value === 0) {
        visibleExcludedCompanies.value = false;
    }
});
</script>

<template>
    <div class="container" v-if="anyExcludedCompanies">
        <span v-if="anyExcludedBaggage" class="msg-baggage-count">
            除外企業の荷物を{{ excludedBaggageCount }}件非表示にしました
        </span>
        <a-button @click="onClickShowExcludedCompanies"
                  type="link"
                  class="btn-show-companies"
                  :class="{ 'btn-show-companies--open': visibleExcludedCompanies }"
        >
            除外企業の解除<a-icon type="down"/>
        </a-button>
        <div v-if="visibleExcludedCompanies" class="container--companies">
            <a-button size="small" class="btn-remove-company" v-for="company in excludedCompanyList" :key="company.id" @click="onClickRemoveCompany(company.id)">
                {{ company.name }}
                <span class="btn-remove-company--icon"><a-icon class="fa-xs" type="close"/></span>
            </a-button>
        </div>
    </div>
</template>

<style lang="less" scoped>
.container {
    margin-top: -16px;
    padding: 0 16px 32px 16px;
    &--companies {
        margin-top: 16px;
    }
}

.msg-baggage-count {
    margin-right: 16px;
}

.btn-show-companies {
    i.anticon.anticon-down {
        height: 12px;
        transform-origin: 50% 50%;
        transition: transform 0.3s;
    }
    &--open {
        i.anticon.anticon-down {
            transform: scale(0.83333333) rotate(180deg);
        }
    }
    margin-left: -16px;
}

.btn-remove-company {
    color: @color-gray-3;
    margin-right: 10px;
    margin-bottom: 10px;
    &--icon {
        margin-left: 4px;
        i.anticon {
            vertical-align: 0;
        }
    }
}
</style>
