import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { NavigationGuardNext, Route as VueRoute } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { Const } from '@/const';
import { PageVue } from '@/mixin/PageVue';
import * as truckTypes from '@/vuex/modules/truck/types';
import * as companyTypes from '@/vuex/modules/company/types';
import store from '@/vuex/store';
// @ts-ignore
import SearchConditionForm from '@/components/Truck/Search/Condition';
// @ts-ignore
import Pagination from '@/components/Truck/Search/Pagination';
// @ts-ignore
import SearchResultList from '@/components/Truck/View/SearchResultList';
// @ts-ignore
import UiSortPaginationControl from '@/components/UI/SortPaginationControl';
// @ts-ignore
import TruckCompanyDetailDrawer from '@/pages/Truck/Search/CompanyDetailDrawer';
import { pagination } from '@/repository/storage/web-storage';
import { PageUtil, Util } from '@/util';
import { Karte } from '@/karte';

const truckMod = namespace('truck');
const companyMod = namespace('company');


@Component({
    title: '空車検索',
    components: {
        UiSortPaginationControl,
        SearchConditionForm,
        Pagination,
        SearchResultList,
        TruckCompanyDetailDrawer,
    },
    beforeRouteLeave: TruckSearchPage.beforeRouteLeave,
})
export default class TruckSearchPage extends PageVue {
    // ======================================================
    // Vuex Bindings
    // ======================================================
    @truckMod.Getter(truckTypes.GETTER.TRUCK_LIST)
    readonly TRUCK_LIST?: truckTypes.TruckList;
    @companyMod.Getter(companyTypes.GETTER.PROFILE_LIST)
    readonly PROFILE_LIST?: companyTypes.CompanyProfile[];

    // ======================================================
    // Properties
    // ======================================================
    @Prop()
    declare readonly companyId?: string;
    @Prop()
    declare readonly truckId?: string;

    // ======================================================
    // Data
    // ======================================================
    loading = false;
    formModel: Partial<truckTypes.TruckSearchForm> = {
        departureFrom: '',
        departureTo: '',
        departurePref: [],
        arrivalFrom: '',
        arrivalTo: '',
        arrivalPref: [],
        truckWeight: [],
        truckModel: [],
        minFreight: '',
        excludeUndecidedFreight: false,
    };
    form: truckTypes.TruckSearchForm = {
        departureFrom: '',
        departureTo: '',
        departurePref: [],
        arrivalFrom: '',
        arrivalTo: '',
        arrivalPref: [],
        truckWeight: [],
        truckModel: [],
        minFreight: '',
        excludeUndecidedFreight: false,
        sortKey: 'DEPARTURE',
        sortOrder: 'ASC',
        pageNo: 1,
        pageSize: Const.DEFAULT_PAGE_SIZE,
    };

    private routeUpdating = false;

    get truckList(): truckTypes.TruckWithCompanyList {
        /* 企業を探す関数 */
        const findCompany = (id: number) => this.PROFILE_LIST?.find((each) => each.id === id);

        /* 空車情報と企業プロフィールをマージする関数 */
        const mergeCompany = (truck: truckTypes.Truck) => {
            return _.merge({}, truck, { company: findCompany(truck.companyId) });
        };
        return { ...this.TRUCK_LIST, data: this.TRUCK_LIST?.data?.map(mergeCompany) };
    }

    /**
     * 企業詳細ドロワーの表示状態を取得します。
     */
    get drawerVisibility(): boolean {
        // ルーティング更新中はDrawerを表示しない
        if (this.routeUpdating) {
            return false;
        }
        return Util.isNumeric(this.companyId);
    }

    // ======================================================
    // Functions
    // ======================================================
    created(): void {
        const pageSize = pagination.getPageSize();
        if (pageSize && Const.PAGE_SIZE_OPTIONS.includes(pageSize ?? '')) {
            this.form.pageSize = Number(pageSize);
        } else {
            pagination.clearPageSize();
        }
    }

    async mounted(): Promise<void> {
        await this.searchTruck({ ...this.form, pageNo: 1 });
    }

    /**
     * 検索ボタンが押下された際に呼び出されます。
     */
    async onClickSearch(): Promise<void> {
        // フォーム内容を検索条件へ反映
        this.commitForm();

        await this.searchTruck(this.form);
        // KARTEイベント送信：空きトラック検索ボタン押下
        Karte.trackSearchTruck();
        this.scrollToSearchResultsSection();
    }

    /**
     * ソート順かソートキーを操作した際に呼び出されます。
     */
    async onChangeSort(param: { sortKey: string; sortAscending: boolean }): Promise<void> {
        // ソート順定義を構築
        const definition = new Map(Const.truckSortKey.map(each => [each.code, each.defaultOrder]))
            .set(this.form.sortKey, param.sortAscending ? 'ASC' : 'DESC');
        // ソートキー
        this.form.sortKey = param.sortKey;
        // ソート順
        this.form.sortOrder = definition.get(param.sortKey) ?? this.form.sortOrder;
        // ページ番号(1にリセット)
        this.form.pageNo = 1;
        // 検索条件をフォームへ反映
        this.revertForm();

        await this.searchTruck(this.form);
    }

    /**
     * ページネーションを操作した際に呼び出されます。
     * （前ページ、次ページ、1ページあたりの表示件数変更）
     */
    async onChangePage(param: { pageNo: number; pageSize: number }): Promise<void> {
        // 検索条件をフォームへ反映
        this.revertForm();

        this.form.pageNo = param.pageNo;
        this.form.pageSize = param.pageSize;
        await this.searchTruck(this.form);
        this.scrollToSearchResultsSection();
        pagination.setPageSize(param.pageSize.toString());
    }

    /**
     * 指定した検索条件で空車情報を検索します。
     */
    searchTruck(form: truckTypes.TruckSearchForm): Promise<void> {
        this.loading = true;
        const notifyFailed = () => {
            this.$message.error('空車情報の検索に失敗しました。検索条件を見直してもう一度お試しください。');
        };
        return TruckSearchPage.searchTruck(form)
            .catch(notifyFailed)
            .finally(() => (this.loading = false));
    }

    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    scrollToSearchResultsSection(): void {
        PageUtil.scrollToContentTop(document.getElementById('jsi-search-results')?.offsetTop);
    }

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     */
    async onClickGround(): Promise<void> {
        // 連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            return;
        }
        // Drawerが開いている場合は閉じる
        if (this.drawerVisibility) {
            await this.onClickCloseDrawer();
        }
    }

    /**
     * ドロワーが閉じる際に呼び出されます。
     */
    async onClickCloseDrawer(): Promise<void> {
        await this.$router.push({ path: this.$route.path });
    }

    /**
     * 空車情報を検索します。
     */
    private static searchTruck(form: truckTypes.TruckSearchForm): Promise<void> {
        return (
            store
                .dispatch(`truck/${ truckTypes.ACTION.SEARCH_TRUCK }`, form)
                // ロードした空車情報リストから
                .then(() => store.getters[`truck/${ truckTypes.GETTER.TRUCK_LIST }`] as truckTypes.TruckList)
                // 空車情報登録企業のIDを抽出
                .then((truckList) => _.uniq(truckList.data.map((each) => each.companyId)))
                // 企業情報をロード
                .then((companyIdList) => TruckSearchPage.loadCompanyProfileList(companyIdList))
        );
    }

    /**
     * 企業プロフィールリストをロードします。
     */
    private static loadCompanyProfileList(list: number[]): Promise<void> {
        if (_.isEmpty(list)) return Promise.resolve();
        const form: companyTypes.CompanyProfileListForm = { id: list };
        return store.dispatch(`company/${ companyTypes.ACTION.LIST_PROFILE }`, form);
    }

    async beforeRouteUpdate(to: VueRoute, _from: VueRoute, next: NavigationGuardNext<TruckSearchPage>): Promise<void> {
        // Drawer＆検索結果アイテムの連打によるルーティングの多重実行を防止
        if (this.routeUpdating) {
            next(false);
            return;
        }
        //
        // ドロワー表示データ処理
        //
        try {
            const { companyId } = to.query;
            // 詳細（ドロワー表示） → 詳細（ドロワー表示）へ遷移するときのルーティングの制御
            if (companyId && this.drawerVisibility && _from.query.companyId !== companyId) {
                this.$nextTick(() => (this.routeUpdating = true)); // 強制的にDOM反映させるために$nextTickを使用
                // ドロワーの開閉アニメーション時間を担保するため、300ms遅延させてルーティングを通す
                setTimeout(() => {
                    this.routeUpdating = false;
                    next();
                }, 300);
            } else {
                next();
            }
        } catch (err) {
            next(false);
        } finally {
            this.routeUpdating = false;
        }
    }

    static async beforeRouteLeave(
        _to: VueRoute,
        _from: VueRoute,
        next: NavigationGuardNext<TruckSearchPage>
    ): Promise<void> {
        // 空車一覧をクリア
        await store.dispatch(`truck/${ truckTypes.ACTION.CLEAR_TRUCK_LIST }`);
        // 企業一覧をクリア
        await store.dispatch(`company/${ companyTypes.ACTION.CLEAR_PROFILE_LIST }`);
        next();
    }

    /**
     * 検索条件フォーム内容を検索条件として確定させます。
     */
    private commitForm(): void {
        Object.assign(this.form, this.formModel);
    }

    /**
     * 確定済みの検索条件を検索フォームへ戻します。
     */
    private revertForm(): void {
        Object.assign(this.formModel, _.pick(this.form, _.keys(this.formModel)));
    }
}
