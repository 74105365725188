import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyConfidence, CompanyProfile, CompanyStatistics, OfficialCompany } from '@/vuex/modules/company/types';
import { DateUtil, PhoneUtil, ZipUtil } from '@/util';
import { Const } from '@/const';
import { Validator } from '@/validator';
import TbxLinkText from '@/_components/ui/TbxLinkText.vue';

@Component({
    components: { TbxLinkText }
})
export default class CompanySummary extends Vue {
    @Prop()
    declare readonly confidence?: CompanyConfidence;
    @Prop()
    declare readonly profile?: CompanyProfile;
    @Prop()
    declare readonly officialCompany?: OfficialCompany;
    @Prop()
    declare readonly statistics?: CompanyStatistics;
    @Prop({ default: false })
    declare readonly enableNameLink: boolean;
    @Prop({ default: false })
    declare readonly hideContact: boolean;

    openedContact = false;

    get closedContact(): boolean {
        return this.hideContact && !this.openedContact;
    }

    /**
     * ウェブサイトURLが正しい形式であるか否かを取得します。
     */
    get urlValid(): boolean {
        return Validator.validateUrl(this.profile?.url ?? '').result;
    }

    /**
     * 企業名が押下された際に呼び出されます。
     */
    onClickCompanyName(): void {
        if (!this.profile) return;
        this.$emit('clickCompanyName', this.profile.id);
    }

    /**
     * 郵便番号をフォーマットします。
     * @param zipCode
     */
    formatZipCode(zipCode: string): string {
        return ZipUtil.format(zipCode);
    }

    /**
     * 電話番号をフォーマットします。
     * @param phoneNumber
     */
    formatPhoneNumber(phoneNumber: string): string {
        return PhoneUtil.format(phoneNumber);
    }

    /**
     * 日付を年月でフォーマットします。
     * @param date
     */
    formatYearMonth(date: string): string {
        return DateUtil.parseDateText(date).format(Const.DEFAULT_DATE_YEAR_MONTH_FORMAT);
    }

    /**
     * 金額を万円単位でフォーマットします。
     * @param value
     */
    formatManYen(value: number): string {
        return `${(value / 10000).toString()} 万円`;
    }

    /**
     * boolean値を「有」or「無」でフォーマットします。
     */
    formatYesNo(value: boolean): string {
        return value ? '有' : '無';
    }

    onClickContact(): void {
        this.openedContact = true;
        this.$emit('clickContact');
    }
}
