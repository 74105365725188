import { Component, Prop, Vue } from 'vue-property-decorator';
import { PageInfo } from '@/components/UI/PaginationControl/types';

@Component
export default class UiPaginationControl extends Vue {
    @Prop()
    declare readonly pageInfo?: PageInfo;

    onChangePage(pageNo: number, pageSize: number): void {
        this.$emit('change', { pageNo, pageSize });
    }
}
