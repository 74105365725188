import _ from 'lodash';

export class URLUtil {
    /**
     * クエリの値をパースします。
     * @param value
     */
    static parseQueryValue(value?: string | (string | null)[]): string | undefined {
        if (!value) {
            return undefined;
        } else if (Array.isArray(value)) {
            const lastValue = _.last(value);
            return lastValue === null ? undefined : lastValue;
        }
        return value;
    }
}
