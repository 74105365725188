import {
    ACTION as a,
    GETTER as g,
    MUTATION as m,
    DeliveryOrderState,
    DeliveryOrderModel
} from './types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { deliveryOrderApi } from '@/repository/api/internal/delivery-order';
import { DeliveryOrderList, DeliveryOrderListViewForm } from '@/models/delivery-order';

const state: DeliveryOrderState = {
    deliveryOrderList: {
        data: [],
    },
    deliveryOrderExist: false,
};

const actions: ActionTree<DeliveryOrderState, void> = {
    /**
     * 配送依頼書一覧をロードします。
     */
    [a.LOAD_DELIVERY_ORDER_LIST]: ({ commit }, param: number[]) => {
        const form = new DeliveryOrderListViewForm(param);
        deliveryOrderApi.list(form).then((deliveryOrderList) => {
            const list = deliveryOrderList.map((value) => new DeliveryOrderModel(value));
            commit(m.SET_DELIVERY_ORDER_LIST, list);
            return deliveryOrderList;
        });
    },
    /**
     * 成約に紐づく配送依頼書の存在をチェックします。
     */
    [a.DELIVERY_ORDER_EXIST]: ({ commit }, param: number) => {
        deliveryOrderApi.exist(param).then((deliveryOrderExist) => {
            const exist = !!deliveryOrderExist.id;
            commit(m.SET_DELIVERY_ORDER_EXIST, exist);
            return exist;
        });
    }
};

const getters: GetterTree<DeliveryOrderState, void> = {
    /**
     * 配送依頼書一覧を取得します。
     */
    [g.DELIVERY_ORDER_LIST]:  (s ) => s.deliveryOrderList,
    /**
     * 成約に紐づく配送依頼書の存在を取得します。
     */
    [g.DELIVERY_ORDER_EXIST]: (s ) => s.deliveryOrderExist
};

const mutations: MutationTree<DeliveryOrderState> = {
    /**
     * 配送依頼書一覧を設定します。
     */
    [m.SET_DELIVERY_ORDER_LIST]: (s, list: DeliveryOrderList) => (s.deliveryOrderList = list),
    /**
     * 成約に紐づく配送依頼書の存在を設定します。
     */
    [m.SET_DELIVERY_ORDER_EXIST]: (s, exist: boolean) => (s.deliveryOrderExist = exist)
};

export const deliveryOrder: Module<DeliveryOrderState, void> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
