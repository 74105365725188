import { PaginatedList } from '@/types/paginated-list';
import { Enum } from '@/types/enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import * as companyTypes from '@/models/company';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { DateValue } from '@/models/vo/date';
import { AgreementUtil, BaggageDetailUtil, BaggageUtil, DateUtil, Util } from '@/util';
import { FreightValue } from '@/models/vo/freight';
import { DateTimeValue } from '@/models/vo/datetime';
import _ from 'lodash';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { Validator } from '@/validator';
import { Const } from '@/const';
import dayjs from 'dayjs';
import { BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';
import { DeliveryDateTimeRange, DeliveryDateTimeRangeType } from '@/models/vo/delivery-datetime-range';
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';

export interface AgreementList extends PaginatedList {
    data: Agreement[];
}

export interface Agreement {
    id: number;
    baggageId: number;
    baggageCompanyId: number;
    truckCompanyId: number;
    status: Enum;
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: Enum;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    truckCount: number;
    share: boolean;
    express: boolean;
    freight: number;
    highwayFareFlg: boolean;
    highwayFare: number;
    waitTimeFee?: number;
    operationFee?: number;
    pickingFee?: number;
    parkingFee?: number;
    clearanceFee?: number;
    staffName: string;
    truckStaffName?: string;
    description?: string;
    paymentDate: string;
    guarantee: boolean;
    settlementProxy: boolean;
    shipperName?: string;
    entryTm: string;
    changeLimitTm: string;
}

export class AgreementListModel implements PaginatedList {
    currentPageNumber: number;
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    data: AgreementModel[];

    constructor(param: AgreementList) {
        this.currentPageNumber = param.currentPageNumber;
        this.pageSize = param.pageSize;
        this.totalPageCount = param.totalPageCount;
        this.totalRecordCount = param.totalRecordCount;
        this.data = param.data.map(each => new AgreementModel(each));
    }
}

export class AgreementModel {
    id: number;
    baggageId: number;
    baggageCompanyId: number;
    truckCompanyId: number;
    status: Enum;
    departure: DeliveryDateTimeRange;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrival: DeliveryDateTimeRange;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: Enum;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    truckCount: number;
    share: boolean;
    express: boolean;
    freight: FreightValue;
    highwayFareFlg: boolean;
    highwayFare: FreightValue;
    waitTimeFee?: number;
    operationFee?: number;
    pickingFee?: number;
    parkingFee?: number;
    clearanceFee?: number;
    staffName: string;
    truckStaffName?: string;
    description?: string;
    paymentDate: DateValue;
    guarantee: boolean;
    entryTm: DateTimeValue;
    changeLimitTm: DateTimeValue;

    constructor(param: Agreement) {
        this.id = param.id;
        this.baggageId = param.baggageId;
        this.baggageCompanyId = param.baggageCompanyId;
        this.truckCompanyId = param.truckCompanyId;
        this.status = param.status;
        this.departure = Util.requireNotNull(DeliveryDateTimeRange.of(param.departureMin, param.departureMax));
        this.departurePref = param.departurePref;
        this.departureCity = param.departureCity;
        this.departureAddress = param.departureAddress;
        this.loadingTimeNote = param.loadingTimeNote;
        this.arrival = Util.requireNotNull(DeliveryDateTimeRange.of(param.arrivalMin, param.arrivalMax));
        this.arrivalPref = param.arrivalPref;
        this.arrivalCity = param.arrivalCity;
        this.arrivalAddress = param.arrivalAddress;
        this.unloadingTimeNote = param.unloadingTimeNote;
        this.category = param.category;
        this.type = param.type;
        this.shape = param.shape;
        this.temperatureZone = param.temperatureZone;
        this.paletteCount = param.paletteCount;
        this.paletteHeight = param.paletteHeight;
        this.paletteWidth = param.paletteWidth;
        this.totalCount = param.totalCount;
        this.totalVolume = param.totalVolume;
        this.totalWeight = param.totalWeight;
        this.loading = param.loading;
        this.unloading = param.unloading;
        this.truckWeight = param.truckWeight;
        this.truckModel = param.truckModel;
        this.truckHeight = param.truckHeight;
        this.truckWidth = param.truckWidth;
        this.largeTruckFlg = param.largeTruckFlg;
        this.truckEquipment = param.truckEquipment;
        this.truckCount = param.truckCount;
        this.share = param.share;
        this.express = param.express;
        this.freight = new FreightValue(param.freight);
        this.highwayFareFlg = param.highwayFareFlg;
        this.highwayFare = new FreightValue(param.highwayFare);
        this.waitTimeFee = param.waitTimeFee;
        this.operationFee = param.operationFee;
        this.pickingFee = param.pickingFee;
        this.parkingFee = param.parkingFee;
        this.clearanceFee = param.clearanceFee;
        this.staffName = param.staffName;
        this.truckStaffName = param.truckStaffName;
        this.description = param.description;
        this.paymentDate = new DateValue(DateUtil.parseDateText(param.paymentDate));
        this.guarantee = param.guarantee;
        this.entryTm = new DateTimeValue(DateUtil.parseDatetimeText(param.entryTm));
        this.changeLimitTm = new DateTimeValue(DateUtil.parseDatetimeText(param.changeLimitTm));
    }

    get departureLocation(): string {
        return [
            this.departurePref.label ?? '',
            this.departureCity ?? '',
            this.departureAddress ?? '',
        ].join('');
    }

    get arrivalLocation(): string {
        return [
            this.arrivalPref.label ?? '',
            this.arrivalCity ?? '',
            this.arrivalAddress ?? '',
        ].join('');
    }

    get paletteCountText(): string | undefined {
        return BaggageDetailUtil.paletteCount(
            this.shape?.code,
            this.paletteCount
        );
    }

    get paletteSizeText(): string | undefined {
        return BaggageDetailUtil.paletteSize(
            this.shape?.code,
            this.paletteHeight,
            this.paletteWidth
        );
    }

    get totalCountText(): string | undefined {
        return BaggageDetailUtil.totalCount(
            this.shape?.code,
            this.totalCount
        );
    }

    get totalVolumeText(): string | undefined {
        return BaggageDetailUtil.totalVolume(
            this.shape?.code,
            this.totalVolume
        );
    }

    get totalWeightText(): string | undefined {
        return BaggageDetailUtil.totalWeight(this.totalWeight);
    }

    get handlingText(): string | undefined {
        return BaggageDetailUtil.handling(
            this.loading?.code,
            this.unloading?.code
        );
    }

    get temperatureZoneText(): string | undefined {
        return this.temperatureZone?.label;
    }

    get largeTruckText(): string {
        return _.isNil(this.largeTruckFlg) ? '指定なし' : this.largeTruckFlg ? '可' : '不可';
    }

    get highwayFareText(): string {
        if (!this.highwayFareFlg) return 'なし';
        return (this.highwayFare.value ?? 0) <= 0 ? '金額未定' : this.highwayFare.format();
    }

    get waitTimeFeeText(): string {
        return new FreightValue(this.waitTimeFee).format('', '円', '金額未定');
    }

    get operationFeeText(): string {
        return new FreightValue(this.operationFee).format('', '円', '金額未定');
    }

    get pickingFeeText(): string {
        return new FreightValue(this.pickingFee).format('', '円', '金額未定');
    }

    get parkingFeeText(): string {
        return new FreightValue(this.parkingFee).format('', '円', '金額未定');
    }

    get clearanceFeeText(): string {
        return new FreightValue(this.clearanceFee).format('', '円', '金額未定');
    }
}

export interface AgreementChangeRequest {
    id: number;
    agreementId: number;
    changeType: Enum;
    status: Enum;
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: Enum;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    truckCount: number;
    share: boolean;
    express: boolean;
    freight: number;
    highwayFareFlg: boolean;
    highwayFare: number;
    waitTimeFee?: number;
    operationFee?: number;
    pickingFee?: number;
    parkingFee?: number;
    clearanceFee?: number;
    staffName: string;
    truckStaffName?: string;
    description?: string;
    paymentDate: string;
    requestDatetime: string;
    actorType: Enum;
    actorCompanyId?: number;
    actorId: number;
}

// 決済代行
export type SettlementProxyFormItem =
    // 決済代行を適用しない
    { use: false } |
    // 決済代行を適用する
    { use: true, confirmed: boolean };

export interface AgreementForm {
    baggageId: number;
    departureMin: string;
    departureMax: string;
    departurePref: { code?: string };
    departureCity: string;
    departureAddress?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: { code?: string };
    arrivalCity: string;
    arrivalAddress?: string;
    type: string;
    shape: Enum<BaggageShapeEnumCode | ''>;
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    freight: string;
    highwayFare: number | string; // NOTE: FormModelItemではstring、API送出時はnumberで扱っている
    waitTimeFee: number | undefined;
    operationFee: number | undefined;
    pickingFee: number | undefined;
    parkingFee: number | undefined;
    clearanceFee: number | undefined;
    highwayFareFlg: boolean;
    paymentDate: string;
    truckStaffName?: string;
    description: string;
    guarantee: boolean;
    settlementProxy: boolean;
}

export class AgreementFormModel implements FormValidatable<AgreementFormModel> {
    baggageId: number = 0;
    departureMin: string = '';
    departureMax: string = '';
    departureType?: DeliveryDateTimeRangeType;
    departurePref: { code?: string } = { code: undefined };
    departureCity: string = '';
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string = '';
    arrivalMax: string = '';
    arrivalType?: DeliveryDateTimeRangeType;
    arrivalPref: { code?: string } = { code: undefined };
    arrivalCity: string = '';
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    type: string = '';
    shape: Enum<BaggageShapeEnumCode | ''> = BaggageShapeEnum.Palette;
    temperatureZone: { code?: string } = { code: undefined };
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    freight: string = '';
    highwayFare: number | string = 0; // NOTE: FormModelItemではstring、API送出時はnumberで扱っている
    highwayFareFlg: boolean = false;
    waitTimeFee: number | undefined;
    operationFee: number | undefined;
    pickingFee: number | undefined;
    parkingFee: number | undefined;
    clearanceFee: number | undefined;
    paymentDate: string = '';
    truckStaffName?: string;
    description: string = '';
    guarantee: boolean = false;
    settlementProxy: SettlementProxyFormItem | undefined;

    previousType: string = '';

    /**
     * 出発日時
     */
    get departureDateTimeRange(): DateTimeRangePickerValue {
        // TODO: 将来的にはDeliveryDateTime型で扱いたい
        return {
            min: this.departureMin,
            max: this.departureMax,
            type: this.departureType,
        };
    }

    set departureDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        this.departureMin = min ?? '';
        this.departureMax = max ?? '';
        this.departureType = type;
    }

    get departure(): DeliveryDateTimeRange | null {
        return (this.departureMin && this.departureMax && this.departureType) ?
            DeliveryDateTimeRange.typeOf(this.departureType, this.departureMin, this.departureMax) : null;
    }

    /**
     * 到着日時
     */
    get arrivalDateTimeRange(): DateTimeRangePickerValue {
        return {
            min: this.arrivalMin,
            max: this.arrivalMax,
            type: this.arrivalType,
        };
    }

    set arrivalDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        this.arrivalMin = min ?? '';
        this.arrivalMax = max ?? '';
        this.arrivalType = type;
    }
    get arrival(): DeliveryDateTimeRange | null {
        return (this.arrivalMin && this.arrivalMax && this.arrivalType) ?
            DeliveryDateTimeRange.typeOf(this.arrivalType, this.arrivalMin, this.arrivalMax) : null;
    }

    /**
     * 出発地（都道府県）
     */
    get departurePrefCode(): PrefectureEnumCode {
        return this.departurePref.code as PrefectureEnumCode;
    }

    set departurePrefCode(value: PrefectureEnumCode) {
        const changed = this.departurePref.code !== value;

        // 出発地を書き換え
        // 都道府県
        this.departurePref = { code: value };
        // 市区町村（都道府県が変更された場合はクリア）
        this.departureCity = changed ? '' : this.departureCity;
        // 番地・建物（都道府県変更された場合はクリア）
        this.departureAddress = changed ? '' : this.departureAddress;
    }

    /**
     * 到着地（都道府県）
     */
    get arrivalPrefCode(): PrefectureEnumCode {
        return this.arrivalPref.code as PrefectureEnumCode;
    }

    set arrivalPrefCode(value: PrefectureEnumCode) {
        const changed = this.arrivalPref.code !== value;

        // 到着地を書き換え
        // 都道府県
        this.arrivalPref = { code: value };
        // 市区町村（都道府県が変更された場合はクリア）
        this.arrivalCity = changed ? '' : this.arrivalCity;
        // 番地・建物（都道府県変更された場合はクリア）
        this.arrivalAddress = changed ? '' : this.arrivalAddress;
    }

    get hasLoadingTimeNote(): boolean {
        return !_.isEmpty(this.loadingTimeNote);
    }

    get hasUnloadingTimeNote(): boolean {
        return !_.isEmpty(this.unloadingTimeNote);
    }

    get shapeCode(): BaggageShapeEnumCode {
        return this.shape.code as BaggageShapeEnumCode;
    }

    set shapeCode(value: BaggageShapeEnumCode) {
        this.shape = BaggageShapeEnum.valueOf(value) ?? BaggageShapeEnum.Palette;
        // 荷姿の変更時の荷種の補完
        switch (this.shape.code) {
            case BaggageShapeEnum.Palette.code:
                this.previousType = this.type.trim();
                this.type = 'パレット';
                break;
            case BaggageShapeEnum.Other.code:
                this.type = this.previousType;
                break;
            default:
                break;
        }
    }

    get temperatureZoneCode(): string {
        return this.temperatureZone.code ?? '';
    }

    set temperatureZoneCode(value: string) {
        this.temperatureZone = { code: value };
    }

    get paletteCountText(): string {
        return this.paletteCount ?? '';
    }

    set paletteCountText(value: string) {
        this.paletteCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteSize(): any {
        return {
            paletteHeight: this.paletteHeight,
            paletteWidth: this.paletteWidth,
        };
    }


    get paletteHeightText(): string {
        return this.paletteHeight ?? '';
    }

    set paletteHeightText(value: string) {
        this.paletteHeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteWidthText(): string {
        return this.paletteWidth ?? '';
    }

    set paletteWidthText(value: string) {
        this.paletteWidth = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalCountText(): string {
        return this.totalCount ?? '';
    }

    set totalCountText(value: string) {
        this.totalCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalVolumeText(): string {
        return this.totalVolume ?? '';
    }

    set totalVolumeText(value: string) {
        this.totalVolume = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalWeightText(): string {
        return this.totalWeight ?? '';
    }

    set totalWeightText(value: string) {
        this.totalWeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get loadingCode(): string | undefined {
        return this.loading?.code;
    }

    set loadingCode(value: string | undefined) {
        this.loading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get unloadingCode(): string | undefined {
        return this.unloading?.code;
    }

    set unloadingCode(value: string | undefined) {
        this.unloading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get freightValue(): string {
        return this.freight;
    }

    set freightValue(value: string) {
        const parsed = Util.parseFreightString(value);
        this.freight = isNaN(Util.toNumber(parsed)) ? '' : `${parsed}`;
    }

    get highwayFareValue(): number | string {
        return this.highwayFare !== 0 ? `${ this.highwayFare }` : '';
    }

    set highwayFareValue(value: number | string) {
        const parsed = Util.toNumber(Util.parseFreightString(value));
        this.highwayFare = isNaN(parsed) ? 0 : parsed;
    }

    get noHighwayFare(): boolean {
        return !(this.highwayFareFlg ?? false);
    }

    set noHighwayFare(value: boolean) {
        this.highwayFareFlg = !value;
        if (value) {
            this.highwayFare = 0;
        }
    }

    get waitTimeFeeValue(): number | string {
        return this.waitTimeFee ?? '';
    }

    set waitTimeFeeValue(value: number | string) {
        const freightString = Util.parseAmountString(value, Const.MAX_WAIT_TIME_FEE);
        if (_.isEmpty(freightString)) {
            this.waitTimeFee = undefined;
            return;
        }
        const parsed = Util.toNumber(freightString);
        this.waitTimeFee = isNaN(parsed) ? undefined : parsed;
    }

    get operationFeeValue(): number | string {
        return this.operationFee ?? '';
    }

    set operationFeeValue(value: number | string) {
        const freightString = Util.parseAmountString(value, Const.MAX_OPERATION_FEE);
        if (_.isEmpty(freightString)) {
            this.operationFee = undefined;
            return;
        }
        const parsed = Util.toNumber(freightString);
        this.operationFee = isNaN(parsed) ? undefined : parsed;
    }

    get pickingFeeValue(): number | string {
        return this.pickingFee ?? '';
    }

    set pickingFeeValue(value: number | string) {
        const freightString = Util.parseAmountString(value, Const.MAX_PICKING_FEE);
        if (_.isEmpty(freightString)) {
            this.pickingFee = undefined;
            return;
        }
        const parsed = Util.toNumber(freightString);
        this.pickingFee = isNaN(parsed) ? undefined : parsed;
    }

    get parkingFeeValue(): number | string {
        return this.parkingFee ?? '';
    }

    set parkingFeeValue(value: number | string) {
        const freightString = Util.parseAmountString(value, Const.MAX_PARKING_FEE);
        if (_.isEmpty(freightString)) {
            this.parkingFee = undefined;
            return;
        }
        const parsed = Util.toNumber(freightString);
        this.parkingFee = isNaN(parsed) ? undefined : parsed;
    }

    get clearanceFeeValue(): number | string {
        return this.clearanceFee ?? '';
    }

    set clearanceFeeValue(value: number | string) {
        const freightString = Util.parseAmountString(value, Const.MAX_CLEARANCE_FEE);
        if (_.isEmpty(freightString)) {
            this.clearanceFee = undefined;
            return;
        }
        const parsed = Util.toNumber(freightString);
        this.clearanceFee = isNaN(parsed) ? undefined : parsed;
    }

    get paymentDateValue(): DateValue | undefined {
        return !_.isEmpty(this.paymentDate) ? new DateValue(this.paymentDate) : undefined;
    }

    set paymentDateValue(value: DateValue | undefined) {
        this.paymentDate = value?.format('YYYY-MM-DD') ?? '';
    }

    /**
     * 荷姿がパレットであるか否か
     */
    get isShapePalette(): boolean {
        return this.shape?.code === BaggageShapeEnum.Palette.code;
    }

    /**
     * 荷姿がその他であるか否か
     */
    get isShapeOther(): boolean {
        return this.shape?.code === BaggageShapeEnum.Other.code;
    }

    /**
     * 入金予定日として選択できる範囲
     */
    get availablePaymentDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 計算基準は着日、フォールバックとして発日を考慮
        const criteria = [this.arrivalMax, this.departureMax].find(_.negate(_.isEmpty)) ?? '';

        return AgreementUtil.availablePaymentDateRange(new DateValue(criteria).value, false);
    }

    /**
     * 入金予定日として選択できない日付であるか否かを取得します。
     */
    isDisabledPaymentDate = (currentDate: DateValue): boolean => {
        return !DateUtil.isIncluded(currentDate.value, this.availablePaymentDateRange);
    };

    normalize(): void {
        this.departureCity = this.departureCity.trim();
        this.departureAddress = this.departureAddress?.trim();
        this.arrivalCity = this.arrivalCity.trim();
        this.arrivalAddress = this.arrivalAddress?.trim();
        this.type = this.type.trim();
        this.description = this.description.trim();
        this.truckStaffName = this.truckStaffName?.trim();
    }

    toForm(): AgreementForm {
        return {
            ...this,
            settlementProxy: this.settlementProxy !== undefined && this.settlementProxy.use,
        };
    }

    validator(): FormValidator<AgreementFormModel> {
        return {
            departureDateTimeRange: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const validated = Validator.validateDateTimeRangeType(this.departure, null);
                        if (!validated.result) {
                            callback(validated.message);
                            return;
                        }
                        callback();
                    }
                }
            ],
            departureAddress: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.departurePrefCode) {
                            callback('都道府県を選択してください。');
                        } else if (_.isEmpty(this.departureCity.trim())) {
                            callback('市区町村を入力してください。');
                        } else if (this.departureCity.length > 200) {
                            callback('市区町村名は200文字以内で入力してください。');
                        } else if ((this.departureAddress?.length ?? 0) > 200) {
                            callback('番地・建物は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                }
            ],
            arrivalDateTimeRange: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const validated = Validator.validateDateTimeRangeType(this.arrival, null);
                        if (!validated.result) {
                            callback(validated.message);
                            return;
                        }
                        const rangeValidated = Validator.validateMultipleDateTimeRange(this.departure, this.arrival);
                        if (!rangeValidated.result) {
                            callback(rangeValidated.message);
                            return;
                        }
                        callback();
                    }
                }
            ],
            arrivalAddress: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.arrivalPrefCode) {
                            callback('都道府県を選択してください。');
                        } else if (_.isEmpty(this.arrivalCity.trim())) {
                            callback('市区町村を入力してください。');
                        } else if (this.arrivalCity.length > 200) {
                            callback('市区町村名は200文字以内で入力してください。');
                        } else if ((this.arrivalAddress?.length ?? 0) > 200) {
                            callback('番地・建物は200文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    }
                }
            ],
            shape: [
                {
                    required: true,
                    message: '荷姿を選択してください。',
                }
            ],
            type: [
                {
                    required: true,
                    whitespace: true,
                    message: '荷種を入力してください。',
                },
                {
                    max: 250,
                    message: '荷種は250文字以内で入力してください。',
                },
            ],
            paletteCount: [
                {
                    pattern: /^[0-9]+$/,
                    message: 'パレット枚数（目安）は数字で入力してください。',
                },
                {
                    max: Const.MAX_PALETTE_COUNT_DIGITS,
                    message: `パレット枚数（目安）は最大${ Const.MAX_PALETTE_COUNT_DIGITS }桁で入力してください。`,
                }
            ],
            paletteSize: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        const height = this.paletteHeight;
                        const width = this.paletteWidth;

                        if (height) {
                            if (height.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                                callback(`パレットサイズ(縦)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                                return;
                            }
                            if (isNaN(Util.toNumber(height))) {
                                callback(`パレットサイズ(縦)は数字で入力してください。`);
                                return;
                            }
                        }
                        if (width) {
                            if (width.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                                callback(`パレットサイズ(横)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                                return;
                            }
                            if (isNaN(Util.toNumber(width))) {
                                callback(`パレットサイズ(横)は数字で入力してください。`);
                                return;
                            }
                        }
                        callback();
                    }
                }
            ],
            description: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        const description = this.description?.trim() ?? '';
                        if (description.length > 2000) {
                            callback('2000文字以内で入力してください。');
                            return;
                        }
                        // NGワードチェック
                        const invalidWords = BaggageUtil.validateDescription(description);
                        if (invalidWords.length > 0) {
                            callback(`${ invalidWords.map((word) => `「${ word }」`).join('') }は含めないようにしてください。`);
                            return;
                        }
                        callback();
                    },
                }
            ],
            freight: [
                {
                    required: true,
                    whitespace: true,
                    message: '運賃を入力してください。',
                },
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '運賃は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        const freight = Number(this.freight);
                        if (_.isNaN(freight)) {
                            callback(`運賃は数字で入力してください。`);
                        } else if (freight > 0 && freight <= Const.MAX_FREIGHT) {
                            callback();
                        } else {
                            callback(`運賃は${ Const.MAX_FREIGHT / 100000000 }億円以内で入力してください。`);
                        }
                    },
                }
            ],
            highwayFare: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '高速代は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (this.highwayFare >= 0 && this.highwayFare <= Const.MAX_HIGHWAY_FARE) {
                            callback();
                        } else {
                            callback(`高速代は${ Const.MAX_HIGHWAY_FARE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            waitTimeFee: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '待機料は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (_.isNil(this.waitTimeFee)) return callback();
                        if (this.waitTimeFee >= 0 && this.waitTimeFee <= Const.MAX_WAIT_TIME_FEE) {
                            callback();
                        } else {
                            callback(`待機料は${ Const.MAX_WAIT_TIME_FEE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            operationFee: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '付帯作業料は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (_.isNil(this.operationFee)) return callback();
                        if (this.operationFee >= 0 && this.operationFee <= Const.MAX_OPERATION_FEE) {
                            callback();
                        } else {
                            callback(`付帯作業料は${ Const.MAX_OPERATION_FEE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            pickingFee: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '搬出料は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (_.isNil(this.pickingFee)) return callback();
                        if (this.pickingFee >= 0 && this.pickingFee <= Const.MAX_PICKING_FEE) {
                            callback();
                        } else {
                            callback(`搬出料は${ Const.MAX_PICKING_FEE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            parkingFee: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '駐車代は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (_.isNil(this.parkingFee)) return callback();
                        if (this.parkingFee >= 0 && this.parkingFee <= Const.MAX_PARKING_FEE) {
                            callback();
                        } else {
                            callback(`駐車代は${ Const.MAX_PARKING_FEE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            clearanceFee: [
                {
                    pattern: /^[0-9０-９]*$/,
                    message: '通関料は数字で入力してください。',
                },
                {
                    validator: (_rule, _value, callback) => {
                        if (_.isNil(this.clearanceFee)) return callback();
                        if (this.clearanceFee >= 0 && this.clearanceFee <= Const.MAX_CLEARANCE_FEE) {
                            callback();
                        } else {
                            callback(`通関料は${ Const.MAX_CLEARANCE_FEE / 10000 }万円以内で入力してください。`);
                        }
                    },
                },
            ],
            truckStaffName: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        if (this.truckStaffName === undefined) {
                            callback();
                        } else if (this.truckStaffName.length > 250) {
                            callback('受注担当者名は250文字以内で入力してください。');
                        } else {
                            callback();
                        }
                    },
                }
            ],
            paymentDate: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (!this.paymentDateValue) return callback('日付を選択してください。');

                        if (this.isDisabledPaymentDate(this.paymentDateValue)) {
                            const rangeText = this.availablePaymentDateRange
                                .map(each => new DateValue(each).format('YYYY年MM月DD日'))
                                .join('から');
                            return callback(`入金予定日は${ rangeText }の範囲で指定してください。`);
                        }

                        callback();
                    },
                }
            ],
            settlementProxy: [
                {
                    validator: (_rule, _value, callback) => {
                        if (this.settlementProxy !== undefined && this.settlementProxy.use) {
                            // 決済代行を利用する場合は、荷主への説明が必要
                            if (!this.settlementProxy.confirmed) {
                                return callback('おまかせ請求対象にするには、荷主の了承を得てください。');
                            }
                        }
                        callback();
                    },
                }
            ],
        };
    }
}

export interface AgreementListForm {
    pageNo?: number;
    pageSize?: number;
    sortKey: string;
    sortOrder: string;
}

export interface AgreementListAcceptedForm {
    pageNo?: number;
    pageSize?: number;
    sortKey: string;
    sortOrder: string;
}

export interface AgreementSearchForm {
    id: number[];
}

export interface AgreementSearchAgreedForm {
    baggageId?: number;
    agreementId?: number;
    departurePref: PrefectureEnum[];
    arrivalPref: PrefectureEnum[];
    departureFrom?: string;
    departureTo?: string;
    truckWeight: TruckWeightEnum[];
    truckModel: TruckModelEnum[];
    staffName?: string;
    partnerCompanyName?: string;
    addressText?: string;
    pageNo: number;
    pageSize: number;
    sortKey: string;
    sortOrder: string;
}

export interface AgreementSearchAcceptedForm {
    baggageId?: number;
    agreementId?: number;
    departurePref: PrefectureEnum[];
    arrivalPref: PrefectureEnum[];
    departureFrom?: string;
    departureTo?: string;
    truckWeight: TruckWeightEnum[];
    truckModel: TruckModelEnum[];
    staffName?: string;
    partnerCompanyName?: string;
    addressText?: string;
    pageNo: number;
    pageSize: number;
    sortKey: string;
    sortOrder: string;
}

export interface AgreementUpdateForm {
    departureMin: string;
    departureMax: string;
    departureType?: DeliveryDateTimeRangeType;
    departurePref: { code?: string };
    departureCity: string;
    departureAddress?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalType?: DeliveryDateTimeRangeType;
    arrivalPref: { code?: string };
    arrivalCity: string;
    arrivalAddress?: string;
    type: string;
    shape: Enum<BaggageShapeEnumCode | ''>;
    temperatureZone: { code?: string }
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    freight: string;
    highwayFare: number | string; // NOTE: FormModelItemではstring、API送出時はnumberで扱っている
    highwayFareFlg: boolean;
    waitTimeFee: number | undefined;
    operationFee: number | undefined;
    pickingFee: number | undefined;
    parkingFee: number | undefined;
    clearanceFee: number | undefined;
    paymentDate: string;
    description: string;
}

export interface AgreementToggleGuaranteeForm {
    enable: boolean;
}

export interface AgreementOnlineOrderAvailability {
    baggageCompany: boolean;
    truckCompany: boolean;
}

export interface AgreementPartnerInfo {
    profile: companyTypes.CompanyProfile;
    officialCompany?: companyTypes.OfficialCompany;
    confidence: companyTypes.CompanyConfidence;
    statistics: companyTypes.CompanyStatistics;
}

export interface AgreementPartnerCompanySearchForm {
    keyword: string;
}

export interface AgreementPartnerCompany {
    profile: companyTypes.CompanyProfile;
    statistics: PartnerCompanyStatistics;
}

export interface PartnerCompanyStatistics {
    partnerCompanyId: number;
    lastAcceptedAgreement: { id: number, entryTm: string } | undefined;
    lastAgreedAgreement: { id: number, entryTm: string } | undefined;
    acceptedCount: number;
    agreedCount: number;
}

export class PartnerCompanyStatisticsModel {
    partnerCompanyId: number;
    lastAcceptedAgreement: PartnerCompanyStatisticsLastAgreement | undefined;
    lastAgreedAgreement: PartnerCompanyStatisticsLastAgreement | undefined;
    acceptedCount: number;
    agreedCount: number;

    constructor(param: PartnerCompanyStatistics) {
        this.partnerCompanyId = param.partnerCompanyId;

        this.lastAcceptedAgreement = _.isNil(param.lastAcceptedAgreement) ? undefined : new PartnerCompanyStatisticsLastAgreement({
            ...param.lastAcceptedAgreement,
            myBaggage: false,
        });

        this.lastAgreedAgreement = _.isNil(param.lastAgreedAgreement) ? undefined : new PartnerCompanyStatisticsLastAgreement({
            ...param.lastAgreedAgreement,
            myBaggage: true,
        });

        this.acceptedCount = param.acceptedCount;
        this.agreedCount = param.agreedCount;
    }
}

export class AgreementPartnerCompanyModel {
    profile: companyTypes.CompanyProfile;
    statistics: PartnerCompanyStatisticsModel;

    constructor(param: AgreementPartnerCompany) {
        this.profile = new companyTypes.CompanyProfile(param.profile);
        this.statistics = new PartnerCompanyStatisticsModel(param.statistics);
    }

    get id(): number {
        return this.profile.id;
    }

    /**
     * 最新の成約を取得する。
     */
    get lastAgreement(): PartnerCompanyStatisticsLastAgreement | undefined {
        const accepted = this.statistics.lastAcceptedAgreement;
        const agreed = this.statistics.lastAgreedAgreement;

        return PartnerCompanyStatisticsLastAgreement.latest(_.compact([accepted, agreed]));
    }
}

export class PartnerCompanyStatisticsLastAgreement {
    id: number;
    entryTm: DateTimeValue;
    myBaggage: boolean;

    constructor(param: { id: number, entryTm: string, myBaggage: boolean }) {
        this.id = param.id;
        this.entryTm = new DateTimeValue(param.entryTm);
        this.myBaggage = param.myBaggage;
    }

    static latest(agreements: PartnerCompanyStatisticsLastAgreement[]): PartnerCompanyStatisticsLastAgreement | undefined {
        return _.maxBy(agreements, each => each.entryTm.unix);
    }
}
