<script setup lang="ts">
import { PageInfo } from './types/page-info';

defineProps<{
    pageInfo?: PageInfo;
}>();
const emits = defineEmits<{
    (e: 'change', value: { pageNo: number, pageSize: number }): void,
}>();

const onChangePage = (pageNo: number, pageSize: number): void => {
    emits('change', { pageNo, pageSize });
};

</script>

<template>
    <a-pagination v-if="pageInfo && pageInfo.totalPageCount > 0"
                  :current="pageInfo.currentPage"
                  :total="pageInfo.totalRecordCount"
                  :page-size="pageInfo.currentPageSize"
                  :show-less-items="true"
                  @change="onChangePage"
                  @showSizeChange="onChangePage"
                  :page-size-options="pageInfo.pageSizeOptions"
                  :show-size-changer="pageInfo.pageSizeOptions.length > 1 && pageInfo.totalRecordCount > 0"
                  :build-option-text="opt => `${opt.value} 件 / ページ`">
    </a-pagination>
</template>

<style scoped lang="less">

</style>
