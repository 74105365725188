<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from '@/composables/helper/route';
import { Const } from '@/const';
import _ from 'lodash';
import { useDocumentTitle } from '@/composables/helper/document-helper';
import { useGtm } from '@/composables/helper/gtm-helper';

const props = withDefaults(defineProps<{
    ghost?: boolean,
    title?: string,
    showTitle?: boolean,
}>(), {
    ghost: false,
    showTitle: true,
});

const { currentRouteMeta, currentRoute } = useRoute();
const pageTitle = computed<string | undefined>(() => {
    if (currentRouteMeta.title && typeof currentRouteMeta.title === 'function') {
        const res = currentRouteMeta.title(currentRoute);
        return res.join(Const.PAGE_TITLE_SEPARATOR);
    } else if (!_.isNil(currentRouteMeta.title)) {
        return currentRouteMeta.title;
    } else {
        return props.title;
    }
});
const { setTitle } = useDocumentTitle();
const { gtm } = useGtm();

onMounted(() => {
    if (pageTitle.value) {
        setTitle(pageTitle.value);
        gtm.setPage(currentRoute.fullPath, pageTitle.value);
    }
});
</script>

<template>
    <a-layout-content>
        <a-page-header :ghost="ghost" :title="pageTitle" v-if="showTitle"/>
        <div class="board-container">
            <slot/>
        </div>
    </a-layout-content>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}
</style>
