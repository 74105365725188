<script setup lang="ts">
import RevokeLayout from '@/_pages/Setting/RevokePremiumPlan/_components/RevokeLayout.vue';
import QuestionItemAnswerInput from '@/_components/ui/QuestionItemAnswerInput.vue';
import {
    useRevokePremiumPlanEditHelper
} from '@/_pages/Setting/RevokePremiumPlan/Edit/revoke-premium-plan-edit-helper';

const {
    profile,
    contracts,
    loading,
    form,
    formValidateRules,
    formModelRef,
    onClickBack,
    onClickConfirm,
    validate,
} = useRevokePremiumPlanEditHelper();
</script>

<template>
    <revoke-layout :account-profile="profile" :contract-list="contracts">
        <a-spin :spinning="loading">
            <a-form-model ref="formModelRef" :model="form" :rules="formValidateRules" :colon="false" layout="vertical">
                <!-- フォーム入力 -->
                <a-card>
                    <div class="introduction">
                        <p>よりよいサービスに改善するため、以下のアンケートにご回答ください。</p>
                    </div>

                    <div v-if="form.questions && form.questions.length > 0">
                        <a-form-model-item v-for="(question, index) in form.questions"
                                           :key="question.question.id"
                                           :prop="question.name"
                                           :label="question.label">
                            <question-item-answer-input v-model="form.questions[index]" @input="validate(question.name)" />
                        </a-form-model-item>
                    </div>

                    <nav>
                        <ul class="actions">
                            <li class="actions__item">
                                <a-button @click="onClickBack">戻る</a-button>
                            </li>
                            <li class="actions__item">
                                <a-button type="primary" @click="onClickConfirm">プレミアムプランの利用を停止する</a-button>
                            </li>
                        </ul>
                    </nav>
                </a-card>
            </a-form-model>
        </a-spin>
    </revoke-layout>
</template>

<style scoped lang="less">
.introduction {
    margin-bottom: 32px;
}

.actions {
    margin-top: 24px;
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;

    .actions__item + .actions__item {
        margin-left: 16px;
    }
}
</style>
