<script setup lang="ts">
import PreLoginLayout from '@/_components/ui/layouts/PreLoginLayout.vue';
import { useAccountPasswordRegisterHelper } from '@/_pages/Account/Password/Register/account-password-register-helper';

const props = defineProps<{
    verificationCode?: string,
}>();
const {
    appServiceName,
    profile,
    loading,
    form,
    formValidateRules,
    formModelRef,
    onSubmit,
} = useAccountPasswordRegisterHelper(props.verificationCode ?? '');
</script>

<template>
    <pre-login-layout>
        <div class="body__container">
            <h1 class="body__title">初期パスワード設定</h1>
            <a-spin :spinning="loading">
                <a-alert type="success">
                    <template #message>
                        {{ profile?.name }}さま、{{ appServiceName }}へようこそ。<br>ご利用を始める前に、あなたのアカウントのパスワードを設定してください。
                    </template>
                </a-alert>
                <div style="font-size: 12px; margin-top: 32px">半角英数字を組み合わせて 8 文字以上で入力してください</div>
                <a-form-model ref="formModelRef" :model="form" :rules="formValidateRules" :colon="false" :wrapper-col="{ span: 24 }">
                    <a-form-model-item prop="password">
                        <a-input-password placeholder="パスワード"
                                          v-model="form.password"
                                          @pressEnter="onSubmit">
                            <template #prefix><a-icon type="lock"/></template>
                        </a-input-password>
                    </a-form-model-item>

                    <a-form-model-item prop="passwordConfirm">
                        <a-input-password placeholder="パスワード（確認用）"
                                          v-model="form.passwordConfirm"
                                          @pressEnter="onSubmit">
                            <template #prefix><a-icon type="lock"/></template>
                        </a-input-password>
                    </a-form-model-item>
                    <div class="form-actions">
                        <a-button type="primary" @click="onSubmit">設定</a-button>
                    </div>
                </a-form-model>
            </a-spin>
        </div>
    </pre-login-layout>

</template>

<style scoped lang="less">
.form-actions {
    text-align: center;

    .ant-btn {
        min-width: 128px;
    }
}
</style>
