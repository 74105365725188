/**
 * トラックの車種グループEnum
 * 車種をUI上グルーピングする必要があるときに用いる。
 * グルーピングはフロント側のみの概念であり、バックエンド側では用いていない。
 */
import { TruckModelEnum, TruckModelEnumCode } from './truck-model.enum';

export type TruckModelGroupEnumCode = 'FLAT' | 'VAN' | 'WING';

export class TruckModelGroupEnum {
    constructor(public code: TruckModelGroupEnumCode, public label: string, public truckModels: TruckModelEnumCode[]) {}

    static Flat = new TruckModelGroupEnum('FLAT', '平', [
        TruckModelEnum.Flat.code,
        TruckModelEnum.FlatWithLowFloor.code,
        TruckModelEnum.FlatWithPowerGate.code,
        TruckModelEnum.FlatWithAirSuspension.code,
    ]);
    static Van = new TruckModelGroupEnum('VAN', '箱', [
        TruckModelEnum.Van.code,
        TruckModelEnum.VanWithLowFloor.code,
        TruckModelEnum.VanWithPowerGate.code,
        TruckModelEnum.VanWithAirSuspension.code,
    ]);
    static Wing = new TruckModelGroupEnum('WING', 'ウイング', [
        TruckModelEnum.Wing.code,
        TruckModelEnum.WingWithLowFloor.code,
        TruckModelEnum.WingWithPowerGate.code,
        TruckModelEnum.WingWithAirSuspension.code,
    ]);

    static valueOf = (code: string): TruckModelGroupEnum | undefined =>
        TruckModelGroupEnum.values.find((value) => value.code === code);
    static values = [TruckModelGroupEnum.Flat, TruckModelGroupEnum.Van, TruckModelGroupEnum.Wing];
}
