/**
 * 時間を管理するマスタEnum
 * このEnum値はフロント内部でのみ利用している概念であり、バックエンド側では用いていない。
 */
import { DeliveryDateTimeType } from '@/models/vo/delivery-datetime';

export type TimeHourEnumCode =
    | 'Day' // 指定なし
    | 'Morning' // 午前
    | 'Afternoon' // 午後
    | 'H00'
    | 'H01'
    | 'H02'
    | 'H03'
    | 'H04'
    | 'H05'
    | 'H06'
    | 'H07'
    | 'H08'
    | 'H09'
    | 'H10'
    | 'H11'
    | 'H12'
    | 'H13'
    | 'H14'
    | 'H15'
    | 'H16'
    | 'H17'
    | 'H18'
    | 'H19'
    | 'H20'
    | 'H21'
    | 'H22'
    | 'H23';

export class TimeHourEnum {
    constructor(
        public code: TimeHourEnumCode,
        public label: string,
        public type: DeliveryDateTimeType,
        public hour?: number
    ) {}

    static Day = new TimeHourEnum('Day', '指定なし', 'Day');
    static Morning = new TimeHourEnum('Morning', '午前', 'Morning');
    static Afternoon = new TimeHourEnum('Afternoon', '午後', 'Afternoon');
    static H00 = new TimeHourEnum('H00', '0時', 'Hourly', 0);
    static H01 = new TimeHourEnum('H01', '1時', 'Hourly', 1);
    static H02 = new TimeHourEnum('H02', '2時', 'Hourly', 2);
    static H03 = new TimeHourEnum('H03', '3時', 'Hourly', 3);
    static H04 = new TimeHourEnum('H04', '4時', 'Hourly', 4);
    static H05 = new TimeHourEnum('H05', '5時', 'Hourly', 5);
    static H06 = new TimeHourEnum('H06', '6時', 'Hourly', 6);
    static H07 = new TimeHourEnum('H07', '7時', 'Hourly', 7);
    static H08 = new TimeHourEnum('H08', '8時', 'Hourly', 8);
    static H09 = new TimeHourEnum('H09', '9時', 'Hourly', 9);
    static H10 = new TimeHourEnum('H10', '10時', 'Hourly', 10);
    static H11 = new TimeHourEnum('H11', '11時', 'Hourly', 11);
    static H12 = new TimeHourEnum('H12', '12時', 'Hourly', 12);
    static H13 = new TimeHourEnum('H13', '13時', 'Hourly', 13);
    static H14 = new TimeHourEnum('H14', '14時', 'Hourly', 14);
    static H15 = new TimeHourEnum('H15', '15時', 'Hourly', 15);
    static H16 = new TimeHourEnum('H16', '16時', 'Hourly', 16);
    static H17 = new TimeHourEnum('H17', '17時', 'Hourly', 17);
    static H18 = new TimeHourEnum('H18', '18時', 'Hourly', 18);
    static H19 = new TimeHourEnum('H19', '19時', 'Hourly', 19);
    static H20 = new TimeHourEnum('H20', '20時', 'Hourly', 20);
    static H21 = new TimeHourEnum('H21', '21時', 'Hourly', 21);
    static H22 = new TimeHourEnum('H22', '22時', 'Hourly', 22);
    static H23 = new TimeHourEnum('H23', '23時', 'Hourly', 23);

    static valueOf = (code: TimeHourEnumCode): TimeHourEnum | undefined =>
        TimeHourEnum.values.find((value) => value.code === code);
    static values = [
        TimeHourEnum.Day,
        TimeHourEnum.Morning,
        TimeHourEnum.Afternoon,
        TimeHourEnum.H00,
        TimeHourEnum.H01,
        TimeHourEnum.H02,
        TimeHourEnum.H03,
        TimeHourEnum.H04,
        TimeHourEnum.H05,
        TimeHourEnum.H06,
        TimeHourEnum.H07,
        TimeHourEnum.H08,
        TimeHourEnum.H09,
        TimeHourEnum.H10,
        TimeHourEnum.H11,
        TimeHourEnum.H12,
        TimeHourEnum.H13,
        TimeHourEnum.H14,
        TimeHourEnum.H15,
        TimeHourEnum.H16,
        TimeHourEnum.H17,
        TimeHourEnum.H18,
        TimeHourEnum.H19,
        TimeHourEnum.H20,
        TimeHourEnum.H21,
        TimeHourEnum.H22,
        TimeHourEnum.H23,
    ];
}
