import dayjs, { OpUnitType } from 'dayjs';
import { ValueObject } from '@/models/vo/value-object';
import { DateValue } from '@/models/vo/date';
import '@/models/vo/dayjs-init';
import _ from 'lodash';

export type DateTimeFormatter =
    'YYYY/MM/DD HH:mm' |
    'YYYY/MM/DD HH:mm:ss' |
    'YYYY-MM-DD HH:mm:ss' |
    'YYYY年MM月DD日 H:mm' |
    'YYYY年MM月DD日 H時mm分' |
    'YYYY年M月D日(ddd) H:mm' |
    'YYYY年M月D日(ddd) H:mm:ss' |
    'M月DD日 H:mm' |
    'M/D' |
    'YYYY年M月' |
    'YYYY年M月D日' |
    'YYYY/MM/DD(ddd)' |
    'YYYY/MM/DD(ddd) HH:mm' |
    'HH:mm' |
    'YYYYMMDD' |
    'M/D(ddd) HH:mm';

export type ManipulateType = OpUnitType;

const TIMEZONE = '+09:00';
const PARSE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
const END_OF_WORLD = '2999-12-31 23:59:59 +09:00';

/**
 * 日時ValueObject
 */
export class DateTimeValue extends ValueObject<dayjs.Dayjs> {
    constructor(value: string | dayjs.Dayjs | Date) {
        const data = (typeof value === 'string' ? dayjs(`${ value } ${ TIMEZONE }`, PARSE_FORMAT).tz() : value instanceof Date? dayjs(value): value);
        super(data);
    }

    add(value: number, unit: ManipulateType): DateTimeValue {
        const newDayjsValue = this.value.add(value, unit);
        return new DateTimeValue(newDayjsValue);
    }

    subtract(value: number, unit?: ManipulateType): DateTimeValue {
        const newDayjsValue = this.value.subtract(value, unit);
        return new DateTimeValue(newDayjsValue);
    }

    startOf(unit: OpUnitType): DateTimeValue {
        return new DateTimeValue(this.value.startOf(unit));
    }

    endOf(unit: OpUnitType): DateTimeValue {
        return new DateTimeValue(this.value.endOf(unit));
    }

    get year(): number {
        return this.value.year();
    }

    get monthValue(): number {
        return this.value.month() + 1;
    }

    get unix(): number {
        return this.value.unix();
    }

    isSame(that: DateTimeValue, unit?: ManipulateType): boolean {
        return this.value.isSame(that.value, unit);
    }

    isBefore(that: DateTimeValue, unit?: ManipulateType): boolean {
        return this.value.isBefore(that.value, unit);
    }

    isSameOrBefore(that: DateTimeValue, unit?: ManipulateType): boolean {
        return this.value.isSameOrBefore(that.value, unit);
    }

    isAfter(that: DateTimeValue, unit?: ManipulateType): boolean {
        return this.value.isAfter(that.value, unit);
    }

    isSameOrAfter(that: DateTimeValue, unit?: ManipulateType): boolean {
        return this.value.isAfter(that.value, unit) || this.value.isSame(that.value, unit);
    }

    /**
     * 指定した日時内に含まれるかどうかを判定します。
     * 境界値を含みます。
     * this.isSameOrAfter(min) && this.isSameOrBefore(max) と同等です。
     * @param min
     * @param max
     * @param unit 判定する単位
     */
    isBetween(min: DateTimeValue, max: DateTimeValue, unit?: ManipulateType): boolean {
        return this.isSameOrAfter(min, unit) && this.isSameOrBefore(max, unit);
    }

    /**
     * 指定した日時からの残り日数を取得します。
     * 時刻は考慮せず、日だけを見て比較します。
     */
    daysLeft(current: DateTimeValue = DateTimeValue.now()): number {
        const now = current.toDate();
        return Math.ceil(this.value.diff(now.value, 'd', true));
    }


    format(formatter: DateTimeFormatter = 'YYYY/MM/DD HH:mm'): string {
        return this.value.format(formatter);
    }

    toDate(): DateValue {
        return new DateValue(this.value);
    }

    static now(): DateTimeValue {
        return new DateTimeValue(dayjs().tz());
    }

    static theEndOfWorld(): DateTimeValue {
        return new DateTimeValue(END_OF_WORLD);
    }

    static min(choice: DateTimeValue[]): DateTimeValue {
        if (choice.length <= 0) throw Error('the parameter must not be empty');
        return _.minBy(choice, (each) => each.unix) ?? choice[0];
    }

    static max(choice: DateTimeValue[]): DateTimeValue {
        if (choice.length <= 0) throw Error('the parameter must not be empty');
        return _.maxBy(choice, (each) => each.unix) ?? choice[0];
    }

    // Adjusters

    /**
     * 当月開始日時を取得する
     */
    get startOfMonth(): DateTimeValue {
        return this.map((value) => value.startOf('month'));
    }

    /**
     * 当月終了日時を取得する
     */
    get endOfMonth(): DateTimeValue {
        return this.map((value) => value.endOf('month'));
    }

    /**
     * 指定した日を適用した日時オブジェクトを取得する
     * @param date
     */
    withDate(date: number): DateTimeValue {
        return this.map((value) => value.date(date));
    }

    map(transform: (value: dayjs.Dayjs) => dayjs.Dayjs): DateTimeValue {
        return new DateTimeValue(transform(this.value));
    }

    toJSON(): string {
        return this.format('YYYY-MM-DD HH:mm:ss');
    }
}
