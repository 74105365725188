import { ref } from 'vue';
import { OfficialCompany } from '@/models/company';
import { companyApi } from '@/repository/api/internal/company';
import { useLoading } from '@/composables/helper/loading-helper';

/**
 * 法人をロードする機能を提供します。
 */
export const useOfficialCompany = () => {
    const officialCompany = ref<OfficialCompany | undefined>(undefined);
    const { state: { loading }, withLoading } = useLoading();

    const load = (companyId: number) => withLoading(async () => {
        officialCompany.value = await companyApi.officialCompany(companyId);
    });

    const clear = () => {
        officialCompany.value = undefined;
    };

    return {
        state: {
            officialCompany,
            loading,
        },
        load,
        clear,
    };
};
