import { createGlobalState, useLocalStorage } from '@vueuse/core';
import { CompanyProfileSearchForm, CompanyProfileSearchFormModel } from '@/models/company';

export const useCompanySearchFormSave = createGlobalState(() => {
    const localValue = useLocalStorage<CompanyProfileSearchForm | null>('companyProfileSearchForm', null);

    const save = (form: CompanyProfileSearchFormModel | null): void => {
        localValue.value = form;
    };

    const load = (): CompanyProfileSearchFormModel | null => {
        if (!localValue.value) return null;
        return new CompanyProfileSearchFormModel(localValue.value);
    };

    return {
        save,
        load,
    };
});
