<script setup lang="ts">
import { ZipUtil } from '@/util';
import { PhoneUtil } from '@/util/phone';
import { DeliveryOrderModel } from '@/models/delivery-order';
import { CompanyProfile } from '@/models/company';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    deliveryOrder: DeliveryOrderModel;
    myCompanyProfile: CompanyProfile;
    partnerCompanyProfile: CompanyProfile;
}>(), {});

/**
 * 自社が荷主かどうかを判定します。
 */
const iAmBaggageCompany = computed(() => {
    return props.myCompanyProfile.id === props.deliveryOrder.baggageCompanyId;
});

/**
 * 宛先
 */
const addressee = computed(() => {
    if (iAmBaggageCompany.value) {
        return props.partnerCompanyProfile.name.kanji;
    }
    return props.myCompanyProfile.name.kanji;
});

/**
 * 送り主
 */
const sender = computed(() => {
    if (iAmBaggageCompany.value) {
        return props.myCompanyProfile;
    }
    return props.partnerCompanyProfile;
});

const senderLocation = computed(() => {
    return `${ sender.value.location.prefecture.label } ${ sender.value.location.city } ${ sender.value.location.address }`;
});
</script>

<template>
    <div class="container">
        <div class="sheet-main">
            <!-- Header -->
            <a-row align="middle" justify="space-between" class="title-row">
                <a-col :span="7"></a-col>
                <a-col :span="10" class="sheet-title">
                    <span>配車依頼書</span>
                </a-col>
                <a-col :span="7" class="management-attributes">
                    <a-row type="flex" justify="end">
                        <span class="label">管理番号:</span>
                        <span>{{ deliveryOrder.manageNumber }}</span>
                    </a-row>
                    <a-row type="flex" justify="end">
                        <span class="label">作成日:</span>
                        <span>
                            {{ deliveryOrder.formattedCreateDate }}
                        </span>
                    </a-row>
                </a-col>
            </a-row>
            <!-- 宛先情報 -->
            <a-row type="flex" align="middle" class="outsourcing-customer-area">
                <div class="outsourcing-customer-name">
                    <span class="name-text">
                        {{ addressee }}
                    </span>
                </div>
                <span class="outsourcing-customer-suffix">御中</span>
            </a-row>
            <a-row type="flex" justify="space-between" class="receiver-row">
                <div>
                    <p>
                        いつもお世話になっております。
                        <br>
                        下記内容をご確認頂き、
                        <br>
                        車両手配の程、よろしくお願いいたします。
                    </p>
                </div>
                <!-- 送り主情報 -->
                <a-row type="flex"
                       align="bottom"
                       class="sender-information">
                    <span class="sender-name">{{ sender.name.kanji }}</span>
                    <span>〒{{ ZipUtil.format(sender.zipCode) }}</span>
                    <span>{{ senderLocation }}</span>
                    <div class="company-tel-fax">
                        <span class="tel">TEL: {{ PhoneUtil.format(sender.phone.number) }}</span>
                        <span>FAX: {{ PhoneUtil.format(sender.phone.faxNumber) }}</span>
                    </div>
                    <div>
                        <span>
                            担当: <span>{{ deliveryOrder.staffName }}</span>
                        </span>
                    </div>
                </a-row>
            </a-row>
            <!-- Body -->
            <div>
                <!-- 運行情報 -->
                <a-row>
                    <span class="sheet-block-title">運行情報</span>
                </a-row>
                <div class="sheet-table transport-information-table">
                    <a-row>
                        <a-col :span="8">積み日</a-col>
                        <a-col :span="16">
                            {{ deliveryOrder.formattedDepartureDateTime }}
                        </a-col>
                    </a-row>
                    <a-row class="location" align="middle">
                        <a-col :span="8">積み地</a-col>
                        <a-col :span="16" class="location-content">
                            {{ deliveryOrder.formattedDepartureLocation }}
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="8">卸し日</a-col>
                        <a-col :span="16">
                            {{ deliveryOrder.formattedArrivalDateTime }}
                        </a-col>
                    </a-row>
                    <a-row class="location" align="middle">
                        <a-col :span="8">卸し地</a-col>
                        <a-col :span="16" class="location-content">
                            {{ deliveryOrder.formattedArrivalLocation }}
                        </a-col>
                    </a-row>
                </div>
                <!-- 荷物情報 -->
                <a-row>
                    <span class="sheet-block-title">荷物情報</span>
                </a-row>
                <div class="sheet-table baggage-information-table">
                    <a-row class="baggage-type" align="middle">
                        <a-col :span="3">荷種</a-col>
                        <a-col :span="5">
                        <span v-if="deliveryOrder.formattedTotalWeight">
                            荷物総重量: {{ deliveryOrder.formattedTotalWeight }}
                        </span>
                        </a-col>
                        <a-col :span="16" class="baggage-type-content">
                            {{ deliveryOrder.formattedBaggageType }}
                        </a-col>
                    </a-row>
                </div>
                <!-- その他 -->
                <a-row>
                    <span class="sheet-block-title">その他</span>
                </a-row>
                <div class="sheet-table others-table">
                    <a-row class="notification" align="middle">
                        <a-col :span="8">注意事項</a-col>
                        <a-col :span="16" class="notification-content">
                            {{ deliveryOrder.formattedDescription }}
                        </a-col>
                    </a-row>
                    <a-row class="truck-spec" align="middle">
                        <a-col :span="8">車両情報</a-col>
                        <a-col :span="16" class="truck-spec-content">
                            {{ deliveryOrder.formattedTruckAttributes }}
                        </a-col>
                    </a-row>
                    <a-row class="freight" align="middle">
                        <a-col :span="8">運賃</a-col>
                        <a-col :span="16" class="freight-content">
                            {{ deliveryOrder.formattedFreight }}
                        </a-col>
                    </a-row>
                </div>
                <!-- 担当者情報 -->
                <a-row>
                    <span class="sheet-block-title">担当者情報</span>
                </a-row>
                <div class="">
                    <div class="sheet-table-display">
                        <div class="sheet-table-display-row">
                            <div class="sheet-table-display-cell">会社名</div>
                            <div class="sheet-table-display-cell">
                                {{ deliveryOrder.truckCompanyName ?? '' }}
                            </div>
                            <div class="sheet-table-display-cell">車番</div>
                            <div class="sheet-table-display-cell">
                                {{ deliveryOrder.truckNumber ?? '' }}
                            </div>
                        </div>
                        <div class="sheet-table-display-row">
                            <div class="sheet-table-display-cell">ドライバー名</div>
                            <div class="sheet-table-display-cell">
                                {{ deliveryOrder.driverName ?? '' }}
                            </div>
                            <div class="sheet-table-display-cell">携帯番号</div>
                            <div class="sheet-table-display-cell">
                                {{ deliveryOrder.formattedDriverPhoneNumber }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
@import '../../../../less/common-styles/print-layout.less';

.container {
    width: 210mm;
    height: 297mm;
    background-color: white;
}

.sheet-main {
    padding: 28px;
}

.title-row {
    margin-bottom: 4px;
}

.sheet-title {
    font-size: 22px;
    text-align: center;
}

.management-attributes {
    .label {
        margin-right: auto;
    }
}

.create-date, .staff-name {
    background-color: rgba(64, 158, 255, 0.24);
}

.receiver-row {
    margin-bottom: 6px;
}

.outsourcing-customer-area {
    margin-bottom: 7px;
}

.outsourcing-customer-name {
    padding: 4px 8px;
    border-bottom: 1px solid black;

    .name-text {
        font-size: 18px;
        font-weight: bold;
    }
}

.outsourcing-customer-suffix {
    font-size: 16px;
}

.sender-information {
    flex-direction: column;

    .sender-name {
        font-size: 16px;
    }
}

.company-tel-fax {
    .tel {
        margin-right: 8px;
    }
}

.sheet-block-title {
    font-weight: bold;
    margin-bottom: 4px;
}

.sheet-table {
    border-top: 1px solid black;
}

.sheet-table > .ant-row {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.sheet-table > .ant-row > .ant-col-16 {
    min-height: 24px;
    border-left: 1px solid black;
}

.sheet-table > .ant-row > .ant-col-9 {
    border-left: 1px solid black;
}

.sheet-table > .ant-row > .ant-col-7 {
    border-left: 1px solid black;
}

.sheet-table > .ant-row > .ant-col {
    padding: 4px;
}

.transport-information-table,
.baggage-information-table,
.others-table {
    margin-bottom: 6px;
}

.location {
    height: 146px;

    .location-content {
        height: 100%;
        overflow-wrap: break-word;
        white-space: pre-line;
        overflow: hidden;
    }
}

.baggage-type {
    height: 64px;

    .baggage-type-content {
        height: 100%;
        overflow-wrap: break-word;
        white-space: pre-line;
        overflow: hidden;
    }
}

.notification {
    height: 168px;

    .notification-content {
        height: 100%;
        white-space: pre-line;
    }
}

.truck-spec {
    height: 30px;

    .truck-spec-content {
        height: 30px;
    }
}

.freight {
    height: 30px;

    .freight-content {
        height: 30px;
    }
}

.sheet-table-display {
    display: table;
    width: 100%;
}

.sheet-table-display-row {
    display: table-row;
}

.sheet-table-display-row:first-child > .sheet-table-display-cell {
    border-bottom: none;
}

.sheet-table-display-cell {
    display: table-cell;
    padding: 8px 4px;
    border: 1px solid black;
}

.sheet-table-display-cell:nth-child(n + 2) {
    border-left: none;
}

.sheet-table-display-cell:nth-child(2n-1) {
    width: 14%;
}

.sheet-table-display-cell:nth-child(2n) {
    width: 37.2%;
}
</style>
