import { computed, ref, Ref } from 'vue';
import { SettlementIncomeRegisterApiForm, SettlementIncomeRegisterModel } from '@/models/settlement';
import { DateValue } from '@/models/vo/date';
import { FormValidator } from '@/models/validate-helper';
import { useSettlementIncomeValidator } from '@/composables/settlement-income-validator';
import _ from 'lodash';
import { Const } from '@/const';
import { AgreementUtil, Util } from '@/util';
import {
    buildViewModel,
    ViewModelDidChangeHandlers,
    ViewModelGetHandlers,
    ViewModelHandlers
} from '@/composables/helper/viewmodel';
import { EntitlementModel } from '@/models/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';

export const useSettlementIncomeRegisterViewModel = (
    validate: (props: (keyof SettlementIncomeRegisterViewModel)[]) => void,
    entitlement: Ref<EntitlementModel | undefined>
) => {
    const initialModel = buildInitialModel();
    const model = _.cloneDeep(initialModel);
    const isDirty = computed(() => !_.isEqual(initialModel, model));
    const viewModel = ref(buildViewModel<SettlementIncomeRegisterModel, SettlementIncomeRegisterViewModel>(model, viewModelHandlers(model, entitlement, validate)));
    const validationRules = ref(buildValidationRules(() => viewModel.value));
    return {
        viewModel,
        isDirty,
        validationRules,
        buildApiForm: () => buildApiForm(viewModel.value),
    };
};

const buildInitialModel = (): SettlementIncomeRegisterModel => ({
    payerCompany: undefined,
    departureDate: undefined,
    arrivalDate: undefined,
    departurePref: undefined,
    departureCity: undefined,
    departureAddress: undefined,
    arrivalPref: undefined,
    arrivalCity: undefined,
    arrivalAddress: undefined,
    truckType: { truckModel: undefined, truckWeight: undefined },
    paymentDate: undefined,
    isCanceling: false,
    freight: 0,
    highwayFare: 0,
    waitTimeFee: 0,
    operationFee: 0,
    pickingFee: 0,
    parkingFee: 0,
    clearanceFee: 0,
    cancellationFee: 0,
});

type FeeProp = 'freight' | 'highwayFare' | 'waitTimeFee' | 'operationFee' | 'pickingFee' | 'parkingFee' | 'clearanceFee' | 'cancellationFee';

export type SettlementIncomeRegisterViewModel = SettlementIncomeRegisterModel & {
    isUnavailableDeliveryDate: (date: DateValue) => boolean;
    defaultPaymentDate: DateValue | undefined;
    isUnavailablePaymentDate: (date: DateValue) => boolean;
    // 承認期限
    nextLimitDate: DateValue;
    // トラボックスからの入金日
    nextPaymentDateFromTrabox: DateValue;
    // 金額の入力可能範囲
    availableFeeRange: (prop: FeeProp) => { min: number, max: number };

    // antdのバリデーション機構に適応させるために必要なマーカー（値は使わないので型を明示していません）
    departureLocation: undefined;
    arrivalLocation: undefined;
};

const buildValidationRules = (
    model: () => SettlementIncomeRegisterViewModel
): FormValidator<SettlementIncomeRegisterViewModel> => {
    const {
        payerCompany,
        departureDate,
        arrivalDate,
        departureLocation,
        arrivalLocation,
        truckType,
        freight,
        cancellationFee,
        paymentDate,
    } = useSettlementIncomeValidator();

    return {
        payerCompany: payerCompany(model),
        departureDate: departureDate(model),
        arrivalDate: arrivalDate(model),
        departureLocation: departureLocation(model),
        arrivalLocation: arrivalLocation(model),
        truckType: truckType(model),
        freight: freight(model),
        cancellationFee: cancellationFee(model),
        paymentDate: paymentDate(model),
    };
};

export const availableSettlementFeeRange: SettlementIncomeRegisterViewModel['availableFeeRange'] = (prop) => {
    switch (prop) {
        case 'freight': return { min: 0, max: Const.MAX_FREIGHT };
        case 'highwayFare': return { min: 0, max: Const.MAX_HIGHWAY_FARE };
        case 'waitTimeFee': return { min: 0, max: Const.MAX_WAIT_TIME_FEE };
        case 'operationFee': return { min: 0, max: Const.MAX_OPERATION_FEE };
        case 'pickingFee': return { min: 0, max: Const.MAX_PICKING_FEE };
        case 'parkingFee': return { min: 0, max: Const.MAX_PARKING_FEE };
        case 'clearanceFee': return { min: 0, max: Const.MAX_CLEARANCE_FEE };
        case 'cancellationFee': return { min: 0, max: Const.MAX_CANCELLATION_FEE };
    }
};

const viewModelHandlers = (
    model: SettlementIncomeRegisterModel,
    entitlement: Ref<EntitlementModel | undefined>,
    validate: (props: (keyof SettlementIncomeRegisterViewModel)[]) => void
): ViewModelHandlers<SettlementIncomeRegisterViewModel> => {
    // 昨日
    const yesterday = () => DateValue.today().subtract(1, 'day');
    // 先月
    const startOfLastMonth = () => DateTimeValue.now().subtract(1, 'month').startOfMonth;
    // おまかせサービス開始日
    const startOfService = computed(() => entitlement.value?.term('settlementProxy')?.start);
    // 取引変更期限日
    const nextLimitDate = () => {
        const atChangeLimit = (value: DateValue) => value.setDate(Const.SETTLEMENT_CHANGE_LIMIT_DAY);
        const plusMonthIfOver = (value: DateValue) => value.add(isOver(value) ? 1 : 0, 'month');
        const isOver = (value: DateValue) => value.date > Const.SETTLEMENT_CHANGE_LIMIT_DAY;

        return DateValue.max(..._.compact([
            model.arrivalDate?.add(1, 'month'),
            plusMonthIfOver(DateValue.today())
        ]).map(each => atChangeLimit(each)));
    };

    // トラボックスからの入金日
    const nextPaymentDateFromTrabox = () => nextLimitDate().endOf('month');

    // 発着日に指定可能か否かをチェック
    const isUnavailableDeliveryDate = () => (date: DateValue) => {
        const min = DateTimeValue.max([startOfService.value ?? startOfLastMonth(), startOfLastMonth()]).toDate();
        const max = yesterday();
        return date.isBefore(min) || date.isAfter(max);
    };

    // 荷主からの入金日（指定不能か否かをチェック）
    const isUnavailablePaymentDate = () => (date: DateValue) => {
        const arrivalDate = model.arrivalDate ?? DateValue.today();

        const min = DateValue.max(
            nextLimitDate().add(1, 'day'),
            arrivalDate.add(3, 'day')
        );
        const max = arrivalDate.add(94, 'day');

        return date.isBefore(min) || date.isAfter(max);
    };

    // 荷主からの入金予定日デフォルト値
    const defaultPaymentDate = computed(() => {
        const departureDate = model.departureDate;
        const payerCompany = model.payerCompany;

        // 発日の指定、荷主の支払条件が必要（baggage-register-helper内の実装に準拠）
        if (_.isNil(departureDate) || _.isNil(payerCompany) || !payerCompany.hasPaymentTerms) return undefined;

        const { cutOffDay, paymentMonth, paymentDay } = payerCompany;

        return new DateValue(AgreementUtil.paymentDate(
            departureDate.value,
            cutOffDay?.code,
            paymentMonth?.code,
            paymentDay?.code
        ));
    });

    const getHandlers: ViewModelGetHandlers<SettlementIncomeRegisterViewModel> = {
        // キャンセルか否かによって金額系項目の値を調整する
        freight: () => model.isCanceling ? 0 : model.freight,
        highwayFare: () => model.isCanceling ? 0 : model.highwayFare,
        waitTimeFee: () => model.isCanceling ? 0 : model.waitTimeFee,
        operationFee: () => model.isCanceling ? 0 : model.operationFee,
        pickingFee: () => model.isCanceling ? 0 : model.pickingFee,
        parkingFee: () => model.isCanceling ? 0 : model.parkingFee,
        clearanceFee: () => model.isCanceling ? 0 : model.clearanceFee,
        cancellationFee: () => model.isCanceling ? model.cancellationFee : 0,
        isUnavailableDeliveryDate,
        defaultPaymentDate: () => defaultPaymentDate.value,
        isUnavailablePaymentDate,
        nextLimitDate,
        nextPaymentDateFromTrabox,
        availableFeeRange: () => availableSettlementFeeRange,
    };
    // 変更時に呼ばれる関数
    const didChangeHandlers: ViewModelDidChangeHandlers<SettlementIncomeRegisterViewModel> = {
        'payerCompany': () => {
            validate(['payerCompany', 'paymentDate']);
        },
        'departureDate': () => {
            validate(['arrivalDate', 'paymentDate']);
        },
        'departurePref': () => {
            // 都道府県が変わったら、市区町村以下をリセットする
            model.departureCity = undefined;
            model.departureAddress = undefined;
            validate(['departureLocation']);
        },
        'departureCity': () => {
            validate(['departureLocation']);
        },
        'departureAddress': () => {
            validate(['departureLocation']);
        },
        'arrivalPref': () => {
            // 都道府県が変わったら、市区町村以下をリセットする
            model.arrivalCity = undefined;
            model.arrivalAddress = undefined;
            validate(['arrivalLocation']);
        },
        'arrivalCity': () => {
            validate(['arrivalLocation']);
        },
        'arrivalAddress': () => {
            validate(['arrivalLocation']);
        },
        'freight': () => validate(['freight']),
        'highwayFare': () => validate(['freight']),
        'waitTimeFee': () => validate(['freight']),
        'operationFee': () => validate(['freight']),
        'pickingFee': () => validate(['freight']),
        'parkingFee': () => validate(['freight']),
        'clearanceFee': () => validate(['freight']),
        'cancellationFee': () => validate(['cancellationFee']),
        'isCanceling': () => validate(['freight', 'cancellationFee']),
    };

    return {
        getHandlers,
        didChangeHandlers
    };
};

const buildApiForm = (viewModel: SettlementIncomeRegisterViewModel): SettlementIncomeRegisterApiForm => {
    const paymentDate = Util.requireNotNull(viewModel.paymentDate ?? viewModel.defaultPaymentDate);

    return {
        payerCompanyId: Util.requireNotNull(viewModel.payerCompany?.id),
        payerDate: paymentDate.format('YYYY-MM-DD'),
        departureDate: Util.requireNotNull(viewModel.departureDate?.format('YYYY-MM-DD')),
        departurePref: { code: Util.requireNotNull(viewModel.departurePref) },
        departureCity: Util.requireNotNull(viewModel.departureCity),
        departureAddress: viewModel.departureAddress,
        arrivalDate: Util.requireNotNull(viewModel.arrivalDate?.format('YYYY-MM-DD')),
        arrivalPref: { code: Util.requireNotNull(viewModel.arrivalPref) },
        arrivalCity: Util.requireNotNull(viewModel.arrivalCity),
        arrivalAddress: viewModel.arrivalAddress,
        truckWeight: Util.requireNotNull(viewModel.truckType.truckWeight),
        truckModel: Util.requireNotNull(viewModel.truckType.truckModel),
        freight: viewModel.freight,
        highwayFare: viewModel.highwayFare,
        waitTimeFee: viewModel.waitTimeFee,
        operationFee: viewModel.operationFee,
        pickingFee: viewModel.pickingFee,
        parkingFee: viewModel.parkingFee,
        clearanceFee: viewModel.clearanceFee,
        cancellationFee: viewModel.cancellationFee,
    };
};
