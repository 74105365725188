import ExcelJS from 'exceljs';
import { message } from 'ant-design-vue';
import { saveAs } from 'file-saver';
import { PrefectureEnum } from '@/enums/prefecture.enum';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';
import { TruckWidthEnum } from '@/enums/truck-width.enum';
import { TruckHeightEnum } from '@/enums/truck-height.enum';
import { DateUtil } from '@/util';
import { BaggageTemperatureZoneEnum } from '@/enums/baggage-temperature-zone.enum';

export const useBaggageBulkFormat = () => {
    const download = async () => {
        try {
            await downloadCsv();
        } catch (e: any) {
            message.error('フォーマットファイルの生成に失敗しました。');
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    return {
        download
    };
};

const downloadCsv = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('フォーマット');

    // ヘッダー
    ws.getRow(1).values = [
        '出発日時', '出発日時メモ', '出発都道府県', '出発市町村', '出発番地', '到着日時', '到着日時メモ', '到着都道府県', '到着市町村',
        '到着番地', '荷姿', '温度帯', 'トラック重量', 'トラック車種', 'トラック床高', 'トラック車幅', '大型可否', '運賃', '荷主名',
        '備考'
    ];

    ws.columns = [
        { key: 'departureDate' },
        { key: 'loadingTimeNote' },
        { key: 'departurePrefecture' },
        { key: 'departureCity' },
        { key: 'departureAddress' },
        { key: 'arrivalDate' },
        { key: 'unloadingTimeNote' },
        { key: 'arrivalPrefecture' },
        { key: 'arrivalCity' },
        { key: 'arrivalAddress' },
        { key: 'type' },
        { key: 'temperatureZone' },
        { key: 'truckWeight' },
        { key: 'truckModel' },
        { key: 'truckHeight' },
        { key: 'totalWidth' },
        { key: 'largeTruckAllowed' },
        { key: 'freight' },
        { key: 'shipperName' },
        { key: 'description' },
    ];

    const departureDate = defaultDepartureDate();

    ws.addRow({
        departureDate: departureDate,
        loadingTimeNote: '',
        departurePrefecture: PrefectureEnum.Tokyo.label,
        departureCity: '江東区',
        departureAddress: '',
        arrivalDate: defaultArrivalDate(departureDate),
        unloadingTimeNote: '',
        arrivalPrefecture: PrefectureEnum.Osaka.label,
        arrivalCity: '大阪市北区',
        arrivalAddress: '',
        type: 'その他',
        temperatureZone: BaggageTemperatureZoneEnum.Ambient.label,
        truckWeight: TruckWeightEnum.Weight4t.label,
        truckModel: TruckModelEnum.Flat.label,
        truckHeight: TruckHeightEnum.Unspecified.label,
        totalWidth: TruckWidthEnum.StandardOnly.label,
        largeTruckAllowed: '可',
        freight: '100000',
        shipperName: '株式会社サンプル',
        description: ''
    });

    // Download
    // https://github.com/exceljs/exceljs#writing-csv
    const options = {
        formatterOptions: {
            rowDelimiter: '\r\n',
        },
    };
    const contentBlob = await wb.csv.writeBuffer(options);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

    saveAs(
        new Blob([bom, contentBlob], { type: 'text/octet-binary' }),
        'baggage-csv.csv'
    );
};

const defaultDepartureDate = (): string => {
    const now = DateUtil.now().add(1, 'hour');
    return `${ now.format('YYYY/MM/DD') } ${ now.toDate().getHours() }:00`;
};

const defaultArrivalDate = (departureDate: string): string => {
    const tomorrow = DateUtil.parseDateText(departureDate).add(1, 'day');
    return tomorrow.format('YYYY/MM/DD HH:mm');
};
