<script setup lang="ts">
import DeliveryOrderSheet from '@/_pages/DeliveryOrder/components/DeliveryOrderSheet.vue';
import { useDeliveryOrderPreviewHelper } from '@/_pages/DeliveryOrder/Preview/delivery-order-preview-helper';

const {
    loading,
    sending,
    isBaggageCompany,
    isSent,
    isReplied,
    deliveryOrder,
    myCompanyProfile,
    partnerCompanyProfile,
    onClickEditButton,
    onClickSendButton,
    onClickReplyButton,
    onClickPrintButton,
    onClickCloseButton,
} = useDeliveryOrderPreviewHelper();

</script>

<template>
    <div class="container">
        <a-spin :spinning="loading">
            <a-row type="flex" class="primary-actions" justify="space-between">
                <div class="action-buttons">
                    <template v-if="isBaggageCompany">
                        <a-button @click="onClickEditButton">編集</a-button>
                        <a-button type="primary" @click="onClickSendButton" :disabled="sending">送信</a-button>
                        <span v-if="isSent">最終送信日時: {{ deliveryOrder.lastSentDateTime }}</span>
                    </template>
                    <template v-else>
                        <a-button type="primary" @click="onClickReplyButton">車番を返信</a-button>
                        <span v-if="isReplied">最終返信日時: {{ deliveryOrder.lastRepliedDateTime }}</span>
                    </template>
                </div>
                <div :style="{ fontSize: '20px' }" class="secondary-actions">
                    <a-button @click="onClickPrintButton">印刷</a-button>
                    <a-icon @click="onClickCloseButton" type="close" :style="{ fontSize: '20px' }"></a-icon>
                </div>
            </a-row>
            <delivery-order-sheet
                v-if="deliveryOrder && partnerCompanyProfile"
                :delivery-order="deliveryOrder"
                :my-company-profile="myCompanyProfile"
                :partner-company-profile="partnerCompanyProfile"
            />
        </a-spin>
    </div>
</template>

<style scoped lang="less">
.container {
    padding: 20px;
}

.primary-actions {
    width: 790px;
    margin-bottom: 16px;

    > .action-buttons {
        > button {
            margin-right: 12px;
        }
    }
}

.secondary-actions {
    > button {
        margin-right: 12px;
    }
}
</style>
